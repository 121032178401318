import React, { FC, ReactNode, useState } from 'react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import SupplierRegistrationCard from '@mortee/routes/validationSystem/createValidationRecord/supplierRegistrationSelector/SupplierRegistrationCard';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import NakedFormSearchInput from '@app/components/NakedFormSearchInput';
import useLoadable from '@app/hooks/loadable/useLoadable';
import ValidationSystemServices from '@mortee/services/validationSystemServices';
import { transformToSupplierRegistrationProcess } from '@mortee/services/supplierRegistrationManagementServices';
import { NULL_ORG } from '@mortee/domain/validationSystem';
import PaginatedInfiniteScroll from '@app/components/PaginatedInfiniteScroll';
import AsyncAccumulatorPaginationManager from '@app/utils/pagination/AsyncAccumulatorPaginationManager';
import InternalMemoryNextPageLoader from '@app/utils/pagination/InternalMemoryNextPageLoader';
import PaginationManager from '@app/utils/pagination/PaginationManager';
import Loadable from '@app/utils/Loadable';
import { useConsistentMemo } from '@app/hooks/useConsistentMemo';
import ToggleButtonGroup from '@app/components/inputs/ToggleButtonGroup';
import Toggle from '@app/components/inputs/Toggle';

interface Props {
  currentOrganizationId: string | undefined;
  className?: string;
  isOriginatedFromRegistrationForm: boolean;
  scrollableTargetId: string;
  onLink(selected: SupplierRegistrationProcess): void;
}

const RECOMMENDATIONS_CARDS_AMOUNT = 10;

const SupplierRegistrationSelectorSearch: FC<Props> = ({
  onLink,
  className,
  isOriginatedFromRegistrationForm,
  currentOrganizationId,
  scrollableTargetId,
}) => {
  async function loadRecommendedSupplierRegistrationProcessesForOrganization(
    organizationId: string | null,
  ): Promise<SupplierRegistrationProcess[]> {
    const supplierRegistrationProcesses = await ValidationSystemServices.getRecommendedSupplierRegistrationProcessesForOrganization(
      organizationId,
      showHiddenRecords,
    );
    return supplierRegistrationProcesses.map(transformToSupplierRegistrationProcess);
  }

  const shouldOnlySearchNullOrganizations = !currentOrganizationId || currentOrganizationId === NULL_ORG.id;
  const [searchNullOrg, setSearchNullOrg] = useState<boolean>(shouldOnlySearchNullOrganizations);
  const [showHiddenRecords, setShowHiddenRecords] = useState<boolean>(false);

  const [recommendedRegistrationProcessesLoadable] = useLoadable(async () => {
    if (searchNullOrg) {
      return await loadRecommendedSupplierRegistrationProcessesForOrganization(null);
    }

    if (!currentOrganizationId) {
      return [];
    }

    return await loadRecommendedSupplierRegistrationProcessesForOrganization(currentOrganizationId);
  }, [currentOrganizationId, searchNullOrg, showHiddenRecords]);

  const [filteredResults, search, setSearch] = useLoadableSearchFilter(recommendedRegistrationProcessesLoadable, {
    partialMatches: (item) => [
      item.companyName,
      item.registrationNumber,
      item.referringCustomer,
      item.fullName,
      item.registrationId,
      item.additionalCompanyName,
    ],
  });

  const paginationManager = useConsistentMemo((): Loadable<PaginationManager<SupplierRegistrationProcess>> => {
    return filteredResults.map((supplierRegistrationProcesses) => {
      return new AsyncAccumulatorPaginationManager(
        new InternalMemoryNextPageLoader<SupplierRegistrationProcess>(
          supplierRegistrationProcesses,
          RECOMMENDATIONS_CARDS_AMOUNT,
          null,
        ),
      );
    });
  }, [filteredResults]);

  function renderSearchItems(items: SupplierRegistrationProcess[]): ReactNode {
    if (items.length === 0) {
      return 'No recommendations available';
    }

    return (
      <Container>
        {items.map((result) => (
          <SupplierRegistrationCard
            key={result.id}
            item={result}
            link={
              !isOriginatedFromRegistrationForm && {
                isLinked: false,
                onLinkChanged: (): void => onLink(result),
              }
            }
          />
        ))}
      </Container>
    );
  }

  return (
    <Container className={className}>
      <InputBox appearance='corners'>
        <NakedFormSearchInput
          name='inpt-sr-selector-search'
          type='text'
          placeholder='Search for a Name, Additional Company Name, Registration number, Registration id'
          placeholderStyle='onlyWhenEmpty'
          clearable
          value={search}
          onChange={(newValue): void => setSearch(newValue ?? '')}
        />
      </InputBox>
      <SupplierRegistrationProcessesOptions>
        <ToggleButtonGroup
          id='toggle-sr-customer-search'
          value={searchNullOrg}
          fixedValue={shouldOnlySearchNullOrganizations || undefined}
          onChange={(newValue: boolean): void => setSearchNullOrg(newValue)}
          accessibilityLabel='Search only Registration Forms without organizations'
          options={[
            {
              text: 'Same customer',
              value: false,
              id: 'toggle-sr-same-customer',
            },
            {
              text: 'Unknown customer',
              value: true,
              id: 'toggle-sr-unknown-customer',
            },
          ]}
        />
        <Toggle
          accessibilityLabel='Toggle show hidden records'
          name='toggle-edit-validation-process-show-hidden-records'
          value={showHiddenRecords}
          onChange={(newValue): void => setShowHiddenRecords(!!newValue)}
        />
        Show Hidden Records
      </SupplierRegistrationProcessesOptions>
      <StyledPaginatedInfiniteScroll
        id='supplier-registration-process-infinite-scroll'
        dataTestId='supplier-registration-process-infinite-scroll'
        paginationManager={paginationManager}
        loader={<CircularProgress color='primary' size={32} />}
        scrollableTarget={scrollableTargetId}
      >
        {(items: SupplierRegistrationProcess[]): ReactNode => renderSearchItems(items)}
      </StyledPaginatedInfiniteScroll>
    </Container>
  );
};

export default SupplierRegistrationSelectorSearch;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SupplierRegistrationProcessesOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
`;

const StyledPaginatedInfiniteScroll = styled(PaginatedInfiniteScroll)`
  overflow: hidden !important;
`;
