import UserStore from './/UserStore';
import config, { serverUrl } from '@app/config';
import { checkHealthCheckResultValid, serverHealthCheck } from '@app/services/healthCheckServices';

export default class HealthCheckStore {
  protected userStore: UserStore;

  constructor(userStore: UserStore) {
    this.userStore = userStore;
  }

  async runHealthCheck(): Promise<void> {
    if (!config.runHealthCheck || this.userStore.user) {
      return;
    }

    const healthCheckResult = await serverHealthCheck(serverUrl);

    if (!checkHealthCheckResultValid(healthCheckResult)) {
      throw new Error('health check result is invalid');
    }
  }
}
