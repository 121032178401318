import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { KnoxerIdentityProvidersServerResponse } from '@app/domain/userManagement/identityProviders';

export async function getAllIdentityProviders(knoxerId: string): Promise<KnoxerIdentityProvidersServerResponse> {
  return request<KnoxerIdentityProvidersServerResponse>(serverUrl, `/api/identity-providers/:knoxerId`, {
    method: HttpMethod.get,
    pathParams: {
      knoxerId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot get identity providers list.',
      },
    },
  });
}

export async function getAllSSOIdentityProviders(knoxerId: string): Promise<KnoxerIdentityProvidersServerResponse> {
  return request<KnoxerIdentityProvidersServerResponse>(serverUrl, `/api/identity-providers/:knoxerId/sso`, {
    method: HttpMethod.get,
    pathParams: { knoxerId },
    errorsHandler: {
      default: {
        message: 'Cannot get SSO providers.',
      },
    },
  });
}
