import React, { FunctionComponent, ReactNode } from 'react';
import Loader, { LoaderProps } from './Loader';
import styled from '@emotion/styled';

interface TransitionLoaderProps extends Omit<LoaderProps, 'spinning'> {
  loading: boolean;
  semiHideContent?: boolean;
  contentClassName?: string;
  className?: string;
  children: ReactNode;
}

const TransitionLoader: FunctionComponent<TransitionLoaderProps> = (props) => {
  const { loading, semiHideContent, contentClassName, className, children, ...loaderProps } = props;

  return (
    <Container className={className}>
      {loading && <Loader spinning transparent {...loaderProps} />}
      <ContentContainer isHidden={loading} semiHideContent={semiHideContent} className={contentClassName}>
        {children}
      </ContentContainer>
    </Container>
  );
};

export default TransitionLoader;

const Container = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div<{ isHidden: boolean; semiHideContent: boolean | undefined }>`
  ${(p): string => {
    if (!p.isHidden) {
      return '';
    }

    if (p.semiHideContent) {
      return 'opacity: 0.5;';
    }

    return 'visibility: hidden;';
  }}
`;
