import luhn from 'fast-luhn';
import { COUNTRY_CODES } from '@app/domain/countries';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';

export function validateTaxIdAccordingToCountry(countryCode: string, taxIdValue: string): string | undefined {
  const taxIdCountryValidator = taxIdCountryValidators[countryCode];

  const relevantTaxIdValidator = taxIdCountryValidator ?? defaultTaxIdValidator;

  return relevantTaxIdValidator(taxIdValue);
}

type TaxIdCountryValidator = (taxId: string) => string | undefined;

function defaultTaxIdValidator(taxId): string | undefined {
  if (!VALIDATION_PATTERNS.alphanumeric.test(taxId)) {
    return 'Tax id value must be alphanumeric';
  }

  if (taxId[0] === '0') {
    return 'Tax id value cannot contain leading zeros';
  }
}

const IL_TAX_ID_VALUE_LENGTH = 9;
const US_TAX_ID_VALUE_LENGTH = 9;
const BR_TAX_ID_VALUE_LENGTH = 14;
const TW_TAX_ID_VALUE_LENGTH = 8;
const AE_TAX_ID_VALUE_LENGTH = 15;
const IN_TAX_ID_VALUE_LENGTH = 15;
const IN_TAX_ID_VALUE_WITH_NO_LEADING_ZEROES_LENGTH = 10;

function standardNumericPatternAndLengthCheck(taxId: string, length: number): string | null {
  if (!VALIDATION_PATTERNS.numeric.test(taxId)) {
    return 'Tax id value must be numeric';
  }

  if (taxId.length !== length) {
    return `Tax id value must be ${length} digits`;
  }
  return null;
}

const taxIdCountryValidators: Partial<Record<COUNTRY_CODES, TaxIdCountryValidator>> = {
  [COUNTRY_CODES.Israel]: (taxId): string | undefined => {
    const numericLengthCheck = standardNumericPatternAndLengthCheck(taxId, IL_TAX_ID_VALUE_LENGTH);
    if (numericLengthCheck) {
      return numericLengthCheck;
    }

    if (!luhn(taxId)) {
      return 'Tax id value contains invalid checksum digit';
    }
  },
  [COUNTRY_CODES.UnitedStates]: (taxId): string | undefined => {
    const numericLengthCheck = standardNumericPatternAndLengthCheck(taxId, US_TAX_ID_VALUE_LENGTH);
    if (numericLengthCheck) {
      return numericLengthCheck;
    }
  },
  [COUNTRY_CODES.Brazil]: (taxId): string | undefined => {
    const numericLengthCheck = standardNumericPatternAndLengthCheck(taxId, BR_TAX_ID_VALUE_LENGTH);
    if (numericLengthCheck) {
      return numericLengthCheck;
    }
  },
  [COUNTRY_CODES.Taiwan]: (taxId): string | undefined => {
    const numericLengthCheck = standardNumericPatternAndLengthCheck(taxId, TW_TAX_ID_VALUE_LENGTH);
    if (numericLengthCheck) {
      return numericLengthCheck;
    }
  },
  [COUNTRY_CODES.UnitedArabEmirates]: (taxId): string | undefined => {
    const numericLengthCheck = standardNumericPatternAndLengthCheck(taxId, AE_TAX_ID_VALUE_LENGTH);
    if (numericLengthCheck) {
      return numericLengthCheck;
    }
  },
  [COUNTRY_CODES.India]: (taxId): string | undefined => {
    if (!VALIDATION_PATTERNS.alphanumeric.test(taxId)) {
      return 'Tax id value must be alphanumeric';
    }

    if (taxId[0] === '0' && taxId.length === IN_TAX_ID_VALUE_WITH_NO_LEADING_ZEROES_LENGTH) {
      return `Tax id value can only be ${IN_TAX_ID_VALUE_WITH_NO_LEADING_ZEROES_LENGTH} digits when there are no leading zeroes`;
    } else if (taxId[0] !== '0' && taxId.length === IN_TAX_ID_VALUE_WITH_NO_LEADING_ZEROES_LENGTH) {
      return;
    }

    if (taxId.length !== IN_TAX_ID_VALUE_LENGTH) {
      return `Tax id value must be ${IN_TAX_ID_VALUE_LENGTH} digits`;
    }
  },
};
