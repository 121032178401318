import React, { FC } from 'react';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import { isTruthy } from '@app/utils/utils';

interface Props {
  organizationId: string;
  canAddUsersToOrganization: boolean;
  onManageUsers(): void;
  onAddUsers(): void;
}

const OrganizationActionsMenu: FC<Props> = ({ organizationId, canAddUsersToOrganization, onManageUsers, onAddUsers }) => {
  function calcMenuItems(): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        id: `btn-org-${organizationId}-manage-users`,
        dataTestId: `btn-org-manage-users`,
        text: canAddUsersToOrganization ? 'Manage Users' : 'View Users',
        onClick: (): void => onManageUsers(),
      },
      canAddUsersToOrganization && {
        id: `btn-org-${organizationId}-add-users`,
        dataTestId: `btn-org-add-users`,
        text: 'Add Users to Organization',
        onClick: (): void => onAddUsers(),
      },
    ].filter(isTruthy);

    if (menuItems.length) {
      return menuItems;
    }

    // Fallback
    return [
      {
        id: `btn-${organizationId}-no-actions`,
        dataTestId: `btn-no-actions`,
        text: 'No actions available',
        onClick: (): void => {},
        disabled: true,
      },
    ];
  }

  return (
    <Menu
      menuItems={calcMenuItems()}
      id={`orgs-table-single-org-${organizationId}-menu`}
      dataTestId='orgs-table-single-org-menu'
    />
  );
};

export default OrganizationActionsMenu;
