import React, { FunctionComponent, ReactNode, useState } from 'react';
import ExpanderButton from './ExpanderButton';

interface ExpanderProps {
  id: string;
  title: string;
  children: ReactNode;
  accessibilityLabel: string;
}

const Expander: FunctionComponent<ExpanderProps> = (props) => {
  const { id, title, accessibilityLabel, children } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <ExpanderButton aria-label={accessibilityLabel} id={id} expanded={expanded} setExpanded={setExpanded}>
        {title}
      </ExpanderButton>
      {expanded && children}
    </div>
  );
};

export default Expander;
