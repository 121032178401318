import React, { FC, ReactElement, ReactNode, Ref, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { extractIbanCountryCode } from '@app/domain/accountsDetailsHelpers';
import { FormComponentProps, GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormFieldDecorators } from '@app/utils/form/form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import FormRadioGroup, { RadioOption } from '@app/components/inputs/FormRadioGroup';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import COUNTRIES, { COUNTRY_CODES, getCountryByCountryCode } from '@app/domain/countries';
import Nationality from '../Nationality';
import {
  AccountVerificationField,
  getAccountValidationFields,
  getAccountValidationForLocalFormatFields,
  isValidDomesticCountry,
  isValidLocalFormatCountry,
  SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES_ARRAY,
  VERIFICATION_ACCOUNT_TYPE,
} from '@app/domain/accountVerification';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';
import { InputBoxAppearance } from '@app/components/inputs/inputBox/InputBox';
import config from '@app/config';
import useMountEffect from '@app/hooks/useMountEffect';
import { useTranslation } from 'react-i18next';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import useInfraStores from '@app/hooks/useInfraStores';
import ToggleButtonGroup from '@app/components/inputs/ToggleButtonGroup';

export interface VerifyAccountFormFields {
  type: VERIFICATION_ACCOUNT_TYPE;
  accountDetails: Partial<MorteeAccountDetailsExtended>;
}

const DEFAULT_COUNTRY_CODE = COUNTRY_CODES.UnitedStates;
const isARMode = config.mode === 'ar';

export const defaultSearchAccountValues: VerifyAccountFormFields = {
  type: isARMode ? VERIFICATION_ACCOUNT_TYPE.domestic : VERIFICATION_ACCOUNT_TYPE.swift,
  accountDetails: {
    countryCode: DEFAULT_COUNTRY_CODE,
    iban: '',
    swiftCode: '',
    bankCode: '',
    branchCode: '',
    accountNumber: '',
    localFormat: '',
  },
};

interface AccountFormFields extends Omit<VerifyAccountFormFields, 'accountDetails'> {}

interface SearchAccountProps extends FormComponentProps<VerifyAccountFormFields> {
  trySubmit: () => void;
  isFormDisabled?: boolean;
  payeeCountryCode?: string | null;
  initialFormValues?: VerifyAccountFormFields | null;
  showFormErrors?: FormItemsBoxProps['showErrors'];
  resetShowFormErrors?: () => void;
  appearance?: InputBoxAppearance;
  stretch?: boolean;
}

const SearchAccount: FC<SearchAccountProps> = observer((props) => {
  const { payeeCountryCode, form, appearance = 'corners', stretch = false } = props;
  const initialFormValues = props.initialFormValues ?? defaultSearchAccountValues;
  const { t } = useTranslation();
  const { languageStore } = useInfraStores();

  useMountEffect(() => {
    form.setFieldsValue({ accountDetails: { ...initialFormValues.accountDetails } });
  });

  const fieldDecorators: FormFieldDecorators<AccountFormFields> = {
    type: {
      initialValue: initialFormValues?.type ?? undefined,
      rules: [
        {
          required: true,
          message: convertToTranslatedMessage(['verifyAccount.errors.pleaseChooseAccountType', 'Please choose account type']),
        },
      ],
    },
  };

  const inputRefs: Ref<Map<string, HTMLInputElement>> = useRef(new Map());

  const getInitialCountryCode = (): string => {
    if (initialFormValues.accountDetails?.countryCode) {
      return initialFormValues.accountDetails?.countryCode;
    }

    if (isValidDomesticCountry(payeeCountryCode)) {
      return payeeCountryCode;
    }

    return DEFAULT_COUNTRY_CODE;
  };

  const [isLocalFormat, setIsLocalFormat] = useState<boolean>(false);

  const countryCodeFieldDecorators: GetFieldDecoratorOptions = {
    initialValue: getInitialCountryCode(),
  };

  const selectedType: VERIFICATION_ACCOUNT_TYPE =
    props.form.getFieldValue('type') || initialFormValues.type || defaultSearchAccountValues.type;

  const selectedCountryCode: string =
    props.form.getFieldValue('accountDetails.countryCode') ||
    initialFormValues.accountDetails.countryCode ||
    defaultSearchAccountValues.accountDetails.countryCode;

  const generateHandleOnChange = (
    maxLength: number | undefined,
    nextFieldToFocusOnMax: string | undefined,
  ): ((newValue: string) => void) => {
    return (newValue: string): void => {
      if (nextFieldToFocusOnMax && maxLength && newValue.trim().length >= maxLength) {
        const nextRef = inputRefs.current?.get(nextFieldToFocusOnMax);
        nextRef?.focus();
      }
    };
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    const { validateFields } = props.form;

    // this is here because otherwise the 'SUBMIT' button is enabled in some situations
    validateFields();

    if (e.key === 'Enter') {
      e.preventDefault();
      await props.trySubmit();
    }
  };

  const typeChanged = (newType: VERIFICATION_ACCOUNT_TYPE): void => {
    const { form, resetShowFormErrors } = props;
    const { validateFields, setFieldsValue } = form;

    setFieldsValue({
      accountDetails: {
        iban: '',
        swiftCode: '',
        bankCode: '',
        branchCode: '',
        accountNumber: '',
        localFormat: '',
      },
    });

    if (newType === VERIFICATION_ACCOUNT_TYPE.domestic) {
      setFieldsValue({
        accountDetails: {
          countryCode: countryCodeFieldDecorators.initialValue,
        },
      });
    }

    validateFields();
    resetShowFormErrors?.();
    inputRefs.current?.clear();
  };

  const changeCountry = (newCountry: string): void => {
    const { form, resetShowFormErrors } = props;
    const { validateFields, setFieldsValue } = form;

    setFieldsValue({
      accountDetails: {
        countryCode: newCountry,
        iban: '',
        swiftCode: '',
        bankCode: '',
        branchCode: '',
        accountNumber: '',
        localFormat: '',
      },
    });

    validateFields();
    resetShowFormErrors?.();
    inputRefs.current?.clear();
  };

  const getAccountTypeOptions = (): RadioOption[] => {
    const typeOptions = {
      domestic: {
        value: VERIFICATION_ACCOUNT_TYPE.domestic,
        label: t('verifyAccount.domesticAccount', 'Domestic Account'),
      },
      swift: {
        value: VERIFICATION_ACCOUNT_TYPE.swift,
        label: t('verifyAccount.swift', 'SWIFT/BIC'),
      },
      iban: {
        value: VERIFICATION_ACCOUNT_TYPE.iban,
        label: t('verifyAccount.iban', 'IBAN'),
      },
    };

    if (isARMode) {
      return [typeOptions.domestic, typeOptions.swift, typeOptions.iban];
    }

    return [typeOptions.swift, typeOptions.iban, typeOptions.domestic];
  };

  const renderAccountTypesRadioGroup = (): ReactElement => {
    return (
      <FormItemBoxHiddenBottom
        form={form}
        fieldName='type'
        appearance='none'
        fieldDecoratorOptions={fieldDecorators.type}
        showErrors='none'
      >
        <FormRadioGroup
          accessibilityLabel={t('general.accessibility.accountType')}
          disabled={props.isFormDisabled}
          textSize='medium'
          color='secondary'
          id='radio-group-guest-validation-account-type'
          dataTestId='radio-group-guest-validation-account-type'
          onChange={typeChanged}
          options={getAccountTypeOptions()}
        />
      </FormItemBoxHiddenBottom>
    );
  };

  const renderCountryDropdown = (): ReactNode => {
    return (
      <CountryFormItemBox
        hidden={selectedType !== VERIFICATION_ACCOUNT_TYPE.domestic}
        form={form}
        fieldName='accountDetails.countryCode'
        fieldDecoratorOptions={countryCodeFieldDecorators}
        showErrors={props.showFormErrors}
        appearance={appearance}
        colorScheme='primary'
        disabled={props.isFormDisabled}
        key='countryCode'
      >
        <NakedDropdown
          accessibilityLabel={t('general.accessibility.country')}
          name='drp-validation-domestic-country'
          dataTestId='drp-validation-domestic-country'
          placeholder={t<string>('verifyAccount.country', 'Country')}
          onChange={changeCountry}
          isSearchable
        >
          {getCountriesListForSearchType().map((country) => {
            return (
              <CountryDropdownItem
                key={country.value}
                value={country.value}
                textWhenSelected={t(`general.countries.${country.label}`, country.label)}
              >
                <div key={`${languageStore.selectedLanguageKey}-${languageStore.isLanguageLoading}-selected-country`}>
                  {t(`general.countries.${country.label}`, country.label)}
                </div>
              </CountryDropdownItem>
            );
          })}
        </NakedDropdown>
      </CountryFormItemBox>
    );
  };

  const localFormatToggleButton = (): ReactNode => {
    return (
      <ToggleButtonGroupDiv
        hidden={!isValidLocalFormatCountry(selectedCountryCode) || selectedType !== VERIFICATION_ACCOUNT_TYPE.domestic}
      >
        <ToggleButtonGroup
          id='toggle-sr-customer-search'
          value={isLocalFormat}
          onChange={(newValue: boolean): void => setIsLocalFormat(newValue)}
          accessibilityLabel={t('general.accessibility.domesticType')}
          options={[
            {
              text: t<string>('verifyAccount.domesticAccount', 'Domestic Account'),
              value: false,
              id: 'toggle-sr-domestic',
            },
            {
              text: t<string>('verifyAccount.localFormat', 'CLABE'),
              value: true,
              id: 'toggle-sr-local-format',
            },
          ]}
        />
      </ToggleButtonGroupDiv>
    );
  };

  const renderIbanField = (fieldKey: string, field?: AccountVerificationField): ReactElement => {
    const currentIban = form.getFieldValue('accountDetails.iban') as string | null;
    const ibanCountry = extractIbanCountryCode(currentIban);

    return (
      <InputWithFlagContainer hidden={!field} key='iban'>
        <StyledFormItemBox
          hidden={false}
          form={form}
          fieldName='accountDetails.iban'
          fieldDecoratorOptions={field?.decorator() ?? {}}
          showErrors={props.showFormErrors}
          appearance={appearance}
          colorScheme='primary'
          disabled={props.isFormDisabled}
        >
          <NakedFormInput
            name='inpt-guest-validation-iban'
            type='string'
            autoFocus
            placeholder={t<string>('verifyAccount.iban', 'IBAN')}
            disableSuggestion
            onKeyPress={handleKeyPress}
          />
        </StyledFormItemBox>
        {getCountryByCountryCode(ibanCountry ?? '') && (
          <FloatingNationality countryCode={ibanCountry} unknownFlagStyle='wavyFlag' />
        )}
      </InputWithFlagContainer>
    );
  };

  const renderField = (fieldType: string, field?: AccountVerificationField): ReactElement => {
    const fieldKey = field?.id ?? fieldType;
    const decorator = field?.decorator();

    return (
      <StyledFormItemBox
        hidden={!field}
        form={form}
        fieldName={`accountDetails.${fieldType}`}
        fieldDecoratorOptions={decorator ?? {}}
        showErrors={field ? props.showFormErrors : 'none'}
        appearance={appearance}
        colorScheme='primary'
        disabled={props.isFormDisabled}
        key={fieldKey}
      >
        <NakedFormInput
          name={field?.id ? `inpt-guest-validation-${field.id}` : ''}
          key={fieldKey}
          type={field?.inputType ?? 'text'}
          placeholder={field?.displayName ?? ''}
          disableSuggestion
          onKeyPress={handleKeyPress}
          onChange={generateHandleOnChange(decorator?.rules?.find((x) => !!x.max)?.max, field?.nextFieldToFocusOnMax)}
          maxLength={decorator?.rules?.find((rule) => !!rule.max)?.max}
          ref={(currentInputRef): void => {
            field && currentInputRef && inputRefs.current?.set(field.id, currentInputRef);
          }}
        />
      </StyledFormItemBox>
    );
  };

  function getCountriesListForSearchType(): { value: COUNTRY_CODES; label: string }[] {
    let countriesList;

    if (selectedType === VERIFICATION_ACCOUNT_TYPE.domestic) {
      countriesList = SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES_ARRAY;
    } else {
      countriesList = Object.values(COUNTRY_CODES);
    }

    return countriesList
      .map((code) => ({
        value: code,
        label: COUNTRIES[code].name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  const renderInputFields = (): ReactElement => {
    const accountDetailsFields: Record<string, typeof renderField> = {
      iban: renderIbanField,
      swiftCode: renderField,
      bankCode: renderField,
      branchCode: renderField,
      accountNumber: renderField,
      localFormat: renderField,
    };

    let fieldDeclarationsBySelectedType:
      | Partial<Record<keyof MorteeAccountDetailsExtended, AccountVerificationField>>
      | undefined;

    const showLocalFormat =
      selectedType === VERIFICATION_ACCOUNT_TYPE.domestic && isLocalFormat && isValidLocalFormatCountry(selectedCountryCode);

    if (showLocalFormat && selectedCountryCode === COUNTRY_CODES.Mexico && selectedType === VERIFICATION_ACCOUNT_TYPE.domestic) {
      fieldDeclarationsBySelectedType = getAccountValidationForLocalFormatFields(selectedCountryCode, t);
    } else if (selectedType === VERIFICATION_ACCOUNT_TYPE.domestic) {
      fieldDeclarationsBySelectedType = getAccountValidationFields(selectedCountryCode, t);
    } else {
      fieldDeclarationsBySelectedType = getAccountValidationFields(selectedType, t);
    }

    return (
      <>
        {Object.entries(accountDetailsFields).map(([accountDetailsFieldName, fieldRenderer]) => {
          return fieldRenderer(accountDetailsFieldName, fieldDeclarationsBySelectedType?.[accountDetailsFieldName]);
        })}
      </>
    );
  };

  const renderFields = (): ReactElement => {
    return (
      <FieldsContainer
        multipleLines={
          selectedType !== VERIFICATION_ACCOUNT_TYPE.domestic ||
          (selectedType === VERIFICATION_ACCOUNT_TYPE.domestic && isValidLocalFormatCountry(selectedCountryCode))
        }
      >
        <RowDiv>
          {renderCountryDropdown()}
          {localFormatToggleButton()}
        </RowDiv>
        <RowDiv>{renderInputFields()}</RowDiv>
      </FieldsContainer>
    );
  };

  return (
    <AccountContainer stretch={stretch}>
      {renderAccountTypesRadioGroup()}
      {renderFields()}
    </AccountContainer>
  );
});

export default SearchAccount;

const StyledFormItemBox = styled(FormItemBox)<{ hidden: boolean }>`
  flex: 1;
  margin-left: 12px;

  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;

const AccountContainer = styled.div<{ stretch?: boolean }>`
  font-family: var(--text-font-family);

  padding-top: 8px;
  z-index: 50;

  ${(p): string => (p.stretch ? 'flex: 1;' : '')}
  .ant-form-item {
    margin-bottom: 25px;

    &.ant-form-item-with-help {
      margin-bottom: 5px;
    }

    & .ant-form-explain {
      min-height: 21px;
    }
  }
`;

const FieldsContainer = styled.div<{ multipleLines: boolean }>`
  display: flex;
  margin-left: -12px;
  flex-direction: ${(p): string => (p.multipleLines ? 'column' : 'row')};
`;

const InputWithFlagContainer = styled.div<{ hidden: boolean }>`
  flex: 1;
  position: relative;
  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;

const FloatingNationality = styled(Nationality)`
  position: absolute;
  inset-inline-end: 20px;
  top: 22px;
`;

const CountryFormItemBox = styled(FormItemBox)<{ hidden: boolean }>`
  margin-left: 12px;
  width: 300px;
  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;

const ToggleButtonGroupDiv = styled.div<{ hidden: boolean }>`
  padding: 0 15px;
  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;

const CountryDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const FormItemBoxHiddenBottom = styled(FormItemBox)`
  .MuiFormControlLabel-root-1 {
    margin-right: 10px !important;
  }
`;

const RowDiv = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;
