import config, { AppMode } from '@app/config';
import { css } from '@emotion/css';

const WIDTH_BY_TYPE: { [appMode in AppMode]: number } = {
  mortee: 950,
  supplierRegistration: 1000,
  ar: 1000,
  backee: 1000,
};

export const MAX_PAGE_WIDTH = WIDTH_BY_TYPE[config.mode] ?? 1100;

export const LOGIN_FIELD_MIN_LENGTH = {
  password: 8,
};

export const LOGIN_FIELD_MAX_LENGTH = {
  username: 255,
  password: 255,
};

export const GENERAL_FIELD_MAX_LENGTH = {
  country: 30,
  countryCode: 4,
  dialCode: 4,
  phoneNumber: 36,
};

export const INFORMATION_FIELD_MAX_LENGTH = {
  KR: {
    BNR: 10,
  },
  BR: {
    CNPJ: 14,
  },
  IL: {
    accountNumber: 10,
    leiLength: 9,
  },
};

export const ACCOUNT_FIELD_MAX_LENGTH = {
  swiftAccount: 35,
  sortCode: 6,
  AU: {
    bank: 6,
  },
  GB: {
    accountNumber: 8,
  },
  IL: {
    bank: 2,
    branch: 3,
    accountNumber: 13,
  },
  US: {
    achRouting: 9,
    abaRouting: 9,
    accountNumber: 17,
  },
  CA: {
    routing: 9,
    accountNumber: 20,
    institutionNumber: 3,
    transitNumber: 5,
  },
  TW: {
    bank: 3,
    branch: 4,
    accountNumber: 14,
  },
  NZ: {
    bank: 2,
    branch: 4,
    accountNumber: 16,
  },
  IN: {
    IFSC: 11,
    accountNumber: 20,
  },
  ZA: {
    bank: 6,
    branch: 6,
    accountNumber: 11,
  },
  KR: {
    bank: 3,
    accountNumber: 35,
  },
  JP: {
    bank: 4,
    branch: 3,
  },
  BR: {
    bank: 3,
    branch: 4,
  },
  MX: {
    bank: 3,
    branch: 3,
    accountNumber: 11,
    clabe: 18,
  },
  SG: {
    bank: 4,
    branch: 3,
    accountNumber: 17,
  },
  NO: {
    bank: 4,
    accountNumber: 7,
  },
  FI: {
    bank: 6,
    accountNumber: 8,
  },
  DE: {
    bank: 8,
    accountNumber: 10,
  },
  DK: {
    bank: 4,
    accountNumber: 10,
  },
  iban: 35,
  furtherCredit: 200,
  accountBankName: 150,
  default: {
    bank: 25,
    branch: 25,
    accountNumber: 35,
  },
};

/**
 * Used with keyboardEvent.which
 */
export enum KeyAsciiValue {
  Enter = 13,
  Space = 32,
}

export enum KeyName {
  Escape = 'Escape',
  Enter = 'Enter',
  ArrowLeft = 'ArrowLeft',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
}

export const WRAP_WORDS_CSS_PROPERTIES = css`
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
`;

export const VALIDATION_PATTERNS = {
  email: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/,
  domainOrEmail: /^((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@)?(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/,
  url: /^(http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,10}(:[0-9]{1,5})?(\/.*)?$/,
  numeric: /^[0-9]*$/,
  alphanumeric: /^[a-zA-Z0-9]*$/,
  alphanumericAndSpecialChars: /^[ \p{L}\p{N}&\-.(),\\/+!@#$%^_~'‘"“\u201F\u201D\u05F4\p{scx=Hira}\p{scx=Han}\u3000-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF]*$/u,
  alphanumericAndSpecialCharsWithNewline: /^[\s\p{L}\p{N}&\-.(),\\/+!@#$%^_~'‘"“\u201F\u201D\u05F4]*$/u,
  alphanumericWithChinese: /^[a-zA-Z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
  alphanumericWithChineseAndDashes: /^[a-zA-Z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF\\-]*$/,
  numericWithSpecialChars: /^[ \p{N}&\-.(),\\/+!@#$%^_~'‘"“\u201F\u201D\u05F4]*$/u,
  fullKnoxIdOrKnoxIdDigits: /^(?:KNOX)?([0-9]+)(?:\/[a-zA-Z0-9]+)?$/,
  fullKnoxId: /^KNOX([0-9]+)(?:\/[a-zA-Z0-9]+)?$/,
  uuid: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  numericWithDashAndSpace: /^[0-9 -]+$/,
};

export const REQUIRED_FORM_MESSAGE = '*Required';
