import IBAN from 'iban';
import { extractValidSwiftCodeCountryCode } from '@app/domain/accountsDetailsHelpers';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';

const SWIFT_CODE_VALIDATION_REGEX = /^[a-zA-Z]{4}[a-zA-Z0-9]{4}([a-zA-Z0-9]{3})?$/;
const SWIFT_CODE_VALIDATION_WITH_SUFFIX_REGEX = /^[a-zA-Z]{4}[a-zA-Z0-9]{7}$/;
const EXPERIMENTAL_IBAN_REGEX_LIST = [
  '^DZ[A-Z0-9]{24}$', // Algeria
  '^AO[A-Z0-9]{23}$', // Angola
  '^BJ[A-Z0-9]{26}$', // Benin
  '^BF[A-Z0-9]{26}$', // Burkina Faso
  '^CM[A-Z0-9]{25}$', // Cameroon
  '^CV[A-Z0-9]{23}$', // Cape Verde
  '^CF[A-Z0-9]{25}$', // Central African Republic
  '^TD[A-Z0-9]{25}$', // Chad
  '^KM[A-Z0-9]{25}$', // Comoros
  '^CG[A-Z0-9]{25}$', // Congo
  '^GQ[A-Z0-9]{25}$', // Equatorial Guinea
  '^GA[A-Z0-9]{25}$', // Gabon
  '^GW[A-Z0-9]{23}$', // Guinea-Bissau
  '^HN[A-Z0-9]{26}$', // Honduras
  '^IR[A-Z0-9]{24}$', // Iran
  '^CI[A-Z0-9]{26}$', // Ivory Coast
  '^MG[A-Z0-9]{25}$', // Madagascar
  '^ML[A-Z0-9]{26}$', // Mali
  '^MA[A-Z0-9]{26}$', // Morocco
  '^MZ[A-Z0-9]{23}$', // Mozambique
  '^NE[A-Z0-9]{26}$', // Niger
  '^SN[A-Z0-9]{26}$', // Senegal
  '^TG[A-Z0-9]{26}$', // Togo
  '^RW[A-Z0-9]{20}$', // Rwanda
  '^OM[A-Z0-9]{21}$', // Sultanate of Oman
];

export async function validateIban(rule, value): Promise<string | void> {
  if (!value) {
    return;
  }

  if (IBAN.isValid(value) || EXPERIMENTAL_IBAN_REGEX_LIST.some((rx) => value.replace(/\s/g, '').match(rx))) {
    return;
  }

  throw convertToTranslatedMessage(['general.errors.invalidIban', 'Invalid IBAN']);
}

export function swiftCodeValidator(rule: any, value: any, callback: any): VoidFunction {
  if (value === '' || value === null || value === undefined) {
    return callback();
  }

  if (typeof value === 'string' && isSwiftCodeValid(value)) {
    return callback();
  }

  return callback(convertToTranslatedMessage(['general.errors.invalidSwiftCode', 'Invalid swift code']));
}

export function swiftCodeWithSuffixValidator(rule: any, value: any, callback: any): VoidFunction {
  if (value === '' || value === null || value === undefined) {
    return callback();
  }

  if (typeof value === 'string') {
    if (isSwiftCodeValid(value, true)) {
      return callback();
    } else if (isSwiftCodeValid(value, false)) {
      return callback('Please enter full SWIFT code');
    }
  }

  return callback('Invalid swift code');
}

export function isSwiftCodeValid(swiftCode?: string, requireWithSuffix?: boolean): swiftCode is string {
  if (!swiftCode) {
    return false;
  }

  const validSwiftCountryCode = extractValidSwiftCodeCountryCode(swiftCode);

  return (
    !!validSwiftCountryCode &&
    !!swiftCode.toString().match(requireWithSuffix ? SWIFT_CODE_VALIDATION_WITH_SUFFIX_REGEX : SWIFT_CODE_VALIDATION_REGEX)
  );
}
