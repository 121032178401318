import React, { FunctionComponent, useContext } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { isTruthy } from '@app/utils/utils';
import FormContext from './FormContext';
import { itemOrFirst, valueOrValuesAsArray } from '@app/utils/arrayUtils';
import { isTranslatedMessage, TranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import FormlessItemBox, { FormlessItemBoxProps } from '@app/components/inputs/FormlessItemBox';
import { parseNestedString } from '@app/utils/stringUtils';

export interface FormItemsBoxProps extends Omit<FormlessItemBoxProps, 'hasValue' | 'error'> {
  form?: WrappedFormUtils;
  fieldNames: string[];
  forcedErrorText?: string | null;
  showErrors?: 'none' | 'all' | 'onTouch';
}

type FormInputErrorsObject = Record<string, unknown> | unknown[];

const FormItemsBox: FunctionComponent<FormItemsBoxProps> = observer((props) => {
  const { t } = useTranslation();

  const formContext = useContext(FormContext);
  const {
    fieldNames,
    forcedErrorText,
    form = formContext.form,
    showErrors = formContext.showErrors ?? 'onTouch',
    disabled: disabledProp,
    children,
    ...rest
  } = props;

  const disabled = disabledProp || formContext.disabled;

  const calcError = (): string | null => {
    if (showErrors === 'none') {
      return null;
    }

    if (forcedErrorText) {
      return forcedErrorText;
    }

    const { getFieldsError, isFieldTouched } = form;

    const fieldsErrors = getFieldsError(fieldNames);

    const firstErrorWithoutTranslation =
      fieldNames
        .map((fieldName) => {
          if (showErrors === 'onTouch' && !isFieldTouched(fieldName)) {
            return null;
          }

          return getErrorsOfField(fieldName, fieldsErrors);
        })
        .filter((errorsOfField) => errorsOfField?.length)[0] ?? null;

    return (
      valueOrValuesAsArray(firstErrorWithoutTranslation)
        .map((error: string | string[] | TranslatedMessage) => {
          if (!isTranslatedMessage(error)) {
            return itemOrFirst(error);
          }

          return t(error.key, error.value ? { value: error.value } : undefined);
        })
        .find((error) => error?.length) ?? null
    );
  };

  const getErrorsOfField = (fieldName: string, errors: FormInputErrorsObject): any => {
    const nestedNames = parseNestedString(fieldName);

    let error = errors;

    for (const nestedName of nestedNames) {
      error = error?.[nestedName] as FormInputErrorsObject;
    }

    return error;
  };

  const hasValue = (): boolean => {
    const { getFieldsValue } = form;

    const fieldsValue = getFieldsValue(fieldNames);

    return Object.values(fieldsValue).some(isTruthy);
  };

  const error = calcError();

  return (
    <FormlessItemBox hasValue={hasValue()} underText={error} disabled={disabled} {...rest}>
      {children}
    </FormlessItemBox>
  );
});

export default FormItemsBox;
