import Log from '@app/libs/logger';
import { flow, runInAction } from 'mobx';
import { identityFunc, isDefined } from '@app/utils/utils';
import { CancellablePromise } from 'mobx/dist/api/flow';

export enum LoadingState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  Resolved = 'Resolved',
}

class LoadableBase<T, TActual, TLoadingState extends LoadingState, TStateMetadata = null> {
  readonly loadState: TLoadingState;
  readonly result: TActual;
  readonly stateMetadata: TStateMetadata | null;

  constructor(loadState: TLoadingState, result: TActual, stateMetadata: TStateMetadata | null) {
    this.loadState = loadState;
    this.result = result;
    this.stateMetadata = stateMetadata;
  }

  isNotStarted(): this is NotStarted<T> {
    return this.loadState === LoadingState.NotStarted;
  }

  isInProgress(): this is InProgress<T> {
    return this.loadState === LoadingState.InProgress;
  }

  isResolved(): this is Resolved<T> {
    return this.loadState === LoadingState.Resolved;
  }

  isRejected(): this is Rejected<T> {
    return this.loadState === LoadingState.Rejected;
  }

  isNotSettled(): this is InProgress<T> | NotStarted<T> {
    return this.isInProgress() || this.isNotStarted();
  }

  isSettled(): this is Resolved<T> | Rejected<T> {
    return this.isResolved() || this.isRejected();
  }

  combineWith<U>(loadable: Loadable<U>): Loadable<[T, U]> {
    return LoadableCreator.combine((this as unknown) as Loadable<T>, loadable);
  }

  onlyWhen(condition: boolean): Loadable<T> {
    if (this.isResolved() && !condition) {
      return LoadableCreator.notStarted();
    }

    return (this as unknown) as Loadable<T>;
  }

  map<U>(doneResultMapper: (result: T) => U, notDoneMapper: (() => U | Loadable<U>) | null = null): Loadable<U> {
    return LoadableBase.map((this as unknown) as Loadable<T>, doneResultMapper, notDoneMapper);
  }

  flatMap<U>(doneResultMapper: (result: T) => Loadable<U>, notDoneMapper: (() => U | Loadable<U>) | null = null): Loadable<U> {
    return LoadableBase.map((this as unknown) as Loadable<T>, doneResultMapper, notDoneMapper);
  }

  resolve<U>(
    doneResultMapper: (result: T) => U,
    notDoneMapper: () => U,
    errorLoadingMapper: ((error: unknown) => U) | null = null,
    notStartedLoadingMapper: (() => U) | null = null,
  ): U {
    if (this instanceof Resolved) {
      return doneResultMapper(this.result);
    }

    if (this.loadState === LoadingState.Rejected && errorLoadingMapper) {
      return errorLoadingMapper(((this as unknown) as Rejected<T>).stateMetadata?.error);
    }

    if (this.loadState === LoadingState.NotStarted && notStartedLoadingMapper) {
      return notStartedLoadingMapper();
    }

    return notDoneMapper();
  }

  withoutPercentage(): Loadable<T> {
    if (this.loadState === LoadingState.InProgress) {
      return LoadableCreator.inProgress();
    }

    return (this as unknown) as Loadable<T>;
  }

  private static map<TOrigin, TDestination>(
    origin: Loadable<TOrigin>,
    doneResultMapper: (result: TOrigin) => TDestination | Loadable<TDestination>,
    notDoneMapper: (() => TDestination | Loadable<TDestination>) | null = null,
  ): Loadable<TDestination> {
    if (origin instanceof Resolved) {
      const result = doneResultMapper(origin.result);

      if (
        result instanceof Resolved ||
        result instanceof NotStarted ||
        result instanceof InProgress ||
        result instanceof Rejected
      ) {
        return LoadableBase.map(result, identityFunc, notDoneMapper);
      }

      return LoadableCreator.resolved(result);
    }

    if (notDoneMapper) {
      const result = notDoneMapper();

      if (
        result instanceof Resolved ||
        result instanceof NotStarted ||
        result instanceof InProgress ||
        result instanceof Rejected
      ) {
        return LoadableBase.map(result, identityFunc, notDoneMapper);
      }

      return LoadableCreator.resolved(result);
    }

    if (origin instanceof NotStarted) {
      return new NotStarted<TDestination>(origin.loadState);
    }

    if (origin instanceof Rejected) {
      return new Rejected<TDestination>(origin.stateMetadata);
    }

    if (origin instanceof InProgress) {
      return new InProgress<TDestination>(origin.stateMetadata);
    }

    return new NotStarted(LoadingState.NotStarted);
  }
}

interface InProgressMetadata {
  loadingPercentage: number | null;
}

class InProgress<T> extends LoadableBase<T, undefined, LoadingState.InProgress, InProgressMetadata> {
  constructor(metadata: InProgressMetadata | null) {
    super(LoadingState.InProgress, undefined, metadata);
  }
}

class NotStarted<T> extends LoadableBase<T, undefined, LoadingState.NotStarted> {
  constructor(loadState: LoadingState.NotStarted) {
    super(loadState, undefined, null);
  }
}

interface ErrorWhileLoadingMetadata {
  error: unknown;
}

class Rejected<T> extends LoadableBase<T, undefined, LoadingState.Rejected, ErrorWhileLoadingMetadata> {
  constructor(metadata: ErrorWhileLoadingMetadata | null) {
    super(LoadingState.Rejected, undefined, metadata);
  }
}

class Resolved<T> extends LoadableBase<T, T, LoadingState.Resolved> {
  constructor(result: T) {
    super(LoadingState.Resolved, result, null);
  }
}

function calcLoadableIfAnyNotDone<T>(loadables: Loadable<any>[]): Loadable<T> | null {
  if (loadables.some((loadable) => loadable.loadState === LoadingState.Rejected)) {
    const errors = loadables
      .filter((loadable) => loadable.isRejected())
      .map((x: Rejected<any>) => x.stateMetadata?.error)
      .filter(isDefined);

    return LoadableCreator.rejected(errors.length ? errors : null);
  }
  if (loadables.some((loadable) => loadable.loadState === LoadingState.NotStarted)) {
    return LoadableCreator.notStarted();
  }
  if (loadables.some((loadable) => loadable.loadState === LoadingState.InProgress)) {
    const loadingPercentages = loadables
      .filter((loadable) => loadable.isInProgress())
      .map((x: InProgress<any>) => x.stateMetadata?.loadingPercentage ?? 0);

    return LoadableCreator.inProgress(Math.min(...loadingPercentages));
  }

  return null;
}

export const LoadableCreator = {
  notStarted<U>(): Loadable<U> {
    return new NotStarted<U>(LoadingState.NotStarted);
  },
  inProgress<U>(loadingPercentage?: number): Loadable<U> {
    return new InProgress<U>(loadingPercentage ? { loadingPercentage } : null);
  },
  rejected<U>(error?: unknown): Loadable<U> {
    return new Rejected<U>(error ? { error } : null);
  },
  resolved<U>(result: U): Loadable<U> {
    return new Resolved<U>(result);
  },
  wrap<U>(value: U | Loadable<U>): Loadable<U> {
    if (value instanceof LoadableBase) {
      return value;
    }

    return LoadableCreator.resolved(value);
  },
  combine<T extends Loadable<any>[]>(...loadables: T): Loadable<ArrayWithoutLoadables<T>> {
    const notDoneLoadable = calcLoadableIfAnyNotDone<ArrayWithoutLoadables<T>>(loadables);

    if (notDoneLoadable) {
      return notDoneLoadable;
    }

    // We checked all other statuses, all of them must be done
    const result = loadables.filter((x) => x.isResolved()).map((x) => x.result) as ArrayWithoutLoadables<T>;

    return LoadableCreator.resolved(result);
  },

  combineLoadablesIntoObject<T extends ObjectOfLoadables>(objWithLoadableValues: T): Loadable<ObjectWithoutLoadables<T>> {
    const loadables = Object.values(objWithLoadableValues);

    const notDoneLoadable = calcLoadableIfAnyNotDone<ObjectWithoutLoadables<T>>(loadables);

    if (notDoneLoadable) {
      return notDoneLoadable;
    }

    const combinedValues = Object.fromEntries(
      Object.entries(objWithLoadableValues).map(([key, loadable]: [string, Resolved<any>]) => [key, loadable.result]),
    ) as ObjectWithoutLoadables<T>;

    // We checked all other statuses, all of them must be done
    return LoadableCreator.resolved(combinedValues);
  },
};

export function flowad<TValue, Args extends unknown[] = []>(
  setter: (newLoadable: Loadable<TValue>) => void,
  loader: (...args: Args) => Promise<TValue>,
): (...args: Args) => CancellablePromise<void> {
  return flow(function* (...args: Args) {
    setter(LoadableCreator.inProgress<TValue>());

    try {
      const result = yield loader(...args);

      setter(LoadableCreator.resolved(result));
    } catch (e: unknown) {
      Log.exception(e);
      setter(LoadableCreator.rejected(e));
      throw e;
    }
  });
}

const calcPercentage = (expectedExecutionDurationMillis: number, executionBeginTimeMillis: number): number => {
  const timePassedSinceStartOfRequest = Date.now() - executionBeginTimeMillis;

  const estimatedPercentage = (timePassedSinceStartOfRequest / expectedExecutionDurationMillis) * 100;

  return Math.min(estimatedPercentage, 95);
};

export function flowadWithPercentage<TValue, Args extends unknown[] = []>(
  setter: (newLoadable: Loadable<TValue>) => void,
  loader: (...args: Args) => Promise<TValue>,
  expectedExecutionDurationMillis: () => number,
): (...args: Args) => CancellablePromise<TValue> {
  return flow(function* (...args: Args) {
    setter(LoadableCreator.inProgress<TValue>(0));

    let intervalHandle: number | null = null;
    try {
      const executionBeginTimeMillis = Date.now();

      intervalHandle = window.setInterval(() => {
        runInAction(() =>
          setter(LoadableCreator.inProgress(calcPercentage(expectedExecutionDurationMillis(), executionBeginTimeMillis))),
        );
      }, 200);

      const result = yield loader(...args);

      window.clearInterval(intervalHandle);
      intervalHandle = null;

      setter(LoadableCreator.resolved(result));

      return result;
    } catch (e: unknown) {
      if (intervalHandle) {
        window.clearInterval(intervalHandle);
      }

      Log.exception(e);
      setter(LoadableCreator.rejected());
      throw e;
    }
  });
}

export function lazyLoadable<TValue>(
  fieldGetter: () => Loadable<TValue>,
  fieldSetter: (newLoadable: Loadable<TValue>) => void,
  loader: () => Promise<TValue>,
): Loadable<TValue> {
  // In case the loadable is already loading there is no need to load again
  const initialLoadable = fieldGetter();
  if (initialLoadable && initialLoadable.loadState !== LoadingState.NotStarted) {
    return initialLoadable;
  }

  flowad<TValue>(fieldSetter, loader)();

  // Getting the value after the flowad did the initial state initialization
  const loadable = fieldGetter();

  if (!loadable) {
    return LoadableCreator.inProgress();
  }

  return loadable;
}

type Loadable<T> = InProgress<T> | NotStarted<T> | Resolved<T> | Rejected<T>;
export default Loadable;

// combine types
export type ResultOfLoadable<T> = T extends Loadable<infer TResult> ? TResult : never;

type ArrayWithoutLoadables<T extends Loadable<any>[]> = {
  [P in keyof T]: ResultOfLoadable<T[P]>;
};

type ObjectOfLoadables = Record<string, Loadable<any>>;

type ObjectWithoutLoadables<T extends ObjectOfLoadables> = { [P in keyof T]: ResultOfLoadable<T[P]> };
