import { GetFieldDecoratorOptions, ValidateFieldsOptions, ValidationRule } from 'antd/lib/form/Form';
import { convertToTranslatedMessage, TranslatedMessageInput } from '@app/utils/form/formTranslatedMessage';
import { ValueWithPlaceholderProps } from '@app/components/inputs/ValueWithPlaceholder';

export const DEFAULT_FORM_VALIDATION_OPTIONS: ValidateFieldsOptions = {
  scroll: { offsetBottom: 30 },
};

export interface ConditionalValidationRule extends ValidationRule {
  enabled?: boolean;
  translatedMessage?: TranslatedMessageInput;
}

export interface GetFieldDecoratorOptionsExtended extends GetFieldDecoratorOptions {
  rules?: ConditionalValidationRule[];
  disableAllRules?: boolean;
}

export type FormFieldDecorators<T> = Record<keyof Required<T>, GetFieldDecoratorOptionsExtended>;

export type FormInputProps<TValue, THasPlaceholder extends boolean | undefined = undefined> = FormInputChangeProps<TValue> &
  FormInputPlaceholderProps<THasPlaceholder> &
  InputPlaceholderAccessoriesProps;

export interface FormInputChangeProps<TValue> {
  value?: TValue;
  onChange?: (newValue: TValue | undefined) => void;
}

export type FormInputPlaceholderProps<THasPlaceholder extends boolean | undefined = undefined> = THasPlaceholder extends true
  ? InputWithPlaceholderProps
  : THasPlaceholder extends false
  ? InputAlwaysWithoutPlaceholderProps
  : InputWithPlaceholderProps | InputWithoutPlaceholderProps;

interface InputWithPlaceholderProps {
  placeholder: string;
  accessibilityLabel?: string;
}

interface InputWithoutPlaceholderProps {
  placeholder: null;
  accessibilityLabel: string;
}

export interface InputAlwaysWithoutPlaceholderProps {
  accessibilityLabel: string;
}

export function convertToPlaceholderProps(
  inputProps: FormInputProps<any, undefined | true> & InputPlaceholderAccessoriesProps,
): FormInputPlaceholderProps<undefined | true> & InputPlaceholderAccessoriesProps {
  if (inputProps.placeholder !== null) {
    return {
      placeholder: inputProps.placeholder,
      accessibilityLabel: inputProps.accessibilityLabel,
      placeholderStyle: inputProps.placeholderStyle,
      heightType: inputProps.heightType,
      placeholderVerticalAlign: inputProps.placeholderVerticalAlign,
    };
  } else {
    return {
      placeholder: inputProps.placeholder,
      accessibilityLabel: inputProps.accessibilityLabel,
      placeholderStyle: inputProps.placeholderStyle,
      heightType: inputProps.heightType,
      placeholderVerticalAlign: inputProps.placeholderVerticalAlign,
    };
  }
}

export interface InputPlaceholderAccessoriesProps {
  placeholderStyle?: ValueWithPlaceholderProps['placeholderStyle'];
  heightType?: ValueWithPlaceholderProps['heightType'];
  placeholderVerticalAlign?: ValueWithPlaceholderProps['placeholderVerticalAlign'];
}

export function convertToFieldDecoratorOptions(
  fieldDecoratorOptions: GetFieldDecoratorOptionsExtended | null,
): GetFieldDecoratorOptions | undefined {
  if (!fieldDecoratorOptions) {
    return;
  }

  const rules = fieldDecoratorOptions.disableAllRules ? [] : fieldDecoratorOptions.rules;

  return {
    ...fieldDecoratorOptions,
    rules: rules
      ?.filter((rule) => rule.enabled === true || rule.enabled === undefined)
      .map(
        (rule: ConditionalValidationRule): ValidationRule => {
          const { translatedMessage, ...restOfRule } = rule;

          if (!translatedMessage) {
            return rule;
          }

          return {
            ...restOfRule,
            message: convertToTranslatedMessage(translatedMessage),
          };
        },
      ),
  };
}
