import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { PrivatePayeeWithDeterministicValidations } from '@mortee/domain/masterDataGuard';
import CategoryAndValues from '@app/components/CategoryAndValues';
import PrivateValidatedPayeesDVMatch from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/content/PrivateValidatedPayeesDVMatch';
import { css } from '@emotion/css';
import PrivateAccountRepresentationsWithDV from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/content/PrivateAccountRepresentationsWithDV';
import { distinctValues } from '@app/utils/arrayUtils';
import {
  getFormattedLEIWithoutType,
  LegalEntityIdentifier,
  orderedStrongLeisDefinitions,
  StrongLegalIdentifies,
} from '@app/domain/legalEntityIdentifier';
import { compare } from '@app/utils/comparatorUtils';

interface PrivatePayeeLiveListItemContentProps {
  payeeWithValidation: PrivatePayeeWithDeterministicValidations;
  onPayeeUpdated: () => Promise<void>;
  className?: string;
}

const PrivatePayeeLiveListItemContent: FunctionComponent<PrivatePayeeLiveListItemContentProps> = (props) => {
  const { payeeWithValidation, onPayeeUpdated, className } = props;

  const { strongLegalIdentifies } = payeeWithValidation.privatePayee;

  const renderStrongLegalIdsOfType = (
    strongLeiType: string,
    strongLeiTypeName: string,
    strongLegalIds: LegalEntityIdentifier[] | undefined,
    displayCountryCode?: boolean,
  ): React.ReactNode => {
    if (!strongLegalIds?.length) {
      return null;
    }

    const formattedLEIs = distinctValues(
      strongLegalIds.map((value) => getFormattedLEIWithoutType(value, displayCountryCode)),
    ).sort(compare.stringsCaseInsensitive());

    return (
      <SIDCategoryAndValues
        key={strongLeiTypeName}
        label={strongLeiTypeName}
        labelClassName={SIDCategoryAndValuesLabelClass}
        inlineLabel
      >
        {formattedLEIs.map((formattedLei) => (
          <div
            data-testid={`private-payee-strong-id-${strongLeiType}`}
            key={formattedLei}
            className={SIDCategoryAndValuesLabelClass}
          >
            {formattedLei}
          </div>
        ))}
      </SIDCategoryAndValues>
    );
  };

  const renderStrongLegalIds = (strongLegalIdentifies: StrongLegalIdentifies | undefined): React.ReactNode => {
    const someHaveValues = orderedStrongLeisDefinitions.some((typeWithName) => {
      return !!strongLegalIdentifies?.[typeWithName.type]?.length;
    });

    if (!someHaveValues) {
      const noSIDsCategory = orderedStrongLeisDefinitions.map((x) => x.name).join('/');
      return (
        <SIDCategoryAndValues label={noSIDsCategory} labelClassName={SIDCategoryAndValuesLabelClass} inlineLabel>
          Not provided
        </SIDCategoryAndValues>
      );
    }

    return orderedStrongLeisDefinitions.map((typeAndName) => {
      return renderStrongLegalIdsOfType(
        typeAndName.type,
        typeAndName.name,
        strongLegalIdentifies?.[typeAndName.type],
        typeAndName.type === 'TaxId',
      );
    });
  };

  return (
    <LayoutContainer className={className}>
      <MiniTitle>Vendor Info</MiniTitle>
      <VendorInfoContainer>
        <StrongLEIsContainer data-testid='strongLEIs'>
          <VendorInfoBoxTitle>Internal Record</VendorInfoBoxTitle>
          {renderStrongLegalIds(strongLegalIdentifies)}
        </StrongLEIsContainer>
        <VerticalSeparator />
        <StyledPrivateValidatedPayeesDVMatch
          privatePayeeAccountsWithValidation={payeeWithValidation.accountsValidation}
          validatedPayees={payeeWithValidation.validatedPayees}
          validatedPayeeAccounts={payeeWithValidation.validatedPayeesAccounts}
        />
      </VendorInfoContainer>
      <MiniTitle>Bank accounts</MiniTitle>
      {payeeWithValidation.accountsValidation
        .sort((accountValidation1, accountValidation2) =>
          accountValidation1.privatePayeeAccount.uniformId.localeCompare(accountValidation2.privatePayeeAccount.uniformId),
        )
        .map((accountValidation) => (
          <StyledPrivateAccountRepresentationsWithDV
            key={accountValidation.privatePayeeAccount.uniformId}
            privatePayee={payeeWithValidation.privatePayee}
            accountWithDV={accountValidation}
            onAccountUpdated={(): Promise<void> => onPayeeUpdated()}
          />
        ))}
      {!payeeWithValidation.accountsValidation.length && (
        <NoAccountsText>This vendor has no bank accounts or has disabled accounts only.</NoAccountsText>
      )}
    </LayoutContainer>
  );
};

export default PrivatePayeeLiveListItemContent;

const LayoutContainer = styled.div`
  --box-vertical-buffer: 12px;
  --box-horizontal-buffer: 16px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  line-height: normal;
`;

const MiniTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.33px;
  color: var(--primary-500);

  margin: 16px 0 6px;
`;

const VendorInfoContainer = styled.div`
  border: solid 1px rgba(20, 35, 97, 0.15);
  padding: var(--box-vertical-buffer) var(--box-horizontal-buffer);

  display: flex;
  flex-direction: row;
`;

const StrongLEIsContainer = styled.div`
  min-width: calc(var(--box-horizontal-buffer) * 10);

  & > *:not(:first-child) {
    margin-top: 12px;
  }
`;

const SIDCategoryAndValues = styled(CategoryAndValues)`
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--black-weak);
`;

const SIDCategoryAndValuesLabelClass = css`
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--black-strong);
`;

const VendorInfoBoxTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--black-strong);
`;

const VerticalSeparator = styled.div`
  background-color: var(--primary-200-100-a);
  width: 1px;
  margin-right: var(--box-horizontal-buffer);
  margin-left: var(--box-horizontal-buffer);
`;

const StyledPrivateValidatedPayeesDVMatch = styled(PrivateValidatedPayeesDVMatch)`
  flex: 1;
`;

const StyledPrivateAccountRepresentationsWithDV = styled(PrivateAccountRepresentationsWithDV)`
  background-color: var(--primary-200-50-a);

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const NoAccountsText = styled.div`
  background-color: var(--primary-200-50-a);
  padding: var(--box-vertical-buffer) var(--box-horizontal-buffer);

  font-size: 14px;
  letter-spacing: 0.31px;
  color: var(--black-strong);
`;
