import React, { FC, ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import styled from '@emotion/styled';
import { Regular13TransparentBlack600 } from '@app/components/Text';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim, trimToNull } from '@app/utils/stringUtils';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import SupplierRegistrationSelector from '@mortee/routes/validationSystem/createValidationRecord/supplierRegistrationSelector/SupplierRegistrationSelector';
import * as messageLauncher from '@app/utils/messageLauncher';
import { EMAIL_ID_PATTERN, SUPPLIER_VALIDATION_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import AsyncButton from '@app/components/AsyncButton';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import Log from '@app/libs/logger';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  CommentTypes,
  displayDistributionWarningMessage,
  noOrgOption,
  NULL_ORG,
  StoreSupplierValidationRecordRequest,
  ValidationSystemOrganization,
} from '@mortee/domain/validationSystem';
import Loadable from '@app/utils/Loadable';
import useNonInitialEffect from '@app/hooks/useNonInitialEffect';
import useIsFirstRun from '@app/hooks/useIsFirstRun';
import { transformToSupplierRegistrationProcess } from '@mortee/services/supplierRegistrationManagementServices';
import { ALPHANUMERIC_PATTERN_AND_DASH, URL_OPTIONAL_PROTOCOL_REGEX } from '@app/utils/regexUtils';
import qs from 'query-string';
import config from '@app/config';
import { Checkbox } from '@material-ui/core';
import NakedCalendarDatePicker from '@app/components/inputs/NakedCalendarDatePicker';
import { convertMomentToTimestamp } from '@app/utils/timeUtils';
import moment from 'moment';
import { compare } from '@app/utils/comparatorUtils';
import { createInitiatorEmailDecorator } from '@app/utils/form/formFieldDecorators';

export interface CreateValidationRecordFormFields {
  startDate: number | undefined;
  organizationId: string;
  supplierName: string;
  emailId: string;
  initiatorEmail: string;
  companyCode: string;
  linkedSupplierRegistration?: SupplierRegistrationProcess;
  comment: string | undefined;
  autoMailerManualLink: string | undefined;
  svPortalHyperlink: string | undefined;
  manualCompletionDate: number | undefined;
  svLinkId: string | undefined;
}

interface Props extends FormComponentProps<CreateValidationRecordFormFields> {
  allOrganizationsLoadable: Loadable<ValidationSystemOrganization[]>;
  initialSupplierRegistrationProcess?: SupplierRegistrationProcess;
  onSaved(
    createAnotherAfterSave: boolean,
    updatedProcess?: SupplierRegistrationProcess,
    initialFieldsValue?: Partial<CreateValidationRecordFormFields>,
  ): void;
  className?: string;
  initialFieldsValue?: Partial<CreateValidationRecordFormFields>;
}

interface SvUrlData {
  organizationData: ValidationSystemOrganization | undefined;
  svLinkId: string | undefined;
}

const CreateValidationRecordForm: FC<Props> = observer((props) => {
  const { allOrganizationsLoadable, initialSupplierRegistrationProcess, onSaved, className, initialFieldsValue } = props;

  const { form, showFormErrors, setShowFormErrors, validateFields, isFormInvalid } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);
  const [isInternalComment, setIsInternalComment] = useState(false);
  const [isOrganizationFoundFromLink, setIsOrganizationFoundFromLink] = useState(false);

  const applyDataFromSvURL = (urlData: SvUrlData, autoMailerManualLink: string): void => {
    const { organizationData, svLinkId } = urlData;

    form.setFieldsValue({
      organizationId: organizationData?.id,
      autoMailerManualLink: autoMailerManualLink,
      svLinkId: svLinkId,
    } as Partial<CreateValidationRecordFormFields>);

    if (organizationData?.companyCode) {
      form.setFieldsValue({
        companyCode: organizationData?.companyCode,
      } as Partial<CreateValidationRecordFormFields>);
    }
    setIsOrganizationFoundFromLink(true);
  };

  useEffect(() => {
    if (!allOrganizationsLoadable.isResolved() || !initialSupplierRegistrationProcess?.organizationId) {
      return;
    }

    // Apply the organization id from the initialSupplierRegistrationProcess only if it is in the org list
    if (
      allOrganizationsLoadable.result.some(
        (organization) => organization.id === initialSupplierRegistrationProcess.organizationId,
      )
    ) {
      form.setFieldsValue({
        organizationId: initialSupplierRegistrationProcess.organizationId,
      } as Partial<CreateValidationRecordFormFields>);

      form.validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [allOrganizationsLoadable, initialSupplierRegistrationProcess]);

  const svPortalHyperlink = form.getFieldValue('svPortalHyperlink');

  useEffect(() => {
    if (!allOrganizationsLoadable.isResolved() || initialSupplierRegistrationProcess) {
      return;
    }

    form.resetFields(['companyCode', 'autoMailerManualLink', 'organizationId', 'svLinkId']);
    setIsOrganizationFoundFromLink(false);

    if (!svPortalHyperlink || !svPortalHyperlink.match(URL_OPTIONAL_PROTOCOL_REGEX)) {
      return;
    }

    const extractedUrlData: SvUrlData | undefined = extractDataFromSvPortalHyperlink(svPortalHyperlink);

    if (extractedUrlData.organizationData || extractedUrlData.svLinkId) {
      applyDataFromSvURL(extractedUrlData, svPortalHyperlink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form and functions are created each render
  }, [allOrganizationsLoadable, svPortalHyperlink]);

  const isFirstRun = useIsFirstRun();

  function getCurrentLinkedSupplierRegistration(): SupplierRegistrationProcess | undefined {
    const formLinkedSupplierRegistration: SupplierRegistrationProcess | undefined = form.getFieldValue(
      'linkedSupplierRegistration',
    );

    if (formLinkedSupplierRegistration) {
      return formLinkedSupplierRegistration;
    }

    // it is the initial value of this field but that is not reflected via the getFieldValue
    if (isFirstRun) {
      return initialSupplierRegistrationProcess;
    }
  }

  const currentOrganizationId: string | undefined = form.getFieldValue('organizationId');
  const currentLinkedSupplierRegistration = getCurrentLinkedSupplierRegistration();
  const isOriginatedFromRegistrationForm = !!initialSupplierRegistrationProcess;

  useNonInitialEffect(() => {
    if (isOriginatedFromRegistrationForm) {
      return;
    }

    if (
      currentLinkedSupplierRegistration &&
      (!currentLinkedSupplierRegistration.organizationId ||
        currentLinkedSupplierRegistration.organizationId === currentOrganizationId)
    ) {
      return;
    }

    form.setFieldsValue({ linkedSupplierRegistration: undefined } as Partial<CreateValidationRecordFormFields>);
    form.validateFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [currentOrganizationId, currentLinkedSupplierRegistration, initialSupplierRegistrationProcess]);

  async function onSave(createAnotherAfterSave: boolean): Promise<void> {
    setIsSaving(true);

    try {
      const formFields = await validateFields();
      const storeSupplierValidationRecordRequest: StoreSupplierValidationRecordRequest = {
        organizationId: formFields.organizationId && formFields.organizationId != NULL_ORG.id ? formFields.organizationId : null,
        supplierName: trim(formFields.supplierName),
        initialEmailId: trimToNull(formFields.emailId),
        initiatorEmail: trimToNull(formFields.initiatorEmail),
        companyCode: trimToNull(formFields.companyCode),
        svRegistrationId: formFields.linkedSupplierRegistration?.id ?? null,
        storeValidationRecordCommentRequest: formFields.comment
          ? {
              comment: formFields.comment,
              commentType: isInternalComment ? CommentTypes.internalComment : CommentTypes.publicComment,
            }
          : null,
        autoMailerManualLink: trimToNull(formFields.autoMailerManualLink),
        manualCompletionDate: formFields.manualCompletionDate ?? null,
        startDate: formFields.startDate ?? null,
        svLinkId: trimToNull(formFields.svLinkId),
      };

      const serverResponse = await validationSystemServices.storeValidationRecord(storeSupplierValidationRecordRequest);

      messageLauncher.shoot({ type: 'success' }, `Validation ${serverResponse?.record.presentationId} created successfully`);
      displayDistributionWarningMessage(serverResponse.warnings);

      onSaved(
        createAnotherAfterSave,
        serverResponse?.record?.supplierValidationProcess
          ? transformToSupplierRegistrationProcess(serverResponse.record.supplierValidationProcess)
          : undefined,
        { startDate: formFields.startDate, organizationId: formFields.organizationId },
      );
    } catch (e) {
      Log.exception(e);
    } finally {
      setIsSaving(false);
    }
  }

  const fieldDecorators = createFieldDecorators(initialSupplierRegistrationProcess, initialFieldsValue);

  function renderOrganizationsDropdown(): ReactElement {
    return (
      <NakedDropdown
        accessibilityLabel='organization'
        name='cbox-create-validation-process-organization'
        dataTestId='cbox-create-validation-process-organization'
        placeholder='Customer Name'
        isSearchable
        loading={allOrganizationsLoadable.isInProgress()}
        disabled={!!initialSupplierRegistrationProcess?.organizationId || isOrganizationFoundFromLink}
      >
        {allOrganizationsLoadable.resolve<ReactElement[]>(
          (allOrganizations) =>
            noOrgOption
              .concat(allOrganizations)
              .sort(compare.byStringField((record) => record.name))
              .map(
                (org): ReactElement => {
                  return (
                    <DropdownItem key={org.id} value={org.id} textWhenSelected={org.name} keywords={[org.id, org.name]}>
                      <div>{org.name}</div>
                    </DropdownItem>
                  );
                },
              ),
          (): ReactElement[] => [],
        )}
      </NakedDropdown>
    );
  }

  function extractDataFromSvPortalHyperlink(svPortalHyperlink: string): SvUrlData {
    const url = new URL(svPortalHyperlink);
    const { ref, id: svLinkId } = qs.parse(url.search);

    const foundOrganization = allOrganizationsLoadable.map((loadedOrganizations) => {
      try {
        const organizationIdentifier = ref || url.hostname.split('.')[0];
        return loadedOrganizations.find(
          (organization) => organization.ref === organizationIdentifier || organization.subdomain === organizationIdentifier,
        );
      } catch (e) {
        Log.exception(e);
      }
    });

    return { organizationData: foundOrganization?.result, svLinkId: typeof svLinkId === 'string' ? svLinkId : undefined };
  }

  return (
    <StyledNsknoxForm form={form} showErrors={showFormErrors} className={className} setShowErrors={setShowFormErrors}>
      <ExpendedVerticalShadowScroller id='create-validation-record-form'>
        <Content>
          {!isOriginatedFromRegistrationForm && (
            <FormItemBox fieldName='svPortalHyperlink' fieldDecoratorOptions={fieldDecorators.svPortalHyperlink}>
              <NakedFormInput
                name='inpt-create-validation-process-sv-portal-hyperlink'
                dataTestId='inpt-create-validation-process-sv-portal-hyperlink'
                type='text'
                placeholder='SV Portal URL'
              />
            </FormItemBox>
          )}
          <FormItemBox fieldName='organizationId' fieldDecoratorOptions={fieldDecorators.organizationId} showAsRequired>
            {renderOrganizationsDropdown()}
          </FormItemBox>
          <FormItemBox fieldName='supplierName' fieldDecoratorOptions={fieldDecorators.supplierName} showAsRequired>
            <NakedFormInput
              name='inpt-create-validation-process-supplier-name'
              dataTestId='inpt-create-validation-process-supplier-name'
              type='text'
              placeholder='Supplier Name'
            />
          </FormItemBox>
          <FormItemBox fieldName='startDate' fieldDecoratorOptions={fieldDecorators.startDate}>
            <NakedCalendarDatePicker
              disableFutureDates
              placeholder='Manual Start Date'
              id='inpt-create-validation-process-start-date'
            />
          </FormItemBox>
          {!isOriginatedFromRegistrationForm && (
            <FormItemBox fieldName='emailId' fieldDecoratorOptions={fieldDecorators.emailId}>
              <NakedFormInput
                name='inpt-create-validation-process-email-id'
                dataTestId='inpt-create-validation-process-email-id'
                type='text'
                placeholder='Email ID'
              />
            </FormItemBox>
          )}
          <FormItemBox fieldName='autoMailerManualLink' fieldDecoratorOptions={fieldDecorators.autoMailerManualLink}>
            <NakedFormInput
              name='inpt-create-validation-process-manual-link'
              dataTestId='inpt-create-validation-process-manual-link'
              type='text'
              placeholder='Auto Mailer Manual Link'
            />
          </FormItemBox>
          <FormItemBox fieldName='svLinkId' fieldDecoratorOptions={fieldDecorators.svLinkId}>
            <NakedFormInput
              name='inpt-create-validation-process-sv-link-id'
              dataTestId='inpt-create-validation-process-sv-link-id'
              type='text'
              placeholder='SV Link ID'
            />
          </FormItemBox>
          <FormItemBox fieldName='companyCode' fieldDecoratorOptions={fieldDecorators.companyCode}>
            <NakedFormInput
              name='inpt-create-validation-process-company-code'
              dataTestId='inpt-create-validation-process-company-code'
              type='text'
              placeholder='Company Code'
            />
          </FormItemBox>
          <CommentContainer>
            <CommentFormItemBox fieldName='comment' fieldDecoratorOptions={fieldDecorators.comment}>
              <NakedDropdown
                accessibilityLabel='comment'
                name='inpt-create-validation-process-comment'
                dataTestId='inpt-create-validation-process-comment'
                placeholder='Comment'
                placeholderStyle='onlyWhenEmpty'
                heightType='thin'
                popupWidth='auto'
                isSearchable
                freeSolo
              >
                {config.svManagement.commonCommentList.map((knownComment) => {
                  return (
                    <DropdownItem value={knownComment} key={knownComment} textWhenSelected={knownComment}>
                      {knownComment}
                    </DropdownItem>
                  );
                })}
              </NakedDropdown>
            </CommentFormItemBox>
            <CheckboxContainer onClick={(): void => setIsInternalComment(!isInternalComment)}>
              <Checkbox
                id='cbox-is-internal-comment'
                data-testid='cbox-is-internal-comment'
                checked={isInternalComment}
                onClick={(): void => setIsInternalComment(!isInternalComment)}
              />
              Post as internal comment
            </CheckboxContainer>
          </CommentContainer>
          <FormItemBox fieldName='manualCompletionDate' fieldDecoratorOptions={fieldDecorators.manualCompletionDate}>
            <NakedCalendarDatePicker
              placeholder='Manual Completion Date'
              id='date-picker-create-validation-process-completion-date'
            />
          </FormItemBox>
          <FormItemBox fieldName='initiatorEmail' fieldDecoratorOptions={fieldDecorators.initiatorEmail}>
            <NakedFormInput
              name='inpt-create-validation-process-initiator-email'
              dataTestId='inpt-create-validation-process-initiator-email'
              type='text'
              placeholder='Initiator Email'
            />
          </FormItemBox>
          <>
            {(currentOrganizationId || currentLinkedSupplierRegistration) && (
              <>
                {!isOriginatedFromRegistrationForm && <SectionTitle>Linked Registration Form (optional)</SectionTitle>}
                <FormItemBox
                  fieldName='linkedSupplierRegistration'
                  fieldDecoratorOptions={fieldDecorators.linkedSupplierRegistration}
                  appearance='none'
                >
                  <SupplierRegistrationSelector
                    scrollableTargetId='create-validation-record-form'
                    id='inpt-create-validation-process-linked-supplier-registration'
                    dataTestId='inpt-create-validation-process-linked-supplier-registration'
                    key={`linked-supplier-registration-${currentOrganizationId}`}
                    currentOrganizationId={currentOrganizationId}
                    isOriginatedFromRegistrationForm={isOriginatedFromRegistrationForm}
                  />
                </FormItemBox>
              </>
            )}
          </>
        </Content>
      </ExpendedVerticalShadowScroller>
      <Content>
        <ActionsContainer>
          {!isOriginatedFromRegistrationForm ? (
            <>
              <StyledSelfValidateButton
                id='btn-create-validation-process-save-and-close'
                appearance='text'
                onClick={(): Promise<void> => onSave(false)}
                disabled={isFormInvalid || isSaving}
                onDisabledClick={(): void => setShowFormErrors('all')}
              >
                SAVE AND CLOSE
              </StyledSelfValidateButton>
              <StyledSelfValidateButton
                id='btn-create-validation-process-save-and-add-another'
                onClick={(): Promise<void> => onSave(true)}
                disabled={isFormInvalid || isSaving}
                onDisabledClick={(): void => setShowFormErrors('all')}
              >
                SAVE AND ADD ANOTHER FOR THIS ORG
              </StyledSelfValidateButton>
            </>
          ) : (
            <StyledSelfValidateButton
              id='btn-create-validation-process-save'
              onClick={(): Promise<void> => onSave(false)}
              disabled={isFormInvalid || isSaving}
              onDisabledClick={(): void => setShowFormErrors('all')}
            >
              SAVE
            </StyledSelfValidateButton>
          )}
        </ActionsContainer>
      </Content>
    </StyledNsknoxForm>
  );
});

function createFieldDecorators(
  initialSupplierRegistrationProcess: SupplierRegistrationProcess | undefined,
  initialFieldsValue: Partial<CreateValidationRecordFormFields> | undefined,
): FormFieldDecorators<CreateValidationRecordFormFields> {
  return {
    organizationId: {
      initialValue: initialFieldsValue?.organizationId,
      rules: [
        {
          required: true,
          transform: trim,
          message: `Please select a customer`,
        },
      ],
    },
    supplierName: {
      initialValue: initialSupplierRegistrationProcess?.companyName,
      rules: [
        {
          type: 'string',
          required: true,
          transform: trim,
          message: `Please enter a supplier name`,
        },
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.supplierName,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.supplierName} characters`,
        },
      ],
    },
    emailId: {
      rules: [
        {
          pattern: EMAIL_ID_PATTERN,
          transform: trim,
          message: `Invalid email id`,
        },
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.emailId,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.emailId} characters`,
        },
      ],
    },
    initiatorEmail: createInitiatorEmailDecorator(),
    companyCode: {
      rules: [
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.companyCode,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.companyCode} characters`,
        },
      ],
    },
    linkedSupplierRegistration: {
      initialValue: initialSupplierRegistrationProcess,
      rules: [
        {
          type: 'object',
          required: false,
          message: `Invalid Registration form`,
        },
      ],
    },
    comment: {
      rules: [
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.comment,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.comment} characters`,
        },
      ],
    },
    autoMailerManualLink: {
      rules: [
        {
          transform: trim,
          pattern: URL_OPTIONAL_PROTOCOL_REGEX,
          message: 'Invalid SV Portal URL',
        },
      ],
    },
    svPortalHyperlink: {
      rules: [
        {
          transform: trim,
          pattern: URL_OPTIONAL_PROTOCOL_REGEX,
          message: 'Invalid SV Portal URL',
        },
      ],
    },
    startDate: {
      initialValue: initialFieldsValue?.startDate ?? convertMomentToTimestamp(moment.utc().startOf('day')),
      rules: [
        {
          required: false,
          message: `Please select a start date`,
        },
      ],
    },
    manualCompletionDate: {
      rules: [
        {
          required: false,
          message: `Please select a completion date`,
        },
      ],
    },
    svLinkId: {
      rules: [
        {
          transform: trim,
          pattern: ALPHANUMERIC_PATTERN_AND_DASH,
          message: 'Invalid SV Link ID',
        },
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.svLinkId,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.svLinkId} characters`,
        },
      ],
    },
  };
}

export default Form.create<Props>()(CreateValidationRecordForm);

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const SectionTitle = styled.h2`
  ${Regular13TransparentBlack600.css};
`;

const Content = styled.div`
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  margin-top: 36px;
`;

const StyledSelfValidateButton = styled(AsyncButton)`
  padding: 9px 43px;
`;

const ExpendedVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
`;

const CheckboxContainer = styled.div`
  align-items: center;
  padding-left: 21px;
  cursor: pointer;
  width: fit-content;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CommentFormItemBox = styled(FormItemBox)`
  flex: 1;
`;
