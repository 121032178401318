import { Modal } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ModalFunc, ModalFuncProps } from 'antd/lib/modal/Modal';
import classNames from 'classnames';
import GeneralModal from './modals/GeneralModal';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import Button from '@app/components/Button';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

export function confirm(confirmProps): ReturnType<ModalFunc> {
  const conMod = Modal.confirm(confirmProps);

  window.onpopstate = (): void => {
    conMod.destroy();
  };

  return conMod;
}

export function confirmAsync(confirmProps): Promise<boolean> {
  return new Promise((resolve) =>
    Modal.confirm({
      ...confirmProps,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    }),
  );
}

export function showCustomResultModalAsync<TResult>(
  content: (onResult: (result: TResult) => void) => ReactNode,
  options: ModalFuncProps = {},
): Promise<TResult> {
  const { className, ...restOfOptions } = options;
  return new Promise<TResult>((resolve) => {
    const modal = Modal.confirm({
      className: classNames('custom-content', className),
      ...defaults,
      ...restOfOptions,
      content: content((result) => {
        modal.destroy();
        resolve(result);
      }),
      style: defaultStyle,
    });
  });
}

export function showInfoModalAsync(content: ReactNode, options: ModalFuncProps = {}): Promise<void> {
  return new Promise<void>((resolve) => {
    Modal.info({
      ...defaults,
      ...options,
      content,
      onOk: (close) => {
        resolve();
        close();
      },
      onCancel: () => {
        resolve();
      },
    });
  });
}

export function showInfoModal(content: ReactNode, options: ModalFuncProps = {}): ReturnType<ModalFunc> {
  return Modal.info({
    ...defaults,
    ...options,
    content,
  });
}

export function showErrorModalWithTitle(title: string, message: string, messageId: string = nanoid()): void {
  const titleId = `alert-title-${messageId}`;
  const contentId = `alert-description-${messageId}`;

  showInfoModal(
    <ModalContainer id={messageId} aria-labelledby={titleId} aria-describedby={contentId}>
      <InfoModalTitle id={titleId}>{title}</InfoModalTitle>
      <InfoModalBody id={contentId}>{message}</InfoModalBody>
    </ModalContainer>,
    {
      okText: `OK`,
      className: 'info-modal',
    },
  );
}

export function showYesNoModal(questionText: string, onYes: () => any, title?: string): ReturnType<ModalFunc> {
  return Modal.confirm({
    ...defaults,
    content: questionText,
    okText: 'Yes',
    onOk: onYes,
    cancelText: 'No',
    style: defaultStyle,
    title,
  });
}

export function showYesNoModalAsync(
  questionText: string,
  title?: string,
  yesText: string = 'Yes',
  noText: string = 'No',
): Promise<boolean> {
  return new Promise<boolean>((resolve) =>
    Modal.confirm({
      ...defaults,
      content: questionText,
      okText: yesText,
      onOk: () => resolve(true),
      cancelText: noText,
      onCancel: () => resolve(false),
      style: defaultStyle,
      title,
    }),
  );
}

export function showContentOnlyModal(
  content: (onDone: () => void) => ReactNode,
  options: ModalFuncProps = {},
): ReturnType<ModalFunc> {
  const { className, ...restOfOptions } = options;

  const modal = Modal.info({
    className: classNames('content-only', className),
    ...defaults,
    ...restOfOptions,
    content: content(() => {
      modal.destroy();
    }),
  });
  return modal;
}

export function getPropsOfCustomModal(options: ModalFuncProps = {}): ModalFuncProps {
  const { className, ...restOfOptions } = options;

  return {
    className: classNames('custom-content', className),
    ...defaults,
    ...restOfOptions,
  };
}

export function showCustomModal(content: (onDone: () => void) => ReactNode, options: ModalFuncProps = {}): ReturnType<ModalFunc> {
  const modal = Modal.info({
    ...getPropsOfCustomModal(options),
    content: content(() => {
      modal.destroy();
    }),
  });
  return modal;
}

export function showCustomModalAsync(
  content: (onAccept: () => void, onDecline: () => void) => ReactNode,
  options: ModalFuncProps = {},
): Promise<boolean> {
  const { className, ...restOfOptions } = options;

  return new Promise<boolean>((resolve) => {
    const modal = Modal.info({
      className: classNames('custom-content', className),
      ...defaults,
      ...restOfOptions,
      content: content(
        () => {
          modal.destroy();
          resolve(true);
        },
        () => {
          modal.destroy();
          resolve(false);
        },
      ),
    });
  });
}

export function showGeneralModal(
  title: ReactNode,
  content: ReactNode,
  okText: string,
  onOk?: () => void,
  cancelText?: string,
  onCancel?: () => void,
  className?: string,
): ReturnType<ModalFunc> {
  const modal = Modal.info({
    className: classNames('custom-content', className),
    ...defaults,
    content: (
      <GeneralModal
        title={title}
        okText={okText}
        onOk={(): void => {
          onOk?.();
          modal.destroy();
        }}
        cancelText={cancelText}
        onCancel={(): void => {
          onCancel?.();
          modal.destroy();
        }}
      >
        {content}
      </GeneralModal>
    ),
  });
  return modal;
}

interface ModalCloseButtonProps {
  onDone: VoidFunction;
}

export const ModalCloseButton: FC<ModalCloseButtonProps> = ({ onDone }) => {
  const { t } = useTranslation();
  return (
    <CloseButton id={`btn-modal-close`} appearance='text' colorScheme='primary' onClick={onDone}>
      <SVG accessibilityLabel={t('general.accessibility.close')} image={ClearIcon} height={20} />
    </CloseButton>
  );
};

const defaults: ModalFuncProps = {
  okType: 'primary',
  okText: 'OK',
  maskStyle: { backgroundColor: 'rgba(94, 96, 102, 0.55)' },
  maskClosable: true,
  width: 'auto',
  centered: true,
  icon: <span />,
};

const defaultStyle: React.CSSProperties = { width: '500px', minWidth: '500px' };

export const ModalTitle = styled.span`
  font-size: 22px;
  color: var(--gray-blue-deprecated);
  margin-bottom: 16px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
`;

export const WrappingModalContainer = styled(ModalContainer)`
  //for older browsers
  overflow-wrap: break-word;
  //for the new cooler browsers
  overflow-wrap: anywhere;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: var(--gray-blue-deprecated);
  letter-spacing: 0.1px;
`;

export const ModalTextLine = styled.div`
  margin-bottom: 10px;
`;

export const InfoModalTitle = styled.span`
  width: 232px;
  height: 32px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.27px;
  text-align: center;
  color: var(--black-strong);
  margin-bottom: 9px;
`;

export const InfoModalBody = styled.div`
  width: 232px;
  height: 48px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--transparent-black-700);
  margin-bottom: 10px;
`;

export const WideInfoModalTitle = styled.span`
  width: 400px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.27px;
  text-align: center;
  color: var(--black-strong);
  margin-bottom: 9px;
`;

export const WideInfoModalBody = styled.div`
  width: 400px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--transparent-black-700);
  margin-bottom: 10px;
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(53, 60, 79, 0.4);
  transition: 0.1s all ease-in-out;
  align-self: flex-end;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;
