const today = new Date();

export function minutesToMilliseconds(minutes: number): number {
  return secondsToMilliseconds(minutes * 60);
}

export function secondsToMilliseconds(seconds: number): number {
  return seconds * 1000;
}

export const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export const formattedDateOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

export const formattedDateOptionsWithHoursAndMinutes: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

export const formattedDateOptionsWithHours24HoursClock: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const formattedDateOptionsDDMMYYYY: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const DEFAULT_LOCALE = 'en-GB';
const defaultDateFormatter = new Intl.DateTimeFormat(DEFAULT_LOCALE, formattedDateOptions);

export function daysInMonth(month?: number, year?: number): number {
  // Because we want to achieve the amount of days in the month, we want to create a date which is the last day of the month
  // and then the day of the month will say how many days there are in the month

  // Because the day count start with 1, setting the day as 0 means "the day before the 1st of the month you specified"
  // For example new Date(2021, february, 1) will create the first of February
  // if you create a date as "new Date(2021, february, 0)" the created date will be the 31st of January
  // So we create "the day before the next month" which is the last day of the current month
  const theNextMonth = (month ?? today.getMonth()) + 1;
  const dateThatIsTheLastDayOfThisMonth = new Date(year ?? today.getFullYear(), theNextMonth, 0);

  return dateThatIsTheLastDayOfThisMonth.getDate();
}

export function getUTCDate(date: number): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  result.setHours(0, 0, 0, 0);
  return result.getTime();
}

export function convertMomentToTimestamp(moment: moment.Moment): number {
  return moment.valueOf();
}

export function daysBetween(startDate: number, endDate: number): number {
  return (getUTCDate(endDate) - getUTCDate(startDate)) / MILLISECONDS_PER_DAY;
}

export function getEpochOfMonthBeginning(date: number | Date = Date.now()): number {
  const beginningOfMonth = new Date(date);
  beginningOfMonth.setDate(1);
  beginningOfMonth.setHours(0, 0, 0, 0);

  return beginningOfMonth.getTime();
}

export function isDateMaximumXMonthsAgo(date: number, numberOfMonths: number): boolean {
  const xMonthsAgo = new Date(getEpochOfMonthBeginning());
  xMonthsAgo.setMonth(xMonthsAgo.getMonth() - numberOfMonths);

  return xMonthsAgo.getTime() < date;
}

export function isDateMaximumXMonthsInTheFuture(date: number, numberOfMonths: number): boolean {
  const xMonthsAgo = new Date(getEpochOfMonthBeginning());
  xMonthsAgo.setMonth(xMonthsAgo.getMonth() + numberOfMonths + 1);

  return date < xMonthsAgo.getTime();
}

// Remove this function when translating Mortee and use localeStore stuff
// This function returns the date in english format (e.g. 16-Aug-1992 or 10-Dec-1992)
export function convertTimestampToDefaultTimeString(timestamp: number | null | undefined): string | null {
  if (!timestamp) {
    return null;
  }

  return defaultDateFormatter.format(new Date(timestamp));
}

export function calculatePriorDate(priorMonths: number): number {
  const date = new Date();

  return date.setMonth(date.getMonth() - priorMonths);
}

export function isPastDate(dateEpoch: number): boolean {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return dateEpoch < currentDate.getTime();
}
