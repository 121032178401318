import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import Sidebar from '@app/components/sidebar/Sidebar';
import Footer from '@app/components/Footer';
import MorteeMode from '@mortee/morteeMode';
import useScrollTopOnLocationChange from '@app/hooks/useScrollTopOnLocationChange';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticationProvider from '@app/login/AuthenticationProvider';

const MorteeAppContent: FC = observer((props) => {
  const { userStore } = useInfraStores<MorteeMode>();
  const appContentContainerRef = useRef<HTMLDivElement | null>(null);
  useScrollTopOnLocationChange(appContentContainerRef);

  return (
    <AuthenticationProvider>
      <PageLayout>
        <StyledSidebar />
        <ContentLayout id='appContentContainer' data-testid='app-content-container' ref={appContentContainerRef}>
          <ContentContainer id='main' key={userStore.selectedOrganization?.id}>
            {props.children}
          </ContentContainer>
          <StyledFooter />
        </ContentLayout>
      </PageLayout>
    </AuthenticationProvider>
  );
});

export default MorteeAppContent;

const ContentLayout = styled.main`
  overflow: auto;
  overflow-y: scroll;
  padding-bottom: 32px;

  // Borrowed from antd's Layout
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
`;

const ContentContainer = styled.div`
  padding-bottom: 32px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledSidebar = styled(Sidebar)`
  flex: 0 0 auto;
  width: 220px;
`;

const StyledFooter = styled(Footer)`
  flex: 0 0 auto;
  text-align: center;
`;

const PageLayout = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;

  height: 100vh;
  min-height: 0;
`;
