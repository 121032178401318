import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import MorteeMode from '@mortee/morteeMode';
import Card from '@app/components/card/Card';
import useAppStores from '@app/hooks/useAppStores';
import { observer } from 'mobx-react';
import PaginatedPrivateArea from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/PaginatedPrivateArea';
import SyncInfoLine from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/SyncInfoLine';
import useMountEffect from '@app/hooks/useMountEffect';

interface Props {
  className?: string;
}

const MasterDataGuardLivePage: FunctionComponent<Props> = observer(({ className }) => {
  const { masterDataGuardStore } = useAppStores<MorteeMode>();
  const { lastSyncInfo } = masterDataGuardStore;

  useMountEffect(
    (): VoidFunction => {
      masterDataGuardStore.setIsCurrentlyWatchingLiveData(true);

      return (): void => {
        masterDataGuardStore.setIsCurrentlyWatchingLiveData(false);
      };
    },
  );

  return (
    <div className={className}>
      <StyledSyncInfo info={lastSyncInfo} />
      <StyledCard>
        <PaginatedPrivateArea />
      </StyledCard>
    </div>
  );
});

export default MasterDataGuardLivePage;

const StyledSyncInfo = styled(SyncInfoLine)`
  margin-left: 12px;
  margin-bottom: 8px;
`;

const StyledCard = styled(Card)`
  margin: 0;
  padding: 0;
`;
