import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { SupplierRegistrationConfigurationServerResponse } from '@supplierRegistration/domain/supplierRegistration';

export async function getSupplierRegistrationConfigurationByRef(
  referenceId: string,
): Promise<SupplierRegistrationConfigurationServerResponse> {
  return request<SupplierRegistrationConfigurationServerResponse>(
    serverUrl,
    `/api/supplier-validation/configuration/:referenceId`,
    {
      method: HttpMethod.get,
      pathParams: {
        referenceId,
      },
      dontRedirectToLogin: true,
      suppressNotification: true,
    },
  );
}

export async function getSupplierRegistrationConfigurationBySubdomain(
  subdomain: string,
): Promise<SupplierRegistrationConfigurationServerResponse> {
  return request<SupplierRegistrationConfigurationServerResponse>(
    serverUrl,
    `/api/supplier-validation/configuration/subdomain/:subdomain`,
    {
      method: HttpMethod.get,
      pathParams: { subdomain },
      dontRedirectToLogin: true,
      suppressNotification: true,
    },
  );
}
