import { makeObservable, observable } from 'mobx';
import Loadable, { lazyLoadable, LoadableCreator } from '@app/utils/Loadable';
import { IdentityProvider, transformKnoxerIdentityProviders } from '@app/domain/userManagement/identityProviders';
import { getAllIdentityProviders, getAllSSOIdentityProviders } from '@backee/services/identityProviderService';
import { compare } from '@app/utils/comparatorUtils';

export default class IdentityProviderStore {
  @observable private _allIdentityProviderTypes: Map<string, Loadable<IdentityProvider[]>> = new Map<string, Loadable<[]>>();
  @observable private _ssoIdentityProviderTypes: Map<string, Loadable<IdentityProvider[]>> = new Map<string, Loadable<[]>>();

  constructor() {
    makeObservable(this);
  }

  getAllIdentityProvidersForKnoxer(knoxerId: string): Loadable<IdentityProvider[]> {
    return lazyLoadable(
      () => this._allIdentityProviderTypes.get(knoxerId) ?? LoadableCreator.notStarted(),
      (newValue) => this._allIdentityProviderTypes.set(knoxerId, newValue),
      async () => {
        const allIdentityProvidersTypes = await getAllIdentityProviders(knoxerId);
        return transformKnoxerIdentityProviders(allIdentityProvidersTypes).identityProviders.sort(
          compare.byStringField((identityProvider) => identityProvider.name),
        );
      },
    );
  }

  getSSOIdentityProvidersForKnoxer(knoxerId: string): Loadable<IdentityProvider[]> {
    return lazyLoadable(
      () => this._ssoIdentityProviderTypes.get(knoxerId) ?? LoadableCreator.notStarted(),
      (newValue) => this._ssoIdentityProviderTypes.set(knoxerId, newValue),
      async () => {
        const allSSOIdentityProvidersTypes = await getAllSSOIdentityProviders(knoxerId);
        return transformKnoxerIdentityProviders(allSSOIdentityProvidersTypes).identityProviders.sort(
          compare.byStringField((identityProvider) => identityProvider.name),
        );
      },
    );
  }
}
