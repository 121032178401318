import React from 'react';
import {
  LivePrivatePayeeFilters,
  LivePrivatePayeeFiltersOptions,
  LivePrivatePayeeValidationLevel,
} from '@mortee/domain/masterDataGuard';
import { LoadableCreator } from '@app/utils/Loadable';

export interface LivePrivatePayeesFiltersContextProps {
  filters: LivePrivatePayeeFilters;
  isAnyFiltersApplied: boolean;
  filtersApplied: BooleanValues<LivePrivatePayeeFilters>;
  filterOptions: LivePrivatePayeeFiltersOptions;
  setFilters: (newFilters: LivePrivatePayeeFilters) => void;
}

export const defaultFilters = {
  searchQuery: '',
  validationLevel: LivePrivatePayeeValidationLevel.all,
  companyCodes: null,
};

const defaultValue: LivePrivatePayeesFiltersContextProps = {
  filters: defaultFilters,
  isAnyFiltersApplied: false,
  filtersApplied: {
    searchQuery: false,
    companyCodes: false,
    validationLevel: false,
  },
  filterOptions: {
    validationLevel: [],
    companyCodes: LoadableCreator.resolved({
      values: new Set<string>(),
      allowBlankValue: false,
    }),
  },
  setFilters: () => {},
};

const LivePrivatePayeesFiltersContext = React.createContext<LivePrivatePayeesFiltersContextProps>(defaultValue);

export default LivePrivatePayeesFiltersContext;
