import {
  SupplierRegistrationConfiguration,
  SupplierRegistrationCustomerReferences,
} from '@supplierRegistration/domain/supplierRegistration';
import * as customerServices from '@supplierRegistration/services/supplierRegistrationCustomerDataServices';
import * as asyncProcessServices from '@supplierRegistration/services/supplierRegistrationAsyncInitiateValidationProcessServices';
import Log from '@app/libs/logger';
import { computed, flow, makeObservable, observable } from 'mobx';
import Loadable, { flowad, LoadableCreator, LoadingState } from '@app/utils/Loadable';
import { HttpStatus, isRequestError, isWebErrorContent, RequestError } from '@app/libs/request';
import SupplierRegistrationThemeStore from '@supplierRegistration/stores/infraStores/SupplierRegistrationThemeStore';
import qs from 'query-string';
import {
  AsyncDataInternalLoadErrors,
  AsyncInitiateValidationMaskedData,
  toAsyncInitiateValidationMaskedData,
} from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { isDefined } from '@app/utils/utils';
import { WebErrorCode } from '@app/libs/request/httpConsts';
import { isValueInEnum } from '@app/utils/enumUtils';
import sleep from '@app/utils/sleep';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default class SupplierRegistrationCustomerDataStore {
  @observable currentReference: SupplierRegistrationCustomerReferences | null | undefined = undefined;
  @observable currentConfiguration: Loadable<SupplierRegistrationConfiguration | null> = LoadableCreator.notStarted();
  @observable currentAsyncId: string | null | undefined = undefined;
  @observable currentAsyncProcessMaskedData: Loadable<AsyncInitiateValidationMaskedData> | null = LoadableCreator.notStarted();

  private _currentCaptchaRef: React.RefObject<ReCAPTCHA> | undefined = undefined;
  private _themeStore: SupplierRegistrationThemeStore;

  constructor(themeStore: SupplierRegistrationThemeStore) {
    this._themeStore = themeStore;
    makeObservable(this);
  }

  getCaptchaRef = (): React.RefObject<ReCAPTCHA> => {
    if (!this._currentCaptchaRef) {
      this._currentCaptchaRef = React.createRef<ReCAPTCHA>();
    }

    return this._currentCaptchaRef;
  };

  @computed
  get currentAsyncProcessMaskedDataLoadError(): AsyncDataInternalLoadErrors | WebErrorCode | null {
    if (this.currentAsyncProcessMaskedData?.loadState !== LoadingState.Rejected) {
      return null;
    }

    if (this.currentAsyncProcessMaskedData.stateMetadata) {
      return SupplierRegistrationCustomerDataStore.extractErrorTypeFromAsyncLoadingError(
        this.currentAsyncProcessMaskedData.stateMetadata.error,
      );
    }

    return AsyncDataInternalLoadErrors.errorLoadingAsyncMaskedData;
  }

  @computed
  get customerName(): string | null {
    if (this._themeStore.theme.brand?.customerName) {
      return this._themeStore.theme.brand?.customerName;
    }

    if (this.currentConfiguration.isResolved() && this.currentConfiguration.result?.clientName) {
      return this.currentConfiguration.result.clientName;
    }

    return null;
  }

  tryLoadCustomerData = flow(function* (
    this: SupplierRegistrationCustomerDataStore,
    referenceId: string | null,
    subdomain: string | null,
    asyncId: string | null,
    isAsyncIdValid: boolean,
  ) {
    yield this.tryLoadCustomerConfiguration(referenceId, subdomain);
    yield this.tryLoadAsyncInitiateValidationProcessData(asyncId, isAsyncIdValid);
  });

  tryLoadCustomerConfiguration = flow(function* (
    this: SupplierRegistrationCustomerDataStore,
    referenceId: string | null,
    subdomain: string | null,
  ) {
    const newReference = this.convertToCustomerReferences(referenceId, subdomain);

    if (SupplierRegistrationCustomerDataStore.isCustomerReferencesEqual(newReference, this.currentReference)) {
      return;
    }

    this.currentReference = newReference;

    if (this.currentReference === null) {
      this.currentConfiguration = LoadableCreator.resolved(null);
      return;
    }

    try {
      yield this.loadCustomerConfiguration(this.currentReference);
    } catch {}
  });

  private loadCustomerConfiguration = flowad<
    SupplierRegistrationConfiguration | null,
    [references: SupplierRegistrationCustomerReferences]
  >(
    (newValue) => {
      this.currentConfiguration = newValue;
    },
    async (references: SupplierRegistrationCustomerReferences): Promise<SupplierRegistrationConfiguration | null> => {
      try {
        let configurationServerResponse;

        if (references.subdomain !== null) {
          configurationServerResponse = await customerServices.getSupplierRegistrationConfigurationBySubdomain(
            references.subdomain,
          );
        } else {
          try {
            configurationServerResponse = await customerServices.getSupplierRegistrationConfigurationByRef(
              references.referenceId,
            );
          } catch (error: unknown) {
            if (
              isRequestError(error) &&
              isWebErrorContent(error.responseJSON) &&
              error.responseJSON.error === 'SUPPLIER_VALIDATION_SHOULD_BE_SERVED_AS_SUBDOMAIN'
            ) {
              const { newAddress: newBaseAddress } = error.responseJSON.additionalData;
              await this.redirectToSubdomain(newBaseAddress);
            } else {
              Log.exception('unknown error while fetching ref', { error });
            }

            throw error;
          }
        }

        if (configurationServerResponse?.brand) {
          this._themeStore.setThemeFromServer(configurationServerResponse.brand);
        }

        return configurationServerResponse;
      } catch (e: unknown) {
        const requestError = e as RequestError;

        // Check if the error is "configuration not found"
        if (
          requestError.code === HttpStatus.notFound &&
          isWebErrorContent(requestError.responseJSON) &&
          requestError.responseJSON.error === 'SUPPLIER_VALIDATION_CONFIGURATION_NOT_FOUND'
        ) {
          return null;
        }

        throw e;
      }
    },
  );

  tryLoadAsyncInitiateValidationProcessData = flow(function* (
    this: SupplierRegistrationCustomerDataStore,
    asyncId: string | null,
    isAsyncIdValid: boolean,
  ) {
    if (!isAsyncIdValid && !this.currentAsyncProcessMaskedData?.isRejected()) {
      this.currentAsyncProcessMaskedData = LoadableCreator.rejected(
        AsyncDataInternalLoadErrors.asyncMaskedDataInvalidQueryParamValue,
      );
      return;
    }

    if (asyncId === this.currentAsyncId) {
      return;
    }

    this.currentAsyncId = asyncId;

    if (this.currentAsyncId === null) {
      this.currentAsyncProcessMaskedData = null;
      return;
    }

    yield this.loadAsyncInitiateValidationProcessData(this.currentAsyncId);
  });

  private loadAsyncInitiateValidationProcessData = flowad<AsyncInitiateValidationMaskedData, [asyncId: string]>(
    (newValue) => {
      this.currentAsyncProcessMaskedData = newValue;
    },
    async (asyncId: string): Promise<AsyncInitiateValidationMaskedData> => {
      if (!this.currentConfiguration.isResolved() || !this.currentConfiguration.result) {
        throw AsyncDataInternalLoadErrors.errorLoadingRef;
      }

      const asyncProcessMaskedDataServerResponse = await asyncProcessServices.getSupplierRegistrationConfigurationByRef(
        this.currentConfiguration.result.ref,
        asyncId,
      );

      return toAsyncInitiateValidationMaskedData(asyncProcessMaskedDataServerResponse);
    },
  );

  private async redirectToSubdomain(newBaseAddress: string): Promise<void> {
    Log.event('SV_REF_REDIRECT_TO_BRAND_ADDRESS', { newBaseAddress });

    const parsedUrlQueryParams = qs.parseUrl(window.location.href).query;
    delete parsedUrlQueryParams.ref;

    const parsedUrlQueryParamsStringified = qs.stringify(parsedUrlQueryParams);

    const newAddress = newBaseAddress + (parsedUrlQueryParamsStringified.length ? `?${parsedUrlQueryParamsStringified}` : '');

    window.location.assign(newAddress);
    // We want to fool the webapp to keep showing the loader while the page is redirecting to the subdomain.
    // If the sleep does not happen, the web app thinks the configuration failed to load and shows an error screen
    await sleep(10000);
  }

  private convertToCustomerReferences(
    referenceId: string | null,
    subdomain: string | null,
  ): SupplierRegistrationCustomerReferences | null {
    if (subdomain) {
      return { subdomain, referenceId: null };
    }

    if (referenceId) {
      return { referenceId, subdomain: null };
    }

    return null;
  }

  private static isCustomerReferencesEqual(
    first: SupplierRegistrationCustomerReferences | null | undefined,
    second: SupplierRegistrationCustomerReferences | null | undefined,
  ): boolean {
    if (first === second) {
      return true;
    }

    if (!isDefined(first) || !isDefined(second)) {
      return false;
    }

    return first.subdomain === second.subdomain && first.referenceId === second.referenceId;
  }

  private static extractErrorTypeFromAsyncLoadingError(error: unknown): AsyncDataInternalLoadErrors | WebErrorCode {
    if (isRequestError(error) && isWebErrorContent(error.responseJSON)) {
      return error.responseJSON.error;
    }

    if (typeof error === 'string' && isValueInEnum(AsyncDataInternalLoadErrors, error)) {
      return error;
    }

    return AsyncDataInternalLoadErrors.errorLoadingAsyncMaskedData;
  }
}
