import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { UserRoleTypeServerResponse } from '@app/domain/userManagement/roleTypes';

export async function getAllServiceTypes(): Promise<UserRoleTypeServerResponse[]> {
  return request<UserRoleTypeServerResponse[]>(serverUrl, `/api/service-types`, {
    method: HttpMethod.get,
    errorsHandler: {
      USER_NOT_IN_ORGANIZATION_ERROR: {
        message: (): string => `The selected user in not in the organization.`,
      },
      default: {
        message: 'Cannot get user service types.',
      },
    },
  });
}
