import React, { FC, ReactNode } from 'react';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { useParams } from 'react-router-dom';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader from '@app/components/header/PageHeader';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import Tabs, { TabPane } from '@app/components/Tabs';
import ManageOrganizationServices from '@backee/routes/organizations/manageOrganizationPage/ManageOrganizationServices';
import { LoadableCreator } from '@app/utils/Loadable';
import ManageOrganizationUsers from '@app/components/userManagement/ManageOrganizationUsers';
import browserHistory from '@app/utils/browserHistory';
import useInfraStores from '@app/hooks/useInfraStores';
import BackeeMode from '@backee/backeeMode';
import ManageOrganizationIdentityProviders from '@backee/routes/organizations/manageOrganizationPage/ManageOrganizationIdentityProviders';
import { transformKnoxerIdentityProviders } from '@app/domain/userManagement/identityProviders';
import useAppStores from '@app/hooks/useAppStores';
import { observer } from 'mobx-react';
import Loader from '@app/components/Loader';
import { getOrganizationById, transformToOrganization } from '@backee/services/organizationsServices';
import { addServiceToOrganization, removeServiceFromOrganization } from '@backee/services/organizationServicesServices';
import {
  addIdentityProviderToOrganization,
  getAllIdentityProvidersOfOrganization,
  removeIdentityProviderFromOrganization,
} from '@app/services/userManagement/organizationIdentityProviderServices';

export enum ManageOrganizationTabType {
  services = 'services',
  users = 'users',
  emailKnoxerIdentityProviders = 'emailKnoxerIdentityProviders',
  phoneKnoxerIdentityProviders = 'phoneKnoxerIdentityProviders',
}

const ManageOrganizationPage: FC = observer(() => {
  const { navigationStore, permissionsStore } = useInfraStores<BackeeMode>();
  const { knoxersStore, identityProvidersStore } = useAppStores<BackeeMode>();
  const { organizationId, activeTab } = useParams<{ organizationId: string; activeTab?: string }>();

  function onActiveTabChange(newActiveTabKey: ManageOrganizationTabType): void {
    browserHistory.replace(navigationStore.generateManageOrganizationLink(organizationId, newActiveTabKey));
  }

  function getValidActiveTab(currentActiveTabKey: string | undefined): ManageOrganizationTabType {
    if (
      !currentActiveTabKey ||
      !Object.values(ManageOrganizationTabType).includes(currentActiveTabKey as ManageOrganizationTabType)
    ) {
      return ManageOrganizationTabType.services;
    }

    return currentActiveTabKey as ManageOrganizationTabType;
  }

  const [organizationLoadable, , setOrganizationLoadable] = useLoadable(async () => {
    const organizationServerResponses = await getOrganizationById(organizationId);
    return transformToOrganization(organizationServerResponses);
  }, [organizationId]);

  const onAddService = async (serviceId: string): Promise<void> => {
    const updatedOrganization = await addServiceToOrganization(organizationId, { serviceId });
    setOrganizationLoadable(LoadableCreator.resolved(transformToOrganization(updatedOrganization)));
  };

  const onRemoveService = async (serviceId: string): Promise<void> => {
    const updatedOrganization = await removeServiceFromOrganization(organizationId, serviceId);
    setOrganizationLoadable(LoadableCreator.resolved(transformToOrganization(updatedOrganization)));
  };

  const [allOrganizationIdentityProvidersLoadable, reloadAllOrganizationIdentityProviders] = useLoadable(async () => {
    const allOrganizationIdentityProviders = await getAllIdentityProvidersOfOrganization(organizationId);
    return allOrganizationIdentityProviders.map(transformKnoxerIdentityProviders);
  }, [organizationId]);

  const knoxers = knoxersStore.knoxers;
  if (!knoxers.isResolved()) {
    return <Loader spinning />;
  }

  const onAddIdentityProvider = async (knoxerId: string, identityProviderName: string): Promise<void> => {
    await addIdentityProviderToOrganization(organizationId, knoxerId, identityProviderName);
    await reloadAllOrganizationIdentityProviders.silent();
  };

  const onRemoveIdentityProvider = async (knoxerId: string, identityProviderName: string): Promise<void> => {
    await removeIdentityProviderFromOrganization(organizationId, knoxerId, identityProviderName);
    await reloadAllOrganizationIdentityProviders.silent();
  };

  function renderIdentityProvidersForKnoxer(knoxerId: string, id: string, dataTestId: string): ReactNode {
    const allIdentityProvidersOfKnoxer = identityProvidersStore.getAllIdentityProvidersForKnoxer(knoxerId);

    const organizationIdentityProvidersOfKnoxerLoadable = allOrganizationIdentityProvidersLoadable.map(
      (allIdentityProvidersOfOrganization) =>
        allIdentityProvidersOfOrganization.find((knoxerIdentityProviders) => knoxerIdentityProviders.knoxerId === knoxerId)
          ?.identityProviders ?? [],
    );

    return organizationIdentityProvidersOfKnoxerLoadable.resolve(
      (organizationIdentityProviders): ReactNode => (
        <ManageOrganizationIdentityProviders
          id={id}
          dataTestId={dataTestId}
          identityProviders={allIdentityProvidersOfKnoxer}
          activeIdentityProviders={organizationIdentityProviders.map((identityProvider) => {
            return identityProvider.name;
          })}
          onServiceAdd={(identityProviderName): Promise<void> => onAddIdentityProvider(knoxerId, identityProviderName)}
          onServiceRemove={(identityProviderName): Promise<void> => onRemoveIdentityProvider(knoxerId, identityProviderName)}
        />
      ),
      () => 'Loading...',
    );
  }

  return (
    <PageWithHeader
      width='full'
      paddingTop='none'
      header={
        <PageHeader backButtonTo={navigationStore.generateOrganizationsListLink()}>
          <PageHeaderTitle
            category='ORGANIZATION'
            title={organizationLoadable.resolve(
              (organization) => organization.name,
              () => 'Loading...',
            )}
          />
        </PageHeader>
      }
    >
      <Tabs tabBarGutter={0} onChange={onActiveTabChange} activeKey={getValidActiveTab(activeTab)} destroyInactiveTabPane>
        <TabPane
          tab={
            <span data-testid={`tab-manage-organization-services${organizationLoadable.isResolved() ? '' : '-disabled'}`}>
              Services
            </span>
          }
          key={ManageOrganizationTabType.services}
          disabled={!organizationLoadable.isResolved()}
        >
          {organizationLoadable.resolve(
            (organization): ReactNode => (
              <ManageOrganizationServices
                activeServiceIds={organization.serviceIds}
                onServiceAdd={onAddService}
                onServiceRemove={onRemoveService}
              />
            ),
            () => 'Loading...',
          )}
        </TabPane>
        <TabPane
          tab={
            <span data-testid={`tab-manage-organization-users${organizationLoadable.isResolved() ? '' : '-disabled'}`}>
              Users
            </span>
          }
          key={ManageOrganizationTabType.users}
          disabled={!organizationLoadable.isResolved()}
        >
          {LoadableCreator.combine(organizationLoadable, allOrganizationIdentityProvidersLoadable, knoxers).resolve(
            ([organization, organizationIdentityProviders, knoxers]): ReactNode => (
              <ManageOrganizationUsers
                organization={organization}
                knoxers={knoxers}
                organizationIdentityProviders={organizationIdentityProviders}
                canViewNsknoxUsers={true}
                canAddUsersToOrg={permissionsStore.isEditAllowed}
                canSuspendUserFromOrganization={permissionsStore.isEditAllowed}
                canEditUserPermissions={permissionsStore.isEditAllowed}
                canKillUserSessions={permissionsStore.isEditAllowed}
              />
            ),
            () => 'Loading...',
          )}
        </TabPane>
        <TabPane
          tab={
            <span
              data-testid={`tab-manage-organization-email-knoxer-identity-providers${
                organizationLoadable.isResolved() ? '' : '-disabled'
              }`}
            >
              Identity Providers - {knoxers.result.emailKnoxer.name} Knoxer
            </span>
          }
          key={ManageOrganizationTabType.emailKnoxerIdentityProviders}
          disabled={!allOrganizationIdentityProvidersLoadable.isResolved()}
        >
          {renderIdentityProvidersForKnoxer(
            knoxers.result.emailKnoxer.id,
            'organization-manage-email-knoxer-identity-providers-table',
            'organization-manage-email-knoxer-identity-providers-table',
          )}
        </TabPane>
        <TabPane
          tab={
            <span
              data-testid={`tab-manage-organization-phone-knoxer-identity-providers${
                organizationLoadable.isResolved() ? '' : '-disabled'
              }`}
            >
              Identity Providers - {knoxers.result.phoneKnoxer.name} Knoxer
            </span>
          }
          key={ManageOrganizationTabType.phoneKnoxerIdentityProviders}
          disabled={!allOrganizationIdentityProvidersLoadable.isResolved()}
        >
          {renderIdentityProvidersForKnoxer(
            knoxers.result.phoneKnoxer.id,
            'organization-manage-phone-knoxer-identity-providers-table',
            'organization-manage-phone-knoxer-identity-providers-table',
          )}
        </TabPane>
      </Tabs>
    </PageWithHeader>
  );
});

export default ManageOrganizationPage;
