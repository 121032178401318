import React, { FC } from 'react';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import styled from '@emotion/styled';
import getCardCornersCssClass from '@app/components/corners/CornerCssGenerator';
import TooltipUnstyled from './TooltipUnstyled';

interface Props extends TooltipPropsWithTitle {
  className?: string;
  cardClassName?: string;
}

const TooltipWithFourCorners: FC<Props> = (tooltipProps) => {
  return <FourCornersTooltipHeader {...tooltipProps} />;
};

export default TooltipWithFourCorners;

const FourCornersTooltipHeader = styled(TooltipUnstyled)<Props>`
  & .ant-tooltip-inner {
    ${getCardCornersCssClass('all')};
    color: var(--transparent-black-900);
    box-shadow: var(--box-shadow-3);
    padding: 20px;
    border-radius: 0;
    ${(p): string | undefined => p.cardClassName}
  }
`;
