import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default class WebSentryLogger implements ExceptionLogger {
  constructor(url: string, version: string) {
    Sentry.init({
      dsn: url,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      release: version,
    });
  }

  async init(): Promise<void> {}

  setUserInfo(userId: string, userInfo: any): void {
    Sentry.setUser({ userId, ...userInfo });
  }

  setTag(name: string, value: string | number): void {
    Sentry.setTags({ [name]: value });
  }

  identify(id: string): void {
    Sentry.setExtras({
      userId: id,
    });
  }

  exception(error: Error, _errorString: string, additionalData?: object): void {
    try {
      Sentry.withScope((scope) => {
        additionalData && scope.setExtra('additionalData', additionalData);
        Sentry.captureException(error);
      });
    } catch (e: unknown) {
      // Do nothing
    }
  }

  event(name: string, additionalData?: object): void {
    Sentry.addBreadcrumb({
      category: 'event',
      message: name,
      data: { ...additionalData },
    });
  }

  currentView(viewName: string, additionalData?: object): void {
    this.event('navigation', { screenName: viewName, ...additionalData });
  }
}
