import styled from '@emotion/styled';
import Nationality from './Nationality';
import { ifProp } from 'styled-tools';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  line-height: 1;
`;

export const Title = styled.span`
  display: block;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.18px;
  color: #212121;
`;

export const BlockHeader = styled.div<{ small?: boolean }>`
  font-size: ${ifProp('small', '17px', '22px')};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.15px;
  color: var(--gray-blue-deprecated);
  margin-left: 24px;
  margin-bottom: 12px;
`;

export const BlockDescriptor = styled.div`
  font-size: 12.1px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: var(--primary-500);
  margin-bottom: 11px;
`;

export const InfoCategory = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 6px;
  flex: 0 0 auto;
`;

export const InfoContent = styled.div`
  font-weight: 200;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  color: var(--transparent-black-700);
`;

export const StyledNationality = styled(Nationality)`
  font-weight: 200;
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  color: rgba(61, 68, 90, 0.56);
  align-self: center;
`;

export const BufferLine = styled.div`
  min-height: 1px;
  background-color: var(--transparent-gray-150);
  margin-top: 12px;
  margin-bottom: 12px;
`;
