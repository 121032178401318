import React, { FC, ReactElement, useContext, useRef } from 'react';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import Button from '@app/components/Button';
import FormContext from '@app/components/inputs/FormContext';
import { isDefined } from '@app/utils/utils';

export interface DynamicListOfFormFieldObject<T> {
  keysList?: number[];
  values?: (T | undefined)[];
}

interface Props {
  id: string;
  dataTestId?: string;
  fieldName: string;
  renderChild(childFieldName: string, remove: () => void, index: number): ReactElement;
  maxAmountOfItems?: number;
  className?: string;
}

const DynamicListOfFormField: FC<Props> = ({ id, dataTestId = id, fieldName, renderChild, maxAmountOfItems, className }) => {
  const { form } = useContext(FormContext);

  const keysRunningIndex = useRef(1);

  const keyListFieldKey = `${fieldName}.keysList`;

  function getKeysList(): number[] {
    return form.getFieldValue(keyListFieldKey) ?? [];
  }

  function setKeysList(newKeysList: number[]): void {
    form.setFieldsValue({
      [keyListFieldKey]: newKeysList,
    });
  }

  function remove(keyToRemove: number): void {
    const currentKeys = getKeysList();
    setKeysList(arrayWithoutValue(currentKeys, keyToRemove));
  }

  function add(): void {
    const currentKeys = getKeysList();
    keysRunningIndex.current = keysRunningIndex.current + 1;
    setKeysList([...currentKeys, keysRunningIndex.current]);
  }

  // This registers the keysList
  form.getFieldDecorator(keyListFieldKey);
  const keysList = getKeysList();

  const hasReachedMaxAmountInList = isDefined(maxAmountOfItems) ? keysList.length === maxAmountOfItems : false;

  return (
    <div className={className}>
      {keysList.map(
        (ssoIdentityListKey, index): ReactElement => (
          <React.Fragment key={ssoIdentityListKey}>
            {renderChild(`${fieldName}.values[` + ssoIdentityListKey + ']', () => remove(ssoIdentityListKey), index)}
          </React.Fragment>
        ),
      )}
      <Button
        id={`btn-${id}-list-add`}
        dataTestId={`btn-${dataTestId}-list-add`}
        onClick={add}
        disabled={hasReachedMaxAmountInList}
        size='small'
      >
        + ADD{' '}
      </Button>
    </div>
  );
};

export default DynamicListOfFormField;
