import styled from '@emotion/styled';
import Button from '@app/components/Button';
import React, { FC, ReactElement } from 'react';
import BackArrow from '@app/components/BackArrow';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
  className?: string;
  onClick: () => void;
}

const BackButton: FC<BackButtonProps> = ({ className, onClick }): ReactElement => {
  const { t } = useTranslation();

  return (
    <StyledButton id='previous-page' dataTestId='previous-page' appearance='text' onClick={onClick} className={className}>
      <BackButtonContainer className={className}>
        <StyledBackArrow className={className} />
        <span>{t('supplierValidation.supplierRegister.back')}</span>
      </BackButtonContainer>
    </StyledButton>
  );
};

export default BackButton;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;

  --button-block-padding: 0.4em;
  --button-line-padding: 0.8em;

  margin-inline-start: calc(-1 * var(--button-line-padding));
`;

const StyledBackArrow = styled(BackArrow)`
  height: 9px;
  width: 6px;

  margin-inline-end: 8px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: baseline;
`;
