import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '../Button';
import DownArrow from '@app/images/arrows/ic-down-arrow-accent.svg';
import DownArrowDisabled from '@app/images/arrows/ic-down-arrow-disabled.svg';
import SVG from '@app/components/SVG';

interface ExpanderButtonProps extends Omit<ButtonProps, 'onClick'> {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const Expander: FunctionComponent<ExpanderButtonProps> = (props) => {
  const { id, disabled, children, expanded, setExpanded, ...buttonProps } = props;

  return (
    <ExpanderButton
      id={id}
      disabled={disabled}
      appearance='text'
      cornerType='none'
      colorScheme='secondary'
      {...buttonProps}
      onClick={(): void => setExpanded(!expanded)}
    >
      {children}{' '}
      <RotatableIcon expanded={expanded}>
        <StyledSVG accessibilityLabel='' image={disabled ? DownArrowDisabled : DownArrow} />
      </RotatableIcon>
    </ExpanderButton>
  );
};

export default Expander;

const ExpanderButton = styled(Button)`
  display: inline-block;
  padding: 4px 0 0;
`;

const RotatableIcon = styled.span<{ expanded?: boolean }>`
  display: inline-block;
  transition: transform 300ms ease;
  transform-origin: center calc(50% - 0.5px);
  ${({ expanded }): string => (expanded ? 'transform: rotate(180deg)' : '')};
`;

const StyledSVG = styled(SVG)`
  min-width: 18px;
  width: 18px;
  height: 18px;
`;
