import Loadable from '@app/utils/Loadable';
import { setsEqual } from '@app/utils/setUtils';
import { PrivatePayee, PrivatePayeeCompaniesServerResponse } from '@mortee/domain/privatePayee';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { ValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import {
  DeterministicValidationWithPrivatePayee,
  DeterministicValidationWithPrivatePayeeServerResponse,
} from '@mortee/domain/morteeDeterministicValidation';
import { PrivatePayeeAccount, PrivatePayeeAccountServerResponse } from '@mortee/domain/privatePayeeAccount';

export interface PrivateAreaCompaniesServerResponse {
  companies: PrivatePayeeCompaniesServerResponse[];
  doesAllHaveCompanies: boolean;
}

export interface MasterDataGuardSearchableDataServerResponse {
  payees: PrivatePayeeSearchableDataServerResponse[];
  payeeAccountsAmount: number;
  companies: PrivateAreaCompaniesServerResponse;
}

export interface PrivatePayeeSearchableDataServerResponse {
  uniformId: string;
  names: string[];
  externalId: string;
  supplierNumbers?: string[];
  companies?: PrivatePayeeCompaniesServerResponse[];
}

export enum SyncPlatform {
  SAP = 'SAP',
  Oracle = 'Oracle',
  Priority = 'Priority',
  Portal = 'Portal',
  SFTP = 'SFTP',
}

export interface SyncInfoServerResponse {
  lastSyncProcess: SyncProcessServerResponse;
  organizationalErpPlatform: SyncPlatform | null;
}

export interface SyncProcessServerResponse {
  id: string;
  sourceTimestamp: number;
  requestingPlatform: SyncPlatform;
}

export enum LivePrivatePayeeValidationLevel {
  all = 'All',
  notValidated = 'NotValidated',
}

export interface CompanyCodesFilter {
  allowBlankValue: boolean;
  values: Set<string>;
}

export interface LivePrivatePayeeFilters {
  searchQuery: string;
  validationLevel: LivePrivatePayeeValidationLevel;
  companyCodes: CompanyCodesFilter | null;
}

export interface LivePrivatePayeeFiltersOptions {
  validationLevel: LivePrivatePayeeValidationLevel[];
  companyCodes: Loadable<CompanyCodesFilter>;
}

export function isLivePrivatePayeeFiltersEquals(item1: LivePrivatePayeeFilters, item2: LivePrivatePayeeFilters): boolean {
  if (item1 === item2) {
    return true;
  }

  return (
    item1.validationLevel === item2.validationLevel &&
    item1.searchQuery === item2.searchQuery &&
    isLivePrivatePayeeCompanyCodesFilterEquals(item1.companyCodes, item2.companyCodes)
  );
}

export function isLivePrivatePayeeCompanyCodesFilterEquals(
  item1: CompanyCodesFilter | null,
  item2: CompanyCodesFilter | null,
): boolean {
  if (item1 === item2) {
    return true;
  }

  if (!item1 || !item2) {
    return false;
  }

  return item1.allowBlankValue === item2.allowBlankValue && setsEqual(item1.values, item2.values);
}

export interface SyncInfo {
  lastSyncProcess: SyncProcess | null;
  organizationalErpPlatform: SyncPlatform | null;
}

export interface DisplayedPayeesTotal {
  payeesAmount: number;
  payeeAccountsAmounts: {
    all: number;
    notValidated: Loadable<number>;
  };
}

export interface PrivateAreaCompanyCodes {
  companyCodes: string[];
  doesAllHaveCompanies: boolean;
}

export interface MasterDataGuardSearchableData {
  payees: PrivatePayeeSearchableData[];
  payeeAccountsAmount: number;
  companyCodes: PrivateAreaCompanyCodes;
}

export interface PrivatePayeeSearchableData {
  uniformId: string;
  namesLowercase: string[];
  externalIdLowercase: string;
  supplierNumbers: string[];
  companyCodes: string[];
}

export interface SyncProcess {
  id: string;
  sourceTimestamp: number;
  requestingPlatform: SyncPlatform;
}

export enum PayeeValidationLevel {
  valid,
  someNotValidated,
  allNotValidated,
}

export interface PrivatePayeeAccountWithDeterministicValidationServerResponse {
  privatePayeeAccount: PrivatePayeeAccountServerResponse;
  deterministicValidationData: DeterministicValidationWithPrivatePayeeServerResponse;
}

export interface PrivatePayeeAccountWithDeterministicValidation {
  privatePayeeAccount: PrivatePayeeAccount;
  deterministicValidation: DeterministicValidationWithPrivatePayee;
}

export interface PrivatePayeeWithDeterministicValidations {
  privatePayee: PrivatePayee;
  validatedPayees: AggregatedValidatedPayee[];
  validatedPayeesAccounts: Partial<Record<string, ValidatedPayeeAccount[]>>;
  accountsValidation: PrivatePayeeAccountWithDeterministicValidation[];
  payeeValidationLevel: PayeeValidationLevel;
}
