import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import Table, { ObserverTableProps } from './tables/Table';
import Card from './card/Card';

interface CardTableProps<T> extends ObserverTableProps<T> {}

const OldCardTable: <T>(props: CardTableProps<T>) => ReactElement = (props) => {
  return (
    <StyledCard>
      <Table {...props} />
    </StyledCard>
  );
};

export default OldCardTable;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0;
`;
