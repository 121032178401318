import { ActivityLogEvent } from '@mortee/domain/validationSystemTimelineEvents';
import { FormattedDate, FormattedTime } from '@app/components/Locale';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BodySmallStartTransparentBlack600, SubtitleSmallStartTransparentBlack900 } from '@app/components/Text';

interface Props {
  event: ActivityLogEvent;
  className?: string;
}
const EventMetadata: FC<Props> = ({ event, className }) => (
  <MetadataLeft className={className}>
    <DateText value={event.metadata.timestamp} />
    <FormattedTime value={event.metadata.timestamp} />
    <span>{event.metadata.performingUserName}</span>
  </MetadataLeft>
);

export default EventMetadata;

const MetadataLeft = styled(BodySmallStartTransparentBlack600.div)`
  display: flex;
  align-items: baseline;

  gap: 10px;
`;

const DateText = styled(FormattedDate)`
  ${SubtitleSmallStartTransparentBlack900.css}
`;
