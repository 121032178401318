import React, { FC, ReactElement } from 'react';
import { Grow, Popper, PopperPlacementType, PopperProps } from '@material-ui/core';
import useInfraStores from '@app/hooks/useInfraStores';
import { observer } from 'mobx-react';
import { TransformOriginProperty } from 'csstype';

export declare type PopupPlacement =
  | 'top-start'
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'top-end'
  | 'start-top'
  | 'left-top'
  | 'right-top'
  | 'end-top'
  | 'start'
  | 'left'
  | 'right'
  | 'end'
  | 'start-bottom'
  | 'left-bottom'
  | 'right-bottom'
  | 'end-bottom'
  | 'bottom-start'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right'
  | 'bottom-end';

export type PopupPlacementOffset = { x?: number; y?: number };

interface Props extends Omit<PopperProps, 'placement'> {
  placement?: PopupPlacement;
  placementOffset?: PopupPlacementOffset;
}

const PopupBase: FC<Props> = observer((props) => {
  const infraStores = useInfraStores();

  const { placement = 'bottom', placementOffset, anchorEl, className, children, style, ...otherPopoverProps } = props;

  const popoverPlacement = calcPopupPlacementOriginsFromPlacements(placement, infraStores.languageStore.isRTL);

  // check why fade does not wor
  return (
    <Popper
      anchorEl={anchorEl}
      placement={popoverPlacement}
      role={undefined}
      style={{ zIndex: 10001, ...style }}
      modifiers={{
        computeStyle: {
          // This will force Popper.js to use top/left instead of translate3d
          gpuAcceleration: false,
        },
        offset: {
          offset: `${placementOffset?.x ?? 0},${placementOffset?.y ?? 0}`,
        },
        flip: {
          enabled: !placementOffset?.x && !placementOffset?.y,
        },
      }}
      transition
      {...otherPopoverProps}
    >
      {({ TransitionProps, placement }): ReactElement => (
        <Grow {...TransitionProps} style={{ transformOrigin: convertPopupPlacementToTransformOrigin(placement) }}>
          <div>{children}</div>
        </Grow>
      )}
    </Popper>
  );
});

export default PopupBase;

function calcPopupPlacementOriginsFromPlacements(placement: PopupPlacement, isRTL: boolean): PopperPlacementType {
  switch (placement) {
    case 'top-start':
      return isRTL ? 'top-start' : 'top-end';
    case 'top-left':
      return 'top-start';
    case 'top':
      return 'top';
    case 'top-right':
      return 'top-end';
    case 'top-end':
      return isRTL ? 'top-end' : 'top-start';
    case 'start-top':
      return isRTL ? 'right-start' : 'left-start';
    case 'left-top':
      return 'left-start';
    case 'right-top':
      return 'right-start';
    case 'end-top':
      return isRTL ? 'left-start' : 'right-start';
    case 'start':
      return isRTL ? 'right' : 'left';
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    case 'end':
      return isRTL ? 'left' : 'right';
    case 'start-bottom':
      return isRTL ? 'right-end' : 'left-end';
    case 'left-bottom':
      return 'left-end';
    case 'right-bottom':
      return 'right-end';
    case 'end-bottom':
      return isRTL ? 'left-end' : 'right-end';
    case 'bottom-start':
      return isRTL ? 'bottom-end' : 'bottom-start';
    case 'bottom-left':
      return 'bottom-start';
    case 'bottom':
      return 'bottom';
    case 'bottom-right':
      return 'bottom-end';
    case 'bottom-end':
      return isRTL ? 'bottom-start' : 'bottom-end';
  }
}

export function convertPopupPlacementToTransformOrigin(popupPlacement: PopperPlacementType): TransformOriginProperty<string> {
  switch (popupPlacement) {
    case 'bottom-end':
      return 'top right';
    case 'bottom-start':
      return 'top left';
    case 'bottom':
      return 'top center';
    case 'left-end':
      return 'right bottom';
    case 'left-start':
      return 'right top';
    case 'left':
      return 'right center';
    case 'right-end':
      return 'left bottom';
    case 'right-start':
      return 'left top';
    case 'right':
      return 'left center';
    case 'top-end':
      return 'bottom right';
    case 'top-start':
      return 'bottom left';
    case 'top':
      return 'bottom center';
  }
}
