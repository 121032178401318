import { ConditionalValidationRule } from '@app/utils/form/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { trim } from '@app/utils/stringUtils';
import * as countryProperties from '@supplierRegistration/domain/countries';
import { AccountType } from '@app/domain/accountsDetailsHelpers';
import { BaseFormFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { COUNTRY_CODES, CountryCode } from '@app/domain/countries';
import { isJpyCurrency } from '@supplierRegistration/domain/countriesWithUniqueProperties/JP';

export function doesCountryHasSpecificBankCodeBehavior(accountBankCountryCode: string | undefined): boolean {
  if (!accountBankCountryCode) {
    return false;
  }

  return !!countryProperties[accountBankCountryCode]?.bankCode;
}

export interface SupplierRegistrationField {
  id: string;
  inputType: 'text' | 'number';
  decoratorRules: ConditionalValidationRule[];
}

const createDefaultSupplierRegistrationField = (accountCountryCode?: CountryCode): SupplierRegistrationField => {
  return {
    id: 'inpt-supp-reg-bank-code',
    inputType: 'text',
    decoratorRules: getDefaultBankCodeRules(accountCountryCode),
  };
};

export function getCountrySupplierRegistrationBankCodeField(
  form: WrappedFormUtils<BaseFormFields>,
  accountCountryCode?: string,
  useDefaultBankCode?: boolean,
): SupplierRegistrationField {
  if (!accountCountryCode || !countryProperties[accountCountryCode] || useDefaultBankCode) {
    return createDefaultSupplierRegistrationField();
  }

  return getBankCodeRulesByCountryOrDefault(accountCountryCode, form);
}

export function getCountrySupplierRegistrationBranchCodeField(
  form: WrappedFormUtils<BaseFormFields>,
  accountCountryCode?: string,
  useDefaultBankCode?: boolean,
): SupplierRegistrationField | undefined {
  if (!accountCountryCode || !countryProperties[accountCountryCode] || useDefaultBankCode) {
    return;
  }

  return getBranchCodeRulesByCountry(accountCountryCode, form);
}

export function getAdditionalSwiftRulesForCountry(countryCode: string | undefined, form: WrappedFormUtils<BaseFormFields>): [] {
  if (!countryCode || !countryProperties[countryCode]?.swiftCodeAdditionalRules) {
    return [];
  }

  return countryProperties[countryCode]?.swiftCodeAdditionalRules(form);
}

export function getAdditionalAccountNumberRulesForCountry(countryCode: string | undefined): [] {
  if (!countryCode) {
    return [];
  }

  return countryProperties[countryCode]?.accountNumberAdditionalRules ?? [];
}

export function getBankCodeRulesByCountryOrDefault(
  countryCode: string,
  form: WrappedFormUtils<BaseFormFields>,
): SupplierRegistrationField {
  if (!countryCode || !countryProperties[countryCode]?.bankCode) {
    return createDefaultSupplierRegistrationField();
  }

  return countryProperties[countryCode].bankCode(form);
}

export function getBranchCodeRulesByCountry(
  countryCode: string,
  form: WrappedFormUtils<BaseFormFields>,
): SupplierRegistrationField | undefined {
  if (!countryCode || !countryProperties[countryCode]?.branchCode) {
    return;
  }

  return countryProperties[countryCode].branchCode(form);
}

export function getAccountTypeByCountry(countryCode: string | undefined): Partial<Record<AccountType, string>> | undefined {
  if (!countryCode || !countryProperties[countryCode]?.accountType) {
    return;
  }

  return countryProperties[countryCode].accountType;
}

export function hideAccountTypeByCountry(countryCode: string | undefined, form, isIndividual: boolean): boolean {
  if (!countryCode || !countryProperties[countryCode]?.showAccountType) {
    return true;
  }

  return !countryProperties[countryCode].showAccountType(form, isIndividual);
}

export function hideBankNameByCountry(countryCode: string | undefined): boolean {
  return !countryCode || !countryProperties[countryCode]?.showBankName;
}

export function hideFurtherCreditByCountry(countryCode: string | undefined): boolean {
  return countryCode && countryProperties[countryCode]?.hideFurtherCredit;
}

export function calculateSwiftOrBankValidatorEnabled(
  accountBankCountryCode: COUNTRY_CODES | undefined,
  form: WrappedFormUtils<BaseFormFields>,
): boolean {
  if (accountBankCountryCode === COUNTRY_CODES.UnitedStates) {
    return false;
  }

  if (accountBankCountryCode === COUNTRY_CODES.Israel) {
    return false;
  }

  if (accountBankCountryCode === COUNTRY_CODES.Japan && isJpyCurrency(form)) {
    return false;
  }

  return true;
}

export const getDefaultBankCodeRules = (accountCountryCode: CountryCode | undefined): ConditionalValidationRule[] => {
  return [
    {
      enabled: accountCountryCode !== COUNTRY_CODES.UnitedStates,
      max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
      transform: trim,
      translatedMessage: {
        key: 'supplierValidation.supplierRegister.maxCharacters',
        value: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
      },
    },
    {
      pattern: VALIDATION_PATTERNS.alphanumericWithChineseAndDashes,
      transform: trim,
      translatedMessage: [
        `supplierValidation.supplierRegister.${accountCountryCode}.errors.invalidBankCode`,
        'supplierValidation.supplierRegister.errors.invalidBankCode',
      ],
    },
  ];
};
