import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { action, flow } from 'mobx';
import { FileAndErrors, FilesErrorOutput } from '@app/domain/files';
import { FilesUploadMode } from '@mortee/domain/fileUpload';
import styled from '@emotion/styled';
import PaymentCheckBatchModel from '@mortee/models/PaymentCheckBatchModel';
import browserHistory from '@app/utils/browserHistory';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import PageWithHeader from '@app/components/PageWithHeader';
import useInfraStores from '@app/hooks/useInfraStores';
import useMountEffect from '@app/hooks/useMountEffect';
import SelectFiles from '@mortee/components/check/SelectFiles';
import Button from '@app/components/Button';
import sleep from '@app/utils/sleep';
import { LOAD_PAYMENT_FILE_TITLE } from '@mortee/domain/paymentsConsts';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';

const LoadBatchPage: FunctionComponent = observer(() => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { paymentCheckStore } = useAppStores<MorteeMode>();
  const BATCH_MAX_FILES = 20;

  const localStore = useLocalObservable(() => ({
    fileAndErrors: [] as FileAndErrors[],
    startUploading: false as boolean,
    hasErrors: false as boolean,
    finishedUploading: false as boolean,
    notes: undefined as string | undefined,
    storedBatch: null as PaymentCheckBatchModel | null,
    uploadErrors: null as { [fileName: string]: string } | null,

    get files(): File[] {
      return localStore.fileAndErrors.map((x) => x.file);
    },
  }));

  useMountEffect(() => {
    window.onpopstate = onBackButtonEvent;
  });

  const onBackButtonEvent = (): void => {
    if (!localStore.finishedUploading) {
      navigationStore.moveToSectionHome();
    }
  };

  const startUpload = action((files: FileAndErrors[], notes?: string): void => {
    localStore.notes = notes;
    localStore.uploadErrors = null;
    localStore.fileAndErrors = files || [];
    localStore.startUploading = true;

    upload();
  });

  const onUploadingError = action(() => {
    if (localStore.uploadErrors) {
      localStore.fileAndErrors = localStore.fileAndErrors.map((fileAndError) => {
        const fileError = localStore.uploadErrors?.[fileAndError.file.name];
        if (!fileError) {
          return fileAndError;
        }

        return { ...fileAndError, errors: [fileError] };
      });
    }

    localStore.startUploading = false;
  });

  const onUploadingEnd = action(() => {
    localStore.finishedUploading = true;
  });

  const upload = flow(function* () {
    try {
      // Make upload request take at lease 3 seconds
      const [storedBatchResponse] = yield Promise.all([
        paymentCheckStore.storePaymentBatch(localStore.files, localStore.notes),
        sleep(3000),
      ]);
      browserHistory.push(navigationStore.generateSingleBatchLink(storedBatchResponse.id));
      localStore.storedBatch = storedBatchResponse;
    } catch (e: unknown) {
      const requestError = e as FilesErrorOutput;
      if (!requestError.erroredFiles) {
        const bodyContent = (
          <div>
            An error occurred while uploading files.
            <br />
            Please contact us at <BlueText>support@nsknox.net</BlueText> for assistance.
            {requestError.errorLogId && ` Error Code: ${requestError.errorLogId}`}
          </div>
        );

        showContentOnlyModal((onDone) => (
          <ErrorModal headerContent='Something went wrong' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
        ));
        localStore.startUploading = false;
      } else {
        localStore.uploadErrors = requestError.erroredFiles;
        onUploadingError();
      }
    } finally {
      onUploadingEnd();
    }
  });

  const cancelPress = (): void => {
    navigationStore.moveToSectionHome();
  };

  return (
    <PageWithHeader widthReactive={false}>
      <Container>
        <PageHeader>
          <PageTitle>New Verification</PageTitle>
          <Button
            id='btn-select-files-cancel'
            disabled={localStore.startUploading}
            onClick={(): void => cancelPress()}
            appearance='outline'
            colorScheme='primary'
          >
            CANCEL
          </Button>
        </PageHeader>
        <SelectFiles
          title={LOAD_PAYMENT_FILE_TITLE}
          isUploading={localStore.startUploading}
          modes={[FilesUploadMode.payments]}
          files={localStore.fileAndErrors}
          initialNotes={localStore.notes}
          onFilesUpload={startUpload}
          maxFilesToUpload={BATCH_MAX_FILES}
        />
      </Container>
    </PageWithHeader>
  );
});

export default LoadBatchPage;

const PageTitle = styled.span`
  font-size: 36px;
  color: var(--gray-blue-deprecated);
  font-weight: 200;
  letter-spacing: 0.49px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BlueText = styled.span`
  font-weight: bold;
  color: var(--accent-blue-600);
`;
