import React, { Component, CSSProperties, FC, ReactElement, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react';
import { DropdownItemProps } from './DropdownItem';
import FormInputsContext from './FormInputsContext';
import { ForwardingFC } from '@app/domain/technicals/components';
import { convertToPlaceholderProps, FormInputProps } from '@app/utils/form/form';
import { ColorScheme } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import DownArrow from '@app/images/arrows/ic-down-arrow-primary.svg';
import DownArrowDynamic from '@app/images/arrows/ic-down-arrow-dynamic.svg';
import DownArrowDisabled from '@app/images/arrows/ic-down-arrow-disabled.svg';
import SmallLoadingSpinner from '../SmallLoadingSpinner';
import { css } from '@emotion/css';
import { PopperProps } from '@material-ui/core';
import useInfraStores from '@app/hooks/useInfraStores';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import PopupBase, { PopupPlacement } from '@app/components/popup/PopupBase';
import { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize/TextareaAutosize';
import { NakedDropdownInnerInputProps } from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputProps';
import NakedDropdownInnerInputDefault from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputDefault';

export type DropdownProps = FormInputProps<string> & {
  name: string;
  dataTestId: string;
  disabled?: boolean;
  loading?: boolean;
  colorScheme?: ColorScheme;
  textColorScheme?: ColorScheme;
  popupWidth?: CSSProperties['width'];
  popupPlacement?: PopupPlacement;
  className?: string;
  children: ReactElement<DropdownItemProps>[];
  useDynamicArrow?: boolean;
  isSearchable?: boolean;
  autoFocus?: boolean;
  InnerInputComponent?: FC<NakedDropdownInnerInputProps>;
  freeSolo?: boolean;
};

const NakedDropdown: ForwardingFC<HTMLDivElement, DropdownProps> = observer(
  React.forwardRef((props: DropdownProps, ref) => {
    const { t } = useTranslation();
    const { languageStore } = useInfraStores();

    const {
      name,
      dataTestId,
      colorScheme: propColorScheme,
      disabled: disabledProp,
      value,
      onChange,
      isSearchable,
      textColorScheme,
      autoFocus,
      popupWidth,
      popupPlacement = 'bottom-start',
      loading,
      useDynamicArrow,
      children,
      className,
      freeSolo,
      InnerInputComponent = NakedDropdownInnerInputDefault,
    } = props;

    const colorScheme = useColorScheme(propColorScheme);

    const getArrowIcon = (): ReactNode => {
      if (freeSolo) {
        return;
      }

      if (loading) {
        return <SmallLoadingSpinner size={16} colorScheme={colorScheme} />;
      }

      if (disabled) {
        return <StyledSVG accessibilityLabel='' image={DownArrowDisabled} />;
      }

      if (useDynamicArrow) {
        return <StyledSVG accessibilityLabel='' image={DownArrowDynamic} />;
      }

      return <StyledSVG accessibilityLabel='' image={DownArrow} />;
    };

    const classes = makeStyles(() =>
      createStyles({
        option: {
          display: 'grid',
          justifyContent: 'stretch',
          padding: 0,
          background: 'transparent',
        },
      }),
    )();

    const childrenAsArray = React.Children.map(children, (child) => (child as unknown) as Component<DropdownItemProps>) ?? [];
    const childrenByKeys = Object.fromEntries(childrenAsArray.map((x) => [x.props.value, x]));

    const { state } = useContext(FormInputsContext);
    const disabled = disabledProp || state === 'disabled';

    function getInputProps(params: AutocompleteRenderInputParams): NakedDropdownInnerInputProps['inputSpecificProps'] {
      const { defaultValue, size, ref, autoComplete = 'new-password', ...rest } = params.inputProps as TextFieldProps;

      return {
        autoComplete,
        ...rest,
      };
    }

    function getTextAreaProps(params: AutocompleteRenderInputParams): NakedDropdownInnerInputProps['textAreaSpecificProps'] {
      const { defaultValue, ref, autoComplete = 'new-password', ...rest } = params.inputProps as TextareaAutosizeProps;

      const textAreaProps = {
        autoComplete,
        ...rest,
      };
      return textAreaProps;
    }

    function renderInput(params: AutocompleteRenderInputParams): ReactElement {
      const { ref: outerRef } = params.InputProps;

      const inputProps = getInputProps(params);
      const textAreaProps = getTextAreaProps(params);
      const innerInputRef = (params.inputProps as TextareaAutosizeProps).ref;
      const placeholderProps = convertToPlaceholderProps(props);

      const arrowIcon = getArrowIcon();

      return (
        <MainInputContainer ref={outerRef} isDisabled={disabled}>
          <DefaultInnerInputContainer>
            <InnerInputComponent
              ref={innerInputRef}
              commonProps={{
                disabled,
                readOnly: !isSearchable,
                name: `dropdown-selected-item-input-${name}`,
                dataTestId: `dropdown-selected-item-input-${dataTestId}`,
                autoFocus,
                colorScheme,
                textColorScheme,
                ...placeholderProps,
              }}
              inputSpecificProps={inputProps}
              textAreaSpecificProps={textAreaProps}
            />
            {arrowIcon}
          </DefaultInnerInputContainer>
        </MainInputContainer>
      );
    }

    const getLowerCasedSearchTerm = (filterOptionsState: FilterOptionsState<string>): string | undefined => {
      if (!freeSolo) {
        // filterOptionsState.inputValue has a value only when the user currently inputs a value to the dropdown
        // So only when there a value of filterOptionsState.inputValue we should filter the options
        // AKA the user is actively trying to set a new value
        return filterOptionsState.inputValue.toLowerCase();
      }

      return value?.toLowerCase();
    };

    function filterOptionsBySearchValue(options: string[], filterOptionsState: FilterOptionsState<string>): string[] {
      const lowerCasedSearch = getLowerCasedSearchTerm(filterOptionsState);
      if (!isSearchable || !lowerCasedSearch) {
        return options;
      }

      return options.filter((optionKey) => {
        const item = childrenByKeys[optionKey];

        const keywords: string[] = item.props.keywords ?? [item.props.textWhenSelected, item.props.value];

        return keywords?.find((keyword) => keyword.toString().toLowerCase().includes(lowerCasedSearch));
      });
    }

    return (
      <Autocomplete
        freeSolo={freeSolo}
        key={`${languageStore.selectedLanguageKey}-${languageStore.isLanguageLoading}-btn-dropdown-open-${name}`}
        id={name}
        data-testid={dataTestId}
        ref={ref}
        selectOnFocus
        openOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={filterOptionsBySearchValue}
        disabled={disabled}
        classes={{ ...classes, paper: popupWidth ? popupWidthClassNameWithWidth(popupWidth) : undefined }}
        value={value ?? null}
        onChange={(event, selectedOption: string | undefined | null): void => {
          onChange?.(selectedOption ?? undefined);
        }}
        onInputChange={(event, value): void => {
          freeSolo && onChange?.(value);
        }}
        PopperComponent={DropdownPopperWrapperWithTestId(
          `dropdown-all-items-${name}`,
          `dropdown-all-items-${dataTestId}`,
          popupPlacement,
        )}
        options={Object.keys(childrenByKeys)}
        getOptionLabel={(dropdownItemOrFreeText: string): string => {
          return t(childrenByKeys[dropdownItemOrFreeText]?.props.textWhenSelected) || dropdownItemOrFreeText;
        }}
        renderOption={(option: string): ReactNode => childrenByKeys[option]}
        renderInput={renderInput}
        className={className}
      />
    );
  }),
);

export default NakedDropdown;

const MainInputContainer = styled.div<{ isDisabled: boolean }>`
  cursor: ${(p): string => (p.isDisabled ? 'default' : 'pointer')};
`;

const DefaultInnerInputContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
`;

const StyledSVG = styled(SVG)`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  fill: var(--action-color, var(--white));
`;

const popupWidthClassNameWithWidth = (width: CSSProperties['width']): string => css`
  width: ${width} !important;
  min-width: 250px;
`;

function DropdownPopperWrapperWithTestId(id: string, dataTestId: string, popupPlacement: PopupPlacement): FC<PopperProps> {
  const DropdownPopperWrapper: FC<PopperProps> = ({ children, placement: placementToIgnore, ...popperProps }) => {
    return (
      <PopupBase placement={popupPlacement} id={id} data-testid={dataTestId} {...popperProps}>
        <ClickEventPropagationBlocker>{children}</ClickEventPropagationBlocker>
      </PopupBase>
    );
  };

  return DropdownPopperWrapper;
}
