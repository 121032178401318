import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import DesertImage from '@app/images/desert.svg';
import SVG from '@app/components/SVG';

const NotFoundErrorPage = (): ReactElement => {
  return (
    <Container>
      <SVG accessibilityLabel='' image={DesertImage} data-testid='desert-icon' />
      <TitleText>There&apos;s nothing here</TitleText>
      <Link to='/'>
        <SubText>Let&apos;s go home!</SubText>
      </Link>
    </Container>
  );
};

export default NotFoundErrorPage;

const Container = styled.div`
  color: black;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: -webkit-center;
  color: gray;
  background: white;

  svg {
    color: rgba(248, 100, 255, 0.82);
  }
`;

const TitleText = styled.span`
  font-size: 70px;
  padding: 0;
  margin: 0;
`;

const SubText = styled.span`
  font-size: 40px;
  color: inherit;
`;
