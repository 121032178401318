import React, { useContext } from 'react';
import { FormInputProps } from '@app/utils/form/form';
import { Switch } from '@material-ui/core';
import { ForwardingFC } from '@app/domain/technicals/components';
import { SwitchProps } from '@material-ui/core/Switch/Switch';
import styled from '@emotion/styled';
import FormContext from '@app/components/inputs/FormContext';

interface Props extends FormInputProps<boolean, false>, Omit<SwitchProps, 'onChange' | 'value' | 'ref' | 'checked'> {
  name: string;
  label?: string;
}

const Toggle: ForwardingFC<HTMLInputElement, Props> = React.forwardRef(
  ({ name, label, value, onChange, disabled: disabledProp, ref: refToIgnore, accessibilityLabel, ...rest }, ref) => {
    const { disabled: disabledForm } = useContext(FormContext);
    const disabled = disabledProp || disabledForm;

    return (
      <RowDiv
        disabled={disabled}
        onClick={(): void => {
          onChange?.(!value);
        }}
      >
        <Switch
          color='primary'
          disableRipple
          name={name}
          checked={value}
          inputRef={ref}
          disabled={disabled}
          aria-label={accessibilityLabel}
          {...rest}
        />
        {label && <div>{label}</div>}
      </RowDiv>
    );
  },
);

const RowDiv = styled.div<{ disabled: boolean | undefined }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: ${(p): string => (p.disabled ? 'default' : 'pointer')};

  ${(p): string | undefined => {
    if (p.disabled) {
      return 'pointer-events: none;' + 'color: rgba(0, 0, 0, 0.38);';
    }
  }};
`;

export default Toggle;
