import React, { FC } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import SearchOvalIcon from '@app/images/search-oval.svg';
import SVG from '@app/components/SVG';
import { Regular18TransparentBlack900 } from '@app/components/Text';
import Button from '@app/components/Button';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';

interface Props {
  createNewVerificationReminder(): void;
}

const EmptyResults: FC<Props> = ({ createNewVerificationReminder }) => {
  const { permissionsStore } = useInfraStores<MorteeMode>();

  return (
    <MainCard>
      <SVG accessibilityLabel='' image={SearchOvalIcon} width={72} height={72} />
      <MiddleText>No Pending Verifications</MiddleText>
      {permissionsStore.isIPSViewMyAccountsEnable && (
        <Button id='btn-ips-verification-reminder-table-create-new' onClick={createNewVerificationReminder}>
          NEW VERIFICATION REMINDER
        </Button>
      )}
    </MainCard>
  );
};

export default EmptyResults;

const MainCard = styled(Card)`
  height: max(270px, min(100vh - 275px, 550px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:not(:first-child) {
    margin-top: 20px;
  }
`;

const MiddleText = styled(Regular18TransparentBlack900.div)`
  text-align: center;
`;
