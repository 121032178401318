import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';
import { convertMomentToTimestamp } from '@app/utils/timeUtils';
import { FormInputProps } from '@app/utils/form/form';
import styled from '@emotion/styled';
import ValueWithPlaceholder from '@app/components/inputs/ValueWithPlaceholder';
import { ColorScheme } from '@app/domain/theme';
import FormInputsContext from '@app/components/inputs/FormInputsContext';
import useColorScheme from '@app/hooks/useColorScheme';
import { ForwardingFC } from '@app/domain/technicals/components';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { BodyRegularStartTransparentBlack900Important } from '@app/components/Text';

type Props = FormInputProps<number, true> & {
  id: string;
  dataTestId?: string;
  disabled?: boolean;
  colorScheme?: ColorScheme;
  className?: string;
  disableFutureDates?: boolean;
} & Partial<Omit<DatePickerProps, 'ref'>>;

const NakedCalendarDatePicker: ForwardingFC<HTMLInputElement, Props> = React.forwardRef(
  (props, ref): ReactElement => {
    const { state } = useContext(FormInputsContext);

    const {
      id,
      dataTestId = id,
      value,
      onChange,
      disabled: disabledProp,
      colorScheme: propColorScheme,
      placeholderVerticalAlign,
      accessibilityLabel,
      placeholder,
      placeholderStyle,
      heightType,
      className,
      ref: propRef,
      disableFutureDates = false,
      ...pickerProps
    } = props;

    const colorScheme = useColorScheme(propColorScheme);

    const disabled = disabledProp || state === 'disabled';
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const valueAsMoment = useMemo(() => {
      if (value) {
        return moment.utc(value);
      }
      return null;
    }, [value]);

    const handleValueChange = (newValue: moment.Moment | null): void => {
      if (disabled) {
        return;
      }

      if (!newValue) {
        onChange?.(undefined);
        return;
      }

      const startOfDay = newValue.utc().startOf('day');

      if (disableFutureDates) {
        const today = moment().utc().startOf('day');
        if (startOfDay.isAfter(today)) {
          return;
        }
      }

      onChange?.(convertMomentToTimestamp(startOfDay));
    };

    const placeholderElementId = `placeholder-of-${id}`;

    const disabledDate = (current: moment.Moment | null): boolean => {
      return !!(disableFutureDates && current?.isAfter(moment().endOf('day')));
    };

    return (
      <ValueWithPlaceholder
        className={className}
        colorScheme={colorScheme}
        valueNotEmpty={!!value}
        hasFocusWithin={isOpen}
        placeholder={placeholder}
        placeholderElementId={placeholderElementId}
        placeholderStyle={placeholderStyle}
        placeholderVerticalAlign={placeholderVerticalAlign}
        heightType={heightType}
      >
        <StyledPicker
          id={id}
          data-testid={dataTestId}
          disabled={disabled}
          onOpenChange={(newIsOpen): void => setIsOpen(newIsOpen)}
          format='DD/MM/YYYY'
          placeholder=''
          value={valueAsMoment}
          onChange={(obj): void => handleValueChange(obj)}
          aria-label={accessibilityLabel}
          aria-labelledby={accessibilityLabel ? undefined : placeholderElementId}
          disabledDate={disabledDate}
          {...pickerProps}
        />
      </ValueWithPlaceholder>
    );
  },
);

export default NakedCalendarDatePicker;

const StyledPicker = styled(AntdDatePicker)`
  width: 100%;

  & .ant-calendar-picker-input.ant-input {
    border: none !important;
    margin-top: 4px;
    height: auto;
    padding: 0;
    ${BodyRegularStartTransparentBlack900Important.css};
  }

  & .ant-calendar-picker-icon {
    display: none;
  }
`;
