import FileSaver from 'file-saver';
import { dataURIToBlob } from '@app/utils/utils';

export function downloadResourceByNamedResource(namedResource: NamedResource): void {
  downloadResourceByFileContent(namedResource.resourceData.content, namedResource.resourceData.mediaType, namedResource.name);
}

export function downloadResourceByFileContent(content: string, mediaType: string | null, name: string): void {
  const blob = dataURIToBlob(encodeURI(`data:${mediaType};base64,${content}`));

  FileSaver.saveAs(blob, name);
}
