import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import FileUploadDragger, { UploadFileDraggerProps } from './FileUploadDragger';

interface UploadCardFileDraggerProps extends UploadFileDraggerProps {}

const FileCardUploadDragger: FunctionComponent<UploadCardFileDraggerProps> = (props) => {
  const { className, ...draggerProps } = props;

  return (
    <StyledCard className={className}>
      <FileUploadDragger {...draggerProps} />
    </StyledCard>
  );
};

export default FileCardUploadDragger;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  width: 100%;
`;
