import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InputBoxAppearance } from './inputBox/InputBox';
import { ColorScheme } from '@app/domain/theme';

export interface FormData {
  appearance?: InputBoxAppearance;
  colorScheme?: ColorScheme;
  disabled?: boolean;
  form: WrappedFormUtils;
  showErrors?: 'none' | 'all' | 'onTouch';
  setShowErrors(showErrors: FormData['showErrors']): void;
}

const FormContext = React.createContext<FormData>({} as FormData);

export default FormContext;
