import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { ButtonProps } from '@app/components/Button';
import { Regular14TransparentBlack600, Regular18TransparentBlack900 } from '@app/components/Text';
import MorteeMode from '@mortee/morteeMode';
import validationSystemServices from '@mortee/services/validationSystemServices';
import EditValidationRecordForm from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordForm';
import useLoadable from '@app/hooks/loadable/useLoadable';
import {
  NULL_ORG,
  SupplierValidationRecord,
  SupplierValidationRecordStatusSubsets,
  transformSupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import useInfraStores from '@app/hooks/useInfraStores';
import Loader from '@app/components/Loader';
import validationRecordIcon from '@mortee/images/validationSystem/validationRecordIcon.svg';
import IconAndText from '@mortee/components/IconAndText';
import { LoadableCreator } from '@app/utils/Loadable';
import { showYesNoModalAsync } from '@app/components/Modal';
import { NextAndPreviousProps } from '@app/components/PreviousAndNextArrows';
import BigModal from '@app/components/BigModal';
import CopyableValue from '@app/components/CopyableValue';
import ClickOutsideDetector from '@app/components/popup/ClickOutsideDetector';
import { isDefined } from '@app/utils/utils';
import { MainTabs } from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordMainTabs';
import { SecondaryTabs } from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/EditValidationRecordSecondaryTabs';
import SeparatorLine from '@app/components/SeparatorLine';
import InlinePreviousAndNextArrows from '@app/components/InlinePreviousAndNextArrows';

type EnumValue<T> = T[keyof T];

interface Props {
  staticId: string;
  onPreviousRecordClick: NextAndPreviousProps['onPreviousClick'];
  onNextRecordClick: NextAndPreviousProps['onNextClick'];
  onClose(isSaved: boolean): void;
}

const INITIAL_MAIN_TAB = MainTabs.generalInfo;

const EditValidationRecordModal: FC<Props> = observer((props) => {
  const { userStore } = useInfraStores<MorteeMode>();

  const [selectedMainTab, setSelectedMainTab] = useState<EnumValue<typeof MainTabs> | null>(null);
  const [selectedSecondaryTab, setSelectedSecondaryTab] = useState<EnumValue<typeof SecondaryTabs>>(SecondaryTabs.comments);

  const isAnyFormSavedRef = useRef<boolean>(false);
  const isCurrentFormUnsaved = useRef<boolean>(false);

  const currentStaticIdRef = useRef<string>(props.staticId);
  // We need the static id as ref because the static id from the props does not update to the latest value (due to closure)
  currentStaticIdRef.current = props.staticId;

  const { staticId, onPreviousRecordClick, onNextRecordClick, onClose } = props;

  const [recordLoadable, reloadRecord, setRecordLoadable] = useLoadable(async () => {
    const serverResponse = await validationSystemServices.getValidationRecordByStaticId(staticId);
    return transformSupplierValidationRecord(serverResponse, userStore.user?.id);
  }, [staticId]);

  async function onCancel(): Promise<void> {
    if (await checkCanCloseRecordForm()) {
      onClose(isAnyFormSavedRef.current);
    }
  }

  function wrapMoveClickWithQuestionBefore(moveAction: ButtonProps['onClick'] | undefined): ButtonProps['onClick'] | undefined {
    if (moveAction) {
      return async (...args) => {
        if (await checkCanCloseRecordForm()) {
          moveAction(...args);
        }
      };
    }
  }

  async function checkCanCloseRecordForm(): Promise<boolean> {
    if (isCurrentFormUnsaved.current) {
      return await showYesNoModalAsync(
        'This form has unsaved changes',
        `Leave editing record ${recordLoadable.result?.presentationId}?`,
        'Leave Without Saving',
        'Stay',
      );
    }

    return true;
  }

  async function onChangeMainTab(newTab: MainTabs): Promise<void> {
    if (await checkCanChangeTab()) {
      // We left the tab so there is no unsaved form data
      isCurrentFormUnsaved.current = false;
      setSelectedMainTab(newTab);
    }
  }

  async function checkCanChangeTab(): Promise<boolean> {
    if (isCurrentFormUnsaved.current) {
      return await showYesNoModalAsync('This tab has unsaved changes', `Leave editing tab`, 'Leave Without Saving', 'Stay');
    }

    return true;
  }

  function onSaved(
    updatedRecordStaticId: string,
    updatedRecord: SupplierValidationRecord | undefined,
    userWantsToClose: boolean,
  ): void {
    isAnyFormSavedRef.current = true;

    if (updatedRecordStaticId !== currentStaticIdRef.current) {
      return;
    }

    if (userWantsToClose) {
      onClose(true);
      return;
    }

    if (updatedRecord) {
      setRecordLoadable(LoadableCreator.resolved(updatedRecord));
    } else {
      reloadRecord.silent();
    }
  }

  useEffect(() => {
    if (recordLoadable.isResolved() && selectedMainTab === null) {
      setSelectedMainTab(getMainTabByRecordData(recordLoadable.result));
    }
  }, [recordLoadable, selectedMainTab]);

  function getMainTabByRecordData(record: SupplierValidationRecord): MainTabs {
    if (SupplierValidationRecordStatusSubsets.shouldOpenEditModalOnVerificationTab.includes(record.status.value)) {
      return MainTabs.verification;
    }

    return INITIAL_MAIN_TAB;
  }

  const title = (
    <Title>
      <TitleLeft>
        {recordLoadable.resolve(
          (record) => (
            <>
              <TitlePresentationText>
                <IconAndText image={validationRecordIcon} iconAccessibilityLabel='validation record icon'>
                  {record.presentationId}
                </IconAndText>
              </TitlePresentationText>
              <SeparatorLine vertical />
              <TitleSupplierAndOrganizationText>
                <CopyableValue dataTestId='supplierName-copyable' label='Supplier name' value={record.supplierName}>
                  {record.supplierName}
                </CopyableValue>
                <Regular14TransparentBlack600.div>
                  Referring customer -{' '}
                  {record.organization?.name ? (
                    <CopyableValue dataTestId='customerName-copyable' label='Customer name' value={record.organization?.name} />
                  ) : (
                    NULL_ORG.name
                  )}
                </Regular14TransparentBlack600.div>
              </TitleSupplierAndOrganizationText>
            </>
          ),
          () => (
            <>Loading...</>
          ),
        )}
      </TitleLeft>
      <StyledPreviousAndNextArrows
        onPreviousClick={wrapMoveClickWithQuestionBefore(onPreviousRecordClick)}
        previousItemText='Previous Record'
        onNextClick={wrapMoveClickWithQuestionBefore(onNextRecordClick)}
        nextItemText='Next Record'
        disableButtons={!recordLoadable.isResolved()}
      />
    </Title>
  );

  return (
    <div data-testid='edit-validation-record-modal'>
      <ClickOutsideDetector onClickOutside={onCancel} preventOutsideClick>
        <StyledModal dataTestId='edit-validation-record-modal' title={title} closeFunc={onCancel}>
          <SeparatorLine />
          {recordLoadable.resolve(
            (record) => (
              <ExpandedEditValidationRecordForm
                key={record.staticId}
                record={record}
                selectedMainTab={{ value: selectedMainTab ?? INITIAL_MAIN_TAB, onChange: onChangeMainTab }}
                selectedSecondaryTab={{ value: selectedSecondaryTab, onChange: setSelectedSecondaryTab }}
                onSaved={(updatedRecord, userWantsToClose): void => onSaved(staticId, updatedRecord, userWantsToClose)}
                onIsFormUnsavedChanged={(isFormUnsaved): void => {
                  if (isDefined(isFormUnsaved)) {
                    isCurrentFormUnsaved.current = isFormUnsaved;
                  }
                }}
              />
            ),
            () => (
              <Loader spinning transparent />
            ),
          )}
        </StyledModal>
      </ClickOutsideDetector>
    </div>
  );
});

export default EditValidationRecordModal;

const ExpandedEditValidationRecordForm = styled(EditValidationRecordForm)`
  flex: 1;
  overflow-y: auto;
`;

const StyledModal = styled(BigModal)`
  width: min(2100px, 90vw);
  height: 95vh;
  position: relative;
  box-shadow: var(--box-shadow-3);
  padding: 0;

  display: flex;
  flex-direction: column;

  --side-padding: 24px;
`;

const Title = styled(Regular18TransparentBlack900.span)`
  flex: 1;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleLeft = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TitlePresentationText = styled.span`
  padding-inline-end: var(--side-padding);
  display: inline-flex;
  align-items: center;

  flex: 0 0 auto;
`;

const TitleSupplierAndOrganizationText = styled.div`
  padding-inline: var(--side-padding);
`;

const StyledPreviousAndNextArrows = styled(InlinePreviousAndNextArrows)`
  align-self: flex-start;
  flex: 0 0 auto;
  justify-content: flex-end;
  align-items: baseline;
  margin-right: 30px;
`;
