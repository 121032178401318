import React, { FC } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { getSvmInstructionType, renderSvmInstructionType } from '@mortee/domain/validationSystemInfoComponents';
import NakedFormSearchInput from '@app/components/NakedFormSearchInput';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import useSearchFilter from '@app/hooks/useSearchFilter';
import {
  SupplierValidationRecordInstructionTypesSubsets,
  SupplierValidationRecordInstructionType,
} from '@mortee/domain/validationSystem';

interface Props {
  onChange(instructionType: SupplierValidationRecordInstructionType | undefined): void;
  onClose(): void;
  className?: string;
}

const SelectInstructionsInput: FC<Props> = (props) => {
  const { onChange, onClose, className } = props;

  const [filteredData, searchTerm, setSearchTerm] = useSearchFilter(
    SupplierValidationRecordInstructionTypesSubsets.svmManualInstructionType,
    {
      partialMatches(searchItem) {
        return [getSvmInstructionType(searchItem)?.name ?? ''];
      },
    },
  );

  function handleInstructionType(instruction: SupplierValidationRecordInstructionType | undefined): void {
    onChange(instruction);
    onClose();
  }

  return (
    <Container className={className} shadowType='elevated'>
      <RemoveChoiceButton
        onClick={(): void => handleInstructionType(undefined)}
        id={'btn-remove-manual-instruction'}
        appearance='text'
        cornerType='none'
        colorScheme='grey'
      >
        Remove Manual Instruction
      </RemoveChoiceButton>
      <Separator />
      <SearchInputBox appearance='corners'>
        <NakedFormSearchInput
          name='inpt-instruction-selector-search'
          type='text'
          placeholder='Search'
          placeholderStyle='onlyWhenEmpty'
          heightType='ultra-thin'
          clearable
          value={searchTerm}
          onChange={(newValue): void => setSearchTerm(newValue ?? '')}
        />
      </SearchInputBox>
      {filteredData.map((instruction) => (
        <Button
          key={instruction}
          id={`btn-set-instruction-${instruction}`}
          appearance='text'
          cornerType='none'
          colorScheme='grey'
          onClick={(): void => handleInstructionType(instruction)}
        >
          {renderSvmInstructionType(instruction)}
        </Button>
      ))}
    </Container>
  );
};

export default SelectInstructionsInput;

const Container = styled(Card)`
  --inline-margin: 12px;

  max-height: 500px;
  min-width: 300px;
  overflow-y: auto;
  padding: 6px 0;
  display: grid;
`;

const Separator = styled.hr`
  border-top: solid 1px var(--transparent-black-200);
  margin: 8px var(--inline-margin);
`;

const SearchInputBox = styled(InputBox)`
  margin: var(--inline-margin) 10px;
`;

const RemoveChoiceButton = styled(Button)`
  color: #db3a32;
`;
