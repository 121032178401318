import React, { ReactElement, ReactNode, useState } from 'react';
import ButtonThatOpensPopup from '@app/components/popup/ButtonThatOpensPopup';
import StatusSelector from '@mortee/routes/validationSystem/statusEdit/StatusSelector';
import {
  CancelReason,
  SupplierValidationRecordStatus,
  SupplierValidationRecordStatusObject,
} from '@mortee/domain/validationSystem';
import { FormInputProps } from '@app/utils/form/form';
import { ForwardingFC } from '@app/domain/technicals/components';
import { validationRecordStatusWithArrowSpecialComponents } from '@mortee/domain/validationSystemInfoComponents';
import CancelReasonSelector from '@mortee/routes/validationSystem/statusEdit/CancelReasonSelector';
import styled from '@emotion/styled';
import TooltipWithFourCorners from '@app/components/TooltipWithFourCorners';
import SVG from '@app/components/SVG';
import CommentImage from '@app/images/comment.svg';
import { observer } from 'mobx-react';
import { PopupPlacement } from '@app/components/popup/PopupBase';

type Props = FormInputProps<SupplierValidationRecordStatusObject, false> & {
  id: string;
  options?: SupplierValidationRecordStatus[];
  dataTestId?: string;
  disabled: boolean;
  className?: string;
};

enum PopupView {
  selectStatus = 'selectStatus',
  selectCancelReason = 'selectCancelReason',
}

const SelectStatusInput: ForwardingFC<HTMLButtonElement, Props> = observer(
  React.forwardRef(
    ({ id, dataTestId = id, options, value: currentStatus, onChange, accessibilityLabel, disabled, className }, ref) => {
      const [currentPopupView, setCurrentPopupView] = useState<PopupView>(PopupView.selectStatus);

      function handleSelectStatus(selectedStatus: SupplierValidationRecordStatus, onDone: () => void): void {
        if (selectedStatus === SupplierValidationRecordStatus.canceled) {
          setCurrentPopupView(PopupView.selectCancelReason);
        } else {
          onChange?.({ value: selectedStatus, cancelReason: null });
          onDone();
        }
      }

      function handleCancelReasonSelection(selectedReason: CancelReason, onDone: () => void): void {
        onChange?.({ value: SupplierValidationRecordStatus.canceled, cancelReason: selectedReason });
        onDone();
      }

      function getPlacement(): PopupPlacement {
        switch (currentPopupView) {
          case PopupView.selectStatus: {
            return 'bottom';
          }
          case PopupView.selectCancelReason: {
            return 'bottom-left';
          }
        }
      }

      function renderPopupContent(onDone: () => void): ReactElement {
        switch (currentPopupView) {
          case PopupView.selectStatus: {
            return (
              <StatusSelector
                options={options}
                onSelect={(supplierValidationRecordStatusObject): void =>
                  handleSelectStatus(supplierValidationRecordStatusObject, onDone)
                }
              />
            );
          }
          case PopupView.selectCancelReason:
            return (
              <CancelReasonSelector
                initialValue={currentStatus?.cancelReason ?? undefined}
                onSelect={(reason): void => handleCancelReasonSelection(reason, onDone)}
                onNotSelect={(): void => onDone()}
              />
            );
        }
      }

      return (
        <Row className={className}>
          <ButtonThatOpensPopup
            id={id}
            dataTestId={dataTestId}
            appearance='text'
            cornerType='circle'
            colorScheme='grey'
            shadowType='none'
            popupKey={currentPopupView}
            popupPlacement={getPlacement()}
            aria-label={
              currentStatus
                ? `${validationRecordStatusWithArrowSpecialComponents.text(currentStatus?.value)} - ${accessibilityLabel}`
                : accessibilityLabel
            }
            popupContent={(onDone): ReactNode => renderPopupContent(onDone)}
            onClosed={(): void => {
              setCurrentPopupView(PopupView.selectStatus);
            }}
            ref={ref}
            disabled={disabled}
          >
            {validationRecordStatusWithArrowSpecialComponents.render(currentStatus?.value) ?? 'Unknown'}
          </ButtonThatOpensPopup>
          {currentStatus?.cancelReason && (
            <DefaultCursor>
              <TooltipWithFourCorners
                title={
                  <div data-testid='supplier-validation-management-table-cancel-reason-text'>
                    {currentStatus.cancelReason.reason}
                  </div>
                }
                placement='top'
              >
                <SVGThatDoesNotShowFocus
                  image={CommentImage}
                  accessibilityLabel='Cancel Reason'
                  // Make it focusable for accessibility
                  tabIndex={0}
                  data-testid='supplier-validation-management-table-cancel-reason-icon'
                />
              </TooltipWithFourCorners>
            </DefaultCursor>
          )}
        </Row>
      );
    },
  ),
);

export default SelectStatusInput;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const DefaultCursor = styled.div`
  cursor: default;
`;

const SVGThatDoesNotShowFocus = styled(SVG)`
  &:focus:not(:focus-visible) {
    outline: none;
  }

  --icon-width-padding: 3px;
  --icon-height-padding: 8px;
  --icon-width: 17px;
  --icon-height: 16px;

  width: calc(var(--icon-width) + 2 * var(--icon-width-padding));
  height: calc(var(--icon-height) + 2 * var(--icon-height-padding));

  padding: var(--icon-height-padding) var(--icon-width-padding);
`;
