import React, { FC, ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import config from '@app/config';
import NotFoundErrorPage from './/NotFoundErrorPage';
import ErrorBoundary from '@app/components/ErrorBoundary';
import { observer } from 'mobx-react';
import { isDefined } from '@app/utils/utils';
import useInfraStores from '@app/hooks/useInfraStores';
import {
  GOOGLE_SSO_LOGIN_MODE_PATH,
  OIDC_SSO_LOGIN_MODE_PATH_PREFIX,
  SAML_SSO_LOGIN_MODE_PATH_PREFIX,
} from '@app/login/domain/sso';

const ErrorBoundaryRoute = ({ component: Component, ...rest }): ReactElement => (
  <Route
    {...rest}
    render={(props): ReactElement => (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    )}
  />
);

const Routers: FC = observer(() => {
  const { navigationStore, permissionsStore } = useInfraStores();
  const { initialRoutePath } = navigationStore;
  const { allowedRoutesByKey } = permissionsStore;

  const renderRoutes = (allowedRoutes: FlatRoute[]): ReactElement[] => {
    return allowedRoutes.map((r) => <ErrorBoundaryRoute key={r.id} exact path={r.path} component={r.component} />);
  };

  const renderRouteRedirects = (allowedRoutes: FlatRoute[]): ReactElement[] => {
    return allowedRoutes.flatMap((r) =>
      r.redirectFromPaths.map((redirectPath) => (
        <Redirect
          key={r.id + redirectPath}
          exact
          from={redirectPath}
          to={{ pathname: r.path, state: { innerNsknoxRedirect: true } }}
        />
      )),
    );
  };

  const allowedRoutes = Object.values(allowedRoutesByKey).filter(isDefined);

  return (
    <Switch>
      {renderRoutes(allowedRoutes)}
      {renderRouteRedirects(allowedRoutes)}
      <Route
        exact
        strict
        path={config.urlPath || '/'}
        render={(): ReactElement => <Redirect to={config.urlPath + initialRoutePath} />}
      />
      <Route exact strict path='/home' render={(): ReactElement => <Redirect to={config.urlPath + initialRoutePath} />} />
      <Route
        exact
        strict
        path={GOOGLE_SSO_LOGIN_MODE_PATH}
        render={(): ReactElement => <Redirect to={config.urlPath + initialRoutePath} />}
      />
      <Route
        exact
        strict
        path={`${SAML_SSO_LOGIN_MODE_PATH_PREFIX}/*`}
        render={(): ReactElement => <Redirect to={config.urlPath + initialRoutePath} />}
      />
      <Route
        exact
        strict
        path={`${OIDC_SSO_LOGIN_MODE_PATH_PREFIX}/*`}
        render={(): ReactElement => <Redirect to={config.urlPath + initialRoutePath} />}
      />
      <Route component={NotFoundErrorPage} />
    </Switch>
  );
});

export default Routers;
