import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { PrivatePayeeAccountWithDeterministicValidation } from '@mortee/domain/masterDataGuard';
import ValidationLevelIcon from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/ValidationLevelIcon';
import {
  getRepresentationNameWithBIC,
  isAccountDetailsPartial,
  splitAccountDetailsIntoRepresentationsMap,
} from '@app/domain/accountDetailsRepresentations';
import CategoryAndValues from '@app/components/CategoryAndValues';
import { css } from '@emotion/css';
import { calcRepresentationsToActuallyDisplay } from '@app/utils/deterministicValidationUtils';
import { ButtonSmallCenter } from '@app/components/Button';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import MorteePermissionStore from '@mortee/stores/infraStores/MorteePermissionStore';
import PrivateAccountInitiateSelfApproveModal from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/PrivateAccountInitiateSelfApproveModal';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import useInfraStores from '@app/hooks/useInfraStores';
import { getRepresentationFormatterWithSwiftban } from '@app/domain/accountDetailsFormatters';
import useUpdatingModal from '@app/hooks/useUpdatingModal';
import { PrivatePayee } from '@mortee/domain/privatePayee';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import {
  PrivatePayeeAccount,
  RemoveSelfApproveRequest,
  SelfApprovePrivatePayeeAccountRequest,
} from '@mortee/domain/privatePayeeAccount';
import { cleanNoSwiftSuffixAccountDetails } from '@app/domain/accountsDetailsHelpers';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

interface Props {
  privatePayee: PrivatePayee;
  accountWithDV: PrivatePayeeAccountWithDeterministicValidation;
  onAccountUpdated: () => Promise<void>;
  className?: string;
}

const PrivateAccountRepresentationsWithDV: FunctionComponent<Props> = observer(
  ({ privatePayee, accountWithDV, onAccountUpdated, className }) => {
    const { permissionsStore } = useInfraStores<MorteeMode>();
    const { masterDataGuardStore } = useAppStores<MorteeMode>();
    const modalContext = useModalContext();

    const { deterministicValidation, privatePayeeAccount } = accountWithDV;

    const representationsToDisplay = calcRepresentationsToActuallyDisplay(deterministicValidation.validationRepresentations);

    // Add invalid representation to display
    if (!representationsToDisplay.length) {
      const { accountDetails } = privatePayeeAccount;

      const allWithPartialRepresentations = splitAccountDetailsIntoRepresentationsMap(
        { ...accountDetails, localFormat: null },
        true,
      );

      representationsToDisplay.push(
        ...[...allWithPartialRepresentations.entries()].map(([repType, representation]) => ({
          type: repType,
          formattedRepresentation: getRepresentationFormatterWithSwiftban(repType)(representation),
          validationLevel: DeterministicValidationResultType.notValidated,
          warningText: null,
        })),
      );
    }

    const [, setModalVisible] = useUpdatingModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <PrivateAccountInitiateSelfApproveModal
            privatePayee={privatePayee}
            privatePayeeAccount={privatePayeeAccount}
            deterministicValidation={deterministicValidation}
            onSubmit={onInitiateSelfApprove}
            onRemove={onRemoveSelfApprove}
            onClose={onDone}
            permissions={{
              canSelfApprove: permissionsStore.isInitiateSelfApproveEnable,
              canRemoveSelfApprove: permissionsStore.isInitiateRemoveSelfApproveEnable,
            }}
          />
        </ModalAppContext>
      ),
      {
        maskClosable: false,
      },
      [
        privatePayee,
        privatePayeeAccount,
        deterministicValidation,
        permissionsStore.isInitiateSelfApproveEnable,
        permissionsStore.isInitiateRemoveSelfApproveEnable,
      ],
    );

    const onInitiateSelfApprove = async (
      filesToUpload: File[],
      request: SelfApprovePrivatePayeeAccountRequest,
    ): Promise<void> => {
      await masterDataGuardStore.storeInitiateSelfApprove(
        privatePayee.uniformId,
        privatePayeeAccount.uniformId,
        filesToUpload,
        request,
      );
      await onAccountUpdated();
    };

    const onRemoveSelfApprove = async (request: RemoveSelfApproveRequest): Promise<void> => {
      await masterDataGuardStore.storeRemoveSelfApprove(privatePayee.uniformId, privatePayeeAccount.uniformId, request);
      await onAccountUpdated();
    };

    const showValidationHistory = deterministicValidation.validationLevel === DeterministicValidationResultType.selfApproved;

    const canInitiateSelfApprove = canUserInitiateSelfApprove(
      permissionsStore,
      privatePayeeAccount,
      deterministicValidation.validationLevel,
    );

    return (
      <LayoutContainer className={className}>
        <RepresentationsList>
          {representationsToDisplay.map((representation) => (
            <RepresentationLine key={representation.type}>
              <ValidationLevelIcon size='small' validationLevel={representation.validationLevel} />
              <RepresentationCategoryAndValues
                key={representation.type}
                data-testid={`private-payee-account-${representation.type}`}
                label={getRepresentationNameWithBIC(representation.type)}
                labelClassName={RepresentationCategoryAndValuesLabelClass}
                inlineLabel
              >
                {representation.formattedRepresentation}
              </RepresentationCategoryAndValues>
              {representation.warningText && <RepresentationWarningChip>{representation.warningText}</RepresentationWarningChip>}
            </RepresentationLine>
          ))}
        </RepresentationsList>
        <ValidationActions>
          {(canInitiateSelfApprove.visible || showValidationHistory) && (
            <ConditionalTooltip
              showTooltip={!canInitiateSelfApprove.enabled && !showValidationHistory}
              title='Self-validation is not available due to partial bank account data'
            >
              <ButtonSmallCenter
                id='master-data-guard-show-validation'
                appearance={canInitiateSelfApprove.visible ? 'full' : 'text'}
                disabled={!canInitiateSelfApprove.enabled && !showValidationHistory}
                onClick={(): void => setModalVisible(true)}
              >
                {canInitiateSelfApprove.visible ? 'INITIATE SELF VALIDATION' : 'VALIDATION DETAILS'}
              </ButtonSmallCenter>
            </ConditionalTooltip>
          )}
        </ValidationActions>
        <VerticalSeparator />
        <OverallDVStatus data-testid={`private-payee-account-dv-${deterministicValidation.validationLevel}`}>
          <ValidationLevelIcon size='big' showText validationLevel={deterministicValidation.validationLevel} />
        </OverallDVStatus>
      </LayoutContainer>
    );
  },
);

export default PrivateAccountRepresentationsWithDV;

const canUserInitiateSelfApprove = (
  permissionsStore: MorteePermissionStore,
  privatePayeeAccount: PrivatePayeeAccount,
  deterministicValidationLevel: DeterministicValidationResultType,
): { visible: boolean; enabled: boolean } => {
  const visible =
    deterministicValidationLevel === DeterministicValidationResultType.notValidated &&
    permissionsStore.isInitiateSelfApproveEnable;

  const enabled =
    visible &&
    !isAccountDetailsPartial({ ...cleanNoSwiftSuffixAccountDetails(privatePayeeAccount.accountDetails), localFormat: null });

  return {
    visible,
    enabled,
  };
};

const LayoutContainer = styled.div`
  --box-vertical-buffer: 12px;

  display: flex;
  flex-direction: row;

  line-height: normal;

  padding: var(--box-vertical-buffer) var(--box-horizontal-buffer);
`;

const RepresentationsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex: 1;

  & > *:not(:first-child) {
    margin-top: 5px;
  }
`;

const ValidationActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 4px;
  }
`;

const RepresentationLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OverallDVStatus = styled.div`
  width: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerticalSeparator = styled.div`
  background-color: var(--primary-200-100-a);
  width: 1px;
  margin-right: var(--box-horizontal-buffer);
  margin-left: var(--box-horizontal-buffer);
`;

const RepresentationCategoryAndValues = styled(CategoryAndValues)`
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--black-weak);

  margin-left: 8px;
`;

const RepresentationCategoryAndValuesLabelClass = css`
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--black-strong);
`;

const RepresentationWarningChip = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.09;
  letter-spacing: 0.39px;

  background-color: white;
  color: var(--alert-color);

  max-width: 310px;
  box-shadow: unset;
  border-radius: 9px;
  padding: 4px 8px 0;
  margin-left: 11px;
`;
