import { DependencyList } from 'react';
import useLoadable from '@app/hooks/loadable/useLoadable';
import useInterval from '@app/hooks/useInterval';
import { LoadableResponse } from '@app/hooks/loadable/useManuallyCalledLoadable';

export default function useIntervalRefreshingLoadable<TResult>(
  loadFunc: (abortSignal: AbortSignal) => Promise<TResult>,
  intervalMillis: number,
  enabled: boolean = true,
  dependencies: DependencyList = [],
): LoadableResponse<TResult, []> {
  const [loadable, loadLoadableFunc, setLoadable, currentlyLoadingAbortControllerRef] = useLoadable<TResult>(
    loadFunc,
    dependencies,
  );

  // Initial load should not be canceled, and silent loads should be (and because they are silent, they do not make
  // the loadable in a state of "in progress")
  if (!enabled && !loadable.isInProgress()) {
    currentlyLoadingAbortControllerRef.current?.abort();
  }

  const shouldActuallyRefresh = enabled && loadable.isResolved();

  useInterval(
    async (): Promise<void> => {
      // A request (background refresh or requested by user) is already in process
      if (currentlyLoadingAbortControllerRef.current) {
        return;
      }

      // Don't reload data when the tab is not active, it is not necessary
      if (document.hidden) {
        return;
      }

      await loadLoadableFunc.silent();
    },
    shouldActuallyRefresh ? intervalMillis : null,
  );

  return [loadable, loadLoadableFunc, setLoadable, currentlyLoadingAbortControllerRef];
}
