import { observer, useLocalObservable } from 'mobx-react';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { action, flow } from 'mobx';
import { toWordsOrdinal } from 'number-to-words';
import TextMessageImage from '@app/login/images/text-message.svg';
import PhoneCallImage from '@app/login/images/phone-call.svg';
import CloseImage from '@app/images/ic_close.svg';
import { BoldSubTitleText, SubTitle, Title } from '../Styles';
import { RowSeparator } from '@app/components/Styles';
import { Form } from 'antd';
import styled from '@emotion/styled';
import { confirm, ModalTitle } from '@app/components/Modal';
import SVG from '@app/components/SVG';
import { AuthCodeDeliveryMethod, LoginErrorBody, LoginErrorCodes } from '@app/login/domain/loginConsts';
import * as messageLauncher from '@app/utils/messageLauncher';
import Button from '@app/components/Button';
import Timer from 'react-compound-timer';
import config from '@app/config';
import { FormFieldDecorators } from '@app/utils/form/form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import useAutofocus from '@app/hooks/useAutofocus';

export const TIMER_IS_PLAYING = 'PLAYING';
export const TIMER_IS_INITIALIZED = 'INITED';

interface PhoneLoginEnterCodeProps extends FormComponentProps<EnterCodeFormFields> {
  knoxerId: string;
  numberOfCodeSent: number;
  totalOfCodesRequired: number;
  flowId: string;
  phoneNumber: string;
  deliveryMethod: AuthCodeDeliveryMethod;
  codeLength: number;
  onBack: () => void;
}

interface EnterCodeFormFields {
  code: string;
}

const PhoneLoginEnterCode: FunctionComponent<PhoneLoginEnterCodeProps> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();
  const inputAutofocusRef = useAutofocus<HTMLInputElement>();

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,
  }));

  const verifySentCode = flow(function* (code: string) {
    const { phoneNumber, flowId, knoxerId, onBack, deliveryMethod } = props;

    try {
      yield authenticationStore.claimWithPhoneAndCode(knoxerId, flowId, phoneNumber, code, deliveryMethod);
    } catch (e: unknown) {
      const response = e as LoginErrorBody;

      switch (response.errorCode) {
        case LoginErrorCodes.InvalidCredentials: {
          localStore.inputErrorText = 'Wrong code, try again';
          break;
        }
        case LoginErrorCodes.PhoneAuthFlowExpired: {
          messageLauncher.shootErrorOld('Phone login code process has expired, restarting process');
          onBack();
          break;
        }
      }
    }
  });

  const fieldDecorators: FormFieldDecorators<EnterCodeFormFields> = {
    code: {
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'Enter code',
        },
        {
          len: props.codeLength,
          message: `Code must be ${props.codeLength} digits long`,
        },
        {
          pattern: /^[0-9]+$/,
          message: 'Code must be numeric',
        },
      ],
    },
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleOk = (): void => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    if (authenticationStore.loading) {
      return;
    }

    validateFieldsAndScroll((errors: Object, values: EnterCodeFormFields) => {
      if (errors) {
        return;
      }

      const { code } = values;

      verifySentCode(code);
    });
  };

  const handleCodeInputClick = action(() => {
    const { resetFields } = props.form;

    if (localStore.inputErrorText) {
      resetFields();
      localStore.inputErrorText = null;
    }
  });

  const onCodeReceived = action(() => {
    const { knoxerId, phoneNumber, deliveryMethod } = props;

    const isFirstKnoxer = !authenticationStore.nextUnclaimedKnoxer?.positionInMethodGroup;

    let okText: string;
    let cancelText: string;
    let onCancel: any;
    switch (deliveryMethod) {
      case AuthCodeDeliveryMethod.TextMessage:
        okText = 'RE-SEND TEXT MESSAGE';
        cancelText = 'CALL ME';
        onCancel = (): void => {
          authenticationStore.resendCode(knoxerId, phoneNumber, AuthCodeDeliveryMethod.PhoneCall);
        };
        break;
      case AuthCodeDeliveryMethod.PhoneCall:
        okText = 'Try Again';
        cancelText = 'Keep Waiting';
        break;
    }

    const { destroy } = confirm({
      okText,
      onCancel,
      cancelText,
      content: renderCodeNotReceived(isFirstKnoxer, () => destroy()),
      icon: <span />,
      onOk: () => {
        // Send to enter phone number in the first knoxer and just resend on the others
        if (isFirstKnoxer) {
          return authenticationStore.returnToPhoneSelection();
        }

        authenticationStore.resendCode(knoxerId, phoneNumber, deliveryMethod);
      },
    });
  });

  const modalContext = useModalContext();

  const renderCodeNotReceived = (isFirstKnoxer: boolean, handleClose: () => void): ReactElement<void> => {
    return (
      <ModalAppContext {...modalContext}>
        <ModalContainer>
          <CloseButton
            id='btn-auth-phone-enter-code-not-received-close-modal'
            appearance='text'
            cornerType='none'
            colorScheme='primary'
            onClick={handleClose}
          >
            <SVG accessibilityLabel='close' image={CloseImage} height={18} />
          </CloseButton>
          <ModalTitle>Code not received?</ModalTitle>
          <ModalBody>
            <ModalRow>
              <ModalTextLine>
                {`Please make sure your phone has good reception ${
                  isFirstKnoxer ? 'and that the phone number entered is correct' : ''
                }`}
              </ModalTextLine>
            </ModalRow>
          </ModalBody>
        </ModalContainer>
      </ModalAppContext>
    );
  };

  const renderFooter = (
    numberOfCodeSent: number,
    phoneNumber: string,
    totalOfCodesRequired: number,
    deliveryMethod: AuthCodeDeliveryMethod,
  ): ReactNode => {
    let returnContent: ReactNode;
    let infoText: string;
    let SVGImage: SpriteSymbol;

    switch (deliveryMethod) {
      case AuthCodeDeliveryMethod.PhoneCall:
        infoText = `You are about to receive a call with a code to ${phoneNumber}. Please check your phone for incoming calls.`;
        SVGImage = PhoneCallImage;
        break;
      case AuthCodeDeliveryMethod.TextMessage:
        infoText = `We sent a text message with a code to ${phoneNumber}. Please check your incoming text messages.`;
        SVGImage = TextMessageImage;
        break;
    }

    if (numberOfCodeSent === 0) {
      returnContent = <FooterSubTitle>{infoText}</FooterSubTitle>;
    } else {
      returnContent = (
        <FooterSubTitle>
          You should now receive a <BoldSubtitle>{toWordsOrdinal(numberOfCodeSent + 1)}</BoldSubtitle> code via text message.
        </FooterSubTitle>
      );
    }

    return (
      <FooterContainer>
        <StyledSMSSVG accessibilityLabel='' image={SVGImage} />
        {returnContent}
      </FooterContainer>
    );
  };

  const renderTitle = (deliveryMethod: AuthCodeDeliveryMethod): ReactNode => {
    let verificationText: string;

    switch (deliveryMethod) {
      case AuthCodeDeliveryMethod.TextMessage:
        verificationText = 'Text Message Verification';
        break;
      case AuthCodeDeliveryMethod.PhoneCall:
        verificationText = 'Voice Message Code';
        break;
    }

    return <Title>{verificationText}</Title>;
  };

  const renderSubTitle = (numberOfCodeSent: number, deliveryMethod: AuthCodeDeliveryMethod): ReactNode => {
    let returnContent: ReactNode;
    let verificationText: string;

    switch (deliveryMethod) {
      case AuthCodeDeliveryMethod.PhoneCall:
        verificationText = 'code that was sent via voice message';
        break;
      case AuthCodeDeliveryMethod.TextMessage:
        verificationText = 'code that was sent via text message';
        break;
    }

    if (numberOfCodeSent === 0) {
      returnContent = verificationText;
    } else {
      returnContent = (
        <div>
          <BoldSubTitleText>{toWordsOrdinal(numberOfCodeSent + 1)}</BoldSubTitleText> {verificationText}
        </div>
      );
    }

    return <LoginSubTitle>Enter the&nbsp;{returnContent}</LoginSubTitle>;
  };

  const renderTimerOrButton = (): ReactNode => {
    const { flowId } = props;

    return (
      <Timer
        formatValue={(value): string => `${value < 10 ? `0${value}` : value}`}
        key={flowId}
        initialTime={config.resendLoginCodeTimerDurationSec * 1000}
        direction='backward'
        timeToUpdate={300}
      >
        {({ getTimerState }): ReactNode => {
          if (getTimerState() === TIMER_IS_INITIALIZED || getTimerState() === TIMER_IS_PLAYING) {
            return (
              <StyledTimerDiv>
                <Timer.Minutes />:
                <Timer.Seconds />
              </StyledTimerDiv>
            );
          }

          return (
            <Button
              id='btn-auth-phone-enter-code-not-received-open-modal'
              appearance='text'
              onClick={onCodeReceived}
              size='small'
            >
              CODE NOT RECEIVED?
            </Button>
          );
        }}
      </Timer>
    );
  };

  const { form, numberOfCodeSent, phoneNumber, totalOfCodesRequired, codeLength, onBack, deliveryMethod } = props;

  return (
    <>
      <ContentContainer>
        {renderTitle(deliveryMethod)}
        {renderSubTitle(numberOfCodeSent, deliveryMethod)}
        <EnterCodeForm onSubmit={handleFormSubmit}>
          <FormItemBox
            appearance='line'
            form={form}
            fieldName='code'
            fieldDecoratorOptions={fieldDecorators.code}
            forcedErrorText={localStore.inputErrorText}
          >
            <NakedFormInput
              name='inpt-auth-phone-enter-code'
              type='number'
              ref={inputAutofocusRef}
              colorScheme='secondary'
              placeholder={`Enter ${codeLength} digits code`}
              onMaxLengthAchieved={handleOk}
              maxLength={codeLength}
              onClick={handleCodeInputClick}
              onFocus={handleCodeInputClick}
            />
          </FormItemBox>
          <ActionsContainer>
            <Button id='btn-auth-phone-enter-code-back' appearance='text' type='button' onClick={onBack} size='small'>
              BACK
            </Button>
            {renderTimerOrButton()}
          </ActionsContainer>
        </EnterCodeForm>
      </ContentContainer>
      <RowSeparator />
      {renderFooter(numberOfCodeSent, phoneNumber, totalOfCodesRequired, deliveryMethod)}
    </>
  );
});

export default Form.create<PhoneLoginEnterCodeProps>()(PhoneLoginEnterCode);

const ContentContainer = styled.div`
  padding: 48px 64px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const LoginSubTitle = styled(SubTitle)`
  display: flex;
  flex: 1;
  margin-bottom: 15px;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const FooterContainer = styled.div`
  padding: 16px 72px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`;

const EnterCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalTextLine = styled.div`
  font-size: 15px;
  color: var(--gray-blue-deprecated);
  letter-spacing: 0.1px;
`;

const StyledSMSSVG = styled(SVG)`
  width: 80px;
  height: 80px;
`;

const FooterSubTitle = styled(SubTitle)`
  margin-bottom: 0 !important;
  text-align: inherit;
  margin-left: 20px;
`;

const BoldSubtitle = styled.span`
  font-weight: 900;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
`;

const StyledTimerDiv = styled.div`
  font-size: 12px;
  padding: 10px;
`;
