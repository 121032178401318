import { isDefined } from '@app/utils/utils';
import Color from 'color';
import { InputBoxAppearance } from '@app/components/inputs/inputBox/InputBox';

export interface ColorSchemeObject {
  main: string;
  fullTextColor: string;
  fullHover: string;
  fullActive: string;
}

export function colorSchemeFromMainColor(mainColorString: string): ColorSchemeObject {
  const mainColor = Color(mainColorString);
  return {
    main: mainColorString,
    fullTextColor: decideTextColorBasedOnBackgroundColor(mainColor.isDark()),
    fullHover: mainColor.lighten(0.25).saturate(0.25).hex(),
    fullActive: mainColor.lighten(0.5).saturate(0.25).hex(),
  };
}

export function textColorSchemeFromMainColor(mainColorString: string): ColorSchemeObject {
  const textColor = Color(mainColorString);
  const backgroundColorString = decideTextColorBasedOnBackgroundColor(textColor.isDark());
  const backgroundColor = Color(backgroundColorString);

  return {
    main: backgroundColorString,
    fullTextColor: mainColorString,
    fullHover: backgroundColor.lighten(0.25).saturate(0.25).hex(),
    fullActive: backgroundColor.lighten(0.5).saturate(0.25).hex(),
  };
}

export function decideTextColorBasedOnBackgroundColor(isBackgroundDark: boolean): string {
  return isBackgroundDark ? '#ffffff' : '#000000';
}

export type ColorSchemePresetPredefinedTypes =
  | 'primary'
  | 'primary300'
  | 'secondary'
  | 'secondary300'
  | 'red'
  | 'white'
  | 'grey'
  | 'darkGrey'
  | 'lightBluePrimary'
  | 'lightBlueAccent'
  | 'black'
  | 'purple'
  | 'disabled';

export const colorSchemePresets: { [key in ColorSchemePresetPredefinedTypes]: ColorSchemeObject } = {
  primary: {
    main: '#142361',
    fullTextColor: '#ffffff',
    fullHover: '#0f4c8c',
    fullActive: '#0976b6',
  },
  primary300: {
    main: '#1d378c',
    fullTextColor: '#ffffff',
    fullHover: '#3853ab',
    fullActive: '#0976b6',
  },
  secondary: {
    main: '#03adef',
    fullTextColor: '#ffffff',
    fullHover: '#4fc5f3',
    fullActive: '#81d6f7',
  },
  secondary300: {
    main: '#026288',
    fullTextColor: '#ffffff',
    fullHover: '#127298',
    fullActive: '#3594b9',
  },
  red: {
    main: '#ed524a',
    fullTextColor: '#ffffff',
    fullHover: '#e16963',
    fullActive: '#d77772',
  },
  white: {
    main: '#ffffff',
    fullTextColor: '#03adef',
    fullHover: '#eeeeee',
    fullActive: '#dddddd',
  },
  grey: {
    main: '#666666',
    fullTextColor: '#ffffff',
    fullHover: '#777777',
    fullActive: '#888888',
  },
  darkGrey: {
    main: '#444444',
    fullTextColor: '#ffffff',
    fullHover: '#666666',
    fullActive: '#888888',
  },
  lightBluePrimary: {
    main: '#f5f6fa',
    fullTextColor: '#142361',
    fullHover: '#e7e9ef',
    fullActive: '#cbd0e1',
  },
  lightBlueAccent: {
    main: '#f5f6fa',
    fullTextColor: '#03adef',
    fullHover: '#e7e9ef',
    fullActive: '#cbd0e1',
  },
  black: {
    main: '#000000',
    fullTextColor: '#ffffff',
    fullHover: '#212121',
    fullActive: '#343434',
  },
  purple: {
    main: '#b243ea',
    fullTextColor: '#ffffff',
    fullHover: '#c069ec',
    fullActive: '#d49af1',
  },
  disabled: {
    main: '#909090cc',
    fullTextColor: '#909090cc',
    fullHover: '#909090cc',
    fullActive: '#909090cc',
  },
};

export type ColorScheme = ColorSchemeObject | ColorSchemePresetPredefinedTypes;

export function getColorScheme(...colorScheme: (ColorScheme | undefined)[]): ColorSchemeObject {
  return convertToColorScheme(colorScheme.find(isDefined)) ?? colorSchemePresets.secondary;
}

export function convertToColorScheme(colorScheme: ColorScheme | undefined): ColorSchemeObject | undefined {
  if (typeof colorScheme === 'string') {
    return colorSchemePresets[colorScheme];
  }

  if (colorScheme) {
    return colorScheme;
  }
}

export interface BasicTheme {
  actionColor: ColorSchemeObject;
  inputBoxAppearance: InputBoxAppearance;
  fontFamily?: string;
  backgroundColor?: string;
}

export const defaultTheme: BasicTheme = {
  actionColor: colorSchemePresets.secondary,
  inputBoxAppearance: 'corners',
};
