import React, { ComponentType, ReactElement } from 'react';

type LoadingProps = { loading: true };

export default function withPlaceholder<TDesignProps = {}, TDataProps = {}>(
  RealComponent: ComponentType<TDesignProps & TDataProps>,
  PlaceholderComponent: ComponentType<TDesignProps>,
): ComponentType<(TDesignProps & LoadingProps) | (TDesignProps & TDataProps)> {
  return (props): ReactElement => {
    if (isLoadingProps(props)) {
      return <PlaceholderComponent {...props} />;
    } else {
      return <RealComponent {...props} />;
    }
  };
}

function isLoadingProps<TLoadingProps extends LoadingProps>(props: any): props is TLoadingProps {
  return 'loading' in props && props['loading'];
}
