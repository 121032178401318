import { getUnifiedPayeeMainId } from '@app/domain/validatedPayeeWithAccounts';
import { PrivatePayeeServerResponse, SupplierNumberType, transformCompaniesToCompanyText } from '@mortee/domain/privatePayee';
import {
  LegalEntityIdentifier,
  LegalEntityIdentifierServerResponse,
  StrongLegalIdentifies,
  StrongLegalIdentifiesServerResponse,
  StrongLegalIdentifiesTypes,
} from '@app/domain/legalEntityIdentifier';
import { isDefined, isTruthy } from '@app/utils/utils';
import { distinctValues, mergeArrays } from '@app/utils/arrayUtils';
import { AggregatedValidatedPayeeServerResponse } from '@app/domain/aggregatedValidatedPayee';
import { AccountVerificationPayeeSearchServerResponse } from '@mortee/domain/morteeAccountVerification';
import { SanctionsScreeningInfo } from '@app/domain/validatedPayee';
import { compare } from '@app/utils/comparatorUtils';

export interface UnifiedPayeeIds {
  validatedId?: string;
  privateIds?: string[];
}

export interface UnifiedPayeeName {
  name: string;
  isValidatedPayee: boolean;
}

export interface UnifiedPayeeLegalEntityIdentifier extends LegalEntityIdentifier {
  isValidatedPayee?: boolean;
}

export type UnifiedPayeeStrongLegalIdentifies = Partial<Record<StrongLegalIdentifiesTypes, UnifiedPayeeLegalEntityIdentifier[]>>;

export interface UnifiedPayeePrimaryFields {
  name?: string;
  address?: string;
  legalIdentifier?: UnifiedPayeeLegalEntityIdentifier;
}

export interface UnifiedPayee {
  id: string;
  ids: UnifiedPayeeIds;
  names?: UnifiedPayeeName[];
  addresses: string[];
  emails: string[];
  phones: string[];
  faxes: string[];
  websites: string[];
  description?: string | null;
  legalIdentifiers?: UnifiedPayeeLegalEntityIdentifier[];
  countryCode?: string;
  primaryFields?: UnifiedPayeePrimaryFields | null;
  externalReferenceIds: string[];
  strongLegalIdentifies?: StrongLegalIdentifies;
  supplierNumbers: string[];
  companyCodes: string[];
  sanctionsScreeningInfo?: SanctionsScreeningInfo[];
}

const extractSupplierNumbersFromPrivateRecords = (
  privateRecords: PrivatePayeeServerResponse[],
): LegalEntityIdentifierServerResponse[] => {
  return privateRecords
    ?.map((currRecord) => currRecord.data.supplierNumbers)
    .flat()
    .filter(isTruthy)
    .map((supplierNumber) => ({
      value: supplierNumber,
      typeName: SupplierNumberType,
      typeId: undefined,
      countryCode: undefined,
    }));
};

export function createUnifiedMorteePayeeFromValidatedAndPrivate(
  validatedPayeeData: AggregatedValidatedPayeeServerResponse | undefined | null,
  privateRecords: PrivatePayeeServerResponse[] | undefined,
): UnifiedPayee {
  const validatedId = validatedPayeeData?.uniformId;
  const privateIds = privateRecords?.map((privatePayee) => privatePayee.uniformId);
  const mainId = getUnifiedPayeeMainId(privateIds, validatedId);

  const payeeIds: UnifiedPayeeIds = {
    validatedId,
    privateIds,
  };

  if (validatedPayeeData && privateRecords?.length) {
    const validatedPayeeNames = validatedPayeeData.names?.map((name) => ({ name, isValidatedPayee: true }));
    const privatePayeeNames = [...new Set(privateRecords?.map((currRecord) => currRecord.data.names).flat())]?.map((name) => ({
      name,
      isValidatedPayee: false,
    }));

    return {
      id: mainId,
      ids: payeeIds,
      names: mergeArrays([validatedPayeeNames, privatePayeeNames], true),
      addresses: validatedPayeeData.addresses,
      phones: validatedPayeeData.phones,
      emails: validatedPayeeData.emails,
      websites: validatedPayeeData.websites,
      faxes: validatedPayeeData.faxes,
      description: validatedPayeeData.descriptions?.[0],
      legalIdentifiers: mergeArrays<UnifiedPayeeLegalEntityIdentifier>(
        [
          validatedPayeeData.legalIdentifiers.map((lei) => ({ ...lei, isValidatedPayee: true })),
          [
            ...new Set(privateRecords?.map((currRecord) => currRecord.data.legalIdentifiers).flat()),
            ...new Set(extractSupplierNumbersFromPrivateRecords(privateRecords)),
          ]
            .filter(isDefined)
            .map((lei) => ({ ...lei, isValidatedPayee: false })),
        ],
        true,
      ).filter(isDefined),
      countryCode: validatedPayeeData.countryCode,
      primaryFields: validatedPayeeData.primaryFields,
      externalReferenceIds: privateRecords?.map((currRecord) => currRecord.externalReferenceId),
      strongLegalIdentifies: knoxIdWithoutSuffix(validatedPayeeData.strongLegalIdentifies),
      supplierNumbers: distinctValues(privateRecords?.map((currRecord) => currRecord.data.supplierNumbers ?? []).flat()).filter(
        (number) => number?.length,
      ),
      companyCodes: distinctValues(
        privateRecords?.map((currRecord) => transformCompaniesToCompanyText(currRecord.data.companies)).flat(),
      )
        .filter(isTruthy)
        .sort(compare.stringsCaseInsensitive()),
    };
  } else if (validatedPayeeData && !privateRecords?.length) {
    return {
      id: mainId,
      ids: payeeIds,
      names: validatedPayeeData.names?.map((name) => ({ name, isValidatedPayee: true })),
      addresses: validatedPayeeData.addresses,
      phones: validatedPayeeData.phones,
      emails: validatedPayeeData.emails,
      websites: validatedPayeeData.websites,
      faxes: validatedPayeeData.faxes,
      description: validatedPayeeData.descriptions?.[0],
      legalIdentifiers: validatedPayeeData.legalIdentifiers.map((lei) => ({ ...lei, isValidatedPayee: true })),
      countryCode: validatedPayeeData.countryCode,
      primaryFields: validatedPayeeData.primaryFields,
      strongLegalIdentifies: knoxIdWithoutSuffix(validatedPayeeData.strongLegalIdentifies),
      externalReferenceIds: [],
      supplierNumbers: [],
      companyCodes: [],
    };
  } else {
    const privateRecord = privateRecords?.[0];
    if (!validatedPayeeData && privateRecord) {
      const leisWithSupplierNumbers = privateRecord.data.legalIdentifiers ?? [];

      if (privateRecord.data.supplierNumbers?.length) {
        leisWithSupplierNumbers.push(...extractSupplierNumbersFromPrivateRecords([privateRecord]));
      }

      return {
        id: mainId,
        ids: payeeIds,
        names: privateRecord.data.names?.map((name) => ({ name, isValidatedPayee: false })),
        addresses: privateRecord.data.addresses,
        phones: privateRecord.data.phones,
        emails: privateRecord.data.emails,
        websites: privateRecord.data.websites,
        faxes: privateRecord.data.faxes,
        description: privateRecord.data.description,
        legalIdentifiers: leisWithSupplierNumbers.map((lei) => ({ ...lei, isValidatedPayee: false })),
        countryCode: privateRecord.data.countryCode,
        externalReferenceIds: [privateRecord.externalReferenceId],
        strongLegalIdentifies: privateRecord.strongLegalIdentifies,
        supplierNumbers: privateRecord.data.supplierNumbers ?? [],
        companyCodes: transformCompaniesToCompanyText(privateRecord.data.companies).sort(compare.stringsCaseInsensitive()),
      };
    } else {
      throw new Error('Missing privates and validated payee data');
    }
  }
}

function knoxIdWithoutSuffix(
  strongLegalIdentifies: StrongLegalIdentifiesServerResponse | undefined,
): StrongLegalIdentifiesServerResponse | undefined {
  if (!strongLegalIdentifies) {
    return strongLegalIdentifies;
  }

  const knoxIdLeis = strongLegalIdentifies.KnoxId;

  if (knoxIdLeis) {
    strongLegalIdentifies.KnoxId = knoxIdLeis.map((knoxIdLei) => {
      return {
        ...knoxIdLei,
        value: knoxIdLei.value.split('/')[0],
      };
    });
  }

  return strongLegalIdentifies;
}

export function transformToUnifiedPayee(payee: AccountVerificationPayeeSearchServerResponse): UnifiedPayee {
  const { privateRecords, validatedPayeeData } = payee;
  return createUnifiedMorteePayeeFromValidatedAndPrivate(validatedPayeeData, privateRecords);
}
