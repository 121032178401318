import request, { HttpMethod } from '@app/libs/request';

export async function serverHealthCheck(serverAddress: string): Promise<string> {
  return request<string>(serverAddress, `/health-check`, {
    method: HttpMethod.get,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    suppressNotification: true,
  });
}

const VALID_HEALTH_CHECK_RESULT = 'nsknox-waf-up';

export function checkHealthCheckResultValid(healthCheckResult: string): boolean {
  return healthCheckResult === VALID_HEALTH_CHECK_RESULT;
}
