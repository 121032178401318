import ARNavigationStore from '@ar/stores/infraStores/ARNavigationStore';
import LocaleStore from '@app/stores/LocaleStore';
import config from '@app/config';
import GuestInfraStores from '@app/guestLogin/stores/GuestInfraStores';
import PermissionsStore from '@app/stores/PermissionsStore';
import { guestHeaderGenerator } from '@app/utils/userIdentityHeadersGenerator';
import { DynamicHeadersGenerator } from '@app/stores/InfraStores';
import ARHealthCheckStore from '@ar/stores/infraStores/ARHealthCheckStore';
import ARThemeStore from '@ar/stores/infraStores/ARThemeStore';
import LanguageStore from '@app/stores/LanguageStore';
import CustomerConfigurationStore from '@ar/stores/infraStores/CustomerConfigurationStore';
import UserStoreImpl from '@app/stores/UserStoreImpl';

export default class ARInfraStores extends GuestInfraStores<ARNavigationStore, PermissionsStore, ARThemeStore> {
  customerConfigurationStore: CustomerConfigurationStore;

  constructor(allSections: Section[], localePath: string) {
    const userStore = new UserStoreImpl();
    const languageStore = new LanguageStore(config.defaultLocale, localePath);
    const localeStore = new LocaleStore(languageStore);
    const permissionsStore = new PermissionsStore(allSections, userStore);
    const navigationStore = new ARNavigationStore(userStore, permissionsStore);
    const themeStore = new ARThemeStore(languageStore);
    const healthCheckStore = new ARHealthCheckStore(userStore);
    super(userStore, languageStore, localeStore, navigationStore, permissionsStore, themeStore, healthCheckStore);

    this.customerConfigurationStore = new CustomerConfigurationStore(themeStore);
  }

  getDynamicHeadersGenerator(): DynamicHeadersGenerator {
    return (_req: any): Promise<Record<string, string> | undefined> =>
      guestHeaderGenerator(this.guestLoginStore, this.customerConfigurationStore);
  }
}
