import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import VerificationValidatedPayeeFullDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedPayeeFullDetails';
import { getAccountValidationFields, VERIFICATION_ACCOUNT_TYPE } from '@app/domain/accountVerification';
import { removeIrrelevantAccountDetailsFields } from '@app/domain/accountsDetailsHelpers';
import SearchAccount, { VerifyAccountFormFields } from '@app/components/accountVerification/SearchAccount';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import useForm from '@app/hooks/useForm';
import Log from '@app/libs/logger';
import useIsMounted from '@app/hooks/useIsMounted';
import ARAboveMainCardTitle from '../ARAboveMainCardTitle';
import Button from '@app/components/Button';
import { COUNTRY_CODES } from '@app/domain/countries';
import { useTranslation } from 'react-i18next';

interface Props extends FormComponentProps<VerifyAccountFormFields> {
  payee: AggregatedValidatedPayee;
  initialAccountFormFields: VerifyAccountFormFields | null;
  verifyAccount(account: VerifyAccountFormFields): Promise<void>;
}

const GuestVerificationWithPayeeAccountFormPage: FC<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.enterAccount);
  const isMountedRef = useIsMounted();
  const [verificationLoading, setVerificationLoading] = useState<boolean>(false);
  const { form, showFormErrors, setShowFormErrors } = useForm(props);
  const { t } = useTranslation();

  const { payee, initialAccountFormFields, verifyAccount } = props;

  const handleSubmitVerification = async (): Promise<void> => {
    form.validateFieldsAndScroll(async (errors: Object, values: VerifyAccountFormFields) => {
      if (errors) {
        setShowFormErrors('all');
        return;
      }

      setVerificationLoading(true);

      if (values.type !== VERIFICATION_ACCOUNT_TYPE.domestic) {
        // remove initial value in case the actual is not domestic
        values.accountDetails.countryCode = null;
      }

      const searchAccountDetails: VerifyAccountFormFields = {
        ...values,
        accountDetails: removeIrrelevantAccountDetailsFields(values.accountDetails),
      };

      try {
        await verifyAccount(searchAccountDetails);
      } catch (e: unknown) {
        Log.exception(e);
      } finally {
        if (isMountedRef.current) {
          setVerificationLoading(false);
        }
      }
    });
  };

  let initialValuesAfterPayeeIsSelected: VerifyAccountFormFields | undefined = {
    type: VERIFICATION_ACCOUNT_TYPE.domestic,
    accountDetails: { countryCode: COUNTRY_CODES.UnitedStates },
  };

  const selectedPayeeCountryCode = payee?.countryCode;

  // In case the payee is selected and that it's country is one of the countries that can be validated against
  if (initialAccountFormFields) {
    initialValuesAfterPayeeIsSelected = initialAccountFormFields;
  } else if (selectedPayeeCountryCode && getAccountValidationFields(selectedPayeeCountryCode, t)) {
    initialValuesAfterPayeeIsSelected = {
      type: VERIFICATION_ACCOUNT_TYPE.domestic,
      accountDetails: { countryCode: selectedPayeeCountryCode },
    };
  }

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <ARAboveMainCardTitle title={t('ar.guestVerification.details.accountVerification')} showBackButton />
      <MainCard>
        <PayeeDetailsCard>
          <VerificationValidatedPayeeFullDetails payee={payee} />
        </PayeeDetailsCard>
        <SubComponentTitle>{t('ar.guestVerification.details.enterPayeeAccountDetails')}</SubComponentTitle>
        <SearchAccount
          payeeCountryCode={selectedPayeeCountryCode}
          key={payee?.uniformId}
          trySubmit={(): Promise<void> => handleSubmitVerification()}
          isFormDisabled={!payee}
          initialFormValues={initialValuesAfterPayeeIsSelected}
          showFormErrors={showFormErrors}
          resetShowFormErrors={(): void => setShowFormErrors('onTouch')}
          form={form}
        />
        <ActionContainer>
          <PrimaryButton
            id='btn-guest-validate-account'
            dataTestId='btnValidate'
            loading={verificationLoading}
            disabled={!payee}
            onClick={(): Promise<void> => handleSubmitVerification()}
          >
            {t('ar.guestVerification.details.upperVerifyAccount')}
          </PrimaryButton>
        </ActionContainer>
      </MainCard>
    </PageWithHeader>
  );
});

export default Form.create<Props>()(GuestVerificationWithPayeeAccountFormPage);

const PayeeDetailsCard = styled.div`
  padding: 16px 32px;
  background: var(--primary-200-50-a);
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 20px;
`;

const MainCard = styled(Card)`
  width: 100%;
  padding: 25px 40px 40px;
`;

const SubComponentTitle = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;

  color: var(--primary-300);

  padding-top: 50px;
`;

export const PrimaryButton = styled(Button)`
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 6px;
`;
