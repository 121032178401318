import { compare } from '@app/utils/comparatorUtils';

export enum AccountValues {
  iban = 'iban',
  swiftCode = 'swiftCode',
  countryCode = 'countryCode',
  bankAndBranchCode = 'bankAndBranchCode',
  accountNumber = 'accountNumber',
}

const accountRepresentationTypeOrder: AccountRepresentationType[] = ['swiftban', 'swift', 'iban', 'domestic'];

const representationTypeComparator = compare.unionType(accountRepresentationTypeOrder);

type AccountValuesToRepresentations = {
  values: AccountValues[];
  allFullRepresentations: AccountRepresentationType[];
  allRepresentationsWithPartial: AccountRepresentationType[];
  representationsToShow: AccountRepresentationType[];
};

const valueOptions: AccountValuesToRepresentations[] = [
  {
    values: [],
    representationsToShow: [],
    allFullRepresentations: [],
    allRepresentationsWithPartial: [],
  },
  {
    values: [AccountValues.swiftCode],
    representationsToShow: ['swift'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['swift'],
  },
  {
    values: [AccountValues.iban],
    representationsToShow: ['iban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban'],
  },
  {
    values: [AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['swiftban'],
    allFullRepresentations: ['iban', 'swiftban'],
    allRepresentationsWithPartial: ['iban'],
  },
  {
    values: [AccountValues.accountNumber],
    representationsToShow: ['domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['domestic'],
  },
  {
    values: [AccountValues.accountNumber, AccountValues.swiftCode],
    representationsToShow: ['swift'],
    allFullRepresentations: ['swift'],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.accountNumber, AccountValues.iban],
    representationsToShow: ['iban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.accountNumber, AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['iban', 'swift'],
    allFullRepresentations: ['iban', 'swift', 'swiftban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode],
    representationsToShow: ['domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.swiftCode],
    representationsToShow: ['swift', 'domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.iban],
    representationsToShow: ['iban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['swiftban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.accountNumber],
    representationsToShow: ['domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.accountNumber, AccountValues.swiftCode],
    representationsToShow: ['swift', 'domestic'],
    allFullRepresentations: ['swift'],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.accountNumber, AccountValues.iban],
    representationsToShow: ['iban', 'domestic'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban', 'domestic'],
  },
  {
    values: [AccountValues.bankAndBranchCode, AccountValues.accountNumber, AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['iban', 'swift', 'domestic'],
    allFullRepresentations: ['iban', 'swift', 'swiftban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode],
    representationsToShow: ['domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.swiftCode],
    representationsToShow: ['swift'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['swift'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.iban],
    representationsToShow: ['iban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['swiftban'],
    allFullRepresentations: ['iban', 'swiftban'],
    allRepresentationsWithPartial: ['iban'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.accountNumber],
    representationsToShow: ['domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.accountNumber, AccountValues.swiftCode],
    representationsToShow: ['swift'],
    allFullRepresentations: ['swift'],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.accountNumber, AccountValues.iban],
    representationsToShow: ['iban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.accountNumber, AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['iban', 'swift'],
    allFullRepresentations: ['iban', 'swift', 'swiftban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode],
    representationsToShow: ['domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode, AccountValues.swiftCode],
    representationsToShow: ['swift', 'domestic'],
    allFullRepresentations: [],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode, AccountValues.iban],
    representationsToShow: ['iban'],
    allFullRepresentations: ['iban'],
    allRepresentationsWithPartial: ['iban', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode, AccountValues.iban, AccountValues.swiftCode],
    representationsToShow: ['swiftban'],
    allFullRepresentations: ['iban', 'swiftban'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode, AccountValues.accountNumber],
    representationsToShow: ['domestic'],
    allFullRepresentations: ['domestic'],
    allRepresentationsWithPartial: ['domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode, AccountValues.accountNumber, AccountValues.swiftCode],
    representationsToShow: ['swift', 'domestic'],
    allFullRepresentations: ['swift', 'domestic'],
    allRepresentationsWithPartial: ['swift', 'domestic'],
  },
  {
    values: [AccountValues.countryCode, AccountValues.bankAndBranchCode, AccountValues.accountNumber, AccountValues.iban],
    representationsToShow: ['iban', 'domestic'],
    allFullRepresentations: ['iban', 'domestic'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
  {
    values: [
      AccountValues.countryCode,
      AccountValues.bankAndBranchCode,
      AccountValues.accountNumber,
      AccountValues.iban,
      AccountValues.swiftCode,
    ],
    representationsToShow: ['iban', 'swift', 'domestic'],
    allFullRepresentations: ['iban', 'swift', 'swiftban', 'domestic'],
    allRepresentationsWithPartial: ['iban', 'swift', 'domestic'],
  },
];

const composeAccountDetailsValuesKey = (values: AccountValues[]): string => {
  return `${values.includes(AccountValues.iban)}-\
${values.includes(AccountValues.swiftCode)}-\
${values.includes(AccountValues.countryCode)}-\
${values.includes(AccountValues.bankAndBranchCode)}-\
${values.includes(AccountValues.accountNumber)}`;
};

interface AccountDetailsRepresentations {
  allWithPartial: AccountRepresentationType[];
  allFull: AccountRepresentationType[];
  toShow: AccountRepresentationType[];
}

const accountFormattingOptions = new Map<string, AccountDetailsRepresentations>(
  valueOptions.map((formattingData) => {
    return [
      composeAccountDetailsValuesKey(formattingData.values),
      {
        toShow: formattingData.representationsToShow.sort(representationTypeComparator),
        allFull: formattingData.allFullRepresentations.sort(representationTypeComparator),
        allWithPartial: formattingData.allRepresentationsWithPartial.sort(representationTypeComparator),
      },
    ];
  }),
);

export const getAccountRepresentations = (
  accountDetails?: MorteeAccountDetailsExtended | null,
): AccountDetailsRepresentations => {
  if (!accountDetails) {
    return { toShow: [], allFull: [], allWithPartial: [] };
  }

  const accountDetailsValuesObject: { [key in AccountValues]: string | null } = {
    countryCode: accountDetails.countryCode,
    bankAndBranchCode: `${accountDetails.bankCode ?? ''}${accountDetails.branchCode ?? ''}`,
    accountNumber: accountDetails.accountNumber,
    iban: accountDetails.iban,
    swiftCode: accountDetails.swiftCode,
  };

  const valuesKeys = composeAccountDetailsValuesKey(
    Object.entries(accountDetailsValuesObject)
      .filter(([, value]) => !!value)
      .map(([key]) => key as AccountValues),
  );

  return accountFormattingOptions.get(valuesKeys) ?? { toShow: [], allFull: [], allWithPartial: [] };
};

export const splitAccountDetailsIntoRepresentationsMap = (
  accountDetails: MorteeAccountDetailsExtended | null | undefined,
  includePartialRepresentations: boolean = false,
  withoutSwiftban: boolean = false,
): Map<AccountRepresentationType, MorteeRepresentationAccountDetails> => {
  if (!accountDetails) {
    return new Map();
  }

  const accountRepresentations = getAccountRepresentations(accountDetails);
  const representationTypes = includePartialRepresentations
    ? accountRepresentations.allWithPartial
    : accountRepresentations.allFull;

  const resultMap = new Map<AccountRepresentationType, MorteeRepresentationAccountDetails>(
    representationTypes.map((representationType) => [
      representationType,
      createAccountDetailsWithOnlyRepresentation(accountDetails, representationType),
    ]),
  );

  if (withoutSwiftban) {
    resultMap.delete('swiftban');
  }

  return resultMap;
};

export const isAccountDetailsPartial = (accountDetails: MorteeAccountDetailsExtended): boolean => {
  return !getAccountRepresentations(accountDetails).allFull.length;
};

export const createAccountDetailsWithOnlyRepresentation = (
  accountDetails: MorteeAccountDetailsExtended,
  accountRepresentation: AccountRepresentationType,
): MorteeRepresentationAccountDetails => {
  switch (accountRepresentation) {
    case 'domestic': {
      return {
        type: 'domestic',
        countryCode: accountDetails.countryCode ?? '',
        bankCode: accountDetails.bankCode,
        branchCode: accountDetails.branchCode,
        accountNumber: accountDetails.accountNumber ?? '',
        localFormat: accountDetails.localFormat ?? '',
      };
    }
    case 'iban': {
      return {
        type: 'iban',
        iban: accountDetails.iban ?? '',
        localFormat: accountDetails.localFormat ?? '',
      };
    }
    case 'swift': {
      return {
        type: 'swift',
        swiftCode: accountDetails.swiftCode ?? '',
        accountNumber: accountDetails.accountNumber ?? '',
        localFormat: accountDetails.localFormat ?? '',
      };
    }
    case 'swiftban': {
      return {
        type: 'swiftban',
        iban: accountDetails.iban ?? '',
        swiftCode: accountDetails.swiftCode ?? '',
        localFormat: accountDetails.localFormat ?? '',
      };
    }
  }
};

export const doesAccountDetailsContainRepresentation = (
  containingAccountDetails: MorteeAccountDetails,
  accountDetailsRepresentation: MorteeRepresentationAccountDetails,
): boolean => {
  switch (accountDetailsRepresentation.type) {
    case 'swiftban': {
      return (
        containingAccountDetails.swiftCode === accountDetailsRepresentation.swiftCode &&
        containingAccountDetails.iban === accountDetailsRepresentation.iban
      );
    }
    case 'swift': {
      return (
        containingAccountDetails.swiftCode === accountDetailsRepresentation.swiftCode &&
        containingAccountDetails.accountNumber === accountDetailsRepresentation.accountNumber
      );
    }
    case 'iban': {
      return containingAccountDetails.iban === accountDetailsRepresentation.iban;
    }
    case 'domestic': {
      return (
        containingAccountDetails.bankCode === accountDetailsRepresentation.bankCode &&
        containingAccountDetails.branchCode === accountDetailsRepresentation.branchCode &&
        containingAccountDetails.accountNumber === accountDetailsRepresentation.accountNumber
      );
    }
  }
};

export function getRepresentationNameWithBIC(type: AccountRepresentationType): string {
  switch (type) {
    case 'iban': {
      return 'IBAN';
    }
    case 'swift': {
      return 'SWIFT';
    }
    case 'domestic': {
      return 'Domestic';
    }
    case 'swiftban': {
      return 'BIC';
    }
  }
}
