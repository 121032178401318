import React, { FunctionComponent } from 'react';
import { SupplierRegistrationProcess, WiringFileTypes, WiringFileTypesDisplay } from '@mortee/domain/morteeRegistrationForms';
import { shoot } from '@app/utils/messageLauncher';
import {
  postSupplierRegistrationWiringFileReportCsv,
  transformToSupplierRegistrationProcess,
} from '@mortee/services/supplierRegistrationManagementServices';
import { showYesNoModal } from '@app/components/Modal';
import { pluralize } from '@app/utils/stringUtils';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import EmailPayeeIcon from '@mortee/images/email-payee.svg';
import EmailPayeeIconDisabled from '@mortee/images/email-payee-disabled.svg';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import { COUNTRY_CODES } from '@app/domain/countries';
import EyeOffImage from '@app/images/ic-eye-off.svg';
import EyeOnImage from '@app/images/ic-eye-on.svg';
import EyeOffDisabledImage from '@app/images/ic-eye-off-disabled.svg';
import EyeOnDisabledImage from '@app/images/ic-eye-on-disabled.svg';
import * as supplierRegistrationManagementServices from '@mortee/services/supplierRegistrationManagementServices';
import ModalAppContext from '@app/ModalAppContext';
import { BodyRegular15TransparentBlack900 } from '@app/components/Text';
import useModalContext from '@app/hooks/useModalContext';

interface Props {
  selectedRecords: SupplierRegistrationProcess[];
  clearSelectedRows(): void;
  onItemUpdate(record: SupplierRegistrationProcess): void;
}

const SupplierRegistrationTableActions: FunctionComponent<Props> = (props) => {
  const { selectedRecords, clearSelectedRows, onItemUpdate } = props;
  const modalContext = useModalContext();

  const isAllSelectedRecordsConditionsAreMet = (conditions: Partial<SupplierRegistrationProcess>): boolean => {
    return (
      !!selectedRecords.length &&
      selectedRecords.every((supplierRegistrationRecord) => {
        return Object.entries(conditions).every(([fieldKey, expectedValue]) => {
          return supplierRegistrationRecord[fieldKey] === expectedValue;
        });
      })
    );
  };

  const isValidAchRecord = (record: SupplierRegistrationProcess): boolean =>
    record.accountBankCountryCode === COUNTRY_CODES.UnitedStates &&
    !!record.accountDetails.accountNumber?.trim() &&
    !!record.accountDetails.bankCode?.trim();

  const noRecordsWereSelected = !selectedRecords.length;

  const isSendBofaWiButtonDisabled = !isAllSelectedRecordsConditionsAreMet({ accountBankCountryCode: 'US', isIndividual: false });

  const isSendAchButtonDisabled = noRecordsWereSelected || !selectedRecords.every((record) => isValidAchRecord(record));

  const isHideButtonDisabled = noRecordsWereSelected || selectedRecords.every((record) => record.hidden);

  const isUnhideButtonDisabled = noRecordsWereSelected || selectedRecords.every((record) => !record.hidden);

  const selectedRowKeys = selectedRecords.map((supplierRegistrationProcess) => supplierRegistrationProcess.id);

  const sendWiEmail = (fileType: WiringFileTypes): void => {
    showYesNoModal(
      `You are exporting ${selectedRecords.length} ${pluralize('record', selectedRecords.length)}. Are you sure?`,
      async (): Promise<void> => {
        const closeLoadingMessage = shoot({ type: 'loading', closeable: true, duration: null }, 'Sending WI');
        try {
          await postSupplierRegistrationWiringFileReportCsv(selectedRowKeys, fileType);
          closeLoadingMessage();
          shoot(
            { type: 'success', closeable: true },
            `${WiringFileTypesDisplay[fileType].display} WI via email have been sent to configured recipients.`,
          );
          clearSelectedRows();
        } catch {
          closeLoadingMessage();
        }
      },
      WiringFileTypesDisplay[fileType].display,
    );
  };

  const updateRecordVisibility = async (
    supplierRegistrationProcessIds: string[],
    isIgnored: boolean,
  ): Promise<SupplierRegistrationProcess[]> => {
    const updateSupplierRegistrationProcessesServerResponse = await supplierRegistrationManagementServices.setVisibilitySupplierRegistrationItem(
      { processIds: supplierRegistrationProcessIds, isIgnored },
    );
    return updateSupplierRegistrationProcessesServerResponse.map(transformToSupplierRegistrationProcess);
  };

  const handleVisibilityChange = (isIgnored: boolean): void => {
    showYesNoModal(
      `${isIgnored ? 'Hide' : 'Unhide'} ${selectedRowKeys.length} ${pluralize('record', selectedRecords.length)}?`,
      async (): Promise<void> => {
        const updateSupplierRegistrationProcessesServerResponse = await updateRecordVisibility(selectedRowKeys, isIgnored);
        for (const supplierRegistrationProcess of updateSupplierRegistrationProcessesServerResponse) {
          onItemUpdate(supplierRegistrationProcess);
        }
        shoot({ type: 'success', closeable: true }, (closeFunc) => (
          <ModalAppContext {...modalContext}>
            <Content>
              <BodyRegular15TransparentBlack900.div>
                {selectedRowKeys.length} {pluralize('record', selectedRecords.length)}{' '}
                {selectedRecords.length > 1 ? 'were' : 'was'} {isIgnored ? 'hidden' : 'unhidden'}
              </BodyRegular15TransparentBlack900.div>
              <Button
                id='undo-button'
                appearance='text'
                colorScheme='primary'
                onClick={async (): Promise<void> => {
                  closeFunc();
                  const updateSupplierRegistrationProcessesServerResponse = await updateRecordVisibility(
                    selectedRowKeys,
                    !isIgnored,
                  );
                  for (const supplierRegistrationProcess of updateSupplierRegistrationProcessesServerResponse) {
                    onItemUpdate(supplierRegistrationProcess);
                  }
                }}
              >
                UNDO
              </Button>
            </Content>
          </ModalAppContext>
        ));
        clearSelectedRows();
      },
      `${isIgnored ? 'Hide' : 'Unhide'} ${pluralize('Record', selectedRecords.length)}`,
    );
  };

  const menuItems: MenuItem[] = [
    {
      id: 'btn-send-bofa-wi-selected-rows',
      text: 'Send BOFA US validation file via email',
      disabled: isSendBofaWiButtonDisabled,
      icon: isSendBofaWiButtonDisabled ? EmailPayeeIconDisabled : EmailPayeeIcon,
      onClick: (): void => sendWiEmail(WiringFileTypes.bofa),
      tooltipText: isSendBofaWiButtonDisabled ? 'Available for US account records (company) only' : undefined,
    },
    {
      id: 'btn-hide-selected-rows',
      text: 'Hide Records',
      icon: isHideButtonDisabled ? EyeOffDisabledImage : EyeOffImage,
      onClick: (): void => handleVisibilityChange(true),
      disabled: isHideButtonDisabled,
    },
    {
      id: 'btn-unhide-selected-rows',
      text: 'Unhide Records',
      icon: isUnhideButtonDisabled ? EyeOnDisabledImage : EyeOnImage,
      onClick: (): void => handleVisibilityChange(false),
      disabled: isUnhideButtonDisabled,
    },
  ];

  return (
    <>
      <ConditionalTooltip showTooltip={isSendAchButtonDisabled} title='Available for ACH - Chase records only'>
        <SubmitButton
          id='btn-send-bofa-ach-wi-selected-rows'
          disabled={isSendAchButtonDisabled}
          onClick={(): void => sendWiEmail(WiringFileTypes.bofaAch)}
        >
          Send Bofa ACH payments via email
        </SubmitButton>
      </ConditionalTooltip>
      <Menu id='records-actions-menu' menuItems={menuItems} />
    </>
  );
};

export default SupplierRegistrationTableActions;

const SubmitButton = styled(Button)`
  flex: 0 0 auto;
  margin-left: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
