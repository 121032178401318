import React, { ReactNode, useContext } from 'react';
import { LegalEntityIdType, LegalIdentifierRequest } from '@mortee/domain/vaildatedPayeeManagement';
import { FormInputProps } from '@app/utils/form/form';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import Loadable from '@app/utils/Loadable';
import styled from '@emotion/styled';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { ForwardingFC } from '@app/domain/technicals/components';
import { BodyRegularStartTransparentBlack900 } from '@app/components/Text';
import ValueWithPlaceholder from '@app/components/inputs/ValueWithPlaceholder';
import Button from '@app/components/Button';
import { isTruthy } from '@app/utils/utils';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import FormContext from '@app/components/inputs/FormContext';

interface Props extends FormInputProps<Partial<LegalIdentifierRequest>, false> {
  name: string;
  dataTestId?: string;
  showCountry: boolean;
  currentPayeeCountryCode?: string;
  legalIdTypeOptions: Loadable<LegalEntityIdType[]>;
  disabled?: boolean;
  forceUpperCaseValue?: boolean;
}

const LegalIdInput: ForwardingFC<HTMLDivElement, Props> = React.forwardRef((props, ref) => {
  const { disabled: disabledForm } = useContext(FormContext);

  const {
    name,
    dataTestId = name,
    value: currentLeiValue,
    onChange,
    showCountry,
    currentPayeeCountryCode,
    legalIdTypeOptions,
    accessibilityLabel,
    disabled: disabledProp,
    forceUpperCaseValue,
  } = props;

  const disabled = disabledProp || disabledForm;

  const onCountryChange = (newCountry: string): void => {
    changeOrClear({ ...currentLeiValue, countryCode: newCountry });
  };

  const onTypeIdChange = (newTypeId: string): void => {
    changeOrClear({ ...currentLeiValue, typeId: newTypeId });
  };

  const onValueChange = (newValue: string): void => {
    changeOrClear({ ...currentLeiValue, value: newValue });
  };

  const changeOrClear = (newLeiValue: Partial<LegalIdentifierRequest>): void => {
    const isObjectNotEmpty = Object.values(newLeiValue).some(isTruthy);

    if (isObjectNotEmpty) {
      onChange?.(newLeiValue);
    } else {
      clearAll();
    }
  };

  const clearAll = (): void => {
    onChange?.(undefined);
  };

  return (
    <EvenlySpacedRow ref={ref} showCountry={showCountry} aria-label={accessibilityLabel}>
      {legalIdTypeOptions.resolve(
        (loadedLegalIdTypeOptions): ReactNode =>
          loadedLegalIdTypeOptions.length === 1 ? (
            <ConstLEITypeValueWithPlaceholder
              placeholder={null}
              colorScheme='secondary'
              placeholderElementId={`${name}-const-typeid`}
            >
              {loadedLegalIdTypeOptions[0].name}
            </ConstLEITypeValueWithPlaceholder>
          ) : (
            <NakedDropdown
              accessibilityLabel='legal identifier'
              name={`drp-${name}-lei-type`}
              dataTestId={`drp-${dataTestId}-lei-type`}
              placeholder='Type'
              value={currentLeiValue?.typeId ?? undefined}
              onChange={onTypeIdChange}
              popupWidth='auto'
              isSearchable={true}
            >
              {loadedLegalIdTypeOptions.map((legalIdTypeOption) => (
                <DropdownItem key={legalIdTypeOption.id} value={legalIdTypeOption.id} textWhenSelected={legalIdTypeOption.name}>
                  {legalIdTypeOption.name}
                </DropdownItem>
              ))}
            </NakedDropdown>
          ),
        () => (
          <NakedDropdown
            accessibilityLabel='legal identifier'
            name={`drp-${name}-lei-type`}
            dataTestId={`drp-${dataTestId}-lei-type`}
            placeholder='Loading Types...'
            disabled={disabled}
            children={[]}
          />
        ),
      )}
      {showCountry && (
        <CountryDropdown
          accessibilityLabel='legal identifier country'
          name={`drp-${name}-lei-country`}
          dataTestId={`drp-${dataTestId}-lei-country`}
          value={currentLeiValue?.countryCode ?? undefined}
          onChange={onCountryChange}
          currentPayeeCountryCode={currentPayeeCountryCode}
          popupWidth='auto'
        />
      )}
      <NakedFormInput
        name={`txt-${name}-lei-value`}
        dataTestId={`txt-${dataTestId}-lei-value`}
        type='text'
        placeholder='Value'
        value={forceUpperCaseValue ? currentLeiValue?.value?.toUpperCase() : currentLeiValue?.value}
        onChange={onValueChange}
      />
      <ClearButton
        id={`btn-${name}-clear`}
        dataTestId={`btn-${dataTestId}-clear`}
        onClick={clearAll}
        appearance='text'
        disabled={disabled}
      >
        Clear
      </ClearButton>
    </EvenlySpacedRow>
  );
});

export default LegalIdInput;

const EvenlySpacedRow = styled.div<{ showCountry: boolean }>`
  display: grid;
  grid-template-columns: 1fr ${(p): string => (p.showCountry ? '1fr 1fr' : '2fr')} auto;

  & > *:not(:last-child) {
    border-right: 1px var(--transparent-black-100) solid;
  }
`;

const ConstLEITypeValueWithPlaceholder = styled(ValueWithPlaceholder)`
  ${BodyRegularStartTransparentBlack900.css};
  line-height: normal;
`;

const ClearButton = styled(Button)`
  display: flex;
  align-items: center;
`;
