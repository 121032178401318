import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem, { DropdownItemProps } from '@app/components/inputs/DropdownItem';
import useInfraStores from '@app/hooks/useInfraStores';
import LanguageFlag from './LanguageFlag';
import { SelectableLanguage } from '@app/stores/LanguageStore';
import { ColorScheme, getColorScheme } from '@app/domain/theme';
import GlobeImage from '@app/images/globe.svg';
import SVG from '@app/components/SVG';
import { useTranslation } from 'react-i18next';
import { NakedDropdownInnerInputProps } from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputProps';
import NakedDropdownInnerInputDefault from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputDefault';
import { ForwardingFC } from '@app/domain/technicals/components';

interface Props {
  textColorScheme: ColorScheme;
  className?: string;
}

const ChangeLanguageDropdown: FC<Props> = observer(({ textColorScheme: propTextColorScheme, className }) => {
  const { languageStore } = useInfraStores();
  const textColorScheme = getColorScheme(propTextColorScheme);
  const { t } = useTranslation();

  const renderLanguageItem = (lang: SelectableLanguage): ReactElement<DropdownItemProps> => {
    return (
      <LanguageDropDownItem textWhenSelected={lang.display} key={lang.key} value={lang.key}>
        <StyledLanguageFlag countryCode={lang.flagCountryCode} />
        {lang.display}
      </LanguageDropDownItem>
    );
  };

  if (languageStore.availableLanguages.length < 2) {
    return null;
  }

  return (
    <DropdownContainer className={className}>
      <StyledNakedDropdown
        accessibilityLabel={t('general.accessibility.changeLanguage')}
        name='drp-lang-change'
        dataTestId='drp-lang-change'
        placeholder={null}
        value={languageStore.selectedLanguageKey}
        onChange={(langKey: string): Promise<void> => languageStore.changeLanguage(langKey)}
        colorScheme={textColorScheme}
        textColorScheme={textColorScheme}
        useDynamicArrow
        InnerInputComponent={ChangeLanguageDropdownInnerInput}
      >
        {languageStore.availableLanguages.map((lang) => renderLanguageItem(lang))}
      </StyledNakedDropdown>
    </DropdownContainer>
  );
});

const ChangeLanguageDropdownInnerInput: ForwardingFC<HTMLTextAreaElement, NakedDropdownInnerInputProps> = React.forwardRef(
  (props: NakedDropdownInnerInputProps, ref) => {
    return (
      <SelectedItemContainer>
        <GlobeSVG
          accessibilityLabel=''
          key='globe-svg'
          image={GlobeImage}
          height={24}
          width={24}
          colorScheme={props.commonProps.textColorScheme}
        />
        <InputContainer>
          <NakedDropdownInnerInputDefault ref={ref} {...props} />
        </InputContainer>
      </SelectedItemContainer>
    );
  },
);

export default ChangeLanguageDropdown;

const DropdownContainer = styled.div`
  position: relative;
  max-width: 220px;
`;

const StyledNakedDropdown = styled(NakedDropdown)`
  direction: ltr;
`;

const LanguageDropDownItem = styled(DropdownItem)`
  padding: 12px 20px;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
  text-align: left;
  display: flex;
  align-items: center;
`;

const SelectedItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GlobeSVG = styled(SVG)`
  flex: 0 0 auto;
`;

const StyledLanguageFlag = styled(LanguageFlag)`
  margin-right: 10px;
`;

const InputContainer = styled.div`
  margin-inline-end: -12px;
`;
