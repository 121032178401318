import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import GuestLoginEnterPhone from './GuestLoginEnterPhone';
import GuestLoginEnterPhoneCode from './GuestLoginEnterPhoneCode';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';

const GuestLoginPhoneFlow: FunctionComponent = observer((props) => {
  const localStore = useLocalObservable(() => ({
    _phoneNumber: undefined as string | undefined,
    _dialCode: undefined as string | undefined,
    _countryCode: undefined as string | undefined,
    _isWaitingForCode: false as boolean,

    get phoneNumber(): string | undefined {
      return localStore._phoneNumber;
    },
    get dialCode(): string | undefined {
      return localStore._dialCode;
    },
    get countryCode(): string | undefined {
      return localStore._countryCode;
    },
    get phoneAndDial(): string {
      return joinNationalPhoneAndDialCode(localStore._dialCode?.trim() || '', localStore._phoneNumber?.trim() || '');
    },
    get isWaitingForCode(): boolean {
      return localStore._isWaitingForCode;
    },

    setDialAndPhone: (dialCode: string | undefined, phoneNumber: string | undefined, countryCode: string | undefined): void => {
      localStore._dialCode = dialCode;
      localStore._phoneNumber = phoneNumber;
      localStore._countryCode = countryCode;
    },

    setIsWaitingForCode: (isWaiting: boolean): void => {
      localStore._isWaitingForCode = isWaiting;
    },
  }));

  const { phoneNumber, dialCode, countryCode, phoneAndDial, isWaitingForCode, setDialAndPhone, setIsWaitingForCode } = localStore;

  if (phoneAndDial && isWaitingForCode) {
    return <GuestLoginEnterPhoneCode phoneNumber={phoneAndDial} cancelWaitingForCode={(): void => setIsWaitingForCode(false)} />;
  }

  return (
    <GuestLoginEnterPhone
      setDialAndPhone={setDialAndPhone}
      setWaitingForCode={(): void => setIsWaitingForCode(true)}
      initialPhone={phoneNumber}
      defaultDialCode={dialCode}
      defaultCountryCode={countryCode}
    />
  );
});

export default GuestLoginPhoneFlow;
