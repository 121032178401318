//

/*  This function run on object (like statuses in accountsConsts) and foreach key
    clone the object and create new key according to the "id" property
    We use this function for easy transitions between server data and client data
    Object before:
      statuses: {
        pendingApproval: {
          id: 'AccountPendingApproval',
          color: '#4F84C4',
          text: 'Pending',
        },
        approved: {
          id: 'AccountApproved',
          color: '#67B610',
          text: 'Approved',
        },
      }

    Object after:
      statuses: {
        pendingApproval: {
          id: 'AccountPendingApproval',
          color: '#4F84C4',
          text: 'Pending',
        },
        approved: {
          id: 'AccountApproved',
          color: '#67B610',
          text: 'Approved',
        },
        AccountPendingApproval: {
          id: 'AccountPendingApproval',
          color: '#4F84C4',
          text: 'Pending',
        },
        AccountApproved: {
          id: 'AccountApproved',
          color: '#67B610',
          text: 'Approved',
        },
      }
*/
export default function duplicateKeysForServerValues(base): void {
  Object.keys(base).forEach((key) => {
    const currValue = base[key];
    if (currValue.id) {
      if (Array.isArray(currValue.id)) {
        for (const id of currValue.id) {
          // eslint-disable-next-line no-prototype-builtins
          if (typeof id !== 'string' || base.hasOwnProperty(id)) {
            throw Error('id must be string and the base cant have property in that name');
          }

          base[id] = {
            ...currValue,
            id,
          };
        }
        // eslint-disable-next-line no-prototype-builtins
      } else if (typeof currValue.id !== 'string' || base.hasOwnProperty(currValue.id)) {
        throw Error('id must be string and the base cant have property in that name');
      } else {
        base[currValue.id] = currValue;
      }
    }
  });
}
