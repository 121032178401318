import React, { FC } from 'react';
import Mode from '@app/modes/Mode';
import { MobXProviderContext } from 'mobx-react';
import InfraStores from '@app/stores/InfraStores';
import { AppStoresObj, AppStoresProviderContext } from '@app/AppStoresByOrganizationProvider';
import CommonAppContext from './CommonAppContext';

export interface ModalAppContextProps {
  mode: Mode<any>;
  infraStores: InfraStores;
  appStores: AppStoresObj;
}

const ModalAppContext: FC<ModalAppContextProps> = (props) => {
  const { mode, infraStores, appStores, children } = props;

  return (
    <MobXProviderContext.Provider value={{ mode, infraStores }}>
      <AppStoresProviderContext.Provider value={appStores}>
        <CommonAppContext>{children}</CommonAppContext>
      </AppStoresProviderContext.Provider>
    </MobXProviderContext.Provider>
  );
};

export default ModalAppContext;
