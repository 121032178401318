import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import AsyncButton from '@app/components/AsyncButton';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { trim } from '@app/utils/stringUtils';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import config from '@app/config';
import DropdownItem from '@app/components/inputs/DropdownItem';
import FormlessItemBox from '@app/components/inputs/FormlessItemBox';
import { SUPPLIER_VALIDATION_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import NakedDropdownInnerInputTextArea from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputTextArea';
import useRunningComponentKey from '@app/hooks/useRunningComponentKey';

interface Props {
  onAlertSaved(): void;
  staticId: string;
  autoFocus?: boolean;
  className?: string;
}

const SupplierValidationRecordActivityLogAlertInput: FC<Props> = ({ onAlertSaved, staticId, autoFocus, className }) => {
  const [alert, setAlert] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertInputKey, nextAlertInputKey] = useRunningComponentKey('alert-input');

  function AlertHasContent(): boolean {
    return !!trim(alert);
  }

  const calculateError = (): string | undefined => {
    if (alert.length > SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.alert) {
      return `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.alert} characters`;
    }
  };

  async function handleSubmit(): Promise<void> {
    if (!AlertHasContent()) {
      return;
    }
    setIsSubmitting(true);
    await validationSystemServices.postValidationAlert(staticId, {
      alertText: alert,
    });
    setAlert('');
    nextAlertInputKey();
    setIsSubmitting(false);
    onAlertSaved();
  }

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Container className={className}>
      <FormlessItemBox underText={calculateError()} appearance='none' hasValue={!!alert} onKeyPress={handleKeyPress}>
        <NakedDropdown
          key={alertInputKey}
          accessibilityLabel='New alert'
          name='inpt-svm-add-alert'
          dataTestId='inpt-svm-add-alert'
          placeholder='New alert'
          placeholderStyle='onlyWhenEmpty'
          value={alert}
          onChange={(newValue): void => setAlert(newValue || '')}
          heightType='thin'
          popupWidth='auto'
          isSearchable
          freeSolo
          autoFocus={autoFocus}
          disabled={isSubmitting}
          InnerInputComponent={NakedDropdownInnerInputTextArea}
        >
          {config.svManagement.commonAlertList &&
            config.svManagement.commonAlertList.map((knownAlert) => {
              return (
                <DropdownItem value={knownAlert} key={knownAlert} textWhenSelected={knownAlert}>
                  {knownAlert}
                </DropdownItem>
              );
            })}
        </NakedDropdown>
      </FormlessItemBox>
      <Actions>
        <AsyncButton
          id='btn-timeline'
          dataTestId='btn-timeline-alert'
          onClick={(): Promise<void> => handleSubmit()}
          size='small'
          disabled={!AlertHasContent()}
          colorScheme={{
            main: '#FC5555',
            fullTextColor: '#ffffff',
            fullHover: '#BC0B28',
            fullActive: '#FF8181FF',
          }}
        >
          Add Alert
        </AsyncButton>
      </Actions>
    </Container>
  );
};

export default SupplierValidationRecordActivityLogAlertInput;

const Container = styled.div`
  position: relative;
  padding: 16px;

  border: solid 1px var(--transparent-black-200);
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
