import React from 'react';
import { NormalText } from '../../Styles';
import { InstructionFormDetails } from '../../commonAfterFormPage/InstructionFormDetails';

export const PoalimFormDetails: InstructionFormDetails = {
  instructionsSubTitle: 'supplierValidation.afterRegister.nowYouAreRegistered',
  paymentInstructionsTitle: 'supplierValidation.afterRegister.paymentInst',
  paymentInstructionsLines: [
    {
      description: 'supplierValidation.afterRegister.beneficiaryName',
      value: 'nsKnox Technologies, Ltd.',
    },
    {
      description: 'supplierValidation.afterRegister.address',
      value: 'Azrieli square tower, Derech Menachem begin 132, Tel Aviv 6702501',
    },
    { description: 'supplierValidation.afterRegister.checkingAccount', value: '94443' },
    { description: 'supplierValidation.afterRegister.swift', value: 'POALILIT' },
    { description: 'supplierValidation.afterRegister.iban', value: 'IL530121700000000094443' },
    { description: 'supplierValidation.afterRegister.branchNumber', value: '170' },
  ],
  paymentInstructionsAddress: {
    description: 'supplierValidation.afterRegister.bankAndAddress',
    value: (
      <NormalText>
        Bank Hapoalim,
        <br />
        Rothschild Blvd 50,
        <br />
        Tel Aviv-Yafo, 61000
      </NormalText>
    ),
  },
  currencies: [
    'USD',
    'EUR',
    'GBP',
    'CHF',
    'AUD',
    'NZD',
    'CAD',
    'JPY',
    'MXN',
    'SGD',
    'DKK',
    'NOK',
    'SEK',
    'TRY',
    'ZAR',
    'HKD',
    'CNY',
    'ILS',
    'CZK',
    'HUF',
    'RUB',
    'PLN',
    'THB',
    'RON',
    'MAD',
    'ISK',
  ],
  pleaseNote: {
    pleaseNoteTitle: 'supplierValidation.afterRegister.pleaseNote',
    pleaseNoteItem: [
      'supplierValidation.afterRegister.transferMustBe',
      'supplierValidation.afterRegister.poalim.supportedCurrencies',
    ],
  },
  showWireTransferWarningBox: {
    achWireTransfer: false,
    swiftWireTransfer: false,
  },
  extraInstructions: null,
};
