import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import UserInfo from '@app/components/sidebar/UserInfo';
import LogoImage from '@app/images/nsknox_logo.svg';
import SVG from '@app/components/SVG';
import { RowSeparator } from '@app/components/Styles';
import { Link } from 'react-router-dom';
import { groupBy } from '@app/utils/utils';
import SectionCategory from '@app/components/sidebar/SectionCategory';
import SeparatorList from '@app/components/SeparatorList';
import useInfraStores from '@app/hooks/useInfraStores';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  className?: string;
}

const Sidebar: FunctionComponent<HeaderProps> = observer((props) => {
  const { navigationStore, permissionsStore } = useInfraStores();
  const { t } = useTranslation();

  const selectedChanged = (sectionId: string): void => {
    navigationStore.moveToSection(sectionId);
  };

  const { selectedSection } = navigationStore;

  const sectionsByCategory = groupBy(permissionsStore.visibleSections, (section) => section.category);

  return (
    <BackgroundContainer className={props.className}>
      <LogoTitle to={{ pathname: navigationStore.initialRoutePath }}>
        <StyledLogo accessibilityLabel={t('general.accessibility.homeButton')} image={LogoImage} />
      </LogoTitle>
      <Sections>
        <SeparatorList
          items={Array.from(sectionsByCategory.entries())}
          keyGetter={([category]): string => category ?? ''}
          separator={<LightRowSeparator />}
        >
          {([sectionCategory, sectionsOfCategory]): ReactNode => (
            <StyledSectionCategory
              category={sectionCategory}
              sections={sectionsOfCategory}
              selectedSectionId={selectedSection?.id}
              selectedSectionChanged={selectedChanged}
            />
          )}
        </SeparatorList>
      </Sections>
      <LightRowSeparator />
      <StyledUserInfo />
    </BackgroundContainer>
  );
});

export default Sidebar;

const BackgroundContainer = styled.aside`
  background-color: var(--app-main-color);
  z-index: 15;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-width: 200px;
`;

const LogoTitle = styled(Link)`
  flex: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Sections = styled.div`
  flex: 1;
  overflow-y: auto;

  --scrollbar-thumb-color: rgba(255, 255, 255, 0.3);
`;

const LightRowSeparator = styled(RowSeparator)`
  background-color: rgba(245, 246, 250, 0.1);
  margin-left: 24px;
  margin-right: 24px;
`;

const StyledSectionCategory = styled(SectionCategory)`
  padding: 38px 0;

  &:first-child {
    padding: 16px 0 38px;
  }
`;

const StyledLogo = styled(SVG)`
  height: 36px;
  margin-top: 20px;
  margin-bottom: 24px;
  align-self: center;
`;

const StyledUserInfo = styled(UserInfo)`
  flex: 0;
  padding: 22px 24px;
`;
