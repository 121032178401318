import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { PaymentCheckBatchServerResponse, SinglePaymentServerResponse } from '@mortee/domain/paymentsConsts';

const paymentCheckService = {
  storePaymentBatch(data: FormData): Promise<PaymentCheckBatchServerResponse[]> {
    return request<PaymentCheckBatchServerResponse[]>(serverUrl, `/api/payments/batches`, {
      method: HttpMethod.post,
      isFileUpload: true,
      data: data,
      timeout: 900000,
      suppressNotification: true,
    });
  },

  async getBatchesByIndex(batchOffset: number, limit: number): Promise<PaginatedData<PaymentCheckBatchServerResponse>> {
    return request<PaginatedData<PaymentCheckBatchServerResponse>>(serverUrl, `/api/payments/batches`, {
      method: HttpMethod.get,
      params: { offset: batchOffset, limit },
      headers: {
        'Api-Version': 'v2',
      },
      errorsHandler: {
        default: {
          message: 'Error fetching payment batches.',
        },
      },
    });
  },

  async getBatchById(batchId: string): Promise<PaymentCheckBatchServerResponse> {
    return request<PaymentCheckBatchServerResponse>(serverUrl, `/api/payments/batches/:batchId`, {
      method: HttpMethod.get,
      pathParams: {
        batchId,
      },
      errorsHandler: {
        default: {
          message: 'Error fetching payment batch.',
        },
      },
    });
  },

  getRejectedPaymentsOfBatch(batchId: string): Promise<SinglePaymentServerResponse[]> {
    return request<SinglePaymentServerResponse[]>(serverUrl, `/api/payments/batches/:batchId/payments`, {
      method: HttpMethod.get,
      pathParams: {
        batchId,
      },
      params: {
        queryType: 'RejectedPayments',
      },
      errorsHandler: {
        default: {
          message: 'Error fetching payments for batch.',
        },
      },
    });
  },

  fetchBatchFile(batchId: string, fileId: string): Promise<NamedResource> {
    return request<NamedResource>(serverUrl, `/api/payments/batches/:batchId/files/:fileId`, {
      method: HttpMethod.get,
      pathParams: {
        batchId,
        fileId,
      },
      errorsHandler: {
        default: {
          message: 'Error while downloading payment file.',
        },
      },
    });
  },

  fetchExportPaymentFile(batchId: string): Promise<NamedResource> {
    return request<NamedResource>(serverUrl, `/api/payments/batches/:batchId/report`, {
      method: HttpMethod.get,
      pathParams: {
        batchId,
      },
      errorsHandler: {
        PAYMENT_BATCH_IN_PROGRESS: {
          message: (): string => 'The payment file is still being processed.',
        },
        default: {
          message: 'Error while exporting payments.',
        },
      },
    });
  },
};

export default paymentCheckService;
