import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import Card from '@app/components/card/Card';
import { Form } from 'antd';
import FormItemBox from '@app/components/inputs/FormItemBox';
import styled from '@emotion/styled';
import { FormComponentProps } from 'antd/es/form/Form';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import { generateKnoxId } from '@mortee/services/validatedPayeeManagementServices';
import { H6StartTransparentBlack800 } from '@app/components/Text';
import useForm from '@app/hooks/useForm';
import Button from '@app/components/Button';
import MorteeMode from '@mortee/morteeMode';
import DatePicker, {
  convertDatePickerValueToEpoch,
  DatePickerValue,
  datePickerValueFullDateRepresentationValidator,
  datePickerValueNotFutureTimestampValidator,
  isPastDate,
} from '@app/components/inputs/DatePicker';
import LegalIdInput from '@mortee/routes/validatedPayeesManagement/LegalIdInput';
import {
  LegalIdentifierRequest,
  SanctionsScreeningRequestInfo,
  StoreValidatedPayeeRequest,
} from '@mortee/domain/vaildatedPayeeManagement';
import { distinctValues, wrapValueAsArray } from '@app/utils/arrayUtils';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import {
  convertLeiRequestToLEIWithName,
  getTextFromSanctionStatus,
  injectTypeIntoStrongLegalIdRequest,
  SanctionsScreeningResultStatus,
} from '@app/domain/validatedPayee';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import { removeFirstLeadingKnoxPrefix, trim, trimToUndefined } from '@app/utils/stringUtils';
import { allOrNoneFieldsValidator, dunsValidator, regularLegalIdValidator, taxIdValidator } from '@app/utils/validators';
import { FormFieldDecorators } from '@app/utils/form/form';
import { cleanDunsLegalId, cleanLegalId } from '@app/utils/legalIdentifierUtils';
import { isDefined, isTruthy } from '@app/utils/utils';
import { LegalEntityIdentifier, StrongLegalIdentifiesTypes } from '@app/domain/legalEntityIdentifier';
import { showCustomModalAsync } from '@app/components/Modal';
import ModalAppContext from '@app/ModalAppContext';
import StoreValidatedPayeeConfirmationModal from '@mortee/routes/validatedPayeesManagement/StoreValidatedPayeeConfirmationModal';
import useModalContext from '@app/hooks/useModalContext';
import { KnoxIdOrigin } from '@mortee/stores/appStores/ValidatedPayeesManagementStore';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { SUPPLIER_REGISTRATION_PATTERNS } from '@app/domain/commonSupplierRegistrationFields';
import useAppStores from '@app/hooks/useAppStores';

export interface ValidatedPayeeFormFields {
  primaryName: string;
  additionalName?: string;
  primaryAddress?: string;
  additionalAddress?: string;
  knoxIdOrigin: KnoxIdOrigin;
  knoxIdSuffix?: string;
  existingKnoxId?: string;
  duns?: LegalIdentifierRequest;
  taxId1?: LegalIdentifierRequest;
  taxId2?: LegalIdentifierRequest;
  legalId1?: LegalIdentifierRequest;
  legalId2?: LegalIdentifierRequest;
  legalId3?: LegalIdentifierRequest;
  countryCode: string;
  sanctionsScreeningTimestamp?: DatePickerValue;
  sanctionsStatus?: SanctionsScreeningResultStatus;
  supplierValidationRegistrationNumber?: string;
}

export interface ValidatedPayeeEventData {
  ownerOrgId: string | null;
  uniformId: string;
  originatingValidatedPayeeAccountEventId: string | null;
}

interface Props extends FormComponentProps<ValidatedPayeeFormFields> {
  initialValues?: ValidatedPayeeFormFields;
  payeeEventData?: ValidatedPayeeEventData;
  storeValidatedPayee(payload): Promise<void>;
}

const ValidatedPayeeForm: FC<Props> = observer((props) => {
  const { initialValues, payeeEventData, storeValidatedPayee } = props;
  const { validatedPayeesManagementStore } = useAppStores<MorteeMode>();
  const { form, showFormErrors, setShowFormErrors, isFormInvalid } = useForm(props);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const allLEITypesLoadable = validatedPayeesManagementStore.allLeiTypes;
  const modalContext = useModalContext();
  const isEditMode = !!payeeEventData;

  const submitDisableClick = (): void => {
    setShowFormErrors('all');
    form.validateFields();
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Record<string, unknown>, values: ValidatedPayeeFormFields) => {
      if (errors) {
        return;
      }

      createOrUpdateValidatedPayee(values);
    });
  };

  const sanctionsScreeningTimestamp = form.getFieldValue('sanctionsScreeningTimestamp');
  const isPastSanctionsDate = isPastDate(sanctionsScreeningTimestamp);

  function calculateSanctionsScreeningRequestInfo(): SanctionsScreeningRequestInfo | undefined {
    const epochTimestamp = convertDatePickerValueToEpoch(sanctionsScreeningTimestamp);
    if (epochTimestamp) {
      return {
        timestamp: epochTimestamp,
        result: form.getFieldValue('sanctionsStatus') ?? null,
      };
    }
  }

  const calcKnoxUniqueIdForValidatedPayee = async (
    knoxIdOrigin: KnoxIdOrigin,
    existingKnoxIdNumber: string | undefined,
    knoxIdSuffix: string | undefined,
  ): Promise<string> => {
    switch (knoxIdOrigin) {
      case KnoxIdOrigin.existing: {
        if (!existingKnoxIdNumber) {
          throw new Error('Empty existingKnoxId');
        }

        return removeFirstLeadingKnoxPrefix(existingKnoxIdNumber);
      }
      case KnoxIdOrigin.generate: {
        const knoxIdServerResponse = await generateKnoxId(trimToUndefined(knoxIdSuffix));
        return knoxIdServerResponse.id;
      }
    }
  };

  const createOrUpdateValidatedPayee = async (formFields: ValidatedPayeeFormFields): Promise<void> => {
    setIsSaving(true);

    const {
      primaryName,
      additionalName,
      primaryAddress,
      additionalAddress,
      knoxIdOrigin,
      knoxIdSuffix,
      existingKnoxId,
      duns,
      taxId1,
      taxId2,
      legalId1,
      legalId2,
      legalId3,
      countryCode,
      supplierValidationRegistrationNumber,
    } = formFields;

    const names = distinctValues([primaryName, additionalName].map(trim).filter(isTruthy));
    const addresses = distinctValues([additionalAddress, primaryAddress].map(trim).filter(isTruthy));
    const inputTaxId1 = cleanLegalId(
      injectTypeIntoStrongLegalIdRequest(allLEITypesLoadable, taxId1, StrongLegalIdentifiesTypes.TaxId),
    );
    const inputTaxId2 = cleanLegalId(
      injectTypeIntoStrongLegalIdRequest(allLEITypesLoadable, taxId2, StrongLegalIdentifiesTypes.TaxId),
    );
    const inputTaxIds = [inputTaxId1, inputTaxId2].filter(isDefined);
    const inputDuns = cleanLegalId(
      injectTypeIntoStrongLegalIdRequest(allLEITypesLoadable, cleanDunsLegalId(duns), StrongLegalIdentifiesTypes.DUNS),
    );
    const inputRegularLegalIds = [legalId1, legalId2, legalId3].map(cleanLegalId).filter(isDefined);
    const primaryLegalIdentifier = inputTaxIds[0];
    const legalIdentifiers = [...inputTaxIds, inputDuns, ...inputRegularLegalIds].filter(isDefined);
    const dunsIdToDisplay = convertLeiRequestToLEIWithName(allLEITypesLoadable, inputDuns);
    const taxIdToDisplay = inputTaxIds
      .map((taxId) => convertLeiRequestToLEIWithName(allLEITypesLoadable, taxId))
      .filter(isDefined);
    const regularLegalIdsToDisplay = inputRegularLegalIds
      .map((lei) => convertLeiRequestToLEIWithName(allLEITypesLoadable, lei))
      .filter(isDefined);

    const knoxUniqueId = await calcKnoxUniqueIdForValidatedPayee(knoxIdOrigin, existingKnoxId, knoxIdSuffix);

    const knoxIdToDisplay: LegalEntityIdentifier = {
      value: `KNOX${(existingKnoxId && removeFirstLeadingKnoxPrefix(existingKnoxId)) || '????????'}`,
      typeName: allLEITypesLoadable.result?.strongTypes.KnoxId?.name,
      typeId: undefined,
      countryCode: undefined,
    };

    const payload: StoreValidatedPayeeRequest = {
      originatingValidatedPayeeAccountEventId: payeeEventData?.originatingValidatedPayeeAccountEventId ?? null,
      uniformId: payeeEventData?.uniformId ?? null,
      ownerOrgId: payeeEventData?.ownerOrgId ?? null,
      countryCode: countryCode,
      names: names,
      addresses: addresses,
      phones: [],
      emails: [],
      faxes: [],
      websites: [],
      description: undefined,
      legalIdentifiers: legalIdentifiers,
      primaryFields: {
        address: trimToUndefined(primaryAddress),
        name: trimToUndefined(primaryName),
        legalIdentifier: primaryLegalIdentifier,
      },
      knoxUniqueId: knoxUniqueId,
      sanctionsScreeningRequestInfo: calculateSanctionsScreeningRequestInfo(),
      supplierValidationRegistrationNumber: supplierValidationRegistrationNumber || null,
    };

    try {
      const alerts = await validatedPayeesManagementStore.calcPayeeCreationAlerts(payload, payeeEventData?.uniformId);

      const shouldSave = await showCustomModalAsync(
        (onAccept, onDecline) => {
          return (
            <ModalAppContext {...modalContext}>
              <StoreValidatedPayeeConfirmationModal
                isEditMode={isEditMode}
                payeeDetails={{
                  ...payload,
                  mainName: trimToUndefined(primaryName),
                  legalIdentifiers: [knoxIdToDisplay, ...taxIdToDisplay, dunsIdToDisplay, ...regularLegalIdsToDisplay].filter(
                    isDefined,
                  ),
                  primaryFields: {
                    address: trimToUndefined(primaryAddress),
                    name: trimToUndefined(primaryName),
                    legalIdentifier: convertLeiRequestToLEIWithName(allLEITypesLoadable, primaryLegalIdentifier),
                  },
                  strongLegalIdentifies: {
                    [StrongLegalIdentifiesTypes.TaxId]: taxIdToDisplay,
                    [StrongLegalIdentifiesTypes.DUNS]: wrapValueAsArray(dunsIdToDisplay),
                    [StrongLegalIdentifiesTypes.KnoxId]: [knoxIdToDisplay],
                  },
                  sanctionsScreeningInfo: {
                    timestamp: payload.sanctionsScreeningRequestInfo?.timestamp ?? null,
                    results: null,
                  },
                  sanctionsScreeningStatusResult: payload.sanctionsScreeningRequestInfo?.result ?? null,
                }}
                alerts={alerts}
                onConfirm={onAccept}
                onClose={onDecline}
              />
            </ModalAppContext>
          );
        },
        { maskClosable: false },
      );

      if (!shouldSave) {
        return;
      }

      await storeValidatedPayee(payload);
    } finally {
      setIsSaving(false);
    }
  };

  const resetSanctions = (): void => {
    form.setFieldsValue({
      sanctionsStatus: undefined,
      sanctionsScreeningTimestamp: undefined,
    } as Partial<ValidatedPayeeFormFields>);
  };

  const knoxIdOrigin = form.getFieldValue('knoxIdOrigin');
  const payeeCountryCode = form.getFieldValue('countryCode');

  function renderFormFields(): ReactElement {
    const fieldDecorators = createFieldDecorators();

    return (
      <WrappingColumns>
        <SmallColumn>
          <FieldsTitle>General Info</FieldsTitle>
          <FormItemBox fieldName='primaryName' fieldDecoratorOptions={fieldDecorators.primaryName} showAsRequired>
            <NakedFormInput
              name='txt-validated-payee-names-primary'
              dataTestId='txt-validated-payee-names-primary'
              type='text'
              placeholder='Primary Name'
              disableSuggestion
            />
          </FormItemBox>
          <FormItemBox fieldName='additionalName' fieldDecoratorOptions={fieldDecorators.additionalName}>
            <NakedFormInput
              name='txt-validated-payee-names-others'
              dataTestId='txt-validated-payee-names-others'
              type='text'
              placeholder='Additional Name'
              disableSuggestion
            />
          </FormItemBox>
          <FormItemBox fieldName='primaryAddress' fieldDecoratorOptions={fieldDecorators.primaryAddress}>
            <NakedFormInput
              name='txt-validated-payee-addresses-primary'
              dataTestId='txt-validated-payee-addresses-primary'
              type='text'
              placeholder='Primary Address'
              disableSuggestion
            />
          </FormItemBox>
          <FormItemBox fieldName='additionalAddress' fieldDecoratorOptions={fieldDecorators.additionalAddress}>
            <NakedFormInput
              name='txt-validated-payee-addresses-others'
              dataTestId='txt-validated-payee-addresses-others'
              type='text'
              placeholder='Additional Address'
              disableSuggestion
            />
          </FormItemBox>
          <FormItemBox fieldName='countryCode' fieldDecoratorOptions={fieldDecorators.countryCode} showAsRequired>
            <CountryDropdown
              accessibilityLabel='countryCode'
              name='drp-validated-payee-countryCode'
              dataTestId='drp-validated-payee-countryCode'
            />
          </FormItemBox>
          <FieldsTitle>Supplier Validation</FieldsTitle>
          <FormItemBox
            fieldName='supplierValidationRegistrationNumber'
            fieldDecoratorOptions={fieldDecorators.supplierValidationRegistrationNumber}
          >
            <NakedFormInput
              name='inpt-validated-payee-supplier-validation-registration-number'
              dataTestId='inpt-validated-payee-supplier-validation-registration-number'
              type='string'
              placeholder='Registration Number'
              disableSuggestion
            />
          </FormItemBox>
          <TitleContainer>
            <FieldsTitle>Sanctions Screening Date</FieldsTitle>
            <ResetButton appearance='text' id='validated-payee-reset-sanctions-fields' onClick={resetSanctions}>
              Reset Sanctions
            </ResetButton>
          </TitleContainer>
          <SanctionsScreeningTimestampDiv>
            <FormItemBox
              fieldName='sanctionsScreeningTimestamp'
              fieldDecoratorOptions={fieldDecorators.sanctionsScreeningTimestamp}
              appearance='none'
            >
              <DatePicker
                appearance='corners'
                id='date-picker-sanctions-date'
                placeholderStyle='onlyWhenEmpty'
                heightType='thin'
                accessibilityLabel='Sanctions Screening Date'
              />
            </FormItemBox>
            {isPastSanctionsDate && <DateWarning>Note the date provided is not the current date</DateWarning>}
          </SanctionsScreeningTimestampDiv>
          <FormItemBox fieldName='sanctionsStatus' appearance='none' fieldDecoratorOptions={fieldDecorators.sanctionsStatus}>
            <FormRadioGroup
              id='radio-group-sanctions-status'
              dataTestId='radio-group-sanctions-status'
              accessibilityLabel='Sanctions Screening Result Status'
              options={[
                {
                  value: SanctionsScreeningResultStatus.Passed,
                  label: getTextFromSanctionStatus(SanctionsScreeningResultStatus.Passed),
                },
                {
                  value: SanctionsScreeningResultStatus.Rejected,
                  label: getTextFromSanctionStatus(SanctionsScreeningResultStatus.Rejected),
                },
              ]}
            />
          </FormItemBox>
        </SmallColumn>
        <BigColumn>
          <FieldsTitle>Strong Legal Ids</FieldsTitle>
          <KnoxIdRow>
            <FormItemBox
              disabled={isEditMode}
              fieldName='knoxIdOrigin'
              appearance='none'
              fieldDecoratorOptions={fieldDecorators.knoxIdOrigin}
            >
              <FormRadioGroup
                id='radio-group-knox-id-origin'
                dataTestId='radio-group-knox-id-origin'
                accessibilityLabel='Knox id origin'
                options={[
                  { value: KnoxIdOrigin.generate, label: 'Generate Knox Id' },
                  { value: KnoxIdOrigin.existing, label: 'Existing Knox Id' },
                ]}
              />
            </FormItemBox>
            {knoxIdOrigin === KnoxIdOrigin.generate ? (
              <KnoxIdTextFormItemBox fieldName='knoxIdSuffix' fieldDecoratorOptions={fieldDecorators.knoxIdSuffix}>
                <NakedFormInput
                  name={`txt-validated-payee-new-knox-id-suffix`}
                  dataTestId={`txt-validated-payee-new-knox-id-suffix`}
                  type='text'
                  placeholder='Knox Id Suffix'
                  disableSuggestion
                />
              </KnoxIdTextFormItemBox>
            ) : (
              <KnoxIdTextFormItemBox
                disabled={isEditMode}
                fieldName='existingKnoxId'
                fieldDecoratorOptions={fieldDecorators.existingKnoxId}
                showAsRequired
              >
                <NakedFormInput
                  name={`txt-validated-payee-existing-knox-id`}
                  dataTestId={`txt-validated-payee-existing-knox-id`}
                  type='text'
                  placeholder='Existing Knox Id'
                  disableSuggestion
                />
              </KnoxIdTextFormItemBox>
            )}
          </KnoxIdRow>
          <FormItemBox fieldName='duns' fieldDecoratorOptions={fieldDecorators.duns}>
            <LegalIdInput
              accessibilityLabel='Duns legal entity id'
              name='validated-payee-duns'
              showCountry={false}
              legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.DUNS))}
            />
          </FormItemBox>
          <FormItemBox fieldName='taxId1' fieldDecoratorOptions={fieldDecorators.taxId1}>
            <LegalIdInput
              accessibilityLabel='First tax id'
              name='validated-payee-tax-id-1'
              showCountry
              currentPayeeCountryCode={payeeCountryCode}
              legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.TaxId))}
            />
          </FormItemBox>
          <FormItemBox fieldName='taxId2' fieldDecoratorOptions={fieldDecorators.taxId2}>
            <LegalIdInput
              accessibilityLabel='second tax id'
              name='validated-payee-tax-id-2'
              showCountry
              currentPayeeCountryCode={payeeCountryCode}
              legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.TaxId))}
            />
          </FormItemBox>
          <FieldsTitle>Regular Legal Ids</FieldsTitle>
          <FormItemBox fieldName='legalId1' fieldDecoratorOptions={fieldDecorators.legalId1}>
            <LegalIdInput
              accessibilityLabel='first legal entity id'
              name='validated-payee-legal-id-1'
              showCountry
              currentPayeeCountryCode={payeeCountryCode}
              legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
            />
          </FormItemBox>
          <FormItemBox fieldName='legalId2' fieldDecoratorOptions={fieldDecorators.legalId2}>
            <LegalIdInput
              accessibilityLabel='second legal entity id'
              name='validated-payee-legal-id-2'
              showCountry
              currentPayeeCountryCode={payeeCountryCode}
              legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
            />
          </FormItemBox>
          <FormItemBox fieldName='legalId3' fieldDecoratorOptions={fieldDecorators.legalId3}>
            <LegalIdInput
              accessibilityLabel='third legal entity id'
              name='validated-payee-legal-id-3'
              showCountry
              currentPayeeCountryCode={payeeCountryCode}
              legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
            />
          </FormItemBox>
        </BigColumn>
      </WrappingColumns>
    );
  }

  function createFieldDecorators(): FormFieldDecorators<ValidatedPayeeFormFields> {
    return {
      primaryName: {
        initialValue: initialValues?.primaryName,
        rules: [
          {
            required: true,
            transform: trim,
            message: 'Missing Name',
          },
        ],
      },
      additionalName: {
        initialValue: initialValues?.additionalName,
      },
      primaryAddress: {
        initialValue: initialValues?.primaryAddress,
      },
      additionalAddress: {
        initialValue: initialValues?.additionalAddress,
      },
      taxId1: {
        initialValue: initialValues?.taxId1,
        rules: [
          {
            transform: cleanLegalId,
            validator: taxIdValidator,
          },
        ],
      },
      taxId2: {
        initialValue: initialValues?.taxId2,
        rules: [
          {
            transform: cleanLegalId,
            validator: taxIdValidator,
          },
        ],
      },
      legalId1: {
        initialValue: initialValues?.legalId1,
        rules: [
          {
            transform: cleanLegalId,
            validator: regularLegalIdValidator,
          },
        ],
      },
      legalId2: {
        initialValue: initialValues?.legalId2,
        rules: [
          {
            transform: cleanLegalId,
            validator: regularLegalIdValidator,
          },
        ],
      },
      legalId3: {
        initialValue: initialValues?.legalId3,
        rules: [
          {
            transform: cleanLegalId,
            validator: regularLegalIdValidator,
          },
        ],
      },
      duns: {
        initialValue: initialValues?.duns,
        rules: [
          {
            transform: cleanDunsLegalId,
            validator: dunsValidator,
          },
        ],
      },
      knoxIdOrigin: {
        initialValue: initialValues?.knoxIdOrigin || KnoxIdOrigin.generate,
        rules: [
          {
            required: true,
            type: 'string',
            message: 'Required',
          },
        ],
      },
      existingKnoxId: {
        initialValue: initialValues?.existingKnoxId,
        rules: [
          {
            required: true,
            enabled: knoxIdOrigin === KnoxIdOrigin.existing,
            message: 'Enter a knox id or choose to generate',
          },
          {
            pattern: VALIDATION_PATTERNS.fullKnoxIdOrKnoxIdDigits,
            transform: trim,
            message: 'Invalid Knox Id',
          },
        ],
      },
      knoxIdSuffix: {
        rules: [
          {
            pattern: VALIDATION_PATTERNS.alphanumeric,
            transform: trim,
            message: 'Must be alphanumeric',
          },
        ],
      },
      countryCode: {
        initialValue: initialValues?.countryCode,
        rules: [
          {
            required: true,
            transform: trim,
            message: 'Missing Country',
          },
        ],
      },
      sanctionsScreeningTimestamp: {
        initialValue: initialValues?.sanctionsScreeningTimestamp,
        rules: [
          {
            type: 'object',
          },
          {
            validator: allOrNoneFieldsValidator(form, ['sanctionsStatus'], 'Missing Sanctions Date'),
          },
          datePickerValueNotFutureTimestampValidator(),
          datePickerValueFullDateRepresentationValidator(),
        ],
      },
      sanctionsStatus: {
        initialValue: initialValues?.sanctionsStatus,
        rules: [
          {
            validator: allOrNoneFieldsValidator(form, ['sanctionsScreeningTimestamp'], 'Missing Sanctions Status'),
          },
        ],
      },
      supplierValidationRegistrationNumber: {
        initialValue: initialValues?.supplierValidationRegistrationNumber,
        rules: [
          {
            transform: trim,
            pattern: SUPPLIER_REGISTRATION_PATTERNS.registrationNumber,
            message: 'Invalid registration number, must be in the format SV-XXXXXXXX',
          },
        ],
      },
    };
  }

  return (
    <StyledForm
      form={form}
      appearance='corners'
      showErrors={showFormErrors}
      disabled={isSaving}
      setShowErrors={setShowFormErrors}
    >
      <StyledCard>
        {renderFormFields()}
        <SumbitButton
          id='btn-submit-validated-payee'
          disabled={isFormInvalid || (isEditMode && !form.isFieldsTouched())}
          onClick={handleOk}
          loading={isSaving}
          onDisabledClick={submitDisableClick}
        >
          {isEditMode ? 'UPDATE' : 'CREATE'}
        </SumbitButton>
      </StyledCard>
    </StyledForm>
  );
});

export default Form.create<Props>()(ValidatedPayeeForm);

const StyledForm = styled(NsknoxForm)`
  display: flex;
  align-items: stretch;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 34px;
  }
`;

const StyledCard = styled(Card)`
  padding: 24px 32px;
`;

const WrappingColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;

  & > * {
    margin-left: 32px;
  }
`;

const FieldsTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 8px;
`;

const SumbitButton = styled(Button)`
  align-self: center;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;
`;

const SmallColumn = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 500px;
`;

const BigColumn = styled.div`
  flex: 2;
  min-width: 500px;
`;

const DateWarning = styled.div`
  margin-top: -17px;
  position: absolute;
  color: var(--accent-orange-400);
`;

const SanctionsScreeningTimestampDiv = styled.div`
  position: relative;
`;

const ResetButton = styled(Button)`
  margin-top: -10px;
`;

const KnoxIdRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const KnoxIdTextFormItemBox = styled(FormItemBox)`
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
