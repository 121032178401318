import { ColumnProps } from 'antd/lib/table';
import { getColumnConstFilterProps } from './constsFilterCreator';

export enum BooleanFilterOptions {
  all = 'all',
  onlyTrue = 'onlyTrue',
  onlyFalse = 'onlyFalse',
}

export type OptionToTextMapper = (booleanFilterOptions: BooleanFilterOptions) => string;

export function getColumnBooleanFilterProps<T>(
  radiobuttonGroupAccessibilityLabel: string,
  booleanFieldGetter: (item: T) => boolean,
  optionToTextMapper: OptionToTextMapper,
): Partial<ColumnProps<T>> {
  const booleanFilterOptions = Object.values(BooleanFilterOptions).map((booleanFilterOptions: BooleanFilterOptions) => ({
    label: optionToTextMapper(booleanFilterOptions),
    value: booleanFilterOptions,
  }));

  return getColumnConstFilterProps(
    radiobuttonGroupAccessibilityLabel,
    booleanFilterOptions,
    (booleanFilterOptions: BooleanFilterOptions, record): boolean => {
      const value = booleanFieldGetter(record);

      switch (booleanFilterOptions) {
        case BooleanFilterOptions.all: {
          return true;
        }
        case BooleanFilterOptions.onlyTrue: {
          return value;
        }
        case BooleanFilterOptions.onlyFalse: {
          return !value;
        }
      }

      return false;
    },
    BooleanFilterOptions.all,
  );
}
