import React, { FunctionComponent, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { RowSeparator } from '@app/components/Styles';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import SearchIcon from '@app/images/ic-search-primary.svg';
import SVG from '@app/components/SVG';
import { useTranslation } from 'react-i18next';

interface Props {
  userOrganizations: UserOrganization[];
  onOrganizationClicked: (organizationId) => void;
  className?: string;
}

const OrganizationsSelectionList: FunctionComponent<Props> = observer(
  ({ userOrganizations, onOrganizationClicked, className }) => {
    const [orgSearch, setOrgSearch] = useState<string>('');
    const { t } = useTranslation();

    const filteredOrganizations = userOrganizations.filter((org) =>
      org.data.name.toLowerCase().includes(orgSearch.toLowerCase()),
    );

    const minOrgsNumberForDisplayingSearch = 3;
    const allowFilterOrganizations = userOrganizations.length > minOrgsNumberForDisplayingSearch;

    const renderSearch = (): ReactNode => {
      return (
        <React.Fragment>
          <SearchInputContainer>
            <StyledInput
              name='org-live-srch'
              dataTestId='org-live-srch'
              type='text'
              colorScheme='primary'
              placeholderStyle='onlyWhenEmpty'
              heightType='thin'
              clearable
              placeholder='Search organization name'
              value={orgSearch}
              onChange={(newValue): void => setOrgSearch(newValue ?? '')}
              autoFocus
            />
            <StyledSearchIcon accessibilityLabel={t('general.search')} image={SearchIcon} />
          </SearchInputContainer>
          <RowSeparator />
        </React.Fragment>
      );
    };

    return (
      <div className={className}>
        {allowFilterOrganizations && renderSearch()}
        <OtherOrganizationsWrapper allowFilterOrganizations={allowFilterOrganizations}>
          {filteredOrganizations.map((organization) => (
            <React.Fragment key={organization.id}>
              <OtherOrganizationButton
                id={`btn-sidebar-switch-organization-${organization.id}`}
                appearance='text'
                cornerType='none'
                onClick={(): void => onOrganizationClicked(organization.id)}
              >
                {organization.data.name}
              </OtherOrganizationButton>
              <RowSeparator />
            </React.Fragment>
          ))}
        </OtherOrganizationsWrapper>
      </div>
    );
  },
);

export default OrganizationsSelectionList;

const OtherOrganizationsWrapper = styled.div<{ allowFilterOrganizations: boolean }>`
  align-self: stretch;
  max-height: 46vh;
  overflow-y: scroll;
  ${(p): string => (p.allowFilterOrganizations ? 'height: 200px;' : '')}
`;

const OtherOrganizationButton = styled(Button)`
  display: grid;
  width: 100%;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  padding: 22px 24px 20px;

  font-size: 16px;
  letter-spacing: 0.22px;
  color: var(--gray-blue-deprecated);
`;

const StyledInput = styled(NakedFormInput)`
  flex: 1;
`;

const StyledSearchIcon = styled(SVG)`
  width: 18px;
  height: 18px;
  align-self: center;
`;

const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 12px;
`;
