import ThemeStore from '@app/stores/ThemeStore';
import { css, SerializedStyles } from '@emotion/react';
import { computed, makeObservable } from 'mobx';
import { colorSchemeFromMainColor, textColorSchemeFromMainColor } from '@app/domain/theme';
import Color from 'color';
import { ARTheme, defaultARTheme } from '@ar/domain/arTheme';
import { ARThemeConfiguration } from '@ar/services/arServices';
import LanguageStore from '@app/stores/LanguageStore';

export default class ARThemeStore extends ThemeStore<ARTheme> {
  private _languageStore: LanguageStore;

  constructor(languageStore: LanguageStore) {
    super(defaultARTheme);
    this._languageStore = languageStore;
    makeObservable(this);
  }

  setThemeFromServer(serverTheme: ARThemeConfiguration): void {
    const isHeaderDark = Color(serverTheme.headerColor).isDark();
    const continueButtonColor = serverTheme.continueButtonColor || serverTheme.actionColor;

    this.setTheme({
      isHeaderDark,
      headerColor: colorSchemeFromMainColor(serverTheme.headerColor),
      headerTextColor: textColorSchemeFromMainColor(serverTheme.headerColor),
      actionColor: colorSchemeFromMainColor(serverTheme.actionColor),
      continueButtonColor: colorSchemeFromMainColor(continueButtonColor),
      inputBoxAppearance: serverTheme.inputStyle ?? defaultARTheme.inputBoxAppearance,
      brand: {
        logo: serverTheme.logo,
        clientName: serverTheme.clientName,
        additionalLogos: serverTheme.additionalLogos,
      },
      fontFamily: serverTheme.fontFamily,
      backgroundColor: serverTheme.backgroundColor,
    });
  }

  @computed
  get modeStyles(): SerializedStyles {
    const fontFamily =
      this._languageStore.selectedLanguageHasDialectCharacters || !this.theme.fontFamily
        ? ''
        : `--text-font-family: ${this.theme.fontFamily};
            --title-font-family: ${this.theme.fontFamily};`;

    const backgroundColor = !this.theme.backgroundColor ? '' : `--root-background-color: ${this.theme.backgroundColor};`;

    return css`
      :root {
        --app-main-color: var(--primary-200);
        --header-color: ${this.theme.headerColor.main};
        --header-text-color: ${this.theme.headerColor.fullTextColor};
        --action-color: ${this.theme.actionColor.main};
        --action-color-hover: ${this.theme.actionColor.fullHover};
        --action-color-active: ${this.theme.actionColor.fullActive};
        --action-text-color: ${this.theme.actionColor.fullTextColor};
        --background-color: ${this.theme.backgroundColor};
        ${fontFamily};
        ${backgroundColor};
      }

      a {
        color: var(--action-color);
      }
    `;
  }
}
