import * as sftpService from '@mortee/services/sftpService';

import { SftpError } from '@mortee/domain/sftpConsts';

export default class SftpStore {
  getAllSftpErrors = async (): Promise<SftpError[]> => {
    const serverResponse = await sftpService.getAllSftpServerErrors();
    return serverResponse.map((sftpError) =>
      sftpService.transformSftpErrors(sftpError)
    );
  };
}
