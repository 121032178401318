import React, { ReactNode, useState } from 'react';
import ShowPasswordOffImage from '@app/images/ic-eye-off.svg';
import ShowPasswordOnImage from '@app/images/ic-eye-on.svg';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import Button from '../Button';
import { ForwardingFC } from '@app/domain/technicals/components';
import NakedFormInput from './NakedFormInput';
import { ColorScheme } from '@app/domain/theme';
import { useTranslation } from 'react-i18next';

interface StyledInputProps {
  colorScheme?: ColorScheme;
}

interface PasswordFormInputProps extends StyledInputProps {
  name: string;
  value?: string;
  placeholder: string;
  onChange?: (value: string) => void;
  maxLength?: number;
  onMaxLengthAchieved?: (value: string) => void;
  autoFocus?: boolean;
  clearable?: boolean;
  onClear?: () => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  dataTestId?: string;
  disableSuggestion?: boolean;
}

const PasswordFormInput: ForwardingFC<HTMLInputElement, PasswordFormInputProps> = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const { name, ...rest } = props;
  const { t } = useTranslation();

  const renderShowPasswordButton = (): ReactNode => (
    <ShowPasswordButton id={`btn-${name}-show-password`} appearance='text' onClick={(): void => setShowPassword(!showPassword)}>
      {showPassword ? (
        <StyledSVG accessibilityLabel={t('general.accessibility.hidePassword')} image={ShowPasswordOffImage} />
      ) : (
        <StyledSVG accessibilityLabel={t('general.accessibility.showPassword')} image={ShowPasswordOnImage} />
      )}
    </ShowPasswordButton>
  );

  return (
    <InputContainer>
      <NakedFormInput
        name={name}
        type={showPassword ? 'text' : 'password'}
        renderSideComponent={renderShowPasswordButton}
        {...rest}
        ref={ref}
      />
    </InputContainer>
  );
});

export default PasswordFormInput;

const InputContainer = styled.div`
  position: relative;
`;

const ShowPasswordButton = styled(Button)`
  align-self: center;
  padding: 0;
  margin-top: 8px;
`;

const StyledSVG = styled(SVG)`
  width: 28px;
  height: 28px;
  padding: 3px 4px;
`;
