import React, { FC, ReactNode } from 'react';
import {
  BodyRegularStartTransparentBlack900,
  BodySmallStartTransparentBlack600,
  BodySmallStartTransparentBlack900,
  Bold13TransparentBlack900,
  CaptionStartTransparentBlack600,
} from '@app/components/Text';
import { Timeline, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import TimelineCheckSpriteSymbol from '@app/images/timeline/timeline-check.svg';
import CategoryAndValues from './CategoryAndValues';
import useInfraStores from '@app/hooks/useInfraStores';

export interface EventTimelineItem {
  id: string | number;
  timestamp?: number;
  icon?: ReactNode;
  content?: ReactNode;
}

interface Props {
  items: EventTimelineItem[];
}

const EventTimeline: FC<Props> = ({ items }) => {
  const { localeStore } = useInfraStores();

  return (
    <StyledTimeline>
      {items.map((timelineItem) => {
        return (
          <StyledTimelineItem key={timelineItem.id}>
            <TimelineTimeSide>
              <TimelineTimeSideContent hasContent={!!timelineItem.timestamp}>
                {timelineItem.timestamp && (
                  <>
                    <Bold13TransparentBlack900.div>
                      {localeStore.formatDate(timelineItem.timestamp)}
                    </Bold13TransparentBlack900.div>
                    <CaptionStartTransparentBlack600.div>
                      {localeStore.formatTime(timelineItem.timestamp)}
                    </CaptionStartTransparentBlack600.div>
                  </>
                )}
              </TimelineTimeSideContent>
            </TimelineTimeSide>
            <TimelineSeparator>
              {timelineItem.icon ?? <SVG accessibilityLabel='' image={TimelineCheckSpriteSymbol} width={16} height={16} />}
            </TimelineSeparator>
            <TimelinePropertiesSide>{timelineItem.content}</TimelinePropertiesSide>
          </StyledTimelineItem>
        );
      })}
    </StyledTimeline>
  );
};

interface GeneralEventTimelineItemContentProps {
  title: string;
  eventOwner: string;
  values: [string, ReactNode][];
}

export const GeneralEventTimelineItemContent: FC<GeneralEventTimelineItemContentProps> = ({ title, eventOwner, values }) => {
  return (
    <>
      <StyledTitle>
        {title}
        <StyledSubTitle> by {eventOwner}</StyledSubTitle>
      </StyledTitle>
      {values.map(([category, value]) => (
        <StyledCategoryAndValues
          label={category}
          key={category}
          inlineLabel
          labelClassName={BodySmallStartTransparentBlack900.className}
        >
          <BodySmallStartTransparentBlack600.span>{value || 'Not provided'}</BodySmallStartTransparentBlack600.span>
        </StyledCategoryAndValues>
      ))}
    </>
  );
};

export default EventTimeline;

const StyledTimeline = styled(Timeline)`
  padding: 6px 0 !important;

  .MuiTimelineItem-root:before {
    display: none;
  }
`;

const TimelineTimeSideContent = styled.div<{ hasContent: boolean }>`
  // Keep size in case there is no timestamp
  ${(p): string => (!p.hasContent ? 'min-width: 85px;' : '')}
`;

const TimelineTimeSide = styled(TimelineOppositeContent)`
  padding: 0 21px 12px 0 !important;
  flex: 0 !important;
  text-align: left !important;
`;

const TimelinePropertiesSide = styled(TimelineContent)`
  padding: 0 0 0 14px !important;
`;

const StyledTitle = styled(BodyRegularStartTransparentBlack900.div)`
  line-height: normal;
  margin-bottom: 11px;
`;

const StyledSubTitle = styled(BodySmallStartTransparentBlack600.span)`
  margin-left: 11px;
`;

const StyledCategoryAndValues = styled(CategoryAndValues)`
  font-size: 14px;
  color: var(--transparent-black-900);
`;

const StyledTimelineItem = styled(TimelineItem)`
  &:last-child {
    padding: 6px 0 !important;
  }

  &:not(:last-child) {
    padding: 0 6px 48px 6px !important;
  }
`;
