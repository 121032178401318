import { MutableRefObject, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useInfraStores from './useInfraStores';

export default function useScrollTopOnLocationChange<TElement extends HTMLElement>(
  ref: MutableRefObject<TElement | null | undefined>,
): void {
  const location = useLocation();
  const { userStore } = useInfraStores();

  useLayoutEffect(() => {
    ref.current?.scrollTo?.(0, 0);
  }, [location.pathname, ref, userStore.selectedOrganization]);
}
