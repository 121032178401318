import React, { FC, ReactNode, useState } from 'react';
import useAppStores from '@app/hooks/useAppStores';
import BackeeMode from '@backee/backeeMode';
import { observer } from 'mobx-react';
import CheckboxCardsList from '@app/components/CheckboxCardsList';
import { wrapValueAsArray } from '@app/utils/arrayUtils';
import PermissionListItem from '@app/components/PermissionListItem';
import useInfraStores from '@app/hooks/useInfraStores';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';

import { BackeeServiceType } from '@backee/domain/serviceTypes';

interface Props {
  activeServiceIds: string[];
  className?: string;
  onServiceAdd(id: string): Promise<void>;
  onServiceRemove(id: string): Promise<void>;
}

const ManageOrganizationServices: FC<Props> = observer(({ activeServiceIds, onServiceAdd, onServiceRemove, className }) => {
  const { permissionsStore } = useInfraStores<BackeeMode>();
  const { organizationsStore } = useAppStores<BackeeMode>();
  const { serviceTypes } = organizationsStore;

  const [filteredData, searchTerm, setSearchTerm] = useLoadableSearchFilter(serviceTypes, {
    partialMatches(user) {
      return [user.id, user.displayName];
    },
  });

  const [changingServiceId, setChangingServiceId] = useState<string | undefined>();

  async function onItemAddRequest(id: string): Promise<void> {
    setChangingServiceId(id);

    try {
      await onServiceAdd(id);
    } finally {
      setChangingServiceId(undefined);
    }
  }

  async function onItemRemoveRequest(id: string): Promise<void> {
    setChangingServiceId(id);

    try {
      await onServiceRemove(id);
    } finally {
      setChangingServiceId(undefined);
    }
  }

  return (
    <div>
      <InputBox appearance='line' colorScheme='secondary'>
        <NakedFormInput
          name='manage-organization-services-search'
          dataTestId='manage-organization-services-search'
          type='text'
          colorScheme='primary'
          placeholderStyle='onlyWhenEmpty'
          heightType='thick'
          clearable
          placeholder='🔍 Search for Id / Name'
          value={searchTerm}
          onChange={(newValue): void => setSearchTerm(newValue ?? '')}
          autoFocus
        />
      </InputBox>
      <CheckboxCardsList
        id='organization-manage-services-table'
        contentColumnTitle='Services'
        readonly={!permissionsStore.isEditAllowed}
        activeIds={activeServiceIds}
        changingIds={wrapValueAsArray(changingServiceId)}
        allItems={filteredData}
        itemRenderer={(serviceType: BackeeServiceType): ReactNode => {
          return <PermissionListItem permissionsId={serviceType.id} displayName={serviceType.displayName} />;
        }}
        itemKeywordsGetter={(serviceType: BackeeServiceType): string[] => {
          return [serviceType.id, serviceType.displayName];
        }}
        onAdd={onItemAddRequest}
        onRemove={onItemRemoveRequest}
        className={className}
      />
    </div>
  );
});

export default ManageOrganizationServices;
