import NavigationStore from '@app/stores/NavigationStore';
import PermissionsStore from '@app/stores/PermissionsStore';
import { makeObservable } from 'mobx';
import UserStore from '@app/stores/UserStore';
import { LocationDescriptor } from 'history';
import { ManageOrganizationTabType } from '@backee/routes/organizations/ManageOrganizationPage';

export default class BackeeNavigationStore extends NavigationStore {
  constructor(userStore: UserStore, permissionsStore: PermissionsStore) {
    super(userStore, permissionsStore);

    makeObservable(this);
  }

  generateOrganizationsListLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.backeeOrganizationsList);
  }

  generateNewOrganizationsLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.backeeNewOrganization);
  }

  generateManageOrganizationLink(organizationId: string, activeTab?: ManageOrganizationTabType): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.backeeManageOrganization, { organizationId, activeTab });
  }

  generateNewUserLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.backeeNewUser);
  }

  generateUserLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.backeeUsers);
  }
}
