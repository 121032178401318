import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import PageHeaderBackButton from '@app/components/header/PageHeaderBackButton';
import useInfraStores from '@app/hooks/useInfraStores';
import { LocationDescriptor } from 'history';

interface Props {
  className?: string;
  sideContent?: ReactNode;
  backButton?: true | ReactNode;
  backButtonTo?: LocationDescriptor;
  wrapHeaderTitle?: boolean;
  onClick?: () => void;
}

const PageHeader: FunctionComponent<Props> = (props) => {
  const { children, sideContent, backButton, backButtonTo, wrapHeaderTitle, className, onClick } = props;
  const { navigationStore } = useInfraStores();

  function calcTitle(): ReactNode {
    if (!children) {
      return <PageHeaderTitle />;
    }

    if (typeof children === 'string') {
      return <PageHeaderTitle title={children} />;
    }

    return children;
  }

  function calcBackButton(): ReactNode {
    if (backButton === true || backButtonTo) {
      return <PageHeaderBackButton to={backButtonTo ?? navigationStore.generateGoHomeLink()} />;
    }

    if (onClick) {
      return <PageHeaderBackButton onClick={onClick} />;
    }

    if (backButton) {
      return backButton;
    }
  }

  return (
    <Main className={className}>
      {calcBackButton()}
      <TitleContainer wrapHeaderTitle={wrapHeaderTitle}>{calcTitle()}</TitleContainer>
      <SideContentContainer wrapHeaderTitle={wrapHeaderTitle}>
        {sideContent ?? <PageHeaderCenteredSideContent />}
      </SideContentContainer>
    </Main>
  );
};

export default PageHeader;

const Main = styled.div`
  min-height: 80px;
  padding: 28px var(--page-side-padding) 5px;

  display: flex;
`;

export const PageHeaderCenteredSideContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const PageHeaderBottomAlignedSideContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SideContentContainer = styled.div<{ wrapHeaderTitle: boolean | undefined }>`
  flex: ${(p): string => (p.wrapHeaderTitle ? '0 0 auto' : '1')};

  display: flex;
  justify-content: flex-end;
`;

const TitleContainer = styled.div<{ wrapHeaderTitle: boolean | undefined }>`
  flex: ${(p): string => (p.wrapHeaderTitle ? '1' : '0 0 auto')};

  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.44px;
  color: var(--transparent-black-900);
`;
