import { observer, useLocalObservable } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { RowSeparator } from '@app/components/Styles';
import { Form } from 'antd';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import { LoginFooterSubTitle, LoginSubTitle, LoginTitle } from '../GuestLoginFlow';
import * as messageLauncher from '@app/utils/messageLauncher';
import { extractLogErrorIdFromError, isAuthenticatorErrorContent, RequestError } from '@app/libs/request';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import { useTranslation } from 'react-i18next';
import { createTranslatedFormalErrorMessage } from '@app/utils/errorMessageUtils';

interface EnterCodeFormFields {
  code: string;
}

interface Props extends FormComponentProps<EnterCodeFormFields> {
  cancelWaitingForCode: () => void;
  email: string;
}

const GuestLoginEnterEmailCode: FunctionComponent<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.loginEnterEmailCode);
  const { guestLoginStore } = useInfraStores<ArMode>();
  const { t } = useTranslation();

  const codeLength = guestLoginStore.emailKnoxer?.codeLength ?? 0;

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,

    setInputErrorText: (errorText: string | null): void => {
      localStore.inputErrorText = errorText;
    },
  }));

  const verifySentCode = async (code: string): Promise<void> => {
    try {
      await guestLoginStore.requestTokenFromEmailKnoxer(code);
    } catch (e: unknown) {
      const requestError = e as RequestError;

      if (isAuthenticatorErrorContent(requestError.responseJSON) && requestError.responseJSON.errorCode === 32) {
        localStore.setInputErrorText(t('ar.guestLogin.errors.wrongCode'));
      } else {
        const errorLogId = extractLogErrorIdFromError(e);
        const errorMessage = createTranslatedFormalErrorMessage(
          t,
          'ar.guestLogin.errors.unexpectedLoginError',
          'general.errors.pleaseContact',
          'general.errors.referToErrorCode',
          errorLogId,
        );

        messageLauncher.shootErrorOld(errorMessage, t('general.errors.errorPopupTitle'));
      }
    }
  };

  const fieldDecorators: FormFieldDecorators<EnterCodeFormFields> = {
    code: {
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          translatedMessage: 'ar.guestLogin.errors.enterCode',
        },
        {
          len: codeLength,
          translatedMessage: {
            key: 'ar.guestLogin.errors.codeLength',
            value: codeLength,
          },
        },
        {
          pattern: /^[0-9]+$/,
          translatedMessage: 'ar.guestLogin.errors.mustBeNumeric',
        },
      ],
    },
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleOk = (): void => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Object, values: EnterCodeFormFields) => {
      if (errors) {
        return;
      }

      const { code } = values;

      verifySentCode(code);
    });
  };

  const handleCodeInputClick = (): void => {
    const { resetFields } = props.form;

    if (localStore.inputErrorText) {
      resetFields();
      localStore.setInputErrorText(null);
    }
  };

  const onBack = (): void => {
    props.cancelWaitingForCode();
  };

  const { form, email } = props;

  return (
    <>
      <ContentContainer>
        <LoginTitle>{t('ar.guestLogin.emailPassword.emailVerification')}</LoginTitle>
        <LoginSubTitle>{t('ar.guestLogin.emailPassword.checkForCode', { email })}</LoginSubTitle>
        <EnterCodeForm onSubmit={handleFormSubmit}>
          <FormItemBox
            form={form}
            fieldName='code'
            appearance='corners'
            fieldDecoratorOptions={fieldDecorators.code}
            forcedErrorText={localStore.inputErrorText}
          >
            <NakedFormInput
              name='inpt-guest-email-enter-code'
              type='number'
              autoFocus
              placeholder={t<string>('ar.guestLogin.emailPassword.enterCode', { codeLength })}
              onMaxLengthAchieved={handleOk}
              maxLength={codeLength}
              onClick={handleCodeInputClick}
              onFocus={handleCodeInputClick}
            />
          </FormItemBox>
          <ActionsContainer>
            <Button id='btn-guest-email-enter-code-back' appearance='text' type='button' onClick={onBack} size='small'>
              {t('general.upperBack')}
            </Button>
          </ActionsContainer>
        </EnterCodeForm>
      </ContentContainer>
      <RowSeparator />
      <FooterContainer>
        <BoldSubtitle>{t('ar.guestLogin.emailPassword.notArriving')}</BoldSubtitle>
        <LoginFooterSubTitle>{t('ar.guestLogin.emailPassword.checkSpam', { email })}</LoginFooterSubTitle>
      </FooterContainer>
    </>
  );
});

export default Form.create<Props>()(GuestLoginEnterEmailCode);

const ContentContainer = styled.div`
  padding: 32px 64px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const FooterContainer = styled.div`
  padding: 16px 72px 32px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const EnterCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

const BoldSubtitle = styled.span`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;

  color: var(--transparent-black-900);

  margin-bottom: 8px;
`;
