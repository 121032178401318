import { trim } from '@app/utils/stringUtils';
import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-CA-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.CA.errors.missingBankCode`,
      },
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.CA.institutionNumber,
        transform: trim,
        translatedMessage: {
          key: `supplierValidation.supplierRegister.CA.errors.bankCodeLength`,
          value: ACCOUNT_FIELD_MAX_LENGTH.CA.institutionNumber,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.numeric,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.CA.errors.invalidBankCode`,
          `supplierValidation.supplierRegister.errors.invalidBankCode`,
        ],
      },
    ],
  };
}

function getBranchCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-CA-branch-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.CA.errors.missingBranchCode',
      },
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.CA.transitNumber,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.CA.errors.branchCodeLength`,
      },
      {
        pattern: VALIDATION_PATTERNS.numeric,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.CA.errors.invalidBranchCode`,
          'supplierValidation.supplierRegister.errors.invalidBranchCode',
        ],
      },
    ],
  };
}

export const CA: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
  branchCode: getBranchCodeFields,
  accountNumberAdditionalRules: [
    {
      pattern: VALIDATION_PATTERNS.numericWithDashAndSpace,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.invalidAccountNumber',
    },
  ],
};
