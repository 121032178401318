import React from 'react';
import { ColorScheme } from '@app/domain/theme';
import { FormItemProps } from 'antd/lib/form/FormItem';

export type InputGroupState = 'empty' | 'disabled' | 'error' | 'filled';

export interface FormInputsData {
  state: InputGroupState;
  colorScheme?: ColorScheme;
  underInputText: string | null | undefined;
  underInputTextType: Truthy<FormItemProps['validateStatus']>;
}

const FormInputsContext = React.createContext<FormInputsData>({
  state: 'empty',
  underInputText: null,
  underInputTextType: 'error',
});

export default FormInputsContext;
