import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import DropdownItem, { DropdownItemProps } from './inputs/DropdownItem';
import SVG, { SVGProps } from '@app/components/SVG';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import { ForwardingFC } from '@app/domain/technicals/components';
import { preventForwardTheseProps } from '@app/utils/styledUtils';

export const LocaleAwareDropdownItem: ForwardingFC<HTMLButtonElement, DropdownItemProps> = observer(
  React.forwardRef((props, ref) => {
    const infraStores = useInfraStores();
    return <StyledLocaleAwareDropdownItem isRTL={infraStores?.languageStore?.isRTL} {...props} />;
  }),
);

const StyledLocaleAwareDropdownItem = styled(DropdownItem)<{ isRTL?: boolean }>`
  direction: ${(p): CSSProperties['direction'] => (p.isRTL ? 'rtl' : 'ltr')};
`;

export const LocaleAwareSVG: ForwardingFC<SVGElement, SVGProps> = observer(
  React.forwardRef((props, ref) => {
    const infraStores = useInfraStores();
    return <StyledLocaleAwareSVG isRTL={infraStores?.languageStore?.isRTL} {...props} />;
  }),
);

const StyledLocaleAwareSVG = styled(SVG, { shouldForwardProp: preventForwardTheseProps('isRTL') })<{ isRTL?: boolean }>`
  ${(p): string => (p.isRTL ? 'transform: scaleX(-1);' : '')}
`;

export const LocaleAwareDiv: ForwardingFC<
  HTMLDivElement,
  { includeChildren?: boolean } & React.HTMLAttributes<HTMLDivElement>
> = observer(
  React.forwardRef((props, ref) => {
    const infraStores = useInfraStores();
    return <StyledLocaleAwareDiv isRTL={infraStores?.languageStore?.isRTL} ref={ref} {...props} />;
  }),
);

const StyledLocaleAwareDiv = styled('div', { shouldForwardProp: preventForwardTheseProps('isRTL', 'includeChildren') })<{
  isRTL?: boolean;
  includeChildren?: boolean;
}>`
  direction: ${(p): CSSProperties['direction'] => (p.isRTL ? 'rtl' : 'ltr')};

  div {
    ${(p): string => (p.includeChildren ? `direction: ${p.isRTL ? 'rtl' : 'ltr'};` : '')}
  }
`;
