import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import SanctionsGlobeImageGood from '@app/images/globe-sanctions.svg';
import SanctionsGlobeImageBad from '@app/images/globe-sanctions-bad.svg';
import Card from '@app/components/card/Card';
import { SanctionsScreeningInfo, SanctionsScreeningResultStatus } from '@app/domain/validatedPayee';
import { convertTimestampToDefaultTimeString } from '@app/utils/timeUtils';

export interface Props {
  sanctionsScreeningInfo: SanctionsScreeningInfo | undefined | null;
}

const SanctionsBadge: FC<Props> = ({ sanctionsScreeningInfo }) => {
  if (!sanctionsScreeningInfo) {
    return null;
  }

  const { timestamp, results } = sanctionsScreeningInfo;

  const statusAllPassed = results?.every((result) => result.result === SanctionsScreeningResultStatus.Passed);

  const renderSanctionsList = (): ReactNode => {
    if (!results) {
      return null;
    }

    return (
      <>
        {results.map((sanction) => (
          <SanctionsListItem key={sanction.listId} data-testid={`sanctions-list-${sanction.listId}`}>
            {sanction.listName}
          </SanctionsListItem>
        ))}
      </>
    );
  };

  return (
    <ComponentContainer data-testid='sanctions-badge'>
      <Container allPassedScreening={!!statusAllPassed}>
        <SVG
          accessibilityLabel=''
          image={statusAllPassed ? SanctionsGlobeImageGood : SanctionsGlobeImageBad}
          width={24}
          height={24}
        />
        <SanctionsLabel>{statusAllPassed ? 'Screened for sanctions' : 'Potential matches found'}</SanctionsLabel>
        <DateLabel>{convertTimestampToDefaultTimeString(timestamp)}</DateLabel>
      </Container>
      {statusAllPassed && (
        <SanctionsListContainer>
          <StyledCard>
            <SanctionsList>{renderSanctionsList()}</SanctionsList>
          </StyledCard>
        </SanctionsListContainer>
      )}
    </ComponentContainer>
  );
};

export default SanctionsBadge;

const SanctionsListContainer = styled.div`
  display: none;
  position: absolute;
  margin-top: 2px;
  z-index: 10;
`;

const SanctionsList = styled.ol`
  padding-inline-start: 15px;
  margin-bottom: 0;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const SanctionsListItem = styled.li`
  font-size: 14px;
  line-height: 1.4;
  color: var(--transparent-black-900);
`;

const ComponentContainer = styled.div`
  margin: 0 8px;
  position: relative;
  flex: 0 0 auto;

  &:hover ${SanctionsListContainer} {
    display: block;
  }
`;

const Container = styled.div<{ allPassedScreening: boolean }>`
  border-radius: 16px;
  background-color: var(${(p): string => (p.allPassedScreening ? '--accent-blue-600' : '--accent-orange-400')});
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 3px 11px;
`;

const SanctionsLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: var(--white);

  margin-left: 5px;
`;

const DateLabel = styled.div`
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: var(--white);
  min-width: 80px;
  margin-left: 5px;
`;

const StyledCard = styled(Card)`
  padding: 10px 15px;
`;

export const PayeeTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
