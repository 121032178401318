import { ColumnProps } from 'antd/lib/table';
import { distinctValues } from '@app/utils/arrayUtils';
import { isTruthy } from '@app/utils/utils';
import { getColumnMultiValueConstSelectLocalFilterProps } from './constsFilterCreator';

export default function getColumnMultiValueArrayFieldSelectProps<
  T,
  TValue extends string | null | undefined = string | null | undefined
>(
  data: T[],
  fieldValueGetter: (item: T) => TValue[] | null | undefined,
  fieldTextGetter?: (item: TValue) => string,
): Partial<ColumnProps<T>> {
  const values = distinctValues(
    data
      .map((value) => fieldValueGetter(value))
      .filter(isTruthy)
      .flat()
      .filter(isTruthy),
  ).map((value: Truthy<TValue>) => ({
    text: fieldTextGetter ? fieldTextGetter(value) : value.toString(),
    value: value.toString(),
  }));

  return getColumnMultiValueConstSelectLocalFilterProps(
    values,
    (value: string, record): boolean => !value || !!fieldValueGetter(record)?.includes(value as TValue),
  );
}
