import React, { FunctionComponent, ReactElement, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { CheckFileOptions, checkFileValidation, FileAndErrors } from '@app/domain/files';
import FileUploadDragger from './FileUploadDragger';
import Button from '@app/components/Button';
import { confirm, ModalBody, ModalContainer, ModalTextLine, ModalTitle } from '@app/components/Modal';
import BigModal from '@app/components/BigModal';

export interface UploadFileDraggerModalProps {
  title: string;
  files: FileAndErrors[];
  onDone: (file: File[]) => void;
  onCancel: () => void;
  checkFileOptions: CheckFileOptions;
}

const FileUploadDraggerModal: FunctionComponent<UploadFileDraggerModalProps> = ({
  title,
  files,
  onDone,
  onCancel,
  checkFileOptions,
}) => {
  const [currentFilesAndErrors, setCurrentFilesAndErrors] = useState<FileAndErrors[]>(files);
  // We need this ref because if we upload multiple files the handleAddFile method is called multiple times in a row the
  // value of currentFilesAndErrors will not update according to the new value
  const refFilesAmount = useRef<number>(files.length);

  const handleDeleteFile = (file: File): void => {
    const index = currentFilesAndErrors.map((x) => x.file).indexOf(file);

    if (index > -1) {
      const newFilesArray = [...currentFilesAndErrors];
      newFilesArray.splice(index, 1);

      setCurrentFilesAndErrors(newFilesArray);
      refFilesAmount.current = newFilesArray.length;
    }
  };

  const renderReplaceFileModalContent = (): ReactElement => {
    return (
      <ModalContainer>
        <ModalTitle>One file only</ModalTitle>
        <ModalBody>
          <ModalTextLine>Only one file can be uploaded. Do you want to replace the existing file?</ModalTextLine>
        </ModalBody>
      </ModalContainer>
    );
  };

  const handleAddFile = (file: File): void => {
    const errors = checkFileValidation(file, checkFileOptions);

    // This method is called a few times in a row before the component is re-rendered
    // Therefore the value currentFilesAndErrors will stay the same for all of those calls.
    // Using the option to pass a method to setCurrentFilesAndErrors allows us to always get the latest value
    if (checkFileOptions.maxFilesAllowed > 1 || !currentFilesAndErrors.length) {
      setCurrentFilesAndErrors((actualCurrentFilesAndErrors) => [...actualCurrentFilesAndErrors, { file, errors }]);
      refFilesAmount.current++;
    } else {
      confirm({
        content: renderReplaceFileModalContent(),
        okText: 'REPLACE FILES',
        cancelText: 'NO',
        icon: <span />,
        onOk: () => {
          setCurrentFilesAndErrors([{ file, errors }]);
        },
      });
    }
  };

  const handleOk = (): void => {
    onDone(currentFilesAndErrors.map((fileAndError) => fileAndError.file));
  };

  const doesSomeFilesHaveErrors = !!currentFilesAndErrors.filter((fileAndError) => fileAndError.errors?.length).length;

  return (
    <StyledCard closeFunc={(): void => onCancel()}>
      <FileUploadDragger
        title={title}
        files={currentFilesAndErrors}
        multiple={checkFileOptions.maxFilesAllowed > 1}
        id='file-dragger-modal-dragger'
        onFileAdded={handleAddFile}
        onFileDeleted={handleDeleteFile}
      />
      <ActionContainer>
        <Button id='file-dragger-modal-cancel' onClick={(): void => onCancel()} appearance='outline'>
          CANCEL
        </Button>
        <Button id='file-dragger-modal-ok' onClick={(): void => handleOk()} disabled={doesSomeFilesHaveErrors}>
          OK
        </Button>
      </ActionContainer>
    </StyledCard>
  );
};

export default FileUploadDraggerModal;

const StyledCard = styled(BigModal)`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 12px;
  width: 600px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    flex: 1;
  }

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
