import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import SinglePaymentModel from '@mortee/models/SinglePaymentModel';
import { DeterministicValidationResultType, translateDeterministicValidationLevel } from '@app/domain/deterministicValidation';
import OldCardTable from '@app/components/OldCardTable';
import { FormattedNumber } from '@app/components/Locale';
import { SinglePaymentResult } from '@mortee/domain/paymentsConsts';
import styled from '@emotion/styled';
import { showInfoModal } from '@app/components/Modal';
import ValidationLevelExplanation from '@mortee/routes/paymentCheck/batchPage/ValidationLevelExplanation';
import { ColumnProps } from 'antd/lib/table';
import ResizeObserver from 'react-resize-observer';
import { action } from 'mobx';
import withTooltip, { WithTooltipProps } from '@app/components/withTooltip';
import { formatAccountWithSwiftban } from '@app/domain/accountDetailsFormatters';
import DownloadableResource from '@app/components/DownloadableResource';
import { getRowClassName, TableExplanationHeader, TableImportantText, TableStandardText } from '@app/components/tables/Table';

export enum LoadingState {
  NotLoading,
  Loading,
  ErrorWhileLoading,
}

interface PaymentsTableProps {
  payments: SinglePaymentModel[];
  loadingState: LoadingState;
  fetchBatchFile(fileId: string): Promise<NamedResource>;
}

interface PaymentResultTableRow {
  id: string;
  accountDetails: MorteeAccountDetailsExtended;
  amount: number;
  currency: string;
  payeeRevisionId: string;
  payeeName: string;
  payeeExternalId: string;
  message: string;
  validationLevel: DeterministicValidationResultType;
  file: FileMetadata;

  rowSpan: number;
  groupIndex: number;
}

const PaymentsTable: FunctionComponent<PaymentsTableProps> = observer((props) => {
  const localStore = useLocalObservable(() => ({
    isDenseMode: false as boolean,
  }));

  const handleResize = action((rect: DOMRect) => {
    localStore.isDenseMode = rect.width < DENSE_MODE_MAX_TABLE_WIDTH;
  });

  const transformIntoResultRows = (payments: SinglePaymentModel[]): PaymentResultTableRow[] => {
    const groupedPaymentRows = payments.map((payment, groupIndex) => {
      const [firstResult, ...rest] = payment.results;

      return [
        transformIntoResultRow(payment, firstResult, groupIndex, rest.length + 1),
        ...rest.map((result) => {
          return transformIntoResultRow(payment, result, groupIndex, 0);
        }),
      ];
    });

    return groupedPaymentRows.flat();
  };

  const transformIntoResultRow = (
    payment: SinglePaymentModel,
    result: SinglePaymentResult,
    groupIndex: number,
    rowSpan: number,
  ): PaymentResultTableRow => {
    return {
      rowSpan,
      groupIndex,
      id: payment.id,
      accountDetails: { ...payment.accountDetails, localFormat: null },
      amount: payment.amount,
      currency: payment.currency,
      file: payment.file,
      payeeRevisionId: result.payeeRevisionId,
      payeeName: result.payeeName,
      payeeExternalId: result.payeeExternalId,
      message: result.message,
      validationLevel: result.validationLevel,
    };
  };

  const { payments, fetchBatchFile, loadingState } = props;
  const columns: ColumnProps<PaymentResultTableRow>[] = createColumnsDefinitions(fetchBatchFile, localStore.isDenseMode);

  return (
    <div>
      <ResizeObserver onResize={handleResize} />
      <StyledTable
        data-testid='paymentsTable'
        columns={columns}
        dataSource={transformIntoResultRows(payments)}
        loading={loadingState === LoadingState.Loading}
        pagination={false}
        rowKey={(payment: PaymentResultTableRow, index: number): string => index.toString()}
        rowClassName={getRowClassName}
        isLoaded={loadingState === LoadingState.NotLoading}
        isDenseMode={localStore.isDenseMode}
      />
    </div>
  );
});

export default PaymentsTable;

const openValidationLevelExplanation = (): void => {
  showInfoModal(<StyledValidationLevelExplanation />, {
    okButtonProps: {
      size: 'small',
      className: 'ant-btn-accent',
    },
  });
};

const createColumnsDefinitions = (
  fetchBatchFile: PaymentsTableProps['fetchBatchFile'],
  isDenseMode: boolean,
): ColumnProps<PaymentResultTableRow>[] => {
  const entityIdColumnWidth: number = isDenseMode ? 112 : 142;
  const payeeNameColumnWidth: number = isDenseMode ? 135 : 305;

  return [
    {
      title: <div data-testid='lblPaymentAccount'>Account</div>,
      key: 'accountDetails',
      dataIndex: 'accountDetails',
      width: isDenseMode ? '260px' : '298px',
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return {
          children: (
            <>
              {formatAccountWithSwiftban(record.accountDetails).map((account: string, accountDetailsIndex: number) => (
                <TableImportantText
                  key={account}
                  data-testid={`${index}_${accountDetailsIndex}_account`}
                  id={`${index}_${accountDetailsIndex}_account`}
                >
                  {account}
                </TableImportantText>
              ))}
            </>
          ),
          props: { rowSpan: record.rowSpan },
        };
      },
    },
    {
      title: <div data-testid='lblPaymentAmount'>Amount</div>,
      dataIndex: 'amount',
      key: 'amount',
      width: isDenseMode ? '120px' : '150px',
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return {
          children: (
            <TableStandardText data-testid={`${index}_amount`}>
              <FormattedNumber value={record.amount} options={{ maximumFractionDigits: 2 }} /> {record.currency}
            </TableStandardText>
          ),
          props: { rowSpan: record.rowSpan },
        };
      },
    },
    {
      title: <div data-testid='lblPaymentEntityId'>Entity ID</div>,
      dataIndex: 'entityId',
      key: 'entityId',
      width: entityIdColumnWidth,
      onCell: (): { style: { whiteSpace: string; maxWidth: number } } => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: entityIdColumnWidth,
          },
        };
      },
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return <EllipsisTooltip data-testid={`${index}_entityId`}>{record.payeeExternalId}</EllipsisTooltip>;
      },
    },
    {
      title: <div data-testid='lblPaymentPayeeName'>Payee name</div>,
      dataIndex: 'payeeName',
      key: 'payeeName',
      width: payeeNameColumnWidth,
      onCell: (): { style: { whiteSpace: string; maxWidth: number } } => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: payeeNameColumnWidth,
          },
        };
      },
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return <EllipsisTooltip data-testid={`${index}_payeeName`}>{record.payeeName}</EllipsisTooltip>;
      },
    },
    {
      title: <div data-testid='lblPaymentFile'>File</div>,
      dataIndex: 'file',
      key: 'file',
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return (
          <DownloadableResource
            dataTestId={`${index}_payment_downloadableResource`}
            key='file'
            fileMetadata={record.file}
            fetchFile={fetchBatchFile}
            spinnerWhileDownloading={false}
            center={false}
          >
            <a>{record.file.name}</a>
          </DownloadableResource>
        );
      },
    },
    {
      title: (
        <TableExplanationHeader dataTestId='lblPaymentValidationLevel' onExplanation={openValidationLevelExplanation}>
          Validation level
        </TableExplanationHeader>
      ),
      dataIndex: 'validationLevel',
      key: 'validationLevel',
      width: isDenseMode ? '158px' : '194px',
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return (
          <TableImportantText data-testid={`${index}_validationLevel`}>
            {translateDeterministicValidationLevel(record.validationLevel)}
          </TableImportantText>
        );
      },
    },
    {
      title: <div data-testid='lblPaymentReason'>Reason</div>,
      dataIndex: 'reason',
      key: 'reason',
      render: (text: any, record: PaymentResultTableRow, index: number): React.ReactNode => {
        return <TableStandardText data-testid={`${index}_reason`}>{record.message}</TableStandardText>;
      },
    },
  ];
};

const DENSE_MODE_MAX_TABLE_WIDTH: number = 1484;

const StyledTable = styled(OldCardTable)`
  .ant-table-tbody > tr > td {
    vertical-align: top;
    border-bottom: none !important;
  }
`;

const EllipsisStandardText = styled(TableStandardText)<WithTooltipProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EllipsisTooltip = withTooltip(EllipsisStandardText);

const StyledValidationLevelExplanation = styled(ValidationLevelExplanation)`
  max-width: 950px;
`;
