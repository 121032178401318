import React, { FC, ReactNode } from 'react';
import {
  getPrivatePayeeAccountSelfApproveReasonText,
  PrivatePayeeAccount,
  PrivatePayeeAccountEventType,
  PrivatePayeeAccountPortalRemoveSelfApproveEvent,
  PrivatePayeeAccountPortalSelfApproveEvent,
  PrivatePayeeAccountRemoveSelfApproveEvent,
  PrivatePayeeAccountSelfApproveEvent,
  PrivatePayeeAccountSelfApproveReason,
  PrivatePayeeAccountValidationStatus,
} from '@mortee/domain/privatePayeeAccount';
import { BodyRegularStartTransparentBlack900 } from '@app/components/Text';
import { showInfoModal } from '@app/components/Modal';
import ModalFileList from '@app/components/fileUpload/ModalFileList';
import { cleanNoSwiftSuffixAccountDetails, doesAccountDetailsContainOther } from '@app/domain/accountsDetailsHelpers';
import EventTimeline, { EventTimelineItem, GeneralEventTimelineItemContent } from '@app/components/EventTimeline';
import { isTruthy } from '@app/utils/utils';
import styled from '@emotion/styled';
import NewCheck from '@app/images/timeline/new-check.svg';
import Ex from '@app/images/timeline/ex.svg';
import SVG from '@app/components/SVG';
import { DeterministicValidationWithPrivatePayee } from '@mortee/domain/morteeDeterministicValidation';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import useInfraStores from '@app/hooks/useInfraStores';
import { compare } from '@app/utils/comparatorUtils';

const SYSTEM_EVENT_OWNER = 'PaymentKnox system';

interface Props {
  privatePayeeAccount: PrivatePayeeAccount;
  deterministicValidation: DeterministicValidationWithPrivatePayee;
  fetchSelfApproveAttachmentFile(eventId: string, fileId: string): Promise<NamedResource>;
}

const PrivateAccountHistoryTimeline: FC<Props> = ({
  privatePayeeAccount,
  deterministicValidation,
  fetchSelfApproveAttachmentFile,
}) => {
  const renderedEvents: EventTimelineItem[] = privatePayeeAccount.events
    .filter(isSelfApproveEvent)
    .sort(compare.writeTimestamp().reverse())
    .map((event) => getEventTimelineContent(event, fetchSelfApproveAttachmentFile));

  const allRenderedTimelineItems: EventTimelineItem[] = [
    ...renderedEvents,
    deterministicValidation.validationLevel === DeterministicValidationResultType.selfApproved &&
      privatePayeeAccount.validationStatus !== PrivatePayeeAccountValidationStatus.selfApproved &&
      createEventTimelineItemForAccountSelfApprovedByOtherPrivateAccount(privatePayeeAccount, deterministicValidation),
  ].filter(isTruthy);

  if (!allRenderedTimelineItems.length) {
    return (
      <BodyRegularStartTransparentBlack900.div>No previous validation requests found</BodyRegularStartTransparentBlack900.div>
    );
  }

  return <EventTimeline items={allRenderedTimelineItems} />;
};

interface SelfApproveEventTimelineItemContentProps {
  selfApproveEvent: PrivatePayeeAccountSelfApproveEvent | PrivatePayeeAccountPortalSelfApproveEvent;
  fetchSelfApproveAttachmentFile(fileId): Promise<NamedResource>;
}

interface RemoveSelfApproveEventTimelineItemContentProps {
  event: PrivatePayeeAccountPortalRemoveSelfApproveEvent | PrivatePayeeAccountRemoveSelfApproveEvent;
}

const getEventTimelineContent = (event, fetchSelfApproveAttachmentFile): EventTimelineItem => {
  let eventTimeline;
  if (
    event.eventType === PrivatePayeeAccountEventType.PrivatePayeeAccountPortalRemoveSelfApprove ||
    event.eventType === PrivatePayeeAccountEventType.PrivatePayeeAccountRemoveSelfApproval
  ) {
    eventTimeline = {
      id: event.id,
      timestamp: event.writeTimestamp,
      content: <PrivateAccountRemoveSelfApproveEventTimelineItemContent event={event} key={event.id} />,
      icon: <SVG accessibilityLabel='' image={Ex} width={16} height={16} data-testid='ex-icon-sa' />,
    };
    return eventTimeline;
  } else {
    eventTimeline = {
      id: event.id,
      timestamp: event.writeTimestamp,
      content: (
        <PrivateAccountSelfApproveEventTimelineItemContent
          selfApproveEvent={event}
          fetchSelfApproveAttachmentFile={(fileId): Promise<NamedResource> => fetchSelfApproveAttachmentFile(event.id, fileId)}
          key={event.id}
        />
      ),
      icon: <SVG accessibilityLabel='' image={NewCheck} width={16} height={16} data-testid='check-icon-sa' />,
    };
    return eventTimeline;
  }
};

const PrivateAccountRemoveSelfApproveEventTimelineItemContent: FC<RemoveSelfApproveEventTimelineItemContentProps> = ({
  event,
}) => {
  const values: [string, ReactNode][] = [['Note', event.data.note && <MultilineText>{event.data.note}</MultilineText>]];
  return (
    <GeneralEventTimelineItemContent title='Self Validation Cancelled' eventOwner={event.performingUserName} values={values} />
  );
};

const PrivateAccountSelfApproveEventTimelineItemContent: FC<SelfApproveEventTimelineItemContentProps> = ({
  selfApproveEvent,
  fetchSelfApproveAttachmentFile,
}) => {
  const { localeStore } = useInfraStores();

  let values: [string, ReactNode][];

  switch (selfApproveEvent.data.reason) {
    case PrivatePayeeAccountSelfApproveReason.oneTimeTransaction:
    case PrivatePayeeAccountSelfApproveReason.smallAmountTransaction:
    case PrivatePayeeAccountSelfApproveReason.transactionHistory: {
      values = [
        ['Reason', getPrivatePayeeAccountSelfApproveReasonText(selfApproveEvent.data.reason)],
        ['Note', selfApproveEvent.data.note && <MultilineText>{selfApproveEvent.data.note}</MultilineText>],
      ];
      break;
    }
    case PrivatePayeeAccountSelfApproveReason.phoneCallVerification: {
      values = [
        ['Reason', getPrivatePayeeAccountSelfApproveReasonText(selfApproveEvent.data.reason)],
        ['Talked to', selfApproveEvent.data.contactName],
        ['Phone number', selfApproveEvent.data.contactPhoneNumber],
        ['Phone call date', localeStore.formatDate(selfApproveEvent.data.phoneCallTimestamp)],
        ['Note', selfApproveEvent.data.description && <MultilineText>{selfApproveEvent.data.description}</MultilineText>],
      ];
      break;
    }
    case PrivatePayeeAccountSelfApproveReason.other: {
      values = [
        ['Reason', selfApproveEvent.data.description],
        ['Note', selfApproveEvent.data.note && <MultilineText>{selfApproveEvent.data.note}</MultilineText>],
      ];
      break;
    }
  }

  values.push([
    'Attachments',
    selfApproveEvent.data.attachmentFiles?.length ? (
      <a
        onClick={(): void => {
          showInfoModal(
            <ModalFileList
              files={selfApproveEvent.data.attachmentFiles.map((file) => [file, fetchSelfApproveAttachmentFile])}
              title='Attachments'
            />,
            {
              okText: 'OK',
            },
          );
        }}
      >
        Show Files
      </a>
    ) : undefined,
  ]);

  return (
    <GeneralEventTimelineItemContent title='Self Validated' eventOwner={selfApproveEvent.performingUserName} values={values} />
  );
};

const createEventTimelineItemForAccountSelfApprovedByOtherPrivateAccount = (
  privatePayeeAccount: PrivatePayeeAccount,
  deterministicValidation: DeterministicValidationWithPrivatePayee,
): EventTimelineItem => {
  const cleanedAccountDetails: MorteeAccountDetails = cleanNoSwiftSuffixAccountDetails(privatePayeeAccount.accountDetails);

  const otherAccountThatThisAccountDVSelfApproved = deterministicValidation.privatePayeeAccounts.find(
    (otherPrivateAccount) =>
      otherPrivateAccount.validationStatus === PrivatePayeeAccountValidationStatus.selfApproved &&
      doesAccountDetailsContainOther(cleanNoSwiftSuffixAccountDetails(otherPrivateAccount.accountDetails), cleanedAccountDetails),
  );

  const otherAccountSelfApproveEvent = otherAccountThatThisAccountDVSelfApproved?.events.find(isSelfApproveEvent);

  return {
    id: otherAccountSelfApproveEvent?.id ?? 'self-by-other-account',
    timestamp: otherAccountSelfApproveEvent?.writeTimestamp,
    content: (
      <GeneralEventTimelineItemContent
        title='Self Validated'
        eventOwner={SYSTEM_EVENT_OWNER}
        values={[['Reason', 'This account matches another self-validated account']]}
      />
    ),
  };
};

const isSelfApproveEvent = (
  accountEvent: ArrayItem<PrivatePayeeAccount['events']>,
): accountEvent is PrivatePayeeAccountSelfApproveEvent | PrivatePayeeAccountPortalSelfApproveEvent => {
  return (
    accountEvent.eventType === PrivatePayeeAccountEventType.PrivatePayeeAccountPortalSelfApproved ||
    accountEvent.eventType === PrivatePayeeAccountEventType.PrivatePayeeAccountSelfApproved ||
    accountEvent.eventType === PrivatePayeeAccountEventType.PrivatePayeeAccountPortalRemoveSelfApprove ||
    accountEvent.eventType === PrivatePayeeAccountEventType.PrivatePayeeAccountRemoveSelfApproval
  );
};

export default PrivateAccountHistoryTimeline;

const MultilineText = styled.div`
  white-space: pre-wrap;
`;
