import { DEFAULT_EQUALS_FUNCTION, identityFunc, isDefined } from '@app/utils/utils';

export function arrayToObject<K extends string, T, P extends keyof T>(array: T[], keyField: P): { [A in K]: T } {
  return array.reduce((obj, item) => {
    // @ts-expect-error complex assignment error
    obj[item[keyField]] = item;

    return obj;
  }, {}) as { [A in K]: T };
}

export function arrayWithoutValue<T>(arr: T[], value: T, equals: (a: T, b: T) => boolean = DEFAULT_EQUALS_FUNCTION): T[] {
  return arr.filter((arrayItem) => !equals(arrayItem, value));
}

export function arrayWithoutValues<T>(arr: T[], values: T[], equals: (a: T, b: T) => boolean = DEFAULT_EQUALS_FUNCTION): T[] {
  return arr.filter((arrayItem) => values.every((value) => !equals(arrayItem, value)));
}

export function arrayWithoutIndex<T>(arr: T[], indexToRemove: number): T[] {
  return arr.filter((arrayItem, index) => index !== indexToRemove);
}

export function replaceOrInsertValue<T>(
  arr: T[],
  value: T,
  itemFinder: (arrItem: T) => boolean = (arrItem): boolean => DEFAULT_EQUALS_FUNCTION(arrItem, value),
): T[] {
  const valueIndex = arr.findIndex((arrayValue) => itemFinder(arrayValue));

  if (valueIndex === -1) {
    return [...arr, value];
  }

  const copiedArr = [...arr];
  copiedArr[valueIndex] = value;

  return copiedArr;
}

export function mergeArrays<T>(arrays: (T[] | null | undefined)[], distinct?: boolean): T[] {
  const mergedArray: T[] = [];

  for (const arr of arrays) {
    if (arr) {
      mergedArray.push(...arr);
    }
  }

  if (distinct) {
    return distinctValues(mergedArray);
  }

  return mergedArray;
}

export function arraysEqual<T>(arr1: T[], arr2: T[], equals: (a: T, b: T) => boolean = DEFAULT_EQUALS_FUNCTION): boolean {
  if (arr1 === arr2) return true;
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;

  return arr1.every((item, index): boolean => {
    return equals(item, arr2[index]);
  });
}

export function getListWithLeading<T>(
  elements?: T[],
  leadingValue?: T | null,
  equals: (a: T, b: T) => boolean = DEFAULT_EQUALS_FUNCTION,
): T[] {
  if (!elements) {
    return [];
  }

  if (!leadingValue || !elements.find((element) => equals(element, leadingValue))) {
    return elements;
  }

  return [leadingValue, ...elements.filter((item) => !equals(item, leadingValue))];
}

export function minValue<T>(arr: T[] | null | undefined, keyComparator: (value1: T, value2: T) => number): T | undefined {
  return minByKey(arr, identityFunc, keyComparator);
}

export function maxValue<T>(arr: T[] | null | undefined, keyComparator: (value1: T, value2: T) => number): T | undefined {
  return maxByKey(arr, identityFunc, keyComparator);
}

export function minByKey<T, TValue>(
  arr: T[] | null | undefined,
  keyGetter: (item: T) => TValue,
  keyComparator: (value1: TValue, value2: TValue) => number,
): T | undefined {
  return maxByKey(arr, keyGetter, (value1, value2) => -keyComparator(value1, value2));
}

export function maxByKey<T, TValue>(
  arr: T[] | null | undefined,
  keyGetter: (item: T) => TValue,
  keyComparator: (value1: TValue, value2: TValue) => number,
): T | undefined {
  if (!arr?.length) {
    return arr?.[0];
  }

  return arr.reduce((prev, current) => {
    if (keyComparator(keyGetter(prev), keyGetter(current)) > 0) {
      return prev;
    }

    return current;
  }, arr[0]);
}

export function distinctValues<TValue>(arr: readonly TValue[]): TValue[];
export function distinctValues<TValue>(arr: readonly TValue[] | undefined): TValue[] | undefined;

export function distinctValues<TValue>(arr: readonly TValue[] | undefined): TValue[] | undefined {
  if (!arr) {
    return arr;
  }

  return [...new Set<TValue>(arr)];
}

export function distinctValuesByKey<TValue>(arr: readonly TValue[], keyGetter: (value: TValue) => any): TValue[];
export function distinctValuesByKey<TValue>(
  arr: readonly TValue[] | undefined,
  keyGetter: (value: TValue) => any,
): TValue[] | undefined;

export function distinctValuesByKey<TValue>(
  arr: readonly TValue[] | undefined,
  keyGetter: (value: TValue) => any,
): TValue[] | undefined {
  if (!arr) {
    return arr;
  }

  const itemsByKeysMap = new Map<any, TValue>();

  for (const arrItem of arr) {
    const keyOfArrItem = keyGetter(arrItem);
    if (!itemsByKeysMap.has(keyOfArrItem)) {
      itemsByKeysMap.set(keyOfArrItem, arrItem);
    }
  }

  return [...itemsByKeysMap.values()];
}

export function findDuplicates<T>(inputList: T[]): T[] {
  const seenElements: Set<T> = new Set();
  const duplicateElements: Set<T> = new Set();
  const resultList: T[] = [];

  for (const element of inputList) {
    // Check if the element has been seen before
    if (seenElements.has(element)) {
      duplicateElements.add(element);
    } else {
      seenElements.add(element);
    }
  }

  // Add each unique duplicate element to the result list
  duplicateElements.forEach((element) => {
    resultList.push(element);
  });

  return resultList;
}

export function createNTimes<T>(n: number, creator: (index: number) => T): T[] {
  return Array(n)
    .fill(null)
    .map((x, i) => creator(i));
}

export function wrapValueAsArray<T>(value: T | undefined | null): [] | [T] {
  if (value === null || value === undefined || value === '') {
    return [];
  }

  return [value];
}

export function valueOrValuesOrNullAsArray<T>(value: T | T[] | Set<T> | null | undefined): T[] {
  if (Array.isArray(value)) {
    return value;
  }

  if (value instanceof Set) {
    return [...value];
  }

  if (!isDefined(value)) {
    return [];
  }

  return [value];
}

export function valueOrValuesAsArray<T>(value: T | T[]): T[] {
  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}

export function itemOrFirst<T>(itemOrArr: T | T[]): T | undefined {
  return Array.isArray(itemOrArr) ? itemOrArr[0] : itemOrArr;
}

export function itemOrSingle<T>(itemOrArr: T | T[]): T | undefined {
  if (!Array.isArray(itemOrArr)) {
    return itemOrArr;
  }

  if (itemOrArr.length === 1) {
    return itemOrArr[0];
  }

  return undefined;
}

export function findLast<T>(arr: T[] | undefined | null, predicate: Predicate<T>): T | undefined {
  if (!arr?.length) {
    return undefined;
  }

  for (let i = arr?.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) {
      return arr[i];
    }
  }

  return undefined;
}

export function intersection<T>(first: Set<T> | T[] | null | undefined, second: Set<T> | T[] | null | undefined): T[] {
  const arr1 = valueOrValuesOrNullAsArray(first);
  const arr2 = valueOrValuesOrNullAsArray(second);

  return arr1.filter((x) => arr2.includes(x));
}

export function multiIntersection<T>(...items: (Set<T> | T[] | null | undefined)[]): T[] {
  if (!items.length) {
    return [];
  }

  const itemsAsArrays = items.filter(isDefined).map(valueOrValuesOrNullAsArray);

  return itemsAsArrays.reduce((previousValue, currentValue) => {
    return intersection(previousValue, currentValue);
  }, itemsAsArrays[0]);
}

export function size<T>(collection: Set<T> | T[] | null | undefined): number {
  if (!collection) {
    return 0;
  }

  if (Array.isArray(collection)) {
    return collection.length;
  }

  return collection.size;
}
