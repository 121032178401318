import { PaymentCheckBatchServerResponse, PaymentCheckBatchStatus } from '@mortee/domain/paymentsConsts';
import paymentCheckServices from '@mortee/services/paymentCheckServices';
import { flow, makeObservable, observable } from 'mobx';

export default class PaymentCheckBatchModel {
  id: string;
  @observable loaded: boolean = false;

  status: PaymentCheckBatchStatus;
  files: FileMetadata[];
  paymentSums: { [currency: string]: number };
  notes: string;
  writeTimestamp: number;
  paymentPolicyRevisionId: string;
  paymentsCount: number;
  performingUserName: string;

  constructor(id: string, obj: PaymentCheckBatchServerResponse | null = null) {
    makeObservable(this);

    this.id = id;
    this.loaded = !!obj;

    if (obj) {
      this.assignLoadedData(obj);
    }
  }

  load = flow(function* (this: PaymentCheckBatchModel) {
    const batchServerResponse = yield paymentCheckServices.getBatchById(this.id);
    this.assignLoadedData(batchServerResponse);
    this.loaded = true;
  });

  private assignLoadedData = (obj: PaymentCheckBatchServerResponse): void => {
    this.status = obj.status;
    this.files = obj.files.map((file) => ({
      id: file.id,
      name: file.name,
    }));
    this.paymentSums = obj.paymentSums;
    this.notes = obj.notes;
    this.writeTimestamp = obj.writeTimestamp;
    this.paymentPolicyRevisionId = obj.paymentPolicyRevisionId;
    this.paymentsCount = obj.paymentsCount;
    this.performingUserName = obj.performingUserName;
  };
}
