import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import { SidePadding, TabTitle } from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';
import SupplierValidationRecordEvidence from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/SupplierValidationRecordEvidence';

interface Props {
  record: SupplierValidationRecord;
  disabled: boolean;
  className?: string;
}

const EditValidationRecordEvidenceTab: FC<Props> = observer((props) => {
  const { record, disabled, className } = props;

  return (
    <SidePadding className={className}>
      <TabTitle>Evidence</TabTitle>
      <SupplierValidationRecordEvidence record={record} disabled={disabled} />
    </SidePadding>
  );
});

export default EditValidationRecordEvidenceTab;
