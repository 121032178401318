import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ModalButton } from '@app/components/Button';
import LogoImage from '@app/images/nsknox_logo.svg';
import MobileNotSupportedImage from '@app/images/mobile-not-supported.svg';
import SVG from '@app/components/SVG';

const LEARN_MORE_REDIRECT_URL = 'https://www.nsknox.net';

const MobileNotSupportedPage: FC = () => {
  const learnMoreOnClick = (): void => {
    location.assign(LEARN_MORE_REDIRECT_URL);
  };

  return (
    <Page>
      <Header>
        <StyledHeaderLogo accessibilityLabel='nsKnox logo' image={LogoImage} />
      </Header>
      <Body>
        <SVG accessibilityLabel='' image={MobileNotSupportedImage} width={70} height={70} />
        <NotSupportedTitle>Mobile Not Supported</NotSupportedTitle>
        <NotSupportedText>This site is not supported on mobile.</NotSupportedText>
        <NotSupportedText>Please try again on desktop.</NotSupportedText>
        <StyledButton id='btn-mobile-view-learn-more' onClick={learnMoreOnClick}>
          Learn More About nsKnox
        </StyledButton>
      </Body>
    </Page>
  );
};

export default MobileNotSupportedPage;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Header = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  background-color: #142361;
`;

const StyledHeaderLogo = styled(SVG)`
  height: 22.4px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: #f3f4f7;
  padding-top: 105px;
`;

const NotSupportedTitle = styled.div`
  font-family: Nexa;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #142361;

  margin-top: 34px;
  margin-bottom: 6px;
`;

const NotSupportedText = styled.div`
  font-family: Nexa;
  font-size: 13px;
  letter-spacing: 0.65px;
  color: #142361;
`;

const StyledButton = styled(ModalButton)`
  margin-top: 34px;
  margin-bottom: 20px;

  font-family: Nexa;
  font-size: 14px;
  font-weight: bold;
`;
