import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { BodySmallStartTransparentBlack900, H5StartTransparentBlack900, H6StartTransparentBlack800 } from '@app/components/Text';
import CloseImage from '@app/images/ic_close.svg';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import Tabs, { TabPane } from '@app/components/Tabs';
import {
  CONTACT_US_TEXT,
  CONTRACTS_VALIDATION_TEXT,
  EMAIL_TEMPLATE,
  PROTECTION_METHOD_TEXT,
  VIDEO_INSTRUCTIONS_URL,
} from '@mortee/routes/ips/customerKit/customerKitConsts';
import CustomerKitCommunicationTextCard from '@mortee/routes/ips/customerKit/CustomerKitCommunicationTextCard';

interface Props {
  onClose: () => void;
}

const CustomerKit: FunctionComponent<Props> = ({ onClose }) => {
  return (
    <Main>
      <MainExplanation>
        <Header>
          <Title>Customer Kit</Title>
          <CloseButton id='btn-ips-customer-kit-close' appearance='text' colorScheme='primary' size='small' onClick={onClose}>
            <SVG accessibilityLabel='close' image={CloseImage} width={30} height={30}>
              Customer Kit
            </SVG>
          </CloseButton>
        </Header>
        <BodySmallStartTransparentBlack900.div>
          To help you communicate to your clients about the steps you took in order to protect them and yourself against bank
          account fraud attempts, we have prepared some templates that can be used as a starting point for different communication
          methods.
        </BodySmallStartTransparentBlack900.div>
      </MainExplanation>
      <StyledTabs defaultActiveKey='email'>
        <TabPane tab={<span data-testid='tab-customer-kit-email'>Email</span>} key='email'>
          <EmailTab>
            <TabContentTitle>Email Template</TabContentTitle>
            <TabContentDescription>
              The following template can be used in emails that you can send to paying clients or partners. In this email you will
              explain the bank account certificate and that they should verify the bank account details and the authenticity of
              the certificate through nsKnox online verification service (verify.nsknox.net).
            </TabContentDescription>
            <CustomerKitCommunicationTextCard copyButtonId='customer-kit-copy-email-template'>
              {EMAIL_TEMPLATE}
            </CustomerKitCommunicationTextCard>
          </EmailTab>
        </TabPane>
        <PaddedTabPane tab={<span data-testid='tab-customer-kit-video'>Video</span>} key='video'>
          <TabContentTitle>Video Instructions Template</TabContentTitle>
          <TabContentDescription>
            The following video clip can be sent to paying clients or partners to explain how they should use the bank account
            certificate to verify the bank account details and the authenticity of the certificate through nsKnox's bank account
            verification service (verify.nsknox.net).
          </TabContentDescription>
          <CustomerKitCommunicationTextCard copyButtonId='customer-kit-copy-video-instructions'>
            {VIDEO_INSTRUCTIONS_URL}
          </CustomerKitCommunicationTextCard>
        </PaddedTabPane>
        <PaddedTabPane tab={<span data-testid='tab-customer-kit-website'>Website</span>} key='website'>
          <TabContentTitle>Website Text Template</TabContentTitle>
          <TabContentDescription>
            We recommend adding the following notice on your website’s Contact Us page, as this page is often used to verify your
            contact details.
          </TabContentDescription>
          <CustomerKitCommunicationTextCard copyButtonId='customer-kit-copy-website'>
            {CONTACT_US_TEXT}
          </CustomerKitCommunicationTextCard>
        </PaddedTabPane>
        <PaddedTabPane tab={<span data-testid='tab-customer-kit-invoices'>Invoices</span>} key='invoices'>
          <TabContentTitle>Invoice Text Template</TabContentTitle>
          <TabContentDescription>
            We recommend adding the following notice on your invoices to remind your clients that they should verify that your
            bank account certificate is validated and up to date through nsKnox’s bank account verification service.
          </TabContentDescription>
          <CustomerKitCommunicationTextCard copyButtonId='customer-kit-copy-invoices'>
            {PROTECTION_METHOD_TEXT}
          </CustomerKitCommunicationTextCard>
        </PaddedTabPane>
        <PaddedTabPane tab={<span data-testid='tab-customer-kit-contracts'>Contracts</span>} key='contracts'>
          <TabContentTitle>Contracts Text Template</TabContentTitle>
          <TabContentDescription>
            We recommend adding the following notice on your contracts to remind your clients that they should verify that your
            bank account certificate is validated and up to date through nsKnox’s online verification service.
          </TabContentDescription>
          <CustomerKitCommunicationTextCard copyButtonId='customer-kit-copy-contracts'>
            {CONTRACTS_VALIDATION_TEXT}
          </CustomerKitCommunicationTextCard>
        </PaddedTabPane>
      </StyledTabs>
    </Main>
  );
};

export default CustomerKit;

const Main = styled.div`
  width: min(100vw, 650px);

  --customer-kit-top-background: #f3f4f7;
  --customer-kit-content-padding: 20px 30px 30px;
  overflow-y: hidden;
`;

const MainExplanation = styled.div`
  background-color: var(--customer-kit-top-background);
  padding: var(--customer-kit-content-padding);
`;

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const Title = styled(H5StartTransparentBlack900.div)`
  flex: 1;
`;

const CloseButton = styled(Button)`
  padding: 4px;
`;

const StyledTabs = styled(Tabs)`
  & .ant-tabs-bar {
    margin: 0;
    background-color: var(--customer-kit-top-background);
  }

  & .ant-tabs-content {
    flex: 1;
  }
`;

const PaddedTabPane = styled(TabPane)`
  padding: var(--customer-kit-content-padding);
`;

const TabContentTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 12px;
`;

const TabContentDescription = styled(BodySmallStartTransparentBlack900.div)`
  margin-bottom: 16px;
`;

const EmailTab = styled.div`
  height: 75vh;
  overflow: hidden auto;
  padding: var(--customer-kit-content-padding);
`;
