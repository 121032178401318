import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { flow } from 'mobx';
import { FilesUploadMode } from '@mortee/domain/fileUpload';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import { FileAndErrors, FileTypes, handleGeneralFileUploadError, translateFileNameToContentType } from '@app/domain/files';
import SelectFiles from '@mortee/components/check/SelectFiles';
import Button from '@app/components/Button';
import MorteeMode from '@mortee/morteeMode';
import PageWithHeader from '@app/components/PageWithHeader';
import useInfraStores from '@app/hooks/useInfraStores';
import * as secureFilesServices from '@mortee/services/secureFilesServices';
import { RequestError } from '@app/libs/request';
import Log from '@app/libs/logger';
import sleep from '@app/utils/sleep';
import useMountEffect from '@app/hooks/useMountEffect';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';

const CheckSecureFilesLoadPage: FunctionComponent<RouteComponentProps> = observer(() => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const localStore = useLocalObservable(() => ({
    secureFiles: [] as FileAndErrors[],
    uploading: false as boolean,
    note: undefined as string | undefined,
  }));

  useMountEffect(() => {
    window.onpopstate = onBackButtonEvent;
  });

  const onBackButtonEvent = (): void => {
    navigationStore.moveToSectionHome();
  };

  const allowedFileTypes: FileTypes[] = [
    FileTypes.txt,
    FileTypes.pdf,
    FileTypes.xls,
    FileTypes.xlsx,
    FileTypes.csv,
    FileTypes.doc,
    FileTypes.docx,
    FileTypes.xml,
  ];

  const handleUpload = flow(function* (files: FileAndErrors[], notes?: string) {
    localStore.uploading = true;
    localStore.note = notes;
    localStore.secureFiles = files || [];
    try {
      const sendForm = new FormData();
      const batchNote = { note: localStore.note };

      localStore.secureFiles.forEach((file) =>
        sendForm.append(
          'secureFiles',
          new Blob([file.file], { type: translateFileNameToContentType(file.file.name) }),
          file.file.name,
        ),
      );

      sendForm.append(
        'batchNote',
        new Blob([JSON.stringify(batchNote)], {
          type: 'application/json',
        }),
      );

      // Make upload request take at lease 3 seconds
      const [secureFilesServerResponse] = yield Promise.all([
        secureFilesServices.postSurveysForOrganization(sendForm),
        sleep(3000),
      ]);

      navigationStore.moveToSectionHome();

      return secureFilesServerResponse;
    } catch (e: unknown) {
      const filesUploadError = handleGeneralFileUploadError(e as RequestError);
      Log.exception(filesUploadError);

      if (filesUploadError.erroredFiles) {
        localStore.secureFiles = localStore.secureFiles.map((secureFile) => {
          const fileError = filesUploadError.erroredFiles?.[secureFile.file.name];
          if (!fileError) {
            return secureFile;
          }

          return { ...secureFile, errors: [fileError] };
        });
      } else {
        const bodyContent = (
          <div>
            An error occurred while uploading files.
            <br />
            Please contact us at <BlueText>support@nsknox.net</BlueText> for assistance.
            {filesUploadError.errorLogId && ` Error Code: ${filesUploadError.errorLogId}`}
          </div>
        );

        showContentOnlyModal((onDone) => (
          <ErrorModal headerContent='Something went wrong' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
        ));
      }
    } finally {
      localStore.uploading = false;
    }
  });

  const cancelPress = (): void => {
    navigationStore.moveToSectionHome();
  };

  return (
    <PageWithHeader widthReactive={false}>
      <Container>
        <PageHeader>
          <PageTitle>Upload Files</PageTitle>
          <Button
            id='btn-secure-files-load-cancel'
            onClick={(): void => cancelPress()}
            disabled={localStore.uploading}
            appearance='outline'
            colorScheme='primary'
          >
            CANCEL
          </Button>
        </PageHeader>
        <SelectFiles
          isUploading={localStore.uploading}
          allowedFileTypes={allowedFileTypes}
          modes={[FilesUploadMode.secureFiles]}
          onFilesUpload={handleUpload}
          initialNotes={localStore.note}
          files={localStore.secureFiles}
        />
      </Container>
    </PageWithHeader>
  );
});

export default CheckSecureFilesLoadPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PageTitle = styled.span`
  font-size: 36px;
  color: var(--gray-blue-deprecated);
  font-weight: 200;
  letter-spacing: 0.49px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BlueText = styled.span`
  font-weight: bold;
  color: var(--accent-blue-600);
`;
