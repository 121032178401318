import React, { FC, ReactNode } from 'react';
import VerifyOkIcon from '@ar/images/guest-verify-ok.svg';
import { Bold15TransparentBlack900, Regular13TransparentBlack900 } from '@app/components/Text';
import VerifyFailedIcon from '@ar/images/guest-verify-failed.svg';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import SVG from '@app/components/SVG';
import { useTranslation } from 'react-i18next';

interface Props {
  isValidated: boolean;
  resultTitle: string;
  className?: string;
  children: ReactNode;
}

const VerificationResultCard: FC<Props> = ({ isValidated, resultTitle, className, children }) => {
  const { t } = useTranslation();

  if (isValidated) {
    return (
      <ValidationCard
        role='status'
        customBackgroundColor='#ecf9eb'
        strokeColor='#4dc749'
        id='crd-after-validation-account-validated'
        dataTestId='conValidateResult'
        data-validation-result='arValidated'
        className={className}
      >
        <VerifyContent>
          <VerifyResult>
            <VerifyResultInner>
              <VerifySVG
                accessibilityLabel={t('general.accessibility.verificationPassed')}
                image={VerifyOkIcon}
                width={38}
                height={45}
              />
              <Bold15TransparentBlack900.div>{resultTitle}</Bold15TransparentBlack900.div>
            </VerifyResultInner>
          </VerifyResult>
          <VerifyResultExplanation>{children}</VerifyResultExplanation>
        </VerifyContent>
      </ValidationCard>
    );
  }

  return (
    <ValidationCard
      role='status'
      customBackgroundColor='#fdeced'
      strokeColor='#ed524a'
      id='crd-after-validation-account-not-validated'
      dataTestId='conValidateResult'
      data-validation-result='arNotValidated'
      className={className}
    >
      <VerifyContent>
        <VerifyResult>
          <VerifyResultInner>
            <VerifySVG
              accessibilityLabel={t('general.accessibility.verificationFailed')}
              image={VerifyFailedIcon}
              width={38}
              height={45}
            />
            <Bold15TransparentBlack900.div>{resultTitle}</Bold15TransparentBlack900.div>
          </VerifyResultInner>
        </VerifyResult>
        <VerifyResultExplanation>{children}</VerifyResultExplanation>
      </VerifyContent>
    </ValidationCard>
  );
};

export default VerificationResultCard;

const ValidationCard = styled(Card)`
  width: 100%;
  padding: 20px 32px 20px 0;
`;

const VerifyContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VerifyResult = styled.div`
  flex: 2;
`;

const VerifyResultInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;

  text-align: center;
`;

const VerifyResultExplanation = styled(Regular13TransparentBlack900.div)`
  flex: 7;
  line-height: 24px;
`;

const VerifySVG = styled(SVG)`
  margin-bottom: 8px;
`;
