import { DependencyList, useEffect, useState } from 'react';

export function useConsistentMemo<T>(factory: () => T, deps: DependencyList | undefined): T {
  const [consistentMemoValue, setConsistentMemoValue] = useState(factory);

  useEffect(() => {
    setConsistentMemoValue(factory());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we do not need factory as part of dependency list
  }, deps);

  return consistentMemoValue;
}
