import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import Tabs, { TabPane } from '@app/components/Tabs';
import SupplierValidationRecordActivityLog from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/activityLog/SupplierValidationRecordActivityLog';
import useLoadable from '@app/hooks/loadable/useLoadable';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { transformActivityLog } from '@mortee/domain/validationSystemTimelineEvents';
import { MainTabs } from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordMainTabs';
import { SelectedTab } from '@mortee/components/ProgressTabs';
import SupplierValidationRecordComments from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/SupplierValidationRecordComments';
import SupplierValidationRecordAlerts from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/SupplierValidationRecordAlerts';
import { Alert, AlertStatus } from '@mortee/domain/validationSystemAlerts';
import Loadable from '@app/utils/Loadable';

export enum SecondaryTabs {
  alerts = 'Alerts',
  comments = 'Comments',
  activityLog = 'Activity Log',
}

interface Props {
  record: SupplierValidationRecord;
  className?: string;
  selectedTab: SelectedTab<SecondaryTabs>;
  selectedMainTab: MainTabs;
  reloadAlerts(): void;
  allAlertsLoadable: Loadable<Alert[]>;
}

const EditValidationRecordSecondaryTabs: FC<Props> = observer((props) => {
  const { record, selectedTab, selectedMainTab, className, reloadAlerts, allAlertsLoadable } = props;

  const { userStore } = useInfraStores<MorteeMode>();

  const [timelineEvents, reloadEvents] = useLoadable(async () => {
    const serverResponse = await validationSystemServices.getValidationTimelineByStaticId(record.staticId);
    return transformActivityLog(serverResponse, userStore.user?.id);
  }, [record.versionId]);

  const onAlertChange = async (): Promise<void> => {
    await Promise.all([reloadAlerts(), reloadEvents()]);
  };

  function getActiveAlertsCount(): number | undefined {
    if (allAlertsLoadable.isResolved()) {
      return allAlertsLoadable.result?.filter((alert) => alert.alertStatus === AlertStatus.active).length;
    }
  }

  const activeAlertsCount = getActiveAlertsCount();

  return (
    <ExpandedTabs
      tabPosition='left'
      activeKey={selectedTab.value}
      onChange={selectedTab.onChange}
      className={className}
      destroyInactiveTabPane
    >
      <StyledTabPane
        tab={
          <>
            <AlertTab data-testid='tab-edit-validation-process-secondary-tabs-alerts'>
              Alerts&nbsp;{!!activeAlertsCount && <AlertBadge data-testid='alerts-badge'>{activeAlertsCount}</AlertBadge>}
            </AlertTab>
          </>
        }
        key={SecondaryTabs.alerts}
      >
        <SupplierValidationRecordAlerts record={record} allAlertsLoadable={allAlertsLoadable} onAlertChanged={onAlertChange} />
      </StyledTabPane>
      <StyledTabPane
        tab={<span data-testid='tab-edit-validation-process-secondary-tabs-comments'>Comments</span>}
        key={SecondaryTabs.comments}
      >
        <SupplierValidationRecordComments
          record={record}
          allTimelineEventLoadable={timelineEvents}
          allowedNewCommentTypes={selectedMainTab === MainTabs.verification ? 'internal-validation' : 'public-or-internal'}
          onSaved={reloadEvents}
        />
      </StyledTabPane>
      <StyledTabPane
        tab={<span data-testid='tab-edit-validation-process-secondary-tabs-activity-log'>Activity Log</span>}
        key={SecondaryTabs.activityLog}
      >
        <SupplierValidationRecordActivityLog allTimelineEventLoadable={timelineEvents} />
      </StyledTabPane>
    </ExpandedTabs>
  );
});

export default EditValidationRecordSecondaryTabs;

const ExpandedTabs = styled(Tabs)`
  flex: 1;

  & .ant-tabs-nav-container {
    padding: 0 var(--side-padding);
  }

  & .ant-tabs-content {
    height: 93%;
  }
`;

const StyledTabPane = styled(TabPane)`
  display: flex;
  flex-direction: column;
`;

const AlertTab = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlertBadge = styled.div`
  font-size: 12px;
  color: white;
  background-color: #f35252;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1px;
`;
