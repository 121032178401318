import { trim } from '@app/utils/stringUtils';
import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-IN-ifsc-code',
    inputType: 'text',
    decoratorRules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.IN.IFSC,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.IN.IFSC,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumeric,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.IN.errors.invalidBankCode`,
          'supplierValidation.supplierRegister.errors.invalidBankCode',
        ],
      },
    ],
  };
}

export enum IndiaLegalIdTypesForCompany {
  GST = 'Goods and Services Tax',
  Other = 'Other',
}

export const IN: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
  leis: { types: IndiaLegalIdTypesForCompany },
};
