import { extractLogErrorIdFromError, HttpStatus, isWebErrorContent, RequestError } from '@app/libs/request';
import Log from '@app/libs/logger';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import { extractErroredFileName } from '@app/components/fileUpload/fileUploadUtils';
import { FilesErrorOutput } from '@app/domain/files';

export enum PaymentCheckBatchStatus {
  batchInProgress = 'BatchInProgress',
  batchRejected = 'BatchRejected',
  batchApproved = 'BatchApproved',
}

export enum SinglePaymentStatus {
  paymentRejected = 'PaymentRejected',
  paymentApproved = 'PaymentApproved',
}

export interface PaymentCheckBatchServerResponse {
  id: string;
  status: PaymentCheckBatchStatus;
  files: FileMetadataServerResponse[];
  paymentSums: { [currency: string]: number };
  notes: string;
  writeTimestamp: number;
  paymentPolicyRevisionId: string;
  paymentsCount: number;
  performingUserName: string;
}

export interface SinglePaymentServerResponse {
  id: string;
  status: SinglePaymentStatus;
  accountDetails: MorteeAccountDetails;
  amount: number;
  currency: string;
  fileId: string;
  results: SinglePaymentResultServerResponse[];
}

export interface SinglePaymentResultServerResponse {
  payeeRevisionId: string;
  payeeName: string;
  payeeExternalId: string;
  message: string;
  validationLevel: DeterministicValidationResultType;
  fileId: string;
}

export interface SinglePaymentResult {
  payeeRevisionId: string;
  payeeName: string;
  payeeExternalId: string;
  message: string;
  validationLevel: DeterministicValidationResultType;
}

export const LOAD_PAYMENT_FILE_TITLE: string =
  'Upload new payments files. The files will be analyzed based on your payment policies.';

export enum PAYMENTS_ERRORS {
  defaultError = 'DEFAULT_ERROR',
  emptyFile = 'EMPTY_FILE',
  emptyFileName = 'EMPTY_FILE_NAME',
  fileUnauthorizedContentType = 'FILE_UNAUTHORIZED_CONTENT_TYPE',
  wrongNamePattern = 'WRONG_FILE_NAME_PATTERN',
  multipleCurrencies = 'MULTIPLE_CURRENCIES',
  faultyAccounts = 'FAULTY_ACCOUNTS',
  noSuitableParser = 'NO_SUITABLE_PARSER',
  failedToParse = 'FAILED_TO_PARSE',
  filesNotSecure = 'FILES_NOT_SECURE',
  nullContent = 'NULL_CONTENT',
  numericFormat = 'NUMERIC_FORMAT',
  automaticInstallment = 'AUTOMATIC_INSTALLMENT',
  emptyValue = 'EMPTY_VALUE',
  nullValue = 'NULL_VALUE',
  dateFormat = 'DATE_FORMAT',
  summaryTooShort = 'SUMMARY_LENGTH_TOO_SHORT',
  summaryTooLong = 'SUMMARY_LENGTH_TOO_LONG',
  summaryValue = 'SUMMARY_VALUE',
  paymentTooShort = 'PAYMENT_LENGTH_TOO_SHORT',
  paymentTooLong = 'PAYMENT_LENGTH_TOO_LONG',
  paymentValue = 'PAYMENT_VALUE',
  bankCodeTooLong = 'BANK_CODE_TOO_LONG',
  branchCodeTooLong = 'BRANCH_CODE_TOO_LONG',
  notEnoughSeparators = 'NOT_ENOUGH_SEPARATORS',
  citiEuAchCtAccountPropertyTooShort = 'CITI_EU_ACH_CT_ACCOUNT_PROPERTY_TOO_SHORT',
  invalidSwift = 'INVALID_SWIFT',
  invalidIban = 'INVALID_IBAN',
  missingCountryInfo = 'MISSING_COUNTRY_INFO',
  invalidBankCode = 'INVALID_BANK_CODE',
  invalidAccountNumber = 'INVALID_ACCOUNT_NUMBER',
  invalidCurrency = 'INVALID_CURRENCY',
  invalidAccountDetails = 'INVALID_ACCOUNT_DETAILS',
  invalidStructure = 'INVALID_STRUCTURE',
  emptyCurrency = 'EMPTY_CURRENCY',
  invalidAmount = 'INVALID_AMOUNT',
  emptyAmount = 'EMPTY_AMOUNT',
  unsupportedCitiPaymentType = 'UNSUPPORTED_CITI_PAYMENT_TYPE',
  swiftCodeTooLong = 'SWIFT_CODE_TOO_LONG',
}

export enum PAYMENTS_ATTRIBUTES {
  LINES_COUNT = 'lines count',
  FILE_VERSION = 'file version',
  FILE_TYPE = 'file type',
  SEQUENCE_ID = 'sequence ID',
  PAYMENT_AMOUNT = 'payment amount',
  CURRENCY = 'currency',
  REFERENCE_AMOUNT = 'reference amount',
  INVOICE_AMOUNT = 'invoice amount',
  BANK_ID = 'bank ID',
  BANK_BRANCH_ID = 'bank branch ID',
  BANK_ACCOUNT_ID = 'bank account ID',
  IBAN = 'IBAN',
  US_ROUTING_NUMBER = 'US routing number',
  US_ACCOUNT_NUMBER = 'US account number',
  US_REST = 'US rest',
  GBD_BANK_ACCOUNT_ID = 'GBD bank account ID',
  GBD_SORT_CODE = 'GBD sort code',
  GBD_ACCOUNT_NUMBER = 'GBD account number',
  GBD_REST = 'GBD rest',
  IDENTIFICATION = 'identification',
  PAYER_ORGANIZATION_MASAV_ID = 'payer organization masav ID',
  PAYMENT_DATE = 'payment date',
  HEADLINE_ENDING = 'headline ending',
  FILE_END_LINE = 'file end line',
  PAYMENT_TOTAL_AMOUNT = 'payment total amount',
  NUMBER_OF_PAYMENTS = 'number of payments',
  PAYMENT_PERIOD_FROM = 'payment period from',
  PAYMENT_PERIOD_FROM_DATE = 'payment period from date',
  PAYMENT_PERIOD_TO = 'payment period to',
  PAYMENT_PERIOD_TO_DATE = 'payment period to date',
  TRANSACTION_AMOUNT = 'transaction amount',
  TRANSACTION_SUM = 'transaction summary',
  CREATION_TIME = 'creation time',
  MESSAGE_ID = 'message ID',
  PAYMENT_INFORMATION = 'payment information',
  PAYMENT_LIST = 'payment list',
  PAYMENT = 'payment',
  AMOUNT_DATA = 'amount data',
  INSTRUCTED_AMOUNT = 'instructed amount',
  AMOUNT = 'amount',
  ACCOUNT = 'account',
  ACCOUNT_IBAN = 'account IBAN',
  ACCOUNT_ID = 'account ID',
}

interface AdditionalData {
  line?: number;
  attribute?: PAYMENTS_ATTRIBUTES;
  actualValue?: string[];
  expectedValue?: number | string | ExpectedValue | string[];
}

interface ExpectedValue {
  min: number;
  max: number;
}

export const PaymentsErrors: { [error in PAYMENTS_ERRORS]: { text: (values: AdditionalData) => string } } = {
  [PAYMENTS_ERRORS.defaultError]: {
    text: (): string =>
      'An error has occurred. Please try again. If the problem persists, please contact us at support@nsknox.net',
  },
  [PAYMENTS_ERRORS.emptyFile]: {
    text: (): string => 'Your file does not contain any payments/data. Please check and try again',
  },
  [PAYMENTS_ERRORS.emptyFileName]: {
    text: (): string => 'Please enter a valid file name',
  },
  [PAYMENTS_ERRORS.fileUnauthorizedContentType]: {
    text: (values: AdditionalData): string => {
      const allowed = (values.expectedValue as string[])?.join();
      if (allowed) {
        return `Invalid file type. Please use one of the following permitted file types: ${allowed}`;
      }
      return `Invalid file type`;
    },
  },
  [PAYMENTS_ERRORS.wrongNamePattern]: {
    text: (): string => 'File name must not contain the following characters: ; , : ? " * | \\ /',
  },
  [PAYMENTS_ERRORS.multipleCurrencies]: {
    text: (): string => 'Payments file contains multiple currencies. Please resubmit with one currency per payment file',
  },
  [PAYMENTS_ERRORS.faultyAccounts]: {
    text: (values: AdditionalData): string => {
      const faulty = values.actualValue?.join();
      return `Payments file contains incorrectly formatted or invalid accounts. ${faulty}`;
    },
  },
  [PAYMENTS_ERRORS.noSuitableParser]: {
    text: (): string => 'Payments file format not recognized. Please check and try again',
  },
  [PAYMENTS_ERRORS.failedToParse]: {
    text: (): string => 'Payments file format failure. Please check formatting of your file and resubmit',
  },
  [PAYMENTS_ERRORS.filesNotSecure]: {
    text: (): string =>
      'File not secure, Please check and try again. If the problem persists, please contact us at support@nsknox.net',
  },
  [PAYMENTS_ERRORS.nullContent]: {
    text: (): string => 'Payments file contains invalid data content. Please check and try again',
  },
  [PAYMENTS_ERRORS.numericFormat]: {
    text: (values: AdditionalData): string => {
      const { attribute, line, actualValue } = values;
      if (attribute && PAYMENTS_ATTRIBUTES[attribute] && line && actualValue) {
        return `Payments file contains invalid alphanumeric content in numeric only fields. Please check ${PAYMENTS_ATTRIBUTES[attribute]} in line ${line}: ${actualValue} and try again`;
      }
      return `Payments file contains invalid alphanumeric content in numeric only fields`;
    },
  },
  [PAYMENTS_ERRORS.automaticInstallment]: {
    text: (): string =>
      'Payments file structure or formatting error. Please check that your file adheres to the published format and try again',
  },
  [PAYMENTS_ERRORS.emptyValue]: {
    text: (values: AdditionalData): string => {
      const { attribute, line } = values;
      if (attribute && PAYMENTS_ATTRIBUTES[attribute] && line) {
        return `Payments file contains invalid data content. Please correct and try again. ${PAYMENTS_ATTRIBUTES[attribute]} in line ${line}`;
      }
      return `Payments file contains invalid data content`;
    },
  },
  [PAYMENTS_ERRORS.nullValue]: {
    text: (values: AdditionalData): string => {
      const { attribute } = values;
      if (attribute && PAYMENTS_ATTRIBUTES[attribute]) {
        return `Payments file contains invalid data content. Please correct and try again. attribute: ${PAYMENTS_ATTRIBUTES[attribute]}`;
      }
      return `Payments file contains invalid data content`;
    },
  },
  [PAYMENTS_ERRORS.dateFormat]: {
    text: (values: AdditionalData): string => {
      const { attribute, line, actualValue } = values;
      if (attribute && PAYMENTS_ATTRIBUTES[attribute] && line && actualValue) {
        return `Please correct date format and try again. ${PAYMENTS_ATTRIBUTES[attribute]} in line ${line}: ${actualValue}`;
      }
      return `Please correct date format and try again`;
    },
  },
  [PAYMENTS_ERRORS.summaryTooShort]: {
    text: (): string =>
      `Payments file structure or formatting error. Please check that your file adheres to the published format and try again`,
  },
  [PAYMENTS_ERRORS.summaryTooLong]: {
    text: (): string =>
      `Payments file structure or formatting error. Please check that your file adheres to the published format and try again`,
  },
  [PAYMENTS_ERRORS.summaryValue]: {
    text: (): string => 'Payments file formatting error. Please check file summary values and try again',
  },
  [PAYMENTS_ERRORS.paymentTooShort]: {
    text: (values: AdditionalData): string => {
      const { line, expectedValue } = values;
      if (expectedValue) {
        const { min, max } = expectedValue as ExpectedValue;
        if (!!min && !!max) {
          return `Payments file contains invalid data content. The payment in line ${line} length should be between ${min} and ${max}`;
        }
      }
      return `Payments file contains invalid data content`;
    },
  },
  [PAYMENTS_ERRORS.paymentTooLong]: {
    text: (values: AdditionalData): string => {
      const { line, expectedValue } = values;
      if (expectedValue) {
        const { min, max } = expectedValue as ExpectedValue;
        if (!!min && !!max) {
          return `Payments file contains invalid data content. The payment in line ${line} length should be between ${min} and ${max}`;
        }
      }
      return `Payments file contains invalid data content`;
    },
  },
  [PAYMENTS_ERRORS.paymentValue]: {
    text: (values: AdditionalData): string => {
      const { line } = values;
      if (line) {
        return `Payments file contains invalid data content. The payment in line ${line} format is wrong`;
      }
      return `Payments file contains invalid data content`;
    },
  },
  [PAYMENTS_ERRORS.bankCodeTooLong]: {
    text: (values: AdditionalData): string => {
      const { line, expectedValue } = values;
      if (expectedValue) {
        const { min, max } = expectedValue as ExpectedValue;
        if (!!min && !!max) {
          return `Payments file contains invalid data content. The bank code of payment in line ${line} length should be between ${min} and ${max}`;
        }
      }
      return `Payments file contains invalid data content. The bank code of payment in line ${line} is too long`;
    },
  },
  [PAYMENTS_ERRORS.branchCodeTooLong]: {
    text: (values: AdditionalData): string => {
      const { line, expectedValue } = values;
      if (expectedValue) {
        const { min, max } = expectedValue as ExpectedValue;
        if (!!min && !!max) {
          return `Payments file contains invalid data content. The branch code of payment in line ${line} length should be between ${min} and ${max}`;
        }
      }
      return `Payments file contains invalid data content. The branch code of payment in line ${line} is too long`;
    },
  },
  [PAYMENTS_ERRORS.swiftCodeTooLong]: {
    text: (values: AdditionalData): string => {
      const { line, expectedValue } = values;
      if (expectedValue) {
        const { min, max } = expectedValue as ExpectedValue;
        if (!!min && !!max) {
          return `Payments file contains invalid data content. The swift code of payment in line ${line} length should be between ${min} and ${max}`;
        }
      }
      return `Payments file contains invalid data content. The swift code of payment in line ${line} is too long`;
    },
  },
  [PAYMENTS_ERRORS.notEnoughSeparators]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Missing separators ('#') in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.citiEuAchCtAccountPropertyTooShort]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Account info is too short for payment of type EU-ACH-CT in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidSwift]: {
    text: (values: AdditionalData): string => `Payments file contains invalid data content. Invalid SWIFT in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidIban]: {
    text: (values: AdditionalData): string => `Payments file contains invalid data content. Invalid IBAN in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.missingCountryInfo]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Missing country info (country code, IBAN, or SWIFT) in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidBankCode]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Incorrect bank code format in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidAccountNumber]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Incorrect account number format in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidCurrency]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Incorrect currency format in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.emptyCurrency]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. No currency specified in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidAmount]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Incorrect amount format in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.invalidStructure]: {
    text: (): string => 'Payment file has invalid structure. Please check and try again',
  },
  [PAYMENTS_ERRORS.invalidAccountDetails]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid account details in payment record number ${values.line}`,
  },
  [PAYMENTS_ERRORS.emptyAmount]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. No amount specified in line ${values.line}`,
  },
  [PAYMENTS_ERRORS.unsupportedCitiPaymentType]: {
    text: (values: AdditionalData): string =>
      `Payments file contains invalid data content. Unsupported Citi payment type in line ${values.line}`,
  },
};
export function handleMultiFileUploadError(error: RequestError): FilesErrorOutput {
  const errorLogId = extractLogErrorIdFromError(error) || null;
  if (error.code !== HttpStatus.badRequest) {
    return { errorLogId, erroredFiles: null };
  }

  const { responseJSON } = error;

  if (!responseJSON) {
    Log.exception(new Error('missing responseJSON'));
    return { errorLogId, erroredFiles: null };
  }

  if (!isWebErrorContent(responseJSON)) {
    Log.exception(new Error('missing responseJSON'));
    return { errorLogId, erroredFiles: null };
  }

  const { error: errorCode, additionalData } = responseJSON;

  if (!errorCode || !additionalData) {
    Log.exception(new Error('missing errorCode or additionalData'));
    return { errorLogId, erroredFiles: null };
  }
  const filesNames: string[] = extractErroredFileName(error);

  if (!filesNames.length) {
    return { errorLogId, erroredFiles: null };
  }

  let paymentErrorMessage = PaymentsErrors[errorCode]?.text(additionalData);

  if (!paymentErrorMessage) {
    Log.exception(new Error(`missing PaymentErrors const for ${errorCode}`), { responseJSON });
    paymentErrorMessage = 'An unknown file error has occurred, please contact us at support@nsknox.net';
  }

  const paymentErrorMessageWithLogId = paymentErrorMessage + (errorLogId ? ` (Error Code: ${errorLogId})` : '');

  const filesAndErrors = Object.fromEntries(
    filesNames.map((fileName): [string, string] => [fileName, paymentErrorMessageWithLogId]),
  );

  return { errorLogId: null, erroredFiles: filesAndErrors };
}
