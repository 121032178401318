import { SupplierRegistrationProcessFormType } from '@app/domain/commonSupplierRegistration';
import {
  ReducedSupplierRegistrationProcess,
  RegistrationType,
  SupplierRegistrationFormValuesState,
  SupplierRegistrationProcessRequest,
  SupplierRegistrationProgressStep,
  ValidationProcessSteps,
} from '@supplierRegistration/domain/supplierRegistration';
import * as registrationServices from '@supplierRegistration/services/supplierRegistrationFormServices';
import * as preRegistrationServices from '@supplierRegistration/services/preRegistrationServices';
import Log from '@app/libs/logger';
import { action, computed, makeObservable, observable } from 'mobx';
import { SupplierRegistrationAsyncInitiateValidationProcessRequest } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { isDefined } from '@app/utils/utils';

export type FormValues = { [field: string]: any };

export default class SupplierRegistrationFormStore {
  @observable skipWelcome: boolean = false;
  @observable currentProgressStep: number = 0;
  @observable currentValidationProcessStep: ValidationProcessSteps = ValidationProcessSteps.welcome;
  @observable currentSupplierRegistrationValues: SupplierRegistrationFormValuesState = {
    supplierValues: null,
    accountValues: null,
  };

  private _formValues: FormValues = {};
  private _formRegistrationType: RegistrationType = RegistrationType.company;
  private _isOtherLEIType: boolean = false;

  constructor() {
    makeObservable(this);
  }

  cleanSupplierRegistrationStoreValues = action(() => {
    this.setSupplierRegistrationValues({
      accountValues: null,
      supplierValues: null,
    });

    this.currentValidationProcessStep = this.skipWelcome
      ? ValidationProcessSteps.companyInformation
      : ValidationProcessSteps.welcome;
  });

  setSkipWelcome = action((skipWelcome: boolean) => {
    this.skipWelcome = skipWelcome;

    if (skipWelcome && this.currentValidationProcessStep === ValidationProcessSteps.welcome) {
      this.setValidationProcessStep(ValidationProcessSteps.companyInformation);
    }
  });

  setProgressStep = action((progressStep: SupplierRegistrationProgressStep) => {
    this.currentProgressStep = this.getStepIndex(progressStep);
  });

  setValidationProcessStep = action((validationStep: ValidationProcessSteps) => {
    this.currentValidationProcessStep = validationStep;
  });

  setSupplierRegistrationValues = action((registrationValues: SupplierRegistrationFormValuesState) => {
    this.currentSupplierRegistrationValues = registrationValues;
  });

  @computed
  get isIndividual(): boolean {
    return this.currentSupplierRegistrationValues?.supplierValues?.registrationType === RegistrationType.individual;
  }

  saveFormValues = (values: FormValues, registrationType: RegistrationType, isLEIType: boolean): void => {
    this._formValues = values;
    this._formRegistrationType = registrationType;
    this._isOtherLEIType = isLEIType;
  };

  clearSavedFormValues = (): void => {
    this._formValues = {};
    this._formRegistrationType = RegistrationType.company;
    this._isOtherLEIType = false;
  };

  getIsOtherLEIType = (): boolean => {
    return this._isOtherLEIType;
  };

  getSavedFormValues = (): FormValues => {
    return this._formValues;
  };

  getRegistrationType = (): RegistrationType => {
    return this._formRegistrationType;
  };

  getStepIndex = (step: SupplierRegistrationProgressStep): number => {
    return [
      !this.skipWelcome ? SupplierRegistrationProgressStep.Welcome : undefined,
      SupplierRegistrationProgressStep.Registration,
      SupplierRegistrationProgressStep.BankAccountDetails,
      SupplierRegistrationProgressStep.OneTimeTransfer,
    ]
      .filter(isDefined)
      .findIndex((element) => element === step);
  };

  storeValidationProcessRequest = async (
    supplierData: SupplierRegistrationProcessRequest,
    isIpsMode: boolean,
    language: string | undefined,
    captchaToken: string | null | undefined,
  ): Promise<ReducedSupplierRegistrationProcess> => {
    try {
      const validationType = isIpsMode
        ? SupplierRegistrationProcessFormType.incomingPaymentSecurity
        : SupplierRegistrationProcessFormType.regularSupplierValidation;

      const serverResponse = await registrationServices.storeSupplierRegistrationProcessRequest(
        supplierData,
        validationType,
        language,
        captchaToken,
      );
      return registrationServices.transformToReducedSupplierRegistrationProcess(serverResponse);
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    }
  };

  storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation = async (
    supplierData: SupplierRegistrationAsyncInitiateValidationProcessRequest,
    asyncProcessId: string,
    language: string | undefined,
    captchaToken: string | null | undefined,
  ): Promise<ReducedSupplierRegistrationProcess> => {
    try {
      const serverResponse = await registrationServices.storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
        supplierData,
        asyncProcessId,
        language,
        captchaToken,
      );
      return registrationServices.transformToReducedSupplierRegistrationProcess(serverResponse);
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    }
  };

  preRegistrationRequest = async (
    supplierData: SupplierRegistrationProcessRequest,
    captchaToken: string | null | undefined,
  ): Promise<void> => {
    try {
      await preRegistrationServices.preRegistrationRequest(supplierData, captchaToken);
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    }
  };

  preRegistrationRequestFromAsyncInitiateValidation = async (
    supplierData: SupplierRegistrationAsyncInitiateValidationProcessRequest,
    asyncProcessId: string,
    captchaToken: string | null | undefined,
  ): Promise<void> => {
    try {
      await preRegistrationServices.preRegistrationRequestFromAsyncInitiateValidation(supplierData, asyncProcessId, captchaToken);
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    }
  };
}
