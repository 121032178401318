import { UUID_REGEX } from '@app/utils/regexUtils';
import { useSingleQueryParam } from '@app/hooks/useSingleQueryParam';

export default function useAsyncIdFromLocation(allowCustomerCustomization: boolean): [asyncId: string | null, isValid: boolean] {
  const [ref, , isAsyncValueValid] = useSingleQueryParam('asyncId', UUID_REGEX);

  if (!allowCustomerCustomization) {
    return [null, true];
  }

  return [ref, isAsyncValueValid];
}
