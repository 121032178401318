import { serverUrl } from '../config';
import request, { HttpMethod } from '../libs/request';

export async function getUserInfo(secretId?: string): Promise<AppUser> {
  return request<AppUser>(serverUrl, `/api/login`, {
    secretId,
    method: HttpMethod.get,
    suppressNotification: true,
    dontRedirectToLogin: true,
  });
}

export async function verifyUserInfo(secretId?: string): Promise<void> {
  return request<void>(serverUrl, `/api/login/verify`, {
    secretId,
    method: HttpMethod.get,
    suppressNotification: true,
    dontRedirectToLogin: true,
  });
}
