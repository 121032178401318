import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import Log from '../libs/logger';

export enum TranslationNamespaces {
  mode = 'mode',
  general = 'general',
}

interface LanguageTranslationMetadata {
  locations: Record<TranslationNamespaces, string>;
  display: string;
  hasDialectCharacters: boolean;
  codes: string[];
  isRTL?: boolean;
  flagCountryCode: string;
}

export interface Language {
  key: string;
  display: string;
  codes: string[];
  isRTL: boolean;
  flagCountryCode: string;
}

export interface SelectableLanguage {
  key: string;
  display: string;
  flagCountryCode: string;
}

export interface CurrentLanguageProvider {
  selectedLocale: string | undefined;
}

const HTML_PAGE_LANGUAGE_ATTRIBUTE = 'lang';

export default class LanguageStore implements CurrentLanguageProvider {
  static readonly DEFAULT_LANGUAGE = 'english';

  translations: Record<string, LanguageTranslationMetadata> = {};
  defaultTranslation: LanguageTranslationMetadata;
  supportedCodesToTranslationMetadata: Map<string, LanguageTranslationMetadata>;
  supportedCodesToLanguageKey: Map<string, string>;
  forceLocale: string | null = null;
  localePath: string;

  @observable selectedLanguageKey: string = '';
  @observable isLanguageLoading: boolean = false;
  @observable isRTL: boolean = false;

  private _i18n = i18n;

  constructor(forceLocale: string | null = null, localePath: string) {
    makeObservable(this);

    this.forceLocale = forceLocale;
    this._i18n.on('languageChanged', this.on18nLanguageChange);
    this.localePath = localePath;
  }

  public init = flow(function* (this: LanguageStore) {
    const translationsResponse = yield fetch(this.localePath);
    this.translations = yield translationsResponse.json();

    this.supportedCodesToTranslationMetadata = new Map<string, LanguageTranslationMetadata>(
      Object.values(this.translations).flatMap((translationMetadata) =>
        translationMetadata.codes.map((code) => [code, translationMetadata]),
      ),
    );

    this.supportedCodesToLanguageKey = new Map<string, string>(
      Object.entries(this.translations).flatMap(([key, translationMetadata]) =>
        translationMetadata.codes.map((code) => [code, key]),
      ),
    );

    this.defaultTranslation = this.translations[LanguageStore.DEFAULT_LANGUAGE];

    this._i18n
      .use(LanguageDetector)
      .use(HttpApi)
      .use(initReactI18next)
      .init({
        fallbackLng: this.defaultTranslation.codes[0],
        supportedLngs: Array.from(this.supportedCodesToTranslationMetadata.keys()),
        nsSeparator: false,
        load: 'currentOnly',
        ns: [TranslationNamespaces.mode, TranslationNamespaces.general],
        defaultNS: TranslationNamespaces.mode,
        fallbackNS: TranslationNamespaces.general,
        initImmediate: false,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: (lngs: string[], namespaces: TranslationNamespaces[]): string =>
            this.supportedCodesToTranslationMetadata.get(lngs[0])?.locations[namespaces[0]] ?? '',
          allowMultiLoading: false,
        },
      });
  });

  @computed
  get availableLanguagesMap(): Map<string, Language> {
    return new Map<string, Language>(
      Object.entries(this.translations).map(([key, trans]) => [
        key,
        {
          key,
          display: trans.display,
          codes: trans.codes,
          isRTL: trans.isRTL ?? false,
          flagCountryCode: trans.flagCountryCode,
        },
      ]),
    );
  }

  @computed
  get availableLanguages(): SelectableLanguage[] {
    return [...this.availableLanguagesMap.values()].sort((trans1, trans2) => trans1.display.localeCompare(trans2.display));
  }

  @computed
  get selectedLanguageHasDialectCharacters(): boolean {
    return !!this.translations[this.selectedLanguageKey]?.hasDialectCharacters;
  }

  @action
  on18nLanguageChange = (): void => {
    const newSelectedLanguageKey = this.supportedCodesToLanguageKey.get(this._i18n.language);
    this.selectedLanguageKey = newSelectedLanguageKey ?? LanguageStore.DEFAULT_LANGUAGE;
    this.isRTL = this.availableLanguagesMap.get(this.selectedLanguageKey)?.isRTL ?? false;
    this.isLanguageLoading = false;
    LanguageStore.setHTMLLanguage(this._i18n.language);
  };

  get selectedLocale(): string | undefined {
    if (this.forceLocale) {
      return this.forceLocale;
    }

    if (this.supportedCodesToTranslationMetadata.has(this._i18n.language)) {
      return this._i18n.language;
    }

    return this.defaultTranslation.codes[0];
  }

  changeLanguage = flow(function* (this: LanguageStore, langKey: string) {
    const loadedLang = this.availableLanguagesMap.get(langKey);

    if (!loadedLang) {
      return;
    }

    try {
      this.isLanguageLoading = true;
      yield this._i18n.changeLanguage(loadedLang.codes[0]);

      if (this.isRTL != loadedLang.isRTL) {
        this.isRTL = loadedLang.isRTL;
        this._i18n.dir(loadedLang.isRTL ? 'rtl' : 'ltr');
      }
    } catch (e: unknown) {
      Log.exception(e);
    } finally {
      this.isLanguageLoading = false;
    }
  });

  private static setHTMLLanguage(newLanguageCode: string): void {
    document.documentElement.setAttribute(HTML_PAGE_LANGUAGE_ATTRIBUTE, newLanguageCode);
  }
}
