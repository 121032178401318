import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import {
  BodyRegularStartTransparentBlack600,
  SubtitleRegularStartTransparentBlack900,
  SubtitleSmallStartError600,
} from '@app/components/Text';
import CertificateImage from '@app/images/certificate-with-white-background.svg';
import RefreshImage from '@app/images/refresh-accent.svg';
import WarningImage from '@app/images/warning-thick.svg';
import SVG from '@app/components/SVG';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import {
  BlackListedFileTypesList,
  checkFile,
  DEFAULT_MAX_FILE_NAME_LENGTH,
  DEFAULT_MAX_FILE_SIZE_MB,
  FileErrorTypes,
  FileTypes,
} from '@app/domain/files';
import { isTruthy } from '@app/utils/utils';
import { mergeArrays, wrapValueAsArray } from '@app/utils/arrayUtils';
import { LineBreakTextCss } from '@app/components/Styles';
import Button from '@app/components/Button';
import ReactiveDragger from '@app/components/fileUpload/ReactiveDragger';
import { Upload } from 'antd';
import ARAboveMainCardTitle from '../ARAboveMainCardTitle';
import { CertificateVerificationError } from '@app/domain/bac';
import { TFunction, useTranslation } from 'react-i18next';

const allowedExtensions: FileTypes[] = [FileTypes.pdf];
const maxFilesAllowed = 1;

interface Props {
  certificate: File;
  certificateVerificationError: CertificateVerificationError | null;
  replaceCertificate(newCertificate: File): void;
  verifyCertificate(): Promise<void>;
}

const GuestVerificationWithCertificateBeforeVerificationPage: FC<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verifyWithCertificateBefore);
  const { certificate, certificateVerificationError, replaceCertificate, verifyCertificate } = props;
  const { t } = useTranslation();

  const fileErrors: string[] = useMemo(() => {
    const fileErrorTypes = checkFile(certificate, { allowedExtensions, maxFilesAllowed });
    return mergeArrays([
      fileErrorTypes.map((error) => getFileErrorText(error, t)).filter(isTruthy),
      wrapValueAsArray(getCertificateValidationText(certificateVerificationError, t)),
    ]);
  }, [certificate, certificateVerificationError, t]);

  const handleBeforeUpload = (newCertificate: File): false => {
    replaceCertificate(newCertificate);
    return false;
  };

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <ARAboveMainCardTitle title={t('ar.guestVerification.cert.certificateVerification')} showBackButton />
      <ReactiveDragger
        id='drag-guest-verify-replace-certificate'
        showUploadList={false}
        openFileDialogOnClick={false}
        beforeUpload={(file): boolean => handleBeforeUpload(file)}
      >
        <MainCard>
          <CertificateBox>
            <CertificateDetails>
              <CertificateIcon accessibilityLabel='' image={CertificateImage} width={22} height={27} />
              <NonFlexibleFlexItem>
                <SubtitleRegularStartTransparentBlack900.div>
                  {t('ar.guestVerification.cert.fileUploaded')}
                </SubtitleRegularStartTransparentBlack900.div>
              </NonFlexibleFlexItem>
              <CertificateName>{certificate.name}</CertificateName>
            </CertificateDetails>
            <NonFlexibleFlexItem>
              <Upload accept='.pdf' showUploadList={false} beforeUpload={(file): boolean => handleBeforeUpload(file)}>
                <ReplaceCertificateButton
                  id='btn-guest-validate-replace-certificate'
                  appearance='text'
                  size='small'
                  onClick={(): void => {}}
                >
                  <SVG accessibilityLabel='' image={RefreshImage} width={14} height={14} />
                  <span>{t('ar.guestVerification.cert.upperReplaceCertificate')}</span>
                </ReplaceCertificateButton>
              </Upload>
            </NonFlexibleFlexItem>
          </CertificateBox>
          {!!fileErrors.length && (
            <FileErrors role='alert'>
              {fileErrors.map((error) => (
                <ErrorContainer key={error}>
                  <WarningIcon accessibilityLabel='' image={WarningImage} />
                  <PaddedDiv>{error}</PaddedDiv>
                </ErrorContainer>
              ))}
            </FileErrors>
          )}
          <ActionContainer>
            <PrimaryButton
              autoFocus
              id='btn-guest-validate-with-certificate'
              dataTestId='btnValidateCertificate'
              disabled={!!fileErrors.length}
              onClick={(): Promise<void> => verifyCertificate()}
            >
              {t('ar.guestVerification.cert.upperValidatedCertificate')}
            </PrimaryButton>
          </ActionContainer>
        </MainCard>
      </ReactiveDragger>
    </PageWithHeader>
  );
});

const getFileErrorText = (errorType: FileErrorTypes, t: TFunction<string>): string | null => {
  switch (errorType) {
    case FileErrorTypes.emptyFile:
      return t('ar.guestVerification.cert.errors.noContent');
    case FileErrorTypes.fileNameLength:
      return t('ar.guestVerification.cert.errors.filenameTooLong', { fileNameLength: DEFAULT_MAX_FILE_NAME_LENGTH });
    case FileErrorTypes.fileSize:
      return t('ar.guestVerification.cert.errors.fileTooLarge', { maxFileSize: DEFAULT_MAX_FILE_SIZE_MB });
    case FileErrorTypes.invalidFileName:
      return t('ar.guestVerification.cert.errors.invalidFilename');
    case FileErrorTypes.unknownExtension:
      return t('ar.guestVerification.cert.errors.unknownExtension', {
        allowedExtensions: allowedExtensions.map((allowedExtension) => allowedExtension.toUpperCase()).join(', '),
      });
    case FileErrorTypes.forbiddenExtension:
      return t('ar.guestVerification.cert.errors.forbiddenExtension', { allowedExtensions: BlackListedFileTypesList.join(', ') });
  }

  return null;
};

const getCertificateValidationText = (error: CertificateVerificationError | null, t: TFunction<string>): string | null => {
  if (!error) {
    return null;
  }

  switch (error.errorType) {
    case 'BANK_ACCOUNT_CERTIFICATE_NOT_VALID_FILE_FORMAT':
      return t('ar.guestVerification.cert.errors.certInvalidFormat');
    case 'BANK_ACCOUNT_CERTIFICATE_PDF_SIGNATURE_ERROR':
    case 'BANK_ACCOUNT_CERTIFICATE_PDF_HASH_COMPARISON_ERROR':
    case 'BANK_ACCOUNT_CERTIFICATE_BAC_SIGNATURE_ERROR':
      return t('ar.guestVerification.cert.errors.certSignatureError');
    case 'BANK_ACCOUNT_CERTIFICATE_BAC_EXPIRED':
      return t('ar.guestVerification.cert.errors.certExpired');
    case 'BANK_ACCOUNT_CERTIFICATE_BELONGS_TO_ANOTHER_ORG_ERROR':
      return t('ar.guestVerification.cert.errors.belongsToAnotherOrg');
    case 'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR':
    default:
      return (
        t('ar.guestVerification.cert.errors.certGeneralError') +
        (error.errorLogId ? t('ar.guestVerification.cert.errors.certReferTo', { errorLogId: error.errorLogId }) : '')
      );
  }
};

export default GuestVerificationWithCertificateBeforeVerificationPage;

const MainCard = styled(Card)`
  width: 100%;
  padding: 20px 30px 50px;
`;

const CertificateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--primary-200-50-a);

  padding: 36px 12px 36px 36px;
`;

const NonFlexibleFlexItem = styled.div`
  flex: 0 0 auto;
`;

const CertificateIcon = styled(SVG)`
  flex: 0 0 auto;
  margin-inline-end: 12px;
`;

const WarningIcon = styled(SVG)`
  min-width: 26px;
  max-width: 26px;
  min-height: 24px;
  max-height: 24px;
  margin-right: 4px;
`;

const CertificateDetails = styled.div`
  display: flex;
  align-items: center;
`;

const CertificateName = styled(BodyRegularStartTransparentBlack600.div)`
  margin-inline-start: 6px;
  text-align: start;
  ${WRAP_WORDS_CSS_PROPERTIES}
`;

const ErrorContainer = styled.div`
  max-width: 70ch;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  padding-left: 22px;
  padding-top: 5px;
  align-items: center;
`;

const FileErrors = styled(SubtitleSmallStartError600.div)`
  margin-top: 16px;
  line-height: 24px;
  ${LineBreakTextCss};
  box-sizing: border-box;
  border: 1px solid #ff8a8a;
  background-color: #fffbfb;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 50px;
`;

const PaddedDiv = styled.div`
  padding: 22px;
  text-align: justify;
`;

const ReplaceCertificateButton = styled(Button)`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-inline-start: 12px;
  }
`;

export const PrimaryButton = styled(Button)`
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 6px;
`;
