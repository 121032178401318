export enum HttpStatus {
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  conflict = 409,
  internalServerError = 500,
}

export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  patch = 'PATCH',
  delete = 'DELETE',
  put = 'PUT',
}

export interface WebError {
  // General errors
  DEFAULT_ERROR;
  EMPTY_FILE;
  EMPTY_FILE_NAME;
  FILE_UNAUTHORIZED_CONTENT_TYPE;
  WRONG_FILE_NAME_PATTERN;
  MULTIPLE_CURRENCIES;
  FAULTY_ACCOUNTS;
  NO_SUITABLE_PARSER;
  PAYMENT_PROPOSAL_NOT_FOUND;
  ILLEGAL_PAYMENT_POLICY;
  ILLEGAL_PAYMENT_PROPOSAL;
  PLANNED_PAYMENTS_ASSEMBLE_ISSUE;
  DUPLICATE_PLANNED_PAYMENT_EXTERNAL_REFERENCE_IDS;
  ILLEGAL_SUPPLIER_REPLY;
  ILLEGAL_SUPPLIER_ACCOUNT_REPLY;
  SUPPLIER_ACCOUNT_UPDATE_CONFLICT;
  ILLEGAL_FILE_CONTENT_TYPE;
  FILES_NOT_SECURE;
  KNOX_CLIENT_ERROR;

  // Parsing errors
  FAILED_TO_PARSE;
  NULL_CONTENT;
  NOT_ENOUGH_LINES;
  HEADLINE_LENGTH;
  HEADLINE_VALUE;
  NUMERIC_FORMAT;
  AUTOMATIC_INSTALLMENT;
  EMPTY_VALUE;
  NULL_VALUE;
  ILLEGAL_LINE_END;
  DATE_FORMAT;
  END_LINE_LENGTH;
  END_LINE_VALUE;
  SUMMARY_LENGTH_TOO_SHORT;
  SUMMARY_LENGTH_TOO_LONG;
  SUMMARY_VALUE;
  PAYMENT_LENGTH_TOO_SHORT;
  PAYMENT_LENGTH_TOO_LONG;
  PAYMENT_VALUE;
  NO_SUITABLE_LINE_PARSER;
  BANK_CODE_TOO_LONG;
  BRANCH_CODE_TOO_LONG;
  HEADLINE_TYPE;
  LINE_LENGTH;
  LINE_TYPE;
  SWIFT_CODE_TOO_LONG;
  INVALID_SWIFT;
  INVALID_CURRENCY;
  NOT_ENOUGH_SEPARATORS;
  CITI_EU_ACH_CT_ACCOUNT_PROPERTY_TOO_SHORT;
  INVALID_IBAN;
  MISSING_COUNTRY_INFO;
  INVALID_BANK_CODE;
  INVALID_ACCOUNT_NUMBER;
  EMPTY_CURRENCY;
  INVALID_AMOUNT;
  EMPTY_AMOUNT;
  UNSUPPORTED_CITI_PAYMENT_TYPE;
  ORG_DUPLICATE_FIELDS;
  INVALID_STRUCTURE;
  INVALID_ACCOUNT_DETAILS;
  FILE_BAD_FORMAT;
  INVALID_PAYMENT_METHOD;
  MISSING_RECORD_TYPE;
  CONFIGURATION_MISSING_MANDATORY_FIELDS;

  // Payment files
  PAYMENT_BATCH_IN_PROGRESS;

  // audit mode
  MVF_NO_SUITABLE_PARSER;
  MVF_MISSING_INTERNAL_ID;
  OTHER_AUDIT_MODE_IN_PROGRESS;

  // Master data guard
  OTHER_MASTER_DATA_GUARD_REPORT_PROGRESS;
  INITIATE_SELF_APPROVE_INVALID_ACCOUNT_STATUS;
  INITIATE_SELF_APPROVE_ACCOUNT_ALREADY_SELF_APPROVED;

  // Verification PDF
  ERROR_CREATING_PDF_REPORT;

  // Welcome letter PDF
  WELCOME_LETTER_CREATION_ERROR;

  // Supplier validation
  SUPPLIER_VALIDATION_PROCESS_MISSING_FIELDS;
  SUPPLIER_VALIDATION_PROCESS_DOES_NOT_EXIST;
  SUPPLIER_VALIDATION_CONFIGURATION_NOT_FOUND;
  SUPPLIER_VALIDATION_SHOULD_BE_SERVED_AS_SUBDOMAIN;

  // Validation System
  SV_REGISTRATION_ALREADY_LINKED_ERROR: {
    svRegistrationId: string;
    validationRecordPresentationId: string;
  };
  USER_NOT_ASSIGNABLE_ERROR;
  SUPPLIER_VALIDATION_PROCESS_ORGANIZATION_ID_MISMATCH_ERROR;
  MATCHING_DISTRIBUTOR_NOT_FOUND_ERROR;
  DISTRIBUTION_EMAIL_NOT_SENT_ERROR;
  DISTRIBUTOR_NOT_AVAILABLE_FOR_THIS_TRIGGER_ERROR;
  SV_LINK_ID_EXISTS_ON_ANOTHER_RECORD;
  INITIAL_EMAIL_ID_EXISTS_ON_ANOTHER_RECORD;
  EMPTY_EVIDENCE_FILE;
  EVIDENCE_FILE_CONTAINS_MORE_THEN_ONE_RECORD_TYPE;
  EVIDENCE_FILE_MISSING_MANDATORY_FIELDS;
  EVIDENCE_FILE_DUPLICATE_IDS;
  EVIDENCE_FILE_CONFLICT;
  EVIDENCE_FILE_SOME_RECORDS_NOT_FOUND;
  VALIDATION_RECORD_NOT_IN_STATUS_WAITING_FOR_APPROVAL;
  LYONS_VALIDATION_RECORD_NOT_PRIVATE;

  // Bank account certificate
  BANK_ACCOUNT_CERTIFICATE_NOT_VALID_FILE_FORMAT;
  BANK_ACCOUNT_CERTIFICATE_PDF_SIGNATURE_ERROR;
  BANK_ACCOUNT_CERTIFICATE_PDF_HASH_COMPARISON_ERROR;
  BANK_ACCOUNT_CERTIFICATE_BAC_SIGNATURE_ERROR;
  BANK_ACCOUNT_CERTIFICATE_BAC_EXPIRED;
  BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR;
  BANK_ACCOUNT_CERTIFICATE_BELONGS_TO_ANOTHER_ORG_ERROR;

  // File based
  DUPLICATE_EXTERNAL_FILE_SURVEY;

  // Supplier validation email generator
  BLANK_PAYEE_NAME_ERROR;
  WRONG_ORGANIZATION_ERROR: { differentOrganizationIds?: string[] } | undefined;
  NULL_KNOX_ID_ERROR;

  // Supplier validation WI CSV email
  PAYMENT_FILE_DATA_VALIDATION_ERROR;
  PAYMENT_FILE_CREATION_ERROR;
  PAYMENT_FILE_STORE_ERROR;
  PAYMENT_FILE_EMAIL_ERROR;

  // Backee
  PHONE_NUMBER_ALREADY_EXISTS_ERROR;
  EMAIL_ALREADY_EXISTS_ERROR;
  RETURNED_TOO_MANY_USERS_ERROR;
  USER_NOT_IN_ORGANIZATION_ERROR;

  // Back office
  SANCTIONS_SCREENING_NOT_CONFIGURED_ERROR;

  // AR
  GUEST_CONFIGURATION_NOT_FOUND;

  // Open banking
  FINICITY_INVALID_ACCOUNT_OR_ROUTING_NUMBER;
}

export type WebErrorCode = keyof WebError;
