export default class EmptyEventLogger implements EventLogger {
  async init(): Promise<void> {}

  setUserInfo(_userId: string, _userInfo: any): void {}

  identify(_id: string): void {}

  setTag(_name: string, _value: string | number): void {}

  exception(_error: Object, _errorString: string, _additionalData?: object): void {}

  event(_name: string, _additionalData?: object): void {}

  currentView(_viewName: string, _additionalData?: object): void {}
}
