import React, { Component, ErrorInfo, ReactElement, ReactNode } from 'react';
import ErrorMessage from './ErrorMessage';

interface ErrorBoundaryProps {
  children: ReactElement;
  image?: string;
  title?: ReactNode;
  description?: ReactNode;
  responsive?: boolean;
  fullscreen?: boolean;
}

/*
  DO NOT use theme or props/dependencies that require variables from
  other places because this component can be the highest component
*/

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean; errorToDisplay: null | { error: Error; info: ErrorInfo } }
> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorToDisplay: null,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({
      hasError: true,
      errorToDisplay: { error, info },
    });
    // TODO: Log that
  }

  render(): ReactElement {
    const { children, ...errorMessageProps } = this.props;

    if (this.state.hasError) {
      return <ErrorMessage {...errorMessageProps} />;
    }

    return children;
  }
}
