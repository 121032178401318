import React, { FunctionComponent, useContext } from 'react';
import Card from '@app/components/card/Card';
import styled from '@emotion/styled';
import FormInputsContext, { InputGroupState } from '../FormInputsContext';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import useColorScheme from '@app/hooks/useColorScheme';
import { InputBoxBaseProps } from '@app/components/inputs/inputBox/base';

const CornersInputBox: FunctionComponent<InputBoxBaseProps> = (props) => {
  const { colorScheme: propColorScheme, inputState: propInputState, className, children } = props;

  const colorScheme = useColorScheme(propColorScheme);
  const { state: contextInputState } = useContext(FormInputsContext);
  const state = propInputState || contextInputState;

  const disabled = state === 'disabled';

  const InputCardComponent = disabled ? DisabledInputCard : EnabledInputCard;

  return (
    <InputCardComponent
      corners='top'
      customBackgroundColor={disabled ? '#f4f4f4' : '#f5f6fa'}
      color={colorScheme.main}
      inputState={state}
      className={className}
    >
      {children}
      <BottomLine inputState={state} />
    </InputCardComponent>
  );
};

export default CornersInputBox;

const BottomLine = styled.div<{ inputState: InputGroupState }>`
  position: absolute;
  bottom: 0;
  height: 1px;
  transform: scaleY(var(--line-thickness));
  transform-origin: bottom;
  background-color: var(--input-active-color);
  width: 100%;

  transition: 0.2s transform, 0.2s background-color;
  --line-thickness: ${(p): string => {
    switch (p.inputState) {
      case 'error': {
        return '2';
      }
      default:
      case 'empty':
      case 'disabled':
      case 'filled': {
        return '1';
      }
    }
  }};

  --input-active-color: ${(p): string => {
    switch (p.inputState) {
      case 'empty':
      case 'disabled': {
        return 'rgba(61, 68, 90, 0.7)';
      }
      case 'error': {
        return 'var(--alert-color)';
      }
      default:
      case 'filled': {
        return 'rgba(61, 68, 90, 0.8)';
      }
    }
  }};
`;

const DisabledInputCard = styled(Card, { shouldForwardProp: preventForwardTheseProps('inputState') })<{
  color: string;
  inputState: InputGroupState;
}>`
  box-shadow: unset;
  padding: 0;
  position: relative;
`;

const EnabledInputCard = styled(Card, { shouldForwardProp: preventForwardTheseProps('inputState') })<{
  color: string;
  inputState: InputGroupState;
}>`
  box-shadow: unset;
  padding: 0;
  position: relative;

  &:hover ${BottomLine} {
    --line-thickness: 2;
  }

  &:focus-within ${BottomLine} {
    --input-active-color: ${(p): string => {
      if (p.inputState === 'error') {
        return 'var(--alert-color)';
      }

      return p.color;
    }};

    --line-thickness: 2;
  }
`;
