import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import alertOutline from '@app/images/alert-outline.svg';
import checkOutline from '@app/images/check-outline.svg';
import errorOutline from '@app/images/outline-error_outline.svg';
import duplicateKeysForServerValues from '@app/domain/helpers/duplicateKeysForServerValues';
import { PaymentCheckBatchStatus } from '@mortee/domain/paymentsConsts';
import { FileSurveyStatus } from '@mortee/domain/mvfCheck';

const StyledSVG = styled(SVG)`
  width: 16px;
  height: 16px;
  align-self: center;
`;

const STATUSES_OPTIONS: { [key: string]: string[] } = {};

export enum SurveyStatus {
  filesReceived = 'FilesReceived',
  missingInformation = 'MissingInformation',
  organizationQuotaExceeded = 'OrganizationQuotaExceeded',
  surveyInProgress = 'SurveyInProgress',
  surveyCompleted = 'SurveyCompleted',
  surveyCanceled = 'SurveyCanceled',
}

fillStatusesOptions(SurveyStatus);
fillStatusesOptions(PaymentCheckBatchStatus);
fillStatusesOptions(FileSurveyStatus);

interface StatusUI {
  id: string | string[];
  text: string;
  color: string;
  icon?: ReactElement;
  bgColor?: string;
  noBgColor?: string;
}

const STATUSES_UI: { [key: string]: StatusUI } = {
  pendingApproval: {
    id: STATUSES_OPTIONS.pendingApproval,
    text: 'Pending enable',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  approved: {
    id: STATUSES_OPTIONS.approved,
    text: 'Active',
    color: '#396b49',
    bgColor: '#b3ffc9',
    noBgColor: '#37ae53',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={checkOutline} fill={this.color} />;
    },
  },
  rejected: {
    id: STATUSES_OPTIONS.rejected,
    text: 'Rejected',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={errorOutline} fill={this.color} />;
    },
  },
  canceled: {
    id: STATUSES_OPTIONS.canceled,
    text: 'Canceled',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={errorOutline} fill={this.color} />;
    },
  },
  pendingDisable: {
    id: STATUSES_OPTIONS.pendingDisable,
    text: 'Pending disable',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  disabled: {
    id: STATUSES_OPTIONS.disabled,
    text: 'Disabled',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
  },
  pending: {
    id: STATUSES_OPTIONS.pending,
    text: 'Pending',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  pendingEnable: {
    id: STATUSES_OPTIONS.pendingEnable,
    text: 'Pending Enable',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  inProgress: {
    id: STATUSES_OPTIONS.inProgress,
    text: 'In Progress',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  actionsRequired: {
    id: STATUSES_OPTIONS.actionsRequired,
    text: 'Pending',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={alertOutline} fill={this.color} />;
    },
  },
  failed: {
    id: STATUSES_OPTIONS.failed,
    text: 'Upload Failed',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#C71B1B',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={errorOutline} fill={this.color} />;
    },
  },
  supplierDisabled: {
    id: STATUSES_OPTIONS.supplierDisabled,
    text: 'Disabled',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
  },
  paymentApproved: {
    id: STATUSES_OPTIONS.paymentApproved,
    text: 'Approved',
    color: '#396b49',
    bgColor: '#b3ffc9',
    noBgColor: '#37ae53',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={checkOutline} fill={this.color} />;
    },
  },
  pendingUpdate: {
    id: STATUSES_OPTIONS.pendingUpdate,
    text: 'Pending update',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },

  // Audit statuses
  surveyCompleted: {
    id: STATUSES_OPTIONS.surveyCompleted,
    text: 'Completed',
    color: '#396b49',
    bgColor: '#b3ffc9',
    noBgColor: '#37ae53',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={checkOutline} fill={this.color} />;
    },
  },
  missingInformation: {
    id: STATUSES_OPTIONS.missingInformation,
    text: 'Missing Info',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={errorOutline} fill={this.color} />;
    },
  },
  organizationQuotaExceeded: {
    id: STATUSES_OPTIONS.organizationQuotaExceeded,
    text: 'Quota Exceeded',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
    get icon(): ReactElement {
      return <StyledSVG accessibilityLabel='' image={errorOutline} fill={this.color} />;
    },
  },
  filesReceived: {
    id: STATUSES_OPTIONS.filesReceived,
    text: 'Files Received',
    color: '#396b49',
    bgColor: '#b3ffc9',
    noBgColor: '#37ae53',
  },
  surveyInProgress: {
    id: STATUSES_OPTIONS.surveyInProgress,
    text: 'In Progress',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  surveyCanceled: {
    id: STATUSES_OPTIONS.surveyCanceled,
    text: 'Canceled',
    color: '#4e2808',
    bgColor: '#d9d9d9',
    noBgColor: '#d9d9d9',
  },
  batchInProgress: {
    id: STATUSES_OPTIONS.batchInProgress,
    text: 'In Progress',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  batchApproved: {
    id: STATUSES_OPTIONS.batchApproved,
    text: 'No Exceptions',
    color: '#396b49',
    bgColor: '#b3ffc9',
    noBgColor: '#37ae53',
  },
  batchRejected: {
    id: STATUSES_OPTIONS.batchRejected,
    text: 'Exceptions Found',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#c71b1b',
  },
  // MVF change log survey statuses
  fileSurveySyncInProgress: {
    id: STATUSES_OPTIONS.fileSurveySyncInProgress,
    text: 'In Progress',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  fileSurveyVerificationInProgress: {
    id: STATUSES_OPTIONS.fileSurveyVerificationInProgress,
    text: 'In Progress',
    color: '#4e2808',
    bgColor: '#ffe9b3',
    noBgColor: '#ffb23d',
  },
  fileSurveyComplete: {
    id: STATUSES_OPTIONS.fileSurveyComplete,
    text: 'Ready',
    color: '#396b49',
    bgColor: '#b3ffc9',
    noBgColor: '#37ae53',
  },
  fileSurveyFailed: {
    id: STATUSES_OPTIONS.fileSurveyFailed,
    text: 'Error',
    color: '#6b3239',
    bgColor: '#ffb2b2',
    noBgColor: '#C71B1B',
  },
};

duplicateKeysForServerValues(STATUSES_UI);

export { STATUSES_UI, STATUSES_OPTIONS };

function fillStatusesOptions(statuses: Record<string, string>): void {
  Object.keys(statuses).forEach((key: string) => {
    if (!STATUSES_OPTIONS[key]) {
      STATUSES_OPTIONS[key] = [];
    }

    if (!STATUSES_OPTIONS[key].includes(statuses[key])) {
      STATUSES_OPTIONS[key].push(statuses[key]);
    }
  });
}
