import { CaptionStartError600, CaptionStartSlateGray700, SubtitleSmallStartTransparentBlack900 } from '@app/components/Text';
import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import TransitionLoader from '@app/components/TransitionLoader';

interface Props {
  title: ReactNode;
  isNewEntity: boolean | null;
  amountOfChanges: number;
  amountOfErrors: number;
  isLoading: boolean;
  actions?: ReactNode;
  actionsVisibility?: 'visible' | 'hover' | 'hidden';
  dataTestId: string;
}

const VerificationFormCollapseHeader: FC<Props> = ({
  title,
  isNewEntity,
  amountOfChanges,
  amountOfErrors,
  isLoading,
  actions,
  actionsVisibility = 'hover',
  dataTestId,
}) => {
  return (
    <CollapseHeader actionsVisibility={actionsVisibility}>
      <div data-testid={dataTestId}>
        <Title>{title}</Title>
        {isNewEntity && (
          <CaptionStartSlateGray700.span>
            <Separator>|</Separator>New
          </CaptionStartSlateGray700.span>
        )}
        {!!amountOfChanges && (
          <CaptionStartSlateGray700.span>
            <Separator>|</Separator>
            {amountOfChanges} Unsaved Changes
          </CaptionStartSlateGray700.span>
        )}
        {!!amountOfErrors && (
          <CaptionStartError600.span>
            <Separator>|</Separator>
            {amountOfErrors} Errors
          </CaptionStartError600.span>
        )}
      </div>
      <TransitionLoader loading={isLoading} small smallSize={24} customColor='--accent-blue-600'>
        <Actions actionsVisibility={actionsVisibility}>{actions}</Actions>
      </TransitionLoader>
    </CollapseHeader>
  );
};

export default VerificationFormCollapseHeader;

const Actions = styled.div<{ actionsVisibility: 'visible' | 'hover' | 'hidden' }>`
  opacity: ${(p): number => (p.actionsVisibility === 'visible' ? 1 : 0)};
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 8px;

  transition: opacity 0.2s;
`;

const CollapseHeader = styled.div<{ actionsVisibility: 'visible' | 'hover' | 'hidden' }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover ${Actions} {
    opacity: ${(p): number => (p.actionsVisibility === 'hidden' ? 0 : 1)};
  }
`;

const Title = styled(SubtitleSmallStartTransparentBlack900.span)`
  display: inline-block;
  margin-inline-end: 10px;
`;

const Separator = styled.span`
  display: inline-block;
  margin-inline: 10px;
`;
