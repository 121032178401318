import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import PageIcon from '@supplierRegistration/images/nothing-to-do-icon.svg';
import SVG from '@app/components/SVG';
import { FAQLink } from '../AfterFormPage';
import ArrowBullet from '@app/components/ArrowBullet';
import useInfraStores from '@app/hooks/useInfraStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useAppStores from '@app/hooks/useAppStores';

interface NothingToDoAfterFormProps {
  isIpsMode: boolean;
  email?: string | null;
}

const NothingToDoAfterForm: FC<NothingToDoAfterFormProps> = observer(({ isIpsMode, email }) => {
  const { navigationStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const { t } = useTranslation();

  return (
    <div>
      <TopSection>
        <StyledSVG accessibilityLabel='' image={PageIcon} height={115} width={150} />
        <ThankYouText>{t('supplierValidation.afterRegister.nothingToDo.thankYou')}</ThankYouText>
        <NothingToDoText>{t('supplierValidation.afterRegister.nothingToDo.willValidate')}</NothingToDoText>
      </TopSection>
      <BottomSection>
        <ActionLine>
          <StyledArrowBullet />{' '}
          <NothingToDoText>
            <Trans i18nKey='supplierValidation.afterRegister.nothingToDo.noActionRequired'>
              At this point <strong>no further action is required</strong>. Should there be any action needed from your side, our
              validation team will contact you with further details.
            </Trans>
          </NothingToDoText>
        </ActionLine>
        <ActionLine>
          <StyledArrowBullet />{' '}
          <NothingToDoText>
            <Trans i18nKey='supplierValidation.afterRegister.nothingToDo.onceCompleted' values={{ email }}>
              Once the validation process has been completed, a confirmation email will be sent to <strong>{{ email }}</strong>.
            </Trans>
          </NothingToDoText>
        </ActionLine>
      </BottomSection>
      <FAQLink
        id='vldt-for-supp-step-2-faq'
        data-testid='vldt-for-supp-step-2-faq'
        to={navigationStore.generateAccountValidationForSuppliersFAQ(
          isIpsMode,
          supplierRegistrationCustomerDataStore.currentReference?.referenceId ?? null,
        )}
      >
        {t('supplierValidation.faq')}
      </FAQLink>
    </div>
  );
});

export default NothingToDoAfterForm;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
`;

const BottomSection = styled.div`
  strong {
    font-weight: bold;
  }
`;

const ActionLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const ThankYouText = styled.div`
  font-family: var(--title-font-family);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`;

const NothingToDoText = styled.div`
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--transparent-black-700);
`;

const StyledSVG = styled(SVG)`
  margin-top: 20px;
  margin-bottom: 18px;
`;

const StyledArrowBullet = styled(ArrowBullet)`
  width: 16px;
  height: 16px;

  margin-top: 3px;

  margin-inline-end: 8px;
`;
