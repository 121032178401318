import SupplierRegistrationFormStore from '@supplierRegistration/stores/appStores/SupplierRegistrationFormStore';
import SupplierRegistrationThemeStore from '@supplierRegistration/stores/infraStores/SupplierRegistrationThemeStore';
import SupplierRegistrationCustomerDataStore from '@supplierRegistration/stores/appStores/SupplierRegistrationCustomerDataStore';

export default class SupplierRegistrationAppStores {
  supplierRegistrationFormStore: SupplierRegistrationFormStore;
  supplierRegistrationCustomerDataStore: SupplierRegistrationCustomerDataStore;

  constructor(themeStore: SupplierRegistrationThemeStore) {
    this.supplierRegistrationFormStore = new SupplierRegistrationFormStore();
    this.supplierRegistrationCustomerDataStore = new SupplierRegistrationCustomerDataStore(themeStore);
  }
}
