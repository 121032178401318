import BackArrowIcon from '@app/images/arrows/left-arrow-accent.svg';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import SVG from '@app/components/SVG';
import useInfraStores from '@app/hooks/useInfraStores';

interface Props {
  id: string;
  dataTestId?: string;
  className?: string;
}

const HomeNavigationButton: FC<Props> = ({ id, dataTestId = id, className }) => {
  const { navigationStore } = useInfraStores();

  return (
    <NavButton
      id={id}
      dataTestId={dataTestId}
      appearance='text'
      onClick={(): void => navigationStore.moveToSectionHome()}
      className={className}
    >
      <StyledBackArrowIcon accessibilityLabel='' image={BackArrowIcon} />
      HOME
    </NavButton>
  );
};

export default HomeNavigationButton;

const NavButton = styled(Button)`
  padding: 8px;
  align-items: flex-end;
  display: inline-flex;
`;

const StyledBackArrowIcon = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;
