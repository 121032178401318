import React, { FunctionComponent, ReactNode } from 'react';
import PageWithHeader from '@app/components/PageWithHeader';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { getValidatedPayeesOwnedByMyOrganization } from '@mortee/services/ipsServices';
import styled from '@emotion/styled';
import MyValidatedPayeeWithAccountsCard from '@mortee/routes/ips/myBankAccounts/MyValidatedPayeeWithAccountsCard';
import { createNTimes } from '@app/utils/arrayUtils';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import CustomerKitButton from '@mortee/routes/ips/customerKit/CustomerKitButton';
import { transformValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';

const MyBankAccountsPage: FunctionComponent = () => {
  const [payeesWithAccounts] = useLoadable(async () => {
    const myValidatedPayeesWithAccounts = await getValidatedPayeesOwnedByMyOrganization();
    return myValidatedPayeesWithAccounts.map(transformValidatedPayeeWithAccounts);
  }, []);

  const renderPageHeader = (): ReactNode => {
    return (
      <PageHeader
        sideContent={
          <PageHeaderCenteredSideContent>
            <CustomerKitButton />
          </PageHeaderCenteredSideContent>
        }
      />
    );
  };

  return (
    <PageWithHeader width='full' header={renderPageHeader()}>
      <PayeesCardsContainer>
        {payeesWithAccounts.resolve(
          (loadedPayeesWithAccounts): ReactNode => {
            return loadedPayeesWithAccounts.map((payeeWithAccounts) => (
              <MyValidatedPayeeWithAccountsCard
                key={payeeWithAccounts.payee.uniformId}
                dataTestId={`validated-payee-card-${payeeWithAccounts.payee.uniformId}`}
                validatedPayeeWithAccounts={payeeWithAccounts}
              />
            ));
          },
          (): ReactNode => {
            return createNTimes(2, (index): ReactNode => <MyValidatedPayeeWithAccountsCard key={index} loading />);
          },
        )}
      </PayeesCardsContainer>
    </PageWithHeader>
  );
};

export default MyBankAccountsPage;

const PayeesCardsContainer = styled.div`
  & > *:not(:first-child) {
    margin-top: 20px;
  }
`;
