import React, { FC } from 'react';
import styled from '@emotion/styled';

interface Props {
  vertical?: boolean;
  className?: string;
}

const SeparatorLine: FC<Props> = ({ vertical, className }) => {
  if (vertical) {
    return <VerticalSeparator className={className} />;
  }

  return <HorizontalSeparator className={className} />;
};

export default SeparatorLine;

const HorizontalSeparator = styled.hr`
  margin: 0;
  border: none;
  border-top: solid 1px var(--transparent-black-200);
`;

const VerticalSeparator = styled.span`
  display: inline-block;
  border-right: solid 1px var(--transparent-black-200);
  height: auto;
`;
