import React, { FunctionComponent, MouseEvent } from 'react';

interface DownloadFilesSvgProps {
  id?: string;
  dataTestId?: string;
  fileNum: number;
  onClick?: (e: MouseEvent<SVGElement>) => void;
  style?: React.CSSProperties;
  isDownloadUnavailable?: boolean;
  accessibilityLabel: string;
}

const moreOrEqualToTenIcon: string =
  'M11.724 0c.177 0 .346.07.471.195l4.943 4.943a.667.667 0 0 1 .195.471v1.919C21.147 8.35 24 11.68 24 15.667 24 20.269 20.194 24 15.5 24a8.567 8.567 0 0 1-5.523-1.999L2.667 22A2.667 2.667 0 0 1 0 19.333V2.667A2.667 2.667 0 0 1 2.667 0h9.057zm3.943 8.667a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-5-7.334h-8c-.693 0-1.263.53-1.328 1.205l-.006.129v16.666c0 .693.53 1.263 1.205 1.328l.129.006h6.032A8.243 8.243 0 0 1 7.338 18H4a.667.667 0 1 1 0-1.333h3.06a8.256 8.256 0 0 1 0-2H4a.667.667 0 0 1 0-1.334h3.338c.21-.71.515-1.381.9-2H4A.667.667 0 1 1 4 10h5.268A8.556 8.556 0 0 1 16 7.348v-.681h-3.333a2 2 0 0 1-1.995-1.851l-.005-.15V1.334zM12 1.872v2.795c0 .334.247.611.568.66l.099.006h2.796L12 1.872z';
const lessThenTenIcon: string =
  'M11.724 0c.177 0 .346.07.471.195l4.943 4.943a.667.667 0 0 1 .195.471l.001 3.754a7.334 7.334 0 1 1-5.7 12.638L2.668 22A2.667 2.667 0 0 1 0 19.333V2.667A2.667 2.667 0 0 1 2.667 0h9.057zm4.943 10.667a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm-6-9.334h-8c-.693 0-1.263.53-1.328 1.205l-.006.129v16.666c0 .693.53 1.263 1.205 1.328l.129.006h7.852a7.29 7.29 0 0 1-1.067-2.679.556.556 0 0 1-.119.012H4a.667.667 0 1 1 0-1.333h5.333c0-.694.097-1.365.276-2H4a.667.667 0 0 1 0-1.334h6.133a7.337 7.337 0 0 1 5.866-3.97L16 6.667h-3.333a2 2 0 0 1-1.995-1.851l-.005-.15V1.334zM9.333 10a.667.667 0 1 1 0 1.333H4A.667.667 0 1 1 4 10h5.333zM12 1.872v2.795c0 .334.247.611.568.66l.099.006h2.796L12 1.872z';

const DownloadFilesSvg: FunctionComponent<DownloadFilesSvgProps> = (props) => {
  const { id, dataTestId, fileNum, onClick, style, isDownloadUnavailable, accessibilityLabel } = props;

  return (
    <svg
      id={id}
      data-testid={dataTestId}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      onClick={isDownloadUnavailable ? undefined : onClick}
      style={style || { cursor: isDownloadUnavailable ? 'default' : 'pointer', marginBottom: '-6px', marginLeft: '2px' }}
    >
      <title>{accessibilityLabel}</title>
      <path
        fill={isDownloadUnavailable ? '#767c8b' : '#03ADEF'}
        fillRule='evenodd'
        d={fileNum >= 10 ? moreOrEqualToTenIcon : lessThenTenIcon}
      />
      <text
        textAnchor='start'
        fontFamily='Helvetica, Arial, sans-serif'
        fontSize='10'
        id='files_no'
        y={fileNum >= 10 ? '19' : '20'}
        x={fileNum >= 10 ? '10' : '14'}
        strokeWidth='0'
        stroke='#000'
        fill={isDownloadUnavailable ? '#767c8b' : '#03ADEF'}
      >
        {fileNum}
      </text>
    </svg>
  );
};

export default DownloadFilesSvg;
