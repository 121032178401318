import React, { FunctionComponent, useRef, useState } from 'react';
import { BodyRegularStartPrimary500 } from '@app/components/Text';
import copyToClipboard from 'copy-to-clipboard';
import InnerCard from '@app/components/card/InnerCard';
import styled from '@emotion/styled';
import Button from '@app/components/Button';

interface Props {
  copyButtonId: string;
  children: string;
}

const COPIED_MESSAGE_TIMEOUT = 3000;

const CustomerKit: FunctionComponent<Props> = ({ copyButtonId, children }) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number>();

  const copyToClipboardAndPopup = (): void => {
    copyToClipboard(children, { format: 'text/plain' });

    timeoutRef.current && window.clearTimeout(timeoutRef.current);
    setCopied(true);
    timeoutRef.current = window.setTimeout(() => setCopied(false), COPIED_MESSAGE_TIMEOUT);
  };

  return (
    <BlueInnerCard>
      {children}
      <CopyButton id={copyButtonId} onClick={(): void => copyToClipboardAndPopup()} size='small'>
        {copied ? 'COPIED!' : 'COPY'}
      </CopyButton>
    </BlueInnerCard>
  );
};

export default CustomerKit;

const BlueInnerCard = styled(InnerCard)`
  background-color: rgba(3, 173, 239, 0.02);
  padding: 15px 18px;
  white-space: pre-wrap;
  ${BodyRegularStartPrimary500.css};
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CopyButton = styled(Button)`
  align-self: flex-end;
  display: inline-block;
  position: sticky;
  bottom: 0;
  right: 0;
  padding: 0.7em 1em;
`;
