import React, { FC, ReactElement, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import LoadableTransitionLoader from '@app/components/LoadableTransitionLoader';
import { alertTypesDisplayData, SupplierValidationRecord } from '@mortee/domain/validationSystem';
import Loadable from '@app/utils/Loadable';
import MultiChipSelector from '@app/components/MultiChipSelector';
import SupplierValidationRecordActivityLogAlertInput from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/activityLog/SupplierValidationRecordActivityLogAlertInput';
import { Alert, AlertStatus } from '@mortee/domain/validationSystemAlerts';
import SupplierValidationRecordAlert from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/SupplierValidationRecordAlert';
import AlertIcon from '@app/images/alert-circle.svg';
import SVG from '@app/components/SVG';

type AlertsFilterOptions = AlertStatus[];
const DEFAULT_FILTER_OPTION: AlertsFilterOptions = [];

interface Props {
  record: SupplierValidationRecord;
  allAlertsLoadable: Loadable<Alert[]>;
  onAlertChanged(): void;
  className?: string;
}

const SupplierValidationRecordAlerts: FC<Props> = ({ record, allAlertsLoadable, onAlertChanged, className }) => {
  const [filter, setFilter] = useState<AlertsFilterOptions>(DEFAULT_FILTER_OPTION);

  const alertFilteredLoadable = allAlertsLoadable.map((allAlerts: Alert[]): Alert[] => {
    if (!filter.length) {
      return allAlerts;
    }

    return allAlerts.filter((alert) => filter.includes(alert.alertStatus));
  });

  function renderLoadedAlerts(alerts: Alert[]): ReactElement {
    if (!alerts.length) {
      return getEmptyAlertsText(filter);
    }

    return (
      <Content>
        {alerts
          .sort((alert) => alert.creationTimestamp)
          .reverse()
          .map((alert) => (
            <PaddedSupplierValidationRecordAlert
              key={alert.alertId}
              record={record}
              alert={alert}
              onAlertResolve={onAlertChanged}
            />
          ))}
      </Content>
    );
  }

  function getEmptyAlertsText(filter: AlertsFilterOptions): ReactElement {
    if (filter.length) {
      return <Content>No alerts under this filter</Content>;
    }

    return <Content>No alerts have been written yet</Content>;
  }

  return (
    <Main className={className} data-testid='supplier-validation-record-alerts'>
      <Icon>
        <SVG accessibilityLabel='alert' image={AlertIcon} width={24} height={24} /> New alert
      </Icon>
      <StyledSupplierValidationRecordActivityLogAlertInput onAlertSaved={onAlertChanged} staticId={record.staticId} />
      <FilterLine>
        <span>Quick filter:</span>
        <MultiChipSelector
          id='supplier-validation-record-alerts-filters'
          value={filter}
          onChange={(newFilter: AlertStatus[]): void => {
            setFilter(newFilter ?? DEFAULT_FILTER_OPTION);
          }}
          options={[
            {
              id: 'supplier-validation-record-alerts-filters-active',
              dataTestId: 'supplier-validation-record-alerts-filters-active',
              text: alertTypesDisplayData.Active.text,
              value: AlertStatus.active,
              selectedColorScheme: alertTypesDisplayData.Active.colorScheme,
            },
            {
              id: 'supplier-validation-record-alerts-filters-resolved',
              dataTestId: 'supplier-validation-record-alerts-filters-resolved',
              text: alertTypesDisplayData.Resolved.text,
              value: AlertStatus.resolved,
              selectedColorScheme: alertTypesDisplayData.Resolved.colorScheme,
            },
          ]}
          accessibilityLabel='Record alerts Filter'
        />
      </FilterLine>
      <StyledVerticalShadowScroller>
        <LoadableTransitionLoader loadable={alertFilteredLoadable} firstLoadingContent={(): ReactNode => <InitialHeightDiv />}>
          {renderLoadedAlerts}
        </LoadableTransitionLoader>
      </StyledVerticalShadowScroller>
    </Main>
  );
};

export default SupplierValidationRecordAlerts;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin-bottom: 15px;

  flex: 1;
`;

const FilterLine = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px 30px 16px;
  gap: 10px;
  flex-wrap: wrap;

  flex: 0 0 auto;
`;

const StyledVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
`;

const InitialHeightDiv = styled.div`
  height: 40vh;
`;

const Content = styled.div`
  padding-inline: 16px;
`;

const Icon = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 0 24px;
  display: flex;
  align-items: center;
`;

const StyledSupplierValidationRecordActivityLogAlertInput = styled(SupplierValidationRecordActivityLogAlertInput)`
  margin: 30px 24px;
`;

const PaddedSupplierValidationRecordAlert = styled(SupplierValidationRecordAlert)`
  padding-block: 16px 26px;
`;
