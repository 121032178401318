import React, { ReactNode, useMemo } from 'react';
import NakedFormInput, { InputProps } from '@app/components/inputs/NakedFormInput';
import { PHONE_PREFIX } from '@backee/routes/users/CreateUserPage';
import { CountryCode } from 'libphonenumber-js/custom';
import { parsePhoneNumber } from 'libphonenumber-js';
import styled from '@emotion/styled';
import Nationality from '@app/components/Nationality';
import { ForwardingFC } from '@app/domain/technicals/components';

type InputPropsWithPlaceholder = InputProps & {
  placeholder: string;
};

const InternationalPhoneNumberFormInput: ForwardingFC<HTMLInputElement, InputPropsWithPlaceholder> = React.forwardRef(
  ({ value, ...otherInputProps }, ref) => {
    const countryCode: string | null = useMemo(() => {
      if (!value) {
        return null;
      }
      try {
        const parsedCountryCode: CountryCode | undefined = parsePhoneNumber(PHONE_PREFIX + value).country;
        return parsedCountryCode?.toString() ?? null;
      } catch (e) {
        return null;
      }
    }, [value]);

    const renderNationality = (): ReactNode => {
      if (countryCode) {
        return <StyledNationality countryCode={countryCode} />;
      }
    };

    return (
      <NakedFormInput
        prefix={PHONE_PREFIX}
        renderSideComponent={renderNationality}
        value={value}
        ref={ref}
        {...otherInputProps}
      />
    );
  },
);

export default InternationalPhoneNumberFormInput;

const StyledNationality = styled(Nationality)`
  align-self: center;
  padding: 0;
  margin-top: 8px;
  margin-right: 8px;
`;
