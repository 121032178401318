import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import styled from '@emotion/styled';
import { Regular22TransparentBlack900 } from '@app/components/Text';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import AsyncButton from '@app/components/AsyncButton';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import Log from '@app/libs/logger';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import Button from '@app/components/Button';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/close-thick.svg';
import Card from '@app/components/card/Card';
import { KeyName } from '@app/domain/uiConsts';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import SupplierRegistrationSelector from '@mortee/routes/validationSystem/SupplierRegistrationSelector';

interface SelectSupplierRegistrationForSupplierValidationRecordFormFields {
  linkedSupplierRegistration: SupplierRegistrationProcess;
}

interface Props extends FormComponentProps<SelectSupplierRegistrationForSupplierValidationRecordFormFields> {
  supplierValidationRecord: SupplierValidationRecord;
  className?: string;
  onSave(selectedRegistrationProcess: SupplierRegistrationProcess | undefined): Promise<void>;
  onDone(): void;
}

const SelectSupplierRegistrationForSupplierValidationRecordForm: FC<Props> = observer((props) => {
  const { supplierValidationRecord, onSave, onDone, className } = props;

  const { form, showFormErrors, setShowFormErrors, isFormInvalid, validateFields } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);

  function onKeyUp(event: React.KeyboardEvent): void {
    if (event.key === KeyName.Escape) {
      onDone();
    }
  }

  async function handleSave(): Promise<void> {
    setIsSaving(true);

    try {
      const { linkedSupplierRegistration } = await validateFields();

      await onSave(linkedSupplierRegistration);
      onDone();
    } catch (e) {
      setIsSaving(false);
      Log.exception(e);
    }
  }

  return (
    <MainContainer dataTestId='link-registration-forms-to-svm-modal' onKeyUp={onKeyUp}>
      <TitleLine>
        <Regular22TransparentBlack900.div>Add to validation system record</Regular22TransparentBlack900.div>
        <CloseButton
          id='btn-link-registration-forms-to-svm-modal-close'
          appearance='text'
          colorScheme='primary'
          onClick={function (): void {
            onDone();
          }}
        >
          <SVG accessibilityLabel='close' image={ClearIcon} height={24} />
        </CloseButton>
      </TitleLine>
      <StyledNsknoxForm form={form} showErrors={showFormErrors} className={className} setShowErrors={setShowFormErrors}>
        <ExpendedVerticalShadowScroller id='link-to-validation-record-form'>
          <FormItemBox
            fieldName='linkedSupplierRegistration'
            fieldDecoratorOptions={fieldDecorators.linkedSupplierRegistration}
            appearance='none'
            expandBelowOnError
          >
            <SupplierRegistrationSelector
              scrollableTargetId='link-to-validation-record-form'
              id='inpt-link-registration-forms-to-svm'
              dataTestId='inpt-link-registration-forms-to-svm'
              currentOrganizationId={supplierValidationRecord.organization?.id}
              isOriginatedFromRegistrationForm={false}
            />
          </FormItemBox>
        </ExpendedVerticalShadowScroller>
        <ActionsContainer>
          <ConditionalTooltip showTooltip={isFormInvalid} title='Please select registration form'>
            <AsyncButton
              id='btn-link-to-existing-validation-record-save'
              onClick={(): Promise<void> => handleSave()}
              loading={isSaving}
              disabled={isFormInvalid}
              onDisabledClick={(): void => setShowFormErrors('all')}
            >
              LINK REGISTRATION FORM
            </AsyncButton>
          </ConditionalTooltip>
        </ActionsContainer>
      </StyledNsknoxForm>
    </MainContainer>
  );
});

const fieldDecorators: FormFieldDecorators<SelectSupplierRegistrationForSupplierValidationRecordFormFields> = {
  linkedSupplierRegistration: {
    rules: [
      {
        type: 'object',
        required: true,
        message: `Select Registration form`,
      },
    ],
  },
};

export default Form.create<Props>()(SelectSupplierRegistrationForSupplierValidationRecordForm);

const MainContainer = styled(Card)`
  width: min(800px, 90vw);
  height: 95vh;
  padding: 31px 8px 24px;
  position: relative;
  box-shadow: var(--box-shadow-3);

  display: flex;
  flex-direction: column;

  --side-padding: 32px;
`;

const TitleLine = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 0 var(--side-padding);
  margin: 0;
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  align-self: flex-start;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  flex: 1;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  margin-top: 36px;

  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;

const ExpendedVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;
