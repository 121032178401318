import { observer } from 'mobx-react';
import React, { FunctionComponent, ReactElement, useRef } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { GENERAL_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { ActionsContainer, FooterSubTitle, PrimaryButton, SubTitle, Title } from '../Styles';
import { RowSeparator } from '@app/components/Styles';
import { Form } from 'antd';
import styled from '@emotion/styled';
import { confirm, ModalBody, ModalContainer, ModalTextLine, ModalTitle } from '@app/components/Modal';
import DropdownItem from '@app/components/inputs/DropdownItem';
import COUNTRIES, { CountryCode, DIAL_CODES, getCountryByCountryCode, getCountryCodeByDialCode } from '@app/domain/countries';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';
import { onChangeValidateOtherFields, validatePhoneNumber } from '@app/utils/validators';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { storeUserCountry, useLocaleCountry } from '@app/hooks/useLocaleCountry';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useMountEffect from '@app/hooks/useMountEffect';
import { trim } from '@app/utils/stringUtils';

interface Props extends FormComponentProps<EnterPhoneFormFields> {
  defaultPhone: string | undefined;
  defaultDialCode: string | undefined;
  defaultCountryCode: string | undefined;
  onBack: () => void;
  onSubmit: (dialCode: string, phoneNumber: string, country: CountryCode) => void;
}

interface EnterPhoneFormFields {
  phoneNumber: string;
  countryCode: CountryCode;
  dialCode: string;
}

const PhoneLoginEnterPhone: FunctionComponent<Props> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();
  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);

  const { form } = props;

  useLocaleCountry((userCountry, userCountryCode): void => {
    let dialCode: string | undefined;
    let countryCode: string | undefined;

    if (props.defaultDialCode) {
      dialCode = props.defaultDialCode;
      countryCode = props.defaultCountryCode || getCountryCodeByDialCode(dialCode);
    } else {
      dialCode = userCountry.dialCode.toString();
      countryCode = userCountryCode;
    }

    form.setFieldsValue({ dialCode: dialCode });
    form.setFieldsValue({ countryCode: countryCode });

    phoneNumberInputRef.current?.focus();
  });

  const fieldDecorators: FormFieldDecorators<EnterPhoneFormFields> = {
    countryCode: {
      initialValue:
        props.defaultCountryCode ||
        (props.defaultDialCode ? getCountryCodeByDialCode(props.defaultDialCode.toString()) : undefined),
      rules: [
        {
          max: GENERAL_FIELD_MAX_LENGTH.country,
          message: `Max ${GENERAL_FIELD_MAX_LENGTH.country} characters`,
        },
        {
          validator: onChangeValidateOtherFields<EnterPhoneFormFields>(props.form, 'phoneNumber'),
        },
      ],
    },
    dialCode: {
      initialValue: props.defaultDialCode,
      rules: [
        {
          required: true,
          message: 'Invalid country code',
        },
        {
          max: GENERAL_FIELD_MAX_LENGTH.dialCode,
          message: `Max ${GENERAL_FIELD_MAX_LENGTH.dialCode} characters`,
        },
        {
          pattern: /^[0-9]+$/,
          message: 'Country code must be numeric',
        },
        {
          type: 'enum',
          enum: DIAL_CODES,
          message: 'Invalid country code',
        },
        {
          validator: onChangeValidateOtherFields<EnterPhoneFormFields>(props.form, 'phoneNumber'),
        },
      ],
    },
    phoneNumber: {
      initialValue: props.defaultPhone,
      rules: [
        {
          required: true,
          message: 'Invalid phone number',
        },
        {
          validator: validatePhoneNumber<EnterPhoneFormFields>(props.form, 'dialCode', 'Invalid phone number'),
        },
        {
          max: GENERAL_FIELD_MAX_LENGTH.phoneNumber,
          message: `Max ${GENERAL_FIELD_MAX_LENGTH.phoneNumber} characters`,
        },
        {
          pattern: VALIDATION_PATTERNS.numericWithSpecialChars,
          transform: trim,
          translatedMessage: 'Invalid phone number',
        },
      ],
    },
  };

  const startPhoneLoginProcess = async (
    formattedDialCode: string,
    formattedPhoneNumber: string,
    countryCode: CountryCode,
  ): Promise<void> => {
    const { onSubmit } = props;

    onSubmit(formattedDialCode, formattedPhoneNumber, countryCode);
  };

  useMountEffect(() => {
    const { form } = props;
    const { validateFields } = form;

    validateFields();
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleDropDownChange = (country: string): void => {
    form.setFieldsValue({ dialCode: getCountryByCountryCode(country)?.dialCode.toString() ?? undefined });
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleOk();
    }
  };

  const handleDialCodeChange = async (dialCode: string): Promise<void> => {
    form.setFieldsValue({ countryCode: getCountryCodeByDialCode(dialCode) ?? undefined });
  };

  const isFormInvalid = (): boolean => {
    const { form } = props;
    const { getFieldsError } = form;

    const fieldsError = getFieldsError();

    const fields = Object.keys(fieldsError);
    return fields.length === 0 || fields.some((field) => fieldsError[field]);
  };

  const handleOk = async (): Promise<void> => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    if (authenticationStore.loading) {
      return;
    }

    validateFieldsAndScroll((errors: Object, values: EnterPhoneFormFields) => {
      const { phoneNumber, dialCode, countryCode } = values;
      if (errors) {
        return;
      }

      const formattedDialCode = dialCode.trim();
      const formattedPhoneNumber = phoneNumber.trim();
      const internationalPhoneNumber = joinNationalPhoneAndDialCode(formattedDialCode, formattedPhoneNumber);

      confirm({
        content: renderPhoneNumber(internationalPhoneNumber),
        okText: 'YES',
        cancelText: 'NO',
        icon: <span />,
        onOk: () => {
          storeUserCountry(countryCode);
          startPhoneLoginProcess(formattedDialCode, formattedPhoneNumber, countryCode);
        },
      });
    });
  };

  const renderPhoneNumber = (phoneNumber: string): ReactElement => {
    return (
      <ModalContainer>
        <ModalTitle>Phone Number Confirmation</ModalTitle>
        <ModalBody>
          <ModalTextLine>Is this your phone number?</ModalTextLine>
          <ModalPhoneNumberLine>{phoneNumber}</ModalPhoneNumberLine>
        </ModalBody>
      </ModalContainer>
    );
  };

  const renderCountryDropdown = (): ReactElement => {
    return (
      <NakedDropdown
        accessibilityLabel='country of phone'
        name='drp-auth-phone-enter-phone-country-name'
        dataTestId='drp-auth-phone-enter-phone-country-name'
        placeholder='Country'
        onChange={handleDropDownChange}
        isSearchable
      >
        {Object.entries(COUNTRIES).map(([countryCode, country]) => {
          return (
            <CountryDropdownItem
              key={countryCode + country.dialCode}
              keywords={[country.name, `+${country.dialCode}`, countryCode]}
              value={countryCode}
              textWhenSelected={country.name}
            >
              <div>{country.name}</div>
              <StyledCountryDialCode>+{country.dialCode}</StyledCountryDialCode>
            </CountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const { onBack } = props;

  const hasFieldErrors = isFormInvalid();

  return (
    <>
      <ContentContainer>
        <Title>
          Text Message
          <br />
          Code Verification
        </Title>
        <LoginSubTitle>Select your country and enter your phone number.</LoginSubTitle>
        <EnterPhoneForm onSubmit={handleFormSubmit}>
          <FormItemBox
            appearance='line'
            form={form}
            fieldName='countryCode'
            fieldDecoratorOptions={fieldDecorators.countryCode}
            showErrors='none'
          >
            {renderCountryDropdown()}
          </FormItemBox>
          <InputsContainer>
            <CountryCodeItem>
              <FormItemBox appearance='line' form={form} fieldName='dialCode' fieldDecoratorOptions={fieldDecorators.dialCode}>
                <NakedFormInput
                  name='inpt-auth-phone-enter-phone-country-code'
                  type='number'
                  colorScheme='secondary'
                  placeholder='Country code'
                  prefix='+'
                  onKeyPress={handleKeyPress}
                  onChange={handleDialCodeChange}
                  disableSuggestion
                />
              </FormItemBox>
            </CountryCodeItem>
            <PhoneNumberItem>
              <FormItemBox
                appearance='line'
                form={form}
                fieldName='phoneNumber'
                fieldDecoratorOptions={fieldDecorators.phoneNumber}
              >
                <NakedFormInput
                  name='inpt-auth-phone-enter-phone-phone-number'
                  type='text'
                  ref={phoneNumberInputRef}
                  colorScheme='secondary'
                  placeholder='Phone Number'
                  onKeyPress={handleKeyPress}
                  disableSuggestion
                />
              </FormItemBox>
            </PhoneNumberItem>
          </InputsContainer>
          <ActionsContainer>
            <Button id='btn-auth-phone-enter-phone-back' appearance='text' onClick={onBack} size='small'>
              BACK
            </Button>
            <PrimaryButton id='btn-auth-phone-enter-phone-continue' onClick={handleOk} disabled={hasFieldErrors}>
              CONTINUE
            </PrimaryButton>
          </ActionsContainer>
        </EnterPhoneForm>
      </ContentContainer>
      <RowSeparator />
      <FooterContainer>
        <FooterSubTitle>
          We will send you a text message with a code to your phone, so that only you can sign in to this account.
        </FooterSubTitle>
      </FooterContainer>
    </>
  );
});

export default Form.create<Props>()(PhoneLoginEnterPhone);

const ContentContainer = styled.div`
  padding: 48px 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const LoginSubTitle = styled(SubTitle)`
  margin-bottom: 15px;
`;

const EnterPhoneForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;

const FooterContainer = styled.div`
  padding: 24px 72px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CountryCodeItem = styled.div`
  width: 140px;
  margin-right: 20px;
`;

const PhoneNumberItem = styled.div`
  flex: 1;
`;

const ModalPhoneNumberLine = styled.div`
  color: var(--accent-blue-600);
`;

const CountryDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const StyledCountryDialCode = styled.div`
  color: var(--transparent-black-900);
`;
