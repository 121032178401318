import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import * as appServices from '../services/appServices';
import Log from '../libs/logger';
import { UUID_REGEX } from '@app/utils/regexUtils';
import browserHistory from '@app/utils/browserHistory';
import UserStore from '@app/stores/UserStore';

const STORAGE_SELECTED_ORGANIZATION_ID_KEY = 'selected_organization_id';

const ORGANIZATION_ID_QUERY_PARAM = 'orgId';

export default class UserStoreImpl implements UserStore {
  @observable getUserInfoLoading: boolean = false;
  @observable user: AppUser | undefined = undefined;
  @observable currentSecretId: string | undefined = undefined;
  @observable selectedOrganizationId: string | undefined = undefined;
  private readonly _isModeOrganizational: boolean;

  constructor(isModeOrganizational = false) {
    makeObservable(this);

    this._isModeOrganizational = isModeOrganizational;

    reaction(
      () => this.selectedOrganization,
      (newOrganization) => UserStoreImpl.saveOrganizationIdToLocalStorage(newOrganization),
    );
  }

  @computed
  get doesUserHaveOrganizations(): boolean {
    return this._isModeOrganizational && !!this.user?.organizations?.length;
  }

  loadUserInfo = flow(function* (this: UserStoreImpl, secretId?: string) {
    this.getUserInfoLoading = true;

    try {
      const userInfo: AppUser = yield appServices.getUserInfo(secretId);
      this.user = { ...userInfo, organizations: this.sortOrganizationsByName(userInfo.organizations) };

      if (secretId) {
        this.currentSecretId = secretId;
      }
    } catch (e: unknown) {
      Log.exception(e);

      throw e;
    } finally {
      this.getUserInfoLoading = false;
    }
  });

  tryLoadUserInfo = async (secretId?: string): Promise<boolean> => {
    try {
      await this.loadUserInfo(secretId);
      return true;
    } catch {
      return false;
    }
  };

  verifyUserInfo = flow(function* (this: UserStoreImpl) {
    this.getUserInfoLoading = true;

    try {
      yield appServices.verifyUserInfo(this.currentSecretId);
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    } finally {
      this.getUserInfoLoading = false;
    }
  });

  @action clearUser = (): void => {
    this.user = undefined;
    this.currentSecretId = undefined;
  };

  sortOrganizationsByName = (organizations: UserOrganization[]): UserOrganization[] => {
    return organizations
      .filter((organization) => organization.data?.name)
      .sort((first, second) => first.data.name.localeCompare(second.data.name));
  };

  @action switchOrganizationById(organizationId: string): void {
    const matchedOrganization = this.getOrganizationById(organizationId);

    if (!matchedOrganization) {
      return;
    }

    this.selectedOrganizationId = matchedOrganization.id;
  }

  @computed
  get selectedOrganization(): UserOrganization | undefined {
    // Don't try to search selected org unless the user is logged in and the user has organizations
    if (!this.doesUserHaveOrganizations) {
      return;
    }

    return (
      this.getOrganizationById(this.selectedOrganizationId) ??
      this.getOrganizationById(UserStoreImpl.selectedOrganizationIdFromQueryParams) ??
      this.getOrganizationById(UserStoreImpl.selectedOrganizationIdFromLocalStorage) ??
      this.user?.organizations?.[0]
    );
  }

  @computed
  private static get selectedOrganizationIdFromQueryParams(): string | undefined {
    const orgIdQueryParams = browserHistory.searchParams.getAll(ORGANIZATION_ID_QUERY_PARAM);

    if (!orgIdQueryParams?.length) {
      return;
    }

    // Removes the orgId query param from the URL
    browserHistory.searchParams.delete(ORGANIZATION_ID_QUERY_PARAM);

    const orgIdQueryParam = orgIdQueryParams[0];

    if (!orgIdQueryParam?.match(UUID_REGEX)) {
      Log.event('IllegalOrganizationId', { organizationId: orgIdQueryParam });
      return;
    }

    return orgIdQueryParam;
  }

  private static get selectedOrganizationIdFromLocalStorage(): string | undefined {
    if (typeof Storage === 'undefined') {
      return;
    }

    return localStorage.getItem(STORAGE_SELECTED_ORGANIZATION_ID_KEY) ?? undefined;
  }

  private getOrganizationById(savedSelectedOrganizationId: string | undefined): UserOrganization | undefined {
    if (!savedSelectedOrganizationId) {
      return;
    }

    return this.user?.organizations?.find((organization) => organization.id === savedSelectedOrganizationId);
  }

  @action
  private static saveOrganizationIdToLocalStorage = (organization: UserOrganization | undefined): void => {
    if (typeof Storage === 'undefined') {
      return;
    }

    if (organization?.id) {
      localStorage.setItem(STORAGE_SELECTED_ORGANIZATION_ID_KEY, organization.id);
    } else {
      localStorage.removeItem(STORAGE_SELECTED_ORGANIZATION_ID_KEY);
    }
  };
}
