import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import EmptyReportImage from '@mortee/images/check/empty-report.svg';
import SVG from '@app/components/SVG';

const EmptyReport: FunctionComponent = () => {
  return (
    <Container>
      <StyledEmptyReportImage accessibilityLabel='' image={EmptyReportImage} />
      <div>No changes were found in your MVF</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 88px 0 110px;

  font-size: 36px;
  letter-spacing: 0.49px;
  text-align: center;
  color: var(--accent-blue-600);
`;

const StyledEmptyReportImage = styled(SVG)`
  width: 216px;
  height: 126px;
  margin-bottom: 40px;
`;

export default EmptyReport;
