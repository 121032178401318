import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Dragger from 'antd/lib/upload/Dragger';
import FileList from './FileList';
import { FileAndErrors } from '@app/domain/files';
import Button from '@app/components/Button';

export interface UploadFileDraggerProps {
  id?: string;
  title: string;
  multiple?: boolean;
  files: FileAndErrors[];
  onFileAdded: (file: File) => void;
  onFileDeleted: (file: File, index: number) => void;
  disabled?: boolean;
  className?: string;
}

const FileUploadDragger: FunctionComponent<UploadFileDraggerProps> = (props) => {
  const handleBeforeUpload = (file: File, allFilesToAdd: File[]): boolean => {
    const { onFileAdded, multiple } = props;

    // Deal with a case the card should support a single file but multiple files are dragged and dropped in
    // In that case we should only take the last file in the list
    if (multiple || (allFilesToAdd?.length && file === allFilesToAdd[allFilesToAdd.length - 1])) {
      onFileAdded?.(file);
    }

    return false;
  };

  const { id, files, onFileDeleted, title, multiple, disabled, className } = props;

  return (
    <StyledDragger
      id={id}
      className={className}
      name='file'
      multiple={multiple}
      showUploadList={false}
      disabled={disabled}
      beforeUpload={(file, allFilesToAdd): boolean => handleBeforeUpload(file, allFilesToAdd)}
    >
      <Title>{title}</Title>
      <FileList files={files} onFileDeleted={onFileDeleted} />
      <BottomTitle>
        Drag and drop{' '}
        {files.length > 0 && (
          <span>
            &nbsp;<Underline>{multiple ? 'more' : 'a different'}</Underline>&nbsp;
          </span>
        )}{' '}
        file{multiple && 's'} here or
        <StyledButton id='btn-file-card-dragger-browse' onClick={(): void => {}} appearance='text'>
          BROWSE
        </StyledButton>
      </BottomTitle>
    </StyledDragger>
  );
};

export default FileUploadDragger;

const StyledDragger = styled(Dragger)<{ disabled: boolean | undefined }>`
  cursor: pointer;
  background: transparent !important;
  border: none !important;

  .ant-upload {
    background: transparent !important;
    border: none !important;
    outline: none;
  }

  .ant-upload-drag {
    padding: 0;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }

  .ant-upload-drag-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    outline: none;
  }

  ${(p): string | undefined => {
    if (p.disabled) {
      return 'pointer-events: none';
    }
  }};
`;

const Title = styled.div`
  padding: 4px 0 20px 0;
  text-align: center;
  font-size: 17px;
`;

const BottomTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  color: var(--transparent-black-50-deprecated);
`;

const Underline = styled.span`
  color: var(--gray-blue-deprecated);
  font-weight: bold;
  text-decoration: underline;
`;

const StyledButton = styled(Button)`
  padding: 13px 5px 9px;
  margin: 4px;
`;
