import React, { FC } from 'react';
import { Assignee } from '@mortee/domain/validationSystem';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

interface Props {
  assignee: Assignee | null;
  className?: string;
  greyedOut?: boolean;
}

const AssigneeText: FC<Props> = ({ assignee, className, greyedOut }) => {
  if (!assignee) {
    return <span className={className}>Unassigned</span>;
  }

  return (
    <StyledSpan className={className} greyedOut={greyedOut}>
      {assignee.name} {assignee.isMe && '(me)'}
    </StyledSpan>
  );
};

export default AssigneeText;

export const StyledSpan = styled.span<{ greyedOut?: boolean | null }>`
  ${ifProp('greyedOut', 'color: var(--transparent-gray-800);')};
`;
