import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import SupplierValidationRecordTimeline from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/activityLog/SupplierValidationRecordTimeline';
import { ActivityLog } from '@mortee/domain/validationSystemTimelineEvents';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import LoadableTransitionLoader from '@app/components/LoadableTransitionLoader';
import Loadable from '@app/utils/Loadable';
import { SidePadding } from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';

interface Props {
  allTimelineEventLoadable: Loadable<ActivityLog>;
  className?: string;
}

const SupplierValidationRecordActivityLog: FC<Props> = ({ allTimelineEventLoadable, className }) => {
  return (
    <StyledVerticalShadowScroller className={className}>
      <LoadableTransitionLoader loadable={allTimelineEventLoadable} firstLoadingContent={(): ReactNode => <InitialHeightDiv />}>
        {(loadedTimelineEvents: ActivityLog): ReactElement => {
          if (!loadedTimelineEvents.events.length) {
            return <SidePadding>No events have happened yet</SidePadding>;
          }

          return <SupplierValidationRecordTimeline events={loadedTimelineEvents.events} />;
        }}
      </LoadableTransitionLoader>
    </StyledVerticalShadowScroller>
  );
};

export default SupplierValidationRecordActivityLog;

const StyledVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
  margin-bottom: 15px;
`;

const InitialHeightDiv = styled.div`
  height: 40vh;
`;
