import { Country, getCountryByCountryCode, getCountryCodeByDialCode } from '@app/domain/countries';
import getUserLocale from 'get-user-locale';
import useMountEffect from './useMountEffect';

export const STORAGE_USER_SELECTED_COUNTRY = 'user_selected_country';

export const storeUserCountry = (countryCode: string | null): void => {
  if (countryCode) {
    localStorage.setItem(STORAGE_USER_SELECTED_COUNTRY, countryCode);
  }
};

export const storeUserCountryByDialCode = (dialCode: string | null): void => {
  storeUserCountry(getCountryCodeByDialCode(dialCode) ?? null);
};

export function useLocaleCountry(callback: (country: Country, countryCode: string) => void): void {
  useMountEffect(() => {
    if (typeof Storage === 'undefined') {
      return;
    }

    const userSelectedCountry = localStorage.getItem(STORAGE_USER_SELECTED_COUNTRY);

    const userCountry = userSelectedCountry ?? getUserLocale().split('-')?.pop();

    if (!userCountry) {
      return;
    }

    const userCountryObj = getCountryByCountryCode(userCountry);

    if (!userCountryObj) {
      return;
    }

    callback(userCountryObj, userCountry);
  });
}
