import {
  BankAccountCertificateAccountData,
  BankAccountCertificateAccountDataServerResponse,
  BankAccountCertificatePayeeData,
  BankAccountCertificatePayeeDataServerResponse,
} from '@app/domain/bac';
import { ValidatedAccountEventData, ValidatedPayeeAccountAttachment } from '@app/domain/validatedPayeeAccount';
import {
  AggregatedValidatedPayee,
  AggregatedValidatedPayeeServerResponse,
  transformAggregatedValidatedPayee,
} from '@app/domain/aggregatedValidatedPayee';
import { SanctionsScreeningInfo, SanctionsScreeningInfoServerResponse } from '@app/domain/validatedPayee';

export interface GuestSearchPayeeResponse {
  validatedPayeeData: AggregatedValidatedPayeeServerResponse;
}

export interface GuestPayeeSearchVerifyResultServerResponse {
  validatedAccountDetails: MorteeAccountDetails | null;
  aggregatedValidatedPayeeData: AggregatedValidatedPayeeServerResponse | null;
  lastValidatedPayeeAccountEventData: ValidatedAccountEventData | null;
  validated: boolean;
  validatedPayeeAccountAttachments: ValidatedPayeeAccountAttachment[] | undefined;
  lastValidationTimestamp: number;
  lastSanctionsScreeningEvent: SanctionsScreeningInfoServerResponse | null;
  presentationAccountNumber: string | null;
}

export interface GuestPayeeSearchVerifyResult {
  validatedAccountDetails: MorteeAccountDetails | null;
  aggregatedValidatedPayeeData: AggregatedValidatedPayee | null;
  lastValidatedPayeeAccountEventData: ValidatedAccountEventData | null;
  validated: boolean;
  validatedPayeeAccountAttachments: ValidatedPayeeAccountAttachment[];
  validationTimestamp: number | null;
  sanctionsScreeningInfo: SanctionsScreeningInfo | null;
  presentationAccountNumber: string | null;
}

export function transformGuestPayeeSearchVerifyResult(
  serverResponse: GuestPayeeSearchVerifyResultServerResponse,
): GuestPayeeSearchVerifyResult {
  const { aggregatedValidatedPayeeData } = serverResponse;
  return {
    ...serverResponse,
    aggregatedValidatedPayeeData: aggregatedValidatedPayeeData
      ? transformAggregatedValidatedPayee(aggregatedValidatedPayeeData)
      : null,
    validatedPayeeAccountAttachments: serverResponse.validatedPayeeAccountAttachments ?? [],
    validationTimestamp: serverResponse.lastValidationTimestamp,
    sanctionsScreeningInfo: serverResponse.lastSanctionsScreeningEvent,
  };
}

export interface GuestBankAccountCertificateVerifyResultServerResponse {
  payeeData: BankAccountCertificatePayeeDataServerResponse;
  accountData: BankAccountCertificateAccountDataServerResponse;
  validated: boolean;
  validatedPayeeAccountAttachments: ValidatedPayeeAccountAttachment[] | undefined;
  lastValidationTimestamp: number;
  lastSanctionsScreeningEvent: SanctionsScreeningInfoServerResponse | undefined;
  isPayeeUpdated: boolean;
  presentationAccountNumber: string | null;
}

export interface GuestBankAccountCertificateVerifyResult {
  payeeData: BankAccountCertificatePayeeData;
  accountData: BankAccountCertificateAccountData;
  validated: boolean;
  validatedPayeeAccountAttachments: ValidatedPayeeAccountAttachment[];
  validationTimestamp: number | null;
  sanctionsScreeningInfo: SanctionsScreeningInfo | undefined;
  isPayeeUpdated: boolean;
  presentationAccountNumber: string | null;
}

export function transformGuestBankAccountCertificateVerifyResult(
  serverResponse: GuestBankAccountCertificateVerifyResultServerResponse,
): GuestBankAccountCertificateVerifyResult {
  return {
    ...serverResponse,
    validatedPayeeAccountAttachments: serverResponse.validatedPayeeAccountAttachments ?? [],
    validationTimestamp: serverResponse.lastValidationTimestamp,
    sanctionsScreeningInfo: serverResponse.lastSanctionsScreeningEvent,
  };
}
