import NavigationStore from '@app/stores/NavigationStore';
import UserStore from '@app/stores/UserStore';
import { UnifiedPayeeIds } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import PermissionsStore from '@app/stores/PermissionsStore';
import { makeObservable } from 'mobx';
import { LocationDescriptor } from 'history';

export const VALIDATIONS_TABLE_SELECTED_RECORD_ID_QUERY_PARAM = 'record-id';

export default class MorteeNavigationStore extends NavigationStore {
  constructor(userStore: UserStore, permissionsStore: PermissionsStore) {
    super(userStore, permissionsStore);

    makeObservable(this);
  }

  generateAccountVerificationHomeLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.accountVerificationInitialPage);
  }

  generateAccountVerificationHomeHref(): string {
    return this.generateHref(this.permissionsStore.allowedRoutesByKey.accountVerificationInitialPage);
  }

  generateAccountVerificationWithPayeeLink(payee: UnifiedPayeeIds): LocationDescriptor {
    if (payee.validatedId) {
      return this.generateAccountVerificationWithValidatePayeeLink(payee.validatedId);
    }

    if (payee.privateIds?.length) {
      return this.generateAccountVerificationWithPrivatePayeeLink(payee.privateIds[0]);
    }

    return this.generateAccountVerificationHomeLink();
  }

  generateAccountVerificationWithPrivatePayeeLink(privateId: string): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.accountVerificationWithPrivatePayee, { privateId });
  }

  generateAccountVerificationWithValidatePayeeLink(validatedId: string): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.accountVerificationWithValidatedPayee, { validatedId });
  }

  generateAccountVerificationWithBankAccountCertificateLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.accountVerificationWithBankAccountCertificate);
  }

  generateLoadSecureFilesLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.secureFilesTransferLoad);
  }

  generateLoadPaymentBatchLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.loadPaymentBatch);
  }

  generateSingleBatchLink(batchId: string): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.singlePaymentBatch, { batchId });
  }

  generateMasterDataGuard(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.masterDataGuard);
  }

  generateMyBankAccountsLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.myBankAccounts);
  }

  generateManageValidatedPayeePageHref(validatedPayeeUniformId: string): string {
    return this.generateHref(this.permissionsStore.allowedRoutesByKey.manageValidatedPayee, { validatedPayeeUniformId });
  }

  generateCreateValidatedPayeeAccountPage(validatedPayeeUniformId: string): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.createValidatedPayeeAccount, { validatedPayeeUniformId });
  }

  generateEditValidatedPayeePage(validatedPayeeUniformId: string): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.editValidatedPayee, { validatedPayeeUniformId });
  }

  generateEditValidatedPayeePageHref(validatedPayeeUniformId: string): string {
    return this.generateHref(this.permissionsStore.allowedRoutesByKey.editValidatedPayee, { validatedPayeeUniformId });
  }

  generateSupplierValidationRecordsTablePageWithEditValidationModalHref(supplierValidationRecordStaticId: string): string {
    return this.generateHref(this.permissionsStore.allowedRoutesByKey.validationSystemRecordsTable, undefined, {
      [VALIDATIONS_TABLE_SELECTED_RECORD_ID_QUERY_PARAM]: supplierValidationRecordStaticId,
    });
  }
}
