import NavigationStore from '@app/stores/NavigationStore';
import UserStore from '@app/stores/UserStore';
import PermissionsStore from '@app/stores/PermissionsStore';
import { makeObservable } from 'mobx';
import { SupplierRegistrationProcessInstructionType } from '@app/domain/commonSupplierRegistration';
import { LocationDescriptor } from 'history';

export default class SupplierRegistrationNavigationStore extends NavigationStore {
  constructor(userStore: UserStore, permissionsStore: PermissionsStore) {
    super(userStore, permissionsStore);

    makeObservable(this);
  }

  get isIpsPage(): boolean {
    return this.selectedSection?.id === 'supplierRegistrationForIncomingPayments';
  }

  generateAccountValidationForSupplierWelcome(isIpsMode: boolean, referenceId: string | null): LocationDescriptor {
    if (isIpsMode) {
      return this.generateLink(this.permissionsStore.allowedRoutesByKey.accountRegistrationForIncomingPaymentSecurityWelcome);
    }

    return this.generateLink(
      this.permissionsStore.allowedRoutesByKey.accountRegistrationForSuppliersWelcome,
      {},
      { ref: referenceId },
    );
  }

  generateAccountValidationForSuppliersFAQ(isIpsMode: boolean, referenceId: string | null): LocationDescriptor {
    if (isIpsMode) {
      return this.generateLink(this.permissionsStore.allowedRoutesByKey.accountRegistrationForIncomingPaymentSecurityFAQ);
    }

    return this.generateLink(
      this.permissionsStore.allowedRoutesByKey.accountRegistrationForSuppliersFAQ,
      {},
      { ref: referenceId },
    );
  }

  generateAccountValidationAfterForm(
    isIpsMode: boolean,
    email: string,
    registrationNumber: string | null,
    referenceId: string | null,
    displayInstructionType: SupplierRegistrationProcessInstructionType | null = null,
    skipWelcome: boolean,
  ): LocationDescriptor {
    if (isIpsMode) {
      return this.generateLink(
        this.permissionsStore.allowedRoutesByKey.accountRegistrationForIncomingPaymentSecurityAfterForm,
        {},
        {
          email,
          instructionType: displayInstructionType,
          regId: registrationNumber,
          skipWelcome: skipWelcome ? 'true' : null,
        },
      );
    }

    return this.generateLink(
      this.permissionsStore.allowedRoutesByKey.accountRegistrationForSuppliersAfterForm,
      {},
      {
        email,
        instructionType: displayInstructionType,
        regId: registrationNumber,
        ref: referenceId,
        skipWelcome: skipWelcome ? 'true' : null,
      },
    );
  }
}
