import useMode from '@app/hooks/useMode';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import { ModalAppContextProps } from '@app/ModalAppContext';

export default function (): ModalAppContextProps {
  const mode = useMode();
  const infraStores = useInfraStores();
  const appStores = useAppStores();

  return { mode, infraStores, appStores };
}
