import React, { ReactNode, useMemo, useState } from 'react';
import { FormInputChangeProps } from '@app/utils/form/form';
import { ForwardingFC } from '@app/domain/technicals/components';
import styled from '@emotion/styled';
import {
  NULL_ORG,
  SupplierValidationRecord,
  SupplierValidationRecordStatusSubsets,
  transformSupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import SupplierValidationRecordInnerCard from '@mortee/routes/validationSystem/SupplierValidationRecordCard';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import ValidationSystemServices from '@mortee/services/validationSystemServices';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import { useConsistentMemo } from '@app/hooks/useConsistentMemo';
import Loadable from '@app/utils/Loadable';
import PaginationManager from '@app/utils/pagination/PaginationManager';
import AsyncAccumulatorPaginationManager from '@app/utils/pagination/AsyncAccumulatorPaginationManager';
import InternalMemoryNextPageLoader from '@app/utils/pagination/InternalMemoryNextPageLoader';
import NakedFormSearchInput from '@app/components/NakedFormSearchInput';
import PaginatedInfiniteScroll from '@app/components/PaginatedInfiniteScroll';
import { CircularProgress } from '@material-ui/core';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import { Bold13TransparentBlack900 } from '@app/components/Text';
import FormlessItemBox from '@app/components/inputs/FormlessItemBox';
import ToggleButtonGroup from '@app/components/inputs/ToggleButtonGroup';
import { SortOrder } from '@mortee/domain/validationRecordTable';

interface Props extends FormInputChangeProps<SupplierValidationRecord> {
  id: string;
  dataTestId?: string;
  organizationIdOfSupplierRegistrationProcess: string | null;
  scrollableTargetId: string;
  className?: string;
}

const RECOMMENDATIONS_CARDS_AMOUNT = 10;

const SupplierValidationRecordSelector: ForwardingFC<HTMLDivElement, Props> = React.forwardRef(
  (
    {
      id,
      dataTestId = id,
      organizationIdOfSupplierRegistrationProcess,
      value: selectedRecord,
      onChange,
      className,
      scrollableTargetId,
    },
    outerRef,
  ) => {
    const { userStore } = useInfraStores<MorteeMode>();

    async function loadRecommendedSupplierRegistrationProcessesForOrganization(
      searchNullOrg: boolean,
      organizationIdOfSupplierRegistrationProcess: string | null,
    ): Promise<SupplierValidationRecord[]> {
      const orgIdsToSearch = getOrgIdsToSearch(searchNullOrg, organizationIdOfSupplierRegistrationProcess);

      const supplierValidationRecordServerResponsePaginatedData = await ValidationSystemServices.getValidationRecords(
        undefined,
        undefined,
        {
          didUserRegister: false,
          status: SupplierValidationRecordStatusSubsets.relevantToLinkToRegistrationProcess,
          organizationId: orgIdsToSearch,
          assignee: undefined,
          automailerStatus: undefined,
          evidenceType: undefined,
          customerInvolvement: undefined,
          registrationDate: undefined,
          instructionType: undefined,
          followUpFilter: undefined,
        },
        { order: SortOrder.descending },
      );

      return supplierValidationRecordServerResponsePaginatedData.content.map((record) =>
        transformSupplierValidationRecord(record, userStore.user?.id),
      );
    }

    function getOrgIdsToSearch(
      searchNullOrg: boolean,
      organizationIdOfSupplierRegistrationProcess: string | null,
    ): string[] | undefined {
      if (!organizationIdOfSupplierRegistrationProcess) {
        // return all
        return undefined;
      }

      if (searchNullOrg) {
        return [NULL_ORG.id];
      }

      return [organizationIdOfSupplierRegistrationProcess];
    }

    const [searchNullOrg, setSearchNullOrg] = useState<boolean>(false);

    const [recommendedValidationRecordsLoadable] = useLoadable(async () => {
      return await loadRecommendedSupplierRegistrationProcessesForOrganization(
        searchNullOrg,
        organizationIdOfSupplierRegistrationProcess,
      );
    }, [organizationIdOfSupplierRegistrationProcess, searchNullOrg]);

    const recommendedValidationRecordsWithSelectedItemLoadable = useMemo((): Loadable<SupplierValidationRecord[]> => {
      if (!selectedRecord) {
        return recommendedValidationRecordsLoadable;
      }

      return recommendedValidationRecordsLoadable.map((loadedItems) => {
        return arrayWithoutValue(loadedItems, selectedRecord, (first, second) => first.staticId === second.staticId);
      });
    }, [selectedRecord, recommendedValidationRecordsLoadable]);

    const [filteredResults, search, setSearch] = useLoadableSearchFilter(recommendedValidationRecordsWithSelectedItemLoadable, {
      partialMatches: (item) => [item.supplierName, item.presentationId, item.companyCode, item.autoMailerManualLink],
    });

    const paginationManager = useConsistentMemo((): Loadable<PaginationManager<SupplierValidationRecord>> => {
      return filteredResults.map((supplierRegistrationProcesses) => {
        return new AsyncAccumulatorPaginationManager(
          new InternalMemoryNextPageLoader<SupplierValidationRecord>(
            supplierRegistrationProcesses,
            RECOMMENDATIONS_CARDS_AMOUNT,
            null,
          ),
        );
      });
    }, [filteredResults]);

    function renderSearchItems(items: SupplierValidationRecord[]): ReactNode {
      if (items.length === 0) {
        return <div data-testid='search-results-no-recommendations'>No recommendations available</div>;
      }

      return (
        <Container data-testid='search-results'>
          {items.map((result) => (
            <div data-testid={`svm-search-result-${result.staticId}`} key={result.staticId}>
              <SupplierValidationRecordInnerCard
                record={result}
                isSelected={false}
                onSelected={(): void => {
                  onChange?.(result);
                }}
              />
            </div>
          ))}
        </Container>
      );
    }

    function renderSelectedRecord(selectedRecord: SupplierValidationRecord): ReactNode {
      return (
        <SelectedItemWrapper data-testid='svm-selector-selected-item'>
          <div>
            <Bold13TransparentBlack900.span>"{selectedRecord.supplierName}" selected</Bold13TransparentBlack900.span> -{' '}
            <a onClick={(): void => onChange?.(undefined)}>Clear selection</a>
          </div>
          <SupplierValidationRecordInnerCard key={selectedRecord.staticId} record={selectedRecord} isSelected />
        </SelectedItemWrapper>
      );
    }

    return (
      <Container className={className} data-testid={dataTestId}>
        {selectedRecord && renderSelectedRecord(selectedRecord)}
        {organizationIdOfSupplierRegistrationProcess && (
          <ToggleButtonGroup
            id='toggle-svm-customer-search'
            value={searchNullOrg}
            onChange={(newValue: boolean): void => setSearchNullOrg(newValue)}
            accessibilityLabel='Search only Validation records without organizations'
            options={[
              {
                text: 'Same customer',
                value: false,
                id: 'toggle-sv-same-customer',
              },
              {
                text: 'Unknown customer',
                value: true,
                id: 'toggle-sv-unknown-customer',
              },
            ]}
          />
        )}
        <FormlessItemBox appearance='corners' hasValue={!!search} expandBelowOnError>
          <NakedFormSearchInput
            name='inpt-sr-selector-search'
            type='text'
            placeholder='Search for a Supplier Name, Id, Company code or Automailer manual link (URL)'
            placeholderStyle='onlyWhenEmpty'
            clearable
            value={search}
            onChange={(newValue): void => setSearch(newValue ?? '')}
          />
        </FormlessItemBox>
        <PaginatedInfiniteScroll
          id='supplier-validation-record-infinite-scroll'
          dataTestId='supplier-validation-record-infinite-scroll'
          paginationManager={paginationManager}
          loader={<CircularProgress color='primary' size={32} />}
          scrollableTarget={scrollableTargetId}
        >
          {(items): ReactNode => renderSearchItems(items)}
        </PaginatedInfiniteScroll>
      </Container>
    );
  },
);

export default SupplierValidationRecordSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const SelectedItemWrapper = styled.div`
  margin-bottom: 10px;
`;
