import React, { forwardRef, ReactNode } from 'react';
import Button from '../Button';
import styled from '@emotion/styled';
import { ForwardingFC } from '@app/domain/technicals/components';

export interface DropdownItemProps {
  children: ReactNode;
  value: string;
  keywords?: string[];
  textWhenSelected: string;
  className?: string;
}

const DropdownItem: ForwardingFC<HTMLButtonElement, DropdownItemProps> = forwardRef((props, ref) => {
  const { className, value, children } = props;

  return (
    <StyledButton
      id={`btn-dropdown-item-${value}`}
      dataTestId={`btn-dropdown-item-${value}`}
      appearance='text'
      cornerType='none'
      className={className}
      ref={ref}
      onClick={(): void => {}}
    >
      {children}
    </StyledButton>
  );
});

export default DropdownItem;

const StyledButton = styled(Button)`
  line-height: normal;
  padding: 16px 12px;
  text-align: unset;
`;
