import { isDefined } from '@app/utils/utils';
import { getRepresentationFormatterWithBIC } from '@app/domain/accountDetailsFormatters';
import {
  DeterministicValidationRepresentationData,
  DeterministicValidationResultType,
} from '@app/domain/deterministicValidation';

export type AccountRepresentationWithValidationLevel = {
  representation: MorteeAccountDetails;
  validationLevel: DeterministicValidationResultType;
};

type PresentationAccountRepresentationWithValidationLevel = {
  type: AccountRepresentationType;
  formattedRepresentation: string;
  validationLevel: DeterministicValidationResultType;
  warningText: string | null;
};

const removeSwiftbanIfIrrelevant = (
  accountRepresentationsDetailsAndValidationLevelMap: Map<AccountRepresentationType, AccountRepresentationWithValidationLevel>,
): Map<AccountRepresentationType, AccountRepresentationWithValidationLevel> => {
  const swiftbanValidationLevel = accountRepresentationsDetailsAndValidationLevelMap.get('swiftban')?.validationLevel;
  const ibanValidationLevel = accountRepresentationsDetailsAndValidationLevelMap.get('iban')?.validationLevel;

  if (swiftbanValidationLevel !== ibanValidationLevel) {
    return accountRepresentationsDetailsAndValidationLevelMap;
  }

  const copyMap = new Map<AccountRepresentationType, AccountRepresentationWithValidationLevel>(
    accountRepresentationsDetailsAndValidationLevelMap.entries(),
  );
  copyMap.delete('swiftban');

  // in case swiftban and iban have the same validation level, we omit the swiftban
  return copyMap;
};

export const calcRepresentationsToActuallyDisplay = (
  dvAllRepresentationsData: DeterministicValidationRepresentationData[],
): PresentationAccountRepresentationWithValidationLevel[] => {
  const accountRepresentationsWithValidationLevelMap = new Map<AccountRepresentationType,
    AccountRepresentationWithValidationLevel>(
    dvAllRepresentationsData.map((dvRepresentationData) => {
      return [
        dvRepresentationData.representationType,
        { representation: dvRepresentationData.rawAccountRepresentation, validationLevel: dvRepresentationData.validationResult },
      ];
    }),
  );

  const toDisplayMap = removeSwiftbanIfIrrelevant(accountRepresentationsWithValidationLevelMap);

  return Array.from(toDisplayMap.entries())
    .map(
      ([type, representationWithValidation]: [
        AccountRepresentationType,
        AccountRepresentationWithValidationLevel,
      ]): PresentationAccountRepresentationWithValidationLevel | null => {
        if (!representationWithValidation) {
          return null;
        }

        return {
          type,
          formattedRepresentation: getRepresentationFormatterWithBIC(type)(representationWithValidation.representation),
          validationLevel: representationWithValidation.validationLevel,
          warningText:
            type === 'swiftban' && toDisplayMap.has('iban')
              ? 'SWIFT+IBAN validation level does not match the IBAN validation level.'
              : null,
        };
      },
    )
    .filter(isDefined);
};
