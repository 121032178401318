import Button, { ButtonProps } from './Button';
import React, { FC, MouseEvent } from 'react';
import { LoadingState } from '@app/utils/Loadable';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';

interface AsyncButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick(event: MouseEvent<HTMLButtonElement>): Promise<void>;
}

const AsyncButton: FC<AsyncButtonProps> = ({ onClick, loading, ...buttonProps }) => {
  const [actionLoadable, wrappedClickAction] = useManuallyCalledLoadable<void, [event: MouseEvent<HTMLButtonElement>]>(onClick);

  return (
    <Button
      loading={loading || actionLoadable.loadState === LoadingState.InProgress}
      onClick={wrappedClickAction}
      {...buttonProps}
    />
  );
};

export default AsyncButton;
