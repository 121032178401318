import React, { FC, KeyboardEvent, ReactElement, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { showInfoModal } from '@app/components/Modal';
import { observer } from 'mobx-react';
import Button from '@app/components/Button';
import GuestSearchPayeeResults from './GuestSearchPayeeResults';
import CornersInputBox from '@app/components/inputs/inputBox/CornersInputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import Log from '@app/libs/logger';
import * as verifyService from '@ar/services/arVerifyService';
import { GUEST_RESULTS_TO_QUERY } from '@ar/stores/appStores/ARVerificationStore';
import { LoadingState } from '@app/utils/Loadable';
import { AggregatedValidatedPayee, transformAggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { useTranslation } from 'react-i18next';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';
import Popup from '@app/components/popup/Popup';

interface Props {
  onPayeeSelected(payee: AggregatedValidatedPayee): void;
}

const SearchPayee: FC<Props> = observer(({ onPayeeSelected }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [hideSearch, setHideSearch] = useState<boolean>(false);
  const popoverParentRef = useRef<HTMLDivElement>(null);

  const [searchResults, searchPayees] = useManuallyCalledLoadable<AggregatedValidatedPayee[]>(
    async (): Promise<AggregatedValidatedPayee[]> => {
      Log.event('searchPayees', { query: searchText }, true);
      const serverResponse = await verifyService.searchPayee(searchText, GUEST_RESULTS_TO_QUERY + 1);
      return serverResponse.map((response) => transformAggregatedValidatedPayee(response.validatedPayeeData));
    },
  );

  const { t } = useTranslation();
  const modalContext = useModalContext();

  const handleKeyPress = async (e: KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      await trySearchPayees();
    }
  };

  const trySearchPayees = async (): Promise<void> => {
    setHideSearch(false);
    if ((searchText?.length ?? 0) < 3) {
      Log.event('searchPayees', { error: 'too short' }, true);
      setHideSearch(true);
      showInfoModal(
        <ModalAppContext {...modalContext}>
          <ModalError>{t('ar.guestVerification.details.errors.searchTooShort')}</ModalError>
        </ModalAppContext>,
      );
      return;
    }

    if ((searchText?.length ?? 0) > 200) {
      Log.event('searchPayees', { error: 'too long' }, true);
      setHideSearch(true);
      showInfoModal(
        <ModalAppContext {...modalContext}>
          <ModalError>{t('ar.guestVerification.details.errors.searchTooLong')}</ModalError>
        </ModalAppContext>,
      );
      return;
    }

    await searchPayees();
  };

  const onSearchTextChange = (searchText: string): void => {
    setHideSearch(true);
    setSearchText(searchText);
  };

  const renderSideComponent = (): ReactElement => {
    return (
      <SearchButtonContainer>
        <StyledSearchButton
          id='btn-guest-search-payee'
          dataTestId='btnSearch'
          disabled={false}
          onClick={(): Promise<void> => trySearchPayees()}
        >
          {t('general.upperSearch')}
        </StyledSearchButton>
      </SearchButtonContainer>
    );
  };

  const showSearchResults =
    !!searchText && (searchResults.loadState === LoadingState.InProgress || searchResults.isResolved()) && !hideSearch;

  const hasTooManyPayeeSearchResults = searchResults.resolve(
    (loadedResults) => loadedResults.length > GUEST_RESULTS_TO_QUERY,
    () => false,
  );

  return (
    <Container ref={popoverParentRef}>
      <CornersInputBox>
        <StyledSearchInput
          name='inpt-guest-payee-search'
          type='text'
          placeholder={t<string>('ar.guestVerification.details.typePayeeName')}
          placeholderStyle='floating'
          dataTestId='txtSearch'
          value={searchText}
          onClear={(): void => onSearchTextChange('')}
          onChange={onSearchTextChange}
          onKeyPress={handleKeyPress}
          renderSideComponent={renderSideComponent}
        />
      </CornersInputBox>
      <Popup
        open={showSearchResults}
        placement='bottom'
        anchorEl={popoverParentRef.current}
        popupWidth='parent'
        onPopupClosed={(): void => setHideSearch(true)}
        preventOutsideClick={false}
      >
        <GuestSearchPayeeResults
          searchText={searchText}
          results={searchResults}
          hasTooManyPayeeSearchResults={hasTooManyPayeeSearchResults}
          onPayeeSelected={onPayeeSelected}
        />
      </Popup>
    </Container>
  );
});
export default SearchPayee;

const Container = styled.div`
  font-family: var(--text-font-family);

  z-index: 100;
  position: relative;
`;

const StyledSearchInput = styled(NakedFormInput)`
  flex: 1;
`;

export const StyledSearchButton = styled(Button)`
  margin-top: 2px;
`;

const ModalError = styled(LocaleAwareDiv)`
  width: 336px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: center;
  margin-bottom: 16px;
`;

const SearchButtonContainer = styled(LocaleAwareDiv)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-right: 8px;
`;
