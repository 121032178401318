import React, { FC } from 'react';
import { Tooltip } from 'antd';
import Button from '@app/components/Button';
import CircleCheckIcon from '@mortee/images/circle-check-green.svg';
import CirclePlusBlueIcon from '@mortee/images/circle-plus-blue.svg';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import Menu from '@app/components/popup/Menu';

interface Props {
  registrationFormRecordId: string;
  isRegistrationFormRecordLinkedToSupplierValidationRecord: boolean;
  onItemLinkToNewValidationClick(): void;
  onItemLinkToExistingValidationClick(): void;
}

const LinkRegistrationFormsToSupplierValidationRecordActions: FC<Props> = ({
  registrationFormRecordId,
  isRegistrationFormRecordLinkedToSupplierValidationRecord,
  onItemLinkToNewValidationClick,
  onItemLinkToExistingValidationClick,
}) => {
  if (isRegistrationFormRecordLinkedToSupplierValidationRecord) {
    return (
      <Tooltip title='Validation record is linked'>
        <Button
          id={`btn-supplier-registration-management-table-validation-linked-${registrationFormRecordId}`}
          dataTestId='btn-supplier-registration-management-table-validation-linked'
          colorScheme='grey'
          appearance='text'
          cornerType='circle'
          disabled
          onClick={(): void => {}}
        >
          <LinkToValidationSVG accessibilityLabel='record linked' image={CircleCheckIcon} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Menu
      id={`menu-supplier-registration-management-table-link-to-validation-${registrationFormRecordId}`}
      dataTestId={`menu-supplier-registration-management-table-link-to-validation`}
      colorScheme='grey'
      appearance='text'
      cornerType='circle'
      menuItems={[
        {
          id: `btn-supplier-registration-management-table-link-to-existing-validation-${registrationFormRecordId}`,
          dataTestId: 'btn-supplier-registration-management-table-link-to-existing-validation',
          text: 'Link to an existing validation record',
          onClick: () => onItemLinkToExistingValidationClick(),
        },
        {
          id: `btn-supplier-registration-management-table-link-to-new-validation-${registrationFormRecordId}`,
          dataTestId: 'btn-supplier-registration-management-table-link-to-new-validation',
          text: 'Create new validation record and link',
          onClick: () => onItemLinkToNewValidationClick(),
        },
      ]}
    >
      <LinkToValidationSVG accessibilityLabel='link record' image={CirclePlusBlueIcon} />
    </Menu>
  );
};

export default LinkRegistrationFormsToSupplierValidationRecordActions;

const LinkToValidationSVG = styled(SVG)`
  width: 19px;
  height: 19px;
`;
