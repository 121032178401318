import React, { FC, ReactElement, useMemo } from 'react';
import { create } from 'jss';
import { createGenerateClassName, jssPreset, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { cache } from '@emotion/css';
import { CacheProvider, css, Global } from '@emotion/react';
import { JssProvider } from 'react-jss';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import { createTheme } from '@material-ui/core';

const generateId = createGenerateClassName();

const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  // This fixes emotion + jss style order problems
  insertionPoint: document.getElementById('jss-insertion-point') || undefined,
});

const ThemeProvider: FC = observer(
  ({ children }): ReactElement => {
    const { languageStore, themeStore } = useInfraStores();

    const muiTheme = useMemo(() => {
      return createOwnMuiTheme(themeStore.theme.actionColor.main);
    }, [themeStore.theme.actionColor]);

    return (
      <>
        <Global styles={themeStore.globalStyles} />
        <Global
          styles={css`
            :root {
              --title-font-family: ${languageStore.selectedLanguageHasDialectCharacters
                ? 'open-sans'
                : 'Nexa, open-sans, arial, SEBSansSerif'};
              --text-font-family: ${languageStore.selectedLanguageHasDialectCharacters
                ? 'open-sans'
                : 'NexaText, open-sans, arial, SEBSansSerif'};
            }
          `}
        />
        <Global styles={themeStore.modeStyles} />
        <JssProvider jss={jss} generateId={generateId}>
          <CacheProvider value={cache}>
            <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
          </CacheProvider>
        </JssProvider>
      </>
    );
  },
);

function createOwnMuiTheme(actionColor: string): Theme {
  return createTheme({
    typography: {
      fontFamily: 'inherit',
    },
    palette: {
      primary: {
        main: actionColor,
      },
      secondary: {
        main: '#142361',
      },
    },
    props: {
      MuiCheckbox: {
        color: 'primary',
      },
      MuiTextField: {
        fullWidth: true,
        margin: 'normal',
      },
    },
  });
}

export default ThemeProvider;
