import React, { FC, ReactNode } from 'react';
import CardHeader, { RightAlignedCardHeaderSideContent, LeftAlignedCardHeaderSideContent } from '@app/components/card/CardHeader';
import MyValidatedPayeeAccountCard from '@mortee/routes/ips/myBankAccounts/MyValidatedPayeeAccountCard';
import { createNTimes } from '@app/utils/arrayUtils';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { RowSeparator } from '@app/components/Styles';
import withPlaceholder from '@app/components/withPlaceholder';
import ValidatedPayeeDetails from '@mortee/components/validatedPayee/ValidatedPayeeDetails';
import { ValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';
import ValidatedPayeeAccountManagementCard from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeAccountManagementCard';
import LinkButton from '@app/components/LinkButton';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';

interface DataProps {
  validatedPayeeWithAccounts: ValidatedPayeeWithAccounts;
  dataTestId: string;
}

interface DisplayProps {
  className?: string;
}

const MyValidatedPayeeWithAccountsCard: FC<DataProps & DisplayProps> = (props) => {
  const { validatedPayeeWithAccounts, dataTestId, className } = props;
  const { navigationStore, permissionsStore } = useInfraStores<MorteeMode>();

  const getPayeeAccounts = (): ReactNode => {
    return (
      <>
        <PaddedRowSeparator light />
        <CardHeader
          sideContent={
            permissionsStore.isStoreValidationsEnable && (
              <RightAlignedCardHeaderSideContent>
                <LinkButton
                  to={navigationStore.generateCreateValidatedPayeeAccountPage(validatedPayeeWithAccounts.payee.uniformId)}
                  id={`btn-validated-payee-${validatedPayeeWithAccounts.payee.uniformId}-add-account`}
                  appearance='text'
                >
                  ADD NEW ACCOUNT +
                </LinkButton>
              </RightAlignedCardHeaderSideContent>
            )
          }
        >
          VALIDATED BANK ACCOUNTS
        </CardHeader>
        {!!validatedPayeeWithAccounts.accounts.length && (
          <PayeeCardContent>
            <AccountsGridContainer data-testid='validated-account-main-card'>{getPayeeAccountsCards()}</AccountsGridContainer>
          </PayeeCardContent>
        )}
      </>
    );
  };

  const getPayeeAccountsCards = (): ReactNode => {
    const { accounts } = validatedPayeeWithAccounts;

    return accounts.map((account) => (
      <ValidatedPayeeAccountManagementCard
        key={account.uniformId}
        validatedPayeeAccount={{
          ...account,
          accountVisibility: account.visibility,
          contributingOrganizationName: account.contributingOrganizationName,
          validationTimestamp: account.lastValidationTimestamp,
          accountDetails: { ...account.accountDetails, localFormat: account.localFormat ?? null },
        }}
        dataTestId={`validated-account-card-${account.uniformId}`}
      />
    ));
  };

  return (
    <PayeeCard className={className} dataTestId={dataTestId}>
      <PayeeCardContent>
        <ValidatedPayeeDetails
          displayNotProvidedValues
          moreInfoHideable={false}
          showUniformId
          showVisibility
          data={{
            ...validatedPayeeWithAccounts.aggregatedPayeeData,
            sanctionsScreeningInfo: validatedPayeeWithAccounts.payee.sanctionsScreeningInfo,
            visibility: {
              value: validatedPayeeWithAccounts.payee.events[0].originatingAccountValidationVisibility,
              organizationId: validatedPayeeWithAccounts.payee.events[0].contributingOrganizationId,
              organizationName: validatedPayeeWithAccounts.payee.events[0].contributingOrganizationName,
            },
          }}
        />
      </PayeeCardContent>
      <LeftAlignedCardHeaderSideContent>
        <LinkButton
          to={navigationStore.generateEditValidatedPayeePage(validatedPayeeWithAccounts.payee.uniformId)}
          id={`btn-validated-payee-${validatedPayeeWithAccounts.payee.uniformId}-edit`}
          appearance='text'
        >
          EDIT PAYEE
        </LinkButton>
      </LeftAlignedCardHeaderSideContent>
      {getPayeeAccounts()}
    </PayeeCard>
  );
};

const Placeholder: FC<DisplayProps> = (props) => {
  const { className } = props;

  const getPayeeCardContent = (): ReactNode => {
    return (
      <PayeeCardContent>
        <ValidatedPayeeDetails loading />
      </PayeeCardContent>
    );
  };

  const getPayeeAccounts = (): ReactNode => {
    return (
      <>
        <PaddedRowSeparator light />
        <CardHeader>VALIDATED BANK ACCOUNTS</CardHeader>
        <PayeeCardContent>
          <AccountsGridContainer>{getPayeeAccountsCards()}</AccountsGridContainer>
        </PayeeCardContent>
      </>
    );
  };

  const getPayeeAccountsCards = (): ReactNode => {
    return createNTimes(3, (index): ReactNode => <MyValidatedPayeeAccountCard key={index} loading />);
  };

  return (
    <PayeeCard className={className}>
      <CardHeader>PAYEE</CardHeader>
      {getPayeeCardContent()}
      {getPayeeAccounts()}
    </PayeeCard>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(MyValidatedPayeeWithAccountsCard, Placeholder);

const PayeeCard = styled(Card)`
  --info-row-gap: 10px;
  --card-side-padding: 20px;

  padding: var(--card-side-padding) 0 0;
`;

const PayeeCardContent = styled.div`
  padding: 0 var(--card-side-padding) 20px;
`;

const AccountsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
  grid-auto-rows: 1fr;
  gap: 15px 20px;
`;

const PaddedRowSeparator = styled(RowSeparator)`
  margin: 0 var(--card-side-padding);
`;
