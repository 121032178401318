import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import TextHighlighter from '@app/components/TextHighlighter';
import { isDefined } from '@app/utils/utils';
import { arrayWithoutValue, distinctValues, getListWithLeading } from '@app/utils/arrayUtils';
import { equalsLEI, unifyLeis } from '@app/utils/legalIdentifierUtils';
import withPlaceholder from '../withPlaceholder';
import Nationality from '../Nationality';
import { SubtitleRegularStartTransparentBlack900 } from '@app/components/Text';
import DataGrid, { ArGridRowMultiline, DataGridRow } from '../DataGrid';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import {
  getFormattedLEI,
  LegalEntityIdentifier,
  removeStrongIds,
  StrongLegalIdentifies,
} from '@app/domain/legalEntityIdentifier';
import { ValidatedPayeePrimaryFields } from '@app/domain/validatedPayee';
import { useTranslation } from 'react-i18next';
import { compare } from '@app/utils/comparatorUtils';

interface DataProps {
  payee: ValidatedPayeeToDisplay;
  searchText?: string;
}

interface DisplayProps {
  hideMainName?: boolean;
  hideAddress?: boolean;
  hideCountry?: boolean;
  className?: string;
}

const VerificationValidatedPayeeDetails: FunctionComponent<DataProps & DisplayProps> = observer((props) => {
  const { t } = useTranslation();

  const renderTitle = (mainName: string | undefined, searchText: string | undefined): React.ReactElement => {
    return (
      <Title>
        <TextHighlighter searchText={searchText} fontWeight='Heavy'>
          {mainName}
        </TextHighlighter>
      </Title>
    );
  };

  const renderPayeeProperties = (
    hideAddress: boolean | undefined,
    hideCountry: boolean | undefined,
    searchText: string | undefined,
    otherNames: string[],
    countryCode: string | undefined,
    identifiers: LegalEntityIdentifier[] | undefined,
    addresses: string[] | undefined,
    strongIdsByLegalIdType: StrongLegalIdentifies | undefined,
    primaryFields?: ValidatedPayeePrimaryFields | null,
  ): ReactElement => {
    const strongIdsToDisplay = {
      KnoxId: getListWithLeading(strongIdsByLegalIdType?.KnoxId, primaryFields?.legalIdentifier, equalsLEI),
      DUNS: getListWithLeading(strongIdsByLegalIdType?.DUNS, primaryFields?.legalIdentifier, equalsLEI),
      TaxId: getListWithLeading(strongIdsByLegalIdType?.TaxId, primaryFields?.legalIdentifier, equalsLEI),
    };

    const strongIds = strongIdsByLegalIdType ? Object.values(strongIdsByLegalIdType).filter(isDefined).flat() : [];
    const primaryFieldWithoutStrong = primaryFields?.legalIdentifier
      ? removeStrongIds([primaryFields.legalIdentifier], strongIds)
      : [];

    const regularLeis = getListWithLeading(
      unifyLeis(removeStrongIds(identifiers, strongIds)),
      primaryFieldWithoutStrong?.[0],
      equalsLEI,
    );

    return (
      <DataGrid>
        {!!otherNames?.length && (
          <DataGridRow title={t('verifyAccount.alsoKnownAs', 'Also known as:')} valueDataTest='payee-details-other-names'>
            <TextHighlighter searchText={searchText}>{otherNames.join(', ')}</TextHighlighter>
          </DataGridRow>
        )}
        {!!countryCode && !hideCountry && (
          <DataGridRow title={t('verifyAccount.countryLabel', 'Country:')} valueDataTest='payee-details-country'>
            <Nationality countryCode={countryCode} />
          </DataGridRow>
        )}
        {!!addresses?.length && !hideAddress && (
          <ArGridRowMultiline title={t('verifyAccount.addresses', 'Addresses:')} valueDataTest='payee-details-address'>
            {getListWithLeading(addresses, primaryFields?.address).map((x) => {
              return <div key={x}>{x}</div>;
            })}
          </ArGridRowMultiline>
        )}
        {Object.keys(strongIdsToDisplay).length && (
          <ArGridRowMultiline title={t('verifyAccount.ids', 'IDs:')} valueDataTest='payee-details-strong-legal-ids'>
            {renderLegalIds(strongIdsToDisplay?.KnoxId, searchText, false)}
            {renderLegalIds(strongIdsToDisplay?.DUNS, searchText, false)}
            {renderLegalIds(strongIdsToDisplay?.TaxId, searchText, true)}
          </ArGridRowMultiline>
        )}
        {!!regularLeis?.length && (
          <ArGridRowMultiline title={t('verifyAccount.otherIds', 'Other IDs:')} valueDataTest='payee-details-regular-legal-ids'>
            {renderLegalIds(regularLeis, searchText, true)}
          </ArGridRowMultiline>
        )}
      </DataGrid>
    );
  };

  const renderLegalIds = (
    strongLegalIds: LegalEntityIdentifier[] | undefined,
    searchText?: string,
    displayCountryCode?: boolean,
  ): ReactNode => {
    return distinctValues(strongLegalIds?.map((value) => getFormattedLEI(value, displayCountryCode)))?.map((leiDetails) => {
      return (
        <div key={leiDetails}>
          <TextHighlighter searchText={searchText}>{leiDetails}</TextHighlighter>
        </div>
      );
    });
  };

  const { className, payee, hideMainName, hideAddress, hideCountry, searchText } = props;

  const { names, mainName, legalIdentifiers, addresses, countryCode, strongLegalIdentifies, primaryFields } = payee;

  const otherNames = mainName ? arrayWithoutValue<string>(names.slice().sort(compare.stringsCaseInsensitive()), mainName) : names;

  return (
    <div className={className}>
      {!hideMainName && renderTitle(mainName, searchText)}
      {renderPayeeProperties(
        hideAddress,
        hideCountry,
        searchText,
        otherNames,
        countryCode,
        legalIdentifiers,
        addresses,
        strongLegalIdentifies,
        primaryFields,
      )}
    </div>
  );
});

const LoadingGuestPayeeDetails: FunctionComponent<DisplayProps> = observer((props) => {
  const { t } = useTranslation();

  const renderTitle = (): React.ReactElement => {
    return (
      <Title>
        <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 180, margin: '0.2em' }} />
      </Title>
    );
  };

  const renderPayeeProperties = (hideAddress: boolean | undefined, hideCountry: boolean | undefined): ReactElement => {
    return (
      <DataGrid>
        <DataGridRow title={t('verifyAccount.alsoKnownAs', 'Also known as:')} valueDataTest='payee-details-other-names'>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
        </DataGridRow>
        {!hideCountry && (
          <DataGridRow title={t('verifyAccount.countryLabel', 'Country:')} valueDataTest='payee-details-country'>
            <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
          </DataGridRow>
        )}
        {!hideAddress && (
          <ArGridRowMultiline title={t('verifyAccount.addresses', 'Addresses:')} valueDataTest='payee-details-address'>
            <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
          </ArGridRowMultiline>
        )}
        <ArGridRowMultiline title={t('verifyAccount.ids', 'IDs:')} valueDataTest='payee-details-strong-legal-ids'>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '18px 0.2em 0' }} />
        </ArGridRowMultiline>
      </DataGrid>
    );
  };

  const { className, hideMainName, hideAddress, hideCountry } = props;

  return (
    <div className={className}>
      {!hideMainName && renderTitle()}
      {renderPayeeProperties(hideAddress, hideCountry)}
    </div>
  );
});

export default withPlaceholder<DisplayProps, DataProps>(VerificationValidatedPayeeDetails, LoadingGuestPayeeDetails);

const Title = styled(SubtitleRegularStartTransparentBlack900.div)`
  margin-inline-end: 24px;
  margin-bottom: 12px;
`;
