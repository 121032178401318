import { message } from 'antd';
import React, { FC, ReactElement, ReactNode } from 'react';
import showOldAlertMessage from './oldAlertMessage';
import { AlertProps } from 'antd/lib/alert';
import { ArgsProps, MessageType } from 'antd/lib/message';
import MessageSuccessIcon from '@app/images/message-success.svg';
import SVG from '@app/components/SVG';
import styled from '@emotion/styled';
import { SubtitleRegularStartTransparentBlack600 } from '../components/Text';
import { ModalButton } from '../components/Button';
import { CircularProgress } from '@material-ui/core';
import { nanoid } from 'nanoid';

type CloseableContentRenderProp = (closeFunc: () => void) => ReactNode;

const IconContainer = styled.div`
  margin-right: 16px;
`;

const defaultIconPerType: Partial<Record<ArgsProps['type'], ReactNode>> = {
  success: (
    <IconContainer>
      <SVG accessibilityLabel='success' image={MessageSuccessIcon} width={24} height={24} />
    </IconContainer>
  ),
  loading: (
    <IconContainer>
      <CircularProgress color='secondary' size={16} />
    </IconContainer>
  ),
};

interface MessageOptions extends Omit<ArgsProps, 'content' | 'duration'> {
  duration?: number | null;
  closeable?: boolean;
}

function getDefaultDuration(messageType: NonNullable<AlertProps['type']> | MessageOptions['type']): ArgsProps['duration'] {
  switch (messageType) {
    case 'error': {
      return null;
    }
    case 'success':
    case 'info':
    case 'warning':
    case 'loading': {
      return 5;
    }
  }
}

function calculateDuration(messageArgs: MessageOptions): number {
  if (messageArgs.duration !== undefined) {
    return messageArgs.duration ?? 0;
  }

  return getDefaultDuration(messageArgs.type) ?? 0;
}

export function shoot(
  messageArgs: MessageOptions,
  content: string | ReactElement | CloseableContentRenderProp,
  messageId: string = nanoid(),
): MessageType {
  const duration = calculateDuration(messageArgs);
  const closeFunc = message.open({
    key: messageId,
    icon: defaultIconPerType[messageArgs.type],
    ...messageArgs,
    duration,
    content: (
      <DefaultCloseableAlert
        id={messageId}
        type={messageArgs.type}
        duration={duration}
        closeable={messageArgs.closeable}
        content={content}
        closeFunc={(): void => closeFunc()}
      />
    ),
  });

  return closeFunc;
}

export function shootOld(
  type: NonNullable<AlertProps['type']>,
  content: ReactNode,
  title?: string,
  duration = getDefaultDuration(type),
  messageId: string = nanoid(),
): void {
  showOldAlertMessage(content, type, duration ?? undefined, title, messageId);
}

export function shootSuccessOld(content: ReactNode, duration?: number, messageId?: string): void {
  shootOld('success', content, undefined, duration, messageId);
}

export function shootErrorOld(content: ReactNode, title?: string, duration?: number, messageId?: string): void {
  shootOld('error', content, title, duration, messageId);
}

interface DefaultCloseableAlertProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'content'> {
  id: string;
  type: MessageOptions['type'];
  duration: number;
  closeable?: boolean;
  content: string | ReactElement | CloseableContentRenderProp;
  closeFunc(): void;
}

const DefaultCloseableAlert: FC<DefaultCloseableAlertProps> = ({ id, type, duration, content, closeable, closeFunc }) => {
  let renderedContent: ReactNode;

  if (typeof content === 'function') {
    renderedContent = content(() => closeFunc());
  } else {
    renderedContent = content;
  }

  const labelId = `alert-label-${id}`;

  // If it closes by itself, that means it does not need interaction, therefore it is an alert
  // In case it does need interaction, it is an alertdialog
  const role = duration ? 'alert' : 'alertdialog';

  return (
    <DefaultCloseableAlertContainer
      id={id}
      aria-labelledby={labelId}
      role={role}
      aria-live={type === 'error' || type === 'warning' ? 'assertive' : 'polite'}
      aria-atomic='true'
    >
      <span id={labelId}>{renderedContent}</span>
      {closeable && (
        <DefaultCloseableAlertCloseButton
          id='btn-popup-close'
          size='small'
          appearance='text'
          colorScheme='grey'
          aria-label='Close alert'
          onClick={closeFunc}
          autoFocus
        >
          X
        </DefaultCloseableAlertCloseButton>
      )}
    </DefaultCloseableAlertContainer>
  );
};

const DefaultCloseableAlertContainer = styled(SubtitleRegularStartTransparentBlack600.span)`
  margin-top: 1px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const DefaultCloseableAlertCloseButton = styled(ModalButton)`
  padding: 8px;
  margin-left: 40px;
`;
