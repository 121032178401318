import React, { FC } from 'react';
import styled from '@emotion/styled';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import { observer } from 'mobx-react';
import ClickOutsideDetector from '@app/components/popup/ClickOutsideDetector';
import PopupBase, { PopupPlacement, PopupPlacementOffset } from '@app/components/popup/PopupBase';

type PopupWidth = 'parent' | 'parent-or-bigger' | 'parent-or-smaller' | number;

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onPopupClosed: () => any;
  preventOutsideClick?: boolean;
  placement?: PopupPlacement;
  placementOffset?: PopupPlacementOffset;
  popupWidth?: PopupWidth;
  className?: string;
}

const Popup: FC<Props> = observer((props) => {
  const {
    open,
    placement = 'bottom',
    placementOffset,
    preventOutsideClick = true,
    anchorEl,
    onPopupClosed,
    popupWidth = 'parent-or-bigger',
    className,
    children,
  } = props;

  function handlePopupClosed(): void {
    onPopupClosed();
  }

  // check why fade does not wor
  return (
    <PopupBase
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      placementOffset={placementOffset}
      role={undefined}
      style={{ zIndex: 10001 }}
      className={className}
      transition
    >
      <ClickOutsideDetector preventOutsideClick={preventOutsideClick} onClickOutside={handlePopupClosed}>
        <StyledContentWrapper popupWidthType={popupWidth} parentPopupWidth={anchorEl?.offsetWidth}>
          {children}
        </StyledContentWrapper>
      </ClickOutsideDetector>
    </PopupBase>
  );
});

export default Popup;

const StyledContentWrapper = styled('div', {
  shouldForwardProp: preventForwardTheseProps('parentPopupWidth', 'popupWidthType'),
})<{
  parentPopupWidth: number | undefined;
  popupWidthType: PopupWidth;
}>`
  ${({ parentPopupWidth, popupWidthType }): string => {
    if (typeof popupWidthType === 'number') {
      return `
      width: ${popupWidthType}px; 
      `;
    }

    switch (popupWidthType) {
      case 'parent': {
        return `
        width: ${parentPopupWidth}px; 
        `;
      }
      case 'parent-or-smaller': {
        return `
        max-width: ${parentPopupWidth}px;
        `;
      }
      case 'parent-or-bigger':
        return `
        min-width: ${parentPopupWidth}px; 
        `;
    }
  }}
`;
