import React, { FC, ReactNode } from 'react';
import SVG, { SpriteSymbol } from '@app/components/SVG';
import Dots from '@app/images/three-vertical-dots.svg';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import { SubtitleSmallStartTransparentBlack900 } from '@app/components/Text';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import { useTranslation } from 'react-i18next';
import ButtonThatOpensPopup, { ButtonThatOpensPopupProps } from '@app/components/popup/ButtonThatOpensPopup';
import Card from '@app/components/card/Card';

export interface MenuItem {
  id: string;
  dataTestId?: string;
  text?: string;
  icon?: SpriteSymbol;
  disabled?: boolean;
  onClick: (...args: any[]) => any;
  tooltipText?: string;
}

interface Props extends Omit<ButtonThatOpensPopupProps, 'popupContent' | 'popupKey'> {
  menuItems: MenuItem[];
  customItemListRenderer?: (item: MenuItem) => ReactNode;
}

const Menu: FC<Props> = (props) => {
  const { t } = useTranslation();

  const createListItems = (index: number, item: MenuItem, onDone: () => void): React.ReactNode => {
    if (customItemListRenderer) {
      return customItemListRenderer(item);
    }

    const doFunctionAndCloseMenu = (event: React.MouseEvent<EventTarget>): void => {
      event.preventDefault();
      event.stopPropagation();
      item.onClick();
      onDone();
    };

    return (
      <ConditionalTooltip key={item.id} showTooltip={!!item.tooltipText} title={item.tooltipText} placement='left'>
        <ListItemButton
          id={item.id}
          dataTestId={item.dataTestId}
          disabled={item.disabled}
          appearance='text'
          cornerType='none'
          colorScheme='grey'
          onClick={doFunctionAndCloseMenu}
        >
          {item.icon && <StyledSVG accessibilityLabel='' image={item.icon} width={23} height={23} />}
          {item.text}
        </ListItemButton>
      </ConditionalTooltip>
    );
  };

  const {
    id,
    dataTestId = id,
    menuItems,
    popupPlacement = 'bottom-start',
    customItemListRenderer,
    ...buttonThatOpensPopupProps
  } = props;

  return (
    <StyledButtonThatOpensPopup
      id={id}
      dataTestId={dataTestId}
      appearance='text'
      cornerType='circle'
      colorScheme='grey'
      shadowType='none'
      popupPlacement={popupPlacement}
      popupContent={(onDone): ReactNode => (
        <PopupCard shadowType='elevated'>{menuItems.map((item, index) => createListItems(index, item, onDone))}</PopupCard>
      )}
      children={<SVG accessibilityLabel={t('general.accessibility.threeDotsMenu')} image={Dots} width={4} height={21} />}
      {...buttonThatOpensPopupProps}
    />
  );
};

export default Menu;

const StyledButtonThatOpensPopup = styled(ButtonThatOpensPopup)`
  margin-right: 2px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupCard = styled(Card)`
  padding: 0;
`;

const ListItemButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${SubtitleSmallStartTransparentBlack900.css}
  width: 100%;
`;

const StyledSVG = styled(SVG)`
  margin-right: 10px;
`;
