import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { valueOrValuesOrNullAsArray } from '@app/utils/arrayUtils';
import { isTruthy } from '@app/utils/utils';
import browserHistory from '@app/utils/browserHistory';

type UpdateQueryParamFunction = (value: string | string[] | null | undefined) => void;

type ValueTypePredicate<T extends string = string> = (value) => value is T;

export function useQueryParam<T extends string = string>(
  name: string,
  filterIllegalValues: ValueTypePredicate<T> | RegExp,
): [values: T[] | null, updateValues: UpdateQueryParamFunction, allValuesValid: boolean] {
  const location = useLocation();
  const search = qs.parse(location?.search);
  const value = search[name];
  const cleanedValues = cleanSearchValues<T>(value, filterIllegalValues);

  function updateQueryParam(newValue): void {
    const newSearchValues = { [name]: newValue ?? null };
    const newSearch = { ...search, ...newSearchValues };
    browserHistory.push({ ...location, search: qs.stringify(newSearch, { skipNull: true }) });
  }

  return [cleanedValues.values, updateQueryParam, cleanedValues.isValuesValid];
}

function cleanSearchValues<T extends string>(
  value: (string | null)[] | string | null,
  filterIllegalValues: ((item: string) => boolean) | RegExp,
): { values: T[] | null; isValuesValid: boolean } {
  if (!value) {
    return { values: null, isValuesValid: true };
  }

  const nonEmptyValues = valueOrValuesOrNullAsArray(value).filter(isTruthy);

  const validValues = nonEmptyValues.filter((singleValue): singleValue is T => {
    if (typeof filterIllegalValues === 'function') {
      return filterIllegalValues(singleValue);
    }

    return !!singleValue.match(filterIllegalValues);
  });

  return { values: validValues, isValuesValid: nonEmptyValues.length === validValues.length };
}
