import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { ColumnProps } from 'antd/lib/table';
import Status from '@mortee/components/Status';
import DownloadFilesSvg from '@mortee/components/DownloadFilesSvg';
import { showInfoModal } from '@app/components/Modal';
import ModalFileList from '@app/components/fileUpload/ModalFileList';
import CardTable from '@app/components/tables/CardTable';
import { ModalFunc } from 'antd/lib/modal/Modal';
import { TableFormattedDate, TableSmallText } from '@app/components/tables/Table';
import Loadable, { LoadingState } from '@app/utils/Loadable';
import { SecureFiles } from '@mortee/domain/secureFiles';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';

interface SecureFilesTableProps {
  secureFiles: Loadable<SecureFiles[]>;
  emptyComponent: ReactElement;
  fetchSecureFile(secureFileId: string, fileId: string): Promise<NamedResource>;
}

const SecureFilesTable: FunctionComponent<SecureFilesTableProps> = observer((props) => {
  const createColumnsDefinition = (): ColumnProps<any>[] => {
    return [
      {
        title: <div data-testid='lblSecureFilesDate'>Date</div>,
        dataIndex: 'writeTimestamp',
        align: 'left',
        width: '150px',
        key: 'writeTimestamp',
        render: (text: any, record: SecureFiles): React.ReactNode => (
          <TableFormattedDate options={formattedDateOptionsWithHoursAndMinutes} value={record.writeTimestamp} />
        ),
      },
      {
        title: <div data-testid='lblSecureFilesUser'>User</div>,
        dataIndex: 'user',
        width: '150px',
        key: 'user',
        render: (text: any, record: SecureFiles): React.ReactNode => <TableSmallText>{record.performingUserName}</TableSmallText>,
      },
      {
        title: <div data-testid='lblSecureFilesNotes'>Notes</div>,
        dataIndex: 'note',
        key: 'note',
        render: (text: any, record: SecureFiles): React.ReactNode => (
          <TableSmallText>{record.note?.trim() ? record.note : `Note wasn't provided`}</TableSmallText>
        ),
      },
      {
        title: <div data-testid='lblSecureFiles'>Files</div>,
        dataIndex: 'files',
        width: '80px',
        align: 'center',
        key: 'files',
        render: (text: any, record: SecureFiles): React.ReactNode => {
          const fetchFile = (fileId: string): Promise<NamedResource> => {
            return fetchSecureFile(record.uniformId, fileId);
          };

          return (
            <DownloadFilesSvg
              accessibilityLabel='files list'
              id='btn-secure-files-list-modal-open'
              fileNum={record.secureFiles.length}
              onClick={(): ReturnType<ModalFunc> =>
                showInfoModal(
                  <ModalFileList title='Download Files' files={record.secureFiles.map((file) => [file, fetchFile])} />,
                  { okText: 'CANCEL' },
                )
              }
            />
          );
        },
      },
      {
        title: <div data-testid='lblSecureFilesStatus'>Status</div>,
        dataIndex: 'status',
        width: '100px',
        align: 'center',
        key: 'status',
        render: (): React.ReactNode => <Status status='FilesReceived' mode='color' fontSize={13} isAuditStatus />,
      },
    ];
  };

  const { secureFiles, fetchSecureFile, emptyComponent } = props;

  const columns = createColumnsDefinition();

  return (
    <CardTable
      data-testid='secureFilesTable'
      columns={columns}
      dataSource={secureFiles.result}
      loading={secureFiles.loadState === LoadingState.InProgress}
      pagination={false}
      rowKey={(secureFile: SecureFiles): string => secureFile.uniformId}
      isLoaded={secureFiles.isResolved()}
      emptyComponent={emptyComponent}
    />
  );
});

export default SecureFilesTable;
