import React, { ReactElement, ReactNode } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import { Checkbox, CircularProgress } from '@material-ui/core';
import {
  BooleanFilterOptions,
  getColumnBooleanFilterProps,
} from '@app/components/tableFilters/singleValue/byBooleanFieldFilterCreator';
import Loadable from '@app/utils/Loadable';
import styled from '@emotion/styled';
import TableOfCards from '@app/components/tables/TableOfCards';
import { compare } from '@app/utils/comparatorUtils';

export interface Item {
  id: string;
}

interface Props<T extends Item> {
  id: string;
  dataTestId?: string;
  contentColumnTitle: string;
  readonly?: boolean;
  allItems: Loadable<T[]>;
  activeIds: string[];
  changingIds: string[];
  itemComparator?: Comparator<T>;
  itemKeywordsGetter?: (item: T) => number | number[] | string | string[] | null | undefined;
  className?: string;
  itemRenderer(item: T): ReactNode;
  onAdd(id: string): Promise<void>;
  onRemove(id: string): Promise<void>;
  disableItem?: (id: string) => boolean;
}

interface TableRow<T> {
  item: T;
  isActive: {
    disabled: boolean;
    loading: boolean;
    value: boolean;
  };
}

export default function CheckboxCardsList<T extends Item>({
  id,
  dataTestId,
  readonly,
  contentColumnTitle,
  allItems,
  activeIds,
  changingIds,
  itemComparator,
  itemRenderer,
  itemKeywordsGetter,
  onAdd,
  onRemove,
  className,
  disableItem,
}: Props<T>): ReactElement {
  function buildTableRows(
    allItems: T[],
    activeIds: string[],
    changingIds: string[],
    readonly: boolean | undefined,
    isRowDisabledFunc: ((id: string) => boolean) | undefined,
  ): TableRow<T>[] {
    return allItems.map((item) => buildTableRow(item, activeIds, changingIds, readonly, isRowDisabledFunc));
  }

  function buildTableRow(
    item: T,
    activeIds: string[],
    changingIds: string[],
    readonly: boolean | undefined,
    isRowDisabledFunc: ((id: string) => boolean) | undefined,
  ): TableRow<T> {
    const isCurrentItemLoading = changingIds.includes(item.id);
    const isCurrentItemDisabled = isRowDisabledFunc?.(item.id) ?? false;

    return {
      item,
      isActive: {
        value: activeIds.includes(item.id),
        loading: isCurrentItemLoading,
        disabled: readonly || (!isCurrentItemLoading && !!changingIds.length) || isCurrentItemDisabled,
      },
    };
  }

  async function onItemActiveChanged(id: string, newValue: boolean): Promise<void> {
    if (newValue) {
      await onAdd(id);
    } else {
      await onRemove(id);
    }
  }

  const tableRows = buildTableRows(allItems.result ?? [], activeIds, changingIds, readonly, disableItem);

  return (
    <TableOfCards
      id={id}
      dataTestId={dataTestId}
      columns={createColumns(
        tableRows,
        contentColumnTitle,
        itemComparator,
        itemRenderer,
        itemKeywordsGetter,
        onItemActiveChanged,
      )}
      dataSource={tableRows}
      className={className}
      pagination={false}
      loading={!allItems.isResolved()}
      rowKey={(row: TableRow<T>): string => row.item.id}
    />
  );
}

function createColumns<T extends Item>(
  data: TableRow<T>[],
  contentColumnTitle: string,
  itemComparator: Comparator<T> | undefined,
  itemRenderer: (item: T) => React.ReactNode,
  itemKeywordsGetter: ((item: T) => number | number[] | string | string[] | null | undefined) | undefined,
  onItemActiveChanged: (id: string, newValue: boolean) => Promise<void>,
): ColumnProps<TableRow<T>>[] {
  return [
    {
      title: <div data-testid='lblActive'>Active</div>,
      dataIndex: 'active',
      key: 'active',
      width: '120px',
      sorter: compare.byField((org) => org.isActive.value, compare.booleans()),
      render: (text: any, record: TableRow<T>): React.ReactNode => (
        <CheckboxContainer>
          {record.isActive.loading ? (
            <CircularProgress color='primary' size={20} data-testid='loader-cbox-item-active' />
          ) : (
            <Checkbox
              id='cbox-item-active'
              data-testid='cbox-item-active'
              checked={record.isActive.value}
              disabled={record.isActive.disabled}
              onChange={(e): void => {
                onItemActiveChanged(record.item.id, e.currentTarget.checked);
              }}
              color='primary'
            />
          )}
        </CheckboxContainer>
      ),
      ...getColumnBooleanFilterProps<TableRow<T>>(
        'Is Active',
        (record) => record.isActive.value,
        (booleanFilterOptions: BooleanFilterOptions): string => {
          switch (booleanFilterOptions) {
            case BooleanFilterOptions.all:
              return 'All';
            case BooleanFilterOptions.onlyTrue:
              return 'Active';
            case BooleanFilterOptions.onlyFalse:
              return 'Not Active';
          }
        },
      ),
    },
    {
      title: <div data-testid='lblContent'>{contentColumnTitle}</div>,
      dataIndex: 'content',
      key: 'content',
      defaultSortOrder: 'ascend',
      sorter: (record1, record2): number => {
        return itemComparator?.(record1.item, record2.item) ?? record1.item.id.localeCompare(record2.item.id);
      },
      render: (text: any, record: TableRow<T>): React.ReactNode => itemRenderer(record.item),
      ...getColumnTextSearchProps<TableRow<T>>((record) => {
        return itemKeywordsGetter?.(record.item) ?? record.item.id;
      }),
    },
  ];
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
