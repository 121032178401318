import React, { FC } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import ArMode from '@ar/arMode';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import useInfraStores from '@app/hooks/useInfraStores';
import { Bold16Primary300, Bold18TransparentBlack900, Regular13TransparentBlack900 } from '@app/components/Text';
import ARAboveMainCardTitle from './ARAboveMainCardTitle';
import CertificateIcon from '@app/images/certificate-big.svg';
import SearchIcon from '@app/images/ic-search-data.svg';
import SVG from '@app/components/SVG';
import ArrowBullet from '@app/components/ArrowBullet';
import LinkButton from '@app/components/LinkButton';
import useMountEffect from '@app/hooks/useMountEffect';
import { useTranslation } from 'react-i18next';
import { ARPageProps } from '@ar/routes/withARConfiguration';
import { ARConfiguration } from '@ar/domain/arGeneral';

interface Props extends ARPageProps {}

const GuestVerificationPage: FC<Props> = observer(({ configuration }) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verify);
  const { navigationStore } = useInfraStores<ArMode>();
  const { arVerificationStore } = useAppStores<ArMode>();
  const { t } = useTranslation();

  useMountEffect(() => {
    // initial cleanup
    arVerificationStore.resetAll();
  });

  // todo banks poc hack
  function isSebOrg(configuration: ARConfiguration | null): boolean {
    return configuration?.clientName?.toLowerCase() === 'seb';
  }

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <ARAboveMainCardTitle title={t('ar.guestVerification.bankAccountVerification')} />
      <MainCard>
        <MainCardInnerTitle>{t('ar.guestVerification.selectOption')}</MainCardInnerTitle>
        <OptionButton appearance='text' id='guest-verify-choose-certificate' to={navigationStore.generateChooseBacPage()}>
          <ButtonData>
            <StyledIcon accessibilityLabel='' image={CertificateIcon} />
            <TextContainer>
              <MainText>
                {isSebOrg(configuration)
                  ? t('ar.guestVerification.iHaveCertificateSEB')
                  : configuration?.clientName
                  ? t('ar.guestVerification.iHaveCertificateWithClient', { clientName: configuration?.clientName })
                  : t('ar.guestVerification.iHaveCertificate')}
              </MainText>
              <SecondaryText>{t('ar.guestVerification.clickToCertificate')}</SecondaryText>
            </TextContainer>
          </ButtonData>
          <StyledArrowBullet />
        </OptionButton>
        <OptionButton appearance='text' id='guest-verify-choose-search' to={navigationStore.generateChooseSearchPage()}>
          <ButtonData>
            <StyledIcon accessibilityLabel='' image={SearchIcon} />
            <TextContainer>
              <MainText>
                {isSebOrg(configuration)
                  ? t('ar.guestVerification.iHaveDetailsSEB')
                  : configuration?.clientName
                  ? t('ar.guestVerification.iHaveDetailsWithClient', { clientName: configuration?.clientName })
                  : t('ar.guestVerification.iHaveDetails')}
              </MainText>
              <SecondaryText>{t('ar.guestVerification.clickToDetails')}</SecondaryText>
            </TextContainer>
          </ButtonData>
          <StyledArrowBullet />
        </OptionButton>
      </MainCard>
    </PageWithHeader>
  );
});

export default GuestVerificationPage;

const MainCard = styled(Card)`
  padding: 30px 25px 31px;
`;

const MainCardInnerTitle = styled(Bold16Primary300.div)`
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

const OptionButton = styled(LinkButton)`
  box-sizing: border-box;
  background-color: #f5f6fa;
  border: 1px #d2d2d2 solid;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 19px;
  justify-content: space-between;
  width: 100%;
`;

const MainText = styled(Bold18TransparentBlack900.div)`
  text-align: left;
  margin-top: 15px;
`;

const SecondaryText = styled(Regular13TransparentBlack900.div)`
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledIcon = styled(SVG)`
  width: 34px;
  height: 41px;
  align-self: center;
  margin-inline-start: 15px;
  margin-inline-end: 30px;
`;

const StyledArrowBullet = styled(ArrowBullet)`
  min-height: 22px;
  max-height: 22px;
  min-width: 14px;
  max-width: 14px;
  align-self: center;
`;

const ButtonData = styled.div`
  display: flex;
  flex-direction: row;
`;
