import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import NationalityFlag from '@app/components/NationalityFlag';
import { showCustomModal } from '@app/components/Modal';
import ValidatedPayeeModal from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/modals/ValidatedPayeeModal';
import { ValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import ModalAppContext, { ModalAppContextProps } from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

interface Props {
  validatedPayee: AggregatedValidatedPayee;
  validatedPayeeAccounts: ValidatedPayeeAccount[];
  className?: string;
}

const ValidatedPayeeChip: FC<Props> = ({ validatedPayee, validatedPayeeAccounts, className }) => {
  const { mainName, countryCode, uniformId } = validatedPayee;

  const modalContext = useModalContext();

  return (
    <ValidatedPayeeChipButton
      className={className}
      key={uniformId}
      id={uniformId}
      colorScheme='grey'
      appearance='text'
      cornerType='none'
      onClick={(): void => showValidatedPayeeModal(validatedPayee, validatedPayeeAccounts, modalContext)}
    >
      <NationalityFlag countryCode={countryCode} size={16} />
      <ValidatedPayeeName>{mainName}</ValidatedPayeeName>
    </ValidatedPayeeChipButton>
  );
};

export default ValidatedPayeeChip;

const showValidatedPayeeModal = (
  validatedPayee: AggregatedValidatedPayee,
  validatedPayeeAccounts: ValidatedPayeeAccount[],
  modalContext: ModalAppContextProps,
): void => {
  showCustomModal(
    (onDone): ReactElement => (
      <ModalAppContext {...modalContext}>
        <ValidatedPayeeModal validatedPayee={validatedPayee} validatedPayeeAccounts={validatedPayeeAccounts} onDone={onDone} />
      </ModalAppContext>
    ),
  );
};

const ValidatedPayeeChipButton = styled(Button)`
  height: 24px;
  border-radius: 12px;
  padding: 0 12px 0 4px;
  margin: 8px;
  background-color: var(--primary-200-50-a);

  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--primary-500);

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ValidatedPayeeName = styled.span`
  margin-left: 8px;
  max-width: 300px;
  text-overflow: ellipsis;

  white-space: nowrap;
  overflow: hidden;
`;
