import request, { HttpMethod, HttpStatus, RequestError } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { removeIrrelevantAccountDetailsFields } from '@app/domain/accountsDetailsHelpers';
import { removeEmptyFields } from '@app/utils/utils';
import { translateFileNameToContentType } from '@app/domain/files';
import {
  morteeTransformVerifyPayeeAccount,
  MorteeVerificationResult,
  MorteeVerificationServerResponse,
  SendValidationEmailToCustomerRequest,
  SendValidationEmailToPayeeRequest,
  ValidationEmailToCustomerValuesServerResponse,
  VerificationResultType,
} from '@mortee/domain/morteeAccountVerification';
import { AccountVerificationBankAccountCertificateVerifyResultServerResponse } from '@app/domain/bac';
import { UnifiedPayeeIds } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import { pluralize } from '@app/utils/stringUtils';
import { WebError } from '@app/libs/request/httpConsts';

export async function downloadAccountVerificationByUniformId(
  payee: UnifiedPayeeIds,
  accountDetails: Partial<MorteeAccountDetails>,
): Promise<NamedResource> {
  return request<NamedResource>(serverUrl, `/api/verifications/accounts/uniform-ids/download`, {
    method: HttpMethod.get,
    params: {
      ...payee,
      ...accountDetails,
    },
    errorsHandler: {
      default: {
        message: 'Cannot download verification report.',
      },
    },
  });
}

function wrongOrganizationErrorMessageCreator(additionalData: WebError['WRONG_ORGANIZATION_ERROR']): string {
  const orgAmount = additionalData?.differentOrganizationIds?.length ?? 0;

  return `Payee has other ${pluralize('organization', orgAmount)} with private information - \
organization ${pluralize('id', orgAmount)}: ${additionalData?.differentOrganizationIds?.join(', ')}.`;
}

export async function getValidationEmailToPayeeValues(
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
): Promise<string[] | null> {
  return request<string[] | null>(
    serverUrl,
    `/api/validations/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId/email/payee/values`,
    {
      method: HttpMethod.get,
      pathParams: {
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
      },
      errorsHandler: {
        WRONG_ORGANIZATION_ERROR: {
          message: wrongOrganizationErrorMessageCreator,
        },
        default: {
          message: 'Cannot calculate values for payee email.',
        },
      },
    },
  );
}

export async function sendValidationEmailToPayee(
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
  data: SendValidationEmailToPayeeRequest,
): Promise<void> {
  return request<void>(
    serverUrl,
    `/api/validations/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId/email/payee/send`,
    {
      data,
      method: HttpMethod.post,
      pathParams: {
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
      },
      errorsHandler: {
        WELCOME_LETTER_CREATION_ERROR: {
          message: (): string => `Error occurred while trying to create welcome letter.`,
        },
        BLANK_PAYEE_NAME_ERROR: {
          message: (): string => 'Cannot send email because the payee has no name to display.',
        },
        WRONG_ORGANIZATION_ERROR: {
          message: wrongOrganizationErrorMessageCreator,
        },
        default: {
          message: 'Unexpected error sending email for payee email.',
        },
      },
    },
  );
}

export async function getValidationEmailToCustomerValues(
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
): Promise<ValidationEmailToCustomerValuesServerResponse> {
  return request<ValidationEmailToCustomerValuesServerResponse>(
    serverUrl,
    `/api/validations/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId/email/referring-customer/values`,
    {
      method: HttpMethod.get,
      pathParams: {
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
      },
      errorsHandler: {
        WRONG_ORGANIZATION_ERROR: {
          message: wrongOrganizationErrorMessageCreator,
        },
        default: {
          message: 'Cannot calculate values for customer email.',
        },
      },
    },
  );
}

export async function sendValidationEmailToCustomer(
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
  data: SendValidationEmailToCustomerRequest,
): Promise<void> {
  return request<void>(
    serverUrl,
    `/api/validations/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId/email/referring-customer/send`,
    {
      data,
      method: HttpMethod.post,
      pathParams: {
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
      },
      errorsHandler: {
        BLANK_PAYEE_NAME_ERROR: {
          message: (): string => 'Cannot send email because the payee has no name to display.',
        },
        NULL_KNOX_ID_ERROR: {
          message: (): string => 'Cannot send email because the payee has no KnoxID to display.',
        },
        WRONG_ORGANIZATION_ERROR: {
          message: wrongOrganizationErrorMessageCreator,
        },
        default: {
          message: 'Unexpected error sending email for customer email.',
        },
      },
    },
  );
}

export async function downloadVerificationWelcomeLetterByUniformId(
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
): Promise<NamedResource> {
  return request<NamedResource>(
    serverUrl,
    `/api/validations/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId/certificate`,
    {
      method: HttpMethod.get,
      pathParams: {
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
      },
      errorsHandler: {
        WELCOME_LETTER_CREATION_ERROR: {
          message: (): string => `Error occurred while trying to create welcome letter.`,
        },
        default: {
          message: 'Cannot download verification certificate.',
        },
      },
    },
  );
}

export async function verifyAccountByUniformId(
  payee: UnifiedPayeeIds,
  accountDetails: Partial<MorteeAccountDetailsExtended>,
): Promise<MorteeVerificationResult> {
  const queryParameters = removeEmptyFields({ ...payee, ...removeIrrelevantAccountDetailsFields(accountDetails) });

  try {
    const response = await request<MorteeVerificationServerResponse>(serverUrl, `/api/verifications/accounts/uniform-ids`, {
      method: HttpMethod.get,
      params: queryParameters,
      errorsHandler: {
        default: {
          message: 'Could not verify.',
        },
      },
    });

    return morteeTransformVerifyPayeeAccount(response);
  } catch (e: unknown) {
    const requestError = e as RequestError;

    if (requestError.code === HttpStatus.notFound) {
      return { result: VerificationResultType.notValidated };
    }

    throw e;
  }
}

export async function verifyAccountByBankAccountCertificate(
  file: File,
): Promise<AccountVerificationBankAccountCertificateVerifyResultServerResponse> {
  const sendForm = new FormData();

  sendForm.append('bac', new Blob([file], { type: translateFileNameToContentType(file.name) }), file.name);

  return request<AccountVerificationBankAccountCertificateVerifyResultServerResponse>(serverUrl, `/api/bac/verify`, {
    method: HttpMethod.post,
    isFileUpload: true,
    data: sendForm,
    timeout: 300000,
    suppressNotification: true,
    errorsHandler: {
      default: {
        message: 'Could not validate with certificate.',
      },
    },
  });
}
