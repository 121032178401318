import { CountryCode } from '@app/domain/countries';

export enum LoginErrorCodes {
  ForgotPasswordInvalidCode = 28,
  ForgotPasswordCodeAlreadyVerified = 29,
  ForgotPasswordFlowExpired = 30,
  PasswordNotByPolicy = 31,
  InvalidCredentials = 32,
  PhoneAuthFlowExpired = 33,
  ForgotPasswordInvalidFlowId = 34,
}

export interface LoginErrorBody {
  errorCode: LoginErrorCodes;
  message: string;
  info?: { violations?: string[] };
}

export enum LoginMode {
  Local = 'Local',
  SavedCredentials = 'SavedCredentials',
}

export enum AuthMethodType {
  EmailPassword = 'EmailPassword',
  PhoneNumber = 'PhoneNumber',
  GoogleSSO = 'GoogleSSO',
  SamlSSO = 'SamlSSO',
  OidcSSO = 'OidcSSO',
}

export enum AuthCodeDeliveryMethod {
  TextMessage = 'TextMessage',
  PhoneCall = 'PhoneCall',
}

export const ssoAuthMethods: AuthMethodType[] = [AuthMethodType.GoogleSSO, AuthMethodType.SamlSSO, AuthMethodType.OidcSSO];
export const emailAuthMethods: AuthMethodType[] = [AuthMethodType.EmailPassword, ...ssoAuthMethods];
export const authMethodTypeOrder = Object.values(AuthMethodType);

export interface KnoxerAuthConfigData {
  id: string;
  authProperties: KnoxerAuthProperties[];
  mandatory?: boolean;
}

export interface KnoxerAuthProperties {
  authMethod: AuthMethodType;
  authUrlEmail?: string;
  authUrlPhoneSms?: string;
  authUrlPhoneCall?: string;
  claimUrl: string;
  userPasswordSelfManagementServiceUrl?: string;
  authAdditionalHeaders?: { [headerName: string]: string };
  claimAdditionalHeaders?: { [headerName: string]: string };
}

export interface AuthMethodDataBase<TMethodUserIdentification> {
  type: AuthMethodType;
  knoxersOfMethod: string[];
  userIdentification?: TMethodUserIdentification;
}

interface UnclaimedKnoxerBase<TMethodData extends AuthMethodData, TKnoxerAuthData = undefined> {
  knoxer: {
    id: string;
    mandatory: boolean;
    authData?: TKnoxerAuthData;
  };
  authMethodData: TMethodData;
  positionInMethodGroup: number;
  positionInAllKnoxers: number;
}

// region User password login interfaces
export type UserPasswordUserIdentification = {
  username: string;
};

export type UserPasswordAuthData = AuthMethodDataBase<UserPasswordUserIdentification>;

export type UnclaimedUserPasswordKnoxer = UnclaimedKnoxerBase<UserPasswordAuthData>;
// endregion

// region Phone login interfaces
export interface KnoxerSpecificPhoneAuthData {
  flowId: string;
  codeLength: number;
  deliveryMethod: AuthCodeDeliveryMethod;
}

export enum PhoneLoginState {
  EnterPhone,
  EnterCode,
}

export type PhoneNumberUserIdentification = {
  dialCode: string;
  phoneNumber: string;
  countryCode: CountryCode;
  state?: PhoneLoginState;
};

export type PhoneNumberAuthData = AuthMethodDataBase<PhoneNumberUserIdentification>;

export type UnclaimedPhoneNumberKnoxer = UnclaimedKnoxerBase<PhoneNumberAuthData, KnoxerSpecificPhoneAuthData>;
// endregion

export type MethodUserIdentification = UserPasswordUserIdentification | PhoneNumberUserIdentification;
export type AuthMethodData = UserPasswordAuthData | PhoneNumberAuthData;
export type UnclaimedKnoxer = UnclaimedUserPasswordKnoxer | UnclaimedPhoneNumberKnoxer;

export interface InitiatePhoneLoginResponse {
  flowId: string;
  codeTTLMinutes: number;
  codeLength: number;
}

// Keep it as an object for backwards compatability
export type PersistedKnoxerAuthData = {
  authType: AuthMethodType;
};
