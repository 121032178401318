import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BlockDescriptor, InfoCategory, InfoContent, StyledNationality } from '@app/components/DisplayFields';
import { getCountryByCountryCode } from '@app/domain/countries';
import { getRepresentationFormatterWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { calculateValidatedSwiftCodeToDisplay } from '@app/domain/accountsDetailsHelpers';
import { runInAction } from 'mobx';
import { MorteeVerificationResult } from '@mortee/domain/morteeAccountVerification';
import { AccountAttachments } from '@app/components/accountVerification/AccountAttachments';
import { AttachmentSource, downloadAccountAttachment } from '@app/services/attachmentServices';
import useInfraStores from '@app/hooks/useInfraStores';
import { formattedDateOptions } from '@app/utils/timeUtils';

interface PayeeAccountDetailsProps {
  verificationDetails?: MorteeVerificationResult;
  accountDetails: Partial<MorteeAccountDetails>;
  children?: ReactNode;
  className?: string;
}

const PayeeAccountDetails: FC<PayeeAccountDetailsProps> = (props) => {
  const { localeStore } = useInfraStores();

  const renderAccounts = (
    validationDetails: MorteeVerificationResult,
    accountDetails: Partial<MorteeAccountDetails>,
  ): ReactElement => {
    const { verificationAccountDetails, data, presentationAccountNumber } = validationDetails;

    if (!verificationAccountDetails) {
      return <AccountDetailsContainer />;
    }

    runInAction(
      () =>
        (verificationAccountDetails.swiftCode = calculateValidatedSwiftCodeToDisplay(
          verificationAccountDetails.swiftCode,
          accountDetails.swiftCode,
        )),
    );

    const { accountNumber, bankCode, iban, swiftCode, localFormat } = verificationAccountDetails;

    // presentationAccountNumber should get priority over accountNumber in case it exists
    verificationAccountDetails.accountNumber = presentationAccountNumber || accountNumber;

    return (
      <AccountDetailsContainer>
        {swiftCode &&
          accountNumber &&
          renderAccountDetails(getRepresentationFormatterWithSwiftban('swift')(verificationAccountDetails), 'Swift')}
        {iban && renderAccountDetails(getRepresentationFormatterWithSwiftban('iban')(verificationAccountDetails), 'IBAN')}
        {accountNumber &&
          bankCode &&
          renderAccountDetails(getRepresentationFormatterWithSwiftban('domestic')(verificationAccountDetails), 'Domestic')}
        {localFormat && renderAccountDetails(localFormat, 'CLABE')}
        {data?.furtherCredit && renderAccountDetails(data.furtherCredit, 'For further credit')}
      </AccountDetailsContainer>
    );
  };

  const renderAccountDetails = (formattedAccount: string, prefix: string): ReactElement => {
    return (
      <InfoLine>
        <InfoCategory>{prefix}:</InfoCategory>
        <InfoContent data-testid={`account-details-${prefix}`}>{formattedAccount}</InfoContent>
      </InfoLine>
    );
  };

  const renderBankName = (bankName: string | null | undefined): ReactNode => {
    if (!bankName) {
      return null;
    }

    return (
      <InfoLine>
        <InfoCategory>Bank name: </InfoCategory>
        <InfoContent>{bankName}</InfoContent>
      </InfoLine>
    );
  };

  const renderValidationDate = (validationTimestamp: number | null | undefined): ReactNode => {
    if (!validationTimestamp) {
      return null;
    }

    return (
      <InfoLine>
        <InfoCategory>Validation date: </InfoCategory>
        <InfoContent>{localeStore.formatDate(validationTimestamp, formattedDateOptions)}</InfoContent>
      </InfoLine>
    );
  };

  const { verificationDetails, accountDetails, className } = props;

  if (!accountDetails || !verificationDetails) {
    return null;
  }

  const bankName = verificationDetails?.data?.bankName;
  const accountCountryCode =
    verificationDetails?.data?.bankCountryCode ??
    verificationDetails?.verificationAccountDetails?.countryCode ??
    accountDetails.countryCode;
  const verificationTimestamp = verificationDetails.verificationTimestamp;

  return (
    <MainContainer data-testid='payee-account-details-container' className={className}>
      <BlockDescriptor>Bank Account</BlockDescriptor>
      <SectionContentContainer>
        <SectionContentLeft>
          {renderBankName(bankName)}
          {renderAccounts(
            {
              ...verificationDetails,
              verificationAccountDetails: verificationDetails.verificationAccountDetails ?? accountDetails,
            },
            accountDetails,
          )}
          {renderValidationDate(verificationTimestamp)}
        </SectionContentLeft>
        <SectionContentRight>
          {accountCountryCode && getCountryByCountryCode(accountCountryCode) && (
            <BankNationality countryCode={accountCountryCode} />
          )}
        </SectionContentRight>
      </SectionContentContainer>
      <AccountAttachments
        validatedAccountAttachments={verificationDetails.validatedAccountAttachments}
        downloadHandler={downloadAccountAttachment}
        source={AttachmentSource.verification}
      />
    </MainContainer>
  );
};

export default PayeeAccountDetails;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const SectionContentLeft = styled.div`
  flex: 1;
`;

const SectionContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 19px;
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-top: 8px;
`;

const AccountDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BankNationality = styled(StyledNationality)`
  font-size: 14px;
`;
