import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { STATUSES_UI } from '@mortee/domain/statuses';
import { capitalizeFirstLetter } from '@app/utils/stringUtils';

// color - background and text color
// icon - with place for icon to the left, only text color as background
// noColor - no color and no center alignment
// badge - same as color but with display inline (to use inside lines - like <span></span>)
// noBackground - text color as background color, without background
type StatusMode = 'color' | 'icon' | 'noColor' | 'badge' | 'noBackground';

interface StatusProps {
  dataTestId?: string;
  status: string;
  mode: StatusMode;
  fontSize?: number;
  isAuditStatus?: boolean;
  className?: string;
}

const Status: FunctionComponent<StatusProps> = (props) => {
  const statusToDisplay = (): string | null => {
    const { status } = props;

    const statusUI = STATUSES_UI[status];

    if (!statusUI) {
      return null;
    }

    return statusUI.text;
  };

  const { dataTestId, status, mode = 'noColor', fontSize, isAuditStatus, className } = props;

  const statusUI = STATUSES_UI[status];

  if (!status || !statusUI) {
    return <div />;
  }

  if (mode === 'badge') {
    return (
      <BadgeContainer color={statusUI.color} bgColor={statusUI.bgColor}>
        {statusToDisplay()}
      </BadgeContainer>
    );
  }

  const textColor = mode === 'color' ? statusUI.color : mode === 'noBackground' ? statusUI.noBgColor : undefined;
  const bgColor = mode === 'noBackground' || mode === 'noColor' ? 'transparent' : statusUI.bgColor;
  const statusSplit = statusToDisplay()?.split(' ') ?? '';

  return (
    <ExtendedStatusContainer className={className}>
      <StatusContainer
        withIcon={mode === 'icon'}
        textColor={textColor}
        fontSize={fontSize}
        bgColor={bgColor}
        mode={mode}
        isAuditStatus={isAuditStatus}
      >
        {mode === 'icon' && statusUI.icon}
        <span data-testid={dataTestId}>{statusSplit.length < 3 ? statusToDisplay() : statusSplit[0]}</span>
      </StatusContainer>
      {statusSplit.length > 2 && (
        <ExtendedStatusText textColor={textColor}>
          {capitalizeFirstLetter(statusSplit[1])} {statusSplit[2]}
        </ExtendedStatusText>
      )}
    </ExtendedStatusContainer>
  );
};

export default Status;

const BadgeContainer = styled.div<{ color?: string; bgColor?: string }>`
  display: inline-block;
  padding: 3px 12px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;

  color: ${(p): string => p.color ?? '#fff'};
  background-color: ${(p): string => p.bgColor ?? 'transparent'};
`;

interface StatusContainerProps {
  textColor?: string;
  withIcon: boolean;
  fontSize?: number;
  bgColor?: string;
  mode?: StatusMode;
  isAuditStatus?: boolean;
}

const StatusContainer = styled.span<StatusContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${(p): string => (p.mode === 'noColor' ? 'flex-start' : 'space-around')};
  border-radius: 15.5px;

  ${(p): string => (!p.fontSize ? 'width: 84px;' : '')}
  ${(p): string => (!p.fontSize ? 'height: 24px;' : '')}

  ${(p): string => (p.isAuditStatus ? 'width: 130px;' : '')}
  ${(p): string => (p.isAuditStatus ? 'height: 24px;' : '')}

  ${(p): string => (p.bgColor ? `background-color: ${p.bgColor};` : '')}

  span {
    ${(p): string => (p.mode !== 'noColor' && !p.isAuditStatus ? 'font-weight: bold;' : '')}

    ${(p): string => (p.textColor ? `color: ${p.textColor};` : '')}
    ${(p): string => (p.withIcon ? `margin-left: 5px;` : '')}
    font-size: ${({ fontSize }): number => fontSize ?? 12}px;
  }
`;

const ExtendedStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const ExtendedStatusText = styled.span<{ textColor?: string }>`
  font-size: 12px;
  margin-top: -2px;

  ${(p): string => (p.textColor ? `color: ${p.textColor};` : '')};
`;
