import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import { getTabPanelId, TabIndicator } from '@mortee/components/ProgressTabs/data';

export interface Props {
  id: string;
  dataTestId?: string;
  key: string;
  indicator: TabIndicator | null;
  selected: boolean;
  onSelection: () => void;
  isFirstTab: boolean;
  isLastTab: boolean;
  className?: string;
  children: ReactNode;
}

const ProgressTab: FC<Props> = ({
  id,
  dataTestId = id,
  key,
  indicator,
  selected,
  onSelection,
  isFirstTab,
  isLastTab,
  className,
  children,
}) => {
  const { icon, text } = indicator ?? {};

  return (
    <TabRow
      selected={selected}
      onClick={onSelection}
      className={className}
      id={id}
      data-testid={dataTestId}
      role='tab'
      aria-selected={selected}
      aria-controls={selected ? getTabPanelId(id) : undefined}
    >
      <BulletAndVerticalLinesWrapper>
        {isFirstTab ? <div /> : <VerticalLine />}
        <ConditionalTooltip showTooltip={!!text} title={text}>
          <ItemBullet hasIcon={!!icon}>{icon}</ItemBullet>
        </ConditionalTooltip>
        {isLastTab ? <div /> : <VerticalLine />}
      </BulletAndVerticalLinesWrapper>
      <TabContent selected={selected}>{children}</TabContent>
    </TabRow>
  );
};

export default ProgressTab;

const TabRow = styled.div<{ selected: boolean }>`
  display: grid;
  grid-template-columns: var(--bullet-dimention) 1fr;
  align-items: stretch;
  transition: background-color 0.2s;
  min-height: calc(var(--bullet-dimention) + 36px);
  padding-inline: 24px;
  cursor: pointer;

  ${(p): string =>
    p.selected
      ? `
  font-weight: bold;
  background-color: var(--transparent-black-100)
  `
      : ''};

  &:hover {
    background-color: var(--transparent-black-100);
  }
`;

const BulletAndVerticalLinesWrapper = styled.span`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto 1fr;
  justify-items: center;

  grid-column: 1;
`;

const TabContent = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: var(--content-vertical-padding) 10px;
  user-select: none;

  grid-column: 2;
`;

const ItemBullet = styled.div<{ hasIcon: boolean }>`
  width: var(--bullet-dimention);
  height: var(--bullet-dimention);
  transition: outline 0.1s, outline-offset 0.1s;

  ${(p): string => {
    if (!p.hasIcon) {
      return `outline: var(--transparent-black-700) solid 1px;
              outline-offset: -1px;`;
    }

    return '';
  }};
  border-radius: var(--bullet-dimention);

  // Limit the content to not exit the bullet
  clip-path: circle(calc(var(--bullet-dimention) * 0.5));

  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerticalLine = styled.span`
  display: inline-block;
  border-right: solid 1px var(--transparent-black-200);
`;
