import TagManager from 'react-gtm-module';

export default class GoogleAnalyticsMonitor implements AnalyticsMonitor {
  private readonly gtmId: string;

  constructor(gtmId: string) {
    this.gtmId = gtmId;
  }

  async init(): Promise<void> {
    const tagManagerArgs = {
      gtmId: this.gtmId,
    };

    await TagManager.initialize(tagManagerArgs);
  }

  setUserInfo(userId: string, additionalInfo?: object): void {
    this.addData({
      userId,
      ...additionalInfo,
    });
  }

  event(name: string, additionalData?: object): void {
    this.addData({
      event: name,
      ...additionalData,
    });
  }

  addData(data: object): void {
    TagManager.dataLayer({ dataLayer: data });
  }
}
