import React, { FC } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';

interface Props {
  onApprove: () => void;
  onClose: () => void;
}

const ExportConfirmationPopup: FC<Props> = ({ onApprove, onClose }) => {
  const closeAndApprove = (): void => {
    onClose();
    onApprove();
  };

  return (
    <MainContainer shadowType='elevated'>
      <Title>Export vendors</Title>
      <Content>
        Exporting all of your data might take a few minutes. Once the processing is done the download will start automatically.
      </Content>
      <Actions>
        <Button id='master-data-guard-export-confirmation-cancel' onClick={onClose} appearance='outline' colorScheme='primary'>
          CANCEL
        </Button>
        <Button
          id='master-data-guard-export-confirmation-approve'
          onClick={closeAndApprove}
          appearance='full'
          colorScheme='primary'
        >
          EXPORT
        </Button>
      </Actions>
    </MainContainer>
  );
};

export default ExportConfirmationPopup;

const MainContainer = styled(Card)`
  width: min(560px, 100vw);
  padding: 21px 16px 12px 24px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`;

const Content = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-600);

  margin-top: 16px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 30px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
