import React, { FC, ReactElement } from 'react';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import InputBox from '@app/components/inputs/inputBox/InputBox';

interface Props {
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  confirm: () => void;
  clearFilters: () => void;
}

const FilterColumnTextPopup: FC<Props> = ({ selectedKeys, setSelectedKeys, confirm, clearFilters }): ReactElement => {
  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      e.preventDefault();
      confirm();
    }
  };

  return (
    <PopupContent onKeyPress={handleKeyPress}>
      <Heading>
        <Title>Filter</Title>
        <Button appearance='text' id='btn-table-text-filter-reset' onClick={(): void => clearFilters()} size='small'>
          CLEAR
        </Button>
      </Heading>
      <InputBox appearance='corners'>
        <StyledNakedFormInput
          placeholder='Search'
          type='text'
          name='input-table-text-filter'
          dataTestId='input-table-text-filter'
          value={selectedKeys[0]}
          onChange={(value): void => setSelectedKeys(value ? [value] : [])}
          autoFocus
        />
      </InputBox>
      <Actions>
        <Button id='btn-table-text-filter-search' size='small' onClick={(): void => confirm()}>
          APPLY
        </Button>
      </Actions>
    </PopupContent>
  );
};

export default FilterColumnTextPopup;

const PopupContent = styled.div`
  padding: 10px 12px 10px 18px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);

  flex: 1;
`;

const Actions = styled.div`
  padding-top: 12px;

  display: flex;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const StyledNakedFormInput = styled(NakedFormInput)`
  min-width: 300px;
`;
