import { makeObservable, observable } from 'mobx';
import { FileSurveyStatus, StoredMvfFileSurveyServerResponse } from '@mortee/domain/mvfCheck';

export default class MvfCheckSurveyModel {
  id: string;
  @observable loaded: boolean = false;
  organizationId: string;
  writeTimestamp: number;
  data: FileSurveyCreatedEventData;
  performingUserName: string;
  @observable status?: FileSurveyStatus = undefined;

  constructor(id: string, obj: StoredMvfFileSurveyServerResponse | null = null) {
    makeObservable(this);

    this.id = id;
    this.loaded = !!obj;

    if (obj) {
      this.assignLoadedData(obj);
    }
  }

  get isInProgress(): boolean {
    return (
      this.status === FileSurveyStatus.fileSurveySyncInProgress ||
      this.status === FileSurveyStatus.fileSurveyVerificationInProgress
    );
  }

  private assignLoadedData = (obj: StoredMvfFileSurveyServerResponse): void => {
    this.organizationId = obj.organizationId;
    this.writeTimestamp = obj.writeTimestamp;
    this.data = {
      note: obj.data.note,
      vendorFilesMetadata: obj.data.vendorFilesMetadata,
    };
    this.performingUserName = obj.performingUserName;
    this.status = obj.status;
  };
}

interface FileSurveyCreatedEventData {
  note: string;
  vendorFilesMetadata: FileMetadata[];
}
