import React, { FC } from 'react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import { isTruthy } from '@app/utils/utils';
import useInfraStores from '@app/hooks/useInfraStores';
import {
  copyAllRegistrationFormData,
  copyWiRegistrationFormData,
  downloadCrowdKnowledgeReportCsv,
} from '@mortee/domain/morteeRegistrationFormsActions';

interface Props {
  id: string;
  supplierRegistrationProcess: SupplierRegistrationProcess;
}

const SupplierRegistrationItemMenu: FC<Props> = ({ id, supplierRegistrationProcess }) => {
  const { localeStore } = useInfraStores();

  const menuItems: MenuItem[] = [
    !!supplierRegistrationProcess.configurationData && {
      id: `btn-${id}-copy-all-data`,
      text: 'Copy all data',
      onClick: () => copyAllRegistrationFormData(supplierRegistrationProcess, localeStore),
    },
    {
      id: `btn-${id}-copy-wi`,
      text: 'Copy WI',
      onClick: () => copyWiRegistrationFormData(supplierRegistrationProcess),
    },
    supplierRegistrationProcess.doesHaveCrowdKnowledgeCandidate && {
      id: `btn-${id}-download-ck-candidates`,
      text: 'Download CK candidates',
      onClick: () => downloadCrowdKnowledgeReportCsv(supplierRegistrationProcess),
    },
  ].filter(isTruthy);

  return <Menu menuItems={menuItems} id={id} />;
};

export default SupplierRegistrationItemMenu;
