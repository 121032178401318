import React, { FC } from 'react';
import styled from '@emotion/styled';
import { H6StartWhite } from '@app/components/Text';
import LinkButton from '@app/components/LinkButton';
import SVG from '@app/components/SVG';
import NewSashSpriteSymbol from '@ar/images/new-sash.svg';
import { useTranslation } from 'react-i18next';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';

interface Props {
  className?: string;
}

const JoinARBanner: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Banner className={className}>
      <NewSashIcon accessibilityLabel='' image={NewSashSpriteSymbol} outerViewBox='4 0 180 81' />
      <StyledLocaleAwareDiv>
        <BannerText>{t('ar.guestVerification.wantToProtect')}</BannerText>
        <PaddedActionButton
          id='ar-banner-action-button'
          to={{ pathname: 'https://nsknox.net/paymentknox-for-ar/' }}
          target='_blank'
          rel='noreferrer'
        >
          {t('general.upperGetStarted')}
        </PaddedActionButton>
      </StyledLocaleAwareDiv>
    </Banner>
  );
};

export default JoinARBanner;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: solid 1px var(--gray-solid-200);
  background-color: var(--primary-500);
  padding-right: 16px;
  direction: ltr;
`;

const NewSashIcon = styled(SVG)`
  flex: 0 0 auto;
  width: 186px;
  height: 81px;
  margin-right: -22px;
`;

const PaddedActionButton = styled(LinkButton)`
  flex: 0 0 auto;
  padding: 1em 1.6em;
`;

const StyledLocaleAwareDiv = styled(LocaleAwareDiv)`
  display: flex;
  align-items: center;
  width: inherit;
`;

const BannerText = styled(H6StartWhite.div)`
  flex: 1;
  padding-right: 10px;
`;
