import { serverUrl } from '@app/config';
import request, { HttpMethod } from '@app/libs/request';

export async function getHandles(longExpiration: boolean = true): Promise<GetHandlesServerResponse> {
  const response = await request<GetHandlesServerResponse>(serverUrl, `/api/login/sessions`, {
    method: HttpMethod.get,
    params: { longExpiration },
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unable to login.',
      },
    },
  });

  if (!response) {
    throw new Error('empty response from getHandles');
  }

  return response;
}

export interface GetHandlesServerResponse {
  creationTimeStamp: number;
  handle: string;
  ipAddress: string;
  userAgent: string;
  secretId: string | null; // Returns null
  participatingKnoxers: string[];
}
