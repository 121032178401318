import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import TransitionLoader from '@app/components/TransitionLoader';
import {
  AuthMethodType,
  LoginMode,
  UnclaimedPhoneNumberKnoxer,
  UnclaimedUserPasswordKnoxer,
} from '@app/login/domain/loginConsts';
import { flow } from 'mobx';
import browserHistory from '@app/utils/browserHistory';
import {
  ModalContainer,
  showErrorModalWithTitle,
  showInfoModal,
  WideInfoModalBody,
  WideInfoModalTitle,
} from '@app/components/Modal';
import AuthenticatedMode from '../AuthenticatedMode';
import useInfraStores from '@app/hooks/useInfraStores';
import {
  GOOGLE_SSO_LOGIN_MODE_PATH,
  OIDC_PROVIDER_REGEX,
  OIDC_SSO_LOGIN_MODE_PATH_PREFIX,
  SAML_PROVIDER_REGEX,
  SAML_SSO_LOGIN_MODE_PATH_PREFIX,
  SSOErrorQueryParamValues,
  SuccessErrorQueryParamValues,
} from '@app/login/domain/sso';
import { useLocation } from 'react-router-dom';
import UserPasswordFlow from '@app/login/routes/userPassword/UserPasswordFlow';
import PhoneLoginFlow from '@app/login/routes/phoneLogin/PhoneLoginFlow';
import { createEnglishFormalErrorMessage } from '@app/utils/errorMessageUtils';
import { extractLogErrorIdFromError } from '@app/libs/request';
import { nanoid } from 'nanoid';
import SSOError from '@app/login/routes/sso/SSOError';
import { MultipleSSOProviderType } from '@app/login/domain/multipleSSO';
import { useSingleQueryParam } from '@app/hooks/useSingleQueryParam';
import { createValueInEnumPredicate } from '@app/utils/enumUtils';

const LocalAuthenticationFlow: FC = observer(() => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();
  const { pathname: currentPathname, search: currentSearch } = useLocation();
  const [errorParam] = useSingleQueryParam('error', createValueInEnumPredicate(SSOErrorQueryParamValues));
  const [authTypeParam] = useSingleQueryParam('authType', createValueInEnumPredicate(SuccessErrorQueryParamValues));
  const [samlProvider] = useSingleQueryParam('samlProvider', SAML_PROVIDER_REGEX);
  const [oidcProvider] = useSingleQueryParam('oidcProvider', OIDC_PROVIDER_REGEX);

  useEffect(() => {
    checkIfGoogleSsoLoginPath(currentPathname);
    claimWithSsoIfRedirected(currentSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot add methods to dependencies
  }, [currentPathname, currentSearch]);

  const { nextUnclaimedKnoxer } = authenticationStore;

  const checkIfGoogleSsoLoginPath = (pathname: string): void => {
    const isGoogleSsoPath = GOOGLE_SSO_LOGIN_MODE_PATH === pathname;
    if (isGoogleSsoPath) {
      authenticationStore.setGoogleSsoLoginPageMode(true);
    }
  };

  const isSsoResult = !!errorParam || !!authTypeParam;

  const claimWithSsoIfRedirected = flow<any, [search: string]>(function* () {
    if (!isSsoResult) {
      return;
    }

    if (
      errorParam === SSOErrorQueryParamValues.googleSso &&
      nextUnclaimedKnoxer &&
      nextUnclaimedKnoxer.authMethodData.type !== AuthMethodType.PhoneNumber
    ) {
      showErrorModalWithTitle(
        'Login failed',
        'Something went wrong, try to log in again or contact us at support@nsknox.net for assistance.',
      );
      authenticationStore.setGoogleSsoLoginPageMode(true);
      replaceToGoogleSsoLoginPageUrl();
      return;
    }

    if (!nextUnclaimedKnoxer || nextUnclaimedKnoxer.authMethodData.type === AuthMethodType.PhoneNumber) {
      return;
    }

    if (authTypeParam === SuccessErrorQueryParamValues.googleSso) {
      try {
        authenticationStore.setLoading(true);
        yield authenticationStore.claimWebSession(nextUnclaimedKnoxer.knoxer.id, AuthMethodType.GoogleSSO);
        authenticationStore.saveAuthenticatedKnoxer(nextUnclaimedKnoxer.knoxer.id, { authType: AuthMethodType.GoogleSSO });
        replaceToGoogleSsoLoginPageUrl();
        authenticationStore.setLoading(false);
      } catch (e: unknown) {
        const errorLogId = extractLogErrorIdFromError(e);
        const messageId = nanoid();

        const titleId = `alert-title-${messageId}`;
        const contentId = `alert-description-${messageId}`;

        showInfoModal(
          <ModalContainer id={messageId} aria-labelledby={titleId} aria-describedby={contentId}>
            <WideInfoModalTitle id={titleId}>Login failed</WideInfoModalTitle>
            <WideInfoModalBody id={contentId}>
              {createEnglishFormalErrorMessage(
                'Something went wrong, try to log in again or contact us at support@nsknox.net for assistance.',
                {
                  preventPleaseContactText: true,
                  errorLogId,
                },
              )}
            </WideInfoModalBody>
          </ModalContainer>,
          {
            okText: `OK`,
          },
        );
        authenticationStore.setLoading(false);
      } finally {
        authenticationStore.setGoogleSsoLoginPageMode(true);
      }
    }

    if (authTypeParam === SuccessErrorQueryParamValues.saml) {
      try {
        authenticationStore.setLoading(true);
        yield authenticationStore.claimWebSession(nextUnclaimedKnoxer.knoxer.id, AuthMethodType.SamlSSO);
        authenticationStore.saveAuthenticatedKnoxer(nextUnclaimedKnoxer.knoxer.id, { authType: AuthMethodType.SamlSSO });
        authenticationStore.setLoading(false);
        samlProvider && replaceToSamlSsoLoginPageUrl(samlProvider);
      } catch (e: unknown) {
        const errorLogId = extractLogErrorIdFromError(e);
        showInfoModal(
          <ModalContainer>
            <WideInfoModalTitle>Login failed</WideInfoModalTitle>
            <WideInfoModalBody>
              {createEnglishFormalErrorMessage(
                'Something went wrong, try to log in again or contact us at support@nsknox.net for assistance.',
                {
                  preventPleaseContactText: true,
                  errorLogId,
                },
              )}
            </WideInfoModalBody>
          </ModalContainer>,
          {
            okText: `OK`,
          },
        );
        authenticationStore.setLoading(false);
      }
    }

    if (authTypeParam === SuccessErrorQueryParamValues.oidc) {
      try {
        authenticationStore.setLoading(true);
        yield authenticationStore.claimWebSession(nextUnclaimedKnoxer.knoxer.id, AuthMethodType.OidcSSO);
        authenticationStore.saveAuthenticatedKnoxer(nextUnclaimedKnoxer.knoxer.id, { authType: AuthMethodType.OidcSSO });
        authenticationStore.setLoading(false);
        oidcProvider && replaceToOIDCSsoLoginPageUrl(oidcProvider);
      } catch (e: unknown) {
        const errorLogId = extractLogErrorIdFromError(e);
        showInfoModal(
          <ModalContainer>
            <WideInfoModalTitle>Login failed</WideInfoModalTitle>
            <WideInfoModalBody>
              {createEnglishFormalErrorMessage(
                'Something went wrong, try to log in again or contact us at support@nsknox.net for assistance.',
                {
                  preventPleaseContactText: true,
                  errorLogId,
                },
              )}
            </WideInfoModalBody>
          </ModalContainer>,
          {
            okText: `OK`,
          },
        );
        authenticationStore.setLoading(false);
      }
    }
  });

  const replaceToGoogleSsoLoginPageUrl = (): void => {
    browserHistory.replace(GOOGLE_SSO_LOGIN_MODE_PATH);
  };

  const replaceToSamlSsoLoginPageUrl = (samlProvider: string): void => {
    browserHistory.replace(`${SAML_SSO_LOGIN_MODE_PATH_PREFIX}/${samlProvider}`);
  };

  const replaceToOIDCSsoLoginPageUrl = (oidcProvider: string): void => {
    browserHistory.replace(`${OIDC_SSO_LOGIN_MODE_PATH_PREFIX}/${oidcProvider}`);
  };

  const resetMode = (loginMode: LoginMode): void => {
    authenticationStore.reset({ loginMode });
  };

  const renderAuthMethod = (): ReactElement => {
    if (errorParam) {
      return renderAuthError(errorParam);
    }

    const { nextUnclaimedKnoxer } = authenticationStore;

    if (nextUnclaimedKnoxer && !isSsoResult) {
      const { authMethodData } = nextUnclaimedKnoxer;

      switch (authMethodData.type) {
        case AuthMethodType.GoogleSSO:
        case AuthMethodType.EmailPassword: {
          return <UserPasswordFlow knoxerToClaim={nextUnclaimedKnoxer as UnclaimedUserPasswordKnoxer} />;
        }
        case AuthMethodType.PhoneNumber: {
          return (
            <PhoneLoginFlow
              knoxerToClaim={nextUnclaimedKnoxer as UnclaimedPhoneNumberKnoxer}
              onBack={(): void => resetMode(LoginMode.Local)}
            />
          );
        }
      }
    }

    return <div />;
  };

  function renderAuthError(errorParam: SSOErrorQueryParamValues): ReactElement {
    switch (errorParam) {
      case SSOErrorQueryParamValues.googleSso:
        // Google sso is weird and I don't want to get into it
        return <></>;
      case SSOErrorQueryParamValues.saml:
        return <SSOError sso={MultipleSSOProviderType.saml} />;
      case SSOErrorQueryParamValues.oidc:
        return <SSOError sso={MultipleSSOProviderType.oidc} />;
    }
  }

  return (
    <TransitionLoader loading={authenticationStore.loading}>
      <Container>{renderAuthMethod()}</Container>
    </TransitionLoader>
  );
});

export default LocalAuthenticationFlow;

const Container = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
`;
