import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import styled from '@emotion/styled';
import { Regular13TransparentBlack600, Regular22TransparentBlack900 } from '@app/components/Text';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import * as messageLauncher from '@app/utils/messageLauncher';
import AsyncButton from '@app/components/AsyncButton';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import Log from '@app/libs/logger';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { displayDistributionWarningMessage, SupplierValidationRecord } from '@mortee/domain/validationSystem';
import { transformToSupplierRegistrationProcess } from '@mortee/services/supplierRegistrationManagementServices';
import Button from '@app/components/Button';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/close-thick.svg';
import Card from '@app/components/card/Card';
import { KeyName } from '@app/domain/uiConsts';
import SupplierValidationRecordSelector from '@mortee/routes/validationSystem/SupplierValidationRecordSelector';
import SupplierRegistrationCard from '@mortee/routes/validationSystem/SupplierRegistrationCard';
import ConditionalTooltip from '@app/components/ConditionalTooltip';

interface LinkSupplierRegistrationToSupplierValidationRecordFormFields {
  linkedSupplierValidationRecord: SupplierValidationRecord;
}

interface Props extends FormComponentProps<LinkSupplierRegistrationToSupplierValidationRecordFormFields> {
  supplierRegistrationProcess: SupplierRegistrationProcess;
  className?: string;
  onSaved(updatedProcess?: SupplierRegistrationProcess): void;
  onCanceled(): void;
}

const SelectSupplierValidationRecordForSupplierRegistrationForm: FC<Props> = observer((props) => {
  const { supplierRegistrationProcess, onSaved, onCanceled, className } = props;

  const { form, showFormErrors, setShowFormErrors, isFormInvalid, validateFields } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);

  function onKeyUp(event: React.KeyboardEvent): void {
    if (event.key === KeyName.Escape) {
      onCanceled();
    }
  }

  async function onSave(): Promise<void> {
    setIsSaving(true);

    try {
      const { linkedSupplierValidationRecord } = await validateFields();

      const serverResponse = await validationSystemServices.updateValidationRecord(linkedSupplierValidationRecord.staticId, {
        svRegistrationId: { content: supplierRegistrationProcess.id },
      });

      messageLauncher.shoot(
        { type: 'success' },
        `Form ${supplierRegistrationProcess.registrationNumber} successfully linked to validation ${serverResponse?.record.presentationId}`,
      );
      displayDistributionWarningMessage(serverResponse.warnings);

      onSaved(
        serverResponse?.record.supplierValidationProcess
          ? transformToSupplierRegistrationProcess(serverResponse.record.supplierValidationProcess)
          : undefined,
      );
    } catch (e) {
      Log.exception(e);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <MainContainer dataTestId='link-registration-forms-to-svm-modal' onKeyUp={onKeyUp}>
      <TitleLine>
        <Regular22TransparentBlack900.div>Add to validation system record</Regular22TransparentBlack900.div>
        <CloseButton
          id='btn-link-registration-forms-to-svm-modal-close'
          appearance='text'
          colorScheme='primary'
          onClick={function (): void {
            onCanceled();
          }}
        >
          <SVG accessibilityLabel='close' image={ClearIcon} height={24} />
        </CloseButton>
      </TitleLine>
      <StyledNsknoxForm form={form} showErrors={showFormErrors} className={className} setShowErrors={setShowFormErrors}>
        <ExpendedVerticalShadowScroller id='link-to-validation-record-form'>
          <SectionTitle>Registration form of your supplier</SectionTitle>
          <div data-testid='selected-supplier-registration'>
            <SupplierRegistrationCard item={supplierRegistrationProcess} dataTestId='sr-inner-card' />
          </div>
          <SectionTitle>Validation system item</SectionTitle>
          <FormItemBox
            fieldName='linkedSupplierValidationRecord'
            fieldDecoratorOptions={fieldDecorators.linkedSupplierValidationRecord}
            appearance='none'
            expandBelowOnError
          >
            <SupplierValidationRecordSelector
              scrollableTargetId='link-to-validation-record-form'
              id='inpt-link-validation-record-to-svm'
              dataTestId='inpt-supplier-validation-selector'
              organizationIdOfSupplierRegistrationProcess={supplierRegistrationProcess.organizationId}
            />
          </FormItemBox>
        </ExpendedVerticalShadowScroller>
        <ActionsContainer>
          <ConditionalTooltip showTooltip={isFormInvalid} title='Please select validation record'>
            <AsyncButton
              id='btn-link-to-registration-process-record-save'
              onClick={(): Promise<void> => onSave()}
              loading={isSaving}
              disabled={isFormInvalid}
              onDisabledClick={(): void => setShowFormErrors('all')}
            >
              LINK REGISTRATION FORM
            </AsyncButton>
          </ConditionalTooltip>
        </ActionsContainer>
      </StyledNsknoxForm>
    </MainContainer>
  );
});

const fieldDecorators: FormFieldDecorators<LinkSupplierRegistrationToSupplierValidationRecordFormFields> = {
  linkedSupplierValidationRecord: {
    rules: [
      {
        type: 'object',
        required: true,
        message: `Select supplier validation record`,
      },
    ],
  },
};

export default Form.create<Props>()(SelectSupplierValidationRecordForSupplierRegistrationForm);

const MainContainer = styled(Card)`
  width: min(800px, 90vw);
  height: 95vh;
  padding: 31px 8px 24px;
  position: relative;
  box-shadow: var(--box-shadow-3);

  display: flex;
  flex-direction: column;

  --side-padding: 32px;
`;

const TitleLine = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 0 var(--side-padding);
  margin: 0;
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  align-self: flex-start;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  flex: 1;
`;

const SectionTitle = styled.h2`
  ${Regular13TransparentBlack600.css};

  margin-top: 24px;
  margin-bottom: 6px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  margin-top: 36px;

  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;

const ExpendedVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;
