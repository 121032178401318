import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ActivityLogEvent } from '@mortee/domain/validationSystemTimelineEvents';
import { BodyRegularStartTransparentBlack900, Regular15TransparentBlack900 } from '@app/components/Text';
import EventMetadata from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/EventMetadata';

interface Props {
  event: ActivityLogEvent;
  eventTitle: React.ReactNode;
  additionalMetadata?: ReactNode;
  className?: string;
}

const SupplierValidationRecordActivityLogEvent: FC<Props> = ({ event, eventTitle, additionalMetadata, children, className }) => {
  return (
    <Main className={className}>
      <EventMetadataLine>
        <MetadataLeft event={event} />
        <MetadataRight>{additionalMetadata}</MetadataRight>
      </EventMetadataLine>
      {eventTitle && <EventDescription>{eventTitle}</EventDescription>}
      <Regular15TransparentBlack900.div>{children}</Regular15TransparentBlack900.div>
    </Main>
  );
};

export default SupplierValidationRecordActivityLogEvent;

export const SupplierValidationRecordActivityLogEventMessage = styled.div`
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 12px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const EventDescription = styled(BodyRegularStartTransparentBlack900.div)`
  margin-bottom: 10px;
`;

const EventMetadataLine = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  gap: 10px;
`;

const MetadataLeft = styled(EventMetadata)`
  flex: 1;
`;

const MetadataRight = styled.div`
  flex: 0 0 auto;
`;
