import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import GuestLoginEnterEmail from './GuestLoginEnterEmail';
import GuestLoginEnterEmailCode from './GuestLoginEnterEmailCode';

const GuestLoginEmailFlow: FunctionComponent = observer(() => {
  const localStore = useLocalObservable(() => ({
    _email: undefined as string | undefined,
    _isWaitingForCode: false as boolean,

    get email(): string | undefined {
      return localStore._email;
    },
    get isWaitingForCode(): boolean {
      return localStore._isWaitingForCode;
    },

    setEmail: (email?: string): void => {
      localStore._email = email;
    },
    setIsWaitingForCode: (isWaiting: boolean): void => {
      localStore._isWaitingForCode = isWaiting;
    },
  }));

  const { email, isWaitingForCode, setEmail, setIsWaitingForCode } = localStore;

  if (email && isWaitingForCode) {
    return <GuestLoginEnterEmailCode email={email} cancelWaitingForCode={(): void => setIsWaitingForCode(false)} />;
  }

  return (
    <GuestLoginEnterEmail setEmail={setEmail} setWaitingForCode={(): void => setIsWaitingForCode(true)} initialEmail={email} />
  );
});

export default GuestLoginEmailFlow;
