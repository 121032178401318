import React, { FC, ReactNode } from 'react';
import Button, { ButtonProps } from '@app/components/Button';
import NoDataImage from '@app/images/table/error-loading-data.svg';
import SVG from '@app/components/SVG';
import { BodySmallStartTransparentBlack900, Regular28TransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';
import { extractLogErrorIdFromError } from '@app/libs/request';

interface Props {
  error?: unknown;
  content?: ReactNode;
  button?: ButtonProps;
  className?: string;
}

const ErrorLoadingData: FC<Props> = ({ content: propContent, error, button, className }) => {
  const correlationId = extractLogErrorIdFromError(error);
  const content =
    propContent ||
    `We're probably already on it.
if the error occurs again, contact support`;

  return (
    <Container className={className}>
      <NoDataSVG accessibilityLabel='' image={NoDataImage} width={74} height={74} />
      <MainText>Something went wrong</MainText>
      <SecondaryText>
        {content}
        {correlationId && (
          <>
            <br />
            (error code: {correlationId})
          </>
        )}
      </SecondaryText>
      {button && <ActionButton appearance='text' {...button} />}
    </Container>
  );
};

export default ErrorLoadingData;

const Container = styled.div`
  padding: 80px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoDataSVG = styled(SVG)`
  margin-bottom: 50px;
`;

const MainText = styled(Regular28TransparentBlack900.div)`
  margin-bottom: 8px;
`;

const SecondaryText = styled(BodySmallStartTransparentBlack900.div)`
  white-space: pre-wrap;
  text-align: center;
`;

const ActionButton = styled(Button)`
  margin-top: 22px;
  display: inline-block;
`;
