import React, { ReactNode, useContext } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ForwardingFC } from '@app/domain/technicals/components';
import { convertToFieldDecoratorOptions, GetFieldDecoratorOptionsExtended } from '@app/utils/form/form';
import FormContext from './FormContext';

interface FormItemWrapperProps {
  form?: WrappedFormUtils;
  fieldName: string;
  fieldDecoratorOptions: GetFieldDecoratorOptionsExtended | null;
  children: ReactNode;
}

const InputDecorator: ForwardingFC<HTMLElement, FormItemWrapperProps> = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: FormItemWrapperProps, ref) => {
    const formContext = useContext(FormContext);
    const { form = formContext.form, fieldName, fieldDecoratorOptions: fieldDecoratorOptionsExtended, children } = props;

    const { getFieldDecorator } = form;
    const fieldDecoratorOptions = convertToFieldDecoratorOptions(fieldDecoratorOptionsExtended);

    return <>{getFieldDecorator(fieldName, fieldDecoratorOptions)(children)}</>;
  },
);

export default InputDecorator;
