export const EMAIL_TEMPLATE = `Dear [your client’s name],
I’m happy to let you know that as a part of our ongoing efforts to provide additional value to our customers and in light of recent increase in payment fraud, we have joined a new service that will help protect your payments to us and is available to you free-of-charge.

This service helps prevent the transfer of payments to a fraudster’s account instead of the intended beneficiary, by allowing you to verify online the validity of our bank account details before making the payment.

Please find attached our Bank Account Certificate, which contains our bank account details in an encrypted format.

To verify the certificate and view its details, simply go to

verify.nsknox.net  and drag-and-drop the certificate.

The result will indicate if the certificate is valid or not, while displaying the full bank account details.

Check that the details match the bank details in your records.

You should always verify the details online any time you receive a request to update our banking information, whether instructed by email, phone or any other means.

To learn more about how to prevent bank account fraud, visit - nsknox.net/incoming-payment-security

Thank you,
[your name]
`;

export const VIDEO_INSTRUCTIONS_URL = `https://nsknox.net/bank-validation-by-nsknox/`;
export const CONTACT_US_TEXT = `Before making payments to us we will send you an encrypted bank account certificate. The certificate helps prevent payments fraud by using an online service to ensure that the bank account details are correct, up-to-date, and validated by a trusted third-party, called nsKnox.
To verify the certificate, go to verify.nsknox.net and drag & drop the certificate.`;
export const PROTECTION_METHOD_TEXT = `PaymentKnox Payment Fraud Protection

In light of the recent increase in payments fraud, we have enrolled in a service aimed at protecting against bank account details manipulation.

We have sent you our bank account certificate, which contains our bank account details in an encrypted format. The certificate helps prevent payments fraud by using an online service that ensures that its bank account details are correct, up-to-date, and validated by a trusted third-party, called nsKnox.

Before making your first payment to us, go to - verify.nsknox.net  and drag & drop the certificate to verify that the certificate is valid and up-to-date. The verification service is free of charge.`;
export const CONTRACTS_VALIDATION_TEXT = `Payments shall be made using bank account details provided by [Company] in an encrypted bank account certificate. To view the details of the encrypted certificate and to verify the validity of the certificate, [Customer] will use the secured online service made available to [Customer] by [Company] free of charge at verify.nsknox.net.`;
