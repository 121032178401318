export enum KnoxerAuthType {
  phone = 'Phone',
  email = 'Email',
}

export interface KnoxerServerResponse {
  id: string;
  authType: KnoxerAuthType;
}

export interface Knoxer {
  id: string;
  authType: KnoxerAuthType;
  name: string;
}

export function transformKnoxer(serverResponse: KnoxerServerResponse): Knoxer {
  let name: string;

  switch (serverResponse.authType) {
    case KnoxerAuthType.email:
      name = 'Azure (Email)';
      break;
    case KnoxerAuthType.phone:
      name = 'AWS (Phone)';
      break;
  }

  return {
    name,
    ...serverResponse,
  };
}

export interface AllKnoxers {
  emailKnoxer: Knoxer;
  phoneKnoxer: Knoxer;
}
