import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import { H5StartTransparentBlack900 } from '@app/components/Text';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: ReactNode;
  icon?: SpriteSymbol;
  alerts: NonNullable<ReactNode>[];
}

const StoreConfirmationAlerts: FC<Props> = ({ title, icon, alerts, ...rest }) => {
  if (alerts.length === 0) {
    return <></>;
  }

  return (
    <AlertsBox {...rest}>
      {icon && <SVG accessibilityLabel='' image={icon} height={48} />}
      <Alerts>
        <AlertTitle>{title}</AlertTitle>
        <AlertLines>
          {alerts.map((renderedAlert, i) => (
            <li key={i} data-testid={`store-confirmation-information-${i}`}>
              {renderedAlert}
            </li>
          ))}
        </AlertLines>
      </Alerts>
    </AlertsBox>
  );
};

export default StoreConfirmationAlerts;

const AlertsBox = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 8px;
`;

const Alerts = styled.div`
  flex: 1;
`;

const AlertTitle = styled(H5StartTransparentBlack900.div)`
  padding-left: 20px;
  padding-bottom: 8px;
`;

const AlertLines = styled.ul`
  margin: 0;
`;
