import PaginationManager, { NextPageLoader } from './PaginationManager';
import { action, computed, flow, makeObservable, observable } from 'mobx';

export default class AsyncAccumulatorPaginationManager<T> implements PaginationManager<T> {
  @observable private readonly _pageLoader: NextPageLoader<T>;
  @observable private _hasMore: boolean = true;
  @observable private _loadedItems: T[] = [];

  private _isNextPageLoading = false;

  constructor(pageLoader: NextPageLoader<T>) {
    this._pageLoader = pageLoader;

    makeObservable(this);
  }

  @computed
  get totalNumberOfItems(): number {
    return this._pageLoader.totalNumberOfItems;
  }

  @computed
  get hasItems(): boolean {
    return !!this.totalNumberOfItems;
  }

  @computed
  get hasMore(): boolean {
    return this._hasMore;
  }

  @computed
  get items(): T[] {
    return this._loadedItems;
  }

  @action
  replaceItem(oldItem: T, newItem: T): void {
    const oldItemIndex = this._loadedItems.indexOf(oldItem);

    if (oldItemIndex === -1) {
      return;
    }

    this._loadedItems[oldItemIndex] = newItem;
  }

  loadNextPage = flow(function* (this: AsyncAccumulatorPaginationManager<T>) {
    if (this._isNextPageLoading) {
      return;
    }

    this._isNextPageLoading = true;
    try {
      const newPage = yield this._pageLoader.loadNextPage();
      this._hasMore = newPage.hasMore;
      this._loadedItems.push(...newPage.items);
    } finally {
      this._isNextPageLoading = false;
    }
  });
}
