import React, { PropsWithChildren, ReactElement } from 'react';
import { ProgressTabsProviderContext } from './ProgressTabsContext';
import ProgressTabsPanel from '@mortee/components/ProgressTabs/ProgressTabsPanel';
import ProgressTabsList from '@mortee/components/ProgressTabs/ProgressTabsList';
import { SelectedTab, Tab } from '@mortee/components/ProgressTabs/data';

interface Props<T extends string> {
  id: string;
  dataTestId: string;
  options: Tab<T>[];
  selectedTab: SelectedTab<T>;
  accessibilityLabel: string;
  className?: string;
}

const ProgressTabs = <T extends string>(props: PropsWithChildren<Props<T>>): ReactElement => {
  const { id, dataTestId, options, selectedTab, accessibilityLabel, children } = props;
  return (
    <ProgressTabsProviderContext.Provider value={{ id, dataTestId, options, selectedTab, accessibilityLabel }}>
      {children}
    </ProgressTabsProviderContext.Provider>
  );
};

ProgressTabs.Tabs = ProgressTabsList;
ProgressTabs.Panel = ProgressTabsPanel;

export default ProgressTabs;

export * from '@mortee/components/ProgressTabs/data';
