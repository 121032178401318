import React, { FunctionComponent } from 'react';
import { ColumnProps, PaginationConfig } from 'antd/lib/table';
import Loadable from '@app/utils/Loadable';
import { pluralize } from '@app/utils/stringUtils';
import { TableImportantText, TableStandardText } from '@app/components/tables/Table';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import { Organization } from '@app/domain/userManagement/organizations';
import getColumnMultiValueArrayFieldSelectProps from '@app/components/tableFilters/multiValue/byArrayFieldFilterCreator';
import TableOfCards from '@app/components/tables/TableOfCards';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import OrganizationActionsMenu from '@backee/routes/organizations/allOrganizationsPage/OrganizationActionsMenu';

interface OrganizationsTableProps {
  id: string;
  dataTestId?: string;
  organizationsLoadable: Loadable<Organization[]>;
  canAddUsersToOrganization: boolean;
  onItemClick: (item: Organization) => void;
  onItemManageUsers: (item: Organization) => void;
  onItemAddUsers: (item: Organization) => void;
  className?: string;
}

const OrganizationsTable: FunctionComponent<OrganizationsTableProps> = (props) => {
  const {
    id,
    dataTestId,
    organizationsLoadable,
    canAddUsersToOrganization,
    onItemClick,
    onItemManageUsers,
    onItemAddUsers,
    className,
  } = props;

  const [filteredItems, searchTerm, setSearchTerm] = useLoadableSearchFilter(organizationsLoadable, {
    exactMatches(organization) {
      return [organization.id];
    },
    partialMatches(organization) {
      return [organization.name];
    },
  });

  const paginationConfig: PaginationConfig = {
    position: 'both',
    defaultPageSize: 10,
  };

  return (
    <div>
      <InputBox appearance='line' colorScheme='secondary'>
        <NakedFormInput
          name='organizations-table-search'
          dataTestId='organizations-table-search'
          type='text'
          colorScheme='secondary'
          placeholderStyle='onlyWhenEmpty'
          heightType='thick'
          clearable
          placeholder='🔍 Search for Id / Name'
          value={searchTerm}
          onChange={(newValue): void => setSearchTerm(newValue ?? '')}
          autoFocus
        />
      </InputBox>
      <TableOfCards
        id={id}
        dataTestId={dataTestId}
        columns={createColumns(filteredItems.result ?? [], canAddUsersToOrganization, onItemManageUsers, onItemAddUsers)}
        dataSource={filteredItems.result ?? []}
        className={className}
        loading={!filteredItems.isResolved()}
        pagination={paginationConfig}
        onRow={(record: Organization): { onClick: React.MouseEventHandler<HTMLDivElement> } => {
          return {
            onClick: (e): void => {
              e.preventDefault();
              e.stopPropagation();
              onItemClick(record);
            }, // click row
          };
        }}
        rowClickable
        rowKey={(organization: Organization): string => organization.id}
      />
    </div>
  );
};

export default OrganizationsTable;

const createColumns = (
  data: Organization[],
  canAddUsersToOrganization: boolean,
  onItemManageUsers: (item: Organization) => void,
  onItemAddUsers: (item: Organization) => void,
): ColumnProps<Organization>[] => {
  return [
    {
      title: <div data-testid='lblName'>Organization Name</div>,
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (org1, org2): number => org1.name.localeCompare(org2.name),
      render: (text: any, record: Organization): React.ReactNode => (
        <TableImportantText data-testid='organizations-table-record-name'>{record.name}</TableImportantText>
      ),
      ...getColumnTextSearchProps<Organization>((record) => record.name),
    },
    {
      title: <div data-testid='lblServices'>Services</div>,
      dataIndex: 'services',
      key: 'services',
      width: '180px',
      sorter: (record1, record2): number => record1.serviceIds.length - record2.serviceIds.length,
      render: (text: any, record: Organization): React.ReactNode => (
        <TableStandardText data-testid='organizations-table-record-services'>
          {record.serviceIds.length} {pluralize('service', record.serviceIds.length)}
        </TableStandardText>
      ),
      ...getColumnMultiValueArrayFieldSelectProps<Organization>(data, (record) => record.serviceIds),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '60px',
      render: (text: any, record: Organization): React.ReactNode => {
        return (
          <OrganizationActionsMenu
            organizationId={record.id}
            canAddUsersToOrganization={canAddUsersToOrganization}
            onAddUsers={(): void => onItemAddUsers(record)}
            onManageUsers={(): void => onItemManageUsers(record)}
          />
        );
      },
    },
  ];
};
