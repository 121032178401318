import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { CustomErrorText, SftpError, SftpErrorServerResponse, SftpFileTypes } from '@mortee/domain/sftpConsts';
import { getErrorTextByCode } from '@mortee/domain/mvfCheck';
import React from 'react';

export async function getAllSftpServerErrors(): Promise<SftpErrorServerResponse[]> {
  return request<SftpErrorServerResponse[]>(serverUrl, `/api/file-based/client-errors`, {
    method: HttpMethod.get,
  });
}

export function transformSftpErrors(serverResponse: SftpErrorServerResponse): SftpError {
  return {
    ...serverResponse,
    fileType: getFileType(serverResponse.fileType),
    customErrorText: getErrorText(serverResponse),
  };
}

function getFileType(fileType: string): string | null {
  if (!fileType) {
    return null;
  }
  return SftpFileTypes[fileType.toLowerCase()] ?? fileType;
}

function getErrorText(serverResponse: SftpErrorServerResponse): CustomErrorText {
  if (getFileType(serverResponse.fileType) === SftpFileTypes.payment) {
    return {
      text:
        'Payment file analysis by PaymentKnox Connect failed. This is likely due to a problem with the file format or missing configuration parameter. For more details, you may try to upload the file using the PaymentKnox Portal or contact nsKnox support at: support@nsknox.net',
      customTooltipContent: (
        <div>
          Payment file analysis by PaymentKnox Connect failed. This is likely due to a problem with the file format or missing
          configuration parameter. For more details, you may try to upload the file using the
          <a href='/outgoing/payment-check/load'> PaymentKnox Portal</a> or contact nsKnox support at: support@nsknox.net
        </div>
      ),
    };
  }

  return {
    text:
      getErrorTextByCode(serverResponse.webErrorCode, serverResponse.errorAdditionalData)?.toString() ??
      `Error text wasn't provided`,
  };
}
