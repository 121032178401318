import { ReactNode } from 'react';

export type Tab<T extends string> = {
  key: T;
  title: ReactNode;
  indicator: TabIndicator | null;
  render(): ReactNode;
};

export type TabIndicator = {
  icon: ReactNode;
  text: ReactNode;
};

export interface SelectedTab<TTabs> {
  value: TTabs;
  onChange(newValue: TTabs): void;
}

export function getSpecificTabId(id: string, optionKey: string): string {
  return `${id}-option-${optionKey}`;
}

export function getTabPanelId(selectedTabId: string): string {
  return `${selectedTabId}-panel`;
}
