import request, { HttpMethod } from '@app/libs/request';
import config, { serverUrl } from '@app/config';
import { SupplierRegistrationAsyncInitiateValidationProcessRequest } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { CAPTCHA_TOKEN_HEADER, SupplierRegistrationProcessRequest } from '@supplierRegistration/domain/supplierRegistration';
import { SupplierRegistrationProcessFormType } from '@app/domain/commonSupplierRegistration';
import { RawAxiosRequestHeaders } from 'axios';

export async function isSuitableForOpenBanking(
  isSuitableRequest: SupplierRegistrationProcessRequest,
  validationType: SupplierRegistrationProcessFormType,
): Promise<boolean> {
  let isSuitable: Promise<boolean> | undefined = undefined;

  try {
    isSuitable = request<boolean>(serverUrl, `/api/open-banking/is-suitable`, {
      data: isSuitableRequest,
      method: HttpMethod.post,
      dontRedirectToLogin: true,
      suppressNotification: true,
      params: { type: validationType },
    });
  } catch (e) {
    return false;
  }

  return isSuitable;
}

export async function isSuitableForOpenBankingAsync(
  isSuitableRequest: SupplierRegistrationAsyncInitiateValidationProcessRequest,
  asyncProcessId: string,
): Promise<boolean> {
  let isSuitable: Promise<boolean> | undefined = undefined;

  try {
    isSuitable = request<boolean>(serverUrl, `/api/open-banking/is-suitable/async/:asyncProcessId`, {
      data: isSuitableRequest,
      method: HttpMethod.post,
      dontRedirectToLogin: true,
      suppressNotification: true,
      pathParams: { asyncProcessId },
    });
  } catch (e) {
    return false;
  }

  return isSuitable;
}

interface GenerateFinicityConnectLinkResponse {
  link: string;
  customerId: string;
}

export async function generateFinicityConnectLink(): Promise<GenerateFinicityConnectLinkResponse> {
  return request<GenerateFinicityConnectLinkResponse>(serverUrl, `/api/open-banking/generate-link`, {
    method: HttpMethod.get,
    dontRedirectToLogin: true,
    suppressNotification: true,
  });
}

export async function validateFinicityAccount(
  customerId: string,
  accountNumber: string,
  achRouting: string,
  abaRouting: string,
  captchaToken: string | null | undefined,
): Promise<string> {
  const headers: RawAxiosRequestHeaders = {};

  if (config.featureFlags?.supplierRegistrationCaptcha && captchaToken) {
    headers[CAPTCHA_TOKEN_HEADER] = captchaToken;
  }

  return request<string>(serverUrl, `/api/open-banking/validate-account`, {
    data: { customerId, accountNumber, achRouting, abaRouting },
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    suppressNotification: true,
    headers,
  });
}

export async function validateFinicityAccountAsync(
  isSuitableRequest: SupplierRegistrationAsyncInitiateValidationProcessRequest,
  asyncProcessId: string,
  customerId: string,
  captchaToken: string | null | undefined,
): Promise<string> {
  const headers: RawAxiosRequestHeaders = {};

  if (config.featureFlags?.supplierRegistrationCaptcha && captchaToken) {
    headers[CAPTCHA_TOKEN_HEADER] = captchaToken;
  }

  return request<string>(serverUrl, `/api/open-banking/validate-account/async/:asyncProcessId`, {
    data: isSuitableRequest,
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    suppressNotification: true,
    pathParams: { asyncProcessId },
    params: { customerId },
    headers,
  });
}
