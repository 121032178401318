import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { action, runInAction } from 'mobx';
import { FilesUploadMode, FilesUploadModeNames } from '@mortee/domain/fileUpload';
import styled from '@emotion/styled';
import { FormComponentProps } from 'antd/lib/form/Form';
import { confirm, ModalBody, ModalContainer, ModalTextLine, ModalTitle, showInfoModal } from '@app/components/Modal';
import ModalErrorsList from '@app/components/fileUpload/ModalErrorsList';
import FileCardUploadDragger from '@app/components/fileUpload/FileCardUploadDragger';
import { CheckFileOptions, checkFileValidation, FileAndErrors, FileTypes } from '@app/domain/files';
import { makeCommaSeparatedString } from '@app/utils/utils';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import MorteeMode from '@mortee/morteeMode';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInfraStores from '@app/hooks/useInfraStores';
import useMountEffect from '@app/hooks/useMountEffect';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import useForm from '@app/hooks/useForm';

const NOTE_MAX_LENGTH = 100;
const MAX_FILE_NAME = 80;
const MAX_FILE_SIZE_MB = 5;
const DEFAULT_MAX_FILES = 10;

interface Props extends FormComponentProps<SelectFilesFieldsValues> {
  modes: FilesUploadMode[];
  files: FileAndErrors[];
  initialNotes?: string;
  singleFile?: boolean;
  title?: string;
  onFilesUpload: (files: FileAndErrors[], notes?: string) => void;
  forbiddenFileExtensions?: string[];
  allowedFileTypes?: FileTypes[];
  isUploading?: boolean;
  maxFilesToUpload?: number;
}

interface SelectFilesFieldsValues {
  notes?: string;
}

const SelectFiles: FunctionComponent<Props> = observer((props) => {
  const filesOptions: CheckFileOptions = {
    forbiddenExtensions: props.forbiddenFileExtensions,
    allowedExtensions: props.allowedFileTypes,
    maxFilesAllowed: props.maxFilesToUpload ?? DEFAULT_MAX_FILES,
    maxFileSizeMB: MAX_FILE_SIZE_MB,
    maxFileNameLength: MAX_FILE_NAME,
  };
  const { files = [] } = props;
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { form, showFormErrors, setShowFormErrors, isFormInvalid } = useForm<SelectFilesFieldsValues>(props);

  useMountEffect(() => {
    window.onpopstate = onBackButtonEvent;
  });

  const fieldDecorators: FormFieldDecorators<SelectFilesFieldsValues> = {
    notes: {
      initialValue: props.initialNotes,
      rules: [
        {
          max: NOTE_MAX_LENGTH,
          message: `Max ${NOTE_MAX_LENGTH} characters`,
        },
      ],
    },
  };

  const fileModesNames = (): string[] => {
    const { modes, singleFile } = props;

    return modes.map((filesUploadMode) => getFilesUploadModeName(filesUploadMode, !singleFile));
  };

  const someFileErrors = (): boolean => {
    return files.some((file) => !!file.errors?.length);
  };

  const shouldDisplayUploadButton = (): boolean => {
    return !isFormInvalid && props.files.length > 0 && !someFileErrors();
  };

  const getFilesUploadModeName = (filesUploadMode: FilesUploadMode, multiple: boolean | undefined = true): string => {
    const modeNames = FilesUploadModeNames[filesUploadMode];
    return multiple ? modeNames.multiple : modeNames.single;
  };

  const calcTitleFromModes = (): ReactElement => {
    const modesNames = makeCommaSeparatedString(fileModesNames());
    const { allowedFileTypes, title, maxFilesToUpload } = props;
    if (modesNames === 'Files' && allowedFileTypes) {
      return (
        <div>
          Supported files formats are: {allowedFileTypes.join(', ')}.
          <br />
          Up to {MAX_FILE_SIZE_MB} MB per file, Up to {maxFilesToUpload ?? DEFAULT_MAX_FILES} files.
        </div>
      );
    }

    return <div>{title}</div>;
  };

  const onBackButtonEvent = (): void => {
    navigationStore.moveToSectionHome();
  };

  const handleDeleteFile = action((file: File) => {
    const index = files.map((x) => x.file).indexOf(file);
    if (index > -1) {
      files.splice(index, 1);
    }
  });

  const renderForm = (): ReactElement => {
    return (
      <NsknoxForm
        disabled={props.isUploading}
        form={form}
        appearance='line'
        showErrors={showFormErrors}
        setShowErrors={setShowFormErrors}
      >
        <FormItemBox fieldName='notes' fieldDecoratorOptions={fieldDecorators.notes}>
          <NakedFormInput
            name='inpt-select-files-notes'
            type='text'
            dataTestId='notesInputField'
            placeholder='Notes (optional)'
          />
        </FormItemBox>
      </NsknoxForm>
    );
  };

  const handleFileAdded = action((file: File): boolean => {
    const { singleFile } = props;

    const errors = checkFileValidation(file, filesOptions);

    // Keep the list with max 1 file if required
    if (!singleFile || files.length === 0) {
      files.push({ file, errors });
    } else {
      confirm({
        content: renderReplaceFileModalContent(),
        okText: 'REPLACE FILES',
        cancelText: 'NO',
        icon: <span />,
        onOk: () => {
          runInAction(() => {
            files.push({ file, errors });
            files.splice(0, 1);
          });
        },
      });
    }

    return false;
  });

  const renderReplaceFileModalContent = (): ReactElement => {
    return (
      <ModalContainer>
        <ModalTitle>One file only</ModalTitle>
        <ModalBody>
          <ModalTextLine>Only one file can be uploaded for verification. Do you want to replace the existing file?</ModalTextLine>
        </ModalBody>
      </ModalContainer>
    );
  };

  const renderFileDraggerCard = (mode: FilesUploadMode, multiple: boolean): ReactElement => {
    return (
      <StylesUploadFileCardDragger
        disabled={props.isUploading}
        id={`${mode}Dragger`}
        key={mode}
        title={getFilesUploadModeName(mode, multiple)}
        files={files}
        multiple={multiple}
        onFileAdded={(file): boolean => handleFileAdded(file)}
        onFileDeleted={(file): void => handleDeleteFile(file)}
      />
    );
  };

  const startUpload = (): void => {
    const { form, onFilesUpload, maxFilesToUpload } = props;
    const { validateFieldsAndScroll } = form;
    const maxFiles = maxFilesToUpload ?? DEFAULT_MAX_FILES;

    validateFieldsAndScroll((errors: object, values: SelectFilesFieldsValues) => {
      if (errors) {
        return;
      }

      if (files.length > maxFiles) {
        showInfoModal(
          <ModalErrorsList errors={[`You can upload up to ${maxFiles} files at a time`]} title='Unable to Submit' />,
          {
            okType: 'ghost',
            className: 'warning',
          },
        );
        return;
      }

      onFilesUpload(files, values.notes);
    });
  };

  const { modes, singleFile, title = calcTitleFromModes() } = props;

  const isSecureFilesMode = modes.includes(FilesUploadMode.secureFiles);

  return (
    <>
      <MainContainer>
        <PageTitle>{title}</PageTitle>
        <CardsContainer>
          {modes.map((mode) => {
            return renderFileDraggerCard(mode, !singleFile);
          })}
        </CardsContainer>
        <FormContainer>{renderForm()}</FormContainer>
        <ButtonContainer>
          <Button
            loading={props.isUploading}
            id='btn-select-files-submit'
            dataTestId='submitVerificationBtn'
            colorScheme='primary'
            onClick={startUpload}
            disabled={!shouldDisplayUploadButton()}
          >
            SUBMIT FILE{singleFile || 'S'} {!isSecureFilesMode && 'FOR VERIFICATION'}
          </Button>
        </ButtonContainer>
      </MainContainer>
    </>
  );
});

export default Form.create<Props>()(SelectFiles);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const PageTitle = styled.div`
  font-size: 17px;
  line-height: 1.41;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--transparent-black-700);
  margin-top: 26px;
  margin-bottom: 43px;
`;

const FormContainer = styled.div`
  margin: 24px 0;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StylesUploadFileCardDragger = styled(FileCardUploadDragger)`
  margin-left: 12px;
  margin-right: 12px;
`;
