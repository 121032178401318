import Loadable from '@app/utils/Loadable';
import validationSystemServices from '@mortee/services/validationSystemServices';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { useDebounce } from '@app/hooks/useDebounce';

export default function useBankNameByCountryCodeAndBankCode(
  countryCode: string | undefined,
  bankCode: string | undefined,
): Loadable<string | null> {
  const debouncedCountryCode = useDebounce(countryCode?.toUpperCase(), 400);
  const debouncedBankCode = useDebounce(bankCode?.toUpperCase(), 400);

  const [result] = useLoadable(async () => {
    if (!debouncedCountryCode || !debouncedBankCode) {
      return null;
    }

    return await validationSystemServices.getBankNameByCountryCodeAndBankCode(debouncedCountryCode, debouncedBankCode);
  }, [debouncedBankCode, debouncedCountryCode]);

  return result;
}
