import { VerificationResultText } from './accountVerification';

export enum DeterministicValidationResultType {
  notValidated = 'NotValidated',
  selfApproved = 'SelfApproved',
  standardValidated = 'StandardValidated',
  extendedValidated = 'ExtendedValidated',
}

export type AccountRepresentationTypeServerResponse = 'IBAN' | 'Swift' | 'Swiftban' | 'Domestic';

export function transformAccountRepresentationType(
  serverResponse: AccountRepresentationTypeServerResponse,
): AccountRepresentationType {
  switch (serverResponse) {
    case 'IBAN':
      return 'iban';
    case 'Swift':
      return 'swift';
    case 'Swiftban':
      return 'swiftban';
    case 'Domestic':
      return 'domestic';
  }
}

export interface DeterministicValidationRepresentationDataServerResponse {
  rawAccountRepresentation: MorteeAccountDetails;
  cleanedAccountRepresentation: MorteeAccountDetails;
  representationType: AccountRepresentationTypeServerResponse;
  validationResult: DeterministicValidationResultType;
}

export interface DeterministicValidationRepresentationData {
  rawAccountRepresentation: MorteeAccountDetails;
  cleanedAccountRepresentation: MorteeAccountDetails;
  representationType: AccountRepresentationType;
  validationResult: DeterministicValidationResultType;
}

export function transformDeterministicValidationRepresentationData(
  serverResponse: DeterministicValidationRepresentationDataServerResponse,
): DeterministicValidationRepresentationData {
  return {
    ...serverResponse,
    representationType: transformAccountRepresentationType(serverResponse.representationType),
  };
}

export const translateDeterministicValidationLevel = (
  validationLevel: DeterministicValidationResultType,
): VerificationResultText => {
  switch (validationLevel) {
    case DeterministicValidationResultType.notValidated: {
      return VerificationResultText.notValidated;
    }
    case DeterministicValidationResultType.selfApproved: {
      return VerificationResultText.selfApproved;
    }
    case DeterministicValidationResultType.standardValidated: {
      return VerificationResultText.standardValidation;
    }
    case DeterministicValidationResultType.extendedValidated: {
      return VerificationResultText.extendedValidation;
    }
    default: {
      return VerificationResultText.unknownResult;
    }
  }
};