import React, { FC } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import ArMode from '@ar/arMode';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import SearchPayee from '@ar/routes/initialPage/SearchPayee';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import browserHistory from '@app/utils/browserHistory';
import useInfraStores from '@app/hooks/useInfraStores';
import ARAboveMainCardTitle from '@ar/routes/ARAboveMainCardTitle';
import { Bold16Primary300 } from '@app/components/Text';
import useMountEffect from '@app/hooks/useMountEffect';
import { useTranslation } from 'react-i18next';

const GuestVerificationChooseSearch: FC = observer(() => {
  useInnerPageEnterAnalyticsReport(InnerPage.verify);
  const { navigationStore } = useInfraStores<ArMode>();
  const { arVerificationStore } = useAppStores<ArMode>();
  const { t } = useTranslation();

  useMountEffect(() => {
    // initial cleanup
    arVerificationStore.resetAll();
  });

  const onPayeeSelected = (payee: AggregatedValidatedPayee): void => {
    arVerificationStore.resetToAfterPayeeSelected(payee);
    browserHistory.push(navigationStore.generatePayeeVerificationEnterAccountPageLink(payee.uniformId));
  };

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <ARAboveMainCardTitle title={t('ar.guestVerification.details.accountVerification')} showBackButton />
      <SearchPayeeCard>
        <SearchPayeeCardInnerTitle>{t('ar.guestVerification.details.enterPayeeDetails')}</SearchPayeeCardInnerTitle>
        <SearchPayee onPayeeSelected={onPayeeSelected} />
      </SearchPayeeCard>
    </PageWithHeader>
  );
});

export default GuestVerificationChooseSearch;

const SearchPayeeCard = styled(Card)`
  padding: 30px 40px 100px;
`;

const SearchPayeeCardInnerTitle = styled(Bold16Primary300.div)`
  margin-bottom: 24px;
`;
