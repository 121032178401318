import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import SearchAccount, { VerifyAccountFormFields } from '@app/components/accountVerification/SearchAccount';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';
import { FormComponentProps } from 'antd/lib/form';
import { Form } from 'antd';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import { removeIrrelevantAccountDetailsFields } from '@app/domain/accountsDetailsHelpers';
import { verifyAccountByUniformId } from '@mortee/services/accountVerificationService';
import Log from '@app/libs/logger';
import { VERIFICATION_ACCOUNT_TYPE } from '@app/domain/accountVerification';
import { UnifiedPayeeIds } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';

interface Props extends FormComponentProps<VerifyAccountFormFields> {
  isFormDisabled?: boolean;
  className?: string;
  initialValues: VerifyAccountFormFields;
  payeeIdentifier?: UnifiedPayeeIds;
  updateFormValues?: (values: VerifyAccountFormFields) => void;
  setSubmitting: (boolean) => void;
  isSubmitting: boolean;
  payeeCountryCode?: string | null;
}

const VerifyAccountForm: FC<Props> = observer((props) => {
  const { payeeSearchStore } = useAppStores<MorteeMode>();

  const [showFormErrors, setShowFormErrors] = useState<FormItemsBoxProps['showErrors']>('onTouch');

  const { form, updateFormValues, setSubmitting, isSubmitting } = props;
  const handleSubmit = async (): Promise<void> => {
    form.validateFieldsAndScroll(async (errors: Object, values: VerifyAccountFormFields) => {
      if (errors) {
        setShowFormErrors('all');
        return;
      }

      const { isFormDisabled } = props;

      if (isFormDisabled || !props.payeeIdentifier) {
        throw new Error(`handleSubmit: Could not submit a disabled form`);
      }

      if (updateFormValues) {
        updateFormValues(values);
      }

      setSubmitting(true);

      if (values.type !== VERIFICATION_ACCOUNT_TYPE.domestic) {
        // remove initial value in case the actual is not domestic
        values.accountDetails.countryCode = null;
      }

      const searchAccountDetails: VerifyAccountFormFields = {
        ...values,
        accountDetails: removeIrrelevantAccountDetailsFields(values.accountDetails),
      };

      try {
        const verificationResponse = await verifyAccountByUniformId(props.payeeIdentifier, searchAccountDetails.accountDetails);

        payeeSearchStore.setVerificationResult(verificationResponse, searchAccountDetails.accountDetails, false);
      } catch (e: unknown) {
        payeeSearchStore.setVerificationResult(undefined, searchAccountDetails.accountDetails, true);

        Log.exception(e);
      } finally {
        setSubmitting(false);
      }
    });
  };

  const { initialValues, isFormDisabled, payeeCountryCode } = props;

  return (
    <Container>
      <SearchAccount
        trySubmit={handleSubmit}
        isFormDisabled={isFormDisabled}
        initialFormValues={initialValues}
        showFormErrors={showFormErrors}
        payeeCountryCode={payeeCountryCode}
        resetShowFormErrors={(): void => setShowFormErrors('onTouch')}
        form={form}
        appearance='line'
        stretch
      />
      <ValidateButtonContainer>
        <ValidateButton
          id='btn-validation-validate'
          colorScheme='primary'
          onClick={handleSubmit}
          disabled={isFormDisabled}
          loading={isSubmitting}
          dataTestId='btnValidate'
        >
          VERIFY
        </ValidateButton>
      </ValidateButtonContainer>
    </Container>
  );
});

export default Form.create<Props>()(VerifyAccountForm);

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

// isError - when form has visible errors add more padding to the bottom
const ValidateButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 88px;
`;

const ValidateButton = styled(Button)`
  margin-left: 16px;
`;
