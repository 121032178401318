import React, { FC, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';
import { TooltipProps } from 'antd/lib/tooltip';
import useMountEffect from '@app/hooks/useMountEffect';

const TooltipWhenEllipsis: FC<TooltipProps> = ({ children, ...tooltipProps }) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  const [isTextCut, setIsTextCut] = useState(false);

  const compareSize = (): void => {
    const compare = (textElementRef?.current?.scrollWidth ?? 0) > (textElementRef?.current?.clientWidth ?? 0);
    setIsTextCut(compare);
  };

  useMountEffect((): VoidFunction => {
    compareSize();
    // Start listen on component mount
    window.addEventListener('resize', compareSize);

    return (): void => {
      // Stop listen on component unmount
      window.removeEventListener('resize', compareSize);
    };
  });

  const content = <SizeMeasureWrapper ref={textElementRef}>{children}</SizeMeasureWrapper>;

  if (!isTextCut) {
    return content;
  }

  return <Tooltip {...tooltipProps}>{content}</Tooltip>;
};

export default TooltipWhenEllipsis;

const SizeMeasureWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
