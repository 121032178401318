import React, { FC, ReactElement } from 'react';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';

interface Props {
  orgName: string;
}

const ReferringOrg: FC<Props> = ({ orgName }): ReactElement => {
  return (
    <Container data-testid='referring-org'>
      <Trans i18nKey='supplierValidation.referringOrg' values={{ orgName }}>
        You have been invited to register to PaymentKnox by <OrgNameText>{orgName}</OrgNameText>
      </Trans>
      <SeparatorLine />
    </Container>
  );
};

export default ReferringOrg;

const SeparatorLine = styled.hr`
  border: solid 1px #b0b0b0;
  margin-top: 20px;
`;

const OrgNameText = styled.span`
  font-weight: bold;
`;

const Container = styled.div`
  font-size: 16px;
  text-align: center;
  color: #191919;
  padding-top: 30px;
`;
