import PayeeSearchStore from '@mortee/stores/appStores/PayeeSearchStore';
import PaymentCheckStore from '@mortee/stores/appStores/PaymentCheckStore';
import SupplierRegistrationForManagementStore from '@mortee/stores/appStores/SupplierRegistrationForManagementStore';
import MasterDataGuardStore from '@mortee/stores/appStores/MasterDataGuardStore';
import MorteeInfraStores from '@mortee/stores/infraStores/MorteeInfraStores';
import ValidatedPayeesManagementStore from '@mortee/stores/appStores/ValidatedPayeesManagementStore';
import SftpStore from '@mortee/stores/appStores/SftpStore';
import ValidationSystemStore from '@mortee/stores/appStores/ValidationSystemStore';

export default class MorteeAppStores {
  readonly payeeSearchStore: PayeeSearchStore;
  readonly paymentCheckStore: PaymentCheckStore;
  readonly masterDataGuardStore: MasterDataGuardStore;
  readonly supplierRegistrationForManagementStore: SupplierRegistrationForManagementStore;
  readonly validatedPayeesManagementStore: ValidatedPayeesManagementStore;
  readonly sftpStore: SftpStore;
  readonly validationSystemStore: ValidationSystemStore;

  constructor(infraStores: MorteeInfraStores) {
    this.payeeSearchStore = new PayeeSearchStore();
    this.paymentCheckStore = new PaymentCheckStore();
    this.masterDataGuardStore = new MasterDataGuardStore(
      infraStores.userStore,
      infraStores.authenticationStore,
      infraStores.permissionsStore,
    );
    this.supplierRegistrationForManagementStore = new SupplierRegistrationForManagementStore();
    this.validatedPayeesManagementStore = new ValidatedPayeesManagementStore(infraStores.navigationStore);
    this.sftpStore = new SftpStore();
    this.validationSystemStore = new ValidationSystemStore(infraStores.userStore);
  }
}
