import { SsoParams } from '@app/guestLogin/domain/sso';

export enum GuestAuthMethodType {
  EmailPassword = 'EmailPassword',
  SSO = 'sso',
  PhoneNumber = 'PhoneNumber',
}

export enum GuestAuthCodeDeliveryMethod {
  TextMessage = 'TextMessage',
  PhoneCall = 'PhoneCall',
}

export const authMethodTypeOrder = Object.values(GuestAuthMethodType);

export interface GuestKnoxerAddressData {
  addressEmail?: string;
  addressPhoneSms?: string;
  addressPhoneCall?: string;
  authMethod: GuestAuthMethodType;
  ssoParams?: SsoParams;
}

export interface GuestLoginCredentials {
  phoneToken?: string;
  emailToken?: string;
  ssoToken?: string;
}

export interface RequestWithKnoxId {
  knoxerId: string;
}

export interface CodeRequest extends RequestWithKnoxId {
  id: string;
}

export interface CodeResponse {
  flowId: string;
  codeTTLMinutes: number;
  codeLength: number;
}

export interface TokenRequest extends RequestWithKnoxId {
  flowId: string;
  code: string;
  id: string;
}

export interface TokenResponse {
  expiration: number;
  refreshToken: string | null;
  refreshExpiration: number;
  idToken: string | null;
  tokenType: string;
  otherClaims: any;
  scope: string;
}

export enum GuestLoginStep {
  Welcome,
  Email,
  Phone,
  Account,
  AfterValidation,
}
