import React, { FC } from 'react';
import { Assignee } from '@mortee/domain/validationSystem';
import InitialsCircle from '@app/components/InitialsCircle';
import SVG from '@app/components/SVG';
import PersonCircleIcon from '@app/images/person-circle.svg';
import styled from '@emotion/styled';
import AssigneeText from '@mortee/components/AssigneeText';

interface Props {
  assignee: Assignee | null;
  className?: string;
  greyedOut?: boolean;
}

const AssigneeWithInitials: FC<Props> = ({ assignee, className, greyedOut }) => {
  return (
    <Row className={className}>
      {assignee ? (
        <InitialsCircle name={assignee.name} />
      ) : (
        <SVG accessibilityLabel='unknown assignee' image={PersonCircleIcon} width={30} height={30} />
      )}
      <AssigneeText assignee={assignee} greyedOut={greyedOut} />
    </Row>
  );
};

export default AssigneeWithInitials;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
