import { observer } from 'mobx-react';
import React, { FC, ReactNode } from 'react';
import PhoneLoginEnterPhone from './PhoneLoginEnterPhone';
import PhoneLoginEnterCode from './PhoneLoginEnterCode';
import { PhoneLoginState, UnclaimedPhoneNumberKnoxer } from '@app/login/domain/loginConsts';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';
import { CountryCode } from '@app/domain/countries';

interface PhoneLoginFlowProps {
  knoxerToClaim: UnclaimedPhoneNumberKnoxer;
  firstGroupFooter?: ReactNode;
  onBack: () => void;
}

const PhoneLoginFlow: FC<PhoneLoginFlowProps> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const handlePhoneEntered = (dialCode: string, phoneNumber: string, countryCode: CountryCode): void => {
    authenticationStore.handlePhoneEntered(dialCode, phoneNumber, countryCode);
  };

  const handleReturnToPhoneEnter = (): void => {
    const { knoxerToClaim, onBack } = props;

    // if the knoxer being claimed is not the first "phone knoxer" then go back to the start
    if (knoxerToClaim.positionInMethodGroup > 0) {
      return onBack();
    }

    authenticationStore.returnToPhoneSelection();
  };

  const { knoxerToClaim, onBack, firstGroupFooter } = props;

  if (!knoxerToClaim) {
    return <div />;
  }

  const { knoxer, authMethodData, positionInMethodGroup } = knoxerToClaim;
  const { id, authData } = knoxer;
  const { userIdentification, knoxersOfMethod } = authMethodData;

  const flowState = userIdentification?.state ?? PhoneLoginState.EnterPhone;

  switch (flowState) {
    case PhoneLoginState.EnterPhone: {
      return (
        <>
          <PhoneLoginEnterPhone
            defaultPhone={userIdentification?.phoneNumber}
            defaultDialCode={userIdentification?.dialCode}
            defaultCountryCode={userIdentification?.countryCode}
            onBack={onBack}
            onSubmit={handlePhoneEntered}
          />
          {firstGroupFooter}
        </>
      );
    }
    case PhoneLoginState.EnterCode: {
      if (!userIdentification?.dialCode || !userIdentification?.phoneNumber || !authData) {
        throw new Error(`PhoneAuthentication - Data missing for code enter, knoxerToClaim = ${JSON.stringify(knoxerToClaim)}`);
      }

      return (
        <PhoneLoginEnterCode
          key={id}
          knoxerId={id}
          numberOfCodeSent={positionInMethodGroup}
          totalOfCodesRequired={knoxersOfMethod.length}
          flowId={authData.flowId}
          codeLength={authData.codeLength}
          phoneNumber={joinNationalPhoneAndDialCode(userIdentification.dialCode, userIdentification.phoneNumber)}
          deliveryMethod={authData.deliveryMethod}
          onBack={handleReturnToPhoneEnter}
        />
      );
    }
  }

  return <div />;
});

export default PhoneLoginFlow;
