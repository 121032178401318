import { SUPPLIER_VALIDATION_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import { trim } from '@app/utils/stringUtils';
import { emailAdditionalValidations } from '@app/utils/validators';
import { GetFieldDecoratorOptionsExtended } from '@app/utils/form/form';

export function createInitiatorEmailDecorator(initialValue?: string | null): GetFieldDecoratorOptionsExtended {
  return {
    initialValue: initialValue ?? undefined,
    rules: [
      {
        max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.initiatorEmail,
        transform: trim,
        message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.initiatorEmail} characters`,
      },
      {
        type: 'email',
        transform: trim,
        translatedMessage: 'Invalid email',
      },
      {
        validator: emailAdditionalValidations('Invalid email'),
      },
    ],
  };
}
