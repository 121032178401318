import React, { FunctionComponent } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

const WhiteWarning: FunctionComponent = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 96 96'>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#FFF'
          d='M50.078 13.724c.431.284.8.652 1.083 1.083l40.985 62.332A3.783 3.783 0 0 1 88.986 83H7.014a3.783 3.783 0 0 1-3.16-5.86l40.985-62.333a3.783 3.783 0 0 1 5.24-1.083zm-2.851 3.496l-.069.094L7.986 78.461a1 1 0 0 0 .726 1.532l.116.007h78.344a1 1 0 0 0 .899-1.438l-.057-.101-39.172-61.147a1 1 0 0 0-1.615-.094zm1.948 48.792c.522 0 .945.423.945.945v1.892a.946.946 0 0 1-.945.945h-1.892a.946.946 0 0 1-.945-.945v-1.892c0-.522.423-.945.945-.945h1.892zm-.074-28.37c1.044 0 1.89.846 1.89 1.891v.073l-.836 21.714a.946.946 0 0 1-.945.909h-1.962a.946.946 0 0 1-.945-.91l-.835-21.713a1.891 1.891 0 0 1 1.817-1.963l.073-.001H49.1z'
        />
      </g>
    </svg>
  );
};

export const WhiteWarningSvgString = encodeURIComponent(renderToStaticMarkup(<WhiteWarning />));

export default WhiteWarning;
