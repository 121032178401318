import React, { FC, ReactNode } from 'react';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import AboveMainCardTitle from '@app/components/AboveMainCardTitle';
import browserHistory from '@app/utils/browserHistory';

interface Props {
  title?: string;
  showBackButton?: boolean;
  children?: ReactNode;
  backButtonHandler?: () => void;
}

const ARAboveMainCardTitle: FC<Props> = ({ title, showBackButton, children, backButtonHandler }) => {
  const { navigationStore } = useInfraStores<ArMode>();

  let backButtonAction;

  const defaultBackButtonHandler = (): void => {
    browserHistory.push(navigationStore.generateStartPageLink());
  };

  if (showBackButton) {
    backButtonAction = backButtonHandler || defaultBackButtonHandler;
  }

  return <AboveMainCardTitle title={title} children={children} backButtonAction={backButtonAction} />;
};

export default ARAboveMainCardTitle;
