import styled from '@emotion/styled';
import { Tabs as AntdTabs } from 'antd';
import { TabsProps as AntdTabsProps } from 'antd/lib/tabs';
import React, { FC } from 'react';

type TabsPosition = 'center' | 'left';

interface TabsProps extends Omit<AntdTabsProps, 'tabPosition'> {
  tabPosition?: TabsPosition;
  hideTabBarLine?: boolean;
}

const Tabs: FC<TabsProps> = ({ tabPosition, ...antdTabsProps }) => {
  return <StyledTabs {...antdTabsProps} tabPositionOverride={tabPosition} />;
};

const StyledTabs = styled(AntdTabs)<{ tabPositionOverride: TabsPosition | undefined; hideTabBarLine?: boolean }>`
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-nav {
    font-family: var(--text-font-family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-strong);

    .ant-tabs-tab-active {
      color: var(--black-strong);
      font-weight: bold;
    }
  }

  // Use grid to make the tabs fully centered, no matter the size of the right actions bar
  // Previously, resizing the right action button changed the "center" of the tabs because the tabs were centered according the
  // space left after the positioning of the right button
  .ant-tabs-bar {
    display: grid;
    grid-template-columns: ${(p): string => (p.tabPositionOverride === 'left' ? '1fr auto auto' : '1fr auto 1fr')};
    grid-template-areas: ${(p): string =>
      p.tabPositionOverride === 'left' ? `'tabs extraActionLeft extraActionRight'` : `'extraActionLeft tabs extraActionRight'`};
    ${(p): string => (p.hideTabBarLine ? `border-bottom: none;` : ``)}
  }

  .ant-tabs-nav-container {
    grid-area: tabs;

    // Stick nav buttons to bottom of nav
    display: flex;
    align-items: flex-end;
  }

  .ant-tabs-extra-content {
    grid-area: extraActionRight;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .ant-tabs-content-animated {
    // Disable swipe animation
    transition: none !important;
    will-change: auto !important;
  }

  .ant-tabs-tabpane {
    // Make fade animation faster
    transition: opacity 0.25s !important;
  }

  .ant-tabs-ink-bar {
    background-color: var(--accent-blue-600);
  }
`;

export default Tabs;
export const TabPane = AntdTabs.TabPane;
