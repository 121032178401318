import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import CheckEmptyResults from '@mortee/components/check/CheckEmptyResults';
import MvfCheckSurveysTable from '@mortee/routes/masterDataGuard/mvfCheck/mvfSurverysListPage/MvfCheckSurveysTable';
import MvfCheckSurveyModel from '@mortee/models/MvfCheckSurveyModel';
import config from '@app/config';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import SyncInfoLine from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/SyncInfoLine';
import useInterval from '@app/hooks/useInterval';
import useInfraStores from '@app/hooks/useInfraStores';
import useMountEffect from '@app/hooks/useMountEffect';

interface MvfSurveysListPageProps {
  handleFirstVerification: () => void;
}

const MvfSurveysListPage: FunctionComponent<MvfSurveysListPageProps> = observer((props: MvfSurveysListPageProps) => {
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { masterDataGuardStore } = useAppStores<MorteeMode>();

  const { handleFirstVerification } = props;

  const isAnySurveyInProgress = (surveys: MvfCheckSurveyModel[]): boolean => {
    return surveys.some((survey) => survey.isInProgress);
  };

  useMountEffect((): void => {
    masterDataGuardStore.resetBatches();
    masterDataGuardStore.loadCurrentPageSurveys();
  });

  useInterval(
    (): void => {
      // practically, at most one inProgress survey
      masterDataGuardStore.surveys
        .filter((survey) => survey.isInProgress)
        .forEach((inProgressSurvey) => masterDataGuardStore.getFileSurveyById(inProgressSurvey.id));
    },
    !masterDataGuardStore.surveys?.length || isAnySurveyInProgress(masterDataGuardStore.surveys)
      ? config.mvfCheckInProgressSurveysPollingIntervalSec * 1000
      : null,
  );

  const handleNewVerification = (): void => {
    handleFirstVerification();
  };

  const renderCardEmptyContent = (): ReactElement => {
    const startVerificationButtonText = permissionsStore.isCreateMvfFileOnMvfCheckEnable
      ? 'START YOUR FIRST VERIFICATION'
      : undefined;

    return (
      <CheckEmptyResults
        titleText='No verifications yet...'
        subTitleText='Here you will see the results of the verifications'
        buttonText={startVerificationButtonText}
        handleNewVerification={handleNewVerification}
      />
    );
  };

  const { surveys, surveyLoadingState, fetchFileSurveyFile } = masterDataGuardStore;
  const { lastSyncInfo } = masterDataGuardStore;

  return (
    <StyledPageContainer>
      <StyledSyncInfo info={lastSyncInfo} />
      <MvfCheckSurveysTable
        surveys={surveys}
        loadingState={surveyLoadingState}
        fetchSurveyFile={fetchFileSurveyFile}
        emptyComponent={renderCardEmptyContent()}
      />
    </StyledPageContainer>
  );
});

export default MvfSurveysListPage;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledSyncInfo = styled(SyncInfoLine)`
  margin-left: 26px;
  margin-bottom: 8px;
`;
