import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import LogoImage from '@app/images/nsknox_logo.svg';
import SVG from '@app/components/SVG';
import LocalAuthenticationFlow from './LocalAuthenticationFlow';
import { observer } from 'mobx-react';
import Card from '@app/components/card/Card';
import Footer from '@app/components/Footer';
import Loader from '@app/components/Loader';
import { LoginMode } from '@app/login/domain/loginConsts';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import MultipleSSOAuthenticationFlow from '@app/login/routes/sso/MultipleSSOAuthenticationFlow';
import { useSingleQueryParam } from '@app/hooks/useSingleQueryParam';

const LoginPage: FC = observer(() => {
  const headerRender = (): ReactElement => (
    <HeaderContainer>
      <StyledLogo accessibilityLabel='nsKnox logo' image={LogoImage} />
    </HeaderContainer>
  );

  const { authenticationStore, userStore } = useInfraStores<AuthenticatedMode>();
  const [isMultipleSSO] = useSingleQueryParam('multipleSSO', /^true$/);

  const renderContentByLoginMode = (loginMode: LoginMode): ReactNode => {
    if (loginMode !== LoginMode.Local) {
      return;
    }

    if (isMultipleSSO === 'true') {
      return (
        <AuthenticationCard>
          <MultipleSSOAuthenticationFlow />
        </AuthenticationCard>
      );
    }

    return (
      <AuthenticationCard>
        <LocalAuthenticationFlow />
      </AuthenticationCard>
    );
  };

  const { loginMode } = authenticationStore;
  const { getUserInfoLoading } = userStore;

  if (getUserInfoLoading) {
    return <Loader fullScreen spinning />;
  }

  return (
    <Container>
      {headerRender()}
      <ContentContainer>{renderContentByLoginMode(loginMode)}</ContentContainer>
      <Footer />
    </Container>
  );
});

export default LoginPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /* this to solve "the content will not be cut when the window is smaller than the content" */
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const ContentContainer = styled.div`
  z-index: 20;
  margin-top: -156px;
  flex: 1;
`;

const AuthenticationCard = styled(Card)`
  width: 586px;
  padding: 0;
`;

const HeaderContainer = styled.header`
  background-color: var(--app-main-color);
  height: 334px;
  max-height: 334px;
  z-index: 15;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const StyledLogo = styled(SVG)`
  margin-top: 62px;
  height: 46px;
`;
