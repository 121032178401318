import { ColorScheme, ColorSchemeObject, getColorScheme } from '@app/domain/theme';
import useTheme from '@app/hooks/useTheme';
import { useContext } from 'react';
import FormInputsContext from '@app/components/inputs/FormInputsContext';

export default function useColorScheme(...customColorSchemes: (ColorScheme | undefined)[]): ColorSchemeObject {
  const { actionColor: themeDefaultActionColor } = useTheme();
  const { colorScheme: contextColorScheme } = useContext(FormInputsContext);

  return getColorScheme(...customColorSchemes, contextColorScheme, themeDefaultActionColor);
}
