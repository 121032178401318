import React, { FC, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { BodyRegularStartTransparentBlack600, Bold13TransparentBlack900, H5StartTransparentBlack900 } from '@app/components/Text';
import SendValidationEmailToPayeeForm, {
  SendValidationEmailToPayeeFormFields,
} from '@mortee/routes/accountVerification/sendValidationEmailToPayee/SendValidationEmailToPayeeForm';
import useLoadable from '@app/hooks/loadable/useLoadable';
import TransitionLoader from '@app/components/TransitionLoader';
import { css } from '@emotion/css';
import { getValidationEmailToPayeeValues, sendValidationEmailToPayee } from '@mortee/services/accountVerificationService';
import Loader from '@app/components/Loader';
import SendValidationEmailToPayeeSummary from '@mortee/routes/accountVerification/sendValidationEmailToPayee/SendValidationEmailToPayeeSummary';
import { shoot } from '@app/utils/messageLauncher';

interface Props {
  validatedPayeeUniformId: string;
  validatedPayeeAccountUniformId: string;
  onDone(): void;
}

interface FormViewState {
  view: 'form';
  formValues: SendValidationEmailToPayeeFormFields | null;
}

interface SummaryViewState {
  view: 'summary';
  formValues: SendValidationEmailToPayeeFormFields;
}

type ViewState = FormViewState | SummaryViewState;
const viewStatesOrder: ViewState['view'][] = ['form', 'summary'];

const SendValidationEmailToPayeeModal: FC<Props> = (props) => {
  const { validatedPayeeUniformId, validatedPayeeAccountUniformId, onDone } = props;

  const [isSendingEmailInProgress, setIsSendingEmailInProgress] = useState<boolean>(false);
  const [viewState, setViewState] = useState<ViewState>({ view: 'form', formValues: null });

  const [toAddressesValues] = useLoadable(
    async (): Promise<string[] | null> =>
      await getValidationEmailToPayeeValues(validatedPayeeUniformId, validatedPayeeAccountUniformId),
    [],
  );

  const onFormFilled = (formValues: SendValidationEmailToPayeeFormFields): void => {
    setViewState({ view: 'summary', formValues: formValues });
  };

  const onSummaryBack = (): void => {
    setViewState({ view: 'form', formValues: viewState.formValues });
  };

  const onSendEmail = async (values: SendValidationEmailToPayeeFormFields): Promise<void> => {
    setIsSendingEmailInProgress(true);

    try {
      await sendValidationEmailToPayee(validatedPayeeUniformId, validatedPayeeAccountUniformId, values);
      shoot({ type: 'success', closeable: true }, 'Email sent to payee');
      onDone();
    } finally {
      setIsSendingEmailInProgress(false);
    }
  };

  return (
    <Card id='send-validation-email-to-payee-modal'>
      <TransitionLoader loading={isSendingEmailInProgress} contentClassName={modalContentCss}>
        <TitleLine>
          <Title>
            <Bold13TransparentBlack900.div>
              Step {viewStatesOrder.indexOf(viewState.view) + 1} of {viewStatesOrder.length}
            </Bold13TransparentBlack900.div>
            <H5StartTransparentBlack900.div>Send Email to Validated Payee</H5StartTransparentBlack900.div>
          </Title>
          <CloseButton
            id='btn-send-validation-email-to-payee-modal-close'
            appearance='text'
            colorScheme='primary'
            onClick={onDone}
          >
            <SVG accessibilityLabel='close' image={ClearIcon} height={20} />
          </CloseButton>
        </TitleLine>
        {viewState.view === 'form' ? (
          toAddressesValues.resolve<ReactNode>(
            (loadedToAddressesValues) => (
              <StyledSendValidationEmailToPayeeForm
                initialToAddressesFormValues={viewState.formValues?.to ?? loadedToAddressesValues}
                onFormSubmit={onFormFilled}
              />
            ),
            () => <Loader spinning />,
            () => (
              <BodyRegularStartTransparentBlack600.div>
                Failed to load data required for this process
              </BodyRegularStartTransparentBlack600.div>
            ),
          )
        ) : (
          <StyledSendValidationEmailToPayeeSummary values={viewState.formValues} onOk={onSendEmail} onBack={onSummaryBack} />
        )}
      </TransitionLoader>
    </Card>
  );
};

export default SendValidationEmailToPayeeModal;

const modalContentCss = css`
  width: min(600px, 90vw);
  min-height: 400px;
  max-height: 90vh;

  padding: 26px 32px 32px;
  position: relative;

  display: flex;
  flex-direction: column;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 auto;

  margin-bottom: 4px;
`;

const Title = styled.div`
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const StyledSendValidationEmailToPayeeForm = styled(SendValidationEmailToPayeeForm)`
  flex: 1;
  padding-top: 20px;
`;

const StyledSendValidationEmailToPayeeSummary = styled(SendValidationEmailToPayeeSummary)`
  flex: 1;
`;
