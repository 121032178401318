import React, { FC } from 'react';
import Button, { ButtonProps } from '@app/components/Button';
import NoDataImage from '@app/images/table/no-data.svg';
import SVG from '@app/components/SVG';
import { BodySmallStartTransparentBlack900, Regular28TransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';

interface Props {
  content: string;
  button?: ButtonProps;
  className?: string;
}

const TableEmptyData: FC<Props> = ({ content, button, className }) => {
  return (
    <Container className={className}>
      <NoDataSVG accessibilityLabel='' image={NoDataImage} width={77} height={70} />
      <MainText>No data</MainText>
      <SecondaryText>{content}</SecondaryText>
      {button && <ActionButton appearance='text' {...button} />}
    </Container>
  );
};

export default TableEmptyData;

const Container = styled.div`
  padding: 80px 10px;
`;

const NoDataSVG = styled(SVG)`
  margin-bottom: 50px;
`;

const MainText = styled(Regular28TransparentBlack900.div)`
  margin-bottom: 8px;
`;

const SecondaryText = styled(BodySmallStartTransparentBlack900.div)`
  white-space: pre-wrap;
  text-align: center;
`;

const ActionButton = styled(Button)`
  margin-top: 22px;
  display: inline-block;
`;
