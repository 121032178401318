import {
  LegalEntityIdentifier,
  LegalEntityIdentifierServerResponse,
  StrongLegalIdentifies,
  StrongLegalIdentifiesServerResponse,
} from '@app/domain/legalEntityIdentifier';
import { minValue } from '@app/utils/arrayUtils';
import {
  SanctionsScreeningInfo,
  SanctionsScreeningResultStatus,
  ValidatedPayeePrimaryFields,
  ValidatedPayeePrimaryFieldsServerResponse,
} from '@app/domain/validatedPayee';
import { compare } from '@app/utils/comparatorUtils';
import { ValidatedPayeeAccountVisibility } from '@mortee/domain/vaildatedPayeeManagement';

export interface AggregatedValidatedPayeeServerResponse {
  uniformId: string;
  addresses: string[];
  emails: string[];
  phones: string[];
  faxes: string[];
  websites: string[];
  names: string[];
  descriptions: string[];
  legalIdentifiers: LegalEntityIdentifierServerResponse[];
  countryCode?: string;
  primaryFields: ValidatedPayeePrimaryFieldsServerResponse | null;
  strongLegalIdentifies?: StrongLegalIdentifiesServerResponse;
  ownerOrgId: string | null;
  visibility: ValidatedPayeeAccountVisibility | null;
}

export interface AggregatedValidatedPayee {
  uniformId: string;
  addresses: string[];
  emails: string[];
  phones: string[];
  faxes: string[];
  websites: string[];
  names: string[];
  mainName: string | undefined;
  descriptions?: string[];
  legalIdentifiers: LegalEntityIdentifier[];
  countryCode?: string;
  primaryFields: ValidatedPayeePrimaryFields | null;
  strongLegalIdentifies?: StrongLegalIdentifies;
  ownerOrgId: string | null;
}

export interface ValidatedPayeeVisibility {
  value: ValidatedPayeeAccountVisibility | null;
  organizationId: string | null;
  organizationName: string | null;
}

export interface ValidatedPayeeToDisplay {
  uniformId: string | null;
  addresses: string[];
  emails: string[];
  phones: string[];
  faxes: string[];
  websites: string[];
  mainName: string | undefined;
  names: string[];
  descriptions?: string[];
  legalIdentifiers: LegalEntityIdentifier[];
  countryCode?: string;
  primaryFields: ValidatedPayeePrimaryFields | null;
  strongLegalIdentifies?: StrongLegalIdentifies;
  sanctionsScreeningInfo?: SanctionsScreeningInfo | null;
  sanctionsScreeningStatusResult?: SanctionsScreeningResultStatus | null;
  effectiveDate?: number | null;
  visibility?: ValidatedPayeeVisibility;
}

export function transformAggregatedValidatedPayee(
  aggregatedPayee: AggregatedValidatedPayeeServerResponse,
): AggregatedValidatedPayee {
  return {
    ...aggregatedPayee,
    mainName: getPayeeMainName(aggregatedPayee),
  };
}

function getPayeeMainName(aggregatedPayee: AggregatedValidatedPayeeServerResponse): string | undefined {
  const { names, primaryFields } = aggregatedPayee;

  return primaryFields?.name || minValue(names, compare.stringsCaseInsensitive()) || undefined;
}
