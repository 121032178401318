import React, { FC, ReactElement, ReactNode } from 'react';
import { ValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import InnerCard from '@app/components/card/InnerCard';
import { SubtitleRegularStartTransparentBlack900, SubtitleSmallStartTransparentBlack900 } from '@app/components/Text';
import { RowSeparator } from '@app/components/Styles';
import styled from '@emotion/styled';
import {
  getRepresentationNameWithBIC,
  splitAccountDetailsIntoRepresentationsMap,
} from '@app/domain/accountDetailsRepresentations';
import { getRepresentationFormatterWithBIC } from '@app/domain/accountDetailsFormatters';
import ReactPlaceholder from 'react-placeholder';
import BankIcon from '@app/images/bank.svg';
import downloadCertificate from '@app/images/certificate-with-black-outline.svg';
import SVG from '@app/components/SVG';
import withPlaceholder from '@app/components/withPlaceholder';
import { getCountryByCountryCode } from '@app/domain/countries';
import NationalityFlag from '@app/components/NationalityFlag';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import { getBankAccountCertificatePdf } from '@mortee/services/ipsServices';
import { downloadResourceByNamedResource } from '@app/utils/fileUtils';
import Log from '@app/libs/logger';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { shoot } from '@app/utils/messageLauncher';
import sleep from '@app/utils/sleep';
import { AttachmentSource, downloadAccountAttachment } from '@app/services/attachmentServices';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';

interface DataProps {
  validatedPayeeAccount: ValidatedPayeeAccount;
  dataTestId: string;
}

interface DisplayProps {
  className?: string;
}

const MyValidatedPayeeAccountCard: FC<DisplayProps & DataProps> = (props) => {
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { validatedPayeeAccount, dataTestId, className } = props;

  const downloadBankAccountCertificatePdf = async (
    validatedPayeeUniformId: string,
    validatedPayeeAccountUniformId: string,
  ): Promise<void> => {
    const closePopUpMessage = shoot({ type: 'info' }, 'Downloading bank account certificate');

    try {
      const resource = await getBankAccountCertificatePdf(validatedPayeeUniformId, validatedPayeeAccountUniformId);

      downloadResourceByNamedResource(resource);

      closePopUpMessage();

      await sleep(300);

      shoot({ type: 'success', duration: 5, closeable: true }, 'Downloaded');
    } catch (e) {
      closePopUpMessage();
      Log.exception(e);
    }
  };

  const downloadPdfCallback = (): Promise<void> =>
    downloadBankAccountCertificatePdf(validatedPayeeAccount.payeeUniformId, validatedPayeeAccount.uniformId);
  const menuItems: MenuItem[] = [
    {
      id: 'btn-bank-account-certificate-download-menu',
      text: 'Download certificate',
      icon: downloadCertificate,
      onClick: downloadPdfCallback,
    },
  ];

  if (validatedPayeeAccount.attachments) {
    menuItems.push(
      ...validatedPayeeAccount.attachments.map((attachment) => ({
        id: `${attachment.id}-download-menu`,
        dataTestId: `${attachment.fileMetadata.name}-download-menu`,
        text: attachment.description,
        icon: downloadCertificate,
        onClick: (): Promise<void> =>
          downloadAccountAttachment(
            AttachmentSource.ips,
            validatedPayeeAccount.payeeUniformId,
            validatedPayeeAccount.uniformId,
            attachment.id,
          ),
      })),
    );
  }

  // presentationAccountNumber should get priority over accountNumber in case it exists
  validatedPayeeAccount.accountDetails.accountNumber =
    validatedPayeeAccount.presentationAccountNumber || validatedPayeeAccount.accountDetails.accountNumber;

  const representations = splitAccountDetailsIntoRepresentationsMap(
    { ...validatedPayeeAccount.accountDetails, localFormat: validatedPayeeAccount.localFormat ?? null },
    false,
    true,
  );

  const getLocalFormatRepresentation = (): ReactNode => {
    if (validatedPayeeAccount.localFormat) {
      return renderGridLine('localformat', 'CLABE', validatedPayeeAccount.localFormat, `account-details-local-format`);
    }

    return null;
  };

  return (
    <StyledInnerCard className={className} data-testid={dataTestId}>
      <Title>
        <SVG accessibilityLabel='' image={BankIcon} width={30} height={25} />
        <BankName data-testid='account-bank-name'>{validatedPayeeAccount.bankName}</BankName>
        {permissionsStore.isAllowedToDownloadBankAccountCertificate && (
          <Menu menuItems={menuItems} id={`account-card-${validatedPayeeAccount.accountDetails.accountNumber}`} />
        )}
      </Title>
      <PaddedRowSeparator light />
      <StyledNationalityHeader countryCode={validatedPayeeAccount.bankCountryCode} />
      <DataGrid>
        {Array.from(representations.entries()).map(([type, representation]) =>
          renderGridLine(
            type,
            getRepresentationNameWithBIC(type),
            getRepresentationFormatterWithBIC(type)(representation),
            `account-details-${type}`,
          ),
        )}
        {validatedPayeeAccount.localFormat && getLocalFormatRepresentation()}
        {validatedPayeeAccount.furtherCredit &&
          renderGridLine(
            'furtherCredit',
            'Further Credit:',
            validatedPayeeAccount.furtherCredit,
            'account-details-further-credit',
          )}
      </DataGrid>
    </StyledInnerCard>
  );
};

const Placeholder: FC<DisplayProps> = ({ className }) => {
  return (
    <InnerCard className={className}>
      <Title>
        <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 230, margin: '0.2em' }} />
      </Title>
      <PaddedRowSeparator light />
      <DataGrid>
        {renderGridLine(
          1,
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 66, margin: '0.2em' }} />,
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 160, margin: '0.2em' }} />,
          'placeholder-1',
        )}
        {renderGridLine(
          2,
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 60, margin: '0.2em' }} />,
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 190, margin: '0.2em' }} />,
          'placeholder-2',
        )}
        {renderGridLine(
          3,
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 75, margin: '0.2em' }} />,
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 110, margin: '0.2em' }} />,
          'placeholder-3',
        )}
      </DataGrid>
    </InnerCard>
  );
};

const NationalityHeader: FC<{ countryCode: string | undefined }> = ({ countryCode }) => {
  const countryName = countryCode ? getCountryByCountryCode(countryCode)?.name ?? countryCode : 'Unknown Country';

  return (
    <NationalityContainer>
      <StyledNationalityFlag countryCode={countryCode} />
      {countryName}
    </NationalityContainer>
  );
};

const renderGridLine = (key: string | number, title: ReactNode, value: ReactNode, valueDataTest: string): ReactElement => {
  return (
    <DataGridRow key={key} title={title} valueDataTest={valueDataTest}>
      {value}
    </DataGridRow>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(MyValidatedPayeeAccountCard, Placeholder);

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const BankName = styled(SubtitleRegularStartTransparentBlack900.div)`
  margin-left: 16px;
  flex: 1;
`;

const PaddedRowSeparator = styled(RowSeparator)`
  margin: 14px 16px 14px 0;
`;

const StyledNationalityFlag = styled(NationalityFlag)`
  margin-right: 12px;
`;

const StyledNationalityHeader = styled(NationalityHeader)`
  margin-bottom: 23px;
  ${SubtitleSmallStartTransparentBlack900.css}
`;

const NationalityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 23px;
`;

const StyledInnerCard = styled(InnerCard)`
  padding: 20px 12px 26px 28px;
`;
