import { distinctValues } from '@app/utils/arrayUtils';

export const UUID_REGEX = /^[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REDUCTED_EMAIL_REGEX = /^[a-zA-Z0-9-_.+]{0,2}\*$/;
export const SUBDOMAIN_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-_]*[a-zA-Z0-9]$/;
export const URL_WITH_PROTOCOL_REGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
export const URL_OPTIONAL_PROTOCOL_REGEX = /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
export const ALPHANUMERIC_PATTERN_AND_DASH = /^[0-9A-Za-z-]+$/;

export function joinRegexes(...regexes: (RegExp | string)[]): RegExp {
  const joinedFlags = distinctValues(
    regexes.flatMap((regexp) => {
      if (typeof regexp === 'string') {
        return [];
      }

      return regexp.flags.split('');
    }),
  )
    .sort()
    .join('')
    .replace(/(.)(?=.*\1)/g, '');

  const joinedRegex = regexes
    .map((regexp) => {
      if (typeof regexp === 'string') {
        return regexp;
      }

      return regexp.source;
    })
    .join('');

  return new RegExp(joinedRegex, joinedFlags);
}
