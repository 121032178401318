import React, { FC, useRef, useState } from 'react';
import { isPointInsideRect } from '@app/utils/utils';
import DragNDropIndicationContext from './DragNDropIndicationContext';

interface Props {
  className?: string;
}

const DragNDropIndicationArea: FC<Props> = (props) => {
  const hoverContainerRef = useRef<HTMLDivElement | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  function onDragEnter(ev: React.DragEvent<HTMLDivElement>): void {
    setIsDragOver(true);
    ev.preventDefault();
  }

  function onDragLeave(ev: React.DragEvent<HTMLDivElement>): void {
    const { clientX, clientY } = ev;

    // This event can be triggered when the drag is happening over an element which is inside this div.
    // This means that the drag is still in process, just over a different element inside the draggable area
    // so we should not stop the drag process
    if (hoverContainerRef.current) {
      const boundingClientRect = hoverContainerRef.current.getBoundingClientRect();

      // Create inner rect which is slightly smaller then the actual box of the div,
      // so there is no way the effect will not turn off when approaching the outside of the div
      const safeRect = new DOMRect(
        boundingClientRect.x + 1,
        boundingClientRect.y + 1,
        boundingClientRect.width - 2,
        boundingClientRect.height - 5,
      );

      if (isPointInsideRect(safeRect, clientX, clientY)) {
        return;
      }
    }

    setIsDragOver(false);
  }

  function onDrop(): void {
    setIsDragOver(false);
  }

  return (
    <DragNDropIndicationContext.Provider value={isDragOver}>
      <div {...props} ref={hoverContainerRef} onDragOver={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop} />
    </DragNDropIndicationContext.Provider>
  );
};

export default DragNDropIndicationArea;
