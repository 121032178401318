import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import GuestVerificationWithPayeeAccountFormPage from './payeeVerificationPage/GuestVerificationWithPayeeAccountFormPage';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import useAppStores from '@app/hooks/useAppStores';
import { useParams } from 'react-router-dom';
import browserHistory from '@app/utils/browserHistory';
import { VerifyAccountFormFields } from '@app/components/accountVerification/SearchAccount';
import * as verifyService from '@ar/services/arVerifyService';
import Log from '@app/libs/logger';
import { GuestPayeeSearchVerifyResult, transformGuestPayeeSearchVerifyResult } from '@ar/domain/arAccountVerification';
import GuestVerificationWithPayeeAfterVerificationPage from './payeeVerificationPage/GuestVerificationWithPayeeAfterVerificationPage';

const GuestVerificationWithPayeePage: FC = observer(() => {
  const { navigationStore } = useInfraStores<ArMode>();
  const { arVerificationStore } = useAppStores<ArMode>();
  const { validatedPayeeUniformId } = useParams<{ validatedPayeeUniformId: string }>();
  const [verificationResult, setVerificationResult] = useState<GuestPayeeSearchVerifyResult | null>(null);
  const [previouslyVerifiedAccountFields, setPreviouslyVerifiedAccountFields] = useState<VerifyAccountFormFields | null>(null);

  const selectedPayee = arVerificationStore.selectedPayeeFromSearch;

  const backButtonSearchAnotherAccount = (): void => {
    setVerificationResult(null);
  };

  // As long as we not fetch this payee, we need to make sure the payee we do have in the global store has the same uniform id
  if (!selectedPayee || !validatedPayeeUniformId || selectedPayee?.uniformId !== validatedPayeeUniformId) {
    arVerificationStore.resetAll();
    browserHistory.push(navigationStore.generateStartPageLink());
    return <div />;
  }

  useEffect(() => {
    arVerificationStore.setIsAfterValidation(!!verificationResult);
  }, [arVerificationStore, verificationResult]);

  const verifyAccount = async (accountFields: VerifyAccountFormFields): Promise<void> => {
    setPreviouslyVerifiedAccountFields(accountFields);
    try {
      const validationResultServerResponse = await verifyService.validateAccountByUniformId(
        validatedPayeeUniformId,
        accountFields.accountDetails,
      );

      if (validationResultServerResponse.validated) {
        setVerificationResult(transformGuestPayeeSearchVerifyResult(validationResultServerResponse));
      } else {
        setVerificationResult({
          validated: false,
          aggregatedValidatedPayeeData: selectedPayee,
          lastValidatedPayeeAccountEventData: null,
          validatedAccountDetails: null,
          validatedPayeeAccountAttachments: [],
          validationTimestamp: null,
          sanctionsScreeningInfo: null,
          presentationAccountNumber: null,
        });
      }

      Log.event('accountValidationResult', { validated: validationResultServerResponse.validated }, true);
    } catch (e) {
      setVerificationResult(null);
      Log.event('Error while validating account', { e, uniformId: validatedPayeeUniformId, account: accountFields });
    }
  };

  if (verificationResult && previouslyVerifiedAccountFields) {
    return (
      <GuestVerificationWithPayeeAfterVerificationPage
        inputAccountDetails={previouslyVerifiedAccountFields.accountDetails}
        verificationResult={verificationResult}
        backButtonHandler={backButtonSearchAnotherAccount}
      />
    );
  } else {
    return (
      <GuestVerificationWithPayeeAccountFormPage
        payee={selectedPayee}
        initialAccountFormFields={previouslyVerifiedAccountFields}
        verifyAccount={verifyAccount}
      />
    );
  }
});

export default GuestVerificationWithPayeePage;
