import { trim } from '@app/utils/stringUtils';
import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import {
  getDefaultBankCodeRules,
  SupplierRegistrationField,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { ACCOUNT_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { AllLegalIdTypeForCompany } from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { COUNTRY_CODES } from '@app/domain/countries';

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBranchCode',
      },
      ...getDefaultBankCodeRules(COUNTRY_CODES.SouthAfrica),
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.ZA.branch,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.ZA.branch,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidBranchCode',
      },
    ],
  };
}

function getAdditionalSwiftRules(): ConditionalValidationRule[] {
  return [
    {
      required: true,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const SouthAfricaLegalIdTypesForCompany = { CompanyTrustNumber: 'Company Trust Number', ...AllLegalIdTypeForCompany };

export const ZA: SupplierRegistrationCountryFields = {
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
  bankCode: getBankCodeFields,
  leis: { types: SouthAfricaLegalIdTypesForCompany },
};
