import React, { FC } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { SendValidationEmailToCustomerFormFields } from './SendValidationEmailToCustomerForm';
import { BodyRegularStartTransparentBlack600 } from '@app/components/Text';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';

interface Props {
  values: SendValidationEmailToCustomerFormFields;
  className?: string;
  onOk(values: SendValidationEmailToCustomerFormFields): void;
  onBack(): void;
}

const SendValidationEmailToCustomerSummary: FC<Props> = ({ values, onOk, onBack, className }) => {
  return (
    <Main className={className}>
      <SummaryExplanation>Check the information provided before sending the email</SummaryExplanation>
      <DetailsGrid>
        <DataGridRow title='To:' valueDataTest='txtToAddresses'>
          <EmailsList>
            {values.to.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </EmailsList>
        </DataGridRow>
        <DataGridRow title='Customer Name:' valueDataTest='txtCustomerName'>
          {values.referringCustomerName}
        </DataGridRow>
      </DetailsGrid>
      <ActionsContainer>
        <BackButton id='btn-send-validation-email-to-customer-summary-back' appearance='text' colorScheme='grey' onClick={onBack}>
          {'<'} BACK
        </BackButton>
        <Button id='btn-send-validation-email-to-customer-summary-ok' onClick={(): void => onOk(values)}>
          SEND EMAIL
        </Button>
      </ActionsContainer>
    </Main>
  );
};

export default SendValidationEmailToCustomerSummary;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsGrid = styled(DataGrid)`
  flex: 1;
  --info-row-font-size: 15px;
`;

const EmailsList = styled.ul`
  padding-inline-start: 20px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;

  // To allow the buttons drop shadow to fully show
  padding: 10px var(--side-padding) 8px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const BackButton = styled(Button)`
  margin-left: calc(var(--button-line-padding) * -1);
`;

const SummaryExplanation = styled(BodyRegularStartTransparentBlack600.div)`
  margin-bottom: 16px;
`;
