import React, { FC, ReactNode } from 'react';
import useInfraStores from '@app/hooks/useInfraStores';
import AboveMainCardTitle from '@app/components/AboveMainCardTitle';
import MorteeMode from '@mortee/morteeMode';
import useAppStores from '@app/hooks/useAppStores';
import browserHistory from '@app/utils/browserHistory';

interface Props {
  title?: string;
  showBackButton?: boolean;
  children?: ReactNode;
}

const AccountVerificationAboveMainCardTitle: FC<Props> = ({ title, showBackButton, children }) => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { payeeSearchStore } = useAppStores<MorteeMode>();

  let backButtonAction;

  if (showBackButton) {
    if (payeeSearchStore.isAfterVerification && !!payeeSearchStore.verificationDetails) {
      backButtonAction = payeeSearchStore.resetAccountSearch;
    } else {
      backButtonAction = (): void => browserHistory.push(navigationStore.generateAccountVerificationHomeLink());
    }
  }

  return <AboveMainCardTitle title={title} children={children} backButtonAction={backButtonAction} />;
};

export default AccountVerificationAboveMainCardTitle;
