import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from './uiConsts';
import { trim } from '@app/utils/stringUtils';
import { GetFieldDecoratorOptionsExtended } from '../utils/form/form';
import { swiftCodeValidator, validateIban } from '@app/domain/accountDetailsValidators';
import { COUNTRY_CODES } from '@app/domain/countries';
import { TFunction } from 'react-i18next';

export enum VerificationResultText {
  extendedValidation = 'Extended Validation',
  standardValidation = 'Standard Validation',
  selfApproved = 'Self Approved',
  crowdKnowledge = 'Crowd Knowledge',
  notValidated = 'Not Validated',
  unknownResult = 'unknownResult',
}

export enum VERIFICATION_ACCOUNT_TYPE {
  iban = 'IBAN',
  domestic = 'DOMESTIC',
  swift = 'SWIFT',
}

export const SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES_ARRAY: COUNTRY_CODES[] = [
  COUNTRY_CODES.Israel,
  COUNTRY_CODES.UnitedKingdom,
  COUNTRY_CODES.UnitedStates,
  COUNTRY_CODES.Canada,
  COUNTRY_CODES.Taiwan,
  COUNTRY_CODES.India,
  COUNTRY_CODES.NewZealand,
  COUNTRY_CODES.Brazil,
  COUNTRY_CODES.Japan,
  COUNTRY_CODES.SouthAfrica,
  COUNTRY_CODES.RepublicOfSouthKorea,
  COUNTRY_CODES.China,
  COUNTRY_CODES.Mexico,
  COUNTRY_CODES.Singapore,
  COUNTRY_CODES.Australia,
  COUNTRY_CODES.Norway,
  COUNTRY_CODES.Finland,
  COUNTRY_CODES.Germany,
  COUNTRY_CODES.Denmark,
];

export const SUPPORTED_VERIFICATION_LOCAL_FORMAT_COUNTRIES_ARRAY: COUNTRY_CODES[] = [COUNTRY_CODES.Mexico];

export type SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES = typeof SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES_ARRAY[number];
export type SUPPORTED_VERIFICATION_LOCAL_FORMAT_COUNTRIES = typeof SUPPORTED_VERIFICATION_LOCAL_FORMAT_COUNTRIES_ARRAY[number];

export function isValidDomesticCountry(
  countryCode: string | null | undefined,
): countryCode is SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES {
  return SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES_ARRAY.includes(countryCode as SUPPORTED_VERIFICATION_DOMESTIC_COUNTRIES);
}

export function isValidLocalFormatCountry(
  countryCode: string | null | undefined,
): countryCode is SUPPORTED_VERIFICATION_LOCAL_FORMAT_COUNTRIES {
  return SUPPORTED_VERIFICATION_LOCAL_FORMAT_COUNTRIES_ARRAY.includes(
    countryCode as SUPPORTED_VERIFICATION_LOCAL_FORMAT_COUNTRIES,
  );
}

export interface AccountVerificationField {
  id: string;
  displayName: string;
  inputType: 'text' | 'number';
  decorator: (isFieldRequired?: boolean) => GetFieldDecoratorOptionsExtended;
  nextFieldToFocusOnMax?: string;
}

function tOrDefaultValue(
  t: TFunction<string> | undefined,
  key: string,
  defaultValue: string,
  otherKeys?: { [key: string]: unknown },
): string {
  return t?.(key, { defaultValue, ...otherKeys }) || defaultValue;
}

export function getAccountValidationFields(
  selectedType: string,
  t?: TFunction<string>,
): Partial<Record<keyof MorteeAccountDetails, AccountVerificationField>> | undefined {
  const types = {
    [VERIFICATION_ACCOUNT_TYPE.iban]: {
      iban: {
        id: 'iban',
        displayName: tOrDefaultValue(t, 'verifyAccount.iban', 'IBAN'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              transform: trim,
              translatedMessage: ['verifyAccount.errors.enterValidIban', 'Please enter a valid iban'],
            },
            {
              transform: trim,
              validator: validateIban,
            },
          ],
        }),
      },
    },
    [VERIFICATION_ACCOUNT_TYPE.swift]: {
      swiftCode: {
        id: 'swift-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.swift', 'SWIFT/BIC'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          validateFirst: true,
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidSwift', 'Please enter a valid swift code'],
            },
            {
              transform: trim,
              validator: swiftCodeValidator,
              translatedMessage: ['verifyAccount.errors.invalidSwiftCode', 'Invalid Swift code'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'swift-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.swiftAccount,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.swiftAccount} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.swiftAccount,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    GB: {
      bankCode: {
        id: 'gb-sort-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.sortCode', 'Sort Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'gb-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidSortCode', 'Please enter a valid sort code'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.sortCode,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.sortCode} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.sortCode,
              },
            },
            {
              transform: trim,
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'gb-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.GB.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.GB.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.GB.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    IL: {
      bankCode: {
        id: 'il-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'il-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.IL.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.IL.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.IL.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      branchCode: {
        id: 'il-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'il-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBranch', 'Please enter a valid branch'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.IL.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.IL.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.IL.branch,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'il-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.IL.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.IL.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.IL.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    US: {
      bankCode: {
        id: 'us-routing-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.routing', 'Routing'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'us-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidRouting', 'Please enter a valid routing number'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.US.achRouting,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.US.achRouting} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.US.achRouting,
              },
            },
            {
              transform: trim,
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'us-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.US.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.US.achRouting} account`],
                value: ACCOUNT_FIELD_MAX_LENGTH.US.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    CA: {
      bankCode: {
        id: 'ca-routing-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.routing', 'Routing'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'ca-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidRouting', 'Please enter a valid routing number'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.CA.routing,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.CA.routing} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.CA.routing,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.numeric,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'ca-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.CA.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.CA.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.CA.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
    IN: {
      bankCode: {
        id: 'in-ifsc-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.ifscCode', 'IFSC Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'in-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidIFSC', 'Please enter a valid IFSC Code'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.IN.IFSC,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.IN.IFSC} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.IN.IFSC,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'in-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.IN.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.IN.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.IN.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    TW: {
      bankCode: {
        id: 'tw-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'tw-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.TW.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.TW.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.TW.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      branchCode: {
        id: 'tw-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'tw-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBranch', 'Please enter a valid branch'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.TW.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.TW.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.TW.branch,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'tw-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.TW.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.TW.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.TW.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    NZ: {
      bankCode: {
        id: 'nz-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'nz-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.NZ.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.NZ.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.NZ.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      branchCode: {
        id: 'nz-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'nz-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBranch', 'Please enter a valid branch'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.NZ.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.NZ.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.NZ.branch,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'nz-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.NZ.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.NZ.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.NZ.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    BR: {
      bankCode: {
        id: 'br-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'br-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              },
            },
          ],
        }),
      },
      branchCode: {
        id: 'br-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.agenciaCode', 'Agencia code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'br-account-number',
        decorator: (): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              },
            },
          ],
        }),
      },
      accountNumber: {
        id: 'br-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              },
            },
          ],
        }),
      },
    },
    JP: {
      bankCode: {
        id: 'jp-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'jp-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              },
            },
          ],
        }),
      },
      branchCode: {
        id: 'jp-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'jp-account-number',
        decorator: (): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              },
            },
          ],
        }),
      },
      accountNumber: {
        id: 'jp-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              },
            },
          ],
        }),
      },
    },
    ZA: {
      bankCode: {
        id: 'za-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'za-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              },
            },
          ],
        }),
      },
      branchCode: {
        id: 'za-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'za-account-number',
        decorator: (): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              },
            },
          ],
        }),
      },
      accountNumber: {
        id: 'za-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              },
            },
          ],
        }),
      },
    },
    KR: {
      bankCode: {
        id: 'kr-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'kr-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              },
            },
          ],
        }),
      },
      accountNumber: {
        id: 'kr-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              },
            },
          ],
        }),
      },
    },
    CN: {
      bankCode: {
        id: 'cn-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankName', 'Bank Name'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'cn-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumericWithChinese,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
      branchCode: {
        id: 'cn-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.subBankName', 'Sub Bank Name'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'cn-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.branch} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumericWithChinese,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'cn-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
    MX: {
      bankCode: {
        id: 'mx-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'mx-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              len: ACCOUNT_FIELD_MAX_LENGTH.MX.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.MX.bank} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.MX.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      branchCode: {
        id: 'mx-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'mx-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBranch', 'Please enter a valid branch'],
            },
            {
              len: ACCOUNT_FIELD_MAX_LENGTH.MX.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.MX.branch} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.MX.branch,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'mx-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              len: ACCOUNT_FIELD_MAX_LENGTH.MX.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.MX.accountNumber} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.MX.accountNumber,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
    SG: {
      bankCode: {
        id: 'sg-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'sg-branch-code',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              len: ACCOUNT_FIELD_MAX_LENGTH.SG.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.SG.bank} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.SG.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      branchCode: {
        id: 'sg-branch-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.branchCode', 'Branch Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'sg-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBranch', 'Please enter a valid branch'],
            },
            {
              len: ACCOUNT_FIELD_MAX_LENGTH.SG.branch,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.SG.branch} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.SG.branch,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'sg-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.SG.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxDigits', `Max ${ACCOUNT_FIELD_MAX_LENGTH.SG.accountNumber} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.SG.accountNumber,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
    AU: {
      bankCode: {
        id: 'au-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bsbCode', 'BSB Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'au-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              len: ACCOUNT_FIELD_MAX_LENGTH.AU.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.AU.bank} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.AU.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'au-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxDigits', `Max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
    NO: {
      bankCode: {
        id: 'no-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'no-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.NO.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.NO.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.NO.bank,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'no-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.NO.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.NO.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.NO.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    FI: {
      bankCode: {
        id: 'fi-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'fi-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.FI.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.FI.bank} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.FI.bank,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'fi-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'text',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.FI.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxCharacters', `Max ${ACCOUNT_FIELD_MAX_LENGTH.FI.accountNumber} characters`],
                value: ACCOUNT_FIELD_MAX_LENGTH.FI.accountNumber,
              },
            },
            {
              transform: trim,
              pattern: VALIDATION_PATTERNS.alphanumeric,
              translatedMessage: ['verifyAccount.errors.mustBeAlphanumeric', 'Must be alphanumeric'],
            },
          ],
        }),
      },
    },
    DE: {
      bankCode: {
        id: 'de-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.blzCode', 'BLZ Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'de-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.DE.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxDigits', `Max ${ACCOUNT_FIELD_MAX_LENGTH.DE.bank} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.DE.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'de-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              transform: trim,
              max: ACCOUNT_FIELD_MAX_LENGTH.DE.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxDigits', `Max ${ACCOUNT_FIELD_MAX_LENGTH.DE.accountNumber} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.DE.accountNumber,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
    DK: {
      bankCode: {
        id: 'dk-bank-code',
        displayName: tOrDefaultValue(t, 'verifyAccount.bankCode', 'Bank Code'),
        inputType: 'number',
        nextFieldToFocusOnMax: 'dk-account-number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidBank', 'Please enter a valid bank'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.DK.bank,
              translatedMessage: {
                key: ['verifyAccount.errors.maxDigits', `Max ${ACCOUNT_FIELD_MAX_LENGTH.DK.bank} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.DK.bank,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
      accountNumber: {
        id: 'dk-account-number',
        displayName: tOrDefaultValue(t, 'verifyAccount.accountNumber', 'Account Number'),
        inputType: 'number',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              transform: trim,
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidAccount', 'Please enter a valid account'],
            },
            {
              max: ACCOUNT_FIELD_MAX_LENGTH.DK.accountNumber,
              translatedMessage: {
                key: ['verifyAccount.errors.maxDigits', `Max ${ACCOUNT_FIELD_MAX_LENGTH.DK.accountNumber} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.DK.accountNumber,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
  };

  return types[selectedType];
}

export function getAccountValidationForLocalFormatFields(
  selectedCountry: string,
  t?: TFunction<string>,
): Partial<Record<keyof MorteeAccountDetailsExtended, AccountVerificationField>> | undefined {
  const types = {
    MX: {
      localFormat: {
        id: 'mx-local-format',
        displayName: tOrDefaultValue(t, 'verifyAccount.localFormat', 'CLABE'),
        inputType: 'text',
        nextFieldToFocusOnMax: 'mx-local-format',
        decorator: (isFieldRequired = true): GetFieldDecoratorOptionsExtended => ({
          rules: [
            {
              required: true,
              enabled: isFieldRequired,
              translatedMessage: ['verifyAccount.errors.enterValidClabe', 'Please enter a valid CLABE'],
            },
            {
              len: ACCOUNT_FIELD_MAX_LENGTH.MX.clabe,
              translatedMessage: {
                key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.MX.clabe} digits`],
                value: ACCOUNT_FIELD_MAX_LENGTH.MX.clabe,
              },
            },
            {
              pattern: /^[0-9]+$/,
              translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
            },
          ],
        }),
      },
    },
  };

  return types[selectedCountry];
}
