import config from '@app/config';
import request, { HttpMethod, HttpStatus } from '@app/libs/request';
import {
  AuthCodeDeliveryMethod,
  AuthMethodType,
  InitiatePhoneLoginResponse,
  KnoxerAuthProperties,
  ssoAuthMethods,
} from '@app/login/domain/loginConsts';

interface OpenIdTokenResponse {
  idToken: string;
}

export function doesHaveKnoxerAuthConfig(knoxerId: string, authMethodType: AuthMethodType): boolean {
  return !!getNullableKnoxerAuthConfig(knoxerId, authMethodType);
}

export function getKnoxerAuthConfig(knoxerId: string, authMethodType: AuthMethodType): KnoxerAuthProperties {
  const nullableKnoxerAuthConfig = getNullableKnoxerAuthConfig(knoxerId, authMethodType);

  if (!nullableKnoxerAuthConfig) {
    throw new Error(`could not find auth properties with auth method type ${authMethodType}`);
  }

  return nullableKnoxerAuthConfig;
}

export function getNullableKnoxerAuthConfig(knoxerId: string, authMethodType: AuthMethodType): KnoxerAuthProperties | null {
  const knoxersAuthConfig = config.knoxersAuthData;

  if (!knoxersAuthConfig) {
    throw new Error('could not find knoxers config');
  }

  const knoxerAuthConfig = knoxersAuthConfig.find((knoxerAuthConfig) => knoxerAuthConfig.id === knoxerId);

  if (!knoxerAuthConfig) {
    throw new Error(`could not find knoxer with id ${knoxerId}`);
  }

  if (!knoxerAuthConfig.authProperties.length) {
    throw new Error(`knox config is missing auth properties`);
  }

  const knoxAuthPropertiesCandidates: KnoxerAuthProperties[] = knoxerAuthConfig.authProperties.filter(
    (authProperty) => authProperty.authMethod === authMethodType,
  );

  if (!knoxAuthPropertiesCandidates?.length) {
    return null;
  }

  const knoxerAuthProperty = knoxAuthPropertiesCandidates[0];

  if (authMethodType === AuthMethodType.EmailPassword) {
    if (!knoxerAuthProperty.authUrlEmail) {
      throw new Error(`knox config is missing authUrlEmail property for EmailPassword auth method type`);
    }

    if (!knoxerAuthProperty.userPasswordSelfManagementServiceUrl) {
      throw new Error(`knox config is missing userPasswordSelfManagementServiceUrl property for EmailPassword auth method type`);
    }
  }

  if (ssoAuthMethods.includes(authMethodType)) {
    if (!knoxerAuthProperty.authUrlEmail) {
      throw new Error(`knox config is missing authUrlEmail property for ${authMethodType} auth method type`);
    }
  }

  if (authMethodType === AuthMethodType.PhoneNumber) {
    if (!knoxerAuthProperty.authUrlPhoneSms) {
      throw new Error(`knox config is missing authUrlPhoneSms property for PhoneNumber auth method type`);
    }

    if (!knoxerAuthProperty.authUrlPhoneCall) {
      throw new Error(`knox config is missing authUrlPhoneCall property for PhoneNumber auth method type`);
    }
  }

  return knoxerAuthProperty;
}

export function getPhoneAuthUrl(
  knoxerAuthConfig: KnoxerAuthProperties,
  deliveryMethod: AuthCodeDeliveryMethod,
): string | undefined {
  if (deliveryMethod === AuthCodeDeliveryMethod.TextMessage) {
    return knoxerAuthConfig.authUrlPhoneSms;
  }

  if (deliveryMethod === AuthCodeDeliveryMethod.PhoneCall) {
    return knoxerAuthConfig.authUrlPhoneCall;
  }
}

export async function logout(): Promise<any> {
  return request(config.serverUrls.logout, null, {
    method: HttpMethod.post,
  });
}

export async function generateTokenWithUsernamePassword(
  knoxerId: string,
  username: string,
  password: string,
): Promise<OpenIdTokenResponse> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);

  assertKnoxerHaveUrl(knoxerAuthConfig.authUrlEmail, knoxerId, 'authUrlEmail');

  return request(knoxerAuthConfig.authUrlEmail, `/auth/token`, {
    method: HttpMethod.post,
    headers: knoxerAuthConfig.authAdditionalHeaders,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
      [HttpStatus.unauthorized]: {
        message: 'Wrong username or password',
      },
    },
    suppressNotification: true,
    data: { username, password },
  });
}

export async function generateTokenWithPhoneAndCode(
  knoxerId: string,
  flowId: string,
  id: string,
  code: string,
  deliveryMethod: AuthCodeDeliveryMethod,
): Promise<OpenIdTokenResponse> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.PhoneNumber);
  const phoneAuthUrl = getPhoneAuthUrl(knoxerAuthConfig, deliveryMethod);

  assertKnoxerHaveUrl(phoneAuthUrl, knoxerId, 'phoneAuthUrl');

  return request(phoneAuthUrl, `/auth/code/token`, {
    method: HttpMethod.post,
    headers: knoxerAuthConfig.authAdditionalHeaders,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
      [HttpStatus.unauthorized]: {
        message: 'Wrong phone or code',
      },
    },
    suppressNotification: true,
    data: { flowId, id, code },
  });
}

export interface ClaimHandleRequestData {
  secretId: string;
  secret: string;
}

export async function claimHandleForKnoxer(
  knoxerId: string,
  handle: string,
  claimData: ClaimHandleRequestData,
  authMethodType: AuthMethodType,
  knoxerIdToken?: string,
): Promise<void> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, authMethodType);
  const authorizationHeader: Record<string, string> = knoxerIdToken ? { Authorization: `Bearer ${knoxerIdToken}` } : {};
  return await request<void>(knoxerAuthConfig.claimUrl, `/api/login/sessions/:handle`, {
    method: HttpMethod.post,
    pathParams: { handle },
    headers: {
      ...authorizationHeader,
      ...(knoxerAuthConfig.claimAdditionalHeaders || {}),
    },
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    suppressNotification: true,
    errorsHandler: {
      default: {
        message: `Unexpected error claiming handle ${handle} for knoxer `,
      },
    },
    data: claimData,
  });
}

export async function phoneLoginSendCodeToPhone(
  knoxerId: string,
  id: string,
  deliveryMethod: AuthCodeDeliveryMethod,
  quiet: boolean,
): Promise<InitiatePhoneLoginResponse> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.PhoneNumber);
  const phoneAuthUrl = getPhoneAuthUrl(knoxerAuthConfig, deliveryMethod);

  assertKnoxerHaveUrl(phoneAuthUrl, knoxerId, 'phoneAuthUrl');

  return request<InitiatePhoneLoginResponse>(phoneAuthUrl, `/auth/code/request`, {
    method: HttpMethod.post,
    headers: knoxerAuthConfig.authAdditionalHeaders,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    suppressNotification: quiet,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to initiate phone login process',
      },
    },
    data: {
      id,
    },
  });
}

export interface ForgotPasswordFlowRequest {
  flowId: string;
  codeTTLMinutes: number;
  codeLength: number;
}

export async function forgotPasswordEnterEmail(knoxerId: string, email: string): Promise<ForgotPasswordFlowRequest> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);

  assertKnoxerHaveUrl(knoxerAuthConfig.userPasswordSelfManagementServiceUrl, knoxerId, 'userPasswordSelfManagementServiceUrl');

  return request<ForgotPasswordFlowRequest>(
    knoxerAuthConfig.userPasswordSelfManagementServiceUrl,
    `/users/me/password/forgot/request`,
    {
      method: HttpMethod.post,
      headers: knoxerAuthConfig.authAdditionalHeaders,
      generateDynamicHeaders: false,
      dontRedirectToLogin: true,
      errorsHandler: {
        default: {
          message: 'Unexpected error occurred while trying to initiate forgot password process',
        },
      },
      data: { email },
    },
  );
}

function assertKnoxerHaveUrl(url: string | undefined, knoxerId: string, urlName: string): asserts url is string {
  if (!url) {
    throw new Error(`Expected knoxerId ${knoxerId} to have ${urlName} configured`);
  }
}

export async function forgotPasswordEnterCode(knoxerId: string, flowId: string, email: string, code: string): Promise<any> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);
  assertKnoxerHaveUrl(knoxerAuthConfig.userPasswordSelfManagementServiceUrl, knoxerId, 'userPasswordSelfManagementServiceUrl');
  return request<any>(knoxerAuthConfig.userPasswordSelfManagementServiceUrl, `/users/me/password/forgot/code`, {
    method: HttpMethod.post,
    headers: knoxerAuthConfig.authAdditionalHeaders,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to enter email verification code',
      },
    },
    suppressNotification: true,
    data: { flowId, email, code },
  });
}

export async function forgotPasswordResetPassword(
  knoxerId: string,
  flowId: string,
  email: string,
  newPassword: string,
): Promise<any> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);
  assertKnoxerHaveUrl(knoxerAuthConfig.userPasswordSelfManagementServiceUrl, knoxerId, 'userPasswordSelfManagementServiceUrl');

  return request<any>(knoxerAuthConfig.userPasswordSelfManagementServiceUrl, `/users/me/password/forgot/reset`, {
    method: HttpMethod.post,
    headers: knoxerAuthConfig.authAdditionalHeaders,
    suppressNotification: true,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to apply new password',
      },
    },
    data: { flowId, email, newPassword },
  });
}
