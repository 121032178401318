import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import useLoadable from '@app/hooks/loadable/useLoadable';
import PageWithHeader from '@app/components/PageWithHeader';
import SftpTable from '@mortee/routes/sftp/SftpTable';

interface SftpErrorLogProps {}

const SftpErrorsLog: FC<SftpErrorLogProps> = observer(() => {
  const { sftpStore } = useAppStores<MorteeMode>();

  const [allSftpErrorsLoadable] = useLoadable(async () => await sftpStore.getAllSftpErrors(), []);

  return (
    <PageWithHeader width='full'>
      <SftpTable errors={allSftpErrorsLoadable} />
    </PageWithHeader>
  );
});

export default SftpErrorsLog;
