import { arraysEqual, distinctValues, wrapValueAsArray } from './arrayUtils';

import { compare } from '@app/utils/comparatorUtils';

export enum DiffState {
  Same,
  New,
  Different,
}

export interface Diff {
  state: DiffState;
  newValue: string[];
  oldValue?: string[];
}

type ArrayKeysOfType<T, TItem> = {
  [P in keyof T]: T[P] extends TItem ? (P extends string ? P : never) : never;
}[keyof T];

type ItemFormatter<TItem extends string | number | object> = (item: TItem) => string;
const DEFAULT_ITEM_FORMATTER = (x): string => x.toString();

export function createArrayFieldDiff<
  T,
  TItem extends string | number | object,
  TField extends ArrayKeysOfType<T, TItem[]> & keyof T
>(
  fieldName: keyof T,
  newObject: T,
  oldObject: T | null,
  changedFields: (keyof T)[] | null,
  fieldFormatter: ItemFormatter<TItem> = DEFAULT_ITEM_FORMATTER,
): Diff {
  return createDiff<T, TField, TItem>(
    fieldName as TField,
    newObject,
    oldObject,
    changedFields,
    fieldFormatter,
    (fieldValue) => (fieldValue ?? []) as TItem[],
  );
}

export function createFieldDiff<T, TItem extends string | number | object, TField extends ArrayKeysOfType<T, TItem> & keyof T>(
  fieldName: TField,
  newObject: T,
  oldObject: T | null,
  changedFields: (keyof T)[] | null,
  fieldFormatter: ItemFormatter<TItem> = DEFAULT_ITEM_FORMATTER,
): Diff {
  return createDiff(
    fieldName,
    newObject,
    oldObject,
    changedFields,
    fieldFormatter,
    (fieldValue) => wrapValueAsArray(fieldValue) as TItem[],
  );
}

function createDiff<T, TField extends keyof T & string, TItem>(
  fieldName: TField,
  newObject: T,
  oldObject: T | null,
  changedFields: (keyof T)[] | null,
  fieldFormatter: (item: TItem) => string,
  itemGetter: (fieldValue: T[TField] | undefined) => TItem[],
): Diff {
  const formattedNewValue = reformatArray(itemGetter(newObject[fieldName]), fieldFormatter);

  const formattedOldValue = reformatArray(itemGetter(oldObject?.[fieldName]), fieldFormatter);

  return {
    state: calcDiffState(fieldName, changedFields, !!oldObject, formattedOldValue, formattedNewValue),
    newValue: formattedNewValue,
    oldValue: formattedOldValue,
  };
}

function calcDiffState<T, TField extends keyof T & string, TItem>(
  fieldName: TField,
  changedFields: (keyof T)[] | null,
  hasOldObject: boolean,
  formattedOldValue: string[],
  formattedNewValue: string[],
): DiffState {
  if (changedFields?.includes(fieldName)) {
    if (arraysEqual(formattedNewValue, formattedOldValue)) {
      return DiffState.Same;
    }

    return DiffState.Different;
  }

  if (!hasOldObject && formattedNewValue.length) {
    return DiffState.New;
  }

  return DiffState.Same;
}

function reformatArray<T>(arr: T[] | undefined, formatter: (item: T) => string): string[] {
  if (arr === null || arr === undefined) {
    return [];
  }

  const formattedArr = arr.map(formatter);

  return distinctValues(formattedArr).sort(compare.stringsCaseInsensitive());
}
