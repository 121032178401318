import React, { FC, useContext } from 'react';
import { DraggerProps } from 'antd/lib/upload';
import styled from '@emotion/styled';
import Dragger from 'antd/lib/upload/Dragger';
import { Bold42White } from '@app/components/Text';
import { pluralize } from '@app/utils/stringUtils';
import DragNDropIndicationContext from './DragNDropIndicationContext';

interface Props extends DraggerProps {
  id: string;
}

const ReactiveDragger: FC<Props> = ({ className, ...draggerProps }) => {
  const isDragOver = useContext(DragNDropIndicationContext);

  return (
    <DragHoverEffectComponent className={className} isDragOver={isDragOver} multiple={draggerProps.multiple ?? false}>
      <StyledDragger {...draggerProps} />
    </DragHoverEffectComponent>
  );
};

export default ReactiveDragger;

const DragHoverEffectComponent = styled.div<{ isDragOver: boolean; multiple: boolean }>`
  position: relative;

  :after {
    content: 'Drop ${(p): string => pluralize('file', p.multiple)} here';
    opacity: 0;
    ${(p): string => (p.isDragOver ? `opacity: 0.7;` : '')}
    background-color: #838CB3;
    outline: #b0bcd0 2px dashed;
    ${Bold42White.css};
    transition: opacity 0.1s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    pointer-events: none;
    cursor: none;
  }
`;

const StyledDragger = styled(Dragger)<DraggerProps>`
  background: transparent !important;
  border: none !important;
  outline: none;
  text-align: start;
  padding: 0;
  cursor: default;

  .ant-upload.ant-upload-drag {
    .ant-upload {
      padding: 0;
    }

    .ant-upload-drag-container {
      display: block;
    }
  }

  .ant-upload {
    ${(p): string => (p.openFileDialogOnClick === false ? 'cursor: default' : '')};
  }
`;
