import React, { FunctionComponent, ReactNode } from 'react';
import { Form } from 'antd';
import styled from '@emotion/styled';
import FormInputsContext, { InputGroupState } from './FormInputsContext';
import { observer } from 'mobx-react';
import InputBox, { InputBoxAppearance } from './inputBox/InputBox';
import { ColorScheme } from '@app/domain/theme';
import { FormItemProps } from 'antd/lib/form/FormItem';

export interface FormlessItemBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  appearance?: InputBoxAppearance;
  colorScheme?: ColorScheme;
  showAsRequired?: boolean;
  disabled?: boolean;
  underText?: string | null;
  underTextType?: Truthy<FormItemProps['validateStatus']>;
  hasValue: boolean;
  expandBelowOnError?: boolean;
  sideContent?: ReactNode | (() => ReactNode);
  children: ReactNode;
  className?: string;
}

const FormlessItemBox: FunctionComponent<FormlessItemBoxProps> = observer((props) => {
  const {
    expandBelowOnError,
    underText,
    underTextType = 'error',
    hasValue,
    children,
    className,
    showAsRequired,
    disabled,
    appearance,
    colorScheme,
    sideContent,
    ...rest
  } = props;

  function calcState(hasError: boolean): InputGroupState {
    if (hasError) {
      return 'error';
    }

    if (disabled) {
      return 'disabled';
    }

    if (hasValue) {
      return 'filled';
    }

    return 'empty';
  }

  const state = calcState(!!underText?.length && underTextType === 'error');

  const renderedSideContent = typeof sideContent === 'function' ? sideContent() : sideContent;

  return (
    <StyledFormItem className={className} {...rest} expandBelowOnError={expandBelowOnError}>
      <FormInputsContext.Provider value={{ state, colorScheme, underInputText: underText, underInputTextType: underTextType }}>
        <Form.Item help={underText || ''} validateStatus={underText ? underTextType : ''}>
          <StyledInputBox appearance={appearance} isFieldRequired={showAsRequired}>
            <MainContentWrapper>{children}</MainContentWrapper>
            {renderedSideContent}
          </StyledInputBox>
        </Form.Item>
      </FormInputsContext.Provider>
    </StyledFormItem>
  );
});

export default FormlessItemBox;

const StyledFormItem = styled.div<{ expandBelowOnError: FormlessItemBoxProps['expandBelowOnError'] }>`
  margin-bottom: -5px;
  position: relative;

  ${({ expandBelowOnError }): string =>
    expandBelowOnError
      ? `
    .ant-form-item {
      margin-bottom: 0px;

      &.ant-form-item-with-help {
        margin-bottom: 5px;
      }

      & .ant-form-explain {
        min-height: 8px;
      }
    }
  `
      : ''}
`;

const StyledInputBox = styled(InputBox)<{ isFieldRequired: boolean | undefined }>`
  position: relative;
  display: flex;

  ${({ isFieldRequired }): string =>
    isFieldRequired
      ? `
  &::after {
    content: '✻';
    position: absolute;
    color: red;
    left: -0.9em;
    font-size: 20px;
    top: calc(50% - 0.4em);
    line-height: 1;
  }`
      : ''}
`;

const MainContentWrapper = styled.div`
  flex: 1;
`;
