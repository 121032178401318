import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import {
  CreateVerificationReminderRequest,
  IPSVerificationLog,
  IPSVerificationLogServerResponse,
  IPSVerificationReminder,
  IPSVerificationReminderServerResponse,
  VerificationLogStatisticsDataServerResponse,
} from '@mortee/domain/ips';
import { ValidatedPayeeWithAccountsServerResponse } from '@app/domain/validatedPayeeWithAccounts';
import { getAccountRepresentations } from '@app/domain/accountDetailsRepresentations';
import { getRepresentationFormatterWithBIC } from '@app/domain/accountDetailsFormatters';
import { transformValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import { transformAggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';

export async function getValidatedPayeesOwnedByMyOrganization(): Promise<ValidatedPayeeWithAccountsServerResponse[]> {
  return request<ValidatedPayeeWithAccountsServerResponse[]>(serverUrl, `/api/ips/validations`, {
    method: HttpMethod.get,
  });
}

export async function getAmountOfValidatedPayeesOwnedByMyOrganization(): Promise<number> {
  return request<number>(serverUrl, `/api/ips/validations/amount`, {
    method: HttpMethod.get,
  });
}

export async function getValidationLogsForPayeesInMyOrganization(limit?: number): Promise<IPSVerificationLogServerResponse[]> {
  return request<IPSVerificationLogServerResponse[]>(serverUrl, `/api/ips/verification-logs`, {
    method: HttpMethod.get,
    params: { limit },
  });
}

export async function getVerificationLogStatisticsData(
  startTimestamp?: number,
  endTimestamp: number = Date.now(),
): Promise<VerificationLogStatisticsDataServerResponse> {
  return request<VerificationLogStatisticsDataServerResponse>(serverUrl, `/api/ips/verification-logs/statistics`, {
    method: HttpMethod.get,
    params: { startTimestamp, endTimestamp },
  });
}

export async function storeValidationReminder(
  data: CreateVerificationReminderRequest,
): Promise<IPSVerificationReminderServerResponse> {
  return request<IPSVerificationReminderServerResponse>(serverUrl, `/api/ips/verification-reminders`, {
    data,
    method: HttpMethod.post,
    errorsHandler: {
      default: {
        message: 'Could not create verification reminder.',
      },
    },
  });
}

export async function deleteVerificationReminder(id: string): Promise<string> {
  return request<string>(serverUrl, `/api/ips/verification-reminders/:id/delete`, {
    method: HttpMethod.post,
    pathParams: { id },
    errorsHandler: {
      default: {
        message: 'Could not delete verification reminder.',
      },
    },
  });
}

export async function getAllPendingValidationReminders(): Promise<IPSVerificationReminderServerResponse[]> {
  return request<IPSVerificationReminderServerResponse[]>(serverUrl, `/api/ips/verification-reminders`, {
    method: HttpMethod.get,
  });
}

export async function getBankAccountCertificatePdf(
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
): Promise<NamedResource> {
  return request<NamedResource>(serverUrl, `/api/bac/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId`, {
    method: HttpMethod.get,
    pathParams: {
      validatedPayeeUniformId,
      validatedPayeeAccountUniformId,
    },
  });
}

export function transformIPSVerificationLog(serverResponse: IPSVerificationLogServerResponse): IPSVerificationLog {
  const searchedAccountDetailsRepresentationType = getAccountRepresentations(serverResponse.searchedAccountDetails).allFull[0];
  return {
    ...serverResponse,
    searchedAccountType: searchedAccountDetailsRepresentationType,
    account: serverResponse.account ? transformValidatedPayeeAccount(serverResponse.account) : null,
    searchedAccountDetailsFormatted: getRepresentationFormatterWithBIC(searchedAccountDetailsRepresentationType)(
      serverResponse.searchedAccountDetails,
    ),
    aggregatedPayeeData: transformAggregatedValidatedPayee(serverResponse.aggregatedPayeeData),
  };
}

export function transformIPSVerificationReminder(serverResponse: IPSVerificationReminderServerResponse): IPSVerificationReminder {
  return {
    ...serverResponse,
    validatedPayee: transformAggregatedValidatedPayee(serverResponse.validatedPayee),
    validatedPayeeAccount: transformValidatedPayeeAccount(serverResponse.validatedPayeeAccount),
  };
}
