import UserStoreImpl from '@app/stores/UserStoreImpl';
import SupplierRegistrationNavigationStore from '@supplierRegistration/stores/infraStores/SupplierRegistrationNavigationStore';
import LocaleStore from '@app/stores/LocaleStore';
import PermissionsStore from '@app/stores/PermissionsStore';
import InfraStores from '@app/stores/InfraStores';
import HealthCheckStore from '@app/stores/HealthCheckStore';
import SupplierRegistrationThemeStore from '@supplierRegistration/stores/infraStores/SupplierRegistrationThemeStore';
import LanguageStore from '@app/stores/LanguageStore';

export default class SupplierRegistrationInfraStores extends InfraStores<
  SupplierRegistrationNavigationStore,
  PermissionsStore,
  SupplierRegistrationThemeStore
> {
  constructor(allSections: Section[], localePath: string) {
    const userStore = new UserStoreImpl();
    const languageStore = new LanguageStore(null, localePath);
    const localeStore = new LocaleStore(languageStore);
    const permissionsStore = new PermissionsStore(allSections, userStore);
    const navigationStore = new SupplierRegistrationNavigationStore(userStore, permissionsStore);
    const themeStore = new SupplierRegistrationThemeStore(languageStore);
    const healthCheckStore = new HealthCheckStore(userStore);
    super(userStore, languageStore, localeStore, navigationStore, permissionsStore, themeStore, healthCheckStore);
  }
}
