import React, { FC } from 'react';
import styled from '@emotion/styled';
import BadMatchIcon from '@mortee/images/bad-match-magnifier-white.svg';
import ArrowBulletAlertIcon from '@app/images/arrows/arrow-bullet-alert.svg';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import ArrowBulletedLine from '@app/components/ArrowBulletedLine';
import Card from '@app/components/card/Card';

interface FixMissingLinkModalProps {
  accountVerificationPath: string;
  onDone: () => void;
}

const FixMissingLinkModal: FC<FixMissingLinkModalProps> = ({ accountVerificationPath, onDone }) => {
  return (
    <MainContainer>
      <Header>
        <StyledBadMatchIcon accessibilityLabel='' image={BadMatchIcon} />
        <div>Vendor not found</div>
      </Header>
      <Content>
        <Title>It is possible that the PaymentKnox record exists, but did not match your internal record:</Title>
        <BulletList>
          <StyledBulletedLine bulletIcon={ArrowBulletAlertIcon}>
            Try finding the vendor through the <a href={accountVerificationPath}>Account Verification screen.</a>
          </StyledBulletedLine>
          <StyledBulletedLine bulletIcon={ArrowBulletAlertIcon}>
            Make sure that your internal (MVF) record includes at least one strong ID (KnoxID/TaxID/DUNS).
          </StyledBulletedLine>
          <StyledBulletedLine bulletIcon={ArrowBulletAlertIcon}>
            Make sure that all strong IDs (KnoxID/TaxID/DUNS) used in your internal (MVF) record match the ones found in
            PaymentKnox.
          </StyledBulletedLine>
        </BulletList>
        <OKButton id='mvf-guard-how-to-fix-modal-ok' appearance='text' colorScheme='primary' onClick={onDone}>
          OK
        </OKButton>
      </Content>
    </MainContainer>
  );
};

export default FixMissingLinkModal;

const MainContainer = styled(Card)`
  width: 400px;
  padding: 0;
  background-color: white;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: white;

  padding: 24px 24px 14px;

  background-color: var(--alert-color);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 1.33;
  color: var(--black-strong);
`;

const Content = styled.div`
  padding: 24px 24px 12px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BulletList = styled.div`
  margin-top: 22px;
`;

const StyledBulletedLine = styled(ArrowBulletedLine)`
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.09px;
  color: var(--black-weak);

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const StyledBadMatchIcon = styled(SVG)`
  width: 48px;
  height: 48px;
`;

const OKButton = styled(Button)`
  align-self: flex-end;
  padding: 8px 36px;
  margin-top: 16px;
`;
