import { useState } from 'react';
import Loadable, { flowadWithPercentage, LoadableCreator } from '@app/utils/Loadable';
import useIsMounted from '../useIsMounted';
import { CancellablePromise } from 'mobx/dist/api/flow';

export default function useLoadableWithPercentage<T, Args extends unknown[]>(
  loadFunc: (...args: Args) => Promise<T>,
  expectedExecutionDurationMillis: number,
): [Loadable<T>, (...args: Args) => CancellablePromise<T>] {
  const isMounted = useIsMounted();

  const [loadable, setLoadable] = useState<Loadable<T>>(LoadableCreator.notStarted());

  const loadLoadableFunc = flowadWithPercentage<T, Args>(
    (newValue) => {
      if (isMounted.current) {
        setLoadable(newValue);
      }
    },
    loadFunc,
    () => expectedExecutionDurationMillis,
  );

  return [loadable, loadLoadableFunc];
}
