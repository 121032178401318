import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import Mode, { InfraStoresOfMode } from '@app/modes/Mode';

export default function useInfraStores<TMode extends Mode<any> = Mode>(): InfraStoresOfMode<TMode> {
  const content = React.useContext(MobXProviderContext);

  if (!content?.infraStores) {
    throw new Error(
      'infraStores is not defined, you might have app context missing, if you are using a modal, please wrap it with ModalAppContext',
    );
  }

  return content?.infraStores;
}
