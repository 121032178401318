import BackeeAppContent from '@backee/routes/app/BackeeAppContent';
import { ComponentType } from 'react';
import { UserRolesType } from '@app/domain/user';
import PageHeader from '@app/components/header/PageHeader';
import AllOrganizationsPage from '@backee/routes/organizations/AllOrganizationsPage';
import Mode from '@app/modes/Mode';
import BackeeInfraStores from '@backee/stores/infraStores/BackeeInfraStores';
import BackeeAppStores from '@backee/stores/appStores/BackeeAppStores';
import CreateOrganizationPage from '@backee/routes/organizations/CreateOrganizationPage';
import ManageOrganizationPage from '@backee/routes/organizations/ManageOrganizationPage';
import createUserPage from '@backee/routes/users/CreateUserPage';

class BackeeMode extends Mode<BackeeInfraStores, BackeeAppStores> {
  getDefaultHeaderComponent(): React.ComponentType {
    return PageHeader;
  }

  constructor() {
    super(sectionDefinitions);
  }

  getAppContentComponent(): ComponentType {
    return BackeeAppContent;
  }

  getHeadTitle(): string {
    return 'nsKnox User Management';
  }

  createInfraStores(): BackeeInfraStores {
    return new BackeeInfraStores(this.sections, this.getLocalePath());
  }

  createAppStores(): BackeeAppStores {
    return new BackeeAppStores();
  }
}

export default BackeeMode;

const sectionDefinitions: SectionDefinition[] = [
  {
    id: 'backeeOrganizations',
    name: 'Organizations',
    requiredRoles: [UserRolesType.adminRole, UserRolesType.backeeViewRole],
    requiredServices: [],
    routes: [
      {
        id: 'backeeOrganizationsList',
        path: '/organizations',
        component: AllOrganizationsPage,
        requiredRoles: [UserRolesType.adminRole, UserRolesType.backeeViewRole],
        requiredServices: [],
        innerRoutes: [
          {
            id: 'backeeNewOrganization',
            path: '/new',
            component: CreateOrganizationPage,
            requiredRoles: [UserRolesType.adminRole, UserRolesType.backeeCreateOrganizationRole],
            requiredServices: [],
          },
          {
            id: 'backeeManageOrganization',
            path: '/:organizationId/:activeTab?',
            component: ManageOrganizationPage,
            requiredRoles: [UserRolesType.adminRole, UserRolesType.backeeViewRole],
            requiredServices: [],
          },
        ],
      },
    ],
  },
  {
    id: 'backeeUsers',
    name: 'Users',
    requiredRoles: [UserRolesType.adminRole, UserRolesType.backeeCreateUserRole],
    requiredServices: [],
    routes: [
      {
        id: 'backeeNewUser',
        path: '/users/new',
        component: createUserPage,
        requiredRoles: [UserRolesType.adminRole, UserRolesType.backeeCreateUserRole],
        requiredServices: [],
      },
    ],
  },
];
