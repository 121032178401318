import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BufferLine } from '@app/components/DisplayFields';
import Button from '@app/components/Button';
import { getListWithLeading } from '@app/utils/arrayUtils';
import VerificationValidatedPayeeDetails from './VerificationValidatedPayeeDetails';
import DataGrid, { DataGridRow } from '../DataGrid';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import { useTranslation } from 'react-i18next';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';

interface PayeeDetailsMoreInfoProps {
  payee: ValidatedPayeeToDisplay;
  onReturn: () => void;
  children?: ReactNode;
  dataTestId?: string;
  className?: string;
}

const PayeeDetailsMoreInfo: FunctionComponent<PayeeDetailsMoreInfoProps> = ({ className, dataTestId, payee, onReturn }) => {
  const { t } = useTranslation();

  const reformatInfoValue = (value: string[] | string | undefined | null): string => {
    if (!value?.length) {
      return t('verifyAccount.notProvided', 'Not provided');
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  };

  const { addresses, emails, phones, faxes, websites, descriptions, primaryFields } = payee;

  return (
    <MainContainer className={className} data-testid={dataTestId}>
      <VerificationValidatedPayeeDetails payee={payee} hideAddress />
      <BufferLine />
      <LeftContainer>
        <DataGrid>
          <DataGridRow title={t('verifyAccount.addresses', 'Addresses:')} valueDataTest='txtAddresses'>
            {reformatInfoValue(getListWithLeading(addresses, primaryFields?.address))}
          </DataGridRow>
          <DataGridRow title={t('verifyAccount.emails', 'Emails:')} valueDataTest='txtEmails'>
            {reformatInfoValue(emails)}
          </DataGridRow>
          <DataGridRow title={t('verifyAccount.phoneNumbers', 'Phone Numbers:')} valueDataTest='txtPhones'>
            {reformatInfoValue(phones)}
          </DataGridRow>
          <DataGridRow title={t('verifyAccount.faxNumbers', 'Fax Numbers:')} valueDataTest='txtFaxes'>
            {reformatInfoValue(faxes)}
          </DataGridRow>
          <DataGridRow title={t('verifyAccount.websites', 'Websites:')} valueDataTest='txtWebsites'>
            {reformatInfoValue(websites)}
          </DataGridRow>
          <DataGridRow title={t('verifyAccount.description', 'Description:')} valueDataTest='txtDescription'>
            {reformatInfoValue(descriptions)}
          </DataGridRow>
        </DataGrid>
        <ReturnButton id='btn-more-info-return' dataTestId='infoRtrnBtn' onClick={onReturn}>
          {t('general.upperReturn', 'RETURN')}
        </ReturnButton>
      </LeftContainer>
    </MainContainer>
  );
};

export default PayeeDetailsMoreInfo;

const MainContainer = styled(LocaleAwareDiv)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 32px;
  width: 950px;
  text-align: initial;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ReturnButton = styled(Button)`
  margin-top: 15px;
`;
