import { formatNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

export function joinNationalPhoneAndDialCode(dialCode: string, localPhoneNumber: string): string {
  const parsedNumber = parsePhoneNumberFromString(`+${dialCode}${localPhoneNumber}`);

  return parsedNumber?.number.trim() || '';
}

export function validateNationalPhoneAndDialCode(dialCode: string, localPhoneNumber: string): boolean {
  const parsedNumber = parsePhoneNumberFromString(`+${dialCode}${localPhoneNumber}`);

  return parsedNumber?.isPossible() ?? false;
}

export function formatInternationalPhoneNumberForDisplay(internationalPhoneNumber: string): string {
  return formatNumber(internationalPhoneNumber, 'INTERNATIONAL', { humanReadable: true });
}
