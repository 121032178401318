import { AggregatedValidatedPayee, AggregatedValidatedPayeeServerResponse } from '@app/domain/aggregatedValidatedPayee';
import { ValidatedPayeeAccount, ValidatedPayeeAccountServerResponse } from '@app/domain/validatedPayeeAccount';

export interface IPSVerificationLogServerResponse {
  logUniformId: string;
  timestamp: number;
  userEmail: string;
  userPhone: string;
  searchedAccountDetails: MorteeAccountDetailsExtended;
  aggregatedPayeeData: AggregatedValidatedPayeeServerResponse;
  account: ValidatedPayeeAccountServerResponse | null;
  validated: boolean;
  verificationType: GuestVerificationType;
}

export interface VerificationLogStatisticsDataServerResponse {
  periodicTotalVerifications: number;
  periodicValidVerificationsPercentage: number;
}

export type SearchedAccountType = AccountRepresentationType | GuestVerificationType.BankAccountCertificate;

export interface IPSVerificationLog {
  logUniformId: string;
  timestamp: number;
  userEmail: string;
  userPhone: string;
  searchedAccountDetails: MorteeAccountDetails;
  searchedAccountDetailsFormatted: string;
  searchedAccountType: SearchedAccountType;
  aggregatedPayeeData: AggregatedValidatedPayee;
  account: ValidatedPayeeAccount | null;
  validated: boolean;
  verificationType: GuestVerificationType;
}

export enum GuestVerificationType {
  AccountVerification = 'AccountVerification',
  BankAccountCertificate = 'BankAccountCertificateVerification',
}

export interface VerificationLogStatisticsData {
  periodicTotalVerifications: number;
  periodicValidVerificationsPercentage: number;
}

export interface CreateVerificationReminderRequest {
  validatedPayeeUniformId: string;
  validatedPayeeAccountUniformId: string;
  payerName: string;
  emailDomainToNotify: string[];
  expirationTimestamp: number;
}

export enum VerificationReminderStatus {
  pending = 'Pending',
  deleted = 'Deleted',
  closed = 'Closed',
  expired = 'Expired',
}

export interface IPSVerificationReminderServerResponse {
  id: string;
  writeTimestamp: number;
  organizationId: string;
  performingUserId: string;
  status: VerificationReminderStatus;
  validatedPayee: AggregatedValidatedPayeeServerResponse;
  validatedPayeeAccount: ValidatedPayeeAccountServerResponse;
  payerName: string;
  emailDomainToNotify: string[];
  expirationTimestamp: number;
}

export interface IPSVerificationReminder {
  id: string;
  writeTimestamp: number;
  organizationId: string;
  performingUserId: string;
  status: VerificationReminderStatus;
  validatedPayee: AggregatedValidatedPayee;
  validatedPayeeAccount: ValidatedPayeeAccount;
  payerName: string;
  emailDomainToNotify: string[];
  expirationTimestamp: number;
}
