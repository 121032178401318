import BackeePermissionStore from '@backee/stores/infraStores/BackeePermissionStore';
import BackeeNavigationStore from '@backee/stores/infraStores/BackeeNavigationStore';
import LocaleStore from '@app/stores/LocaleStore';
import config from '@app/config';
import AuthenticatedInfraStores from '@app/login/stores/AuthenticatedInfraStores';
import { userIdentityHeadersGenerator } from '@app/utils/userIdentityHeadersGenerator';
import { DynamicHeadersGenerator } from '@app/stores/InfraStores';
import BackeeHealthCheckStore from '@backee/stores/infraStores/BackeeHealthCheckStore';
import BackeeThemeStore from '@backee/stores/infraStores/BackeeThemeStore';
import LanguageStore from '@app/stores/LanguageStore';
import UserStoreImpl from '@app/stores/UserStoreImpl';

export default class BackeeInfraStores extends AuthenticatedInfraStores<BackeeNavigationStore, BackeePermissionStore> {
  constructor(allSections: Section[], localePath: string) {
    const userStore = new UserStoreImpl();
    const languageStore = new LanguageStore(config.defaultLocale, localePath);
    const localeStore = new LocaleStore(languageStore);
    const permissionsStore = new BackeePermissionStore(allSections, userStore);
    const navigationStore = new BackeeNavigationStore(userStore, permissionsStore);
    const themeStore = new BackeeThemeStore();
    const healthCheckStore = new BackeeHealthCheckStore(userStore);
    super(userStore, languageStore, localeStore, navigationStore, permissionsStore, themeStore, healthCheckStore);
  }

  getDynamicHeadersGenerator(): DynamicHeadersGenerator {
    return (req: any): Promise<Record<string, string> | undefined> => userIdentityHeadersGenerator(req, this.userStore);
  }
}
