import React, { FC, ReactElement } from 'react';
import useLoadable from '@app/hooks/loadable/useLoadable';
import ValidatedPayeeManagementServices from '@mortee/services/validatedPayeeManagementServices';
import { transformValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';
import { useParams } from 'react-router-dom';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader from '@app/components/header/PageHeader';
import ValidatedPayeeManagementCard from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeManagementCard';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import browserHistory from '@app/utils/browserHistory';

const ManageValidatedPayeePage: FC = () => {
  const { validatedPayeeUniformId } = useParams<{ validatedPayeeUniformId: string }>();

  const [validatedPayeeWithAccounts] = useLoadable(async () => {
    const validatedPayeesWithAccountsServerResponses = await ValidatedPayeeManagementServices.getValidatedPayeesWithAccountsByUniformIds(
      [validatedPayeeUniformId],
    );
    const validatedPayeeWithAccountServerResponse = validatedPayeesWithAccountsServerResponses?.[0];

    if (!validatedPayeeWithAccountServerResponse) {
      throw new Error(`Could not find validated payee with uniform id ${validatedPayeeUniformId}`);
    }

    return transformValidatedPayeeWithAccounts(validatedPayeeWithAccountServerResponse);
  }, [validatedPayeeUniformId]);

  return (
    <PageWithHeader
      width='full'
      header={
        <PageHeader onClick={(): void => browserHistory.goBack()}>
          <PageHeaderTitle title='Manage Validated Payee' />
        </PageHeader>
      }
    >
      {validatedPayeeWithAccounts.resolve(
        (value): ReactElement => (
          <ValidatedPayeeManagementCard
            validatedPayeeWithAccounts={value}
            dataTestId={`manage-validated-payee-${value.payee.uniformId}`}
          />
        ),
        () => (
          <ValidatedPayeeManagementCard loading />
        ),
      )}
    </PageWithHeader>
  );
};

export default ManageValidatedPayeePage;
