import { getOS, OperatingSystem } from '@app/utils/utils';

export function readHTMLContentFromClipboardData(clipboardData: DataTransfer | null): string | null {
  return readContentTypeFromClipboardData(clipboardData, 'text/html') ?? null;
}

export function readPlaintextContentFromClipboardData(clipboardData: DataTransfer | null): string | null {
  return readContentTypeFromClipboardData(clipboardData, 'text/plain') ?? null;
}

export function readContentTypeFromClipboardData(clipboardData: DataTransfer | null, format: string): string | null | undefined {
  return clipboardData?.getData(format);
}

export async function readHTMLContentFromClipboardDirectly(): Promise<string | null> {
  return readContentTypeFromClipboardDirectly('text/html');
}

export async function readContentTypeFromClipboardDirectly(contentType: string): Promise<string | null> {
  let permissionQueryResult: PermissionStatus;
  try {
    permissionQueryResult = await navigator.permissions.query({ name: 'clipboard-read' });
  } catch (e) {
    throw new Error(ClipboardReadError.permissionNotWorking);
  }

  if (permissionQueryResult.state !== 'granted' && permissionQueryResult.state !== 'prompt') {
    throw new Error(ClipboardReadError.permissionNotGranted);
  }

  if (!navigator.clipboard?.read) {
    throw new Error(ClipboardReadError.readMethodNotSupported);
  }

  const clipboardItems = await navigator.clipboard.read();

  for (let i = 0; i < clipboardItems.length; i++) {
    const clipboardItem = clipboardItems[i];

    if (clipboardItem.types.includes(contentType)) {
      const html = await clipboardItem.getType(contentType);
      return await html.text();
    }
  }

  return null;
}

export enum ClipboardReadError {
  permissionNotWorking = 'permissionNotWorking',
  permissionNotGranted = 'noPermissions',
  readMethodNotSupported = 'readMethodNotSupported',
}

export function convertReadErrorToExplanation(readError: ClipboardReadError | string | null): string {
  const alternativeOptionText =
    'Please use the alternative:\nclick anywhere in the page and paste using the keyboard shortcut ' + getPasteShortcut() + '.';

  switch (readError) {
    case ClipboardReadError.readMethodNotSupported:
    case ClipboardReadError.permissionNotWorking: {
      return 'Could not read data from clipboard due to lack of browser support.\n\n' + alternativeOptionText;
    }
    case ClipboardReadError.permissionNotGranted: {
      return 'Could not acquire permissions to read data from clipboard.\n\n' + alternativeOptionText;
    }
  }

  return (
    'Could not read data from clipboard due to unknown error.\n\n' +
    alternativeOptionText +
    '\n\nIf the problem persists, please call support'
  );
}

function getPasteShortcut(): string {
  switch (getOS()) {
    case OperatingSystem.Linux:
    case OperatingSystem.UNIX:
    case OperatingSystem.Windows: {
      return `"Ctrl + V"`;
    }
    case OperatingSystem.MacOS: {
      return `"⌘ + V"`;
    }
    default: {
      return `"Ctrl + V" for Windows/Linux or "⌘ + V" for Mac OS`;
    }
  }
}
