interface TranslatedMessageObjectInput {
  key: string | string[],
  value: unknown
}

export type TranslatedMessageInput = string | string[] | TranslatedMessageObjectInput

export interface TranslatedMessage extends TranslatedMessageObjectInput {
  isTranslatedMessage: true;
}

export function convertToTranslatedMessage(translatedMessageInput: TranslatedMessageInput): TranslatedMessage {
  if (typeof translatedMessageInput === 'string' || Array.isArray(translatedMessageInput)) {
    return {
      isTranslatedMessage: true,
      key: translatedMessageInput,
      value: undefined,
    };
  }

  return {
    isTranslatedMessage: true,
    ...translatedMessageInput,
  };
}

export function isTranslatedMessage(obj: any): obj is TranslatedMessage {
  return obj && typeof obj === 'object' && 'isTranslatedMessage' in obj && obj['isTranslatedMessage'] === true;
}
