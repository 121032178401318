import React, { FunctionComponent, useRef } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { showInfoModal } from '@app/components/Modal';
import Button from '@app/components/Button';
import { ModalFunc } from 'antd/lib/modal/Modal';
import VerificationValidatedPayeeDetailsMoreInfo from './VerificationValidatedPayeeDetailsMoreInfo';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import { useTranslation } from 'react-i18next';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';

interface Props {
  payee: ValidatedPayeeToDisplay;
  className?: string;
}

const VerificationValidatedPayeeDetailsMoreInfoButton: FunctionComponent<Props> = observer(({ className, payee }) => {
  const openModalRef = useRef<ReturnType<ModalFunc>>();
  const { t } = useTranslation();
  const modalContext = useModalContext();

  const openMoreInfo = (): void => {
    if (payee) {
      const content = (
        <ModalAppContext {...modalContext}>
          <VerificationValidatedPayeeDetailsMoreInfo
            payee={payee}
            dataTestId='modalPayeeDetailsMoreInfo'
            onReturn={closeMoreInfo}
          />
        </ModalAppContext>
      );
      openModalRef.current = showInfoModal(content, { okType: 'ghost', className: 'card' });
    }
  };

  const closeMoreInfo = (): void => {
    openModalRef.current?.destroy();
    openModalRef.current = undefined;
  };

  const hasMoreInfo = (): boolean => {
    const { emails, phones, faxes, websites, descriptions } = payee;

    return !!emails?.length || !!phones?.length || !!faxes?.length || !!websites?.length || !!descriptions?.length;
  };

  return (
    <MoreInfoButton
      id='btn-more-info'
      appearance='text'
      size='small'
      disabled={!hasMoreInfo()}
      onClick={openMoreInfo}
      dataTestId='btnMoreInfo'
      className={className}
    >
      {t('general.upperMoreInfo', 'MORE INFO')}
    </MoreInfoButton>
  );
});

export default VerificationValidatedPayeeDetailsMoreInfoButton;

const MoreInfoButton = styled(Button)`
  display: inline-block;
`;
