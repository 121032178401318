import React, { FC } from 'react';
import NationalityFlag from './NationalityFlag';

export interface DataProps {
  countryCode?: string | null;
  unknownFlagStyle?: 'questionMark' | 'wavyFlag';
  size?: number;
  className?: string;
}

const LanguageFlag: FC<DataProps> = (props) => {
  const { countryCode, size = 20, unknownFlagStyle, className } = props;

  return <NationalityFlag className={className} countryCode={countryCode} size={size} unknownFlagStyle={unknownFlagStyle} />;
};

export default LanguageFlag;
