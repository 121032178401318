import { ColumnProps } from 'antd/lib/table';
import { getColumnConstFilterProps } from '@app/components/tableFilters/singleValue/constsFilterCreator';
import { daysBetween } from '@app/utils/timeUtils';
import { IPSVerificationReminder, VerificationReminderStatus } from '@mortee/domain/ips';

enum ExpirationTimeFilterOptions {
  today = 'today',
  in7Days = 'in7Days',
  in30Days = 'in30Days',
  in90Days = 'in90Days',
  expired = 'expired',
}

const futureTimeFilterOptionText: Record<ExpirationTimeFilterOptions, string> = {
  [ExpirationTimeFilterOptions.today]: 'Today',
  [ExpirationTimeFilterOptions.in7Days]: 'Next 7 days',
  [ExpirationTimeFilterOptions.in30Days]: 'Next 30 days',
  [ExpirationTimeFilterOptions.in90Days]: 'Next 3 months',
  [ExpirationTimeFilterOptions.expired]: 'Expired',
};

export function getColumnVerificationReminderExpirationTimeFilterProps(): Partial<ColumnProps<IPSVerificationReminder>> {
  const partTimeFilterOptions = Object.values(ExpirationTimeFilterOptions).map(
    (partTimeFilterOption: ExpirationTimeFilterOptions) => ({
      label: futureTimeFilterOptionText[partTimeFilterOption],
      value: partTimeFilterOption,
    }),
  );

  return getColumnConstFilterProps(
    'Expiration date',
    partTimeFilterOptions,
    (futureTimeFilterOptions: ExpirationTimeFilterOptions, record): boolean => {
      if (futureTimeFilterOptions === ExpirationTimeFilterOptions.expired) {
        return record.status === VerificationReminderStatus.expired;
      }

      if (record.status !== VerificationReminderStatus.pending) {
        return false;
      }

      const value = record.expirationTimestamp;
      const daysSinceTimestamp = daysBetween(Date.now(), value);

      switch (futureTimeFilterOptions) {
        case ExpirationTimeFilterOptions.today: {
          return daysSinceTimestamp < 1;
        }
        case ExpirationTimeFilterOptions.in7Days: {
          return daysSinceTimestamp < 7;
        }
        case ExpirationTimeFilterOptions.in30Days: {
          return daysSinceTimestamp < 30;
        }
        case ExpirationTimeFilterOptions.in90Days: {
          return daysSinceTimestamp < 90;
        }
      }

      return false;
    },
  );
}
