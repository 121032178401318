import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import Button from '@app/components/Button';
import { SubtitleRegularStartTransparentBlack900 } from '@app/components/Text';
import CardHeader from '@app/components/card/CardHeader';
import { StoreValidatedPayeeAccountRequest, ValidatedPayeeAccountVisibilityText } from '@mortee/domain/vaildatedPayeeManagement';
import ValidatedPayeeAccountManagementCard, {
  ValidatedPayeeAccountDisplay,
} from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeAccountManagementCard';
import { ValidatedPayeeAccountValidationStatus } from '@app/domain/validatedPayeeAccount';
import InnerCard from '@app/components/card/InnerCard';
import { RowSeparator } from '@app/components/Styles';
import { FormattedDate } from '@app/components/Locale';
import { isDefined } from '@app/utils/utils';
import StoreConfirmationAlerts from '@mortee/routes/validatedPayeesManagement/StoreConfirmationAlerts';
import { pluralize } from '@app/utils/stringUtils';
import AlertSpriteSymbol from '@app/images/alert-outline.svg';
import InfoSpriteSymbol from '@app/images/ic-info-primary.svg';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';

interface Props {
  accountValidationRequest: StoreValidatedPayeeAccountRequest;
  accountValidationType: ValidatedPayeeAccountValidationStatus;
  alerts: ReactNode[];
  notices: ReactNode[];
  onConfirm: () => void;
  onClose: () => void;
}

const CreateValidatedPayeeAccountConfirmationModal: FC<Props> = ({
  onConfirm,
  onClose,
  accountValidationRequest,
  accountValidationType,
  alerts,
  notices,
}) => {
  const {
    supplierValidationRegistrationNumber,
    accountDetails,
    bankCountryCode,
    bankName,
    furtherCredit,
    validationTimestamp,
    localFormat,
    ...nonValidatedAccountFields
  } = accountValidationRequest;

  const validatedPayeeAccountDisplay: ValidatedPayeeAccountDisplay = {
    accountDetails: { localFormat, ...accountDetails },
    bankName: bankName,
    furtherCredit: furtherCredit,
    bankCountryCode: bankCountryCode,
    supplierValidationRegistrationNumber: supplierValidationRegistrationNumber,
    validationTimestamp: validationTimestamp,
    status: accountValidationType,
    presentationAccountNumber: accountDetails.accountNumber,
    accountVisibility: nonValidatedAccountFields.accountVisibility,
  };

  const allOtherFields: Record<keyof typeof nonValidatedAccountFields, [string, ReactNode] | null> = {
    uniformId: null,
    accountVisibility: ['Visibility', ValidatedPayeeAccountVisibilityText[nonValidatedAccountFields.accountVisibility]],
    bankLogoResourceId: null,
    contributingOrganizationId: null,
    effectiveDateTimestamp: nonValidatedAccountFields.effectiveDateTimestamp
      ? ['Effective Date', <FormattedDate value={nonValidatedAccountFields.effectiveDateTimestamp} />]
      : null,
  };

  const renderOtherInformationCard = (): ReactNode => {
    return (
      <PaddedOtherInfoCard>
        <Title>Other Information</Title>
        <PaddedRowSeparator light />
        <DataGrid>
          {Object.values(allOtherFields)
            .filter(isDefined)
            .map(([fieldName, fieldValue]) => (
              <DataGridRow key={fieldName} title={fieldName} valueDataTest={`account-additional-details-${fieldName}`}>
                {fieldValue}
              </DataGridRow>
            ))}
        </DataGrid>
      </PaddedOtherInfoCard>
    );
  };

  return (
    <MainCard shadowType='elevated'>
      <CardHeader>VALIDATED PAYEE ACCOUNT CREATION SUMMARY</CardHeader>
      <AlertsBox
        title={`${alerts.length} data ${pluralize('check', alerts.length)} did not pass:`}
        icon={AlertSpriteSymbol}
        alerts={alerts.filter(isDefined)}
        data-testid='create-validated-payee-account-confirmation-modal-alert'
      />
      <NoticeBox
        title='Please Notice:'
        icon={InfoSpriteSymbol}
        alerts={notices.filter(isDefined)}
        data-testid='create-validated-payee-account-confirmation-modal-notice'
      />
      <PaddedValidatedPayeeAccountManagementCard
        validatedPayeeAccount={validatedPayeeAccountDisplay}
        dataTestId={`validated-account-card-new-account-confirmation-modal`}
      />
      {renderOtherInformationCard()}
      <ActionsContainer>
        <Button
          id='btn-create-validated-payee-account-confirmation-modal-close'
          appearance='text'
          colorScheme='secondary'
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          id='btn-create-validated-payee-account-confirmation-modal-create'
          appearance='full'
          colorScheme='secondary'
          onClick={onConfirm}
          autoFocus
        >
          CREATE
        </Button>
      </ActionsContainer>
    </MainCard>
  );
};

export default CreateValidatedPayeeAccountConfirmationModal;

const MainCard = styled(Card)`
  width: min(950px, 100vw);

  // Padding will be applied by the content
  padding: 0;

  --card-side-padding: 24px;
  --card-padding: 16px var(--card-side-padding);
`;

const AlertsBox = styled(StoreConfirmationAlerts)`
  background: var(--warning-color);
  padding: var(--card-padding);
  margin: 16px 0;
`;

const NoticeBox = styled(StoreConfirmationAlerts)`
  background: #8fcdf6;
  padding: var(--card-padding);
  margin: 16px 0;
`;

const PaddedValidatedPayeeAccountManagementCard = styled(ValidatedPayeeAccountManagementCard)`
  padding: var(--card-padding);
  margin: 0 var(--card-side-padding);
`;

const PaddedOtherInfoCard = styled(InnerCard)`
  padding: var(--card-padding);
  margin: 16px var(--card-side-padding) 0;
`;

const Title = styled(SubtitleRegularStartTransparentBlack900.div)`
  padding-bottom: 8px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  // To allow the buttons drop shadow to fully show
  padding: var(--card-padding);

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const PaddedRowSeparator = styled(RowSeparator)`
  margin: 14px 16px 14px 0;
`;
