import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { runInAction } from 'mobx';
import styled from '@emotion/styled';
import EmptyFiles from '@app/images/files/empty_upload_files.svg';
import Warning from '@app/images/warning-thick.svg';
import Delete from '@app/images/close-thick.svg';
import SVG from '@app/components/SVG';
import { confirm, ModalTitle, showInfoModal } from '@app/components/Modal';
import ModalErrorsList from './ModalErrorsList';
import { FileAndErrors } from '@app/domain/files';
import Button from '@app/components/Button';
import { BodyRegularStartTransparentBlack600, CaptionStartError600 } from '@app/components/Text';
import { useTranslation } from 'react-i18next';

interface UploadFileListProps {
  multiple?: boolean;
  files: FileAndErrors[];
  onFileDeleted: (file: File, index: number) => void;
}

const FileList: FunctionComponent<UploadFileListProps> = (props) => {
  const { t } = useTranslation();

  const renderDeleteConfirmContent = (filename: string): ReactElement => {
    return (
      <RemoveFileContainer>
        <ModalTitle>Remove File</ModalTitle>
        <RemoveFileText>
          <RemoveFileTextLine>Are you sure you want to remove</RemoveFileTextLine>
          <RemoveFileTextLine>"{filename}"?</RemoveFileTextLine>
        </RemoveFileText>
      </RemoveFileContainer>
    );
  };

  const handleOnClick = (fileAndError: FileAndErrors, e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (fileAndError.errors?.length) {
      e.stopPropagation();
      showInfoModal(<ModalErrorsList errors={fileAndError.errors} />, {
        okType: 'ghost',
        className: 'warning',
      });
    }
  };

  const deleteFile = (fileAndError: FileAndErrors, e): void => {
    e.stopPropagation();
    const { files, onFileDeleted } = props;
    const index = files.map((x) => x.file).indexOf(fileAndError.file);

    if (index === -1) {
      return;
    }

    // If the file already has an error, no need to ask permission from the user to delete it
    // since it can not be uploaded anyway
    if (fileAndError.errors?.length) {
      onFileDeleted?.(fileAndError.file, index);
      return;
    }

    confirm({
      content: renderDeleteConfirmContent(fileAndError.file.name),
      okCancel: true,
      okText: 'CANCEL',
      cancelText: 'REMOVE FILE',
      icon: <span />,
      onCancel: () => {
        runInAction(() => {
          const index = files.map((x) => x.file).indexOf(fileAndError.file);
          if (index > -1) {
            onFileDeleted?.(fileAndError.file, index);
          }
        });
      },
    });
  };

  const renderFileNameAndError = (fileAndError: FileAndErrors, index: number): ReactElement => {
    return (
      <>
        {fileAndError.errors?.length ? (
          <>
            <ErrorFileName>{fileAndError.file.name}</ErrorFileName>
            {renderFileError(index)}
          </>
        ) : (
          <span>{fileAndError.file.name}</span>
        )}
      </>
    );
  };

  const renderFileError = (index: number): ReactElement => {
    const errorText = 'We found a problem with this file, click for details';
    return (
      <FileRowError>
        <SVG accessibilityLabel={t('general.accessibility.warning')} image={Warning} height={18} />
        <ErrorText data-testid={`${index}_errorText`}>{errorText}</ErrorText>
      </FileRowError>
    );
  };

  const renderFileRow = (fileAndError: FileAndErrors, index: number): ReactElement => (
    <FileRow
      onClick={(e): void => {
        handleOnClick(fileAndError, e);
      }}
      key={`${fileAndError.file.name}-${index}`}
      data-testid={`${index}_fileRow`}
    >
      <FileNameAndError>{renderFileNameAndError(fileAndError, index)}</FileNameAndError>
      <RemoveFileButton
        id={`btn-file-list-file-delete-${index}`}
        dataTestId={`btn-file-list-file-delete-${index}`}
        onClick={(e): void => deleteFile(fileAndError, e)}
        appearance='text'
        colorScheme='primary'
      >
        <SVG accessibilityLabel={t('general.accessibility.removeFile')} image={Delete} height={18} />
      </RemoveFileButton>
    </FileRow>
  );

  const renderFiles = (files: FileAndErrors[]): ReactNode => {
    if (files.length === 0) {
      return (
        <EmptyFilesContainer>
          <SVG accessibilityLabel={t('general.accessibility.uploadFiles')} image={EmptyFiles} height={99} />
        </EmptyFilesContainer>
      );
    }
    return files
      .slice()
      .sort((a, b) => (b.errors ? b.errors.length : 0) - (a.errors ? a.errors.length : 0))
      .map((x, index) => renderFileRow(x, index));
  };

  return <GrayCard>{renderFiles(props.files)}</GrayCard>;
};

export default FileList;

const GrayCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--primary-200-50-a);
  padding: 0 16px;
  height: 300px;
  width: 100%;
  overflow: auto;
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 0;
  border-bottom: solid 1px var(--primary-200-50-a);
`;

const FileNameAndError = styled(BodyRegularStartTransparentBlack600.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: start;
  flex: 1;

  min-height: 42px;

  & > *:not(:first-child) {
    margin-top: 2px;
  }
`;

const FileRowError = styled(CaptionStartError600.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EmptyFilesContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.span`
  margin-left: 4px;
`;

const RemoveFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
`;

const RemoveFileText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const RemoveFileTextLine = styled.div`
  font-size: 15px;
  color: var(--gray-blue-deprecated);
  letter-spacing: 0.1px;
`;

const RemoveFileButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(53, 60, 79, 0.4);
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const ErrorFileName = styled.span`
  color: var(--error-600);
`;
