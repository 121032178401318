import React, { ReactElement, ReactNode } from 'react';
import { Table as AntTable } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import TableHeaderInfoIcon from '@app/images/table-header-info.svg';
import { FormattedDate } from '@app/components/Locale';
import { useTranslation } from 'react-i18next';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import {
  BodySmallStartTransparentBlack600,
  SubtitleSmallStartTransparentBlack600,
  SubtitleSmallStartTransparentBlack900,
} from '../Text';

export interface ExtendedColumnProps<T> extends ColumnProps<T> {
  hidden?: boolean;
  hideFilter?: boolean;
}

export interface ObserverTableProps<T> extends Omit<TableProps<T>, 'columns'> {
  isLoaded?: boolean;
  emptyComponent?: ReactElement | (() => ReactElement);
  rowClickable?: boolean;
  rowHighlightable?: boolean;
  isDenseMode?: boolean;
  columns: ExtendedColumnProps<T>[];
  disableTableOperations?: boolean;
}

export function convertExtendedColumnsToRegularColumns<T>(
  columns: ExtendedColumnProps<T>[],
  disableTableOperations: boolean | undefined,
): ColumnProps<T>[] {
  return columns
    .filter((column) => !column.hidden)
    .map((column) => {
      if (!column.hideFilter && !disableTableOperations) {
        return column;
      }

      return {
        ...column,
        defaultFilteredValue: undefined,
        filterDropdown: undefined,
        filterDropdownVisible: undefined,
        filtered: undefined,
        filteredValue: undefined,
        filterIcon: undefined,
        filterMultiple: undefined,
        filters: undefined,
        onFilter: undefined,
        onFilterDropdownVisibleChange: undefined,
      };
    })
    .map((column) => {
      if (!disableTableOperations) {
        return column;
      }

      return {
        ...column,
        sorter: false,
        sortOrder: undefined,
        sortDirections: undefined,
        defaultSortOrder: undefined,
      };
    });
}

function Table<T>(props: ObserverTableProps<T>): ReactElement {
  const { isLoaded, emptyComponent, columns, disableTableOperations, rowClassName, ...tableProps } = props;

  function renderEmptyComponentText(): ReactNode {
    if (!tableProps.loading && isLoaded !== false && emptyComponent && !tableProps.dataSource?.length) {
      if (typeof emptyComponent === 'function') {
        return emptyComponent();
      }

      return emptyComponent;
    }
  }

  const visibleColumns = convertExtendedColumnsToRegularColumns(columns, disableTableOperations);

  return (
    <StyledTable
      {...tableProps}
      columns={visibleColumns}
      rowClassName={rowClassName ?? rowClassFactory}
      locale={{ emptyText: renderEmptyComponentText() }}
    />
  );
}

export default Table;

const DEFAULT_ROW_STYLE = css`
  td {
    border-bottom: solid 1px #eeeff0 !important;
  }
`;

const rowClassFactory = (): string => DEFAULT_ROW_STYLE;

export function removeColumnInteractionProperties<T>(column: ColumnProps<T>): ColumnProps<T> {
  const {
    defaultSortOrder,
    filterDropdown,
    filterDropdownVisible,
    filters,
    filterIcon,
    filteredValue,
    filterMultiple,
    onFilter,
    onFilterDropdownVisibleChange,
    sorter,
    sortOrder,
    sortDirections,
    ...requestedProps
  } = column;

  return requestedProps;
}

export const OddRow = css`
  &.ant-table-row.ant-table-row-level-0 {
    background-color: var(--primary-200-50-a) !important;
  }
`;

export const NotFirstRowInGroup = css`
  &.ant-table-row.ant-table-row-level-0 > td {
    padding-top: 0;
  }
`;

export const getRowClassName = (row: { groupIndex: number; rowSpan?: number }): string => {
  const classConditions = {
    [OddRow]: row.groupIndex % 2 === 1,
    [NotFirstRowInGroup]: row.rowSpan === 0,
  };

  return Object.entries(classConditions)
    .filter(([, shouldApply]) => shouldApply)
    .map(([className]) => className)
    .join(' ');
};

const StyledTable = styled(AntTable)<ObserverTableProps<any>>`
  .ant-table-tbody > tr:hover > td {
    ${({ rowClickable, rowHighlightable }): string =>
      !rowClickable && !rowHighlightable ? `background: transparent !important;` : ''}
  }

  .ant-table-tbody > tr > td {
    font-size: ${({ isDenseMode }): number => (isDenseMode ? 13 : 15)}px;
    padding-left: ${({ isDenseMode }): number => (isDenseMode ? 16 : 24)}px;
    padding-right: ${({ isDenseMode }): number => (isDenseMode ? 16 : 24)}px;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 14px;
    }

    // Set the padding left of the first td in a row back to normal
    // in case this td is in a row that it's first columns are overrun by rowspan
    // meaning that this td is not really the first td displayed in that row
    &:first-child:not([rowspan]) {
      padding-left: ${({ isDenseMode }): number => (isDenseMode ? 16 : 24)}px;
    }
  }

  .ant-table-thead > tr {
    background: transparent !important;

    & > th {
      font-size: 14.2px;
      font-weight: bold;
      color: var(--primary-200) !important;

      background: transparent !important;

      border: none;
      border-bottom: solid 1px #eeeff0 !important;
      padding: 14px ${({ isDenseMode }): number => (isDenseMode ? 16 : 24)}px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 14px;
      }
    }
  }

  .ant-table-header {
    background-color: transparent !important;
  }

  .ant-table-body {
    background-color: transparent !important;
  }

  .ant-table-row {
    ${({ rowClickable }): string =>
      rowClickable
        ? `
      &:hover {
        cursor: pointer;
      }
      &:active {
        cursor: pointer;
      }
    `
        : ''}
  }

  width: 100%;
`;

const TableExplanationHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTableHeaderExplanationIcon = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-left: 8px;
  cursor: pointer;
`;

export const TableExplanationHeader = (props: {
  dataTestId: string;
  onExplanation: () => void;
  children: ReactNode;
}): ReactElement => {
  const { dataTestId, onExplanation, children } = props;
  const { t } = useTranslation();
  return (
    <TableExplanationHeaderContainer data-testid={dataTestId}>
      {children}
      <StyledTableHeaderExplanationIcon
        accessibilityLabel={t('general.accessibility.explainer')}
        image={TableHeaderInfoIcon}
        onClick={onExplanation}
      />
    </TableExplanationHeaderContainer>
  );
};

export const TableFormattedDate = styled(FormattedDate)`
  font-size: 15px;
  color: var(--gray-blue-deprecated);
  font-weight: 200;
`;

export const TableStandardText = styled.div`
  ${BodySmallStartTransparentBlack600.css};
  ${WRAP_WORDS_CSS_PROPERTIES}
`;

export const TableSmallText = styled.span`
  font-size: 13px;
  font-weight: 200;
  color: var(--transparent-black-50-deprecated);
  ${WRAP_WORDS_CSS_PROPERTIES}
`;

export const TableStandardSVG = styled(SVG)`
  width: 18px;
  height: 18px;
  display: block;
  margin: auto;
`;

export const TableImportantText = styled.div`
  ${SubtitleSmallStartTransparentBlack900.css};
  ${WRAP_WORDS_CSS_PROPERTIES}
`;

export const TableStatusText = styled(TableStandardText)`
  ${SubtitleSmallStartTransparentBlack600.css}
`;
