import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import browserHistory from '@app/utils/browserHistory';
import GuestVerificationWithCertificateAfterVerificationPage from '@mortee/routes/accountVerification/certificateVerificationPage/AccountVerificationWithCertificateAfterVerificationPage';
import AccountVerificationWithCertificateBeforeVerificationPage from '@mortee/routes/accountVerification/certificateVerificationPage/AccountVerificationWithCertificateBeforeVerificationPage';
import * as accountVerificationService from '@mortee/services/accountVerificationService';
import Log from '@app/libs/logger';
import { extractLogErrorIdFromError, HttpStatus, isRequestError, isWebErrorContent } from '@app/libs/request';
import sleep from '@app/utils/sleep';
import MorteeMode from '@mortee/morteeMode';
import {
  AccountVerificationBankAccountCertificateVerifyResult,
  CertificateVerificationError,
  CertificateVerificationErrorsTypes,
  transformAccountVerificationBankAccountCertificateVerifyResult,
} from '@app/domain/bac';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';

const AccountVerificationWithCertificatePage: FC = observer(() => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { payeeSearchStore } = useAppStores<MorteeMode>();

  const selectedCertificateFile = payeeSearchStore.selectedBankAccountCertificateFile;

  // We need to make sure the file exist in the global store
  if (!selectedCertificateFile) {
    payeeSearchStore.resetAll();
    browserHistory.push(navigationStore.generateAccountVerificationHomeLink());
    return <div />;
  }

  const [verificationError, setVerificationError] = useState<CertificateVerificationError | null>(null);
  const [verificationResult, verifyCertificate] = useManuallyCalledLoadable(
    async (): Promise<AccountVerificationBankAccountCertificateVerifyResult> => {
      try {
        // Make all verify certificate requests take at lease 3 seconds
        const [verifyResultServerResponse] = await Promise.all([
          accountVerificationService.verifyAccountByBankAccountCertificate(selectedCertificateFile),
          sleep(3000),
        ]);

        const verifyResult = transformAccountVerificationBankAccountCertificateVerifyResult(verifyResultServerResponse);

        const validated = verifyResult.validationResult !== DeterministicValidationResultType.notValidated;
        Log.event('accountValidationResult', { validated }, true);

        return verifyResult;
      } catch (e: unknown) {
        if (!(isRequestError(e) && e.code === HttpStatus.badRequest && isWebErrorContent(e.responseJSON))) {
          Log.event('accountValidationResult', { error: 'Unknown' }, true);
          setVerificationError({
            errorType: 'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR',
            errorLogId: extractLogErrorIdFromError(e),
          });
          throw e;
        }

        Log.event('accountValidationResult', { error: e.responseJSON.error }, true);
        if (CertificateVerificationErrorsTypes.includes(e.responseJSON.error)) {
          setVerificationError({
            errorType: e.responseJSON.error,
            errorLogId: extractLogErrorIdFromError(e),
          });
        } else {
          setVerificationError({
            errorType: 'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR',
            errorLogId: extractLogErrorIdFromError(e),
          });
        }
        throw e;
      }
    },
  );

  const replaceCertificate = (newCertificate: File): void => {
    payeeSearchStore.resetToAfterCertificateSelected(newCertificate);
    setVerificationError(null);
  };

  if (verificationResult.isInProgress()) {
    return <GuestVerificationWithCertificateAfterVerificationPage loading />;
  }

  if (!verificationError && verificationResult.isResolved()) {
    return <GuestVerificationWithCertificateAfterVerificationPage verificationResult={verificationResult.result} />;
  }

  return (
    <AccountVerificationWithCertificateBeforeVerificationPage
      certificate={selectedCertificateFile}
      verifyCertificate={verifyCertificate}
      replaceCertificate={replaceCertificate}
      certificateVerificationError={verificationError}
    />
  );
});

export default AccountVerificationWithCertificatePage;
