import React, { FC, ReactNode } from 'react';
import { Bold13TransparentBlack900, Regular13TransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import { isTextPresent } from '@app/utils/reactComponentUtils';

interface Props {
  className?: string;
  hideWhenEmpty?: boolean;
  children: ReactNode;
}

interface GridRowProps {
  title: ReactNode;
  valueDataTest: string;
  className?: string;
  children: ReactNode;
}

const DataGrid: FC<Props> = ({ className, hideWhenEmpty, children }) => {
  if (hideWhenEmpty && !isTextPresent(children)) {
    return <></>;
  }

  return <KeyValueGrid className={className}>{children}</KeyValueGrid>;
};

export const DataGridRow: FC<GridRowProps> = ({ title, valueDataTest, className, children }) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Content className={className} data-testid={valueDataTest}>
        {children}
      </Content>
    </React.Fragment>
  );
};

const Title = styled(Bold13TransparentBlack900.div)`
  font-size: var(--info-row-title-font-size, var(--info-row-font-size));
`;

const Content = styled(Regular13TransparentBlack900.div)`
  font-size: var(--info-row-content-font-size, var(--info-row-font-size));
  ${WRAP_WORDS_CSS_PROPERTIES};
`;

export const ArGridRowMultiline = styled(DataGridRow)`
  word-wrap: break-word;

  & > *:not(:first-child) {
    margin-top: 6px;
  }
`;

export default DataGrid;

const KeyValueGrid = styled.div`
  --info-row-font-size: 13px;

  font-size: var(--info-row-font-size);

  display: grid;
  grid-template-columns: minmax(max(10ch, 25%), max-content) auto;
  gap: var(--info-row-gap, 12px) 14px;
  align-content: start;

  margin-top: var(--info-row-gap, 10px);
`;
