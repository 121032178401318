import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { SyncInfo, SyncPlatform } from '@mortee/domain/masterDataGuard';
import Loadable from '@app/utils/Loadable';
import { FormattedDateTime } from '@app/components/Locale';

interface SyncInfoLineProps {
  info: Loadable<SyncInfo>;
  className?: string;
}

const SyncInfoLine: FC<SyncInfoLineProps> = ({ info, className }) => {
  const content: ReactNode = info.resolve(
    (loadedInfo): ReactNode => {
      const { lastSyncProcess } = loadedInfo;

      if (!lastSyncProcess || !lastSyncProcess.sourceTimestamp) {
        return <></>;
      }
      const { requestingPlatform } = lastSyncProcess;

      let platformText;

      switch (requestingPlatform) {
        case SyncPlatform.SFTP:
          platformText = 'PaymentKnox Connect';
          break;
        case SyncPlatform.SAP:
        case SyncPlatform.Oracle:
        case SyncPlatform.Priority: {
          platformText = `ERP (${requestingPlatform})`;
          break;
        }
        case SyncPlatform.Portal: {
          platformText = 'MVF Upload';
          break;
        }
        default: {
          platformText = 'Unknown';
          break;
        }
      }

      return (
        <>
          <AccentBullet />
          &nbsp;Last sync was at&nbsp;
          <FormattedDateTime value={lastSyncProcess.sourceTimestamp} />
          &nbsp;- received via {platformText}
        </>
      );
    },
    (): ReactNode => {
      return <></>;
    },
  );

  return <Container className={className}>{content}</Container>;
};

export default SyncInfoLine;

const Container = styled.span`
  display: flex;
  align-items: center;

  font-size: 11px;
  letter-spacing: 0.31px;
  color: var(--black-weak);
`;

const AccentBullet = styled.span`
  background-color: var(--accent-blue-600);
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;
