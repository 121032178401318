import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';

export enum IndonesiaLegalIdTypesForCompany {
  NPWP = 'NPWP',
  Other = 'Other',
}

function getAdditionalSwiftRules(): ConditionalValidationRule[] {
  return [
    {
      required: true,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const ID: SupplierRegistrationCountryFields = {
  leis: { types: IndonesiaLegalIdTypesForCompany },
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
};
