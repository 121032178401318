import { trim } from '@app/utils/stringUtils';
import { ACCOUNT_FIELD_MAX_LENGTH, INFORMATION_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { AccountType } from '@app/domain/accountsDetailsHelpers';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CURRENCIES_KEYS } from '@app/domain/currencies';

enum BrazilLegalIdTypesForCompany {
  CNPJ = 'CNPJ',
}

function showAccountType(form: WrappedFormUtils<BaseFormFields>, isIndividual: boolean): boolean {
  return [CURRENCIES_KEYS.BRL, CURRENCIES_KEYS.USD].includes(form.getFieldValue('currency')) && !isIndividual;
}

const AccountTypeInfo = {
  [AccountType.Checking]: 'Checking',
  [AccountType.Saving]: 'Saving',
};

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-BR-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.errors.missingBankCode`,
      },
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.BR.bank,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.BR.errors.bankCodeLength',
          value: ACCOUNT_FIELD_MAX_LENGTH.BR.bank,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidBankCode',
      },
    ],
  };
}

function getBranchCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-BR-branch-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.BR.errors.missingBranchCode`,
      },
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.BR.branch,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.BR.errors.branchCodeLength',
          value: ACCOUNT_FIELD_MAX_LENGTH.BR.branch,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidBranchCode',
      },
    ],
  };
}

export const BR: SupplierRegistrationCountryFields = {
  leis: { types: BrazilLegalIdTypesForCompany, defaultValue: BrazilLegalIdTypesForCompany.CNPJ },
  legalIdTypesForCompany: [
    {
      key: BrazilLegalIdTypesForCompany.CNPJ,
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingLegalIdValue',
        },
        {
          len: INFORMATION_FIELD_MAX_LENGTH.BR.CNPJ,
          transform: trim,
          translatedMessage: `supplierValidation.supplierRegister.BR.errors.legalIdValueLength`,
        },
        {
          pattern: VALIDATION_PATTERNS.numeric,
          transform: trim,
          translatedMessage: `supplierValidation.supplierRegister.BR.errors.legalIdValueLength`,
        },
      ],
    },
  ],
  branchCode: getBranchCodeFields,
  bankCode: getBankCodeFields,
  accountType: AccountTypeInfo,
  showAccountType: showAccountType,
};
