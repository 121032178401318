import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { Regular14TransparentBlack900 } from '@app/components/Text';
import { useProgressTabsContext } from '@mortee/components/ProgressTabs/ProgressTabsContext';
import { getSpecificTabId } from './data';
import ProgressTab from '@mortee/components/ProgressTabs/ProgressTab';

const ProgressTabsList: FC<{ className?: string }> = ({ className }): ReactElement => {
  const { id, dataTestId, options, selectedTab, accessibilityLabel } = useProgressTabsContext();

  return (
    <MainTabListContainer
      className={className}
      role='tablist'
      data-testid={`${dataTestId}-tablist`}
      aria-label={accessibilityLabel}
      aria-orientation='vertical'
    >
      {options.map((option, index) => {
        return (
          <ProgressTab
            key={option.key}
            id={getSpecificTabId(id, option.key)}
            dataTestId={`${dataTestId}-tablist-option-${option.key}`}
            indicator={option.indicator}
            selected={option.key === selectedTab.value}
            onSelection={(): void => selectedTab.onChange(option.key)}
            isFirstTab={index === 0}
            isLastTab={index === options.length - 1}
          >
            {option.title}
          </ProgressTab>
        );
      })}
    </MainTabListContainer>
  );
};

export default ProgressTabsList;

const MainTabListContainer = styled(Regular14TransparentBlack900.div)`
  --bullet-dimention: 22px;
  --content-vertical-padding: 11px;
`;
