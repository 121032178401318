import React, { FC, useState } from 'react';
import SVG, { SpriteSymbol } from '@app/components/SVG';
import styled from '@emotion/styled';
import useInterval from '@app/hooks/useInterval';

// First logos
import EyImage from '@app/images/companyLogo/1/ey.svg';
import RockwellImage from '@app/images/companyLogo/1/rockwell.svg';
import SvbImage from '@app/images/companyLogo/1/svb.svg';
import FoursqaureImage from '@app/images/companyLogo/1/foursquare.svg';
import MedatronicImage from '@app/images/companyLogo/1/medtronic.svg';
import NvidiaImage from '@app/images/companyLogo/1/nvidia.svg';
import DeloitteImage from '@app/images/companyLogo/1/deloitte.svg';
import MarriottImage from '@app/images/companyLogo/1/marriott.svg';

// Second logos
import AonImage from '@app/images/companyLogo/2/aon.svg';
import UodImage from '@app/images/companyLogo/2/university-of-delaware.svg';
// import AdeccoImage from '@app/images/companyLogo/2/aon.svg'; // Should change
import IdgImage from '@app/images/companyLogo/2/idg-communications.svg';
import KuImage from '@app/images/companyLogo/2/ku-leuven.svg';
import SelImage from '@app/images/companyLogo/2/sel.svg';
import SingaporeImage from '@app/images/companyLogo/2/singapore-post.svg';
import TeledyneImage from '@app/images/companyLogo/2/teledyne.svg';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import { useTranslation } from 'react-i18next';

const FIRST_LOGOS: { image: SpriteSymbol; clientName: string }[] = [
  { image: EyImage, clientName: 'Ernst and Young' },
  { image: RockwellImage, clientName: 'Rockwell Automation' },
  { image: SvbImage, clientName: 'Silicon Valley Bank' },
  { image: FoursqaureImage, clientName: 'Foursquare City Guide' },
  { image: MedatronicImage, clientName: 'Medtronic' },
  { image: NvidiaImage, clientName: 'Nvidia' },
  { image: DeloitteImage, clientName: 'Deloitte' },
  { image: MarriottImage, clientName: 'Marriott' },
];
const SECOND_LOGOS: { image: SpriteSymbol; clientName: string }[] = [
  { image: AonImage, clientName: 'Aon' },
  { image: UodImage, clientName: 'University of Delaware' } /* AdeccoImage, */,
  { image: IdgImage, clientName: 'International Data Group' },
  { image: KuImage, clientName: 'KULE' },
  { image: SelImage, clientName: 'Schweitzer Engineering Laboratories' },
  { image: SingaporeImage, clientName: 'Singapore Post' },
  { image: TeledyneImage, clientName: 'Teledyne Technologies' },
];

const REPLACE_DURATION = 4000;

interface LogoStripProps {
  className?: string;
}

const LogoStrip: FC<LogoStripProps> = ({ className }) => {
  const [showFirstLogos, setShowFirstLogos] = useState(true);
  const { t } = useTranslation();

  useInterval(() => {
    setShowFirstLogos(!showFirstLogos);
  }, REPLACE_DURATION);

  return (
    <StripWrapper data-testid='logos-strip' className={className}>
      <StripContainer isHidden={!showFirstLogos}>
        {FIRST_LOGOS.map((resource, index) => (
          <CompanySVG
            accessibilityLabel={t('general.accessibility.logoOfCompany', { clientName: resource.clientName })}
            key={`g1-${index}`}
            image={resource.image}
          />
        ))}
      </StripContainer>
      <StripContainer isHidden={showFirstLogos}>
        {SECOND_LOGOS.map((resource, index) => (
          <CompanySVG
            accessibilityLabel={t('general.accessibility.logoOfCompany', { clientName: resource.clientName })}
            key={`g2-${index}`}
            image={resource.image}
          />
        ))}
      </StripContainer>
    </StripWrapper>
  );
};

export default LogoStrip;

const StripWrapper = styled.div`
  margin-top: 20px;

  display: grid;
  grid-template-areas: 'content';
`;

const StripContainer = styled('div', { shouldForwardProp: preventForwardTheseProps('isHidden') })<{ isHidden: boolean }>`
  grid-area: content;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  transition: opacity 1s ease;

  opacity: ${(p): string => (p.isHidden ? '0' : '1')};
`;

const CompanySVG = styled(SVG)`
  max-height: 19.35px;

  &:not(:first-child) {
    margin-left: min(5vh, 45px);
  }
`;
