import UserStoreImpl from '@app/stores/UserStoreImpl';
import MorteePermissionStore from '@mortee/stores/infraStores/MorteePermissionStore';
import MorteeNavigationStore from '@mortee/stores/infraStores/MorteeNavigationStore';
import LocaleStore from '@app/stores/LocaleStore';
import config from '@app/config';
import AuthenticatedInfraStores from '@app/login/stores/AuthenticatedInfraStores';
import { userIdentityHeadersGenerator } from '@app/utils/userIdentityHeadersGenerator';
import { DynamicHeadersGenerator } from '@app/stores/InfraStores';
import { RequestOptions } from '@app/libs/request';
import MorteeHealthCheckStore from '@mortee/stores/infraStores/MorteeHealthCheckStore';
import MorteeThemeStore from '@mortee/stores/infraStores/MorteeThemeStore';
import LanguageStore from '@app/stores/LanguageStore';
import KnoxersStore from '@app/stores/KnoxersStore';

export default class MorteeInfraStores extends AuthenticatedInfraStores<MorteeNavigationStore, MorteePermissionStore> {
  readonly knoxersStore: KnoxersStore;

  constructor(allSections: Section[], localePath: string) {
    const userStore = new UserStoreImpl(true);
    const languageStore = new LanguageStore(config.defaultLocale, localePath);
    const localeStore = new LocaleStore(languageStore);
    const permissionsStore = new MorteePermissionStore(allSections, userStore);
    const navigationStore = new MorteeNavigationStore(userStore, permissionsStore);
    const themeStore = new MorteeThemeStore();
    const healthCheckStore = new MorteeHealthCheckStore(userStore);
    super(userStore, languageStore, localeStore, navigationStore, permissionsStore, themeStore, healthCheckStore);

    this.knoxersStore = new KnoxersStore();
  }

  getDynamicHeadersGenerator(): DynamicHeadersGenerator {
    return (req: RequestOptions): Promise<Record<string, string> | undefined> =>
      userIdentityHeadersGenerator(req, this.userStore);
  }
}
