export function createUpdateRequestFromUpdateFieldsDiff<T extends Record<any, Exclude<any, undefined>>>(
  old: T | null | undefined,
  updated: T,
  fieldsEquator: ObjectFieldsEquators<T>,
): UpdateRequestObject<T> {
  return Object.fromEntries(
    Object.keys(updated)
      .filter((fieldName) => {
        const oldValue = old?.[fieldName] ?? null;
        const updatedValue = updated[fieldName] ?? null;
        const equalsFunctionOfField = fieldsEquator[fieldName];

        return !equalsFunctionOfField?.(oldValue, updatedValue);
      })
      .map((fieldName): [string, UpdateField<any>] => [fieldName, { content: updated[fieldName] }]),
  ) as UpdateRequestObject<T>;
}

export function calcUpdateRequestAmountOfChanges(updateRequest: UpdateRequestObject<any>): number {
  // Use the reduce function to accumulate the total count
  return Object.values(updateRequest).reduce((accumulator, value) => {
    if (value && Array.isArray(value.content)) {
      // If the value is an array, add its length to the accumulator
      return accumulator + value.content.length;
    }

    if (value) {
      // If the value is defined, increment the accumulator by 1
      return accumulator + 1;
    }

    // Otherwise, keep the accumulator unchanged
    return accumulator;
  }, 0);
}
