import React, { FC, ReactNode } from 'react';
import LogoImage from '@app/images/nsknox_logo.svg';
import LogoutImage from '@app/guestLogin/images/guest-logout.svg';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import useAppStores from '@app/hooks/useAppStores';
import ArMode from '@ar/arMode';
import GuestLoginProgress from '@app/guestLogin/routes/GuestLoginProgress';
import { GuestLoginStep } from '@app/guestLogin/domain/guestLogin';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import { useTranslation } from 'react-i18next';
import ChangeLanguageDropdown from '@app/components/ChangeLanguageDropdown';
import PoweredByLogo from '@app/images/powered-by.svg';
import NsknoxLogoWhite from '@app/images/nsknox-white.svg';
import NsknoxLogoBlack from '@app/images/nsknox-black.svg';
import ImageFromResource from '@app/components/ImageFromResource';
import useTheme from '@app/hooks/useTheme';

interface Props {
  className?: string;
}

const ARHeader: FC<Props> = observer((props) => {
  const { guestLoginStore, themeStore } = useInfraStores<ArMode>();
  const { arVerificationStore } = useAppStores<ArMode>();
  const { headerTextColor } = useTheme<ArMode>();
  const { children, ...rest } = props;
  const { t } = useTranslation();

  const onLogout = async (): Promise<void> => {
    await guestLoginStore.logout();
    arVerificationStore.resetAll();
  };

  const guestLoginStep =
    guestLoginStore.isLoggedIn && arVerificationStore.isAfterValidation
      ? GuestLoginStep.AfterValidation
      : guestLoginStore.loginStep;

  const renderLogo = (): ReactNode => {
    if (!themeStore.theme.brand) {
      return (
        <LogoContainer>
          <StyledLogo accessibilityLabel={t('general.accessibility.logoOfCompany', { clientName: 'nsKnox' })} image={LogoImage} />
        </LogoContainer>
      );
    }

    const { clientName, logo } = themeStore.theme.brand;
    return (
      <LogoContainer>
        <StyledHeaderLogo altName={t('general.accessibility.logoOfCompany', { clientName })} resource={logo} />
        <SubLogoContainer>
          <PoweredBy accessibilityLabel={t('general.accessibility.poweredBy')} image={PoweredByLogo} />
          <NsknoxRefLogo accessibilityLabel='nsKnox' image={themeStore.theme.isHeaderDark ? NsknoxLogoWhite : NsknoxLogoBlack} />
        </SubLogoContainer>
      </LogoContainer>
    );
  };

  return (
    <BlueHeader {...rest}>
      <HeaderSection>{renderLogo()}</HeaderSection>
      <StyledGuestLoginProgress step={guestLoginStep} />
      <HeaderSection>
        <LogoutLanguageContainer>
          <ChangeLanguageDropdown textColorScheme={headerTextColor} />
          {guestLoginStore.isLoggedIn && (
            <StyledActionsContainer>
              <Button id='btn-guest-logout' appearance='text' onClick={onLogout} colorScheme={headerTextColor}>
                <ButtonContent>
                  <StyledLogoutSVG
                    accessibilityLabel=''
                    image={LogoutImage}
                    width={17}
                    height={17}
                    colorScheme={headerTextColor}
                  />
                  {t('general.upperLogout')}
                </ButtonContent>
              </Button>
            </StyledActionsContainer>
          )}
        </LogoutLanguageContainer>
      </HeaderSection>
    </BlueHeader>
  );
});

export default ARHeader;

const HeaderSection = styled.div`
  flex: 1;
`;

const LogoutLanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const BlueHeader = styled.div`
  background-color: var(--header-color);
  min-height: 130px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  flex: 1;

  padding: 24px clamp(10px, 5vh, 32px);

  color: var(--header-text-color);
  --text-color: var(--header-text-color);
  --current-or-passed-step-color: var(--header-text-color);
  --not-passed-step-color: var(--header-text-color);
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledLogo = styled(SVG)`
  height: 31px;
`;

const StyledLogoutSVG = styled(SVG)`
  margin-right: 8px;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  font-family: var(--text-font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.79px;
`;

const StyledGuestLoginProgress = styled(GuestLoginProgress)`
  width: 400px;
  margin-top: 10px;
`;

const SubLogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

const PoweredBy = styled(SVG)`
  height: 17px;
  margin-right: 10px;
  fill: currentcolor;
`;

const NsknoxRefLogo = styled(SVG)`
  height: 21px;
  margin-bottom: 10px;
  width: 75px;
`;

const StyledHeaderLogo = styled(ImageFromResource)`
  object-fit: contain;
  object-position: left;
  height: 60px;
  width: 200px;
`;
