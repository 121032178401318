import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';

enum UnitedStatesLegalIdTypesForCompany {
  EIN = 'EIN',
  SSN = 'SSN',
  Other = 'Other',
}

export const US: SupplierRegistrationCountryFields = {
  leis: { types: UnitedStatesLegalIdTypesForCompany },
  legalIdTypesForCompany: [
    {
      key: UnitedStatesLegalIdTypesForCompany.EIN,
      rules: [
        {
          required: true,
          pattern: /^(?:\d-?){8}\d$/,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidEIN',
        },
      ],
    },
  ],
};
