import HealthCheckStore from '@app/stores/HealthCheckStore';
import UserStore from '@app/stores/UserStore';
import { distinctValues } from '@app/utils/arrayUtils';
import config, { serverUrl } from '@app/config';
import { checkHealthCheckResultValid, serverHealthCheck } from '@app/services/healthCheckServices';
import { isTruthy } from '@app/utils/utils';

export default class ARHealthCheckStore extends HealthCheckStore {
  constructor(userStore: UserStore) {
    super(userStore);
  }

  async runHealthCheck(): Promise<void> {
    if (!config.runHealthCheck || this.userStore.user) {
      return;
    }

    const knoxersAddresses = distinctValues(
      Object.values(config.guestLoginAddresses ?? {}).flatMap((knoxer) => [
        knoxer.addressEmail,
        config.shouldLoginWithPhone && knoxer.addressPhoneSms,
        config.shouldLoginWithPhone && knoxer.addressPhoneCall,
        config.shouldDisplayGuestSSO && knoxer.ssoParams?.tokenerUrl,
      ]),
    ).filter(isTruthy);

    const healthCheckResults = await Promise.all([
      serverHealthCheck(serverUrl),
      ...knoxersAddresses.map((knoxerAddress) => serverHealthCheck(knoxerAddress)),
    ]);

    if (!healthCheckResults.every(checkHealthCheckResultValid)) {
      throw new Error('health check result is invalid');
    }
  }
}
