import React, { FC, useRef } from 'react';
import { HeightCoordinator } from '@app/hooks/useHeightCoordinator';
import useMountEffect from '@app/hooks/useMountEffect';

interface Props {
  heightCoordinator: HeightCoordinator;
}

const HeightCoordinatedDiv: FC<Props> = ({ heightCoordinator, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useMountEffect(() => {
    heightCoordinator.addRef(ref);

    return () => {
      heightCoordinator.removeRef(ref);
    };
  });

  return <div ref={ref}>{children}</div>;
};

export default HeightCoordinatedDiv;
