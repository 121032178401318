import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import Button from '@app/components/Button';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/close-thick.svg';
import { KeyName } from '@app/domain/uiConsts';
import CreateValidationRecordForm, {
  CreateValidationRecordFormFields,
} from '@mortee/routes/validationSystem/createValidationRecord/CreateValidationRecordForm';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import validationRecordIcon from '@mortee/images/validationSystem/validationRecordIcon.svg';
import IconAndText from '@mortee/components/IconAndText';
import { Regular22TransparentBlack900 } from '@app/components/Text';

interface Props {
  initialSupplierRegistrationProcess?: SupplierRegistrationProcess;
  onSaved(updatedProcess?: SupplierRegistrationProcess): void;
  onCanceled(): void;
}

const INITIAL_FORM_COUNTER = 1;

const CreateValidationRecordModal: FC<Props> = observer((props) => {
  const { validationSystemStore } = useAppStores<MorteeMode>();
  const { initialSupplierRegistrationProcess, onSaved, onCanceled } = props;
  const [formsCounter, setFormsCounter] = useState(INITIAL_FORM_COUNTER);
  const [initialFieldsValue, setInitialFieldsValue] = useState<Partial<CreateValidationRecordFormFields> | undefined>();

  function onKeyUp(event: React.KeyboardEvent): void {
    if (event.key === KeyName.Escape) {
      handleExitWithoutSave();
    }
  }

  function handleExitWithoutSave(): void {
    if (formsCounter === INITIAL_FORM_COUNTER) {
      onCanceled();
    } else {
      onSaved();
    }
  }

  function handleOnSaved(
    createAnotherAfterSave: boolean,
    updatedProcess?: SupplierRegistrationProcess,
    initialFieldsValue?: Partial<CreateValidationRecordFormFields>,
  ): void {
    if (createAnotherAfterSave) {
      setInitialFieldsValue({ organizationId: initialFieldsValue?.organizationId });
      setFormsCounter((currentCounter) => currentCounter + 1);
    } else {
      onSaved(updatedProcess);
    }
  }

  return (
    <MainContainer dataTestId='create-validation-record-modal' onKeyUp={onKeyUp}>
      <TitleLine>
        <TitleIconAndText image={validationRecordIcon} iconAccessibilityLabel='validation record icon'>
          Create validation record
        </TitleIconAndText>
        <CloseButton
          id='btn-create-validation-process-modal-close'
          appearance='text'
          colorScheme='primary'
          onClick={handleExitWithoutSave}
        >
          <SVG accessibilityLabel='close' image={ClearIcon} height={24} />
        </CloseButton>
      </TitleLine>
      <ExpandedCreateValidationRecordForm
        key={formsCounter}
        allOrganizationsLoadable={validationSystemStore.organizations}
        initialSupplierRegistrationProcess={initialSupplierRegistrationProcess}
        onSaved={handleOnSaved}
        initialFieldsValue={initialFieldsValue}
      />
    </MainContainer>
  );
});

export default CreateValidationRecordModal;

const MainContainer = styled(Card)`
  width: min(800px, 90vw);
  height: 95vh;
  padding: 31px 8px 24px;
  position: relative;
  box-shadow: var(--box-shadow-3);

  display: flex;
  flex-direction: column;

  --side-padding: 32px;
`;

const TitleLine = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  padding: 0 var(--side-padding) 13px;
  gap: 10px;
`;

const TitleIconAndText = styled(IconAndText)`
  ${Regular22TransparentBlack900.css};
  flex: 1;
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  align-self: flex-start;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const ExpandedCreateValidationRecordForm = styled(CreateValidationRecordForm)`
  flex: 1;
`;
