import { BodyRegularStartTransparentBlack900Important } from '../Text';
import { css } from '@emotion/css';
import { ColorSchemeObject } from '@app/domain/theme';

export default function internalInputStyle(
  colorScheme: ColorSchemeObject,
  textColorScheme: ColorSchemeObject,
  cursorReadOnly: boolean,
  overrideCss?: string,
): string {
  return css`
    height: auto !important;
    width: 100%;
    padding: 1px 0 !important;
    border: none !important;
    ${BodyRegularStartTransparentBlack900Important.css};
    background-color: transparent !important;
    color: ${textColorScheme.main} !important;
    outline: none !important;
    box-shadow: 0 4px 20px 0 transparent !important;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out !important;
    -webkit-appearance: none !important;
    caret-color: ${cursorReadOnly ? 'transparent' : colorScheme.main} !important;
    cursor: ${cursorReadOnly ? 'default' : 'text'} !important;

    &::placeholder {
      color: rgba(61, 68, 90, 0.7) !important;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent !important;
    }

    &:focus:-moz-placeholder {
      color: transparent !important;
    }

    /* FF 4-18 */

    &:focus::-moz-placeholder {
      color: transparent !important;
    }

    /* FF 19+ */

    &:focus:-ms-input-placeholder {
      color: transparent !important;
    }

    /* IE 10+ */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type='number'] {
      -moz-appearance: textfield !important; /* Firefox */
    }

    ${overrideCss}
  `;
}
