import React, { FC } from 'react';
import styled from '@emotion/styled';
import { getCountryByCountryCode } from '@app/domain/countries';
import NationalityFlag, { DataProps as FlagDataProps } from './NationalityFlag';
import ReactPlaceholder from 'react-placeholder';
import withPlaceholder from './withPlaceholder';
import { useTranslation } from 'react-i18next';

interface DataProps {
  countryCode: string | null | undefined;
  unknownFlagStyle?: FlagDataProps['unknownFlagStyle'];
}

interface DisplayProps {
  className?: string;
}

const Nationality: FC<DataProps & DisplayProps> = (props) => {
  const { t } = useTranslation();

  const { countryCode, unknownFlagStyle, className } = props;

  const countryName = countryCode ? getCountryByCountryCode(countryCode)?.name ?? countryCode : 'Unknown Country';

  return (
    <NationalityContainer className={className}>
      <StyledNationalityFlag countryCode={countryCode} unknownFlagStyle={unknownFlagStyle} />
      {t(`general.countries.${countryName}`, countryName)}
    </NationalityContainer>
  );
};

const Placeholder: FC<DisplayProps> = ({ className }) => {
  return (
    <NationalityContainer className={className}>
      <StyledNationalityFlag loading />
      <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 100, marginTop: 0 }} />
    </NationalityContainer>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(Nationality, Placeholder);

const NationalityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledNationalityFlag = styled(NationalityFlag)`
  margin-inline-end: 4px;
`;
