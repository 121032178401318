import { ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form';
import { ReactNode } from 'react';
import { validateNationalPhoneAndDialCode } from '@app/utils/phoneUtils';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { isDefined, isTruthy } from '@app/utils/utils';
import { arrayWithoutValue, valueOrValuesAsArray } from './arrayUtils';
import { getCountryByCountryCode, getDialCodeByCountryCode } from '@app/domain/countries';
import { LegalIdentifierRequest } from '@mortee/domain/vaildatedPayeeManagement';
import { validateTaxIdAccordingToCountry } from '@app/domain/taxIdValidation';
import { trimDunsValue } from '@app/utils/legalIdentifierUtils';
import { VALIDATED_PAYEE_FIELD_MAX_LENGTH } from '@mortee/domain/validatedPayeeManagementFields';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';

type FieldOfForm<TFormFields> = keyof TFormFields & string;

function isValueFull(value: unknown): boolean {
  if (!isDefined(value)) {
    return false;
  }

  if (typeof value === 'string') {
    return !!value.trim();
  }

  return true;
}

export type AntdFormFieldValidator = NonNullable<ValidationRule['validator']>;
export type ValueSynchronousValidator = (value: any) => string | undefined;

export function arrayValidator(itemValidator: AntdFormFieldValidator, messageInCaseOfAnyError?: string): AntdFormFieldValidator {
  return async (rule, value, callback): Promise<void> => {
    if (!value) {
      return callback();
    }

    if (!Array.isArray(value)) {
      return callback();
    }

    const allErrorMessages: (string | undefined)[] = await Promise.all(
      value.map(
        (itemOfArrayValue): Promise<string | undefined> => {
          return new Promise<string | undefined>((resolve) =>
            itemValidator(rule, itemOfArrayValue, (error: string | undefined) => {
              resolve(error);
            }),
          );
        },
      ),
    );

    const firstErrorMessage = allErrorMessages.filter(isTruthy)[0];

    if (firstErrorMessage) {
      return callback(messageInCaseOfAnyError || firstErrorMessage);
    }

    return callback();
  };
}

export function patternValidatorSynchronous(
  pattern: string | RegExp,
  errorMessageCreator: (value: string) => string,
): ValueSynchronousValidator {
  const patternAsRegExp = typeof pattern === 'string' ? new RegExp(pattern) : pattern;

  return (value): string | undefined => {
    if (!value) {
      return;
    }

    if (typeof value !== 'string') {
      return;
    }

    if (!patternAsRegExp.test(value)) {
      return errorMessageCreator(value);
    }

    return;
  };
}

export function patternValidator(
  pattern: string | RegExp,
  errorMessageCreator: (value: string) => string,
): AntdFormFieldValidator {
  const validatorOfPattern = patternValidatorSynchronous(pattern, errorMessageCreator);

  return (rule, value, callback): VoidFunction => {
    return callback(validatorOfPattern(value));
  };
}

export function atLeastOneOfFields<TFormFields>(
  form: WrappedFormUtils,
  otherFields: FieldOfForm<TFormFields>[],
  errorMessage: ReactNode,
): AntdFormFieldValidator {
  return (rule, value, callback): VoidFunction => {
    if (value) {
      validateNonValidatingFields(form, otherFields);
      return callback();
    }

    const atLeastOneHaveValue = Object.values(form.getFieldsValue(otherFields)).some(isValueFull);

    if (atLeastOneHaveValue) {
      validateNonValidatingFields(form, otherFields);
      return callback();
    }

    validateNonValidatingFields(form, otherFields);
    return callback(errorMessage);
  };
}

function getIsGroupFull<TFormFields>(form: WrappedFormUtils<any>, myFieldGroup: FieldOfForm<TFormFields>[]): boolean {
  return Object.values(form.getFieldsValue(myFieldGroup)).every(isValueFull);
}

export function atLeastOneOfFieldGroups<TFormFields>(
  form: WrappedFormUtils,
  fieldName: FieldOfForm<TFormFields>,
  fieldsGroups: FieldOfForm<TFormFields>[][],
  errorMessage: ReactNode,
): AntdFormFieldValidator {
  const myFieldGroup = fieldsGroups.find((fieldsGroup) => fieldsGroup.includes(fieldName));

  if (!myFieldGroup) {
    throw new Error(`fieldsGroups should contain the value ${fieldName}`);
  }

  const allOtherFields = arrayWithoutValue(fieldsGroups.flat(), fieldName);
  const otherGroups = arrayWithoutValue(fieldsGroups, myFieldGroup);

  return (rule, value, callback): VoidFunction => {
    const allMyGroupIsFull = getIsGroupFull(form, myFieldGroup);

    if (allMyGroupIsFull) {
      validateNonValidatingFields(form, allOtherFields);
      return callback();
    }

    const isAnyOtherGroupFull = otherGroups.some((group) => getIsGroupFull(form, group));

    if (isAnyOtherGroupFull) {
      validateNonValidatingFields(form, allOtherFields);
      return callback();
    }

    validateNonValidatingFields(form, allOtherFields);
    return callback(errorMessage);
  };
}

export function allOrNoneFieldsValidator<TFormFields>(
  form: WrappedFormUtils,
  otherFields: FieldOfForm<TFormFields>[],
  errorMessage: ReactNode,
): AntdFormFieldValidator {
  return (rule, value, callback): VoidFunction => {
    const allOtherFieldsHaveValues = Object.values(form.getFieldsValue(otherFields)).every(isValueFull);

    if (!value && allOtherFieldsHaveValues) {
      validateNonValidatingFields(form, otherFields);
      return callback(errorMessage);
    }

    validateNonValidatingFields(form, otherFields);
    return callback();
  };
}

export function requireOnOtherFieldFull<TFormFields>(
  form: WrappedFormUtils,
  otherField: FieldOfForm<TFormFields>,
  errorMessage: ReactNode,
): AntdFormFieldValidator {
  return (rule, value, callback): VoidFunction => {
    if (value) {
      return callback();
    }

    const isFieldFull = Object.values(form.getFieldsValue([otherField])).some(isValueFull);

    if (!isFieldFull) {
      return callback();
    }

    return callback(errorMessage);
  };
}

export function requireOnOtherFieldValue<TFormFields>(
  form: WrappedFormUtils,
  otherField: FieldOfForm<TFormFields>,
  errorMessage: ReactNode,
  fieldValue: any,
): AntdFormFieldValidator {
  return (rule, value, callback): VoidFunction => {
    if (value) {
      return callback();
    }

    const isResultTrue = form.getFieldValue(otherField) === fieldValue;

    if (!isResultTrue) {
      return callback();
    }

    return callback(errorMessage);
  };
}

export function onChangeValidateOtherFields<TFormFields>(
  form: WrappedFormUtils<TFormFields>,
  otherFields: FieldOfForm<TFormFields> | FieldOfForm<TFormFields>[],
): AntdFormFieldValidator {
  return (rule, value, callback): VoidFunction => {
    validateNonValidatingFields(form, valueOrValuesAsArray(otherFields));
    return callback();
  };
}

function validateNonValidatingFields(form: WrappedFormUtils, otherFields: string[]): void {
  const fieldsThatAreNotAlreadyValidating = otherFields?.filter((field) => !form.isFieldValidating(field));

  if (fieldsThatAreNotAlreadyValidating?.length) {
    form.validateFields(fieldsThatAreNotAlreadyValidating);
  }
}

export function validatePhoneNumber<TFormFields>(
  form: WrappedFormUtils,
  prefixField: FieldOfForm<TFormFields>,
  errorMessage: string | ReactNode,
  prefixFieldIsCountryCode?: boolean,
): AntdFormFieldValidator {
  return (rule, value, callback): void => {
    const nationalPhoneNumber = value?.trim();

    // Allow empty value and let other validators take care of empty values
    if (!nationalPhoneNumber) {
      callback();
      return;
    }

    const countryCodeOrDialCode: string | undefined = form.getFieldValue(prefixField);

    const countryDialCode = prefixFieldIsCountryCode ? getDialCodeByCountryCode(countryCodeOrDialCode) : countryCodeOrDialCode;
    if (!countryDialCode) {
      callback(errorMessage);
      return;
    }

    const validNumber = validateNationalPhoneAndDialCode(countryDialCode.toString(), nationalPhoneNumber);

    if (!validNumber) {
      callback(errorMessage);
      return;
    }

    return callback();
  };
}

export function validateInternationalTextPhoneNumber(errorMessage: string | ReactNode, prefix?: string): AntdFormFieldValidator {
  return (rule, value, callback): boolean => {
    let phoneNumber = value?.trim();
    phoneNumber = prefix && phoneNumber ? prefix + phoneNumber : phoneNumber;

    // Allow empty value and let other validators take care of empty values
    if (!phoneNumber) {
      callback();
      return true;
    }

    const validNumber = isPossiblePhoneNumber(phoneNumber);

    if (!validNumber) {
      callback(errorMessage);
      return false;
    }

    return callback();
  };
}

const MAX_EMAIL_LOCAL_PART = 64;

export function emailAdditionalValidations(errorMessage: string | ReactNode): AntdFormFieldValidator {
  return (rule, value, callback): boolean => {
    const trimmedEmail = value?.trim();

    // Allow empty value and let other validators take care of empty values
    if (!trimmedEmail) {
      callback();
      return true;
    }

    // Allow non string values and let other validators take care of those
    if (typeof trimmedEmail !== 'string') {
      callback();
      return true;
    }

    const [localPart] = trimmedEmail.split('@');

    if ((localPart?.length ?? 0) > MAX_EMAIL_LOCAL_PART) {
      callback(errorMessage);
      return false;
    }

    callback();
    return true;
  };
}

export function notFutureTimestampValidator(): AntdFormFieldValidator {
  return (rule, dateEpoch: number | undefined, callback): void => {
    if (!dateEpoch) {
      // the other validator check that a value exist
      return callback();
    }

    if (dateEpoch > Date.now()) {
      return callback('Can’t use a future date');
    }

    return callback();
  };
}

export function regularLegalIdValidator(
  rule: any,
  value: Partial<LegalIdentifierRequest> | undefined,
  callback: any,
): VoidFunction {
  if (value === null || value === undefined) {
    return callback();
  }

  if (!value.countryCode) {
    return callback('Pick a country');
  }

  if (!getCountryByCountryCode(value.countryCode)) {
    return callback('Pick a valid country');
  }

  if (!value.typeId) {
    return callback('Pick a type');
  }

  if (!value.value) {
    return callback('Enter a value');
  }

  return callback();
}

export function taxIdValidator(rule: any, leiRequest: Partial<LegalIdentifierRequest> | undefined, callback: any): VoidFunction {
  if (leiRequest === null || leiRequest === undefined) {
    return callback();
  }

  const countryCode = leiRequest.countryCode;
  const value = leiRequest.value;

  if (!countryCode) {
    return callback('Pick a country');
  }

  if (!getCountryByCountryCode(countryCode)) {
    return callback('Pick a valid country');
  }

  if (!value) {
    return callback('Enter a value');
  }

  const countryTaxIdValidationResult = validateTaxIdAccordingToCountry(countryCode, value);

  return callback(countryTaxIdValidationResult);
}

export function dunsValidator(rule: any, value: Partial<LegalIdentifierRequest> | undefined, callback: any): VoidFunction {
  if (!value?.value) {
    return callback();
  }

  const trimmedValue = trimDunsValue(value.value);

  if (!trimmedValue) {
    return callback();
  }

  if ((trimmedValue?.length ?? 0) > VALIDATED_PAYEE_FIELD_MAX_LENGTH.duns) {
    return callback(`max ${VALIDATED_PAYEE_FIELD_MAX_LENGTH.duns} characters`);
  }

  if (!VALIDATION_PATTERNS.numeric.test(trimmedValue)) {
    return callback('Only numbers and dashes allowed');
  }

  return callback();
}
