import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import MorteeMode from '@mortee/morteeMode';
import PaginatedInfiniteScroll from '@app/components/PaginatedInfiniteScroll';
import Loadable, { LoadableCreator, LoadingState } from '@app/utils/Loadable';
import SearchIcon from '@app/images/ic-search-primary.svg';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import { observer, useLocalObservable } from 'mobx-react';
import {
  DisplayedPayeesTotal,
  isLivePrivatePayeeCompanyCodesFilterEquals,
  isLivePrivatePayeeFiltersEquals,
  LivePrivatePayeeFilters,
  LivePrivatePayeeFiltersOptions,
  LivePrivatePayeeValidationLevel,
  PrivatePayeeSearchableData,
  PrivatePayeeWithDeterministicValidations,
  SyncPlatform,
} from '@mortee/domain/masterDataGuard';
import PaginationManager from '@app/utils/pagination/PaginationManager';
import PaginatedPrivateAreaEndMessage from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/PaginatedPrivateAreaEndMessage';
import PrivatePayeeLiveListItemHeader from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/PrivatePayeeLiveListItemHeader';
import { Collapse } from 'antd';
import { createNTimes } from '@app/utils/arrayUtils';
import LivePrivatePayeesFiltersContext, {
  defaultFilters,
  LivePrivatePayeesFiltersContextProps,
} from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import PrivatePayeeLiveListItemContent from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/content/PrivatePayeeLiveListItemContent';
import styled from '@emotion/styled';
import CornersInputBox from '@app/components/inputs/inputBox/CornersInputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import SVG from '@app/components/SVG';
import { BufferLine } from '@app/components/DisplayFields';
import DisplayedPayeesTotalNumbers from './DisplayedPayeesTotalNumbers';
import Log from '@app/libs/logger';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';
import ExportButton from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/buttons/ExportButton';
import PrivateAreaFiltersButton from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/buttons/PrivateAreaFiltersButton';
import AsyncAccumulatorPaginationManager from '@app/utils/pagination/AsyncAccumulatorPaginationManager';
import InternalMemoryNextPageLoader from '@app/utils/pagination/InternalMemoryNextPageLoader';
import { DEFAULT_PAGE_SIZE } from '@mortee/stores/appStores/MasterDataGuardStore';
import ExistingIdsListNextPageLoader from '@app/utils/pagination/ExistingIdsListNextPageLoader';
import { identityFunc } from '@app/utils/utils';
import { shoot } from '@app/utils/messageLauncher';
import useNonInitialEffect from '@app/hooks/useNonInitialEffect';

const { Panel } = Collapse;

const LOADER_CARDS_AMOUNT = 11;

const PaginatedPrivateArea: FunctionComponent = observer(() => {
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { masterDataGuardStore } = useAppStores<MorteeMode>();

  const localStore = useLocalObservable(() => ({
    _filters: defaultFilters as LivePrivatePayeeFilters,
    paginatedPrivatePayees: LoadableCreator.inProgress() as Loadable<PaginationManager<PrivatePayeeWithDeterministicValidations>>,

    get filters(): LivePrivatePayeeFilters {
      return localStore._filters;
    },

    get filtersApplied(): BooleanValues<LivePrivatePayeeFilters> {
      return {
        validationLevel: localStore._filters.validationLevel !== defaultFilters.validationLevel,
        searchQuery: localStore._filters.searchQuery !== defaultFilters.searchQuery,
        companyCodes: !isLivePrivatePayeeCompanyCodesFilterEquals(localStore._filters.companyCodes, defaultFilters.companyCodes),
      };
    },

    get filterOptions(): LivePrivatePayeeFiltersOptions {
      return {
        validationLevel: permissionsStore.isMasterDataGuardEnable
          ? [LivePrivatePayeeValidationLevel.all, LivePrivatePayeeValidationLevel.notValidated]
          : [LivePrivatePayeeValidationLevel.all],
        companyCodes: masterDataGuardStore.privateAreaCompanyCodes.map((companyCodes) => ({
          values: new Set<string>(companyCodes.companyCodes),
          allowBlankValue: !companyCodes.doesAllHaveCompanies,
        })),
      };
    },

    setFilters: (newFilters: LivePrivatePayeeFilters): void => {
      if (!isLivePrivatePayeeFiltersEquals(localStore._filters, newFilters)) {
        localStore._filters = newFilters;
        localStore.recreatePaginatedPrivatePayees();
      }
    },

    recreatePaginatedPrivatePayees(): void {
      if (localStore._filters.validationLevel === LivePrivatePayeeValidationLevel.notValidated) {
        localStore.paginatedPrivatePayees = masterDataGuardStore.allNotValidatedPayees.map((allNotValidatedPayees) => {
          return new AsyncAccumulatorPaginationManager(
            new InternalMemoryNextPageLoader<PrivatePayeeWithDeterministicValidations>(
              [...allNotValidatedPayees],
              DEFAULT_PAGE_SIZE,
              masterDataGuardStore.createFullPayeeFilterMethod(localStore._filters),
            ),
          );
        });

        return;
      }

      localStore.paginatedPrivatePayees = masterDataGuardStore.privateAreaPayees.map((payeesToSearch) => {
        return new AsyncAccumulatorPaginationManager(
          new ExistingIdsListNextPageLoader<PrivatePayeeWithDeterministicValidations, PrivatePayeeSearchableData>(
            [...payeesToSearch],
            DEFAULT_PAGE_SIZE,
            masterDataGuardStore.createSearchablePayeeFilterMethod(localStore._filters),
            (searchablePayees) =>
              masterDataGuardStore.loadPrivatePayeesDeterministicValidation(searchablePayees.map((payee) => payee.uniformId)),
          ),
        );
      });
    },

    get hasItems(): boolean {
      return !!localStore.paginatedPrivatePayees.result?.hasItems;
    },

    get displayedPayeesTotal(): Loadable<DisplayedPayeesTotal> {
      const currentDisplayResults =
        localStore.filters.validationLevel === LivePrivatePayeeValidationLevel.notValidated
          ? masterDataGuardStore.notValidatedPayeesAmounts
          : masterDataGuardStore.allPrivateAreaAmounts;

      const paginatedPrivatePayees: Loadable<PaginationManager<PrivatePayeeWithDeterministicValidations>> =
        localStore.paginatedPrivatePayees;
      return LoadableCreator.combine(paginatedPrivatePayees, currentDisplayResults).map(
        ([loadedPaginatedPrivatePayees, loadedTotals]): DisplayedPayeesTotal => {
          return {
            payeesAmount: loadedPaginatedPrivatePayees.totalNumberOfItems,
            payeeAccountsAmounts: loadedTotals.payeeAccountsAmounts,
          };
        },
      );
    },

    // It is calculated so the context will get the same object every time and will not
    // cause irrelevant refreshes
    get filtersContextValues(): LivePrivatePayeesFiltersContextProps {
      return {
        filters: localStore.filters,
        filtersApplied: localStore.filtersApplied,
        isAnyFiltersApplied: Object.values(localStore.filtersApplied).some(identityFunc),
        filterOptions: localStore.filterOptions,
        setFilters: localStore.setFilters,
      };
    },
  }));

  useEffect(() => {
    if (localStore.filters.validationLevel === LivePrivatePayeeValidationLevel.all) {
      localStore.recreatePaginatedPrivatePayees();
    }
  }, [localStore, masterDataGuardStore.privateAreaPayees.loadState]);

  useEffect(() => {
    if (localStore.filters.validationLevel === LivePrivatePayeeValidationLevel.notValidated) {
      localStore.recreatePaginatedPrivatePayees();
    }
  }, [localStore, masterDataGuardStore.allNotValidatedPayees.loadState]);

  useNonInitialEffect(() => {
    if (masterDataGuardStore.allNotValidatedPayeesRequestLoadingState === LoadingState.Resolved) {
      onNotValidatedReportReady();
    }
  }, [masterDataGuardStore.allNotValidatedPayeesRequestLoadingState]);

  const onNotValidatedReportReady = (): void => {
    shoot({ type: 'success', duration: 8, closeable: true }, 'Filter “Not validated only” is ready');
  };

  const onPayeeUpdated = async (itemNeedsUpdate: PrivatePayeeWithDeterministicValidations): Promise<void> => {
    try {
      const fetchedItems = await masterDataGuardStore.loadPrivatePayeesDeterministicValidation(
        [itemNeedsUpdate.privatePayee.uniformId],
        true,
      );

      const fetchedItem = fetchedItems[0];

      if (!fetchedItem) {
        throw new Error('Error updating list after change');
      }

      if (localStore.paginatedPrivatePayees.isResolved()) {
        localStore.paginatedPrivatePayees.result.replaceItem(itemNeedsUpdate, fetchedItem);
      }

      masterDataGuardStore.updateNotValidatedPayeesList(fetchedItems);
    } catch (e: unknown) {
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent='Something went wrong'
          bodyContent='Error while loading the data. Please refresh the page'
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      Log.exception(e);
      throw new Error('Error updating list after change');
    }
  };

  const searchPlaceholder = (): string => {
    return masterDataGuardStore.lastSyncInfo.resolve(
      (loadedLastSyncInfo) => {
        if (
          loadedLastSyncInfo.lastSyncProcess?.requestingPlatform === SyncPlatform.Oracle ||
          loadedLastSyncInfo.organizationalErpPlatform === SyncPlatform.Oracle
        ) {
          return 'Search for supplier name or number';
        }

        return 'Search vendor name or internal ID';
      },
      () => 'Search...',
    );
  };

  return (
    <LivePrivatePayeesFiltersContext.Provider value={localStore.filtersContextValues}>
      <div>
        <FiltersLine>
          <StyledInputBox colorScheme='primary'>
            <StyledInput
              name='mvf-grd-live-srch'
              dataTestId='mvf-grd-live-srch'
              type='text'
              colorScheme='primary'
              placeholderStyle='onlyWhenEmpty'
              heightType='ultra-thin'
              placeholder={searchPlaceholder()}
              value={localStore.filters.searchQuery || undefined}
              onChange={(newSearchQuery): void => {
                localStore.setFilters({
                  ...localStore.filters,
                  searchQuery: newSearchQuery ?? '',
                });
              }}
              clearable
            />
            {!localStore.filters.searchQuery && <StyledSearchIcon accessibilityLabel='search' image={SearchIcon} />}
          </StyledInputBox>
          <ExportButton disabled={!localStore.hasItems} />
          <PrivateAreaFiltersButton
            buttonDisabled={!masterDataGuardStore.privateAreaPayees.isResolved()}
            allNotValidatedPayeesWithPercentage={masterDataGuardStore.allNotValidatedPayeesWithPercentage}
          />
        </FiltersLine>
        <StyledBufferLine />
        <StyledDisplayedPayeesTotalNumbers info={localStore.displayedPayeesTotal} />
        <StyledBufferLine />
        <PaginatedInfiniteScroll
          id='private-payees-infinite-scroll'
          dataTestId='private-payees-infinite-scroll'
          paginationManager={localStore.paginatedPrivatePayees}
          endMessage={<PaginatedPrivateAreaEndMessage hasItems={localStore.hasItems} />}
          loader={<PrivatePayeeLiveListItemHeader loading />}
          initialLoader={
            <>
              {createNTimes(LOADER_CARDS_AMOUNT, (index) => (
                <PrivatePayeeLiveListItemHeader key={index} loading />
              ))}
            </>
          }
          scrollableTarget='appContentContainer'
        >
          {(items): ReactElement => (
            <Collapse bordered={false} accordion>
              {items.map((item, index) => (
                <StyledPanel
                  key={item.privatePayee.uniformId}
                  id={`mvf-grd-row-${index}`}
                  showArrow={false}
                  header={<PrivatePayeeLiveListItemHeader payeeWithValidation={item} />}
                >
                  <PrivatePayeeLiveListItemContent
                    payeeWithValidation={item}
                    onPayeeUpdated={(): Promise<void> => onPayeeUpdated(item)}
                  />
                </StyledPanel>
              ))}
            </Collapse>
          )}
        </PaginatedInfiniteScroll>
      </div>
    </LivePrivatePayeesFiltersContext.Provider>
  );
});

export default PaginatedPrivateArea;

const FiltersLine = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: row;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const StyledInputBox = styled(CornersInputBox)`
  flex: 1;

  display: flex;
  flex-direction: row;
`;

const StyledInput = styled(NakedFormInput)`
  flex: 1;
`;

const StyledSearchIcon = styled(SVG)`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 12px;
`;

const StyledBufferLine = styled(BufferLine)`
  margin: 0;
`;

const StyledDisplayedPayeesTotalNumbers = styled(DisplayedPayeesTotalNumbers)`
  margin: 4px 24px 1px;
`;

const StyledPanel = styled(Panel)`
  transition: background-color 0.2s ease;

  &:nth-child(even) {
    background-color: var(--primary-200-50-a-without-transperanc);
  }

  &:nth-child(odd) {
    background-color: white;
  }

  & > .ant-collapse-header {
    padding: 0 !important;

    &:hover {
      background-color: var(--primary-200-100-a);
    }

    &:active {
      background-color: rgba(20, 35, 97, 0.15);
    }
  }

  border: none !important;
`;
