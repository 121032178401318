import { action, computed, flow, makeObservable, observable, ObservableMap, ObservableSet, reaction } from 'mobx';
import { toWordsOrdinal } from 'number-to-words';
import * as loginService from '../services/login';
import Log from '@app/libs/logger';
import KnoxersManager from '@app/utils/knoxersManager';
import config from '@app/config';
import * as authenticationService from '../services/authenticationService';
import * as messageLauncher from '@app/utils/messageLauncher';
import {
  AuthCodeDeliveryMethod,
  AuthMethodData,
  AuthMethodType,
  authMethodTypeOrder,
  InitiatePhoneLoginResponse,
  KnoxerAuthConfigData,
  KnoxerAuthProperties,
  KnoxerSpecificPhoneAuthData,
  LoginErrorBody,
  LoginMode,
  MethodUserIdentification,
  PersistedKnoxerAuthData,
  PhoneLoginState,
  PhoneNumberUserIdentification,
  ssoAuthMethods,
  UnclaimedKnoxer,
} from '@app/login/domain/loginConsts';
import { ModalContainer, showInfoModalAsync, WideInfoModalBody, WideInfoModalTitle } from '@app/components/Modal';
import { isDefined, isTruthy } from '@app/utils/utils';
import { capitalizeFirstLetter } from '@app/utils/stringUtils';
import { css } from '@emotion/css';
import UserStore from '@app/stores/UserStore';
import { extractLogErrorIdFromError, HttpStatus, isRequestError, RequestError } from '@app/libs/request';
import browserHistory from '@app/utils/browserHistory';
import { distinctValues, distinctValuesByKey } from '@app/utils/arrayUtils';
import { createEnglishFormalErrorMessage } from '@app/utils/errorMessageUtils';
import { GOOGLE_SSO_AUTHORIZATION_PATH, GOOGLE_SSO_LOGIN_MODE_PATH } from '@app/login/domain/sso';
import sleep from '@app/utils/sleep';
import React from 'react';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';
import { CountryCode } from '@app/domain/countries';
import { compare } from '@app/utils/comparatorUtils';

const KNOXERS_AUTH_DATA_STORAGE_KEY = 'KNOXERS_AUTH_DATA';
const NSKNOX_MARKETING_WEBSITE = 'https://nsknox.net';

export default class AuthenticationStore {
  private userStore: UserStore;
  @observable loading: boolean = false;
  @observable logoutLoading: boolean = false;
  @observable loginMode: LoginMode = LoginMode.Local;
  @observable readonly _mandatoryKnoxerIds: readonly string[] = [];
  @observable _rememberMe: boolean = false;
  @observable _handleCreated = false;
  @observable _handleParticipatingKnoxerIds: string[] | null = null;
  @observable _methodUserIdentification: ObservableMap<AuthMethodType, MethodUserIdentification> = observable.map();
  @observable _ignoredKnoxers: ObservableSet<string> = observable.set();
  @observable _claimedKnoxers: ObservableSet<string> = observable.set();
  @observable _knoxerAuthData: ObservableMap<string, KnoxerSpecificPhoneAuthData> = observable.map();
  @observable _isGoogleSsoLoginPageMode: boolean = false;

  constructor(userStore: UserStore) {
    makeObservable(this);

    this.userStore = userStore;
    this.reset();
    this._mandatoryKnoxerIds = Object.freeze(
      config.knoxersAuthData.filter((knoxer) => !!knoxer.mandatory).map((knoxer) => knoxer.id),
    );

    reaction(
      () => [this.isLoggedIn, this.userStore.currentSecretId],
      () => {
        if (this.isLoggedIn && this.userStore.currentSecretId) {
          KnoxersManager.save(this.userStore.currentSecretId);
        }
      },
    );

    reaction(
      () => this.isLoggedIn,
      () => {
        Log.setUserInfo(this.userStore.user?.analyticsId);
      },
    );
  }

  async initAfterAllStoresInitialized(): Promise<void> {
    KnoxersManager.load();

    // Only if we have loaded knoxers try to get user info to check if the user is already connected
    if (KnoxersManager.isInitialized && !!KnoxersManager.activeKnoxersSecrets?.knoxers?.length) {
      await this.tryLogInWithSavedCredentials();
    }
  }

  @computed
  get isLoggedIn(): boolean {
    if (!this.userStore.user) {
      return false;
    }

    // For QR mode we only need a logged in user
    // For Local auth we need all of the knoxers to be claimed
    return this.loginMode !== LoginMode.Local || !this.unclaimedKnoxers.length;
  }

  @computed
  get knoxerIdsToAuth(): readonly string[] {
    let baseKnoxerIds = this._mandatoryKnoxerIds;

    if (!config.loginMandatoryOnly) {
      baseKnoxerIds = distinctValues(
        this.userStore.user?.organizations?.flatMap((org) => org.knoxerIds) ?? this._mandatoryKnoxerIds,
      );
    }

    const handleFilteredKnoxers = baseKnoxerIds
      .filter((knoxerId) => !this._ignoredKnoxers.has(knoxerId))
      .filter((knoxerId) => this._handleParticipatingKnoxerIds?.includes(knoxerId) ?? true)
      .map((knoxerId) => config.knoxersAuthData.find((knoxerAuthConfig) => knoxerAuthConfig.id === knoxerId))
      .filter(isDefined);

    // Sort the ids by the following precedence:
    // 1. claimed knoxers first
    // 2. by auth method order (user-password, sso, and then phone number). first get the highest precedence auth method
    //    type for each knoxer, and then compare among knoxers
    // 3. mandatory first
    // 4. knox id - for consistency
    return handleFilteredKnoxers
      .sort(
        compare
          .byField((knoxer: DeepReadonly<KnoxerAuthConfigData>) => this._claimedKnoxers.has(knoxer.id), compare.booleans())
          .then(compare.byField((knoxer) => this.getHighestPrecedenceAuthMethodIndex(knoxer.authProperties), compare.numbers()))
          .then(compare.byField((knoxer) => this._mandatoryKnoxerIds.includes(knoxer.id), compare.booleans()))
          .then(compare.byStringField((knoxer) => knoxer.id)),
      )
      .map((handleFilteredKnoxer) => handleFilteredKnoxer?.id);
  }

  private getHighestPrecedenceAuthMethodIndex = (authProperties: DeepReadonlyArray<KnoxerAuthProperties>): number => {
    const authMethodsOrderIndexes = authProperties.map((specificAuthMethodProperties) =>
      authMethodTypeOrder.indexOf(specificAuthMethodProperties.authMethod),
    );

    // The lower index the more important the auth method is
    return Math.min(...authMethodsOrderIndexes);
  };

  @computed
  get authMethodsData(): readonly AuthMethodData[] {
    return authMethodTypeOrder.map((authMethodType) => {
      const knoxerIdsOfMethod: string[] = this.knoxerIdsToAuth.filter((knoxerId) => {
        return config.knoxersAuthData
          .find((knoxerAuthConfig) => knoxerAuthConfig.id === knoxerId)
          ?.authProperties.some((authProperties) => authProperties.authMethod === authMethodType);
      });

      const userIdentification = this._methodUserIdentification.get(authMethodType);
      return {
        userIdentification,
        type: authMethodType,
        knoxersOfMethod: knoxerIdsOfMethod,
      } as AuthMethodData;
    });
  }

  @computed
  get unclaimedKnoxers(): readonly UnclaimedKnoxer[] {
    const unclaimedKnoxersWithDuplicates = this.authMethodsData
      .map((authMethodData) =>
        authMethodData.knoxersOfMethod.map((knoxerId, knoxerPositionInMethodGroup) => {
          const knoxer = config.knoxersAuthData.find((knoxer) => knoxer.id === knoxerId);
          const mandatory = knoxer?.mandatory ?? false;
          const positionInAllKnoxers = this.knoxerIdsToAuth.indexOf(knoxerId);

          return {
            positionInAllKnoxers,
            authMethodData,
            positionInMethodGroup: knoxerPositionInMethodGroup,
            knoxer: {
              mandatory,
              id: knoxerId,
              authData: this._knoxerAuthData.get(knoxerId),
            },
          } as UnclaimedKnoxer;
        }),
      )
      .flat()
      .filter((knoxerAuthData) => !this._claimedKnoxers.has(knoxerAuthData.knoxer.id));

    const unclaimedKnoxersWithoutDuplicates = distinctValuesByKey(
      unclaimedKnoxersWithDuplicates,
      (unclaimed) => unclaimed.knoxer.id,
    );
    return unclaimedKnoxersWithoutDuplicates;
  }

  @computed
  get nextUnclaimedKnoxer(): UnclaimedKnoxer | null {
    return this.unclaimedKnoxers[0] ?? null;
  }

  @computed
  get rememberMe(): boolean {
    return this._rememberMe;
  }

  get isGoogleSsoLoginPageMode(): boolean {
    return this._isGoogleSsoLoginPageMode;
  }

  createHandle = flow(function* (this: AuthenticationStore, authMethodType: AuthMethodType) {
    try {
      const shouldForceLongExpiration = ssoAuthMethods.includes(authMethodType);
      const handles = yield loginService.getHandles(this.rememberMe || shouldForceLongExpiration);

      KnoxersManager.createKnoxersSecretByHandles(handles);
      this._handleCreated = true;
      this._handleParticipatingKnoxerIds = handles.participatingKnoxers;
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    }
  });

  private verifyHandleCreation = async (authMethodType: AuthMethodType): Promise<void> => {
    if (this._handleCreated) {
      return;
    }

    await this.createHandle(authMethodType);
  };

  @action
  setMethodAuthData = (method: AuthMethodType, methodAuthData: MethodUserIdentification): void => {
    this._methodUserIdentification.set(method, methodAuthData);
  };

  @action
  setRememberMe = (rememberMe: boolean): void => {
    this._rememberMe = rememberMe;
  };

  @action
  setGoogleSsoLoginPageMode = (isGoogleSsoLoginPageMode: boolean): void => {
    this._isGoogleSsoLoginPageMode = isGoogleSsoLoginPageMode;
  };

  @action
  setLoading = (loading: boolean): void => {
    this.loading = loading;
  };

  @action
  reset = ({ loginMode, logoutLoading = false }: { loginMode?: LoginMode; logoutLoading?: boolean } = {}): void => {
    this.loginMode = AuthenticationStore.determineNewLoginMode(this.loginMode, loginMode);
    this.loading = false;
    this.logoutLoading = logoutLoading;
    this._rememberMe = false;
    this._handleCreated = false;
    this._handleParticipatingKnoxerIds = null;
    this._ignoredKnoxers.clear();
    this._claimedKnoxers.clear();
    this._methodUserIdentification.clear();
    this._knoxerAuthData.clear();
    this.userStore.clearUser();
  };

  claimWithPhoneAndCode = flow(function* generateTokenWithPhoneAndCode(
    this: AuthenticationStore,
    knoxerId: string,
    flowId: string,
    phoneNumber: string,
    code: string,
    deliveryMethod: AuthCodeDeliveryMethod,
  ) {
    try {
      this.loading = true;
      const openIdToken = yield authenticationService.generateTokenWithPhoneAndCode(
        knoxerId,
        flowId,
        phoneNumber,
        code,
        deliveryMethod,
      );

      if (!openIdToken) {
        throw new Error(`Cannot generate token using phone-code for knoxer id ${knoxerId}`);
      }

      yield this.claimWebSession(knoxerId, AuthMethodType.PhoneNumber, openIdToken.idToken);
      this.saveAuthenticatedKnoxer(knoxerId, { authType: AuthMethodType.PhoneNumber });
    } catch (e: unknown) {
      const requestError = e as RequestError;

      console.error(requestError);
      throw requestError.responseJSON as LoginErrorBody;
    } finally {
      this.loading = false;
    }
  });

  claimWithUsernamePassword = flow(function* generateTokenWithUsernamePassword(
    this: AuthenticationStore,
    knoxerId: string,
    username: string,
    password: string,
  ) {
    try {
      this.loading = true;
      const openIdToken = yield authenticationService.generateTokenWithUsernamePassword(knoxerId, username, password);

      if (!openIdToken) {
        throw new Error(`Cannot generate token using user-password for knoxer id ${knoxerId}`);
      }

      yield this.claimWebSession(knoxerId, AuthMethodType.EmailPassword, openIdToken.idToken);
      this.saveAuthenticatedKnoxer(knoxerId, { authType: AuthMethodType.EmailPassword });
    } catch (e: unknown) {
      const requestError = e as RequestError;

      console.error(requestError);
      throw requestError;
    } finally {
      this.loading = false;
    }
  });

  private getAuthBaseUrlEmail = (knoxerId: string, authMethodType: AuthMethodType): string => {
    const url = authenticationService.getKnoxerAuthConfig(knoxerId, authMethodType).authUrlEmail;
    if (!url) {
      throw new Error(`knox config is missing authUrlEmail property for ${authMethodType} auth method type`);
    }

    return url;
  };

  claimWebSession = flow(function* generateTokenWithUsernamePassword(
    this: AuthenticationStore,
    knoxerId: string,
    authMethodType: AuthMethodType,
    idToken?: string,
  ) {
    try {
      yield this.verifyHandleCreation(authMethodType);

      if (!this.knoxerIdsToAuth.includes(knoxerId)) {
        throw new Error(`knoxer with id ${knoxerId} was not found`);
      }

      if (this._claimedKnoxers.has(knoxerId)) {
        throw new Error(`knoxer with id ${knoxerId} already claimed`);
      }

      const lastPendingSecret = KnoxersManager.lastPendingSecret;
      const knoxerPendingSecret = lastPendingSecret.knoxers.find((knoxer) => knoxerId === knoxer.knoxerId);

      if (!knoxerPendingSecret) {
        // A freak case, where the knoxer is the first knoxer that authenticates but it does not exist in the handle.
        // We should just mark it as claimed and move on
        yield this.markKnoxerAsClaimed(knoxerId);
        return;
      }

      try {
        yield authenticationService.claimHandleForKnoxer(
          knoxerId,
          lastPendingSecret.handle,
          {
            secretId: lastPendingSecret.secretId,
            secret: knoxerPendingSecret.secret,
          },
          authMethodType,
          idToken,
        );
      } catch (e: unknown) {
        if (!ssoAuthMethods.includes(authMethodType) || !isRequestError(e) || e.code !== HttpStatus.unauthorized) {
          const errorLogId = extractLogErrorIdFromError(e);
          const errorMessage = createEnglishFormalErrorMessage('An error occurred during the sign in process.', errorLogId);
          messageLauncher.shootErrorOld(errorMessage);
        }

        this.reset({ loginMode: LoginMode.Local });
        throw e;
      }

      yield this.markKnoxerAsClaimed(knoxerId);
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  });

  claimWebSessionAfterMultipleSSOAuthentication = flow(function* claimWebSessionAfterMultipleSSOAuthentication(
    this: AuthenticationStore,
  ) {
    while (this.nextUnclaimedKnoxer) {
      const knoxerId = this.nextUnclaimedKnoxer.knoxer.id;

      const authenticatedKnoxer = this.getAuthenticatedKnoxers()[knoxerId];
      if (!authenticatedKnoxer) {
        throw new Error(`knoxer with id ${knoxerId} does not have auth data saved`);
      }

      yield this.claimWebSession(knoxerId, authenticatedKnoxer.authType);
    }
  });

  handlePhoneEntered = flow(function* (
    this: AuthenticationStore,
    dialCode: string,
    phoneNumber: string,
    countryCode: CountryCode,
  ) {
    this._methodUserIdentification.set(AuthMethodType.PhoneNumber, {
      dialCode,
      phoneNumber,
      countryCode,
    });

    if (!this.nextUnclaimedKnoxer) {
      return;
    }

    try {
      this.loading = true;
      yield this.sendCodeToUnclaimedPhoneKnoxer(this.nextUnclaimedKnoxer);
    } finally {
      this.loading = false;
    }
  });

  returnToPhoneSelection = action(() => {
    const phoneAuthData = this._methodUserIdentification.get(AuthMethodType.PhoneNumber) as PhoneNumberUserIdentification;

    if (phoneAuthData) {
      phoneAuthData.state = PhoneLoginState.EnterPhone;
    }
  });

  private sendCodeAndUpdateKnoxer = flow(function* sendCodeAndUpdateKnoxer(
    this: AuthenticationStore,
    knoxerId: string,
    phoneNumber: string,
    deliveryMethod: AuthCodeDeliveryMethod,
    quiet: boolean = true,
  ) {
    const phoneLoginFlowRequest: InitiatePhoneLoginResponse = yield authenticationService.phoneLoginSendCodeToPhone(
      knoxerId,
      phoneNumber,
      deliveryMethod,
      quiet,
    );

    this._knoxerAuthData.set(knoxerId, {
      deliveryMethod,
      flowId: phoneLoginFlowRequest.flowId,
      codeLength: phoneLoginFlowRequest.codeLength,
    });
  });

  resendCode = (knoxerId: string, phoneNumber: string, deliveryMethod: AuthCodeDeliveryMethod): void => {
    try {
      this.sendCodeAndUpdateKnoxer(knoxerId, phoneNumber, deliveryMethod);
    } catch (e: unknown) {
      Log.event('resendSms: error while resending the code.', { knoxerId, phoneNumber, deliveryMethod, e });
    }
  };

  private sendCodeToUnclaimedPhoneKnoxer = flow(function* generateTokenWithPhoneAndCode(
    this: AuthenticationStore,
    unclaimedKnoxer: UnclaimedKnoxer,
  ) {
    if (unclaimedKnoxer.authMethodData.type !== AuthMethodType.PhoneNumber) {
      return;
    }

    const { authMethodData, knoxer, positionInMethodGroup } = unclaimedKnoxer;

    const { userIdentification } = authMethodData;
    const { id, mandatory } = knoxer;

    if (!userIdentification) {
      return;
    }

    const phoneNumberUserIdentification: PhoneNumberUserIdentification = userIdentification as PhoneNumberUserIdentification;
    try {
      try {
        yield this.sendCodeAndUpdateKnoxer(
          id,
          joinNationalPhoneAndDialCode(phoneNumberUserIdentification.dialCode, phoneNumberUserIdentification.phoneNumber),
          AuthCodeDeliveryMethod.TextMessage,
          !mandatory,
        );
      } catch (e: unknown) {
        if (mandatory) {
          throw e;
        }

        // Ignore this knoxer and try to prepare the next knoxer in line
        this._ignoredKnoxers.add(id);
        return yield this.prepareNextKnoxerBeforeClaimingCurrent();
      }

      // This is not the first text message sent
      if (positionInMethodGroup !== 0) {
        yield showInfoModalAsync(`You should receive another text message in a few moments`, {
          title: `${capitalizeFirstLetter(toWordsOrdinal(positionInMethodGroup))} code approved`,
          okText: `ENTER THE ${toWordsOrdinal(positionInMethodGroup + 1)} CODE`.toUpperCase(),
          className: anotherCodeModalClassName,
        });
      }

      const phoneAuthData = this._methodUserIdentification.get(AuthMethodType.PhoneNumber) as PhoneNumberUserIdentification;
      phoneAuthData.state = PhoneLoginState.EnterCode;
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  });

  forgotPasswordEnterEmail = flow(function* generateTokenWithUsernamePassword(
    this: AuthenticationStore,
    knoxerId: string,
    email: string,
  ) {
    try {
      this.loading = true;
      return yield authenticationService.forgotPasswordEnterEmail(knoxerId, email);
    } catch (error: unknown) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  });

  forgotPasswordEnterCode = flow(function* generateTokenWithUsernamePassword(
    this: AuthenticationStore,
    knoxerId: string,
    flowId: string,
    email: string,
    code: string,
  ) {
    try {
      this.loading = true;
      return yield authenticationService.forgotPasswordEnterCode(knoxerId, flowId, email, code);
    } catch (e: unknown) {
      const requestError = e as RequestError;
      console.error(requestError);

      throw requestError.responseJSON;
    } finally {
      this.loading = false;
    }
  });

  forgotPasswordResetPassword = flow(function* generateTokenWithUsernamePassword(
    this: AuthenticationStore,
    knoxerId: string,
    flowId: string,
    email: string,
    newPassword: string,
  ) {
    try {
      this.loading = true;
      return yield authenticationService.forgotPasswordResetPassword(knoxerId, flowId, email, newPassword);
    } catch (e: unknown) {
      const requestError = e as RequestError;
      console.error(requestError);

      throw requestError.responseJSON;
    } finally {
      this.loading = false;
    }
  });

  private markKnoxerAsClaimed = flow(function* (this: AuthenticationStore, knoxerId: string) {
    // This is the last knoxer to claim
    if (this.unclaimedKnoxers.length === 1) {
      try {
        yield this.checkUserLoggedId();
      } catch (e) {
        const errorLogId = extractLogErrorIdFromError(e);

        const errorMessage = createEnglishFormalErrorMessage(
          'There was an error while loading user, restarting process.',
          errorLogId,
        );
        messageLauncher.shootErrorOld(errorMessage);
        this.reset();
        return;
      }
    }

    yield this.prepareNextKnoxerBeforeClaimingCurrent();

    // After everything is valid and the next knoxer is ready we can mark the current knoxer as claimed
    this._claimedKnoxers.add(knoxerId);
  });

  private prepareNextKnoxerBeforeClaimingCurrent = flow(function* (this: AuthenticationStore) {
    const [, secondKnoxerToAuth] = this.unclaimedKnoxers;

    if (!secondKnoxerToAuth) {
      return;
    }

    if (secondKnoxerToAuth.authMethodData.type === AuthMethodType.PhoneNumber) {
      yield this.sendCodeToUnclaimedPhoneKnoxer(secondKnoxerToAuth);
    }
  });

  private checkUserLoggedId = flow(function* (this: AuthenticationStore) {
    // For the user to be logged in the handle must be created
    if (!this._handleCreated) {
      return;
    }

    // In case the user does not exist, load it
    if (!this.userStore.user) {
      yield this.userStore.loadUserInfo(KnoxersManager.lastPendingSecret.secretId);
      return;
    }

    if (this.isGoogleSsoLoginPageMode) {
      this.replaceToMainPageUrl();
    }

    // In case the user is already loaded (after login to mandatory knoxers),
    // verify that all of the knoxers are logged in to the same user
    yield this.userStore.verifyUserInfo();
  });

  private replaceToMainPageUrl = (): void => {
    browserHistory.replace('/');
  };

  private tryLogInWithSavedCredentials = flow(function* (this: AuthenticationStore) {
    if (yield this.userStore.tryLoadUserInfo(undefined)) {
      this.loginMode = LoginMode.SavedCredentials;
    }
  });

  logout = flow(function* logout(this: AuthenticationStore) {
    this.logoutLoading = true;

    try {
      yield authenticationService.logout();
    } catch (error: unknown) {
      Log.exception(error);
    } finally {
      KnoxersManager.clearSaved();
      this.reset({ logoutLoading: true });
      this.handleSessionEnded();
      yield sleep(1000);
      this.logoutLoading = false;
    }
  });

  async handleSessionExpired(): Promise<void> {
    const knoxersAuthMethod = this.getAuthenticatedKnoxers();

    const authTypes = Object.values(knoxersAuthMethod)
      .map((x) => x?.authType)
      .filter(isTruthy);

    if (authTypes.includes(AuthMethodType.SamlSSO) || authTypes.includes(AuthMethodType.OidcSSO)) {
      await showInfoModalAsync(
        <ModalContainer>
          <WideInfoModalTitle>Session Expired</WideInfoModalTitle>
          <WideInfoModalBody>
            {createEnglishFormalErrorMessage(
              'Your session has expired and will be redirected to nsknox website.\n' +
                'You can login again by using the link provided you by your organization.',
              {
                preventPleaseContactText: true,
              },
            )}
          </WideInfoModalBody>
        </ModalContainer>,
        {
          okText: `OK`,
        },
      );
    }

    this.handleSessionEnded();
  }

  handleSessionEnded(): void {
    const knoxersAuthMethod = Object.values(this.getAuthenticatedKnoxers());

    const authTypes = Object.values(knoxersAuthMethod)
      .map((x) => x?.authType)
      .filter(isTruthy);

    this.clearAuthenticatedKnoxers();

    if (authTypes.includes(AuthMethodType.SamlSSO)) {
      AuthenticationStore.redirectToPageForSamlNotAuthenticatedUsers();
    } else if (authTypes.includes(AuthMethodType.OidcSSO)) {
      AuthenticationStore.redirectToPageForOIDCNotAuthenticatedUsers();
    } else if (authTypes.includes(AuthMethodType.GoogleSSO)) {
      AuthenticationStore.redirectToPageForSsoGoogleNotAuthenticatedUsers();
    } else {
      window.location.reload();
    }
  }

  private static redirectToPageForSamlNotAuthenticatedUsers(): void {
    location.assign(NSKNOX_MARKETING_WEBSITE);
  }

  private static redirectToPageForOIDCNotAuthenticatedUsers(): void {
    location.assign(NSKNOX_MARKETING_WEBSITE);
  }

  private static redirectToPageForSsoGoogleNotAuthenticatedUsers(): void {
    browserHistory.push(GOOGLE_SSO_LOGIN_MODE_PATH);
  }

  redirectToPageForSsoGoogleAuthentication(knoxerId: string): void {
    const authenticatorBaseUrl = this.getAuthBaseUrlEmail(knoxerId, AuthMethodType.GoogleSSO);
    location.assign(`${authenticatorBaseUrl}/${GOOGLE_SSO_AUTHORIZATION_PATH}`);
  }

  redirectToPageForSsoSamlAuthentication(knoxerId: string, samlProviderName: string): void {
    const authenticatorBaseUrl = this.getAuthBaseUrlEmail(knoxerId, AuthMethodType.SamlSSO);
    location.assign(`${authenticatorBaseUrl}/${samlProviderName}`);
  }

  redirectToPageForSsoOIDCAuthentication(knoxerId: string, oidcProviderName: string): void {
    const authenticatorBaseUrl = this.getAuthBaseUrlEmail(knoxerId, AuthMethodType.OidcSSO);
    location.assign(`${authenticatorBaseUrl}/${oidcProviderName}`);
  }

  private static determineNewLoginMode = (currentLoginMode?: LoginMode, wantedLoginMode?: LoginMode): LoginMode => {
    if (wantedLoginMode && config.loginModes.includes(wantedLoginMode)) {
      return wantedLoginMode;
    }

    if (currentLoginMode === LoginMode.SavedCredentials) {
      return config.loginModes[0];
    }

    return currentLoginMode ?? config.loginModes[0] ?? LoginMode.Local;
  };

  saveAuthenticatedKnoxer(knoxerId: string, persistedKnoxerAuthData: PersistedKnoxerAuthData): void {
    const knoxersAuthMethod = this.getAuthenticatedKnoxers();

    const newKnoxersAuthMethod = {
      ...knoxersAuthMethod,
      [knoxerId]: persistedKnoxerAuthData,
    };

    localStorage.setItem(KNOXERS_AUTH_DATA_STORAGE_KEY, JSON.stringify(newKnoxersAuthMethod));
  }

  getAuthenticatedKnoxers(): Partial<Record<string, PersistedKnoxerAuthData>> {
    const knoxersAuthMethod = localStorage.getItem(KNOXERS_AUTH_DATA_STORAGE_KEY);
    return knoxersAuthMethod ? JSON.parse(knoxersAuthMethod) : {};
  }

  private clearAuthenticatedKnoxers(): void {
    localStorage.removeItem(KNOXERS_AUTH_DATA_STORAGE_KEY);
  }
}

const anotherCodeModalClassName = css`
  text-align: center !important;
  width: 290px !important;
`;
