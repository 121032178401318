import { ColumnProps } from 'antd/lib/table';
import React, { ReactElement } from 'react';
import FilterColumnRangedTimeTable from './FilterColumnRangedTimeTable';
import { DateRange } from '@app/domain/filters';
import { isDefinedAndHasLength } from '@app/utils/utils';
import { valueOrValuesOrNullAsArray } from '@app/utils/arrayUtils';

export function getColumnCustomTimeLocalFilterProps<T>(timeFieldGetter: (item: T) => number): Partial<ColumnProps<T>> {
  const isFieldValueInsideDateRange = (dateRange: DateRange, record: T): boolean => {
    const recordTimestamp = timeFieldGetter(record);
    return recordTimestamp >= dateRange.start && recordTimestamp <= dateRange.end;
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnRangedTimeTable
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    onFilter: (dateRange: DateRange | undefined, record): boolean => {
      return !dateRange || isFieldValueInsideDateRange(dateRange, record);
    },
  };
}

export function getColumnCustomTimeServerFilterProps(currentFilterValue: DateRange[] | undefined): Partial<ColumnProps<any>> {
  return {
    filteredValue: valueOrValuesOrNullAsArray(currentFilterValue),
    filtered: isDefinedAndHasLength(currentFilterValue),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnRangedTimeTable
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
  };
}
