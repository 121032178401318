import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { getColorScheme } from '@app/domain/theme';
import Chip, { ChipProps } from '@app/components/Chip';

export interface SpecialInfoComponentType {
  name: string;
  component?(): ReactNode;
}

export const SpecialInfoBadge: FC<ChipProps> = ({ colorScheme = 'primary300', ...rest }) => {
  const colorSchemeObject = getColorScheme(colorScheme);
  return <SpecialInfoBadgeInner {...rest} colorScheme={colorSchemeObject} />;
};

export const TallSpecialInfoBadge = styled(SpecialInfoBadge)`
  padding-block: 6px;
  display: inline-flex;
`;

const SpecialInfoBadgeInner = styled(Chip)`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding: 3px 8px;
  text-align: center;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
`;

export function getSpecialInfo<TKey extends keyof any>(
  key: TKey | null | undefined,
  mappings: Record<TKey, SpecialInfoComponentType>,
): SpecialInfoComponentType | null {
  if (!key) {
    return null;
  }

  const componentType = mappings[key];

  if (!componentType) {
    return null;
  }

  return componentType;
}

export function renderSpecialInfo(infoComponent: SpecialInfoComponentType | null): React.ReactNode {
  return infoComponent?.component?.() ?? infoComponent?.name ?? null;
}

interface SpecialInfoFunctions<T> {
  get(value: T | null | undefined): SpecialInfoComponentType | null;
  text(value: T | null | undefined): string | null;
  render(value: T | null | undefined): ReactNode;
}

export function wrapSpecialInfo<T extends keyof any>(
  mappings: Record<T, SpecialInfoComponentType>,
): Record<T, SpecialInfoComponentType> & SpecialInfoFunctions<T> {
  function getValue(value: T): SpecialInfoComponentType | null {
    return getSpecialInfo(value, mappings);
  }
  function getText(value: T): string | null {
    return getValue(value)?.name ?? null;
  }
  function renderValue(value: T): ReactNode {
    return renderSpecialInfo(getValue(value));
  }

  return {
    ...mappings,
    get: getValue,
    text: getText,
    render: renderValue,
  };
}
