import { action, autorun, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import config from '../config';
import browserHistory from '@app/utils/browserHistory';
import UserStore from './/UserStore';
import qs from 'query-string';
import PermissionsStore from './/PermissionsStore';
import { filterValues } from '@app/utils/utils';
import { LocationDescriptor, LocationState } from 'history';

export default class NavigationStore {
  protected readonly permissionsStore: PermissionsStore;

  @observable currentRoute: FlatRoute | null = null;

  currentViewDispose: IReactionDisposer;

  constructor(userStore: UserStore, permissionsStore: PermissionsStore) {
    makeObservable(this);

    this.permissionsStore = permissionsStore;

    reaction(
      () => userStore.selectedOrganization,
      (newOrg, oldOrg) => {
        // Check that a transition between organizations occurred and not the selection of the initial organization
        if (oldOrg) {
          this.moveToSectionHome();
        }
      },
    );
  }

  initAfterInfraStoresInitialized(): void {
    this.currentViewDispose = autorun(
      () => {
        const matchedRoute = Object.values(this.permissionsStore.allowedRoutesByKey).find((route): boolean => {
          return !!route?.pattern?.test(browserHistory.location.pathname);
        });

        if (matchedRoute) {
          this.setCurrentRoute(matchedRoute);
        }
      },
      { name: 'currentViewDispose' },
    );
  }

  @computed
  get selectedSection(): Section | undefined {
    return this.permissionsStore.allowedSections.find((section) => section.id === this.currentRoute?.sectionId);
  }

  @computed
  private get initialRoute(): FlatRoute | undefined {
    return this.permissionsStore.allowedSections?.[0]?.baseRoute;
  }

  @computed
  get initialRoutePath(): string {
    return this.initialRoute?.path ?? config.initialView;
  }

  @action
  setCurrentRoute(currentRoute: FlatRoute): void {
    this.currentRoute = currentRoute;
  }

  moveToRoute(currentRoute: FlatRoute): void {
    browserHistory.push(currentRoute.path);
  }

  moveToSection(sectionId: string): void {
    const newSelectedSection = this.permissionsStore.allowedSections.find((section) => section.id === sectionId);

    if (newSelectedSection) {
      this.moveToRoute(newSelectedSection.baseRoute);
    }
  }

  getInitialViewRoute(): FlatRoute | undefined {
    if (this.initialRoute) {
      return this.initialRoute;
    }
  }

  moveToInitialView(): void {
    const initialRoute = this.getInitialViewRoute();
    if (initialRoute) {
      this.moveToRoute(initialRoute);
    }
  }

  moveToSectionHome(): void {
    const selectedSection = this.selectedSection;

    if (selectedSection) {
      this.moveToRoute(selectedSection.baseRoute);
    } else {
      this.moveToInitialView();
    }
  }

  generateGoHomeLink(): LocationDescriptor {
    const selectedSection = this.selectedSection;

    if (selectedSection) {
      return this.generateLink(selectedSection.baseRoute);
    } else {
      return this.generateLink(this.getInitialViewRoute());
    }
  }

  protected generateLink = (
    route?: FlatRoute | RoutesKeys,
    values: any = {},
    queryParams: Record<string, string | null> | null = null,
    state: LocationState = null,
  ): LocationDescriptor => {
    if (!route) {
      return '404';
    }

    if (typeof route === 'string') {
      return this.generateLink(this.permissionsStore.allowedRoutesByKey?.[route], values, queryParams);
    }

    let suffix = route.path;

    if (route.pathBuilder) {
      suffix = route.pathBuilder(values);
    }

    let queryString = '';

    if (queryParams) {
      const filteredQueryParams = filterValues(queryParams, (value) => value !== null);

      if (Object.keys(filteredQueryParams).length) {
        queryString = `?${qs.stringify(filteredQueryParams)}`;
      }
    }

    return {
      pathname: `${config.urlPath}${suffix}`,
      search: queryString,
      state: state,
    };
  };

  protected generateHref = (
    route?: FlatRoute | RoutesKeys,
    values: any = {},
    queryParams: Record<string, string | null> | null = null,
  ): string => {
    if (!route) {
      return '404';
    }

    if (typeof route === 'string') {
      return this.generateHref(this.permissionsStore.allowedRoutesByKey?.[route], values, queryParams);
    }

    let suffix = route.path;

    if (route.pathBuilder) {
      suffix = route.pathBuilder(values);
    }

    let queryString = '';

    if (queryParams) {
      const filteredQueryParams = filterValues(queryParams, (value) => value !== null);

      if (Object.keys(filteredQueryParams).length) {
        queryString = `?${qs.stringify(filteredQueryParams)}`;
      }
    }

    return `${config.urlPath}${suffix}${queryString}`;
  };
}
