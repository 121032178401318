import { Collapse as AntdCollapse } from 'antd';
import styled from '@emotion/styled';

import React, { FC } from 'react';
import useInfraStores from '@app/hooks/useInfraStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { observer } from 'mobx-react';
import { CollapseProps } from 'antd/lib/collapse';

interface Props extends CollapseProps {}

const Collapse: FC<Props> = observer((collapseProps) => {
  const { languageStore } = useInfraStores<SupplierRegistrationMode>();

  return <StyledAntdCollapse isRTL={languageStore.isRTL} defaultActiveKey={['1']} {...collapseProps} />;
});

export default Collapse;

// @ts-expect-error -- in old antd versions there is a mismatch between the collapse props and the type of the default values
const StyledAntdCollapse = styled(AntdCollapse)<{ isRTL?: boolean }>`
  background: transparent !important;

  & .ant-collapse-header {
    padding: 21px ${(p): number => (p.isRTL ? 64 : 16)}px 19px ${(p): number => (p.isRTL ? 16 : 64)}px !important;

    svg {
      left: ${(p): string => (p.isRTL ? 'unset' : '16px')} !important;
      right: ${(p): string => (p.isRTL ? '16px' : 'unset')} !important;
    }
  }

  border-top: none !important;

  & .ant-collapse-content {
    border: none;
  }
`;

export const CollapsePanel = styled(AntdCollapse.Panel)`
  background: transparent;
  & .ant-collapse-header {
    border-top: 1px solid #d9d9d9;
  }
`;

export const CollapsePanelStickyHeader = styled(CollapsePanel)`
  z-index: 0;
  position: relative;

  & .ant-collapse-header {
    position: sticky !important;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 100;
  }
`;
