import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import SVG from '@app/components/SVG';
import BadMatchIcon from '@mortee/images/bad-match-magnifier-red.svg';
import ValidatedPayeeChip from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/content/ValidatedPayeeChip';
import Button from '@app/components/Button';
import { showContentOnlyModal } from '@app/components/Modal';
import FixMissingLinkModal from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/modals/FixMissingLinkModal';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { PrivatePayeeAccountWithDeterministicValidation } from '@mortee/domain/masterDataGuard';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import ModalAppContext, { ModalAppContextProps } from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

interface Props {
  privatePayeeAccountsWithValidation: PrivatePayeeAccountWithDeterministicValidation[];
  validatedPayees: AggregatedValidatedPayee[];
  validatedPayeeAccounts: Partial<Record<string, ValidatedPayeeAccount[]>>;
  className?: string;
}

const PrivateValidatedPayeesDVMatch: FC<Props> = ({
  validatedPayees,
  validatedPayeeAccounts,
  privatePayeeAccountsWithValidation,
  className,
}) => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const modalContext = useModalContext();

  let mainContent: ReactNode;
  let sideAction: ReactNode = null;

  if (validatedPayees.length) {
    mainContent = (
      <>
        <MatchTextFix matched>Found a match on PaymentKnox:</MatchTextFix>
        <ChipsBag data-testid='mdg-match-payee'>
          {validatedPayees.map((validatedPayee) => (
            <ValidatedPayeeChip
              key={validatedPayee.uniformId}
              validatedPayee={validatedPayee}
              validatedPayeeAccounts={validatedPayeeAccounts[validatedPayee.uniformId] ?? []}
            />
          ))}
        </ChipsBag>
      </>
    );
  } else if (
    privatePayeeAccountsWithValidation.length &&
    privatePayeeAccountsWithValidation.every(
      (privatePayeeAccount) =>
        privatePayeeAccount.deterministicValidation.validationLevel === DeterministicValidationResultType.selfApproved,
    )
  ) {
    mainContent = <MatchTextFix matched>All accounts are Self-Approved</MatchTextFix>;
  } else {
    mainContent = (
      <>
        <StyledBadMatchIcon accessibilityLabel='' image={BadMatchIcon} />
        <MatchText matched={false}>Vendor not found in PaymentKnox database</MatchText>
      </>
    );
    sideAction = (
      <HowToFixButton
        id='mvf-guard-how-to-fix'
        appearance='text'
        colorScheme='secondary'
        onClick={(): void => showHowToFixModal(navigationStore.generateAccountVerificationHomeHref(), modalContext)}
      >
        HOW TO FIX?
      </HowToFixButton>
    );
  }
  return (
    <MainContainer className={className}>
      <ResultContainer>{mainContent}</ResultContainer>
      {sideAction && <SideAction>{sideAction}</SideAction>}
    </MainContainer>
  );
};

const showHowToFixModal = (accountVerificationPath: string, modalContext: ModalAppContextProps): void => {
  showContentOnlyModal(
    (onDone): ReactElement => (
      <ModalAppContext {...modalContext}>
        <FixMissingLinkModal accountVerificationPath={accountVerificationPath} onDone={onDone} />
      </ModalAppContext>
    ),
  );
};

export default PrivateValidatedPayeesDVMatch;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
`;

const SideAction = styled.div`
  flex: 0 0 auto;
`;

const HowToFixButton = styled(Button)`
  font-size: 12px;
`;

const MatchText = styled.span<{ matched: boolean }>`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.36px;
  color: ${(p): string => (p.matched ? 'var(--black-strong)' : 'var(--alert-color)')};
`;

const MatchTextFix = styled(MatchText)`
  flex: 0 0 auto;
`;

const StyledBadMatchIcon = styled(SVG)`
  width: 24px;
  height: 24px;

  margin-right: 16px;
`;

const ChipsBag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  margin-left: 8px;
  padding: 3px 0;
`;
