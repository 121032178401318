import React, { FC } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import * as messageLauncher from '@app/utils/messageLauncher';
import { renderSpecialInfo, SpecialInfoComponentType } from '@app/components/SpecialInfoComponents';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import CopyIcon from '@app/images/copy.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  value: string | string[] | SpecialInfoComponentType | SpecialInfoComponentType[];
  hideCopyButton?: boolean;
  dataTestId: string;
}

const CopyableValue: FC<Props> = ({ label, value, children, hideCopyButton, dataTestId }) => {
  if (typeof value === 'string') {
    return (
      <SingleCopyableValue label={label} dataTestId={dataTestId} valueToCopy={value} hideCopyButton={hideCopyButton}>
        {children || value}
      </SingleCopyableValue>
    );
  }

  // it is SpecialInfoComponentType
  if (!Array.isArray(value)) {
    return (
      <SingleCopyableValue label={label} dataTestId={dataTestId} valueToCopy={value.name} hideCopyButton={hideCopyButton}>
        {children || renderSpecialInfo(value)}
      </SingleCopyableValue>
    );
  }

  return (
    <>
      {value.map((valueInArray: string | SpecialInfoComponentType) => {
        const key = typeof valueInArray === 'string' ? valueInArray : valueInArray.name;
        return (
          <CopyableValue key={key} label={label} dataTestId={dataTestId} value={valueInArray} hideCopyButton={hideCopyButton} />
        );
      })}
    </>
  );
};

export default CopyableValue;

interface SingleCopyableValueProps {
  label: string;
  valueToCopy: string;
  hideCopyButton?: boolean;
  dataTestId: string;
}

const SingleCopyableValue: FC<SingleCopyableValueProps> = ({ label, valueToCopy, hideCopyButton, dataTestId, children }) => {
  const { t } = useTranslation();

  function onValueClicked(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    event.stopPropagation();
    copyToClipboard(valueToCopy);
    messageLauncher.shoot({ type: 'success', duration: 2, closeable: true }, `${label} "${valueToCopy}" Copied!`);
  }

  if (hideCopyButton) {
    return <>{children}</>;
  }

  return (
    <CopyButton id={dataTestId} dataTestId={dataTestId} onClick={onValueClicked} colorScheme='white'>
      {children ?? valueToCopy}
      <CopyIconSVG accessibilityLabel={t('general.accessibility.copy')} width={15} height={16} image={CopyIcon} />
    </CopyButton>
  );
};

const CopyButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  color: unset;
  font-size: unset;
  font-weight: unset;
  letter-spacing: unset;
  text-align: unset;

  transition: margin-right 0.2s;
  padding: 7px 8px;
  margin: -7px -8px -7px -8px;

  background-color: transparent;

  &:hover {
    margin-right: -31px;
  }

  box-shadow: none !important;
`;

const CopyIconSVG = styled(SVG)`
  min-width: 0;
  max-width: 0;

  transition: min-width 0.2s, max-width 0.2s, margin-left 0.2s;

  ${CopyButton}:hover & {
    min-width: 15px;
    max-width: 15px;
    margin-left: 8px;
  }
`;
