import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigLoadingSpinner } from '@app/components/BigLoadingSpinner';
import { BodyRegularStartTransparentBlack900 } from '@app/components/Text';

const LoggingOutPage: FC = () => {
  return (
    <Container>
      <BigLoadingSpinner />
      <MainText>Logging out...</MainText>
    </Container>
  );
};

export default LoggingOutPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const MainText = styled(BodyRegularStartTransparentBlack900.div)`
  padding-top: 10px;
`;
