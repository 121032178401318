import { mapValues } from '@app/utils/utils';
import { replaceAll, trimToFallback } from './stringUtils';

export function readExcelRowsFromClipboardHtmlContent(clipboardExcelRowsHtml: string | null | undefined): Cell[][] {
  if (!clipboardExcelRowsHtml) {
    return [];
  }

  const htmlDoc = parseHTMLFromString(clipboardExcelRowsHtml);

  if (!htmlDoc) {
    return [];
  }

  const tableNodes = htmlDoc.getElementsByTagName('table');

  if (!tableNodes?.[0]) {
    return [];
  }

  return rowsFromTable(tableNodes[0]);
}

function parseHTMLFromString(html: string): Document | null {
  const parser = new DOMParser();
  let htmlDoc: Document;

  try {
    htmlDoc = parser.parseFromString(html, 'text/html');
  } catch (e) {
    return null;
  }

  if (!htmlDoc) {
    // for Old Browser and phantomjs
    htmlDoc = document.implementation.createHTMLDocument('');
    htmlDoc.body.innerHTML = html;
  }

  return htmlDoc;
}

function rowsFromTable(tableNode: HTMLTableElement): Cell[][] {
  const result: Cell[][] = [];
  const rows = tableNode.getElementsByTagName('tr');

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const resultRow: Cell[] = [];
    const cols = rows[rowIndex].querySelectorAll<HTMLTableHeaderCellElement | HTMLTableCellElement>('th, td');

    for (let colIndex = 0; colIndex < cols.length; colIndex++) {
      const col = cols[colIndex];

      const colSpan = col.colSpan || 0;

      resultRow.push({
        value: normalizeCellTextContent(col.textContent),
        isHeader: col.nodeName?.toUpperCase() === 'TH',
      });

      // If the cell spans for more then 1 column, put the value in the first column and set
      // the other columns as empty value
      for (let columnInSpan = 1; columnInSpan < colSpan; columnInSpan++) {
        resultRow.push({
          value: '',
          isHeader: col.nodeName?.toUpperCase() === 'TH',
        });
      }
    }

    result.push(resultRow);
  }

  return result;
}

function normalizeCellTextContent(cellTextContent: string | null): string {
  const initialContent = trimToFallback(cellTextContent, '');

  // Newlines are added by the clipboard copy
  const removedClipboardNewline = replaceAll(initialContent, / *\n */g, ' ');

  // <br> are the actual real newlines that the user added
  return replaceAll(removedClipboardNewline, '<br>', '\n');
}

export function excelColumnTitleToNumber(excelColumnName: ValidExcelColumn): number {
  let result = 0;

  for (let i = 0; i < excelColumnName.length; i++) {
    const currentLetterValue = excelColumnName[i].charCodeAt(0) - 'A'.charCodeAt(0) + 1;

    result = result * 26 + currentLetterValue;
  }

  return result;
}

export interface Cell {
  value: string;
  isHeader: boolean;
}

type UppercaseAlphabet =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';

export type ValidExcelColumn =
  | UppercaseAlphabet
  | `${UppercaseAlphabet}${UppercaseAlphabet}`
  | `${UppercaseAlphabet}${UppercaseAlphabet}${UppercaseAlphabet}`;

export type ExcelRowDefinition<T extends object> = { [P in keyof T]: ValidExcelColumn };
export type ExcelRowObject<T extends object> = { [P in keyof T]: string | null };

export function excelRowIntoObject<T extends object>(row: Cell[], rowDefinition: ExcelRowDefinition<T>): ExcelRowObject<T> {
  return mapValues(rowDefinition, (rowDefinition) => {
    return row[excelColumnTitleToNumber(rowDefinition) - 1]?.value.trim() || null;
  }) as ExcelRowObject<T>;
}

export function excelValueToBoolean(value: string | null): boolean {
  return value?.toUpperCase() === 'YES';
}

export function excelColumnIndexToLetters(columnNumber): string {
  const asciiStartChar = 'A'.charCodeAt(0);
  const asciiEndChar = 'Z'.charCodeAt(0);
  const asciiCharacterSpace = asciiEndChar - asciiStartChar + 1;

  let result = '';
  while (columnNumber >= 0) {
    result = String.fromCharCode((columnNumber % asciiCharacterSpace) + asciiStartChar) + result;
    columnNumber = Math.floor(columnNumber / asciiCharacterSpace) - 1;
  }
  return result;
}
