import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LOGIN_FIELD_MAX_LENGTH, LOGIN_FIELD_MIN_LENGTH } from '@app/domain/uiConsts';
import { ActionsContainer, PrimaryButton, SubTitle, Title } from '../../Styles';
import { action, flow } from 'mobx';
import PasswordFormInput from '@app/components/inputs/PasswordFormInput';
import { LoginErrorBody, LoginErrorCodes } from '@app/login/domain/loginConsts';
import * as messageLauncher from '@app/utils/messageLauncher';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useMountEffect from '@app/hooks/useMountEffect';

interface Props extends FormComponentProps<NewPasswordFormFields> {
  knoxerId: string;
  flowId: string;
  email: string;
  onBack: () => void;
  onSubmit: () => void;
}

interface NewPasswordFormFields {
  newPassword: string;
  newPasswordConfirmation: string;
}

const ForgotPasswordNewPassword: FunctionComponent<Props> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,
  }));

  const compareToFirstPassword = (rule, value, callback): void => {
    const { form } = props;

    if (!value || value !== form.getFieldValue('newPassword')) {
      callback('Must match first password');
      return;
    }

    callback();
  };

  const validateToNextPassword = (rule, value, callback): void => {
    const { form } = props;

    if (value) {
      form.validateFields(['newPasswordConfirmation'], { force: true });
    }

    callback();
  };

  const fieldDecorators: FormFieldDecorators<NewPasswordFormFields> = {
    newPassword: {
      validateFirst: true,
      rules: [
        {
          required: true,
          message: 'Please enter the new password',
        },
        {
          pattern: /[A-Z]/,
          message: 'Password must contain at least one uppercase letter',
        },
        {
          pattern: /[a-z]/,
          message: 'Password must contain at least one lowercase letter',
        },
        {
          pattern: /[0-9]/,
          message: 'Password must contain at least one digit',
        },
        {
          min: LOGIN_FIELD_MIN_LENGTH.password,
          message: `Password must be at least ${LOGIN_FIELD_MIN_LENGTH.password} characters long`,
        },
        {
          max: LOGIN_FIELD_MAX_LENGTH.password,
          message: `Max ${LOGIN_FIELD_MAX_LENGTH.password} characters`,
        },
        {
          validator: validateToNextPassword,
        },
      ],
    },
    newPasswordConfirmation: {
      rules: [
        {
          validator: compareToFirstPassword,
        },
      ],
    },
  };

  useMountEffect(() => {
    const { form } = props;
    const { validateFields } = form;

    validateFields();
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      await handleOk();
    }
  };

  const isFormInvalid = (): boolean => {
    const { form } = props;
    const { getFieldsError } = form;
    const fieldsError = getFieldsError();

    const fields = Object.keys(fieldsError);
    return fields.length === 0 || fields.some((field) => fieldsError[field]);
  };

  const handleCodeInputFocus = action(() => {
    localStore.inputErrorText = null;
  });

  const handleOk = async (): Promise<void> => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    if (authenticationStore.loading) {
      return;
    }

    validateFieldsAndScroll((errors: Object, values: NewPasswordFormFields) => {
      if (errors) {
        return;
      }

      const { newPassword } = values;

      startForgotPasswordProcess(newPassword);
    });
  };

  const decidePasswordPolicyErrorMessage = (loginError: LoginErrorBody): string => {
    if (!loginError || !loginError.info || !loginError.info.violations) {
      return 'Password must not be too simple or equal to any of last 3 passwords';
    }

    const errors = loginError.info.violations.map((violation) => {
      switch (violation) {
        case 'invalidPasswordHistoryMessage': {
          return 'Password must not equal to any of last 3 passwords';
        }
        case 'invalidPasswordNotUsernameMessage': {
          return 'Password must not equal to the username';
        }
        case 'invalidPasswordBlacklistedMessage': {
          return 'Password is blacklisted, choose another password';
        }
      }
    });

    return errors.join('\n');
  };

  const startForgotPasswordProcess = flow(function* (newPassword: string) {
    const { knoxerId, flowId, email, onSubmit, onBack } = props;

    try {
      yield authenticationStore.forgotPasswordResetPassword(knoxerId, flowId, email, newPassword);
      messageLauncher.shootSuccessOld('Password changed successfully');
      onSubmit();
    } catch (e: unknown) {
      const response = e as LoginErrorBody;

      switch (response.errorCode) {
        case LoginErrorCodes.PasswordNotByPolicy: {
          localStore.inputErrorText = decidePasswordPolicyErrorMessage(response);
          break;
        }
        case LoginErrorCodes.ForgotPasswordFlowExpired: {
          messageLauncher.shootErrorOld('Forgot password process has expired, restarting process');
          onBack();
          break;
        }
        case LoginErrorCodes.ForgotPasswordInvalidFlowId: {
          messageLauncher.shootErrorOld(
            'Unexpected error occurred while trying to enter email verification code, restarting process',
          );
          onBack();
          break;
        }
      }
    }
  });

  const { form, onBack } = props;

  const hasFieldErrors = isFormInvalid();

  return (
    <ContentContainer>
      <Title>New Password</Title>
      <LoginSubTitle>Enter a new password</LoginSubTitle>
      <NewPasswordForm onSubmit={handleFormSubmit}>
        <FormItemBox
          appearance='line'
          form={form}
          fieldName='newPassword'
          fieldDecoratorOptions={fieldDecorators.newPassword}
          forcedErrorText={localStore.inputErrorText}
        >
          <PasswordFormInput
            name='inpt-auth-forgot-enter-password-first'
            colorScheme='secondary'
            autoFocus
            placeholder='New Password'
            onClick={handleCodeInputFocus}
            onFocus={handleCodeInputFocus}
            onKeyPress={handleKeyPress}
          />
        </FormItemBox>
        <FormItemBox
          appearance='line'
          form={form}
          fieldName='newPasswordConfirmation'
          fieldDecoratorOptions={fieldDecorators.newPasswordConfirmation}
        >
          <PasswordFormInput
            name='inpt-auth-forgot-enter-password-second'
            colorScheme='secondary'
            placeholder='Re-enter new password'
            onKeyPress={handleKeyPress}
          />
        </FormItemBox>
        <ActionsContainer>
          <Button id='btn-auth-forgot-enter-password-back' appearance='text' onClick={onBack} size='small'>
            BACK
          </Button>
          <PrimaryButton id='btn-auth-forgot-enter-password-continue' onClick={handleOk} disabled={hasFieldErrors}>
            CONTINUE
          </PrimaryButton>
        </ActionsContainer>
      </NewPasswordForm>
    </ContentContainer>
  );
});

export default Form.create<Props>()(ForgotPasswordNewPassword);

const ContentContainer = styled.div`
  padding: 48px 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const LoginSubTitle = styled(SubTitle)`
  margin-bottom: 15px;
`;

const NewPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;
