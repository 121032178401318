import React, { FC, ReactElement, ReactNode } from 'react';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import { Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import Loadable, { LoadableCreator } from '@app/utils/Loadable';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';

export interface MultiselectValue {
  text: ReactNode;
  value: string;
  keywords?: string[];
}

interface Props {
  values: MultiselectValue[] | Loadable<MultiselectValue[]>;
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  confirm: () => void;
  clearFilters: () => void;
  showSearch?: boolean;
}

const FilterColumnMultiValuePopup: FC<Props> = ({
  values,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  showSearch,
}): ReactElement => {
  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      e.preventDefault();
      confirm();
    }
  };

  const valuesLoadable = LoadableCreator.wrap(values);
  const [filteredData, searchTerm, setSearchTerm] = useLoadableSearchFilter(valuesLoadable, {
    partialMatches(searchItem) {
      return searchItem.keywords ?? [];
    },
  });

  return (
    <PopupContent onKeyPress={handleKeyPress}>
      <Heading>
        <Title>Filter</Title>
        <Button
          appearance='text'
          id='btn-table-multivalue-filter-reset'
          onClick={(): void => {
            setSearchTerm('');
            clearFilters();
          }}
          size='small'
        >
          CLEAR
        </Button>
      </Heading>
      {showSearch && (
        <InputBox>
          <StyledNakedFormInput
            placeholder='Search'
            type='text'
            name='input-table-text-filter'
            dataTestId='input-table-text-filter'
            value={searchTerm}
            onChange={(newValue): void => setSearchTerm(newValue ?? '')}
            autoFocus
          />
        </InputBox>
      )}
      {filteredData.resolve(
        (loadedValues): ReactNode => (
          <>
            <CheckboxList data-testid='cbox-list-all-values'>
              {loadedValues
                // .filter((value) => (value.text ? value.text.toString().toLowerCase().includes(searchValue.toLowerCase()) : false))
                .map((value) => (
                  <StyledFormControlLabel
                    key={`companyCode-value-${value.value}`}
                    control={
                      <StyledCheckbox
                        id={`cbox-table-multivalue-filter-value-${value.value}`}
                        data-testid={`cbox-table-multivalue-filter-value-${value.value}`}
                        checked={selectedKeys.includes(value.value)}
                        onChange={(newValue): void => {
                          if (newValue.currentTarget.checked) {
                            setSelectedKeys([...selectedKeys, value.value]);
                          } else {
                            setSelectedKeys(arrayWithoutValue(selectedKeys, value.value));
                          }
                        }}
                        color='primary'
                      />
                    }
                    label={value.text}
                  />
                ))}
            </CheckboxList>
            <Actions>
              <Button id='btn-table-multivalue-filter-search' size='small' onClick={(): void => confirm()}>
                APPLY
              </Button>
            </Actions>
          </>
        ),
        () => (
          <LoaderConteiner>
            <CircularProgress color='primary' size={32} />
          </LoaderConteiner>
        ),
      )}
    </PopupContent>
  );
};

export default FilterColumnMultiValuePopup;

const PopupContent = styled.div`
  padding: 10px 12px 10px 18px;
  max-height: 95vh;

  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  flex: 0 0 auto;

  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);

  flex: 1;
`;

const Actions = styled.div`
  flex: 0 0 auto;

  padding-top: 12px;

  display: flex;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const CheckboxList = styled.div`
  flex: 1;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  font-size: 12px;
  letter-spacing: 0.68px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: -12px;
`;

const LoaderConteiner = styled.div`
  padding: 100px;
`;

const StyledNakedFormInput = styled(NakedFormInput)`
  min-width: 300px;
`;
