import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import {
  PrivatePayeeAccount,
  PrivatePayeeAccountSelfApproveReason,
  PrivatePayeeAccountValidationStatus,
  RemoveSelfApproveRequest,
  SelfApprovePrivatePayeeAccountRequest,
} from '@mortee/domain/privatePayeeAccount';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/close-thick.svg';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { Bold13TransparentGrey800, Regular22TransparentBlack900 } from '@app/components/Text';
import PrivateAccountInitiateSelfApproveForm, {
  MAX_ALLOWED_FILES,
  SELF_APPROVE_FORM_ALLOWED_EXTENSIONS,
  SelfApproveFields,
} from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/PrivateAccountInitiateSelfApproveForm';
import { Collapse, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';
import Log from '@app/libs/logger';
import { convertDatePickerValueToEpoch } from '@app/components/inputs/DatePicker';
import { showContentOnlyModal, showCustomModal } from '@app/components/Modal';
import FileUploadDraggerModal from '@app/components/fileUpload/FileUploadDraggerModal';
import { wrapValueAsArray } from '@app/utils/arrayUtils';
import { trimToUndefined } from '@app/utils/stringUtils';
import ErrorModal from '@app/components/ErrorModal';
import { DEFAULT_FORM_VALIDATION_OPTIONS } from '@app/utils/form/form';
import { isRequestError, isWebErrorContent } from '@app/libs/request';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import useMountEffect from '@app/hooks/useMountEffect';
import { Subtitle } from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/Styles';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import PayeeAndAccountDetails from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/PayeeAndAccountDetails';
import PrivateAccountHistoryTimeline from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/PrivateAccountHistoryTimeline';
import ValidationLevelIcon from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/ValidationLevelIcon';
import RemoveSelfApproveModal from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/RemoveSelfApproveModal';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import { PrivatePayee } from '@mortee/domain/privatePayee';
import { DeterministicValidationWithPrivatePayee } from '@mortee/domain/morteeDeterministicValidation';
import { fetchSelfApproveEventAttachmentFile } from '@mortee/services/masterDataGuardServices';
import { CheckFileOptions, DEFAULT_MAX_FILE_NAME_LENGTH, DEFAULT_MAX_FILE_SIZE_MB, FilesErrorOutput } from '@app/domain/files';
import ModalAppContext, { ModalAppContextProps } from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

const { Panel } = Collapse;

interface Props extends FormComponentProps<SelfApproveFields> {
  privatePayee: PrivatePayee;
  privatePayeeAccount: PrivatePayeeAccount;
  deterministicValidation: DeterministicValidationWithPrivatePayee;
  // Remove this method prop when moving to antd v4 - it has a way to pass context to modals
  // (and just use masterDataGuardStore.storeInitiateSelfApprove)
  onSubmit: (filesToUpload: File[], request: SelfApprovePrivatePayeeAccountRequest) => Promise<void>;
  onRemove: (request: RemoveSelfApproveRequest) => Promise<void>;
  onClose: () => void;
  permissions: {
    canSelfApprove: boolean;
    canRemoveSelfApprove: boolean;
  };
}

const PrivateAccountInitiateSelfApproveModal: FC<Props> = (props) => {
  useMountEffect(() => {
    form.validateFields();
  });

  const { form, onSubmit, onRemove, onClose, permissions, privatePayee, privatePayeeAccount, deterministicValidation } = props;

  const [showFormErrors, setShowFormErrors] = useState<FormItemsBoxProps['showErrors']>('onTouch');
  const [loading, setLoading] = useState<boolean>(false);
  const [firstSelfApproveInSession, setFirstSelfApproveInSession] = useState<boolean>(
    deterministicValidation.validationLevel === DeterministicValidationResultType.notValidated,
  );
  const modalContext = useModalContext();

  const checkFileOptions: CheckFileOptions = {
    allowedExtensions: SELF_APPROVE_FORM_ALLOWED_EXTENSIONS,
    maxFilesAllowed: MAX_ALLOWED_FILES,
    maxFileSizeMB: DEFAULT_MAX_FILE_SIZE_MB,
    maxFileNameLength: DEFAULT_MAX_FILE_NAME_LENGTH,
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleSelfApprove();
  };

  const handleOkClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleSelfApprove();
    setFirstSelfApproveInSession(false);
  };

  const handleRemoveClick = async (): Promise<void> => {
    showRemoveSelfApproveModal(privatePayee, privatePayeeAccount, deterministicValidation, handleRemoveEvent, modalContext);
  };

  const handleSelfApprove = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll(DEFAULT_FORM_VALIDATION_OPTIONS, (errors: Object, values: SelfApproveFields) => {
      if (errors) {
        setShowFormErrors('all');
        return;
      }

      processSelfApproveRequest(values);
    });
  };

  const handleRemoveEvent = async (payload: RemoveSelfApproveRequest): Promise<void> => {
    await onRemove(payload);
  };

  const processSelfApproveRequest = async (formFields: SelfApproveFields): Promise<void> => {
    if (loading) {
      return;
    }

    let payload: SelfApprovePrivatePayeeAccountRequest;

    const { reason, files, phoneCallDate, contactName, contactPhoneNumber, phoneCallDescription, description, note } = formFields;

    switch (reason) {
      case PrivatePayeeAccountSelfApproveReason.phoneCallVerification: {
        payload = {
          reason,
          contactName: contactName.trim(),
          contactPhoneNumber: contactPhoneNumber.trim(),
          phoneCallDescription: phoneCallDescription.trim(),
          phoneCallTimestamp: convertDatePickerValueToEpoch(phoneCallDate) ?? 0,
        };
        break;
      }
      case PrivatePayeeAccountSelfApproveReason.transactionHistory:
      case PrivatePayeeAccountSelfApproveReason.smallAmountTransaction:
      case PrivatePayeeAccountSelfApproveReason.oneTimeTransaction: {
        payload = {
          reason,
          note: trimToUndefined(note),
        };
        break;
      }
      case PrivatePayeeAccountSelfApproveReason.other: {
        payload = {
          reason,
          description: description.trim(),
          note: trimToUndefined(note),
        };
        break;
      }
    }

    setLoading(true);

    try {
      await onSubmit(files, payload);
    } catch (e: unknown) {
      if (e instanceof Error && e.message === 'Error updating list after change') {
        onClose();
        return;
      }

      Log.exception(e);

      if (isRequestError(e)) {
        if (isWebErrorContent(e.responseJSON) && e.responseJSON.error === 'INITIATE_SELF_APPROVE_ACCOUNT_ALREADY_SELF_APPROVED') {
          showContentOnlyModal((onDone) => (
            <ErrorModal
              headerContent='Account was modified'
              bodyContent='The account you are trying to self approve has been modified. Please try again.'
              okButtonText='OK'
              onDone={onDone}
            />
          ));
          return;
        }
      }

      const fileErrors = e as FilesErrorOutput;
      if (files.some((file) => fileErrors.erroredFiles?.[file.name])) {
        // Add error from response to the file and then sort so that the files with errors will be first
        const filesAndErrors = (
          files?.map((file) => ({ file, errors: wrapValueAsArray(fileErrors.erroredFiles?.[file.name]) })) ?? []
        ).sort((fileAndError1, fileAndError2) => fileAndError2.errors.length - fileAndError1.errors.length);

        showCustomModal((onDone) => {
          return (
            <ModalAppContext {...modalContext}>
              <FileUploadDraggerModal
                files={filesAndErrors}
                checkFileOptions={checkFileOptions}
                onDone={(newFiles): void => {
                  form.setFieldsValue({ files: newFiles });
                  onDone();
                }}
                onCancel={onDone}
                title='Attach Files'
              />
            </ModalAppContext>
          );
        });
      } else {
        showContentOnlyModal((onDone) => (
          <ModalAppContext {...modalContext}>
            <ErrorModal
              headerContent='Something went wrong'
              bodyContent={`Something went wrong, please try again later.${
                fileErrors.errorLogId ? '\n' + 'Error code: ' + fileErrors.errorLogId : ''
              }`}
              okButtonText='OK'
              onDone={onDone}
            />
          </ModalAppContext>
        ));
      }
    } finally {
      setLoading(false);
    }
  };

  const showSelfApprove = (): boolean => {
    return (
      deterministicValidation.validationLevel === DeterministicValidationResultType.notValidated && permissions.canSelfApprove
    );
  };

  const showRemoveSelfApprove = (): boolean => {
    return (
      deterministicValidation.validationLevel === DeterministicValidationResultType.selfApproved &&
      permissions.canRemoveSelfApprove
    );
  };

  const showRemoveSelfApproveTooltip = (): boolean => {
    return showRemoveSelfApprove() && privatePayeeAccount.validationStatus !== PrivatePayeeAccountValidationStatus.selfApproved;
  };

  return (
    <MainContainer>
      <TitleLine>
        <Regular22TransparentBlack900.div>Self Validation</Regular22TransparentBlack900.div>
        <CloseButton id='btn-mvf-guard-sa-validation-modal-close' appearance='text' colorScheme='primary' onClick={onClose}>
          <SVG accessibilityLabel='close' image={ClearIcon} height={24} />
        </CloseButton>
      </TitleLine>
      <VerticalShadowScroller>
        <Content>
          <ColumnHeader data-testid='sa-modal-validation-level'>
            <StyledHeader>Validation level:</StyledHeader>
            <ValidationLevelIcon size='small' showText validationLevel={deterministicValidation.validationLevel} />
            {showRemoveSelfApprove() && (
              <ConditionalTooltip
                showTooltip={showRemoveSelfApproveTooltip()}
                title='This account self-validation cannot be canceled, since it matches another self-validated account.'
                placement='top'
              >
                <CancelButton
                  id='remove-self-validation'
                  appearance='text'
                  onClick={handleRemoveClick}
                  disabled={showRemoveSelfApproveTooltip()}
                >
                  Cancel Self Validation
                </CancelButton>
              </ConditionalTooltip>
            )}
          </ColumnHeader>
          <StyledHr />
          <PayeeAndAccountDetails privatePayee={privatePayee} deterministicValidation={deterministicValidation} />
          <StyledHr />
          <StyledSubtitle>SELF VALIDATION HISTORY</StyledSubtitle>
          <PrivateAccountHistoryTimeline
            privatePayeeAccount={privatePayeeAccount}
            deterministicValidation={deterministicValidation}
            fetchSelfApproveAttachmentFile={(eventId, fileId): Promise<NamedResource> =>
              fetchSelfApproveEventAttachmentFile(privatePayee.uniformId, privatePayeeAccount.uniformId, eventId, fileId)
            }
          />
          {showSelfApprove() && (
            <>
              <StyledHr />
              <Form onSubmit={handleFormSubmit} data-testid='initiate-sa-modal-expander'>
                <Collapse bordered={false} defaultActiveKey={[firstSelfApproveInSession ? '1' : '']} accordion>
                  <StyledPanel
                    firstSelfApprove={firstSelfApproveInSession}
                    disabled={firstSelfApproveInSession}
                    header={<Bold13TransparentGrey800.div>INITIATE SELF VALIDATION</Bold13TransparentGrey800.div>}
                    key='1'
                    showArrow={!firstSelfApproveInSession}
                  >
                    {showRemoveSelfApprove() ? '1' : ''}
                    <PrivateAccountInitiateSelfApproveForm
                      form={form}
                      showFormErrors={showFormErrors}
                      resetShowFormErrors={(): void => setShowFormErrors('onTouch')}
                    />
                    <ActionsContainer>
                      <StyledCancelButton
                        id='btn-mvf-guard-sa-validation-initiate-self-cancel'
                        onClick={onClose}
                        appearance='outline'
                        disabled={loading}
                      >
                        CANCEL
                      </StyledCancelButton>
                      <StyledSelfValidateButton
                        id='btn-mvf-guard-sa-validation-initiate-self-validate'
                        onClick={handleOkClick}
                        loading={loading}
                        loaderProps={{ customColor: 'white' }}
                      >
                        SELF VALIDATE
                      </StyledSelfValidateButton>
                    </ActionsContainer>
                  </StyledPanel>
                </Collapse>
              </Form>
            </>
          )}
        </Content>
      </VerticalShadowScroller>
    </MainContainer>
  );
};

export default Form.create<Props>()(PrivateAccountInitiateSelfApproveModal);

const showRemoveSelfApproveModal = (
  privatePayee: PrivatePayee,
  privatePayeeAccount: PrivatePayeeAccount,
  deterministicValidation: DeterministicValidationWithPrivatePayee,
  onRemove: (payload: RemoveSelfApproveRequest) => Promise<void>,
  modalContext: ModalAppContextProps,
): void => {
  showCustomModal(
    (onDone) => (
      <ModalAppContext {...modalContext}>
        <RemoveSelfApproveModal
          privatePayee={privatePayee}
          privatePayeeAccount={privatePayeeAccount}
          deterministicValidation={deterministicValidation}
          onSubmit={onRemove}
          onClose={onDone}
        />
      </ModalAppContext>
    ),
    {
      maskClosable: false,
      maskStyle: { backgroundColor: 'transparent' },
    },
  );
};

const MainContainer = styled(Card)`
  width: min(800px, 90vw);
  padding: 31px 0 8px;
  position: relative;
  box-shadow: var(--box-shadow-3);

  max-height: 95vh;
  display: flex;
  flex-direction: column;

  --side-padding: 32px;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 0 var(--side-padding) 13px;
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  align-self: flex-end;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 18px;
`;

const Content = styled.div`
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  margin-top: 36px;
`;

const StyledCancelButton = styled(Button)`
  padding: 9px 31px;
`;

const StyledSelfValidateButton = styled(Button)`
  padding: 9px 43px;
  margin-left: 17px;
`;

const StyledHr = styled.hr`
  border: 1px solid #e1e1e1;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const StyledPanel = styled(Panel)<{ firstSelfApprove?: boolean }>`
  i {
    left: 0 !important;
  }

  & > .ant-collapse-header {
    padding-left: ${(p): number => (p.firstSelfApprove ? 0 : 18)}px !important;
    cursor: ${(p): string => (p.firstSelfApprove ? 'default' : 'pointer')} !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  border: none !important;
`;

const StyledHeader = styled(Bold13TransparentGrey800.div)`
  margin-right: 9px;
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CancelButton = styled(Button)`
  font-family: var(--text-font-family);
  text-align: center;
  width: auto;
  height: auto;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5454;
  margin-left: 6px;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;
