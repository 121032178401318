import React, { ReactNode, useEffect } from 'react';
import Button, { ButtonProps } from '@app/components/Button';
import { ForwardingFC } from '@app/domain/technicals/components';
import useMergedRef from '@app/hooks/useMergedRef';
import Popup from '@app/components/popup/Popup';
import { PopupPlacement, PopupPlacementOffset } from '@app/components/popup/PopupBase';

export interface ButtonThatOpensPopupProps extends Omit<ButtonProps, 'onClick'> {
  // Changing this prop will cause the popup content to re-render
  popupKey?: string;
  popupPlacement?: PopupPlacement;
  popupPlacementOffset?: PopupPlacementOffset;
  preventOutsideClick?: boolean;
  popupContent(onDone: () => void): ReactNode;
  onClosed?(): void;
}

const ButtonThatOpensPopup: ForwardingFC<HTMLButtonElement, ButtonThatOpensPopupProps> = React.forwardRef((props, ref) => {
  const {
    popupContent,
    popupKey,
    popupPlacement = 'bottom',
    popupPlacementOffset,
    preventOutsideClick,
    onClosed,
    children,
    ...buttonProps
  } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const mergedRef = useMergedRef(anchorRef, ref);

  function handleToggle(event: React.MouseEvent<EventTarget>): void {
    event.preventDefault();
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  }

  useEffect(() => {
    if (!open) {
      onClosed?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onClosed can change with each render
  }, [open]);

  function handleClose(): void {
    setOpen(false);
  }

  function renderPopupContent(): ReactNode {
    return popupContent(handleClose);
  }

  return (
    <>
      <Button ref={mergedRef} onClick={handleToggle} {...buttonProps}>
        {children}
      </Button>
      <Popup
        open={open}
        key={popupKey}
        preventOutsideClick={preventOutsideClick}
        placement={popupPlacement}
        placementOffset={popupPlacementOffset}
        anchorEl={anchorRef.current}
        onPopupClosed={handleClose}
      >
        {renderPopupContent()}
      </Popup>
    </>
  );
});

export default ButtonThatOpensPopup;
