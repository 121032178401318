import styled from '@emotion/styled';

export const BigLoadingSpinner = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto;
  text-indent: -12345px;
  border-top: 1px solid rgba(15, 131, 230, 0.08);
  border-right: 1px solid rgba(15, 131, 230, 0.08);
  border-bottom: 1px solid rgba(15, 131, 230, 0.08);
  border-left: 1px solid rgba(15, 131, 230, 0.7);
  border-radius: 50%;
  z-index: 100001;
  animation: spinner 600ms infinite linear;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
