import Log from '@app/libs/logger';
import useMountEffect from './useMountEffect';

export enum InnerPage {
  welcome = '/welcome',
  loginEnterEmail = '/login/enterEmail',
  loginEnterEmailCode = '/login/enterEmailCode',
  loginEnterPhone = '/login/enterPhone',
  loginEnterPhoneCode = '/login/enterPhoneCode',
  verify = '/verifyAccount',
  enterAccount = '/enterAccount',
  verifyWithPayeeResult = '/verifyWithPayeeResult',
  verifyWithCertificateBefore = '/verifyWithCertificateBefore',
  verifyWithCertificateResult = '/verifyWithCertificateResult',
}

export default function useInnerPageEnterAnalyticsReport(innerPage: InnerPage): void {
  useMountEffect(() => {
    Log.event('innerPageEnter', { innerPage }, true);
  });
}
