import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import TransitionLoader from '@app/components/TransitionLoader';
import AuthenticatedMode from '../../AuthenticatedMode';
import useInfraStores from '@app/hooks/useInfraStores';
import SamlAuthentication from '@app/login/routes/sso/saml/SamlAuthentication';
import config from '@app/config';
import { useSingleQueryParam } from '@app/hooks/useSingleQueryParam';
import {
  AuthenticatorSSOAuthResult,
  isValidSSOProvider,
  MultipleSSOProviderType,
  multipleSSOProviderTypeToKnoxerAuthType,
} from '@app/login/domain/multipleSSO';
import SSOError from '@app/login/routes/sso/SSOError';
import Loader from '@app/components/Loader';
import { OIDC_PROVIDER_REGEX, SAML_PROVIDER_REGEX } from '@app/login/domain/sso';
import OidcAuthentication from '@app/login/routes/sso/oidc/OidcAuthentication';

const MultipleSSOAuthenticationFlow: FC = observer(() => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const [sso] = useSingleQueryParam<MultipleSSOProviderType>('sso', isValidSSOProvider);
  const [result] = useSingleQueryParam<AuthenticatorSSOAuthResult>('result', /^(success)|(error)$/);

  const [nextSSO] = useSingleQueryParam<MultipleSSOProviderType>('nextSSO', isValidSSOProvider);
  const [nextSamlProvider] = useSingleQueryParam('nextSamlProvider', SAML_PROVIDER_REGEX);
  const [nextOIDCProvider] = useSingleQueryParam('nextOIDCProvider', OIDC_PROVIDER_REGEX);

  const isBeforeFirstKnoxer = nextSSO !== null && result === null;
  const isBeforeSecondKnoxer = nextSSO !== null && result != null;
  const isFullAuthenticationSuccessful = nextSSO === null && result === 'success';

  useEffect(() => {
    if (isFullAuthenticationSuccessful) {
      authenticationStore.claimWebSessionAfterMultipleSSOAuthentication();
    }
  }, [isFullAuthenticationSuccessful, authenticationStore]);

  function getKnoxerIds(): { currentKnoxerId: string | null; nextKnoxerId: string | null } {
    let currentKnoxerIndex: number;

    if (isBeforeFirstKnoxer) {
      currentKnoxerIndex = -1;
    } else if (isBeforeSecondKnoxer) {
      currentKnoxerIndex = 0;
    } else {
      currentKnoxerIndex = 1;
    }

    const currentKnoxerId = config.knoxersAuthData[currentKnoxerIndex]?.id ?? null;
    const nextKnoxerId = config.knoxersAuthData[currentKnoxerIndex + 1]?.id ?? null;
    return { currentKnoxerId, nextKnoxerId };
  }

  const { currentKnoxerId, nextKnoxerId } = getKnoxerIds();

  useEffect(() => {
    if (result === 'success' && sso && currentKnoxerId) {
      const authType = multipleSSOProviderTypeToKnoxerAuthType(sso);
      authenticationStore.saveAuthenticatedKnoxer(currentKnoxerId, { authType });
    }
  }, [authenticationStore, currentKnoxerId, result, sso]);

  function renderSSOComponent(): ReactElement {
    if (isFullAuthenticationSuccessful) {
      return <Loader spinning />;
    }

    if (result === 'error') {
      return <SSOError sso={sso} />;
    }

    if (!nextSSO || !nextKnoxerId) {
      return <SSOError sso={null} />;
    }

    switch (nextSSO) {
      case MultipleSSOProviderType.saml: {
        return <SamlAuthentication knoxerId={nextKnoxerId} samlProviderName={nextSamlProvider} />;
      }
      case MultipleSSOProviderType.oidc: {
        return <OidcAuthentication knoxerId={nextKnoxerId} oidcProviderName={nextOIDCProvider} />;
      }
    }
  }

  return (
    <TransitionLoader loading={authenticationStore.loading}>
      <Container>{renderSSOComponent()}</Container>
    </TransitionLoader>
  );
});

export default MultipleSSOAuthenticationFlow;

const Container = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
`;
