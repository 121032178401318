import memoize from 'fast-memoize';
import { TFunction } from 'react-i18next';

import { compare } from '@app/utils/comparatorUtils';

export enum USA_STATES {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  DC = 'District of Columbia',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  NewHampshire = 'New Hampshire',
  NewJersey = 'New Jersey',
  NewMexico = 'New Mexico',
  NewYork = 'New York',
  NorthCarolina = 'North Carolina',
  NorthDakota = 'North Dakota',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Pennsylvania = 'Pennsylvania',
  RhodeIsland = 'Rhode Island',
  SouthCarolina = 'South Carolina',
  SouthDakota = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virginia = 'Virginia',
  Washington = 'Washington',
  WestVirginia = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
}

// Define a memoized function to get USA states in a alphabetically order
export const getUsaStatesOrder = memoize(
  (language?: string, translationMethod?: TFunction): string[] => {
    const states = Object.keys(USA_STATES);

    if (!language || !translationMethod) {
      // If language or translationMethod is not provided, return the states as they are
      return states;
    }

    // Sort the state names based on the translation result using a case-insensitive comparator
    return states.sort(compare.byStringField((state) => translationMethod(state)));
  },
  {
    // Serialize memoization arguments (language) to use as cache key
    serializer: ([languageParam]) => languageParam ?? '',
  },
);

export default USA_STATES;
