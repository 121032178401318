import React, { FC } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';
import NakedFormTextarea from '@app/components/inputs/NakedFormTextarea';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { SELF_APPROVE_FIELD_MAX_LENGTH } from '@mortee/domain/initiateSelfApproveFields';

export interface RemoveSelfApproveFormFields {
  note: string;
}

interface Props {
  form: WrappedFormUtils<RemoveSelfApproveFormFields>;
  showFormErrors: FormItemsBoxProps['showErrors'];
}

const PrivateAccountRemoveSelfApproveForm: FC<Props> = ({ form, showFormErrors }) => {
  const fieldDecorator: FormFieldDecorators<RemoveSelfApproveFormFields> = {
    note: {
      rules: [
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.removeSelfApprove.note,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.removeSelfApprove.note} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialCharsWithNewline,
          message: 'Invalid character',
        },
        {
          required: true,
          transform: trim,
          message: `Missing Note`,
        },
      ],
    },
  };

  return (
    <div>
      <FormItemBox
        fieldName='note'
        fieldDecoratorOptions={fieldDecorator.note}
        appearance='corners'
        showErrors={showFormErrors}
        form={form}
      >
        <NakedFormTextarea name='inpt-mdg-remove-self-note' placeholder='Add Note' minRows={3} type='text' heightType='thin' />
      </FormItemBox>
    </div>
  );
};

export default PrivateAccountRemoveSelfApproveForm;
