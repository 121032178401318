import { trim } from '@app/utils/stringUtils';
import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { CURRENCIES_KEYS } from '@app/domain/currencies';
import { WrappedFormUtils } from 'antd/lib/form/Form';

function getBankCodeFields(form: WrappedFormUtils<BaseFormFields>): SupplierRegistrationField | undefined {
  if (form.getFieldValue('currency') === CURRENCIES_KEYS.AUD) {
    return {
      id: 'inpt-supp-reg-AU-bank-code',
      inputType: 'number',
      decoratorRules: [
        {
          required: true,
          transform: trim,
          translatedMessage: `supplierValidation.supplierRegister.AU.errors.missingBankCode`,
        },
        {
          len: ACCOUNT_FIELD_MAX_LENGTH.AU.bank,
          transform: trim,
          translatedMessage: {
            key: `supplierValidation.supplierRegister.AU.errors.bankCodeLength`,
            value: ACCOUNT_FIELD_MAX_LENGTH.AU.bank,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: [
            `supplierValidation.supplierRegister.AU.errors.invalidBankCode`,
            `supplierValidation.supplierRegister.errors.invalidBankCode`,
          ],
        },
      ],
    };
  }

  return {
    id: 'inpt-supp-reg-AU-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.AU.bank,
        transform: trim,
        translatedMessage: {
          key: `supplierValidation.supplierRegister.AU.errors.bankCodeLength`,
          value: ACCOUNT_FIELD_MAX_LENGTH.AU.bank,
        },
      },
    ],
  };
}

export const AU: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
};
