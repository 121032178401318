export interface DateRange {
  start: number;
  end: number;
}

export enum YesNoFilterOption {
  yes = 'YES',
  no = 'NO',
}

export const YesNoOptions = [
  { value: YesNoFilterOption.yes, label: 'Yes' },
  { value: YesNoFilterOption.no, label: 'No' },
];
