import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Pagination } from 'antd';
import styled from '@emotion/styled';

interface Props {
  total: number;
  pageSize: number;
  currentIndex: number;
  onChange: (page: number, pageSize?: number) => void;
}

const PaginationWithIndex: FC<Props> = observer(({ total, pageSize, currentIndex, onChange }) => {
  if (!total || total <= pageSize) {
    return null;
  }

  return (
    <PaginationContainer>
      <Pagination
        total={total}
        size='small'
        current={currentIndex}
        pageSize={pageSize}
        onChange={onChange}
        showTotal={(total, range): string => `${range[0]}-${range[1]} of ${total}`}
      />
    </PaginationContainer>
  );
});

export default PaginationWithIndex;

const PaginationContainer = styled.div`
  align-self: flex-end;
  margin: 15px;
  font-size: 12px;

  .ant-pagination-item {
    border: none !important;
  }

  .ant-pagination-total-text,
  .ant-pagination-item {
    font-size: 12px;
  }
`;
