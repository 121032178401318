import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import {
  createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord,
  EvidenceType,
  StoreSupplierValidationPayeeVerificationRecordRequest,
  SupplierValidationPayeeVerificationRecord,
  SupplierValidationPayeeVerificationRecordServerResponse,
  SupplierValidationRecord,
  SupplierValidationRecordStatus,
  supplierValidationRecordSupplierVerificationAllUpdateFieldsEquators,
  SupplierValidationVerificationRecordEvidence,
  SupplierValidationVerificationSanctionsScreeningInfo,
  transformSupplierValidationPayeeVerification,
} from '@mortee/domain/validationSystem';
import { FormFieldDecorators } from '@app/utils/form/form';
import { SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import {
  createEqualityCheckFunctionCaseInsensitiveIgnoredChars,
  removeCharsAndUpperCase,
  trim,
  trimToNull,
} from '@app/utils/stringUtils';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import Collapse, { CollapsePanelStickyHeader } from '@app/components/Collapse';
import { isDefined } from '@app/utils/utils';
import useFormChangesStatus from '@app/hooks/useFormChangesStatus';
import VerificationFormCollapseHeader from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationFormCollapseHeader';
import Button from '@app/components/Button';
import {
  getTextFromSanctionStatus,
  injectTypeIntoStrongLegalIdRequest,
  SanctionsScreeningResultStatus,
} from '@app/domain/validatedPayee';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import {
  allOrNoneFieldsValidator,
  dunsValidator,
  notFutureTimestampValidator,
  onChangeValidateOtherFields,
  regularLegalIdValidator,
  taxIdValidator,
} from '@app/utils/validators';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import NakedCalendarDatePicker from '@app/components/inputs/NakedCalendarDatePicker';
import { ValidatedPayeeFormFields } from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeForm';
import { H6StartTransparentBlack800 } from '@app/components/Text';
import { cleanDunsLegalId, cleanLegalId } from '@app/utils/legalIdentifierUtils';
import LegalIdInput from '@mortee/routes/validatedPayeesManagement/LegalIdInput';
import { itemOrFirst, wrapValueAsArray } from '@app/utils/arrayUtils';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import { removeStrongIds, StrongLegalIdentifiesTypes } from '@app/domain/legalEntityIdentifier';
import validationSystemServices from '@mortee/services/validationSystemServices';
import FormItemBoxWithSuggestions from '@app/components/inputs/FormItemBoxWithSuggestions';
import useInfraStores from '@app/hooks/useInfraStores';
import COUNTRIES, { getCountryName } from '@app/domain/countries';
import Toggle from '@app/components/inputs/Toggle';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { isPastDate } from '@app/utils/timeUtils';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import {
  markFirstAsPrimary,
  VerificationLegalIdentifierRequest,
} from '@mortee/domain/validationSystemVerificationLegalIdentifiers';
import { LegalIdentifierRequest } from '@mortee/domain/vaildatedPayeeManagement';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import { toWordsOrdinal } from 'number-to-words';
import { compare } from '@app/utils/comparatorUtils';

interface CreateVerificationDataRecordPayeeFormFields {
  name: string | undefined;
  additionalName: string | undefined;
  address: string | undefined;
  additionalAddress: string | undefined;
  countryCode: string | undefined;
  duns: VerificationLegalIdentifierRequest | undefined;
  taxId1: VerificationLegalIdentifierRequest | undefined;
  taxId2: VerificationLegalIdentifierRequest | undefined;
  legalId1: VerificationLegalIdentifierRequest | undefined;
  legalId2: VerificationLegalIdentifierRequest | undefined;
  legalId3: VerificationLegalIdentifierRequest | undefined;
  sanctionsScreeningTimestamp: number | undefined;
  sanctionsStatus: SanctionsScreeningResultStatus | undefined;
  alreadyExistingValidatedPayeeKnoxId: string | undefined;
  isPrivate: boolean;
  isUpdateRequired: boolean;
  effectiveTimestamp: number | undefined;
  useExistingPayee: boolean;
}

interface Props extends FormComponentProps<CreateVerificationDataRecordPayeeFormFields> {
  verificationRecordPayeeData: SupplierValidationPayeeVerificationRecord | null;
  className?: string;
  onSaved(payeeData: SupplierValidationPayeeVerificationRecord): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean): void;
  evidenceData: SupplierValidationVerificationRecordEvidence | null | undefined;
  supplierValidationRecord: SupplierValidationRecord;
}

const legalIds: (keyof CreateVerificationDataRecordPayeeFormFields)[] = ['legalId1', 'legalId2', 'legalId3'];

const VerificationRecordPayeeForm: FC<Props> = observer((props) => {
  const {
    verificationRecordPayeeData,
    onSaved,
    onIsFormUnsavedChanged,
    className,
    evidenceData,
    supplierValidationRecord,
  } = props;
  const { validatedPayeesManagementStore } = useAppStores<MorteeMode>();
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { localeStore } = useInfraStores();
  const allLEITypesLoadable = validatedPayeesManagementStore.allLeiTypes;

  const { form, showFormErrors, setShowFormErrors, validateFields } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const useExistingPayee = form.getFieldValue('useExistingPayee');
  const payeeCountryCode = form.getFieldValue('countryCode');
  const sanctionsScreeningTimestamp = form.getFieldValue('sanctionsScreeningTimestamp');
  const isPastSanctionsDate = !!sanctionsScreeningTimestamp && isPastDate(sanctionsScreeningTimestamp);
  const isPendingApprovalAndDoesntHaveApprovalRole =
    !permissionsStore.isValidationApprover &&
    supplierValidationRecord.status.value === SupplierValidationRecordStatus.waitingForApproval;

  const resetSanctions = (): void => {
    form.setFieldsValue({
      sanctionsStatus: undefined,
      sanctionsScreeningTimestamp: undefined,
    } as Partial<ValidatedPayeeFormFields>);
  };

  function createUpdateRequestAllUpdateFieldsFromFormFields(
    formFields: CreateVerificationDataRecordPayeeFormFields,
  ): StoreSupplierValidationPayeeVerificationRecordRequest {
    const legalIdsList = [
      cleanLegalId(formFields.legalId1),
      cleanLegalId(formFields.legalId2),
      cleanLegalId(formFields.legalId3),
      ...markFirstAsPrimary([
        cleanLegalId(
          injectTypeIntoStrongLegalIdRequest<VerificationLegalIdentifierRequest>(
            allLEITypesLoadable,
            formFields.taxId1,
            StrongLegalIdentifiesTypes.TaxId,
          ),
        ),
        cleanLegalId(
          injectTypeIntoStrongLegalIdRequest<VerificationLegalIdentifierRequest>(
            allLEITypesLoadable,
            formFields.taxId2,
            StrongLegalIdentifiesTypes.TaxId,
          ),
        ),
      ]),
      cleanLegalId(
        injectTypeIntoStrongLegalIdRequest(
          allLEITypesLoadable,
          cleanDunsLegalId(formFields.duns),
          StrongLegalIdentifiesTypes.DUNS,
        ),
      ),
    ].filter(isDefined);

    const sanctionsScreeningObject: SupplierValidationVerificationSanctionsScreeningInfo = {
      result: formFields.sanctionsStatus,
      screeningTimestamp: formFields.sanctionsScreeningTimestamp,
    };

    return {
      name: trimToNull(formFields.name),
      additionalName: trimToNull(formFields.additionalName),
      address: trimToNull(formFields.address),
      additionalAddress: trimToNull(formFields.additionalAddress),
      countryCode: trimToNull(formFields.countryCode),
      legalEntityIdentifiers: legalIdsList.length ? legalIdsList : null,
      sanctionsScreening: Object.values(sanctionsScreeningObject).filter(isDefined).length ? sanctionsScreeningObject : null,
      alreadyExistingValidatedPayeeKnoxId: trimToNull(formFields.alreadyExistingValidatedPayeeKnoxId),
      isPrivate: formFields.isPrivate,
      isUpdateRequired: formFields.isUpdateRequired,
      effectiveTimestamp: formFields.effectiveTimestamp ?? null,
    };
  }

  const isNewEntity = !verificationRecordPayeeData;

  const verificationRecordPayeeBaseData = useMemo((): StoreSupplierValidationPayeeVerificationRecordRequest => {
    if (isNewEntity) {
      return {
        isPrivate: false,
        isUpdateRequired: false,
        alreadyExistingValidatedPayeeKnoxId: null,
        effectiveTimestamp: null,
        sanctionsScreening: null,
        additionalAddress: null,
        address: null,
        countryCode: null,
        additionalName: null,
        name: null,
        legalEntityIdentifiers: null,
      };
    }

    return createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord(verificationRecordPayeeData);
  }, [verificationRecordPayeeData, isNewEntity]);

  const { isFormNotInitialized, amountOfChanges, amountOfErrors, storeRequest, updateRequest } = useFormChangesStatus(
    verificationRecordPayeeBaseData,
    form,
    createUpdateRequestAllUpdateFieldsFromFormFields,
    supplierValidationRecordSupplierVerificationAllUpdateFieldsEquators,
  );

  const hasChanges = !!amountOfChanges;

  useEffect(() => {
    onIsFormUnsavedChanged(hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged can change anytime
  }, [hasChanges]);

  const handleSave = async (): Promise<void> => {
    if (isFormNotInitialized) {
      return;
    }

    try {
      await validateFields();
      setIsSaving(true);
      let payeeVerificationRecordServerResponse: SupplierValidationPayeeVerificationRecordServerResponse;

      if (isNewEntity) {
        payeeVerificationRecordServerResponse = await validationSystemServices.storeSupplierValidationPayeeVerificationRecord(
          supplierValidationRecord.staticId,
          storeRequest,
        );
      } else {
        payeeVerificationRecordServerResponse = await validationSystemServices.updateSupplierValidationPayeeVerificationRecord(
          supplierValidationRecord.staticId,
          updateRequest,
        );
      }

      setIsOpen(false);
      onSaved(transformSupplierValidationPayeeVerification(payeeVerificationRecordServerResponse));
    } catch {
      setIsOpen(true);
      await validateFields();
    } finally {
      setIsSaving(false);
    }
  };

  const taxId1 = form.getFieldValue('taxId1');
  const taxId2 = form.getFieldValue('taxId2');
  const firstEmptyLegalIdFieldIndex = legalIds.findIndex((legalId) => !form.getFieldValue(legalId));
  const firstEmptyLegalIdField = legalIds[firstEmptyLegalIdFieldIndex];

  const isCopyTextButtonVisible = (taxId: VerificationLegalIdentifierRequest | undefined): boolean => {
    if (!taxId) {
      return false;
    }

    return !!removeCharsAndUpperCase(taxId?.value, ['-', '/', ' ', '.']) && !!taxId?.countryCode && !!firstEmptyLegalIdField;
  };

  const copyBelow = (taxId: VerificationLegalIdentifierRequest | undefined): void => {
    if (!taxId) {
      return;
    }

    if (!firstEmptyLegalIdField) {
      return;
    }

    form.setFieldsValue({
      [firstEmptyLegalIdField]: { typeId: undefined, countryCode: taxId.countryCode, value: taxId.value },
    });
  };

  const calculateTooltipTitle = (): string => {
    return `Copy Tax ID to ${toWordsOrdinal(firstEmptyLegalIdFieldIndex + 1)} Regular Legal ID`;
  };

  /**
   * Creates a function to compare two legal identifier objects for equality.
   * @param charsToIgnore - An array of characters to ignore during the comparison.
   * @returns A function that checks the equality of two LegalIdentifierRequest objects.
   */
  function createLegalIdentifierEqualityFunction(
    charsToIgnore: string[],
  ): (legalId1: LegalIdentifierRequest | undefined, legalId2: LegalIdentifierRequest | undefined) => boolean {
    const valueEqualityFunction = createEqualityCheckFunctionCaseInsensitiveIgnoredChars(charsToIgnore);

    return (legalId1: LegalIdentifierRequest | undefined, legalId2: LegalIdentifierRequest | undefined) => {
      if (legalId1 === legalId2) {
        return true;
      }

      if (!legalId1 || !legalId2) {
        return false;
      }

      return (
        legalId1.countryCode === legalId2.countryCode &&
        legalId1.typeId === legalId2.typeId &&
        valueEqualityFunction(legalId1.value, legalId2.value)
      );
    };
  }

  function renderFormFields(): ReactElement {
    const fieldDecorators = createFieldDecorators(verificationRecordPayeeData, form);

    const upperCaseExceptDBAFAC = (value: string | null | undefined): string | undefined => {
      return value?.toUpperCase().replace(' DBA ', ' dba ').replace(' D.B.A ', ' d.b.a ').replace(' VIA/FAC ', ' via/fac ');
    };

    const upperCaseFieldValue = (e: React.FocusEvent<HTMLInputElement>, fieldName: string): void => {
      const finalValue = upperCaseExceptDBAFAC(e.target.value);

      form.setFieldsValue({
        [fieldName]: finalValue,
      });

      validateFields();
    };

    const charsToIgnore = ['-', '/', ' ', '.', '\\'];

    return (
      <Collapse
        activeKey={isOpen ? 'payee' : undefined}
        onChange={(keyOrKeys): void => setIsOpen(itemOrFirst(keyOrKeys) === 'payee')}
      >
        <CollapsePanelStickyHeader
          key='payee'
          forceRender
          header={
            <StickyHeaderContainer>
              <VerificationFormCollapseHeader
                dataTestId='verification-record-supplier-details-expander'
                title='SUPPLIER DETAILS'
                isNewEntity={isNewEntity}
                amountOfChanges={amountOfChanges}
                amountOfErrors={amountOfErrors}
                isLoading={isSaving}
              />
              <ActionsContainer>
                <ClickEventPropagationBlocker>
                  <Button
                    id='btn-verification-data-payee-save'
                    disabled={!hasChanges || isSaving || isPendingApprovalAndDoesntHaveApprovalRole}
                    onClick={handleSave}
                    disableAnimations
                  >
                    SAVE
                  </Button>
                </ClickEventPropagationBlocker>
              </ActionsContainer>
            </StickyHeaderContainer>
          }
        >
          <NsknoxForm
            form={form}
            showErrors={showFormErrors}
            disabled={isSaving || isPendingApprovalAndDoesntHaveApprovalRole}
            className={className}
            setShowErrors={setShowFormErrors}
          >
            <FieldsTitle>Payee Source</FieldsTitle>
            <FormItemBox appearance='none' fieldName='useExistingPayee' fieldDecoratorOptions={fieldDecorators.useExistingPayee}>
              <Toggle
                accessibilityLabel='Toggle new or existing payee'
                name='toggle-verification-data-payee-use-existing-payee'
                label='Use existing payee'
                onChange={(): void => {
                  setTimeout(() => {
                    form.setFieldsValue({
                      isUpdateRequired: false,
                      alreadyExistingValidatedPayeeKnoxId: undefined,
                    });
                    form.validateFields(['useExistingPayee', 'isUpdateRequired', 'alreadyExistingValidatedPayeeKnoxId']);
                  });
                }}
              />
            </FormItemBox>
            <InputGrid>
              <TransparentFormItemBox
                shouldBeHidden={!useExistingPayee}
                showErrors={useExistingPayee ? 'all' : undefined}
                fieldName='alreadyExistingValidatedPayeeKnoxId'
                fieldDecoratorOptions={fieldDecorators.alreadyExistingValidatedPayeeKnoxId}
                disabled={!useExistingPayee}
              >
                <NakedFormInput
                  name='inpt-verification-data-payee-existing-knox-id'
                  dataTestId='inpt-verification-data-payee-existing-knox-id'
                  type='text'
                  placeholder='Existing Knox Id'
                />
              </TransparentFormItemBox>
              <TransparentFormItemBox
                shouldBeHidden={!useExistingPayee}
                showErrors={useExistingPayee ? 'all' : undefined}
                appearance='none'
                fieldName='isUpdateRequired'
                fieldDecoratorOptions={fieldDecorators.isUpdateRequired}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id='checkbox-verification-data-payee-update-required'
                      data-testid='checkbox-verification-data-payee-update-required'
                      disabled={!useExistingPayee || isPendingApprovalAndDoesntHaveApprovalRole}
                    />
                  }
                  label='Update Required'
                />
              </TransparentFormItemBox>
            </InputGrid>
            {!useExistingPayee && <FieldsTitle>Visibility</FieldsTitle>}
            <TransparentFormItemBox
              shouldBeHidden={!!useExistingPayee}
              appearance='none'
              fieldName='isPrivate'
              fieldDecoratorOptions={fieldDecorators.isPrivate}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id='checkbox-verification-data-payee-private-payee'
                    data-testid='checkbox-verification-data-payee-private-payee'
                    disabled={isPendingApprovalAndDoesntHaveApprovalRole}
                  />
                }
                label={useExistingPayee ? 'Set new accounts as private' : 'Set payee and accounts as private'}
              />
            </TransparentFormItemBox>
            <FieldsTitle>General Info</FieldsTitle>
            <InputGrid>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-name-suggestion'
                fieldName='name'
                fieldDecoratorOptions={fieldDecorators.name}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(supplierValidationRecord.supplierRegistrationProcess?.companyName),
                    displayValue: supplierValidationRecord.supplierRegistrationProcess?.companyName,
                  },
                  {
                    name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                    value: upperCaseExceptDBAFAC(evidenceData?.data.payeeName),
                    displayValue: evidenceData?.data.payeeName,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'name')}
                  name='inpt-verification-data-payee-name'
                  dataTestId='inpt-verification-data-payee-name'
                  type='text'
                  placeholder='Name'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-additional-name-suggestion'
                fieldName='additionalName'
                fieldDecoratorOptions={fieldDecorators.additionalName}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(supplierValidationRecord.supplierRegistrationProcess?.additionalCompanyName),
                    displayValue: supplierValidationRecord.supplierRegistrationProcess?.additionalCompanyName,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'additionalName')}
                  name='inpt-verification-data-payee-additional-name'
                  dataTestId='inpt-verification-data-payee-additional-name'
                  type='text'
                  placeholder='Additional Name'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-address-suggestion'
                fieldName='address'
                fieldDecoratorOptions={fieldDecorators.address}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                        supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                    ),
                    displayValue:
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                      supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                  },
                  {
                    name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                    value: upperCaseExceptDBAFAC(evidenceData?.data.address),
                    displayValue: evidenceData?.data.address,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'address')}
                  name='inpt-verification-data-payee-address'
                  dataTestId='inpt-verification-data-payee-address'
                  type='text'
                  placeholder='Address'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-address-additional-suggestion'
                fieldName='additionalAddress'
                fieldDecoratorOptions={fieldDecorators.additionalAddress}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                        supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                    ),
                    displayValue:
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                      supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'additionalAddress')}
                  name='inpt-verification-data-payee-additional-address'
                  dataTestId='inpt-verification-data-payee-additional-address'
                  type='text'
                  placeholder='Additional Address'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-country-suggestion'
                fieldName='countryCode'
                fieldDecoratorOptions={fieldDecorators.countryCode}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: supplierValidationRecord.supplierRegistrationProcess?.countryCode,
                    displayValue: getCountryName(supplierValidationRecord.supplierRegistrationProcess?.countryCode),
                  },
                ]}
              >
                <CountryDropdown
                  accessibilityLabel='Country code dropdown'
                  name='drp-verification-data-payee-countryCode'
                  dataTestId='drp-verification-data-payee-countryCode'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-effective-date-timestamp'
                fieldName='effectiveTimestamp'
                fieldDecoratorOptions={fieldDecorators.effectiveTimestamp}
                suggestions={[
                  {
                    name: 'Evidence',
                    value: evidenceData?.effectiveDate,
                    displayValue: evidenceData?.effectiveDate ? localeStore.formatDate(evidenceData.effectiveDate) : undefined,
                  },
                ]}
              >
                <NakedCalendarDatePicker
                  placeholder='Effective Date'
                  id='date-picker-verification-data-payee-effective-date-timestamp'
                  accessibilityLabel='Effective Date'
                  disabledDate={(date): boolean => date?.isAfter() ?? false}
                />
              </FormItemBoxWithSuggestions>
            </InputGrid>
            <FieldsTitle>Strong Legal Ids</FieldsTitle>
            <FormItemBox fieldName='duns' fieldDecoratorOptions={fieldDecorators.duns}>
              <LegalIdInput
                accessibilityLabel='Duns legal entity id'
                name='verification-data-payee-duns'
                showCountry={false}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.DUNS))}
                forceUpperCaseValue
              />
            </FormItemBox>
            <Row>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-taxId1-suggestion'
                fieldName='taxId1'
                fieldDecoratorOptions={fieldDecorators.taxId1}
                equalityCheck={createLegalIdentifierEqualityFunction(charsToIgnore)}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: supplierValidationRecord.supplierRegistrationProcess?.legalId
                      ? {
                          typeId: allLEITypesLoadable.result?.strongTypes.TaxId?.id ?? null,
                          countryCode: supplierValidationRecord.supplierRegistrationProcess?.countryCode ?? null,
                          value: removeCharsAndUpperCase(
                            supplierValidationRecord.supplierRegistrationProcess?.legalId,
                            charsToIgnore,
                          ),
                        }
                      : undefined,
                    displayValue: `${supplierValidationRecord.supplierRegistrationProcess?.legalId} - ${
                      supplierValidationRecord.supplierRegistrationProcess?.legalIdType
                    } (${getCountryName(supplierValidationRecord.supplierRegistrationProcess?.countryCode)})`,
                  },
                  {
                    name: 'Evidence',
                    value: evidenceData?.taxId
                      ? {
                          typeId: allLEITypesLoadable.result?.strongTypes.TaxId?.id ?? null,
                          countryCode: evidenceData?.taxIdCountryCode ?? null,
                          value: removeCharsAndUpperCase(evidenceData?.taxId, charsToIgnore),
                        }
                      : undefined,
                    displayValue: `${evidenceData?.taxId} - ${evidenceData?.taxIdType ?? 'NA'}${
                      evidenceData?.taxIdCountryCode ? ` (${COUNTRIES[evidenceData.taxIdCountryCode]?.name})` : ''
                    }`,
                  },
                ]}
              >
                <LegalIdInput
                  accessibilityLabel='First tax id'
                  name='verification-data-payee-tax-id-1'
                  showCountry
                  currentPayeeCountryCode={payeeCountryCode}
                  legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.TaxId))}
                  forceUpperCaseValue
                />
              </FormItemBoxWithSuggestions>
              <ConditionalTooltip showTooltip={isCopyTextButtonVisible(taxId1)} title={calculateTooltipTitle()} placement='top'>
                <CopyBelowButton
                  appearance='text'
                  id='btn-verification-data-payee-tax-id-1-copy-below'
                  onClick={(): void => copyBelow(taxId1)}
                  hidden={!isCopyTextButtonVisible}
                >
                  ⬇
                </CopyBelowButton>
              </ConditionalTooltip>
            </Row>
            <Row>
              <FullWidth>
                <FormItemBox fieldName='taxId2' fieldDecoratorOptions={fieldDecorators.taxId2}>
                  <LegalIdInput
                    accessibilityLabel='second tax id'
                    name='verification-data-payee-tax-id-2'
                    showCountry
                    currentPayeeCountryCode={payeeCountryCode}
                    legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.TaxId))}
                    forceUpperCaseValue
                  />
                </FormItemBox>
              </FullWidth>
              <ConditionalTooltip showTooltip={isCopyTextButtonVisible(taxId2)} title={calculateTooltipTitle()} placement='top'>
                <CopyBelowButton
                  appearance='text'
                  id='btn-verification-data-payee-tax-id-2-copy-below'
                  onClick={(): void => copyBelow(taxId2)}
                  hidden={!isCopyTextButtonVisible}
                >
                  ⬇
                </CopyBelowButton>
              </ConditionalTooltip>
            </Row>
            <FieldsTitle>Regular Legal Ids</FieldsTitle>
            <FormItemBox fieldName='legalId1' fieldDecoratorOptions={fieldDecorators.legalId1}>
              <LegalIdInput
                accessibilityLabel='first legal entity id'
                name='verification-data-payee-legal-id-1'
                showCountry
                currentPayeeCountryCode={payeeCountryCode}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
                forceUpperCaseValue
              />
            </FormItemBox>
            <FormItemBox fieldName='legalId2' fieldDecoratorOptions={fieldDecorators.legalId2}>
              <LegalIdInput
                accessibilityLabel='second legal entity id'
                name='verification-data-payee-legal-id-2'
                showCountry
                currentPayeeCountryCode={payeeCountryCode}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
                forceUpperCaseValue
              />
            </FormItemBox>
            <FormItemBox fieldName='legalId3' fieldDecoratorOptions={fieldDecorators.legalId3}>
              <LegalIdInput
                accessibilityLabel='third legal entity id'
                name='verification-data-payee-legal-id-3'
                showCountry
                currentPayeeCountryCode={payeeCountryCode}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
                forceUpperCaseValue
              />
            </FormItemBox>
            <TitleContainer>
              <FieldsTitle>Sanctions Screening</FieldsTitle>
              <ResetButton
                appearance='text'
                id='verification-data-payee-reset-sanctions-fields'
                onClick={resetSanctions}
                disabled={isPendingApprovalAndDoesntHaveApprovalRole}
              >
                Reset Sanctions
              </ResetButton>
            </TitleContainer>
            <InputGrid>
              <SanctionsScreeningTimestampDiv>
                <FormItemBox
                  fieldName='sanctionsScreeningTimestamp'
                  fieldDecoratorOptions={fieldDecorators.sanctionsScreeningTimestamp}
                >
                  <NakedCalendarDatePicker
                    id='date-picker-sanctions-date'
                    placeholder='Sanctions Screening Date'
                    accessibilityLabel='Sanctions Screening Date'
                    disabledDate={(date): boolean => date?.isAfter() ?? false}
                  />
                </FormItemBox>
                {isPastSanctionsDate && <DateWarning>Note the date provided is not the current date</DateWarning>}
              </SanctionsScreeningTimestampDiv>
              <FormItemBox appearance='none' fieldName='sanctionsStatus' fieldDecoratorOptions={fieldDecorators.sanctionsStatus}>
                <FormRadioGroup
                  id='radio-group-sanctions-status'
                  dataTestId='radio-group-sanctions-status'
                  accessibilityLabel='Sanctions Screening Result Status'
                  options={[
                    {
                      value: SanctionsScreeningResultStatus.Passed,
                      label: getTextFromSanctionStatus(SanctionsScreeningResultStatus.Passed),
                    },
                    {
                      value: SanctionsScreeningResultStatus.Rejected,
                      label: getTextFromSanctionStatus(SanctionsScreeningResultStatus.Rejected),
                    },
                  ]}
                />
              </FormItemBox>
            </InputGrid>
          </NsknoxForm>
        </CollapsePanelStickyHeader>
      </Collapse>
    );
  }

  return renderFormFields();
});

export default Form.create<Props>()(VerificationRecordPayeeForm);

function createFieldDecorators(
  verificationRecordPayeeData: SupplierValidationPayeeVerificationRecord | null,
  form: WrappedFormUtils<CreateVerificationDataRecordPayeeFormFields>,
): FormFieldDecorators<CreateVerificationDataRecordPayeeFormFields> {
  const allLegalIdentifiers = verificationRecordPayeeData?.legalEntityIdentifiers || [];
  const strongLegalIdentifiers = verificationRecordPayeeData?.strongLegalIdentifies || {};
  const weakLegalIdentifies = removeStrongIds(allLegalIdentifiers, Object.values(strongLegalIdentifiers).flat());

  const [payeeTaxId1, payeeTaxId2] = [...(strongLegalIdentifiers.TaxId || [])].sort(
    compare.byField((lei) => lei.isPrimary, compare.booleans()),
  );

  const payeeDunsId = strongLegalIdentifiers.DUNS?.[0];

  return {
    name: {
      initialValue: verificationRecordPayeeData?.name ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.name,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.name} characters`,
        },
      ],
    },
    additionalName: {
      initialValue: verificationRecordPayeeData?.additionalName ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalName,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalName} characters`,
        },
      ],
    },
    address: {
      initialValue: verificationRecordPayeeData?.address ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.address,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.address} characters`,
        },
      ],
    },
    additionalAddress: {
      initialValue: verificationRecordPayeeData?.additionalAddress ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalAddress,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalAddress} characters`,
        },
      ],
    },
    countryCode: {
      initialValue: verificationRecordPayeeData?.countryCode ?? undefined,
    },
    sanctionsScreeningTimestamp: {
      initialValue: verificationRecordPayeeData?.sanctionsScreening?.screeningTimestamp,
      rules: [
        {
          type: 'number',
        },
        {
          validator: allOrNoneFieldsValidator(form, ['sanctionsStatus'], 'Missing Sanctions Date'),
        },
        {
          validator: notFutureTimestampValidator(),
        },
      ],
    },
    sanctionsStatus: {
      initialValue: verificationRecordPayeeData?.sanctionsScreening?.result,
      rules: [
        {
          validator: allOrNoneFieldsValidator(form, ['sanctionsScreeningTimestamp'], 'Missing Sanctions Status'),
        },
      ],
    },
    alreadyExistingValidatedPayeeKnoxId: {
      initialValue: verificationRecordPayeeData?.alreadyExistingValidatedPayee?.knoxId ?? undefined,
      rules: [
        {
          required: form.getFieldValue('useExistingPayee'),
          message: 'Please enter payee Knox ID',
        },
        {
          pattern: VALIDATION_PATTERNS.fullKnoxId,
          transform: trim,
          message: 'Invalid Knox Id',
        },
      ],
    },
    useExistingPayee: {
      initialValue: !!verificationRecordPayeeData?.alreadyExistingValidatedPayee,
      rules: [
        {
          validator: onChangeValidateOtherFields(form, ['alreadyExistingValidatedPayeeKnoxId']),
        },
      ],
    },
    isPrivate: {
      valuePropName: 'checked',
      initialValue: verificationRecordPayeeData?.isPrivate ?? false,
    },
    isUpdateRequired: {
      valuePropName: 'checked',
      initialValue: verificationRecordPayeeData?.isUpdateRequired ?? false,
    },
    effectiveTimestamp: {
      initialValue: verificationRecordPayeeData?.effectiveTimestamp ?? undefined,
      rules: [
        {
          type: 'number',
        },
        {
          validator: notFutureTimestampValidator(),
        },
      ],
    },
    taxId1: {
      initialValue: payeeTaxId1,
      rules: [
        {
          transform: cleanLegalId,
          validator: taxIdValidator,
        },
      ],
    },
    taxId2: {
      initialValue: payeeTaxId2,
      rules: [
        {
          transform: cleanLegalId,
          validator: taxIdValidator,
        },
      ],
    },
    legalId1: {
      initialValue: weakLegalIdentifies?.[0],
      rules: [
        {
          transform: cleanLegalId,
          validator: regularLegalIdValidator,
        },
      ],
    },
    legalId2: {
      initialValue: weakLegalIdentifies?.[1],
      rules: [
        {
          transform: cleanLegalId,
          validator: regularLegalIdValidator,
        },
      ],
    },
    legalId3: {
      initialValue: weakLegalIdentifies?.[2],
      rules: [
        {
          transform: cleanLegalId,
          validator: regularLegalIdValidator,
        },
      ],
    },
    duns: {
      initialValue: payeeDunsId,
      rules: [
        {
          transform: cleanDunsLegalId,
          validator: dunsValidator,
        },
      ],
    },
  };
}

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SanctionsScreeningTimestampDiv = styled.div`
  position: relative;
`;

const DateWarning = styled.div`
  margin-top: -17px;
  position: absolute;
  color: var(--accent-orange-400);
`;

const ResetButton = styled(Button)`
  margin-top: -10px;
`;

const FieldsTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

const StickyHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TransparentFormItemBox = styled(FormItemBox)<{ shouldBeHidden: boolean }>`
  ${(p): string => (p.shouldBeHidden ? 'display: none;' : '')}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const CopyBelowButton = styled(Button)<{ hidden: boolean }>`
  ${(p): string => (p.hidden ? 'display: none;' : '')}
  padding: 10px 12px;
  margin-right: 20px;
  position: absolute;
  right: 55px;
  z-index: 1;
  font-size: 22px;
  font-weight: bold;
`;

const FullWidth = styled.div`
  width: 100%;
`;
