import React, { FC, ReactElement } from 'react';
import { CancelReason } from '@mortee/domain/validationSystem';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import BigModal from '@app/components/BigModal';
import { BodySmallStartTransparentBlack600, Bold18TransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import Button from '@app/components/Button';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import TransitionLoader from '@app/components/TransitionLoader';

interface Props extends FormComponentProps<CancelReasonFields> {
  initialValue: CancelReason | undefined;
  className?: string;
  onSelect(newValue: CancelReason): void;
  onNotSelect(): void;
}

interface CancelReasonFields {
  cancelReasonId: string;
}

const CancelReasonSelector: FC<Props> = observer((props) => {
  const { validationSystemStore } = useAppStores<MorteeMode>();

  const { initialValue, onSelect, onNotSelect, className } = props;
  const { form, validateFields, showFormErrors, setShowFormErrors } = useForm(props);

  const cancelReasons = validationSystemStore.cancelReasons;

  async function handleSubmit(): Promise<void> {
    const { cancelReasonId } = await validateFields();
    const selectedReason = cancelReasons.result?.find((reason) => cancelReasonId === reason.id);
    selectedReason && onSelect(selectedReason);
  }

  const fieldDecorators = createFieldDecorators(initialValue);
  return (
    <ClickEventPropagationBlocker className={className}>
      <NsknoxForm
        form={form}
        disabled={cancelReasons.isNotSettled()}
        showErrors={showFormErrors}
        setShowErrors={setShowFormErrors}
      >
        <SelectReasonModal
          closeFunc={onNotSelect}
          title={<Bold18TransparentBlack900.div>Cancel record</Bold18TransparentBlack900.div>}
          dataTestId='cancel-reason-modal'
        >
          <SelectExplanationText>Choose one of the following reasons for cancellation:</SelectExplanationText>
          <TransitionLoader loading={cancelReasons.isInProgress()}>
            <FormItemBox fieldName='cancelReasonId' fieldDecoratorOptions={fieldDecorators.cancelReasonId}>
              <NakedDropdown
                name='drp-select-cancel-reason'
                dataTestId='drp-select-cancel-reason'
                placeholder='Cancellation Reason'
                isSearchable
              >
                {
                  cancelReasons.result?.map((option) => (
                    <DropdownItem key={option.id} value={option.id} textWhenSelected={option.reason}>
                      {option.reason}
                    </DropdownItem>
                  )) as ReactElement[]
                }
              </NakedDropdown>
            </FormItemBox>
            <FormActions>
              <Button
                id='btn-cancel-reason-update-status'
                onClick={(): void => {
                  handleSubmit();
                }}
                colorScheme='primary'
                disabled={cancelReasons.isNotSettled()}
              >
                UPDATE STATUS
              </Button>
            </FormActions>
          </TransitionLoader>
        </SelectReasonModal>
      </NsknoxForm>
    </ClickEventPropagationBlocker>
  );
});

function createFieldDecorators(initialReason: CancelReason | undefined): FormFieldDecorators<CancelReasonFields> {
  return {
    cancelReasonId: {
      initialValue: initialReason?.id,
      rules: [
        {
          required: true,
          message: 'Select cancel reason',
        },
      ],
    },
  };
}

export default Form.create<Props>()(CancelReasonSelector);

const SelectReasonModal = styled(BigModal)`
  padding: 16px;
  width: 500px;
  box-shadow: var(--box-shadow-3);

  --block-padding: 0;
  --side-padding: 0;
`;

const SelectExplanationText = styled(BodySmallStartTransparentBlack600.div)`
  margin-bottom: 8px;
`;

const FormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
