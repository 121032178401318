import React, { FunctionComponent, useContext } from 'react';
import styled from '@emotion/styled';
import FormInputsContext, { InputGroupState } from '../FormInputsContext';
import { ColorSchemeObject } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';
import { InputBoxBaseProps } from '@app/components/inputs/inputBox/base';

const LineInputBox: FunctionComponent<InputBoxBaseProps> = (props) => {
  const { colorScheme: propColorScheme, inputState: propInputState, className, children } = props;

  const { state: contextInputState } = useContext(FormInputsContext);
  const state = propInputState || contextInputState;

  const colorScheme = useColorScheme(propColorScheme);

  const disabled = state === 'disabled';

  return (
    <Container colorScheme={colorScheme} inputState={state} isDisabled={disabled} className={className}>
      {children}
    </Container>
  );
};

export default LineInputBox;

const Container = styled.div<{ colorScheme: ColorSchemeObject; inputState: InputGroupState; isDisabled: boolean }>`
  --input-active-color: ${(p): string => {
    if (p.inputState === 'error') {
      return 'var(--alert-color)';
    }

    if (p.isDisabled) {
      return 'var(--disabled-text)';
    }

    if (p.inputState === 'empty' || p.inputState === 'filled') {
      return '#000000';
    }

    return p.colorScheme.main;
  }};

  border-radius: 4px;
  border: 1px solid var(--input-active-color);
  ${(p): string => (p.isDisabled ? 'background-color: var(--disabled-background)' : '')};
  transition: border 0.2s ease-in-out;

  &:hover {
    --input-hover-color: ${(p): string => {
      if (p.inputState === 'error') {
        return 'var(--alert-color)';
      }

      if (p.isDisabled) {
        return 'var(--disabled-text)';
      }

      return 'var(--transparent-black-400)';
    }};

    border: 1px solid var(--input-hover-color);
  }

  &:focus-within {
    border: 1px solid
      ${(p): string => {
        if (p.inputState === 'error') {
          return 'var(--alert-color)';
        }

        return p.colorScheme.main;
      }};
  }
`;
