import { computed, makeObservable, observable } from 'mobx';
import Loadable, { lazyLoadable, LoadableCreator } from '@app/utils/Loadable';
import { getAllWebAppRoleTypes } from '@backee/services/roleTypesService';
import { UserRoleType } from '@app/domain/userManagement/roleTypes';

export default class UserRoleStore {
  @observable private _userRoleTypes: Loadable<UserRoleType[]> = LoadableCreator.notStarted();

  constructor() {
    makeObservable(this);
  }

  @computed
  get userRoleTypes(): Loadable<UserRoleType[]> {
    return lazyLoadable(
      () => this._userRoleTypes,
      (newValue) => (this._userRoleTypes = newValue),
      async () => {
        const allRoleTypes = await getAllWebAppRoleTypes();
        return allRoleTypes.sort((serviceType1, serviceType2) => {
          return serviceType1.id.localeCompare(serviceType2.id);
        });
      },
    );
  }
}
