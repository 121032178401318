import React, { FC } from 'react';
import FormItemBox, { FormItemBoxProps } from '@app/components/inputs/FormItemBox';
import styled from '@emotion/styled';
import PrefilledInformationByCustomerTooltip from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/PrefilledInformationByCustomerTooltip';

interface Props extends Omit<FormItemBoxProps, 'sideContent'> {
  hasPrefilledData: boolean;
}

const SupplierRegistrationFormItemBox: FC<Props> = ({ hasPrefilledData, disabled, ...formItemBoxProps }) => {
  return (
    <FormItemBox
      {...formItemBoxProps}
      disabled={hasPrefilledData || disabled}
      sideContent={hasPrefilledData ? <StyledPrefilledInformationByCustomerTooltip /> : undefined}
    />
  );
};

export default SupplierRegistrationFormItemBox;

const StyledPrefilledInformationByCustomerTooltip = styled(PrefilledInformationByCustomerTooltip)`
  align-self: center;
  margin-inline-end: 12px;
`;
