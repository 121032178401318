import PermissionsStore from '@app/stores/PermissionsStore';
import { computed, makeObservable } from 'mobx';
import { UserRolesType } from '@app/domain/user';
import UserStore from '@app/stores/UserStore';

export default class BackeePermissionStore extends PermissionsStore {
  constructor(allSections: Section[], userStore: UserStore) {
    super(allSections, userStore);

    makeObservable(this);
  }

  @computed
  get isViewAllowed(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.backeeViewRole]);
  }

  @computed
  get isEditAllowed(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.backeeEditRole]);
  }

  @computed
  get isCreateOrganizationAllowed(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.backeeCreateOrganizationRole]);
  }

  @computed
  get isCreateUserAllowed(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.backeeCreateUserRole]);
  }

  @computed
  get isEditWebAllowed(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.backeeEditRole]);
  }
}
