import BackArrowSvg from '@app/images/arrows/left-arrow.svg';
import React, { FC, ReactElement } from 'react';
import { LocaleAwareSVG } from '@app/components/LocaleAwareComponents';
import styled from '@emotion/styled';

interface ArrowBulletProps {
  className?: string;
}

const BackArrow: FC<ArrowBulletProps> = ({ className }): ReactElement => {
  return <StyledLocaleAwareSVG accessibilityLabel='' image={BackArrowSvg} className={className} viewBox='0 0 6 10' />;
};

export default BackArrow;

const StyledLocaleAwareSVG = styled(LocaleAwareSVG)`
  fill: var(--action-color);
`;
