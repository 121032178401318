import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useMountEffect from '@app/hooks/useMountEffect';
import Loader from '@app/components/Loader';
import SSOError from '@app/login/routes/sso/SSOError';
import { MultipleSSOProviderType } from '@app/login/domain/multipleSSO';

interface Props {
  samlProviderName: string | null;
  knoxerId: string;
}

const SamlAuthentication: FC<Props> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const { knoxerId, samlProviderName } = props;

  useMountEffect(() => {
    if (samlProviderName != null) {
      authenticationStore.redirectToPageForSsoSamlAuthentication(knoxerId, samlProviderName);
    }
  });

  if (samlProviderName == null) {
    return <SSOError sso={MultipleSSOProviderType.saml} />;
  }

  return <Loader spinning fullScreen />;
});

export default SamlAuthentication;
