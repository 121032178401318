import React, { FC } from 'react';
import styled from '@emotion/styled';
import config from '../config';

interface Props {
  className?: string;
}

const Footer: FC<Props> = ({ className }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Container className={className}>
      © {currentYear} nsKnox Technologies v{config.version}
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  padding: 68px 50px 0;
  text-align: center;
  font-size: 13px;
  color: rgba(61, 68, 90, 0.8);
  background-color: transparent;
  font-weight: 200;
`;
