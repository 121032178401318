import React, { FunctionComponent, useRef } from 'react';
import styled from '@emotion/styled';
import Bullet from '@app/images/arrows/arrow-bullet-orange.svg';
import SVG from '@app/components/SVG';
import { nanoid } from 'nanoid';

interface ModalErrorsListProps {
  errors: string[];
  title?: string;
}

const ModalErrorsList: FunctionComponent<ModalErrorsListProps> = (props) => {
  const { errors, title } = props;

  const randomIdRef = useRef(nanoid());
  const messageId = randomIdRef.current;

  const titleId = `alert-title-${messageId}`;
  const contentId = `alert-description-${messageId}`;

  return (
    <ModalContainer id={messageId} aria-labelledby={titleId} aria-describedby={contentId}>
      <ModalTitle id={titleId}>{title ? title : 'Something went wrong'}</ModalTitle>
      <StyledContainer id={contentId}>
        {errors.map((error, index) => (
          <RowContainer key={`error-${index}`}>
            <StyledSVG accessibilityLabel='' image={Bullet} height={18} />
            <RowText>{error}</RowText>
          </RowContainer>
        ))}
      </StyledContainer>
    </ModalContainer>
  );
};

export default ModalErrorsList;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 400px;
`;

const ModalTitle = styled.span`
  font-size: 22px;
  color: var(--gray-blue-deprecated);
  margin-bottom: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-around;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const RowText = styled.span`
  display: flex;
`;

const StyledSVG = styled(SVG)`
  min-width: 18px;
  margin-top: 1px;
`;
