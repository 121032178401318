export enum FilesUploadMode {
  vendor = 'vendor',
  payments = 'payments',
  secureFiles = 'secureFiles',
}

export interface FilesUploadModeNamingTypes {
  single: string;
  multiple: string;
}

export const FilesUploadModeNames: { [fileMode in FilesUploadMode]: FilesUploadModeNamingTypes } = {
  [FilesUploadMode.vendor]: { single: 'Master Vendor File (MVF)', multiple: 'Master Vendor Files (MVF)' },
  [FilesUploadMode.payments]: { single: 'Payment File', multiple: 'Payment Files' },
  [FilesUploadMode.secureFiles]: { single: 'File', multiple: 'Files' },
};
