import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import { Form } from 'antd';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { FormComponentProps } from 'antd/es/form/Form';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import organizationsService from '@backee/services/organizationsServices';
import useForm from '@app/hooks/useForm';
import { ActionsList, StyledBackeeCard, StyledBackeeForm, SubmitButton } from '@backee/styles/styles';
import { shoot } from '@app/utils/messageLauncher';
import useIsMounted from '@app/hooks/useIsMounted';
import useInfraStores from '@app/hooks/useInfraStores';
import BackeeMode from '@backee/backeeMode';
import browserHistory from '@app/utils/browserHistory';
import {
  ORGANIZATION_FIELD_MAX_LENGTH,
  StoreKnoxerIdentityProviderForOrganization,
  StoreOrganizationRequest,
} from '@app/domain/userManagement/organizations';
import PageHeader from '@app/components/header/PageHeader';
import { confirmAsync, ModalTitle } from '@app/components/Modal';
import SSOProviderSelect from '@backee/routes/organizations/createOrganizationPage/SSOProviderSelect';
import useAppStores from '@app/hooks/useAppStores';
import styled from '@emotion/styled';
import Loader from '@app/components/Loader';
import InnerCard from '@app/components/card/InnerCard';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import { H6StartTransparentBlack800 } from '@app/components/Text';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';

interface CreateOrganizationFormFields {
  name: string;
  emailKnoxerIdentityProviderNames?: string[];
  phoneKnoxerIdentityProviderNames?: string[];
}

interface Props extends FormComponentProps<CreateOrganizationFormFields> {}

const CreateOrganizationPage: FC<Props> = observer((props) => {
  const isMountedRef = useIsMounted();
  const { navigationStore } = useInfraStores<BackeeMode>();
  const { form, isFormInvalid, showFormErrors, setShowFormErrors } = useForm(props);
  const { knoxersStore, identityProvidersStore } = useAppStores<BackeeMode>();
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  if (!knoxersStore.knoxers.isResolved()) {
    return <Loader spinning />;
  }

  const knoxers = knoxersStore.knoxers.result;

  const identityProvidersForEmailKnoxer = identityProvidersStore
    .getAllIdentityProvidersForKnoxer(knoxers.emailKnoxer.id)
    .map((identityProviders) => identityProviders.map((identityProvider) => identityProvider.name));

  const identityProvidersForPhoneKnoxer = identityProvidersStore
    .getAllIdentityProvidersForKnoxer(knoxers.phoneKnoxer.id)
    .map((identityProviders) => identityProviders.map((identityProvider) => identityProvider.name));

  const submitDisableClick = (): void => {
    setShowFormErrors('all');
    form.validateFields();
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Record<string, unknown>, values: CreateOrganizationFormFields) => {
      if (errors) {
        return;
      }

      createOrganization(values);
    });
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      await handleOk();
    }
  };

  const collectDataFromForm = (formFields: CreateOrganizationFormFields): StoreOrganizationRequest => {
    const knoxerIdentityProviders: StoreKnoxerIdentityProviderForOrganization[] = [
      {
        knoxerId: knoxers.emailKnoxer.id,
        identityProviderNames: formFields.emailKnoxerIdentityProviderNames ?? [],
      },
      {
        knoxerId: knoxers.phoneKnoxer.id,
        identityProviderNames: formFields.phoneKnoxerIdentityProviderNames ?? [],
      },
    ].filter((knoxerIdentityProviders) => knoxerIdentityProviders.identityProviderNames?.length);

    return {
      name: formFields.name,
      knoxerIdentityProviders,
      serviceIds: [],
    };
  };

  const renderConfirmationModal = (formFields: CreateOrganizationFormFields): ReactElement => {
    const { name, emailKnoxerIdentityProviderNames, phoneKnoxerIdentityProviderNames } = formFields;

    return (
      <ModalContainer data-testid='create-organization-confirmation-modal'>
        <ModalTitle>Save Organization?</ModalTitle>
        <OrganizationCard>
          <H6StartTransparentBlack800.div>{name}</H6StartTransparentBlack800.div>
          <DataGrid>
            {!!emailKnoxerIdentityProviderNames?.length && (
              <DataGridRow
                title={`${knoxers.emailKnoxer.name} Knoxer Identity Providers`}
                valueDataTest='email-knoxer-identity-providers'
              >
                {emailKnoxerIdentityProviderNames.map((identityProvider) => (
                  <div key={identityProvider}>{identityProvider}</div>
                ))}
              </DataGridRow>
            )}
            {!!phoneKnoxerIdentityProviderNames?.length && (
              <DataGridRow
                title={`${knoxers.phoneKnoxer.name} Knoxer Identity Providers`}
                valueDataTest='phone-knoxer-identity-providers'
              >
                {phoneKnoxerIdentityProviderNames.map((identityProvider) => (
                  <div key={identityProvider}>{identityProvider}</div>
                ))}
              </DataGridRow>
            )}
          </DataGrid>
        </OrganizationCard>
      </ModalContainer>
    );
  };

  const createOrganization = async (formFields: CreateOrganizationFormFields): Promise<void> => {
    setSavingInProgress(true);

    try {
      const shouldSave = await confirmAsync({
        content: renderConfirmationModal(formFields),
        okText: 'YES',
        cancelText: 'NO',
        icon: <span />,
        maskClosable: false,
        width: 500,
      });

      if (!shouldSave) {
        return;
      }

      const collectedData = collectDataFromForm(formFields);
      const result = await organizationsService.storeOrganization(collectedData);

      if (isMountedRef.current) {
        browserHistory.push(navigationStore.generateManageOrganizationLink(result.id));
      }

      shoot({ type: 'success', closeable: true }, `Organization "${result.name}" created`);
    } finally {
      if (isMountedRef.current) {
        setSavingInProgress(false);
      }
    }
  };

  return (
    <PageWithHeader width='full' header={<PageHeader backButton>Create Organization</PageHeader>}>
      <StyledBackeeForm
        form={form}
        appearance='corners'
        showErrors={showFormErrors}
        disabled={savingInProgress}
        setShowErrors={setShowFormErrors}
      >
        <StyledBackeeCard>
          <FormItemBox fieldName='name' fieldDecoratorOptions={fieldDecorators.name} showAsRequired>
            <NakedFormInput
              name={`txt-create-organization-name`}
              dataTestId={`txt-create-organization-name`}
              type='text'
              placeholder='Name'
              disableSuggestion
              onKeyPress={handleKeyPress}
            />
          </FormItemBox>
          <FormItemBox
            fieldName='emailKnoxerIdentityProviderNames'
            fieldDecoratorOptions={fieldDecorators.emailKnoxerIdentityProviderNames}
          >
            <SSOProviderSelect
              name='select-create-organization-email-knoxer-sso-provider'
              ssoProviders={identityProvidersForEmailKnoxer}
              placeholder={`Identity Providers - ${knoxers.emailKnoxer.name} Knoxer`}
            />
          </FormItemBox>
          <FormItemBox
            fieldName='phoneKnoxerIdentityProviderNames'
            fieldDecoratorOptions={fieldDecorators.phoneKnoxerIdentityProviderNames}
          >
            <SSOProviderSelect
              name='select-create-organization-phone-knoxer-sso-provider'
              ssoProviders={identityProvidersForPhoneKnoxer}
              placeholder={`Identity Providers - ${knoxers.phoneKnoxer.name} Knoxer`}
            />
          </FormItemBox>
          <ActionsList>
            <SubmitButton
              id='btn-create-organization-submit'
              disabled={isFormInvalid}
              onClick={handleOk}
              loading={savingInProgress}
              onDisabledClick={submitDisableClick}
            >
              CREATE
            </SubmitButton>
          </ActionsList>
        </StyledBackeeCard>
      </StyledBackeeForm>
    </PageWithHeader>
  );
});

export default Form.create<Props>()(CreateOrganizationPage);

const fieldDecorators: FormFieldDecorators<CreateOrganizationFormFields> = {
  name: {
    rules: [
      {
        required: true,
        transform: trim,
        message: `Missing Name`,
      },
      {
        max: ORGANIZATION_FIELD_MAX_LENGTH.name,
        message: `Max ${ORGANIZATION_FIELD_MAX_LENGTH.name} characters`,
      },
    ],
  },
  emailKnoxerIdentityProviderNames: {},
  phoneKnoxerIdentityProviderNames: {},
};

export const ModalContainer = styled.div`
  ${WRAP_WORDS_CSS_PROPERTIES};
`;

const OrganizationCard = styled(InnerCard)`
  margin-top: 10px;
`;
