import request, { HttpMethod } from '@app/libs/request';
import config, { serverUrl } from '@app/config';
import {
  API_VERSION_HEADER,
  CAPTCHA_TOKEN_HEADER,
  SupplierRegistrationProcessRequest,
  WEB_REFERRER_HEADER,
} from '@supplierRegistration/domain/supplierRegistration';
import { SupplierRegistrationAsyncInitiateValidationProcessRequest } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { RawAxiosRequestHeaders } from 'axios';

export async function preRegistrationRequest(
  supplierData: SupplierRegistrationProcessRequest,
  captchaToken: string | null | undefined,
): Promise<void> {
  const headers: RawAxiosRequestHeaders = {
    [WEB_REFERRER_HEADER]: document.referrer,
    [API_VERSION_HEADER]: 'v2',
  };

  if (config.featureFlags?.supplierRegistrationCaptcha && captchaToken) {
    headers[CAPTCHA_TOKEN_HEADER] = captchaToken;
  }

  await request<void>(serverUrl, `/api/supplier-validation/pre-registration`, {
    data: supplierData,
    headers,
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    suppressNotification: true,
    errorsHandler: {
      default: {
        message: 'Could not request for supplier validation pre registration.',
      },
    },
  });
}

export async function preRegistrationRequestFromAsyncInitiateValidation(
  supplierData: SupplierRegistrationAsyncInitiateValidationProcessRequest,
  asyncProcessId: string,
  captchaToken: string | null | undefined,
): Promise<void> {
  const headers: RawAxiosRequestHeaders = {
    [WEB_REFERRER_HEADER]: document.referrer,
    [API_VERSION_HEADER]: 'v2',
  };

  if (config.featureFlags?.supplierRegistrationCaptcha && captchaToken) {
    headers[CAPTCHA_TOKEN_HEADER] = captchaToken;
  }

  await request<void>(serverUrl, `/api/supplier-validation/pre-registration/async/:asyncProcessId`, {
    data: supplierData,
    pathParams: { asyncProcessId },
    headers,
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    suppressNotification: true,
    errorsHandler: {
      default: {
        message: 'Could not request for supplier validation pre registration async.',
      },
    },
  });
}
