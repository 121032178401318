import React from 'react';
import Mode, { AppStoresOfMode } from '@app/modes/Mode';
import { AppStoresProviderContext } from '../AppStoresByOrganizationProvider';

export default function useAppStores<TMode extends Mode>(): AppStoresOfMode<TMode> {
  const content = React.useContext(AppStoresProviderContext);

  if (!content) {
    throw new Error(
      'appStores is not defined, you might have app context missing, if you are using a modal, please wrap it with ModalAppContext',
    );
  }

  return content as AppStoresOfMode<TMode>;
}
