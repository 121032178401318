import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '@app/components/Button';
import useMountEffect from '@app/hooks/useMountEffect';
import { KeyName } from '@app/domain/uiConsts';
import NextArrowImage from '@app/images/arrows/right-arrow-small.svg';
import PreviousArrowImage from '@app/images/arrows/left-arrow-small.svg';
import SVG from '@app/components/SVG';

export interface NextAndPreviousProps {
  onPreviousClick: ButtonProps['onClick'] | undefined;
  previousItemText?: string;
  onNextClick: ButtonProps['onClick'] | undefined;
  nextItemText?: string;
  disableButtons?: boolean;
  className?: string;
}

const InlinePreviousAndNextArrows: FC<NextAndPreviousProps> = ({
  onPreviousClick,
  previousItemText = 'Previous item',
  onNextClick,
  nextItemText = 'Next item',
  disableButtons,
  className,
}) => {
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const forwardButtonRef = useRef<HTMLButtonElement>(null);

  useMountEffect(
    (): VoidFunction => {
      const onArrowPress = (e: KeyboardEvent): void => {
        if (e.ctrlKey && e.shiftKey) {
          switch (e.key) {
            case KeyName.ArrowLeft:
              backButtonRef?.current?.click();
              break;
            case KeyName.ArrowRight:
              forwardButtonRef?.current?.click();
              break;
          }
        }
      };

      // Start listen on component mount
      window.addEventListener('keydown', onArrowPress);

      return (): void => {
        // Stop listen on component unmount
        window.removeEventListener('keydown', onArrowPress);
      };
    },
  );

  return (
    <Container className={className}>
      <ArrowButton
        ref={backButtonRef}
        id='btn-prev-and-next-previous-arrow'
        onClick={onPreviousClick ?? onOpClick}
        disabled={!onPreviousClick || disableButtons}
        onDisabledClick={onOpClick}
        appearance='text'
      >
        {previousItemText} <SVG image={PreviousArrowImage} width={7} accessibilityLabel='' />
      </ArrowButton>
      <ArrowButton
        ref={forwardButtonRef}
        id='btn-prev-and-next-next-arrow'
        onClick={onNextClick ?? onOpClick}
        disabled={!onNextClick || disableButtons}
        onDisabledClick={onOpClick}
        appearance='text'
      >
        <SVG image={NextArrowImage} width={7} accessibilityLabel='' /> {nextItemText}
      </ArrowButton>
    </Container>
  );
};

export default InlinePreviousAndNextArrows;

const onOpClick = (): void => {};

const Container = styled.div`
  display: flex;
`;

const ArrowButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`;
