import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import {
  BodyRegularStartTransparentBlack600,
  SubtitleRegularStartTransparentBlack900,
  SubtitleSmallStartError600,
} from '@app/components/Text';
import CertificateImage from '@app/images/certificate-with-white-background.svg';
import RefreshImage from '@app/images/refresh-accent.svg';
import SVG from '@app/components/SVG';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import {
  checkFile,
  CheckFileOptions,
  DEFAULT_MAX_FILE_NAME_LENGTH,
  DEFAULT_MAX_FILE_SIZE_MB,
  FileTypes,
} from '@app/domain/files';
import { isTruthy } from '@app/utils/utils';
import { mergeArrays, wrapValueAsArray } from '@app/utils/arrayUtils';
import { LineBreakTextCss } from '@app/components/Styles';
import Button from '@app/components/Button';
import ReactiveDragger from '@app/components/fileUpload/ReactiveDragger';
import { Upload } from 'antd';
import AccountVerificationAboveMainCardTitle from '@mortee/routes/accountVerification/AccountVerificationAboveMainCardTitle';
import { CertificateVerificationError } from '@app/domain/bac';
import { getFileErrorText } from '@app/components/fileUpload/fileUploadUtils';

const allowedExtensions: FileTypes[] = [FileTypes.pdf];
const maxFilesAllowed = 1;

const filesOptions: CheckFileOptions = {
  allowedExtensions,
  maxFileSizeMB: DEFAULT_MAX_FILE_SIZE_MB,
  maxFileNameLength: DEFAULT_MAX_FILE_NAME_LENGTH,
  maxFilesAllowed,
};

interface Props {
  certificate: File;
  certificateVerificationError: CertificateVerificationError | null;
  replaceCertificate(newCertificate: File): void;
  verifyCertificate(): Promise<void>;
}

const AccountVerificationWithCertificateBeforeVerificationPage: FC<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verifyWithCertificateBefore);
  const { certificate, certificateVerificationError, replaceCertificate, verifyCertificate } = props;

  const fileErrors: string[] = useMemo(() => {
    const fileErrorTypes = checkFile(certificate, { allowedExtensions, maxFilesAllowed });
    return mergeArrays([
      fileErrorTypes.map((error) => getFileErrorText(error, filesOptions)).filter(isTruthy),
      wrapValueAsArray(getCertificateValidationText(certificateVerificationError)),
    ]);
  }, [certificate, certificateVerificationError]);

  const handleBeforeUpload = (newCertificate: File): false => {
    replaceCertificate(newCertificate);
    return false;
  };

  return (
    <PageWithHeader paddingTop={52} width={850} widthReactive={false}>
      <AccountVerificationAboveMainCardTitle title='Certificate Verification' showBackButton />
      <ReactiveDragger
        id='drag-guest-verify-replace-certificate'
        showUploadList={false}
        openFileDialogOnClick={false}
        beforeUpload={(file): boolean => handleBeforeUpload(file)}
      >
        <MainCard>
          <CertificateBox>
            <CertificateDetails>
              <CertificateIcon accessibilityLabel='' image={CertificateImage} width={22} height={27} />
              <NonFlexibleFlexItem>
                <SubtitleRegularStartTransparentBlack900.div>File uploaded:</SubtitleRegularStartTransparentBlack900.div>
              </NonFlexibleFlexItem>
              <CertificateName>{certificate.name}</CertificateName>
            </CertificateDetails>
            <NonFlexibleFlexItem>
              <Upload accept='.pdf' showUploadList={false} beforeUpload={(file): boolean => handleBeforeUpload(file)}>
                <ReplaceCertificateButton
                  id='btn-guest-validate-replace-certificate'
                  appearance='text'
                  colorScheme='secondary'
                  size='small'
                  onClick={(): void => {}}
                >
                  <SVG accessibilityLabel='' image={RefreshImage} width={14} height={14} />
                  <span>REPLACE CERTIFICATE</span>
                </ReplaceCertificateButton>
              </Upload>
            </NonFlexibleFlexItem>
          </CertificateBox>
          <FileErrors>
            {fileErrors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </FileErrors>
          <ActionContainer>
            <PrimaryButton
              id='btn-guest-validate-with-certificate'
              dataTestId='btnValidateCertificate'
              disabled={!!fileErrors.length}
              onClick={(): Promise<void> => verifyCertificate()}
            >
              VALIDATE CERTIFICATE
            </PrimaryButton>
          </ActionContainer>
        </MainCard>
      </ReactiveDragger>
    </PageWithHeader>
  );
});

const getCertificateValidationText = (error: CertificateVerificationError | null): string | null => {
  if (!error) {
    return null;
  }

  switch (error.errorType) {
    case 'BANK_ACCOUNT_CERTIFICATE_NOT_VALID_FILE_FORMAT':
      return 'This file may not be an authentic Bank Account Certificate.\nPlease contact your payee and/or nsKnox support if this is a Bank Account Certificate that you have received from your payee.';
    case 'BANK_ACCOUNT_CERTIFICATE_PDF_SIGNATURE_ERROR':
    case 'BANK_ACCOUNT_CERTIFICATE_PDF_HASH_COMPARISON_ERROR':
    case 'BANK_ACCOUNT_CERTIFICATE_BAC_SIGNATURE_ERROR':
      return 'The certificate is not valid. It may be corrupted or has expired.\n Please contact the certificate owner to confirm you receive a valid certificate.';
    case 'BANK_ACCOUNT_CERTIFICATE_BAC_EXPIRED':
      return 'The certificate has expired. Ask the payee for a new certificate.';
    case 'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR':
    default:
      return (
        `Something went wrong.\nTry uploading the certificate again or ask your payee for a new certificate.\nIf the problem persists, please contact us at support@nsknox.net` +
        (error.errorLogId ? `\nRefer to error code: ${error.errorLogId}` : '')
      );
  }
};

export default AccountVerificationWithCertificateBeforeVerificationPage;

const MainCard = styled(Card)`
  width: 100%;
  padding: 20px 30px 50px;
`;

const CertificateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--primary-200-50-a);

  padding: 36px 12px 36px 36px;
`;

const NonFlexibleFlexItem = styled.div`
  flex: 0 0 auto;
`;

const CertificateIcon = styled(SVG)`
  flex: 0 0 auto;
  margin-right: 12px;
`;

const CertificateDetails = styled.div`
  display: flex;
  align-items: center;
`;

const CertificateName = styled(BodyRegularStartTransparentBlack600.div)`
  margin-left: 6px;
  ${WRAP_WORDS_CSS_PROPERTIES}
`;

const FileErrors = styled(SubtitleSmallStartError600.div)`
  margin-top: 16px;
  line-height: 24px;
  ${LineBreakTextCss};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 50px;
`;

const ReplaceCertificateButton = styled(Button)`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 12px;
  }
`;

export const PrimaryButton = styled(Button)`
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 6px;
`;
