import React, { FC } from 'react';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import EditValidationRecordMainTabs, {
  MainTabs,
} from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordMainTabs';
import EditValidationRecordSecondaryTabs, {
  SecondaryTabs,
} from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/EditValidationRecordSecondaryTabs';
import styled from '@emotion/styled';
import { SelectedTab } from '@mortee/components/ProgressTabs';
import SeparatorLine from '@app/components/SeparatorLine';
import useLoadable from '@app/hooks/loadable/useLoadable';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { AlertStatus, transformValidationAlert } from '@mortee/domain/validationSystemAlerts';

interface Props {
  record: SupplierValidationRecord;
  className?: string;
  selectedMainTab: SelectedTab<MainTabs>;
  selectedSecondaryTab: SelectedTab<SecondaryTabs>;

  onSaved(updatedRecord: SupplierValidationRecord | undefined, userWantsToClose: boolean): void;

  onIsFormUnsavedChanged(isFormUnsaved: boolean | null): void;
}

export const HasAlertsContext = React.createContext<boolean>(false);

const EditValidationRecordForm: FC<Props> = (props) => {
  const { record, selectedMainTab, selectedSecondaryTab, onSaved, onIsFormUnsavedChanged, className } = props;

  const [timelineAlerts, reloadAlerts] = useLoadable(async () => {
    const serverResponse = await validationSystemServices.getAlertsByStaticId(record.staticId);
    return serverResponse.map((response) => transformValidationAlert(response));
  }, [record.versionId]);

  function hasActiveAlertsOrLoadingAlerts(): boolean {
    if (timelineAlerts.isResolved()) {
      return timelineAlerts.result?.some((alert) => alert.alertStatus === AlertStatus.active);
    }
    return true;
  }

  return (
    <MainLayout className={className}>
      <HasAlertsContext.Provider value={hasActiveAlertsOrLoadingAlerts()}>
        <EditValidationRecordMainTabs
          selectedTab={selectedMainTab}
          record={record}
          onIsFormUnsavedChanged={onIsFormUnsavedChanged}
          onSaved={onSaved}
        />
      </HasAlertsContext.Provider>
      <SeparatorLine vertical />
      <EditValidationRecordSecondaryTabs
        selectedTab={selectedSecondaryTab}
        selectedMainTab={selectedMainTab.value}
        record={record}
        allAlertsLoadable={timelineAlerts}
        reloadAlerts={reloadAlerts}
      />
    </MainLayout>
  );
};

export default EditValidationRecordForm;

const MainLayout = styled.div`
  display: grid;
  grid-template-columns: 3fr auto minmax(450px, 1fr);
`;
