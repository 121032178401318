import styled from '@emotion/styled';
import { css } from '@emotion/css';

export const PageSecondTitle = styled.div`
  font-size: 24px;
  letter-spacing: 0.33px;
  color: var(--black-strong);
`;

export const RowSeparator = styled.div<{ dashed?: boolean; light?: boolean }>`
  height: 1px;
  min-height: 1px;
  background-color: ${(p): string => (p.light ? 'var(--transparent-gray-150)' : '#f5f6fa')};
  align-self: stretch;
  ${(p): string =>
    p.dashed
      ? `
    border: none;
    border-top: 1px dashed #979797;
  `
      : ''}
`;

export const LineBreakTextCss = css`
  white-space: pre-wrap;
`;

export const LineBreakText = styled.div`
  ${LineBreakTextCss}
`;
