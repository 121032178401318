import React, { FunctionComponent, ReactElement } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import { validationTypes } from '@mortee/domain/morteeAccountVerification';

interface ValidationLevelExplanationProps {
  className?: string;
}

const ValidationLevelExplanation: FunctionComponent<ValidationLevelExplanationProps> = (props) => {
  const renderVerificationTypeExplanation = (key: string, verificationType): ReactElement => {
    return (
      <VerificationTypeExplanationContainer key={key}>
        <VerificationTypeIcon accessibilityLabel='' image={verificationType.logo} />
        <VerificationTypeExplanationTextContainer>
          <ValidationTypeTitle>{verificationType.title}</ValidationTypeTitle>
          <ValidationTypeDescription>{verificationType.description}</ValidationTypeDescription>
        </VerificationTypeExplanationTextContainer>
      </VerificationTypeExplanationContainer>
    );
  };

  const { className } = props;

  return (
    <MainContainer>
      <Title>Validation Levels</Title>
      <VerificationTypesExplanationContainer className={className}>
        {Object.entries(validationTypes).map(([verificationResultType, verificationResult]) =>
          renderVerificationTypeExplanation(verificationResultType, verificationResult),
        )}
      </VerificationTypesExplanationContainer>
    </MainContainer>
  );
};

export default ValidationLevelExplanation;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerificationTypesExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.29px;
  color: var(--transparent-black-900);
  margin-bottom: 40px;
`;

const VerificationTypeExplanationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const ValidationTypeTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: var(--primary-500);
`;

const ValidationTypeDescription = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.42px;
  color: var(--transparent-black-600);
`;

const VerificationTypeExplanationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const VerificationTypeIcon = styled(SVG)`
  height: 48px;
  min-width: 40px;
  margin-right: 24px;
`;
