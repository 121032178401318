import MorteeAppContent from '@mortee/routes/app/MorteeAppContent';
import { ComponentType } from 'react';
import { UserRolesType } from '@app/domain/user';
import { OrganizationServiceType } from '@app/domain/organizations';
import SecureFilesListPage from '@mortee/routes/secureFiles/SecureFilesListPage';
import CheckSecureFilesLoadPage from '@mortee/routes/secureFiles/CheckSecureFilesLoadPage';
import BatchesListPage from '@mortee/routes/paymentCheck/BatchesListPage';
import LoadPage from '@mortee/routes/paymentCheck/LoadBatchPage';
import PaymentBatchPage from '@mortee/routes/paymentCheck/PaymentBatchPage';
import SupplierRegistrationListForManagementPage from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationListForManagementPage';
import MasterDataGuardPage from '@mortee/routes/masterDataGuard/MasterDataGuardPage';
import PageHeader from '@app/components/header/PageHeader';
import MyBankAccountsPage from '@mortee/routes/ips/myBankAccounts/MyBankAccountsPage';
import OverviewPage from '@mortee/routes/ips/overviewPage/OverviewPage';
import AccountVerificationWithPayeePage from '@mortee/routes/accountVerification/AccountVerificationWithPayeePage';
import Mode from '@app/modes/Mode';
import MorteeInfraStores from '@mortee/stores/infraStores/MorteeInfraStores';
import MorteeAppStores from '@mortee/stores/appStores/MorteeAppStores';
import ManageValidatedPayeePage from '@mortee/routes/validatedPayeesManagement/ManageValidatedPayeePage';
import CreateValidatedPayeeAccountPage from '@mortee/routes/validatedPayeesManagement/CreateValidatedPayeeAccountPage';
import CreateValidatedPayeePage from '@mortee/routes/validatedPayeesManagement/CreateValidatedPayeePage';
import AccountVerificationInitialPage from '@mortee/routes/accountVerification/AccountVerificationInitialPage';
import AccountVerificationWithCertificatePage from '@mortee/routes/accountVerification/AccountVerificationWithCertificatePage';
import SftpErrorsLog from '@mortee/routes/sftp/SftpErrorsLog';
import ValidationSystemPageForManagement from '@mortee/routes/validationSystem/ValidationSystemPageForManagement';
import EditValidatedPayeePage from '@mortee/routes/validatedPayeesManagement/EditValidatedPayeePage';
import ManageOrganizationUsers from '@mortee/routes/organizationManagement/ManageOrganizationPage';

class MorteeMode extends Mode<MorteeInfraStores, MorteeAppStores> {
  getDefaultHeaderComponent(): React.ComponentType {
    return PageHeader;
  }

  constructor() {
    super(sectionDefinitions);
  }

  getAppContentComponent(): ComponentType {
    return MorteeAppContent;
  }

  getHeadTitle(): string {
    return 'PaymentKnox';
  }

  createInfraStores(): MorteeInfraStores {
    return new MorteeInfraStores(this.sections, this.getLocalePath());
  }

  createAppStores(infraStores: MorteeInfraStores): MorteeAppStores {
    return new MorteeAppStores(infraStores);
  }
}

export default MorteeMode;

const sectionDefinitions: SectionDefinition[] = [
  {
    id: 'ipsOverview',
    name: 'Overview',
    category: 'incomingPayments',
    requiredRoles: [UserRolesType.ipsViewLogsRole],
    requiredServices: [OrganizationServiceType.IncomingPaymentSecurityService],
    routes: [
      {
        id: 'ipsOverview',
        path: '/incoming/overview',
        redirectFromPaths: ['/incoming/verification-log'],
        component: OverviewPage,
        requiredRoles: [UserRolesType.ipsViewLogsRole],
        requiredServices: [OrganizationServiceType.IncomingPaymentSecurityService],
      },
    ],
  },
  {
    id: 'myBankAccounts',
    name: 'My Bank Accounts',
    category: 'incomingPayments',
    requiredRoles: [UserRolesType.ipsViewMyAccountsRole],
    requiredServices: [OrganizationServiceType.IncomingPaymentSecurityService],
    routes: [
      {
        id: 'myBankAccounts',
        path: '/incoming/my-bank-accounts',
        component: MyBankAccountsPage,
        requiredRoles: [UserRolesType.ipsViewMyAccountsRole],
        requiredServices: [OrganizationServiceType.IncomingPaymentSecurityService],
      },
    ],
  },
  {
    id: 'accountVerification',
    name: 'Account Verification',
    category: 'outgoingPayments',
    requiredRoles: [UserRolesType.verifyAccountRole],
    requiredServices: [OrganizationServiceType.accountVerificationService],
    routes: [
      {
        id: 'accountVerificationInitialPage',
        path: '/outgoing/verification',
        redirectFromPaths: ['/validation', '/outgoing/validation'],
        component: AccountVerificationInitialPage,
        requiredRoles: [UserRolesType.verifyAccountRole],
        requiredServices: [OrganizationServiceType.accountVerificationService],
        innerRoutes: [
          {
            id: 'accountVerificationWithPrivatePayee',
            path: '/company/private/:privateId',
            component: AccountVerificationWithPayeePage,
            requiredRoles: [UserRolesType.verifyAccountRole],
            requiredServices: [OrganizationServiceType.accountVerificationService],
          },
          {
            id: 'accountVerificationWithValidatedPayee',
            path: '/company/public/:validatedId',
            component: AccountVerificationWithPayeePage,
            requiredRoles: [UserRolesType.verifyAccountRole],
            requiredServices: [OrganizationServiceType.accountVerificationService],
          },
          {
            id: 'accountVerificationWithBankAccountCertificate',
            path: '/certificate',
            component: AccountVerificationWithCertificatePage,
            requiredRoles: [UserRolesType.verifyAccountRole],
            requiredServices: [OrganizationServiceType.accountVerificationService],
          },
        ],
      },
    ],
  },
  {
    id: 'masterDataGuard',
    name: 'Master Data Guard',
    category: 'outgoingPayments',
    requiredRoles: [UserRolesType.viewFileSurveyRole, UserRolesType.createFileSurveyRole],
    requiredServices: [OrganizationServiceType.OnlineMasterDataGuard],
    routes: [
      {
        id: 'masterDataGuard',
        path: '/outgoing/master-data-guard',
        redirectFromPaths: ['/master-data-guard'],
        component: MasterDataGuardPage,
        requiredRoles: [UserRolesType.viewFileSurveyRole, UserRolesType.createFileSurveyRole],
        requiredServices: [OrganizationServiceType.OnlineMasterDataGuard],
      },
    ],
  },
  {
    id: 'paymentCheck',
    name: 'Payment Check',
    category: 'outgoingPayments',
    requiredRoles: [UserRolesType.paymentCheckCreateRole, UserRolesType.paymentCheckViewRole],
    requiredServices: [OrganizationServiceType.paymentCheck],
    routes: [
      {
        id: 'batchesList',
        path: '/outgoing/payment-check',
        redirectFromPaths: ['/payment-check'],
        component: BatchesListPage,
        requiredRoles: [UserRolesType.paymentCheckCreateRole, UserRolesType.paymentCheckViewRole],
        requiredServices: [OrganizationServiceType.paymentCheck],
        innerRoutes: [
          {
            id: 'loadPaymentBatch',
            path: '/load',
            component: LoadPage,
            requiredRoles: [UserRolesType.paymentCheckCreateRole],
            requiredServices: [OrganizationServiceType.paymentCheck],
          },
          {
            id: 'singlePaymentBatch',
            path: '/batch/:batchId',
            component: PaymentBatchPage,
            requiredRoles: [UserRolesType.paymentCheckViewRole, UserRolesType.paymentCheckCreateRole],
            requiredServices: [OrganizationServiceType.paymentCheck],
          },
        ],
      },
    ],
  },
  {
    id: 'secureFilesTransfer',
    name: 'Secure Files Transfer',
    category: 'outgoingPayments',
    requiredRoles: [UserRolesType.secureFilesRole],
    requiredServices: [OrganizationServiceType.secureFiles],
    routes: [
      {
        id: 'secureFilesTransfer',
        path: '/outgoing/secure-files',
        redirectFromPaths: ['/secure-files'],
        component: SecureFilesListPage,
        requiredRoles: [UserRolesType.secureFilesRole],
        requiredServices: [OrganizationServiceType.secureFiles],
        innerRoutes: [
          {
            id: 'secureFilesTransferLoad',
            path: '/load',
            component: CheckSecureFilesLoadPage,
            requiredRoles: [UserRolesType.secureFilesRole],
            requiredServices: [OrganizationServiceType.secureFiles],
          },
        ],
      },
    ],
  },
  {
    id: 'sftpErrorLog',
    name: 'SFTP Error Log',
    category: 'outgoingPayments',
    requiredRoles: [UserRolesType.sftpPortalViewRole],
    requiredServices: [OrganizationServiceType.sftp],
    routes: [
      {
        id: 'sftpErrorLogTable',
        path: '/sftp-error-log',
        redirectFromPaths: ['/sftp-error-log'],
        component: SftpErrorsLog,
        requiredRoles: [UserRolesType.sftpPortalViewRole],
        requiredServices: [OrganizationServiceType.sftp],
      },
    ],
  },
  {
    id: 'supplierRegistrationForManagement',
    name: 'Registration Forms',
    category: 'validations',
    requiredRoles: [UserRolesType.adminRole, UserRolesType.viewValidationRequestsRole],
    sectionViewRoles: [UserRolesType.adminRole, UserRolesType.viewValidationRequestsRole],
    requiredServices: [],
    routes: [
      {
        id: 'accountValidationForSuppliersManagementList',
        path: '/admin/registration-forms',
        redirectFromPaths: ['/admin/sv-forms', '/sv-forms'],
        component: SupplierRegistrationListForManagementPage,
        requiredRoles: [UserRolesType.adminRole, UserRolesType.viewValidationRequestsRole],
        requiredServices: [],
      },
    ],
  },
  {
    id: 'validationSystemForManagement',
    name: 'Validation System',
    category: 'validations',
    requiredRoles: [
      UserRolesType.adminRole,
      UserRolesType.editValidationSystemRole,
      UserRolesType.createValidationSystemRole,
      UserRolesType.viewValidationSystemRole,
    ],
    sectionViewRoles: [
      UserRolesType.adminRole,
      UserRolesType.editValidationSystemRole,
      UserRolesType.createValidationSystemRole,
      UserRolesType.viewValidationSystemRole,
    ],
    requiredServices: [],
    routes: [
      {
        id: 'validationSystemRecordsTable',
        path: '/admin/validation-system',
        component: ValidationSystemPageForManagement,
        requiredRoles: [
          UserRolesType.editValidationSystemRole,
          UserRolesType.createValidationSystemRole,
          UserRolesType.viewValidationSystemRole,
          UserRolesType.adminRole,
        ],
        requiredServices: [],
      },
    ],
  },
  {
    id: 'validatedPayeesManagement',
    name: 'Validated Payees',
    category: 'validations',
    requiredRoles: [UserRolesType.storeValidatedPayeesRole, UserRolesType.viewManageValidatedPayeesRole],
    sectionViewRoles: [UserRolesType.storeValidatedPayeesRole],
    requiredServices: [],
    routes: [
      {
        id: 'createValidatedPayee',
        path: '/admin/validated-payee/create',
        component: CreateValidatedPayeePage,
        requiredRoles: [UserRolesType.storeValidatedPayeesRole],
        requiredServices: [],
      },
      {
        id: 'manageValidatedPayee',
        path: '/admin/validated-payee/:validatedPayeeUniformId',
        component: ManageValidatedPayeePage,
        requiredRoles: [UserRolesType.storeValidatedPayeesRole, UserRolesType.viewManageValidatedPayeesRole],
        requiredServices: [],
        innerRoutes: [
          {
            id: 'createValidatedPayeeAccount',
            path: '/create-account',
            component: CreateValidatedPayeeAccountPage,
            requiredRoles: [UserRolesType.storeValidatedPayeesRole],
            requiredServices: [],
          },
          {
            id: 'editValidatedPayee',
            path: '/edit-payee',
            component: EditValidatedPayeePage,
            requiredRoles: [UserRolesType.storeValidatedPayeesRole],
            requiredServices: [],
          },
        ],
      },
    ],
  },
  {
    id: 'paymentKnoxOrganizationManagement',
    name: 'Users',
    category: 'management',
    requiredRoles: [UserRolesType.adminRole, UserRolesType.organizationAdmin],
    sectionViewRoles: [UserRolesType.adminRole, UserRolesType.organizationAdmin],
    requiredServices: [],
    routes: [
      {
        id: 'paymentKnoxUserManagement',
        path: '/management/users',
        component: ManageOrganizationUsers,
        requiredRoles: [UserRolesType.adminRole, UserRolesType.organizationAdmin],
        requiredServices: [],
      },
    ],
  },
];

export interface SelectedPayeeIdParams {
  privateId?: string;
  validatedId?: string;
}

export interface PaymentBatchIdParams {
  batchId: string;
}
