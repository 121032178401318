import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import FormContext from '@app/components/inputs/FormContext';
import ValueSuggestions, { Suggestion } from '@app/components/ValueSuggestions';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { isDefined } from '@app/utils/utils';
import styled from '@emotion/styled';

interface Props<TValue> {
  fieldName: string;
  fieldDecoratorOptions: GetFieldDecoratorOptions | null;
  children: ReactElement;
  suggestions: Suggestion<TValue | null | undefined>[];
  dataTestId: string;
  className?: string;
  equalityCheck?(inputValue: TValue | undefined, suggestionValue: TValue): boolean;
  hidden?: boolean;
}

const FormItemBoxWithSuggestions = <TValue,>(props: PropsWithChildren<Props<TValue>>): ReactElement => {
  const { fieldName, fieldDecoratorOptions, children, suggestions, equalityCheck, dataTestId, className, hidden = false } = props;

  const { form, disabled = false, setShowErrors } = useContext(FormContext);
  const value = form.getFieldValue(fieldName);

  function onChange(newValue: TValue | undefined): void {
    form.setFieldsValue({ [fieldName]: newValue });
    form.validateFields([fieldName]);
    setShowErrors('all');
  }

  const definedSuggestions = suggestions.filter((suggestion): suggestion is Suggestion<TValue> => isDefined(suggestion.value));

  return (
    <Container>
      <FormItemBox
        fieldName={fieldName}
        fieldDecoratorOptions={fieldDecoratorOptions}
        expandBelowOnError={!!definedSuggestions.length}
        hidden={hidden}
      >
        {children}
      </FormItemBox>
      {!hidden && (
        <ValueSuggestions<TValue>
          value={value}
          onChange={onChange}
          suggestions={definedSuggestions}
          disabled={disabled}
          dataTestId={dataTestId}
          className={className}
          equalityCheck={equalityCheck}
        />
      )}
    </Container>
  );
};

export default FormItemBoxWithSuggestions;

const Container = styled.div`
  width: 100%;
`;
