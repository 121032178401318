import HealthCheckStore from '@app/stores/HealthCheckStore';
import UserStore from '@app/stores/UserStore';
import { distinctValues } from '@app/utils/arrayUtils';
import config, { serverUrl } from '@app/config';
import { isTruthy } from '@app/utils/utils';
import { checkHealthCheckResultValid, serverHealthCheck } from '@app/services/healthCheckServices';

export default class BackeeHealthCheckStore extends HealthCheckStore {
  constructor(userStore: UserStore) {
    super(userStore);
  }

  async runHealthCheck(): Promise<void> {
    if (!config.runHealthCheck || this.userStore.user) {
      return;
    }

    const knoxersAddresses = distinctValues(
      config.knoxersAuthData
        .filter((knoxer) => knoxer.mandatory)
        .flatMap((knoxer) => knoxer.authProperties)
        .flatMap((authProperties) => [
          authProperties.authUrlEmail,
          authProperties.authUrlPhoneSms,
          authProperties.authUrlPhoneCall,
          authProperties.claimUrl,
          authProperties.userPasswordSelfManagementServiceUrl,
        ])
        .filter(isTruthy),
    );

    const healthCheckResults = await Promise.all([
      serverHealthCheck(serverUrl),
      ...knoxersAddresses.map((knoxerAddress) => serverHealthCheck(knoxerAddress)),
    ]);

    if (!healthCheckResults.every(checkHealthCheckResultValid)) {
      throw new Error('health check result is invalid');
    }
  }
}
