import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { AsyncInitiateValidationMaskedDataServerResponse } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';

export async function getSupplierRegistrationConfigurationByRef(
  referenceId: string,
  asyncId: string,
): Promise<AsyncInitiateValidationMaskedDataServerResponse> {
  return request<AsyncInitiateValidationMaskedDataServerResponse>(
    serverUrl,
    `/api/initiate-validation/async/ref/:referenceId/process/:asyncId`,
    {
      method: HttpMethod.get,
      pathParams: {
        referenceId,
        asyncId,
      },
      dontRedirectToLogin: true,
      suppressNotification: true,
    },
  );
}
