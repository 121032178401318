import React, { Ref } from 'react';
import { ForwardingFC } from '@app/domain/technicals/components';
import { css } from '@emotion/css';
import { NakedDropdownInnerInputProps } from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputProps';
import NakedFormTextarea from '@app/components/inputs/NakedFormTextarea';

const NakedDropdownInnerInputTextArea: ForwardingFC<HTMLTextAreaElement, NakedDropdownInnerInputProps> = React.forwardRef(
  (props: NakedDropdownInnerInputProps, ref) => {
    const { commonProps, textAreaSpecificProps } = props;
    const { disabled, readOnly } = commonProps;
    const { value, onChange, ...textAreaSpecificPropsRest } = textAreaSpecificProps;

    function getInputClassName(): string | undefined {
      return !disabled && readOnly ? nonSearchableInputClassName : undefined;
    }

    return (
      <NakedFormTextarea
        value={(value as string | undefined) ?? ''}
        {...commonProps}
        {...textAreaSpecificPropsRest}
        ref={ref as Ref<HTMLTextAreaElement>}
        onChangeEvent={(changeEvent): void => onChange?.(changeEvent)}
        type='text'
        onSelect={(event): void => {
          if (readOnly) {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        textAreaClassName={getInputClassName()}
      />
    );
  },
);

export default NakedDropdownInnerInputTextArea;

const nonSearchableInputClassName = css`
  cursor: pointer !important;
`;
