import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import { observer, useLocalObservable } from 'mobx-react';
import { flow, reaction } from 'mobx';
import PaymentBatchSummary from '@mortee/routes/paymentCheck/batchPage/PaymentBatchSummary';
import SinglePaymentModel from '@mortee/models/SinglePaymentModel';
import PaymentsTable, { LoadingState } from '@mortee/routes/paymentCheck/batchPage/PaymentsTable';
import { PaymentCheckBatchStatus } from '@mortee/domain/paymentsConsts';
import BatchApprovedIcon from '@mortee/images/check/approved.svg';
import { LineBreakText, PageSecondTitle } from '@app/components/Styles';
import PaymentCheckBatchModel from '@mortee/models/PaymentCheckBatchModel';
import Loader from '@app/components/Loader';
import Log from '@app/libs/logger';
import MorteeMode, { PaymentBatchIdParams } from '@mortee/morteeMode';
import useAppStores from '@app/hooks/useAppStores';
import PageWithHeader from '@app/components/PageWithHeader';
import HomeNavigationButton from '@app/components/HomeNavigationButton';
import { useParams } from 'react-router-dom';
import useMountEffect from '@app/hooks/useMountEffect';

const PaymentBatchPage: FunctionComponent = observer(() => {
  const { paymentCheckStore } = useAppStores<MorteeMode>();

  const localStore = useLocalObservable(() => ({
    rejectedPayments: null as SinglePaymentModel[] | null,
    batchLoadingState: LoadingState.NotLoading as LoadingState,
    paymentsLoadingState: LoadingState.NotLoading as LoadingState,
  }));

  const urlParams = useParams<PaymentBatchIdParams>();

  useMountEffect(
    (): VoidFunction => {
      paymentCheckStore.setSelectedBatchById(urlParams.batchId);

      return (): void => {
        paymentCheckStore.setSelectedBatchById(null);
      };
    },
  );

  useMountEffect(
    (): VoidFunction => {
      const updateDataDisposer = reaction(
        () => {
          return paymentCheckStore.selectedBatch;
        },
        async () => {
          await loadSelectedBatch();
          await loadRejectedPayments();
        },
        {
          fireImmediately: true,
        },
      );

      return (): void => {
        updateDataDisposer();
      };
    },
  );

  const loadSelectedBatch = flow(function* () {
    const { selectedBatch } = paymentCheckStore;

    if (!selectedBatch || selectedBatch.loaded) {
      return;
    }

    localStore.batchLoadingState = LoadingState.Loading;
    try {
      yield selectedBatch.load();
      localStore.batchLoadingState = LoadingState.NotLoading;
    } catch (e: unknown) {
      Log.exception(e);
      localStore.batchLoadingState = LoadingState.ErrorWhileLoading;
    }
  });

  const getSelectedBatch = (): PaymentCheckBatchModel | null => {
    const { selectedBatch } = paymentCheckStore;

    if (!selectedBatch?.loaded) {
      return null;
    }

    return selectedBatch;
  };

  const loadRejectedPayments = flow(function* () {
    const selectedBatch = getSelectedBatch();

    if (!selectedBatch) {
      return;
    }

    if (showSuccess()) {
      return;
    }

    localStore.paymentsLoadingState = LoadingState.Loading;

    try {
      localStore.rejectedPayments = yield paymentCheckStore.getRejectedPaymentsOfBatch(selectedBatch.id);
    } catch (e: unknown) {
      localStore.rejectedPayments = null;
      localStore.paymentsLoadingState = LoadingState.ErrorWhileLoading;
    } finally {
      localStore.paymentsLoadingState = LoadingState.NotLoading;
    }
  });

  const showSuccess = (): boolean => {
    if (getSelectedBatch()?.status === PaymentCheckBatchStatus.batchApproved) {
      return true;
    }

    return !!localStore.rejectedPayments && !localStore.rejectedPayments.length;
  };

  const renderNoPolicyExceptions = (): ReactNode => {
    let noExceptionsMessage: string;

    if (getSelectedBatch()?.files.length) {
      noExceptionsMessage = `These payment files have
no policy exceptions`;
    } else {
      noExceptionsMessage = `This payment file has
no policy exceptions`;
    }

    return (
      <BatchApproveInfo>
        <StyledBatchApprovedIcon accessibilityLabel='' image={BatchApprovedIcon} />
        <BatchApprovedTitle data-testid='noExceptionsMessage'>{noExceptionsMessage}</BatchApprovedTitle>
      </BatchApproveInfo>
    );
  };

  function fetchBatchFile(fileId): Promise<NamedResource> {
    const selectedBatch = getSelectedBatch();

    if (!selectedBatch) {
      throw new Error('Batch is not defined');
    }

    return paymentCheckStore.fetchBatchFile(selectedBatch.id, fileId);
  }

  const renderRejectedPaymentsTable = (): ReactElement => {
    return (
      <>
        <PolicyExceptionsTitle>Policy Exceptions</PolicyExceptionsTitle>
        <PaymentsTable
          payments={localStore.rejectedPayments || []}
          fetchBatchFile={fetchBatchFile}
          loadingState={localStore.paymentsLoadingState}
        />
      </>
    );
  };

  if (localStore.batchLoadingState === LoadingState.Loading) {
    return <Loader spinning />;
  }
  if (localStore.batchLoadingState === LoadingState.ErrorWhileLoading) {
    return <div />;
  }

  return (
    <PageWithHeader width='full' paddingTop='none'>
      <HomeButton id='btn-payment-batch-page-home' />
      <MainContainer>
        <StyledPaymentBatchSummary storedBatch={getSelectedBatch()} fetchBatchFile={fetchBatchFile} />
        <PolicyExceptions>{showSuccess() ? renderNoPolicyExceptions() : renderRejectedPaymentsTable()}</PolicyExceptions>
      </MainContainer>
    </PageWithHeader>
  );
});

export default PaymentBatchPage;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledPaymentBatchSummary = styled(PaymentBatchSummary)`
  margin-left: 24px;
`;

const PolicyExceptions = styled.div`
  margin-top: 42px;
`;

const PolicyExceptionsTitle = styled(PageSecondTitle)`
  margin-left: 24px;
  margin-bottom: 16px;
`;

const BatchApproveInfo = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBatchApprovedIcon = styled(SVG)`
  width: 144px;
  height: 144px;
`;

const BatchApprovedTitle = styled(LineBreakText)`
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--accent-blue-600);
`;

const HomeButton = styled(HomeNavigationButton)`
  margin-bottom: 20px;
`;
