import { computed, makeObservable, observable } from 'mobx';
import UserStore from './/UserStore';
import { OrganizationServiceType } from '../domain/organizations';
import { UserRolesType } from '../domain/user';
import { arrayToObject, mergeArrays } from '@app/utils/arrayUtils';

export default class PermissionsStore {
  private readonly allSections: Section[];
  private readonly userStore: UserStore;

  @observable currentView: FlatRoute | null = null;

  constructor(allSections: Section[], userStore: UserStore) {
    makeObservable(this);

    this.allSections = allSections;
    this.userStore = userStore;
  }

  @computed
  get allowedSections(): Section[] {
    return this.allSections.filter((section) => {
      if (!this.userStore.user && !section.isAnonymous) {
        return true;
      }

      if (section.isDisabled) {
        return false;
      }

      return this.isActionAllowed(
        section.requiredServices as OrganizationServiceType[],
        section.requiredRoles as UserRolesType[],
      );
    });
  }

  @computed
  get visibleSections(): Section[] {
    return this.allowedSections.filter((section) => {
      return !section.sectionViewRoles || this.canViewSection(section.sectionViewRoles);
    });
  }

  @computed
  get allowedRoutesByKey(): Partial<{ [key in RoutesKeys]: FlatRoute }> {
    const nsRoutes = this.allowedSections
      .flatMap((section) => section.routes)
      .filter((r) => this.isActionAllowed(r.requiredServices, r.requiredRoles, r.requireAllSpecifiedRoles) && !r.isDisabled);
    return arrayToObject<RoutesKeys, FlatRoute, 'id'>(nsRoutes, 'id');
  }

  isActionAllowed = (
    requiredOrganizationServices: OrganizationServiceType[],
    requiredUserRoles: UserRolesType[],
    requireAllSpecifiedRoles?: boolean,
  ): boolean => {
    return PermissionsStore.isActionAllowedForUser(
      this.userStore.user,
      this.userStore.selectedOrganization,
      requiredOrganizationServices,
      requiredUserRoles,
      requireAllSpecifiedRoles,
    );
  };

  canViewSection = (requiredSectionViewRoles: string[], requireAllSpecifiedRoles?: boolean): boolean => {
    const userRoles = PermissionsStore.getUserRolesByOrganization(this.userStore.user, this.userStore.selectedOrganization);
    if (!requiredSectionViewRoles.length) {
      return false;
    }
    return requireAllSpecifiedRoles
      ? requiredSectionViewRoles.every((requiredRole) => userRoles.map((role) => role.valueOf()).includes(requiredRole))
      : requiredSectionViewRoles.some((requiredRole) => userRoles.map((role) => role.valueOf()).includes(requiredRole));
  };

  private static isActionAllowedForUser = (
    user,
    organization,
    requiredOrganizationServices: OrganizationServiceType[],
    requiredUserRoles: UserRolesType[],
    requireAllSpecifiedRoles?: boolean,
  ): boolean => {
    const userRoles = PermissionsStore.getUserRolesByOrganization(user, organization);

    return (
      (!requiredOrganizationServices.length ||
        requiredOrganizationServices.some((requiredService) => organization?.services?.includes(requiredService))) &&
      (!requiredUserRoles.length ||
        (requireAllSpecifiedRoles
          ? requiredUserRoles.every((requiredRole) => userRoles.includes(requiredRole))
          : requiredUserRoles.some((requiredRole) => userRoles.includes(requiredRole))))
    );
  };

  private static getUserRolesByOrganization(user, organization): UserRolesType[] {
    return mergeArrays([user?.roles[''], user?.roles[organization?.id]]);
  }
}
