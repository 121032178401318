import styled from '@emotion/styled';
import { BodySmallStartPrimary300 } from '@app/components/Text';

const OldChip = styled.div`
  ${BodySmallStartPrimary300.css}

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.15em 0.6em;

  background-color: var(--primary-200-50-a);
  // Setting border radius to a really big value allows us to change the height and still get the effect of rounded corners.
  // Setting 50% will create an ellipse which is not the desired result
  border-radius: 10000px;
`;

export default OldChip;
