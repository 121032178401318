import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CURRENCIES_KEYS } from '@app/domain/currencies';

function getBankCodeFields(): void {}

function getBranchCodeFields(): void {}

export enum MexicoLegalIdTypesForCompany {
  RFC = 'RFC',
}

function getAdditionalSwiftRules(form: WrappedFormUtils<BaseFormFields>): ConditionalValidationRule[] {
  return [
    {
      required: form.getFieldValue('currency') === CURRENCIES_KEYS.USD,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const MX: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
  branchCode: getBranchCodeFields,
  leis: { types: MexicoLegalIdTypesForCompany, defaultValue: MexicoLegalIdTypesForCompany.RFC },
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
};
