import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { pluralize } from '@app/utils/stringUtils';
import { shoot } from '@app/utils/messageLauncher';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { SupplierValidationRecord, transformSupplierValidationRecord } from '@mortee/domain/validationSystem';
import Button from '@app/components/Button';
import { showYesNoModal } from '@app/components/Modal';
import useInfraStores from '@app/hooks/useInfraStores';
import {
  SidePadding,
  TabTitle,
  TransparentCard,
} from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';

interface Props {
  record: SupplierValidationRecord;
  onSaved(record: SupplierValidationRecord): void;
  className?: string;
}

const EditValidationRecordDistributionTab: FC<Props> = observer((props) => {
  const { record, onSaved, className } = props;
  const { userStore } = useInfraStores();

  const externalValidationId = record.externalValidationId;
  const {
    isDistributed,
    isScheduledDistributable,
    isDistributable,
    distributionCount,
    isSingleUserRequestDistributable: isRecordDistributable,
  } = record.distributions;

  const onDistribute = (): void => {
    showYesNoModal(
      'Are you sure you want to distribute this record?',
      async (): Promise<void> => {
        const closeLoadingMessage = shoot({ type: 'loading', closeable: true, duration: null }, 'Distributing');
        try {
          const updatedRecord = await validationSystemServices.distributeValidationRecord(record.staticId);
          closeLoadingMessage();
          onSaved(transformSupplierValidationRecord(updatedRecord, userStore.user?.id));
          shoot({ type: 'success', closeable: true }, 'This record has been distributed');
        } catch {
          closeLoadingMessage();
        }
      },
      'Distribute',
    );
  };

  const onMarkAsDistributed = (): void => {
    showYesNoModal(
      'Are you sure you want to mark this record as distributed?',
      async (): Promise<void> => {
        const closeLoadingMessage = shoot({ type: 'loading', closeable: true, duration: null }, 'Marking as distributed');
        try {
          const updatedRecord = await validationSystemServices.markAsDistributedValidationRecord(record.staticId);
          closeLoadingMessage();
          onSaved(transformSupplierValidationRecord(updatedRecord, userStore.user?.id));
          shoot({ type: 'success', closeable: true }, 'This record has been marked as distributed');
        } catch {
          closeLoadingMessage();
        }
      },
      'Mark as distributed',
    );
  };

  function renderDistributionText(): ReactElement {
    if (isDistributed) {
      return (
        <div>
          This record has been distributed {distributionCount} <Bold>{pluralize('time', distributionCount)}</Bold>.
        </div>
      );
    }

    if (isScheduledDistributable) {
      return (
        <div>
          This record <Bold>will be distributed</Bold> in the future.
        </div>
      );
    }

    if (isDistributable) {
      return (
        <div>
          This record has <Bold>not been distributed</Bold> for the current instruction type yet.
        </div>
      );
    }

    return (
      <div>
        This record has is <Bold>not distributable</Bold> with the current data.
      </div>
    );
  }

  return (
    <SidePadding className={className}>
      <TabTitle>Distribution</TabTitle>
      <TransparentCard>
        {renderDistributionText()}
        {externalValidationId && (
          <div>
            Validation external id 1: <Bold>{externalValidationId}</Bold>
          </div>
        )}
        <ButtonsContainer>
          <Button
            disabled={!isRecordDistributable}
            appearance='full'
            id='btn-edit-validation-process-distribute'
            onClick={onDistribute}
          >
            Distribute {distributionCount ? 'Again' : ''}
          </Button>
          <Button appearance='full' id='btn-edit-validation-process-mark-as-distribute' onClick={onMarkAsDistributed}>
            Mark As Distributed
          </Button>
        </ButtonsContainer>
      </TransparentCard>
    </SidePadding>
  );
});

export default EditValidationRecordDistributionTab;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 25px;
`;

const Bold = styled.span`
  font-weight: bold;
`;
