import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ModalButton } from '@app/components/Button';
import { BodyRegularStartTransparentBlack600, H6StartTransparentBlack900 } from '@app/components/Text';
import Card from '@app/components/card/Card';

interface Props {
  title?: ReactNode;
  okText: string;
  onOk: () => void;
  cancelText?: string;
  onCancel?: () => void;
  className?: string;
  children: ReactNode;
}

const GeneralModal: FC<Props> = ({ title, children: content, okText, onOk, cancelText, className, onCancel }) => {
  return (
    <MainContainer className={className} shadowType='elevated'>
      <H6StartTransparentBlack900.div>{title}</H6StartTransparentBlack900.div>
      <StyledContent>{content}</StyledContent>
      <ActionContainer>
        {cancelText && onCancel && (
          <StyledButton id='modal-cancel' appearance='outline' colorScheme='primary' onClick={onCancel}>
            {cancelText}
          </StyledButton>
        )}
        <StyledButton id='modal-ok' colorScheme='primary' onClick={onOk}>
          {okText}
        </StyledButton>
      </ActionContainer>
    </MainContainer>
  );
};

export default GeneralModal;

const MainContainer = styled(Card)`
  width: 450px;
  padding: 21px 16px 12px 24px;
`;

const StyledContent = styled(BodyRegularStartTransparentBlack600.div)`
  margin-top: 8px;
  white-space: pre-wrap;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  margin-top: 20px;
`;

const StyledButton = styled(ModalButton)`
  padding: 8px 36px;
`;
