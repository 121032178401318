import { useCallback } from 'react';

type RefFunction<TElement extends HTMLElement> = (instance: TElement | null) => void;

export default function useAutofocus<TElement extends HTMLElement>(): RefFunction<TElement> {
  return useCallback((element: TElement | null) => {
    setTimeout(() => {
      element?.focus();
    }, 0);
  }, []);
}
