import React, { ReactNode } from 'react';
import ButtonThatOpensPopup from '@app/components/popup/ButtonThatOpensPopup';
import { FormInputProps } from '@app/utils/form/form';
import { ForwardingFC } from '@app/domain/technicals/components';
import { observer } from 'mobx-react';
import InstructionsSelector from '@mortee/routes/validationSystem/instructionsEdit/InstructionsSelector';
import { SupplierRegistrationProcessInstructionType } from '@app/domain/commonSupplierRegistration';
import {
  getInstructionType,
  renderInstructionType,
  renderSvmInstructionTypeWithDoubleBorder,
} from '@mortee/domain/validationSystemInfoComponents';
import styled from '@emotion/styled';
import { SupplierValidationRecordInstructionType } from '@mortee/domain/validationSystem';

type Props = FormInputProps<SupplierValidationRecordInstructionType, false> & {
  id: string;
  dataTestId?: string;
  instructionFromRegistrationForm?: SupplierRegistrationProcessInstructionType | null | undefined;
};

const SelectInstructionsInput: ForwardingFC<HTMLButtonElement, Props> = observer(
  React.forwardRef(
    ({ id, dataTestId = id, value: currentInstruction, onChange, accessibilityLabel, instructionFromRegistrationForm }, ref) => {
      let buttonContent;
      let ariaLabel;
      if (currentInstruction) {
        buttonContent = renderSvmInstructionTypeWithDoubleBorder(currentInstruction);
        ariaLabel = `${currentInstruction} - ${accessibilityLabel}`;
      } else if (!currentInstruction && instructionFromRegistrationForm) {
        buttonContent = (
          <ButtonContent>
            {renderInstructionType(instructionFromRegistrationForm)}
            <div>From registration</div>
          </ButtonContent>
        );
        ariaLabel = getInstructionType(instructionFromRegistrationForm)?.name;
      } else {
        buttonContent = <div>From registration</div>;
        ariaLabel = accessibilityLabel;
      }

      return (
        <ButtonThatOpensPopup
          id={id}
          dataTestId={dataTestId}
          appearance='text'
          cornerType='circle'
          colorScheme='grey'
          shadowType='none'
          popupPlacement='bottom-start'
          popupContent={(onDone): ReactNode => (
            <InstructionsSelector onClose={onDone} onChange={(instructionType): void => onChange?.(instructionType)} />
          )}
          ref={ref}
          aria-label={ariaLabel}
        >
          {buttonContent}
        </ButtonThatOpensPopup>
      );
    },
  ),
);

export default SelectInstructionsInput;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
`;
