import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import Card from '@app/components/card/Card';
import Footer from '@app/components/Footer';
import GuestLoginFlow from './GuestLoginFlow';
import GuestLandingPage from './GuestLandingPage';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import TransitionLoader from '@app/components/TransitionLoader';
import { flow } from 'mobx';
import qs from 'query-string';
import { showErrorModalWithTitle } from '@app/components/Modal';
import browserHistory from '@app/utils/browserHistory';
import { GuestAuthMethodType } from '@app/guestLogin/domain/guestLogin';
import LogoStrip from '@app/components/LogoStrip';
import useMountEffect from '@app/hooks/useMountEffect';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import { ARPageProps } from '@ar/routes/withARConfiguration';
import LogoCarousel from '@app/components/logoCarousel/LogoCarousel';
import useTheme from '@app/hooks/useTheme';
import ARHeader from '@ar/routes/app/ARHeader';

interface Props extends ARPageProps {}

const GuestLoginPage: FC<Props> = observer(({ configuration }) => {
  const { guestLoginStore } = useInfraStores<ArMode>();
  const theme = useTheme<ArMode>();

  useMountEffect(() => {
    claimWithGoogleSsoIfRedirected();
  });

  const claimWithGoogleSsoIfRedirected = flow<any, []>(function* () {
    const errorParam = qs.parse(location.search.substr(1))['error'];
    if (errorParam === GuestAuthMethodType.SSO) {
      guestLoginStore.setIsUserStartedLogin(true);
      showErrorModalWithTitle('Sign-in failed', 'Something went wrong, please try again.');
      replaceToSsoLoginPageUrl();

      return;
    }

    const authTypeParam = qs.parse(location.search.substr(1))['authType'];
    if (authTypeParam !== GuestAuthMethodType.SSO) {
      return;
    }

    if (authTypeParam !== GuestAuthMethodType.SSO || !guestLoginStore.emailKnoxer) {
      return;
    }

    try {
      guestLoginStore.setIsUserStartedLogin(true);
      yield guestLoginStore.loadTokenForSSO(guestLoginStore.emailKnoxer.knoxerId);
    } finally {
      // So we don't claim twice on back/refresh operations
      replaceToSsoLoginPageUrl();
    }
  });

  const replaceToSsoLoginPageUrl = (): void => {
    browserHistory.replace('/');
  };

  const renderCard = (): ReactElement => {
    if (guestLoginStore.isUserStartedLogin) {
      return (
        <AuthenticationCard>
          <TransitionLoader loading={guestLoginStore.isLoginLoading}>
            <GuestLoginFlow />
          </TransitionLoader>
        </AuthenticationCard>
      );
    }

    return (
      <WelcomePage>
        <AboveCardSpace>
          <LogoCarousel logos={theme.brand?.additionalLogos} maxLogosToKeepStatic={5} />
        </AboveCardSpace>
        <LandingCard fontFamily={theme.fontFamily}>
          <GuestLandingPage configuration={configuration} />
        </LandingCard>
        {!theme.brand?.clientName && <LogoStrip />}
      </WelcomePage>
    );
  };

  return (
    <div>
      <ARHeader />
      <CenteredContent>
        <ContentContainer>{renderCard()}</ContentContainer>
        <StyledFooter />
      </CenteredContent>
    </div>
  );
});

export default GuestLoginPage;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled(LocaleAwareDiv)`
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WelcomePage = styled.div`
  width: min(900px, 95vw);
`;

const LandingCard = styled(Card)`
  padding: 40px;
`;

const AuthenticationCard = styled(Card)`
  margin-top: 90px;
  width: 520px;
  padding: 0;
`;

const StyledFooter = styled(Footer)`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 50px 50px 10px;
`;

const AboveCardSpace = styled.div`
  height: 90px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
`;
