import React, { FC } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import ArMode from '@ar/arMode';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import browserHistory from '@app/utils/browserHistory';
import useInfraStores from '@app/hooks/useInfraStores';
import { BodyRegularStartAccentBlue500, BodyRegularStartPrimary200 } from '@app/components/Text';
import { Upload } from 'antd';
import ReactiveDragger from '@app/components/fileUpload/ReactiveDragger';
import ARAboveMainCardTitle from '@ar/routes/ARAboveMainCardTitle';
import useMountEffect from '@app/hooks/useMountEffect';
import { Trans, useTranslation } from 'react-i18next';
import { ARConfiguration } from '@ar/domain/arGeneral';
import { ARPageProps } from '@ar/routes/withARConfiguration';

interface Props extends ARPageProps {}

const GuestVerificationChooseBac: FC<Props> = observer(({ configuration }) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verify);
  const { navigationStore } = useInfraStores<ArMode>();
  const { arVerificationStore } = useAppStores<ArMode>();
  const { t } = useTranslation();

  useMountEffect(() => {
    // initial cleanup
    arVerificationStore.resetAll();
  });

  // todo banks poc hack
  function isSebOrg(configuration: ARConfiguration | null): boolean {
    return configuration?.clientName?.toLowerCase() === 'seb';
  }

  const handleBeforeUpload = (file: File): boolean => {
    arVerificationStore.resetToAfterCertificateSelected(file);
    browserHistory.push(navigationStore.generateCertificateVerificationPageLink());
    return false;
  };

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <ARAboveMainCardTitle title={t('ar.guestVerification.cert.certificateVerification')} showBackButton />
      <ReactiveDragger
        id='drag-guest-verify-add-certificate'
        showUploadList={false}
        openFileDialogOnClick={false}
        beforeUpload={(file): boolean => handleBeforeUpload(file)}
      >
        <BacCard>
          <DashedArea>
            {isSebOrg(configuration) ? (
              <Text>
                <Trans i18nKey='ar.guestVerification.cert.dragDropCertificateSEB'>
                  Drag & drop the Bank Account Certificate here or
                  <StyledUpload accept='.pdf' showUploadList={false} beforeUpload={(file): boolean => handleBeforeUpload(file)}>
                    <a data-testid='upload-guest-verify-add-certificate'>click to upload</a>
                  </StyledUpload>
                </Trans>
              </Text>
            ) : (
              <Text>
                <Trans i18nKey='ar.guestVerification.cert.dragDropCertificate'>
                  Drag & drop the Payee's nsKnox Bank Account Certificate here or
                  <StyledUpload accept='.pdf' showUploadList={false} beforeUpload={(file): boolean => handleBeforeUpload(file)}>
                    <a data-testid='upload-guest-verify-add-certificate'>click to upload</a>
                  </StyledUpload>
                </Trans>
              </Text>
            )}
          </DashedArea>
        </BacCard>
      </ReactiveDragger>
    </PageWithHeader>
  );
});

export default GuestVerificationChooseBac;

const Text = styled(BodyRegularStartPrimary200.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;

  margin: 10px;
`;

const BacCard = styled(Card)`
  height: 510px;
  padding: 28px 24px;
`;

const DashedArea = styled.div`
  border: 1px dashed #778296;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledUpload = styled(Upload)`
  margin-inline-start: 3px;

  & .ant-upload {
    // Fix ant-upload text css override
    ${BodyRegularStartAccentBlue500.css};

    cursor: pointer;
  }
`;
