import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { TenantUserServerResponse } from '@app/domain/userManagement/users';

export async function searchTenantUsers(query: string): Promise<TenantUserServerResponse[]> {
  return request<TenantUserServerResponse[]>(serverUrl, `/api/users`, {
    params: { query },
    method: HttpMethod.get,
    errorsHandler: {
      RETURNED_TOO_MANY_USERS_ERROR: {
        suppressNotification: true,
      },
      default: {
        message: 'Cannot search users.',
      },
    },
  });
}
