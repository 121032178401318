import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import InfoIcon from '@app/images/exclamation-primary.svg';
import SVG from '@app/components/SVG';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import Loadable from '@app/utils/Loadable';
import Button from '@app/components/Button';
import { createNTimes } from '@app/utils/arrayUtils';
import PayeeDetails from '@mortee/components/payeeDetails/PayeeDetails';
import PayeeSearchStore from '@mortee/stores/appStores/PayeeSearchStore';

interface Props {
  className?: string;
  searchText: string;
  currentOrganization: UserOrganization | undefined;
  results: Loadable<UnifiedPayee[]>;
  hasTooManyPayeeSearchResults: boolean;
  onPayeeSelected(payee: UnifiedPayee): void;
}

const AccountVerificationSearchPayeeResults: FunctionComponent<Props> = observer(
  ({ className, searchText, currentOrganization, results, hasTooManyPayeeSearchResults, onPayeeSelected }) => {
    const renderContent = (): ReactElement => {
      return results.resolve(
        (loadedResults): ReactElement => {
          if (!loadedResults.length) {
            return (
              <ZeroResultsContainer data-testid='no-result-matching-query'>
                There were no results matching “{searchText}”
              </ZeroResultsContainer>
            );
          }

          return (
            <>
              {loadedResults.map((payee, index) => {
                return (
                  <div key={payee.id}>
                    <PayeeResultButton
                      id={`btn-search-select-payee-${payee.id}`}
                      index={index}
                      onClick={(): void => onPayeeSelected(payee)}
                      dataTestId='companyCard'
                      appearance='text'
                      cornerType='none'
                      colorScheme='white'
                    >
                      <PayeeDetails payee={payee} organization={currentOrganization} searchText={searchText} />
                    </PayeeResultButton>
                  </div>
                );
              })}
            </>
          );
        },
        () => {
          return (
            <>
              {createNTimes(2, (index) => {
                return (
                  <PayeeResultLoading key={index} index={index} data-testid={`loading-search-select-payee-${index}`}>
                    <PayeeDetails showLoading />
                  </PayeeResultLoading>
                );
              })}
            </>
          );
        },
      );
    };

    return (
      <div className={className}>
        {hasTooManyPayeeSearchResults && (
          <MiddleCard>
            <StyledSVG accessibilityLabel='' image={InfoIcon} height={24} width={24} />
            <MiddleTextTitle data-testid='too-many-payees-results'>
              Too many results were found, 1-{PayeeSearchStore.RESULTS_TO_QUERY} are shown. To reduce the number of results, enter
              the full name or full ID.
            </MiddleTextTitle>
          </MiddleCard>
        )}
        <ResultsCard>
          <ScrollableList>{renderContent()}</ScrollableList>
        </ResultsCard>
      </div>
    );
  },
);

export default AccountVerificationSearchPayeeResults;

const ResultsCard = styled(Card)`
  padding: 8px 0;
`;

const ScrollableList = styled.div`
  overflow-y: auto;
  max-height: 45vh;
`;

const PayeeResultButton = styled(Button)<{ index: number }>`
  width: 100%;
  padding: 16px 32px;
  text-align: left;
  text-align: start;

  background-color: var(${(p): string => (p.index % 2 ? '--primary-200-50-a' : '--white')});

  &:hover {
    background-color: var(--primary-200-100-a) !important;
  }
`;

const PayeeResultLoading = styled.div<{ index: number }>`
  padding: 16px 32px;
  text-align: left;
  text-align: start;

  background-color: var(${(p): string => (p.index % 2 ? '--primary-200-50-a' : '--white')});
`;

const ZeroResultsContainer = styled.div`
  padding-left: 12px;
  line-height: 20px;
`;

const StyledSVG = styled(SVG)`
  margin-right: 12px;
`;

const MiddleCard = styled(Card)`
  margin-bottom: 4px;
  padding: 13px 32px;
  display: flex;
  flex-direction: row;
`;

const MiddleTextTitle = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-300);
`;
