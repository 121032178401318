import { ColumnProps } from 'antd/lib/table';
import { getColumnConstFilterProps } from './constsFilterCreator';
import { daysBetween, isDateMaximumXMonthsAgo } from '@app/utils/timeUtils';

enum PastTimeFilterOptions {
  today = 'today',
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  lastMonth = 'lastMonth',
  last3Months = 'last3Months',
}

const pastTimeFilterOptionText: Record<PastTimeFilterOptions, string> = {
  [PastTimeFilterOptions.today]: 'Today',
  [PastTimeFilterOptions.last7Days]: 'Last 7 days',
  [PastTimeFilterOptions.last30Days]: 'Last 30 days',
  [PastTimeFilterOptions.lastMonth]: 'Last month',
  [PastTimeFilterOptions.last3Months]: 'Last 3 months',
};

export function getColumnPastTimeFilterProps<T>(
  radiobuttonGroupAccessibilityLabel: string,
  timeFieldGetter: (item: T) => number,
): Partial<ColumnProps<T>> {
  const partTimeFilterOptions = Object.values(PastTimeFilterOptions).map((partTimeFilterOption: PastTimeFilterOptions) => ({
    label: pastTimeFilterOptionText[partTimeFilterOption],
    value: partTimeFilterOption,
  }));

  return getColumnConstFilterProps(
    radiobuttonGroupAccessibilityLabel,
    partTimeFilterOptions,
    (pastTimeFilterOptions: PastTimeFilterOptions, record): boolean => {
      const value = timeFieldGetter(record);
      const daysSinceTimestamp = daysBetween(value, Date.now());

      switch (pastTimeFilterOptions) {
        case PastTimeFilterOptions.today: {
          return daysSinceTimestamp < 1;
        }
        case PastTimeFilterOptions.last7Days: {
          return daysSinceTimestamp < 7;
        }
        case PastTimeFilterOptions.last30Days: {
          return daysSinceTimestamp < 30;
        }
        case PastTimeFilterOptions.lastMonth: {
          return daysSinceTimestamp < 31 && isDateMaximumXMonthsAgo(value, 0);
        }
        case PastTimeFilterOptions.last3Months: {
          return isDateMaximumXMonthsAgo(value, 2);
        }
      }
    },
  );
}
