import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { H4StartTransparentBlack900, SmallTitleTransparentBlack900 } from '@app/components/Text';
import { SectionCategoryNames } from '@app/domain/sections';
import useInfraStores from '@app/hooks/useInfraStores';

interface Props {
  title?: ReactNode;
  category?: string;
}

const PageHeaderTitle: FunctionComponent<Props> = (props) => {
  const { title, category } = props;
  const { navigationStore } = useInfraStores();
  const { selectedSection } = navigationStore;

  return (
    <div>
      <TitleCategory>{category ?? (selectedSection?.category && SectionCategoryNames[selectedSection.category])}</TitleCategory>
      <Title>{title ?? selectedSection?.name}</Title>
    </div>
  );
};

export default PageHeaderTitle;

const TitleCategory = styled(SmallTitleTransparentBlack900.div)`
  flex: 1;
  margin-bottom: 6px;
`;

const Title = styled(H4StartTransparentBlack900.div)`
  flex: 0 0 auto;
`;
