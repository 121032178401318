import config from '@app/config';
import { concatAllPairs } from '@app/utils/stringUtils';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import pathToRegexp from 'path-to-regexp';

export const SectionCategoryNames: Record<SectionCategory, string> = {
  incomingPayments: 'INCOMING PAYMENTS',
  outgoingPayments: 'OUTGOING PAYMENTS',
  validations: 'VALIDATIONS',
  management: 'MANAGEMENT',
};

export function fromDefinition(sectionDefinition: SectionDefinition, isModeAnonymous: boolean): Section {
  const routes = flatRoutes(sectionDefinition.routes).map((route) => fromRouteDefinition(route, sectionDefinition.id));

  return {
    ...sectionDefinition,
    routes,
    baseRoute: routes[0],
    isAnonymous: sectionDefinition.isAnonymous || isModeAnonymous,
  };
}

function fromRouteDefinition(routeDefinition: HierarchicalRoute, sectionId: SectionKey): FlatRoute {
  const { redirectFromPaths, path, ...restOfRouteDefinition } = routeDefinition;
  const pattern = pathToRegexp(path || '/');
  const pathBuilder = pathToRegexp.compile(path || '/');

  return {
    ...restOfRouteDefinition,
    sectionId,
    pattern,
    pathBuilder,
    redirectFromPaths: redirectFromPaths || [],
    path: config.urlPath + path,
  };
}

function flatRoutes(
  routes: HierarchicalRoute[] | undefined,
  baseRoute: string = '',
  baseRedirectedFrom: string[] = [],
): HierarchicalRoute[] {
  const formatterBaseRoute = baseRoute === '/' ? '' : baseRoute;

  const routesWithInner = (routes || []).map((route) => {
    const path = `${formatterBaseRoute}${route.path}`;
    const routeWithRealPath: HierarchicalRoute = {
      ...route,
      path,
      redirectFromPaths: arrayWithoutValue(
        concatAllPairs([baseRoute, ...baseRedirectedFrom], [route.path, ...(route.redirectFromPaths ?? [])]),
        path,
      ),
    };

    return [routeWithRealPath, ...flatRoutes(route.innerRoutes, routeWithRealPath.path, routeWithRealPath.redirectFromPaths)];
  });

  return routesWithInner.flat();
}
