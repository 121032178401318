import React, { FC, ReactNode } from 'react';
import Card from '@app/components/card/Card';
import styled from '@emotion/styled';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import useAppStores from '@app/hooks/useAppStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { EMAIL_REGEX, REDUCTED_EMAIL_REGEX } from '@app/utils/regexUtils';
import { itemOrFirst } from '@app/utils/arrayUtils';
import browserHistory from '@app/utils/browserHistory';
import {
  SupplierRegistrationProcessInstructionType,
  SupplierRegistrationProcessInstructionTypesSubsets,
} from '@app/domain/commonSupplierRegistration';
import NothingToDoAfterForm from './afterFormPage/NothingToDoAfterForm';
import { ACHFormDetails } from './afterFormPage/ACHChaseAfterForm';
import { LeumiFormDetails } from './afterFormPage/LeumiAfterForm';
import PageWithHeader from '@app/components/PageWithHeader';
import useInfraStores from '@app/hooks/useInfraStores';
import useMountEffect from '@app/hooks/useMountEffect';
import { InstructionFormDetails } from '../commonAfterFormPage/InstructionFormDetails';
import TransferInstructionForm from '../commonAfterFormPage/TransferInstructionForm';
import { BlueWarningIcon, PleaseNoteText, TransferInfo, TransferTypeTitle } from '../Styles';
import { BOFAWireFormDetails } from './afterFormPage/BOFAWireAfterForm';
import { BarclaysGBPFormDetails } from './afterFormPage/BarclaysGBPAfterForm';
import { BarclaysEuroFormDetails } from './afterFormPage/BarclaysEuroAfterForm';
import { BOFAWirePingPongFormDetails } from './afterFormPage/BOFAWirePingPongAfterForm';
import { BarclaysPingPongFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/BarclaysPingPongAfterForm';
import { CanadaPayoneerPingPongFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/CanadaPayoneerPingPongAfterForm';
import { SupplierRegistrationProgressStep } from '@supplierRegistration/domain/supplierRegistration';
import { PoalimFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/PoalimAfterForm';
import { JapanPayoneerFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/JapanPayoneerAfterForm';
import { MexicoFrictionlessFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/MexicoFrictionlessAfterFormSpecialComponent.tsx/MexicoFrictionlessAfterForm';
import { ChileLightFrictionFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/ChileLightFrictionAfterForm';
import { ArgentinaLightFrictionFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/ArgentinaLightFrictionAfterForm';
import { VietnamLightFrictionFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/VietnamLightFrictionAfterForm';
import { BarclaysUSDFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/BarclaysUSDAfterForm';
import { PingPongUSDFormDetails } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/PingPongUSDAfterForm';
import { ILLinkedAccountAfterForm } from '@supplierRegistration/routes/supplierRegistrationProcess/afterFormPage/ILLinkedAccountAfterForm';

interface AfterFormPageQueryParams {
  email?: string | string[];
  instructionType?: SupplierRegistrationProcessInstructionType | SupplierRegistrationProcessInstructionType[];
}

const AfterFormPage: FC = observer(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const { email: emailQueryParam, instructionType: instructionTypeQueryParam } = qs.parse(
    location.search,
  ) as AfterFormPageQueryParams;
  const { navigationStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationFormStore, supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const isIpsMode = navigationStore.isIpsPage;

  useMountEffect(() => {
    supplierRegistrationFormStore.clearSavedFormValues();
    supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.OneTimeTransfer);
  });

  const email = emailQueryParam ? itemOrFirst(emailQueryParam) : null;
  const instructionType = instructionTypeQueryParam
    ? itemOrFirst(instructionTypeQueryParam)
    : SupplierRegistrationProcessInstructionType.bofaWireTransfer;

  if (!email?.match(EMAIL_REGEX) && !email?.match(REDUCTED_EMAIL_REGEX)) {
    browserHistory.push(navigationStore.generateAccountValidationForSupplierWelcome(isIpsMode, null));
  }

  let instructionFormDetails: InstructionFormDetails;
  const isIpsInstruction = !!instructionType && SupplierRegistrationProcessInstructionTypesSubsets.ips.includes(instructionType);

  switch (instructionType) {
    case SupplierRegistrationProcessInstructionType.achChaseWireTransfer:
      instructionFormDetails = ACHFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.incomingPaymentSecurityLeumiUSBankWireTransfer:
    case SupplierRegistrationProcessInstructionType.leumiUSBankWireTransfer:
      instructionFormDetails = LeumiFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.bofaWirePingPong:
      instructionFormDetails = BOFAWirePingPongFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.pingPongUSD:
      instructionFormDetails = PingPongUSDFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.canadaPayoneerPingPong:
      instructionFormDetails = CanadaPayoneerPingPongFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.barclaysGBP:
      instructionFormDetails = BarclaysGBPFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.barclaysUSD:
      instructionFormDetails = BarclaysUSDFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.barclaysEUR:
    case SupplierRegistrationProcessInstructionType.IPSBarclaysEUR:
      instructionFormDetails = BarclaysEuroFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.barclaysPingPong:
      instructionFormDetails = BarclaysPingPongFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.poalimBankWireTransfer:
    case SupplierRegistrationProcessInstructionType.incomingPaymentSecurityPoalimBankWireTransfer:
      instructionFormDetails = PoalimFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.japanPayoneer:
      instructionFormDetails = JapanPayoneerFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.mexicoFrictionless:
    case SupplierRegistrationProcessInstructionType.mexicoAlternative:
      instructionFormDetails = MexicoFrictionlessFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.argentinaLF:
      instructionFormDetails = ArgentinaLightFrictionFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.chileLF:
      instructionFormDetails = ChileLightFrictionFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.vietnamLF:
      instructionFormDetails = VietnamLightFrictionFormDetails;
      break;
    case SupplierRegistrationProcessInstructionType.ilLinkedAccount:
      instructionFormDetails = ILLinkedAccountAfterForm;
      break;
    case SupplierRegistrationProcessInstructionType.IPSBOFAWireTransfer:
    default:
      instructionFormDetails = BOFAWireFormDetails;
  }

  const createNotes = (instructionForm: InstructionFormDetails): ReactNode => {
    return instructionForm.pleaseNote.pleaseNoteItem.map((note) => (
      <TransferInfo key={note}>
        <BlueWarningIcon /> <PleaseNoteText>{t(note)}</PleaseNoteText>
      </TransferInfo>
    ));
  };

  const renderInvoice = (): ReactNode => {
    if (instructionFormDetails.pleaseNote.hideInvoice) {
      return null;
    }

    return (
      <TransferInfo>
        <BlueWarningIcon />{' '}
        <PleaseNoteText>
          {isIpsInstruction ? t('supplierValidation.afterRegister.ips.invoice') : t('supplierValidation.afterRegister.invoice')}
        </PleaseNoteText>
      </TransferInfo>
    );
  };

  return (
    <PageWithHeader paddingTop={90} widthReactive>
      <div role='status' data-testid='after-form-details'>
        {instructionType && SupplierRegistrationProcessInstructionTypesSubsets.bankValidation.includes(instructionType) ? (
          <MainCard>
            <TransferTypeTitle>{t('supplierValidation.standardAccountValidation')}</TransferTypeTitle>
            <NothingToDoAfterForm isIpsMode={isIpsMode} email={email} />
          </MainCard>
        ) : (
          <>
            <MainCard>
              <TransferTypeTitle>{t('supplierValidation.standardAccountValidation')}</TransferTypeTitle>
              <TransferInstructionForm instructionDetails={instructionFormDetails} />
            </MainCard>
            {createNotes(instructionFormDetails)}
            {renderInvoice()}
            <FAQLink
              id='vldt-for-supp-step-2-faq'
              data-testid='vldt-for-supp-step-2-faq'
              to={navigationStore.generateAccountValidationForSuppliersFAQ(
                isIpsMode,
                supplierRegistrationCustomerDataStore.currentReference?.referenceId ?? null,
              )}
            >
              {t('supplierValidation.faq')}
            </FAQLink>
          </>
        )}
      </div>
    </PageWithHeader>
  );
});

export default AfterFormPage;

const MainCard = styled(Card)`
  font-weight: 200;
  max-width: 1000px;
  align-self: center;
  padding: 54px 40px;
  line-height: 1.4;
  margin-bottom: 52px;
`;

export const WireTransferWarningBox = styled.div`
  color: #b958ec;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  padding-top: 5px;
`;

export const WarningTitle = styled.div`
  font-size: 16px;
`;

export const FAQLink = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline !important;
  margin-top: 36px;
  margin-left: 29px;
`;
