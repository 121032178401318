import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import DownloadableResource, { DownloadableResourceProps } from '../DownloadableResource';
import { ModalTitle } from '@app/components/Modal';

interface ModalFileListProps {
  files: [FileMetadata, DownloadableResourceProps['fetchFile']][];
  title: string;
}

const ModalFileList: FunctionComponent<ModalFileListProps> = (props) => {
  const { files, title } = props;

  return (
    <ModalContainer>
      <ModalTitle>{title}</ModalTitle>
      <StyledContainer>
        {files.map(([file, fetchFile], index: number) => (
          <StyledDownloadableResource
            dataTestId={`${index}_btn-downloadable-resource-download`}
            fileMetadata={file}
            fetchFile={fetchFile}
            key={file.id}
          />
        ))}
      </StyledContainer>
    </ModalContainer>
  );
};

export default ModalFileList;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-width: 400px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding: 0 16px;
`;

const StyledDownloadableResource = styled(DownloadableResource)`
  width: 100%;
  padding: 16px 0;
`;
