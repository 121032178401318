import React, { FC } from 'react';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import {
  renderRecordType,
  ShouldSendInstComponentTypes,
  SupplierRegistrationProcess,
} from '@mortee/domain/morteeRegistrationForms';
import { renderSpecialInfo } from '@app/components/SpecialInfoComponents';
import useInfraStores from '@app/hooks/useInfraStores';
import styled from '@emotion/styled';
import { Regular22TransparentBlack900, SubtitleSmallStartTransparentBlack400 } from '@app/components/Text';
import Button from '@app/components/Button';
import { openSupplierRegistrationAllDataModal } from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationViewModal';
import useModalContext from '@app/hooks/useModalContext';
import supplierRegistrationProcessIcon from '@mortee/images/validationSystem/supplierRegistrationProcessIcon.svg';
import IconAndText from '@mortee/components/IconAndText';
import EyeOffImage from '@app/images/ic-eye-off.svg';
import SVG from '@app/components/SVG';
import { InnerCardGrey } from '@app/components/card/InnerCard';
import FormRadio from '@app/components/inputs/FormRadio';

import { renderInstructionType } from '@mortee/domain/validationSystemInfoComponents';

type SelectionActions = {
  isSelected: boolean;
  onSelected(): void;
};

interface Props {
  item: SupplierRegistrationProcess;
  titleOnly?: boolean;
  selected?: SelectionActions | false;
  dataTestId: string;
  className?: string;
}

const SupplierRegistrationCard: FC<Props> = ({ item, titleOnly, selected, dataTestId, className }) => {
  const { localeStore } = useInfraStores();
  const modalContext = useModalContext();

  const isSelected = selected && selected.isSelected;

  return (
    <Card className={className} data-testid={dataTestId} isSelected={isSelected}>
      {selected && (
        <LeftPart>
          <FormRadio
            id={`sr-inner-card-check-box-${item.id}`}
            dataTestId='sr-inner-card-check-box'
            label={null}
            value=''
            isChecked={selected.isSelected}
            onChecked={(): void => selected.onSelected()}
          />
        </LeftPart>
      )}
      <MainPart isSelected={isSelected}>
        <TitleLine>
          <div>
            <Regular22TransparentBlack900.div data-testid='sr-company-name'>{item.companyName}</Regular22TransparentBlack900.div>
            <TitleIconsAndTextContainer>
              {item.hidden && <SVG width={24} height={24} image={EyeOffImage} accessibilityLabel='Hidden Record' />}
              <IconAndText
                image={supplierRegistrationProcessIcon}
                iconAccessibilityLabel='supplier registration process icon'
                className={SubtitleSmallStartTransparentBlack400.className}
              >
                <span data-testid='sr-registration-number'>{item.registrationNumber}</span>
              </IconAndText>
            </TitleIconsAndTextContainer>
          </div>
          <Actions>
            <Button
              id='btn-sr-open-all-data'
              appearance='text'
              colorScheme='secondary'
              size='small'
              onClick={(): void => openSupplierRegistrationAllDataModal(item, modalContext)}
            >
              ALL DATA
            </Button>
          </Actions>
        </TitleLine>
        {!titleOnly && (
          <>
            <Separator />
            <TwoColumnGrid>
              <DataGrid>
                <DataGridRow title='Registration time' valueDataTest='sr-registration-time'>
                  {localeStore.formatDateTime(item.writeTimestamp)}
                </DataGridRow>
                <DataGridRow title='Form type' valueDataTest='sr-form-type'>
                  {renderRecordType(item)}
                </DataGridRow>
                <DataGridRow title='Company/Account holder' valueDataTest='sr-holder'>
                  {item.fullName}
                </DataGridRow>
                <DataGridRow title='Instructions' valueDataTest='sr-instructions'>
                  {renderInstructionType(item.instructionType)}
                </DataGridRow>
                <DataGridRow title='Additional company name' valueDataTest='sr-additional-company-name'>
                  {item.additionalCompanyName}
                </DataGridRow>
              </DataGrid>
              <DataGrid>
                <DataGridRow title='Email' valueDataTest='sr-email'>
                  {item.email}
                </DataGridRow>
                <DataGridRow title='Referring Customer' valueDataTest='sr-referring-customer'>
                  {item.referringCustomer}
                </DataGridRow>
                <DataGridRow title='Should send Instructions' valueDataTest='sr-should-send-instructions'>
                  {renderSpecialInfo(ShouldSendInstComponentTypes[item.shouldSendInstructionToSupplier])}
                </DataGridRow>
                <DataGridRow title='Additional Parameters (id)' valueDataTest='sr-additional-id'>
                  {item.registrationId}
                </DataGridRow>
              </DataGrid>
            </TwoColumnGrid>
          </>
        )}
      </MainPart>
    </Card>
  );
};

export default SupplierRegistrationCard;

const Card = styled(InnerCardGrey)<{ isSelected: boolean | undefined }>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'selection content';
  padding: 0;

  --unselected-line-color: var(--transparent-black-200);
  --selected-line-color: var(--transparent-black-600);
  --line-color: ${(p): string => (p.isSelected ? 'var(--selected-line-color)' : 'var(--unselected-line-color)')};
`;

const LeftPart = styled.div`
  grid-area: selection;
  padding: 20px 0 10px;

  border: 1px solid var(--line-color);

  transition: border 0.2s;
`;

const MainPart = styled.div<{ isSelected: boolean | undefined }>`
  grid-area: content;

  padding: 24px 20px;
  border: ${(p): string =>
    p.isSelected ? '1px solid var(--selected-line-color)' : '1px solid var(--inner-card-background-color-grey)'};
  border-left-color: transparent;

  transition: border 0.2s;
`;

const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Separator = styled.hr`
  border-top: solid 1px var(--unselected-line-color);
`;

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: start;
`;

const TitleIconsAndTextContainer = styled.div`
  ${Regular22TransparentBlack900.css};
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
