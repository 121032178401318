import {
  LegalEntityIdentifier,
  LegalEntityIdentifierServerResponse,
  StrongLegalIdentifies,
  StrongLegalIdentifiesServerResponse,
} from '@app/domain/legalEntityIdentifier';
import { isTruthy } from '@app/utils/utils';

export interface PrivatePayeeCompaniesServerResponse {
  companyCode: string | null;
  companyName: string | null;
}

export interface PrivatePayeeDataServerResponse {
  addresses: string[];
  emails: string[];
  phones: string[];
  faxes: string[];
  websites: string[];
  names: string[];
  description?: string | null;
  legalIdentifiers?: LegalEntityIdentifierServerResponse[];
  countryCode?: string;
  supplierNumbers?: string[] | null;
  companies: PrivatePayeeCompaniesServerResponse[];
}

export interface PrivatePayeeServerResponse {
  uniformId: string;
  externalReferenceId: string;
  data: PrivatePayeeDataServerResponse;
  strongLegalIdentifies?: StrongLegalIdentifiesServerResponse;
}

export interface PrivatePayeeCompanies {
  companyCode: string | null;
  companyName: string | null;
}

export interface PrivatePayeeData {
  addresses: string[];
  emails: string[];
  phones: string[];
  faxes: string[];
  websites: string[];
  names: string[];
  description?: string | null;
  legalIdentifiers?: LegalEntityIdentifier[];
  countryCode?: string;
  supplierNumbers?: string[] | null;
  companyCodes: string[];
  companies: PrivatePayeeCompanies[];
}

export interface PrivatePayee {
  uniformId: string;
  externalReferenceId: string;
  data: PrivatePayeeData;
  strongLegalIdentifies?: StrongLegalIdentifies;
}

export function transformPrivatePayee(serverResponse: PrivatePayeeServerResponse): PrivatePayee {
  return {
    ...serverResponse,
    data: {
      ...serverResponse.data,
      companyCodes: transformCompaniesToCompanyText(serverResponse.data.companies),
      supplierNumbers: serverResponse.data.supplierNumbers ?? [],
    },
  };
}

export function transformCompaniesToCompanyText(companies: PrivatePayeeCompanies[] | undefined): string[] {
  const companiesToReturn = companies?.map(transformCompanyToCompanyText) ?? [];

  return companiesToReturn.filter(isTruthy);
}

export function transformCompanyToCompanyText(company: PrivatePayeeCompanies): string {
  if (company.companyName) {
    return `${company.companyCode} - ${company.companyName}`;
  }

  return company.companyCode ?? '';
}

export const SupplierNumberType = 'Supplier number';
