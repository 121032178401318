import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { OrganizationServerResponse } from '@app/domain/userManagement/organizations';
import { ORGANIZATION_HEADER_NAME } from '@app/utils/userIdentityHeadersGenerator';
import { AddServiceToOrganizationRequest } from '@backee/domain/organizationServices';

export async function addServiceToOrganization(
  organizationId: string,
  data: AddServiceToOrganizationRequest,
): Promise<OrganizationServerResponse> {
  return request<OrganizationServerResponse>(serverUrl, `/api/organizations/services`, {
    data,
    method: HttpMethod.post,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot add service to organization.',
      },
    },
  });
}

export async function removeServiceFromOrganization(
  organizationId: string,
  serviceId: string,
): Promise<OrganizationServerResponse> {
  return request<OrganizationServerResponse>(serverUrl, `/api/organizations/services/:serviceId`, {
    method: HttpMethod.delete,
    pathParams: {
      serviceId,
    },
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot remove service from organization.',
      },
    },
  });
}
