import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import ValidateAccountForm from './VerifyAccountForm';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { VerifyAccountFormFields } from '@app/components/accountVerification/SearchAccount';
import { VERIFICATION_ACCOUNT_TYPE } from '@app/domain/accountVerification';
import { UnifiedPayeeIds } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';

interface VerifyAccountFormCardProps {
  payeeIdentifier?: UnifiedPayeeIds;
  isFormDisabled?: boolean;
  payeeCountryCode?: string | null;
  initialFormValues?: VerifyAccountFormFields | null;
  updateFormValues?: (values: VerifyAccountFormFields) => void;
  setSubmitting: (boolean) => void;
  isSubmitting: boolean;
}

const VerifyAccountFormCard: FunctionComponent<VerifyAccountFormCardProps> = observer((props) => {
  const initialFormValuesForForm = (): VerifyAccountFormFields => {
    const { initialFormValues } = props;

    if (initialFormValues) {
      return initialFormValues;
    }

    return {
      type: VERIFICATION_ACCOUNT_TYPE.swift,
      accountDetails: {
        countryCode: '',
        bankCode: '',
        branchCode: '',
        accountNumber: '',
        iban: '',
        swiftCode: '',
      },
    };
  };

  return (
    <StyledPayeeCard>
      <StyledValidateAccountForm
        initialValues={initialFormValuesForForm()}
        isFormDisabled={props.isFormDisabled}
        payeeIdentifier={props.payeeIdentifier}
        updateFormValues={props.updateFormValues}
        setSubmitting={props.setSubmitting}
        isSubmitting={props.isSubmitting}
        payeeCountryCode={props.payeeCountryCode}
      />
    </StyledPayeeCard>
  );
});

export default VerifyAccountFormCard;

const StyledPayeeCard = styled(Card)`
  padding: 12px 32px 8px 32px;
  position: relative;
  z-index: 1;
`;

const StyledValidateAccountForm = styled(ValidateAccountForm)`
  width: 100%;
`;
