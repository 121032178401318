import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { InputBoxAppearance } from '@app/components/inputs/inputBox/InputBox';

export interface ARThemeServerResponse {
  customerName: string;
  headerColor: string;
  actionColor: string;
  continueButtonColor?: string;
  logo: ResourceData;
  inputStyle?: InputBoxAppearance;
  additionalLogos?: ResourceData[];
  organizationId: string;
  fontFamily?: string;
  backgroundColor?: string;
}

export interface ARThemeConfiguration {
  clientName: string;
  headerColor: string;
  actionColor: string;
  continueButtonColor?: string;
  logo: ResourceData;
  inputStyle?: InputBoxAppearance;
  additionalLogos?: ResourceData[];
  organizationId: string;
  fontFamily?: string;
  backgroundColor?: string;
}

export async function getARConfigurationBySubdomain(subdomain: string): Promise<ARThemeServerResponse> {
  return request<ARThemeServerResponse>(serverUrl, `/api/guest/configuration/subdomain/:subdomain`, {
    method: HttpMethod.get,
    pathParams: {
      subdomain,
    },
    dontRedirectToLogin: true,
    suppressNotification: true,
  });
}

export function transformATThemeServerResponse(response: ARThemeServerResponse): ARThemeConfiguration {
  return { ...response, clientName: response.customerName };
}
