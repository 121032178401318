export const SUPPLIER_VALIDATION_FIELD_MAX_LENGTH = {
  supplierName: 200,
  companyCode: 50,
  initiatorEmail: 255,
  emailId: 1000,
  comment: 1000,
  alert: 1000,
  svLinkId: 20,
};

export const SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH = {
  name: 100,
  additionalName: 100,
  address: 200,
  additionalAddress: 200,
};

export const SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH = {
  bankName: 100,
  bankCode: 25,
  branchCode: 25,
  accountNumber: 35,
  iban: 40,
  furtherCredit: 100,
};

export const EMAIL_ID_PATTERN = /^<(.+@.+)>$/;
