import React from 'react';
import { InstructionFormDetails } from '../../commonAfterFormPage/InstructionFormDetails';
import { NormalText } from '../../Styles';

export const BarclaysGBPFormDetails: InstructionFormDetails = {
  instructionsSubTitle: 'supplierValidation.afterRegister.barclaysGBP.nowYouAreRegistered',
  paymentInstructionsTitle: 'supplierValidation.afterRegister.paymentInst',
  paymentInstructionsLines: [
    {
      description: 'supplierValidation.afterRegister.beneficiaryName',
      value: 'nsKnox Technologies, Ltd.',
    },
    { description: 'supplierValidation.afterRegister.checkingAccount', value: '33111903' },
    { description: 'supplierValidation.afterRegister.sortCode', value: '200000' },
    { description: 'supplierValidation.afterRegister.swift', value: 'BARCGB22' },
    { description: 'supplierValidation.afterRegister.iban', value: 'GB32BARC20000033111903' },
  ],
  paymentInstructionsAddress: {
    description: 'supplierValidation.afterRegister.bankAndAddress',
    value: (
      <NormalText>
        Barclays Bank PLC
        <br />
        1 Churchill Place,
        <br />
        London E14 5HP
      </NormalText>
    ),
  },
  currencies: [],
  pleaseNote: {
    pleaseNoteTitle: 'supplierValidation.afterRegister.pleaseNote',
    pleaseNoteItem: ['supplierValidation.afterRegister.transferMustBe'],
  },
  showWireTransferWarningBox: {
    achWireTransfer: false,
    swiftWireTransfer: false,
  },
  extraInstructions: null,
};
