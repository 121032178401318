export interface TenantUserServerResponse {
  tenantUserId: string;
  name: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  organizationIds: string[];
  webAppRoleIds: Record<string, string[]>;
}

export interface UserServerResponse {
  tenantUserId: string;
  name: string;
  email: string;
  phoneNumber: string;
}

export interface KnoxerAllIdentityProvidersAssignedToUserServerResponse {
  knoxerId: String;
  identities: IdentityProviderAssignedToUserServerResponse[];
}

interface IdentityProviderAssignedToUserServerResponse {
  identityProviderName: string;
  id: string;
}

export interface KnoxerAllIdentityProvidersAssignedToUser {
  knoxerId: String;
  identities: IdentityProviderAssignedToUser[];
}

interface IdentityProviderAssignedToUser {
  identityProviderName: string;
  id: string;
}

export interface RemoveWebUserRoleRequest {
  webAppRoleId: string;
}

export interface AddWebUserRoleRequest {
  webAppRoleId: string;
}

export const USER_FIELD_MAX_LENGTH = {
  name: 200,
  email: 255,
  phone: 255,
  ssoId: 255,
};

export const SEARCH_USERS_MIN_LENGTH = {
  query: 3,
};
