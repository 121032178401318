import Mode from '@app/modes/Mode';
import React, { ComponentType } from 'react';
import ARAppContent from '@ar/routes/app/ARAppContent';
import ARHeader from '@ar/routes/app/ARHeader';
import ARInfraStores from '@ar/stores/infraStores/ARInfraStores';
import ARAppStores from '@ar/stores/appStores/ARAppStores';
import GuestVerificationInitialPage from '@ar/routes/GuestVerificationInitialPage';
import GuestVerificationWithPayeePage from '@ar/routes/GuestVerificationWithPayeePage';
import GuestVerificationWithCertificatePage from '@ar/routes/GuestVerificationWithCertificatePage';
import GuestVerificationChooseSearch from '@ar/routes/GuestVerificationChooseSearch';
import GuestVerificationChooseBac from '@ar/routes/GuestVerificationChooseBac';
import withARConfiguration from '@ar/routes/withARConfiguration';

class ArMode extends Mode<ARInfraStores, ARAppStores> {
  constructor() {
    super(sectionDefinitions);
  }

  getAppContentComponent(): ComponentType {
    return withARConfiguration(ARAppContent);
  }

  getHeadTitle(): string {
    return 'nsKnox Validation';
  }

  createInfraStores(): ARInfraStores {
    return new ARInfraStores(this.sections, this.getLocalePath());
  }

  createAppStores(): ARAppStores {
    return new ARAppStores();
  }

  getDefaultHeaderComponent(): React.ComponentType {
    return ARHeader;
  }

  getLocalePath(): string {
    return 'locales/ar/list.json';
  }
}

export default ArMode;

const sectionDefinitions: SectionDefinition[] = [
  {
    id: 'verificationForGuests',
    name: 'Account verification',
    isAnonymous: true,
    requiredRoles: [],
    requiredServices: [],
    routes: [
      {
        id: 'guestVerificationStartPage',
        path: '/',
        redirectFromPaths: ['/verify'],
        component: withARConfiguration(GuestVerificationInitialPage),
        requiredRoles: [],
        requiredServices: [],
        innerRoutes: [
          {
            id: 'guestVerificationChooseSearch',
            path: '/search-payee',
            component: withARConfiguration(GuestVerificationChooseSearch),
            requiredRoles: [],
            requiredServices: [],
          },
          {
            id: 'guestVerificationChooseBac',
            path: '/certificate-upload',
            component: withARConfiguration(GuestVerificationChooseBac),
            requiredRoles: [],
            requiredServices: [],
          },
          {
            id: 'guestVerificationWithPayee',
            path: '/payee/:validatedPayeeUniformId',
            component: withARConfiguration(GuestVerificationWithPayeePage),
            requiredRoles: [],
            requiredServices: [],
          },
          {
            id: 'guestVerificationWithCertificate',
            path: '/certificate',
            component: withARConfiguration(GuestVerificationWithCertificatePage),
            requiredRoles: [],
            requiredServices: [],
          },
        ],
      },
    ],
  },
];
