import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import RiverImage from '@app/images/river.svg';
import { ifProp } from 'styled-tools';
import SVG from '@app/components/SVG';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';

interface ErrorMessageProps {
  image?: string;
  title?: ReactNode;
  description?: ReactNode;
  responsive?: boolean;
  fullscreen?: boolean;
}

/*
  DO NOT use theme or props/dependencies that require variables from
  other places because this component can be the highest component
*/

const ErrorMessage: FunctionComponent<ErrorMessageProps> = (props) => {
  const { image, title, responsive, description, fullscreen } = props;

  return (
    <Container fullscreen={fullscreen} responsive={responsive}>
      {image ? <img src={image} alt='content not found' /> : <SVG accessibilityLabel='content not found' image={RiverImage} />}
      <Title>{title || 'Oh My!'}</Title>
      <Description>
        {description || (
          <span>
            We didn&#39;t see that coming.
            <br />
            Try again or contact support.
          </span>
        )}
      </Description>
    </Container>
  );
};

export default ErrorMessage;

/*
  DO NOT use theme or props/dependencies that require variables from
  other places because this component can be the highest component
*/

const Container = styled.div<{ fullscreen?: boolean; responsive?: boolean }>`
  ${ifProp('fullscreen', 'position: absolute; padding: 5vw 5vh; height: 100%;')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: black;
  text-align: center;
  width: 100%;
  font-weight: 300;

  > img,
  svg {
    ${ifProp('responsive', 'padding-bottom: 1vw;')};

    width: 30%;
    };
  }
`;

const Title = styled.div`
  font-size: 5vw;
  color: #000;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  font-size: 2vw;
  color: gray;
  white-space: normal;
  ${WRAP_WORDS_CSS_PROPERTIES}
`;
