import React, { FC, ReactElement, ReactNode } from 'react';
import {
  allSupplierValidationRecordInstructionTypesWithCalculated,
  Assignee,
  AUTOMAILER_STATUS_UNAVAILABLE_TITLE,
  AUTOMAILER_STATUS_UNAVAILABLE_VALUE,
  EVIDENCE_TYPE_NOT_PROVIDED_TITLE,
  EVIDENCE_TYPE_NOT_PROVIDED_VALUE,
  EvidenceType,
  evidenceTypeText,
  getSVMasterFileAutomaticEmailStatusText,
  noOrgOption,
  SupplierValidationRecord,
  SupplierValidationRecordInstructionTypesSubsets,
  SupplierValidationRecordStatus,
  SupplierValidationRecordStatusObject,
  SVMasterFileAutomaticEmailStatus,
  ValidationSystemOrganization,
} from '@mortee/domain/validationSystem';
import Loadable from '@app/utils/Loadable';
import CardTable from '@app/components/tables/CardTable';
import { SorterResult, TableEventListeners } from 'antd/lib/table/interface';
import { ShouldSendInstComponentTypes, ShouldSendInstructionsToSupplier } from '@mortee/domain/morteeRegistrationForms';
import { ExtendedColumnProps, TableImportantText, TableStandardText } from '@app/components/tables/Table';
import { FormattedDateTime } from '@app/components/Locale';
import { css } from '@emotion/css';
import CopyableValue from '@app/components/CopyableValue';
import classNames from 'classnames';
import TooltipWhenEllipsis from '@app/components/TooltipWhenEllipsis';
import {
  convertAntdFilterToOurFilters,
  convertAntdSortToOurs,
  convertOurFiltersToAntdFilters,
  getAntdSortForField,
  SupplierValidationRecordFieldFilters,
  SupplierValidationRecordSortableField,
  SupplierValidationRecordSorting,
  SupplierValidationRecordTableColumn,
  validationRecordFilterEqual,
  validationRecordSortingEqual,
} from '@mortee/domain/validationRecordTable';
import { PaginationConfig } from 'antd/lib/pagination';
import { getColumnCustomTimeServerFilterProps } from '@app/components/tableFilters/dateValue/byColumnCustomTimeFilterCreator';
import { DateRange, YesNoFilterOption, YesNoOptions } from '@app/domain/filters';
import { getColumnMultiValueConstSelectServerFilterProps } from '@app/components/tableFilters/multiValue/constsFilterCreator';
import { MultiselectValue } from '@app/components/tableFilters/multiValue/FilterColumnMultiValuePopup';
import SelectStatusInput from '@mortee/routes/validationSystem/statusEdit/SelectStatusInput';
import {
  getSvmInstructionType,
  renderInstructionType,
  renderSvmInstructionType,
  renderSvmInstructionTypeWithDoubleBorder,
  validationRecordStatusSpecialComponents,
} from '@mortee/domain/validationSystemInfoComponents';
import TableEmptyData from '@app/components/tables/TableEmptyData';
import TableEmptyFilteredData from '@app/components/tables/TableEmptyFilteredData';
import ErrorLoadingData from '@app/components/ErrorLoadingData';
import { renderSpecialInfo, SpecialInfoBadge } from '@app/components/SpecialInfoComponents';
import { getColumnSingleServerFilterProps } from '@app/components/tableFilters/singleValue/constsFilterCreator';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { ModalAppContextProps } from '@app/ModalAppContext';
import { openSupplierRegistrationAllDataModal } from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationViewModal';
import useModalContext from '@app/hooks/useModalContext';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import { isDefined } from '@app/utils/utils';
import SelectAssigneeInput from '@mortee/routes/validationSystem/assigneeEdit/SelectAssigneeInput';
import AssigneeWithInitials from '@mortee/components/AssigneeWithInitials';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { compare } from '@app/utils/comparatorUtils';
import TransitionLoader from '@app/components/TransitionLoader';

interface Props {
  header?: ReactNode;
  columnsToShow: SupplierValidationRecordTableColumn[];
  recordsLoadable: Loadable<SupplierValidationRecord[]>;
  onStatusChange: (staticId: string, status: SupplierValidationRecordStatusObject) => void;
  allUsersLoadable: Loadable<Assignee[]>;
  assignableUsersLoadable: Loadable<Assignee[]>;
  allOrganizationsLoadable: Loadable<ValidationSystemOrganization[]>;
  loadingRowIds: string[];
  selectedRowId: string | null;
  filters: SupplierValidationRecordFieldFilters | undefined;
  isTableFiltered: boolean;
  sort: SupplierValidationRecordSorting | undefined;
  currentPage: number;
  pageSize: number;
  totalAmountOfItems: Loadable<number>;
  disableTableOperations?: boolean;
  className?: string;
  onRecordClick(staticId: string): void;
  onNewValidationClick(): void;
  onFiltersChange(newFilters: SupplierValidationRecordFieldFilters): void;
  onSortChange(newSort: SupplierValidationRecordSorting | undefined): void;
  onPageChange(newPage: number): void;
  onAssigneeChange: (staticId: string, assigneeId: string | null | undefined) => void;
}

export interface SupplierValidationRecordAntdTableFilters {
  status: SupplierValidationRecordStatus[] | undefined;
  assignee: string[] | undefined;
  didUserRegister: boolean | undefined;
  organizationId: string[] | undefined;
  registrationDate: DateRange[] | undefined;
  automailerStatus: string[] | undefined;
  evidenceType: string[] | undefined;
  customerInvolvement: YesNoFilterOption | undefined;
  instructionType: string[] | undefined;
  followUpFilter: boolean | undefined;
}

const SupplierValidationRecordTable: FC<Props> = (props) => {
  const modalContext = useModalContext();

  const {
    header,
    columnsToShow,
    recordsLoadable,
    onStatusChange,
    onRecordClick,
    onNewValidationClick,
    allUsersLoadable,
    assignableUsersLoadable,
    allOrganizationsLoadable,
    selectedRowId,
    loadingRowIds,
    filters,
    onFiltersChange,
    isTableFiltered,
    sort,
    onSortChange,
    currentPage,
    onPageChange,
    pageSize,
    totalAmountOfItems,
    disableTableOperations,
    className,
    onAssigneeChange,
  } = props;

  function handlePageChange(newPagination: PaginationConfig): void {
    if (newPagination.current) {
      onPageChange(newPagination.current);
    }
  }

  function handleSortChange(newAntdSort: SorterResult<SupplierValidationRecord>): void {
    const newSort = convertAntdSortToOurs(newAntdSort);

    if (validationRecordSortingEqual(newSort, sort)) {
      return;
    }

    onSortChange(newSort);
  }

  function handleFiltersChange(newAntdFilters: SupplierValidationRecordAntdTableFilters): void {
    const newFilters = convertAntdFilterToOurFilters(newAntdFilters);

    if (!validationRecordFilterEqual(filters, newFilters)) {
      onFiltersChange(newFilters);
    }
  }

  function onChange(
    newPagination: PaginationConfig,
    newAntdFilters: Partial<Record<keyof SupplierValidationRecord, string[]>>,
    newAntdSort: SorterResult<SupplierValidationRecord>,
  ): void {
    handlePageChange(newPagination);
    handleSortChange(newAntdSort);
    handleFiltersChange(newAntdFilters as SupplierValidationRecordAntdTableFilters);
  }

  function renderInstructionForRecord(record: SupplierValidationRecord): ReactNode | null {
    if (
      !isDefined(record.manualInstructionType) &&
      !isDefined(record.supplierRegistrationProcess?.shouldSendInstructionToSupplier)
    ) {
      return null;
    }
    if (record.manualInstructionType) {
      return renderSvmInstructionTypeWithDoubleBorder(record.manualInstructionType);
    }
    if (
      record.supplierRegistrationProcess?.shouldSendInstructionToSupplier ===
        ShouldSendInstructionsToSupplier.checkCrowdKnowledgeAccount ||
      record.supplierRegistrationProcess?.shouldSendInstructionToSupplier ===
        ShouldSendInstructionsToSupplier.checkValidatedPayeeAccount
    ) {
      return renderSpecialInfo(ShouldSendInstComponentTypes[record.supplierRegistrationProcess?.shouldSendInstructionToSupplier]);
    }
    return (
      <div>
        {renderInstructionType(record.supplierRegistrationProcess?.instructionType)}
        <InstructionType>From registration</InstructionType>
      </div>
    );
  }

  function renderEmptyTableComponent(): ReactElement {
    if (recordsLoadable.isRejected()) {
      const error = recordsLoadable.stateMetadata?.error;
      return <ErrorLoadingData error={error} />;
    }

    if (isTableFiltered) {
      return (
        <TableEmptyFilteredData
          content={`There are no results matching your current filter criteria. 
Try adjusting your search or filter to find what you are looking for. `}
        />
      );
    }

    return (
      <TableEmptyData
        content='There is currently no records available'
        button={{
          id: 'btn-supplier-validation-management-table-create-record',
          onClick: onNewValidationClick,
          children: '+ Create the first validation record',
        }}
      />
    );
  }

  function localOnStatusChange(staticId: string, status: SupplierValidationRecordStatusObject): void {
    onStatusChange(staticId, status);
  }

  const { permissionsStore } = useInfraStores<MorteeMode>();

  const columns = createColumns(
    columnsToShow,
    loadingRowIds,
    convertOurFiltersToAntdFilters(filters),
    sort,
    allUsersLoadable,
    assignableUsersLoadable,
    allOrganizationsLoadable,
    localOnStatusChange,
    modalContext,
    renderInstructionForRecord,
    onAssigneeChange,
    permissionsStore.isValidationApprover,
  );

  function getRowClassName(record: SupplierValidationRecord): string {
    if (loadingRowIds.includes(record.staticId)) {
      return disabledRowClassName;
    }

    if (selectedRowId === record.staticId) {
      return selectedRowClassName;
    }

    return '';
  }

  return (
    <CardTable
      data-testid='SupplierValidationRecordsTable'
      dataSource={recordsLoadable.result ?? []}
      columns={columns}
      loading={recordsLoadable.isInProgress()}
      header={header}
      rowClassName={getRowClassName}
      rowClickable
      onRow={(record: SupplierValidationRecord): TableEventListeners => ({
        onClick: (): void => {
          onRecordClick(record.staticId);
        },
      })}
      onChange={onChange}
      pagination={{
        pageSize,
        current: currentPage,
        total: totalAmountOfItems.result,
        hideOnSinglePage: false,
      }}
      rowKey={(record): string => record.staticId}
      scroll={{ x: true }}
      disableTableOperations={disableTableOperations}
      emptyComponent={renderEmptyTableComponent}
      className={className}
    />
  );
};

export default SupplierValidationRecordTable;

const EMPTY_VALUE_CELL = '-';

const recordEvidenceTypeTableOptions: MultiselectValue[] = [
  ...Object.values(EvidenceType).map((type) => ({
    text: evidenceTypeText[type],
    value: type,
  })),
  { text: EVIDENCE_TYPE_NOT_PROVIDED_TITLE, value: EVIDENCE_TYPE_NOT_PROVIDED_VALUE },
];

const recordStatusTableOptions: MultiselectValue[] = Object.values(SupplierValidationRecordStatus).map((status) => ({
  text: validationRecordStatusSpecialComponents.render(status),
  value: status,
}));

const recordInstructionTypesTableOptions: MultiselectValue[] = Object.values(
  allSupplierValidationRecordInstructionTypesWithCalculated.filter((instruction) =>
    SupplierValidationRecordInstructionTypesSubsets.svmTableFilter.includes(instruction),
  ),
).map((instruction) => ({
  text: renderSvmInstructionType(instruction),
  value: instruction,
  keywords: [instruction, getSvmInstructionType(instruction)?.name ?? ''],
}));

const recordAutomailerStatusTableOptions: MultiselectValue[] = [
  ...Object.values(SVMasterFileAutomaticEmailStatus).map((status) => ({
    text: getSVMasterFileAutomaticEmailStatusText(status),
    value: status,
  })),
  { text: AUTOMAILER_STATUS_UNAVAILABLE_TITLE, value: AUTOMAILER_STATUS_UNAVAILABLE_VALUE },
];

function createColumns(
  columnsToShow: SupplierValidationRecordTableColumn[],
  loadingRowsIds: string[],
  filters: SupplierValidationRecordAntdTableFilters | undefined,
  sort: SupplierValidationRecordSorting | undefined,
  allUsersLoadable: Loadable<Assignee[]>,
  assignableUsersLoadable: Loadable<Assignee[]>,
  allOrganizationsLoadable: Loadable<ValidationSystemOrganization[]>,
  onStatusChange: (staticId: string, status: SupplierValidationRecordStatusObject) => void,
  modalContext: ModalAppContextProps,
  renderInstructionForRecord: (record: SupplierValidationRecord) => ReactNode,
  onAssigneeChange: (staticId: string, assigneeId: string | null | undefined) => void,
  isApproverRole: boolean,
): ExtendedColumnProps<SupplierValidationRecord>[] {
  function getOrgName(record: SupplierValidationRecord): string {
    return record?.organization?.name ?? 'Unknown';
  }

  const allColumns: Record<SupplierValidationRecordTableColumn, ExtendedColumnProps<SupplierValidationRecord>> = {
    [SupplierValidationRecordTableColumn.id]: {
      title: <div data-testid='lblId'>Id</div>,
      key: 'presentationId',
      fixed: 'left',
      className: copyableColumnClassName,
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => {
        return (
          <TableImportantText>
            <TransitionLoader
              loading={loadingRowsIds.includes(record.staticId)}
              semiHideContent
              small
              smallSize={25}
              customColor='var(--accent-blue-600)'
            >
              <CopyableValue dataTestId='presentationId-copyable' label='Id' value={record.presentationId} />
            </TransitionLoader>
          </TableImportantText>
        );
      },
    },
    [SupplierValidationRecordTableColumn.evidenceType]: {
      title: <div data-testid='lblEvidenceType'>Evidence</div>,
      key: SupplierValidationRecordTableColumn.evidenceType,
      fixed: 'left',
      width: '140px',
      ...getColumnMultiValueConstSelectServerFilterProps(recordEvidenceTypeTableOptions, filters?.evidenceType),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => (
        <TableStandardText data-testid='supplier-validation-management-table-record-evidence-type'>
          {record.evidence?.type ? evidenceTypeText[record.evidence.type] : EVIDENCE_TYPE_NOT_PROVIDED_TITLE}
        </TableStandardText>
      ),
    },
    [SupplierValidationRecordTableColumn.customerInvolvement]: {
      title: <div data-testid='lblCustomerInvolvement'>Customer Involvement</div>,
      key: SupplierValidationRecordTableColumn.customerInvolvement,
      width: '140px',
      ...getColumnSingleServerFilterProps('Customer Involvement', YesNoOptions, filters?.customerInvolvement),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode =>
        record.isCustomerInvolvementRequired ? <SpecialInfoBadge colorScheme='red'>Yes</SpecialInfoBadge> : null,
    },
    [SupplierValidationRecordTableColumn.supplierName]: {
      title: <div data-testid='lblSupplierName'>Supplier name</div>,
      key: 'supplierName',
      fixed: 'left',
      className: classNames(limitFreeTextColumnClassName, copyableColumnClassName),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => (
        <TableStandardText>
          <CopyableValue dataTestId='supplierName-copyable' label='Supplier name' value={record.supplierName}>
            <TooltipWhenEllipsis title={record.supplierName}>{record.supplierName}</TooltipWhenEllipsis>
          </CopyableValue>
        </TableStandardText>
      ),
    },
    [SupplierValidationRecordTableColumn.organizationId]: {
      title: <div data-testid='lblCustomer'>Customer</div>,
      key: SupplierValidationRecordTableColumn.organizationId,
      className: classNames(limitFreeTextColumnClassName, copyableColumnClassName),
      ...getColumnMultiValueConstSelectServerFilterProps(
        allOrganizationsLoadable.map((allOrganizations) => {
          return noOrgOption
            .concat(allOrganizations)
            .sort(compare.byStringField((record) => record.name))
            .map((organization) => {
              return {
                text: organization.name,
                value: organization.id,
                keywords: [organization.name],
              };
            });
        }),
        filters?.organizationId,
        true,
      ),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => (
        <TableStandardText>
          <CopyableValue dataTestId='organization-copyable' label='Customer' value={getOrgName(record)}>
            <TooltipWhenEllipsis title={getOrgName(record)}>{getOrgName(record)}</TooltipWhenEllipsis>
          </CopyableValue>
        </TableStandardText>
      ),
    },
    [SupplierValidationRecordTableColumn.registrationForm]: {
      title: <div data-testid='lblRegistrationFormRef'>Registration forms Ref</div>,
      key: 'RegistrationFormRef',
      width: '150px',
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => {
        const supplierRegistrationProcess = record.supplierRegistrationProcess;

        if (record.isOfflineForm) {
          return <TableStandardText>Offline Process</TableStandardText>;
        }

        if (!supplierRegistrationProcess) {
          return;
        }

        return (
          <ClickEventPropagationBlocker>
            <Button
              id='supplier-validation-record-item-menu-sv-ref'
              onClick={(): void => openSupplierRegistrationAllDataModal(supplierRegistrationProcess, modalContext)}
              appearance='text'
              cornerType='circle'
              colorScheme='grey'
            >
              {supplierRegistrationProcess.registrationNumber}
            </Button>
          </ClickEventPropagationBlocker>
        );
      },
    },
    [SupplierValidationRecordTableColumn.registrationDate]: {
      title: <div data-testid='lblRegistrationWriteTimestamp'>Registration date</div>,
      key: SupplierValidationRecordTableColumn.registrationDate,
      sorter: true,
      sortOrder: getAntdSortForField(sort, SupplierValidationRecordSortableField.registrationDate),
      width: '190px',
      ...getColumnCustomTimeServerFilterProps(filters?.registrationDate),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => (
        <TableStandardText>
          {record.registrationDate ? <FormattedDateTime value={record.registrationDate} /> : EMPTY_VALUE_CELL}
        </TableStandardText>
      ),
    },
    [SupplierValidationRecordTableColumn.currentInstruction]: {
      title: <div data-testid='lblInstructions'>Current Instructions</div>,
      key: 'instructionType',
      width: '200px',
      ...getColumnMultiValueConstSelectServerFilterProps(recordInstructionTypesTableOptions, filters?.instructionType, true),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => {
        return <TableStandardText>{renderInstructionForRecord(record) ?? EMPTY_VALUE_CELL}</TableStandardText>;
      },
    },
    [SupplierValidationRecordTableColumn.status]: {
      title: <div data-testid='lblStatus'>Status</div>,
      key: SupplierValidationRecordTableColumn.status,
      width: '150px',
      ...getColumnMultiValueConstSelectServerFilterProps(recordStatusTableOptions, filters?.status),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => {
        return (
          <TableStandardText data-testid='supplier-validation-management-table-record-status'>
            <StatusRow>
              <SelectStatusInput
                id='select-supplier-validation-management-table-status'
                accessibilityLabel='Change record status'
                value={record.status}
                onChange={(newStatus): void => newStatus && onStatusChange(record.staticId, newStatus)}
                disabled={!isApproverRole && record.status.value === SupplierValidationRecordStatus.waitingForApproval}
              />
            </StatusRow>
          </TableStandardText>
        );
      },
    },
    [SupplierValidationRecordTableColumn.automailerStatus]: {
      title: <div data-testid='lblMailerStatus'>Automailer status</div>,
      key: SupplierValidationRecordTableColumn.automailerStatus,
      width: '150px',
      ...getColumnMultiValueConstSelectServerFilterProps(recordAutomailerStatusTableOptions, filters?.automailerStatus),
      render: (_: any, record: SupplierValidationRecord): React.ReactNode => (
        <TableStandardText data-testid='supplier-validation-management-table-record-mailer-status'>
          {record.emailStatus ? getSVMasterFileAutomaticEmailStatusText(record.emailStatus) : AUTOMAILER_STATUS_UNAVAILABLE_TITLE}
        </TableStandardText>
      ),
    },
    [SupplierValidationRecordTableColumn.assignee]: {
      title: <div data-testid='lblAssignee'>Assignee</div>,
      key: SupplierValidationRecordTableColumn.assignee,
      className: limitFreeTextColumnClassName,
      ...getColumnMultiValueConstSelectServerFilterProps(
        allUsersLoadable.map((allUsers) => {
          return [
            {
              text: <AssigneeWithInitials assignee={null} />,
              value: 'unassigned',
            },
          ].concat(
            allUsers.map((user) => {
              return {
                text: <AssigneeWithInitials assignee={user} greyedOut={!user.isAssignable} />,
                value: user.id,
              };
            }),
          );
        }),
        filters?.assignee,
      ),
      render: (text: any, record: SupplierValidationRecord): React.ReactNode => (
        <TableStandardText>
          <SelectAssigneeInput
            id='select-supplier-validation-management-table-assignee'
            accessibilityLabel='Change record assignee'
            value={record.assignee ?? undefined}
            onChange={(newAssignee): void => onAssigneeChange(record.staticId, newAssignee?.id)}
            assignableUsersLoadable={assignableUsersLoadable}
          />
        </TableStandardText>
      ),
    },
  };

  return columnsToShow.map((columnToShow) => allColumns[columnToShow]);
}

const limitFreeTextColumnClassName = css`
  max-width: 300px;
`;

const copyableColumnClassName = css`
  padding-right: 4px !important;

  thead & {
    padding-right: 16px !important;
  }
`;

const disabledRowClassName = css`
  opacity: 0.3;
  pointer-events: none;
`;

const selectedRowClassName = css`
  background-color: #b9ddff !important;

  td {
    background-color: #b9ddff !important;
  }
`;

const StatusRow = styled.div`
  display: flex;
  align-items: center;
`;

const InstructionType = styled.div`
  display: flex;
  flex-direction: column;
`;
