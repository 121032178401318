import React, { FC } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import SearchOvalIcon from '@app/images/search-oval.svg';
import SVG from '@app/components/SVG';
import { Regular18TransparentBlack900 } from '@app/components/Text';

const EmptyFilterResults: FC = () => {
  return (
    <MainCard>
      <SVG accessibilityLabel='' image={SearchOvalIcon} width={72} height={72} />
      <MainText>0 results found for the filters applied</MainText>
    </MainCard>
  );
};

export default EmptyFilterResults;

const MainCard = styled(Card)`
  height: max(270px, min(100vh - 275px, 550px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const MainText = styled(Regular18TransparentBlack900.div)`
  text-align: center;
`;
