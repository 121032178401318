import { DependencyList, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { ModalFuncProps } from 'antd/lib/modal/Modal';
import useControlledModal from '@app/hooks/useControlledModal';

export default function useUpdatingModal<T>(
  content: (onDone: () => void) => ReactNode,
  options: ModalFuncProps = {},
  dependencies: DependencyList,
  initialVisibility: boolean = false,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [visible, setVisible] = useState<boolean>(initialVisibility);

  useControlledModal(
    visible,
    (onDone) => content(() => onDone(true)),
    () => setVisible(false),
    options,
    dependencies,
  );

  return [visible, setVisible];
}
