import OrganizationsStore from '@backee/stores/appStores/OrganizationsStore';
import UserRoleStore from '@backee/stores/appStores/UserRoleStore';
import IdentityProviderStore from '@backee/stores/appStores/IdentityProviderStore';
import KnoxersStore from '@app/stores/KnoxersStore';

export default class BackeeAppStores {
  readonly knoxersStore: KnoxersStore;
  readonly organizationsStore: OrganizationsStore;
  readonly userRolesStore: UserRoleStore;
  readonly identityProvidersStore: IdentityProviderStore;

  constructor() {
    this.knoxersStore = new KnoxersStore();
    this.organizationsStore = new OrganizationsStore();
    this.userRolesStore = new UserRoleStore();
    this.identityProvidersStore = new IdentityProviderStore();
  }
}
