import { css, SerializedStyles } from '@emotion/react';
import { isDefined } from '@app/utils/utils';

export type CornerAppearance = 'all' | 'top' | 'left' | 'right' | 'circle' | 'none';

const getCardCornersCssClass = (
  cornerType: CornerAppearance = 'all',
  customBackgroundColor: string = 'white',
  strokeColor?: string,
  opacity: number = 1,
  strokeOpacity: number = 1,
): SerializedStyles => {
  return css`
    ${getBorderRadiusPropertyByCornerType(cornerType)};
    background-color: ${calculateFullColor(customBackgroundColor, opacity)};
    ${strokeColor ? `border: 1px solid ${calculateFullColor(strokeColor, strokeOpacity)};` : ''};
  `;
};

export default getCardCornersCssClass;

function getBorderRadiusPropertyByCornerType(cornerType: CornerAppearance): string {
  switch (cornerType) {
    case 'none': {
      return '';
    }
    case 'left': {
      return 'border-radius: 4px 0 0 4px';
    }
    case 'right': {
      return 'border-radius: 0 4px 4px 0';
    }
    case 'top': {
      return 'border-radius: 4px 4px 0 0';
    }
    case 'circle': {
      return 'border-radius: 100000px';
    }
    case 'all': {
      return 'border-radius: 4px';
    }
  }
}

function calculateFullColor(color: string, opacity: number | undefined = 1): string {
  if (opacity === 1 || !isDefined(opacity) || !color.startsWith('#')) {
    return color;
  }

  const rawHex = Math.round(opacity * 255);
  let hex = rawHex.toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }

  return `${color}${hex}`;
}
