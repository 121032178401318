import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import BigModal from '@app/components/BigModal';
import { css } from '@emotion/css';
import VerificationPayeeAndAccountsChanges, {
  PayeeAndAccountsWithWarnings,
} from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationPayeeAndAccountsChanges';

interface Props {
  payeeWithAccountsWarnings: PayeeAndAccountsWithWarnings;
  supplierValidationRecord: SupplierValidationRecord;
  onClose: () => void;
}

const VerificationReviewWarningsModal: FC<Props> = ({ onClose, payeeWithAccountsWarnings, supplierValidationRecord }) => {
  function renderActionButtons(): ReactNode {
    return (
      <Button
        id='btn-create-verification-payee-and-accounts-check-warnings-modal-close'
        appearance='full'
        colorScheme='secondary'
        onClick={onClose}
      >
        CLOSE
      </Button>
    );
  }

  return (
    <Main closeFunc={onClose} title='Verification Warnings' titleLineClassName={stickyTitleLine}>
      <VerificationPayeeAndAccountsChanges
        payeeWithAccountsWarnings={payeeWithAccountsWarnings}
        supplierValidationRecord={supplierValidationRecord}
      />
      <ActionsContainer>{renderActionButtons()}</ActionsContainer>
    </Main>
  );
};

export default VerificationReviewWarningsModal;

const Main = styled(BigModal)`
  width: min(1050px, 90vw);
  background: #eeeeee;

  max-height: 95vh;
  overflow: auto;

  // Horizontal padding will be applied by the content
  padding: 0;

  --side-padding: 24px;
  --card-padding: 16px 24px;
`;

const stickyTitleLine = css`
  background: white;
  position: sticky;
  top: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  // To allow the buttons drop shadow to fully show
  padding: var(--card-padding);

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
