import React, { FC, ReactNode } from 'react';
import useLoadable from '@app/hooks/loadable/useLoadable';
import PageWithHeader from '@app/components/PageWithHeader';
import ManageOrganizationUsers from '@app/components/userManagement/ManageOrganizationUsers';
import useInfraStores from '@app/hooks/useInfraStores';
import { transformKnoxerIdentityProviders } from '@app/domain/userManagement/identityProviders';
import { observer } from 'mobx-react';
import Loader from '@app/components/Loader';
import { getAllIdentityProvidersOfOrganization } from '@app/services/userManagement/organizationIdentityProviderServices';
import MorteeMode from '@mortee/morteeMode';
import { Organization } from '@app/domain/userManagement/organizations';

const ManageOrganizationPage: FC = observer(() => {
  const { userStore, knoxersStore } = useInfraStores<MorteeMode>();

  const organization = userStore.selectedOrganization;

  const [allOrganizationIdentityProvidersLoadable] = useLoadable(async () => {
    if (!organization) {
      return [];
    }

    const allOrganizationIdentityProviders = await getAllIdentityProvidersOfOrganization(organization.id);
    return allOrganizationIdentityProviders.map(transformKnoxerIdentityProviders);
  }, [organization]);

  if (!organization || !knoxersStore.knoxers.isResolved()) {
    return <Loader spinning />;
  }

  const knoxers = knoxersStore.knoxers;

  function convertUserOrganizationToUserManagementOrganization(userOrganization: UserOrganization): Organization {
    return {
      name: userOrganization.data.name,
      id: userOrganization.id,
      serviceIds: userOrganization.services,
    };
  }

  return (
    <PageWithHeader width='full'>
      {allOrganizationIdentityProvidersLoadable.resolve(
        (organizationIdentityProviders): ReactNode => (
          <ManageOrganizationUsers
            knoxers={knoxers.result}
            organization={convertUserOrganizationToUserManagementOrganization(organization)}
            organizationIdentityProviders={organizationIdentityProviders}
            canViewNsknoxUsers={false}
            canAddUsersToOrg={false}
            canSuspendUserFromOrganization={false}
            canKillUserSessions={false}
            canEditUserPermissions={false}
          />
        ),
        () => 'Loading...',
      )}
    </PageWithHeader>
  );
});

export default ManageOrganizationPage;
