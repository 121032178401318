import FilterColumnMultiValuePopup, { MultiselectValue } from './FilterColumnMultiValuePopup';
import { ColumnProps } from 'antd/lib/table';
import React, { ReactElement } from 'react';
import { isDefinedAndHasLength } from '@app/utils/utils';
import Loadable from '@app/utils/Loadable';

export function getColumnMultiValueConstSelectLocalFilterProps<T>(
  values: MultiselectValue[],
  isSelectedValueValid: (value: string, record: T) => boolean,
  showSearch?: boolean,
): Partial<ColumnProps<T>> {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnMultiValuePopup
        values={values}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        showSearch={showSearch}
      />
    ),
    onFilter: isSelectedValueValid,
  };
}

export function getColumnMultiValueConstSelectServerFilterProps<T>(
  options: MultiselectValue[] | Loadable<MultiselectValue[]>,
  selectedValues: string[] | undefined | null,
  showSearch?: boolean,
): Partial<ColumnProps<T>> {
  const isFiltered = isDefinedAndHasLength(selectedValues);

  return {
    filteredValue: isFiltered ? selectedValues : [],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnMultiValuePopup
        values={options}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        showSearch={showSearch}
      />
    ),
  };
}
