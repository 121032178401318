import { COUNTRY_CODES } from '@app/domain/countries';
import * as countryProperties from '@supplierRegistration/domain/countries';

export enum AllLegalIdTypeForCompany {
  CompanyID = 'Company ID',
  EIN = 'EIN',
  SSN = 'SSN',
  TIN = 'TIN',
  VAT = 'VAT',
  Other = 'Other',
}

export function getLegalIdTypesOfCountryForCompany(supplierCountryCode: string | undefined): Record<string, string> {
  if (!supplierCountryCode || !countryProperties[supplierCountryCode]?.leis) {
    return AllLegalIdTypeForCompany;
  }

  return countryProperties[supplierCountryCode].leis.types;
}

export function getInitialLegalIdTypValuesOfCountryForCompany(
  supplierCountryCode: string | undefined,
): Record<string, string> | undefined {
  if (supplierCountryCode && countryProperties[supplierCountryCode]?.leis) {
    return countryProperties[supplierCountryCode]?.leis.defaultValue;
  }
}

export function getAdditionalLeiValueRulesForCountryForLeiType(
  supplierCountryCode: string | undefined,
  leiType: string | undefined,
): [] {
  let countryRules;
  if (supplierCountryCode && leiType && countryProperties[supplierCountryCode]?.legalIdTypesForCompany) {
    countryRules = countryProperties[supplierCountryCode].legalIdTypesForCompany.find((lei) => lei.key === leiType);
  }

  return countryRules?.rules ?? [];
}

export function getAdditionalLeiValueRules(supplierCountryCode: string | undefined): [] {
  let countryAdditionalLeiValueRules;
  if (supplierCountryCode && countryProperties[supplierCountryCode]?.legalIdValueAdditionalRules) {
    countryAdditionalLeiValueRules = countryProperties[supplierCountryCode].legalIdValueAdditionalRules;
  }

  return countryAdditionalLeiValueRules ?? [];
}

export function getLegalIdTypesOfCountryForIndividual(supplierCountryCode: string | undefined): string {
  if (!supplierCountryCode) {
    return 'Tax ID Number';
  }

  switch (supplierCountryCode) {
    case COUNTRY_CODES.India:
      return 'Permanent Account Number';
    case COUNTRY_CODES.China:
      return 'Resident Identity Card Number';
    default:
      return 'Tax ID Number';
  }
}
