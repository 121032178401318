import { trim } from '@app/utils/stringUtils';
import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import {
  getDefaultBankCodeRules,
  SupplierRegistrationField,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { COUNTRY_CODES } from '@app/domain/countries';

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-bank-code',
    inputType: 'text',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.GB.errors.missingBankCode',
      },
      ...getDefaultBankCodeRules(COUNTRY_CODES.UnitedKingdom),
    ],
  };
}

export const GB: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
};
