import React, { ClipboardEvent, FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import Button from '@app/components/Button';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import ValidatedPayeeForm, { ValidatedPayeeFormFields } from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeForm';
import {
  convertReadErrorToExplanation,
  readHTMLContentFromClipboardData,
  readHTMLContentFromClipboardDirectly,
} from '@app/utils/clipboardUtils';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';
import { readExcelRowsFromClipboardHtmlContent } from '@app/utils/excelUtils';
import {
  excelRowIntoValidatedPayeeData,
  LegalIdentifierRequest,
  StoreValidatedPayeeRequest,
} from '@mortee/domain/vaildatedPayeeManagement';
import { KnoxIdOrigin } from '@mortee/stores/appStores/ValidatedPayeesManagementStore';
import { convertToDatePickerFormat } from '@app/components/inputs/DatePicker';
import moment from 'moment';
import { getCountryByCountryCode } from '@app/domain/countries';
import { getSanctionStatusFromText } from '@app/domain/validatedPayee';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import browserHistory from '@app/utils/browserHistory';
import { shoot } from '@app/utils/messageLauncher';
import Log from '@app/libs/logger';
import useInfraStores from '@app/hooks/useInfraStores';
import useIsMounted from '@app/hooks/useIsMounted';
import { storeValidatedPayee } from '@mortee/services/validatedPayeeManagementServices';

const CreateValidatedPayeePage: FC = observer(() => {
  const [validatedPayeeInitialValues, setValidatedPayeeInitialValues] = useState<ValidatedPayeeFormFields | undefined>(undefined);
  const [formKey, setFormKey] = useState<number>(1);

  const { validatedPayeesManagementStore } = useAppStores<MorteeMode>();
  const { navigationStore } = useInfraStores<MorteeMode>();
  const isMountedRef = useIsMounted();

  const allLEITypes = validatedPayeesManagementStore.allLeiTypes;

  const renderHeaderSideContent = (): ReactElement => (
    <PageHeaderCenteredSideContent>
      <Button id='btn-create-validated-payee-from-clipboard' appearance='text' onClick={handleReadFromClipboard}>
        LOAD DATA FROM CLIPBOARD
      </Button>
    </PageHeaderCenteredSideContent>
  );

  const handleReadFromClipboard = async (): Promise<void> => {
    let clipboardExcelRowsHtml: string | null = null;
    try {
      clipboardExcelRowsHtml = await readHTMLContentFromClipboardDirectly();
    } catch (e: unknown) {
      let modalErrorContent: string;

      if (e instanceof Error) {
        modalErrorContent = convertReadErrorToExplanation(e.message);
      } else {
        modalErrorContent = convertReadErrorToExplanation(null);
      }

      showContentOnlyModal((onDone) => (
        <ErrorModal headerContent='Paste Error' bodyContent={modalErrorContent} okButtonText='OK' onDone={onDone} />
      ));
      return;
    }

    applyValidatedPayeeExcelRowToForm(clipboardExcelRowsHtml);
  };

  const onPaste = (e: ClipboardEvent<HTMLDivElement>): void => {
    if (document.activeElement?.tagName.toLowerCase() !== 'input') {
      const clipboardExcelRowsHtml = readHTMLContentFromClipboardData(e.clipboardData);

      applyValidatedPayeeExcelRowToForm(clipboardExcelRowsHtml);
    }
  };

  const applyValidatedPayeeExcelRowToForm = (clipboardExcelRowsHtml: string | null): void => {
    if (!clipboardExcelRowsHtml) {
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent='Paste Error'
          bodyContent='No suitable content found in clipboard'
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      return;
    }

    const rows = readExcelRowsFromClipboardHtmlContent(clipboardExcelRowsHtml);

    if (!rows.length) {
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent='Paste Error'
          bodyContent='Could not find excel rows in clipboard to fill the form'
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      return;
    }

    const validatedPayeeExcelRow = excelRowIntoValidatedPayeeData(rows[0]);

    const knoxIdOrigin = validatedPayeeExcelRow.knoxId ? KnoxIdOrigin.existing : KnoxIdOrigin.generate;

    const formattedSanctionsDate = validatedPayeeExcelRow.sanctionsDate
      ? convertToDatePickerFormat(moment(validatedPayeeExcelRow.sanctionsDate, 'DD/MM/YYYY').toDate())
      : null;

    setValidatedPayeeInitialValues({
      knoxIdOrigin: knoxIdOrigin,
      existingKnoxId: validatedPayeeExcelRow.knoxId ?? undefined,
      knoxIdSuffix: validatedPayeeExcelRow.KnoxIdSuffix ?? undefined,
      primaryName: validatedPayeeExcelRow.initiatorName ?? '',
      additionalName: validatedPayeeExcelRow.alsoKnownAs ?? undefined,
      primaryAddress: validatedPayeeExcelRow.initiatorAddress ?? undefined,
      additionalAddress: validatedPayeeExcelRow.secondaryAddress ?? undefined,
      countryCode: validatedPayeeExcelRow.countryCode ?? '',
      taxId1: createLEIFromExcelRow(null, validatedPayeeExcelRow.taxIdCountryCode, validatedPayeeExcelRow.taxIdValue),
      duns: createLEIFromExcelRow(null, null, validatedPayeeExcelRow.duns),
      legalId1: createLEIFromExcelRow(
        validatedPayeeExcelRow.legalId1Type,
        validatedPayeeExcelRow.legalId1CountyCode,
        validatedPayeeExcelRow.legalId1Value,
      ),
      legalId2: createLEIFromExcelRow(
        validatedPayeeExcelRow.legalId2Type,
        validatedPayeeExcelRow.legalId2CountyCode,
        validatedPayeeExcelRow.legalId2Value,
      ),
      legalId3: createLEIFromExcelRow(
        validatedPayeeExcelRow.legalId3Type,
        validatedPayeeExcelRow.legalId3CountyCode,
        validatedPayeeExcelRow.legalId3Value,
      ),
      sanctionsScreeningTimestamp: formattedSanctionsDate ?? undefined,
      sanctionsStatus: getSanctionStatusFromText(validatedPayeeExcelRow.sanctionsStatus) ?? undefined,
      supplierValidationRegistrationNumber: validatedPayeeExcelRow.supplierValidationRegistrationNumber ?? undefined,
    });
    setFormKey((prevState) => prevState + 1);
  };

  const createLEIFromExcelRow = (
    typeName: string | null,
    countryCode: string | null,
    value: string | null,
  ): LegalIdentifierRequest | undefined => {
    const validCountryCode = getCountryByCountryCode(countryCode) ? countryCode : null;

    if (!typeName && !validCountryCode && !value) {
      return undefined;
    }

    return {
      countryCode: validCountryCode,
      typeId: allLEITypes.result?.regularTypes.find((type) => type.name === typeName)?.id ?? '',
      value: value ?? '',
    };
  };

  async function createValidatedPayee(payload: StoreValidatedPayeeRequest): Promise<void> {
    try {
      const result = await storeValidatedPayee(payload);
      if (isMountedRef.current) {
        browserHistory.push(navigationStore.generateManageValidatedPayeePageHref(result.uniformId));
      }
      shoot({ type: 'success', closeable: true }, `Validated payee ${payload.primaryFields.name} created successfully`);
    } catch (e) {
      Log.exception(e);
    }
  }

  return (
    <PageWithHeader
      onPaste={onPaste}
      width='full'
      header={
        <PageHeader wrapHeaderTitle sideContent={renderHeaderSideContent()}>
          <PageHeaderTitle title='Create New Validated Payee' />
        </PageHeader>
      }
    >
      <ValidatedPayeeForm key={formKey} initialValues={validatedPayeeInitialValues} storeValidatedPayee={createValidatedPayee} />
    </PageWithHeader>
  );
});

export default CreateValidatedPayeePage;
