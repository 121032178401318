import React, { FunctionComponent, ReactElement, useContext } from 'react';
import Loadable from '@app/utils/Loadable';
import FilterPrimaryIcon from '@app/images/filter-primary.svg';
import FilterGreyIcon from '@app/images/filter-grey.svg';
import FilterWhiteIcon from '@app/images/filter-white.svg';
import LivePrivatePayeesFiltersContext from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import { PrivatePayeeWithDeterministicValidations } from '@mortee/domain/masterDataGuard';
import PrivateAreaFiltersPopup from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/buttons/PrivateAreaFiltersPopup';
import { identityFunc } from '@app/utils/utils';
import ButtonThatOpensPopup from '@app/components/popup/ButtonThatOpensPopup';

interface Props {
  buttonDisabled: boolean;
  allNotValidatedPayeesWithPercentage: Loadable<PrivatePayeeWithDeterministicValidations[]>;
}

const PrivateAreaFiltersButton: FunctionComponent<Props> = ({ buttonDisabled, allNotValidatedPayeesWithPercentage }) => {
  const { filters, filtersApplied, filterOptions, setFilters } = useContext(LivePrivatePayeesFiltersContext);

  const showCompanyCodeFilter = !!filterOptions.companyCodes.result?.values.size;
  const showValidationLevelFilter = filterOptions.validationLevel.length > 1;
  const canTheUserFilter = showValidationLevelFilter || showCompanyCodeFilter;

  const isButtonDisabled = !canTheUserFilter || buttonDisabled;

  const { searchQuery, ...filtersRelevantToButton } = filtersApplied;

  const isRelevantFiltersApplied = Object.values(filtersRelevantToButton).some(identityFunc);

  const getFilterIcon = (): SpriteSymbol => {
    return isButtonDisabled ? FilterGreyIcon : isRelevantFiltersApplied ? FilterWhiteIcon : FilterPrimaryIcon;
  };

  function renderPopup(onDone): ReactElement {
    return (
      <PrivateAreaFiltersPopup
        filters={filters}
        filterOptions={filterOptions}
        setFilters={setFilters}
        onPopupClosed={onDone}
        allNotValidatedPayeesWithPercentage={allNotValidatedPayeesWithPercentage}
      />
    );
  }

  return (
    <StyledFilterNotValidatedButton
      id='mvf-grd-live-not-vald'
      popupContent={renderPopup}
      colorScheme={isRelevantFiltersApplied ? 'primary' : 'lightBluePrimary'}
      disabled={isButtonDisabled}
    >
      <StyledFilterIcon accessibilityLabel='' image={getFilterIcon()} />
      &nbsp;Filter
    </StyledFilterNotValidatedButton>
  );
};

export default PrivateAreaFiltersButton;

const StyledFilterIcon = styled(SVG)`
  width: 24px;
  height: 24px;
`;

const StyledFilterNotValidatedButton = styled(ButtonThatOpensPopup)`
  box-shadow: unset !important;
  display: flex;
  align-items: center;
  position: relative;

  padding: 6px 12px;
`;
