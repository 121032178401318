import React, { FunctionComponent } from 'react';
import SupplierRegistrationProgress from '@supplierRegistration/components/SupplierRegistrationProgress';
import ChangeLanguageDropdown from '@app/components/ChangeLanguageDropdown';
import styled from '@emotion/styled';
import useAppStores from '@app/hooks/useAppStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { observer } from 'mobx-react';
import useTheme from '@app/hooks/useTheme';
import PoweredByLogo from '@app/images/powered-by.svg';
import nsknoxLogoBlack from '@app/images/nsknox-black.svg';
import nsknoxLogoWhite from '@app/images/nsknox-white.svg';
import SVG from '@app/components/SVG';
import { nsknoxLogoResource } from '@supplierRegistration/domain/supplierRegistrationTheme';
import ImageFromResource from '@app/components/ImageFromResource';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  hideProgress?: boolean;
}

const SupplierRegistrationHeader: FunctionComponent<Props> = observer((props) => {
  const { className, hideProgress } = props;
  const { t } = useTranslation();
  const { supplierRegistrationFormStore } = useAppStores<SupplierRegistrationMode>();
  const { brand, headerTextColor, isHeaderDark } = useTheme<SupplierRegistrationMode>();
  const isHeaderContentColorBlack = !isHeaderDark;

  return (
    <HeaderContainer data-testid='page-header' className={className}>
      <LogoContainer>
        {brand?.logo ? (
          <StyledHeaderLogo
            altName={t('general.accessibility.logoOfCompany', { clientName: brand.customerName })}
            resource={brand?.logo}
          />
        ) : (
          <StyledHeaderLogo
            altName={t('general.accessibility.logoOfCompany', { clientName: 'nsKnox' })}
            resource={nsknoxLogoResource}
          />
        )}
        {brand && (
          <SubLogoContainer>
            <PoweredBy
              accessibilityLabel={t('general.accessibility.poweredBy')}
              data-testid='powered-by-logo'
              image={PoweredByLogo}
            />
            <NsknoxRefLogo
              accessibilityLabel='nsKnox'
              data-testid='nsknox-logo'
              image={isHeaderContentColorBlack ? nsknoxLogoBlack : nsknoxLogoWhite}
            />
          </SubLogoContainer>
        )}
      </LogoContainer>
      <HeaderCenter>
        {!hideProgress ? (
          <StyledSupplierRegistrationProgress step={supplierRegistrationFormStore.currentProgressStep} />
        ) : (
          <div />
        )}
      </HeaderCenter>
      <ChangeLangHeader>
        <ChangeLanguageDropdown textColorScheme={headerTextColor} />
      </ChangeLangHeader>
    </HeaderContainer>
  );
});

export default SupplierRegistrationHeader;

const StyledHeaderLogo = styled(ImageFromResource)`
  object-fit: contain;
  object-position: left;
  height: 60px;
  width: 200px;
`;

const HeaderContainer = styled.div`
  background-color: var(--header-color);
  direction: ltr;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-start;

  padding: 26px 32px 26px 32px;
  min-height: 130px;

  color: var(--header-text-color);
  --text-color: var(--header-text-color);
  --current-or-passed-step-color: var(--header-text-color);
  --not-passed-step-color: var(--header-text-color);
`;

const HeaderCenter = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.44px;
  color: var(--transparent-black-900);
`;

const StyledSupplierRegistrationProgress = styled(SupplierRegistrationProgress)`
  width: 440px;
  --circle-size: 16px;
  --line-distance-from-ball: 12px;
`;

const ChangeLangHeader = styled.div`
  // Negative margin is because ValueWithPlaceholder has padding
  // We want the logo and the lang menu to be symmetrical
  margin-top: -12px;
  margin-right: -18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

const SubLogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  margin-top: 10px;
`;

const PoweredBy = styled(SVG)`
  height: 17px;
  margin-right: 10px;
  fill: currentcolor;
`;

const NsknoxRefLogo = styled(SVG)`
  height: 21px;
  margin-bottom: 10px;
`;
