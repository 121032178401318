import { DependencyList, useEffect } from 'react';
import useManuallyCalledLoadable, { LoadableResponse } from '@app/hooks/loadable/useManuallyCalledLoadable';

export default function useLoadable<TResult>(
  loadFunc: (abortSignal: AbortSignal) => Promise<TResult>,
  dependencies: DependencyList,
): LoadableResponse<TResult, []> {
  const [loadable, loadLoadableFunc, setLoadable, currentlyLoadingAbortControllerRef] = useManuallyCalledLoadable(loadFunc);

  useEffect(() => {
    loadLoadableFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dependencies come from the outside and should stay consistent
  }, dependencies);

  return [loadable, loadLoadableFunc, setLoadable, currentlyLoadingAbortControllerRef];
}
