import { filterValues } from '@app/utils/utils';
import { mergeArrays } from '@app/utils/arrayUtils';
import { extractErroredFileName, getFileErrorText } from '@app/components/fileUpload/fileUploadUtils';
import { extractLogErrorIdFromError, HttpStatus } from '@app/libs/request';
import Log from '@app/libs/logger';

export const DEFAULT_MAX_FILE_AMOUNT = 5;
export const DEFAULT_MAX_FILE_NAME_LENGTH = 80;
export const DEFAULT_MAX_FILE_SIZE_MB = 5;

const formatChars =
  '[\xAD\u0600-\u0605\u061C\u06DD\u070F\u08E2\u180E\u200B-\u200F\u202A-\u202E\u2060-\u2064\u2066-\u206F\uFEFF\uFFF9-\uFFFB]|\uD804[\uDCBD\uDCCD]|\uD80D[\uDC30-\uDC38]|\uD82F[\uDCA0-\uDCA3]|\uD834[\uDD73-\uDD7A]|\uDB40[\uDC01\uDC20-\uDC7F]';
const validChar = `([a-zA-Z0-9א-ת_(). -]|${formatChars})`;
const fileNameRegex = RegExp(`^${validChar}*$`);

export enum FileErrorTypes {
  emptyFile = 'EmptyFile',
  fileSize = 'FileSize',
  fileNameLength = 'FileNameSize',
  tooManyFiles = 'TooManyFiles',
  invalidFileName = 'InvalidFileName',
  unknownExtension = 'UnknownExtension',
  forbiddenExtension = 'ForbiddenExtension',
}

export interface FileAndErrors {
  file: File;
  errors?: string[];
}

export enum FileTypes {
  pdf = 'pdf',
  csv = 'csv',
  txt = 'txt',
  eml = 'eml',
  msg = 'msg',
  xml = 'xml',
  xls = 'xls',
  xlsx = 'xlsx',
  doc = 'doc',
  docx = 'docx',
  png = 'png',
  jpeg = 'jpeg',
  jpg = 'jpg',
  exe = 'exe',
  msp = 'msp',
}

export const BlackListedFileTypesList: string[] = [FileTypes.exe];

export const FileTypeExplanation: Partial<Record<FileTypes, string>> = {
  [FileTypes.eml]: 'imported from gmail',
  [FileTypes.msg]: 'imported from outlook',
};

export enum FileTypeCategory {
  Documents = 'Word documents',
  Spreadsheets = 'Spreadsheets',
  PDFs = 'PDFs',
  Images = 'Images',
  Text = 'Text files',
  Emails = 'Email export files',
}

export const FileTypeCategoryExplanation: Partial<Record<FileTypeCategory, string>> = {
  [FileTypeCategory.Images]: 'e.g. print screens',
};

export const fileTypesByCategory: Record<FileTypeCategory, FileTypes[]> = {
  [FileTypeCategory.Documents]: [FileTypes.doc, FileTypes.docx],
  [FileTypeCategory.Spreadsheets]: [FileTypes.csv, FileTypes.xls, FileTypes.xlsx],
  [FileTypeCategory.PDFs]: [FileTypes.pdf],
  [FileTypeCategory.Text]: [FileTypes.txt, FileTypes.xml],
  [FileTypeCategory.Emails]: [FileTypes.eml, FileTypes.msg],
  [FileTypeCategory.Images]: [FileTypes.png, FileTypes.jpeg, FileTypes.jpg],
};

export enum FileContentTypes {
  APPLICATION_PDF = 'application/pdf',
  TEXT_CSV = 'text/csv',
  TEXT_PLAIN = 'text/plain',
  APPLICATION_XML = 'application/xml',
  APPLICATION_XLS = 'application/vnd.ms-excel',
  APPLICATION_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_DOC = 'application/msword',
  APPLICATION_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  IMAGE_PNG = 'image/png',
  IMAGE_JPEG = 'image/jpeg',
  MESSAGE_RFC822 = 'message/rfc822',
  APPLICATION_MS_OUTLOOK = 'application/vnd.ms-outlook',
}

const FileExtensionAndMimeType: { extension: FileTypes; mimeType: FileContentTypes }[] = [
  { extension: FileTypes.pdf, mimeType: FileContentTypes.APPLICATION_PDF },
  { extension: FileTypes.csv, mimeType: FileContentTypes.TEXT_CSV },
  { extension: FileTypes.txt, mimeType: FileContentTypes.TEXT_PLAIN },
  { extension: FileTypes.eml, mimeType: FileContentTypes.MESSAGE_RFC822 },
  { extension: FileTypes.msg, mimeType: FileContentTypes.APPLICATION_MS_OUTLOOK },
  { extension: FileTypes.xml, mimeType: FileContentTypes.APPLICATION_XML },
  { extension: FileTypes.xls, mimeType: FileContentTypes.APPLICATION_XLS },
  { extension: FileTypes.xlsx, mimeType: FileContentTypes.APPLICATION_XLSX },
  { extension: FileTypes.doc, mimeType: FileContentTypes.APPLICATION_DOC },
  { extension: FileTypes.docx, mimeType: FileContentTypes.APPLICATION_DOCX },
  { extension: FileTypes.png, mimeType: FileContentTypes.IMAGE_PNG },
  { extension: FileTypes.jpeg, mimeType: FileContentTypes.IMAGE_JPEG },
  { extension: FileTypes.jpg, mimeType: FileContentTypes.IMAGE_JPEG },
];

export function translateFileNameToContentType(fileName: string): string {
  const lowercaseFileName = fileName.toLowerCase();

  const matchMimeType = FileExtensionAndMimeType.find((extensionAndMimeType) =>
    lowercaseFileName.match(`.+\\.${extensionAndMimeType.extension}$`),
  )?.mimeType;

  return matchMimeType ?? FileContentTypes.TEXT_PLAIN;
}

export function translateContentTypeToFileExtension(mimeType: string | null): string {
  const matchExtension = FileExtensionAndMimeType.find((extensionAndMimeType) => mimeType === extensionAndMimeType.mimeType)
    ?.extension;

  return matchExtension ? `.${matchExtension}` : '';
}

export function getFileExtension(fileName: string): string | undefined {
  const fileNameParts = fileName.split('.');

  if (fileNameParts.length < 2) {
    return;
  }

  return fileNameParts[fileNameParts.length - 1];
}

export function doesFileHaveAllowedExtension(file: File, allowedExtensions: string[]): boolean {
  const fileExtension = getFileExtension(file.name);

  return !!fileExtension && allowedExtensions.includes(fileExtension.toLowerCase());
}

export function isFileTypeForbidden(file: File, additionalForbiddenExtensions?: String[]): boolean {
  const fileExtension = getFileExtension(file.name);
  return (
    !!fileExtension &&
    mergeArrays([additionalForbiddenExtensions, BlackListedFileTypesList]).includes(fileExtension.toLowerCase())
  );
}

export enum GENERAL_FILE_ERRORS {
  illegalFileContentType = 'ILLEGAL_FILE_CONTENT_TYPE',
  filesNotSecure = 'FILES_NOT_SECURE',
}

export const generalFilesErrors: { [error in GENERAL_FILE_ERRORS]: { text: () => string } } = {
  [GENERAL_FILE_ERRORS.illegalFileContentType]: {
    text: (): string => `Invalid file type. Please use one of the permitted file types`,
  },
  [GENERAL_FILE_ERRORS.filesNotSecure]: {
    text: (): string =>
      `File not secure, Please check and try again. If the problem persists, please contact us at support@nsknox.net`,
  },
};

export type FilesErrorOutput = { errorLogId: string | null; erroredFiles: ErrorTextByFileName | null };
export type ErrorTextByFileName = { [fileName: string]: string };

export function handleGeneralFileUploadError(error): FilesErrorOutput {
  const errorLogId = extractLogErrorIdFromError(error) || null;

  if (error.code !== HttpStatus.badRequest) {
    return { errorLogId, erroredFiles: null };
  }

  const { responseJSON } = error;

  if (!responseJSON) {
    Log.exception('missing responseJSON');
    return { errorLogId, erroredFiles: null };
  }

  const { error: errorCode, additionalData } = responseJSON;

  if (!errorCode || !additionalData) {
    Log.exception('missing errorCode or additionalData');
    return { errorLogId, erroredFiles: null };
  }

  const erroredFilesNames: string[] = extractErroredFileName(error);

  if (!erroredFilesNames.length) {
    return { errorLogId, erroredFiles: null };
  }

  let fileErrorMessage = generalFilesErrors?.[errorCode].text();

  if (!fileErrorMessage) {
    Log.exception(new Error(`missing generalFilesErrors const for ${errorCode}`), { responseJSON });
    fileErrorMessage = 'An unknown file error has occurred, please contact us at support@nsknox.net';
  }

  const fileErrorMessageWithLogId = fileErrorMessage + (errorLogId ? ` (Error Code: ${errorLogId})` : '');

  const filesAndErrors = Object.fromEntries(
    erroredFilesNames.map((fileName): [string, string] => [fileName, fileErrorMessageWithLogId]),
  );

  return { errorLogId: null, erroredFiles: filesAndErrors };
}

export interface CheckFileOptions {
  maxFileSizeMB?: number;
  maxFileNameLength?: number;
  allowedExtensions?: FileTypes[];
  forbiddenExtensions?: string[];
  maxFilesAllowed: number;
}

export function checkFile(file: File, options: CheckFileOptions): FileErrorTypes[] {
  const opts = { maxFileSizeMB: DEFAULT_MAX_FILE_SIZE_MB, maxFileNameLength: DEFAULT_MAX_FILE_NAME_LENGTH, ...options };
  const allPossibleErrors: Partial<Record<FileErrorTypes, boolean>> = {
    [FileErrorTypes.emptyFile]: file.size === 0,
    [FileErrorTypes.fileSize]: file.size / 1024 / 1024 > opts.maxFileSizeMB,
    [FileErrorTypes.fileNameLength]: file.name.length > opts.maxFileNameLength,
    [FileErrorTypes.invalidFileName]: !fileNameRegex.test(file.name),
    [FileErrorTypes.forbiddenExtension]: isFileTypeForbidden(file, opts.forbiddenExtensions),
    [FileErrorTypes.unknownExtension]: opts.allowedExtensions && !doesFileHaveAllowedExtension(file, opts.allowedExtensions),
  };

  const actualErrors = filterValues(allPossibleErrors, (value) => value);

  return Object.keys(actualErrors) as FileErrorTypes[];
}

export function checkFileValidation(file: File, filesOptions: CheckFileOptions): string[] {
  return checkFile(file, filesOptions).map((error) => getFileErrorText(error, filesOptions, file.name));
}
