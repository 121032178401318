import React, { FC } from 'react';
import ForgotPasswordFlow from './forgotPassword/ForgotPasswordFlow';
import { AuthMethodType, UnclaimedUserPasswordKnoxer } from '@app/login/domain/loginConsts';
import UserPasswordLoginForm from './UserPasswordLoginForm';
import { observer, useLocalObservable } from 'mobx-react';
import { extractOIDCProviderNameFromAddress, extractSamlProviderNameFromAddress } from '@app/login/domain/sso';
import { useLocation } from 'react-router-dom';
import SamlAuthentication from '@app/login/routes/sso/saml/SamlAuthentication';
import { doesHaveKnoxerAuthConfig } from '@app/login/services/authenticationService';
import OidcAuthentication from '@app/login/routes/sso/oidc/OidcAuthentication';

interface UserPasswordFlowLoginProps {
  knoxerToClaim: UnclaimedUserPasswordKnoxer;
}

const UserPasswordFlow: FC<UserPasswordFlowLoginProps> = observer((props) => {
  const localStore = useLocalObservable(() => ({
    forgotPasswordMode: false as boolean,
    email: '' as string,

    setForgotPasswordData: (email: string, forgotPasswordMode: boolean): void => {
      localStore.email = email;
      localStore.forgotPasswordMode = forgotPasswordMode;
    },
  }));

  const { pathname: currentPathname } = useLocation();
  const samlProviderName = extractSamlProviderNameFromAddress(currentPathname);
  const oidcProviderName = extractOIDCProviderNameFromAddress(currentPathname);

  const { knoxerToClaim } = props;
  const { id: knoxerId } = knoxerToClaim.knoxer;

  if (samlProviderName && doesHaveKnoxerAuthConfig(knoxerId, AuthMethodType.SamlSSO)) {
    return <SamlAuthentication samlProviderName={samlProviderName} knoxerId={knoxerId} />;
  }

  if (oidcProviderName && doesHaveKnoxerAuthConfig(knoxerId, AuthMethodType.OidcSSO)) {
    return <OidcAuthentication oidcProviderName={oidcProviderName} knoxerId={knoxerId} />;
  }

  if (localStore.forgotPasswordMode) {
    return (
      <ForgotPasswordFlow
        knoxerId={knoxerId}
        initialEmail={localStore.email}
        onFinish={(email): void => localStore.setForgotPasswordData(email, false)}
      />
    );
  }

  return (
    <UserPasswordLoginForm
      knoxerToClaim={knoxerToClaim}
      initialEmail={localStore.email}
      goToForgotPassword={(email): void => localStore.setForgotPasswordData(email, true)}
    />
  );
});

export default UserPasswordFlow;
