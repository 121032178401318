import { Cell, ExcelRowDefinition, excelRowIntoObject, excelValueToBoolean } from '@app/utils/excelUtils';
import {
  LegalEntityIdentifier,
  LegalEntityIdentifierServerResponse,
  StrongLegalIdentifiesTypes,
} from '@app/domain/legalEntityIdentifier';
import { SanctionsScreeningResultStatus } from '@app/domain/validatedPayee';
import {
  AggregatedValidatedPayee,
  AggregatedValidatedPayeeServerResponse,
  transformAggregatedValidatedPayee,
} from '@app/domain/aggregatedValidatedPayee';
import { ValidatedPayeeAccountValidationType } from '@app/domain/validatedPayeeAccount';

export interface LegalIdentifierRequest {
  countryCode: string | null;
  value: string;
  typeId: string | null;
}

export function transformLegalEntityIdentifierToLegalIdentifierRequest(lei: LegalEntityIdentifier): LegalIdentifierRequest {
  return {
    typeId: lei.typeId ?? null,
    countryCode: lei.countryCode ?? null,
    value: lei.value,
  };
}

export interface LegalEntityIdTypesServerResponse {
  regularTypes: LegalEntityIdTypeServerResponse[];
  strongTypes: Partial<Record<StrongLegalIdentifiesTypes, LegalEntityIdTypeServerResponse>>;
}

export interface LegalEntityIdTypeServerResponse {
  name: string;
  id: string;
}

export interface KnoxIdServerResponse {
  id: string;
  suffix: string | null;
  suffixUppered: string | null;
  writeTimestamp: number;
}

export interface LegalEntityIdTypes {
  regularTypes: LegalEntityIdType[];
  strongTypes: Partial<Record<StrongLegalIdentifiesTypes, LegalEntityIdType>>;
}

export interface LegalEntityIdType {
  name: string;
  id: string;
}

export interface PayeePrimaryFieldsRequest {
  name?: string;
  address?: string;
  legalIdentifier?: LegalIdentifierRequest;
}

export interface StoreValidatedPayeeRequest {
  ownerOrgId: string | null;
  originatingValidatedPayeeAccountEventId: string | null;
  uniformId: string | null;
  countryCode: string | undefined;
  legalIdentifiers: LegalIdentifierRequest[];
  names: string[];
  addresses: string[];
  phones: string[];
  emails: string[];
  faxes: string[];
  websites: string[];
  description: string | undefined;
  primaryFields: PayeePrimaryFieldsRequest;
  knoxUniqueId: string | null;
  sanctionsScreeningRequestInfo: SanctionsScreeningRequestInfo | undefined;
  supplierValidationRegistrationNumber: string | null;
}

export interface SanctionsScreeningRequestInfo {
  timestamp: number;
  result: SanctionsScreeningResultStatus | null;
}

//region Validate payee store warning
export enum ValidatedPayeeStoreWarningType {
  missingAddress = 'MissingAddress',
  missingSanctionScreening = 'MissingSanctionScreening',
  existingLegalIdInOtherPayees = 'ExistingLegalIdInOtherPayees',
  duplicateLegalIdValueWithinThisPayee = 'DuplicateLegalIdValueWithinThisPayee',
  payeeCountryNotMatchLegalIdsCountry = 'PayeeCountryNotMatchLegalIdsCountry',
  ssnLeiTypeOnPublicPayee = 'SsnLeiTypeOnPublicPayee',
  noLeiOnNewPayee = 'NoLeiOnNewPayee',
}

export interface NoticesBulletsData {
  organizationName: string | undefined;
}

export interface OrganizationDataFromRegistrationFormRefServerResponse {
  name: string;
  id: string;
}

export interface OrganizationDataFromRegistrationFormRef {
  name: string | undefined;
  id: string | undefined;
}

export function transformOrganizationData(
  serverResponse: OrganizationDataFromRegistrationFormRefServerResponse,
): OrganizationDataFromRegistrationFormRef {
  return serverResponse;
}

export interface ValidatedPayeeMissingAddressWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.missingAddress;
}

export interface ValidatedPayeeMissingSanctionScreeningWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.missingSanctionScreening;
}

export interface ValidatedPayeeExistingLegalIdInOtherPayeesWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.existingLegalIdInOtherPayees;
  otherValidatedPayees: AggregatedValidatedPayeeServerResponse[];
}

export interface ValidatedPayeeDuplicateLegalIdValueWithinThisPayeeWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.duplicateLegalIdValueWithinThisPayee;
  leisWithTheSameValue: LegalEntityIdentifierServerResponse[];
}

export interface ValidatedPayeePayeeCountryNotMatchLegalIdsCountryWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.payeeCountryNotMatchLegalIdsCountry;
  leisWithDifferentCountryThenPayee: LegalEntityIdentifierServerResponse[];
}

export interface SsnLeiTypeOnPublicPayeeStoreWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.ssnLeiTypeOnPublicPayee;
}

export interface NoLeiOnNewPayeeStoreWarningServerResponse {
  type: ValidatedPayeeStoreWarningType.noLeiOnNewPayee;
}

export type ValidatedPayeeStoreWarningServerResponse =
  | ValidatedPayeeMissingAddressWarningServerResponse
  | ValidatedPayeeMissingSanctionScreeningWarningServerResponse
  | ValidatedPayeeExistingLegalIdInOtherPayeesWarningServerResponse
  | ValidatedPayeeDuplicateLegalIdValueWithinThisPayeeWarningServerResponse
  | ValidatedPayeePayeeCountryNotMatchLegalIdsCountryWarningServerResponse
  | SsnLeiTypeOnPublicPayeeStoreWarningServerResponse
  | NoLeiOnNewPayeeStoreWarningServerResponse;

export interface ValidatedPayeeMissingAddressWarning {
  type: ValidatedPayeeStoreWarningType.missingAddress;
}

export interface ValidatedPayeeMissingSanctionScreeningWarning {
  type: ValidatedPayeeStoreWarningType.missingSanctionScreening;
}

export interface ValidatedPayeeExistingLegalIdInOtherPayeesWarning {
  type: ValidatedPayeeStoreWarningType.existingLegalIdInOtherPayees;
  otherValidatedPayees: AggregatedValidatedPayee[];
}

export interface ValidatedPayeeDuplicateLegalIdValueWithinThisPayeeWarning {
  type: ValidatedPayeeStoreWarningType.duplicateLegalIdValueWithinThisPayee;
  leisWithTheSameValue: LegalEntityIdentifier[];
}

export interface ValidatedPayeePayeeCountryNotMatchLegalIdsCountryWarning {
  type: ValidatedPayeeStoreWarningType.payeeCountryNotMatchLegalIdsCountry;
  leisWithDifferentCountryThenPayee: LegalEntityIdentifier[];
}

export interface SsnLeiTypeOnPublicPayeeStoreWarning {
  type: ValidatedPayeeStoreWarningType.ssnLeiTypeOnPublicPayee;
}

export interface NoLeiOnNewPayeeStoreWarning {
  type: ValidatedPayeeStoreWarningType.noLeiOnNewPayee;
}

export type ValidatedPayeeStoreWarning =
  | ValidatedPayeeMissingAddressWarning
  | ValidatedPayeeMissingSanctionScreeningWarning
  | ValidatedPayeeExistingLegalIdInOtherPayeesWarning
  | ValidatedPayeeDuplicateLegalIdValueWithinThisPayeeWarning
  | ValidatedPayeePayeeCountryNotMatchLegalIdsCountryWarning
  | SsnLeiTypeOnPublicPayeeStoreWarning
  | NoLeiOnNewPayeeStoreWarning;

export function transformValidatedPayeeStoreWarning(
  serverResponse: ValidatedPayeeStoreWarningServerResponse,
): ValidatedPayeeStoreWarning {
  switch (serverResponse.type) {
    case ValidatedPayeeStoreWarningType.missingAddress: {
      const missingAddressWarning: ValidatedPayeeMissingAddressWarning = serverResponse;

      return missingAddressWarning;
    }
    case ValidatedPayeeStoreWarningType.missingSanctionScreening: {
      const missingSanctionScreeningWarning: ValidatedPayeeMissingSanctionScreeningWarning = serverResponse;

      return missingSanctionScreeningWarning;
    }
    case ValidatedPayeeStoreWarningType.existingLegalIdInOtherPayees: {
      const leiInOtherPayees: ValidatedPayeeExistingLegalIdInOtherPayeesWarning = {
        ...serverResponse,
        otherValidatedPayees: serverResponse.otherValidatedPayees.map(transformAggregatedValidatedPayee),
      };

      return leiInOtherPayees;
    }
    case ValidatedPayeeStoreWarningType.duplicateLegalIdValueWithinThisPayee: {
      const duplicateLegalIdValue: ValidatedPayeeDuplicateLegalIdValueWithinThisPayeeWarning = {
        ...serverResponse,
        leisWithTheSameValue: serverResponse.leisWithTheSameValue,
      };

      return duplicateLegalIdValue;
    }
    case ValidatedPayeeStoreWarningType.payeeCountryNotMatchLegalIdsCountry: {
      const legalIdsCountryNotMatchWarning: ValidatedPayeePayeeCountryNotMatchLegalIdsCountryWarning = {
        ...serverResponse,
        leisWithDifferentCountryThenPayee: serverResponse.leisWithDifferentCountryThenPayee,
      };

      return legalIdsCountryNotMatchWarning;
    }
    case ValidatedPayeeStoreWarningType.ssnLeiTypeOnPublicPayee: {
      const ssnOnPublicPayeeWarning: SsnLeiTypeOnPublicPayeeStoreWarning = serverResponse;

      return ssnOnPublicPayeeWarning;
    }

    case ValidatedPayeeStoreWarningType.noLeiOnNewPayee: {
      const noLeiOnNewPayee: NoLeiOnNewPayeeStoreWarning = serverResponse;

      return noLeiOnNewPayee;
    }
  }
}

//endregion

//region Validated payee account warning
export enum ValidatedPayeeAccountStoreWarningType {
  supplierValidationProcessNotFound = 'SupplierValidationProcessNotFound',
  svManagementRecordNotFound = 'SvManagementRecordNotFound',
  bankCountryNotMatchPayeeCountry = 'BankCountryNotMatchPayeeCountry',
  bankCountryNotMatchAccountDetailsCountry = 'BankCountryNotMatchAccountDetailsCountry',
  bankCountryNotMatchAccountDetailsIBANCountry = 'BankCountryNotMatchAccountDetailsIBANCountry',
  bankCountryNotMatchAccountDetailsSWIFTCountry = 'BankCountryNotMatchAccountDetailsSWIFTCountry',
  existingRepresentationsInOtherValidatedAccounts = 'ExistingRepresentationsInOtherValidatedAccounts',
  bankCountryMissing = 'BankCountryMissing',
  noAccountFullRepresentation = 'NoAccountFullRepresentation',
  visibilityIncompatibilityWithExistingValidatedPayee = 'VisibilityIncompatibilityWithExistingValidatedPayee',
}

export interface BankCountryMissingStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryMissing;
}

export interface SupplierValidationProcessNotFoundStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.supplierValidationProcessNotFound;
}

export interface SvManagementRecordNotFoundStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.svManagementRecordNotFound;
}

export interface BankCountryNotMatchPayeeCountryStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchPayeeCountry;
  bankCountryCode: string | null | undefined;
  payeeCountryCode: string | null | undefined;
}

export interface BankCountryNotMatchAccountDetailsCountryStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsCountry;
  bankCountryCode: string;
  accountDetailsCountryCode: string;
}

export interface BankCountryNotMatchAccountDetailsIBANCountryStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsIBANCountry;
  bankCountryCode: string;
  iban: string;
  ibanCountryCode: string | null | undefined;
}

export interface BankCountryNotMatchAccountDetailsSWIFTCountryStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsSWIFTCountry;
  bankCountryCode: string;
  swift: string;
  swiftCountryCode: string | null | undefined;
}

export interface ExistingRepresentationsInOtherValidatedAccountsStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.existingRepresentationsInOtherValidatedAccounts;
  validatedPayees: AggregatedValidatedPayeeServerResponse[];
}

export interface VisibilityIncompatibilityWithExistingValidatedPayeeStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.visibilityIncompatibilityWithExistingValidatedPayee;
}

export interface NoAccountFullRepresentationStoreWarningServerResponse {
  type: ValidatedPayeeAccountStoreWarningType.noAccountFullRepresentation;
}

export type ValidatedPayeeAccountStoreWarningServerResponse =
  | SupplierValidationProcessNotFoundStoreWarningServerResponse
  | BankCountryMissingStoreWarningServerResponse
  | SvManagementRecordNotFoundStoreWarningServerResponse
  | BankCountryNotMatchPayeeCountryStoreWarningServerResponse
  | BankCountryNotMatchAccountDetailsCountryStoreWarningServerResponse
  | BankCountryNotMatchAccountDetailsIBANCountryStoreWarningServerResponse
  | BankCountryNotMatchAccountDetailsSWIFTCountryStoreWarningServerResponse
  | ExistingRepresentationsInOtherValidatedAccountsStoreWarningServerResponse
  | NoAccountFullRepresentationStoreWarningServerResponse
  | VisibilityIncompatibilityWithExistingValidatedPayeeStoreWarningServerResponse;

export interface BankCountryMissingStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryMissing;
}

export interface SupplierValidationProcessNotFoundStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.supplierValidationProcessNotFound;
}

export interface SvManagementRecordNotFoundStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.svManagementRecordNotFound;
}

export interface BankCountryNotMatchPayeeCountryStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchPayeeCountry;
  bankCountryCode: string | null;
  payeeCountryCode: string | null;
}

export interface BankCountryNotMatchAccountDetailsCountryStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsCountry;
  bankCountryCode: string;
  accountDetailsCountryCode: string;
}

export interface BankCountryNotMatchAccountDetailsIBANCountryStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsIBANCountry;
  bankCountryCode: string;
  iban: string;
  ibanCountryCode: string | null | undefined;
}

export interface BankCountryNotMatchAccountDetailsSWIFTCountryStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsSWIFTCountry;
  bankCountryCode: string;
  swift: string;
  swiftCountryCode: string | null | undefined;
}

export interface ExistingRepresentationsInOtherValidatedAccountsStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.existingRepresentationsInOtherValidatedAccounts;
  validatedPayees: AggregatedValidatedPayee[];
}

export interface ExistingValidatedPayeeVisibilityIncompatibilityStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.visibilityIncompatibilityWithExistingValidatedPayee;
}

export interface NoAccountFullRepresentationStoreWarning {
  type: ValidatedPayeeAccountStoreWarningType.noAccountFullRepresentation;
}

export type ValidatedPayeeAccountStoreWarning =
  | SupplierValidationProcessNotFoundStoreWarning
  | SvManagementRecordNotFoundStoreWarning
  | BankCountryMissingStoreWarning
  | BankCountryNotMatchPayeeCountryStoreWarning
  | BankCountryNotMatchAccountDetailsCountryStoreWarning
  | BankCountryNotMatchAccountDetailsIBANCountryStoreWarning
  | BankCountryNotMatchAccountDetailsSWIFTCountryStoreWarning
  | ExistingRepresentationsInOtherValidatedAccountsStoreWarning
  | NoAccountFullRepresentationStoreWarning
  | ExistingValidatedPayeeVisibilityIncompatibilityStoreWarning;

export function transformValidatedPayeeAccountStoreWarning(
  serverResponse: ValidatedPayeeAccountStoreWarningServerResponse,
): ValidatedPayeeAccountStoreWarning {
  switch (serverResponse.type) {
    case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchPayeeCountry: {
      return {
        ...serverResponse,
        bankCountryCode: serverResponse.bankCountryCode ?? null,
        payeeCountryCode: serverResponse.payeeCountryCode ?? null,
      };
    }
    case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsCountry: {
      return serverResponse;
    }
    case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsIBANCountry: {
      return {
        ...serverResponse,
        ibanCountryCode: serverResponse.ibanCountryCode ?? null,
      };
    }
    case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsSWIFTCountry: {
      return {
        ...serverResponse,
        swiftCountryCode: serverResponse.swiftCountryCode ?? null,
      };
    }
    case ValidatedPayeeAccountStoreWarningType.existingRepresentationsInOtherValidatedAccounts:
      return {
        ...serverResponse,
        validatedPayees: serverResponse.validatedPayees.map(transformAggregatedValidatedPayee),
      };
    case ValidatedPayeeAccountStoreWarningType.supplierValidationProcessNotFound: {
      return serverResponse;
    }
    case ValidatedPayeeAccountStoreWarningType.svManagementRecordNotFound: {
      return serverResponse;
    }
    case ValidatedPayeeAccountStoreWarningType.bankCountryMissing: {
      return serverResponse;
    }
    case ValidatedPayeeAccountStoreWarningType.noAccountFullRepresentation: {
      return serverResponse;
    }
    case ValidatedPayeeAccountStoreWarningType.visibilityIncompatibilityWithExistingValidatedPayee: {
      return serverResponse;
    }
  }
}

//endregion

export interface ValidatedPayeeSearchRequest {
  knoxUniqueId: string | null;
  legalIdentifiers: LegalIdentifierRequest[];
}

export enum ValidatedPayeeAccountVisibility {
  publicVisibility = 'PublicVisibility',
  userOnlyVisibility = 'UserOnlyVisibility',
  organizationalVisibility = 'OrganizationalVisibility',
}

export interface StoreValidatedPayeeAccountRequest {
  uniformId: string | null;
  accountDetails: MorteeAccountDetails;
  bankName: string | null;
  bankCountryCode: string | null;
  bankLogoResourceId: string | null;
  contributingOrganizationId: string | null;
  accountVisibility: ValidatedPayeeAccountVisibility;
  furtherCredit: string | null;
  supplierValidationRegistrationNumber: string | null;
  validationTimestamp: number | null;
  effectiveDateTimestamp: number | null;
  localFormat: string | null;
}

export interface StoreValidatedPayeeAccountWithTypeRequest extends StoreValidatedPayeeAccountRequest {
  type: ValidatedPayeeAccountValidationType;
}

export const ValidatedPayeeAccountVisibilityText: Record<ValidatedPayeeAccountVisibility, string> = {
  [ValidatedPayeeAccountVisibility.userOnlyVisibility]: 'Users Only',
  [ValidatedPayeeAccountVisibility.organizationalVisibility]: 'Organizational',
  [ValidatedPayeeAccountVisibility.publicVisibility]: 'Public',
};

export function excelRowIntoValidatedPayeeData(row: Cell[]): ValidatedPayeeExcelRow {
  return excelRowIntoObject(row, ValidatedPayeeExcelRowFieldToColumn);
}

export interface ValidatedPayeeExcelRow {
  supplierValidationRegistrationNumber: string | null;
  initiatorName: string | null;
  alsoKnownAs: string | null;
  initiatorAddress: string | null;
  secondaryAddress: string | null;
  countryCode: string | null;
  knoxId: string | null;
  KnoxIdSuffix: string | null;
  taxIdCountryCode: string | null;
  taxIdValue: string | null;
  duns: string | null;
  legalId1CountyCode: string | null;
  legalId1Type: string | null;
  legalId1Value: string | null;
  legalId2CountyCode: string | null;
  legalId2Type: string | null;
  legalId2Value: string | null;
  legalId3CountyCode: string | null;
  legalId3Type: string | null;
  legalId3Value: string | null;
  sanctionsDate: string | null;
  sanctionsStatus: string | null;
}

const ValidatedPayeeExcelRowFieldToColumn: ExcelRowDefinition<ValidatedPayeeExcelRow> = {
  supplierValidationRegistrationNumber: 'A',
  sanctionsStatus: 'F',
  initiatorName: 'H',
  alsoKnownAs: 'I',
  initiatorAddress: 'J',
  secondaryAddress: 'K',
  countryCode: 'M',
  legalId1CountyCode: 'O',
  legalId1Type: 'P',
  legalId1Value: 'Q',
  legalId2CountyCode: 'R',
  legalId2Type: 'S',
  legalId2Value: 'T',
  legalId3CountyCode: 'U',
  legalId3Type: 'V',
  legalId3Value: 'W',
  taxIdCountryCode: 'X',
  taxIdValue: 'Y',
  duns: 'Z',
  knoxId: 'AA',
  KnoxIdSuffix: 'AB',
  sanctionsDate: 'AQ',
};

export function excelRowIntoValidatedAccountData(row: Cell[]): ValidatedAccountExcelRow {
  const rowRawObj = excelRowIntoObject(row, ValidatedAccountExcelRowFieldToColumn);

  return {
    ...rowRawObj,
    isExtendedValidation: excelValueToBoolean(rowRawObj.isExtendedValidation),
    storeAsOrganizationalAccount: excelValueToBoolean(rowRawObj.storeAsOrganizationalAccount),
  };
}

export interface ValidatedAccountExcelRow {
  supplierValidationRegistrationNumber: string | null;
  validationType: 'Light SV' | 'Direct' | 'Linked Account' | 'Bank Validation' | 'Internal Validation' | string | null;
  isExtendedValidation: boolean | null;
  storeAsOrganizationalAccount: boolean | null;
  bankName: string | null;
  countryCode: string | null;
  bankCode: string | null;
  branchCode: string | null;
  accountNumber: string | null;
  iban: string | null;
  swiftCode: string | null;
  furtherCredit: string | null;
  effectiveDate: string | null;
  validationDate: string | null;
}

const ValidatedAccountExcelRowFieldToColumn: ExcelRowDefinition<Omit<ValidatedAccountExcelRow, 'localFormat'>> = {
  supplierValidationRegistrationNumber: 'A',
  validationType: 'C',
  isExtendedValidation: 'E',
  storeAsOrganizationalAccount: 'G',
  bankName: 'AC',
  countryCode: 'AE',
  bankCode: 'AG',
  branchCode: 'AH',
  accountNumber: 'AI',
  iban: 'AJ',
  swiftCode: 'AK',
  furtherCredit: 'AO',
  effectiveDate: 'AR',
  validationDate: 'BD',
};
