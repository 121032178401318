import React, { FC, Key, ReactElement, ReactNode } from 'react';
import { identityFunc } from '@app/utils/utils';

interface Props<T> {
  items: T[];
  keyGetter: (item: T) => Key;
  children: (item: T) => ReactNode;
  separator: ReactNode;
}

function SeparatorList<T>({ items, children: itemRenderer, keyGetter, separator }: Props<T>): ReactElement {
  return (
    <>
      {items.map((item, index) => {
        const itemComponent = itemRenderer(item);
        const itemKey = keyGetter(item);
        return (
          <React.Fragment key={itemKey}>
            {index !== 0 && separator}
            {itemComponent}
          </React.Fragment>
        );
      })}
    </>
  );
}

interface StringProps {
  items: string[];
  separator: ReactNode;
}

export const StringSeparatorList: FC<StringProps> = ({ items, separator }) => {
  return <SeparatorList items={items} separator={separator} keyGetter={identityFunc} children={identityFunc} />;
};

export default SeparatorList;
