import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Bold15TransparentBlack900, Regular15TransparentBlack600 } from '@app/components/Text';
import Button from '@app/components/Button';
import { ReminderTimePeriod } from '@mortee/routes/ips/createReminderModal/CreateVerificationReminderForm';

export interface CreateVerificationReminderSummaryFields {
  payerName: string;
  emailsAndDomains: string[];
  accountToMonitor: string;
  timePeriod: ReminderTimePeriod;
}

interface Props {
  values: CreateVerificationReminderSummaryFields;
  onOk(): void;
  onBack(): void;
  className?: string;
}

const CreateVerificationReminderSummary: FC<Props> = ({ values, onOk, onBack, className }) => {
  return (
    <Main className={className}>
      <DetailsList>
        <div>
          <Bold15TransparentBlack900.span>Payer name:{'  '}</Bold15TransparentBlack900.span>
          <Regular15TransparentBlack600.span>{values.payerName}</Regular15TransparentBlack600.span>
        </div>
        <div>
          <Bold15TransparentBlack900.span>Payer emails/domains:{'  '}</Bold15TransparentBlack900.span>
          <Regular15TransparentBlack600.span>{values.emailsAndDomains.join(', ')}</Regular15TransparentBlack600.span>
        </div>
        <div>
          <Bold15TransparentBlack900.span>Your account:{'  '}</Bold15TransparentBlack900.span>
          <Regular15TransparentBlack600.span>{values.accountToMonitor}</Regular15TransparentBlack600.span>
        </div>
        <div>
          <Bold15TransparentBlack900.span>Expiration date:{'  '}</Bold15TransparentBlack900.span>
          <Regular15TransparentBlack600.span>{values.timePeriod} days left</Regular15TransparentBlack600.span>
        </div>
      </DetailsList>
      <ActionsContainer>
        <BackButton id='btn-create-verification-reminder-summary-back' appearance='text' colorScheme='grey' onClick={onBack}>
          {'<'} BACK
        </BackButton>
        <Button id='btn-create-verification-reminder-summary-ok' onClick={onOk}>
          SET REMINDER
        </Button>
      </ActionsContainer>
    </Main>
  );
};

export default CreateVerificationReminderSummary;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsList = styled.div`
  flex: 1;

  & > *:not(:first-child) {
    margin-top: 12px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;

  // To allow the buttons drop shadow to fully show
  padding: 10px var(--side-padding) 8px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const BackButton = styled(Button)`
  margin-left: calc(var(--button-line-padding) * -1); ;
`;
