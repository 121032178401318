import { serverUrl } from '@app/config';
import request, { HttpMethod } from '@app/libs/request';
import { SecureFiles, SecureFilesServerResponse } from '@mortee/domain/secureFiles';

export async function loadSurveysForOrganization(): Promise<SecureFilesServerResponse[]> {
  return request<SecureFilesServerResponse[]>(serverUrl, `/api/secure-files`, {
    method: HttpMethod.get,
    suppressNotification: true,
  });
}

export async function postSurveysForOrganization(data: FormData): Promise<SecureFilesServerResponse[]> {
  return request<SecureFilesServerResponse[]>(serverUrl, `/api/secure-files`, {
    method: HttpMethod.post,
    isFileUpload: true,
    data: data,
    timeout: 300000,
    suppressNotification: true,
  });
}

export async function fetchSecureFileById(secureFileId: string, fileId: string): Promise<NamedResource> {
  return request<NamedResource>(serverUrl, `/api/secure-files/:secureFileId/files/:fileId`, {
    method: HttpMethod.get,
    pathParams: {
      secureFileId,
      fileId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot download secure file.',
      },
    },
  });
}

export function transformSecureFiles(serverResponse: SecureFilesServerResponse): SecureFiles {
  return serverResponse;
}
