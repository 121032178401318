import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import ForgotPasswordEnterEmail from './ForgotPasswordEnterEmail';
import ForgotPasswordEnterCode from './ForgotPasswordEnterCode';
import ForgotPasswordNewPassword from './ForgotPasswordNewPassword';

interface ForgotPasswordFlowProps {
  knoxerId: string;
  initialEmail?: string;
  onFinish: (email: string) => void;
}

enum ForgotPasswordFlowPage {
  EnterEmail,
  EnterCode,
  EnterPassword,
}

const ForgotPasswordFlow: FunctionComponent<ForgotPasswordFlowProps> = observer((props) => {
  const localStore = useLocalObservable(() => ({
    flowPage: ForgotPasswordFlowPage.EnterEmail as ForgotPasswordFlowPage,
    flowId: undefined as string | undefined,
    email: undefined as string | undefined,
    codeLength: undefined as number | undefined,

    handleEmailEntered: (flowId: string, email: string, codeLength: number): void => {
      localStore.flowPage = ForgotPasswordFlowPage.EnterCode;
      localStore.flowId = flowId;
      localStore.email = email;
      localStore.codeLength = codeLength;
    },

    handleReturnToEmailEnter: (): void => {
      localStore.flowId = undefined;
      localStore.codeLength = undefined;
      localStore.flowPage = ForgotPasswordFlowPage.EnterEmail;
    },

    handleCodeEnteredSuccessfully: (): void => {
      localStore.flowPage = ForgotPasswordFlowPage.EnterPassword;
    },
  }));

  const { onFinish, initialEmail, knoxerId } = props;

  switch (localStore.flowPage) {
    case ForgotPasswordFlowPage.EnterEmail: {
      return (
        <ForgotPasswordEnterEmail
          knoxerId={knoxerId}
          initialEmail={localStore.email ?? initialEmail}
          onBack={(): void => onFinish('')}
          onSubmit={localStore.handleEmailEntered}
        />
      );
    }
    case ForgotPasswordFlowPage.EnterCode: {
      if (!localStore.flowId || !localStore.email || !localStore.codeLength) {
        throw new Error(
          `Data missing for code enter, flowId = ${localStore.flowId} email = ${localStore.email} codeLength = ${localStore.codeLength}`,
        );
      }

      return (
        <ForgotPasswordEnterCode
          knoxerId={knoxerId}
          flowId={localStore.flowId}
          email={localStore.email}
          codeLength={localStore.codeLength}
          onBack={localStore.handleReturnToEmailEnter}
          onSubmit={localStore.handleCodeEnteredSuccessfully}
        />
      );
    }
    case ForgotPasswordFlowPage.EnterPassword: {
      if (!localStore.flowId || !localStore.email) {
        throw new Error(`Data missing for password reset, flowId = ${localStore.flowId} email = ${localStore.email}`);
      }

      return (
        <ForgotPasswordNewPassword
          knoxerId={knoxerId}
          flowId={localStore.flowId}
          email={localStore.email}
          onBack={localStore.handleReturnToEmailEnter}
          onSubmit={(): void => onFinish(localStore.email ?? '')}
        />
      );
    }
  }

  return <div />;
});

export default ForgotPasswordFlow;
