import NavigationStore from './NavigationStore';
import PermissionsStore from './PermissionsStore';
import { HttpStatus, setRequestConfig } from '@app/libs/request';
import config from '@app/config';
import * as messageLauncher from '@app/utils/messageLauncher';
import HealthCheckStore from '@app/stores/HealthCheckStore';
import ThemeStore from '@app/stores/ThemeStore';
import LanguageStore from '@app/stores/LanguageStore';
import LocaleStore from '@app/stores/LocaleStore';
import UserStore from '@app/stores/UserStore';

export type DynamicHeadersGenerator = (req: any) => Promise<Record<string, string | number> | undefined>;

export default abstract class InfraStores<
  TNavigationStore extends NavigationStore = NavigationStore,
  TPermissionsStore extends PermissionsStore = PermissionsStore,
  TThemeStore extends ThemeStore = ThemeStore
> {
  userStore: UserStore;
  navigationStore: TNavigationStore;
  permissionsStore: TPermissionsStore;
  languageStore: LanguageStore;
  localeStore: LocaleStore;
  themeStore: TThemeStore;
  healthCheckStore: HealthCheckStore;

  constructor(
    userStore: UserStore,
    languageStore: LanguageStore,
    localeStore: LocaleStore,
    navigationStore: TNavigationStore,
    permissionsStore: TPermissionsStore,
    themeStore: TThemeStore,
    healthCheckStore: HealthCheckStore,
  ) {
    this.userStore = userStore;
    this.localeStore = localeStore;
    this.languageStore = languageStore;
    this.navigationStore = navigationStore;
    this.permissionsStore = permissionsStore;
    this.themeStore = themeStore;
    this.healthCheckStore = healthCheckStore;

    setRequestConfig({
      generateDynamicHeadersPerRequest: this.getDynamicHeadersGenerator(),
      sessionExpiredHandler: this.handleSessionExpired.bind(this),
      timeout: config.defaultRequestTimeout,
      shootErrorNotification: messageLauncher.shootErrorOld,
      headers: { 'Api-Version': config.apiVersion },
      fallbackStatusMessage: {
        [HttpStatus.unauthorized]: 'Login error.',
        [HttpStatus.forbidden]: 'Permission error.',
        [HttpStatus.notFound]: 'Not found.',
      },
    });
  }

  async init(): Promise<void> {
    this.navigationStore.initAfterInfraStoresInitialized();
    this.themeStore.init();
    await this.languageStore.init();
  }

  async handleSessionExpired(): Promise<void> {
    window.location.reload();
  }

  getDynamicHeadersGenerator(): DynamicHeadersGenerator {
    return (): Promise<undefined> => Promise.resolve(undefined);
  }
}
