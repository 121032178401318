import { SupplierValidationRecordQuickFilter, SupplierValidationRecordTableColumn } from '@mortee/domain/validationRecordTable';

export enum TableView {
  communication = 'communication',
  verification = 'verification',
  approval = 'approval',
}

export function getTableViewsForUser(isValidationApprover: boolean): TableViewsOptions {
  if (isValidationApprover) {
    return approverTableViewConfiguration;
  } else {
    return validatorTableViewConfiguration;
  }
}

interface TableViewData {
  text: string;
  columns: SupplierValidationRecordTableColumn[];
  quickFilters: SupplierValidationRecordQuickFilter[];
}

export const TableViewData: Record<TableView, TableViewData> = {
  [TableView.communication]: {
    text: 'Communication',
    columns: [
      SupplierValidationRecordTableColumn.id,
      SupplierValidationRecordTableColumn.supplierName,
      SupplierValidationRecordTableColumn.organizationId,
      SupplierValidationRecordTableColumn.customerInvolvement,
      SupplierValidationRecordTableColumn.automailerStatus,
      SupplierValidationRecordTableColumn.registrationForm,
      SupplierValidationRecordTableColumn.registrationDate,
      SupplierValidationRecordTableColumn.currentInstruction,
      SupplierValidationRecordTableColumn.status,
      SupplierValidationRecordTableColumn.assignee,
    ],
    quickFilters: [
      SupplierValidationRecordQuickFilter.onlyMine,
      SupplierValidationRecordQuickFilter.notCompleted,
      SupplierValidationRecordQuickFilter.unlinkedRecords,
      SupplierValidationRecordQuickFilter.followUp,
    ],
  },
  [TableView.verification]: {
    text: 'Verification',
    columns: [
      SupplierValidationRecordTableColumn.id,
      SupplierValidationRecordTableColumn.evidenceType,
      SupplierValidationRecordTableColumn.supplierName,
      SupplierValidationRecordTableColumn.organizationId,
      SupplierValidationRecordTableColumn.registrationForm,
      SupplierValidationRecordTableColumn.registrationDate,
      SupplierValidationRecordTableColumn.currentInstruction,
      SupplierValidationRecordTableColumn.status,
      SupplierValidationRecordTableColumn.assignee,
    ],
    quickFilters: [
      SupplierValidationRecordQuickFilter.onlyMine,
      SupplierValidationRecordQuickFilter.notCompleted,
      SupplierValidationRecordQuickFilter.unlinkedRecords,
    ],
  },
  [TableView.approval]: {
    text: 'Approval',
    columns: [
      SupplierValidationRecordTableColumn.id,
      SupplierValidationRecordTableColumn.evidenceType,
      SupplierValidationRecordTableColumn.supplierName,
      SupplierValidationRecordTableColumn.organizationId,
      SupplierValidationRecordTableColumn.registrationForm,
      SupplierValidationRecordTableColumn.registrationDate,
      SupplierValidationRecordTableColumn.currentInstruction,
      SupplierValidationRecordTableColumn.status,
      SupplierValidationRecordTableColumn.assignee,
    ],
    quickFilters: [
      SupplierValidationRecordQuickFilter.onlyMine,
      SupplierValidationRecordQuickFilter.notCompleted,
      SupplierValidationRecordQuickFilter.unlinkedRecords,
    ],
  },
};

interface TableViewsOptions {
  readonly options: TableView[];
  readonly default: TableView;
}

const approverTableViewConfiguration: TableViewsOptions = {
  options: [TableView.communication, TableView.verification, TableView.approval],
  default: TableView.approval,
};

const validatorTableViewConfiguration: TableViewsOptions = {
  options: [TableView.communication, TableView.verification],
  default: TableView.communication,
};
