import React, { ClipboardEvent, useContext, useState } from 'react';
import ValueWithPlaceholder from './ValueWithPlaceholder';
import { ForwardingFC } from '@app/domain/technicals/components';
import { FormInputProps } from '@app/utils/form/form';
import FormInputsContext from './FormInputsContext';
import { arrayWithoutIndex, mergeArrays } from '@app/utils/arrayUtils';
import { KeyAsciiValue } from '@app/domain/uiConsts';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { ValueSynchronousValidator } from '@app/utils/validators';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import InternalInputStyle from './InternalInputStyle';
import { DeletableChip } from '../Chip';
import { readPlaintextContentFromClipboardData } from '@app/utils/clipboardUtils';
import { splitByMultiple, trimToNull } from '@app/utils/stringUtils';
import { isTruthy } from '@app/utils/utils';
import ChipInput, { ChipRenderer, StandardTextFieldProps } from '@app/libs/material-ui-chip-input';
import { ColorScheme } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import Button from '@app/components/Button';
import { useTranslation } from 'react-i18next';

type Props = FormInputProps<string[]> &
  Omit<StandardTextFieldProps, 'value' | 'onChange'> & {
    id: string;
    colorScheme?: ColorScheme;
    textColorScheme?: ColorScheme;
    disabled?: boolean;
    pasteItemsSeparators?: string[];
    arrayItemValidator?: ValueSynchronousValidator;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    className?: string;
    dataTestId?: string;
    clearable?: boolean;
    onClear?: () => void;
  };

const NakedFormChipList: ForwardingFC<HTMLDivElement, Props> = React.forwardRef((props, ref) => {
  const { state } = useContext(FormInputsContext);

  const {
    id,
    colorScheme: propColorScheme,
    textColorScheme: propTextColorScheme,
    value = [],
    accessibilityLabel,
    placeholder,
    placeholderStyle,
    heightType,
    disabled: disabledProp,
    pasteItemsSeparators,
    onChange,
    arrayItemValidator,
    onClick,
    onFocus,
    onKeyPress,
    newChipKeyCodes = [KeyAsciiValue.Enter, KeyAsciiValue.Space],
    className,
    dataTestId,
    clearable,
    onClear,
    ...chipInputProps
  } = props;

  const { t } = useTranslation();
  const colorScheme = useColorScheme(propColorScheme);
  const disabled = disabledProp || state === 'disabled';
  const textColorScheme = useColorScheme(disabled ? 'disabled' : undefined, propTextColorScheme, 'darkGrey');
  const [editingInputValue, setEditingInputValue] = useState('');

  const onAdd = (newValue: string): void => {
    onChange?.([...(value ?? []), newValue]);
    setEditingInputValue('');
  };

  const onDelete = (removedValue: string, index: number): void => {
    onChange?.(arrayWithoutIndex(value ?? [], index));
  };

  const onPaste = (event: ClipboardEvent<HTMLDivElement>): void => {
    const clipboardData = readPlaintextContentFromClipboardData(event.clipboardData);
    if (!clipboardData) {
      return;
    }

    event.preventDefault();

    const newValues = splitByMultiple(clipboardData, pasteItemsSeparators).map(trimToNull).filter(isTruthy);

    const lastValue = newValues[newValues.length - 1];

    if (lastValue && arrayItemValidator?.(lastValue)) {
      // The last value it invalid, we should not add it as a chip, but as the value of the editing input
      onChange?.(mergeArrays([value, arrayWithoutIndex(newValues, newValues.length - 1)], true));
      setEditingInputValue(lastValue);
    } else {
      onChange?.(mergeArrays([value, newValues], true));
    }
  };

  const clearContent = (): void => {
    if (clearable && onChange) {
      onChange([]);
    }
  };

  const chipRenderer: ChipRenderer = ({ chip, className, isDisabled, isReadOnly, isFocused, handleClick, handleDelete }, key) => {
    const valueValidationResultMessage = arrayItemValidator?.(chip);

    const isValueInvalid = !!valueValidationResultMessage;

    return (
      <ConditionalTooltip key={key} showTooltip={isValueInvalid} title={valueValidationResultMessage}>
        <StyledDeletableChip
          id={`${id}-chip-${key}`}
          dataTestId={`chip-${key}`}
          appearance={isValueInvalid ? 'full' : 'outline'}
          colorScheme={isValueInvalid ? 'red' : colorScheme}
          onDelete={handleDelete}
          disabled={isDisabled}
        >
          {chip}
        </StyledDeletableChip>
      </ConditionalTooltip>
    );
  };

  const placeholderElementId = `placeholder-of-${id}`;

  return (
    <div data-testid={dataTestId} id={id}>
      <StyledValueWithPlaceholder
        valueNotEmpty={!!value?.length}
        placeholder={placeholder ?? null}
        placeholderElementId={placeholderElementId}
        placeholderStyle={placeholderStyle}
        colorScheme={colorScheme}
        heightType={heightType}
      >
        <Line>
          <ChipInput
            className={className}
            ref={ref}
            value={value}
            onAdd={onAdd}
            onDelete={onDelete}
            inputValue={editingInputValue}
            onUpdateInput={(event): void => setEditingInputValue(event.target.value)}
            onKeyPress={onKeyPress}
            onClick={onClick}
            newChipKeyCodes={newChipKeyCodes}
            onFocus={onFocus}
            onPaste={onPaste}
            disabled={disabled}
            blurBehavior='add'
            disableUnderline
            fullWidth
            chipRenderer={chipRenderer}
            classes={{
              chipContainer: chipContainerClassName,
              input: InternalInputStyle(colorScheme, textColorScheme, !!disabled),
            }}
            InputProps={{
              'aria-label': accessibilityLabel,
              'aria-labelledby': accessibilityLabel ? undefined : placeholderElementId,
            }}
            {...chipInputProps}
          />
          {clearable && !!value?.length && (
            <ClickEventPropagationBlocker>
              <ClearButton
                id={`btn-${id}-clean`}
                dataTestId={`btn-${dataTestId}-clean`}
                appearance='text'
                colorScheme='primary'
                onClick={clearContent}
                disabled={disabled}
              >
                <SVG accessibilityLabel={t('general.accessibility.clearText', { placeholder })} image={ClearIcon} height={18} />
              </ClearButton>
            </ClickEventPropagationBlocker>
          )}
        </Line>
      </StyledValueWithPlaceholder>
    </div>
  );
});

export default NakedFormChipList;

const chipContainerClassName = css`
  gap: 4px 10px;
  align-items: center !important;

  min-height: 0 !important;
`;

const StyledValueWithPlaceholder = styled(ValueWithPlaceholder)`
  line-height: normal;
`;

const StyledDeletableChip = styled(DeletableChip)`
  // Hack to lower chip position without over complicating
  margin-bottom: -2px;
`;

const ClearButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(53, 60, 79, 0.4);
  transition: 0.1s all ease-in-out;
  align-self: flex-end;
  padding: 0 3px 1px;
  margin-bottom: 2px;
`;

const Line = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
`;
