import { HttpMethod, HttpStatus, WebError, WebErrorCode } from './httpConsts';
import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse, RawAxiosResponseHeaders } from 'axios';
import { createEnglishFormalErrorMessage } from '@app/utils/errorMessageUtils';
import { ReactNode } from 'react';
import pathToRegexp from 'path-to-regexp';
import { mapValues } from '@app/utils/utils';
import qs from 'query-string';
import { combinePaths } from '@app/utils/stringUtils';

const LOG_CORRELATION_HEADER = 'request-correlation-id';
const LOG_SUPPORT_HEADER = 'support-id';

interface HttpCodeErrorHandlerOptions {
  message?: ReactNode;
  suppressNotification?: boolean;
}

interface WebErrorCodeErrorHandlerOptions<T> {
  message?: (additionalData: T) => ReactNode;
  suppressNotification?: boolean;
}

interface GeneralErrorHandler {
  message: ReactNode;
  suppressNotification: boolean;
}

const xsrfCookieName = '__Secure-XSRF-TOKEN';
const xsrfHeaderName = 'X-XSRF-TOKEN';

type HttpCodesErrorHandler = Record<number | 'default', HttpCodeErrorHandlerOptions>;
type WebErrorCodesErrorHandler = {
  [P in keyof WebError]?: WebErrorCodeErrorHandlerOptions<WebError[P]>;
};
type ErrorsHandler = HttpCodesErrorHandler & WebErrorCodesErrorHandler;

interface CustomRequestOptions {
  pathParams?: Record<string, string>;
  suppressNotification?: boolean;
  errorsHandler?: ErrorsHandler;
  dontRedirectToLogin?: boolean;
  isFileUpload?: boolean;
  secretId?: string;
  responseHeadersKeys?: string[];
  generateDynamicHeaders?: boolean;
  onUploadProgress?: (percentComplete: number) => void;
}

export interface RequestOptions extends Omit<AxiosRequestConfig, 'url' | 'onUploadProgress'>, CustomRequestOptions {}

interface RequestConfig {
  timeout: number;
  headers?: Record<string, any>;
  fallbackStatusMessage: Record<number, string>;
  sessionExpiredHandler(): Promise<void>;
  shootErrorNotification(msg: string): void;
  generateDynamicHeadersPerRequest(req: RequestOptions): Promise<{ [key: string]: string | number } | undefined>;
}

export interface RequestError {
  responseJSON: unknown;
  code: number;
  message: string;
  logSupportId: string | undefined;
  logCorrelationId: string | undefined;
}

export function extractLogErrorIdFromError(e: unknown): string | undefined {
  if (isRequestError(e)) {
    return e.logCorrelationId || e.logSupportId;
  }

  return undefined;
}

export function isRequestError(body: any): body is RequestError {
  return typeof body?.code === 'number';
}

export interface WebErrorContent {
  error: WebErrorCode;
  additionalData: any;
}

export function isWebErrorContent(body: any): body is WebErrorContent {
  return typeof body?.error === 'string';
}

export interface AuthenticatorErrorContent {
  errorCode: number;
  message: string;
}

export function isAuthenticatorErrorContent(body: any): body is AuthenticatorErrorContent {
  return typeof body?.errorCode === 'number';
}

interface ResponseWithHeader<TResult> {
  result: TResult;
  headers: Map<string, string | null>;
  logCorrelationId: string | undefined;
  logSupportId: string | undefined;
}

class WebRequest {
  private config: RequestConfig = {
    timeout: 7000,
    sessionExpiredHandler: async () => {
      window.location.reload();
    },
    fallbackStatusMessage: {},
    shootErrorNotification: () => {},
    generateDynamicHeadersPerRequest: () => Promise.resolve({}),
  };

  setRequestConfig = (params: Partial<RequestConfig>): void => {
    this.config = {
      ...this.config,
      ...params,
    };
  };

  request = async <R>(baseUrl: string, path: string | null, options: RequestOptions): Promise<R> => {
    const responseWithHeader = await this.requestWithHeaders<R>(baseUrl, path, options);
    return responseWithHeader.result;
  };

  requestWithHeaders = async <R>(
    baseUrl: string,
    path: string | null,
    options: RequestOptions,
  ): Promise<ResponseWithHeader<R>> => {
    options = {
      xsrfCookieName,
      xsrfHeaderName,
      responseHeadersKeys: [LOG_CORRELATION_HEADER, LOG_SUPPORT_HEADER],
      isFileUpload: false,
      suppressNotification: false,
      dontRedirectToLogin: false,
      generateDynamicHeaders: true,
      timeout: this.config.timeout,
      withCredentials: true,
      ...options,
    };

    const {
      pathParams,
      suppressNotification,
      errorsHandler,
      dontRedirectToLogin,
      isFileUpload,
      secretId,
      responseHeadersKeys,
      generateDynamicHeaders: shouldGenerateDynamicHeaders,
      onUploadProgress,
      ...restOfOptions
    } = options;

    const dynamicHeaders = await this.generateDynamicHeaders(shouldGenerateDynamicHeaders, options);
    const axiosOptions: Omit<AxiosRequestConfig, 'url'> = {
      ...restOfOptions,
      headers: {
        ...options.headers,
        ...dynamicHeaders,
        ...WebRequest.generateFileUploadHeaders(isFileUpload),
      },
      onUploadProgress: function (evt: AxiosProgressEvent): void {
        if (evt?.lengthComputable && onUploadProgress && evt.total !== undefined) {
          const percentComplete = (evt.loaded / evt.total) * 100;
          onUploadProgress(percentComplete);
        }
      },
      transitional: {
        clarifyTimeoutError: true,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { skipNull: true, skipEmptyString: true });
      },
    };

    let axiosResponse: AxiosResponse;

    const realUrl = this.buildUrl(baseUrl, path, pathParams);
    try {
      axiosResponse = await axios(realUrl, axiosOptions);
    } catch (e: unknown) {
      if (!axios.isAxiosError(e)) {
        throw e;
      }

      if (e.code === AxiosError.ETIMEDOUT) {
        this.config.shootErrorNotification(createEnglishFormalErrorMessage('A request took a bit too long', btoa(realUrl)));
      }

      if (e.code === AxiosError.ERR_NETWORK) {
        this.config.shootErrorNotification(createEnglishFormalErrorMessage('Network error', btoa(realUrl)));
      }

      const { response } = e as AxiosError;

      if (!response) {
        throw e;
      }

      throw await this.createRequestErrorFromResponse(response, errorsHandler, options);
    }

    const logSupportId = WebRequest.getSupportId(axiosResponse.headers);
    if (logSupportId) {
      throw await this.createRequestErrorFromResponse(axiosResponse, errorsHandler, options);
    }

    const headers = new Map<string, string | null>();

    responseHeadersKeys?.forEach((key: string) => {
      if (key !== LOG_CORRELATION_HEADER && key !== LOG_SUPPORT_HEADER) {
        headers.set(key, axiosResponse.headers[key]);
      }
    });

    const logCorrelationId = WebRequest.getCorrelationId(axiosResponse.headers);

    return { headers, logCorrelationId, logSupportId, result: axiosResponse.data };
  };

  private buildUrl(baseUrl: string, path: string | null, pathParams: Record<string, string> | undefined): string {
    return combinePaths(baseUrl, this.encodePathParamsInfoPath(pathParams, path));
  }

  private encodePathParamsInfoPath(pathParams: Record<string, string> | undefined, path: string | null): string | null {
    if (!pathParams || !Object.keys(pathParams).length) {
      return path || null;
    }

    if (!path) {
      throw new Error('Cannot have pathParams without a path');
    }

    const encodedPathParams = mapValues<string, string, string>(pathParams, (pathParam) => encodeURIComponent(pathParam));

    return pathToRegexp.compile(path)(encodedPathParams);
  }

  private async createRequestErrorFromResponse(
    response: AxiosResponse,
    errorsHandler: ErrorsHandler | undefined,
    requestOptions: RequestOptions,
  ): Promise<RequestError> {
    const logCorrelationId = WebRequest.getCorrelationId(response.headers);
    const logSupportId = WebRequest.getSupportId(response.headers);

    const statusCode = response.status;
    const responseJSON = response.data;

    const errorLogId = logCorrelationId || logSupportId;
    const { suppressNotification, dontRedirectToLogin } = requestOptions;

    const errorHandler = this.getErrorHandler(errorsHandler, responseJSON, statusCode, suppressNotification);
    const completeErrorMessage = createEnglishFormalErrorMessage(errorHandler.message, errorLogId);

    const returnError: RequestError = {
      responseJSON,
      code: statusCode,
      message: completeErrorMessage,
      logCorrelationId,
      logSupportId,
    };

    if (!dontRedirectToLogin && returnError.code === HttpStatus.unauthorized) {
      await this.config.sessionExpiredHandler();
    }

    // Show notification only if global suppressNotification and specific suppressNotification are false
    if (!errorHandler.suppressNotification) {
      this.config.shootErrorNotification(returnError.message);
    }

    return returnError;
  }

  private async generateDynamicHeaders(
    shouldGenerateDynamicHeaders: boolean | undefined,
    options: RequestOptions,
  ): Promise<{ [key: string]: string | number } | undefined> {
    if (shouldGenerateDynamicHeaders !== false) {
      return this.config.generateDynamicHeadersPerRequest(options);
    }
  }

  private static generateFileUploadHeaders(isFileUpload: boolean | undefined): { [key: string]: string | number } | undefined {
    if (isFileUpload) {
      return { 'Content-Type': 'multipart/form-data' };
    }
  }

  private static getCorrelationId(responseHeaders: RawAxiosResponseHeaders | undefined): string | undefined {
    if (typeof responseHeaders?.[LOG_CORRELATION_HEADER] === 'string') {
      return responseHeaders?.[LOG_CORRELATION_HEADER];
    }

    return undefined;
  }

  private static getSupportId(responseHeaders: RawAxiosResponseHeaders | undefined): string | undefined {
    if (typeof responseHeaders?.[LOG_SUPPORT_HEADER] === 'string') {
      return responseHeaders?.[LOG_SUPPORT_HEADER];
    }

    return undefined;
  }

  private getFallbackMessage(statusCode: number): string | undefined {
    return this.config.fallbackStatusMessage[statusCode];
  }

  private getErrorHandler(
    errorsHandler: ErrorsHandler | undefined,
    responseJSON: any,
    statusCode: number,
    defaultSuppressNotification: boolean | undefined,
  ): GeneralErrorHandler {
    let webErrorHandler: WebErrorCodeErrorHandlerOptions<unknown> | undefined;
    let httpStatusCodeErrorHandler: HttpCodeErrorHandlerOptions | undefined;

    if (errorsHandler) {
      if (isWebErrorContent(responseJSON)) {
        webErrorHandler = errorsHandler[responseJSON.error];
      }

      httpStatusCodeErrorHandler = errorsHandler[statusCode];
    }

    const message =
      webErrorHandler?.message?.(responseJSON.additionalData) ||
      httpStatusCodeErrorHandler?.message ||
      errorsHandler?.default?.message ||
      this.getFallbackMessage(statusCode) ||
      'Unexpected error occurred.';

    const suppressNotification =
      webErrorHandler?.suppressNotification ||
      httpStatusCodeErrorHandler?.suppressNotification ||
      errorsHandler?.default?.suppressNotification ||
      defaultSuppressNotification ||
      false;

    return {
      message,
      suppressNotification,
    };
  }
}

const webRequestInstance = new WebRequest();
const { setRequestConfig, request } = webRequestInstance;

export { HttpStatus, HttpMethod, setRequestConfig };

export default request;
