import React, { FunctionComponent, ReactElement } from 'react';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import InputDecorator from './InputDecorator';
import FormItemsBox, { FormItemsBoxProps } from './FormItemsBox';

export interface FormItemBoxProps extends Omit<FormItemsBoxProps, 'fieldNames'> {
  fieldName: string;
  fieldDecoratorOptions: GetFieldDecoratorOptions | null;
  children: ReactElement;
}

const FormItemBox: FunctionComponent<FormItemBoxProps> = (props) => {
  const { appearance, colorScheme, disabled, form, fieldName, fieldDecoratorOptions, showErrors, children, ...rest } = props;

  return (
    <FormItemsBox
      appearance={appearance}
      colorScheme={colorScheme}
      disabled={disabled}
      form={form}
      fieldNames={[fieldName]}
      showErrors={showErrors}
      {...rest}
    >
      <InputDecorator form={form} fieldName={fieldName} fieldDecoratorOptions={fieldDecoratorOptions}>
        {children}
      </InputDecorator>
    </FormItemsBox>
  );
};

export default FormItemBox;
