import { SUBDOMAIN_REGEX } from '@app/utils/regexUtils';

export default function useSubdomainFromLocation(allowCustomerCustomization: boolean): string | null {
  if (!allowCustomerCustomization) {
    return null;
  }

  return extractSubdomainFromLocation();
}

export function extractSubdomainFromLocation(): string | null {
  const splitUrl = window.location.host.split('.');
  if (splitUrl && splitUrl.length === 4 && splitUrl[0].match(SUBDOMAIN_REGEX)) {
    return splitUrl[0];
  }

  return null;
}
