import React, { FC } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';

interface Props {
  image: SpriteSymbol;
  iconAccessibilityLabel: string;
  className?: string;
}

const IconAndText: FC<Props> = ({ image, iconAccessibilityLabel, className, children }) => {
  return (
    <Container className={className}>
      <SVGByTextSize image={image} accessibilityLabel={iconAccessibilityLabel} />
      {children}
    </Container>
  );
};

export default IconAndText;

const Container = styled.span`
  display: inline-flex;
  gap: 0.3em;
`;

const SVGByTextSize = styled(SVG)`
  height: 1.2em;
`;
