import Loadable from '@app/utils/Loadable';
import { useEffect } from 'react';
import { SUBDOMAIN_REGEX } from '@app/utils/regexUtils';
import { ARConfiguration } from '@ar/domain/arGeneral';
import ArMode from '@ar/arMode';
import useInfraStores from '@app/hooks/useInfraStores';
import config from '@app/config';

export default function useARDynamicSubdomain(): Loadable<ARConfiguration | null> {
  const { customerConfigurationStore } = useInfraStores<ArMode>();

  const subdomain = extractSubdomainFromLocation();

  useEffect(() => {
    customerConfigurationStore.tryLoadConfigurationBySubdomain(subdomain);
  }, [customerConfigurationStore, subdomain]);

  return customerConfigurationStore.currentConfiguration;
}

export function extractSubdomainFromLocation(): string | null {
  if (!config.featureFlags?.hallucinateSEBSubdomain) {
    return getRealSubdomain();
  }

  return 'seb';
}

function getRealSubdomain(): string | null {
  const splitUrl = window.location.host.split('.');
  if (splitUrl && splitUrl.length === 4 && splitUrl[0].match(SUBDOMAIN_REGEX)) {
    return splitUrl[0];
  }

  return null;
}
