import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Regular14TransparentBlack900 } from '@app/components/Text';

export interface TimelineItem {
  key: string;
  content: ReactNode;
  icon: ReactNode;
}

interface Props {
  id: string;
  dataTestId: string;
  items: TimelineItem[];
  className?: string;
}

const TimelineWithLeftDots: FC<Props> = (props) => {
  const { id, dataTestId, items, className } = props;
  return (
    <MainContainer className={className}>
      {items.map((option, index) => {
        return (
          <TimelineItem
            key={option.key}
            id={`${id}-item-${option.key}`}
            dataTestId={`${dataTestId}-item-${option.key}`}
            icon={option.icon}
            isFirstItem={index === 0}
            isLastItem={index === items.length - 1}
          >
            {option.content}
          </TimelineItem>
        );
      })}
    </MainContainer>
  );
};

export interface ProgressTabProps {
  id: string;
  dataTestId?: string;
  icon: ReactNode;
  isFirstItem: boolean;
  isLastItem: boolean;
  className?: string;
}

const TimelineItem: FC<ProgressTabProps> = ({ id, dataTestId = id, icon, isFirstItem, isLastItem, className, children }) => {
  return (
    <ItemRow className={className} id={id} data-testid={dataTestId}>
      <BulletAndVerticalLinesWrapper>
        {isFirstItem ? <div /> : <VerticalLine />}
        <ItemBullet hasIcon={!!icon}>{icon}</ItemBullet>
        {isLastItem ? <div /> : <VerticalLine />}
      </BulletAndVerticalLinesWrapper>
      <TimelineItemContent>{children}</TimelineItemContent>
    </ItemRow>
  );
};

export default TimelineWithLeftDots;

const MainContainer = styled(Regular14TransparentBlack900.div)`
  --bullet-dimention: 22px;
  --content-vertical-padding: 25px;
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: var(--bullet-dimention) 1fr;
  align-items: stretch;
  transition: background-color 0.2s;
  min-height: calc(var(--bullet-dimention) + 36px);
  padding-inline: 24px;
`;

const BulletAndVerticalLinesWrapper = styled.span`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: var(--content-vertical-padding) auto 1fr;
  justify-items: center;

  grid-column: 1;
`;

const TimelineItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: var(--content-vertical-padding) 10px;

  grid-column: 2;
`;

const ItemBullet = styled.div<{ hasIcon: boolean }>`
  width: var(--bullet-dimention);
  height: var(--bullet-dimention);
  transition: outline 0.1s, outline-offset 0.1s;

  border-radius: var(--bullet-dimention);

  // Limit the content to not exit the bullet
  clip-path: circle(calc(var(--bullet-dimention) * 0.5));

  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerticalLine = styled.span`
  display: inline-block;
  border-right: solid 1px var(--transparent-black-200);
`;
