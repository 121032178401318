import React, { FC, ReactElement } from 'react';
import {
  SupplierValidationPayeeWithAccountsVerification,
  SupplierValidationRecord,
  transformSupplierValidationPayeeWithAccountsVerification,
  transformSupplierValidationVerificationRecordEvidence,
} from '@mortee/domain/validationSystem';
import validationSystemServices from '@mortee/services/validationSystemServices';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { LoadableCreator } from '@app/utils/Loadable';
import VerificationRecordDataTabContent from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationRecordDataTabContent';
import { HttpStatus, isRequestError } from '@app/libs/request';
import styled from '@emotion/styled';
import LoadableTransitionLoader from '@app/components/LoadableTransitionLoader';

interface Props {
  record: SupplierValidationRecord;
  isPendingApprovalAndDoesntHaveApprovalRole: boolean;
  onRecordSaved(updatedSupplierValidationRecord?: SupplierValidationRecord): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean): void;
  className?: string;
}

const VerificationRecordDataTab: FC<Props> = (props) => {
  const { onIsFormUnsavedChanged, onRecordSaved, record, isPendingApprovalAndDoesntHaveApprovalRole } = props;
  const staticId = record.staticId;

  const [verificationDataLoadable, , setVerificationDataLoadable] = useLoadable(async () => {
    const serverResponse = await validationSystemServices.getVerificationDataValidationRecordByStaticId(record.staticId);
    return transformSupplierValidationPayeeWithAccountsVerification(serverResponse);
  }, [record.staticId]);

  const [evidenceLoadable] = useLoadable(async () => {
    try {
      const serverResponse = await validationSystemServices.getVerificationRecordEvidence(staticId);
      return transformSupplierValidationVerificationRecordEvidence(serverResponse);
    } catch (e) {
      if (isRequestError(e) && e.code === HttpStatus.notFound) {
        return null;
      }
    }
  }, [staticId]);

  function onSaved(
    updatedVerificationData: SupplierValidationPayeeWithAccountsVerification | undefined,
    updatedRecord: SupplierValidationRecord | undefined,
  ): void {
    if (updatedVerificationData) {
      setVerificationDataLoadable(LoadableCreator.resolved(updatedVerificationData));
    }

    onRecordSaved(updatedRecord);
  }

  return (
    <MainWrapper>
      <LoadableTransitionLoader loadable={LoadableCreator.combine(verificationDataLoadable, evidenceLoadable)}>
        {([verificationData, evidence]): ReactElement => (
          <VerificationRecordDataTabContent
            isPendingApprovalAndDoesntHaveApprovalRole={isPendingApprovalAndDoesntHaveApprovalRole}
            record={record}
            verificationRecordData={verificationData}
            evidenceData={evidence}
            onSaved={onSaved}
            onIsFormUnsavedChanged={onIsFormUnsavedChanged}
          />
        )}
      </LoadableTransitionLoader>
    </MainWrapper>
  );
};

export default VerificationRecordDataTab;

const MainWrapper = styled.div`
  position: relative;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex: 1;
`;
