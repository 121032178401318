import styled from '@emotion/styled';

const InnerCard = styled.div`
  padding: 28px 26px;
  border-radius: 4px;
  border: solid 1px var(--transparent-black-200);
  background-color: var(--inner-card-background-color);
`;

export const InnerCardGrey = styled.div`
  padding: 28px 26px;
  border-radius: 4px;
  background-color: var(--inner-card-background-color-grey);
`;

export default InnerCard;
