import { makeObservable, observable } from 'mobx';
import { CurrentLanguageProvider } from '@app/stores/LanguageStore';

export default class LocaleStore {
  @observable languageProvider: CurrentLanguageProvider;

  constructor(languageProvider: CurrentLanguageProvider) {
    makeObservable(this);

    this.languageProvider = languageProvider;
  }

  formatDate(date: Date | number, options?: Intl.DateTimeFormatOptions): string {
    return new Intl.DateTimeFormat(this.languageProvider.selectedLocale, options).format(date);
  }

  formatTime(date: Date | number, options?: Intl.DateTimeFormatOptions): string {
    return new Intl.DateTimeFormat(
      this.languageProvider.selectedLocale,
      options || {
        hour: 'numeric',
        minute: 'numeric',
      },
    ).format(date);
  }

  formatDateTime(date: Date | number, options?: Intl.DateTimeFormatOptions): string {
    if (options) {
      return new Intl.DateTimeFormat(this.languageProvider.selectedLocale, options).format(date);
    }

    return `${this.formatDate(date)} ${this.formatTime(date)}`;
  }

  formatDateTimeForFilename(date: Date | number, options?: Intl.DateTimeFormatOptions): string {
    const dateTimeParts = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      ...options,
    }).formatToParts(date);

    const partsMap = new Map<Intl.DateTimeFormatPartTypes, string>(dateTimeParts.map((part) => [part.type, part.value]));

    return `${partsMap.get('day')}-${partsMap.get('month')}-${partsMap.get('year')} - \
${partsMap.get('hour')}-${partsMap.get('minute')}`;
  }

  formatNumber(amount: number, options?: Intl.NumberFormatOptions): string {
    return new Intl.NumberFormat(this.languageProvider.selectedLocale, { ...options }).format(amount);
  }

  formatCurrency(amount: number, currency: string, options?: Intl.NumberFormatOptions): string {
    return new Intl.NumberFormat(this.languageProvider.selectedLocale, {
      currency,
      style: 'currency',
      ...options,
    }).format(amount);
  }
}
