import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';

enum VietnameseLegalIdTypesForCompany {
  TaxIdentificationNumber = 'Tax Identification Number',
  Other = 'Other',
}

function getAdditionalSwiftRules(): ConditionalValidationRule[] {
  return [
    {
      required: true,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: false,
        transform: trim,
      },
    ],
  };
}

function getBranchCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-VN-branch-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBranchCode',
      },
      {
        pattern: VALIDATION_PATTERNS.numeric,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidBranchCode',
      },
    ],
  };
}

export const VN: SupplierRegistrationCountryFields = {
  additionalCompanyName: true,
  showBankName: true,
  hideFurtherCredit: true,
  leis: { types: VietnameseLegalIdTypesForCompany },
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
  bankCode: getBankCodeFields,
  branchCode: getBranchCodeFields,
};
