import {
  LegalEntityIdentifier,
  LegalEntityIdentifierServerResponse,
  StrongLegalIdentifiesTypes,
} from '@app/domain/legalEntityIdentifier';
import { isDefined } from '@app/utils/utils';

export interface VerificationLegalEntityIdentifierServerResponse extends LegalEntityIdentifierServerResponse {
  isPrimary: boolean;
}

export interface VerificationLegalEntityIdentifier extends LegalEntityIdentifier {
  isPrimary: boolean;
}

export type VerificationStrongLegalIdentifiesServerResponse = Partial<
  Record<StrongLegalIdentifiesTypes, VerificationLegalEntityIdentifierServerResponse[]>
>;

export type VerificationStrongLegalIdentifies = Partial<Record<StrongLegalIdentifiesTypes, VerificationLegalEntityIdentifier[]>>;

export interface VerificationLegalIdentifierRequest {
  countryCode: string | null;
  value: string;
  typeId: string | null;
  isPrimary: boolean;
}

export function transformVerificationLegalEntityIdentifierToVerificationLegalIdentifierRequest(
  lei: VerificationLegalEntityIdentifier,
): VerificationLegalIdentifierRequest {
  return {
    typeId: lei.typeId ?? null,
    countryCode: lei.countryCode ?? null,
    value: lei.value,
    isPrimary: lei.isPrimary,
  };
}

export function markFirstAsPrimary(
  leis: (VerificationLegalIdentifierRequest | undefined)[],
): VerificationLegalIdentifierRequest[] {
  const existingLeis = leis.filter(isDefined);
  if (!existingLeis.length) {
    return existingLeis;
  }

  if (existingLeis.some((lei) => lei.isPrimary)) {
    return existingLeis;
  }

  existingLeis[0] = {
    ...existingLeis[0],
    isPrimary: true,
  };

  return existingLeis;
}
