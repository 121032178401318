import React, { ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import InputBox, { InputBoxAppearance } from './inputBox/InputBox';
import NakedDropdown from './NakedDropdown';
import { createNTimes } from '@app/utils/arrayUtils';
import DropdownItem from './DropdownItem';
import { ForwardingFC } from '@app/domain/technicals/components';
import { daysInMonth, isPastDate as isEpochPastDate } from '@app/utils/timeUtils';
import { FormInputProps } from '@app/utils/form/form';
import { useTranslation } from 'react-i18next';
import { isDefined } from '@app/utils/utils';

export interface DatePickerValue {
  day?: number;
  month?: number;
  year?: number;
}

export enum DatePickerFormat {
  YYMMDD = 'YYMMDD',
  MMDDYY = 'MMDDYY',
  DDMMYY = 'DDMMYY',
}

export function convertDatePickerValueToEpoch(value: DatePickerValue | undefined): number | undefined {
  // month can have a valid value of 0 (month of january)
  if (!value || !value.day || value.month === null || value.month === undefined || !value.year) {
    return;
  }

  return new Date(value.year, value.month, value.day).getTime();
}

export function convertToDatePickerFormat(date: Date): DatePickerValue | undefined {
  if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
    return;
  }

  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };
}

export function convertEpochToDatePickerValue(value: number | undefined | null): DatePickerValue | undefined {
  if (!isDefined(value)) {
    return;
  }

  const dateFromValue = new Date(value);
  return convertToDatePickerFormat(dateFromValue);
}

type ValidatorFunction = { validator: (rule, value: DatePickerValue | undefined, callback) => void; message: string };

export function isPastDate(date: DatePickerValue | undefined): boolean {
  if (!date) {
    return false;
  }

  const dateEpoch = convertDatePickerValueToEpoch(date);
  if (!dateEpoch) {
    return false;
  }

  return isEpochPastDate(dateEpoch);
}

export function datePickerValueFullDateRepresentationValidator(): ValidatorFunction {
  return {
    validator: (rule, value: DatePickerValue | undefined, callback): void => {
      if (!value || (value?.day && value?.month && value?.year)) {
        return callback();
      }

      const dateEpoch = convertDatePickerValueToEpoch(value);
      if (!dateEpoch) {
        return callback('Date is incomplete');
      }

      return callback();
    },
    message: 'Date is incomplete',
  };
}

export function datePickerValueNotFutureTimestampValidator(): ValidatorFunction {
  return {
    validator: (rule, value: DatePickerValue | undefined, callback): void => {
      const dateEpoch = convertDatePickerValueToEpoch(value);

      if (!dateEpoch) {
        // the other validator check that a value exist
        return callback();
      }

      if (dateEpoch > Date.now()) {
        return callback('Can’t use a future date');
      }

      return callback();
    },
    message: 'Can’t use a future date',
  };
}

interface Props extends FormInputProps<DatePickerValue, false> {
  appearance: InputBoxAppearance;
  id: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  dataTestId?: string;
  dateFormat?: DatePickerFormat;
  bottomYearLimit?: number;
  translatedMonths?: string[];
}

const LOCALE = 'en-GB';
const DatePicker: ForwardingFC<HTMLDivElement, Props> = React.forwardRef(
  (
    {
      appearance,
      id,
      value,
      accessibilityLabel,
      placeholderStyle,
      heightType,
      onChange,
      className,
      dataTestId,
      dateFormat = DatePickerFormat.MMDDYY,
      bottomYearLimit = 2,
      translatedMonths,
    },
    ref,
  ) => {
    const currentMonth = value?.month;
    const currentDay = value?.day;
    const currentYear = value?.year;

    const { t } = useTranslation();
    const nowDate = new Date();

    const monthsNumberAndName = useMemo<[number, string][]>(() => {
      const onlyMonthDateFormatter = new Intl.DateTimeFormat(LOCALE, { month: 'long' });

      if (translatedMonths && translatedMonths.length == 12) {
        return createNTimes(12, (monthNumber) => {
          return [monthNumber, t(translatedMonths[monthNumber])];
        });
      }

      return createNTimes(12, (monthNumber) => {
        // We just want the name of the month, so the day of the month does not matter
        const aDateInTheRequestedMonth = new Date(0, monthNumber);
        return [monthNumber, onlyMonthDateFormatter.format(aDateInTheRequestedMonth)];
      });
    }, [translatedMonths, t]);

    const onMonthChange = (newMonthString: string): void => {
      const newMonth = parseInt(newMonthString, 10);
      const daysInNewMonth = daysInMonth(newMonth, value?.year);
      onChange?.({
        ...value,
        day: value?.day ? Math.min(daysInNewMonth, value?.day) : undefined,
        month: newMonth,
      });
    };

    const onDayChange = (newDayString: string): void => {
      onChange?.({
        ...value,
        day: parseInt(newDayString, 10),
      });
    };

    const onYearChange = (newYearString: string): void => {
      const newYear = parseInt(newYearString, 10);
      const daysInMonthOfNewYear = daysInMonth(value?.month, newYear);

      onChange?.({
        ...value,
        day: value?.day ? Math.min(daysInMonthOfNewYear, value?.day) : undefined,
        year: parseInt(newYearString, 10),
      });
    };

    const month = (
      <InputBox appearance={appearance}>
        <MonthDropdown
          accessibilityLabel={t('general.accessibility.month')}
          name={`${id}-drp-month`}
          dataTestId={`${dataTestId}-drp-month`}
          placeholder='Month'
          value={currentMonth?.toString()}
          onChange={onMonthChange}
          placeholderStyle={placeholderStyle}
          heightType={heightType}
        >
          {monthsNumberAndName.map(([monthNumber, monthName]) => {
            return (
              <StyledDropdownItem key={monthNumber} value={monthNumber.toString()} textWhenSelected={monthName}>
                <div>{monthName}</div>
              </StyledDropdownItem>
            );
          })}
        </MonthDropdown>
      </InputBox>
    );

    const day = (
      <InputBox appearance={appearance}>
        <DayDropdown
          accessibilityLabel={t('general.accessibility.day')}
          name={`${id}-drp-day-of-month`}
          dataTestId={`${dataTestId}-drp-day-of-month`}
          placeholder='Day'
          value={currentDay?.toString()}
          onChange={onDayChange}
          placeholderStyle={placeholderStyle}
          heightType={heightType}
        >
          {createNTimes(daysInMonth(currentMonth, currentYear), (oneDayBeforeInMonth) => {
            const dayInMonth = oneDayBeforeInMonth + 1;
            return (
              <StyledDropdownItem key={dayInMonth} value={dayInMonth.toString()} textWhenSelected={dayInMonth.toString()}>
                <div>{dayInMonth}</div>
              </StyledDropdownItem>
            );
          })}
        </DayDropdown>
      </InputBox>
    );

    const year = (
      <InputBox appearance={appearance}>
        <YearDropdown
          accessibilityLabel={t('general.accessibility.year')}
          name={`${id}-drp-year`}
          dataTestId={`${dataTestId}-drp-year`}
          placeholder='Year'
          value={currentYear?.toString()}
          onChange={onYearChange}
          placeholderStyle={placeholderStyle}
          heightType={heightType}
        >
          {createNTimes(bottomYearLimit, (distanceFromCurrentYear) => {
            const year = nowDate.getFullYear() - distanceFromCurrentYear;
            return (
              <StyledDropdownItem key={year} value={year.toString()} textWhenSelected={year.toString()}>
                <div>{year}</div>
              </StyledDropdownItem>
            );
          })}
        </YearDropdown>
      </InputBox>
    );

    const getDropdownsByFormatOrder = (): ReactElement => {
      switch (dateFormat) {
        case DatePickerFormat.YYMMDD:
          return (
            <>
              {year}
              {month}
              {day}
            </>
          );
        case DatePickerFormat.DDMMYY:
          return (
            <>
              {day}
              {month}
              {year}
            </>
          );
        case DatePickerFormat.MMDDYY:
        default:
          return (
            <>
              {month}
              {day}
              {year}
            </>
          );
      }
    };

    return (
      <InputsLine className={className} id={id} data-testid={dataTestId} aria-label={accessibilityLabel} ref={ref}>
        {getDropdownsByFormatOrder()}
      </InputsLine>
    );
  },
);

export default DatePicker;

const InputsLine = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 8px;
`;

const MonthDropdown = styled(NakedDropdown)`
  min-width: 145px;
`;

const DayDropdown = styled(NakedDropdown)`
  min-width: 80px;
`;

const YearDropdown = styled(NakedDropdown)`
  min-width: 115px;
`;

const StyledDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: rgba(61, 68, 90, 0.7);
`;
