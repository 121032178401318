import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CURRENCIES_KEYS } from '@app/domain/currencies';
import { AccountType } from '@app/domain/accountsDetailsHelpers';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import {
  getDefaultBankCodeRules,
  SupplierRegistrationField,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { COUNTRY_CODES } from '@app/domain/countries';

export enum ChileLegalIdTypesForCompany {
  RUT = 'RUT',
}

const AccountTypeInfo = {
  [AccountType.CheckingCL]: 'Checking',
  [AccountType.SavingCL]: 'Saving',
  [AccountType.CuentaVista]: 'Cuenta en linea\\cuenta vista',
};

function showAccountType(form: WrappedFormUtils<BaseFormFields>): boolean {
  return form.getFieldValue('currency') === CURRENCIES_KEYS.CLP;
}

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-bank-code',
    inputType: 'text',
    decoratorRules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBankCode',
      },
      ...getDefaultBankCodeRules(COUNTRY_CODES.Chile),
    ],
  };
}

function getAdditionalSwiftRules(): ConditionalValidationRule[] {
  return [
    {
      required: true,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const CL: SupplierRegistrationCountryFields = {
  leis: { types: ChileLegalIdTypesForCompany, defaultValue: ChileLegalIdTypesForCompany.RUT },
  accountType: AccountTypeInfo,
  showAccountType: showAccountType,
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
  bankCode: getBankCodeFields,
};
