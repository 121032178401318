import request, { HttpMethod } from '@app/libs/request';
import config from '@app/config';
import { KnoxerServerResponse } from '@app/domain/userManagement/knoxers';

export async function getAllKnoxers(): Promise<KnoxerServerResponse[]> {
  return request<KnoxerServerResponse[]>(config.serverUrls.backee, `/api/knoxers`, {
    method: HttpMethod.get,
    errorsHandler: {
      default: {
        message: 'Cannot get knoxers.',
      },
    },
  });
}
