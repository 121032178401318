import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { isDefined } from '@app/utils/utils';
import StoreConfirmationAlerts from '@mortee/routes/validatedPayeesManagement/StoreConfirmationAlerts';
import {
  SupplierValidationAccountVerificationRecord,
  SupplierValidationPayeeVerificationRecord,
  SupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import ValidatedPayeeAccountManagementCard, {
  ValidatedPayeeAccountDisplay,
} from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeAccountManagementCard';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { H4StartTransparentBlack600 } from '@app/components/Text';
import { HeightCoordinator } from '@app/hooks/useHeightCoordinator';
import HeightCoordinatedDiv from '@app/components/HeightCoordinatedDiv';
import VerificationEntityChangesBullets from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationEntityChangesBullets';

interface Props {
  payee: SupplierValidationPayeeVerificationRecord | null;
  account: SupplierValidationAccountVerificationRecord;
  accountNumber: number;
  renderedWarnings: ReactNode[];
  supplierValidationRecord: SupplierValidationRecord;
  heightCoordinator: HeightCoordinator;
  className?: string;
  visibilityGap: boolean;
}

interface EntityChangeMessage {
  content: ReactNode;
  type: 'error' | 'notice' | 'regular';
}

const VerificationAccountChanges: FC<Props> = ({
  payee,
  account,
  accountNumber,
  renderedWarnings,
  supplierValidationRecord,
  heightCoordinator,
  className,
  visibilityGap,
}) => {
  const { navigationStore } = useInfraStores<MorteeMode>();

  const organizationName = supplierValidationRecord.organization?.name || '';

  function calculateAccountDataForDisplay(
    accountData: SupplierValidationAccountVerificationRecord,
  ): ValidatedPayeeAccountDisplay {
    return {
      bankCountryCode: accountData.countryCode,
      bankName: accountData.bankName,
      furtherCredit: accountData.furtherCredit,
      validationTimestamp: accountData.validationTimestamp,
      status: accountData.type ?? null,
      supplierValidationRegistrationNumber: null,
      accountDetails: {
        bankCode: accountData.bankCode ?? null,
        branchCode: accountData.branchCode ?? null,
        iban: accountData.iban ?? null,
        swiftCode: accountData.swift ?? null,
        accountNumber: accountData.accountNumber ?? null,
        countryCode: accountData.countryCode ?? null,
        localFormat: accountData.localFormat ?? null,
      },
      presentationAccountNumber: accountData.accountNumber ?? null,
    };
  }

  function calculateAccountChangeMessages(
    payeeData: SupplierValidationPayeeVerificationRecord | null,
    accountData: SupplierValidationAccountVerificationRecord,
  ): EntityChangeMessage[] {
    const visibilityGapEntityChangeMessage: EntityChangeMessage = {
      type: 'notice',
      content: 'Accounts have different visibility levels',
    };

    if (!payeeData) {
      const messages: EntityChangeMessage[] = [
        {
          type: 'error',
          content: `Fill payee data in order to see how the account will be affected`,
        },
      ];

      return visibilityGap ? [...messages, visibilityGapEntityChangeMessage] : messages;
    }

    const { isPrivate: isPrivatePayee, alreadyExistingValidatedPayee } = payeeData;
    const { isPrivate: isPrivateAccount } = accountData;

    if (!alreadyExistingValidatedPayee) {
      if (isPrivatePayee || isPrivateAccount) {
        const messages: EntityChangeMessage[] = [
          {
            type: 'regular',
            content: (
              <span>
                Visibility: <ImportantText>Organizational visibility</ImportantText> only for "{organizationName}"
              </span>
            ),
          },
        ];

        return visibilityGap ? [...messages, visibilityGapEntityChangeMessage] : messages;
      }

      const messages: EntityChangeMessage[] = [
        {
          type: 'regular',
          content: `Visibility: All users visibility`,
        },
      ];

      return visibilityGap ? [...messages, visibilityGapEntityChangeMessage] : messages;
    }

    if (isPrivatePayee || isPrivateAccount) {
      const messages: EntityChangeMessage[] = [
        {
          type: 'regular',
          content: (
            <span>
              Visibility: <ImportantText>Organizational visibility</ImportantText> only for "{organizationName}"
            </span>
          ),
        },
        {
          type: 'regular',
          content: `The payee "${alreadyExistingValidatedPayee.displayName}" will not be affected by it`,
        },
      ];

      return visibilityGap ? [...messages, visibilityGapEntityChangeMessage] : messages;
    }

    const messages: EntityChangeMessage[] = [
      {
        type: 'regular',
        content: `Visibility: All users visibility`,
      },
      {
        type: 'notice',
        content: (
          <span>
            Make sure the payee{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href={navigationStore.generateManageValidatedPayeePageHref(alreadyExistingValidatedPayee.uniformId)}
            >
              {alreadyExistingValidatedPayee.displayName}
            </a>{' '}
            is not private or public
          </span>
        ),
      },
    ];

    return visibilityGap ? [...messages, visibilityGapEntityChangeMessage] : messages;
  }

  return (
    <AccountEntityCard key={account.staticId} className={className}>
      <HeightCoordinatedDiv heightCoordinator={heightCoordinator}>
        <EntityCardHeader>{`New Account NO. ${accountNumber}`}</EntityCardHeader>
        <VerificationEntityChangesBullets entityChangeMessages={calculateAccountChangeMessages(payee, account)} />
        <AlertsBox
          title={`Warnings:`}
          alerts={renderedWarnings.filter(isDefined)}
          data-testid='verification-account-confirmation-modal-alert-box'
        />
      </HeightCoordinatedDiv>
      <PaddedValidatedPayeeAccountManagementCard
        validatedPayeeAccount={calculateAccountDataForDisplay(account)}
        dataTestId='verification-account-card-confirmation-modal'
      />
    </AccountEntityCard>
  );
};

export default VerificationAccountChanges;

const ImportantText = styled.span`
  font-weight: 800;
  color: var(--transparent-black-800);
`;

const AccountEntityCard = styled.div`
  // Horizontal padding will be applied by the content
  padding: 16px 0;

  border-radius: 8px;
  background: white;
`;

const EntityCardHeader = styled(H4StartTransparentBlack600.div)`
  padding: var(--card-padding);
`;

const AlertsBox = styled(StoreConfirmationAlerts)`
  background: var(--warning-color);
  padding: 16px 16px 16px 0;
  margin: 16px;
`;

const PaddedValidatedPayeeAccountManagementCard = styled(ValidatedPayeeAccountManagementCard)`
  padding: var(--card-padding);
  margin: var(--card-padding);
`;
