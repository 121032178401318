import React, { CSSProperties, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import { ForwardingFC } from '@app/domain/technicals/components';
import { FormInputProps } from '@app/utils/form/form';
import FormInputsContext from './FormInputsContext';
import FormRadio, { RadioProps } from '@app/components/inputs/FormRadio';

export interface RadioOption {
  value: string;
  label: ReactNode;
  disabled?: boolean;
  dataTestId?: string;
}

interface RadioGroupProps extends FormInputProps<string, false> {
  disabled?: boolean;
  id: string;
  dataTestId: string;
  options: RadioOption[];
  autoFocus?: boolean;
  textSize?: 'medium' | 'big';
  direction?: CSSProperties['flexDirection'];
  color?: RadioProps['color'];
  className?: string;
}

const FormRadioGroup: ForwardingFC<HTMLDivElement, RadioGroupProps> = React.forwardRef((props, ref) => {
  const { state } = useContext(FormInputsContext);
  const {
    options,
    id,
    dataTestId,
    value,
    autoFocus,
    disabled: disabledProp,
    textSize = 'big',
    direction = 'row',
    color = 'primary',
    accessibilityLabel,
    className,
  } = props;

  const disabled = disabledProp || state === 'disabled';

  const handleChangeValue = (newValue: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = props;

    onChange?.(newValue.target.value);
  };

  return (
    <StyledRadioGroup
      id={id}
      data-testid={dataTestId}
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleChangeValue(event)}
      direction={direction}
      ref={ref}
      className={className}
      role='radiogroup'
      aria-label={accessibilityLabel}
    >
      {options.map((o, index) => {
        const isThisRadioChecked = o.value === value;
        return (
          <FormRadio
            key={o.value}
            id={`rad-${o.value}`}
            dataTestId={`rad-${o.dataTestId ?? o.value}`}
            value={o.value}
            label={o.label}
            disabled={disabled || o.disabled}
            color={color}
            textSize={textSize}
            autoFocus={autoFocus && index === 0}
            isChecked={isThisRadioChecked}
          />
        );
      })}
    </StyledRadioGroup>
  );
});

export default FormRadioGroup;

const StyledRadioGroup = styled.div<{ direction: CSSProperties['flexDirection'] }>`
  .MuiRadio-colorPrimary-10.MuiRadio-checked-8 {
    color: #40a9ff;
  }

  display: flex;
  flex-direction: ${({ direction }): CSSProperties['flexDirection'] => direction};

  & > *:not(:first-child) {
    margin-${({ direction }): string => (direction === 'row' ? 'left' : 'top')}: 6px;
  }
`;
