import NavigationStore from '@app/stores/NavigationStore';
import PermissionsStore from '@app/stores/PermissionsStore';
import UserStore from '@app/stores/UserStore';
import LocaleStore from '@app/stores/LocaleStore';
import InfraStores from '@app/stores/InfraStores';
import AuthenticationStore from './AuthenticationStore';
import HealthCheckStore from '@app/stores/HealthCheckStore';
import ThemeStore from '@app/stores/ThemeStore';
import LanguageStore from '@app/stores/LanguageStore';

export default abstract class AuthenticatedInfraStores<
  TNavigationStore extends NavigationStore = NavigationStore,
  TPermissionsStore extends PermissionsStore = PermissionsStore,
  TThemeStore extends ThemeStore = ThemeStore
> extends InfraStores<TNavigationStore, TPermissionsStore, TThemeStore> {
  authenticationStore: AuthenticationStore;

  constructor(
    userStore: UserStore,
    languageStore: LanguageStore,
    localeStore: LocaleStore,
    navigationStore: TNavigationStore,
    permissionsStore: TPermissionsStore,
    themeStore: TThemeStore,
    healthCheckStore: HealthCheckStore,
  ) {
    super(userStore, languageStore, localeStore, navigationStore, permissionsStore, themeStore, healthCheckStore);
    this.authenticationStore = new AuthenticationStore(userStore);
  }

  async init(): Promise<void> {
    await super.init();
    await this.authenticationStore.initAfterAllStoresInitialized();
  }

  async handleSessionExpired(): Promise<void> {
    return this.authenticationStore.handleSessionExpired();
  }
}

export function isAuthenticatedInfraStores(infraStores: InfraStores): infraStores is AuthenticatedInfraStores {
  return 'authenticationStore' in infraStores;
}
