import React, { FC, ReactElement, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { PayeeValidationLevel, PrivatePayeeWithDeterministicValidations } from '@mortee/domain/masterDataGuard';
import NationalityFlag from '@app/components/NationalityFlag';
import { pluralize } from '@app/utils/stringUtils';
import ReactPlaceholder from 'react-placeholder';
import { css } from '@emotion/css';
import TextHighlighter from '@app/components/TextHighlighter';
import SVG from '@app/components/SVG';
import SomeInvalidIcon from '@app/images/indicator-warning.svg';
import AllInvalidIcon from '@app/images/indicator-error.svg';
import { getCountryName } from '@app/domain/countries';
import { distinctValues } from '@app/utils/arrayUtils';
import TooltipWhenEllipsis from '@app/components/TooltipWhenEllipsis';
import LivePrivatePayeesFiltersContext from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import OldChip from '@app/components/OldChip';
import { isDefined } from '@app/utils/utils';
import { SupplierNumberType } from '@mortee/domain/privatePayee';
import { getFormattedLEI, removeStrongIds } from '@app/domain/legalEntityIdentifier';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import { compare } from '@app/utils/comparatorUtils';

interface PrivatePayeeLiveListItemHeaderProps {
  payeeWithValidation: PrivatePayeeWithDeterministicValidations;
  loading?: false;
  className?: string;
}

interface PrivatePayeeLiveListItemHeaderLoadingProps {
  payeeWithValidation?: undefined;
  loading: true;
  className?: string;
}

const PrivatePayeeLiveListItemHeader: FC<PrivatePayeeLiveListItemHeaderProps | PrivatePayeeLiveListItemHeaderLoadingProps> = (
  props,
) => {
  const {
    filters: { searchQuery },
  } = useContext(LivePrivatePayeesFiltersContext);
  const { className } = props;

  const renderListOfNames = (allNamesOrdered: string[]): ReactNode => {
    return (
      <>{allNamesOrdered.length === 1 ? allNamesOrdered[0] : allNamesOrdered.map((name) => <div key={name}>• {name}</div>)}</>
    );
  };

  const renderNames = (): ReactElement => {
    if (props.loading) {
      return (
        <PayeeNames>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ marginTop: 3, marginBottom: 3, width: 190 }} />
        </PayeeNames>
      );
    }

    const { payeeWithValidation } = props;
    const allNamesOrdered = payeeWithValidation.privatePayee.data.names.slice().sort(compare.stringsCaseInsensitive());
    const [firstName, ...restOfNames] = allNamesOrdered;

    return (
      <PayeeNames>
        <PayeeMainName>
          {firstName ? (
            <TooltipWhenEllipsis title={firstName}>
              <span>
                <TextHighlighter searchText={searchQuery} highlightClass={highlightedTextClass}>
                  {firstName}
                </TextHighlighter>
              </span>
            </TooltipWhenEllipsis>
          ) : (
            'Unknown'
          )}
        </PayeeMainName>
        {!!restOfNames.length && (
          <PayeeSecondaryText>
            <TooltipWhenEllipsis title={renderListOfNames(restOfNames)}>
              {restOfNames.map((otherName, index) => (
                <React.Fragment key={index}>
                  &nbsp;
                  <SmallMiscInfoBullet />
                  &nbsp;
                  <TextHighlighter searchText={searchQuery} highlightClass={highlightedTextClass}>
                    {otherName}
                  </TextHighlighter>
                </React.Fragment>
              ))}
            </TooltipWhenEllipsis>
          </PayeeSecondaryText>
        )}
      </PayeeNames>
    );
  };

  const renderMetadata = (): ReactElement => {
    if (props.loading) {
      return (
        <PayeeMetadata>
          <ReactPlaceholder type='round' ready={false} children='' style={{ width: 20, height: 20 }} />
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ marginTop: 0, width: 60 }} />
        </PayeeMetadata>
      );
    }

    const {
      payeeWithValidation: {
        privatePayee: { data, externalReferenceId, strongLegalIdentifies },
      },
    } = props;
    const { legalIdentifiers, countryCode, supplierNumbers, companyCodes } = data;

    const strongIds = strongLegalIdentifies ? Object.values(strongLegalIdentifies).filter(isDefined).flat() : [];
    const regularPayeeLegalIdentifiers = removeStrongIds(legalIdentifiers, strongIds);

    return (
      <PayeeMetadata>
        <Tooltip placement='left' title={getCountryName(countryCode, 'Unknown Country')}>
          <FlagContainer>
            <NationalityFlag countryCode={countryCode} size={18} unknownFlagStyle='wavyFlag' />
          </FlagContainer>
        </Tooltip>
        <HideWhenOpen>
          <Tooltip placement='right' title={`Internal ID: ${externalReferenceId}`}>
            <span>
              <TextHighlighter searchText={searchQuery} highlightClass={highlightedTextClass}>
                {externalReferenceId}
              </TextHighlighter>
            </span>
          </Tooltip>
        </HideWhenOpen>
        <ShowWhenOpen>
          Internal ID:{' '}
          <TextHighlighter searchText={searchQuery} highlightClass={highlightedTextClass} fullMatchOnly>
            {externalReferenceId}
          </TextHighlighter>
          {distinctValues(regularPayeeLegalIdentifiers.map((lei) => getFormattedLEI(lei, true))).map((formattedLei) => (
            <React.Fragment key={formattedLei}>
              &nbsp;
              <SmallMiscInfoBullet />
              &nbsp;
              <PayeeOtherInfoText>{formattedLei}</PayeeOtherInfoText>
            </React.Fragment>
          ))}
        </ShowWhenOpen>
        {supplierNumbers?.map((supplierNumber) => (
          <React.Fragment key={supplierNumber}>
            <MiscInfoBullet />
            <span>
              {SupplierNumberType}:{' '}
              <TextHighlighter searchText={searchQuery} highlightClass={highlightedTextClass}>
                {supplierNumber}
              </TextHighlighter>
            </span>
          </React.Fragment>
        ))}
        {!!companyCodes.length && (
          <>
            <MiscInfoBullet />
            {companyCodes.map((companyCode) => (
              <OldChip key={companyCode}>{companyCode}</OldChip>
            ))}
          </>
        )}
      </PayeeMetadata>
    );
  };

  const renderIndicatorTooltipMessage = (payee: PrivatePayeeWithDeterministicValidations): ReactNode => {
    switch (payee.payeeValidationLevel) {
      case PayeeValidationLevel.valid: {
        return '';
      }
      case PayeeValidationLevel.allNotValidated: {
        return 'All of this vendor’s accounts are not validated';
      }
      case PayeeValidationLevel.someNotValidated: {
        const numberOfNotValidatedAccounts = payee.accountsValidation.filter(
          (x) => x.deterministicValidation.validationLevel === DeterministicValidationResultType.notValidated,
        ).length;

        const numberOfValidatedAccounts = payee.accountsValidation.length - numberOfNotValidatedAccounts;

        return `This vendor has ${numberOfValidatedAccounts} validated ${pluralize(
          'account',
          numberOfValidatedAccounts,
        )} and ${numberOfNotValidatedAccounts} not validated ${pluralize('account', numberOfNotValidatedAccounts)}.`;
      }
    }
  };

  const renderIndicator = (): ReactElement => {
    if (props.loading) {
      return <ReactPlaceholder type='round' ready={false} children='' style={{ width: 16, height: 16 }} />;
    }

    const areSomeNotValidated = props.payeeWithValidation.payeeValidationLevel === PayeeValidationLevel.someNotValidated;

    return (
      <>
        {props.payeeWithValidation.payeeValidationLevel !== PayeeValidationLevel.valid && (
          <Tooltip title={(): ReactNode => renderIndicatorTooltipMessage(props.payeeWithValidation)}>
            <StyledValidationLevelIndicatorIcon
              accessibilityLabel={
                areSomeNotValidated ? 'partially validated accounts vendor' : 'not validated accounts only vendor'
              }
              data-testid={`${areSomeNotValidated ? 'some-not-validated-icon' : 'all-not-validated-icon'}`}
              image={areSomeNotValidated ? SomeInvalidIcon : AllInvalidIcon}
            />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <LayoutContainer className={className}>
      <PayeeInfoContainer>
        {renderNames()}
        {renderMetadata()}
      </PayeeInfoContainer>
      <IndicatorContainer>{renderIndicator()}</IndicatorContainer>
    </LayoutContainer>
  );
};

export default PrivatePayeeLiveListItemHeader;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;

  line-height: normal;
`;

const PayeeInfoContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
`;

const IndicatorContainer = styled.div`
  margin-left: 10px;
`;
const StyledValidationLevelIndicatorIcon = styled(SVG)`
  width: 24px;
  height: 24px;
`;

const PayeeNames = styled.div`
  font-size: 15px;
  letter-spacing: 0.42px;
  color: var(--black-strong);

  // This prevents descender letters from being cut in FF
  line-height: 1.3;

  display: flex;
  align-items: baseline;
`;

const PayeeMainName = styled.span`
  flex: 0 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

const PayeeSecondaryText = styled.span`
  flex: 1 0 132px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;

  display: flex;
  flex-direction: row;
  align-items: baseline;

  font-size: 11px;
  letter-spacing: 0.31px;
  color: var(--black-weak);
`;

const PayeeOtherInfoText = styled.span`
  font-size: 11px;
  letter-spacing: 0.31px;
  color: var(--black-weak);
`;

const MiscInfoBullet = styled.span`
  display: inline-block;

  min-width: 4px;
  max-width: 4px;
  height: 4px;
  background-color: var(--black-weak);
  border-radius: 50%;
`;

const SmallMiscInfoBullet = styled(MiscInfoBullet)`
  margin-bottom: 2px;
`;

const PayeeMetadata = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  font-size: 13px;
  color: var(--black-weak);

  & > * {
    margin-right: 4px;
    margin-top: 4px;
  }
`;

const FlagContainer = styled.span`
  margin-top: 2px;
`;

const ShowWhenOpen = styled.div`
  display: none;

  .ant-collapse-item-active & {
    display: initial;
  }
`;

const HideWhenOpen = styled.div`
  .ant-collapse-item-active & {
    display: none;
  }
`;

const highlightedTextClass = css`
  font-weight: 900;
  color: var(--primary-500);
`;
