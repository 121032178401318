import React, { ButtonHTMLAttributes, ReactNode, useRef } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import classNames from 'classnames';
import Loader, { LoaderProps } from './Loader';
import { KeyAsciiValue } from '@app/domain/uiConsts';
import { ForwardingFC } from '@app/domain/technicals/components';
import mergeRefs from 'react-merge-refs';
import getCardCornersCssClass, { CornerAppearance } from './corners/CornerCssGenerator';
import { SerializedStyles } from '@emotion/react';
import { ColorScheme, ColorSchemeObject, getColorScheme } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';

type ButtonSize = 'regular' | 'small';

// id and onClick exist to enforce their existence
export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'> {
  id: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  colorScheme?: ColorScheme;
  size?: ButtonSize;
  appearance?: 'full' | 'outline' | 'text';
  cornerType?: CornerAppearance;
  loading?: boolean;
  disabled?: boolean;
  disableTabIndex?: boolean;
  shadowType?: 'regular' | 'none';
  type?: 'button' | 'submit';
  onDisabledClick?: () => void;
  loaderProps?: Omit<LoaderProps, 'spinning'>;
  dataTestId?: string;
  disableAnimations?: boolean;
}

export interface StyledButtonProps {
  size: ButtonSize;
  colorScheme: ColorSchemeObject;
  cornerType: CornerAppearance;
  shadowType: 'regular' | 'none';
  disableAnimations?: false;
}

const Button: ForwardingFC<HTMLButtonElement, ButtonProps> = React.forwardRef(
  ({ colorScheme: propColorScheme, ...rest }, outerRef) => {
    const colorScheme = useColorScheme(propColorScheme);

    return <ModalButton colorScheme={colorScheme} ref={outerRef} {...rest} />;
  },
);

export const ModalButton: ForwardingFC<HTMLButtonElement, ButtonProps> = React.forwardRef(
  (
    {
      id,
      appearance = 'full',
      cornerType = 'all',
      colorScheme: propColorScheme,
      disabled,
      className,
      dataTestId = id,
      onClick,
      onDisabledClick,
      shadowType = 'regular',
      size = 'regular',
      type = 'button',
      children,
      loading,
      loaderProps,
      onKeyPress,
      disableTabIndex,
      disableAnimations,
      ...rest
    },
    outerRef,
  ) => {
    const innerRef = useRef<HTMLButtonElement>();

    const colorScheme = getColorScheme(propColorScheme);

    const renderButtonChildren = (): ReactNode => {
      if (loading) {
        const defaultLoaderProps: Omit<LoaderProps, 'spinning'> = {
          customColor: appearance === 'full' ? colorScheme.fullTextColor : colorScheme.main,
        };

        return (
          <>
            <ContentContainer isHidden>{children}</ContentContainer>
            <Loader small transparent spinning {...defaultLoaderProps} {...loaderProps} />
          </>
        );
      }

      return children;
    };

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (loading) {
        return;
      }

      if (disabled) {
        onDisabledClick?.();
        return;
      }

      onClick(e);
    };

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
      if (e.which === KeyAsciiValue.Enter) {
        e.preventDefault();
        innerRef.current?.click();
        return;
      }

      onKeyPress?.(e);
    };

    const getButtonComponent = (appearance: ButtonProps['appearance']): StyledComponent<StyledButtonProps, any, any> => {
      switch (appearance) {
        case 'text': {
          return StyledTextButton;
        }
        case 'outline': {
          return StyledOutlineButton;
        }
        case 'full':
        default: {
          return StyledFullButton;
        }
      }
    };

    const ButtonComponent = getButtonComponent(appearance);

    return (
      <ButtonComponent
        id={id}
        ref={mergeRefs([outerRef, innerRef])}
        tabIndex={disabled || disableTabIndex ? undefined : 0}
        cornerType={cornerType}
        colorScheme={colorScheme}
        size={size}
        disableAnimations={disableAnimations}
        {...rest}
        className={classNames(className, {
          disabled,
          loading,
        })}
        data-testid={dataTestId}
        shadowType={shadowType}
        type={type}
        aria-disabled={disabled}
        onClick={onButtonClick}
        onKeyPress={handleOnKeyPress}
      >
        {renderButtonChildren()}
      </ButtonComponent>
    );
  },
);

export default Button;

const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  background: none;
  border: none;
  user-select: none;
  cursor: pointer;
  font-family: var(--text-font-family);
  font-weight: bold;
  font-size: 14.2px;
  letter-spacing: 0.8px;
  line-height: normal;
  -webkit-appearance: none;
  text-align: center;
  fill: currentColor;

  --button-block-padding: 0.8em;
  --button-line-padding: 1.2em;
  padding: ${(p): string =>
    p.cornerType === 'circle' ? 'var(--button-block-padding)' : 'var(--button-block-padding) var(--button-line-padding)'};

  ${({ size }): string =>
    size === 'small'
      ? `
    font-size: 12px;
    letter-spacing: 0.68px;
    --button-block-padding: 0.7em;
    --button-line-padding: 0.7em;
    `
      : ''};

  ${({ disableAnimations }): string =>
    disableAnimations
      ? ''
      : '  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;'};

  &:focus-visible {
    outline: solid ${(p): string => p.colorScheme.main} 3px;
    outline-offset: 2px;
  }
`;

const StyledFullButton = styled(StyledButton)<StyledButtonProps>`
  ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, p.colorScheme.main)}
  color: ${(p): string => p.colorScheme.fullTextColor};
  box-shadow: ${(p): string => (p.shadowType === 'none' ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.2)')};

  &.disabled {
    ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, '#000000', undefined, 0.12)}
    color: rgba(0, 0, 0, 0.38);
    box-shadow: none;
  }

  &:hover:not(.disabled),
  &:focus-visible:not(.disabled) {
    ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, p.colorScheme.fullHover)}
    box-shadow: ${(p): string => (p.shadowType === 'none' ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.2)')};
  }

  &:active:not(.disabled) {
    ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, p.colorScheme.fullActive)}
    box-shadow: ${(p): string => (p.shadowType === 'none' ? 'none' : '0 1px 2px rgba(0, 0, 0, 0.2)')};
  }
`;

const StyledTextButton = styled(StyledButton)<StyledButtonProps>`
  border: 0 solid transparent;

  background-color: transparent;
  ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, 'transparent')}
  color: ${(p): string => p.colorScheme.main};

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &:hover:not(.disabled),
  &:focus-visible:not(.disabled) {
    ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, p.colorScheme.main, undefined, 0.08)}
  }

  &:active:not(.disabled) {
    ${(p): SerializedStyles => getCardCornersCssClass(p.cornerType, p.colorScheme.main, undefined, 0.16)}
  }
`;

const StyledOutlineButton = styled(StyledButton)<StyledButtonProps>`
  ${(p): SerializedStyles => getCardCornersCssClass('all', p.colorScheme.main, p.colorScheme.main, 0, 0.1)}
  color: ${(p): string => p.colorScheme.main};

  &.disabled {
    ${(p): SerializedStyles => getCardCornersCssClass('all', '#ffffff', '#000000', 0.1)}
    color: rgba(0, 0, 0, 0.38);
  }

  &:hover:not(.disabled),
  &:focus-visible:not(.disabled) {
    ${(p): SerializedStyles => getCardCornersCssClass('all', p.colorScheme.main, p.colorScheme.main, 0.06, 0.3)}
  }

  &:active:not(.disabled) {
    ${(p): SerializedStyles => getCardCornersCssClass('all', p.colorScheme.main, p.colorScheme.main, 0.12, 0.4)}
  }
`;

const ContentContainer = styled.div<{ isHidden: boolean }>`
  ${(p): string => (p.isHidden ? 'visibility: hidden;' : '')}
`;

export const ButtonRegularCenter = styled(Button)`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

export const ButtonSmallCenter = styled(Button)`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  padding-top: 7px;
  padding-bottom: 7px;
`;
