import React, { FC, useState } from 'react';
import FormlessItemBox from '@app/components/inputs/FormlessItemBox';
import { KeyName } from '@app/domain/uiConsts';
import styled from '@emotion/styled';
import NakedFormSearchInput from '@app/components/NakedFormSearchInput';

interface Props {
  isSearchActive: boolean;
  value: string | undefined;
  onValueChange(value: string): void;
  onSearch(value: string): void;
  onSearchEnd(): void;
  className?: string;
}

const MIN_SEARCH_QUERY_LENGTH = 3;

const SupplierValidationRecordTableSearch: FC<Props> = (props) => {
  const { isSearchActive, value, onValueChange, onSearch, onSearchEnd, className } = props;

  const [failedToSubmitSearch, setFailedToSubmitSearch] = useState<boolean>(false);

  function handleValueChanged(newValue: string | undefined): void {
    onValueChange(newValue ?? '');

    if (isSearchActive && !newValue) {
      handleSearchEnd();
    }
  }

  const search = (): void => {
    if (!value) {
      handleSearchEnd();
      return;
    }

    handleSearch(value);
  };

  function handleOnKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key !== KeyName.Enter) {
      return;
    }

    e.preventDefault();
    search();
  }

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === KeyName.Escape) {
      e.preventDefault();
      onValueChange('');
      isSearchActive && handleSearchEnd();
      return;
    }
  }

  function isSearchQueryValid(searchQuery: string | undefined): boolean {
    return (searchQuery?.length ?? 0) >= MIN_SEARCH_QUERY_LENGTH;
  }

  function handleSearch(searchQuery: string): void {
    if (!isSearchQueryValid(searchQuery)) {
      setFailedToSubmitSearch(true);
      return;
    }

    setFailedToSubmitSearch(false);
    onSearch(searchQuery);
  }

  function handleSearchEnd(): void {
    setFailedToSubmitSearch(false);
    onSearchEnd();
  }

  const showError = failedToSubmitSearch && !isSearchQueryValid(value);

  function calcUnderText(): string {
    if (showError) {
      return `Search should be at least ${MIN_SEARCH_QUERY_LENGTH} characters`;
    }

    return `Press 'Enter' to search`;
  }

  return (
    <StyledInputBox
      hasValue={!!value}
      underText={calcUnderText()}
      underTextType={showError ? 'error' : 'success'}
      className={className}
    >
      <NakedFormSearchInput
        name='inpt-validation-table-search'
        type='text'
        placeholder='Search'
        placeholderStyle='onlyWhenEmpty'
        heightType='ultra-thin'
        clearable
        autoFocus
        onClear={handleSearchEnd}
        onKeyPress={handleOnKeyPress}
        onKeyDown={handleOnKeyDown}
        value={value}
        onChange={handleValueChanged}
        isSearchActive={isSearchActive}
        onButtonClick={search}
      />
    </StyledInputBox>
  );
};

export default SupplierValidationRecordTableSearch;

const StyledInputBox = styled(FormlessItemBox)`
  & .ant-form-explain,
  .ant-form-extra {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &:focus-within .ant-form-explain,
  .ant-form-extra {
    opacity: 1;
  }
`;
