import { Ref, useCallback } from 'react';
import { setRef } from '@app/utils/refUtils';

function useMergedRef<T>(...refs: Ref<T>[]): Ref<T> {
  return useCallback(
    (element: T) => refs.forEach((ref) => setRef(ref, element)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs,
  );
}

export default useMergedRef;
