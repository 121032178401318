export interface AlertServerResponse {
  creationTimestamp: number;
  createdByUserName: string;
  alertText: string;
  alertStatus: AlertStatus;
  alertId: string;
  resolveTimestamp: number | undefined;
  resolvedByUserName: string | undefined;
}

export interface Alert {
  creationTimestamp: number;
  createdByUserName: string;
  alertText: string;
  alertStatus: AlertStatus;
  alertId: string;
  resolveTimestamp: number | null;
  resolvedByUserName: string | null;
}

export enum AlertStatus {
  active = 'Active',
  resolved = 'Resolved',
}

export function transformValidationAlert(serverResponse: AlertServerResponse): Alert {
  return {
    ...serverResponse,
    resolveTimestamp: serverResponse.resolveTimestamp ?? null,
    resolvedByUserName: serverResponse.resolvedByUserName ?? null,
  };
}
