import styled from '@emotion/styled';
import Button from '@app/components/Button';

export const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  letter-spacing: 0.49px;
  text-align: center;
  color: var(--primary-200);
`;

export const SubTitle = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: rgba(20, 35, 97, 0.7);
`;

export const SubTitleText = styled.span`
  font-size: 15px;
  letter-spacing: 0.1px;
  color: rgba(20, 35, 97, 0.7);
`;

export const BoldSubTitleText = styled(SubTitleText)`
  font-weight: bold;
  color: rgba(20, 35, 97, 0.87);
`;

export const ActionsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PrimaryButton = styled(Button)`
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 6px;
`;

export const FooterTitle = styled.div`
  font-size: 17px;
  letter-spacing: 0.15px;
  color: var(--primary-200);
`;

export const FooterSubTitle = styled(SubTitle)`
  margin-bottom: 0;
`;
