import WarningSVG from '@supplierRegistration/images/icon-notification-warning-blue.svg';
import React, { FC, ReactElement } from 'react';
import { LocaleAwareSVG } from '@app/components/LocaleAwareComponents';
import { useTranslation } from 'react-i18next';

interface ArrowBulletProps {
  className?: string;
}

const WarningBullet: FC<ArrowBulletProps> = ({ className }): ReactElement => {
  const { t } = useTranslation();
  return <LocaleAwareSVG accessibilityLabel={t('general.accessibility.bulletPoint')} image={WarningSVG} className={className} />;
};

export default WarningBullet;
