import React, { ComponentType, ReactElement } from 'react';
import { Tooltip } from 'antd';
import { css } from '@emotion/css';
import { AbstractTooltipProps } from 'antd/lib/tooltip';

export interface WithTooltipProps {
  children?: string;
}

const tooltipClassName = css`
  .ant-tooltip-inner {
    background-color: white;
    color: var(--black-weak);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
  }
  .ant-tooltip-arrow {
    visibility: hidden;
  }
`;

const defaultTooltipProps: AbstractTooltipProps = { placement: 'bottom', overlayClassName: tooltipClassName };

export default function withTooltip<P extends WithTooltipProps>(
  InnerComponent: ComponentType<P>,
  tooltipProps: AbstractTooltipProps = {},
): React.ComponentType<Omit<P, keyof WithTooltipProps>> {
  return (props: P): ReactElement => {
    const composedTooltipProps = {
      ...defaultTooltipProps,
      ...tooltipProps,
    };

    return (
      <Tooltip title={props.children} {...composedTooltipProps}>
        <InnerComponent {...props} />
      </Tooltip>
    );
  };
}
