import { DependencyList, ReactNode, useEffect, useRef } from 'react';
import { ModalFunc, ModalFuncProps } from 'antd/lib/modal/Modal';
import useIsMounted from '@app/hooks/useIsMounted';
import { getPropsOfCustomModal } from '@app/components/Modal';
import { Modal } from 'antd';

export default function useControlledModal(
  visible: boolean,
  content: (onDone: (result: boolean) => void) => ReactNode,
  onModalClosed: (result: boolean) => void,
  options: ModalFuncProps = {},
  dependencies: DependencyList,
): void {
  const modalRef = useRef<ReturnType<ModalFunc>>();
  const isMountedRef = useIsMounted();

  useEffect(() => {
    if (!visible) {
      modalRef.current?.destroy();
      modalRef.current = undefined;
      return;
    }

    const mergedOptions = {
      ...getPropsOfCustomModal({ ...options, onCancel: () => onModalClosed(false), onOk: () => onModalClosed(true) }),
      content: content((result) => {
        if (isMountedRef.current) {
          onModalClosed(result);
        }
      }),
    };

    if (!modalRef.current) {
      modalRef.current = Modal.info(mergedOptions);
    } else {
      modalRef.current?.update(mergedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dependencies come from the outside and should stay consistent
  }, [visible, ...dependencies]);
}
