import { useRef } from 'react';
import useMountEffect from '@app/hooks/useMountEffect';

export default function useIsFirstRun(): boolean {
  const initialRender = useRef(true);

  useMountEffect(() => {
    initialRender.current = false;
  });

  return initialRender.current;
}
