import React, { FC, KeyboardEvent, ReactElement, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { showInfoModal } from '@app/components/Modal';
import { observer } from 'mobx-react';
import Button from '@app/components/Button';
import CornersInputBox from '@app/components/inputs/inputBox/CornersInputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import Log from '@app/libs/logger';
import { transformToUnifiedPayee, UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import { searchPayee } from '@mortee/services/payeesServices';
import PayeeSearchStore from '@mortee/stores/appStores/PayeeSearchStore';
import AccountVerificationSearchPayeeResults from '@mortee/routes/accountVerification/initialPage/AccountVerificationSearchPayeeResults';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';
import Popup from '@app/components/popup/Popup';

interface Props {
  onPayeeSelected(payee: UnifiedPayee): void;
}

const SearchPayee: FC<Props> = observer(({ onPayeeSelected }) => {
  const { userStore } = useInfraStores<MorteeMode>();

  const [searchText, setSearchText] = useState<string>('');
  const [hideSearch, setHideSearch] = useState<boolean>(false);
  const popoverParentRef = useRef<HTMLDivElement>(null);

  const [searchResults, searchPayees] = useManuallyCalledLoadable<UnifiedPayee[]>(
    async (): Promise<UnifiedPayee[]> => {
      // We search more than the limit for the search message
      const response = await searchPayee(searchText.trim(), PayeeSearchStore.RESULTS_TO_QUERY + 1);

      return response.map((payee) => transformToUnifiedPayee(payee));
    },
  );

  const handleKeyPress = async (e: KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      await trySearchPayees();
    }
  };

  const trySearchPayees = async (): Promise<void> => {
    setHideSearch(false);
    if ((searchText?.length ?? 0) < 3) {
      Log.event('searchPayees', { error: 'too short' }, true);
      setHideSearch(true);
      showInfoModal(<ModalError>Your search term is too short. Please use at least 3 characters.</ModalError>);
      return;
    }

    if ((searchText?.length ?? 0) > 200) {
      Log.event('searchPayees', { error: 'too long' }, true);
      setHideSearch(true);
      showInfoModal(<ModalError>Your search term is too long. Please use less than 200 characters.</ModalError>);
      return;
    }

    await searchPayees();
  };

  const onSearchTextChange = (searchText: string): void => {
    setHideSearch(true);
    setSearchText(searchText);
  };

  const renderSideComponent = (): ReactElement => {
    return (
      <SearchButtonContainer>
        <StyledSearchButton
          id='btn-account-verification-search-payee'
          dataTestId='btnSearch'
          colorScheme='primary'
          disabled={false}
          onClick={(): Promise<void> => trySearchPayees()}
        >
          SEARCH
        </StyledSearchButton>
      </SearchButtonContainer>
    );
  };

  const showSearchResults = !!searchText && (searchResults.isInProgress() || searchResults.isResolved()) && !hideSearch;

  const hasTooManyPayeeSearchResults = searchResults.resolve(
    (loadedResults) => loadedResults.length > PayeeSearchStore.RESULTS_TO_QUERY,
    () => false,
  );

  return (
    <Container ref={popoverParentRef}>
      <CornersInputBox>
        <StyledSearchInput
          name='inpt-account-verification-payee-search'
          type='text'
          placeholder='Type payee name or entity identifier'
          placeholderStyle='floating'
          dataTestId='txtSearch'
          value={searchText}
          onClear={(): void => onSearchTextChange('')}
          onChange={onSearchTextChange}
          onKeyPress={handleKeyPress}
          renderSideComponent={renderSideComponent}
        />
      </CornersInputBox>
      <Popup
        open={showSearchResults}
        placement='bottom'
        anchorEl={popoverParentRef.current}
        popupWidth='parent'
        onPopupClosed={(): void => setHideSearch(true)}
        preventOutsideClick={false}
      >
        <StyledAccountVerificationSearchPayeeResults
          searchText={searchText}
          results={searchResults}
          currentOrganization={userStore.selectedOrganization}
          hasTooManyPayeeSearchResults={hasTooManyPayeeSearchResults}
          onPayeeSelected={onPayeeSelected}
        />
      </Popup>
    </Container>
  );
});
export default SearchPayee;

const Container = styled.div`
  font-family: var(--text-font-family);

  z-index: 100;
  position: relative;
`;

const StyledSearchInput = styled(NakedFormInput)`
  flex: 1;
`;

export const StyledSearchButton = styled(Button)`
  margin-top: 2px;
`;

export const StyledAccountVerificationSearchPayeeResults = styled(AccountVerificationSearchPayeeResults)`
  margin-top: 4px;
`;

const ModalError = styled.div`
  width: 336px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: center;
  margin-bottom: 16px;
`;

const SearchButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-right: 8px;
`;
