import { UUID_REGEX } from '@app/utils/regexUtils';
import { useSingleQueryParam } from '@app/hooks/useSingleQueryParam';

export default function useRefFromLocation(allowCustomerCustomization: boolean): string | null {
  const [ref] = useSingleQueryParam('ref', UUID_REGEX);

  if (!allowCustomerCustomization) {
    return null;
  }

  return ref ?? null;
}
