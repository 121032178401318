import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useMode from '@app/hooks/useMode';
import useInfraStores from '@app/hooks/useInfraStores';
import InfraStores from '@app/stores/InfraStores';
import { isGuestInfraStores } from '@app/guestLogin/stores/GuestInfraStores';
import { isAuthenticatedInfraStores } from '@app/login/stores/AuthenticatedInfraStores';

export const AppStoresProviderContext = React.createContext<Record<string, any>>({});
export type AppStoresObj = object | {};

const AppStoresByOrganizationProvider: FC = observer(({ children }) => {
  const mode = useMode();
  const infraStores = useInfraStores();

  const [appStoresByOrgMap, setAppStoresByOrgMap] = useState<Map<string | undefined, AppStoresObj>>(() => new Map());

  const orgId = infraStores.userStore.selectedOrganization?.id;
  const loggedIn = !mode.isAnonymous && calcIsLoggedIn(infraStores);

  useEffect(() => {
    if (!mode.isAnonymous && !loggedIn) {
      setAppStoresByOrgMap(new Map());
    }
  }, [mode.isAnonymous, loggedIn]);

  if (shouldCreateAppStoresForOrg(orgId, appStoresByOrgMap, mode.isAnonymous, loggedIn)) {
    appStoresByOrgMap.set(orgId, mode.createAppStores(infraStores));
  }

  const appStores = appStoresByOrgMap.get(orgId) ?? {};

  return <AppStoresProviderContext.Provider value={appStores}>{children}</AppStoresProviderContext.Provider>;
});

const shouldCreateAppStoresForOrg = (
  orgId: string | undefined,
  currentOrgsAppStoreMap: Map<string | undefined, AppStoresObj>,
  isModeAnonymous: boolean,
  isLoggedIn: boolean,
): boolean => {
  if (currentOrgsAppStoreMap.has(orgId)) {
    return false;
  }

  if (isModeAnonymous) {
    return true;
  }

  return isLoggedIn;
};

const calcIsLoggedIn = (infraStores: InfraStores): boolean => {
  if (isGuestInfraStores(infraStores)) {
    return infraStores.guestLoginStore.isLoggedIn;
  }

  if (isAuthenticatedInfraStores(infraStores)) {
    return infraStores.authenticationStore.isLoggedIn;
  }

  return false;
};

export default AppStoresByOrganizationProvider;
