import React, { FunctionComponent, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import VerifyAccountSection from '@mortee/routes/accountVerification/accountVerificationWithPayeePage/VerifyAccountSection';
import SelectedPayeeItemsSection from '@mortee/routes/accountVerification/accountVerificationWithPayeePage/SelectedPayeeItemsSection';
import DownloadPurpleIcon from '@app/images/download-v2-purple-enabled.svg';
import EmailPayeeIcon from '@mortee/images/email-payee.svg';
import EmailCustomerIcon from '@mortee/images/email-customer.svg';
import DownloadIcon from '@app/images/download-v2-accent-enabled.svg';
import DownloadDisabledIcon from '@app/images/download-v2-disabled.svg';
import browserHistory from '@app/utils/browserHistory';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import MorteeMode, { SelectedPayeeIdParams } from '@mortee/morteeMode';
import useAppStores from '@app/hooks/useAppStores';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import {
  downloadAccountVerificationByUniformId,
  downloadVerificationWelcomeLetterByUniformId,
} from '@mortee/services/accountVerificationService';
import { downloadResourceByFileContent } from '@app/utils/fileUtils';
import { FileContentTypes, translateContentTypeToFileExtension } from '@app/domain/files';
import LinearProgress from '@material-ui/core/LinearProgress';
import useInterval from '@app/hooks/useInterval';
import config from '@app/config';
import PageWithHeader from '@app/components/PageWithHeader';
import { useParams } from 'react-router-dom';
import useInfraStores from '@app/hooks/useInfraStores';
import LinkButton from '@app/components/LinkButton';
import AccountVerificationAboveMainCardTitle from '@mortee/routes/accountVerification/AccountVerificationAboveMainCardTitle';
import { MorteeVerificationResult, VerificationResultType } from '@mortee/domain/morteeAccountVerification';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import { isTruthy } from '@app/utils/utils';
import { shoot } from '@app/utils/messageLauncher';
import { showCustomModal } from '@app/components/Modal';
import SendValidationEmailToPayeeModal from '@mortee/routes/accountVerification/sendValidationEmailToPayee/SendValidationEmailToPayeeModal';
import SmallLoadingSpinner from '@app/components/SmallLoadingSpinner';
import SendValidationEmailToCustomerModal from '@mortee/routes/accountVerification/sendValidationEmailToCustomer/SendValidationEmailToCustomerModal';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';

const allowedVerificationPDFDownloadTypes = [
  VerificationResultType.crowdKnowledge,
  VerificationResultType.extendedValidated,
  VerificationResultType.lightweightValidated,
  VerificationResultType.internalValidated,
  VerificationResultType.reverseWireValidated,
  VerificationResultType.selfApproved,
  VerificationResultType.externalBankValidated,
  VerificationResultType.lyonsValidated,
];

const allowedCertificatePDFDownloadTypes = [
  VerificationResultType.extendedValidated,
  VerificationResultType.lightweightValidated,
  VerificationResultType.internalValidated,
  VerificationResultType.reverseWireValidated,
  VerificationResultType.lyonsValidated,
];

const AccountVerificationWithPayeePage: FunctionComponent = observer(() => {
  const { navigationStore, permissionsStore } = useInfraStores<MorteeMode>();
  const { payeeSearchStore } = useAppStores<MorteeMode>();
  const modalContext = useModalContext();

  const [isPreparingReportDownload, setPreparingReportDownload] = useState(false);
  const [reportDownloadPercentage, setReportDownloadPercentage] = useState(0);

  const [isWelcomeLetterDownloading, setIsVerificationWelcomeLetterDownloading] = useState(false);

  const urlParams = useParams<SelectedPayeeIdParams>();
  const {
    selectedPayeeFromSearch,
    isAfterVerification,
    isVerificationError,
    accountDetails,
    verificationDetails,
  } = payeeSearchStore;

  const urlId = urlParams.privateId || urlParams.validatedId;

  if (!selectedPayeeFromSearch || !urlId || selectedPayeeFromSearch.id !== urlId) {
    payeeSearchStore.resetAll();
    browserHistory.push(navigationStore.generateAccountVerificationHomeLink());
    return <div />;
  }

  useInterval(
    () => {
      if (isPreparingReportDownload) {
        setReportDownloadPercentage((currentDownloadPercentage) => Math.min(currentDownloadPercentage + 10, 95));
      }
    },
    isPreparingReportDownload ? (config.downloadPDFProgressExpectedDurationSec / 10) * 1000 : null,
  );

  const downloadVerificationReport = async (
    selectedPayee: UnifiedPayee,
    verificationDetails: MorteeVerificationResult | undefined,
    accountDetails?: Partial<MorteeAccountDetails>,
  ): Promise<void> => {
    if (!accountDetails) {
      return;
    }

    // presentationAccountNumber should get priority over accountNumber in case it exists
    accountDetails.accountNumber = verificationDetails?.presentationAccountNumber || accountDetails.accountNumber;

    setReportDownloadPercentage(0);
    setPreparingReportDownload(true);

    try {
      const resource: NamedResource = await downloadAccountVerificationByUniformId(selectedPayee?.ids, accountDetails);

      if (resource) {
        const { name, resourceData } = resource;

        downloadResourceByFileContent(
          resourceData.content,
          resourceData.mediaType ?? FileContentTypes.APPLICATION_PDF,
          `${name}${translateContentTypeToFileExtension(resourceData.mediaType)}`,
        );
      }
    } finally {
      setPreparingReportDownload(false);
    }
  };

  const downloadVerificationWelcomeLetter = async (
    validatedPayeeUniformId: string,
    validatedPayeeAccountUniformId: string,
  ): Promise<void> => {
    setIsVerificationWelcomeLetterDownloading(true);
    const closePopUpMessage = shoot(
      {
        type: 'info',
        icon: <MessageLoadingSpinner colorScheme='secondary' size={16} />,
      },
      'Downloading Welcome Letter',
    );

    try {
      const resource: NamedResource = await downloadVerificationWelcomeLetterByUniformId(
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
      );

      if (resource) {
        const { name, resourceData } = resource;

        downloadResourceByFileContent(
          resourceData.content,
          resourceData.mediaType ?? FileContentTypes.APPLICATION_PDF,
          `${name}${translateContentTypeToFileExtension(resourceData.mediaType)}`,
        );
      }
    } finally {
      setIsVerificationWelcomeLetterDownloading(false);
      closePopUpMessage();
    }
  };

  const openSendValidationEmailToPayeeModal = (validatedPayeeUniformId: string, validatedPayeeAccountUniformId: string): void => {
    showCustomModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <SendValidationEmailToPayeeModal
            validatedPayeeUniformId={validatedPayeeUniformId}
            validatedPayeeAccountUniformId={validatedPayeeAccountUniformId}
            onDone={onDone}
          />
        </ModalAppContext>
      ),
      {
        maskClosable: false,
      },
    );
  };

  const openSendValidationEmailToCustomerModal = (
    validatedPayeeUniformId: string,
    validatedPayeeAccountUniformId: string,
  ): void => {
    showCustomModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <SendValidationEmailToCustomerModal
            validatedPayeeUniformId={validatedPayeeUniformId}
            validatedPayeeAccountUniformId={validatedPayeeAccountUniformId}
            onDone={onDone}
          />
        </ModalAppContext>
      ),
      {
        maskClosable: false,
      },
    );
  };

  const isValidated = (result?: VerificationResultType): boolean => {
    if (!result) {
      return false;
    }

    return allowedVerificationPDFDownloadTypes.includes(result);
  };

  const isDownloadReportEnabled =
    isAfterVerification && !isVerificationError && accountDetails && isValidated(verificationDetails?.result);

  const renderDownloadReportButton = (): ReactNode => {
    if (!selectedPayeeFromSearch) {
      return null;
    }

    return (
      <Button
        id='btn-validation-download'
        dataTestId='downloadBtn'
        appearance='text'
        size='small'
        onClick={(): Promise<void> | boolean =>
          !isPreparingReportDownload && downloadVerificationReport(selectedPayeeFromSearch, verificationDetails, accountDetails)
        }
        disabled={!isDownloadReportEnabled}
      >
        <StyledStartOverIcon accessibilityLabel='' image={isDownloadReportEnabled ? DownloadIcon : DownloadDisabledIcon} />
        DOWNLOAD
        {isPreparingReportDownload && (
          <LoadProgressBar color='primary' variant='determinate' value={reportDownloadPercentage ?? 0} />
        )}
      </Button>
    );
  };

  const isValidationTypeOfValidatedPayee = (result?: VerificationResultType): boolean => {
    if (!result) {
      return false;
    }

    return allowedCertificatePDFDownloadTypes.includes(result);
  };

  const renderValidationTeamActions = (): ReactNode => {
    if (!isAfterVerification || isVerificationError || !isValidationTypeOfValidatedPayee(verificationDetails?.result)) {
      return null;
    }

    const validatedId = selectedPayeeFromSearch?.ids.validatedId;
    const validatedAccountId = verificationDetails?.validatedAccountUniformId;

    if (!validatedId || !validatedAccountId) {
      return null;
    }

    const menuItems: MenuItem[] = [
      permissionsStore.isSendEmailToPayeePostValidationEnable && {
        id: 'validations-team-menu-send-email-to-payee',
        text: 'Send email to payee',
        icon: EmailPayeeIcon,
        onClick: (): void => openSendValidationEmailToPayeeModal(validatedId, validatedAccountId),
      },
      permissionsStore.isSendEmailToCustomerPostValidationEnable && {
        id: 'validations-team-menu-send-email-to-customer',
        text: 'Send email to customer',
        icon: EmailCustomerIcon,
        onClick: (): void => openSendValidationEmailToCustomerModal(validatedId, validatedAccountId),
      },
      permissionsStore.isDownloadCertificateEnable && {
        id: 'validations-team-menu-download-welcome-letter',
        text: 'Download Welcome Letter',
        icon: DownloadPurpleIcon,
        disabled: isWelcomeLetterDownloading,
        onClick: (): Promise<void> | boolean => downloadVerificationWelcomeLetter(validatedId, validatedAccountId),
      },
    ].filter(isTruthy);

    if (!menuItems.length) {
      return;
    }

    return <Menu id='validations-team-menu' menuItems={menuItems} />;
  };

  return (
    <PageWithHeader widthReactive={false}>
      <AccountVerificationAboveMainCardTitle showBackButton>
        <CardTitleButtons>
          {renderValidationTeamActions()}
          {permissionsStore.isViewManageValidatedPayeesEnable && urlParams.validatedId && (
            <LinkButton
              id='btn-manage-payee'
              appearance='text'
              colorScheme='purple'
              size='small'
              to={navigationStore.generateManageValidatedPayeePageHref(urlParams.validatedId)}
            >
              MANAGE PAYEE
            </LinkButton>
          )}
          {selectedPayeeFromSearch && renderDownloadReportButton()}
        </CardTitleButtons>
      </AccountVerificationAboveMainCardTitle>
      <SelectedPayeeItemsSection payee={selectedPayeeFromSearch} />
      <StyledVerifyAccountSection />
    </PageWithHeader>
  );
});

export default AccountVerificationWithPayeePage;

const CardTitleButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;

  gap: 8px;
`;

const StyledStartOverIcon = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const StyledVerifyAccountSection = styled(VerifyAccountSection)`
  margin-top: 64px;
`;

const LoadProgressBar = styled(LinearProgress)`
  position: absolute !important;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 2px !important;
`;

const MessageLoadingSpinner = styled(SmallLoadingSpinner)`
  margin-right: 10px !important;
`;
