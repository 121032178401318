import { ForwardingFC } from '@app/domain/technicals/components';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import ValueWithPlaceholder from '@app/components/inputs/ValueWithPlaceholder';
import styled from '@emotion/styled';
import { Select } from 'antd';
import { ColorScheme } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';
import { FormInputProps } from '@app/utils/form/form';
import Loadable from '@app/utils/Loadable';

const { Option } = Select;

type Props = FormInputProps<string[], true> & {
  name: string;
  dataTestId?: string;
  ssoProviders: Loadable<string[]>;
  colorScheme?: ColorScheme;
};

const SSOProviderSelect: ForwardingFC<HTMLDivElement, Props> = observer(
  React.forwardRef(
    (
      {
        name,
        dataTestId = name,
        ssoProviders,
        onChange,
        value,
        colorScheme: propColorScheme,
        placeholder,
        placeholderStyle,
        heightType,
        accessibilityLabel,
      },
      ref,
    ) => {
      const colorScheme = useColorScheme(propColorScheme);

      const renderSSOProviders = (): ReactElement[] => {
        return (
          ssoProviders.result?.map(
            (provider): ReactElement => (
              <Option key={provider} value={provider} data-testid={`${dataTestId}-option-${provider}`}>
                {provider}
              </Option>
            ),
          ) ?? []
        );
      };

      const placeholderElementId = `placeholder-of-${name}`;

      return (
        <ValueWithPlaceholder
          colorScheme={colorScheme}
          placeholder={placeholder}
          placeholderStyle={placeholderStyle}
          heightType={heightType}
          placeholderElementId={placeholderElementId}
          valueNotEmpty={!!value?.length}
          dataTestId={name}
        >
          <StyledSelect
            id={name}
            data-testId={dataTestId}
            loading={ssoProviders.isNotSettled()}
            mode='multiple'
            onChange={onChange}
            value={value}
            aria-labelledby={accessibilityLabel ? undefined : placeholderElementId}
            aria-label={accessibilityLabel}
          >
            {renderSSOProviders()}
          </StyledSelect>
        </ValueWithPlaceholder>
      );
    },
  ),
);

export default SSOProviderSelect;

const StyledSelect = styled(Select)`
  .ant-select-selection {
    border: none !important;
  }
`;
