import { UserServerResponse } from '@app/domain/userManagement/users';
import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { StoreUserRequest } from '@backee/domain/users';

const usersService = {
  async storeUser(data: StoreUserRequest): Promise<UserServerResponse> {
    return request<UserServerResponse>(serverUrl, `/api/users`, {
      data,
      method: HttpMethod.post,
      errorsHandler: {
        PHONE_NUMBER_ALREADY_EXISTS_ERROR: {
          message: (): string => `Phone number is already in use by another user.`,
        },
        EMAIL_ALREADY_EXISTS_ERROR: {
          message: (): string => `Email is already in use by another user.`,
        },
        default: {
          message: 'Cannot create user.',
        },
      },
    });
  },
};
export default usersService;
