import React, { FC } from 'react';
import { Organization } from '@app/domain/userManagement/organizations';
import {
  OrganizationalUser,
  OrganizationalUserServerResponse,
  transformOrganizationalUser,
} from '@app/domain/userManagement/organizationUsers';
import useLoadable from '@app/hooks/loadable/useLoadable';
import {
  addUserToOrganization,
  exportOrganisationUserRoles,
  getAllUsersOfOrganization,
  getAllUsersOfOrganizationWithNsknoxUsers,
  killUserSessions,
  suspendUserFromOrganization,
} from '@app/services/userManagement/organizationUsersService';
import OrganizationUsersTable from '@app/components/userManagement/organizationUsersTable/OrganizationUsersTable';
import Button from '@app/components/Button';
import {
  confirmAsync,
  ModalBody,
  ModalTextLine,
  ModalTitle,
  showCustomModalAsync,
  showCustomResultModalAsync,
  WrappingModalContainer,
} from '@app/components/Modal';
import AddUsersToOrganizationModal from '@app/components/userManagement/addUsersToOrganization/AddUsersToOrganizationModal';
import { LoadableCreator } from '@app/utils/Loadable';
import styled from '@emotion/styled';
import { shoot } from '@app/utils/messageLauncher';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import downloadFile from '@app/images/download-file.svg';
import { downloadResourceByFileContent } from '@app/utils/fileUtils';
import { FileTypes } from '@app/domain/files';
import moment from 'moment';
import SuspendUserConfirmationModal, {
  SuspendUserConfirmationResult,
} from '@app/components/userManagement/organizationUsersTable/SuspendUserConfirmationModal';
import { KnoxerIdentityProviders } from '@app/domain/userManagement/identityProviders';
import { AllKnoxers } from '@app/domain/userManagement/knoxers';

interface Props {
  organization: Organization;
  knoxers: AllKnoxers;
  organizationIdentityProviders: KnoxerIdentityProviders[];
  canViewNsknoxUsers: boolean;
  canAddUsersToOrg: boolean;
  canSuspendUserFromOrganization: boolean;
  canKillUserSessions: boolean;
  canEditUserPermissions: boolean;
  className?: string;
}

const ManageOrganizationUsers: FC<Props> = ({
  organization,
  knoxers,
  organizationIdentityProviders,
  canViewNsknoxUsers,
  canAddUsersToOrg,
  canKillUserSessions,
  canEditUserPermissions,
  canSuspendUserFromOrganization,
  className,
}) => {
  const modalContext = useModalContext();
  const [usersLoadable, reloadOrganizationUsers, setUsersLoadable] = useLoadable(async (): Promise<OrganizationalUser[]> => {
    let usersServerResponse: OrganizationalUserServerResponse[];

    if (canViewNsknoxUsers) {
      usersServerResponse = await getAllUsersOfOrganizationWithNsknoxUsers(organization.id);
    } else {
      usersServerResponse = await getAllUsersOfOrganization(organization.id);
    }

    return usersServerResponse.map(transformOrganizationalUser);
  }, [organization.id]);

  const addUserToOrganizationAndUpdateTable = async (tenantUserId: string): Promise<OrganizationalUser> => {
    const addedUserServerResponse = await addUserToOrganization(organization.id, tenantUserId);
    const addedUser = transformOrganizationalUser(addedUserServerResponse);

    updateUserInTable(addedUser);

    return addedUser;
  };

  const onUserRoleUpdated = async (updateOrganizationalUser: OrganizationalUser): Promise<void> => {
    updateUserInTable(updateOrganizationalUser);
  };

  const updateUserInTable = (newUser: OrganizationalUser): void => {
    setUsersLoadable((currentUsersLoadable) => {
      if (!currentUsersLoadable.isResolved()) {
        return currentUsersLoadable;
      }

      const sameOrganizationalUserInTable = currentUsersLoadable.result.find(
        (user) => user.tenantUserId === newUser.tenantUserId,
      );

      // Already in table, replace new old item with the new item
      if (sameOrganizationalUserInTable) {
        const newArray = [...currentUsersLoadable.result];
        newArray[newArray.indexOf(sameOrganizationalUserInTable)] = newUser;

        return LoadableCreator.resolved(newArray);
      }

      return LoadableCreator.resolved([...currentUsersLoadable.result, newUser]);
    });
  };

  async function openAddNewUserToOrgModal(): Promise<void> {
    await showCustomModalAsync(
      (onDone) => {
        return (
          <ModalAppContext {...modalContext}>
            <AddUsersToOrganizationModal
              organizationId={organization.id}
              organizationName={organization.name}
              addUserToOrganization={addUserToOrganizationAndUpdateTable}
              onDone={onDone}
            />
          </ModalAppContext>
        );
      },
      {
        maskClosable: false,
      },
    );

    await reloadOrganizationUsers();
  }

  async function killUserSessionsWithMessage(tenantUserId: string): Promise<void> {
    await killUserSessions(tenantUserId);

    shoot(
      {
        type: 'success',
        closeable: true,
      },
      `All user's sessions have been stopped`,
    );
  }

  const renderConfirmationModal = (userName: string | null): React.ReactElement => {
    return (
      <WrappingModalContainer>
        <ModalTitle>Kill all the sessions for {userName}?</ModalTitle>
        <ModalBody>
          <ModalTextLine>You are about to kill all the sessions for this users. Are you sure you want to proceed?</ModalTextLine>
        </ModalBody>
      </WrappingModalContainer>
    );
  };

  async function killUserSessionFromMenu(tenantUserId: string, userName: string | null): Promise<void> {
    const shouldKillProcess = await confirmAsync({
      content: renderConfirmationModal(userName),
      okText: 'YES',
      cancelText: 'NO',
      icon: <span />,
      maskClosable: false,
      width: 550,
    });

    if (!shouldKillProcess) {
      return;
    }

    await killUserSessionsWithMessage(tenantUserId);
  }

  async function suspendUserFromOrganizationAndReloadTable(tenantUserId: string, userName: string | null): Promise<void> {
    const { confirm, killUserSessions } = await showCustomResultModalAsync<SuspendUserConfirmationResult>((onResult) => (
      <SuspendUserConfirmationModal onResult={onResult} userName={userName} modalContext={modalContext} />
    ));

    if (!confirm) {
      return;
    }

    const closeLoadingMessage = shoot({ type: 'loading', closeable: true, duration: null }, 'Suspending user');
    try {
      if (killUserSessions) {
        await killUserSessionsWithMessage(tenantUserId);
      }

      await suspendUserFromOrganization(organization.id, tenantUserId);
      closeLoadingMessage();

      shoot(
        {
          type: 'success',
          closeable: true,
        },
        `User ${userName ? `"${userName}" ` : ''}suspended from organization "${organization.name}"`,
      );
    } catch {
      closeLoadingMessage();
    }

    await reloadOrganizationUsers();
  }

  const clickExportOrganisationUserRoles = async (): Promise<void> => {
    const attachment = await exportOrganisationUserRoles(organization.id);
    const currentDate = moment(new Date()).format('DD-MM-YYYY');

    downloadResourceByFileContent(
      attachment.content,
      FileTypes.xlsx,
      `PaymentKnoxUsers - ${organization.name} - ${currentDate}.xlsx`,
    );
  };

  const actionMenuItems: MenuItem[] = [
    {
      id: `btn-export-org-users-roles`,
      text: 'Export account user permissions',
      icon: downloadFile,
      onClick: clickExportOrganisationUserRoles,
    },
  ];

  return (
    <div>
      <ActionsBlock>
        {canAddUsersToOrg && (
          <AddNewUserButton id='organization-manage-users-add-user' onClick={openAddNewUserToOrgModal} appearance='text'>
            + ADD USER
          </AddNewUserButton>
        )}
        <Menu menuItems={actionMenuItems} id={`organization-manage-users-table-actions-menu`} />
      </ActionsBlock>
      <OrganizationUsersTable
        id='organization-manage-users-table'
        organizationId={organization.id}
        usersLoadable={usersLoadable}
        knoxers={knoxers}
        organizationIdentityProviders={organizationIdentityProviders}
        canSuspendUserFromOrganization={canSuspendUserFromOrganization}
        canKillUserSessions={canKillUserSessions}
        canEditUserPermissions={canEditUserPermissions}
        suspendUserFromOrganization={suspendUserFromOrganizationAndReloadTable}
        killUserSessions={killUserSessionFromMenu}
        onUserUpdated={onUserRoleUpdated}
        className={className}
      />
    </div>
  );
};

export default ManageOrganizationUsers;

const ActionsBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddNewUserButton = styled(Button)`
  display: inline-block;
`;
