import React, { FC, ReactElement } from 'react';

interface LeftToRightTextProps {
  text: string | null | undefined;
  className?: string;
}

//&lrm; keeps the text in the correct order when changing to direction rtl
const LeftToRightText: FC<LeftToRightTextProps> = ({ className, text }): ReactElement => {
  return text ? <div className={className}>&lrm;{text}</div> : <></>;
};

export default LeftToRightText;
