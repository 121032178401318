import { ReactNode } from 'react';

export interface SftpErrorServerResponse {
  id: string;
  writeTimestamp: number;
  fileType: SftpFileTypes;
  correlationId: string;
  fileName: string;
  webErrorCode: string;
  errorAdditionalData: any;
}

export interface SftpError {
  id: string;
  writeTimestamp: number;
  fileType: string | null;
  correlationId: string;
  fileName: string;
  webErrorCode: string;
  customErrorText: CustomErrorText;
}

export enum SftpFileTypes {
  mvf = 'MVF',
  payment = 'Payment',
}

export interface CustomErrorText {
  text: string;
  customTooltipContent?: ReactNode;
}
