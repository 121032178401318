import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import SVG, { SpriteSymbol } from '@app/components/SVG';
import ExtendedImage from '@app/images/validateResult/scale/extendedGroup.svg';
import NotValidatedImage from '@app/images/validateResult/scale/notValidatedGroup.svg';
import StandardImage from '@app/images/validateResult/scale/standardGroup.svg';
import SelfImage from '@app/images/validateResult/scale/selfGroup.svg';
import notValidatedIcon from '@app/images/validateResult/bullets/warningIcon.svg';
import Card from '@app/components/card/Card';
import { BlockDescriptor, InfoContent } from '@app/components/DisplayFields';
import { validationTypes, VerificationResultType } from '@mortee/domain/morteeAccountVerification';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';

const VALIDATE_RESULT_TEST_ID = 'conValidateResult';
const validatedResultAccessibilityLabel = (verificationResult: string): string => {
  return `A rating scale from 1 to 4 that expresses the reliability of the account, with the lowest rank being "Not Validated", the next rank is "Self Validation", then "Standard Validation" and finally the highest rank - "Extended Validation". The verification result for this search is: ${verificationResult}`;
};

interface Props {
  verificationDetails: VerificationResultType | DeterministicValidationResultType;
}

interface GenericValidation {
  validationResult: DeterministicValidationResultType;
  validationResultLabel: string;
  logo: SpriteSymbol;
  icon?: SpriteSymbol;
  title: string;
  subtitle?: string;
  extraData?: ReactNode;
}

function convertToDeterministicValidationResult(
  verificationResult: VerificationResultType | DeterministicValidationResultType,
): DeterministicValidationResultType {
  switch (verificationResult) {
    case DeterministicValidationResultType.notValidated:
    case VerificationResultType.notValidated:
    case VerificationResultType.requireStandardValidation:
    case VerificationResultType.requireExtendedValidation:
    case VerificationResultType.crowdKnowledge: {
      return DeterministicValidationResultType.notValidated;
    }
    case DeterministicValidationResultType.selfApproved:
    case VerificationResultType.selfApproved: {
      return DeterministicValidationResultType.selfApproved;
    }
    case DeterministicValidationResultType.standardValidated:
    case VerificationResultType.lightweightValidated:
    case VerificationResultType.internalValidated:
    case VerificationResultType.externalBankValidated:
    case VerificationResultType.reverseWireValidated:
    case VerificationResultType.lyonsValidated: {
      return DeterministicValidationResultType.standardValidated;
    }
    case DeterministicValidationResultType.extendedValidated:
    case VerificationResultType.extendedValidated: {
      return DeterministicValidationResultType.extendedValidated;
    }
  }
}

const VerifyResultCard: FC<Props> = (props) => {
  const renderAccountNotFound = (): ReactElement => {
    return renderGeneric({
      validationResult: DeterministicValidationResultType.notValidated,
      validationResultLabel: 'Not Validated Account',
      logo: NotValidatedImage,
      title: 'Bank Account Not Found',
      subtitle: 'Vendor was found, but the specified bank account details were not found.',
      icon: notValidatedIcon,
    });
  };

  const renderSelfValidation = (): ReactElement => {
    return renderGeneric({
      validationResult: DeterministicValidationResultType.selfApproved,
      validationResultLabel: 'Self Validated Account',
      logo: SelfImage,
      title: 'Bank Account Recognized',
      subtitle:
        'Bank account details were validated by your organization due to internal assessment that the bank account details are correct. This bank account was not validated by nsKnox.',
    });
  };

  const renderStandardValidation = (): ReactElement => {
    return renderGeneric({
      validationResult: DeterministicValidationResultType.standardValidated,
      validationResultLabel: 'Standard Validated Account',
      logo: StandardImage,
      title: 'Bank Account Registered Under Specified Name',
      subtitle: 'Bank account details were validated through Standard Validation.',
      extraData: renderStandardValidationExtraData(),
    });
  };

  const renderStandardValidationExtraData = (): ReactElement => {
    return (
      <SubtitleRow>
        {`The vendor's bank account information was verified by nsKnox.
This verification ensures the specified bank account was registered under the specified vendor name and ID and, therefore, it is most probably correct.`}
      </SubtitleRow>
    );
  };

  const renderExtendedValidation = (): ReactElement => {
    return renderGeneric({
      validationResult: DeterministicValidationResultType.extendedValidated,
      validationResultLabel: 'Extended Validated Account',
      logo: ExtendedImage,
      title: 'Bank Account and Identity of Account Holders Verified',
      subtitle: `Bank account details were validated through Extended Validation. 
The vendor's bank account information was verified by nsKnox. 
This is the most comprehensive verification as it actively verifies the validity of the account and the identity of the account holders.`,
    });
  };

  const renderGeneric = (validationObject: GenericValidation): ReactElement => {
    const { validationResult, validationResultLabel, logo, title, icon, subtitle, extraData } = validationObject;

    return (
      <>
        <StyledVerifiedCard role='status'>
          <Container data-testid={VALIDATE_RESULT_TEST_ID} data-validation-result={validationResult}>
            <TextContainer>
              <BigTitle>Verification Result</BigTitle>
            </TextContainer>
            <Logo>
              <SVG accessibilityLabel={validatedResultAccessibilityLabel(validationResultLabel)} image={logo} />
            </Logo>
          </Container>
        </StyledVerifiedCard>
        <StyledTextVerifiedCard>
          <DescTextContainer>
            <TitleRow>
              {icon && (
                <IconContainer>
                  <SVG accessibilityLabel='' image={icon} height={20} />
                </IconContainer>
              )}
              <Title data-testid='verification-title'>{title}</Title>
            </TitleRow>
            {subtitle && <SubtitleRow data-testid='verification-subtitle'>{subtitle}</SubtitleRow>}
            {extraData && <ExtraDataRow data-testid='verification-extra-data'>{extraData}</ExtraDataRow>}
          </DescTextContainer>
        </StyledTextVerifiedCard>
      </>
    );
  };

  const renderCardContent = (
    verificationResult: VerificationResultType | DeterministicValidationResultType,
  ): React.ReactElement => {
    const deterministicValidationResult: DeterministicValidationResultType = convertToDeterministicValidationResult(
      verificationResult,
    );

    switch (deterministicValidationResult) {
      case DeterministicValidationResultType.extendedValidated:
        return renderExtendedValidation();
      case DeterministicValidationResultType.standardValidated:
        return renderStandardValidation();
      case DeterministicValidationResultType.selfApproved:
        return renderSelfValidation();
      case DeterministicValidationResultType.notValidated:
        return renderAccountNotFound();
    }
  };

  const renderValidationTypeExplanation = (key: string, verificationType): ReactNode => {
    return (
      <ValidationTypeExplanationContainer key={key}>
        <ValidationTypeIcon accessibilityLabel='' image={verificationType.logo} />
        <ValidationTypeExplanationTextContainer>
          <ValidationTypeDescriptor>{verificationType.title}</ValidationTypeDescriptor>
          <InfoContent>{verificationType.description}</InfoContent>
        </ValidationTypeExplanationTextContainer>
      </ValidationTypeExplanationContainer>
    );
  };

  const { verificationDetails } = props;

  return (
    <div>
      {renderCardContent(verificationDetails)}
      {
        <ValidationTypesExplanationContainer>
          {Object.entries(validationTypes).map(([verificationResultType, verificationResult]) =>
            renderValidationTypeExplanation(verificationResultType, verificationResult),
          )}
        </ValidationTypesExplanationContainer>
      }
    </div>
  );
};

export default VerifyResultCard;

interface TitleProps {
  color?: string;
  isBold?: boolean;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--gray-semi-transparent);
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledVerifiedCard = styled(StyledCard)`
  padding: 30px 32px 25px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextVerifiedCard = styled(StyledVerifiedCard)`
  align-items: start;
  margin-top: 4px;
`;

const Logo = styled.div`
  svg,
  img {
    height: 221px;
    margin: 28px 16px 0;
  }
`;

const BigTitle = styled.div<TitleProps>`
  display: flex;
  margin-top: 8px;
  justify-content: center;
  font-weight: 200;
  font-size: 36px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  text-align: center;
  color: var(--gray-blue-deprecated);
`;

const Title = styled.div<TitleProps>`
  font-size: 17px;
  color: var(--transparent-black-900);
  white-space: pre-wrap;
`;

const TextContainer = styled.div`
  display: inline;
  align-items: center;
`;

const DescTextContainer = styled.div`
  margin: 0 50px;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const SubtitleRow = styled.div`
  font-size: 13px;
  color: var(--transparent-black-600);
  white-space: pre-wrap;
`;

const ExtraDataRow = styled.div`
  margin-top: 8px;
`;

const ValidationTypesExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
`;

const ValidationTypeExplanationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 18px;
`;

const ValidationTypeDescriptor = styled(BlockDescriptor)`
  margin-bottom: 6px;
`;

const ValidationTypeExplanationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 9px;
`;

const ValidationTypeIcon = styled(SVG)`
  height: 48px;
  min-width: 40px;
  margin-right: 16px;
`;
