import React, { ReactNode } from 'react';

export function isTextPresent(node: ReactNode): boolean {
  if (typeof node === 'string' || typeof node === 'number') {
    return node.toString().trim() !== '';
  }

  if (Array.isArray(node)) {
    return node.some(isTextPresent);
  }

  if (React.isValidElement(node) && node.props.children) {
    return isTextPresent(node.props.children);
  }

  return false;
}
