import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form/Form';
import useForm from '@app/hooks/useForm';
import { shoot } from '@app/utils/messageLauncher';
import useIsMounted from '@app/hooks/useIsMounted';
import PageHeader from '@app/components/header/PageHeader';
import { confirmAsync, ModalTitle, showContentOnlyModal } from '@app/components/Modal';
import { USER_FIELD_MAX_LENGTH } from '@app/domain/userManagement/users';
import { ActionsList, FieldsTitle, StyledBackeeForm, SubmitButton } from '@backee/styles/styles';
import styled from '@emotion/styled';
import BackeeMode from '@backee/backeeMode';
import useAppStores from '@app/hooks/useAppStores';
import Loadable from '@app/utils/Loadable';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { trim } from '@app/utils/stringUtils';
import { REQUIRED_FORM_MESSAGE } from '@app/domain/uiConsts';
import { FormFieldDecorators } from '@app/utils/form/form';
import { isDefined } from '@app/utils/utils';
import Loader from '@app/components/Loader';
import Card from '@app/components/card/Card';
import Button from '@app/components/Button';
import SSOAndPhoneFlowInput, { SSOAndPhoneIdentityForUser } from '@backee/routes/users/createUserPage/SSOAndPhoneFlowInput';
import DynamicListOfFormField, { DynamicListOfFormFieldObject } from '@app/components/inputs/DynamicListOfFormField';
import DoubleSSOFlowInput, { DoubleSSOIdentityForUser } from '@backee/routes/users/createUserPage/DoubleSSOFlowInput';
import EmailAndPhoneFlowInput, { EmailAndPhoneIdentityForUser } from '@backee/routes/users/createUserPage/EmailAndPhoneFlowInput';
import { distinctValues, findDuplicates } from '@app/utils/arrayUtils';
import ErrorModal from '@app/components/ErrorModal';
import { parsePhoneNumber } from 'libphonenumber-js';
import { H6StartTransparentBlack800 } from '@app/components/Text';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import InnerCard from '@app/components/card/InnerCard';
import usersService from '@backee/services/usersService';
import {
  IdentityProviderAssignedToUserRequest,
  KnoxerAllIdentityProvidersAssignedToUserRequest,
  StoreUserRequest,
} from '@backee/domain/users';

export const PHONE_PREFIX = '+';

interface CreateUserFormFields {
  name: string;
  emailAndPhoneIdentities?: DynamicListOfFormFieldObject<EmailAndPhoneIdentityForUser>;
  ssoAndPhoneIdentities?: DynamicListOfFormFieldObject<SSOAndPhoneIdentityForUser>;
  doubleSSOIdentities?: DynamicListOfFormFieldObject<DoubleSSOIdentityForUser>;
}

interface Props extends FormComponentProps<CreateUserFormFields> {}

const CreateUserPage: FC<Props> = observer((props) => {
  const { knoxersStore, identityProvidersStore } = useAppStores<BackeeMode>();
  const isMountedRef = useIsMounted();
  const { form, showFormErrors, setShowFormErrors, validateFields } = useForm(props);

  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  const renderConfirmationModal = (formValues: CreateUserFormFields): React.ReactElement => {
    const emailAndPhoneIdentities = formValues.emailAndPhoneIdentities?.values?.filter(isDefined) ?? [];
    const ssoAndPhoneIdentities = formValues.ssoAndPhoneIdentities?.values?.filter(isDefined) ?? [];
    const doubleSSOIdentities = formValues.doubleSSOIdentities?.values?.filter(isDefined) ?? [];

    return (
      <div>
        <ModalTitle>Save User?</ModalTitle>
        <UserCard>
          <H6StartTransparentBlack800.div>{formValues.name}</H6StartTransparentBlack800.div>
          <DataGrid>
            {!!emailAndPhoneIdentities?.length && (
              <DataGridRow title={`"Email ➡ Phone" Auth Flow`} valueDataTest='email-phone-auth-flows'>
                <ul>
                  {emailAndPhoneIdentities.map((identity) => (
                    <li key={identity.email + identity.phoneNumber}>
                      <div>{identity.email}</div>
                      <div>{formatPhoneNumber(identity.phoneNumber)}</div>
                      <div></div>
                    </li>
                  ))}
                </ul>
              </DataGridRow>
            )}
            {!!ssoAndPhoneIdentities?.length && (
              <DataGridRow title={`"SSO ➡ Phone" Auth Flow`} valueDataTest='sso-phone-auth-flows'>
                <ul>
                  {ssoAndPhoneIdentities.map((identity) => (
                    <li key={identity.emailKnoxerSSOProvider}>
                      <div>
                        {identity.ssoId} - {identity.emailKnoxerSSOProvider}
                      </div>
                      <div>{formatPhoneNumber(identity.phoneNumber)}</div>
                      <div></div>
                    </li>
                  ))}
                </ul>
              </DataGridRow>
            )}
            {!!doubleSSOIdentities?.length && (
              <DataGridRow title={`"SSO ➡ SSO" Auth Flow`} valueDataTest='sso-sso-auth-flows'>
                <ul>
                  {doubleSSOIdentities.map((identity) => (
                    <li key={identity.phoneKnoxerSSOProvider}>
                      <div>
                        {identity.ssoId} - {identity.emailKnoxerSSOProvider}
                      </div>
                      <div>
                        {identity.ssoId} - {identity.phoneKnoxerSSOProvider}
                      </div>
                      <div></div>
                    </li>
                  ))}
                </ul>
              </DataGridRow>
            )}
          </DataGrid>
        </UserCard>
      </div>
    );
  };

  const createUser = async (collectedData: StoreUserRequest, formValues: CreateUserFormFields): Promise<void> => {
    setSavingInProgress(true);

    try {
      const shouldSave = await confirmAsync({
        content: renderConfirmationModal(formValues),
        okText: 'YES',
        cancelText: 'NO',
        icon: <span />,
        maskClosable: false,
        width: 550,
      });

      if (!shouldSave) {
        return;
      }

      const result = await usersService.storeUser(collectedData);

      if (isMountedRef.current) {
        form.resetFields();
      }

      shoot({ type: 'success', closeable: true }, `User "${result.name}" created`);
    } finally {
      if (isMountedRef.current) {
        setSavingInProgress(false);
      }
    }
  };

  if (!knoxersStore.knoxers.isResolved()) {
    return <Loader spinning />;
  }

  const knoxers = knoxersStore.knoxers.result;

  const ssoIdentityProvidersForEmailKnoxer: Loadable<string[]> = identityProvidersStore
    .getSSOIdentityProvidersForKnoxer(knoxers.emailKnoxer.id)
    .map((identityProviders) => identityProviders.map((identityProvider) => identityProvider.name));

  const ssoIdentityProvidersForPhoneKnoxer: Loadable<string[]> = identityProvidersStore
    .getSSOIdentityProvidersForKnoxer(knoxers.phoneKnoxer.id)
    .map((identityProviders) => identityProviders.map((identityProvider) => identityProvider.name));

  const handleOk = async (): Promise<void> => {
    const values = await validateFields();

    const createUserValues = extractValidRequestFormValues(values);

    if (!createUserValues) {
      return;
    }

    await createUser(createUserValues, values);
  };

  function formatPhoneNumber(phoneNumber: string | undefined): string | undefined {
    if (!phoneNumber) {
      return;
    }

    return parsePhoneNumber(PHONE_PREFIX + phoneNumber).number;
  }

  function extractValidRequestFormValues(formValues: CreateUserFormFields): StoreUserRequest | null {
    const identitiesWithEmail = formValues.emailAndPhoneIdentities?.values?.filter(isDefined) ?? [];
    const identitiesWithEmailKnoxerSSO =
      [...(formValues.ssoAndPhoneIdentities?.values ?? []), ...(formValues.doubleSSOIdentities?.values ?? [])]?.filter(
        isDefined,
      ) ?? [];
    const identitiesWithPhoneNumber =
      [...(formValues.ssoAndPhoneIdentities?.values ?? []), ...(formValues.emailAndPhoneIdentities?.values ?? [])]?.filter(
        isDefined,
      ) ?? [];
    const identitiesWithPhoneKnoxerSSO = formValues.doubleSSOIdentities?.values?.filter(isDefined) ?? [];

    if (
      !identitiesWithEmail.length &&
      !identitiesWithEmailKnoxerSSO.length &&
      !identitiesWithPhoneNumber.length &&
      !identitiesWithPhoneKnoxerSSO.length
    ) {
      const bodyContent = <div>You must define at least one auth flow in order to create a user</div>;
      showContentOnlyModal((onDone) => (
        <ErrorModal headerContent='Missing Flow Error' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
      ));
      return null;
    }

    const distinctEmails = distinctValues(identitiesWithEmail.map((x) => x.email).map(trim));
    if (distinctEmails.length > 1) {
      const bodyContent = (
        <div>
          There are multiple different emails defined in the form:
          {distinctEmails.map((email) => (
            <div key={email}>"{email}"</div>
          ))}
        </div>
      );
      showContentOnlyModal((onDone) => (
        <ErrorModal headerContent='Multiple Emails Error' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
      ));
      return null;
    }

    const distinctPhoneNumbers = distinctValues(identitiesWithPhoneNumber.map((x) => x.phoneNumber).map(trim));
    if (distinctPhoneNumbers.length > 1) {
      const bodyContent = (
        <div>
          There are multiple different phone numbers defined in the form:{' '}
          {distinctPhoneNumbers.map((phoneNumber) => (
            <div key={phoneNumber}>"+{phoneNumber}"</div>
          ))}
        </div>
      );
      showContentOnlyModal((onDone) => (
        <ErrorModal headerContent='Multiple Phone Numbers Error' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
      ));
      return null;
    }

    const distinctEmailKnoxerSSOProvider = findDuplicates(identitiesWithEmailKnoxerSSO.map((x) => x.emailKnoxerSSOProvider));
    if (distinctEmailKnoxerSSOProvider.length) {
      const bodyContent = (
        <div>
          The SSO providers of knoxer {knoxers?.emailKnoxer.name} are defined multiple times:{' '}
          {distinctEmailKnoxerSSOProvider.map((emailKnoxerSSOProvider) => (
            <div key={emailKnoxerSSOProvider}>"{emailKnoxerSSOProvider}"</div>
          ))}
        </div>
      );
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent={`Duplicate ${knoxers?.emailKnoxer.name} Knoxer SSO Providers`}
          bodyContent={bodyContent}
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      return null;
    }

    const distinctPhoneNumberKnoxerSSOProvider = findDuplicates(
      identitiesWithPhoneKnoxerSSO.map((x) => x.phoneKnoxerSSOProvider),
    );
    if (distinctPhoneNumberKnoxerSSOProvider.length) {
      const bodyContent = (
        <div>
          The SSO providers of knoxer {knoxers?.phoneKnoxer.name} are defined multiple times:{' '}
          {distinctPhoneNumberKnoxerSSOProvider.map((phoneNumberKnoxerSSOProvider) => (
            <div key={phoneNumberKnoxerSSOProvider}>"{phoneNumberKnoxerSSOProvider}"</div>
          ))}
        </div>
      );
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent={`Duplicate ${knoxers?.phoneKnoxer.name} Knoxer SSO Providers`}
          bodyContent={bodyContent}
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      return null;
    }

    const ssoUserIds: KnoxerAllIdentityProvidersAssignedToUserRequest[] = [
      {
        knoxerId: knoxers.emailKnoxer.id,
        identities: identitiesWithEmailKnoxerSSO
          .filter((ssoIdentity) => ssoIdentity.emailKnoxerSSOProvider)
          .map(
            (ssoIdentity): IdentityProviderAssignedToUserRequest => {
              return {
                id: ssoIdentity.ssoId,
                identityProviderName: ssoIdentity.emailKnoxerSSOProvider,
              };
            },
          ),
      },
      {
        knoxerId: knoxers.phoneKnoxer.id,
        identities: identitiesWithPhoneKnoxerSSO
          .filter(isDefined)
          .map((ssoIdentity): IdentityProviderAssignedToUserRequest | null => {
            return {
              id: ssoIdentity.ssoId,
              identityProviderName: ssoIdentity.phoneKnoxerSSOProvider,
            };
          })
          .filter(isDefined),
      },
    ].filter((knoxerSSOIdentities) => knoxerSSOIdentities.identities.length);

    return {
      name: formValues.name,
      phoneNumber: formatPhoneNumber(identitiesWithPhoneNumber[0]?.phoneNumber),
      email: distinctEmails[0],
      ssoUserIds: ssoUserIds,
    };
  }

  const fieldDecorators = createFieldDecorators();

  return (
    <PageWithHeader width='full' header={<PageHeader>Create User</PageHeader>}>
      <StyledBackeeForm
        form={form}
        appearance='corners'
        showErrors={showFormErrors}
        disabled={savingInProgress}
        setShowErrors={setShowFormErrors}
      >
        <FormCard>
          <FieldsTitle>General</FieldsTitle>
          <FormItemBox fieldName='name' fieldDecoratorOptions={fieldDecorators.name} showAsRequired>
            <NakedFormInput
              name={`txt-create-user-name`}
              dataTestId={`txt-create-user-name`}
              type='text'
              placeholder='Name'
              disableSuggestion
            />
          </FormItemBox>
          <SmallSeparator />
          <FieldsTitle>"Email ➡ Phone" Auth Flow</FieldsTitle>
          <DynamicListOfFormField
            id='create-user-list-email-and-phone'
            fieldName='emailAndPhoneIdentities'
            maxAmountOfItems={1}
            renderChild={(childFieldName, remove, index): ReactElement => {
              return (
                <SSOIdentityInputLine>
                  <FullWidthEmailAndPhoneFlowInput
                    id={`ssoidentity-create-user-email-and-phone-flow-${index}`}
                    dataTestId={`ssoidentity-create-user-email-and-phone-flow-${index}`}
                    fieldName={childFieldName}
                    accessibilityLabel={`Email and Phone Identity #${index}`}
                  />
                  <RemoveButton
                    id={`btn-create-user-email-and-phone-remove-${index}`}
                    onClick={remove}
                    appearance='text'
                    colorScheme='red'
                    cornerType='circle'
                  >
                    -
                  </RemoveButton>
                </SSOIdentityInputLine>
              );
            }}
          />
          <SmallSeparator />
          <FieldsTitle>"SSO ➡ Phone" Auth Flow</FieldsTitle>
          <DynamicListOfFormField
            id='create-user-list-sso-and-phone'
            fieldName='ssoAndPhoneIdentities'
            renderChild={(childFieldName, remove, index): ReactElement => {
              return (
                <SSOIdentityInputLine>
                  <FullWidthSSOAndPhoneFlowInput
                    id={`ssoidentity-create-user-sso-and-phone-flow-${index}`}
                    dataTestId={`ssoidentity-create-user-sso-and-phone-flow-${index}`}
                    fieldName={childFieldName}
                    accessibilityLabel={`SSO and phone Identity #${index}`}
                    allEmailKnoxerSSOProviders={ssoIdentityProvidersForEmailKnoxer}
                  />
                  <RemoveButton
                    id={`btn-create-user-sso-and-phone-remove-${index}`}
                    onClick={remove}
                    appearance='text'
                    colorScheme='red'
                    cornerType='circle'
                  >
                    -
                  </RemoveButton>
                </SSOIdentityInputLine>
              );
            }}
          />
          <SmallSeparator />
          <FieldsTitle>"SSO ➡ SSO" Auth Flow</FieldsTitle>
          <DynamicListOfFormField
            id='create-user-list-double-sso'
            fieldName='doubleSSOIdentities'
            renderChild={(childFieldName, remove, index): ReactElement => {
              return (
                <SSOIdentityInputLine>
                  <FullWidthDoubleSSOFlowInput
                    id={`ssoidentity-create-user-double-sso-flow-${index}`}
                    dataTestId={`ssoidentity-create-user-double-sso-flow-${index}`}
                    fieldName={childFieldName}
                    accessibilityLabel={`Double SSO Identity #${index}`}
                    allEmailKnoxerSSOProviders={ssoIdentityProvidersForEmailKnoxer}
                    allPhoneKnoxerSSOProviders={ssoIdentityProvidersForPhoneKnoxer}
                  />
                  <RemoveButton
                    id={`btn-create-user-double-sso-remove-${index}`}
                    onClick={remove}
                    appearance='text'
                    colorScheme='red'
                    cornerType='circle'
                  >
                    -
                  </RemoveButton>
                </SSOIdentityInputLine>
              );
            }}
          />
          <ActionsList>
            <SubmitButton id='btn-create-user-submit' onClick={handleOk} loading={savingInProgress}>
              CREATE
            </SubmitButton>
          </ActionsList>
        </FormCard>
      </StyledBackeeForm>
    </PageWithHeader>
  );
});

export default Form.create<Props>()(CreateUserPage);

const createFieldDecorators = (): FormFieldDecorators<CreateUserFormFields> => ({
  name: {
    rules: [
      {
        required: true,
        transform: trim,
        message: REQUIRED_FORM_MESSAGE,
      },
      {
        max: USER_FIELD_MAX_LENGTH.name,
        message: `Max ${USER_FIELD_MAX_LENGTH.name} characters`,
      },
    ],
  },
  emailAndPhoneIdentities: {
    rules: [
      {
        type: 'object',
      },
    ],
  },
  ssoAndPhoneIdentities: {
    rules: [
      {
        type: 'object',
      },
    ],
  },
  doubleSSOIdentities: {
    rules: [
      {
        type: 'object',
      },
    ],
  },
});

const FormCard = styled(Card)`
  padding: 24px 32px;
  min-width: 400px;
`;

const SSOIdentityInputLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;

const FullWidthEmailAndPhoneFlowInput = styled(EmailAndPhoneFlowInput)`
  flex: 1;
`;

const FullWidthSSOAndPhoneFlowInput = styled(SSOAndPhoneFlowInput)`
  flex: 1;
`;

const FullWidthDoubleSSOFlowInput = styled(DoubleSSOFlowInput)`
  flex: 1;
`;

const RemoveButton = styled(Button)`
  line-height: 1;
  margin-top: 5px;
  padding: 0 11px 4px;
  font-size: 40px;
`;

export const SmallSeparator = styled.hr`
  width: 64px;
  background-color: var(--primary-200);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0;
`;

const UserCard = styled(InnerCard)`
  margin-top: 10px;
`;
