import React, { useContext } from 'react';
import { SelectedTab, Tab } from '@mortee/components/ProgressTabs/data';

interface ProgressTabsContextProps {
  id: string;
  dataTestId: string;
  options: Tab<any>[];
  selectedTab: SelectedTab<any>;
  accessibilityLabel: string;
}

export const ProgressTabsProviderContext = React.createContext<ProgressTabsContextProps | null>(null);

export function useProgressTabsContext(): ProgressTabsContextProps {
  const context = useContext(ProgressTabsProviderContext);

  if (!context) {
    throw new Error('ProgressTabsList needs to be placed inside Index');
  }
  return context;
}
