import React, { FunctionComponent, ReactNode, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import VerifyAccountFormCard from '@mortee/routes/accountVerification/verifyAccount/VerifyAccountFormCard';
import VerifyResultCard from '@mortee/routes/accountVerification/verifyAccount/VerifyResultCard';
import { BlockHeader } from '@app/components/DisplayFields';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import { VerifyAccountFormFields } from '@app/components/accountVerification/SearchAccount';

interface ValidateAccountSectionProps {
  className?: string;
}

const VerifyAccountSection: FunctionComponent<ValidateAccountSectionProps> = observer((props) => {
  const { payeeSearchStore } = useAppStores<MorteeMode>();

  const validateAccountSectionRef = useRef({
    accountFormValues: null as null | VerifyAccountFormFields,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const renderBeforeVerification = (): ReactNode => {
    const { selectedPayeeFromSearch } = payeeSearchStore;

    return (
      <div>
        <BlockHeader small>2. Enter bank account details:</BlockHeader>
        <VerifyAccountFormCard
          isFormDisabled={!selectedPayeeFromSearch}
          payeeIdentifier={selectedPayeeFromSearch?.ids ?? undefined}
          payeeCountryCode={selectedPayeeFromSearch?.countryCode}
          updateFormValues={(values: VerifyAccountFormFields): VerifyAccountFormFields =>
            (validateAccountSectionRef.current.accountFormValues = values)
          }
          initialFormValues={validateAccountSectionRef.current.accountFormValues}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      </div>
    );
  };

  const renderAfterVerification = (): ReactNode => {
    const { selectedPayeeFromSearch, verificationDetails, isAfterVerification, isVerificationError } = payeeSearchStore;

    if (!(selectedPayeeFromSearch && isAfterVerification && verificationDetails && !isVerificationError)) {
      return <div />;
    }

    return (
      <>
        <BlockHeader>Verification Result</BlockHeader>
        <VerifyResultCard verificationDetails={verificationDetails.result} />
      </>
    );
  };

  const verified =
    payeeSearchStore.isAfterVerification && payeeSearchStore.verificationDetails && !payeeSearchStore.isVerificationError;

  return <div className={props.className}>{verified ? renderAfterVerification() : renderBeforeVerification()}</div>;
});

export default VerifyAccountSection;
