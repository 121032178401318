import React, { FC } from 'react';
import { isTextPresent } from '@app/utils/reactComponentUtils';

const AutoHideEmptyContent: FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({ children }) => {
  const isVisible = isTextPresent(children);

  return isVisible ? <>{children}</> : null;
};

export default AutoHideEmptyContent;
