import React, { FunctionComponent, ReactNode, useState } from 'react';
import PageWithHeader from '@app/components/PageWithHeader';
import useLoadable from '@app/hooks/loadable/useLoadable';
import {
  deleteVerificationReminder,
  getAllPendingValidationReminders,
  getAmountOfValidatedPayeesOwnedByMyOrganization,
  getValidationLogsForPayeesInMyOrganization,
  getVerificationLogStatisticsData,
  transformIPSVerificationLog,
  transformIPSVerificationReminder,
} from '@mortee/services/ipsServices';
import styled from '@emotion/styled';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import CustomerKitButton from '@mortee/routes/ips/customerKit/CustomerKitButton';
import StatisticsBankAccountIcon from '@mortee/images/ips/statistics-bank-account.svg';
import StatisticsMonthValidLogsIcon from '@mortee/images/ips/statistics-month-valid-logs.svg';
import StatisticsMonthValidLogsPercentIcon from '@mortee/images/ips/statistics-month-valid-logs-percent.svg';
import StatisticCard from '@mortee/routes/ips/overviewPage/StatisticCard';
import MorteeMode from '@mortee/morteeMode';
import { getEpochOfMonthBeginning } from '@app/utils/timeUtils';
import { IPSVerificationReminder, VerificationLogStatisticsData } from '@mortee/domain/ips';
import IPSVerificationLogsTable from '@mortee/routes/ips/logTable/IPSVerificationLogsTable';
import useInfraStores from '@app/hooks/useInfraStores';
import Tabs, { TabPane } from '@app/components/Tabs';
import Button from '@app/components/Button';
import { SubtitleSmallStartPrimary500, SubtitleSmallStartTransparentGrey800 } from '@app/components/Text';
import CardHeader from '@app/components/card/CardHeader';
import { pluralize } from '@app/utils/stringUtils';
import { showCustomModalAsync } from '@app/components/Modal';
import CreateVerificationReminderModal from '@mortee/routes/ips/createReminderModal/CreateVerificationReminderModal';
import IPSVerificationRemindersTable from '@mortee/routes/ips/verificationRemindersTable/IPSVerificationRemindersTable';
import usePersistentFunction from '@app/hooks/usePersistentFunction';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

enum OverviewPageTabs {
  verificationReminders = 'verificationReminders',
  recentVerifications = 'recentVerifications',
}

const OverviewPage: FunctionComponent = () => {
  const { navigationStore, permissionsStore } = useInfraStores<MorteeMode>();

  const modalContext = useModalContext();

  const [currentTab, setCurrentTab] = useState<OverviewPageTabs>(
    permissionsStore.isIPSManageVerificationRemindersEnable
      ? OverviewPageTabs.verificationReminders
      : OverviewPageTabs.recentVerifications,
  );

  const [verificationLogsStatistics] = useLoadable<VerificationLogStatisticsData>(async () => {
    const epochOfMonthBeginning = getEpochOfMonthBeginning();
    return await getVerificationLogStatisticsData(epochOfMonthBeginning);
  }, []);

  const [amountOfBankAccountsStatistics] = useLoadable(async () => {
    if (!permissionsStore.isIPSViewMyAccountsEnable) {
      return 0;
    }

    return await getAmountOfValidatedPayeesOwnedByMyOrganization();
  }, []);

  const [verificationReminders, reloadVerificationReminders] = useLoadable(async () => {
    if (!permissionsStore.isIPSManageVerificationRemindersEnable) {
      return [];
    }

    const verificationReminderServerResponses = await getAllPendingValidationReminders();

    return verificationReminderServerResponses.map(transformIPSVerificationReminder);
  }, []);

  const [verificationLogs] = useLoadable(async () => {
    const verificationLogs = await getValidationLogsForPayeesInMyOrganization();
    return verificationLogs.map(transformIPSVerificationLog);
  }, []);

  const renderPageHeader = (): ReactNode => {
    return (
      <PageHeader
        sideContent={
          <PageHeaderCenteredSideContent>
            <CustomerKitButton />
          </PageHeaderCenteredSideContent>
        }
      />
    );
  };

  const renderTableHeader = (): ReactNode => {
    return verificationLogs.resolve(
      (logs): ReactNode => {
        const amountOfValidatedLogs = logs.filter((log) => log.validated).length;
        return (
          <CardHeader>
            <SubtitleSmallStartTransparentGrey800.div>
              <StrongNumber data-testid='total-verifications-number-ips'>{logs.length}</StrongNumber>
              {pluralize('VERIFICATION', logs.length, 'VERIFICATIONS')}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <StrongNumber data-testid='validated-verifications-number-ips'>{amountOfValidatedLogs}</StrongNumber>
              VALIDATED&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <StrongNumber data-testid='not-validated-verifications-number-ips'>
                {logs.length - amountOfValidatedLogs}
              </StrongNumber>
              NOT VALIDATED
            </SubtitleSmallStartTransparentGrey800.div>
          </CardHeader>
        );
      },
      (): ReactNode => {
        return 'Loading...';
      },
    );
  };

  const showCreateVerificationReminderFormModal = async (): Promise<void> => {
    const isReminderCreated = await showCustomModalAsync(
      (onSuccess, onCancel) => (
        <ModalAppContext {...modalContext}>
          <CreateVerificationReminderModal onSuccess={onSuccess} onCancel={onCancel} />
        </ModalAppContext>
      ),
      {
        maskClosable: false,
      },
    );

    if (isReminderCreated) {
      setCurrentTab(OverviewPageTabs.verificationReminders);
      await reloadVerificationReminders();
    }
  };

  const handleDeleteVerificationReminder = usePersistentFunction(
    async (reminder: IPSVerificationReminder): Promise<void> => {
      const deletedVerificationReminderId = await deleteVerificationReminder(reminder.id);

      if (deletedVerificationReminderId) {
        await reloadVerificationReminders();
      }
    },
  );

  const renderAddVerificationReminderButton = (): ReactNode => {
    if (!permissionsStore.isIPSManageVerificationRemindersEnable || !permissionsStore.isIPSViewMyAccountsEnable) {
      return null;
    }

    return (
      <NewReminderButton
        id='ips-overview-add-verification-reminder'
        colorScheme='secondary'
        appearance='text'
        onClick={showCreateVerificationReminderFormModal}
      >
        + NEW VERIFICATION REMINDER
      </NewReminderButton>
    );
  };

  return (
    <PageWithHeader width='full' header={renderPageHeader()}>
      <StatisticsGrid>
        {permissionsStore.isIPSViewMyAccountsEnable && (
          <StatisticCard
            icon={StatisticsBankAccountIcon}
            title='MY VERIFIED BANK ACCOUNT'
            value={amountOfBankAccountsStatistics}
            actionLink={{
              text: 'EXPLORE >',
              to: navigationStore.generateMyBankAccountsLink(),
            }}
            id='card-ips-overview-statistics-bank-accounts'
          />
        )}
        <StatisticCard
          icon={StatisticsMonthValidLogsIcon}
          title='VERIFICATIONS THIS MONTH'
          value={verificationLogsStatistics.map((statistics) => statistics.periodicTotalVerifications)}
          id='card-ips-overview-statistics-month-valid-logs'
        />
        <StatisticCard
          icon={StatisticsMonthValidLogsPercentIcon}
          title='VALIDATED VERIFICATIONS THIS MONTH'
          value={verificationLogsStatistics.map((statistics) => `${statistics.periodicValidVerificationsPercentage}%`)}
          id='card-ips-overview-statistics-month-valid-logs-percentage'
        />
      </StatisticsGrid>
      <Tabs
        tabPosition='left'
        hideTabBarLine
        activeKey={currentTab}
        onChange={(newActiveKey: OverviewPageTabs): void => setCurrentTab(newActiveKey)}
        tabBarExtraContent={renderAddVerificationReminderButton()}
      >
        {permissionsStore.isIPSManageVerificationRemindersEnable && (
          <PaddedTabPane
            tab={<span data-testid='ips-overview-pending-tab'>Pending verifications</span>}
            key={OverviewPageTabs.verificationReminders}
          >
            <IPSVerificationRemindersTable
              verificationReminders={verificationReminders}
              createNewVerificationReminder={showCreateVerificationReminderFormModal}
              deleteVerificationReminder={handleDeleteVerificationReminder}
            />
          </PaddedTabPane>
        )}
        <PaddedTabPane
          tab={<span data-testid='ips-overview-recent-tab'>Recent verifications</span>}
          key={OverviewPageTabs.recentVerifications}
        >
          <IPSVerificationLogsTable header={renderTableHeader()} verificationLogs={verificationLogs} allowInteraction />
        </PaddedTabPane>
      </Tabs>
    </PageWithHeader>
  );
};
export default OverviewPage;

const StatisticsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  margin-bottom: 24px;
`;

const StrongNumber = styled(SubtitleSmallStartPrimary500.span)`
  margin-right: 5px;
`;

const PaddedTabPane = styled(TabPane)`
  padding: 2px;
`;

const NewReminderButton = styled(Button)`
  align-self: flex-end;
`;
