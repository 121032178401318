import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BodyRegularStartWhite1000 } from './Text';
import { Link, LinkProps } from 'react-router-dom';
import SelectableBulletedItem, { SelectableBulletedItemProps } from './SelectableBulletedItem';

interface Props extends Omit<SelectableBulletedItemProps, 'onSelection'> {
  to: LinkProps['to'];
}

const LinkSelectableBulletedItem: FC<Props> = ({ to, ...innerProps }) => {
  return (
    <StyledLink to={to}>
      <SelectableBulletedItem {...innerProps} onSelection={(): void => {}} />
    </StyledLink>
  );
};

export default LinkSelectableBulletedItem;

const StyledLink = styled(Link)`
  ${BodyRegularStartWhite1000.css}

  &:hover {
    ${BodyRegularStartWhite1000.css}
  }

  &:active {
    ${BodyRegularStartWhite1000.css}
  }
`;
