import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import EmptyListIcon from '@app/images/big-empty-list.svg';
import NotFoundIcon from '@app/images/big-not-found.svg';
import SVG from '@app/components/SVG';
import LivePrivatePayeesFiltersContext from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';

interface Props {
  hasItems: boolean;
}

const PaginatedPrivateAreaEndMessage: FC<Props> = ({ hasItems }: Props) => {
  const { filters, filtersApplied, isAnyFiltersApplied } = useContext(LivePrivatePayeesFiltersContext);

  if (filtersApplied.searchQuery && !hasItems) {
    return (
      <MainContainer>
        <BigIcon accessibilityLabel='' image={NotFoundIcon} />
        <Title>There were no results matching:</Title>
        <SecondaryTitle primary>"{filters.searchQuery}"</SecondaryTitle>
      </MainContainer>
    );
  }

  if (isAnyFiltersApplied) {
    return (
      <MainContainer>
        <Title>Looking for other vendors?</Title>
        <SecondaryTitle>Change the filtering criteria.</SecondaryTitle>
      </MainContainer>
    );
  }

  if (hasItems) {
    return null;
  }

  return (
    <MainContainer>
      <BigIcon accessibilityLabel='' image={EmptyListIcon} />
      <Title>No vendors yet...</Title>
      <SecondaryTitle>Contact your admin for more information.</SecondaryTitle>
    </MainContainer>
  );
};

export default PaginatedPrivateAreaEndMessage;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.67px;
  text-align: center;
  color: var(--black-strong);
`;

const SecondaryTitle = styled.div<{ primary?: boolean }>`
  font-size: 15px;
  letter-spacing: 0.42px;
  text-align: center;
  color: ${(p): string => (p.primary ? 'var(--primary-500)' : 'var(--black-weak)')};

  margin-top: 4px;
`;

const BigIcon = styled(SVG)`
  width: 180px;
  height: 180px;
  margin-bottom: 24px;
`;
