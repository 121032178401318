import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LOGIN_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { ActionsContainer, PrimaryButton, SubTitle, Title } from '../../Styles';
import { flow } from 'mobx';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useMountEffect from '@app/hooks/useMountEffect';

interface Props extends FormComponentProps<EnterEmailFormFields> {
  knoxerId: string;
  initialEmail?: string;
  onBack: () => void;
  onSubmit: (flowId: string, email: string, codeLength: number) => void;
}

interface EnterEmailFormFields {
  email: string;
}

const ForgotPasswordEnterEmail: FunctionComponent<Props> = observer((props) => {
  const fieldDecorators: FormFieldDecorators<EnterEmailFormFields> = {
    email: {
      initialValue: props.initialEmail,
      rules: [
        {
          required: true,
          message: 'Please enter a valid email',
        },
        {
          max: LOGIN_FIELD_MAX_LENGTH.username,
          message: `Max ${LOGIN_FIELD_MAX_LENGTH.username} characters`,
        },
        {
          type: 'email',
          message: 'Invalid email address',
        },
      ],
    },
  };

  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const startForgotPasswordProcess = flow(function* (email: string) {
    const { knoxerId, onSubmit } = props;

    const forgotPasswordFlowRequest = yield authenticationStore.forgotPasswordEnterEmail(knoxerId, email);
    onSubmit(forgotPasswordFlowRequest.flowId, email, forgotPasswordFlowRequest.codeLength);
  });

  useMountEffect(() => {
    const { form } = props;
    const { validateFields } = form;

    validateFields();
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const isFormInvalid = (): boolean => {
    const { form } = props;
    const { getFieldsError } = form;

    const fieldsError = getFieldsError();

    const fields = Object.keys(fieldsError);
    return fields.length === 0 || fields.some((field) => fieldsError[field]);
  };

  const handleOk = async (): Promise<void> => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    if (authenticationStore.loading) {
      return;
    }

    validateFieldsAndScroll((errors: Object, values: EnterEmailFormFields) => {
      if (errors) {
        return;
      }

      const { email } = values;

      startForgotPasswordProcess(email.trim());
    });
  };

  const { form, onBack } = props;

  const hasFieldErrors = isFormInvalid();

  return (
    <ContentContainer>
      <Title>Forgot Password</Title>
      <LoginSubTitle>To reset your password, enter the email address you use to sign in</LoginSubTitle>
      <EnterEmailForm onSubmit={handleFormSubmit}>
        <FormItemBox appearance='line' form={form} fieldName='email' fieldDecoratorOptions={fieldDecorators.email}>
          <NakedFormInput
            name='inpt-auth-forgot-enter-email'
            type='text'
            autoFocus
            colorScheme='secondary'
            placeholder='Email'
            disableSuggestion
          />
        </FormItemBox>
        <ActionsContainer>
          <Button id='btn-auth-forgot-enter-email-back' appearance='text' onClick={onBack} size='small'>
            BACK
          </Button>
          <PrimaryButton id='btn-auth-forgot-enter-email-continue' onClick={handleOk} disabled={hasFieldErrors}>
            CONTINUE
          </PrimaryButton>
        </ActionsContainer>
      </EnterEmailForm>
    </ContentContainer>
  );
});

export default Form.create<Props>()(ForgotPasswordEnterEmail);

const ContentContainer = styled.div`
  padding: 48px 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const LoginSubTitle = styled(SubTitle)`
  margin-bottom: 15px;
`;

const EnterEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;
