import React, { FunctionComponent, ReactNode, useRef } from 'react';
import styled from '@emotion/styled';
import WarningIcon from '@app/images/ic-warning-white.svg';
import SVG from '@app/components/SVG';
import { ModalButton } from './Button';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import { nanoid } from 'nanoid';

interface ErrorModalProps {
  headerContent: ReactNode;
  bodyContent: ReactNode;
  okButtonText: string;
  onDone: () => void;
  className?: string;
  isWarning?: boolean;
  hideOkButton?: boolean;
}

const ErrorModal: FunctionComponent<ErrorModalProps> = (props) => {
  const { headerContent, bodyContent, okButtonText, onDone, className, isWarning, hideOkButton } = props;

  const randomIdRef = useRef(nanoid());
  const messageId = randomIdRef.current;

  const titleId = `alert-title-${messageId}`;
  const contentId = `alert-description-${messageId}`;

  return (
    <MainContainer id={messageId} className={className} role='alertdialog' aria-labelledby={titleId} aria-describedby={contentId}>
      <Header isWarning={isWarning}>
        <StyledBadMatchIcon accessibilityLabel='' image={WarningIcon} />
        <div id={titleId}>{headerContent}</div>
      </Header>
      <Content>
        <Text id={contentId}>{bodyContent}</Text>
        {!hideOkButton && (
          <OKButton id='modal-ok' appearance='outline' colorScheme='primary' onClick={onDone}>
            {okButtonText}
          </OKButton>
        )}
      </Content>
    </MainContainer>
  );
};

export default ErrorModal;

const MainContainer = styled.div`
  width: 400px;
  background-color: white;
`;

const Header = styled('div', { shouldForwardProp: preventForwardTheseProps('isWarning') })<{ isWarning?: boolean }>`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: white;

  padding: 24px 24px 14px;

  background-color: ${(p): string => (p.isWarning ? 'var(--warning-color)' : 'var(--alert-color)')};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Text = styled.div`
  font-size: 15px;
  line-height: 1.33;
  color: var(--transparent-black-600);
  white-space: pre-line;
  align-self: center;
`;

const StyledBadMatchIcon = styled(SVG)`
  width: 64px;
  height: 64px;
`;

const OKButton = styled(ModalButton)`
  text-transform: uppercase;
  margin-top: 32px;
`;
