import request, { HttpMethod } from '@app/libs/request';
import config from '@app/config';
import { ExportedSpreadsheetDTO } from '@app/domain/userManagement/organizations';
import { OrganizationalUserServerResponse } from '@app/domain/userManagement/organizationUsers';
import { ORGANIZATION_HEADER_NAME } from '@app/utils/userIdentityHeadersGenerator';
import { AddWebUserRoleRequest, RemoveWebUserRoleRequest } from '@app/domain/userManagement/users';

export async function getAllUsersOfOrganizationWithNsknoxUsers(
  organizationId: string,
): Promise<OrganizationalUserServerResponse[]> {
  return request<OrganizationalUserServerResponse[]>(config.serverUrls.backee, `/api/organizations/users/include-nsknox`, {
    method: HttpMethod.get,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot get users of organization.',
      },
    },
  });
}

export async function getAllUsersOfOrganization(organizationId: string): Promise<OrganizationalUserServerResponse[]> {
  return request<OrganizationalUserServerResponse[]>(config.serverUrls.backee, `/api/organizations/users`, {
    method: HttpMethod.get,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot get users of organization.',
      },
    },
  });
}

export async function addUserToOrganization(
  organizationId: string,
  tenantUserId: string,
): Promise<OrganizationalUserServerResponse> {
  return request<OrganizationalUserServerResponse>(config.serverUrls.backee, `/api/organizations/users`, {
    data: {
      tenantUserId,
    },
    method: HttpMethod.post,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot add user to organization.',
      },
    },
  });
}

export async function exportOrganisationUserRoles(organizationId: string): Promise<ExportedSpreadsheetDTO> {
  return request<ExportedSpreadsheetDTO>(config.serverUrls.backee, `/api/organizations/users/export`, {
    method: HttpMethod.get,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot download file',
      },
    },
  });
}

export async function suspendUserFromOrganization(organizationId: string, tenantUserId: string): Promise<boolean> {
  return request<boolean>(config.serverUrls.backee, `/api/organizations/suspended-users`, {
    data: {
      tenantUserId,
    },
    method: HttpMethod.post,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot suspend user from organization.',
      },
    },
  });
}

export async function killUserSessions(tenantUserId: string): Promise<null> {
  return request<null>(config.serverUrls.backee, `/api/users/${tenantUserId}/web-sessions`, {
    method: HttpMethod.delete,
    errorsHandler: {
      default: {
        message: "We couldn't complete this action, try again later.",
      },
    },
  });
}

export async function addRoleToUser(
  organizationId: string,
  tenantUserId: string,
  data: AddWebUserRoleRequest,
): Promise<OrganizationalUserServerResponse> {
  return request<OrganizationalUserServerResponse>(config.serverUrls.backee, `/api/users/:tenantUserId/web-app-roles`, {
    data,
    method: HttpMethod.post,
    pathParams: {
      tenantUserId,
    },
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      USER_NOT_IN_ORGANIZATION_ERROR: {
        message: (): string => `The selected user in not in the organization.`,
      },
      default: {
        message: 'Cannot add role to user.',
      },
    },
  });
}

export async function removeRoleFromUser(
  organizationId: string,
  tenantUserId: string,
  data: RemoveWebUserRoleRequest,
): Promise<OrganizationalUserServerResponse> {
  return request<OrganizationalUserServerResponse>(config.serverUrls.backee, `/api/users/:tenantUserId/web-app-roles`, {
    data,
    method: HttpMethod.delete,
    pathParams: { tenantUserId },
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      USER_NOT_IN_ORGANIZATION_ERROR: {
        message: (): string => `The selected user in not in the organization.`,
      },
      default: {
        message: 'Cannot remove role from user.',
      },
    },
  });
}
