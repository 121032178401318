import React, { FC, useState } from 'react';
import { H5StartTransparentBlack900 } from '@app/components/Text';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import Button from '@app/components/Button';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';
import SuccessModal from '@app/components/SuccessModal';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';

interface Props {
  onDone: () => void;
}

const MASAV_VALIDATED_HASH =
  'D44A4F47602F44768FCC9175F7B183FDFEB0C12AA8B9EC4AC9D0D26AD3B3B4E98C6CDC11A4114F6B8D2EBFCDF0852480B9C7C3D742E18C1B26FB2A4A2D5DD3F6';

const MasavHashModal: FC<Props> = (props) => {
  const { onDone } = props;

  const modalContext = useModalContext();

  const calculateMasavHash = (masavHashToValidate: string): void => {
    if (masavHashToValidate === MASAV_VALIDATED_HASH) {
      showContentOnlyModal((onDone) => (
        <ModalAppContext {...modalContext}>
          <SuccessModal
            headerContent='Masav Hash is matching'
            bodyContent='Please review the verification result'
            okButtonText='OK'
            onDone={onDone}
          />
        </ModalAppContext>
      ));
      onDone();
    } else {
      showContentOnlyModal((onDone) => (
        <ModalAppContext {...modalContext}>
          <ErrorModal
            headerContent='Masav Hash is not matching'
            bodyContent='Make sure you are pasting the right value or check if your file was compromised'
            okButtonText='OK'
            onDone={onDone}
          />
        </ModalAppContext>
      ));
      onDone();
    }
  };

  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <MainCard id='validate-hash-masav-modal'>
      <TitleLine>
        <Title>
          <H5StartTransparentBlack900.div>Enter Masav Hash For Validation</H5StartTransparentBlack900.div>
        </Title>
        <CloseButton id='validate-hash-masav-modal-close' appearance='text' onClick={onDone}>
          <SVG image={ClearIcon} height={30} accessibilityLabel={''} />
        </CloseButton>
      </TitleLine>
      <InputBox appearance='line'>
        <NakedFormInput
          name='validate-hash-masav-search'
          dataTestId='validate-hash-masav-search'
          type='text'
          colorScheme='primary'
          placeholderStyle='onlyWhenEmpty'
          heightType='thick'
          clearable
          placeholder='🔍 Masav Hash'
          value={searchTerm}
          onChange={(newValue): void => setSearchTerm(newValue ?? '')}
          autoFocus
        />
      </InputBox>
      <ConfirmButton disabled={!searchTerm} id='validate-hash-masav-confirm' onClick={(): void => calculateMasavHash(searchTerm)}>
        VALIDATE
      </ConfirmButton>
    </MainCard>
  );
};

export default MasavHashModal;

const MainCard = styled(Card)`
  width: min(500px, 50vw);
  min-height: 200px;
  max-height: 50vh;

  padding: 26px 32px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;

  margin-bottom: 4px;
`;

const Title = styled.div`
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const ConfirmButton = styled(Button)`
  margin-top: 40px;
`;
