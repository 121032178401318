import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import PayeeDetails from './PayeeDetails';
import { BufferLine, InfoCategory, InfoContent } from '@app/components/DisplayFields';
import Button from '@app/components/Button';
import { getListWithLeading } from '@app/utils/arrayUtils';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import { MorteeVerificationResult } from '@mortee/domain/morteeAccountVerification';

interface PayeeDetailsMoreInfoProps {
  payee: UnifiedPayee;
  validationDetails: MorteeVerificationResult | null;
  onReturn: () => void;
  children?: ReactNode;
  dataTestId?: string;
  className?: string;
}

const PayeeDetailsMoreInfo: FunctionComponent<PayeeDetailsMoreInfoProps> = ({
  className,
  dataTestId,
  payee,
  validationDetails,
  onReturn,
}) => {
  const reformatInfoValue = (value: string[] | string | undefined | null): string => {
    if (!value?.length) {
      return 'Not provided';
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  };

  const renderInfoLine = (name: string, value: string[] | string | undefined | null, testKey: string = name): ReactNode => {
    return (
      <InfoLine>
        <StyledInfoCategory>{name}:</StyledInfoCategory>
        <InfoContent data-testid={`txt${testKey}`}>{reformatInfoValue(value)}</InfoContent>
      </InfoLine>
    );
  };

  if (!payee) {
    return <div />;
  }

  const { addresses, emails, phones, faxes, websites, description, primaryFields } = payee;

  return (
    <MainContainer className={className} data-testid={dataTestId}>
      <PayeeDetails payee={payee} verificationDetails={validationDetails ?? undefined} fullDetails={false} />
      <BufferLine />
      <LeftContainer>
        {renderInfoLine('Addresses', getListWithLeading(addresses, primaryFields?.address))}
        {renderInfoLine('Emails', emails)}
        {renderInfoLine('Phone Numbers', phones, 'Phones')}
        {renderInfoLine('Fax Numbers', faxes, 'Faxes')}
        {renderInfoLine('Websites', websites)}
        {renderInfoLine('Description', description)}
        <ReturnButton id='btn-more-info-return' dataTestId='infoRtrnBtn' onClick={onReturn}>
          RETURN
        </ReturnButton>
      </LeftContainer>
    </MainContainer>
  );
};

export default PayeeDetailsMoreInfo;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 32px;
  width: 950px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: baseline;
`;

const StyledInfoCategory = styled(InfoCategory)`
  width: 123px;
`;

const ReturnButton = styled(Button)`
  margin-top: 15px;
`;
