import Card, { CardProps } from '@app/components/card/Card';
import styled from '@emotion/styled';

import React, { FC, ReactNode } from 'react';
import Button from '@app/components/Button';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/close-thick.svg';
import { KeyName } from '@app/domain/uiConsts';

interface Props extends Omit<CardProps, 'title'> {
  title?: ReactNode;
  disableCloseByEscKey?: boolean;
  closeFunc(): void;
  titleLineClassName?: string;
}

const BigModal: FC<Props> = ({ title, closeFunc, children, disableCloseByEscKey, titleLineClassName, onKeyUp, ...cardProps }) => {
  function handleOnKeyUp(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (!disableCloseByEscKey && event.key === KeyName.Escape) {
      event.stopPropagation();
      event.preventDefault();
      closeFunc();
    } else {
      onKeyUp?.(event);
    }
  }

  // Sometimes the scroll starts at the bottom, this is a workaround to fix it
  function scrollToTopWorkaround(cardRef: HTMLDivElement | null): void {
    return cardRef?.scrollTo?.(0, 0);
  }

  return (
    <BigModalCard {...cardProps} onKeyUp={handleOnKeyUp} ref={scrollToTopWorkaround}>
      <TitleLine className={titleLineClassName}>
        <TitleContent>{title}</TitleContent>
        <CloseButton id='btn-modal-close' appearance='text' colorScheme='primary' onClick={closeFunc}>
          <SVG accessibilityLabel='close' image={ClearIcon} height={24} />
        </CloseButton>
      </TitleLine>
      {children}
    </BigModalCard>
  );
};

export default BigModal;

const BigModalCard = styled(Card)`
  padding-top: 0;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: var(--block-padding, 16px) var(--side-padding, 32px);
`;

const TitleContent = styled.div`
  flex: 1;
  display: flex;
`;

const CloseButton = styled(Button)`
  flex: 0 0 auto;
  align-self: start;

  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;
