import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { LocationDescriptor } from 'history';
import Loadable from '@app/utils/Loadable';
import SVG from '@app/components/SVG';
import { Bold38TitleTransparentBlack900, SubtitleSmallStartTransparentGrey800 } from '@app/components/Text';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import LinkButton from '@app/components/LinkButton';

interface Props {
  icon: SpriteSymbol;
  title: ReactNode;
  value: Loadable<number | string>;
  actionLink?: {
    text: string;
    to: LocationDescriptor;
  };
  id: string;
  dataTestId?: string;
  className?: string;
}

const StatisticCard: FC<Props> = (props) => {
  const { icon, title, value, actionLink, id, dataTestId = id, className } = props;

  return value.resolve(
    (loadedValue) => (
      <MainCard className={className} id={id} dataTestId={dataTestId}>
        <SVG accessibilityLabel='' image={icon} width={72} height={72} />
        <Content>
          <StyledCardHeader>{title}</StyledCardHeader>
          <ValueAndAction>
            <Bold38TitleTransparentBlack900.div>{loadedValue}</Bold38TitleTransparentBlack900.div>
            {actionLink && (
              <LinkButton
                to={actionLink.to}
                appearance='text'
                id={`btn-${id}-statistics-action-link`}
                dataTestId={`btn-${dataTestId}-statistics-action-link`}
              >
                {actionLink.text}
              </LinkButton>
            )}
          </ValueAndAction>
        </Content>
      </MainCard>
    ),
    () => (
      <MainCard className={className} id={id} dataTestId={dataTestId}>
        <ReactPlaceholder type='round' ready={false} children='' style={{ width: 72, height: 72 }} />
        <Content>
          <StyledCardHeader>
            <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: '80%', margin: '0.15em' }} />
          </StyledCardHeader>
          <ValueAndAction>
            <Bold38TitleTransparentBlack900.div>
              <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 70, margin: '0.15em' }} />
            </Bold38TitleTransparentBlack900.div>
          </ValueAndAction>
        </Content>
      </MainCard>
    ),
  );
};

export default StatisticCard;

const MainCard = styled(Card)`
  padding: 28px 32px;
  display: flex;
  align-items: center;
`;

const StyledCardHeader = styled(SubtitleSmallStartTransparentGrey800.div)`
  margin-bottom: 10px;
`;

const Content = styled.div`
  flex: 1;
  padding-left: 32px;
`;

const ValueAndAction = styled.div`
  display: flex;
  align-items: flex-end;
`;
