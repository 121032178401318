import React from 'react';
import { FormInputChangeProps } from '@app/utils/form/form';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import SupplierRegistrationCard from '@mortee/routes/validationSystem/createValidationRecord/supplierRegistrationSelector/SupplierRegistrationCard';
import { ForwardingFC } from '@app/domain/technicals/components';
import styled from '@emotion/styled';
import SupplierRegistrationSelectorSearch from '@mortee/routes/validationSystem/createValidationRecord/supplierRegistrationSelector/SupplierRegistrationSelectorSearch';

interface Props extends FormInputChangeProps<SupplierRegistrationProcess> {
  id: string;
  currentOrganizationId: string | undefined;
  dataTestId?: string;
  className?: string;
  isOriginatedFromRegistrationForm: boolean;
  scrollableTargetId: string;
}

const SupplierRegistrationSelector: ForwardingFC<HTMLDivElement, Props> = React.forwardRef(
  (
    {
      id,
      dataTestId = id,
      value,
      onChange,
      currentOrganizationId,
      className,
      isOriginatedFromRegistrationForm,
      scrollableTargetId,
    },
    outerRef,
  ) => {
    return (
      <Container className={className} id={id} data-testid={dataTestId} ref={outerRef}>
        {value && (
          <SupplierRegistrationCard
            item={value}
            link={
              !isOriginatedFromRegistrationForm && {
                isLinked: true,
                onLinkChanged: (): void => onChange?.(undefined),
              }
            }
          />
        )}
        {!value && (
          <SupplierRegistrationSelectorSearch
            onLink={(selected): void => {
              onChange?.(selected);
            }}
            isOriginatedFromRegistrationForm={isOriginatedFromRegistrationForm}
            currentOrganizationId={currentOrganizationId}
            scrollableTargetId={scrollableTargetId}
          />
        )}
      </Container>
    );
  },
);

export default SupplierRegistrationSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
