import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  BlueWarningIcon,
  BoldText,
  HighlightedInstructionsAdditional,
  HighlightedInstructionsCard,
  HighlightedInstructionsContainer,
  HighlightedInstructionsIcon,
  HighlightedInstructionsTitle,
  NormalText,
  InstructionsCard,
  PaymentInstructionsLineHr,
  InstructionsTitleCard,
} from '../Styles';
import CurrencyChips from './CurrencyChips';
import { InstructionFormDetails } from './InstructionFormDetails';
import { WarningTitle, WireTransferWarningBox } from '../supplierRegistrationProcess/AfterFormPage';
import styled from '@emotion/styled';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useTheme from '@app/hooks/useTheme';
import SVG from '@app/components/SVG';
import EFTWarningImage from '@supplierRegistration/images/warning-white-orange.svg';

interface Props {
  instructionDetails: InstructionFormDetails;
}

const TransferInstructionForm: FC<Props> = observer(({ instructionDetails }) => {
  const { t } = useTranslation();

  const getInstructionLines = (line: { description: string; value: string }[]): ReactNode => {
    return line.map((line) => (
      <React.Fragment key={line.description}>
        <PaymentInstructionsLine withMarginTop={false}>
          <BoldText>{t(line.description)}</BoldText>
          <NormalText>{t(line.value)}</NormalText>
        </PaymentInstructionsLine>
        <PaymentInstructionsLineHr />
      </React.Fragment>
    ));
  };

  const getExtraInstructions = (extraInstructions): ReactNode => {
    return extraInstructions.map((instruction, index) => (
      <ExtraInstructions key={instruction}>
        {index + 1}. {t(instruction)}
      </ExtraInstructions>
    ));
  };

  const { headerColor, isHeaderDark } = useTheme<SupplierRegistrationMode>();

  const renderHighlightedInstructions = (): ReactNode => {
    const highlightedInstructions = instructionDetails.highlightedInstructions;

    if (!highlightedInstructions) {
      return null;
    }

    return (
      <HighlightedInstructionsCard customBackgroundColor='#f5f6fa'>
        <HighlightedInstructionsIcon>
          <BlueWarningIcon />
        </HighlightedInstructionsIcon>
        <HighlightedInstructionsContainer>
          {highlightedInstructions.title && (
            <HighlightedInstructionsTitle>{t(highlightedInstructions.title)}</HighlightedInstructionsTitle>
          )}
          {highlightedInstructions.additionalItems?.map((item, index) => (
            <HighlightedInstructionsAdditional key={`highlighted-inst-${index}`}>{t(item)}</HighlightedInstructionsAdditional>
          ))}
        </HighlightedInstructionsContainer>
      </HighlightedInstructionsCard>
    );
  };

  return (
    <div>
      <InstructionsSubTitle>
        {t(instructionDetails.instructionsSubTitle)}
        {(instructionDetails?.extraInstructions?.length ?? 0) > 0 && getExtraInstructions(instructionDetails.extraInstructions)}
      </InstructionsSubTitle>
      {instructionDetails.specialComponent ? (
        instructionDetails.specialComponent
      ) : (
        <React.Fragment>
          {renderHighlightedInstructions()}
          {instructionDetails?.currencies.length > 0 && <CurrencyChips supportedCurrencies={instructionDetails.currencies} />}
          <InstructionsTitleCard isHeaderDark={isHeaderDark} customBackgroundColor={isHeaderDark ? headerColor.main : 'black'}>
            {t(instructionDetails.paymentInstructionsTitle)}
          </InstructionsTitleCard>
          <InstructionsCard customBackgroundColor='#f5f6fa' noPadding>
            {instructionDetails.showWireTransferWarningBox.achWireTransfer && (
              <WireTransferWarningBox>
                <WarningTitle>{t('supplierValidation.afterRegister.doNotUseACH')}</WarningTitle>
                {t('supplierValidation.afterRegister.onlySwift')}
              </WireTransferWarningBox>
            )}
            {instructionDetails.showWireTransferWarningBox.swiftWireTransfer && (
              <WireTransferWarningBox>
                <EFTWarningBox>
                  <EFTWarningSVG accessibilityLabel={t('general.accessibility.warning')} image={EFTWarningImage} height={30} />
                  <EFTWarningText>
                    <strong>{t('supplierValidation.afterRegister.onlyEft')}</strong>
                    <EFTWarningSubtext>
                      <Trans i18nKey='supplierValidation.afterRegister.doNotSendWires'>
                        <strong>Do not send Wires,</strong> interac/e-transfer payments- they will be rejected.
                      </Trans>
                    </EFTWarningSubtext>
                  </EFTWarningText>
                </EFTWarningBox>
              </WireTransferWarningBox>
            )}
            <AccountDetailsDiv>
              {getInstructionLines(instructionDetails.paymentInstructionsLines)}
              <PaymentInstructionsLine>
                <BoldText>{t(instructionDetails.paymentInstructionsAddress.description)}</BoldText>
                {instructionDetails.paymentInstructionsAddress.value}
              </PaymentInstructionsLine>
            </AccountDetailsDiv>
          </InstructionsCard>
        </React.Fragment>
      )}
    </div>
  );
});

export default TransferInstructionForm;

const InstructionsSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--black-weak);
  margin-bottom: 20px;
`;

const ExtraInstructions = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: #09215e;

  &:first-child {
    margin-top: 10px;
  }
`;

const EFTWarningBox = styled.div`
  background-color: #ff9500;
  border-radius: 50px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
`;

const EFTWarningText = styled.div`
  color: #eefcff;
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const EFTWarningSubtext = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const EFTWarningSVG = styled(SVG)``;

const AccountDetailsDiv = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const PaymentInstructionsLine = styled.div<{ withMarginTop?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &:first-child {
    margin-top: ${(p): number => (p.withMarginTop ? 8 : 0)}px;
  }

  & > div {
    flex: 1;
  }
`;
