import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import CheckEmptyResults from '@mortee/components/check/CheckEmptyResults';
import browserHistory from '@app/utils/browserHistory';
import addVerificationIcon from '@mortee/images/check/homePage/add-verification.svg';
import SVG from '@app/components/SVG';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import SecureFilesTable from '@mortee/routes/secureFiles/SecureFilesTable';
import useLoadable from '@app/hooks/loadable/useLoadable';
import * as secureFilesServices from '@mortee/services/secureFilesServices';
import { fetchSecureFileById, transformSecureFiles } from '@mortee/services/secureFilesServices';
import LinkButton from '@app/components/LinkButton';
import { compare } from '@app/utils/comparatorUtils';

const SecureFilesListPage: FunctionComponent = observer(() => {
  const { navigationStore } = useInfraStores<MorteeMode>();

  const [fileGroup] = useLoadable(async () => {
    const data = await secureFilesServices.loadSurveysForOrganization();

    return data.map((survey) => transformSecureFiles(survey)).sort(compare.writeTimestamp().reverse());
  }, []);

  const handleNewVerification = (): void => {
    browserHistory.push(navigationStore.generateLoadSecureFilesLink());
  };

  const renderCardEmptyContent = (): ReactElement => (
    <CheckEmptyResults
      handleNewVerification={handleNewVerification}
      titleText='No files uploaded yet...'
      buttonText='UPLOAD FILES'
    />
  );

  const headerSideContent = (): ReactElement => (
    <PageHeaderCenteredSideContent>
      <NewFileButton id='btn-send-secure-files-new' to={navigationStore.generateLoadSecureFilesLink()} appearance='text'>
        <StyledPlusSvg accessibilityLabel='' image={addVerificationIcon} />
        <NewFileText>UPLOAD FILES</NewFileText>
      </NewFileButton>
    </PageHeaderCenteredSideContent>
  );

  return (
    <PageWithHeader width={1000} header={<PageHeader sideContent={headerSideContent()} />} widthReactive={false}>
      <SecureFilesTable secureFiles={fileGroup} fetchSecureFile={fetchSecureFileById} emptyComponent={renderCardEmptyContent()} />
    </PageWithHeader>
  );
});

export default SecureFilesListPage;

const StyledPlusSvg = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const NewFileButton = styled(LinkButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NewFileText = styled.div`
  margin-top: 2px;
`;
