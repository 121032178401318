import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

type ConditionalTooltipProps = TooltipProps & {
  showTooltip: boolean;
};

const ConditionalTooltip: FC<ConditionalTooltipProps> = ({ showTooltip, children, ...tooltipProps }) => {
  if (!showTooltip) {
    return <>{children}</>;
  }

  return <Tooltip {...tooltipProps}>{children}</Tooltip>;
};

export default ConditionalTooltip;
