import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/es/form/Form';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';

interface WrappedFormWithTypeUtils<TFormFields> extends Omit<WrappedFormUtils<TFormFields>, 'getFieldValue'> {
  getFieldValue<TField extends keyof TFormFields>(fieldName: TField): TFormFields[TField] | undefined;
}

interface Result<TFormFields extends object> {
  form: WrappedFormWithTypeUtils<TFormFields>;
  showFormErrors: FormItemsBoxProps['showErrors'];
  setShowFormErrors: Dispatch<SetStateAction<FormItemsBoxProps['showErrors']>>;
  isFormInvalid: boolean;
  validateFields(): Promise<TFormFields>;
}

interface Options<TFormFields extends object> {
  fieldsToRevalidateForm?: (string & keyof TFormFields)[];
  initialShowErrors?: FormItemsBoxProps['showErrors'];
}

export default function useForm<TFormFields extends object>(
  { form }: FormComponentProps<TFormFields>,
  options: Options<TFormFields> = {},
): Result<TFormFields> {
  const { fieldsToRevalidateForm = [], initialShowErrors = 'onTouch' } = options;

  const [showFormErrors, setShowFormErrors] = useState<FormItemsBoxProps['showErrors']>(initialShowErrors);
  const valuesToRevalidate = form.getFieldsValue(fieldsToRevalidateForm);

  useEffect(
    (): void => {
      form.validateFields();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- fieldsToRevalidateForm come from the outside and should stay consistent
    fieldsToRevalidateForm.map((field) => valuesToRevalidate[field]),
  );

  function validateFields(): Promise<TFormFields> {
    return new Promise<TFormFields>((resolve, reject) => {
      form.validateFieldsAndScroll((errors: Object, values: TFormFields) => {
        if (errors) {
          setShowFormErrors('all');
          reject(errors);
        }

        resolve(values);
      });
    });
  }

  return {
    form: form as WrappedFormWithTypeUtils<TFormFields>,
    showFormErrors,
    setShowFormErrors,
    isFormInvalid: calcIsFormInvalid(form),
    validateFields,
  };
}

const calcIsFormInvalid = (form: WrappedFormUtils): boolean => {
  const fieldsError = form.getFieldsError();

  const fields = Object.keys(fieldsError);
  return fields.length === 0 || fields.some((field) => fieldsError[field]);
};
