import { action, comparer, computed, flow, makeObservable, observable } from 'mobx';
import * as masterDataGuardServices from '@mortee/services/masterDataGuardServices';
import {
  DisplayedPayeesTotal,
  LivePrivatePayeeFilters,
  LivePrivatePayeeValidationLevel,
  MasterDataGuardSearchableData,
  MasterDataGuardSearchableDataServerResponse,
  PrivateAreaCompanyCodes,
  PrivatePayeeSearchableData,
  PrivatePayeeWithDeterministicValidations,
  SyncInfo,
  SyncInfoServerResponse,
} from '@mortee/domain/masterDataGuard';
import UserStore from '@app/stores/UserStore';
import AuthenticationStore from '@app/login/stores/AuthenticationStore';
import Loadable, { flowad, flowadWithPercentage, LoadableCreator, LoadingState } from '@app/utils/Loadable';
import Log from '@app/libs/logger';
import MorteePermissionStore from '@mortee/stores/infraStores/MorteePermissionStore';
import MvfCheckSurveyModel from '@mortee/models/MvfCheckSurveyModel';
import { FileSurveyReport, handleMvfFileUploadError, StoredMvfFileSurveyServerResponse } from '@mortee/domain/mvfCheck';
import { CancellablePromise } from 'mobx/dist/api/flow';
import * as mvfCheckServices from '@mortee/services/mvfCheckServices';
import { RequestError } from '@app/libs/request';
import { handleGeneralFileUploadError, translateFileNameToContentType } from '@app/domain/files';
import {
  PrivatePayeeAccountServerResponse,
  RemoveSelfApproveRequest,
  SelfApprovePrivatePayeeAccountRequest,
} from '@mortee/domain/privatePayeeAccount';
import { defaultFilters } from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import { compare } from '@app/utils/comparatorUtils';

export const DEFAULT_PAGE_SIZE: number = 20;
export const SEARCHABLE_TO_NOT_VALIDATED_REPORT_TIME_MULTIPLIER: number = 30;
export const SEARCHABLE_LOAD_TIME_FALLBACK_MILLIS: number = 600000; // Ten minutes

export enum DataLoadingState {
  NotLoading,
  Loading,
  ErrorWhileLoading,
}

export enum MasterDataGuardPageMode {
  myData = 'myData',
  changelog = 'changelog',
}

export const MVF_SURVEY_TABLE_PAGE_SIZE: number = 7;

export default class MasterDataGuardStore {
  private readonly _permissionsStore: MorteePermissionStore;

  @observable private _pageMode: MasterDataGuardPageMode = MasterDataGuardPageMode.myData;

  // Changelog
  @observable private _selectedSurvey: MvfCheckSurveyModel | undefined;
  @observable private _surveyChangelogReports: Map<string, FileSurveyReport[]> = observable.map();
  @observable surveyLoadingState: DataLoadingState = DataLoadingState.NotLoading;
  @observable reportLoadingState: DataLoadingState = DataLoadingState.NotLoading;
  @observable private _allSurveysByPage: Map<number, MvfCheckSurveyModel[]> = new Map<number, MvfCheckSurveyModel[]>();
  @observable private _currentSurveyPageIndex: number = 0;
  @observable totalSurveysCount: number = 0;

  // My data
  @observable private _isCurrentlyWatchingLiveData: boolean = false;
  @observable private _syncInfo: Loadable<SyncInfo> = LoadableCreator.notStarted();
  @observable private _searchableData: Loadable<MasterDataGuardSearchableData> = LoadableCreator.notStarted();
  @observable private _allNotValidatedPayeesWithPercentage: Loadable<
    Record<string, PrivatePayeeWithDeterministicValidations>
  > = LoadableCreator.notStarted();
  @observable private _searchableDataLoadTotalTimeMillis: number | null = null;

  constructor(userStore: UserStore, authenticationStore: AuthenticationStore, permissionsStore: MorteePermissionStore) {
    makeObservable(this);

    this._permissionsStore = permissionsStore;
  }

  @action
  setPageMode = (mode: MasterDataGuardPageMode): void => {
    this._pageMode = mode;
  };

  @computed
  get getPageMode(): MasterDataGuardPageMode {
    const selectedSurvey = this._selectedSurvey;
    if (selectedSurvey) {
      return MasterDataGuardPageMode.changelog;
    }

    return this._pageMode;
  }

  // region Changelog
  @computed
  get currentMvfSurveyPageIndex(): number {
    return this._currentSurveyPageIndex;
  }

  @action
  setCurrentSurveyPageIndex = (currentPageIndex: number): void => {
    if (currentPageIndex !== this._currentSurveyPageIndex) {
      this._currentSurveyPageIndex = currentPageIndex;
    }
  };

  @computed
  get surveys(): MvfCheckSurveyModel[] {
    const currentPageSurveys = this._allSurveysByPage.get(this._currentSurveyPageIndex);
    if (currentPageSurveys) {
      return Array.from(currentPageSurveys)
        .filter((survey) => survey.loaded)
        .sort(compare.writeTimestamp().reverse());
    }

    return [];
  }

  @computed
  get selectedSurvey(): MvfCheckSurveyModel | undefined {
    return this._selectedSurvey;
  }

  @computed
  get selectedChangelogReport(): FileSurveyReport[] | undefined {
    const selectedSurvey = this._selectedSurvey;

    if (!selectedSurvey) {
      return;
    }

    return this._surveyChangelogReports.get(selectedSurvey.id);
  }

  private surveyLoadingWrapper<R, Args extends unknown[]>(
    func: (...args: Args) => CancellablePromise<R>,
  ): (...args: Args) => CancellablePromise<R> {
    return flow<R, Args>(function* (this: MasterDataGuardStore, ...args: Args) {
      try {
        this.surveyLoadingState = DataLoadingState.Loading;
        const result = yield func.call(this, ...args);
        this.surveyLoadingState = DataLoadingState.NotLoading;
        return result;
      } catch (e: unknown) {
        this.surveyLoadingState = DataLoadingState.ErrorWhileLoading;
        throw e;
      }
    });
  }

  private reportLoadingWrapper<R, Args extends unknown[]>(
    func: (...args: Args) => CancellablePromise<R>,
  ): (...args: Args) => CancellablePromise<R> {
    return flow<R, Args>(function* (this: MasterDataGuardStore, ...args: Args) {
      try {
        this.reportLoadingState = DataLoadingState.Loading;
        const result = yield func.call(this, ...args);
        this.reportLoadingState = DataLoadingState.NotLoading;
        return result;
      } catch (e: unknown) {
        this.reportLoadingState = DataLoadingState.ErrorWhileLoading;
        throw e;
      }
    });
  }

  loadSurveyCheckReport = this.reportLoadingWrapper(
    flow<any, [string]>(function* (this: MasterDataGuardStore, surveyId: string) {
      try {
        const data: FileSurveyReport[] = yield mvfCheckServices.getFileSurveyReportById(surveyId);

        this._surveyChangelogReports.set(surveyId, this.sortFileSurveyReportEvents(data));
      } catch (e: unknown) {
        Log.event('error loading check report for survey', { surveyId, e, func: 'loadSurveyChangelogReport' });
      }
    }),
  );

  resetBatches = (): void => {
    this.setCurrentSurveyPageIndex(0);
    this._allSurveysByPage.clear();
  };

  loadCurrentPageSurveys = this.surveyLoadingWrapper(
    flow<any, []>(function* (this: MasterDataGuardStore) {
      try {
        if (this._allSurveysByPage.get(this._currentSurveyPageIndex)?.length) {
          return;
        }

        const pageData = yield mvfCheckServices.getSurveysByIndex(
          this._currentSurveyPageIndex * MVF_SURVEY_TABLE_PAGE_SIZE,
          MVF_SURVEY_TABLE_PAGE_SIZE,
        );
        if (!pageData) {
          return;
        }

        if (this.totalSurveysCount != pageData.totalElements) {
          this.totalSurveysCount = pageData.totalElements;
        }

        const data = pageData.content;
        this._allSurveysByPage.set(
          this._currentSurveyPageIndex,
          data.map((surveyResponse) => new MvfCheckSurveyModel(surveyResponse.id, surveyResponse)),
        );
      } catch (e: unknown) {
        Log.exception(e);
        throw e;
      }
    }),
  );

  getFileSurveyById = this.surveyLoadingWrapper(
    flow(function* (this: MasterDataGuardStore, surveyId: string) {
      try {
        const surveyResponse: StoredMvfFileSurveyServerResponse = yield mvfCheckServices.getFileSurveyById(surveyId);
        const newSurvey = new MvfCheckSurveyModel(surveyResponse.id, surveyResponse);

        if (surveyId === this._selectedSurvey?.id) {
          this.setSelectedSurvey(newSurvey);
        }

        if (!newSurvey.isInProgress) {
          this.loadSyncInfoAndCompareToPrevious();
        }

        return newSurvey;
      } catch (e: unknown) {
        Log.exception(e);
        throw handleMvfFileUploadError(e as RequestError);
      }
    }),
  );

  storeMvfFileSurvey = this.surveyLoadingWrapper(
    flow(function* (this: MasterDataGuardStore, filesToUpload: File[], note: string | undefined) {
      try {
        const sendForm = new FormData();
        const surveyRequest = { note };

        filesToUpload.forEach((file) =>
          sendForm.append('vendorFiles', new Blob([file], { type: translateFileNameToContentType(file.name) }), file.name),
        );

        sendForm.append(
          'survey',
          new Blob([JSON.stringify(surveyRequest)], {
            type: 'application/json',
          }),
        );

        const surveyResponse: StoredMvfFileSurveyServerResponse = yield mvfCheckServices.storeMvfFileSurvey(sendForm);
        const newSurvey = new MvfCheckSurveyModel(surveyResponse.id, surveyResponse);
        this._allSurveysByPage.get(0)?.push(newSurvey);

        return newSurvey;
      } catch (e: unknown) {
        Log.exception(e);
        throw handleMvfFileUploadError(e as RequestError);
      }
    }),
  );

  @action
  setSelectedSurveyId = (surveyId: string | null): void => {
    if (surveyId) {
      if (this._selectedSurvey?.id !== surveyId) {
        const surveyFromLoadedSurveys = Array.from(this._allSurveysByPage.values())
          .flat()
          .find((survey) => survey.id === surveyId);

        if (surveyFromLoadedSurveys) {
          this._selectedSurvey = surveyFromLoadedSurveys;
        } else {
          this._selectedSurvey = new MvfCheckSurveyModel(surveyId);
        }
      }
    } else {
      this._selectedSurvey = undefined;
    }
  };

  @action
  setSelectedSurvey = (survey: MvfCheckSurveyModel | null): void => {
    if (survey) {
      this._selectedSurvey = survey;
    } else {
      this._selectedSurvey = undefined;
    }
  };

  fetchFileSurveyFile = (surveyId: string, fileId: string): Promise<NamedResource> => {
    return mvfCheckServices.fetchFileSurveyFile(surveyId, fileId);
  };

  private sortFileSurveyReportEvents = (fileReports: FileSurveyReport[]): FileSurveyReport[] => {
    fileReports.forEach((fileReport) =>
      fileReport.accounts.sort((a, b) => (a.changeType < b.changeType ? 1 : b.changeType < a.changeType ? -1 : 0)),
    );

    return fileReports;
  };
  // endregion

  // region My data
  @computed
  get lastSyncInfo(): Loadable<SyncInfo> {
    return this._syncInfo.onlyWhen(this._isCurrentlyWatchingLiveData);
  }

  @computed
  private get searchableData(): Loadable<MasterDataGuardSearchableData> {
    return LoadableCreator.combine(this._searchableData, this.lastSyncInfo).map(([searchableData]) => searchableData);
  }

  @computed
  get privateAreaPayees(): Loadable<PrivatePayeeSearchableData[]> {
    return this.searchableData.map((searchableData) => searchableData.payees);
  }

  @computed
  get privateAreaCompanyCodes(): Loadable<PrivateAreaCompanyCodes> {
    return this.searchableData.map((searchableData) => searchableData.companyCodes);
  }

  @computed
  get exportedReportExpectedExecutionTimeMillis(): number {
    return (
      (this._searchableDataLoadTotalTimeMillis ?? SEARCHABLE_LOAD_TIME_FALLBACK_MILLIS) *
      SEARCHABLE_TO_NOT_VALIDATED_REPORT_TIME_MULTIPLIER
    );
  }

  @computed
  get allNotValidatedPayeesWithPercentage(): Loadable<PrivatePayeeWithDeterministicValidations[]> {
    return LoadableCreator.combine(this.searchableData, this._allNotValidatedPayeesWithPercentage).map(
      ([loadedSearchableData, loadedAllNotValidatedPayees]) => {
        return loadedSearchableData.payees
          .map((payee) => loadedAllNotValidatedPayees[payee.uniformId])
          .filter((maybeDVMaybeNull) => !!maybeDVMaybeNull);
      },
    );
  }

  @computed
  get allNotValidatedPayeesRequestLoadingState(): LoadingState {
    return this._allNotValidatedPayeesWithPercentage.combineWith(this._searchableData).loadState;
  }

  /**
   * This calculated property exist on its own because the allNotValidatedPayeesWithPercentage property updates every
   * time the percentage updates, and that update causes every calculated property that depends on it to recalculate.
   * Some calculated properties (like notValidatedPayeesAmounts) that depend on
   * allNotValidatedPayeesWithPercentage (directly or indirectly) are used on a very high level component, that causes
   * the entire tree beneath it to re-render as well. This frequent updates cause the UI to flicker and things like tab switch
   * to render multiple times while a tab switch occurs.
   * The solution is to create a computed that does not contain the percentage, and it only updates when the status change
   */
  @computed({
    equals: comparer.structural,
  })
  get allNotValidatedPayees(): Loadable<PrivatePayeeWithDeterministicValidations[]> {
    return this.allNotValidatedPayeesWithPercentage.withoutPercentage();
  }

  @computed
  get notValidatedPayeesAmounts(): Loadable<DisplayedPayeesTotal> {
    return this.allNotValidatedPayees.map((allNotValidatedPayees) => {
      const allAccounts = allNotValidatedPayees.flatMap((x) => x.accountsValidation);

      return {
        payeesAmount: allNotValidatedPayees.length,
        payeeAccountsAmounts: {
          all: allAccounts.length,
          notValidated: LoadableCreator.resolved(
            allAccounts.filter(
              (x) => x.deterministicValidation.validationLevel === DeterministicValidationResultType.notValidated,
            ).length,
          ),
        },
      };
    });
  }

  @computed
  get allPrivateAreaAmounts(): Loadable<DisplayedPayeesTotal> {
    return this.searchableData.map((searchableData) => ({
      payeesAmount: searchableData.payees.length,
      payeeAccountsAmounts: {
        all: searchableData.payeeAccountsAmount,
        notValidated: this.notValidatedPayeesAmounts.flatMap((result) => result.payeeAccountsAmounts.notValidated),
      },
    }));
  }

  createSearchablePayeeFilterMethod(filters: LivePrivatePayeeFilters): (payee: PrivatePayeeSearchableData) => boolean {
    const searchQueryLowercase = filters.searchQuery.toLowerCase();

    return (payee): boolean => {
      const failSearchQueryFilter =
        searchQueryLowercase &&
        !payee.externalIdLowercase.includes(searchQueryLowercase) &&
        !payee.namesLowercase.some((name) => name.includes(searchQueryLowercase)) &&
        !payee.supplierNumbers.some((supplierName) => supplierName.includes(searchQueryLowercase));

      if (failSearchQueryFilter) {
        return false;
      }

      const requiredCompanyCodes = filters.companyCodes;
      return (
        !requiredCompanyCodes ||
        (requiredCompanyCodes.allowBlankValue && !payee.companyCodes.length) ||
        payee.companyCodes.some((companyCode) => requiredCompanyCodes.values.has(companyCode))
      );
    };
  }

  createFullPayeeFilterMethod(
    filters: LivePrivatePayeeFilters,
  ): (payeeWithDV: PrivatePayeeWithDeterministicValidations) => boolean {
    const searchQueryLowercase = filters.searchQuery.toLowerCase();

    return (payee): boolean => {
      const failSearchQueryFilter =
        searchQueryLowercase &&
        !payee.privatePayee.externalReferenceId.toLowerCase().includes(searchQueryLowercase) &&
        !payee.privatePayee.data.names.some((name) => name.toLowerCase().includes(searchQueryLowercase)) &&
        !payee.privatePayee.data.supplierNumbers?.some((supplierNumber) =>
          supplierNumber.toLowerCase().includes(searchQueryLowercase),
        );

      if (failSearchQueryFilter) {
        return false;
      }

      const requiredCompanyCodes = filters.companyCodes;
      return (
        !requiredCompanyCodes ||
        (requiredCompanyCodes.allowBlankValue && !payee.privatePayee.data.companyCodes.length) ||
        payee.privatePayee.data.companyCodes.some((companyCode) => requiredCompanyCodes.values.has(companyCode))
      );
    };
  }

  @action
  setIsCurrentlyWatchingLiveData = (isCurrentlyWatchingLiveData: boolean): void => {
    this._isCurrentlyWatchingLiveData = isCurrentlyWatchingLiveData;

    if (this._isCurrentlyWatchingLiveData) {
      this.loadSyncInfoAndCompareToPrevious();
    }
  };

  private loadSyncInfoAndCompareToPrevious = flow(function* (this: MasterDataGuardStore) {
    // In case the sync info is already loading there is no need to load again
    // at the end of the current load a comparison will occur
    if (this._syncInfo.loadState === LoadingState.InProgress) {
      return;
    }

    const previousSyncInfo = this._syncInfo;

    this._syncInfo = LoadableCreator.inProgress();

    try {
      const syncInfo: SyncInfoServerResponse = yield masterDataGuardServices.getSyncInfo();
      const result = masterDataGuardServices.transformToSyncInfo(syncInfo);

      // load searchable data before we mark sync info as done
      if (this.hasSyncInfoChanged(previousSyncInfo, result)) {
        this.loadPrivateAreaSearchableData();
        this.tryLoadAllPrivatePayeesNotValidatedDeterministicValidation();
      }

      this._syncInfo = LoadableCreator.resolved(result);
    } catch (e: unknown) {
      Log.exception(e);
      this._syncInfo = LoadableCreator.rejected();
      throw e;
    }
  });

  private hasSyncInfoChanged = (loadablePrevious: Loadable<SyncInfo>, current: SyncInfo): boolean => {
    return loadablePrevious.resolve(
      (loadedPrevious) => {
        if (!loadedPrevious.lastSyncProcess?.id) {
          return true;
        }

        return loadedPrevious.lastSyncProcess.id !== current.lastSyncProcess?.id;
      },
      () => true,
    );
  };

  private loadPrivateAreaSearchableData = flow(function* (this: MasterDataGuardStore) {
    const loadStartTimeMillis = Date.now();

    yield flowad(
      (newValue) => (this._searchableData = newValue),
      async () => {
        const searchableDataServerResponse: MasterDataGuardSearchableDataServerResponse = await masterDataGuardServices.getPrivateAreaSearchableData();
        return masterDataGuardServices.transformToMasterDataGuardSearchableData(searchableDataServerResponse);
      },
    )();

    this._searchableDataLoadTotalTimeMillis = Date.now() - loadStartTimeMillis;
  });

  loadPrivatePayeesDeterministicValidation = async (
    privatePayeeUniformIds: string[],
    quiet?: boolean,
  ): Promise<PrivatePayeeWithDeterministicValidations[]> => {
    const privatePayeeDeterministicValidations = await masterDataGuardServices.getPrivatePayeesDeterministicValidations(
      privatePayeeUniformIds,
      quiet,
    );

    // Using privatePayeeUniformIds in order to preserve the order of private payees
    return privatePayeeUniformIds
      .map((uniformId) => privatePayeeDeterministicValidations[uniformId])
      .map(masterDataGuardServices.transformToPrivatePayeeWithDeterministicValidation)
      .filter((x) => x !== null)
      .map((x) => x as PrivatePayeeWithDeterministicValidations);
  };

  @action
  updateNotValidatedPayeesList = (
    privatePayeesWithDeterministicValidations: PrivatePayeeWithDeterministicValidations[],
  ): void => {
    if (!this._allNotValidatedPayeesWithPercentage.isResolved()) {
      return;
    }

    const notValidatedPayeesWithDVsCopy = { ...this._allNotValidatedPayeesWithPercentage.result };
    privatePayeesWithDeterministicValidations
      .filter(
        (privatePayeeWithDeterministicValidations) =>
          !!notValidatedPayeesWithDVsCopy[privatePayeeWithDeterministicValidations.privatePayee.uniformId],
      )
      .forEach((privatePayeeWithDeterministicValidations) => {
        const hadNotValidatedAccount = privatePayeeWithDeterministicValidations.accountsValidation.some(
          (validation) => validation.deterministicValidation.validationLevel === DeterministicValidationResultType.notValidated,
        );

        if (hadNotValidatedAccount) {
          notValidatedPayeesWithDVsCopy[
            privatePayeeWithDeterministicValidations.privatePayee.uniformId
          ] = privatePayeeWithDeterministicValidations;
        } else {
          delete notValidatedPayeesWithDVsCopy[privatePayeeWithDeterministicValidations.privatePayee.uniformId];
        }
      });

    this._allNotValidatedPayeesWithPercentage = LoadableCreator.resolved(notValidatedPayeesWithDVsCopy);
  };

  private tryLoadAllPrivatePayeesNotValidatedDeterministicValidation = flow(function* (this: MasterDataGuardStore) {
    if (!this._permissionsStore.isMasterDataGuardEnable) {
      return;
    }

    yield flowadWithPercentage<Record<string, PrivatePayeeWithDeterministicValidations>>(
      (newValue) => (this._allNotValidatedPayeesWithPercentage = newValue),
      async () => {
        const privatePayeeDeterministicValidationsResponse = await masterDataGuardServices.getAllPrivatePayeesNotValidatedDeterministicValidations();

        // Using privatePayeeUniformIds in order to preserve the order of private payees
        return Object.fromEntries(
          Object.entries(privatePayeeDeterministicValidationsResponse).map<[string, PrivatePayeeWithDeterministicValidations]>(
            ([uniformId, accountsWithDeterministicValidation]) => [
              uniformId,
              masterDataGuardServices.transformToPrivatePayeeWithDeterministicValidation(
                accountsWithDeterministicValidation,
              ) as PrivatePayeeWithDeterministicValidations,
            ],
          ),
        );
      },
      () => this.exportedReportExpectedExecutionTimeMillis,
    )();
  });

  calculateVisibleLiveData = async (filters: LivePrivatePayeeFilters | null): Promise<NamedResource> => {
    let uniformIds: string[] | undefined = undefined;

    if (filters) {
      if (filters.validationLevel === LivePrivatePayeeValidationLevel.notValidated) {
        uniformIds = this.allNotValidatedPayees.resolve(
          (allNotValidatedPayees) => {
            return allNotValidatedPayees
              .filter(this.createFullPayeeFilterMethod(filters))
              .map((payeeWithDV) => payeeWithDV.privatePayee.uniformId);
          },
          () => undefined,
        );
      } else {
        uniformIds = this.privateAreaPayees.resolve(
          (allSearchablePayees) => {
            return allSearchablePayees
              .filter(this.createSearchablePayeeFilterMethod(filters))
              .map((searchablePayee) => searchablePayee.uniformId);
          },
          () => {
            return undefined;
          },
        );
      }
    }

    return await masterDataGuardServices.getPrivatePayeesVisibleLiveData(
      uniformIds,
      filters?.validationLevel ?? defaultFilters.validationLevel,
    );
  };

  storeInitiateSelfApprove = async (
    privatePayeeUniformId: string,
    privatePayeeAccountUniformId: string,
    filesToUpload: File[],
    request: SelfApprovePrivatePayeeAccountRequest,
  ): Promise<PrivatePayeeAccountServerResponse> => {
    try {
      const sendForm = new FormData();

      filesToUpload.forEach((file) =>
        sendForm.append('attachments', new Blob([file], { type: translateFileNameToContentType(file.name) }), file.name),
      );

      sendForm.append(
        'request',
        new Blob([JSON.stringify(request)], {
          type: 'application/json',
        }),
      );

      return await masterDataGuardServices.storeInitiateSelfApprove(
        privatePayeeUniformId,
        privatePayeeAccountUniformId,
        sendForm,
      );
    } catch (e: unknown) {
      Log.exception(e);
      const fileUploadError = handleGeneralFileUploadError(e as RequestError);

      if (fileUploadError && fileUploadError.erroredFiles) {
        throw fileUploadError;
      }

      throw e;
    }
  };

  storeRemoveSelfApprove = async (
    privatePayeeUniformId: string,
    privatePayeeAccountUniformId: string,
    request: RemoveSelfApproveRequest,
  ): Promise<PrivatePayeeAccountServerResponse> => {
    try {
      return await masterDataGuardServices.storeRemoveSelfApprove(privatePayeeUniformId, privatePayeeAccountUniformId, request);
    } catch (e: unknown) {
      Log.exception(e);
      throw e;
    }
  };
  //endregion
}
