import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { CURRENCIES_KEYS } from '@app/domain/currencies';
import { trim } from '@app/utils/stringUtils';

function getAdditionalSwiftRules(form: WrappedFormUtils<BaseFormFields>): ConditionalValidationRule[] {
  return [
    {
      required: form.getFieldValue('currency') === CURRENCIES_KEYS.PHP,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const PH: SupplierRegistrationCountryFields = {
  additionalCompanyName: true,
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
};
