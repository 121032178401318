import React, { FC, ReactElement } from 'react';
import { UserCandidateToOrganization } from '@app/domain/userManagement/organizationUsers';
import SVG from '@app/components/SVG';
import CheckImage from '@app/images/check-full.svg';
import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import { pluralize } from '@app/utils/stringUtils';
import Table, { TableImportantText, TableStandardText } from '@app/components/tables/Table';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import AsyncButton from '@app/components/AsyncButton';
import { SubtitleSmallStartTransparentBlack600 } from '@app/components/Text';
import Loadable from '@app/utils/Loadable';
import { isRequestError, isWebErrorContent } from '@app/libs/request';
import { compare } from '@app/utils/comparatorUtils';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import LoadableTransitionLoader from '@app/components/LoadableTransitionLoader';
import ErrorLoadingData from '@app/components/ErrorLoadingData';

interface Props {
  searchResultsLoadable: Loadable<UserCandidateToOrganization[]>;
  addUserToOrganization(tenantUserId: string): Promise<void>;
}

const OrganizationalUsersSearchResults: FC<Props> = ({ searchResultsLoadable, addUserToOrganization }) => {
  function renderResults(): ReactElement {
    if (searchResultsLoadable.isRejected()) {
      const error = searchResultsLoadable.stateMetadata?.error;
      if (
        isRequestError(error) &&
        isWebErrorContent(error.responseJSON) &&
        error.responseJSON.error === 'RETURNED_TOO_MANY_USERS_ERROR'
      ) {
        return <div>Search resulted with too many users</div>;
      }

      return <ErrorLoadingData error={error} />;
    }

    return (
      <LoadableTransitionLoader loadable={searchResultsLoadable}>
        {(users): ReactElement => {
          return (
            <VerticalShadowScroller>
              <SubtitleSmallStartTransparentBlack600.div>
                {users.length} {pluralize('result', users.length)}
              </SubtitleSmallStartTransparentBlack600.div>
              <Table
                data-testid='users-search-results-table'
                showHeader={false}
                pagination={false}
                columns={createColumns(addUserToOrganization)}
                dataSource={users}
                rowKey={(user): string => user.tenantUserId}
              />
            </VerticalShadowScroller>
          );
        }}
      </LoadableTransitionLoader>
    );
  }

  return <div data-testid='users-search-results'>{renderResults()}</div>;
};

export default OrganizationalUsersSearchResults;

function createColumns(
  addUserToOrganization: (tenantUserId: string) => Promise<void>,
): ColumnProps<UserCandidateToOrganization>[] {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: compare.byStringField((record) => record.name),
      render: (text: any, record: UserCandidateToOrganization): React.ReactNode => (
        <TableImportantText>{record.name}</TableImportantText>
      ),
      ...getColumnTextSearchProps((record) => record.name),
    },
    {
      dataIndex: 'email',
      key: 'email',
      render: (text: any, record: UserCandidateToOrganization): React.ReactNode => (
        <TableStandardText>{record.email}</TableStandardText>
      ),
    },
    {
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (text: any, record: UserCandidateToOrganization): React.ReactNode => (
        <TableStandardText>{record.phoneNumber}</TableStandardText>
      ),
    },
    {
      dataIndex: 'actions',
      width: '90px',
      key: 'actions',
      render: (text: any, record: UserCandidateToOrganization): React.ReactNode => {
        return (
          <OverlappingContainer>
            <Hiddenable isHidden={!record.isPartOfOrganization}>
              <SVG accessibilityLabel='checked' image={CheckImage} width={16} height={16} data-testid='img-user-part-of-org' />
            </Hiddenable>
            <Hiddenable isHidden={record.isPartOfOrganization}>
              <AddButton
                id={`btn-${record.tenantUserId}-add-user-to-org`}
                dataTestId='btn-add-user-to-org'
                size='small'
                onClick={async (): Promise<void> => addUserToOrganization(record.tenantUserId)}
              >
                ADD
              </AddButton>
            </Hiddenable>
          </OverlappingContainer>
        );
      },
    },
  ];
}

const AddButton = styled(AsyncButton)`
  display: inline-block;
`;

const OverlappingContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  align-items: center;
  justify-items: center;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }
`;

const Hiddenable = styled.div<{ isHidden: boolean | undefined }>`
  ${(p): string =>
    p.isHidden
      ? `
  visibility: hidden;
  pointer-events: none;
`
      : ''}
`;
