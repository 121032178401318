// https://github.com/ReactTraining/react-router/blob/master/FAQ.md#how-do-i-access-the-history-object-outside-of-components
import { createBrowserHistory, History, Location, LocationDescriptor, LocationDescriptorObject, LocationState } from 'history';
import qs from 'query-string';
import { isTruthy } from '@app/utils/utils';
import { createObservableHistory as createObservableHistoryWrapper } from 'mobx-observable-history';
import config from '@app/config';

interface InnerRedirectState {
  innerNsknoxRedirect?: boolean;
}

function isInnerRedirectState(state: unknown): state is InnerRedirectState {
  if (typeof state !== 'object' || !state) {
    return false;
  }

  return 'innerNsknoxRedirect' in state;
}

function mergeQueryParams(
  currentLocation: LocationDescriptorObject,
  newLocation: LocationDescriptorObject,
  preserve: string[],
): string | undefined {
  if (!(isInnerRedirectState(newLocation.state) && newLocation.state?.innerNsknoxRedirect)) {
    return newLocation.search;
  }

  const currentQuery = currentLocation.search && qs.parse(currentLocation.search);

  if (!currentQuery) {
    return newLocation.search;
  }

  const preservedQuery: { [key: string]: unknown } = Object.fromEntries(
    preserve
      .map((queryParamToPreserve) => [queryParamToPreserve, currentQuery[queryParamToPreserve]])
      .filter(([, qParamValue]) => isTruthy(qParamValue)),
  );

  return qs.stringify({
    ...preservedQuery,
    ...qs.parse(newLocation.search ?? ''),
  });
}

function preserveQueryParameters(
  currentLocation: LocationDescriptorObject,
  newLocation: LocationDescriptorObject,
  preserve: string[],
): LocationDescriptorObject {
  const newQueryParams = mergeQueryParams(currentLocation, newLocation, preserve);

  return {
    ...newLocation,
    search: newQueryParams,
  };
}

function createLocationDescriptorObject<TLocationState>(
  location: LocationDescriptor<TLocationState>,
  state?: TLocationState,
): LocationDescriptorObject<TLocationState> {
  return typeof location === 'string' ? { pathname: location, state } : location;
}

export function preserveQueryParamHistoryWrapper<THistory extends History>(
  history: THistory,
  queryParameters: string[],
): THistory {
  const oldPush = history.push;
  history.push = (path: LocationDescriptor, state?: LocationState): void => {
    const locationDescriptorObject = preserveQueryParameters(
      history.location as Location,
      createLocationDescriptorObject(path, state),
      queryParameters,
    );
    oldPush.call(history, locationDescriptorObject);
  };

  const oldReplace = history.replace;
  history.replace = (path: LocationDescriptor, state?: LocationState): void => {
    const locationDescriptorObject = preserveQueryParameters(
      history.location as Location,
      createLocationDescriptorObject(path, state),
      queryParameters,
    );
    oldReplace.call(history, locationDescriptorObject);
  };

  return history;
}

const originalBrowserHistory = createBrowserHistory({ basename: config.baseUrl });

const preserveQueryHistory = preserveQueryParamHistoryWrapper(originalBrowserHistory, [
  'ref',
  'email',
  'regId',
  'instructionType',
  'orgId',
]);

const observableHistory = createObservableHistoryWrapper(preserveQueryHistory);

export default observableHistory;
