import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useScrollDistance } from '@app/hooks/useScrollDistance';
import { isDefined } from '@app/utils/utils';

interface VerticalShadowScrollerProps {
  id?: string;
  className?: string;
}

const VerticalShadowScroller: FC<VerticalShadowScrollerProps> = ({ id, className, children }) => {
  const [scrollDistance, scrollableRef, scrollableContentRef] = useScrollDistance<HTMLDivElement>();

  return (
    <ScrollableArea id={id} className={className} ref={scrollableRef}>
      <ScrollableContent
        // One of many ways of knowing if the shadow should change is to know if the children of the scrollable content change size
        // so applying the ref to the content we can track of when it changes size, because tracking the parent with the scrollbar to know when the
        // scroll size changes is too difficult
        ref={scrollableContentRef}
        distanceFromTop={scrollDistance?.distanceFromTop}
        distanceFromBottom={scrollDistance?.distanceFromBottom}
      >
        {children}
      </ScrollableContent>
    </ScrollableArea>
  );
};

export default VerticalShadowScroller;

const DISTANCE_THRESHOLD = 30;

const calcColorOpacityFromDistance = (distance: number | undefined): string => {
  let alphaNumber: number;

  if (!isDefined(distance)) {
    alphaNumber = 0;
  } else {
    alphaNumber = Math.min((Math.min(distance, DISTANCE_THRESHOLD) / DISTANCE_THRESHOLD) * 256, 255);
  }

  const [first = '0', second = '0'] = alphaNumber.toString(16);

  return first + second;
};

const ScrollableArea = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  z-index: 0;
`;

const ScrollableContent = styled.div<{ distanceFromTop: number | undefined; distanceFromBottom: number | undefined }>`
  --scroll-shadow-height: 12px;

  margin-block: calc(var(--scroll-shadow-height) * -1);

  &::before {
    content: '';
    display: block;
    box-shadow: inset 0 var(--scroll-shadow-height) 8px -10px ${(p): string => `#cccccc${calcColorOpacityFromDistance(p.distanceFromTop)}`};
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--scroll-shadow-height);
    z-index: 1;
    visibility: ${(p): string => ((p.distanceFromTop ?? 0) < 2 ? 'hidden' : 'visible')};
  }

  &::after {
    content: '';
    display: block;
    box-shadow: inset 0 calc(var(--scroll-shadow-height) * -1) 8px -10px ${(p): string => `#cccccc${calcColorOpacityFromDistance(p.distanceFromBottom)}`};
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--scroll-shadow-height);
    z-index: 1;
    visibility: ${(p): string => ((p.distanceFromBottom ?? 0) < 2 ? 'hidden' : 'visible')};
  }
`;
