import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';

enum FranceLegalIdTypesForCompany {
  SIRET = 'SIRET',
  Other = 'Other',
}

export const FR: SupplierRegistrationCountryFields = {
  leis: { types: FranceLegalIdTypesForCompany },
};
