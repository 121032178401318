import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { Organization, OrganizationServerResponse, StoreOrganizationRequest } from '@app/domain/userManagement/organizations';
import { ORGANIZATION_HEADER_NAME } from '@app/utils/userIdentityHeadersGenerator';

export async function getAllOrganizations(): Promise<OrganizationServerResponse[]> {
  return request<OrganizationServerResponse[]>(serverUrl, `/api/organizations/all`, {
    method: HttpMethod.get,
    errorsHandler: {
      default: {
        message: 'Cannot get organizations.',
      },
    },
  });
}

export async function getOrganizationById(id: string): Promise<OrganizationServerResponse> {
  return request<OrganizationServerResponse>(serverUrl, `/api/organizations`, {
    method: HttpMethod.get,
    headers: {
      [ORGANIZATION_HEADER_NAME]: id,
    },
    errorsHandler: {
      default: {
        message: 'Cannot get organization by id.',
      },
    },
  });
}

export default {
  async storeOrganization(data: StoreOrganizationRequest): Promise<OrganizationServerResponse> {
    return request<OrganizationServerResponse>(serverUrl, `/api/organizations`, {
      data,
      method: HttpMethod.post,
      errorsHandler: {
        default: {
          message: 'Cannot store organization.',
        },
      },
    });
  },
};

export function transformToOrganization(serverResponse: OrganizationServerResponse): Organization {
  return {
    ...serverResponse,
  };
}
