import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { MAX_PAGE_WIDTH } from '../domain/uiConsts';
import useMode from '@app/hooks/useMode';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import { preventForwardTheseProps } from '@app/utils/styledUtils';

export interface BasicPageProps extends React.HTMLAttributes<HTMLDivElement> {
  paddingTop?: 'none' | number;
  header?: ReactNode;
}

interface FixedWidthPageProps {
  width?: number;
  widthReactive: boolean;
}

interface FullWidthPageProps {
  width: 'full' | 'fullWithoutPadding';
  widthReactive?: undefined;
}

type PageProps = BasicPageProps & (FixedWidthPageProps | FullWidthPageProps);

const PageWithHeader: FC<PageProps> = (props): ReactElement => {
  const { width = MAX_PAGE_WIDTH, paddingTop = 25, widthReactive, header, className, children, ...rest } = props;

  const mode = useMode();

  const HeaderComponent = mode.getDefaultHeaderComponent();

  return (
    <Container className={className} {...rest}>
      {header || <HeaderComponent />}
      <PageContainer width={width} widthReactive={widthReactive} paddingTop={paddingTop}>
        {children}
      </PageContainer>
    </Container>
  );
};

export default PageWithHeader;

const PageContainer = styled(LocaleAwareDiv, {
  shouldForwardProp: preventForwardTheseProps('widthReactive', 'paddingTop', 'width'),
})<{
  width: 'full' | 'fullWithoutPadding' | number;
  widthReactive: boolean | undefined;
  paddingTop: 'none' | number;
}>`
  ${(p): string | undefined => {
    if (p.paddingTop !== 'none') {
      return `padding-top: ${p.paddingTop}px;`;
    }
  }}

  ${(p): string => {
    if (p.width === 'full') {
      return `
        width: 100%;
        padding-right: var(--page-side-padding);
        padding-left: var(--page-side-padding);
      `;
    }

    if (p.width === 'fullWithoutPadding') {
      return `
        width: 100%;
      `;
    }

    const requestedWidth = p.widthReactive ? `min(${p.width}px, 95vw)` : `${p.width}px`;

    return `
    width: ${requestedWidth};
    align-self: center;
  `;
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
