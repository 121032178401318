export enum UserRolesType {
  // Account verification Module
  verifyAccountRole = 'ROLE_VERIFY_ACCOUNT',

  // Payment check module
  paymentCheckViewRole = 'ROLE_VIEW_PAYMENT_CHECK',
  paymentCheckCreateRole = 'ROLE_CREATE_PAYMENT_CHECK',

  // Mvf check module
  viewFileSurveyRole = 'ROLE_VIEW_FILE_SURVEY',
  createFileSurveyRole = 'ROLE_CREATE_FILE_SURVEY',

  // Sftp module
  sftpPortalViewRole = 'ROLE_FILE_BASED_SFTP_PORTAL_VIEW',

  // Admin Module
  adminRole = 'ROLE_KNOX_ADMIN',

  // Validations Module
  viewValidationRequestsRole = 'ROLE_VIEW_VALIDATION_PROCESS_REQUESTS',
  viewValidationSystemRole = 'ROLE_SV_MANAGEMENT_VALIDATIONS_VIEWER',
  createValidationSystemRole = 'ROLE_SV_MANAGEMENT_VALIDATIONS_CREATOR',
  editValidationSystemRole = 'ROLE_SV_MANAGEMENT_VALIDATIONS_EDITOR',
  createValidationsReportSystemRole = 'ROLE_SV_MANAGEMENT_VALIDATIONS_REPORT_CREATOR',

  // Verifications
  roleSvManagementValidationsApprover = 'ROLE_SV_MANAGEMENT_VALIDATIONS_APPROVER',

  // Mvf Guard module
  initiateSelfApproveRole = 'ROLE_INITIATE_SELF_APPROVE',
  initiateRemoveSelfApproveRole = 'ROLE_REMOVE_SELF_APPROVE',

  // Admin Validated Payee Module
  storeValidatedPayeesRole = 'ROLE_STORE_VALIDATIONS',
  viewManageValidatedPayeesRole = 'ROLE_VIEW_MANAGE_VALIDATED_PAYEE',

  // Incoming Payment Security module
  ipsViewMyAccountsRole = 'ROLE_IPS_VIEW_ACCOUNTS',
  ipsViewLogsRole = 'ROLE_IPS_VIEW_LOGS',

  // Secure Files module
  secureFilesRole = 'ROLE_SECURE_FILES',

  // Non Backee User Management
  organizationAdmin = 'ROLE_WEB_ORG_ADMIN',

  // Backee Mode
  backeeViewRole = 'ROLE_BACKEE_WEB_VIEW',
  backeeEditRole = 'ROLE_BACKEE_WEB_EDIT',
  backeeCreateOrganizationRole = 'ROLE_BACKEE_WEB_CREATE_ORGANIZATION',
  backeeCreateUserRole = 'ROLE_BACKEE_WEB_CREATE_USER',
}
