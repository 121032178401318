import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import Flag from 'react-world-flags';
import SVG from '@app/components/SVG';
import UnknownFlagIcon from '@app/images/flag-unknown.svg';
import { getCountryByCountryCode, getCountryName } from '@app/domain/countries';
import ReactPlaceholder from 'react-placeholder';
import withPlaceholder from './withPlaceholder';
import ConditionalTooltip from './ConditionalTooltip';
import { useTranslation } from 'react-i18next';

export interface DataProps {
  countryCode?: string | null;
  unknownFlagStyle?: 'questionMark' | 'wavyFlag';
  tooltip?: boolean;
}

export interface DisplayProps {
  size?: number;
  className?: string;
}

const NationalityFlag: FC<DataProps & DisplayProps> = (props) => {
  const { countryCode, size = 20, unknownFlagStyle, tooltip, className } = props;
  const { t } = useTranslation();

  let content: ReactElement;

  if (countryCode && getCountryByCountryCode(countryCode)) {
    content = <StyledFlag alt='' className={className} code={countryCode} size={size} />;
  }

  // Rendering unknown flag
  else if (unknownFlagStyle === 'wavyFlag') {
    content = (
      <StyledUnknownFlagIcon
        accessibilityLabel={t('general.accessibility.unknownCountry')}
        className={className}
        image={UnknownFlagIcon}
        size={size}
      />
    );
  } else {
    content = (
      <FlagCircleWithoutContent className={className} size={size}>
        ?
      </FlagCircleWithoutContent>
    );
  }

  const tooltipText = getCountryName(countryCode, 'Unknown Country');

  return (
    <ConditionalTooltip showTooltip={tooltip ?? false} title={tooltipText}>
      {content}
    </ConditionalTooltip>
  );
};

const Placeholder: FC<DisplayProps> = ({ className, size = 20 }) => {
  return <ReactPlaceholder className={className} type='round' ready={false} children='' style={{ width: size, height: size }} />;
};

export default withPlaceholder<DisplayProps, DataProps>(NationalityFlag, Placeholder);

const StyledFlag = styled(Flag)<{ size: number }>`
  width: ${({ size }): number => size}px;
  height: ${({ size }): number => size}px;
  border-radius: ${({ size }): number => size / 2}px;
  object-fit: cover;
  border: solid 1px rgba(60, 67, 90, 0.8);
`;

const StyledUnknownFlagIcon = styled(SVG)<{ size: number }>`
  width: ${({ size }): number => size}px;
  height: ${({ size }): number => size}px;
`;

export const FlagCircleWithoutContent = styled.span<{ size: number }>`
  width: ${({ size }): number => size}px;
  height: ${({ size }): number => size}px;

  border-radius: ${({ size }): number => size / 2}px;
  border: solid 1px rgba(60, 67, 90, 0.8);

  color: rgba(60, 67, 90, 0.8);

  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  line-height: normal;
  font-weight: bold;
  font-size: 14px;
`;
