import React, { FunctionComponent, ReactElement, useState } from 'react';
import PageWithHeader from '@app/components/PageWithHeader';
import MorteeMode from '@mortee/morteeMode';
import { observer } from 'mobx-react';
import MasterDataGuardLivePage from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/MasterDataGuardLivePage';
import Button from '@app/components/Button';
import addVerificationIcon from '@mortee/images/check/homePage/add-verification.svg';
import SVG from '@app/components/SVG';
import BackArrowIcon from '@app/images/arrows/left-arrow-accent.svg';
import MvfCheckReportPage from '@mortee/routes/masterDataGuard/mvfCheck/MvfCheckReportPage';
import MvfSurveysListPage from '@mortee/routes/masterDataGuard/mvfCheck/MvfSurveysListPage';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import { MasterDataGuardPageMode } from '@mortee/stores/appStores/MasterDataGuardStore';
import LoadMvfCheckPage from '@mortee/routes/masterDataGuard/LoadMvfCheckPage';
import styled from '@emotion/styled';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import Tabs, { TabPane } from '@app/components/Tabs';
import useMountEffect from '@app/hooks/useMountEffect';

const MasterDataGuardPage: FunctionComponent = observer(() => {
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { masterDataGuardStore } = useAppStores<MorteeMode>();

  useMountEffect(() => {
    handleTabChanged(MasterDataGuardPageMode.myData);
  });

  const [showNewMvfCheckUpload, setShowNewMvfCheckUpload] = useState(false);

  const { isMasterDataGuardEnable, isCreateMvfFileOnMvfCheckEnable } = permissionsStore;

  const handleUploadMvf = (): void => {
    setShowNewMvfCheckUpload(true);
  };

  const handleTabChanged = (pageMode: MasterDataGuardPageMode): void => {
    masterDataGuardStore.setPageMode(pageMode);
    masterDataGuardStore.setSelectedSurveyId(null);
  };

  const resetToChangelog = (): void => {
    handleTabChanged(MasterDataGuardPageMode.changelog);
  };

  const renderHeader = (): ReactElement => {
    return (
      <PageHeaderCenteredSideContent>
        {isCreateMvfFileOnMvfCheckEnable && !showNewMvfCheckUpload && (
          <UploadMvfButton id='btn-upload-mvf' onClick={handleUploadMvf} appearance='text'>
            <StyledUploadMvfSvg accessibilityLabel='' image={addVerificationIcon} />
            <UploadMvfText>UPLOAD MVF</UploadMvfText>
          </UploadMvfButton>
        )}
      </PageHeaderCenteredSideContent>
    );
  };

  return (
    <PageWithHeader width='fullWithoutPadding' paddingTop='none' header={<PageHeader sideContent={renderHeader()} />}>
      {showNewMvfCheckUpload ? (
        <Container>
          <LoadMvfCheckPageWrapper>
            <LoadMvfCheckPage
              onUploaded={(): void => setShowNewMvfCheckUpload(false)}
              onCancel={(): void => setShowNewMvfCheckUpload(false)}
            />
          </LoadMvfCheckPageWrapper>
        </Container>
      ) : (
        <MainContainer>
          <Tabs
            defaultActiveKey={masterDataGuardStore.getPageMode}
            onChange={(key: MasterDataGuardPageMode): void => handleTabChanged(key)}
            tabBarGutter={0}
          >
            <StyledTabPane tab={<span data-testid='tab-mdg-my-data'>My data</span>} key={MasterDataGuardPageMode.myData}>
              <SizeLimitedMasterDataGuardLivePage />
            </StyledTabPane>
            {isMasterDataGuardEnable && (
              <StyledTabPane tab={<span data-testid='tab-mdg-changelog'>Changelog</span>} key={MasterDataGuardPageMode.changelog}>
                <ChangelogContainer>
                  {masterDataGuardStore.selectedSurvey ? (
                    <>
                      <NavButton id='btn-header-nav' appearance='text' onClick={resetToChangelog}>
                        <StyledBackArrowIcon accessibilityLabel='' image={BackArrowIcon} /> BACK
                      </NavButton>
                      <MvfCheckReportPage />
                    </>
                  ) : (
                    <MvfSurveysListPage handleFirstVerification={handleUploadMvf} />
                  )}
                </ChangelogContainer>
              </StyledTabPane>
            )}
          </Tabs>
        </MainContainer>
      )}
    </PageWithHeader>
  );
});

export default MasterDataGuardPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
`;

const LoadMvfCheckPageWrapper = styled.div`
  width: 950px;
`;

const MainContainer = styled.div`
  width: 100%;
  padding-top: 0;
`;

const ChangelogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SizeLimitedMasterDataGuardLivePage = styled(MasterDataGuardLivePage)`
  width: min(100%, 950px);
`;

const StyledUploadMvfSvg = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const UploadMvfButton = styled(Button)`
  display: flex;
  flex-direction: row;

  padding: 16px 8px;
`;

const UploadMvfText = styled.div`
  margin-top: 2px;
`;

const NavButton = styled(Button)`
  position: absolute;
  top: 6px;
  left: 32px;
  padding: 8px;
  align-items: flex-end;
  display: flex;
`;

const StyledBackArrowIcon = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const StyledTabPane = styled(TabPane)`
  padding-right: 32px;
  padding-left: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
