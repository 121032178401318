import { useEffect } from 'react';
import useAppStores from '@app/hooks/useAppStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useRefFromLocation from '@supplierRegistration/hooks/useRefFromLocation';
import useSubdomainFromLocation from '@supplierRegistration/hooks/useSubdomainFromLocation';
import useAsyncIdFromLocation from '@supplierRegistration/hooks/useAsyncIdFromLocation';
import useSkipWelcomeFromLocation from '@supplierRegistration/hooks/useSkipWelcomeFromLocation';

export default function useSupplierRegistrationCustomerData(allowCustomerCustomization: boolean): void {
  const { supplierRegistrationCustomerDataStore, supplierRegistrationFormStore } = useAppStores<SupplierRegistrationMode>();

  const referenceId = useRefFromLocation(allowCustomerCustomization);
  const subdomain = useSubdomainFromLocation(allowCustomerCustomization);
  const [asyncId, isAsyncIdValid] = useAsyncIdFromLocation(allowCustomerCustomization);
  const skipWelcome = useSkipWelcomeFromLocation();

  useEffect(() => {
    supplierRegistrationFormStore.setSkipWelcome(skipWelcome);
    supplierRegistrationCustomerDataStore.tryLoadCustomerData(referenceId, subdomain, asyncId, isAsyncIdValid);
  }, [
    supplierRegistrationCustomerDataStore,
    supplierRegistrationFormStore,
    referenceId,
    subdomain,
    asyncId,
    isAsyncIdValid,
    skipWelcome,
  ]);
}
