import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import Loadable, { LoadableCreator } from '@app/utils/Loadable';

interface FilterValues<T> {
  exactMatches?: (item: T) => (string | null | undefined)[];
  partialMatches?: (item: T) => (string | null | undefined)[];
}

function cleanSearchValue(searchTerm: string | null | undefined): string | null | undefined {
  return searchTerm?.trim().toLowerCase();
}

export default function useSearchFilter<T>(
  items: T[],
  filterValues: FilterValues<T>,
): [T[], string, Dispatch<SetStateAction<string>>] {
  const [filteredItems, searchTerm, setSearchTerm] = useLoadableSearchFilter(LoadableCreator.resolved(items), filterValues);
  return [filteredItems.result ?? [], searchTerm, setSearchTerm];
}

export function useLoadableSearchFilter<T>(
  items: Loadable<T[]>,
  filterValues: FilterValues<T>,
): [Loadable<T[]>, string, Dispatch<SetStateAction<string>>] {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const cleanedSearchTerm = cleanSearchValue(searchTerm);

  const filteredItems = useMemo<Loadable<T[]>>(() => {
    return items.map((itemsArray) => {
      if (!cleanedSearchTerm) {
        return itemsArray;
      }

      return itemsArray?.filter((item) => {
        const exactMatchValues = filterValues.exactMatches?.(item);
        if (exactMatchValues?.includes(cleanedSearchTerm)) {
          return true;
        }

        const partialMatchValues = filterValues.partialMatches?.(item);
        return !!partialMatchValues?.some((field) => cleanSearchValue(field)?.includes(cleanedSearchTerm));
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- filterValues is a new instance every time and causes rerender
  }, [cleanedSearchTerm, items]);

  return [filteredItems, searchTerm, setSearchTerm];
}
