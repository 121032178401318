import React, { ChangeEventHandler, KeyboardEvent, PropsWithChildren, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { action } from 'mobx';
import ClearIcon from '@app/images/ic_close.svg';
import SVG from '@app/components/SVG';
import Button from '../Button';
import ValueWithPlaceholder from './ValueWithPlaceholder';
import FormInputsContext from './FormInputsContext';
import useLocalObservableWithProps from '@app/hooks/useLocalObservableWithProps';
import { ForwardingFC } from '@app/domain/technicals/components';
import { BodyRegularStartTransparentBlack900 } from '../Text';
import { FormInputProps } from '@app/utils/form/form';
import InternalInputStyle from './InternalInputStyle';
import { ColorScheme } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';
import { useTranslation } from 'react-i18next';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';

export type InputProps = FormInputProps<string> &
  Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange' | 'placeholder'> & {
    name: string;
    type: HTMLInputElement['type'];
    colorScheme?: ColorScheme;
    textColorScheme?: ColorScheme;
    readOnly?: boolean;
    disabled?: boolean;
    maxLength?: number;
    onMaxLengthAchieved?: (value: string) => void;
    autoFocus?: boolean;
    clearable?: boolean;
    onClear?: () => void;
    allowArrowKeysPress?: boolean;
    onChangeEvent?: ChangeEventHandler<HTMLInputElement>;
    className?: string;
    dataTestId?: string;
    prefix?: string;
    disableSuggestion?: boolean;
    inputClassName?: string;
    renderSideComponent?: () => ReactNode;
  };

const NakedFormInput: ForwardingFC<HTMLInputElement, InputProps> = observer(
  React.forwardRef((props: PropsWithChildren<InputProps>, ref) => {
    const { state } = useContext(FormInputsContext);
    const { t } = useTranslation();

    const {
      name,
      type,
      colorScheme: propColorScheme,
      textColorScheme: propTextColorScheme,
      placeholder,
      placeholderStyle,
      placeholderVerticalAlign,
      accessibilityLabel,
      heightType,
      disabled: disabledProp,
      className,
      inputClassName,
      dataTestId = name,
      clearable,
      autoFocus = false,
      value = '',
      prefix,
      readOnly,
      disableSuggestion,
      renderSideComponent,
      onChange,
      onChangeEvent,
      maxLength,
      onMaxLengthAchieved,
      onKeyPress,
      allowArrowKeysPress = false,
      onKeyDown,
      onFocus,
      onClear,
      ...rest
    } = props;

    const localStore = useLocalObservableWithProps(
      () => ({
        _focused: false,
        value: '' as string,

        setBlur: (): void => {
          localStore._focused = false;
        },

        get hasValue(): boolean {
          return localStore.value !== null && localStore.value !== undefined && localStore.value !== '';
        },

        get active(): boolean {
          return this._focused || this.hasValue;
        },
      }),
      {
        value: value,
      },
    );

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.currentTarget.value;

      if (!isValueValid(newValue) || props.disabled) {
        return;
      }

      const trimmedValue = newValue && maxLength ? newValue.substr(0, maxLength) : newValue;

      if (maxLength && trimmedValue?.length > maxLength) {
        return;
      }

      onChange?.(trimmedValue);
      onChangeEvent?.({ ...event, currentTarget: { ...event.currentTarget, value: trimmedValue } });

      if (maxLength && trimmedValue && trimmedValue.length >= maxLength) {
        onMaxLengthAchieved?.(trimmedValue);
      }
    };

    const handleOnKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
      const value: string = event.currentTarget.value;

      if (!isKeypressValid(value, event) || trimValue(value) !== value || props.disabled) {
        event.defaultPrevented = true;
        event.preventDefault?.();
        return;
      }

      onKeyPress?.(event);
    };

    const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (!allowArrowKeysPress && isKeyPressIsArrow(event)) {
        event.defaultPrevented = true;
        event.preventDefault?.();
        return;
      }

      onKeyDown?.(event);
    };

    const isKeypressValid = (value: string, keypressEvent: KeyboardEvent<HTMLInputElement>): boolean => {
      return type !== 'number' || isKeyPressNumeric(keypressEvent);
    };

    const isKeyPressNumeric = (event): boolean => {
      let key: string;

      if (event.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
      } else {
        // Handle key press
        const charKey = event.keyCode || event.which;
        key = String.fromCharCode(charKey);
      }

      return isNumeric(key);
    };

    const isKeyPressIsArrow = (event): boolean => {
      return event.key === 'ArrowDown' || event.key === 'ArrowUp';
    };

    const trimValue = (value: string): string => {
      if (maxLength && value.length >= maxLength) {
        return value.substr(0, maxLength);
      }

      return value;
    };

    const isValueValid = (content: string): boolean => {
      return type !== 'number' || isNumeric(content);
    };

    const isNumeric = (content: string): boolean => {
      return /^[0-9\n\r]*$/.test(content);
    };

    const clearContent = (): void => {
      if (clearable && onChange) {
        onChange('');
      }

      if (clearable && onClear) {
        onClear();
      }
    };

    const handleOnFocus = action((event: React.FocusEvent<HTMLInputElement>) => {
      localStore._focused = true;

      onFocus?.(event);
    });

    const colorScheme = useColorScheme(propColorScheme);
    const disabled = disabledProp || state === 'disabled';
    const textColorScheme = useColorScheme(disabled ? 'disabled' : undefined, propTextColorScheme, 'darkGrey');

    const cursorReadOnly = (readOnly || disabled) ?? false;
    const placeholderElementId = `placeholder-of-${name}`;

    return (
      <Container className={className}>
        <StyledValueWithPlaceholder
          colorScheme={colorScheme}
          valueNotEmpty={!!value}
          placeholder={placeholder}
          placeholderStyle={placeholderStyle}
          placeholderVerticalAlign={placeholderVerticalAlign}
          placeholderElementId={placeholderElementId}
          heightType={heightType}
        >
          <Line>
            {localStore.active && prefix && (
              <BodyRegularStartTransparentBlack900.div>{prefix}</BodyRegularStartTransparentBlack900.div>
            )}
            <input
              name={name}
              aria-label={accessibilityLabel}
              aria-labelledby={accessibilityLabel ? undefined : placeholderElementId} // this is a fallback in case the accessibilityLabel is not defined
              data-testid={dataTestId}
              type={type === 'text' ? 'search' : type}
              value={value}
              onChange={(event): void => handleChangeValue(event)}
              disabled={disabled}
              onKeyPress={handleOnKeyPress}
              onKeyDown={handleOnKeyDown}
              onFocus={handleOnFocus}
              onBlur={localStore.setBlur}
              maxLength={maxLength}
              autoFocus={autoFocus}
              readOnly={readOnly || (disableSuggestion && !localStore._focused)}
              className={InternalInputStyle(colorScheme, textColorScheme, cursorReadOnly, inputClassName)}
              autoComplete='off'
              data-lpignore='true' // Prevents lastpass suggestions
              {...rest}
              ref={ref}
            />
            {clearable && localStore.hasValue && (
              <ClickEventPropagationBlocker>
                <ClearButton
                  id={`btn-${name}-clean`}
                  dataTestId={`btn-${name}-clean`}
                  appearance='text'
                  colorScheme='primary'
                  onClick={clearContent}
                  disabled={disabled}
                >
                  <SVG accessibilityLabel={t('general.accessibility.clearText', { placeholder })} image={ClearIcon} height={18} />
                </ClearButton>
              </ClickEventPropagationBlocker>
            )}
          </Line>
        </StyledValueWithPlaceholder>
        {renderSideComponent?.()}
      </Container>
    );
  }),
);

export default NakedFormInput;

const ClearButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(53, 60, 79, 0.4);
  transition: 0.1s all ease-in-out;
  align-self: flex-end;
  padding: 0 3px 1px;
  margin-bottom: 2px;
`;

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
`;

const StyledValueWithPlaceholder = styled(ValueWithPlaceholder)`
  display: flex;
  flex: 1;
  margin-right: 12px;
`;

const Line = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
`;
