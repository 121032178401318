import { getRandomId } from './helpers';

export default class ConsolePerfMonitor implements PerfMonitor {
  traces: Object = {};

  start(eventName: string): number | undefined {
    const id = getRandomId();

    this.traces[id] = eventName;

    console.time(`${id} - ${eventName}`);

    return id;
  }

  stop(id?: number): void {
    if (!id) {
      return;
    }

    console.timeEnd(`${id} - ${this.traces[id]}`);

    delete this.traces[id];
  }

  incrementMetric(id: number | undefined, event: string): void {
    if (!id || !this.traces[id]) {
      return;
    }

    console.info(`${id} - ${this.traces[id]}: ${event}`);
  }
}
