import React, { ReactNode } from 'react';
import { Alert, message } from 'antd';
import styled from '@emotion/styled';
import { AlertProps } from 'antd/lib/alert';
import { capitalizeFirstLetter } from './stringUtils';

export default function showOldAlertMessage(
  text: ReactNode,
  type: NonNullable<AlertProps['type']>,
  duration: number | undefined = 0,
  title: string | undefined,
  messageId: string,
): void {
  // Needed as object to send close function by ref
  const messageRef = {
    onClose: (): void => {},
  };

  // Don't dismiss automatically
  messageRef.onClose = message.open({
    key: messageId,
    duration,
    content: oldAlertMessageContent(text, messageRef, type, duration, title, messageId),
    type: 'info',
    icon: <span />,
  });
}

function oldAlertMessageContent(
  text: ReactNode,
  onClose: { onClose: () => void },
  type: AlertProps['type'] = 'error',
  duration: number,
  title: string | undefined,
  messageId: string,
): ReactNode {
  const titleId = `alert-title-${messageId}`;
  const contentId = `alert-description-${messageId}`;

  // If it closes by itself, that means it does not need interaction, therefore it is an alert
  // In case it does need interaction, it is an alertdialog
  const role = duration ? 'alert' : 'alertdialog';

  return (
    <AlertContainer
      id={messageId}
      aria-labelledby={titleId}
      aria-describedby={contentId}
      role={role}
      aria-live={type === 'error' || type === 'warning' ? 'assertive' : 'polite'}
      aria-atomic='true'
    >
      <Alert
        message={<span id={titleId}>{title ?? capitalizeFirstLetter(type)}</span>}
        description={<span id={contentId}>{text}</span>}
        closable
        onClose={(): void => onClose.onClose()}
        type={type}
        showIcon
      />
    </AlertContainer>
  );
}

// Remove ant's message component padding
const AlertContainer = styled.div`
  margin: -10px -16px;
  text-align: left;
  white-space: pre-wrap;

  .ant-alert-message {
    font-weight: bold;
    font-size: 16px;
    color: #1b3445;
  }

  .ant-alert-description {
    font-size: 14px;
    color: #1b3445;
  }

  .ant-alert-error {
    border: solid 1px #bf1b1b;
    background-color: #ffb2b2;

    .ant-alert-icon {
      color: #bf1b1b;
    }
  }

  .ant-alert-warning {
    border: solid 1px #4e2808;
    background-color: #ffe9b3;

    .ant-alert-icon {
      color: #4e2808;
    }
  }

  .ant-alert-info {
    border: solid 1px #2495f0;
    background-color: #c9e7ff;

    .ant-alert-icon {
      color: #2495f0;
    }
  }

  .ant-alert-success {
    border: solid 1px #396b49;
    background-color: #b3ffc9;

    .ant-alert-icon {
      color: #396b49;
    }
  }

  .ant-alert {
    width: 520px;
  }
`;
