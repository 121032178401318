import React, { FunctionComponent, ReactElement } from 'react';
import styled from '@emotion/styled';
import config from '@app/config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useInfraStores from '@app/hooks/useInfraStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useAppStores from '@app/hooks/useAppStores';

const SupplierRegistrationFooter: FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigationStore, languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const currentYear = new Date().getFullYear();

  const renderDot = (): ReactElement => {
    return <DotContainer>⬝</DotContainer>;
  };

  return (
    <Container direction={languageStore.isRTL ? 'rtl' : 'ltr'}>
      <FooterLeft>
        <LTRDiv>© {currentYear} nsKnox Technologies Ltd.</LTRDiv>
        {renderDot()} v{config.version} {renderDot()}
        <FAQLink
          id='vldt-for-supp-wlcm-faq'
          data-testid='vldt-for-supp-wlcm-faq'
          to={navigationStore.generateAccountValidationForSuppliersFAQ(
            navigationStore.isIpsPage,
            supplierRegistrationCustomerDataStore.currentReference?.referenceId ?? null,
          )}
        >
          {t('supplierValidation.faqAbbr')}
        </FAQLink>
      </FooterLeft>
      <FooterRight>
        <ContactUsContainer>{t('supplierValidation.contactNsknox')}</ContactUsContainer>
        accountvalidation@nsknox.net {renderDot()} USA +1 917 695 3505 {renderDot()} UK +44 (0) 20 3151 4055 {renderDot()}{' '}
        {t('supplierValidation.FAQ.other')}&nbsp; <OtherPhone>+972 54 707 3700</OtherPhone>
      </FooterRight>
    </Container>
  );
};

export default SupplierRegistrationFooter;

const Container = styled.footer<{ direction: string }>`
  padding: 8px 37px 0;
  font-size: 13px;
  color: rgba(61, 68, 90, 0.8);
  background-color: transparent;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: #dadada solid 1px;
  direction: ${({ direction }): string => direction};
`;

const FooterLeft = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  align-self: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
`;

const FooterRight = styled.div`
  align-self: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  margin-top: 10px;
`;

const DotContainer = styled.div`
  margin: 0 13px;
`;

const FAQLink = styled(Link)`
  font-size: 14px;
  color: var(--slate-gray-700);
  font-weight: bold;
  text-decoration: none !important;

  &:hover {
    color: var(--action-color);
  }
`;

const ContactUsContainer = styled.div`
  text-decoration: underline;
  margin-right: 13px;
`;

const LTRDiv = styled.div`
  direction: ltr;
`;

const OtherPhone = styled.div`
  direction: ltr !important;
`;
