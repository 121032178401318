import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { BodyRegularStartTransparentBlack600, Regular13TransparentBlack600 } from '@app/components/Text';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim, trimToNull } from '@app/utils/stringUtils';
import * as messageLauncher from '@app/utils/messageLauncher';
import { EMAIL_ID_PATTERN, SUPPLIER_VALIDATION_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import AsyncButton from '@app/components/AsyncButton';
import Log from '@app/libs/logger';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  createSupplierValidationRecordAutomailerUpdateFieldsFromRecord,
  displayDistributionWarningMessage,
  SupplierValidationRecord,
  supplierValidationRecordAllUpdateFieldsEquators,
  SupplierValidationRecordAutomailerUpdateFields,
  transformSupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import Toggle from '@app/components/inputs/Toggle';
import { FormattedDateTime } from '@app/components/Locale';
import {
  automaticEmailStatusSpecialComponents,
  automaticEmailStopReasonSpecialComponents,
} from '@mortee/domain/validationSystemInfoComponents';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import { ALPHANUMERIC_PATTERN_AND_DASH, URL_OPTIONAL_PROTOCOL_REGEX } from '@app/utils/regexUtils';
import useInfraStores from '@app/hooks/useInfraStores';
import { doesPartialHasAllRequiredFields } from '@app/utils/utils';
import useFormChangesStatus from '@app/hooks/useFormChangesStatus';
import {
  ActionsContainer,
  SidePadding,
  TabTitle,
  TransparentCard,
} from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';

interface EditValidationRecordAutomailerFormFields {
  initialEmailId?: string;
  stopEmails: boolean;
  autoMailerManualLink?: string;
  svLinkId?: string;
}

interface Props extends FormComponentProps<EditValidationRecordAutomailerFormFields> {
  record: SupplierValidationRecord;
  className?: string;
  onSaved(updatedRecord: SupplierValidationRecord, userWantsToClose: boolean): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean | null): void;
}

const EditValidationRecordAutomailerTab: FC<Props> = observer((props) => {
  const { record, onSaved, onIsFormUnsavedChanged, className } = props;
  const { userStore } = useInfraStores();

  const { form, showFormErrors, setShowFormErrors, validateFields, isFormInvalid } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);

  const validationRecordBaseData = useMemo((): SupplierValidationRecordAutomailerUpdateFields => {
    return createSupplierValidationRecordAutomailerUpdateFieldsFromRecord(record);
  }, [record]);

  const { amountOfChanges, updateRequest } = useFormChangesStatus(
    validationRecordBaseData,
    form,
    createUpdateRequestAutomatilerUpdateFieldsFromFormFields,
    supplierValidationRecordAllUpdateFieldsEquators,
  );

  const doesFormHaveAnyChanges = !!amountOfChanges;

  useEffect(() => {
    onIsFormUnsavedChanged(doesFormHaveAnyChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged might change on every render
  }, [doesFormHaveAnyChanges]);

  async function onSave(userWantsToClose: boolean): Promise<void> {
    setIsSaving(true);

    try {
      const formFields = await validateFields();
      const formFieldsAllUpdateFields = createUpdateRequestAutomatilerUpdateFieldsFromFormFields(formFields);

      if (!formFieldsAllUpdateFields || !updateRequest) {
        throw new Error('The form is still not valid');
      }

      const serverResponse = await validationSystemServices.updateValidationRecord(record.staticId, updateRequest);
      displayDistributionWarningMessage(serverResponse.warnings);
      const updatedRecord = transformSupplierValidationRecord(serverResponse.record, userStore.user?.id);

      messageLauncher.shoot(
        { type: 'success', duration: 4, closeable: true },
        `Validation ${serverResponse?.record.presentationId} saved successfully`,
      );
      onSaved(updatedRecord, userWantsToClose);
    } catch (e) {
      Log.exception(e);
    } finally {
      setIsSaving(false);
    }
  }

  const fieldDecorators = createFieldDecorators(record);

  return (
    <StyledNsknoxForm form={form} showErrors={showFormErrors} className={className} setShowErrors={setShowFormErrors}>
      <FullHeightVerticalShadowScroller>
        <Content>
          <TabTitle>Automailer</TabTitle>
          <SectionTitle>Information</SectionTitle>
          <AutomailerInnerCard>
            <DataGrid>
              <DataGridRow title='Status' valueDataTest=''>
                {automaticEmailStatusSpecialComponents.render(record.emailStatus) ?? 'Unavailable'}
              </DataGridRow>
              {record.emailStopReason && (
                <DataGridRow title='Stop Reason' valueDataTest=''>
                  {automaticEmailStopReasonSpecialComponents.render(record.emailStopReason)}
                </DataGridRow>
              )}
              <DataGridRow title='Email Count' valueDataTest=''>
                {record.emailCount}
              </DataGridRow>
              {(record.lastEmailTimestamp || record.lastEmailId) && (
                <DataGridRow title='Last Email' valueDataTest=''>
                  {record.lastEmailTimestamp && <FormattedDateTime value={record.lastEmailTimestamp} />}
                  {record.lastEmailId && <div>Email id = {record.lastEmailId}</div>}
                </DataGridRow>
              )}
            </DataGrid>
          </AutomailerInnerCard>
          <SectionTitle>Properties</SectionTitle>
          <FormItemBox fieldName='initialEmailId' fieldDecoratorOptions={fieldDecorators.initialEmailId}>
            <NakedFormInput
              name='inpt-edit-validation-process-initial-email-id'
              dataTestId='inpt-edit-validation-process-initial-email-id'
              type='text'
              placeholder='Email ID'
            />
          </FormItemBox>
          <FormItemBox fieldName='autoMailerManualLink' fieldDecoratorOptions={fieldDecorators.autoMailerManualLink}>
            <NakedFormInput
              name='inpt-edit-validation-process-manual-link'
              dataTestId='inpt-edit-validation-process-manual-link'
              type='text'
              placeholder='Auto Mailer Manual Link'
            />
          </FormItemBox>
          <FormItemBox fieldName='svLinkId' fieldDecoratorOptions={fieldDecorators.svLinkId}>
            <NakedFormInput
              name='inpt-edit-validation-process-sv-link-id'
              dataTestId='inpt-edit-validation-process-sv-link-id'
              type='text'
              placeholder='SV Link ID'
            />
          </FormItemBox>
          <FieldLine>
            <FieldLabel>Stop Emails:</FieldLabel>
            <FormItemBox fieldName='stopEmails' fieldDecoratorOptions={fieldDecorators.stopEmails} appearance='none'>
              <Toggle accessibilityLabel='Toggle stop emails' name='toggle-edit-validation-process-stop-emails' />
            </FormItemBox>
          </FieldLine>
        </Content>
      </FullHeightVerticalShadowScroller>
      <ActionsContainer>
        <StyledSaveButton
          id='btn-edit-validation-process-save-and-close'
          onClick={(): Promise<void> => onSave(true)}
          disabled={isFormInvalid || isSaving || !doesFormHaveAnyChanges}
          onDisabledClick={(): void => setShowFormErrors('all')}
          appearance='text'
        >
          SAVE AND CLOSE
        </StyledSaveButton>
        <StyledSaveButton
          id='btn-edit-validation-process-save'
          onClick={(): Promise<void> => onSave(false)}
          disabled={isFormInvalid || isSaving || !doesFormHaveAnyChanges}
          onDisabledClick={(): void => setShowFormErrors('all')}
        >
          SAVE
        </StyledSaveButton>
      </ActionsContainer>
    </StyledNsknoxForm>
  );
});

function createUpdateRequestAutomatilerUpdateFieldsFromFormFields(
  formFields: Partial<EditValidationRecordAutomailerFormFields>,
): SupplierValidationRecordAutomailerUpdateFields | null {
  const onlyFieldsThatShouldBeNonNull: PartialNotOptional<NonNullableFields<EditValidationRecordAutomailerFormFields>> = {
    stopEmails: formFields.stopEmails,
  };

  // Some fields that should be not null are not ready at the loading of the form
  // This function makes sure that these required fields are there
  if (!doesPartialHasAllRequiredFields<EditValidationRecordAutomailerFormFields>(formFields, onlyFieldsThatShouldBeNonNull)) {
    return null;
  }

  return {
    initialEmailId: trimToNull(formFields.initialEmailId),
    stopEmails: formFields.stopEmails ?? null,
    autoMailerManualLink: trimToNull(formFields.autoMailerManualLink),
    svLinkId: trimToNull(formFields.svLinkId),
  };
}

function createFieldDecorators(record: SupplierValidationRecord): FormFieldDecorators<EditValidationRecordAutomailerFormFields> {
  return {
    initialEmailId: {
      initialValue: record.initialEmailId ?? undefined,
      rules: [
        {
          pattern: EMAIL_ID_PATTERN,
          transform: trim,
          message: `Invalid email id`,
        },
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.emailId,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.emailId} characters`,
        },
      ],
    },
    stopEmails: {
      initialValue: record.stopEmails,
      rules: [
        {
          type: 'boolean',
          message: 'Invalid Value',
        },
      ],
    },
    autoMailerManualLink: {
      initialValue: record.autoMailerManualLink ?? undefined,
      rules: [
        {
          transform: trim,
          pattern: URL_OPTIONAL_PROTOCOL_REGEX,
          message: 'Invalid SV Portal URL',
        },
      ],
    },
    svLinkId: {
      initialValue: record.svLinkId ?? undefined,
      rules: [
        {
          transform: trim,
          pattern: ALPHANUMERIC_PATTERN_AND_DASH,
          message: 'Invalid SV Link ID',
        },
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.svLinkId,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.svLinkId} characters`,
        },
      ],
    },
  };
}

export default Form.create<Props>()(EditValidationRecordAutomailerTab);

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const FullHeightVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled(SidePadding)`
  flex: 1;
`;

const SectionTitle = styled.h2`
  ${Regular13TransparentBlack600.css};

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const StyledSaveButton = styled(AsyncButton)`
  padding: 9px 43px;
  margin-left: 17px;
`;

const FieldLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FieldLabel = styled(BodyRegularStartTransparentBlack600.div)`
  // To align with the form item bottom margin (that it needs for error messages)
  margin-bottom: 25px;
`;

const AutomailerInnerCard = styled(TransparentCard)`
  padding: 8px 26px 18px;
`;
