import { getCountryName } from '@app/domain/countries';

export interface LegalEntityIdentifierServerResponse {
  countryCode: string | undefined | null;
  typeName: string | undefined | null;
  typeId: string | undefined | null;
  value: string;
}

export interface LegalEntityIdentifier {
  countryCode: string | undefined | null;
  typeName: string | undefined | null;
  typeId: string | undefined | null;
  value: string;
}

export enum StrongLegalIdentifiesTypes {
  KnoxId = 'KnoxId',
  DUNS = 'DUNS',
  TaxId = 'TaxId',
}

export type StrongLegalIdentifiesServerResponse = Partial<
  Record<StrongLegalIdentifiesTypes, LegalEntityIdentifierServerResponse[]>
>;

export type StrongLegalIdentifies = Partial<Record<StrongLegalIdentifiesTypes, LegalEntityIdentifier[]>>;

export function getFormattedLEI(lei: LegalEntityIdentifier, displayCountryCode: boolean = false): string {
  if (!lei.typeName?.trim()) {
    return getFormattedLEIWithoutType(lei, displayCountryCode);
  }

  if (displayCountryCode && lei.countryCode) {
    return `${lei.value} (${lei.typeName}-${getCountryName(lei.countryCode)})`;
  }

  return `${lei.value} (${lei.typeName})`;
}

export function getFormattedLEIWithoutType(lei: LegalEntityIdentifier, displayCountryCode: boolean = false): string {
  if (displayCountryCode && lei.countryCode) {
    return `${lei.value} (${getCountryName(lei.countryCode)})`;
  }

  return lei.value;
}

export function removeStrongIds(
  list: LegalEntityIdentifier[] | undefined,
  strongIdsList: LegalEntityIdentifier[] | undefined,
): LegalEntityIdentifier[] {
  return list?.filter((lei) => !strongIdsList?.find((strong) => strong.typeId === lei.typeId)) ?? [];
}

export const orderedStrongLeisDefinitions: { type: StrongLegalIdentifiesTypes; name: string; formatWithCountry: boolean }[] = [
  { type: StrongLegalIdentifiesTypes.KnoxId, name: 'KnoxID', formatWithCountry: false },
  { type: StrongLegalIdentifiesTypes.DUNS, name: 'DUNS', formatWithCountry: false },
  { type: StrongLegalIdentifiesTypes.TaxId, name: 'Tax ID', formatWithCountry: true },
];
