import { trim } from '@app/utils/stringUtils';
import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { ACCOUNT_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { onChangeValidateOtherFields, requireOnOtherFieldFull } from '@app/utils/validators';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { CURRENCIES_KEYS } from '@app/domain/currencies';

function getBankCodeFields(form: WrappedFormUtils<BaseFormFields>): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-TW-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.TW.bank,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.TW.bank,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.TW.errors.invalidBankCode`,
          'supplierValidation.supplierRegister.errors.invalidBankCode',
        ],
      },
      {
        transform: trim,
        validator: requireOnOtherFieldFull(
          form,
          'branchCode',
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.TW.errors.missingBankCode`,
            'supplierValidation.supplierRegister.errors.missingBankCode',
          ]),
        ),
      },
      {
        validator: onChangeValidateOtherFields<BaseFormFields>(form, 'branchCode'),
      },
    ],
  };
}

function getBranchCodeFields(form: WrappedFormUtils<BaseFormFields>): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-TW-branch-code',
    inputType: 'number',
    decoratorRules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.TW.branch,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.TW.branch,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.TW.errors.invalidBranchCode`,
          'supplierValidation.supplierRegister.errors.invalidBranchCode',
        ],
      },
      {
        transform: trim,
        validator: requireOnOtherFieldFull(
          form,
          'bankCode',
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.TW.errors.missingBranchCode`,
            'supplierValidation.supplierRegister.errors.missingBranchCode',
          ]),
        ),
      },
      {
        validator: onChangeValidateOtherFields<BaseFormFields>(form, 'bankCode'),
      },
    ],
  };
}

function getAdditionalSwiftRules(form: WrappedFormUtils<BaseFormFields>): ConditionalValidationRule[] {
  return [
    {
      required: form.getFieldValue('currency') === CURRENCIES_KEYS.USD,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const TW: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
  branchCode: getBranchCodeFields,
  additionalCompanyName: true,
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
};
