import { getCountryByCountryCode } from '@app/domain/countries';
import { mapValues, removeEmptyFields } from '@app/utils/utils';
import { trimToNull } from '@app/utils/stringUtils';
import { bankBranchCountrySpecificLogic, BankBranchData } from '@app/domain/bankBranchHelpers';

export enum AccountType {
  // Japan
  Futsu = 'Futsu',
  Touza = 'Touza',
  Chochiku = 'Chochiku',

  // Brazil
  Checking = 'Checking',
  Saving = 'Saving',

  // Chile
  CuentaVista = 'CuentaVista',
  CheckingCL = 'CheckingCL',
  SavingCL = 'SavingCL',
}

const SWIFT_CODE_DEFAULT_SUFFIX = 'XXX';

function cleanBankBranchData(countryCode: string | undefined | null, bankBranchData: BankBranchData): BankBranchData {
  if (!countryCode) {
    return bankBranchData;
  }

  return bankBranchCountrySpecificLogic[countryCode]?.(bankBranchData) ?? bankBranchData;
}

// Use this function to remove spaces, dashes and leading zero from strings
function cleanString(str?: string | null): string | null {
  const stringWithoutSpacesAndDashes = str?.toUpperCase().replace(/[ -]/g, '');

  if (!stringWithoutSpacesAndDashes) {
    return null;
  }

  const isAllZeros = !stringWithoutSpacesAndDashes.match(/[^0]/);
  if (isAllZeros) {
    return stringWithoutSpacesAndDashes;
  }

  return stringWithoutSpacesAndDashes.replace(/^0+/, '');
}

function cleanCountryCode(countryCode: string | null | undefined): string | null {
  return countryCode?.toUpperCase().replace(/[ \-0-9]/g, '') || null;
}

const cleanAccountDetailsValues = (accountDetails: MorteeAccountDetails): MorteeAccountDetails => {
  const bankBranchData = cleanBankBranchData(accountDetails.countryCode, accountDetails);
  return {
    bankCode: bankBranchData.bankCode,
    branchCode: bankBranchData.branchCode,
    iban: cleanString(accountDetails.iban),
    swiftCode: cleanString(accountDetails.swiftCode),
    accountNumber: cleanString(accountDetails.accountNumber),
    countryCode: cleanCountryCode(accountDetails.countryCode),
  };
};

function cleanDomesticRepresentationAccountDetailsValues(
  representationAccountDetails: MorteeDomesticAccountDetails,
): MorteeDomesticAccountDetails {
  const cleanedCountryCode = cleanCountryCode(representationAccountDetails.countryCode);
  const bankBranchData = cleanBankBranchData(cleanedCountryCode, representationAccountDetails);

  return {
    type: 'domestic',
    countryCode: cleanedCountryCode,
    bankCode: bankBranchData.bankCode,
    branchCode: bankBranchData.branchCode,
    accountNumber: cleanString(representationAccountDetails.accountNumber),
  };
}

const cleanRepresentationAccountDetailsValues = (
  representationAccountDetails: MorteeRepresentationAccountDetails,
): MorteeRepresentationAccountDetails => {
  switch (representationAccountDetails.type) {
    case 'iban': {
      return {
        ...representationAccountDetails,
        iban: cleanString(representationAccountDetails.iban),
      };
    }
    case 'domestic': {
      return cleanDomesticRepresentationAccountDetailsValues(representationAccountDetails);
    }
    case 'swift': {
      return {
        ...representationAccountDetails,
        swiftCode: cleanString(representationAccountDetails.swiftCode),
        accountNumber: cleanString(representationAccountDetails.accountNumber),
      };
    }
    case 'swiftban': {
      return {
        ...representationAccountDetails,
        iban: cleanString(representationAccountDetails.iban),
        swiftCode: cleanString(representationAccountDetails.swiftCode),
      };
    }
  }
};

function cleanSwiftSuffix(swiftCode: string): string;
function cleanSwiftSuffix(swiftCode: string | null): string | null;
function cleanSwiftSuffix(swiftCode: string | null): string | null {
  return swiftCode?.toUpperCase().replace(/[ -]/g, '').replace(/^0/, '').slice(0, 8) || null;
}

export const cleanNoSwiftSuffixAccountDetails = (accountDetails: MorteeAccountDetails): MorteeAccountDetails => {
  return {
    ...cleanAccountDetailsValues(accountDetails),
    swiftCode: cleanSwiftSuffix(accountDetails.swiftCode),
  };
};

export const cleanNoSwiftSuffixRepresentationAccountDetails = (
  accountRepresentationDetails: MorteeRepresentationAccountDetails,
): MorteeRepresentationAccountDetails => {
  const cleanRepresentation = cleanRepresentationAccountDetailsValues(accountRepresentationDetails);

  if (cleanRepresentation.type !== 'swift' && cleanRepresentation.type !== 'swiftban') {
    return cleanRepresentation;
  }

  return {
    ...cleanRepresentation,
    swiftCode: cleanSwiftSuffix(cleanRepresentation.swiftCode),
  };
};

export function removeIrrelevantAccountDetailsFields(
  accountDetails: Partial<MorteeAccountDetails>,
): Partial<MorteeAccountDetails> {
  return removeEmptyFields(mapValues(accountDetails, trimToNull));
}

export function extractIbanCountryCode(iban: string | undefined | null): string | undefined {
  return iban?.substr?.(0, 2);
}

export function extractSwiftCodeCountryCode(swiftCode?: string): string | null {
  if (!swiftCode) {
    return null;
  }

  return swiftCode.toString().substring(4, 6).toUpperCase() || null;
}

export function extractValidSwiftCodeCountryCode(swiftCode?: string): string | null {
  const swiftCountryCode = extractSwiftCodeCountryCode(swiftCode);

  if (!swiftCountryCode || !getCountryByCountryCode(swiftCountryCode)) {
    return null;
  }

  return swiftCountryCode;
}

export const doesAccountDetailsContainOther = (
  accountDetails: MorteeAccountDetails,
  containedAccountDetails: MorteeAccountDetails,
): boolean => {
  // all account values are empty
  if (Object.values(containedAccountDetails).every((value) => !value)) {
    return false;
  }

  return Object.entries(containedAccountDetails).every(([key, valueFromContained]) => {
    if (!valueFromContained) {
      return true;
    }

    const valueFromContainer = accountDetails[key];
    return valueFromContainer === valueFromContained;
  });
};

export const calculateValidatedSwiftCodeToDisplay = (
  validatedSwift?: string | null,
  otherSwiftCode?: string | null,
): string | null => {
  const [validatedFullCleanedSwiftCode, validatedSwiftCodePrefix, validatedSwiftCodePostfix] = splitSwiftCodeToPrefixAndPostfix(
    validatedSwift,
  );
  const [, otherSwiftCodePrefix, otherSwiftCodePostfix] = splitSwiftCodeToPrefixAndPostfix(otherSwiftCode);

  // Basic "deal-breaker" cases
  // 1. the other swift code is not defined
  // 2. the swift code prefix is different
  // 3. the validated swift code postfix is not defined (therefore there is no logic to do)
  if (!otherSwiftCodePrefix || validatedSwiftCodePrefix !== otherSwiftCodePrefix || !validatedSwiftCodePostfix) {
    return validatedFullCleanedSwiftCode ?? null;
  }

  if (validatedSwiftCodePostfix === SWIFT_CODE_DEFAULT_SUFFIX) {
    // Return the full swift code in case the other postfix is not defined or it is default
    if (otherSwiftCodePostfix === SWIFT_CODE_DEFAULT_SUFFIX || !otherSwiftCodePostfix) {
      return validatedFullCleanedSwiftCode;
    }

    // If the other postfix is other then default - omit the swift code prefix
    return validatedSwiftCodePrefix;
  }

  // In this part of the code we know that the validated postfix is not empty or default
  if (otherSwiftCodePostfix === validatedSwiftCodePostfix) {
    return validatedFullCleanedSwiftCode;
  }

  // In case they are different - omit the swift code prefix
  return validatedSwiftCodePrefix;
};

export function splitSwiftCodeToPrefixAndPostfix(
  swiftCode: string | null | undefined,
): [string | null, string | null, string | null] {
  const cleanedSwiftCode = cleanString(swiftCode ?? null);

  if (!cleanedSwiftCode) {
    return [null, null, null];
  }

  const prefix = cleanedSwiftCode?.substr(0, 8) || null;
  const postfix = cleanedSwiftCode?.substr(8, 3) || null;

  return [cleanedSwiftCode, prefix, postfix];
}
