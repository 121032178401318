import { getAccountRepresentations } from '@app/domain/accountDetailsRepresentations';
import { isTruthy } from '@app/utils/utils';

export type AccountRepresentationFormatter = (
  account: Partial<MorteeRepresentationAccountDetails> | Partial<MorteeAccountDetails>,
) => string;

const MISSING_INFO_TEXT = 'Missing info';
const MISSING_VALUE_TEXT = '?';

export const formatSwiftAccount: AccountRepresentationFormatter = (
  accountDetails: Partial<MorteeSwiftAccountDetails>,
): string => {
  return appendMissingInfo(
    `${orMissing(accountDetails.swiftCode)} - ${orMissing(accountDetails.accountNumber)}`,
    accountDetails.swiftCode,
    accountDetails.accountNumber,
  );
};

export const formatIBANAccount: AccountRepresentationFormatter = (accountDetails: Partial<MorteeIBANAccountDetails>): string => {
  const formattedAccount = orMissing(accountDetails.iban);
  return appendMissingInfo(formattedAccount, accountDetails.iban);
};

export const formatSwiftbanAccount: AccountRepresentationFormatter = (
  accountDetails: Partial<MorteeSwiftbanAccountDetails>,
): string => {
  const formattedAccount = `${orMissing(accountDetails.swiftCode)} - ${orMissing(accountDetails.iban)}`;
  return appendMissingInfo(formattedAccount, accountDetails.swiftCode, accountDetails.iban);
};

export const formatBICAccount: AccountRepresentationFormatter = (
  accountDetails: Partial<MorteeSwiftbanAccountDetails>,
): string => {
  const formattedAccount = `${orMissing(accountDetails.swiftCode)}`;
  return appendMissingInfo(formattedAccount, accountDetails.swiftCode);
};

export const formatDomesticAccount: AccountRepresentationFormatter = (
  accountDetails: Partial<MorteeDomesticAccountDetails>,
): string => {
  const countryCodeText = accountDetails.countryCode ? ` (${accountDetails.countryCode})` : '';
  const bankAndBranchCode = [accountDetails.bankCode, accountDetails.branchCode].filter(isTruthy).join(' - ');
  const formattedAccount = `${orMissing(bankAndBranchCode)} - ${orMissing(accountDetails.accountNumber)}${countryCodeText}`;
  return appendMissingInfo(formattedAccount, bankAndBranchCode, accountDetails.accountNumber);
};

const appendMissingInfo = (formattedAccount: string, ...requiredValues: (string | null | undefined)[]): string => {
  if (requiredValues?.length && requiredValues?.every(isTruthy)) {
    return formattedAccount;
  }

  return `${formattedAccount} (${MISSING_INFO_TEXT})`;
};

const orMissing = (value: string | null | undefined): string => {
  return value || MISSING_VALUE_TEXT;
};

export const getRepresentationFormatterWithSwiftban: (
  representation: AccountRepresentationType,
) => AccountRepresentationFormatter = (representation: AccountRepresentationType) => {
  switch (representation) {
    case 'iban': {
      return formatIBANAccount;
    }
    case 'swift': {
      return formatSwiftAccount;
    }
    case 'domestic': {
      return formatDomesticAccount;
    }
    case 'swiftban': {
      return formatSwiftbanAccount;
    }
  }
};

export const getRepresentationFormatterWithBIC: (representation: AccountRepresentationType) => AccountRepresentationFormatter = (
  representation: AccountRepresentationType,
) => {
  switch (representation) {
    case 'iban': {
      return formatIBANAccount;
    }
    case 'swift': {
      return formatSwiftAccount;
    }
    case 'domestic': {
      return formatDomesticAccount;
    }
    case 'swiftban': {
      return formatBICAccount;
    }
  }
};

const INVALID_ACCOUNT_TEXT = 'Invalid Account';

export const formatAccountWithSwiftban = (
  accountDetails?: MorteeAccountDetailsExtended | MorteeAccountDetailsExtended[] | null,
): string[] => {
  if (!accountDetails) {
    return [INVALID_ACCOUNT_TEXT];
  }

  if (Array.isArray(accountDetails)) {
    return accountDetails.flatMap((account) => formatAccountWithSwiftban(account));
  }

  const accountRepresentationsToShow = getAccountRepresentations(accountDetails).toShow;

  const formattedAccounts = accountRepresentationsToShow?.map((representation) =>
    getRepresentationFormatterWithSwiftban(representation)(accountDetails),
  );

  return formattedAccounts?.length ? formattedAccounts : [INVALID_ACCOUNT_TEXT];
};

export const formatRepresentationWithSwiftban = (representation: MorteeRepresentationAccountDetails): string => {
  return getRepresentationFormatterWithSwiftban(representation.type)(representation);
};

export const formatAccountWithBIC = (
  accountDetails?: MorteeAccountDetailsExtended | MorteeAccountDetailsExtended[] | null,
): string[] => {
  if (!accountDetails) {
    return [INVALID_ACCOUNT_TEXT];
  }

  if (Array.isArray(accountDetails)) {
    return accountDetails.flatMap((account) => formatAccountWithBIC(account));
  }

  const accountRepresentationsToShow = getAccountRepresentations(accountDetails).toShow;

  const formattedAccounts = accountRepresentationsToShow?.map((representation) =>
    getRepresentationFormatterWithBIC(representation)(accountDetails),
  );

  return formattedAccounts?.length ? formattedAccounts : [INVALID_ACCOUNT_TEXT];
};
