import React, { FC, useState } from 'react';
import Loadable from '@app/utils/Loadable';
import { OrganizationalUser } from '@app/domain/userManagement/organizationUsers';
import { PaginationConfig } from 'antd/lib/table';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { ExtendedColumnProps, TableImportantText, TableStandardText } from '@app/components/tables/Table';
import { pluralize } from '@app/utils/stringUtils';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import TableOfCards from '@app/components/tables/TableOfCards';
import getColumnMultiValueArrayFieldSelectProps from '@app/components/tableFilters/multiValue/byArrayFieldFilterCreator';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import OrganizationalUserActionsMenu, {
  OrganizationalUserActionsPermissions,
} from '@app/components/userManagement/organizationUsersTable/OrganizationalUserActionsMenu';
import styled from '@emotion/styled';
import UserPermissionsModal from '@app/components/userManagement/organizationUsersTable/UserPermissionsModal';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import { AllKnoxers } from '@app/domain/userManagement/knoxers';
import { KnoxerIdentityProviders } from '@app/domain/userManagement/identityProviders';
import { ifNotProp } from 'styled-tools';
import { compare } from '@app/utils/comparatorUtils';
import useControlledModal from '@app/hooks/useControlledModal';
import { getOrganizationWebAppRoleTypes } from '@app/services/userManagement/oragnizationRoleTypesService';
import { UserRoleType } from '@app/domain/userManagement/roleTypes';
import useLoadable from '@app/hooks/loadable/useLoadable';

interface OrganizationUsersTableProps {
  id: string;
  organizationId: string;
  dataTestId?: string;
  usersLoadable: Loadable<OrganizationalUser[]>;
  knoxers: AllKnoxers;
  organizationIdentityProviders: KnoxerIdentityProviders[];
  canSuspendUserFromOrganization: boolean;
  canKillUserSessions: boolean;
  canEditUserPermissions: boolean;
  className?: string;
  suspendUserFromOrganization(tenantUserId: string, userName: string | null): void;
  killUserSessions(tenantUserId: string, userName: string | null): void;
  onUserUpdated(organizationalUser: OrganizationalUser): void;
}

const OrganizationUsersTable: FC<OrganizationUsersTableProps> = ({
  id,
  organizationId,
  dataTestId = id,
  usersLoadable,
  knoxers,
  organizationIdentityProviders,
  canSuspendUserFromOrganization,
  canKillUserSessions,
  canEditUserPermissions,
  suspendUserFromOrganization,
  killUserSessions,
  onUserUpdated,
  className,
}) => {
  const modalContext = useModalContext();
  const [filteredData, searchTerm, setSearchTerm] = useLoadableSearchFilter(usersLoadable, {
    exactMatches(user) {
      return [user.tenantUserId];
    },
    partialMatches(user) {
      return [user.name, user.email, user.phoneNumber];
    },
  });

  const [organizationUserRoleType] = useLoadable(async (): Promise<UserRoleType[]> => {
    const organizationRoleTypesServerResponse = await getOrganizationWebAppRoleTypes(organizationId);
    return organizationRoleTypesServerResponse.sort((roleType1, roleType2) => roleType1.id.localeCompare(roleType2.id));
  }, [organizationId]);

  const [userPermissionsModalUser, setUserPermissionsModalUser] = useState<OrganizationalUser | null>(null);
  useControlledModal(
    !!userPermissionsModalUser,
    (onDone) => {
      if (!userPermissionsModalUser) {
        return <div />;
      }

      return (
        <ModalAppContext {...modalContext}>
          <UserPermissionsModal
            organizationId={organizationId}
            organizationalUser={userPermissionsModalUser}
            userRoleTypes={organizationUserRoleType}
            canEdit={canEditUserPermissions}
            onUserUpdated={onUserUpdated}
            onDone={(): void => onDone(true)}
          />
        </ModalAppContext>
      );
    },
    (): void => {
      setUserPermissionsModalUser(null);
    },
    {},
    [organizationId, userPermissionsModalUser, organizationUserRoleType],
  );

  const showUserPermissionsModal = (organizationalUser: OrganizationalUser): void => {
    setUserPermissionsModalUser(organizationalUser);
  };

  const paginationConfig: PaginationConfig = {
    position: 'both',
    defaultPageSize: 50,
  };

  return (
    <div className={className}>
      <InputBox appearance='line' colorScheme='secondary'>
        <NakedFormInput
          name={`${id}-search`}
          dataTestId={`${dataTestId}-search`}
          type='text'
          colorScheme='primary'
          placeholderStyle='onlyWhenEmpty'
          heightType='thick'
          clearable
          placeholder='🔍 Search for Id / Name / Email / Phone number'
          value={searchTerm}
          onChange={(newValue): void => setSearchTerm(newValue ?? '')}
          autoFocus
        />
      </InputBox>
      <TableOfCards
        id={id}
        data-testid={dataTestId}
        columns={createColumns(
          filteredData.result ?? [],
          {
            canSuspendUserFromOrganization,
            canKillUserSessions,
          },
          suspendUserFromOrganization,

          killUserSessions,
          showUserPermissionsModal,
          knoxers,
          organizationIdentityProviders,
        )}
        dataSource={filteredData.result ?? []}
        loading={!filteredData.isResolved()}
        pagination={paginationConfig}
        rowKey={(organizationalUser: OrganizationalUser): string => organizationalUser.tenantUserId}
      />
    </div>
  );
};

export default OrganizationUsersTable;

function renderSSOIdentityProvidersOfKnoxer(
  record: OrganizationalUser,
  knoxerId: string,
  knoxerIdentityProviders: KnoxerIdentityProviders[],
): React.ReactNode {
  const ssoIdsOfKnoxer = record.ssoUserIds.find((knoxerIdentityProviders) => knoxerIdentityProviders.knoxerId === knoxerId);
  const identityProvidersNamesOfKnoxer =
    knoxerIdentityProviders
      .find((knoxerIdentityProvider) => knoxerIdentityProvider.knoxerId === knoxerId)
      ?.identityProviders.map((value) => value.name) ?? [];

  return ssoIdsOfKnoxer?.identities
    .map((userIdentityProviderData) => ({
      ...userIdentityProviderData,
      isIdentityProviderDefinedForOrganization: identityProvidersNamesOfKnoxer.includes(
        userIdentityProviderData.identityProviderName,
      ),
    }))
    .sort(compare.byField((first) => first.isIdentityProviderDefinedForOrganization, compare.booleans()))
    .map((userIdentityProviderData) => (
      <UserIdentity
        key={userIdentityProviderData.identityProviderName}
        isIdentityProviderDefinedForOrganization={userIdentityProviderData.isIdentityProviderDefinedForOrganization}
      >{`${userIdentityProviderData.id} | ${userIdentityProviderData.identityProviderName}`}</UserIdentity>
    ));
}

function createColumns(
  data: OrganizationalUser[],
  actionsPermissions: OrganizationalUserActionsPermissions,
  suspendUserFromOrganization: OrganizationUsersTableProps['suspendUserFromOrganization'],
  killUserSessions: OrganizationUsersTableProps['killUserSessions'],
  showUserPermissionsModal: (organizationalUser: OrganizationalUser) => void,
  knoxers: AllKnoxers,
  knoxerIdentityProviders: KnoxerIdentityProviders[],
): ExtendedColumnProps<OrganizationalUser>[] {
  const renderPermissionsChip = (text: any, record: OrganizationalUser): React.ReactNode => {
    let chipText = 'Set permissions';
    if (record?.allActiveAppRoleIds?.length !== 0) {
      chipText = `${record.allActiveAppRoleIds.length} ${pluralize('Permission', record.allActiveAppRoleIds.length)}`;
    }
    return (
      <PermissionsButton
        data-testid={`permissions-chip-${record.tenantUserId}`}
        onClick={(): void => showUserPermissionsModal(record)}
      >
        {chipText}
      </PermissionsButton>
    );
  };

  return [
    {
      title: <div data-testid='lblName'>Name</div>,
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: compare.byStringField((record) => record.name),
      render: (text: any, record: OrganizationalUser): React.ReactNode => <TableImportantText>{record.name}</TableImportantText>,
      ...getColumnTextSearchProps((record) => record.name),
    },
    {
      title: <div data-testid='lblEmail'>{knoxers.emailKnoxer.name} Knoxer</div>,
      dataIndex: 'emailKnoxer',
      key: 'emailKnoxer',
      sorter: compare.byStringField((record) => record.email),
      render: (text: any, record: OrganizationalUser): React.ReactNode => (
        <div>
          {record.email && <TableStandardText>{record.email} | nsKnox Email Auth</TableStandardText>}
          {renderSSOIdentityProvidersOfKnoxer(record, knoxers.emailKnoxer.id, knoxerIdentityProviders)}
        </div>
      ),
      ...getColumnTextSearchProps((record) => record.email),
    },
    {
      title: <div data-testid='lblPhoneNumber'>{knoxers.phoneKnoxer.name} Knoxer</div>,
      dataIndex: 'phoneKnoxer',
      key: 'phoneKnoxer',
      sorter: compare.byStringField((record) => record.phoneNumber),
      render: (text: any, record: OrganizationalUser): React.ReactNode => (
        <div>
          {record.phoneNumber && <TableStandardText>{record.phoneNumber} | nsKnox Phone Auth</TableStandardText>}
          {renderSSOIdentityProvidersOfKnoxer(record, knoxers.phoneKnoxer.id, knoxerIdentityProviders)}
        </div>
      ),
      ...getColumnTextSearchProps((record) => record.phoneNumber),
    },
    {
      title: <div data-testid='lblPermissions'>Permissions</div>,
      dataIndex: 'permissions',
      key: 'permissions',
      sorter: (record1, record2): number => record1.allActiveAppRoleIds.length - record2.allActiveAppRoleIds.length,
      render: renderPermissionsChip,
      ...getColumnMultiValueArrayFieldSelectProps<OrganizationalUser>(data, (record) => record.allActiveAppRoleIds),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '60px',
      hidden: Object.values(actionsPermissions).every((permission) => permission === false),
      render: (text: any, record: OrganizationalUser): React.ReactNode => {
        return (
          <OrganizationalUserActionsMenu
            tenantUserId={record.tenantUserId}
            permissions={actionsPermissions}
            suspendUserFromOrganization={(): void => suspendUserFromOrganization(record.tenantUserId, record.name)}
            killUserSessions={(): void => killUserSessions(record.tenantUserId, record.name)}
          />
        );
      },
    },
  ];
}

const PermissionsButton = styled.div`
  width: 128px;
  border-radius: 12px;
  background-color: var(--accent-blue-600);
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: var(--white);
`;

const UserIdentity = styled(TableStandardText)<{ isIdentityProviderDefinedForOrganization: boolean }>`
  ${ifNotProp(
    'isIdentityProviderDefinedForOrganization',
    `
  color: var(--transparent-black-400);
  `,
  )}
`;
