import React, { FunctionComponent, ReactElement, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import MvfCheckReportTable from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/MvfCheckReportTable';
import styled from '@emotion/styled';
import InProgressImage from '@app/images/web-maintenance.svg';
import downloadFile from '@app/images/download-file-primary.svg';
import SVG from '@app/components/SVG';
import Card from '@app/components/card/Card';
import config from '@app/config';
import DownloadableResource from '@app/components/DownloadableResource';
import MorteeMode from '@mortee/morteeMode';
import useAppStores from '@app/hooks/useAppStores';
import useInterval from '@app/hooks/useInterval';
import { FormattedDateTime } from '@app/components/Locale';
import useMountEffect from '@app/hooks/useMountEffect';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';
import * as mvfCheckServices from '@mortee/services/mvfCheckServices';
import AsyncButton from '@app/components/AsyncButton';
import { FileSurveyReport } from '@mortee/domain/mvfCheck';

const MvfCheckReportPage: FunctionComponent = observer(() => {
  const { masterDataGuardStore } = useAppStores<MorteeMode>();
  const { selectedSurvey, reportLoadingState, selectedChangelogReport, lastSyncInfo } = masterDataGuardStore;

  useMountEffect(() => {
    // if unloaded, load (for example, when arriving from a direct URL or explicit refresh, and not from "portal-flow").
    // if in progress, do one fetch before "auto-refresh", which will apply only in mvfCheckInProgressSurveysPollingIntervalSec from now.
    if (selectedSurvey && (!selectedSurvey.loaded || selectedSurvey.isInProgress)) {
      masterDataGuardStore.getFileSurveyById(selectedSurvey.id);
    }
  });

  const surveyIsNotInProgress = selectedSurvey && selectedSurvey.loaded && !selectedSurvey.isInProgress;

  useEffect(() => {
    if (selectedSurvey && selectedSurvey.loaded && !selectedSurvey.isInProgress) {
      masterDataGuardStore.loadSurveyCheckReport(selectedSurvey.id);
    }
  }, [masterDataGuardStore, selectedSurvey, selectedSurvey?.id, surveyIsNotInProgress]);

  useInterval(
    () => {
      const { selectedSurvey } = masterDataGuardStore;

      if (selectedSurvey?.isInProgress) {
        masterDataGuardStore.getFileSurveyById(selectedSurvey.id);
      }
    },
    selectedSurvey?.isInProgress ? config.mvfCheckInProgressSurveysPollingIntervalSec * 1000 : null,
  );

  const renderInProgress = (): ReactElement => {
    return (
      <Card>
        <TablePlaceholder>
          <StyledSVG accessibilityLabel='' image={InProgressImage} />
          MVF check is in progress...
        </TablePlaceholder>
      </Card>
    );
  };

  const renderDownloadButton = (surveyId: string, selectedChangelogReport: FileSurveyReport[] | undefined): ReactElement => {
    if (!selectedSurvey?.loaded || !selectedChangelogReport?.length) {
      return <div />;
    }

    return (
      <StyledButton
        id='btn-changelog-download'
        dataTestId='downloadBtn'
        appearance='text'
        size='small'
        onClick={async (): Promise<void> => mvfCheckServices.downloadChangeLogReport(surveyId)}
      >
        <SVG accessibilityLabel='' image={downloadFile} height={20} width={20} />
        Export Report
      </StyledButton>
    );
  };

  const renderFilesList = (surveyId: string, surveyFilesMetadata: FileMetadata[]): React.ReactNode[] => {
    return surveyFilesMetadata.map((fileMetadata) => {
      return (
        <DownloadableResource
          dataTestId='btn-downloadable-resource-download'
          key={fileMetadata.id}
          fileMetadata={fileMetadata}
          fetchFile={(fileId): Promise<NamedResource> => masterDataGuardStore.fetchFileSurveyFile(surveyId, fileId)}
          spinnerWhileDownloading={false}
        >
          <a>{fileMetadata.name}</a>
        </DownloadableResource>
      );
    });
  };

  const renderResults = (): ReactElement => {
    if (!selectedSurvey?.loaded) {
      return <div />;
    }

    const { id, writeTimestamp, performingUserName, data } = selectedSurvey;
    const { vendorFilesMetadata } = data;
    const fileElements: ReactNode[] = renderFilesList(id, vendorFilesMetadata);

    const note = data.note?.trim() ?? `Note wasn't provided`;

    return (
      <SummaryContainer>
        <HeaderRow>
          <SummaryDataTitle>Result:</SummaryDataTitle>
          {renderDownloadButton(id, selectedChangelogReport)}
        </HeaderRow>
        <SummaryDataContainer>
          <SummaryData>
            <SummaryDataLabel>
              Date:
              <SummaryDataText>
                <FormattedDateTime value={writeTimestamp} options={formattedDateOptionsWithHoursAndMinutes} />
              </SummaryDataText>
            </SummaryDataLabel>
            <SummaryDataLabel>
              User:<SummaryDataText>{performingUserName}</SummaryDataText>
            </SummaryDataLabel>
          </SummaryData>
          <VerticalSeparator />
          <SummaryData>
            <SummaryDataLabel>
              <UploadedFiles>
                File{fileElements.length > 1 && 's'} analyzed: {fileElements.map((element) => ['\xA0', element])}
              </UploadedFiles>
            </SummaryDataLabel>
            <SummaryDataLabel>
              Note:<SummaryDataText>{note}</SummaryDataText>
            </SummaryDataLabel>
          </SummaryData>
        </SummaryDataContainer>
      </SummaryContainer>
    );
  };

  const content = selectedSurvey?.isInProgress ? (
    renderInProgress()
  ) : (
    <MvfCheckReportTable
      reportLoadingState={reportLoadingState}
      selectedChangelogReport={selectedChangelogReport}
      lastSyncInfo={lastSyncInfo}
    />
  );

  return (
    <PageContainer>
      {renderResults()}
      {content}
    </PageContainer>
  );
});

export default MvfCheckReportPage;

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  flex-direction: column;
`;

const SummaryContainer = styled.div`
  margin-bottom: 80px;
  margin-left: 24px;
`;

const SummaryDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

const SummaryData = styled.div`
  display: flex;
  flex-direction: column;

  flex: 4;

  &:first-child {
    flex: 1;
  }
`;

const SummaryDataText = styled.span`
  font-size: 15px;
  letter-spacing: 0.42px;
  color: var(--transparent-black-600);
  margin: 4px 0 4px 4px;
`;

const SummaryDataTitle = styled(SummaryDataText)`
  font-size: 32px;
  letter-spacing: 0.44px;
  color: var(--transparent-black-900);
  margin-left: 0;
`;

const SummaryDataLabel = styled(SummaryDataText)`
  font-size: 17px;
  letter-spacing: 0.15px;
  color: var(--transparent-black-900);
  margin-left: 0;
`;

const VerticalSeparator = styled.div`
  background-color: #dcdee8;
  width: 1px;
  margin-right: 32px;
`;

const TablePlaceholder = styled.div`
  background-color: white;
  height: 409px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.44px;
  color: var(--transparent-black-900);
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 84px 0;
`;

const StyledSVG = styled(SVG)`
  height: 170px;
  width: 170px;
  margin-bottom: 33px;
`;

const UploadedFiles = styled.div`
  display: flex;
  text-align: start;
`;

const StyledButton = styled(AsyncButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: var(--primary-200);
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
