import React, { FC, ReactNode } from 'react';
import CardHeader from '@app/components/card/CardHeader';
import MyValidatedPayeeAccountCard from './MyValidatedPayeeAccountCard';
import { createNTimes } from '@app/utils/arrayUtils';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { RowSeparator } from '@app/components/Styles';
import withPlaceholder from '@app/components/withPlaceholder';
import ValidatedPayeeDetails from '@mortee/components/validatedPayee/ValidatedPayeeDetails';
import { ValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';

interface DataProps {
  validatedPayeeWithAccounts: ValidatedPayeeWithAccounts;
  dataTestId: string;
}

interface DisplayProps {
  className?: string;
}

const MyValidatedPayeeWithAccountsCard: FC<DataProps & DisplayProps> = (props) => {
  const { validatedPayeeWithAccounts, dataTestId, className } = props;

  const getPayeeAccounts = (): ReactNode => {
    if (!validatedPayeeWithAccounts.accounts.length) {
      return null;
    }

    return (
      <>
        <PaddedRowSeparator light />
        <CardHeader>VALIDATED BANK ACCOUNTS</CardHeader>
        <PayeeCardContent>
          <AccountsGridContainer>{getPayeeAccountsCards()}</AccountsGridContainer>
        </PayeeCardContent>
      </>
    );
  };

  const getPayeeAccountsCards = (): ReactNode => {
    const { accounts } = validatedPayeeWithAccounts;

    return accounts.map((account) => (
      <MyValidatedPayeeAccountCard
        key={account.uniformId}
        validatedPayeeAccount={account}
        dataTestId={`validated-account-card-${account.uniformId}`}
      />
    ));
  };

  return (
    <PayeeCard className={className} dataTestId={dataTestId}>
      <CardHeader>PAYEE</CardHeader>
      <PayeeCardContent>
        <ValidatedPayeeDetails
          displayNotProvidedValues
          moreInfoHideable
          data={{
            ...validatedPayeeWithAccounts.aggregatedPayeeData,
            sanctionsScreeningInfo: validatedPayeeWithAccounts.payee.sanctionsScreeningInfo,
          }}
        />
      </PayeeCardContent>
      {getPayeeAccounts()}
    </PayeeCard>
  );
};

const Placeholder: FC<DisplayProps> = (props) => {
  const { className } = props;

  const getPayeeCardContent = (): ReactNode => {
    return (
      <PayeeCardContent>
        <ValidatedPayeeDetails loading />
      </PayeeCardContent>
    );
  };

  const getPayeeAccounts = (): ReactNode => {
    return (
      <>
        <PaddedRowSeparator light />
        <CardHeader>VALIDATED BANK ACCOUNTS</CardHeader>
        <PayeeCardContent>
          <AccountsGridContainer>{getPayeeAccountsCards()}</AccountsGridContainer>
        </PayeeCardContent>
      </>
    );
  };

  const getPayeeAccountsCards = (): ReactNode => {
    return createNTimes(3, (index): ReactNode => <MyValidatedPayeeAccountCard key={index} loading />);
  };

  return (
    <PayeeCard className={className}>
      <CardHeader>PAYEE</CardHeader>
      {getPayeeCardContent()}
      {getPayeeAccounts()}
    </PayeeCard>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(MyValidatedPayeeWithAccountsCard, Placeholder);

const PayeeCard = styled(Card)`
  padding: 0;

  --info-row-gap: 10px;
  --card-side-padding: 20px;
`;

const PayeeCardContent = styled.div`
  padding: 0 var(--card-side-padding) 20px;
`;

const AccountsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-auto-rows: 1fr;
  gap: 15px 20px;
`;

const PaddedRowSeparator = styled(RowSeparator)`
  margin: 0 var(--card-side-padding);
`;
