import { MutableRefObject, useLayoutEffect, useState } from 'react';
import { arrayWithoutValue } from '@app/utils/arrayUtils';

type Target = MutableRefObject<HTMLElement | null>;

const handleResize = (targets: Target[]): void => {
  const max = Math.max(
    ...targets.map((element) => {
      return element.current?.clientHeight ?? 0;
    }),
  );

  // update height of all 'joined' elements
  targets.forEach((element) => {
    if (element.current) {
      element.current.style.minHeight = `${max}px`;
    }
  });
};

export interface HeightCoordinator {
  addRef(target: Target): void;
  removeRef(target: Target): void;
}

export const useHeightCoordinator = (): HeightCoordinator => {
  const [targets, setTargets] = useState<Target[]>([]);

  useLayoutEffect(() => {
    handleResize(targets);
  }, [targets]);

  return {
    addRef(target: Target): void {
      setTargets((targets) => [...targets, target]);
    },
    removeRef(target: Target): void {
      setTargets((targets) => arrayWithoutValue(targets, target));
    },
  };
};
