export interface OrganizationServerResponse {
  id: string;
  name: string;
  serviceIds: string[];
}

export interface Organization {
  id: string;
  name: string;
  serviceIds: string[];
}

export interface StoreOrganizationRequest {
  name: string;
  serviceIds: string[];
  knoxerIdentityProviders: StoreKnoxerIdentityProviderForOrganization[];
}

export interface StoreKnoxerIdentityProviderForOrganization {
  knoxerId: string;
  identityProviderNames: string[];
}

export interface ExportedSpreadsheetDTO {
  content: string;
}

export const ORGANIZATION_FIELD_MAX_LENGTH = {
  name: 200,
};
