export interface Currency {
  name: string;
  numericCode: string;
  symbol?: string;
}

export enum CURRENCIES_KEYS {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  UYI = 'UYI',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  XSU = 'XSU',
  XUA = 'XUA',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export const CURRENCIES: Record<CURRENCIES_KEYS, Currency> = {
  AED: { name: 'UAE Dirham', numericCode: '784' },
  AFN: { name: 'Afghani', numericCode: '971' },
  ALL: { name: 'Lek', numericCode: '008' },
  AMD: { name: 'Armenian Dram', numericCode: '051' },
  ANG: { name: 'Netherlands Antillean Guilder', numericCode: '532' },
  AOA: { name: 'Kwanza', numericCode: '973' },
  ARS: { name: 'Argentine Peso', numericCode: '032' },
  AUD: { name: 'Australian Dollar', numericCode: '036', symbol: '$' },
  AWG: { name: 'Aruban Florin', numericCode: '533' },
  AZN: { name: 'Azerbaijanian Manat', numericCode: '944' },
  BAM: { name: 'Convertible Mark', numericCode: '977' },
  BBD: { name: 'Barbados Dollar', numericCode: '052' },
  BDT: { name: 'Taka', numericCode: '050' },
  BGN: { name: 'Bulgarian Lev', numericCode: '975' },
  BHD: { name: 'Bahraini Dinar', numericCode: '048' },
  BIF: { name: 'Burundi Franc', numericCode: '108' },
  BMD: { name: 'Bermudian Dollar', numericCode: '060' },
  BND: { name: 'Brunei Dollar', numericCode: '096' },
  BOB: { name: 'Boliviano', numericCode: '068' },
  BOV: { name: 'Mvdol', numericCode: '984' },
  BRL: { name: 'Brazilian Real', numericCode: '986' },
  BSD: { name: 'Bahamian Dollar', numericCode: '044' },
  BTN: { name: 'Ngultrum', numericCode: '064' },
  BWP: { name: 'Pula', numericCode: '072' },
  BYN: { name: 'Belarussian Ruble', numericCode: '933' },
  BZD: { name: 'Belize Dollar', numericCode: '084' },
  CAD: { name: 'Canadian Dollar', numericCode: '124', symbol: '$' },
  CDF: { name: 'Congolese Franc', numericCode: '976' },
  CHE: { name: 'WIR Euro', numericCode: '947' },
  CHF: { name: 'Swiss Franc', numericCode: '756', symbol: 'Fr.' },
  CHW: { name: 'WIR Franc', numericCode: '948' },
  CLF: { name: 'Unidad de Fomento', numericCode: '990' },
  CLP: { name: 'Chilean Peso', numericCode: '152' },
  CNY: { name: 'Yuan Renminbi', numericCode: '156', symbol: '¥' },
  COP: { name: 'Colombian Peso', numericCode: '170' },
  COU: { name: 'Unidad de Valor Real', numericCode: '970' },
  CRC: { name: 'Costa Rican Colon', numericCode: '188' },
  CUC: { name: 'Peso Convertible', numericCode: '931' },
  CUP: { name: 'Cuban Peso', numericCode: '192' },
  CVE: { name: 'Cabo Verde Escudo', numericCode: '132' },
  CZK: { name: 'Czech Koruna', numericCode: '203', symbol: 'Kč' },
  DJF: { name: 'Djibouti Franc', numericCode: '262' },
  DKK: { name: 'Danish Krone', numericCode: '208', symbol: 'Kr.' },
  DOP: { name: 'Dominican Peso', numericCode: '214' },
  DZD: { name: 'Algerian Dinar', numericCode: '012' },
  EGP: { name: 'Egyptian Pound', numericCode: '818' },
  ERN: { name: 'Nakfa', numericCode: '232' },
  ETB: { name: 'Ethiopian Birr', numericCode: '230' },
  EUR: { name: 'Euro', numericCode: '978', symbol: '€' },
  FJD: { name: 'Fiji Dollar', numericCode: '242' },
  FKP: { name: 'Falkland Islands Pound', numericCode: '238' },
  GBP: { name: 'Pound Sterling', numericCode: '826', symbol: '£' },
  GEL: { name: 'Lari', numericCode: '981' },
  GHS: { name: 'Ghana Cedi', numericCode: '936' },
  GIP: { name: 'Gibraltar Pound', numericCode: '292' },
  GMD: { name: 'Dalasi', numericCode: '270' },
  GNF: { name: 'Guinea Franc', numericCode: '324' },
  GTQ: { name: 'Quetzal', numericCode: '320' },
  GYD: { name: 'Guyana Dollar', numericCode: '328' },
  HKD: { name: 'Hong Kong Dollar', numericCode: '344', symbol: '$' },
  HNL: { name: 'Lempira', numericCode: '340' },
  HRK: { name: 'Kuna', numericCode: '191' },
  HTG: { name: 'Gourde', numericCode: '332' },
  HUF: { name: 'Forint', numericCode: '348', symbol: 'Ft' },
  IDR: { name: 'Rupiah', numericCode: '360' },
  ILS: { name: 'New Israeli Sheqel', numericCode: '376', symbol: '₪' },
  INR: { name: 'Indian Rupee', numericCode: '356' },
  IQD: { name: 'Iraqi Dinar', numericCode: '368' },
  IRR: { name: 'Iranian Rial', numericCode: '364' },
  ISK: { name: 'Iceland Krona', numericCode: '352', symbol: 'Kr.' },
  JMD: { name: 'Jamaican Dollar', numericCode: '388' },
  JOD: { name: 'Jordanian Dinar', numericCode: '400' },
  JPY: { name: 'Yen', numericCode: '392', symbol: '¥' },
  KES: { name: 'Kenyan Shilling', numericCode: '404' },
  KGS: { name: 'Som', numericCode: '417' },
  KHR: { name: 'Riel', numericCode: '116' },
  KMF: { name: 'Comoro Franc', numericCode: '174' },
  KPW: { name: 'North Korean Won', numericCode: '408' },
  KRW: { name: 'Won', numericCode: '410' },
  KWD: { name: 'Kuwaiti Dinar', numericCode: '414' },
  KYD: { name: 'Cayman Islands Dollar', numericCode: '136' },
  KZT: { name: 'Tenge', numericCode: '398' },
  LAK: { name: 'Kip', numericCode: '418' },
  LBP: { name: 'Lebanese Pound', numericCode: '422' },
  LKR: { name: 'Sri Lanka Rupee', numericCode: '144' },
  LRD: { name: 'Liberian Dollar', numericCode: '430' },
  LSL: { name: 'Loti', numericCode: '426' },
  LYD: { name: 'Libyan Dinar', numericCode: '434' },
  MAD: { name: 'Moroccan Dirham', numericCode: '504', symbol: 'MAD' },
  MDL: { name: 'Moldovan Leu', numericCode: '498' },
  MGA: { name: 'Malagasy Ariary', numericCode: '969' },
  MKD: { name: 'Denar', numericCode: '807' },
  MMK: { name: 'Kyat', numericCode: '104' },
  MNT: { name: 'Tugrik', numericCode: '496' },
  MOP: { name: 'Pataca', numericCode: '446' },
  MRU: { name: 'Ouguiya', numericCode: '929' },
  MUR: { name: 'Mauritius Rupee', numericCode: '480' },
  MVR: { name: 'Rufiyaa', numericCode: '462' },
  MWK: { name: 'Kwacha', numericCode: '454' },
  MXN: { name: 'Mexican Peso', numericCode: '484', symbol: '$' },
  MXV: { name: 'Mexican Unidad de Inversion (UDI)', numericCode: '979' },
  MYR: { name: 'Malaysian Ringgit', numericCode: '458' },
  MZN: { name: 'Mozambique Metical', numericCode: '943' },
  NAD: { name: 'Namibia Dollar', numericCode: '516' },
  NGN: { name: 'Naira', numericCode: '566' },
  NIO: { name: 'Cordoba Oro', numericCode: '558' },
  NOK: { name: 'Norwegian Krone', numericCode: '578', symbol: 'Kr.' },
  NPR: { name: 'Nepalese Rupee', numericCode: '524' },
  NZD: { name: 'New Zealand Dollar', numericCode: '554', symbol: '$' },
  OMR: { name: 'Rial Omani', numericCode: '512' },
  PAB: { name: 'Balboa', numericCode: '590' },
  PEN: { name: 'Nuevo Sol', numericCode: '604' },
  PGK: { name: 'Kina', numericCode: '598' },
  PHP: { name: 'Philippine Peso', numericCode: '608' },
  PKR: { name: 'Pakistan Rupee', numericCode: '586' },
  PLN: { name: 'Zloty', numericCode: '985', symbol: 'zł' },
  PYG: { name: 'Guarani', numericCode: '600' },
  QAR: { name: 'Qatari Rial', numericCode: '634' },
  RON: { name: 'Romanian Leu', numericCode: '946', symbol: 'lei' },
  RSD: { name: 'Serbian Dinar', numericCode: '941' },
  RUB: { name: 'Russian Ruble', numericCode: '643', symbol: '₽' },
  RWF: { name: 'Rwanda Franc', numericCode: '646' },
  SAR: { name: 'Saudi Riyal', numericCode: '682' },
  SBD: { name: 'Solomon Islands Dollar', numericCode: '090' },
  SCR: { name: 'Seychelles Rupee', numericCode: '690' },
  SDG: { name: 'Sudanese Pound', numericCode: '938' },
  SEK: { name: 'Swedish Krona', numericCode: '752', symbol: 'Kr.' },
  SGD: { name: 'Singapore Dollar', numericCode: '702', symbol: '$' },
  SHP: { name: 'Saint Helena Pound', numericCode: '654' },
  SLL: { name: 'Leone', numericCode: '694' },
  SOS: { name: 'Somali Shilling', numericCode: '706' },
  SRD: { name: 'Surinam Dollar', numericCode: '968' },
  SSP: { name: 'South Sudanese Pound', numericCode: '728' },
  STN: { name: 'Dobra', numericCode: '930' },
  SVC: { name: 'El Salvador Colon', numericCode: '222' },
  SYP: { name: 'Syrian Pound', numericCode: '760' },
  SZL: { name: 'Lilangeni', numericCode: '748' },
  THB: { name: 'Baht', numericCode: '764', symbol: '฿' },
  TJS: { name: 'Somoni', numericCode: '972' },
  TMT: { name: 'Turkmenistan New Manat', numericCode: '934' },
  TND: { name: 'Tunisian Dinar', numericCode: '788' },
  TOP: { name: 'Paanga', numericCode: '776' },
  TRY: { name: 'Turkish Lira', numericCode: '949', symbol: '₺' },
  TTD: { name: 'Trinidad and Tobago Dollar', numericCode: '780' },
  TWD: { name: 'New Taiwan Dollar', numericCode: '901' },
  TZS: { name: 'Tanzanian Shilling', numericCode: '834' },
  UAH: { name: 'Hryvnia', numericCode: '980' },
  UGX: { name: 'Uganda Shilling', numericCode: '800' },
  USD: { name: 'US Dollar', numericCode: '840', symbol: '$' },
  USN: { name: 'US Dollar (Next day)', numericCode: '997' },
  UYI: { name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)', numericCode: '940' },
  UYU: { name: 'Peso Uruguayo', numericCode: '858' },
  UZS: { name: 'Uzbekistan Sum', numericCode: '860' },
  VEF: { name: 'Bolivar', numericCode: '937' },
  VND: { name: 'Dong', numericCode: '704' },
  VUV: { name: 'Vatu', numericCode: '548' },
  WST: { name: 'Tala', numericCode: '882' },
  XAF: { name: 'CFA Franc BEAC', numericCode: '950' },
  XCD: { name: 'East Caribbean Dollar', numericCode: '951' },
  XDR: { name: 'SDR (Special Drawing Right)', numericCode: '960' },
  XOF: { name: 'CFA Franc BCEAO', numericCode: '952' },
  XPF: { name: 'CFP Franc', numericCode: '953' },
  XSU: { name: 'Sucre', numericCode: '994' },
  XUA: { name: 'ADB Unit of Account', numericCode: '965' },
  YER: { name: 'Yemeni Rial', numericCode: '886' },
  ZAR: { name: 'Rand', numericCode: '710', symbol: 'R' },
  ZMW: { name: 'Zambian Kwacha', numericCode: '967' },
  ZWL: { name: 'Zimbabwe Dollar', numericCode: '932' },
};
