import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import { ActionsContainer, PrimaryButton } from '@app/guestLogin/routes/Styles';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import CheckSVG from '@app/guestLogin/images/guest-file-checked.svg';
import DownloadSVG from '@app/guestLogin/images/guest-file-download.svg';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import { Trans, useTranslation } from 'react-i18next';
import ArrowBullet from '@app/components/ArrowBullet';
import { ARPageProps } from '@ar/routes/withARConfiguration';
import useTheme from '@app/hooks/useTheme';
import { ARConfiguration } from '@ar/domain/arGeneral';

interface Props extends ARPageProps {}

const GuestLandingPage: FC<Props> = observer(({ configuration }) => {
  useInnerPageEnterAnalyticsReport(InnerPage.welcome);
  const { guestLoginStore } = useInfraStores<ArMode>();
  const { t } = useTranslation();
  const { isHeaderDark } = useTheme<ArMode>();

  const onContinue = (): void => {
    guestLoginStore.setIsUserStartedLogin(true);
  };

  // todo banks poc hack
  function isSebOrg(configuration: ARConfiguration | null): boolean {
    return configuration?.clientName?.toLowerCase() === 'seb';
  }

  return (
    <div>
      <SmallTitle>{t('ar.guestLogin.landingPage.welcome')}</SmallTitle>
      <BigTitle>
        {configuration?.clientName
          ? t('ar.guestLogin.landingPage.bankAccountDetailsVerificationWithClient', { clientName: configuration?.clientName })
          : t('ar.guestLogin.landingPage.bankAccountDetailsVerification')}
      </BigTitle>
      <ItalicTitle>{t('ar.guestLogin.landingPage.protectingPayments')}</ItalicTitle>
      <RegularText>
        {isSebOrg(configuration)
          ? t('ar.guestLogin.landingPage.yourBankAccountDetailsSEB')
          : configuration?.clientName
          ? t('ar.guestLogin.landingPage.yourBankAccountDetailsWithClient', { clientName: configuration?.clientName })
          : t('ar.guestLogin.landingPage.yourBankAccountDetails')}
      </RegularText>
      <RegularText>
        {isSebOrg(configuration)
          ? t('ar.guestLogin.landingPage.toEnsureSEB')
          : configuration?.clientName
          ? t('ar.guestLogin.landingPage.toEnsureWithClient', { clientName: configuration?.clientName })
          : t('ar.guestLogin.landingPage.toEnsure')}
      </RegularText>

      <RegularText>
        {isSebOrg(configuration)
          ? t('ar.guestLogin.landingPage.pleaseMakeSureSEB')
          : t('ar.guestLogin.landingPage.pleaseMakeSure')}
      </RegularText>
      <IconsContainer>
        <IconSection>
          <Oval isHeaderDark={isHeaderDark}>
            <SVG accessibilityLabel='' image={CheckSVG} height={50} width={50} />
          </Oval>
          <IconGrayText>
            <Trans i18nKey='ar.guestLogin.landingPage.rightPayee'>
              Verify our
              <br />
              banking details
            </Trans>
          </IconGrayText>
        </IconSection>
        <Arrows>
          <StyledArrowsSVG />
          <StyledArrowsSVG />
          <StyledArrowsSVG />
        </Arrows>
        <IconSection>
          <Oval isHeaderDark={isHeaderDark}>
            <SVG accessibilityLabel='' image={DownloadSVG} height={50} width={50} />
          </Oval>
          <IconGrayText>
            <Trans i18nKey='ar.guestLogin.landingPage.rightAccount'>
              Access required
              <br />
              documents
            </Trans>
          </IconGrayText>
        </IconSection>
      </IconsContainer>
      <StyledActionsContainer>
        <PrimaryButton id='btn-guest-landing-continue' onClick={onContinue}>
          {t('ar.guestLogin.landingPage.getStarted')}
        </PrimaryButton>
      </StyledActionsContainer>
      <IAgreeText>
        <Trans i18nKey='ar.guestLogin.landingPage.iAgree'>
          Use of this service is subject to the nsKnox terms of use and privacy policy. By clicking “Get started” and using this
          service, I confirm that I authorize nsKnox to process the details I provide, subject to the nsKnox{' '}
          <a href='https://nsknox.net/terms-of-use/' target='_blank' rel='noreferrer'>
            terms of use
          </a>{' '}
          and nsKnox{' '}
          <a href='https://nsknox.net/privacy-policy/' target='_blank' rel='noreferrer'>
            privacy policy
          </a>
          .
        </Trans>
      </IAgreeText>
    </div>
  );
});

export default GuestLandingPage;

const IconSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
`;

const StyledArrowsSVG = styled(ArrowBullet)`
  height: 21px;
`;

const IconGrayText = styled.div`
  font-family: var(--text-font-family);
  font-size: 14px;
  line-height: 1.43;
  color: var(--transparent-black-600);
  text-align: center;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px 0;
`;

const StyledActionsContainer = styled(ActionsContainer)`
  justify-content: space-around;
`;

const SmallTitle = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: var(--transparent-black-900);
`;

const BigTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.38;
  color: var(--transparent-black-900);
`;

const ItalicTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  line-height: 2.25;
  color: var(--transparent-black-900);
`;

const RegularText = styled.div`
  font-family: var(--text-font-family);
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--transparent-black-600);
`;

const Oval = styled.div<{ isHeaderDark: boolean }>`
  width: 104px;
  height: 104px;
  background-color: var(${(p): string => (p.isHeaderDark ? '--header-color' : '--black-strong')});
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
`;

const IAgreeText = styled.div`
  padding-top: 50px;
  font-family: var(--title-font-family);
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`;
