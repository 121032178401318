import { ColumnProps } from 'antd/lib/table';
import { distinctValues } from '@app/utils/arrayUtils';
import { isTruthy } from '@app/utils/utils';
import { getColumnMultiValueConstSelectLocalFilterProps } from './constsFilterCreator';

export default function getColumnMultiValueFieldSelectProps<
  T,
  TValue extends string | number | null | undefined = string | number | null | undefined
>(data: T[], fieldValueGetter: (item: T) => TValue, fieldTextGetter?: (item: TValue) => string): Partial<ColumnProps<T>> {
  const values = distinctValues(data.map((value) => fieldValueGetter(value)).filter(isTruthy)).map((value) => ({
    text: fieldTextGetter ? fieldTextGetter(value) : value.toString(),
    value: value.toString(),
  }));

  return getColumnMultiValueConstSelectLocalFilterProps(
    values,
    (value, record): boolean => fieldValueGetter(record)?.toString() === value,
  );
}
