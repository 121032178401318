import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { ColumnProps } from 'antd/lib/table';
import Status from '@mortee/components/Status';
import DownloadFilesSvg from '@mortee/components/DownloadFilesSvg';
import { showInfoModal } from '@app/components/Modal';
import ModalFileList from '@app/components/fileUpload/ModalFileList';
import MvfCheckSurveyModel from '@mortee/models/MvfCheckSurveyModel';
import { DataLoadingState, MVF_SURVEY_TABLE_PAGE_SIZE } from '@mortee/stores/appStores/MasterDataGuardStore';
import MorteeMode from '@mortee/morteeMode';
import useAppStores from '@app/hooks/useAppStores';
import Table, { TableFormattedDate, TableSmallText, TableStandardText } from '@app/components/tables/Table';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import PaginationWithIndex from '@app/components/PaginationWithIndex';

interface MvfCheckSurveysTableProps {
  surveys: MvfCheckSurveyModel[];
  fetchSurveyFile: (surveyId: string, fileId: string) => Promise<NamedResource>;
  loadingState: DataLoadingState;
  emptyComponent: ReactElement;
}

const MvfCheckSurveysTable: FunctionComponent<MvfCheckSurveysTableProps> = observer((props) => {
  const { masterDataGuardStore } = useAppStores<MorteeMode>();

  const onSurveyRowClicked = (survey: MvfCheckSurveyModel): void => {
    masterDataGuardStore.setSelectedSurveyId(survey.id);
  };

  const onPaginationChange = (page: number): void => {
    masterDataGuardStore.setCurrentSurveyPageIndex(page - 1);
    masterDataGuardStore.loadCurrentPageSurveys();
  };

  const { totalSurveysCount } = masterDataGuardStore;
  const { surveys, fetchSurveyFile, loadingState, emptyComponent } = props;

  const columns = createColumns(fetchSurveyFile);
  return (
    <StyledCard>
      <Table
        data-testid='mvfCheckSurveysTable'
        columns={columns}
        dataSource={surveys}
        loading={loadingState === DataLoadingState.Loading}
        pagination={false}
        rowKey={(audit: MvfCheckSurveyModel): string => audit.id}
        isLoaded={loadingState === DataLoadingState.NotLoading}
        rowClickable
        onRow={(survey): { onClick: VoidFunction } => ({ onClick: (): void => onSurveyRowClicked(survey) })}
        emptyComponent={emptyComponent}
      />
      <PaginationWithIndex
        total={totalSurveysCount}
        pageSize={MVF_SURVEY_TABLE_PAGE_SIZE}
        currentIndex={masterDataGuardStore.currentMvfSurveyPageIndex + 1}
        onChange={onPaginationChange}
      />
    </StyledCard>
  );
});

export default MvfCheckSurveysTable;

function createColumns(fetchSurveyFile: MvfCheckSurveysTableProps['fetchSurveyFile']): ColumnProps<MvfCheckSurveyModel>[] {
  return [
    {
      title: <div data-testid='lblAuditDate'>Date</div>,
      dataIndex: 'writeTimestamp',
      key: 'writeTimestamp',
      width: '150px',
      render: (text: any, record: MvfCheckSurveyModel): React.ReactNode => (
        <TableFormattedDate options={formattedDateOptionsWithHoursAndMinutes} value={record.writeTimestamp} />
      ),
    },
    {
      title: <div data-testid='lblAuditUser'>User</div>,
      dataIndex: 'user',
      key: 'user',
      width: '200px',
      render: (text: any, record: MvfCheckSurveyModel): React.ReactNode => (
        <TableStandardText>{record.performingUserName}</TableStandardText>
      ),
    },
    {
      title: <div data-testid='lblAuditNotes'>Notes</div>,
      dataIndex: 'notes',
      key: 'notes',
      width: '600px',
      render: (text: any, record: MvfCheckSurveyModel): React.ReactNode => {
        const note = record.data.note?.trim();
        return <TableSmallText>{note || `Note wasn't provided`}</TableSmallText>;
      },
    },
    {
      title: <div data-testid='lblAuditFiles'>File</div>,
      dataIndex: 'files',
      width: '45px',
      align: 'center',
      key: 'files',
      render: (text: any, record: MvfCheckSurveyModel): React.ReactNode => {
        const files = record.data.vendorFilesMetadata;

        if (files?.length) {
          return (
            <div>
              <DownloadFilesSvg
                accessibilityLabel='files list'
                id='btn-mvf-check-file-list-modal-open'
                dataTestId='btn-mvf-check-file-list-modal-open'
                isDownloadUnavailable={false}
                fileNum={files?.length}
                onClick={(event): void => {
                  event.stopPropagation();
                  showInfoModal(
                    <ModalFileList
                      files={files.map((file) => [file, (fileId): Promise<NamedResource> => fetchSurveyFile(record.id, fileId)])}
                      title={`Download MVF File${files.length > 1 ? 's' : ''}`}
                    />,
                    {
                      okText: 'CANCEL',
                    },
                  );
                }}
              />
            </div>
          );
        }

        return <TableStandardText>N/A</TableStandardText>;
      },
    },
    {
      title: <div data-testid='lblAuditStatus'>Status</div>,
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      key: 'status',
      render: (text: any, record: MvfCheckSurveyModel): React.ReactNode => (
        <Status status={record.status ?? ''} mode='color' fontSize={13} isAuditStatus />
      ),
    },
  ];
}

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0;
  flex-direction: column;
`;
