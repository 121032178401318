import { ColumnProps } from 'antd/lib/table';
import React, { ReactElement } from 'react';
import FilterColumnTextPopup from './FilterColumnTextPopup';
import { valueOrValuesOrNullAsArray } from '@app/utils/arrayUtils';

export function getColumnTextSearchProps<T>(
  fieldGetter: (item: T) => number | (number | null | undefined)[] | string | (string | null | undefined)[] | null | undefined,
): Partial<ColumnProps<T>> {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnTextPopup
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    onFilter(value, record): boolean {
      const fieldValue = fieldGetter(record);

      const arrayOfFieldValues = valueOrValuesOrNullAsArray(fieldValue);

      return arrayOfFieldValues.some((innerValue) => isValueInField(value, innerValue));
    },
  };
}

function isValueInField(value: any, fieldValue: number | string | null | undefined): boolean {
  return !!fieldValue?.toString().toLowerCase().includes(value.toLowerCase());
}
