import React, { FC, ReactNode } from 'react';
import PageWithHeader from '@app/components/PageWithHeader';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { addUserToOrganization } from '@app/services/userManagement/organizationUsersService';
import PageHeader, { PageHeaderBottomAlignedSideContent } from '@app/components/header/PageHeader';
import LinkButton from '@app/components/LinkButton';
import useInfraStores from '@app/hooks/useInfraStores';
import BackeeMode from '@backee/backeeMode';
import OrganizationsTable from '@backee/routes/organizations/allOrganizationsPage/OrganizationsTable';
import { Organization } from '@app/domain/userManagement/organizations';
import { OrganizationalUserServerResponse } from '@app/domain/userManagement/organizationUsers';
import browserHistory from '@app/utils/browserHistory';
import { showCustomModalAsync } from '@app/components/Modal';
import AddUsersToOrganizationModal from '@app/components/userManagement/addUsersToOrganization/AddUsersToOrganizationModal';
import { ManageOrganizationTabType } from '@backee/routes/organizations/ManageOrganizationPage';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import { getAllOrganizations, transformToOrganization } from '@backee/services/organizationsServices';

const AllOrganizationsPage: FC = () => {
  const modalContext = useModalContext();
  const { navigationStore, permissionsStore } = useInfraStores<BackeeMode>();

  const [organizations] = useLoadable(async () => {
    const organizationServerResponses = await getAllOrganizations();

    return organizationServerResponses.map(transformToOrganization);
  }, []);

  const renderHeaderSideContent = (): ReactNode => {
    if (!permissionsStore.isCreateOrganizationAllowed) {
      return null;
    }

    return (
      <PageHeaderBottomAlignedSideContent>
        <LinkButton id='link-create-organization' appearance='text' to={navigationStore.generateNewOrganizationsLink()}>
          + CREATE ORGANIZATION
        </LinkButton>
      </PageHeaderBottomAlignedSideContent>
    );
  };

  const handleOrganizationClick = (organization: Organization): void => {
    browserHistory.push(navigationStore.generateManageOrganizationLink(organization.id));
  };

  const handleOrganizationManageUsersClick = (organization: Organization): void => {
    browserHistory.push(navigationStore.generateManageOrganizationLink(organization.id, ManageOrganizationTabType.users));
  };

  function openAddNewUserToOrgModal(organization: Organization): void {
    showCustomModalAsync(
      (onDone) => {
        return (
          <ModalAppContext {...modalContext}>
            <AddUsersToOrganizationModal
              organizationId={organization.id}
              organizationName={organization.name}
              addUserToOrganization={(tenantUserId): Promise<OrganizationalUserServerResponse> =>
                addUserToOrganization(organization.id, tenantUserId)
              }
              onDone={onDone}
            />
          </ModalAppContext>
        );
      },
      {
        maskClosable: false,
      },
    );
  }

  return (
    <PageWithHeader width='full' header={<PageHeader sideContent={renderHeaderSideContent()}>All Organizations</PageHeader>}>
      <OrganizationsTable
        id='all-organizations-table'
        organizationsLoadable={organizations}
        canAddUsersToOrganization={permissionsStore.isEditAllowed}
        onItemClick={handleOrganizationClick}
        onItemManageUsers={handleOrganizationManageUsersClick}
        onItemAddUsers={openAddNewUserToOrgModal}
      />
    </PageWithHeader>
  );
};

export default AllOrganizationsPage;
