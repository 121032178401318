import React, { FC } from 'react';
import styled from '@emotion/styled';
import { showContentOnlyModal } from '@app/components/Modal';
import ModalAppContext from '@app/ModalAppContext';
import BigModal from '@app/components/BigModal';
import useModalContext from '@app/hooks/useModalContext';
import Button from '@app/components/Button';
import { SubtitleRegularStartTransparentBlack600 } from '@app/components/Text';

interface Props extends Omit<React.HTMLProps<HTMLIFrameElement>, 'src' | 'as'> {
  id: string;
  title: string;
  autoplaySrc: string;
  nonAutoplaySrc: string;
}

const VideoPopup: FC<Props> = ({ id, title, nonAutoplaySrc, autoplaySrc, className, ...iframeProps }) => {
  const modalContext = useModalContext();

  function showVideoPopup(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    e.stopPropagation();

    showContentOnlyModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <FullscreenModal closeFunc={onDone} title={title}>
            <iframe
              src={autoplaySrc}
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              {...iframeProps}
              width='100%'
              height='90%'
            />
          </FullscreenModal>
        </ModalAppContext>
      ),
      {
        maskClosable: true,
      },
    );
  }

  return (
    <VideoThumbnailButton
      id={`btn-play-${id}`}
      appearance='text'
      colorScheme='grey'
      onClick={showVideoPopup}
      className={className}
    >
      <VideoThumbnailTitle>{title}</VideoThumbnailTitle>
      <VideoThumbnailIframe src={nonAutoplaySrc} frameBorder='0' {...iframeProps} />
    </VideoThumbnailButton>
  );
};

export default VideoPopup;

const FullscreenModal = styled(BigModal)`
  width: 90vw;
  height: 90vh;
`;

const VideoThumbnailButton = styled(Button)`
  cursor: pointer;
`;

const VideoThumbnailTitle = styled(SubtitleRegularStartTransparentBlack600.div)`
  text-align: start;
`;

const VideoThumbnailIframe = styled.iframe`
  pointer-events: none;
`;
