import React, { FC } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { Form, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DEFAULT_FORM_VALIDATION_OPTIONS, FormFieldDecorators } from '@app/utils/form/form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import NakedFormChipList from '@app/components/inputs/NakedFormChipList';
import { arrayValidator, patternValidator, patternValidatorSynchronous } from '@app/utils/validators';
import { SEND_VALIDATION_EMAIL_FIELD_MAX_LENGTH } from '@mortee/domain/sendValidationEmailFields';
import SVG from '@app/components/SVG';
import OutlineInfoSpriteSymbol from '@app/images/designSystem/indication/info.svg';
import { ValidationEmailToCustomerValues } from '@mortee/domain/morteeAccountVerification';
import NakedFormInput from '@app/components/inputs/NakedFormInput';

export interface SendValidationEmailToCustomerFormFields {
  to: string[];
  referringCustomerName: string;
}

interface Props extends FormComponentProps<SendValidationEmailToCustomerFormFields> {
  initialFormValues: ValidationEmailToCustomerValues;
  className?: string;
  onFormSubmit(values: SendValidationEmailToCustomerFormFields): void;
}

const SendValidationEmailToCustomerFormModal: FC<Props> = (props) => {
  const { onFormSubmit, initialFormValues, className } = props;

  const { form, isFormInvalid, showFormErrors, setShowFormErrors } = useForm(props);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleOk();
  };

  const handleOkClick = async (): Promise<void> => {
    await handleOk();
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll(
      DEFAULT_FORM_VALIDATION_OPTIONS,
      (errors: Object, values: SendValidationEmailToCustomerFormFields) => {
        if (errors) {
          setShowFormErrors('all');
          return;
        }

        onFormSubmit(values);
      },
    );
  };

  const fieldDecorators = createFieldDecorators(initialFormValues);

  return (
    <StyledNsknoxForm
      onSubmit={handleFormSubmit}
      form={form}
      showErrors={showFormErrors}
      className={className}
      setShowErrors={setShowFormErrors}
    >
      <InputsContainer>
        <InputAndTooltipContainer>
          <FullWidthFormItemBox fieldName='to' fieldDecoratorOptions={fieldDecorators.to}>
            <NakedFormChipList
              id='txt-send-validation-email-to-customer-to'
              dataTestId='txt-send-validation-email-to-customer-to'
              placeholder='Send Email To'
              pasteItemsSeparators={[';', ',']}
              arrayItemValidator={patternValidatorSynchronous(
                VALIDATION_PATTERNS.email,
                (invalidValue) => `"${invalidValue}" is an invalid email`,
              )}
              clearable
            />
          </FullWidthFormItemBox>
          <Tooltip
            title={`The recipients' email addresses (of the customer), those addresses will be visible to all recipients of this email, should not include nsknox internal email address`}
          >
            <ExplanationIcon
              accessibilityLabel='information tooltip popup'
              image={OutlineInfoSpriteSymbol}
              width={24}
              height={24}
            />
          </Tooltip>
        </InputAndTooltipContainer>
        <InputAndTooltipContainer>
          <FullWidthFormItemBox fieldName='referringCustomerName' fieldDecoratorOptions={fieldDecorators.referringCustomerName}>
            <NakedFormInput
              name='txt-send-validation-email-to-customer-referring-customer-name'
              type='text'
              dataTestId='txt-send-validation-email-to-customer-referring-customer-name'
              placeholder='Customer Name'
              clearable
            />
          </FullWidthFormItemBox>
          <Tooltip title='The name of our customer, this will be placed in the subject and body of the email.'>
            <ExplanationIcon
              accessibilityLabel='information tooltip popup'
              image={OutlineInfoSpriteSymbol}
              width={24}
              height={24}
            />
          </Tooltip>
        </InputAndTooltipContainer>
      </InputsContainer>
      <ActionsContainer>
        <Button id='btn-send-validation-email-to-customer-continue' disabled={isFormInvalid} onClick={handleOkClick}>
          CONTINUE {'>'}
        </Button>
      </ActionsContainer>
    </StyledNsknoxForm>
  );
};

export default Form.create<Props>()(SendValidationEmailToCustomerFormModal);

const createFieldDecorators = (
  initialValues: ValidationEmailToCustomerValues,
): FormFieldDecorators<SendValidationEmailToCustomerFormFields> => ({
  to: {
    initialValue: initialValues.to,
    rules: [
      {
        required: true,
        type: 'array',
        min: 1,
        message: `Missing Emails`,
      },
      {
        type: 'array',
        max: SEND_VALIDATION_EMAIL_FIELD_MAX_LENGTH.emailAmount,
        message: `Max ${SEND_VALIDATION_EMAIL_FIELD_MAX_LENGTH.emailAmount} emails`,
      },
      {
        validator: arrayValidator(
          patternValidator(VALIDATION_PATTERNS.email, (invalidValue) => `"${invalidValue}" is an invalid email`),
          'One of the values is invalid',
        ),
      },
    ],
  },
  referringCustomerName: {
    initialValue: initialValues.referringCustomerName,
    rules: [
      {
        required: true,
        message: `Missing customer name`,
      },
      {
        max: SEND_VALIDATION_EMAIL_FIELD_MAX_LENGTH.customerName,
        message: `Max ${SEND_VALIDATION_EMAIL_FIELD_MAX_LENGTH.customerName} characters`,
      },
    ],
  },
});

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div`
  flex: 1;
`;

const InputAndTooltipContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FullWidthFormItemBox = styled(FormItemBox)`
  flex: 1;
`;

const ExplanationIcon = styled(SVG)`
  flex: 0 0 auto;
  margin-top: 16px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  // To allow the buttons drop shadow to fully show
  padding: 10px var(--side-padding) 8px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
