import React, { FC, ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  SupplierValidationRecord,
  SupplierValidationRecordStatus,
  SupplierValidationRecordStatusObject,
  SupplierValidationRecordUpdateRequest,
  SVMasterFileAutomaticEmailStatus,
  transformSupplierValidationRecord,
  transformToSupplierValidationRecordStatusUpdateRequest,
} from '@mortee/domain/validationSystem';
import EditValidationRecordGeneralInfoTab from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordGeneralInfoTab';
import EditValidationRecordRegistrationFormsTab from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordRegistrationFormsTab';
import EditValidationRecordAutomailerTab from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordAutomailerTab';
import EditValidationRecordDistributionTab from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordDistributionTab';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import VerificationRecordDataTab from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationRecordDataTab';
import useAppStores from '@app/hooks/useAppStores';
import styled from '@emotion/styled';
import ProgressTabs, { SelectedTab, Tab, TabIndicator } from '@mortee/components/ProgressTabs';
import SeparatorLine from '@app/components/SeparatorLine';
import SelectStatusInput from '@mortee/routes/validationSystem/statusEdit/SelectStatusInput';
import validationSystemServices from '@mortee/services/validationSystemServices';
import SelectAssigneeInput from '@mortee/routes/validationSystem/assigneeEdit/SelectAssigneeInput';
import DoneIcon from '@mortee/images/validationSystem/indicators/done.svg';
import AutomailerStoppedIcon from '@mortee/images/validationSystem/indicators/automailer/stopped.svg';
import AutomailerErrorIcon from '@mortee/images/validationSystem/indicators/automailer/error.svg';
import AutomailerNAIcon from '@mortee/images/validationSystem/indicators/automailer/not-available.svg';
import AutomailerNewIcon from '@mortee/images/validationSystem/indicators/automailer/new.svg';
import AutomailerEmailCountIcon from '@mortee/images/validationSystem/indicators/automailer/email-count.svg';
import RegistrationFormLinkedIcon from '@mortee/images/validationSystem/indicators/registration-form/linked.svg';
import RegistrationFormOfflineIcon from '@mortee/images/validationSystem/indicators/registration-form/offline.svg';
import DistributionNAIcon from '@mortee/images/validationSystem/indicators/distribution/not-available.svg';
import DistributionNotYetIcon from '@mortee/images/validationSystem/indicators/distribution/not-yet.svg';
import DistributionErrorIcon from '@mortee/images/validationSystem/indicators/distribution/error.svg';
import EvidenceLinkedIcon from '@mortee/images/validationSystem/indicators/evidence/linked.svg';
import VerificationDraftIcon from '@mortee/images/validationSystem/indicators/verification/draft.svg';
import VerificationPartialCompletedIcon from '@mortee/images/validationSystem/indicators/verification/partial-completed.svg';
import VerificationClarificationIcon from '@mortee/images/validationSystem/indicators/verification/clarification.svg';
import VerificationWaitingApprovalIcon from '@mortee/images/validationSystem/indicators/verification/waiting-approval.svg';
import { css } from '@emotion/css';
import SVG from '@app/components/SVG';
import EditValidationRecordEvidenceTab from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/EditValidationRecordEvidenceTab';

export enum MainTabs {
  generalInfo = 'general-info',
  automailer = 'automailer',
  registrationForms = 'registration-forms',
  distributions = 'distributions',
  evidence = 'evidence',
  verification = 'verification',
}

interface Props {
  record: SupplierValidationRecord;
  className?: string;
  selectedTab: SelectedTab<MainTabs>;
  onSaved(updatedRecord: SupplierValidationRecord | undefined, userWantsToClose: boolean): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean | null): void;
}

const EditValidationRecordMainTabs: FC<Props> = observer((props) => {
  const { record, selectedTab, onSaved, onIsFormUnsavedChanged } = props;

  const { userStore, permissionsStore } = useInfraStores<MorteeMode>();
  const { validationSystemStore } = useAppStores<MorteeMode>();

  const isPendingApprovalAndDoesntHaveApprovalRole =
    !permissionsStore.isValidationApprover && record?.status?.value === SupplierValidationRecordStatus.waitingForApproval;

  async function onStatusChange(staticId: string, status: SupplierValidationRecordStatusObject): Promise<void> {
    await updateRecordAndNotify(staticId, {
      status: {
        content: transformToSupplierValidationRecordStatusUpdateRequest(status),
      },
    });
  }

  async function onAssigneeChange(staticId: string, assigneeId: string | undefined | null): Promise<void> {
    await updateRecordAndNotify(staticId, {
      assigneeId: { content: assigneeId ?? null },
    });
  }

  async function updateRecordAndNotify(staticId: string, updateRequest: SupplierValidationRecordUpdateRequest): Promise<void> {
    const serverResponse = await validationSystemServices.updateValidationRecord(staticId, updateRequest);
    const updatedRecord = transformSupplierValidationRecord(serverResponse.record, userStore.user?.id);

    onSaved(updatedRecord, false);
  }

  function renderIndicatorForAutomailerTab(): TabIndicator | null {
    switch (true) {
      case record.emailStatus === SVMasterFileAutomaticEmailStatus.ended: {
        return {
          text: 'Ended',
          icon: <SVG width={22} height={22} image={DoneIcon} accessibilityLabel='Automailer - Ended' />,
        };
      }
      case record.emailStatus === SVMasterFileAutomaticEmailStatus.stopped || record.stopEmails: {
        return {
          text: 'Stopped',
          icon: <SVG width={22} height={22} image={AutomailerStoppedIcon} accessibilityLabel='Automailer - Stopped' />,
        };
      }
      case record.emailStatus === SVMasterFileAutomaticEmailStatus.error: {
        return {
          text: 'Error',
          icon: <SVG width={22} height={22} image={AutomailerErrorIcon} accessibilityLabel='Automailer - Error' />,
        };
      }
      case record.emailStatus === SVMasterFileAutomaticEmailStatus.inProgress: {
        return {
          text: 'Email Count = ' + record.emailCount,
          icon: (
            <EmailCountWrapper>
              <SVG
                width={22}
                height={22}
                image={AutomailerEmailCountIcon}
                accessibilityLabel={`Automailer - Email Count Of ${record.emailCount}`}
              />
              <div>{record.emailCount}</div>
            </EmailCountWrapper>
          ),
        };
      }
      case record.emailStatus === SVMasterFileAutomaticEmailStatus.notStarted: {
        return {
          text: 'Not Started',
          icon: (
            <EmailCountWrapper>
              <SVG width={22} height={22} image={AutomailerEmailCountIcon} accessibilityLabel={`Automailer - Not Started`} />
              <div>0</div>
            </EmailCountWrapper>
          ),
        };
      }
      case record.status.value !== SupplierValidationRecordStatus.inProgress: {
        return {
          text: 'Stopped due to record status',
          icon: (
            <SVG
              width={22}
              height={22}
              image={AutomailerStoppedIcon}
              accessibilityLabel='Automailer - Stopped due to record status'
            />
          ),
        };
      }
      case !record.initialEmailId: {
        return {
          text: 'Not Available',
          icon: <SVG width={22} height={22} image={AutomailerNAIcon} accessibilityLabel='Automailer - Not Available' />,
        };
      }
      case record.emailStatus === null: {
        return {
          text: 'New',
          icon: <SVG width={22} height={22} image={AutomailerNewIcon} accessibilityLabel='Automailer - New' />,
        };
      }
    }

    return null;
  }

  function renderIndicatorForRegistrationFormsTab(): TabIndicator | null {
    if (record.supplierRegistrationProcess) {
      return {
        text: 'Linked',
        icon: <SVG width={22} height={22} image={RegistrationFormLinkedIcon} accessibilityLabel='Registration Form - Linked' />,
      };
    }

    if (record.isOfflineForm) {
      return {
        text: 'Offline Process',
        icon: (
          <SVG
            width={22}
            height={22}
            image={RegistrationFormOfflineIcon}
            accessibilityLabel='Registration Form - Offline Process'
          />
        ),
      };
    }

    return {
      text: 'Unlinked',
      icon: null,
    };
  }

  function renderIndicatorForDistributionTab(): TabIndicator | null {
    if (record.distributions.isDistributed) {
      return {
        text: 'Distributed',
        icon: <SVG width={22} height={22} image={DoneIcon} accessibilityLabel='Distribution - Distributed' />,
      };
    }

    if (!record.manualInstructionType && !record.supplierRegistrationProcess?.instructionType) {
      return {
        text: 'No Instruction Type',
        icon: null,
      };
    }

    if (!record.distributions.isDistributable) {
      return {
        text: 'Instruction Type Not Distributable',
        icon: (
          <SVG
            width={22}
            height={22}
            image={DistributionNAIcon}
            accessibilityLabel='Distribution - Instruction Type Not Distributable'
          />
        ),
      };
    }

    if (record.distributions.isScheduledDistributable) {
      return {
        text: 'Will Be Scheduled Distributed',
        icon: (
          <SVG
            width={22}
            height={22}
            image={DistributionNotYetIcon}
            accessibilityLabel='Distribution - Will Be Scheduled Distributed'
          />
        ),
      };
    }

    return {
      text: 'Unknown Error',
      icon: <SVG width={22} height={22} image={DistributionErrorIcon} accessibilityLabel='Distribution - Unknown Error' />,
    };
  }

  function renderIndicatorForEvidenceTab(): TabIndicator | null {
    if (record.evidence) {
      return {
        text: 'Linked',
        icon: <SVG width={22} height={22} image={EvidenceLinkedIcon} accessibilityLabel='Evidence - Linked' />,
      };
    }

    return {
      text: 'Unlinked',
      icon: null,
    };
  }

  function renderIndicatorForVerificationTab(): TabIndicator | null {
    switch (record.status.value) {
      case SupplierValidationRecordStatus.completed: {
        return {
          text: 'Completed',
          icon: <SVG width={22} height={22} image={DoneIcon} accessibilityLabel='Verification - Completed' />,
        };
      }
      case SupplierValidationRecordStatus.completedWithNoEmails:
      case SupplierValidationRecordStatus.updateCompleted: {
        return {
          text: 'Partial Completed',
          icon: (
            <SVG
              width={22}
              height={22}
              image={VerificationPartialCompletedIcon}
              accessibilityLabel='Verification - Partial Completed'
            />
          ),
        };
      }
      case SupplierValidationRecordStatus.inApproval: {
        return {
          text: 'In Approval',
          icon: (
            <SVG width={22} height={22} image={VerificationClarificationIcon} accessibilityLabel='Verification - In Approval' />
          ),
        };
      }
      case SupplierValidationRecordStatus.clarification: {
        return {
          text: 'Clarification',
          icon: (
            <SVG width={22} height={22} image={VerificationClarificationIcon} accessibilityLabel='Verification - Clarification' />
          ),
        };
      }
      case SupplierValidationRecordStatus.validationDraft: {
        return {
          text: 'Draft',
          icon: <SVG width={22} height={22} image={VerificationDraftIcon} accessibilityLabel='Verification - Draft' />,
        };
      }
      case SupplierValidationRecordStatus.waitingForApproval: {
        return {
          text: 'Waiting Approval',
          icon: (
            <SVG
              width={22}
              height={22}
              image={VerificationWaitingApprovalIcon}
              accessibilityLabel='Verification - Waiting Approval'
            />
          ),
        };
      }
      case SupplierValidationRecordStatus.inProgress:
      case SupplierValidationRecordStatus.canceled:
      case SupplierValidationRecordStatus.hold:
      case SupplierValidationRecordStatus.deleted: {
        return null;
      }
    }
  }

  const options: Tab<MainTabs>[] = useMemo(
    () => [
      {
        key: MainTabs.generalInfo,
        title: 'General Info',
        indicator: {
          text: null,
          icon: <SVG width={22} height={22} image={DoneIcon} accessibilityLabel='General Info - Done' />,
        },
        render(): ReactElement {
          return (
            <EditValidationRecordGeneralInfoTab
              record={record}
              allOrganizationsLoadable={validationSystemStore.organizations}
              onSaved={onSaved}
              onIsFormUnsavedChanged={onIsFormUnsavedChanged}
              className={FullWidthPanelClassName}
            />
          );
        },
      },
      {
        key: MainTabs.automailer,
        title: 'Automailer',
        indicator: renderIndicatorForAutomailerTab(),
        render(): ReactElement {
          return (
            <EditValidationRecordAutomailerTab
              record={record}
              onIsFormUnsavedChanged={onIsFormUnsavedChanged}
              onSaved={onSaved}
              className={FullWidthPanelClassName}
            />
          );
        },
      },
      {
        key: MainTabs.registrationForms,
        title: 'Registration Forms',
        indicator: renderIndicatorForRegistrationFormsTab(),
        render(): ReactElement {
          return <EditValidationRecordRegistrationFormsTab record={record} onSaved={onSaved} />;
        },
      },
      {
        key: MainTabs.distributions,
        title: 'Distributions',
        indicator: renderIndicatorForDistributionTab(),
        render(): ReactElement {
          return (
            <EditValidationRecordDistributionTab
              record={record}
              onSaved={(updatedRecord): void => onSaved(updatedRecord, false)}
            />
          );
        },
      },
      {
        key: MainTabs.evidence,
        title: 'Evidence',
        indicator: renderIndicatorForEvidenceTab(),
        render(): ReactElement {
          return <EditValidationRecordEvidenceTab disabled={isPendingApprovalAndDoesntHaveApprovalRole} record={record} />;
        },
      },
      {
        key: MainTabs.verification,
        title: 'Verification',
        indicator: renderIndicatorForVerificationTab(),
        render(): ReactElement {
          return (
            <VerificationRecordDataTab
              isPendingApprovalAndDoesntHaveApprovalRole={isPendingApprovalAndDoesntHaveApprovalRole}
              record={record}
              onIsFormUnsavedChanged={onIsFormUnsavedChanged}
              onRecordSaved={(newRecord): void => onSaved(newRecord, false)}
              className={FullWidthPanelClassName}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line -- it wants all the functions...
    [record],
  );

  return (
    <ProgressTabs<MainTabs>
      id='edit-validation-record-tabs'
      dataTestId='edit-validation-record-tabs'
      options={options}
      selectedTab={selectedTab}
      accessibilityLabel='Validation Record Modal Tabs'
    >
      <TabsAndContent>
        <LeftPanel>
          <div>
            <PaddedSelectStatusInput
              id='edit-validation-record-select-status'
              accessibilityLabel='Change record status'
              value={record.status}
              onChange={(newStatus): undefined | Promise<void> => newStatus && onStatusChange(record.staticId, newStatus)}
              disabled={isPendingApprovalAndDoesntHaveApprovalRole}
            />
            <ProgressTabs.Tabs className={PaddedProgressTabsClass} />
          </div>
          <PaddedSelectAssigneeInput
            id='edit-validation-record-select-assignee'
            dataTestId='edit-validation-record-select-assignee'
            accessibilityLabel='Change record assignee'
            value={record.assignee ?? undefined}
            onChange={(newAssignee): undefined | Promise<void> => onAssigneeChange(record.staticId, newAssignee?.id)}
            assignableUsersLoadable={validationSystemStore.allAssignableUsers}
          />
        </LeftPanel>
        <SeparatorLine vertical />
        <StyledProgressTabsPanel />
      </TabsAndContent>
    </ProgressTabs>
  );
});
export default EditValidationRecordMainTabs;

const TabsAndContent = styled.div`
  display: grid;
  grid-template-columns: 270px auto 1fr;
  overflow-y: auto;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: space-between;
`;

const PaddedSelectStatusInput = styled(SelectStatusInput)`
  margin: 20px 12px 10px;
  height: 64px;
`;

const PaddedProgressTabsClass = css`
  margin-block: 10px;
`;

const PaddedSelectAssigneeInput = styled(SelectAssigneeInput)`
  margin: 10px 12px 28px;
`;

const EmailCountWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 13px;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
`;

const StyledProgressTabsPanel = styled(ProgressTabs.Panel)`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
`;

const FullWidthPanelClassName = css`
  flex: 1;
`;
