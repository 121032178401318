import styled from '@emotion/styled';
import React, { FC, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BigText } from '@supplierRegistration/routes/Styles';
import ArrowBullet from '@app/components/ArrowBullet';
import { Link } from 'react-router-dom';
import useInfraStores from '@app/hooks/useInfraStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useAppStores from '@app/hooks/useAppStores';
import { SupplierRegistrationConfiguration } from '@supplierRegistration/domain/supplierRegistration';

// This is a temp solution until we will have real dynamic UI according the ref
const DART_DISCLAIMER_REFS = [
  'a38ee32e-7b07-42f6-8726-fff0bf12f69d', // Dev Envs - "nsKnox first test ORG" + Preprod - "Etrod"
  'b2d79578-be28-411c-b37b-1a61fecdd59b', // Prod - Dart
];

interface Props {
  isIpsMode: boolean;
  configuration: SupplierRegistrationConfiguration | null;
}

const PleaseNote: FC<Props> = ({ isIpsMode, configuration }): ReactElement => {
  const { t } = useTranslation();
  const { navigationStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  return (
    <TransferInfoContainer>
      <PleaseNotDiv>{t('supplierValidation.supplierRegister.pleaseNote')}</PleaseNotDiv>
      <TransferInfo>
        <SmallSquareArrowBullet />{' '}
        <BigText>
          <Trans i18nKey='supplierValidation.supplierRegister.yourData'>
            Your data (including any details you enter and the details of your bank account) will be stored in the highly secured
            nsKnox Cooperative Cyber Security™ (CCS) Platform. For more details on how your data is secured please visit{' '}
            <a href='https://nsknox.net/technology/' target='_blank' rel='noreferrer'>
              https://nsknox.net/technology/
            </a>
          </Trans>
        </BigText>
      </TransferInfo>
      <TransferInfo>
        <SmallSquareArrowBullet /> <BigText>{t('supplierValidation.supplierRegister.disclaimer')}</BigText>
      </TransferInfo>
      {configuration?.ref && DART_DISCLAIMER_REFS.includes(configuration.ref) && (
        <TransferInfo data-testid='dart-privacy-disclaimer'>
          <SmallSquareArrowBullet />{' '}
          <BigText>
            <Trans i18nKey='supplierValidation.supplierRegister.dartPrivacyDisclaimer'>
              Dart Enterprises Ltd. takes your privacy very seriously and will only use your personal information to respond to
              your inquiry. The full details of how we use the personal information you provide to us, and the steps we take to
              protect your privacy, can be found within our{' '}
              <a href='https://www.dart.ky/privacy-policy/' target='_blank' rel='noreferrer'>
                Privacy policy
              </a>
            </Trans>
          </BigText>
        </TransferInfo>
      )}
      <FAQLink
        id='vldt-for-supp-step-2-faq'
        data-testid='vldt-for-supp-step-2-faq'
        to={navigationStore.generateAccountValidationForSuppliersFAQ(
          isIpsMode,
          supplierRegistrationCustomerDataStore.currentReference?.referenceId ?? null,
        )}
      >
        {t('supplierValidation.faq')}
      </FAQLink>
    </TransferInfoContainer>
  );
};

export default PleaseNote;

const TransferInfoContainer = styled.div`
  max-width: 900px;
  align-self: center;
  margin-top: 40px;
  padding: 0 90px;
`;

const TransferInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const PleaseNotDiv = styled.div`
  color: #3d455a;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const SmallSquareArrowBullet = styled(ArrowBullet)`
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  margin-left: 8px;
  margin-right: 8px;
`;

const FAQLink = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline !important;
  margin-top: 36px;
  margin-left: 24px;
`;
