import React, { FunctionComponent } from 'react';

interface ImageFromResourceProps extends Omit<React.HTMLAttributes<HTMLImageElement>, 'src' | 'resource'> {
  resource: ResourceData;
  altName: string;
}

const ImageFromResource: FunctionComponent<ImageFromResourceProps> = (props) => {
  const { resource, altName, ...rest } = props;

  return <img alt={altName} src={`data:${resource.mediaType};base64,${resource.content}`} {...rest} />;
};

export default ImageFromResource;
