import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { H5StartTransparentBlack900 } from '@app/components/Text';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import ThemeProvider from '@app/routes/app/ThemeProvider';
import { IAgreeText, StyledCheckbox, StyledFormControlLabel } from '@supplierRegistration/routes/Styles';
import { PrimaryButton } from '@ar/routes/payeeVerificationPage/GuestVerificationWithPayeeAccountFormPage';

interface Props {
  onDone: (result: boolean | undefined) => void;
}

const IsraelConfirmationModal: FC<Props> = (props) => {
  const { onDone } = props;
  const [hasAgreedIsraelTerms, setHasAgreedIsraelTerms] = useState<boolean | undefined>(false);

  return (
    <ThemeProvider>
      <MainCard id='add-confirmation-israel-modal'>
        <TitleLine>
          <Title>
            <H5StartTransparentBlack900.div>כתב הסכמה לביצוע אימות בעלות על חשבון</H5StartTransparentBlack900.div>
          </Title>
          <CloseButton
            id='btn-confirmation-israel-modal-close'
            appearance='text'
            colorScheme='primary'
            onClick={(): void => onDone(undefined)}
          >
            <SVG accessibilityLabel='close' image={ClearIcon} height={30} />
          </CloseButton>
        </TitleLine>
        <div>
          חברת nsKnox מבצעת אימות של הבעלות על פרטי החשבון שסיפקתם במגוון שיטות, בין השאר תוך שימוש במידע ובשירותים מתאימים
          מהמערכת הבנקאית. במילוי הפרטים בתהליך רישום זה, לחיצה על מקש האישור ובהשלמת תהליך האימות על ידכם, הינך מאשר/ת בזאת כי
          הינך מורשה לחתום בשם בעלי החשבון וכי הינך מאשר/ת בשם בעלי החשבון ל-nsKnox לבצע אימות כאמור אל מול המערכת הבנקאית, כולל
          אימות שם בעל החשבון, כתובתו, ומספר הזהות או הח"פ שלו, והכל בהתאם לתנאי השימוש ולמדיניות הפרטיות של nsKnox. לצורך האימות
          הנ״ל על-ידי nsKnox, הינך מאשר/ת בזאת לבנק בו מתנהל החשבון להשיב לבקשת האימות של nsKnox ומוותר/ת על כל טענה אפשרית מולו
          בדבר הפרת חובת הסודיות הבנקאית אליה מחוייב הבנק כלפי לקוחותיו לפי כל דין. ידוע לך כי הינכם זכאים לחזור בכם מהסכמה זו בכל
          עת.
        </div>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              id='cbox-supp-reg-confirmation-israel'
              data-testid='cbox-supp-reg-confirmation-israel'
              checked={hasAgreedIsraelTerms}
              onChange={(newValue): void => setHasAgreedIsraelTerms(!!newValue)}
            />
          }
          label={
            <IAgreeText>
              <div>קראתי ואני מאשר/ת.</div>
            </IAgreeText>
          }
        />
        <ActionContainer>
          <PrimaryButton
            id='btn-confirmation-israel-ok'
            dataTestId='confirmIsrael'
            disabled={!hasAgreedIsraelTerms}
            onClick={(): void => onDone(true)}
          >
            המשך
          </PrimaryButton>
        </ActionContainer>
      </MainCard>
    </ThemeProvider>
  );
};

export default IsraelConfirmationModal;

const MainCard = styled(Card)`
  width: min(900px, 90vw);
  min-height: 420px;
  max-height: 70vh;

  padding: 26px 32px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  direction: rtl;
  text-align: right;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;

  margin-bottom: 4px;
`;

const Title = styled.div`
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  margin-top: 20px;
`;
