type StatusIndication<T> = {
  [K in keyof T]: boolean;
};

type StatusesSubsetFromIndication<TStatus extends string | number, TIndications extends StatusIndication<TIndications>> = {
  [K in keyof TIndications]: TStatus[];
};

export function convertStatusesIndicationsToStatusSubsets<
  TStatus extends string,
  TStatusIndication extends StatusIndication<TStatusIndication>
>(indicatorsPerSubset: Record<TStatus, TStatusIndication>): StatusesSubsetFromIndication<TStatus, TStatusIndication> {
  const result: Partial<StatusesSubsetFromIndication<TStatus, TStatusIndication>> = {};
  Object.entries(indicatorsPerSubset).forEach((statusWithIndicationEntry: [TStatus, TStatusIndication]) => {
    const [status, indications] = statusWithIndicationEntry;

    Object.entries(indications).forEach(([indicationName, isRelevant]) => {
      if (!isRelevant) {
        return;
      }

      result[indicationName] = result[indicationName] ?? [];
      result[indicationName]?.push(status);
    });
  });

  return result as StatusesSubsetFromIndication<TStatus, TStatusIndication>;
}
