import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import {
  LivePrivatePayeeValidationLevel,
  MasterDataGuardSearchableData,
  MasterDataGuardSearchableDataServerResponse,
  PayeeValidationLevel,
  PrivatePayeeAccountWithDeterministicValidation,
  PrivatePayeeAccountWithDeterministicValidationServerResponse,
  PrivatePayeeSearchableData,
  PrivatePayeeSearchableDataServerResponse,
  PrivatePayeeWithDeterministicValidations,
  SyncInfo,
  SyncInfoServerResponse,
} from '@mortee/domain/masterDataGuard';
import { groupByAndMap } from '@app/utils/utils';
import { getAccountRepresentations } from '@app/domain/accountDetailsRepresentations';
import { distinctValuesByKey } from '@app/utils/arrayUtils';
import { PrivatePayeeAccountServerResponse, RemoveSelfApproveRequest } from '@mortee/domain/privatePayeeAccount';
import { PrivatePayee, PrivatePayeeServerResponse, transformCompaniesToCompanyText } from '@mortee/domain/privatePayee';
import { transformAggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { transformDeterministicValidation } from '@mortee/domain/morteeDeterministicValidation';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import { compare } from '@app/utils/comparatorUtils';

export async function getPrivateAreaSearchableData(): Promise<MasterDataGuardSearchableDataServerResponse> {
  return request<MasterDataGuardSearchableDataServerResponse>(serverUrl, `/api/private-payees/searchable`, {
    method: HttpMethod.get,
    timeout: 600000,
    errorsHandler: {
      default: {
        message: 'Error fetching Master Data Guard live basic data.',
      },
    },
  });
}

export async function getPrivatePayeesDeterministicValidations(
  privatePayeeUniformIds: string[],
  quiet?: boolean,
): Promise<Record<string, PrivatePayeeAccountWithDeterministicValidationServerResponse[]>> {
  return request<Record<string, PrivatePayeeAccountWithDeterministicValidationServerResponse[]>>(
    serverUrl,
    `/api/private-payees/deterministic`,
    {
      data: privatePayeeUniformIds,
      method: HttpMethod.post,
      suppressNotification: quiet,
      errorsHandler: {
        default: {
          message: 'Error fetching Master Data Guard live validation data.',
        },
      },
    },
  );
}

export async function getAllPrivatePayeesNotValidatedDeterministicValidations(): Promise<
  Record<string, PrivatePayeeAccountWithDeterministicValidationServerResponse[]>
> {
  return request<Record<string, PrivatePayeeAccountWithDeterministicValidationServerResponse[]>>(
    serverUrl,
    `/api/private-payees/deterministic/not-validated`,
    {
      method: HttpMethod.get,
      timeout: 600000,
      errorsHandler: {
        default: {
          message: 'Error fetching Master Data Guard live validation data.',
        },
      },
    },
  );
}

export async function getPrivatePayeesVisibleLiveData(
  uniformIds: string[] | undefined,
  validationLevel: LivePrivatePayeeValidationLevel,
): Promise<NamedResource> {
  return request<NamedResource>(serverUrl, `/api/private-payees/deterministic/reports`, {
    params: { onlyNotValidated: validationLevel === LivePrivatePayeeValidationLevel.notValidated },
    data: uniformIds,
    method: HttpMethod.post,
    timeout: 3000000,
    errorsHandler: {
      default: {
        message: 'Error generating Master Data Guard report.',
      },
    },
  });
}

export async function getSyncInfo(): Promise<SyncInfoServerResponse> {
  return request<SyncInfoServerResponse>(serverUrl, `/api/sync/info`, {
    method: HttpMethod.get,
    timeout: 300000,
    headers: {
      'Api-Version': 'v2',
    },
    errorsHandler: {
      default: {
        message: 'Error fetching Master Data Guard live validation info.',
      },
    },
  });
}

export function storeInitiateSelfApprove(
  privatePayeeUniformId: string,
  privatePayeeAccountUniformId: string,
  data: FormData,
): Promise<PrivatePayeeAccountServerResponse> {
  return request<PrivatePayeeAccountServerResponse>(
    serverUrl,
    `/api/private-payees/:privatePayeeUniformId/accounts/:privatePayeeAccountUniformId/initiate-self-approve`,
    {
      method: HttpMethod.post,
      pathParams: {
        privatePayeeUniformId,
        privatePayeeAccountUniformId,
      },
      isFileUpload: true,
      data: data,
      timeout: 5400000,
      suppressNotification: true,
    },
  );
}

export function storeRemoveSelfApprove(
  privatePayeeUniformId: string,
  privatePayeeAccountUniformId: string,
  data: RemoveSelfApproveRequest,
): Promise<PrivatePayeeAccountServerResponse> {
  return request<PrivatePayeeAccountServerResponse>(
    serverUrl,
    `/api/private-payees/:privatePayeeUniformId/accounts/:privatePayeeAccountUniformId/initiate-remove-self-approve`,
    {
      method: HttpMethod.post,
      pathParams: {
        privatePayeeUniformId,
        privatePayeeAccountUniformId,
      },
      data: data,
      suppressNotification: true,
    },
  );
}

export function fetchSelfApproveEventAttachmentFile(
  privatePayeeUniformId: string,
  privateAccountUniformId: string,
  eventId: string,
  fileId: string,
): Promise<NamedResource> {
  return request<NamedResource>(
    serverUrl,
    `/api/private-payees/:privatePayeeUniformId/accounts/:privateAccountUniformId/events/:eventId/files/:fileId`,
    {
      method: HttpMethod.get,
      pathParams: {
        privatePayeeUniformId,
        privateAccountUniformId,
        eventId,
        fileId,
      },
      errorsHandler: {
        default: {
          message: 'Error while downloading MVF file.',
        },
      },
    },
  );
}

export function transformToMasterDataGuardSearchableData({
  payees,
  payeeAccountsAmount,
  companies,
}: MasterDataGuardSearchableDataServerResponse): MasterDataGuardSearchableData {
  return {
    payeeAccountsAmount,
    payees: payees.map(transformToPrivatePayeeSearchableData),
    companyCodes: {
      doesAllHaveCompanies: companies.doesAllHaveCompanies,
      companyCodes: transformCompaniesToCompanyText(companies.companies),
    },
  };
}

export function transformToSyncInfo(serverResponse: SyncInfoServerResponse): SyncInfo {
  return serverResponse;
}

function transformToPrivatePayeeSearchableData({
  uniformId,
  externalId,
  names,
  supplierNumbers,
  companies,
}: PrivatePayeeSearchableDataServerResponse): PrivatePayeeSearchableData {
  return {
    uniformId,
    supplierNumbers: supplierNumbers ?? [],
    companyCodes: transformCompaniesToCompanyText(companies),
    externalIdLowercase: externalId?.toLowerCase(),
    namesLowercase: names?.map((name) => name.toLowerCase()),
  };
}

export function transformToPrivatePayeeWithDeterministicValidation(
  accountWithDVs: PrivatePayeeAccountWithDeterministicValidationServerResponse[],
): PrivatePayeeWithDeterministicValidations | null {
  // An invalid state - even if payee does not have any accounts - there should be a single result with null account
  if (!accountWithDVs?.length) {
    return null;
  }

  const {
    privatePayee: privatePayeeServerResponse,
    validatedPayees: validatedPayeeServerResponse,
  } = accountWithDVs[0].deterministicValidationData;

  const privatePayee = transformToPrivatePayee(privatePayeeServerResponse);
  const validatedPayees = validatedPayeeServerResponse.map(transformAggregatedValidatedPayee);

  const accountsValidation = accountWithDVs
    .map(
      (accountWithDV): PrivatePayeeAccountWithDeterministicValidation => {
        return {
          privatePayeeAccount: accountWithDV.privatePayeeAccount,
          deterministicValidation: transformDeterministicValidation(accountWithDV.deterministicValidationData),
        };
      },
    )
    .filter(
      (privateAccountWithDV) =>
        !!getAccountRepresentations({ ...privateAccountWithDV.privatePayeeAccount?.accountDetails, localFormat: null })
          .allWithPartial.length,
    );

  const validatedPayeeUniformIdToAllValidatedPayeeAccount = groupByAndMap(
    accountsValidation.flatMap((x) => Object.entries(x.deterministicValidation.validatedPayeesAccounts)),
    ([key]) => key,
    ([, accounts]) => accounts,
  );

  const validatedPayeesAccounts = Object.fromEntries(
    Array.from(validatedPayeeUniformIdToAllValidatedPayeeAccount.entries()).map(
      ([validatedPayeeUniformId, validatedPayeeAccountsDoubleArray]) => {
        const arr = validatedPayeeAccountsDoubleArray.flat();
        return [validatedPayeeUniformId, distinctValuesByKey(arr, (validatedPayeeAccount) => validatedPayeeAccount.uniformId)];
      },
    ),
  );

  const notValidatedAccounts = accountsValidation.filter(
    (x) => x.deterministicValidation.validationLevel === DeterministicValidationResultType.notValidated,
  );

  let payeeValidationLevel: PayeeValidationLevel;

  if (!notValidatedAccounts.length) {
    payeeValidationLevel = PayeeValidationLevel.valid;
  } else if (notValidatedAccounts.length === accountsValidation.length) {
    payeeValidationLevel = PayeeValidationLevel.allNotValidated;
  } else {
    payeeValidationLevel = PayeeValidationLevel.someNotValidated;
  }

  return {
    privatePayee,
    validatedPayees,
    validatedPayeesAccounts,
    accountsValidation,
    payeeValidationLevel,
  };
}

export function transformToPrivatePayee(privatePayeeServerResponse: PrivatePayeeServerResponse): PrivatePayee {
  return {
    ...privatePayeeServerResponse,
    data: {
      ...privatePayeeServerResponse.data,
      supplierNumbers: privatePayeeServerResponse.data.supplierNumbers ?? [],
      companyCodes: transformCompaniesToCompanyText(privatePayeeServerResponse.data.companies).sort(
        compare.stringsCaseInsensitive(),
      ),
    },
  };
}
