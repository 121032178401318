import {
  Cell,
  excelColumnIndexToLetters,
  ExcelRowDefinition,
  excelRowIntoObject,
  readExcelRowsFromClipboardHtmlContent,
} from '@app/utils/excelUtils';
import { convertReadErrorToExplanation, readHTMLContentFromClipboardDirectly } from '@app/utils/clipboardUtils';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';
import React from 'react';

export const handleReadFromClipboard = async (): Promise<null | SupplierRegistrationExcelRow> => {
  try {
    return applyValidatedPayeeExcelRowToForm(await readHTMLContentFromClipboardDirectly());
  } catch (e: unknown) {
    let modalErrorContent: string;

    if (e instanceof Error) {
      modalErrorContent = convertReadErrorToExplanation(e.message);
    } else {
      modalErrorContent = convertReadErrorToExplanation(null);
    }

    showContentOnlyModal((onDone) => (
      <ErrorModal headerContent='Paste Error' bodyContent={modalErrorContent} okButtonText='OK' onDone={onDone} />
    ));
    return null;
  }
};

const applyValidatedPayeeExcelRowToForm = (clipboardExcelRowsHtml: string | null): null | SupplierRegistrationExcelRow => {
  if (!clipboardExcelRowsHtml) {
    showContentOnlyModal((onDone) => (
      <ErrorModal
        headerContent='Paste Error'
        bodyContent='No suitable content found in clipboard'
        okButtonText='OK'
        onDone={onDone}
      />
    ));
    return null;
  }

  const rows = readExcelRowsFromClipboardHtmlContent(clipboardExcelRowsHtml);

  if (!rows.length) {
    showContentOnlyModal((onDone) => (
      <ErrorModal
        headerContent='Paste Error'
        bodyContent='Could not find excel rows in clipboard to fill the form'
        okButtonText='OK'
        onDone={onDone}
      />
    ));
    return null;
  }

  return excelRowIntoRegistration(rows[0]);
};

export function excelRowIntoRegistration(row: Cell[]): SupplierRegistrationExcelRow {
  return excelRowIntoObject(row, getValidatedPayeeExcelRowFieldToColumn()) as SupplierRegistrationExcelRow;
}

export class SupplierRegistrationExcelRow {
  registrationType: string | null | undefined;
  companyName: string | null | undefined;
  additionalCompanyName: string | null | undefined;
  companyAddressCountry: string | null | undefined;
  companyAddress: string | null | undefined;
  companyAddressCity: string | null | undefined;
  companyAddressState: string | null | undefined;
  companyAddressZipCode: string | null | undefined;
  fullName: string | null | undefined;
  leiType: string | null | undefined;
  leiTypeOtherValue: string | null | undefined;
  leiValue: string | null | undefined;
  phoneCountryCode: string | null | undefined;
  phoneNumber: string | null | undefined;
  email: string | null | undefined;
  country: string | null | undefined;
  website: string | null | undefined;
  referrer: string | null | undefined;
  hasAgreed: string | null | undefined;
  swiftCode: string | null | undefined;
  bankCode: string | null | undefined;
  branchCode: string | null | undefined;
  accountNumber: string | null | undefined;
  accountBankCountryCode: string | null | undefined;
  abaRouting: string | null | undefined;
  currency: string | null | undefined;
  furtherCredit: string | null | undefined;
  canUseAch: string | null | undefined;
  canUseEft: string | null | undefined;
  isIndividual: string | null | undefined;
  accountType: string | null | undefined;
  dateOfBirth: string | null | undefined;
}

//Dynamically populate the columns letters from the class
export function getValidatedPayeeExcelRowFieldToColumn(): ExcelRowDefinition<SupplierRegistrationExcelRow> {
  const keysOfProps = Object.keys(new SupplierRegistrationExcelRow());
  const validatedPayeeExcelRowFieldToColumn = {};
  keysOfProps.map((key, index) => (validatedPayeeExcelRowFieldToColumn[key] = excelColumnIndexToLetters(index)));
  return validatedPayeeExcelRowFieldToColumn as ExcelRowDefinition<SupplierRegistrationExcelRow>;
}
