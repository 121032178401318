import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { downloadResourceByNamedResource } from '@app/utils/fileUtils';

export enum AttachmentSource {
  ips = 'ips',
  verification = 'verifications',
  guestVerification = 'guest',
}

export async function downloadAccountAttachment(
  source: AttachmentSource,
  validatedPayeeUniformId: string,
  validatedPayeeAccountUniformId: string,
  fileId: string,
): Promise<void> {
  const attachment = await request<NamedResource>(
    serverUrl,
    `/api/:source/:validatedPayeeUniformId/accounts/:validatedPayeeAccountUniformId/attachments/:fileId`,
    {
      method: HttpMethod.get,
      pathParams: {
        source,
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId,
        fileId,
      },
      errorsHandler: {
        WELCOME_LETTER_CREATION_ERROR: {
          message: (): string => `Error occurred while trying to download attachment.`,
        },
        default: {
          message: 'Cannot download attachment.',
        },
      },
    },
  );

  downloadResourceByNamedResource(attachment);
}
