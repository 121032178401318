import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { BodyRegularStartTransparentBlack600, Bold13TransparentBlack900, H5StartTransparentBlack900 } from '@app/components/Text';
import CreateVerificationReminderForm, {
  CreateVerificationReminderFormFields,
} from '@mortee/routes/ips/createReminderModal/CreateVerificationReminderForm';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { getValidatedPayeesOwnedByMyOrganization, storeValidationReminder } from '@mortee/services/ipsServices';
import { transformValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';
import CreateVerificationReminderSummary, {
  CreateVerificationReminderSummaryFields,
} from '@mortee/routes/ips/createReminderModal/CreateVerificationReminderSummary';
import { MILLISECONDS_PER_DAY } from '@app/utils/timeUtils';
import TransitionLoader from '@app/components/TransitionLoader';
import { css } from '@emotion/css';

import { compare } from '@app/utils/comparatorUtils';

interface Props {
  onSuccess(): void;
  onCancel(): void;
}

interface FormViewState {
  view: 'form';
  formValues: CreateVerificationReminderFormFields | null;
}

interface SummaryViewState {
  view: 'summary';
  formValues: CreateVerificationReminderFormFields;
}

type ViewState = FormViewState | SummaryViewState;
const viewStatesOrder: ViewState['view'][] = ['form', 'summary'];

const CreateVerificationReminderModal: FC<Props> = (props) => {
  const { onSuccess, onCancel } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewState, setViewState] = useState<ViewState>({ view: 'form', formValues: null });

  const [accountsData] = useLoadable(async () => {
    const myValidatedPayeesWithAccounts = await getValidatedPayeesOwnedByMyOrganization();

    const payeeAndAccountPairs = myValidatedPayeesWithAccounts
      .map(transformValidatedPayeeWithAccounts)
      .flatMap((payeeWithAccounts) =>
        payeeWithAccounts.accounts.map((accountOfPayee) => ({
          payee: payeeWithAccounts.aggregatedPayeeData,
          account: accountOfPayee,
        })),
      );

    const payeeUniformIdByAccountUniformId = new Map<string, string>(
      payeeAndAccountPairs.map(({ payee, account }) => [account.uniformId, payee.uniformId]),
    );

    const accountDisplayTextByAccountUniformId = new Map<string, string>(
      payeeAndAccountPairs
        .map(({ payee, account }): [string, string] => [
          account.uniformId,
          `${payee.mainName} - ${account.bankName} - ${account.shortAccountDetails}`,
        ])
        .sort(compare.byStringField(([, displayText]) => displayText)),
    );

    return {
      payeeUniformIdByAccountUniformId,
      accountDisplayTextByAccountUniformId,
    };
  }, []);

  const onFormFilled = (formValues: CreateVerificationReminderFormFields): void => {
    setViewState({ view: 'summary', formValues: formValues });
  };

  const onSummaryBack = (): void => {
    setViewState({ view: 'form', formValues: viewState.formValues });
  };

  const onCreateReminder = async (): Promise<void> => {
    const { formValues } = viewState;
    if (!formValues) {
      return;
    }

    const validatedPayeeUniformId = accountsData.result?.payeeUniformIdByAccountUniformId.get(
      formValues.accountUniformIdToMonitor,
    );

    if (!validatedPayeeUniformId) {
      return;
    }

    const expirationTimestamp = Date.now() + MILLISECONDS_PER_DAY * Number(formValues.timePeriod);

    setIsLoading(true);

    try {
      await storeValidationReminder({
        expirationTimestamp,
        validatedPayeeUniformId,
        validatedPayeeAccountUniformId: formValues.accountUniformIdToMonitor,
        payerName: formValues.payerName,
        emailDomainToNotify: formValues.emailsAndDomains,
      });
      onSuccess();
    } finally {
      setIsLoading(false);
    }
  };

  const convertFormValuesToSummaryValues = (
    formValues: CreateVerificationReminderFormFields,
  ): CreateVerificationReminderSummaryFields => {
    return {
      payerName: formValues.payerName,
      emailsAndDomains: formValues.emailsAndDomains,
      accountToMonitor: accountsData.result?.accountDisplayTextByAccountUniformId.get(formValues.accountUniformIdToMonitor) ?? '',
      timePeriod: formValues.timePeriod,
    };
  };

  return (
    <Card>
      <TransitionLoader loading={isLoading} contentClassName={modalContentCss}>
        <TitleLine>
          <Title>
            <Bold13TransparentBlack900.div>
              Step {viewStatesOrder.indexOf(viewState.view) + 1} of {viewStatesOrder.length}
            </Bold13TransparentBlack900.div>
            <H5StartTransparentBlack900.div>New verification reminder</H5StartTransparentBlack900.div>
            {viewState.view === 'form' && (
              <BodyRegularStartTransparentBlack600.div>
                Fill the details below to monitor if your payee has verified your bank account details. A reminder will be sent to
                you if they did not verify after the specified timeframe.
              </BodyRegularStartTransparentBlack600.div>
            )}
          </Title>
          <CloseButton
            id='btn-create-verification-reminder-modal-close'
            appearance='text'
            colorScheme='primary'
            onClick={onCancel}
          >
            <SVG accessibilityLabel='close' image={ClearIcon} height={20} />
          </CloseButton>
        </TitleLine>
        {viewState.view === 'form' ? (
          <StyledCreateVerificationReminderForm
            accountsOfUserByUniformId={accountsData.map(
              (loadedAccountsData) => loadedAccountsData.accountDisplayTextByAccountUniformId,
            )}
            initialFormValues={viewState.formValues}
            onFormSubmit={onFormFilled}
          />
        ) : (
          <StyledCreateVerificationReminderSummary
            values={convertFormValuesToSummaryValues(viewState.formValues)}
            onOk={onCreateReminder}
            onBack={onSummaryBack}
          />
        )}
      </TransitionLoader>
    </Card>
  );
};

export default CreateVerificationReminderModal;

const modalContentCss = css`
  width: min(600px, 90vw);
  min-height: 480px;
  max-height: 90vh;

  padding: 26px 32px 32px;
  position: relative;

  display: flex;
  flex-direction: column;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 auto;

  margin-bottom: 30px;
`;

const Title = styled.div`
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const StyledCreateVerificationReminderForm = styled(CreateVerificationReminderForm)`
  flex: 1;
`;

const StyledCreateVerificationReminderSummary = styled(CreateVerificationReminderSummary)`
  flex: 1;
`;
