import { ColumnProps } from 'antd/lib/table';
import React, { ReactElement } from 'react';
import FilterColumnSingleValuePopup from './FilterColumnSingleValuePopup';
import { RadioOption } from '@app/components/inputs/FormRadioGroup';
import { isDefinedAndHasLength } from '@app/utils/utils';
import { valueOrValuesOrNullAsArray } from '@app/utils/arrayUtils';

export function getColumnConstFilterProps<T>(
  radiobuttonGroupAccessibilityLabel: string,
  values: RadioOption[],
  isSelectedValueValid: (value: string, record: T) => boolean,
  defaultValue?: string,
): Partial<ColumnProps<T>> {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnSingleValuePopup
        values={values}
        defaultValue={defaultValue}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        radiobuttonGroupAccessibilityLabel={radiobuttonGroupAccessibilityLabel}
      />
    ),
    onFilter: (selectedFilterOption: string | undefined, record): boolean => {
      return !selectedFilterOption || isSelectedValueValid(selectedFilterOption, record);
    },
  };
}

export function getColumnSingleServerFilterProps<T>(
  radiobuttonGroupAccessibilityLabel: string,
  values: RadioOption[],
  currentFilterValue: string | undefined,
): Partial<ColumnProps<T>> {
  return {
    filteredValue: valueOrValuesOrNullAsArray(currentFilterValue),
    filtered: isDefinedAndHasLength(currentFilterValue),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): ReactElement => (
      <FilterColumnSingleValuePopup
        values={values}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        radiobuttonGroupAccessibilityLabel={radiobuttonGroupAccessibilityLabel}
      />
    ),
  };
}
