import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { PrivatePayeeAccount, RemoveSelfApproveRequest } from '@mortee/domain/privatePayeeAccount';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { BodyRegular15TransparentBlack900 } from '@app/components/Text';
import { SelfApproveFields } from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/PrivateAccountInitiateSelfApproveForm';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';
import Log from '@app/libs/logger';
import { showContentOnlyModal } from '@app/components/Modal';
import { trim } from '@app/utils/stringUtils';
import ErrorModal from '@app/components/ErrorModal';
import { DEFAULT_FORM_VALIDATION_OPTIONS } from '@app/utils/form/form';
import PrivateAccountRemoveSelfApproveForm, {
  RemoveSelfApproveFormFields,
} from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/PrivateAccountRemoveSelfApproveForm';
import useMountEffect from '@app/hooks/useMountEffect';
import { PrivatePayee } from '@mortee/domain/privatePayee';
import { DeterministicValidationWithPrivatePayee } from '@mortee/domain/morteeDeterministicValidation';
import { RequestError } from '@app/libs/request';

interface Props extends FormComponentProps<SelfApproveFields> {
  privatePayee: PrivatePayee;
  privatePayeeAccount: PrivatePayeeAccount;
  deterministicValidation: DeterministicValidationWithPrivatePayee;
  // Remove this method prop when moving to antd v4 - it has a way to pass context to modals
  // (and just use masterDataGuardStore.storeInitiateSelfApprove)
  onSubmit: (payload: RemoveSelfApproveRequest) => Promise<void>;
  onClose: () => void;
}

const RemoveSelfApproveModal: FC<Props> = (props) => {
  useMountEffect(() => {
    form.validateFields();
  });

  const { form, onSubmit, onClose } = props;

  const [showFormErrors, setShowFormErrors] = useState<FormItemsBoxProps['showErrors']>('onTouch');
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleOk();
  };

  const handleOkClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleOk();
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll(DEFAULT_FORM_VALIDATION_OPTIONS, (errors: Object, fields: RemoveSelfApproveFormFields) => {
      if (errors) {
        setShowFormErrors('all');
        return;
      }

      storeValidationProcessRequest(fields);
    });
  };

  const storeValidationProcessRequest = async (fields: RemoveSelfApproveFormFields): Promise<void> => {
    if (loading) {
      return;
    }

    const payload: RemoveSelfApproveRequest = {
      note: trim(fields.note),
    };

    setLoading(true);

    try {
      await onSubmit(payload);
      onClose();
    } catch (e: unknown) {
      if (e instanceof Error && e.message === 'Error updating list after change') {
        onClose();
        return;
      }

      Log.exception(e);
      const errorLogId = (e as RequestError).logCorrelationId;
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent='Something went wrong'
          bodyContent={`Something went wrong, please try again later.${errorLogId ? '\n' + 'Error code: ' + errorLogId : ''}`}
          okButtonText='OK'
          onDone={onDone}
        />
      ));
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer>
      <TitleText>Cancel Self Validation</TitleText>
      <Form onSubmit={handleFormSubmit}>
        <PrivateAccountRemoveSelfApproveForm form={form} showFormErrors={showFormErrors} />
        <ActionsContainer>
          <StyledCancelButton id='btn-mvf-guard-remove-sa-cancel' onClick={onClose} appearance='outline' disabled={loading}>
            CANCEL
          </StyledCancelButton>
          <StyledRemoveSelfApproveButton
            id='btn-mvf-guard-remove-sa-confirm'
            onClick={handleOkClick}
            loading={loading}
            loaderProps={{ customColor: 'white' }}
          >
            CANCEL SELF VALIDATION
          </StyledRemoveSelfApproveButton>
        </ActionsContainer>
      </Form>
    </MainContainer>
  );
};

export default Form.create<Props>()(RemoveSelfApproveModal);

const MainContainer = styled(Card)`
  width: min(728px, 90vw);
  padding: 19px 36px 26px 28px;
  position: relative;
  box-shadow: var(--box-shadow-3);

  max-height: 95vh;
  display: flex;
  flex-direction: column;

  --side-padding: 32px;
`;

const TitleText = styled(BodyRegular15TransparentBlack900.div)`
  margin-bottom: 15px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  margin-top: 16px;
`;

const StyledCancelButton = styled(Button)`
  padding: 9px 31px;
`;

const StyledRemoveSelfApproveButton = styled(Button)`
  padding: 9px 16px;
  margin-left: 17px;
`;
