import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { isDefined } from '@app/utils/utils';

interface Props {
  entityChangeMessages: EntityChangeMessage[];
  className?: string;
}

export interface EntityChangeMessage {
  content: ReactNode;
  type: 'error' | 'notice' | 'regular';
}

const VerificationEntityChangesBullets: FC<Props> = ({ entityChangeMessages, className }) => {
  function entityChangeTypeToBulletType(entityChangeType: EntityChangeMessage['type']): string | null {
    switch (entityChangeType) {
      case 'error': {
        return '🛑';
      }
      case 'notice': {
        return '⚠️';
      }
      case 'regular': {
        return null;
      }
    }
  }

  return (
    <ul>
      {entityChangeMessages.filter(isDefined).map((renderedAlert, i) => (
        <EntityChangeBullet key={i} data-testid={`entity-change-${i}`} bullet={entityChangeTypeToBulletType(renderedAlert.type)}>
          {renderedAlert.content}
        </EntityChangeBullet>
      ))}
    </ul>
  );
};

export default VerificationEntityChangesBullets;

const EntityChangeBullet = styled.li<{ bullet: string | null }>`
  ${(p): string => (p.bullet ? `list-style-type: "${p.bullet} "` : '')};
`;
