import React, { FC, ReactElement } from 'react';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import FormRadioGroup, { RadioOption } from '@app/components/inputs/FormRadioGroup';

interface Props {
  values: RadioOption[];
  radiobuttonGroupAccessibilityLabel: string;
  defaultValue?: string;
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  confirm: () => void;
  clearFilters: () => void;
}

const FilterColumnSingleValuePopup: FC<Props> = ({
  values,
  radiobuttonGroupAccessibilityLabel,
  defaultValue,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}): ReactElement => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onApply();
    }
  };

  const onApply = (): void => {
    if (defaultValue && selectedKeys[0] === defaultValue) {
      clearFilters();
    } else {
      confirm();
    }
  };

  return (
    <PopupContent onKeyPress={handleKeyPress}>
      <Heading>
        <Title>Filter</Title>
        <Button appearance='text' id='btn-table-single-value-filter-reset' onClick={(): void => clearFilters()} size='small'>
          CLEAR
        </Button>
      </Heading>
      <StyledFormRadioGroup
        id='radio-group-table-single-value-filter'
        dataTestId='radio-group-table-single-value-filter'
        options={values}
        value={selectedKeys[0] ?? defaultValue}
        direction='column'
        onChange={(value): void => setSelectedKeys(value ? [value] : [])}
        accessibilityLabel={radiobuttonGroupAccessibilityLabel}
      />
      <Actions>
        <Button id='btn-table-single-value-filter-apply' size='small' onClick={(): void => onApply()}>
          APPLY
        </Button>
      </Actions>
    </PopupContent>
  );
};

export default FilterColumnSingleValuePopup;

const PopupContent = styled.div`
  padding: 10px 12px 10px 18px;
  max-height: 95vh;

  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  flex: 0 0 auto;

  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);

  flex: 1;
`;

const StyledFormRadioGroup = styled(FormRadioGroup)`
  flex: 1;
  overflow-y: scroll;
`;

const Actions = styled.div`
  flex: 0 0 auto;

  padding-top: 12px;

  display: flex;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
