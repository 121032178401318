import React from 'react';
import { InstructionFormDetails } from '../../commonAfterFormPage/InstructionFormDetails';
import { NormalText } from '../../Styles';

export const LeumiFormDetails: InstructionFormDetails = {
  instructionsSubTitle: 'supplierValidation.afterRegister.nowYouAreRegistered',
  paymentInstructionsTitle: 'supplierValidation.afterRegister.paymentInst',
  paymentInstructionsLines: [
    {
      description: 'supplierValidation.afterRegister.beneficiaryName',
      value: 'nsKnox Technologies, Inc',
    },
    {
      description: 'supplierValidation.afterRegister.address',
      value: '135 Madison Ave, New York, NY 10016',
    },
    { description: 'supplierValidation.afterRegister.checkingAccount', value: '4632793200' },
    { description: 'supplierValidation.afterRegister.swift', value: 'LUMIUS3N' },
    { description: 'supplierValidation.afterRegister.abaRouting', value: '026002794' },
  ],
  paymentInstructionsAddress: {
    description: 'supplierValidation.afterRegister.bankAndAddress',
    value: (
      <NormalText>
        Bank Leumi USA,
        <br />
        579 Fifth Avenue,
        <br />
        New York, NY 10017
      </NormalText>
    ),
  },
  currencies: [
    'USD',
    'EUR',
    'GBP',
    'CHF',
    'AUD',
    'NZD',
    'CAD',
    'JPY',
    'MXN',
    'SGD',
    'DKK',
    'NOK',
    'SEK',
    'TRY',
    'ZAR',
    'HKD',
    'CNY',
    'ILS',
  ],
  pleaseNote: {
    pleaseNoteTitle: 'supplierValidation.afterRegister.pleaseNote',
    pleaseNoteItem: [
      'supplierValidation.afterRegister.transferMustBe',
      'supplierValidation.afterRegister.leumi.supportedCurrencies',
    ],
  },
  showWireTransferWarningBox: {
    achWireTransfer: true,
    swiftWireTransfer: false,
  },
  extraInstructions: null,
};
