import { KnoxerAuthConfigData, LoginMode } from '@app/login/domain/loginConsts';
import { GuestKnoxerAddressData } from '@app/guestLogin/domain/guestLogin';

export type AppMode = 'mortee' | 'supplierRegistration' | 'ar' | 'backee';

const configJSON: Partial<ConfigProperties> = window.appConfig ?? {};

export type ConfigProperties = DeepReadonly<{
  initialView: string;
  serverUrls: {
    mortee: url;
    backee: url;
    supplierRegistration: url;
    ar: url;
    svm: url;
    logout: url;
    guestLogout: url;
  };
  baseUrl: string;
  apiVersion: string;
  urlPath: string;
  knoxersAuthData: KnoxerAuthConfigData[];
  defaultRequestTimeout: number;
  version: string;
  sentryUrlPath: string;
  gtmId: string;
  mode: AppMode;
  defaultLocale: string;
  loginModes: LoginMode[];
  loginMandatoryOnly: boolean;
  runHealthCheck: boolean;
  resendLoginCodeTimerDurationSec: number;
  mvfCheckInProgressSurveysPollingIntervalSec: number;
  downloadPDFProgressExpectedDurationSec: number;
  downloadGuestPDFProgressExpectedDurationSec: number;
  guestLoginAddresses?: { [key: string]: GuestKnoxerAddressData };
  shouldLoginWithPhone: boolean;
  shouldDisplayGuestSSO: boolean;
  supplierRegistration: {
    video: {
      visible: boolean;
      autoplayingUrl: string;
      nonAutoplayingUrl: string;
    };
    captcha: {
      siteKey: string;
    };
  };
  registrationForms: {
    tableBackgroundRefreshIntervalSecs: number;
  };
  svManagement: {
    tablePageSize: number;
    tableBackgroundRefreshIntervalSecs: number;
    registrationFormsPriorMonths?: number;
    commonCommentList: string[];
    commonAlertList: string[];
  };
  featureFlags?: {
    masavDemo: boolean;
    quickFillSupplierRegistration: boolean;
    finicityOpenBanking?: boolean;
    supplierRegistrationCaptcha?: boolean;
    hallucinateSEBSubdomain: boolean;
  };
}>;

const urlPath = getUrlPath();

const config: Partial<ConfigProperties> = {
  ...configJSON,
  urlPath,
  knoxersAuthData: configJSON.knoxersAuthData ?? [],
  loginModes: configJSON.loginModes ?? [LoginMode.Local],
  serverUrls: configJSON.serverUrls ?? {
    mortee: '',
    backee: '',
    supplierRegistration: '',
    ar: '',
    svm: '',
    logout: '',
    guestLogout: '',
  },
};

const fullConfig = config as ConfigProperties;
export default fullConfig;
export const serverUrl = Object.freeze(fullConfig.serverUrls[fullConfig.mode]);

function getUrlPath(): string {
  const loc = configJSON.baseUrl ? getLocation(configJSON.baseUrl) : null;

  let urlPath = loc?.pathname || '';

  // urlPath should start with / and end without /
  // urlPath can be empty string
  if (!urlPath.startsWith('/')) {
    urlPath = `/${configJSON.urlPath}`;
  }

  if (urlPath.endsWith('/')) {
    urlPath = urlPath.slice(0, -1);
  }

  return urlPath;
}

function getLocation(href: string): SimpleLocation | null {
  const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);

  return (
    match && {
      href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  );
}
