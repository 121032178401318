import React from 'react';
import { FormFieldDecorators, FormInputPlaceholderProps } from '@app/utils/form/form';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { ForwardingFC } from '@app/domain/technicals/components';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { PHONE_PREFIX } from '@backee/routes/users/CreateUserPage';
import { trim } from '@app/utils/stringUtils';
import { REQUIRED_FORM_MESSAGE, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { USER_FIELD_MAX_LENGTH } from '@app/domain/userManagement/users';
import { emailAdditionalValidations, validateInternationalTextPhoneNumber } from '@app/utils/validators';
import InternationalPhoneNumberFormInput from '@backee/components/InternationalPhoneNumberFormInput';
import styled from '@emotion/styled';
import useAppStores from '@app/hooks/useAppStores';
import BackeeMode from '@backee/backeeMode';

export interface EmailAndPhoneIdentityForUser {
  email: string;
  phoneNumber: string;
}

interface Props extends FormInputPlaceholderProps<false> {
  id: string;
  dataTestId?: string;
  fieldName: string;
  disabled?: boolean;
  className?: string;
}

const EmailAndPhoneFlowInput: ForwardingFC<HTMLDivElement, Props> = React.forwardRef(
  ({ id, dataTestId = id, fieldName, accessibilityLabel, disabled, className }, ref) => {
    const { knoxersStore } = useAppStores<BackeeMode>();
    const knoxers = knoxersStore.knoxers.result;
    const fieldDecorators = createFieldDecorators(PHONE_PREFIX);

    return (
      <Line className={className} ref={ref}>
        <SameWidthFormItemBox
          fieldName={`${fieldName}.email`}
          fieldDecoratorOptions={fieldDecorators.email}
          disabled={disabled}
          showAsRequired
        >
          <NakedFormInput
            name={`txt-${id}-email`}
            dataTestId={`txt-${dataTestId}-email`}
            accessibilityLabel={`${accessibilityLabel} - Email`}
            type='text'
            placeholder={`Email - ${knoxers?.emailKnoxer.name} Knoxer`}
            disableSuggestion
          />
        </SameWidthFormItemBox>
        <SameWidthFormItemBox
          fieldName={`${fieldName}.phoneNumber`}
          fieldDecoratorOptions={fieldDecorators.phoneNumber}
          disabled={disabled}
          showAsRequired
        >
          <InternationalPhoneNumberFormInput
            type='text'
            name={`txt-${id}-phone-number`}
            dataTestId={`txt-${dataTestId}-phone-number`}
            accessibilityLabel={`${accessibilityLabel} - Phone number`}
            placeholder={`Phone number - ${knoxers?.phoneKnoxer.name} Knoxer`}
            disableSuggestion
          />
        </SameWidthFormItemBox>
      </Line>
    );
  },
);

export default EmailAndPhoneFlowInput;
const createFieldDecorators = (prefix: string): FormFieldDecorators<EmailAndPhoneIdentityForUser> => ({
  email: {
    rules: [
      {
        required: true,
        transform: trim,
        message: REQUIRED_FORM_MESSAGE,
      },
      {
        max: USER_FIELD_MAX_LENGTH.email,
        transform: trim,
        message: `Max ${USER_FIELD_MAX_LENGTH.email} characters`,
      },
      {
        type: 'email',
        transform: trim,
        message: 'Please enter a valid email',
      },
      {
        validator: emailAdditionalValidations('please enter a valid email'),
      },
    ],
  },
  phoneNumber: {
    rules: [
      {
        required: true,
        transform: trim,
        message: REQUIRED_FORM_MESSAGE,
      },
      {
        max: USER_FIELD_MAX_LENGTH.phone,
        transform: trim,
        message: `Max ${USER_FIELD_MAX_LENGTH.phone} characters`,
      },
      {
        transform: trim,
        pattern: VALIDATION_PATTERNS.numericWithSpecialChars,
        message: 'Invalid character',
      },
      {
        validator: validateInternationalTextPhoneNumber('Phone number is invalid', prefix),
      },
    ],
  },
});

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const SameWidthFormItemBox = styled(FormItemBox)`
  flex: 1;
`;
