import { ButtonSmallCenterDisable, CaptionStartTransparentBlack600 } from '@app/components/Text';
import React, { ReactElement } from 'react';
import AddSpriteSymbol from '@app/images/plus-primary.svg';
import AddDisabledSpriteSymbol from '@app/images/plus-grey.svg';
import ClippySpriteSymbol from '@app/images/clippy-primary.svg';
import ClippyDisabledSpriteSymbol from '@app/images/clippy-grey.svg';
import RemoveFileSpriteSymbol from '@app/images/remove-grey-primary.svg';
import SVG from '@app/components/SVG';
import { showCustomModal, showGeneralModal } from '@app/components/Modal';
import FileUploadDraggerModal from '@app/components/fileUpload/FileUploadDraggerModal';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import {
  CheckFileOptions,
  FileTypeCategory,
  FileTypeCategoryExplanation,
  FileTypeExplanation,
  FileTypes,
  fileTypesByCategory,
} from '@app/domain/files';
import { ForwardingFC } from '@app/domain/technicals/components';
import OldChip from '@app/components/OldChip';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  id: string;
  disabled?: boolean;
  value?: File[];
  onChange?: (newValue: File[]) => void;
  className?: string;
  checkFileOptions: CheckFileOptions;
}

const FileAttacher: ForwardingFC<HTMLDivElement, Props> = React.forwardRef(
  ({ id, disabled, value: files, onChange, checkFileOptions, ...divProps }, ref) => {
    const modalContext = useModalContext();

    const handleDeleteFile = (file: File): void => {
      if (!files) {
        return;
      }

      const index = files.indexOf(file);

      if (index === -1) {
        return;
      }

      const newFilesArray = [...files];
      newFilesArray.splice(index, 1);
      onChange?.(newFilesArray);
    };

    const onAddFiles = (): void => {
      showCustomModal(
        (onDone): ReactElement => (
          <ModalAppContext {...modalContext}>
            <FileUploadDraggerModal
              files={files?.map((file) => ({ file })) ?? []}
              checkFileOptions={checkFileOptions}
              onDone={(newFiles): void => {
                onChange?.(newFiles);
                onDone();
              }}
              onCancel={onDone}
              title='Attach Files'
            />
          </ModalAppContext>
        ),
      );
    };

    const showSupportedFileExtension = (allowedExtensions: FileTypes[]): void => {
      const content = Object.values(FileTypeCategory)
        .filter((category) => allowedExtensions.some((extension) => fileTypesByCategory[category].includes(extension)))
        .map((category) => {
          const extensionsOfCategory = allowedExtensions
            .filter((extension) => fileTypesByCategory[category].includes(extension))
            .map((extensionOfCategory) => {
              const extensionExplanation = FileTypeExplanation[extensionOfCategory];
              return `.${extensionOfCategory}${extensionExplanation ? ` (${extensionExplanation})` : ''}`;
            });

          const categoryExplainer = FileTypeCategoryExplanation[category];
          return `${category}${categoryExplainer ? ` (${categoryExplainer})` : ''} - ${extensionsOfCategory.join(', ')}`;
        })
        .join('\n');

      showGeneralModal('Supported file formats', content, 'OK');
    };

    const renderAddFilesButton = (): ReactElement => {
      const fileAmount = files?.length ?? 0;

      if (fileAmount >= checkFileOptions.maxFilesAllowed) {
        return <ButtonSmallCenterDisable.div>Maximum files reached</ButtonSmallCenterDisable.div>;
      }

      if (fileAmount) {
        return (
          <AddFilesButton
            id={`btn-file-attacher-add-more-${id}`}
            disabled={disabled}
            onClick={onAddFiles}
            appearance='text'
            colorScheme='primary'
          >
            <SVG accessibilityLabel='' image={disabled ? AddDisabledSpriteSymbol : AddSpriteSymbol} width={18} height={18} /> ADD
            MORE
          </AddFilesButton>
        );
      }

      return (
        <AddFilesButton
          id={`btn-file-attacher-attach-files-${id}`}
          disabled={disabled}
          onClick={onAddFiles}
          appearance='text'
          colorScheme='primary'
        >
          <SVG accessibilityLabel='' image={disabled ? ClippyDisabledSpriteSymbol : ClippySpriteSymbol} width={18} height={18} />{' '}
          ATTACH FILES
        </AddFilesButton>
      );
    };

    const renderFileChip = (file: File): ReactElement => {
      return (
        <FileNameAndButton key={`${file.name}-${file.size}-${file.lastModified}`}>
          <FileName>{file.name}</FileName>
          <FileRemoveButton
            id={`file-attacher-file-remove-${file.name}`}
            onClick={(): void => handleDeleteFile(file)}
            appearance='text'
            cornerType='none'
          >
            <SVG accessibilityLabel='remove file' image={RemoveFileSpriteSymbol} width={18} height={18} />
          </FileRemoveButton>
        </FileNameAndButton>
      );
    };

    const allowedExtensions = checkFileOptions.allowedExtensions;

    return (
      <FlexWrapLine {...divProps} ref={ref}>
        {files?.map(renderFileChip)}
        <ConditionalTooltip showTooltip={!!disabled} title='Select a reason before attaching the files.' placement='bottomLeft'>
          {renderAddFilesButton()}
        </ConditionalTooltip>
        {allowedExtensions && (
          <SupportedExtensionsLink
            className={CaptionStartTransparentBlack600.className}
            onClick={(): void => showSupportedFileExtension(allowedExtensions)}
          >
            (Supported file formats)
          </SupportedExtensionsLink>
        )}
      </FlexWrapLine>
    );
  },
);

export default FileAttacher;

const FlexWrapLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  margin-top: -8px;

  & > * {
    // This allows wrapped lines to have a 8px gap
    margin-top: 8px;
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const AddFilesButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: var(--primary-200);

  padding: 5px 12px 5px 0;
`;

const SupportedExtensionsLink = styled.a`
  text-decoration: underline !important;
  color: var(--accent-blue-600);
`;

const FileNameAndButton = styled(OldChip)`
  padding: 0.15em 0.3em 0.15em 0.6em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileName = styled.div`
  flex: 1;
  font-family: var(--text-font-family);
  font-size: 14px;
  line-height: 1.5;
  color: var(--primary-300);

  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileRemoveButton = styled(Button)`
  padding: 0;

  display: flex;
  align-items: baseline;
  justify-content: center;
`;
