import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { ACCOUNT_FIELD_MAX_LENGTH, INFORMATION_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { trim } from '@app/utils/stringUtils';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-IL-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.IL.bank,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.IL.bank,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.IL.errors.invalidBankCode`,
          'supplierValidation.supplierRegister.errors.invalidBankCode',
        ],
      },
      {
        required: true,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.errors.missingBankCode`,
      },
    ],
  };
}

function getBranchCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-IL-branch-code',
    inputType: 'number',
    decoratorRules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.IL.branch,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.IL.branch,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.IL.errors.invalidBranchCode`,
          'supplierValidation.supplierRegister.errors.invalidBranchCode',
        ],
      },
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBranchCode',
      },
    ],
  };
}

export const IL: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
  branchCode: getBranchCodeFields,
  legalIdValueAdditionalRules: [
    {
      max: INFORMATION_FIELD_MAX_LENGTH.IL.leiLength,
      transform: trim,
      translatedMessage: {
        key: 'supplierValidation.supplierRegister.maxCharacters',
        value: INFORMATION_FIELD_MAX_LENGTH.IL.leiLength,
      },
    },
  ],
  accountNumberAdditionalRules: [
    {
      max: INFORMATION_FIELD_MAX_LENGTH.IL.accountNumber,
      transform: trim,
      translatedMessage: {
        key: 'supplierValidation.supplierRegister.maxCharacters',
        value: INFORMATION_FIELD_MAX_LENGTH.IL.accountNumber,
      },
    },
  ],
};
