import React, { FunctionComponent, useMemo } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableImportantText, TableStandardText, TableStatusText } from '@app/components/tables/Table';
import { FormattedDate, FormattedTime } from '@app/components/Locale';
import { IPSVerificationReminder, VerificationReminderStatus } from '@mortee/domain/ips';
import { pluralize } from '@app/utils/stringUtils';
import Loadable from '@app/utils/Loadable';
import { getColumnPastTimeFilterProps } from '@app/components/tableFilters/singleValue/byPastTimeFieldFilterCreator';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import getColumnMultiValueFieldSelectProps from '@app/components/tableFilters/multiValue/byFieldFilterCreator';
import EmptyResults from '@mortee/routes/ips/verificationRemindersTable/EmptyResults';
import { daysBetween } from '@app/utils/timeUtils';
import ClockBlackIcon from '@app/images/clock-black.svg';
import TrashIcon from '@app/images/trash-black.svg';
import RingingBellRedIcon from '@app/images/bell-ringing-red.svg';
import SVG from '@app/components/SVG';
import styled from '@emotion/styled';
import AsyncButton from '@app/components/AsyncButton';
import TableOfCards from '@app/components/tables/TableOfCards';
import { getColumnVerificationReminderExpirationTimeFilterProps } from '@mortee/routes/ips/verificationRemindersTable/byVerificationReminderExpirationTimeFilterCreator';
import EmptyFilterResults from '@mortee/routes/ips/verificationRemindersTable/EmptyFilterResults';
import { compare } from '@app/utils/comparatorUtils';

interface Props {
  verificationReminders: Loadable<IPSVerificationReminder[]>;
  createNewVerificationReminder(): void;
  deleteVerificationReminder(reminder: IPSVerificationReminder): Promise<void>;
}

const IPSVerificationRemindersTable: FunctionComponent<Props> = ({
  verificationReminders,
  createNewVerificationReminder,
  deleteVerificationReminder,
}) => {
  const columns = useMemo(() => {
    return createColumnsWithInteraction(verificationReminders.result ?? [], deleteVerificationReminder);
  }, [deleteVerificationReminder, verificationReminders.result]);

  if (verificationReminders.result?.length === 0) {
    return <EmptyResults createNewVerificationReminder={createNewVerificationReminder} />;
  }

  return (
    <TableOfCards
      id='ips-verification-reminders-table'
      dataTestId='ips-verification-reminders-table'
      columns={columns}
      dataSource={verificationReminders.result}
      loading={!verificationReminders.isResolved()}
      pagination={{ pageSize: 25 }}
      rowKey={(reminder): string => reminder.id}
      emptyFilterComponent={<EmptyFilterResults />}
    />
  );
};

export default IPSVerificationRemindersTable;

function createColumnsWithInteraction(
  data: IPSVerificationReminder[],
  deleteVerificationReminder: Props['deleteVerificationReminder'],
): ColumnProps<IPSVerificationReminder>[] {
  return [
    {
      title: <div data-testid='lblPayerName'>Payer name</div>,
      dataIndex: 'payerName',
      key: 'payerName',
      sorter: compare.byStringField((record) => record.payerName),
      render: (text: any, record: IPSVerificationReminder): React.ReactNode => (
        <TableImportantText>{record.payerName}</TableImportantText>
      ),
      ...getColumnTextSearchProps((record) => record.payerName),
    },
    {
      title: <div data-testid='lblPayerEmailsAndDomains'>Payer emails/domains</div>,
      dataIndex: 'emailDomainToNotify',
      key: 'emailDomainToNotify',
      sorter: compare.byStringField((record) => record.emailDomainToNotify[0]),
      render: (text: any, record: IPSVerificationReminder): React.ReactNode => (
        <TableStandardText>{record.emailDomainToNotify.join(', ')}</TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => record.emailDomainToNotify),
    },
    {
      title: <div data-testid='lblAccount'>Account (last 4 digits)</div>,
      dataIndex: 'account',
      key: 'account',
      sorter: compare.byStringField((record) => record.validatedPayeeAccount?.shortAccountIdentifier),
      render: (text: any, record: IPSVerificationReminder): React.ReactNode => (
        <TableStandardText>{record.validatedPayeeAccount.shortAccountIdentifier}</TableStandardText>
      ),
      ...getColumnMultiValueFieldSelectProps<IPSVerificationReminder>(
        data,
        (reminder) => reminder.validatedPayeeAccount?.shortAccountIdentifier,
      ),
    },
    {
      title: <div data-testid='lblWriteTimestamp'>Created in</div>,
      dataIndex: 'writeTimestamp',
      key: 'writeTimestamp',
      width: '220px',
      sorter: compare.byField((record) => record?.writeTimestamp, compare.numbers()),
      render: (text: any, record: IPSVerificationReminder): React.ReactNode => (
        <TableStandardText>
          <FormattedDate value={record.writeTimestamp} options={{ day: 'numeric', month: 'long', year: 'numeric' }} />,{' '}
          <FormattedTime value={record.writeTimestamp} options={{ hour: '2-digit', minute: '2-digit' }} />
        </TableStandardText>
      ),
      ...getColumnPastTimeFilterProps('Created in', (record) => record.writeTimestamp),
    },
    {
      title: <div data-testid='lblExpirationDate'>Expiration date</div>,
      colSpan: 2,
      dataIndex: 'expirationTimestamp',
      key: 'expirationTimestamp',
      width: '160px',
      defaultSortOrder: 'ascend',
      sorter: compare.byField((record) => record?.expirationTimestamp, compare.numbers()),
      render: (text: any, record: IPSVerificationReminder): React.ReactNode => {
        if (record.status === VerificationReminderStatus.pending) {
          const daysBetweenNowAndExpiration = Math.floor(daysBetween(Date.now(), record.expirationTimestamp));

          const daysLeftMessage =
            daysBetweenNowAndExpiration === 0
              ? 'Ending today'
              : `${daysBetweenNowAndExpiration} ${pluralize('day', daysBetweenNowAndExpiration)} left`;

          return (
            <IconAndText>
              <SVG accessibilityLabel='' image={ClockBlackIcon} width={20} height={15} />
              <span>{daysLeftMessage}</span>
            </IconAndText>
          );
        } else if (record.status === VerificationReminderStatus.expired) {
          return (
            <RedIconAndText>
              <SVG accessibilityLabel='' image={RingingBellRedIcon} width={19} height={19} />
              <span>Expired</span>
            </RedIconAndText>
          );
        }
      },
      ...getColumnVerificationReminderExpirationTimeFilterProps(),
    },
    {
      title: '',
      colSpan: 0,
      dataIndex: 'actions',
      key: 'actions',
      width: '60px',
      render: (text: any, record: IPSVerificationReminder): React.ReactNode => {
        return (
          <DeleteReminderButton
            id='ips-verification-reminder-table-delete-reminder'
            data-testid='ips-verification-reminder-table-delete-reminder'
            appearance='text'
            colorScheme='grey'
            onClick={(): Promise<void> => deleteVerificationReminder(record)}
            loaderProps={{ customColor: 'black' }}
          >
            <AppearOnRowHover>
              <SVG accessibilityLabel='delete reminder' image={TrashIcon} width={14} height={14} />
            </AppearOnRowHover>
          </DeleteReminderButton>
        );
      },
    },
  ];
}

const IconAndText = styled(TableStatusText)`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
`;
const RedIconAndText = styled(IconAndText)`
  color: var(--error-600);
`;

const AppearOnRowHover = styled.div`
  opacity: 0;
  transition: opacity 0.2s;
  content: '';

  tr:hover & {
    opacity: 1;
  }
`;

const DeleteReminderButton = styled(AsyncButton)`
  padding: 6px 8px;
`;
