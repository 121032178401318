import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { BodyRegularStartTransparentBlack900 } from '@app/components/Text';
import ErrorSVG from '@supplierRegistration/images/icon-page-error.svg';
import SVG from '@app/components/SVG';
import { createEnglishFormalErrorMessage } from '@app/utils/errorMessageUtils';
import { MultipleSSOProviderType } from '@app/login/domain/multipleSSO';
import styled from '@emotion/styled';

interface Props {
  sso: MultipleSSOProviderType | null;
}

const SSOError: FC<Props> = observer(({ sso }) => {
  switch (sso) {
    case MultipleSSOProviderType.oidc:
    case MultipleSSOProviderType.saml: {
      return (
        <ErrorContainer>
          <StyledSVG image={ErrorSVG} accessibilityLabel='error' />
          {createEnglishFormalErrorMessage(
            'Contact your organization’s administrator for further assistance. If the issue continues, contact us at support@nsknox.net for assistance.',
            {
              preventPleaseContactText: true,
            },
          )}
        </ErrorContainer>
      );
    }
    case null: {
      return (
        <ErrorContainer>
          <StyledSVG image={ErrorSVG} accessibilityLabel='error' />
          {createEnglishFormalErrorMessage(
            'Something went wrong, try to log in again or contact us at support@nsknox.net for assistance.',
            {
              preventPleaseContactText: true,
            },
          )}
        </ErrorContainer>
      );
    }
  }
});

export default SSOError;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${BodyRegularStartTransparentBlack900.css};
  padding: 30px clamp(20px, 5vw, 50px) 30px;
  text-align: center;
`;

const StyledSVG = styled(SVG)`
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
`;
