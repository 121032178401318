import { useEffect } from 'react';
import { AnnotationsMap, runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';

// "props" param must contain the same keys every time
// This is why in "PaginatedPrivateArea" we declare the object manually, to make sure the object has consistent keys
export default function useLocalObservableWithProps<TProps extends Record<string, any>,
  TStore extends Record<string, any> & TProps>(initializer: () => TStore, props: TProps, annotations?: AnnotationsMap<TStore, never>): TStore {
  const localStore = useLocalObservable(() => {
    const newlyCreatedLocalStore = initializer();
    Object.entries(props).forEach(([key, value]: [keyof TProps, any]) => {
      newlyCreatedLocalStore[key] = value;
    });

    return newlyCreatedLocalStore;
  }, annotations);

  useEffect(() => {
      Object.entries(props).forEach(([key, value]: [keyof TProps, any]) => {
        runInAction(() => {
          localStore[key] = value;
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- props come from the outside and should stay consistent
    [Object.values(props)]);

  return localStore;
}
