import ArrowBulletIcon from '@app/images/arrows/arrow-bullet.svg';
import React, { FC, ReactElement } from 'react';
import { LocaleAwareSVG } from './LocaleAwareComponents';
import styled from '@emotion/styled';
import { preventForwardTheseProps } from '@app/utils/styledUtils';

interface ArrowBulletProps {
  className?: string;
  leftArrow?: boolean;
}

const ArrowBullet: FC<ArrowBulletProps> = ({ leftArrow, className }): ReactElement => {
  return (
    <StyledArrowBulletSVG
      accessibilityLabel=''
      leftArrow={leftArrow}
      image={ArrowBulletIcon}
      className={className}
      viewBox='0 0 6 10'
    />
  );
};

const StyledArrowBulletSVG = styled(LocaleAwareSVG, { shouldForwardProp: preventForwardTheseProps('leftArrow') })<{
  leftArrow?: boolean;
}>`
  fill: var(--action-color, var(--accent-blue-600));
  ${(p): string => (p.leftArrow ? 'transform: scaleX(-1);' : '')}
`;

export default ArrowBullet;
