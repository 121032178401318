import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import GuestLoginEmailFlow from './email/GuestLoginEmailFlow';
import GuestLoginPhoneFlow from './phone/GuestLoginPhoneFlow';
import styled from '@emotion/styled';
import ArMode from '@ar/arMode';

const GuestLoginFlow: FunctionComponent = observer(() => {
  const { guestLoginStore } = useInfraStores<ArMode>();
  const { isPhoneKnoxerLoggedIn, isEmailKnoxerLoggedIn } = guestLoginStore;

  if (!isEmailKnoxerLoggedIn) {
    return <GuestLoginEmailFlow />;
  }

  if (!isPhoneKnoxerLoggedIn) {
    return <GuestLoginPhoneFlow />;
  }

  return <div />;
});

export default GuestLoginFlow;

export const LoginTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: var(--transparent-black-900);
  margin-bottom: 16px;
`;

export const LoginSubTitle = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--transparent-black-600);
  margin-bottom: 28px;
`;

export const LoginFooterSubTitle = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 1.5;

  color: var(--transparent-black-600);
`;
