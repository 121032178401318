import { useQueryParam } from '@app/hooks/useQueryParam';
import { itemOrSingle } from '@app/utils/arrayUtils';

type UpdateQueryParamFunction = (value: string | string[] | null | undefined) => void;

type ValueTypePredicate<T extends string = string> = (value) => value is T;

export function useSingleQueryParam<T extends string = string>(
  name: string,
  filterIllegalValues: ValueTypePredicate<T> | RegExp,
): [values: T | null, updateValues: UpdateQueryParamFunction, allValuesValid: boolean] {
  const [result, ...rest] = useQueryParam<T>(name, filterIllegalValues);

  const singleItem = (result && itemOrSingle(result)) ?? null;

  return [singleItem, ...rest];
}
