import React, { FC } from 'react';
import { BodySmallStartTransparentBlack600, SubtitleRegularStartTransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';
import OutlineInfoSpriteSymbol from '@app/images/designSystem/indication/info.svg';
import SVG from '@app/components/SVG';
import { Tooltip } from 'antd';

interface Props {
  permissionsId: string;
  displayName: string | null;
  className?: string;
  tooltip?: string | null;
}

const PermissionListItem: FC<Props> = ({ displayName, permissionsId, className, tooltip }) => {
  return (
    <Container className={className}>
      <HeaderDiv>
        {tooltip && (
          <Tooltip title={tooltip}>
            <ExplanationIcon
              accessibilityLabel='information tooltip popup'
              image={OutlineInfoSpriteSymbol}
              width={20}
              height={20}
            />
          </Tooltip>
        )}
        <SubtitleRegularStartTransparentBlack900.div>{permissionsId}</SubtitleRegularStartTransparentBlack900.div>
      </HeaderDiv>
      <BodySmallStartTransparentBlack600.div>{displayName}</BodySmallStartTransparentBlack600.div>
    </Container>
  );
};

export default PermissionListItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExplanationIcon = styled(SVG)`
  flex: 0 0 auto;
  fill: var(--accent-blue-600);
  margin-right: 3px;
`;
