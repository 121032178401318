export interface KnoxerIdentityProvidersServerResponse {
  knoxerId: string;
  identityProviders: IdentityProviderServerResponse[];
}

export interface IdentityProviderServerResponse {
  type: string;
  name: string;
}

export interface KnoxerIdentityProviders {
  knoxerId: string;
  identityProviders: IdentityProvider[];
}

export interface IdentityProvider {
  type: string;
  name: string;
  id: string;
}

export function transformKnoxerIdentityProviders(
  knoxerIdentityProvider: KnoxerIdentityProvidersServerResponse,
): KnoxerIdentityProviders {
  return {
    knoxerId: knoxerIdentityProvider.knoxerId,
    identityProviders: knoxerIdentityProvider.identityProviders.map(transformIdentityProvider),
  };
}

function transformIdentityProvider(identityProvider: IdentityProviderServerResponse): IdentityProvider {
  return {
    name: identityProvider.name,
    type: identityProvider.type,
    id: identityProvider.name,
  };
}
