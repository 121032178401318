import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { CommentEvent } from '@mortee/domain/validationSystemTimelineEvents';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import { confirmAsync, ModalBody, ModalTitle, WrappingModalContainer } from '@app/components/Modal';
import validationSystemServices from '@mortee/services/validationSystemServices';
import SVG from '@app/components/SVG';
import TrashIcon from '@app/images/trash-black.svg';
import AsyncButton from '@app/components/AsyncButton';
import { commentTypesSpecialComponents } from '@mortee/domain/validationSystemInfoComponents';
import SupplierValidationRecordActivityEvent, {
  SupplierValidationRecordActivityLogEventMessage,
} from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/SupplierValidationRecordActivityLogEvent';

interface Props {
  record: SupplierValidationRecord;
  comment: CommentEvent;
  onCommentDeleted(): void;
  className?: string;
}

const SupplierValidationRecordActivityLog: FC<Props> = ({ record, comment, onCommentDeleted, className }) => {
  async function handleRemoveComment(commentId: string, comment: string): Promise<void> {
    const renderConfirmationModal = (comment: string): ReactElement => {
      return (
        <WrappingModalContainer>
          <ModalTitle>Are you sure you want to permanently delete the comment?</ModalTitle>
          <ModalBody>
            <ModalSubTitleText>
              you are about to delete the comment:
              <br />
              {comment}
            </ModalSubTitleText>
          </ModalBody>
        </WrappingModalContainer>
      );
    };

    const shouldDelete = await confirmAsync({
      content: renderConfirmationModal(comment),
      okText: 'YES',
      cancelText: 'NO',
      icon: <span />,
      maskClosable: false,
      width: 550,
    });

    if (!shouldDelete) {
      return;
    }

    await validationSystemServices.deleteValidationCommentEvent(record.staticId, commentId);
    onCommentDeleted();
  }

  return (
    <SupplierValidationRecordActivityEvent
      event={comment}
      eventTitle={null}
      additionalMetadata={
        <MetadataRight>
          {commentTypesSpecialComponents.render(comment.data.comment.commentType)}
          <RemoveCommentButton
            id='btn-supplier-validation-record-timeline-comment-delete'
            appearance='text'
            cornerType='circle'
            colorScheme='grey'
            onClick={(): Promise<void> => handleRemoveComment(comment.data.comment.id, comment.data.comment.content)}
          >
            <SVG accessibilityLabel='delete' image={TrashIcon} width={14} height={14} />
          </RemoveCommentButton>
        </MetadataRight>
      }
      className={className}
    >
      <SupplierValidationRecordActivityLogEventMessage>
        {comment.data.comment.content}
      </SupplierValidationRecordActivityLogEventMessage>
    </SupplierValidationRecordActivityEvent>
  );
};

export default SupplierValidationRecordActivityLog;

const MetadataRight = styled.div`
  display: flex;
  align-items: baseline;

  gap: 10px;
`;

const ModalSubTitleText = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
  word-break: break-word;
`;

const RemoveCommentButton = styled(AsyncButton)`
  padding: 10px 12px;
`;
