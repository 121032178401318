import useMountEffect from '@app/hooks/useMountEffect';

export default function useLeaveConfirmation(): void {
  useMountEffect(() => {
    const beforeUnloadHandler = (event: WindowEventMap['beforeunload']): void => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  });
}
