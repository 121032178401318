import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import {
  CaptionStartTransparentBlack400,
  CaptionStartTransparentBlack600,
  CaptionStartTransparentBlack900,
  SubtitleSmallStartTransparentGrey800,
} from '../Text';
import Table, { TableProps } from 'antd/lib/table';
import { css } from '@emotion/css';
import getCardCornersCssClass from '../corners/CornerCssGenerator';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import { createNTimes } from '@app/utils/arrayUtils';
import { convertExtendedColumnsToRegularColumns } from '@app/components/tables/Table';

export interface TableOfCardsProps<T> extends TableProps<T> {
  emptyComponent?: ReactElement;
  emptyFilterComponent?: ReactElement;
  rowClickable?: boolean;
  id: string;
  dataTestId?: string;
  className?: string;
  disableTableOperations?: boolean;
}

const TableOfCards: <T>(props: TableOfCardsProps<T>) => ReactElement = function <T>({
  header,
  emptyComponent,
  emptyFilterComponent,
  columns,
  pagination,
  loading,
  dataSource,
  className,
  rowClickable,
  id,
  dataTestId = id,
  disableTableOperations,
  ...tableProps
}) {
  if (!loading && emptyComponent && !dataSource?.length) {
    return emptyComponent;
  }

  const totalAmountsText = (total: number, [start, end]: [number, number]): ReactNode => (
    <CaptionStartTransparentBlack600.span>
      {start}-{end} of {total}
    </CaptionStartTransparentBlack600.span>
  );

  const calcPaginationConfig = (currentPaginationConfig: TableProps<T>['pagination']): TableProps<T>['pagination'] => {
    if (currentPaginationConfig === false) {
      return false;
    }

    return {
      hideOnSinglePage: true,
      showTotal: totalAmountsText,
      ...currentPaginationConfig,
    };
  };

  const paginationConfig = calcPaginationConfig(pagination);

  const renderTableBody: FC = ({ children, ...innerTBodyProps }) => {
    if (loading) {
      return (
        <tbody>
          {createNTimes(3, (index) => (
            <tr key={index}>
              <td colSpan={100}>
                <ReactPlaceholder key={index} type='rect' children='' ready={false} style={customPlaceholderStyle} />
              </td>
            </tr>
          ))}
        </tbody>
      );
    }

    if (emptyFilterComponent && dataSource?.length && !React.Children.count(children)) {
      return (
        <tbody>
          <tr>
            <td colSpan={100}>{emptyFilterComponent}</td>
          </tr>
        </tbody>
      );
    }

    return <tbody {...innerTBodyProps} children={children} />;
  };

  const regularColumns = convertExtendedColumnsToRegularColumns<T>(columns, disableTableOperations);

  return (
    <div id={id} data-testid={dataTestId}>
      <StyledTable
        dataSource={dataSource}
        pagination={paginationConfig}
        rowClassName={(): string => cardRowClassName}
        hideNoDataPlaceholder={loading || !!emptyFilterComponent}
        rowClickable={!!rowClickable}
        className={className}
        components={{
          body: {
            wrapper: renderTableBody,
          },
        }}
        columns={regularColumns}
        {...tableProps}
      />
    </div>
  );
};

export default TableOfCards;

const StyledTable = styled(Table)<{ hideNoDataPlaceholder: boolean; rowClickable: boolean }>`
  .ant-table-column-title {
    ${SubtitleSmallStartTransparentGrey800.css}
  }

  .ant-table-placeholder {
    ${(p): string => (p.hideNoDataPlaceholder ? 'display: none;' : '')}
  }

  & th {
    background: transparent !important;
    border: none !important;
  }

  & .ant-table table {
    border-collapse: separate;
    border-spacing: 0 8px;

    .ant-table-tbody {
      border-collapse: separate;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 45px;

    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-prev {
      border: none;

      .ant-pagination-item-link {
        border: none;
      }
    }

    .ant-pagination-item {
      ${CaptionStartTransparentBlack400.css}
      min-width: auto;
      padding: 8px 2px;

      &.ant-pagination-item-active {
        font-weight: bold;
        ${CaptionStartTransparentBlack900.css}
      }

      a {
        color: inherit;
      }
    }
  }

  .ant-table-thead > tr {
    background: transparent !important;

    & > th {
      font-size: 14.2px;
      font-weight: bold;
      color: var(--primary-200) !important;

      background: transparent !important;

      border: none;
      padding: 4px 24px !important;

      &:first-child {
        padding-left: 24px !important;
      }

      &:last-child {
        padding-right: 14px !important;
      }
    }
  }

  .ant-table-header {
    background-color: transparent !important;
  }

  .ant-table-body {
    background-color: transparent !important;
  }

  .ant-table-row {
    ${({ rowClickable }): string =>
      rowClickable
        ? `
      &:hover {
        cursor: pointer;
      }
      
      &:active {
        cursor: pointer;
        box-shadow: var(--box-shadow-2);
      }
    `
        : ''}
  }
`;

const cardRowClassName = css`
  box-shadow: var(--box-shadow-1);
  border-radius: 4px;

  &:hover {
    box-shadow: var(--box-shadow-1-dark);
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: white !important;
  }

  .ant-table-tbody > tr:hover > td {
    background: white !important;
  }

  & > td {
    font-size: 15px;
    padding-left: 24px !important;
    padding-right: 24px !important;

    &:first-child {
      padding-left: 24px !important;
    }

    &:last-child {
      padding-right: 14px !important;
    }

    // Set the padding left of the first td in a row back to normal
    // in case this td is in a row that it's first columns are overrun by rowspan
    // meaning that this td is not really the first td displayed in that row
    &:first-child:not([rowspan]) {
      padding-left: 24px !important;
    }
  }

  & > td:first-child {
    ${getCardCornersCssClass('left')};
  }

  &:not(:hover) > td:first-child {
    background: white !important;
  }

  & > td:last-child {
    ${getCardCornersCssClass('right')};
  }

  & > td:not(:first-child) {
    background: white;
  }

  & > td:not(:first-child).ant-table-column-sort {
    background: white;
  }

  &.tr > td.ant-table-column-sort {
    background: transparent;
  }
`;

const customPlaceholderStyle = { height: '60px', backgroundColor: '#DEDEDE' };
