import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SupplierRegistrationProgressStep } from '@supplierRegistration/domain/supplierRegistration';
import StepsProgress from '@app/components/StepsProgress';
import useTheme from '@app/hooks/useTheme';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useAppStores from '@app/hooks/useAppStores';
import { isTruthy } from '@app/utils/utils';

interface SupplierRegistrationProgressProps {
  step: SupplierRegistrationProgressStep;
  className?: string;
}

const SupplierRegistrationProgress: FunctionComponent<SupplierRegistrationProgressProps> = ({ step, className }) => {
  const { t } = useTranslation();
  const { isHeaderDark } = useTheme<SupplierRegistrationMode>();
  const { supplierRegistrationFormStore } = useAppStores<SupplierRegistrationMode>();

  const steps = [
    !supplierRegistrationFormStore.skipWelcome && t('supplierValidation.progress.welcome'),
    <Trans i18nKey='supplierValidation.progress.companyDetails'>
      Company
      <br />
      Details
    </Trans>,
    <Trans i18nKey='supplierValidation.progress.bankAccountDetails'>
      Bank Account
      <br />
      Details
    </Trans>,
    <Trans i18nKey='supplierValidation.progress.validationInstructions'>
      Validation
      <br />
      Instructions
    </Trans>,
  ].filter(isTruthy);

  return <StepsProgress currentStepIndex={step} steps={steps} className={className} isHeaderDark={isHeaderDark} />;
};

export default SupplierRegistrationProgress;
