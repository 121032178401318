import React, { FC, ReactNode } from 'react';
import {
  ActivityLogEvent,
  CompatibilityFailedDistributionEvent,
  DistributionTrigger,
  DistributionType,
  SuccessfulDistributionEvent,
  SupplierValidationTimelineEventType,
} from '@mortee/domain/validationSystemTimelineEvents';
import { FormattedDateTime } from '@app/components/Locale';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';
import {
  getSVMasterFileAutomaticEmailStatusText,
  getSVMasterFileAutomaticEmailStopReasonText,
  PaymentCompatabilityCheckAttributeText,
  SupplierValidationRecordStatus,
} from '@mortee/domain/validationSystem';
import {
  commentTypesSpecialComponents,
  renderSvmInstructionType,
  statusColorSchemes,
  validationRecordStatusSpecialComponents,
} from '@mortee/domain/validationSystemInfoComponents';
import AssigneeText from '@mortee/components/AssigneeText';
import styled from '@emotion/styled';
import TimelineWithLeftDots, { TimelineItem } from '@app/components/accountVerification/TimelineWithLeftDots';
import SupplierValidationRecordActivityLogEvent, {
  SupplierValidationRecordActivityLogEventMessage,
} from '../SupplierValidationRecordActivityLogEvent';

interface Props {
  events: ActivityLogEvent[];
}

const SupplierValidationRecordTimeline: FC<Props> = ({ events }) => {
  let eventText: ReactNode;

  function createEventByType(event: ActivityLogEvent): TimelineItem {
    const key = `event-${event.type}-${event.metadata.timestamp}`;

    switch (event.type) {
      case SupplierValidationTimelineEventType.svLinked:
        eventText = (
          <div>
            Registration Form (<BoldText>{event.data.svRegistrationNumber}</BoldText>) was linked.
          </div>
        );

        return {
          key,
          icon: <Dot color='#ACE8FF' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText}>
              The supplier: <BoldText>{event.data.supplierName}</BoldText> was registered on&nbsp;
              <BoldText>
                <FormattedDateTime value={event.data.registrationTimestamp} options={formattedDateOptionsWithHoursAndMinutes} />{' '}
              </BoldText>
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      case SupplierValidationTimelineEventType.svUnlinked:
        return {
          key,
          icon: <Dot color='#ACE8FF' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle='Registration form was unlinked' />,
        };
      case SupplierValidationTimelineEventType.svLinkUpdate:
        eventText = (
          <div>
            Registration form <BoldText>{event.data.svRegistrationNumber}</BoldText> was unlinked from this record.
          </div>
        );
        return {
          key,
          icon: <Dot color='#ACE8FF' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText} />,
        };
      case SupplierValidationTimelineEventType.statusUpdate:
        if (statusColorSchemes[event.data.status]) {
          eventText = <div>Status was change to {validationRecordStatusSpecialComponents.render(event.data.status)}</div>;
        } else {
          eventText = <div>Missing status</div>;
        }

        return {
          key,
          icon: <Dot color='#006BBC' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText} />,
        };
      case SupplierValidationTimelineEventType.assigneeUpdate:
        eventText = (
          <AssigneeEventText>
            Record assigned to&nbsp;
            <BoldText>
              <AssigneeText assignee={event.data.assignee} />
            </BoldText>
          </AssigneeEventText>
        );
        return {
          key,
          icon: <Dot color='#006BBC' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText} />,
        };
      case SupplierValidationTimelineEventType.isCustomerInvolvementUpdate:
        eventText = (
          <div>
            Customer involvement status was changed to
            <BoldText>"{event.data.isCustomerInvolvementRequired ? 'Yes' : 'No'}"</BoldText>
          </div>
        );
        return {
          key,
          icon: <Dot color='#191919' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText} />,
        };
      case SupplierValidationTimelineEventType.autoMailerEmailCountUpdate:
        eventText = (
          <div>
            Auto-mailer sent email number <BoldText>{event.data.emailCount}</BoldText>.
          </div>
        );
        return {
          key,
          icon: <Dot color='#03ADEF' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText} />,
        };
      case SupplierValidationTimelineEventType.autoMailerStatusUpdate:
        eventText = (
          <div>
            Auto-mailer changed its status to&nbsp;
            <BoldText>{getSVMasterFileAutomaticEmailStatusText(event.data.emailStatus)}</BoldText>.
            {event.data.emailMessage && '\nThe following message was added:'}
          </div>
        );
        return {
          key,
          icon: <Dot color='#03ADEF' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText}>
              {event.data.emailMessage && getSVMasterFileAutomaticEmailStopReasonText(event.data.emailMessage)}
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      case SupplierValidationTimelineEventType.autoMailerStopEmailUpdate:
        return {
          key,
          icon: <Dot color='#03ADEF' />,
          content: (
            <SupplierValidationRecordActivityLogEvent
              event={event}
              eventTitle={`Auto-mailer was ${event.data.stopEmails ? 'stopped' : 'resumed'}`}
            />
          ),
        };
      case SupplierValidationTimelineEventType.create:
        return {
          key,
          icon: <Dot color='#283D84' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle='validation record was created' />,
        };
      case SupplierValidationTimelineEventType.manualInstructionTypeUpdate: {
        if (event.data.manualInstructionType) {
          eventText = 'Instruction type was manually changed to:';
        } else {
          eventText = 'Manual instruction type was removed';
        }

        return {
          key,
          icon: <Dot color='rgba(255,123,16,0.98)' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText}>
              <InstructionContainer>{renderSvmInstructionType(event.data.manualInstructionType)}</InstructionContainer>
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      }
      case SupplierValidationTimelineEventType.comment: {
        return {
          key,
          icon: <Dot color='#F5A021' />,
          content: (
            <SupplierValidationRecordActivityLogEvent
              event={event}
              eventTitle='Comment added'
              additionalMetadata={commentTypesSpecialComponents.render(event.data.comment.commentType)}
            >
              <SupplierValidationRecordActivityLogEventMessage>
                {event.data.comment.content}
              </SupplierValidationRecordActivityLogEventMessage>
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      }
      case SupplierValidationTimelineEventType.successfulDistribution:
        return {
          key: `event-${event.type}-${event.metadata.timestamp}`,
          icon: <Dot color='#2e8540' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle='Distributed'>
              {getSuccessfulDistributionEventText(event)}
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      case SupplierValidationTimelineEventType.compatibilityFailedDistribution:
        return {
          key: `event-${event.type}-${event.metadata.timestamp}`,
          icon: <Dot color='#2e8540' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle='Distribution Failed'>
              {getCompatibilityFailedDistributionEventText(event)}
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      case SupplierValidationTimelineEventType.cancelReasonUpdate:
        return {
          key,
          icon: <Dot color={statusColorSchemes[SupplierValidationRecordStatus.canceled].main} />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle='User changed cancellation reason'>
              Reason:
              <SupplierValidationRecordActivityLogEventMessage>
                {event?.data?.reason ?? 'Cancel reason removed'}
              </SupplierValidationRecordActivityLogEventMessage>
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      case SupplierValidationTimelineEventType.evidenceLink:
        return {
          key,
          icon: <Dot color='#ca25ef' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle='Verification evidence was linked' />,
        };
      case SupplierValidationTimelineEventType.evidenceDelete:
        return {
          key,
          icon: <Dot color='#ef2536' />,
          content: <SupplierValidationRecordActivityLogEvent event={event} eventTitle='Verification evidence was deleted' />,
        };
      case SupplierValidationTimelineEventType.alertOpen:
        eventText = (
          <div>
            <BoldText>Alert Opened</BoldText>
          </div>
        );
        return {
          key,
          icon: <Dot color='#f35252' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText}>
              {event.data.alertText}
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
      case SupplierValidationTimelineEventType.alertResolve:
        eventText = (
          <div>
            <BoldText>Alert Resolved</BoldText>
          </div>
        );
        return {
          key,
          icon: <Dot color='#f35252' />,
          content: (
            <SupplierValidationRecordActivityLogEvent event={event} eventTitle={eventText}>
              {event.data.alertText}
            </SupplierValidationRecordActivityLogEvent>
          ),
        };
    }
  }

  function getSuccessfulDistributionEventText(event: SuccessfulDistributionEvent): ReactNode {
    const { distributionType, distributionTrigger } = event.data;

    switch (distributionType) {
      case DistributionType.onlyMarkedAsDistributed: {
        return 'Was marked as distributed';
      }
      case DistributionType.distributed: {
        return <div>Distribution reason: {calcDistributionReasonText(distributionTrigger)}</div>;
      }
    }
  }

  function getCompatibilityFailedDistributionEventText(event: CompatibilityFailedDistributionEvent): ReactNode {
    const { distributionTrigger, failureReasons } = event.data;

    return (
      <div>
        <div>
          Compatibility issues with the following fields:{' '}
          <ul>
            {failureReasons.map((failureReason) => (
              <li key={failureReason}>{PaymentCompatabilityCheckAttributeText[failureReason]}</li>
            ))}
          </ul>
        </div>
        <div>Distribution reason: {calcDistributionReasonText(distributionTrigger)}</div>
      </div>
    );
  }

  function calcDistributionReasonText(distributionTrigger: DistributionTrigger): string {
    switch (distributionTrigger) {
      case DistributionTrigger.scheduled:
        return 'Scheduled distribution';
      case DistributionTrigger.onLink:
        return 'Registration form link';
      case DistributionTrigger.manualInstructionTypeUpdate:
        return 'Manual instruction type update';
      case DistributionTrigger.userRequest:
        return 'User request';
    }
  }

  return (
    <TimelineWithLeftDots
      id='supplier-validation-record-event-timeline'
      dataTestId='supplier-validation-record-event-timeline'
      items={events.map((event) => createEventByType(event))}
    />
  );
};

export default SupplierValidationRecordTimeline;

const Dot = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${(p): string => p.color};
  border-radius: 50%;
  display: inline-block;
`;

const AssigneeEventText = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const InstructionContainer = styled.div`
  text-align: center;
  width: 250px;
`;
