import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';

interface BasicLocaleProps {
  className?: string;
}

interface FormattedDateTimeProps extends BasicLocaleProps {
  dataTestId?: string;
  value: Date | number;
  options?: Intl.DateTimeFormatOptions;
}

export const FormattedDate: FunctionComponent<FormattedDateTimeProps> = observer((props) => {
  const { dataTestId, className, value, options } = props;
  const { localeStore } = useInfraStores();

  return (
    <span data-testid={dataTestId} className={className}>
      {localeStore.formatDate(value, options)}
    </span>
  );
});

export const FormattedTime: FunctionComponent<FormattedDateTimeProps> = (props) => {
  const { className, value, options } = props;
  const { localeStore } = useInfraStores();

  return <span className={className}>{localeStore.formatTime(value, options)}</span>;
};

export const FormattedDateTime: FunctionComponent<FormattedDateTimeProps> = (props) => {
  const { className, value, options } = props;
  const { localeStore } = useInfraStores();

  return <span className={className}>{localeStore.formatDateTime(value, options)}</span>;
};

interface FormattedNumberProps extends BasicLocaleProps {
  value: number;
  currency?: string;
  options?: Intl.NumberFormatOptions;
}

export const FormattedNumber: FunctionComponent<FormattedNumberProps> = (props) => {
  const { value, options, className } = props;
  const { localeStore } = useInfraStores();

  return <span className={className}>{localeStore.formatNumber(value, options)}</span>;
};

interface FormattedCurrencyProps extends FormattedNumberProps {
  currency: string;
}

export const FormattedCurrency: FunctionComponent<FormattedCurrencyProps> = (props) => {
  const { value, currency, options, className } = props;
  const { localeStore } = useInfraStores();

  return <span className={className}>{localeStore.formatCurrency(value, currency, options)}</span>;
};
