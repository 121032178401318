import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { action, flow } from 'mobx';
import { FileAndErrors, FilesErrorOutput, FileTypes } from '@app/domain/files';
import { FilesUploadMode } from '@mortee/domain/fileUpload';
import browserHistory from '@app/utils/browserHistory';
import MvfCheckSurveyModel from '@mortee/models/MvfCheckSurveyModel';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import useMountEffect from '@app/hooks/useMountEffect';
import SelectFiles from '@mortee/components/check/SelectFiles';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import { LOAD_MVF_FILE_TITLE, MVF_ALLOWED_FILE_TYPES } from '@mortee/domain/mvfCheck';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';

interface LoadMvfCheckPageProps {
  onCancel(): void;
  onUploaded(): void;
}

const LoadMvfCheckPage: FunctionComponent<LoadMvfCheckPageProps> = observer((props: LoadMvfCheckPageProps) => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { masterDataGuardStore } = useAppStores<MorteeMode>();

  const { onCancel, onUploaded } = props;

  const localStore = useLocalObservable(() => ({
    fileAndErrors: [] as FileAndErrors[],
    startUploading: false as boolean,
    finishedUploading: false as boolean,
    note: undefined as string | undefined,
    storedSurvey: null as MvfCheckSurveyModel | null,
    uploadErrors: null as { [fileName: string]: string } | string | null,

    get files(): File[] {
      return localStore.fileAndErrors.map((x) => x.file);
    },
  }));

  useMountEffect(() => {
    window.onpopstate = onBackButtonEvent;
  });

  const onBackButtonEvent = (): void => {
    if (!localStore.finishedUploading) {
      navigationStore.moveToSectionHome();
    }
  };

  const startUpload = action((files: FileAndErrors[], notes?: string): void => {
    localStore.note = notes;
    localStore.uploadErrors = null;
    localStore.fileAndErrors = files || [];
    localStore.startUploading = true;

    upload();
  });

  const onUploadingError = action(() => {
    if (localStore.uploadErrors) {
      localStore.fileAndErrors = localStore.fileAndErrors.map((fileAndError) => {
        const fileError = localStore.uploadErrors?.[fileAndError.file.name];
        if (!fileError) {
          return fileAndError;
        }

        return { ...fileAndError, errors: [fileError] };
      });
    }

    localStore.startUploading = false;
  });

  const onUploadingEnd = action(() => {
    localStore.finishedUploading = true;
  });

  const upload = flow(function* () {
    try {
      const storedSurveyResponse: MvfCheckSurveyModel = yield masterDataGuardStore.storeMvfFileSurvey(
        localStore.files,
        localStore.note,
      );
      masterDataGuardStore.setSelectedSurveyId(storedSurveyResponse.id);
      browserHistory.push(navigationStore.generateMasterDataGuard());
      localStore.storedSurvey = storedSurveyResponse;
      onUploaded?.();
    } catch (e: unknown) {
      const requestError = e as FilesErrorOutput;
      if (!requestError.erroredFiles) {
        const bodyContent = (
          <div>
            An error occurred while uploading files.
            <br />
            Please contact us at <BlueText>support@nsknox.net</BlueText> for assistance.
            {requestError.errorLogId && ` Error Code: ${requestError.errorLogId}`}
          </div>
        );

        showContentOnlyModal((onDone) => (
          <ErrorModal headerContent='Something went wrong' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
        ));
        localStore.startUploading = false;
      } else {
        localStore.uploadErrors = requestError.erroredFiles;
        onUploadingError();
      }
    } finally {
      onUploadingEnd();
    }
  });

  return (
    <Container>
      <PageHeader>
        <PageTitle>New Verification</PageTitle>
        <Button
          id='btn-select-files-cancel'
          disabled={localStore.startUploading}
          onClick={(): void => onCancel()}
          appearance='outline'
          colorScheme='primary'
        >
          CANCEL
        </Button>
      </PageHeader>
      <SelectFiles
        title={LOAD_MVF_FILE_TITLE}
        allowedFileTypes={MVF_ALLOWED_FILE_TYPES}
        isUploading={localStore.startUploading}
        modes={[FilesUploadMode.vendor]}
        files={localStore.fileAndErrors}
        initialNotes={localStore.note}
        forbiddenFileExtensions={[FileTypes.txt]}
        onFilesUpload={startUpload}
        singleFile
      />
    </Container>
  );
});

export default LoadMvfCheckPage;

const PageTitle = styled.span`
  font-size: 36px;
  color: var(--gray-blue-deprecated);
  font-weight: 200;
  letter-spacing: 0.49px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BlueText = styled.span`
  font-weight: bold;
  color: var(--accent-blue-600);
`;
