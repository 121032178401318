import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { ColumnProps, PaginationConfig } from 'antd/lib/table';
import CardTable from '@app/components/tables/CardTable';
import { TableFormattedDate, TableStandardText } from '@app/components/tables/Table';
import Loadable, { LoadingState } from '@app/utils/Loadable';
import { CustomErrorText, SftpError } from '@mortee/domain/sftpConsts';
import styled from '@emotion/styled';
import { Bold15TransparentBlack900, Regular13TransparentBlack900 } from '@app/components/Text';
import { getColumnCustomTimeLocalFilterProps } from '@app/components/tableFilters/dateValue/byColumnCustomTimeFilterCreator';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import TooltipWithFourCorners from '@app/components/TooltipWithFourCorners';
import getColumnMultiValueFieldSelectProps from '@app/components/tableFilters/multiValue/byFieldFilterCreator';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';
import { compare } from '@app/utils/comparatorUtils';

interface SftpTableProps {
  errors: Loadable<SftpError[]>;
}

const SftpErrorTable: FunctionComponent<SftpTableProps> = observer((props) => {
  const createColumnsDefinition = (): ColumnProps<any>[] => {
    return [
      {
        title: <div data-testid='lblSftpErrorDate'>Date-Time</div>,
        width: '20%',
        dataIndex: 'writeTimestamp',
        key: 'writeTimestamp',
        align: 'left',
        sorter: compare.writeTimestamp(),
        render: (text: any, record: SftpError): React.ReactNode => (
          <TableFormattedDate options={formattedDateOptionsWithHoursAndMinutes} value={record.writeTimestamp} />
        ),
        ...getColumnCustomTimeLocalFilterProps((record) => record.writeTimestamp),
      },
      {
        title: <div data-testid='lblCorrelationId'>Error Code</div>,
        width: '20%',
        dataIndex: 'correlationId',
        key: 'correlationId',
        sorter: compare.byStringField((record) => record.correlationId),
        render: (text: any, record: SftpError): React.ReactNode => <TableStandardText>{record.correlationId}</TableStandardText>,
        ...getColumnTextSearchProps((record) => record.correlationId),
      },
      {
        title: <div data-testid='lblFileName'>File Name</div>,
        width: '20%',
        dataIndex: 'fileName',
        key: 'fileName',
        sorter: compare.byStringField((record) => record.fileName),
        render: (text: any, record: SftpError): React.ReactNode => {
          return <TableStandardText>{record.fileName ?? `No file name found`}</TableStandardText>;
        },
        ...getColumnTextSearchProps((record) => record.fileName),
      },
      {
        title: <div data-testid='lblFileType'>File Type</div>,
        width: '20%',
        dataIndex: 'fileType',
        key: 'fileType',
        sorter: compare.byStringField((record) => record.fileType),
        render: (text: any, record: SftpError): React.ReactNode => (
          <TableStandardText>{record.fileType ?? `No file type found`}</TableStandardText>
        ),
        ...getColumnMultiValueFieldSelectProps(errors.result ?? [], (error) => error.fileType),
      },
      {
        title: <div data-testid='lblErrorDescription'>Error Description</div>,
        width: '20%',
        dataIndex: 'errorDescription',
        key: 'errorDescription',
        sorter: compare.byStringField((record) => record.customErrorText.text),
        render: (text: any, record: SftpError): React.ReactNode => getErrorCell(record.customErrorText),
        ...getColumnTextSearchProps((record) => record.customErrorText.text),
      },
    ];
  };

  const getErrorCell = (errorText: CustomErrorText): React.ReactElement => {
    return (
      <TooltipWithFourCorners
        title={
          <ErrorToolTip>
            <ErrorTextHeader>Error Description</ErrorTextHeader>
            <ErrorTooltipText>{errorText.customTooltipContent ?? errorText.text}</ErrorTooltipText>
          </ErrorToolTip>
        }
      >
        <ErrorText>{errorText.text}</ErrorText>
      </TooltipWithFourCorners>
    );
  };

  const paginationConfig: PaginationConfig = {
    position: 'bottom',
    defaultPageSize: 50,
  };

  const { errors } = props;

  const columns = createColumnsDefinition();

  return (
    <CardTable
      data-testid='SftpErrorTable'
      columns={columns}
      dataSource={errors.result}
      loading={errors.loadState === LoadingState.InProgress}
      pagination={paginationConfig}
      rowKey={(error: SftpError): string => error.id}
      isLoaded={errors.isResolved()}
    />
  );
});

const ErrorTextHeader = styled(Bold15TransparentBlack900.span)`
  font-weight: bold;
`;

const ErrorTooltipText = styled(Regular13TransparentBlack900.span)`
  margin-top: 20px;
`;

const ErrorToolTip = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorText = styled(TableStandardText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  &:hover ${ErrorTooltipText} {
    display: block;
    visibility: visible;
  }
`;

export default SftpErrorTable;
