import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from '@emotion/styled';
import OldCardTable from '@app/components/OldCardTable';
import {
  changelogWarningTranslation,
  ChangeTypeTranslation,
  FileSurveyReport,
  FileSurveyReportAccount,
  FileSurveyReportAccountChangeType,
  FileSurveyReportPayeeChangeType,
  FileSurveyReportPayeeData,
  MVFCheckWarning,
} from '@mortee/domain/mvfCheck';
import { DataLoadingState } from '@mortee/stores/appStores/MasterDataGuardStore';
import { getRandomId } from '@app/libs/logger/perfMonitor/helpers';
import MvfVendorInfo from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/MvfVendorInfo';
import NonDeterministicVerificationResult from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/NonDeterministicVerificationResult';
import { ColumnProps } from 'antd/lib/table';
import EmptyReport from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/EmptyReport';
import ResizeObserver from 'react-resize-observer';
import { action } from 'mobx';
import DeterministicValidationResult from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/DeterministicValidationResult';
import { formatAccountWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { createArrayFieldDiff, createFieldDiff, Diff, DiffState } from '@app/utils/diffUtils';
import { getCountryName } from '@app/domain/countries';
import { getRowClassName, TableImportantText, TableStandardText } from '@app/components/tables/Table';
import { SyncInfo, SyncPlatform } from '@mortee/domain/masterDataGuard';
import { getLEIText } from '@app/utils/legalIdentifierUtils';
import { LegalEntityIdentifier } from '@app/domain/legalEntityIdentifier';
import { PrivatePayeeCompanies, transformCompanyToCompanyText } from '@mortee/domain/privatePayee';
import Loadable from '@app/utils/Loadable';

interface MvfCheckReportTableProps {
  reportLoadingState: DataLoadingState;
  selectedChangelogReport: FileSurveyReport[] | undefined;
  lastSyncInfo: Loadable<SyncInfo>;
}

interface UnifiedTableDetailsWithGroupIndex {
  changeType: FileSurveyReportPayeeChangeType | FileSurveyReportAccountChangeType;
  dataDiff: {
    hasChanges: boolean;
    names: Diff;
    legalIdentifiers: Diff;
    countryCode: Diff;
    supplierNumbers: Diff;
    companyCodes: Diff;
  };
  externalReferenceId: string;
  account?: FileSurveyReportAccount;
  groupIndex: number;
  rowSpan?: number;
  warnings?: MVFCheckWarning[];
}

const DENSE_MODE_MAX_TABLE_WIDTH: number = 1484;

const MvfCheckReportTable: FunctionComponent<MvfCheckReportTableProps> = observer((props) => {
  const { reportLoadingState, selectedChangelogReport, lastSyncInfo } = props;

  const localStore = useLocalObservable(() => ({
    isDenseMode: false as boolean,
  }));

  const transformFileSurveyReport = (surveyReport?: FileSurveyReport[]): UnifiedTableDetailsWithGroupIndex[] | undefined => {
    return surveyReport?.flatMap((surveyReport: FileSurveyReport, surveyIndex) => {
      const currentSurveyResult: UnifiedTableDetailsWithGroupIndex[] = [];

      const dataDiff = calcDataDiff(surveyReport);

      if (surveyReport.changeType !== FileSurveyReportPayeeChangeType.unchangedPayee) {
        if (surveyReport.changeType !== FileSurveyReportPayeeChangeType.changedPayee || dataDiff.hasChanges) {
          currentSurveyResult.push({
            ...surveyReport,
            dataDiff,
            account: undefined,
            warnings: surveyReport.data?.warnings,
            groupIndex: surveyIndex,
            rowSpan: (surveyReport.accounts?.length ?? 0) + 1,
          });
        }
      }

      if (surveyReport.accounts) {
        currentSurveyResult.push(
          ...surveyReport.accounts.map((account, accountIndex) => {
            let rowSpan = 0;

            // Define rowSpan only if there is no payee change and it's the first account
            // Also handle the case when there is payee change without data (as no payee record - therefore we need the span)
            if (
              (surveyReport.changeType === FileSurveyReportPayeeChangeType.unchangedPayee ||
                (surveyReport.changeType === FileSurveyReportPayeeChangeType.changedPayee && !dataDiff.hasChanges)) &&
              accountIndex === 0
            ) {
              rowSpan = surveyReport.accounts.length;
            }

            return {
              ...surveyReport,
              dataDiff,
              account,
              rowSpan,
              warnings: account.warnings,
              changeType: account.changeType,
              groupIndex: surveyIndex,
            };
          }),
        );
      }

      return currentSurveyResult;
    });
  };

  const calcDataDiff = (surveyReport: FileSurveyReport): UnifiedTableDetailsWithGroupIndex['dataDiff'] => {
    const nameDiff = createArrayFieldDiff<FileSurveyReportPayeeData, string, 'names'>(
      'names',
      surveyReport.data,
      surveyReport.oldData,
      surveyReport.changedFields,
    );

    const leiDiff = createArrayFieldDiff<FileSurveyReportPayeeData, LegalEntityIdentifier, 'legalIdentifiers'>(
      'legalIdentifiers',
      surveyReport.data,
      surveyReport.oldData,
      surveyReport.changedFields,
      getLEIText,
    );

    const countryCodeDiff = createFieldDiff<FileSurveyReportPayeeData, string, 'countryCode'>(
      'countryCode',
      surveyReport.data,
      surveyReport.oldData,
      surveyReport.changedFields,
      getCountryName,
    );

    const supplierNumbersDiff = createArrayFieldDiff<FileSurveyReportPayeeData, string, 'supplierNumbers'>(
      'supplierNumbers',
      surveyReport.data,
      surveyReport.oldData,
      surveyReport.changedFields,
    );

    const companyCodesDiff = createArrayFieldDiff<FileSurveyReportPayeeData, PrivatePayeeCompanies, 'companies'>(
      'companies',
      surveyReport.data,
      surveyReport.oldData,
      surveyReport.changedFields,
      transformCompanyToCompanyText,
    );

    return {
      names: nameDiff,
      legalIdentifiers: leiDiff,
      countryCode: countryCodeDiff,
      supplierNumbers: supplierNumbersDiff,
      companyCodes: companyCodesDiff,
      hasChanges:
        nameDiff.state !== DiffState.Same ||
        leiDiff.state !== DiffState.Same ||
        countryCodeDiff.state !== DiffState.Same ||
        supplierNumbersDiff.state !== DiffState.Same ||
        companyCodesDiff.state !== DiffState.Same,
    };
  };

  const handleResize = action((rect: DOMRect) => {
    localStore.isDenseMode = rect.width < DENSE_MODE_MAX_TABLE_WIDTH;
  });

  if (!selectedChangelogReport && reportLoadingState !== DataLoadingState.Loading) {
    return <div />;
  }

  return (
    <div>
      <ResizeObserver onResize={handleResize} />
      <StyledTable
        data-testid='mvfCheckTable'
        columns={createColumnDefinition(
          localStore.isDenseMode,
          lastSyncInfo.result?.organizationalErpPlatform === SyncPlatform.Oracle,
        )}
        dataSource={transformFileSurveyReport(selectedChangelogReport)}
        loading={!selectedChangelogReport || reportLoadingState === DataLoadingState.Loading}
        pagination={false}
        rowKey={(report: UnifiedTableDetailsWithGroupIndex): string => `${report.externalReferenceId}-${getRandomId()}`}
        isLoaded={reportLoadingState === DataLoadingState.NotLoading}
        rowClassName={getRowClassName}
        emptyComponent={<EmptyReport />}
      />
    </div>
  );
});

export default MvfCheckReportTable;

const EmptyValue = styled.div`
  color: #666666;
  font-size: 14px;
`;

const emptyValue = <EmptyValue>-----</EmptyValue>;

const StyledTableImportantText = styled(TableImportantText)`
  font-size: 14px;
`;

const StyledTableStandardText = styled(TableStandardText)`
  font-size: 14px;
`;

const ColumnSize = {
  vendorInfo: { default: 320, dense: 250 },
  event: { default: 200, dense: 190 },
  accountInfo: { default: 300, dense: 190 },
  validationResult: { default: 400, dense: 250 },
  warnings: { default: 300, dense: 150 },
};

const createColumnDefinition = (
  isDenseMode: boolean,
  showErpFields: boolean,
): ColumnProps<UnifiedTableDetailsWithGroupIndex>[] => [
  {
    title: <div data-testid='lblMVFInfo'>Payee Details (in MVF)</div>,
    key: 'mvfInfo',
    width: isDenseMode ? ColumnSize.vendorInfo.dense : ColumnSize.vendorInfo.default,
    className: 'with-right-border',
    render: (text: any, record: UnifiedTableDetailsWithGroupIndex): React.ReactNode => {
      const { externalReferenceId, dataDiff } = record;

      return {
        children: (
          <MvfVendorInfo
            internalId={externalReferenceId}
            names={dataDiff.names}
            countryCode={dataDiff.countryCode}
            legalIdentifiers={dataDiff.legalIdentifiers}
            showErpFields={showErpFields}
            supplierNumbers={dataDiff.supplierNumbers}
            companyCodes={dataDiff.companyCodes}
          />
        ),
        props: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: <div data-testid='lblEvent'>Event</div>,
    dataIndex: 'changeType',
    key: 'changeType',
    width: isDenseMode ? ColumnSize.event.dense : ColumnSize.event.default,
    render: (text: any, record: UnifiedTableDetailsWithGroupIndex): React.ReactNode => {
      return {
        children: (
          <StyledTableImportantText data-testid='event-type'>{ChangeTypeTranslation[record.changeType]}</StyledTableImportantText>
        ),
      };
    },
  },
  {
    title: <div data-testid='lblAccountInfo'>Bank Account (in MVF)</div>,
    key: 'accountInfo',
    width: isDenseMode ? ColumnSize.accountInfo.dense : ColumnSize.accountInfo.default,
    render: (text: any, record: UnifiedTableDetailsWithGroupIndex): React.ReactNode => {
      const { account } = record;

      if (!account?.accountDetails) {
        return emptyValue;
      }

      return (
        <>
          {formatAccountWithSwiftban(account.accountDetails)?.map((account) => (
            <StyledTableStandardText key={account}>{account}</StyledTableStandardText>
          ))}
          {account.accountDetails.countryCode && (
            <StyledTableStandardText key={account.accountDetails.countryCode}>
              Country: {getCountryName(account.accountDetails.countryCode)}
            </StyledTableStandardText>
          )}
        </>
      );
    },
  },
  {
    title: <div data-testid='lblValidationResult'>Verification result</div>,
    key: 'validationResult',
    width: isDenseMode ? ColumnSize.validationResult.dense : ColumnSize.validationResult.default,
    render: (text: any, record: UnifiedTableDetailsWithGroupIndex): React.ReactNode => {
      const { changeType, account } = record;

      if (!account || changeType === FileSurveyReportAccountChangeType.removedAccount) {
        return emptyValue;
      }

      return (
        <ColumnContainer>
          {account.nonDeterministicVerifications?.map((verification) => (
            <NonDeterministicVerificationResult key={`verify-${getRandomId()}`} verification={verification} />
          ))}
          {account.deterministicValidation && (
            <DeterministicValidationResult
              inputAccountDetails={{ ...account.accountDetails, localFormat: null }}
              deterministicValidation={account.deterministicValidation}
            />
          )}
        </ColumnContainer>
      );
    },
  },
  {
    title: <div data-testid='lblWarnings'>Warnings</div>,
    key: 'warnings',
    dataIndex: 'warnings',
    width: isDenseMode ? ColumnSize.warnings.dense : ColumnSize.warnings.default,
    render: (text: any, record: UnifiedTableDetailsWithGroupIndex): React.ReactNode => {
      const { warnings, changeType } = record;

      if (
        !warnings?.length ||
        changeType === FileSurveyReportAccountChangeType.removedAccount ||
        changeType === FileSurveyReportPayeeChangeType.removedPayee
      ) {
        return emptyValue;
      }

      return (
        <ColumnContainer>
          {warnings.map((warning) => (
            <StyledTableStandardText key={warning.warningType} data-testid='mvf-warning'>
              {changelogWarningTranslation(warning)}
            </StyledTableStandardText>
          ))}
        </ColumnContainer>
      );
    },
  },
];

const StyledTable = styled(OldCardTable)`
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }

  tr td:last-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  tr th:last-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  table {
    border-collapse: collapse;
    border-style: hidden;
  }

  table td {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 18px !important;
  }

  .with-right-border {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
