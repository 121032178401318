import React, { FunctionComponent } from 'react';
import { GuestLoginStep } from '@app/guestLogin/domain/guestLogin';
import { Trans, useTranslation } from 'react-i18next';
import StepsProgress from '@app/components/StepsProgress';
import { isTruthy } from '@app/utils/utils';
import config from '@app/config';
import useTheme from '@app/hooks/useTheme';
import ArMode from '@ar/arMode';

interface GuestLoginProgressProps {
  step: GuestLoginStep;
  className?: string;
}

const GuestLoginProgress: FunctionComponent<GuestLoginProgressProps> = ({ step, className }) => {
  const { t } = useTranslation();
  const { isHeaderDark } = useTheme<ArMode>();

  const steps = [
    t('ar.guestLogin.loginProgress.welcome'),
    <Trans i18nKey='ar.guestLogin.loginProgress.emailVerification'>
      Email
      <br />
      verification
    </Trans>,
    config.shouldLoginWithPhone && (
      <Trans i18nKey='ar.guestLogin.loginProgress.phoneVerification'>
        Phone
        <br />
        verification
      </Trans>
    ),
    <Trans i18nKey='ar.guestLogin.loginProgress.accountVerification'>
      Account verification
      <br />
      and details
    </Trans>,
  ].filter(isTruthy);

  return (
    <StepsProgress currentStepIndex={step} showStepNumbering steps={steps} className={className} isHeaderDark={isHeaderDark} />
  );
};

export default GuestLoginProgress;
