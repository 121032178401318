import React, { FC, Suspense } from 'react';
import { Router } from 'react-router-dom';
import browserHistory from '@app/utils/browserHistory';
import ThemeProvider from '@app/routes/app/ThemeProvider';
import Loader from '@app/components/Loader';
import DragNDropIndicationArea from '@app/components/fileUpload/DragNDropIndicationArea';

const CommonAppContext: FC = (props) => {
  const { children } = props;

  return (
    <ThemeProvider>
      <Router history={browserHistory}>
        <Suspense fallback={<Loader spinning fullScreen />}>
          <DragNDropIndicationArea>{children}</DragNDropIndicationArea>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default CommonAppContext;
