import React, { FunctionComponent, ReactElement, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import PaymentCheckBatchModel from '@mortee/models/PaymentCheckBatchModel';
import Status from '@mortee/components/Status';
import { PaymentCheckBatchStatus } from '@mortee/domain/paymentsConsts';
import DownloadableResource from '@app/components/DownloadableResource';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import Button from '@app/components/Button';
import exportIcon from '@app/images/export-Icon.svg';
import searchIcon from '@app/images/search-oval.svg';
import SVG from '@app/components/SVG';
import { downloadResourceByNamedResource } from '@app/utils/fileUtils';
import SmallLoadingSpinner from '@app/components/SmallLoadingSpinner';
import paymentCheckServices from '@mortee/services/paymentCheckServices';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';
import { showCustomModal } from '@app/components/Modal';
import MasavHashModal from '@mortee/routes/paymentCheck/batchPage/MasavHashModal';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import config from '@app/config';

interface PaymentBatchSummaryProps {
  storedBatch: PaymentCheckBatchModel | null;
  files?: File[];
  fetchBatchFile: (fileId: string) => Promise<NamedResource>;
  className?: string;
}

const PaymentBatchSummary: FunctionComponent<PaymentBatchSummaryProps> = observer((props) => {
  const { localeStore } = useInfraStores();
  const [isExporting, setIsExporting] = useState(false);
  const { storedBatch } = props;

  const exportPayments = async (): Promise<void> => {
    setIsExporting(true);

    try {
      const batchId = storedBatch?.id;

      if (!batchId) {
        return;
      }

      const resource: NamedResource = await paymentCheckServices.fetchExportPaymentFile(batchId);
      downloadResourceByNamedResource(resource);
    } finally {
      setIsExporting(false);
    }
  };

  const modalContext = useModalContext();

  const showMasavHashModal = (): void => {
    showCustomModal((onDone) => (
      <ModalAppContext {...modalContext}>
        <MasavHashModal onDone={onDone} />
      </ModalAppContext>
    ));
  };

  const renderBatchResult = (): ReactElement => {
    return (
      <HeaderDiv>
        <Container>
          <BatchVerificationStatus>
            Verification result:&nbsp;&nbsp;
            <StyledStatus
              dataTestId='batch-verification-status'
              status={storedBatch ? storedBatch.status : PaymentCheckBatchStatus.batchInProgress}
              mode='color'
              fontSize={13}
              isAuditStatus
            />
          </BatchVerificationStatus>
          {config.featureFlags?.masavDemo && (
            <VerifyHashButton appearance='text' id='btn-verify-hash' onClick={(): void => showMasavHashModal()}>
              <StyledSearchSVG image={searchIcon} accessibilityLabel={''} />
              Masav Hash
            </VerifyHashButton>
          )}
        </Container>
        <ExportPaymentsButton
          id='btn-export-payments'
          onClick={exportPayments}
          appearance='text'
          disabled={isExporting || !storedBatch}
        >
          {isExporting ? (
            <MessageLoadingSpinner colorScheme='secondary' size={16} />
          ) : (
            <StyledDownloadSVG accessibilityLabel='' image={exportIcon} />
          )}
          <ExportText>{isExporting ? 'Downloading report' : 'Download Full Report'}</ExportText>
        </ExportPaymentsButton>
      </HeaderDiv>
    );
  };

  const renderFilesListFromFiles = (files: File[]): ReactNode[] => {
    return files.map((file) => file.name);
  };

  const renderFilesListFromBatchMetadataFiles = (filesMetadata: FileMetadata[]): ReactNode[] => {
    return filesMetadata.map((fileMetadata: FileMetadata, index: number) => {
      return (
        <DownloadableResource
          dataTestId={`${index}_downloadableResource`}
          key={fileMetadata.id}
          fileMetadata={fileMetadata}
          fetchFile={(fileId): Promise<NamedResource> => fetchBatchFile(fileId)}
          spinnerWhileDownloading={false}
        >
          <LinkWithLine>{fileMetadata.name}</LinkWithLine>
        </DownloadableResource>
      );
    });
  };

  const renderFileDetail = (title: string, info: string, testId: string): ReactNode => {
    return (
      <DetailTitle>
        {title}
        <DetailInfo data-testid={testId}>{info?.trim() ? info : 'Not Provided'}</DetailInfo>
      </DetailTitle>
    );
  };

  const renderFilesAnalyzed = (): ReactNode => {
    const { storedBatch, files } = props;

    let fileElements: ReactNode[] = [];

    if (storedBatch) {
      fileElements = renderFilesListFromBatchMetadataFiles(storedBatch.files);
    } else if (files) {
      fileElements = renderFilesListFromFiles(files);
    }

    return (
      <FileDetailsContainer>
        {storedBatch && (
          <>
            <DetailsSection>
              {renderFileDetail(
                'Date:',
                localeStore.formatDate(storedBatch.writeTimestamp, formattedDateOptionsWithHoursAndMinutes),
                'detail-info-date',
              )}
              {renderFileDetail('User:', storedBatch.performingUserName, 'detail-info-user')}
            </DetailsSection>
            <VerticalSeparator />
          </>
        )}
        <DetailsSection>
          <UploadedFiles>
            <StyledFilesAnalyzed>File{fileElements.length !== 1 && 's'} analyzed:</StyledFilesAnalyzed>
            <StyledFilesAnalyzedElements>
              {fileElements.map((element, index) => [index ? '\xA0\xA0' : '', element])}
            </StyledFilesAnalyzedElements>
          </UploadedFiles>
          {storedBatch && renderFileDetail('Note:', storedBatch.notes?.trim() || `Note wasn't provided`, 'detail-info-note')}
        </DetailsSection>
      </FileDetailsContainer>
    );
  };

  const { fetchBatchFile, className } = props;

  return (
    <SummaryContainer className={className}>
      {renderBatchResult()}
      {renderFilesAnalyzed()}
    </SummaryContainer>
  );
});

export default PaymentBatchSummary;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BatchVerificationStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.44px;
  color: var(--transparent-black-900);
`;

const StyledStatus = styled(Status)`
  width: 130px;
`;

const UploadedFiles = styled.div`
  display: flex;
  text-align: start;
  margin-bottom: 14px;
`;

const FileDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 15px;

  font-size: 16px;
`;

const DetailsSection = styled.div`
  min-width: 400px;
`;

const DetailTitle = styled.div`
  display: flex;
  text-align: start;
  max-width: 440px;
  margin-bottom: 14px;
`;

const DetailInfo = styled.span`
  padding-left: 9px;
  color: var(--transparent-black-600);
`;

const VerticalSeparator = styled.div`
  width: 1px;
  background-color: var(--transparent-black-400);
  margin-right: 20px;
`;

const StyledFilesAnalyzed = styled.div`
  flex: 0 0 auto;
`;

const StyledFilesAnalyzedElements = styled.div`
  display: flex;
  text-align: start;
  flex-wrap: wrap;

  padding-left: 9px;
`;

const LinkWithLine = styled.a`
  text-decoration: underline !important;
`;

const ExportPaymentsButton = styled(Button)`
  display: flex;
  flex-direction: row;
  color: var(--transparent-black-600);
  align-self: center;
  padding: 16px 8px;
  align-items: center;
`;

const VerifyHashButton = styled(Button)`
  display: flex;
  flex-direction: row;
  color: var(--transparent-black-600);
  align-self: center;
  padding: 16px 8px;
  align-items: center;
`;

const ExportText = styled.div`
  margin-top: 2px;
`;

const StyledDownloadSVG = styled(SVG)`
  margin-right: 5px;
  width: 23px;
  height: 23px;
`;

const StyledSearchSVG = styled(SVG)`
  margin-right: 5px;
  width: 30px;
  height: 30px;
`;

const MessageLoadingSpinner = styled(SmallLoadingSpinner)`
  margin-right: 10px;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
