import request, { HttpMethod, HttpStatus } from '@app/libs/request';
import config from '@app/config';
import {
  AssigneeServerResponse,
  CancelReasonServerResponse,
  NULL_ORG,
  statusStrings,
  StoreSupplierValidationAccountVerificationRecordRequest,
  StoreSupplierValidationAlert,
  StoreSupplierValidationComment,
  StoreSupplierValidationPayeeVerificationRecordRequest,
  StoreSupplierValidationRecordRequest,
  SupplierValidationAccountVerificationRecordServerResponse,
  SupplierValidationExportServerResponse,
  SupplierValidationPayeeVerificationRecordServerResponse,
  SupplierValidationPayeeWithAccountsVerificationServerResponse,
  SupplierValidationPayeeWithAccountsVerificationWarningsServerResponse,
  SupplierValidationRecordServerResponse,
  SupplierValidationRecordUpdateRequest,
  SupplierValidationRecordWithWarningsServerResponse,
  SupplierValidationVerificationEvidenceServerResponse,
  SupplierValidationVerificationRecordAccountUpdateRequest,
  SupplierValidationVerificationRecordEvidenceServerResponse,
  SupplierValidationVerificationRecordPayeeUpdateRequest,
  ValidationSystemOrganizationServerResponse,
} from '@mortee/domain/validationSystem';
import { SupplierValidationRecordFieldFilters, SupplierValidationRecordSorting } from '@mortee/domain/validationRecordTable';
import { SupplierRegistrationProcessServerResponse } from '@mortee/domain/morteeRegistrationForms';
import qs from 'query-string';
import { ActivityLog, ActivityLogServerResponse } from '@mortee/domain/validationSystemTimelineEvents';
import { YesNoFilterOption } from '@app/domain/filters';
import { trimToUndefined } from '@app/utils/stringUtils';
import { translateFileNameToContentType } from '@app/domain/files';
import React from 'react';
import { minutesToMilliseconds } from '@app/utils/timeUtils';
import { AlertServerResponse } from '@mortee/domain/validationSystemAlerts';

function transformEmptyOrgToNull(orgIds: string[] | undefined): string[] | undefined {
  if (!orgIds || orgIds?.length === 0) {
    return orgIds;
  }

  return orgIds.map((id) => (id === '' ? NULL_ORG.id : id));
}

function sendEmptyArrayAsQueryParam<T>(arr: T[] | null | undefined): string | T[] | null | undefined {
  if (arr?.length === 0) {
    return '';
  }

  return arr;
}

export default {
  async storeValidationRecord(
    validationProcessRequest: StoreSupplierValidationRecordRequest,
  ): Promise<SupplierValidationRecordWithWarningsServerResponse> {
    return request<SupplierValidationRecordWithWarningsServerResponse>(config.serverUrls.svm, `/api/validations`, {
      data: validationProcessRequest,
      method: HttpMethod.post,
      errorsHandler: {
        default: {
          message: 'Could not create validation record.',
        },
        SV_REGISTRATION_ALREADY_LINKED_ERROR: {
          message: ({ validationRecordPresentationId }) => {
            return `The registration form is already linked to ${validationRecordPresentationId}. You can remove the current link in ${validationRecordPresentationId} and try again.`;
          },
        },
        SUPPLIER_VALIDATION_PROCESS_ORGANIZATION_ID_MISMATCH_ERROR: {
          message: () => {
            return `Customer name does not match referring customer of the linked registration forms.`;
          },
        },
        SV_LINK_ID_EXISTS_ON_ANOTHER_RECORD: {
          message: () => {
            return `The organisation already has an existing SV Link Id.`;
          },
        },
        INITIAL_EMAIL_ID_EXISTS_ON_ANOTHER_RECORD: {
          message: () => {
            return `Email ID already used in another record.`;
          },
        },
      },
    });
  },

  async getValidationRecordByStaticId(staticId: string): Promise<SupplierValidationRecordServerResponse> {
    return request<SupplierValidationRecordServerResponse>(config.serverUrls.svm, `/api/validations/:staticId`, {
      method: HttpMethod.get,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not get validation record.',
        },
      },
    });
  },

  async getVerificationDataValidationRecordByStaticId(
    validationRecordStaticId: string,
  ): Promise<SupplierValidationPayeeWithAccountsVerificationServerResponse> {
    return request<SupplierValidationPayeeWithAccountsVerificationServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId`,
      {
        method: HttpMethod.get,
        pathParams: {
          validationRecordStaticId,
        },
        errorsHandler: {
          default: {
            message: 'Could not get payee record details.',
          },
          [HttpStatus.notFound]: {
            suppressNotification: true,
          },
        },
      },
    );
  },

  async getVerificationDataValidationRecordWarningsByStaticId(
    validationRecordStaticId: string,
  ): Promise<SupplierValidationPayeeWithAccountsVerificationWarningsServerResponse> {
    return request<SupplierValidationPayeeWithAccountsVerificationWarningsServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId/warnings`,
      {
        method: HttpMethod.get,
        pathParams: {
          validationRecordStaticId,
        },
        errorsHandler: {
          default: {
            message: 'Could not calculate record warnings.',
          },
        },
      },
    );
  },

  async storeSupplierValidationPayeeVerificationRecord(
    validationRecordStaticId: string,
    payeeVerificationRequest: StoreSupplierValidationPayeeVerificationRecordRequest,
  ): Promise<SupplierValidationPayeeVerificationRecordServerResponse> {
    return request<SupplierValidationPayeeVerificationRecordServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId/payee`,
      {
        method: HttpMethod.post,
        pathParams: {
          validationRecordStaticId,
        },
        data: payeeVerificationRequest,
        errorsHandler: {
          default: {
            message: 'Could not create payee verification record.',
          },
        },
      },
    );
  },

  async storeSupplierValidationAccountVerificationRecord(
    validationRecordStaticId: string,
    accountVerificationRequest: StoreSupplierValidationAccountVerificationRecordRequest,
  ): Promise<SupplierValidationAccountVerificationRecordServerResponse> {
    return request<SupplierValidationAccountVerificationRecordServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId/accounts`,
      {
        method: HttpMethod.post,
        pathParams: {
          validationRecordStaticId,
        },
        data: accountVerificationRequest,
        errorsHandler: {
          default: {
            message: 'Could not create account verification record.',
          },
        },
      },
    );
  },

  async updateSupplierValidationPayeeVerificationRecord(
    validationRecordStaticId: string,
    updateRequest: SupplierValidationVerificationRecordPayeeUpdateRequest,
  ): Promise<SupplierValidationPayeeVerificationRecordServerResponse> {
    return request<SupplierValidationPayeeVerificationRecordServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId/payee`,
      {
        method: HttpMethod.patch,
        pathParams: {
          validationRecordStaticId,
        },
        data: updateRequest,
        errorsHandler: {
          default: {
            message: 'Could not update payee verification record.',
          },
        },
      },
    );
  },

  async updateSupplierValidationAccountVerificationRecord(
    validationRecordStaticId: string,
    verificationAccountStaticId: string,
    updateRequest: SupplierValidationVerificationRecordAccountUpdateRequest,
  ): Promise<SupplierValidationAccountVerificationRecordServerResponse> {
    return request<SupplierValidationAccountVerificationRecordServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId/accounts/:verificationAccountStaticId`,
      {
        method: HttpMethod.patch,
        pathParams: {
          validationRecordStaticId,
          verificationAccountStaticId,
        },
        data: updateRequest,
        errorsHandler: {
          default: {
            message: 'Could not update account verification record.',
          },
        },
      },
    );
  },

  async deleteSupplierValidationAccountVerificationRecord(
    validationRecordStaticId: string,
    verificationAccountStaticId: string,
  ): Promise<SupplierValidationPayeeWithAccountsVerificationServerResponse> {
    return request<SupplierValidationPayeeWithAccountsVerificationServerResponse>(
      config.serverUrls.svm,
      `/api/verifications/:validationRecordStaticId/accounts/:verificationAccountStaticId`,
      {
        method: HttpMethod.delete,
        pathParams: {
          validationRecordStaticId,
          verificationAccountStaticId,
        },
        errorsHandler: {
          default: {
            message: 'Could not delete account verification record.',
          },
        },
      },
    );
  },

  async completeSupplierValidationVerificationRecord(
    validationRecordStaticId: string,
    presentationId: string,
    editVerificationPath: string,
  ): Promise<void> {
    return request<void>(config.serverUrls.svm, `/api/verifications/:validationRecordStaticId/complete`, {
      method: HttpMethod.post,
      pathParams: {
        validationRecordStaticId,
      },
      timeout: minutesToMilliseconds(10),
      errorsHandler: {
        VALIDATION_RECORD_NOT_IN_STATUS_WAITING_FOR_APPROVAL: {
          message: () => `Could not complete because the validation record status is not "${statusStrings.WaitingForApproval}"`,
        },
        LYONS_VALIDATION_RECORD_NOT_PRIVATE: {
          message: () => `Could not complete Lyons validation record that is not private.`,
        },
        default: {
          message: (
            <span>
              Could not complete{' '}
              <a href={editVerificationPath} target='_blank' rel='noreferrer'>
                verification of {presentationId}
              </a>
            </span>
          ),
        },
      },
    });
  },

  async storeVerificationEvidence(file: File): Promise<SupplierValidationVerificationEvidenceServerResponse> {
    const sendForm = new FormData();

    sendForm.append('evidenceFile', new Blob([file], { type: translateFileNameToContentType(file.name) }), file.name);

    return request<SupplierValidationVerificationEvidenceServerResponse>(config.serverUrls.svm, `/api/evidence`, {
      method: HttpMethod.post,
      isFileUpload: true,
      data: sendForm,
      timeout: 5400000,
      errorsHandler: {
        default: {
          message: 'Could not upload evidence file.',
        },
        EVIDENCE_FILE_MISSING_MANDATORY_FIELDS: {
          message: ({ missingField }) => {
            return `Some rows missing mandatory field - ${missingField}.`;
          },
        },
        EMPTY_EVIDENCE_FILE: {
          message: () => {
            return 'Evidence file is empty.';
          },
        },
        EVIDENCE_FILE_CONTAINS_MORE_THEN_ONE_RECORD_TYPE: {
          message: () => {
            return 'Evidence file contains more then one record type.';
          },
        },
        EVIDENCE_FILE_DUPLICATE_IDS: {
          message: () => {
            return 'Evidence file contains duplicate VR ids.';
          },
        },
        EVIDENCE_FILE_CONFLICT: {
          message: () => {
            return 'Evidence file contains rows that have existing evidence.';
          },
        },
        EVIDENCE_FILE_SOME_RECORDS_NOT_FOUND: {
          message: () => {
            return 'Evidence file contains rows that have no corresponding SVM records.';
          },
        },
      },
    });
  },

  async getVerificationRecordEvidence(
    validationRecordStaticId: string,
  ): Promise<SupplierValidationVerificationRecordEvidenceServerResponse> {
    return request<SupplierValidationVerificationRecordEvidenceServerResponse>(
      config.serverUrls.svm,
      `/api/evidence/:validationRecordStaticId`,
      {
        method: HttpMethod.get,
        pathParams: {
          validationRecordStaticId,
        },
        suppressNotification: true,
      },
    );
  },

  async deleteVerificationRecordEvidence(validationRecordStaticId: string, evidenceId: string): Promise<void> {
    return request<void>(config.serverUrls.svm, `/api/validations/:validationRecordStaticId/evidence/:evidenceId`, {
      method: HttpMethod.delete,
      pathParams: {
        validationRecordStaticId,
        evidenceId,
      },
      errorsHandler: {
        default: {
          message: 'Could not delete record evidence details.',
        },
      },
    });
  },

  async getValidationRecords(
    currentPage: number | undefined,
    pageSize: number | undefined,
    filters: SupplierValidationRecordFieldFilters | undefined,
    sort: SupplierValidationRecordSorting | undefined,
    abortRequestSignal?: AbortSignal,
  ): Promise<PaginatedData<SupplierValidationRecordServerResponse>> {
    const start = ((currentPage ?? 1) - 1) * (pageSize ?? 0);

    const customerInvolvement = !filters?.customerInvolvement?.length
      ? undefined
      : filters.customerInvolvement[0] === YesNoFilterOption.yes;

    return request<PaginatedData<SupplierValidationRecordServerResponse>>(config.serverUrls.svm, `/api/validations`, {
      signal: abortRequestSignal,
      params: {
        statuses: sendEmptyArrayAsQueryParam(filters?.status),
        'assignee-ids': sendEmptyArrayAsQueryParam(filters?.assignee),
        'did-user-register': filters?.didUserRegister,
        'organization-ids': sendEmptyArrayAsQueryParam(transformEmptyOrgToNull(filters?.organizationId)),
        'registration-timestamp-start': filters?.registrationDate?.start,
        'registration-timestamp-end': filters?.registrationDate?.end,
        'automailer-statuses': sendEmptyArrayAsQueryParam(filters?.automailerStatus),
        'customer-involvement': customerInvolvement,
        'evidence-types': sendEmptyArrayAsQueryParam(filters?.evidenceType),
        'instruction-types': sendEmptyArrayAsQueryParam(filters?.instructionType),
        'follow-up-filter': filters?.followUpFilter,
        sort: sort?.field,
        order: sort?.order,
        offset: start,
        limit: pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not get validation records.',
        },
      },
      suppressNotification: true,
    });
  },

  async searchValidationRecords(
    currentPage: number | undefined,
    pageSize: number,
    searchQuery: string,
    abortRequestSignal?: AbortSignal,
  ): Promise<PaginatedData<SupplierValidationRecordServerResponse>> {
    const start = ((currentPage ?? 1) - 1) * pageSize;

    return request<PaginatedData<SupplierValidationRecordServerResponse>>(config.serverUrls.svm, `/api/validations/search`, {
      signal: abortRequestSignal,
      params: {
        query: searchQuery,
        offset: start,
        limit: pageSize,
      },
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not search validation records.',
        },
      },
      suppressNotification: true,
    });
  },

  async getAllAssignees(isAssignable?: boolean): Promise<AssigneeServerResponse[]> {
    return request<AssigneeServerResponse[]>(config.serverUrls.svm, `/api/users`, {
      params: { isAssignable },
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not fetch assignees.',
        },
      },
    });
  },

  async getAllValidationSystemOrganizations(): Promise<ValidationSystemOrganizationServerResponse[]> {
    return request<ValidationSystemOrganizationServerResponse[]>(config.serverUrls.svm, `/api/organizations`, {
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not fetch organizations.',
        },
      },
    });
  },

  async getRecommendedSupplierRegistrationProcessesForOrganization(
    organizationId: string | null,
    includeIgnored: boolean,
  ): Promise<SupplierRegistrationProcessServerResponse[]> {
    const orgId = organizationId === NULL_ORG.id ? null : organizationId;
    return request<SupplierRegistrationProcessServerResponse[]>(config.serverUrls.svm, `/api/supplier-validation`, {
      method: HttpMethod.get,
      params: {
        organizationId: orgId,
        linked: false,
        includeIgnored: includeIgnored,
      },
      errorsHandler: {
        default: {
          message: 'Could not get recommended registration forms records.',
        },
      },
    });
  },

  async updateValidationRecord(
    staticId: string,
    updateRequest: SupplierValidationRecordUpdateRequest,
  ): Promise<SupplierValidationRecordWithWarningsServerResponse> {
    return request<SupplierValidationRecordWithWarningsServerResponse>(config.serverUrls.svm, `/api/validations/:staticId`, {
      data: updateRequest,
      method: HttpMethod.patch,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not update validation record.',
        },
        SV_REGISTRATION_ALREADY_LINKED_ERROR: {
          message: ({ validationRecordPresentationId }) => {
            return `The registration form is already linked to ${validationRecordPresentationId}. You can remove the current link in ${validationRecordPresentationId} and try again.`;
          },
        },
        USER_NOT_ASSIGNABLE_ERROR: {
          message: () => {
            return `You cannot assign this record to this user. Try selecting a different user from the list.`;
          },
        },
        SUPPLIER_VALIDATION_PROCESS_ORGANIZATION_ID_MISMATCH_ERROR: {
          message: () => {
            return `Customer name does not match referring customer of the linked registration forms.`;
          },
        },
        SV_LINK_ID_EXISTS_ON_ANOTHER_RECORD: {
          message: () => {
            return `The organisation already has an existing SV Link Id.`;
          },
        },
        INITIAL_EMAIL_ID_EXISTS_ON_ANOTHER_RECORD: {
          message: () => {
            return `Email ID already used in another record.`;
          },
        },
      },
    });
  },

  async getValidationTimelineByStaticId(staticId: string): Promise<ActivityLogServerResponse> {
    return request<ActivityLogServerResponse>(config.serverUrls.svm, `/api/validations/:staticId/activity-log`, {
      method: HttpMethod.get,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not get activity log.',
        },
      },
    });
  },

  async getAllValidationRecordCancelReasons(): Promise<CancelReasonServerResponse[]> {
    return request<CancelReasonServerResponse[]>(config.serverUrls.svm, `/api/cancel-reasons`, {
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not get options of cancel reason.',
        },
      },
    });
  },

  async postValidationComment(staticId: string, comment: StoreSupplierValidationComment): Promise<ActivityLog> {
    return request<ActivityLog>(config.serverUrls.svm, `/api/validations/:staticId/comments`, {
      data: comment,
      method: HttpMethod.post,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not post comment.',
        },
      },
    });
  },

  async deleteValidationCommentEvent(staticId: string, commentId: string): Promise<ActivityLog> {
    return request<ActivityLog>(config.serverUrls.svm, `/api/validations/:staticId/comments/:commentId`, {
      method: HttpMethod.delete,
      pathParams: {
        staticId,
        commentId,
      },
      errorsHandler: {
        default: {
          message: 'Could not delete comment.',
        },
      },
    });
  },

  async distributeValidationRecord(staticId: string): Promise<SupplierValidationRecordServerResponse> {
    return request<SupplierValidationRecordServerResponse>(config.serverUrls.svm, `/api/distribution/:staticId`, {
      data: {},
      method: HttpMethod.post,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not distribute record.',
        },
        DISTRIBUTION_EMAIL_NOT_SENT_ERROR: {
          message: () => {
            return `Distribution was not sent due to server error.`;
          },
        },
        DISTRIBUTOR_NOT_AVAILABLE_FOR_THIS_TRIGGER_ERROR: {
          message: () => {
            return `This instruction type distribution is not available on this trigger.`;
          },
        },
        MATCHING_DISTRIBUTOR_NOT_FOUND_ERROR: {
          message: () => {
            return `Distribution is not yet implemented for the record instruction type.`;
          },
        },
      },
    });
  },

  async markAsDistributedValidationRecord(staticId: string): Promise<SupplierValidationRecordServerResponse> {
    return request<SupplierValidationRecordServerResponse>(config.serverUrls.svm, `/api/distribution/:staticId/mark-as`, {
      data: {},
      method: HttpMethod.post,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not mark this record as distributed.',
        },
      },
    });
  },

  async sendValidationsReport(): Promise<SupplierValidationExportServerResponse> {
    return request<SupplierValidationExportServerResponse>(config.serverUrls.svm, `/api/validations/export`, {
      method: HttpMethod.get,
    });
  },

  async exportValidationsReport(
    organizationId: string,
    reportPassword: string | undefined | null,
  ): Promise<SupplierValidationExportServerResponse> {
    return request<SupplierValidationExportServerResponse>(
      config.serverUrls.svm,
      `/api/validations/export/validations-report/:organizationId`,
      {
        method: HttpMethod.get,
        pathParams: { organizationId },
        params: {
          reportPassword: trimToUndefined(reportPassword),
        },
        errorsHandler: {
          default: {
            message: 'Could not generate validations report.',
          },
        },
      },
    );
  },

  async getBankNameBySwiftCode(swift: string): Promise<string> {
    return request<string>(config.serverUrls.svm, `/api/bank-name/by-swift`, {
      method: HttpMethod.get,
      params: {
        swiftCode: swift,
      },
      errorsHandler: {
        default: {
          message: 'Could not get bank name by swift code.',
        },
        [HttpStatus.notFound]: {
          suppressNotification: true,
        },
      },
    });
  },

  async getBankNameByCountryCodeAndBankCode(countryCode: string, bankCode: string): Promise<string> {
    return request<string>(config.serverUrls.svm, `/api/bank-name/by-country-code-and-bank-code`, {
      method: HttpMethod.get,
      params: {
        countryCode: countryCode,
        bankCode: bankCode,
      },
      errorsHandler: {
        default: {
          message: 'Could not get bank name by country code and bank code.',
        },
        [HttpStatus.notFound]: {
          suppressNotification: true,
        },
      },
    });
  },

  async getAlertsByStaticId(staticId: string): Promise<AlertServerResponse[]> {
    return request<AlertServerResponse[]>(config.serverUrls.svm, `/api/alerts/validations/:staticId`, {
      method: HttpMethod.get,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not get alerts for record.',
        },
      },
    });
  },

  async postValidationAlert(staticId: string, alert: StoreSupplierValidationAlert): Promise<ActivityLog> {
    return request<ActivityLog>(config.serverUrls.svm, `/api/alerts/validations/:staticId`, {
      data: alert,
      method: HttpMethod.post,
      pathParams: {
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not post alert.',
        },
      },
    });
  },

  async resolveValidationAlert(staticId: string, alertStaticId: string): Promise<ActivityLog> {
    return request<ActivityLog>(config.serverUrls.svm, `/api/alerts/:alertStaticId/validations/:staticId`, {
      method: HttpMethod.patch,
      pathParams: {
        alertStaticId,
        staticId,
      },
      errorsHandler: {
        default: {
          message: 'Could not post alert.',
        },
      },
    });
  },
};
