import React, { FC, ReactElement } from 'react';
import useLoadable from '@app/hooks/loadable/useLoadable';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  EvidenceType,
  evidenceTypeText,
  SupplierValidationRecord,
  transformSupplierValidationVerificationRecordEvidence,
} from '@mortee/domain/validationSystem';
import { Regular22TransparentBlack900 } from '@app/components/Text';
import Button from '@app/components/Button';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import styled from '@emotion/styled';
import { showYesNoModal } from '@app/components/Modal';
import { shoot } from '@app/utils/messageLauncher';
import useInfraStores from '@app/hooks/useInfraStores';
import { extractLogErrorIdFromError, HttpStatus, isRequestError } from '@app/libs/request';
import { formattedDateOptionsDDMMYYYY } from '@app/utils/timeUtils';
import { TransparentCard } from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';
import LoadableTransitionLoader from '@app/components/LoadableTransitionLoader';
import ReactJson from '@microlink/react-json-view';

interface Props {
  record: SupplierValidationRecord;
  disabled: boolean;
}

const SupplierValidationRecordEvidence: FC<Props> = ({ record, disabled }) => {
  const { localeStore } = useInfraStores();
  const { staticId, versionId } = record;

  const [evidence, reloadEvidence] = useLoadable(async () => {
    const serverResponse = await validationSystemServices.getVerificationRecordEvidence(staticId);
    return transformSupplierValidationVerificationRecordEvidence(serverResponse);
  }, [staticId, versionId]);

  const onDeleteEvidence = (evidenceId: string): void => {
    showYesNoModal(
      'Are you sure you want to delete this record evidence?',
      async (): Promise<void> => {
        const closeLoadingMessage = shoot({ type: 'loading', closeable: true, duration: null }, 'Deleting...');
        try {
          await validationSystemServices.deleteVerificationRecordEvidence(staticId, evidenceId);
          await reloadEvidence();
          closeLoadingMessage();
          shoot({ type: 'success', closeable: true }, `The record's evidence have been deleted`);
        } catch {
          closeLoadingMessage();
        }
      },
      'Delete Evidence',
    );
  };

  if (evidence.isRejected()) {
    const error = evidence.stateMetadata?.error;

    const displayMessage =
      isRequestError(error) && error.code === HttpStatus.notFound
        ? 'No evidence were found for this record'
        : `Error loading evidence data, refer to error code: ${extractLogErrorIdFromError(error)}`;

    return (
      <TransparentCard>
        <div data-testid='no-evidence-found'>{displayMessage}</div>
      </TransparentCard>
    );
  }

  return (
    <LoadableTransitionLoader loadable={evidence}>
      {(evidenceData): ReactElement => (
        <TransparentCard>
          <TitleLine>
            <div>
              <Regular22TransparentBlack900.div>Latest Evidence</Regular22TransparentBlack900.div>
            </div>
          </TitleLine>
          <Separator />
          <TwoColumnGrid>
            <DataGrid>
              {evidenceData.type && (
                <DataGridRow title='Type:' valueDataTest='evidence-type'>
                  {evidenceTypeText[evidenceData.type]}
                </DataGridRow>
              )}
              {evidenceData.data.payeeName && (
                <DataGridRow title='Payee Name:' valueDataTest='evidence-payee-name'>
                  {evidenceData.data.payeeName}
                </DataGridRow>
              )}
              {evidenceData.data.address && (
                <DataGridRow title='Address:' valueDataTest='evidence-address'>
                  {evidenceData.data.address}
                </DataGridRow>
              )}
              {evidenceData.taxId && (
                <DataGridRow title='Tax Id:' valueDataTest='evidence-tax-id'>
                  {evidenceData.taxId}
                </DataGridRow>
              )}
              {evidenceData.taxIdType && (
                <DataGridRow title='Tax Id Type:' valueDataTest='evidence-tax-id-type'>
                  {evidenceData.taxIdType}
                </DataGridRow>
              )}
              {evidenceData.taxIdCountryCode && (
                <DataGridRow title='Tax Id Country Code:' valueDataTest='evidence-tax-id-country-code'>
                  {evidenceData.taxIdCountryCode}
                </DataGridRow>
              )}
              {evidenceData.result && (
                <DataGridRow title='Result:' valueDataTest='evidence-result'>
                  {evidenceData.result}
                </DataGridRow>
              )}
              {evidenceData.data.comment && (
                <DataGridRow title='Comment:' valueDataTest='evidence-comment'>
                  {evidenceData.data.comment}
                </DataGridRow>
              )}
              {evidenceData.evidenceRecordId && (
                <DataGridRow title='Evidence Record Id:' valueDataTest='evidence-record-id'>
                  {evidenceData.evidenceRecordId}
                </DataGridRow>
              )}
              {evidenceData.customerId && (
                <DataGridRow title='Customer id:' valueDataTest='evidence-customer-id'>
                  {evidenceData.customerId}
                </DataGridRow>
              )}
              {evidenceData.type === EvidenceType.lyonsValidation && evidenceData.jsonRawData && (
                <DataGridRow title='Lyons Raw Data:' valueDataTest='evidence-raw-data'>
                  <ReactJson
                    src={JSON.parse(evidenceData.jsonRawData)}
                    name={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    collapsed
                  />
                </DataGridRow>
              )}
            </DataGrid>
            <DataGrid>
              {evidenceData.data.bankName && (
                <DataGridRow title='Bank Name:' valueDataTest='evidence-bank-name'>
                  {evidenceData.data.bankName}
                </DataGridRow>
              )}
              {evidenceData.swiftCode && (
                <DataGridRow title='SWIFT:' valueDataTest='evidence-swift'>
                  {evidenceData.swiftCode}
                </DataGridRow>
              )}
              {evidenceData.iban && (
                <DataGridRow title='IBAN:' valueDataTest='evidence-iban'>
                  {evidenceData.iban}
                </DataGridRow>
              )}
              {evidenceData.bankCode && (
                <DataGridRow title='Bank Code:' valueDataTest='evidence-bank-code'>
                  {evidenceData.bankCode}
                </DataGridRow>
              )}
              {evidenceData.branchCode && (
                <DataGridRow title='Branch Code:' valueDataTest='evidence-branch-code'>
                  {evidenceData.branchCode}
                </DataGridRow>
              )}
              {evidenceData.accountNumber && (
                <DataGridRow title='Account Number:' valueDataTest='evidence-account-number'>
                  {evidenceData.accountNumber}
                </DataGridRow>
              )}
              {evidenceData.currency && (
                <DataGridRow title='Currency:' valueDataTest='evidence-currency'>
                  {evidenceData.currency}
                </DataGridRow>
              )}
              {evidenceData.amount && (
                <DataGridRow title='Amount:' valueDataTest='evidence-amount'>
                  {evidenceData.amount}
                </DataGridRow>
              )}
              {evidenceData.effectiveDate && (
                <DataGridRow title='Effective Date:' valueDataTest='evidence-effective-date'>
                  {localeStore.formatDateTime(evidenceData.effectiveDate, formattedDateOptionsDDMMYYYY)}
                </DataGridRow>
              )}
            </DataGrid>
          </TwoColumnGrid>
          <ButtonsContainer>
            <Button
              appearance='full'
              id='btn-edit-validation-process-delete-evidence'
              onClick={(): void => onDeleteEvidence(evidenceData.id)}
              disabled={disabled || !evidenceData.isDeletable}
            >
              DELETE EVIDENCE
            </Button>
          </ButtonsContainer>
        </TransparentCard>
      )}
    </LoadableTransitionLoader>
  );
};

export default SupplierValidationRecordEvidence;

const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Separator = styled.hr`
  border-top: solid 1px var(--transparent-black-200);
  margin: 14px 0;
`;

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: start;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 25px;
`;
