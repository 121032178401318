import React, { FunctionComponent, ReactElement, ReactNode, useRef } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import TextHighlighter from '@app/components/TextHighlighter';
import { BlockDescriptor, InfoCategory, InfoContent, Title, TitleContainer } from '@app/components/DisplayFields';
import { showInfoModal } from '@app/components/Modal';
import PayeeDetailsMoreInfo from './PayeeDetailsMoreInfo';
import ReactPlaceholder from 'react-placeholder';
import { RectShape, TextBlock, TextRow } from 'react-placeholder/lib/placeholders';
import Nationality from '@app/components/Nationality';
import Card from '@app/components/card/Card';
import { isDefined } from '@app/utils/utils';
import Button from '@app/components/Button';
import { distinctValuesByKey, getListWithLeading, mergeArrays } from '@app/utils/arrayUtils';
import OldChip from '@app/components/OldChip';
import { equalsLEI, unifyLeis } from '@app/utils/legalIdentifierUtils';
import { MorteeVerificationResult } from '@mortee/domain/morteeAccountVerification';
import {
  UnifiedPayee,
  UnifiedPayeeLegalEntityIdentifier,
  UnifiedPayeeStrongLegalIdentifies,
} from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import { getFormattedLEI, removeStrongIds, StrongLegalIdentifiesTypes } from '@app/domain/legalEntityIdentifier';
import { ValidatedPayeePrimaryFields } from '@app/domain/validatedPayee';
import SanctionsBadge from '@app/components/SanctionsBadge';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import CopyableValue from '@app/components/CopyableValue';
import { compare } from '@app/utils/comparatorUtils';

interface LoadingPayeeDetailsProps {
  showLoading: true;
  title?: string;
  fullDetails?: false;
  className?: string;
  payee?: undefined;
  verificationDetails?: MorteeVerificationResult;
  hideTitle?: boolean;
}

interface PayeeModelPayeeDetailsProps {
  payee: UnifiedPayee;
  title?: string;
  fullDetails?: boolean;
  children?: ReactNode;
  showLoading?: false;
  className?: string;
  organization?: UserOrganization;
  searchText?: string;
  verificationDetails?: MorteeVerificationResult;
  hideTitle?: boolean;
}

const PayeeDetails: FunctionComponent<LoadingPayeeDetailsProps | PayeeModelPayeeDetailsProps> = observer((props) => {
  const payeeDetailsRef = useRef({
    modal: undefined as any,
  });

  const modalContext = useModalContext();

  const openMoreInfo = (): void => {
    const { payee, verificationDetails, showLoading } = props;

    if (showLoading) {
      return;
    }

    if (payee) {
      const content = (
        <ModalAppContext {...modalContext}>
          <PayeeDetailsMoreInfo
            payee={payee}
            validationDetails={verificationDetails ?? null}
            dataTestId='modalPayeeDetailsMoreInfo'
            onReturn={closeMoreInfo}
          />
        </ModalAppContext>
      );
      payeeDetailsRef.current.modal = showInfoModal(content, { okType: 'ghost', className: 'card' });
    }
  };

  const closeMoreInfo = (): void => {
    payeeDetailsRef.current.modal?.destroy();
    payeeDetailsRef.current.modal = null;
  };

  const hasMoreInfo = (): boolean => {
    if (!props.payee) {
      return false;
    }

    const { emails, phones, faxes, websites, description } = props.payee;

    return !!emails?.length || !!phones?.length || !!faxes?.length || !!websites?.length || !!description?.length;
  };

  const renderTitleAndCountry = (names: string[], countryCode: string | undefined): React.ReactElement => {
    const searchText = props.showLoading ? '' : props.searchText;
    const [firstName, ...others] = names;

    const { verificationDetails } = props;

    return (
      <>
        <PayeeTitleContainer>
          <TitleDisplay data-testid='payee-name'>
            <TextHighlighter searchText={searchText} fontWeight='Heavy'>
              {firstName}
            </TextHighlighter>
            <SanctionsBadge sanctionsScreeningInfo={verificationDetails?.sanctionsScreeningInfo} />
          </TitleDisplay>
          <PropTextCountryCode data-testid='payee-country'>
            <StyledNationality countryCode={countryCode} />
          </PropTextCountryCode>
        </PayeeTitleContainer>
        {!!others?.length && (
          <PropAKA data-testid='payee-other-names'>
            <InfoCategory>Also known as: </InfoCategory>
            <PropTextRow>
              <TextHighlighter searchText={searchText}>{others.join(', ')}</TextHighlighter>
            </PropTextRow>
          </PropAKA>
        )}
      </>
    );
  };

  const renderPayeeProperties = (
    countryCode: string | undefined,
    identifiers: UnifiedPayeeLegalEntityIdentifier[] | undefined,
    companyCodes: string[] | undefined,
    addresses: string[] | undefined,
    strongIdsByLegalIdType: UnifiedPayeeStrongLegalIdentifies | undefined,
    primaryFields?: ValidatedPayeePrimaryFields | null,
  ): ReactElement => {
    if (props.showLoading) {
      return <div />;
    }

    const { payee, organization, searchText } = props;

    const strongIdsToDisplay = {
      KnoxId: getListWithLeading(strongIdsByLegalIdType?.KnoxId, primaryFields?.legalIdentifier, equalsLEI),
      DUNS: getListWithLeading(strongIdsByLegalIdType?.DUNS, primaryFields?.legalIdentifier, equalsLEI),
      TaxId: getListWithLeading(strongIdsByLegalIdType?.TaxId, primaryFields?.legalIdentifier, equalsLEI),
    };

    const strongIds = strongIdsByLegalIdType ? Object.values(strongIdsByLegalIdType).filter(isDefined).flat() : [];
    const primaryFieldWithoutStrong = primaryFields?.legalIdentifier
      ? removeStrongIds([primaryFields.legalIdentifier], strongIds)
      : [];

    const unifiedLeis = getListWithLeading(
      unifyLeis(removeStrongIds(identifiers, strongIds)),
      primaryFieldWithoutStrong?.[0],
      equalsLEI,
    );

    const payeeExternalIds = payee?.externalReferenceIds;
    const organizationName = organization?.data?.name || 'your company';

    const displayOtherIds: boolean = !!((unifiedLeis && unifiedLeis?.length) || (payeeExternalIds && payeeExternalIds?.length));

    return (
      <>
        {Object.keys(strongIdsToDisplay).length > 0 && (
          <PropRow>
            <InfoCategory>IDs:</InfoCategory>
            <PropValueVertical>
              {renderStrongLegalIds(StrongLegalIdentifiesTypes.KnoxId, 'Knox ID', strongIdsToDisplay?.KnoxId, searchText, false)}
              {renderStrongLegalIds(StrongLegalIdentifiesTypes.DUNS, 'Duns ID', strongIdsToDisplay?.DUNS, searchText, false)}
              {renderStrongLegalIds(StrongLegalIdentifiesTypes.TaxId, 'Tax ID', strongIdsToDisplay?.TaxId, searchText, true)}
            </PropValueVertical>
          </PropRow>
        )}
        {displayOtherIds && (
          <PropRow data-testid='payee-other-ids'>
            <InfoCategory>Other IDs:</InfoCategory>
            <PropValueVertical>
              {payeeExternalIds?.map((payeeExternalId) => (
                <PropTextRow key={payeeExternalId}>
                  <TextHighlighter
                    searchText={searchText}
                  >{`${payeeExternalId} (Internal to ${organizationName})`}</TextHighlighter>
                </PropTextRow>
              ))}
              {unifiedLeis?.map((lei) => {
                return (
                  <PropTextRow key={`${lei?.value}-${lei?.typeName}-${lei?.countryCode}`}>
                    <TextHighlighter searchText={searchText}>{getFormattedLEI(lei, true)}</TextHighlighter>
                  </PropTextRow>
                );
              })}
            </PropValueVertical>
          </PropRow>
        )}
        {!!companyCodes?.length && (
          <PropRow data-testid='payee-company-codes'>
            <InfoCategory>Sub-organization{companyCodes?.length > 1 ? 's' : ''}:</InfoCategory>
            <PropValueChips>
              {companyCodes.map((x) => {
                return <StyledChip key={x}>{x}</StyledChip>;
              })}
            </PropValueChips>
          </PropRow>
        )}
        {!!addresses?.length && (
          <PropRow data-testid='payee-addresses'>
            <InfoCategory>Address{addresses?.length > 1 ? 'es' : ''}:</InfoCategory>
            <PropValueVertical>
              {getListWithLeading(addresses, primaryFields?.address).map((x) => {
                return <PropTextRow key={x}>{x}</PropTextRow>;
              })}
            </PropValueVertical>
          </PropRow>
        )}
      </>
    );
  };

  const renderStrongLegalIds = (
    type: StrongLegalIdentifiesTypes,
    typeText: string,
    strongLegalIds: UnifiedPayeeLegalEntityIdentifier[] | undefined,
    searchText?: string,
    displayCountryCode?: boolean,
  ): ReactNode => {
    const distinctLeiPairs = distinctValuesByKey(
      strongLegalIds?.map(
        (value) => [value, getFormattedLEI(value, displayCountryCode)] as [UnifiedPayeeLegalEntityIdentifier, string],
      ),
      ([, leiString]) => leiString,
    );
    return distinctLeiPairs?.map(([lei, leiString]) => (
      <PropTextRow key={leiString} data-testid={`validated-payee-modal-strong-id-${type}`}>
        <CopyableValue label={typeText} value={lei.value} dataTestId='copyable' hideCopyButton={!fullDetails}>
          <span data-testid='strong-id-text'>
            <TextHighlighter searchText={searchText}>{leiString}</TextHighlighter>
          </span>
        </CopyableValue>
      </PropTextRow>
    ));
  };

  const renderPayeeDetails = (payee: UnifiedPayee | undefined): ReactElement => {
    if (!payee || props.showLoading) {
      return <div />;
    }

    const { fullDetails, organization, verificationDetails } = props;

    const { privateIds, validatedId } = payee.ids;
    const {
      names,
      legalIdentifiers: allPayeesLegalIdentifiers,
      addresses,
      companyCodes,
      countryCode,
      strongLegalIdentifies,
      primaryFields,
    } = payee;

    const validationLegalIdentifiers = verificationDetails?.validatedPayeeLegalIdentifiers;
    const validationStrongLegalIdentifiers = verificationDetails?.strongLegalIdentifiers;
    const privateLegalIdentifiers = allPayeesLegalIdentifiers?.filter((lei) => !lei.isValidatedPayee) ?? [];
    const validatedPayeeLegalIdentifiers = validationLegalIdentifiers?.length
      ? mergeArrays([privateLegalIdentifiers, validationLegalIdentifiers])
      : allPayeesLegalIdentifiers;
    const payeeStrongLegalIdentifiers = validationStrongLegalIdentifiers || strongLegalIdentifies;
    const payeeLEIPrimaryFields: ValidatedPayeePrimaryFields = { ...primaryFields, ...verificationDetails?.primaryFields };

    const validatedNames = names?.filter((payeeName) => payeeName.isValidatedPayee).map((payeeName) => payeeName.name);
    const privateNames = names?.filter((payeeName) => !payeeName.isValidatedPayee).map((payeeName) => payeeName.name);

    const namesWithPrimary = mergeArrays(
      [
        getListWithLeading(validatedNames?.slice().sort(compare.stringsCaseInsensitive()), payeeLEIPrimaryFields?.name),
        privateNames?.sort(compare.stringsCaseInsensitive()),
      ],
      true,
    );

    const newTitle = renderTitleAndCountry(namesWithPrimary?.length ? namesWithPrimary : ['Unknown'], countryCode);
    const orgName = organization?.data.name;

    const bubbleText =
      orgName &&
      !!privateIds?.length &&
      (validatedId ? `Some of this vendor's data is internal to ${orgName}` : `This vendor is internal to ${orgName}`);

    return (
      <SectionContentContainer data-testid='payee-details-container'>
        {bubbleText && (
          <PurpleContainer>
            <PurpleDot>
              <CardContainer>
                <Card customBackgroundColor='#b243ea'>
                  <BubbleText>{bubbleText}</BubbleText>
                </Card>
              </CardContainer>
            </PurpleDot>
          </PurpleContainer>
        )}
        <SectionContentTop>
          {newTitle}
          {renderPayeeProperties(
            countryCode,
            validatedPayeeLegalIdentifiers,
            companyCodes,
            fullDetails ? addresses : undefined,
            payeeStrongLegalIdentifiers,
            payeeLEIPrimaryFields,
          )}
        </SectionContentTop>
        <SectionContentBottom>
          <SectionContentOptions>
            {fullDetails && (
              <MoreInfoAndCopyIdButton
                id='btn-more-info'
                appearance='text'
                colorScheme='primary'
                size='small'
                disabled={!hasMoreInfo()}
                onClick={openMoreInfo}
                dataTestId='btnMoreInfo'
              >
                MORE INFO
              </MoreInfoAndCopyIdButton>
            )}
          </SectionContentOptions>
        </SectionContentBottom>
      </SectionContentContainer>
    );
  };

  const renderCustomPlaceholder = (): ReactElement => {
    return (
      <SectionContentContainer>
        <SectionContentTop>
          <Title>
            <TextRow color='#CDCDCD' style={{ width: 150, margin: 0 }} />
          </Title>
          <PayeeSubTitle>
            <InfoContent>
              <TextBlock rows={props.fullDetails ? 2 : 1} lineSpacing={8} color='#CDCDCD' style={{ width: 450, marginTop: 5 }} />
            </InfoContent>
          </PayeeSubTitle>
        </SectionContentTop>
        <SectionContentBottom>
          <RectShape color='#CDCDCD' style={{ width: 56, height: 56, marginRight: 0 }} />
        </SectionContentBottom>
      </SectionContentContainer>
    );
  };

  const { fullDetails, className, showLoading, payee, title, hideTitle } = props;

  return (
    <MainContainer className={className}>
      {fullDetails && <BlockDescriptor>{hideTitle ? '' : title || 'Vendor'}</BlockDescriptor>}
      <ReactPlaceholder customPlaceholder={renderCustomPlaceholder()} ready={!showLoading}>
        {renderPayeeDetails(payee)}
      </ReactPlaceholder>
    </MainContainer>
  );
});

export default PayeeDetails;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
`;

const PurpleContainer = styled.div`
  display: flex;
  position: relative;
`;

const CardContainer = styled.div`
  display: none;
  position: absolute;
  width: 250px;
  top: 20px;
  z-index: 10;
`;

const PurpleDot = styled.div`
  display: flex;
  position: absolute;
  left: -16px;
  top: 8px;
  height: 8px;
  width: 8px;
  background-color: #b243ea;
  border-radius: 50%;

  &:hover ${CardContainer} {
    display: block;
  }
`;

const SectionContentTop = styled.div`
  flex: 1;
`;

const SectionContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const PayeeSubTitle = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

const MoreInfoAndCopyIdButton = styled(Button)`
  opacity: 0.8;
  margin-top: 8px;
  position: relative;
  top: 10px;
  left: 8px;
  color: var(--accent-blue-600);
`;

const SectionContentOptions = styled.div`
  display: flex;
`;

const PropRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: baseline;
`;

const PropTextCountryCode = styled(InfoContent)`
  flex: 0 0 auto;
`;

const StyledNationality = styled(Nationality)`
  align-self: center;
`;

const PropValueVertical = styled(InfoContent)`
  display: flex;
  flex-direction: column;
`;

const StyledChip = styled(OldChip)`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const PropValueChips = styled(InfoContent)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;

  margin-bottom: -8px;
  margin-right: -8px;
`;

const PropTextRow = styled(PropValueVertical)`
  margin-left: 0;
  margin-bottom: 8px;
  padding-top: 1px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BubbleText = styled(InfoContent)`
  color: #fff;
`;

const PropAKA = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: start;
`;

const TitleDisplay = styled(Title)`
  flex: 1;
  margin-right: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PayeeTitleContainer = styled(TitleContainer)`
  align-items: center;
`;
