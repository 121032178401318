import { flow, makeObservable, observable } from 'mobx';
import Loadable, { flowad, LoadableCreator } from '@app/utils/Loadable';
import { extractLogErrorIdFromError, HttpStatus, isWebErrorContent, RequestError } from '@app/libs/request';
import { ARConfiguration } from '@ar/domain/arGeneral';
import ARThemeStore from '@ar/stores/infraStores/ARThemeStore';
import Log from '@app/libs/logger';
import { getARConfigurationBySubdomain, transformATThemeServerResponse } from '@ar/services/arServices';

export default class CustomerConfigurationStore {
  @observable currentConfiguration: Loadable<ARConfiguration | null> = LoadableCreator.inProgress();
  @observable currentSubdomain: string | null = null;
  @observable currentOrgId: string | null = null;

  private _themeStore: ARThemeStore;

  constructor(themeStore: ARThemeStore) {
    this._themeStore = themeStore;
    makeObservable(this);
  }

  tryLoadConfigurationBySubdomain = flow(function* (this: CustomerConfigurationStore, subdomain: string | null) {
    if (subdomain === null) {
      this.currentConfiguration = LoadableCreator.resolved(null);
      return;
    }

    if (subdomain === this.currentSubdomain) {
      return;
    }

    this.currentSubdomain = subdomain;

    if (this.currentSubdomain) {
      try {
        yield this.loadConfigurationBySubdomain(this.currentSubdomain);
      } catch (e) {
        Log.event('cannot load configuration for subdomain', { subdomain, logId: extractLogErrorIdFromError(e) });
        this.currentConfiguration = LoadableCreator.resolved(null);
        return;
      }
    }
  });

  private loadConfigurationBySubdomain = async (subdomain: string): Promise<void> => {
    await flowad<ARConfiguration | null>(
      (newValue) => {
        this.currentConfiguration = newValue;
      },
      async (): Promise<ARConfiguration | null> => {
        try {
          const arThemeConfiguration = transformATThemeServerResponse(await getARConfigurationBySubdomain(subdomain));
          // todo banks poc hack
          if (['seb', 'freestyle'].includes(subdomain?.toLowerCase())) {
            arThemeConfiguration.fontFamily = 'SEBSansSerif';
            arThemeConfiguration.backgroundColor = '#EEEEEE';
          }

          //for local debug of the theme
          // const arThemeConfiguration = {
          //   clientName: 'test',
          //   headerColor: '#571e3e',
          //   actionColor: '#007AC7',
          //   logo: {
          //     mediaType: null,
          //     content: 'string',
          //   },
          //   organizationId: 'string',
          //   fontFamily: 'SEBSansSerif',
          //   backgroundColor: '#EEEEEE',
          // };

          if (arThemeConfiguration) {
            this.currentOrgId = arThemeConfiguration.organizationId;
            this._themeStore.setThemeFromServer(arThemeConfiguration);
          }

          return { ...arThemeConfiguration };
        } catch (e: unknown) {
          const requestError = e as RequestError;

          // Check if the error is "configuration not found"
          if (requestError.code === HttpStatus.notFound && isWebErrorContent(requestError.responseJSON)) {
            if (requestError.responseJSON.error === 'GUEST_CONFIGURATION_NOT_FOUND') {
              return null;
            }
          }

          throw e;
        }
      },
    )();
  };
}
