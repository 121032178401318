import React, { FC, ReactElement } from 'react';
import Button from '@app/components/Button';
import styled from '@emotion/styled';
import { DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import { convertMomentToTimestamp } from '@app/utils/timeUtils';
import { DateRange } from '@app/domain/filters';

const { RangePicker } = DatePicker;

interface Props {
  selectedKeys: DateRange[];
  setSelectedKeys: (keys: DateRange[]) => void;
  confirm: () => void;
  clearFilters: () => void;
}

const FilterColumnRangedTimeTable: FC<Props> = ({ selectedKeys, setSelectedKeys, confirm, clearFilters }): ReactElement => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onApply();
    }
  };

  const onApply = (): void => {
    if (!selectedKeys[0]) {
      clearFilters();
    } else {
      confirm();
    }
  };

  const calculateDateValuesAndSetKeys = (rangePickerValue: RangePickerValue): void => {
    const [initialStartTime, initialEndTime] = rangePickerValue ?? [];

    if (!initialStartTime && !initialEndTime) {
      setSelectedKeys([]);
      return;
    }

    const startOfDay = (initialStartTime ?? initialEndTime ?? moment()).startOf('day');
    const endOfDay = (initialEndTime ?? initialStartTime ?? moment()).endOf('day');

    setSelectedKeys([{ start: convertMomentToTimestamp(startOfDay), end: convertMomentToTimestamp(endOfDay) }]);
  };

  return (
    <PopupContent onKeyPress={handleKeyPress}>
      <Heading>
        <Title>Filter</Title>
        <Button appearance='text' id='btn-table-ranged-date-value-filter-reset' onClick={(): void => clearFilters()} size='small'>
          CLEAR
        </Button>
      </Heading>
      <RangePicker
        format='DD-MM-YYYY'
        value={selectedKeys[0] ? [moment(selectedKeys[0].start), moment(selectedKeys[0].end)] : undefined}
        onChange={(obj): void => calculateDateValuesAndSetKeys(obj)}
      />
      <Actions>
        <Button id='btn-table-ranged-date-value-filter-apply' size='small' onClick={(): void => onApply()}>
          APPLY
        </Button>
      </Actions>
    </PopupContent>
  );
};

export default FilterColumnRangedTimeTable;

const PopupContent = styled.div`
  padding: 10px 12px 10px 18px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);

  flex: 1;
`;

const Actions = styled.div`
  padding-top: 12px;

  display: flex;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
