import React, { FC } from 'react';
import Menu, { MenuItem } from '@app/components/popup/Menu';
import { isTruthy } from '@app/utils/utils';

export interface OrganizationalUserActionsPermissions {
  canSuspendUserFromOrganization: boolean;
  canKillUserSessions: boolean;
}

interface Props {
  tenantUserId: string;
  permissions: OrganizationalUserActionsPermissions;
  suspendUserFromOrganization(): void;
  killUserSessions(): void;
}

const OrganizationalUserActionsMenu: FC<Props> = ({
  tenantUserId,
  permissions,
  suspendUserFromOrganization,
  killUserSessions,
}) => {
  function calcMenuItems(): MenuItem[] {
    const menuItems: MenuItem[] = [
      permissions.canSuspendUserFromOrganization && {
        id: `btn-${tenantUserId}-suspend-from-organization`,
        dataTestId: `btn-suspend-from-organization`,
        text: 'Suspend from organization',
        onClick: (): void => suspendUserFromOrganization(),
      },
      permissions.canKillUserSessions && {
        id: `btn-${tenantUserId}-kill-sessions`,
        dataTestId: `btn-kill-sessions`,
        text: 'Kill sessions',
        onClick: (): void => killUserSessions(),
      },
    ].filter(isTruthy);

    if (menuItems.length) {
      return menuItems;
    }

    // Fallback
    return [
      {
        id: `btn-${tenantUserId}-no-actions`,
        dataTestId: `btn-no-actions`,
        text: 'No actions available',
        onClick: (): void => {},
        disabled: true,
      },
    ];
  }

  return (
    <Menu
      menuItems={calcMenuItems()}
      id={`org-users-table-single-user-${tenantUserId}-menu`}
      dataTestId='org-users-table-single-user-menu'
    />
  );
};

export default OrganizationalUserActionsMenu;
