import { nanoid } from 'nanoid';
import { GetHandlesServerResponse } from '@app/login/services/login';
import { action, computed, makeObservable, observable } from 'mobx';
import Log from '@app/libs/logger';

export const STORAGE_AUTH_KEY = 'auth';

const AMOUNT_OF_OLD_SECRETS_TO_KEEP = 5;

class KnoxersManager {
  @observable isInitialized = false;
  @observable private pendingKnoxersSecrets: KnoxersSecrets[] = [];
  @observable activeKnoxersSecrets: KnoxersSecrets;
  @observable private _lastPendingSecret: KnoxersSecrets;

  private currentPendingSecretId: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  savePendingKnoxersSecrets(knoxersSecrets: KnoxersSecrets): void {
    this.pendingKnoxersSecrets[knoxersSecrets.secretId] = knoxersSecrets;
    this._lastPendingSecret = knoxersSecrets;
    this.currentPendingSecretId++;

    this.isInitialized = true;
  }

  @action
  setActiveSecret(knoxersSecrets: KnoxersSecrets): void {
    this.activeKnoxersSecrets = knoxersSecrets;
    this.isInitialized = true;
  }

  getPendingSecretsBySecretId(secretId: string): KnoxersSecrets {
    return this.pendingKnoxersSecrets[+secretId];
  }

  getAllPendingSecrets(): KnoxersSecrets[] {
    return this.pendingKnoxersSecrets;
  }

  @computed
  get lastPendingSecret(): KnoxersSecrets {
    return this._lastPendingSecret;
  }

  @action
  createKnoxersSecretByHandles(handlesResponse: GetHandlesServerResponse): void {
    this.currentPendingSecretId = 0;
    this.pendingKnoxersSecrets = [];

    this.createNewPendingSecret(handlesResponse.handle, handlesResponse.participatingKnoxers);
  }

  refreshSecret(): void {
    if (!this.lastPendingSecret) {
      return;
    }

    const knoxersSecrets: KnoxersSecrets = this.lastPendingSecret;
    this.createNewPendingSecret(
      knoxersSecrets.handle,
      knoxersSecrets.knoxers.map((knoxerSecret) => knoxerSecret.knoxerId),
    );
  }

  createNewPendingSecret(handle: string, knoxersList: string[]): void {
    const knoxers: SingleKnoxerSecret[] = [];
    const calculatedSecretId = this.currentPendingSecretId % AMOUNT_OF_OLD_SECRETS_TO_KEEP;

    knoxersList.forEach((knoxer) => {
      knoxers.push({
        knoxerId: knoxer,
        secret: nanoid(JWT_SECRET_LENGTH),
      });
    });

    this.savePendingKnoxersSecrets({
      knoxers,
      handle,
      secretId: `${calculatedSecretId}`,
    });
  }

  save(secretId: string): void {
    // Check browser support
    if (typeof Storage === 'undefined') {
      return;
    }

    const knoxersSecrets: KnoxersSecrets = this.getPendingSecretsBySecretId(secretId);

    if (!knoxersSecrets) {
      Log.event('NoSecretInfo');
      return;
    }

    this.setActiveSecret(knoxersSecrets);

    try {
      localStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(knoxersSecrets));
    } catch (e: unknown) {
      Log.exception(e);
    }
  }

  load(): void {
    // Check browser support
    if (typeof Storage === 'undefined') {
      return;
    }

    try {
      const auth = localStorage.getItem(STORAGE_AUTH_KEY);

      if (!auth) {
        return;
      }

      this.setActiveSecret(JSON.parse(auth));
    } catch (e) {
      Log.exception(e);
    }
  }

  clearSaved(): void {
    // Check browser support
    if (typeof Storage === 'undefined') {
      return;
    }

    localStorage.removeItem(STORAGE_AUTH_KEY);
  }
}

export default new KnoxersManager();

export interface SingleKnoxerSecret {
  knoxerId: string;
  secret: string;
}

export interface KnoxersSecrets {
  handle: string;
  secretId: string;
  knoxers: SingleKnoxerSecret[];
}

const JWT_SECRET_LENGTH = 32;
