import { AuthMethodType } from '@app/login/domain/loginConsts';

export enum MultipleSSOProviderType {
  saml = 'saml',
  oidc = 'oidc',
}

export function isValidSSOProvider(value: string): value is MultipleSSOProviderType {
  return Object.values(MultipleSSOProviderType).includes(value as MultipleSSOProviderType);
}

export function multipleSSOProviderTypeToKnoxerAuthType(multipleSSOProviderType: MultipleSSOProviderType): AuthMethodType {
  switch (multipleSSOProviderType) {
    case MultipleSSOProviderType.saml:
      return AuthMethodType.SamlSSO;
    case MultipleSSOProviderType.oidc:
      return AuthMethodType.OidcSSO;
  }
}

export type AuthenticatorSSOAuthResult = 'success' | 'error';
