import React, { FC } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from '@emotion/styled';
import PayeeDetails from '@mortee/components/payeeDetails/PayeeDetails';
import PayeeAccountDetails from '@mortee/components/payeeDetails/PayeeAccountDetails';
import { BufferLine } from '@app/components/DisplayFields';
import Card from '@app/components/card/Card';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import useAppStores from '@app/hooks/useAppStores';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';

interface SelectedPayeeItemsSectionProps {
  payee: UnifiedPayee;
}

const SelectedPayeeItemsSection: FC<SelectedPayeeItemsSectionProps> = observer((props) => {
  const { userStore } = useInfraStores<MorteeMode>();
  const { payeeSearchStore } = useAppStores<MorteeMode>();

  const localStore = useLocalObservable(() => ({
    searchText: '' as string,

    setSearchText: (searchText: string): void => {
      localStore.searchText = searchText;
    },
  }));

  const { isAfterVerification, verificationDetails, accountDetails } = payeeSearchStore;
  const { selectedOrganization } = userStore;
  const { payee } = props;

  return (
    <PayeeCard>
      <PayeeDetails
        payee={payee}
        fullDetails
        organization={selectedOrganization}
        verificationDetails={verificationDetails ?? undefined}
      />
      {isAfterVerification && accountDetails && (
        <>
          <BufferLine />
          <PayeeAccountDetails verificationDetails={verificationDetails} accountDetails={accountDetails} />
        </>
      )}
    </PayeeCard>
  );
});

export default SelectedPayeeItemsSection;

const PayeeCard = styled(Card)`
  padding: 20px 32px;
  flex-direction: column;
  position: relative;
`;
