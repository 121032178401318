import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { css as classNameCreator } from '@emotion/css';

interface TextRenderingOptions {
  css: SerializedStyles;
  className: string;
  div: ReturnType<typeof styled.div>;
  span: ReturnType<typeof styled.span>;
}

const convertCssToTextRenderingOptions = (css: SerializedStyles): TextRenderingOptions => {
  return {
    css,
    className: classNameCreator`${css}`,
    div: styled.div`
      ${css}
    `,
    span: styled.span`
      ${css}
    `,
  };
};

// export const H1StartTransparentBlack600 = convertCssToTextRenderingOptions(css`
//   font-size: 96px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-600);
// `);
// export const H1StartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-size: 96px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
// export const H2StartTransparentBlack900 = convertCssToTextRenderingOptions(css`
//   font-size: 60px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-900);
// `);
// export const H2StartTransparentBlack600 = convertCssToTextRenderingOptions(css`
//   font-size: 60px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-600);
// `);
// export const H2StartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-size: 60px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
// export const H3StartTransparentBlack900 = convertCssToTextRenderingOptions(css`
//   font-size: 48px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-900);
// `);
// export const H3StartTransparentBlack600 = convertCssToTextRenderingOptions(css`
//   font-size: 48px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-600);
// `);
// export const H3StartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-size: 48px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
export const Bold42White = convertCssToTextRenderingOptions(css`
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
`);
export const Bold38TitleTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const H4StartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const H4StartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
// export const H4StartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-size: 32px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
export const Regular28TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
// export const H5StartWhite1000 = convertCssToTextRenderingOptions(css`
//   font-size: 24px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--white);
// `);
// export const H5StartPrimaryBlue300 = convertCssToTextRenderingOptions(css`
//   font-size: 24px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--primary-300);
// `);
export const H5StartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
// export const H5StartTransparentBlack600 = convertCssToTextRenderingOptions(css`
//   font-size: 24px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-600);
// `);
export const Regular24TransparentBlack400 = convertCssToTextRenderingOptions(css`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-400);
`);
export const H5StartError600 = convertCssToTextRenderingOptions(css`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--error-600);
`);
// export const H6Center100 = convertCssToTextRenderingOptions(css`
//   font-size: 21px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.52;
//   letter-spacing: 0.29px;
//   text-align: center;
//   color: var(--transparent-black-900);
// `);
export const Regular22TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Regular22TransparentBlack400 = convertCssToTextRenderingOptions(css`
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-400);
`);
export const H6StartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const H6StartTransparentBlack800 = convertCssToTextRenderingOptions(css`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-800);
`);
// export const H6StartTransparentBlack600 = convertCssToTextRenderingOptions(css`
//   font-size: 20px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   color: var(--transparent-black-600);
// `);
// export const H6StartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-size: 20px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
export const H6StartWhite = convertCssToTextRenderingOptions(css`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white);
`);
export const Bold18TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
// export const H6Center900900 = convertCssToTextRenderingOptions(css`
//   font-size: 20px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: center;
//   color: var(--transparent-black-900);
// `);
export const Regular18TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Bold16Primary300 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-300);
`);
export const SubtitleRegularStartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const SubtitleRegularStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
// export const SubtitleRegularStartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 16px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.25;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
export const BodyRegularStartWhite1000 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white);
`);

export const BodyRegularStartPrimary200 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--primary-200);
`);
// export const BodyRegularStartPrimary300 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 16px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   color: var(--primary-300);
// `);
export const BodyRegularStartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const BodyRegularStartTransparentBlack900Important = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family) !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: var(--transparent-black-900) !important;
`);
export const BodyRegularStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
export const BodyRegularStartTransparentBlack400 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-400);
`);
export const BodyRegularStartAccentBlue500 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--accent-blue-600);
`);
export const BodyRegularStartPrimary500 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--primary-500);
`);
// export const CaptionStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 16px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: center;
//   color: var(--transparent-black-600);
// `);
export const Bold15TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Regular15TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const BodyRegular15TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Regular15TransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
// export const SubtitleSmallStartWhite1000 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--white);
// `);
export const SubtitleSmallStartPrimary300 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-300);
`);
export const SubtitleSmallStartPrimary500 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-500);
`);
export const SubtitleSmallStartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const SubtitleSmallStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
export const SubtitleSmallStartTransparentBlack400 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-400);
`);
export const SubtitleSmallStartTransparentGrey800 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-gray-800);
`);
export const Regular14TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Regular14TransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
export const Regular14White = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
`);
export const Bold13TransparentGrey800 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-gray-800);
`);
export const SubtitleSmallStartError600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--error-600);
`);
export const SubtitleSmallStartAccentBlue500 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--accent-blue-600);
`);
// export const ButtonRegularCenterWhite1000 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   text-align: center;
//   color: var(--white);
// `);
// export const BodySmallStartWhite1000 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.43;
//   letter-spacing: normal;
//   color: var(--white);
// `);
export const BodySmallStartPrimary300 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-300);
`);
export const BodySmallStartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const BodySmallStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
// export const BodySmallStartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.43;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
// export const BodySmallStartAccentBlue500 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.43;
//   letter-spacing: normal;
//   color: var(--accent-blue-600);
// `);
export const Bold13TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Regular13TransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const Regular13TransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
export const Regular13TransparentBlack400 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--transparent-black-400);
`);
// export const ButtonSmallCenterWhite = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 12px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   text-align: center;
//   color: var(--white);
// `);
export const ButtonSmallCenterDisable = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--transparent-black-400);
`);
export const ButtonSmallCenterAccent = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--accent-blue-600);
`);
export const Regular12White = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--white);
`);
export const CaptionStartTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
export const CaptionStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
export const CaptionStartTransparentBlack400 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--transparent-black-400);
`);
// export const CaptionStartTransparentBlack200 = convertCssToTextRenderingOptions(css`
//   font-family: var(--text-font-family);
//   font-size: 12px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.33;
//   letter-spacing: normal;
//   color: var(--transparent-black-200);
// `);
export const CaptionStartError600 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--error-600);
`);
export const CaptionStartAccentBlue500 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--accent-blue-600);
`);
export const CaptionStartPrimary200 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--primary-200);
`);
export const CaptionStartSlateGray700 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--slate-gray-700);
`);
export const CaptionStartColorless = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`);
// export const OverlineStartTransparentBlack900 = convertCssToTextRenderingOptions(css`
//   font-size: 10px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-900);
// `);
export const OverlineStartTransparentBlack600 = convertCssToTextRenderingOptions(css`
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-600);
`);
// export const OverlineStartTransparentBlack400 = convertCssToTextRenderingOptions(css`
//   font-size: 10px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--transparent-black-400);
// `);
export const SmallTextSlateGray300 = convertCssToTextRenderingOptions(css`
  font-family: var(--text-font-family);
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-gray-300);
`);
export const SmallTitleTransparentBlack900 = convertCssToTextRenderingOptions(css`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);
`);
