import {
  Assignee,
  AssigneeServerResponse,
  CancelReason,
  CancelReasonServerResponse,
  CommentTypes,
  PaymentCompatabilityCheckAttribute,
  SupplierValidationRecordInstructionType,
  SupplierValidationRecordStatus,
  SVMasterFileAutomaticEmailStatus,
  SVMasterFileAutomaticEmailStopReason,
  transformAssignee,
} from '@mortee/domain/validationSystem';
import Log from '@app/libs/logger';
import { isDefined } from '@app/utils/utils';
import { AlertStatus } from '@mortee/domain/validationSystemAlerts';

export enum SupplierValidationTimelineEventType {
  svLinked = 'SvLinked',
  svUnlinked = 'SvUnlinked', //no data
  svLinkUpdate = 'SvLinkUpdate',
  statusUpdate = 'StatusUpdate',
  assigneeUpdate = 'AssigneeUpdate',
  isCustomerInvolvementUpdate = 'IsCustomerInvolvementUpdate',
  autoMailerEmailCountUpdate = 'AutoMailerEmailCountUpdate',
  autoMailerStatusUpdate = 'AutoMailerStatusUpdate',
  autoMailerStopEmailUpdate = 'AutoMailerStopEmailUpdate',
  create = 'Create', //no data
  comment = 'Comment',
  successfulDistribution = 'SuccessfulDistribution',
  compatibilityFailedDistribution = 'CompatibilityFailedDistribution',
  cancelReasonUpdate = 'CancelReasonUpdate',
  manualInstructionTypeUpdate = 'ManualInstructionTypeUpdate',
  evidenceLink = 'EvidenceLink',
  evidenceDelete = 'EvidenceDelete',
  alertOpen = 'AlertOpen',
  alertResolve = 'AlertResolve',
}

export enum DistributionType {
  onlyMarkedAsDistributed = 'OnlyMarkedAsDistributed',
  distributed = 'Distributed',
}

export enum DistributionTrigger {
  onLink = 'OnLink',
  scheduled = 'Scheduled',
  userRequest = 'UserRequest',
  manualInstructionTypeUpdate = 'ManualInstructionTypeUpdate',
}

export interface ActivityLogServerResponse {
  events: ActivityLogEventServerResponse[];
}

interface ActivityLogBaseEventServerResponse<TType extends SupplierValidationTimelineEventType, TData> {
  type: TType;
  metadata: BaseActivityLogEventMetaDataServerResponse;
  data: TData;
}

export type ActivityLogEventServerResponse =
  | SvLinkedEventServerResponse
  | SvUnlinkedEventServerResponse
  | SvLinkUpdateEventServerResponse
  | StatusUpdateEventServerResponse
  | AssigneeUpdateEventServerResponse
  | IsCustomerInvolvementUpdateEventServerResponse
  | AutoMailerEmailCountUpdateEventServerResponse
  | AutoMailerStatusUpdateEventServerResponse
  | AutoMailerStopEmailUpdateEventServerResponse
  | CreateEventServerResponse
  | CommentEventServerResponse
  | SuccessfulDistributionEventServerResponse
  | CompatibilityFailedDistributionEventServerResponse
  | CancelReasonUpdateUpdateServerResponse
  | ManualInstructionTypeUpdateServerResponse
  | EvidenceLinkEventServerResponse
  | EvidenceDeleteEventServerResponse
  | AlertOpenEventServerResponse
  | AlertResolveEventServerResponse;

export interface BaseActivityLogEventMetaDataServerResponse {
  performingUserId: string;
  performingUserName: string;
  timestamp: number;
}

export type SvLinkedEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.svLinked,
  {
    svRegistrationId: string;
    svRegistrationNumber: string;
    registrationTimestamp: number;
    supplierName: string;
  }
>;

export type SvUnlinkedEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.svUnlinked,
  null
>;

export type SvLinkUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.svLinkUpdate,
  {
    svRegistrationId: string;
    svRegistrationNumber: string;
    registrationTimestamp: string;
    supplierName: string;
  }
>;

export type StatusUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.statusUpdate,
  {
    status: SupplierValidationRecordStatus;
  }
>;

export type AssigneeUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.assigneeUpdate,
  {
    assignee: AssigneeServerResponse | null | undefined;
  }
>;

export type IsCustomerInvolvementUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.isCustomerInvolvementUpdate,
  {
    isCustomerInvolvementRequired: boolean;
  }
>;

export type AutoMailerEmailCountUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.autoMailerEmailCountUpdate,
  {
    emailCount: number;
  }
>;

export type AutoMailerStatusUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.autoMailerStatusUpdate,
  {
    emailStatus: SVMasterFileAutomaticEmailStatus;
    emailMessage: SVMasterFileAutomaticEmailStopReason | null | undefined;
  }
>;

export type AutoMailerStopEmailUpdateEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.autoMailerStopEmailUpdate,
  {
    stopEmails: boolean;
  }
>;

export type CreateEventServerResponse = ActivityLogBaseEventServerResponse<SupplierValidationTimelineEventType.create, null>;

export type EvidenceLinkEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.evidenceLink,
  null
>;

export type EvidenceDeleteEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.evidenceDelete,
  null
>;

export type CommentEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.comment,
  {
    id: string;
    comment: string;
    commentType: CommentTypes;
  }
>;

export type SuccessfulDistributionEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.successfulDistribution,
  {
    distributionType: DistributionType;
    distributionTrigger: DistributionTrigger;
  }
>;

export type CompatibilityFailedDistributionEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.compatibilityFailedDistribution,
  {
    distributionType: DistributionType;
    distributionTrigger: DistributionTrigger;
    failureReasons: PaymentCompatabilityCheckAttribute[];
  }
>;

export type CancelReasonUpdateUpdateServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.cancelReasonUpdate,
  CancelReasonServerResponse | null | undefined
>;

export type ManualInstructionTypeUpdateServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.manualInstructionTypeUpdate,
  { manualInstructionType: SupplierValidationRecordInstructionType | null | undefined }
>;

export type AlertOpenEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.alertOpen,
  {
    alertId: string;
    alertText: string;
    status: AlertStatus;
  }
>;

export type AlertResolveEventServerResponse = ActivityLogBaseEventServerResponse<
  SupplierValidationTimelineEventType.alertResolve,
  {
    alertId: string;
    alertText: string;
    status: AlertStatus;
  }
>;

export interface ActivityLog {
  events: ActivityLogEvent[];
}

interface ActivityLogBaseEvent<TType extends SupplierValidationTimelineEventType, TData> {
  type: TType;
  metadata: BaseActivityLogEventMetaData;
  data: TData;
}

export type ActivityLogEvent =
  | SvLinkedEvent
  | SvUnlinkedEvent
  | SvLinkUpdateEvent
  | StatusUpdateEvent
  | AssigneeUpdateEvent
  | IsCustomerInvolvementUpdateEvent
  | AutoMailerEmailCountUpdateEvent
  | AutoMailerStatusUpdateEvent
  | AutoMailerStopEmailUpdateEvent
  | CreateEvent
  | CommentEvent
  | SuccessfulDistributionEvent
  | CompatibilityFailedDistributionEvent
  | CancelReasonUpdateUpdateEvent
  | ManualInstructionTypeUpdateEvent
  | EvidenceLinkEvent
  | EvidenceDeleteEvent
  | AlertOpenEvent
  | AlertResolveEvent;

export interface BaseActivityLogEventMetaData {
  performingUserId: string;
  performingUserName: string;
  timestamp: number;
}

export type SvLinkedEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.svLinked,
  {
    svRegistrationId: string;
    svRegistrationNumber: string;
    registrationTimestamp: number;
    supplierName: string;
  }
>;

export type SvUnlinkedEvent = ActivityLogBaseEvent<SupplierValidationTimelineEventType.svUnlinked, null>;

export type SvLinkUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.svLinkUpdate,
  {
    svRegistrationId: string;
    svRegistrationNumber: string;
    registrationTimestamp: string;
    supplierName: string;
  }
>;

export type StatusUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.statusUpdate,
  {
    status: SupplierValidationRecordStatus;
  }
>;

export type AssigneeUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.assigneeUpdate,
  {
    assignee: Assignee | null;
  }
>;

export type IsCustomerInvolvementUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.isCustomerInvolvementUpdate,
  {
    isCustomerInvolvementRequired: boolean;
  }
>;

export type AutoMailerEmailCountUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.autoMailerEmailCountUpdate,
  {
    emailCount: number;
  }
>;

export type AutoMailerStatusUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.autoMailerStatusUpdate,
  {
    emailStatus: SVMasterFileAutomaticEmailStatus;
    emailMessage: SVMasterFileAutomaticEmailStopReason | null;
  }
>;

export type AutoMailerStopEmailUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.autoMailerStopEmailUpdate,
  {
    stopEmails: boolean;
  }
>;

export type CancelReasonUpdateUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.cancelReasonUpdate,
  CancelReason | null
>;

export type ManualInstructionTypeUpdateEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.manualInstructionTypeUpdate,
  { manualInstructionType: SupplierValidationRecordInstructionType | null }
>;

export type CreateEvent = ActivityLogBaseEvent<SupplierValidationTimelineEventType.create, null>;

export type EvidenceLinkEvent = ActivityLogBaseEvent<SupplierValidationTimelineEventType.evidenceLink, null>;

export type EvidenceDeleteEvent = ActivityLogBaseEvent<SupplierValidationTimelineEventType.evidenceDelete, null>;

export type CommentEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.comment,
  {
    comment: {
      id: string;
      content: string;
      commentType: CommentTypes;
    };
  }
>;

export type SuccessfulDistributionEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.successfulDistribution,
  {
    distributionType: DistributionType;
    distributionTrigger: DistributionTrigger;
  }
>;

export type CompatibilityFailedDistributionEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.compatibilityFailedDistribution,
  {
    distributionType: DistributionType;
    distributionTrigger: DistributionTrigger;
    failureReasons: PaymentCompatabilityCheckAttribute[];
  }
>;

export type AlertOpenEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.alertOpen,
  {
    alertId: string;
    alertText: string;
    status: AlertStatus;
  }
>;

export type AlertResolveEvent = ActivityLogBaseEvent<
  SupplierValidationTimelineEventType.alertResolve,
  {
    alertId: string;
    alertText: string;
    status: AlertStatus;
  }
>;

export function transformActivityLog(serverResponse: ActivityLogServerResponse, myUserId: string | undefined): ActivityLog {
  return {
    events: serverResponse.events.map((event) => transformActivityLogEvent(event, myUserId)).filter(isDefined),
  };
}

function transformActivityLogEvent(
  serverResponse: ActivityLogEventServerResponse,
  myUserId: string | undefined,
): ActivityLogEvent | null {
  switch (serverResponse.type) {
    case SupplierValidationTimelineEventType.svLinked:
      return serverResponse;
    case SupplierValidationTimelineEventType.svUnlinked:
      return serverResponse;
    case SupplierValidationTimelineEventType.svLinkUpdate:
      return serverResponse;
    case SupplierValidationTimelineEventType.statusUpdate:
      return serverResponse;
    case SupplierValidationTimelineEventType.assigneeUpdate: {
      const assigneeUpdateEvent: AssigneeUpdateEvent = {
        ...serverResponse,
        data: {
          assignee: serverResponse.data.assignee ? transformAssignee(serverResponse.data.assignee, myUserId) : null,
        },
      };
      return assigneeUpdateEvent;
    }
    case SupplierValidationTimelineEventType.isCustomerInvolvementUpdate:
      return serverResponse;
    case SupplierValidationTimelineEventType.autoMailerEmailCountUpdate:
      return serverResponse;
    case SupplierValidationTimelineEventType.autoMailerStatusUpdate: {
      const autoMailerStatusUpdate: AutoMailerStatusUpdateEvent = {
        ...serverResponse,
        data: {
          ...serverResponse.data,
          emailMessage: serverResponse.data.emailMessage ?? null,
        },
      };
      return autoMailerStatusUpdate;
    }
    case SupplierValidationTimelineEventType.autoMailerStopEmailUpdate:
      return serverResponse;
    case SupplierValidationTimelineEventType.create:
      return serverResponse;
    case SupplierValidationTimelineEventType.comment: {
      return {
        ...serverResponse,
        data: {
          comment: {
            id: serverResponse.data.id,
            content: serverResponse.data.comment,
            commentType: serverResponse.data.commentType,
          },
        },
      };
    }
    case SupplierValidationTimelineEventType.successfulDistribution: {
      return serverResponse;
    }
    case SupplierValidationTimelineEventType.compatibilityFailedDistribution: {
      return serverResponse;
    }
    case SupplierValidationTimelineEventType.cancelReasonUpdate: {
      return {
        ...serverResponse,
        data: serverResponse.data ?? null,
      };
    }
    case SupplierValidationTimelineEventType.manualInstructionTypeUpdate: {
      return {
        ...serverResponse,
        data: {
          ...serverResponse.data,
          manualInstructionType: serverResponse.data.manualInstructionType ?? null,
        },
      };
    }
    case SupplierValidationTimelineEventType.evidenceLink:
      return serverResponse;
    case SupplierValidationTimelineEventType.evidenceDelete:
      return serverResponse;
    case SupplierValidationTimelineEventType.alertOpen:
      return serverResponse;
    case SupplierValidationTimelineEventType.alertResolve:
      return serverResponse;
    default: {
      Log.exception(new Error(`missing event type: ${serverResponse}`));
      return null;
    }
  }
}

export function isEventAComment(event: ActivityLogEvent): event is CommentEvent {
  return event.type === SupplierValidationTimelineEventType.comment;
}
