import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import BatchesTable from '@mortee/routes/paymentCheck/batchesListPage/BatchesTable';
import browserHistory from '@app/utils/browserHistory';
import addVerificationIcon from '@mortee/images/check/homePage/add-verification.svg';
import SVG from '@app/components/SVG';
import CheckEmptyResults from '@mortee/components/check/CheckEmptyResults';
import Button from '@app/components/Button';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import useMountEffect from '@app/hooks/useMountEffect';

interface BatchesListPageProps {}

const BatchesListPage: FC<BatchesListPageProps> = observer(() => {
  const { navigationStore, permissionsStore } = useInfraStores<MorteeMode>();
  const { paymentCheckStore } = useAppStores<MorteeMode>();

  useMountEffect(() => {
    paymentCheckStore.resetBatches();
    paymentCheckStore.loadCurrentPageBatches();
  });

  const handleNewVerification = (): void => {
    browserHistory.push(navigationStore.generateLoadPaymentBatchLink());
  };

  const renderCardEmptyContent = (): ReactElement => {
    const buttonText = permissionsStore.isPaymentFileOnPaymentCheckEnable ? 'START YOUR FIRST VERIFICATION' : undefined;

    return (
      <CheckEmptyResults
        handleNewVerification={handleNewVerification}
        titleText='No verifications yet...'
        subTitleText='Here you will see the results of the verifications'
        buttonText={buttonText}
      />
    );
  };

  const headerSideContent = permissionsStore.isPaymentFileOnPaymentCheckEnable && (
    <PageHeaderCenteredSideContent>
      <NewVerificationButton id='btn-payment-verification-new' onClick={handleNewVerification} appearance='text'>
        <StyledVerificationSvg accessibilityLabel='' image={addVerificationIcon} />
        <NewVerificationText>NEW VERIFICATION</NewVerificationText>
      </NewVerificationButton>
    </PageHeaderCenteredSideContent>
  );

  return (
    <PageWithHeader width='full' header={<PageHeader sideContent={headerSideContent} />}>
      <StyledPageContainer>
        <BatchesTable emptyComponent={renderCardEmptyContent()} />
      </StyledPageContainer>
    </PageWithHeader>
  );
});

export default BatchesListPage;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const NewVerificationButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledVerificationSvg = styled(SVG)`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const NewVerificationText = styled.div`
  margin-top: 2px;
`;
