import React from 'react';
import { InstructionFormDetails } from '../../commonAfterFormPage/InstructionFormDetails';
import { NormalText } from '../../Styles';

export const CanadaPayoneerPingPongFormDetails: InstructionFormDetails = {
  instructionsSubTitle: 'supplierValidation.afterRegister.canadaPayoneerPingPong.nowYouAreRegistered',
  paymentInstructionsTitle: 'supplierValidation.afterRegister.paymentInst',
  paymentInstructionsLines: [
    {
      description: 'supplierValidation.afterRegister.beneficiaryName',
      value: 'nsKnox Technologies',
    },
    { description: 'supplierValidation.afterRegister.checkingAccount', value: '96165874064' },
    { description: 'supplierValidation.afterRegister.institutionNumber', value: '0352' },
    { description: 'supplierValidation.afterRegister.transitNumber', value: '20009' },
    { description: 'supplierValidation.afterRegister.accountType', value: 'CHECKING' },
  ],
  paymentInstructionsAddress: {
    description: 'supplierValidation.afterRegister.bankAndAddress',
    value: (
      <NormalText>
        Digital Commerce Bank,
        <br />
        736 Meridian Road NE Calgary,
        <br />
        Alberta T2A 2N7, Canada
      </NormalText>
    ),
  },
  currencies: [],
  pleaseNote: {
    pleaseNoteTitle: 'supplierValidation.afterRegister.pleaseNote',
    pleaseNoteItem: [
      'supplierValidation.afterRegister.canadaPayoneerPingPong.pleaseNote',
      'supplierValidation.afterRegister.transferMustBe',
    ],
  },
  showWireTransferWarningBox: {
    achWireTransfer: false,
    swiftWireTransfer: true,
  },
  extraInstructions: [
    'supplierValidation.afterRegister.canadaPayoneerPingPong.step1',
    'supplierValidation.afterRegister.canadaPayoneerPingPong.step2',
  ],
};
