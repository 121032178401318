import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ClickableChip } from '@app/components/Chip';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import { FormInputProps } from '@app/utils/form/form';
import CheckOutlineIcon from '@app/images/check-outline.svg';
import SVG from '@app/components/SVG';
import { ColorScheme, colorSchemePresets, getColorScheme } from '@app/domain/theme';
import { useTranslation } from 'react-i18next';

export interface MultiChipOption<T> {
  value: T;
  text: ReactNode;
  disabled?: boolean;
  id: string;
  dataTestId?: string;
  selectedColorScheme?: ColorScheme;
}

interface Props<T extends NonNullable<string>>
  extends FormInputProps<T[], false>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  id: string;
  dataTestId?: string;
  options: MultiChipOption<T>[];
  disabled?: boolean;
  chipClassName?: string;
}

const MultiChipSelector = React.forwardRef(<T extends NonNullable<string>>(props: PropsWithChildren<Props<T>>, ref) => {
  const { options, value: selected, onChange, disabled, accessibilityLabel, chipClassName, ...rest } = props;

  const { t } = useTranslation();

  function onChipClicked(clickedItemKey: T): void {
    if (selected?.includes(clickedItemKey)) {
      onChange?.(arrayWithoutValue(selected, clickedItemKey));
    } else {
      onChange?.([...(selected ?? []), clickedItemKey]);
    }
  }

  return (
    <Container ref={ref} aria-label={accessibilityLabel} {...rest}>
      {options.map(
        (option): ReactElement => {
          const { id, dataTestId = id, value, text, selectedColorScheme, disabled } = option;
          const isSelected = selected?.includes(value);
          const colorScheme = getColorScheme(selectedColorScheme, colorSchemePresets.secondary);
          return (
            <StyledChip
              key={id}
              id={id}
              data-testid={dataTestId}
              appearance='outline'
              disabled={disabled}
              colorScheme={{
                ...colorScheme,
                main: isSelected ? colorScheme.main : '#666666',
              }}
              onClick={(): void => onChipClicked(value)}
              className={chipClassName}
            >
              <TextValue>{text}</TextValue>
              {isSelected && <CheckSVG accessibilityLabel={t('general.accessibility.checked')} image={CheckOutlineIcon} />}
            </StyledChip>
          );
        },
      )}
    </Container>
  );
});

export default MultiChipSelector;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledChip = styled(ClickableChip)`
  display: grid;
  grid-template-areas: 'text check';
  grid-template-columns: auto 16px;

  gap: 5px;
  padding: 0.15em 0.5em;
`;

const TextValue = styled.div`
  grid-area: text;
  padding-inline-start: 5px;
`;

const CheckSVG = styled(SVG)`
  grid-area: check;

  width: 16px;
  height: 16px;
`;
