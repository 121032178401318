import { DEFAULT_EQUALS_FUNCTION } from '@app/utils/utils';

function wrapAsDistinctValuesArray<T>(items: Set<T> | T[]): T[] {
  return Array.isArray(items) ? items : [...items];
}

export function setsEqual<T>(
  items1: Set<T> | T[] | null | undefined,
  items2: Set<T> | T[] | null | undefined,
  customEquality: Equator<T> = DEFAULT_EQUALS_FUNCTION,
): boolean {
  if (!items1 && !items2) {
    return true;
  }

  if (!items1 || !items2) {
    return false;
  }

  const distinctArr1 = wrapAsDistinctValuesArray(items1);
  const distinctArr2 = wrapAsDistinctValuesArray(items2);

  if (distinctArr1 === distinctArr2) return true;
  if (!distinctArr1 || !distinctArr2) return false;
  if (distinctArr1.length !== distinctArr2.length) return false;

  return distinctArr1.every((valueOfFirstArray) =>
    distinctArr2.some((valueOfSecondArray) => customEquality(valueOfFirstArray, valueOfSecondArray)),
  );
}
