import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { SubtitleSmallStartTransparentGrey800 } from '../Text';

interface CardHeaderProps {
  sideContent?: ReactNode;
  className?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ className, children, sideContent }) => {
  return (
    <Header className={className}>
      <MainText>{children}</MainText>
      {sideContent}
    </Header>
  );
};

export default CardHeader;

const Header = styled(SubtitleSmallStartTransparentGrey800.div)`
  display: flex;
`;

const MainText = styled.div`
  flex: 0 0 auto;
  padding: var(--card-vertical-padding, 20px) var(--card-side-padding, 20px);
`;

export const RightAlignedCardHeaderSideContent = styled.div`
  flex: 1;
  align-self: stretch;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LeftAlignedCardHeaderSideContent = styled.div`
  flex: 1;
  align-self: stretch;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
