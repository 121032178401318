import { SignJWT } from 'jose';
import KnoxersManager, { KnoxersSecrets } from './knoxersManager';
import Log from '@app/libs/logger';
import { RequestOptions } from '@app/libs/request';
import UserStore from '@app/stores/UserStore';
import GuestLoginStore from '@app/guestLogin/stores/GuestLoginStore';
import config from '@app/config';
import CustomerConfigurationStore from '@ar/stores/infraStores/CustomerConfigurationStore';

export const AUTH_HEADER_NAME = 'Authorization-JWT';
export const ORGANIZATION_HEADER_NAME = 'x-organization-id';
const AUTH_GUEST_HEADER = 'Authorization-Guest';

enum GuestAuthHeaderType {
  phone = 'Phone-KeycloakOTP',
  email = 'Email-KeycloakOTP',
  sso = 'Email-GoogleSSO',
}

interface GuestHeaders {
  [ORGANIZATION_HEADER_NAME]?: string;
  [AUTH_HEADER_NAME]?: string;
}

export async function guestHeaderGenerator(
  guestLoginStore: GuestLoginStore,
  customerConfigurationStore: CustomerConfigurationStore,
): Promise<Record<string, string> | undefined> {
  const { isLoggedIn, guestLoginCredentials } = guestLoginStore;
  const { currentOrgId } = customerConfigurationStore;

  const headers: GuestHeaders = {};

  if (currentOrgId) {
    headers[ORGANIZATION_HEADER_NAME] = currentOrgId;
  }

  if (isLoggedIn && guestLoginCredentials) {
    const { emailToken, phoneToken, ssoToken } = guestLoginCredentials;

    const authHeaders: string[] = [];

    if (phoneToken && config.shouldLoginWithPhone) {
      authHeaders.push(`${GuestAuthHeaderType.phone}:${phoneToken}`);
    }

    if (emailToken) {
      authHeaders.push(`${GuestAuthHeaderType.email}:${emailToken}`);
    }

    if (ssoToken) {
      authHeaders.push(`${GuestAuthHeaderType.sso}:${ssoToken}`);
    }

    headers[AUTH_GUEST_HEADER] = authHeaders.join(',');
  }

  if (Object.keys(headers).length) {
    return Promise.resolve({ ...headers });
  }

  return;
}

async function getOrganizationHeaderForRequest(userStore: UserStore): Promise<Record<string, string> | undefined> {
  const organizationId = userStore?.selectedOrganization?.id;

  if (!organizationId) {
    Log.event('generateDynamicHeaders: missing organization or organization id');
    return;
  }

  return {
    [ORGANIZATION_HEADER_NAME]: organizationId,
  };
}

async function getUserAuthHeaders(req: RequestOptions): Promise<Record<string, string> | undefined> {
  let currentSecret: KnoxersSecrets = KnoxersManager.activeKnoxersSecrets;

  // In case of first login request
  if (req.secretId) {
    currentSecret = KnoxersManager.getPendingSecretsBySecretId(req.secretId);
  }

  if (!currentSecret) {
    Log.event('Missing secret to check');
    return;
  }

  const jwts: string[] = await Promise.all(
    currentSecret.knoxers.map(async (knoxer) => {
      const jwt = await new SignJWT({})
        .setProtectedHeader({ alg: 'HS256', kid: currentSecret.handle, typ: 'JWT' })
        .setExpirationTime(`${(req.timeout ?? 30000) / 1000}s`)
        .setIssuedAt(new Date())
        .sign(new TextEncoder().encode(btoa(knoxer.secret)));

      return `${knoxer.knoxerId}:${jwt}`;
    }),
  );

  const headerValue = jwts.join(',');

  return {
    [AUTH_HEADER_NAME]: headerValue,
  };
}

export async function userIdentityHeadersGenerator(req: RequestOptions, userStore: UserStore): Promise<Record<string, string>> {
  return {
    ...(await getUserAuthHeaders(req)),
    ...(await getOrganizationHeaderForRequest(userStore)),
  };
}
