import { NextPageLoader, Page } from './PaginationManager';

export default class ExistingIdsListNextPageLoader<T, TId> implements NextPageLoader<T> {
  private readonly _filteredItemsIds: TId[];
  private readonly _itemsLoader: (ids: TId[]) => Promise<T[]>;
  private readonly _pageSize: number;
  private _lastCheckItemIdIndex: number = -1;

  constructor(
    itemsIds: TId[],
    pageSize: number,
    filterer: ((item: TId) => boolean) | null,
    itemsLoader: (ids: TId[]) => Promise<T[]>,
  ) {
    this._pageSize = pageSize;
    this._itemsLoader = itemsLoader;
    this._filteredItemsIds = filterer ? itemsIds.filter(filterer) : itemsIds;
  }

  get totalNumberOfItems(): number {
    return this._filteredItemsIds.length;
  }

  loadNextPage = async (): Promise<Page<T>> => {
    const newLastCheckIndex = this._lastCheckItemIdIndex + this._pageSize;

    const newPageItemsIds: TId[] = this._filteredItemsIds.slice(this._lastCheckItemIdIndex + 1, newLastCheckIndex + 1);

    const newPageItems = newPageItemsIds.length ? await this._itemsLoader(newPageItemsIds) : [];

    this._lastCheckItemIdIndex = newLastCheckIndex;

    return {
      items: newPageItems,
      hasMore: this._lastCheckItemIdIndex < this._filteredItemsIds.length - 1,
    };
  };
}
