import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useMountEffect from '@app/hooks/useMountEffect';
import Loader from '@app/components/Loader';
import SSOError from '@app/login/routes/sso/SSOError';
import { MultipleSSOProviderType } from '@app/login/domain/multipleSSO';

interface Props {
  oidcProviderName: string | null;
  knoxerId: string;
}

const OidcAuthentication: FC<Props> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const { knoxerId, oidcProviderName } = props;

  useMountEffect(() => {
    if (oidcProviderName != null) {
      authenticationStore.redirectToPageForSsoOIDCAuthentication(knoxerId, oidcProviderName);
    }
  });

  if (oidcProviderName == null) {
    return <SSOError sso={MultipleSSOProviderType.oidc} />;
  }

  return <Loader spinning fullScreen />;
});

export default OidcAuthentication;
