import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { ColumnProps } from 'antd/lib/table';
import Status from '@mortee/components/Status';
import DownloadFilesSvg from '@mortee/components/DownloadFilesSvg';
import { LoadingState, PAYMENT_BATCH_PAGE_SIZE } from '@mortee/stores/appStores/PaymentCheckStore';
import PaymentCheckBatchModel from '@mortee/models/PaymentCheckBatchModel';
import browserHistory from '@app/utils/browserHistory';
import { showInfoModal } from '@app/components/Modal';
import ModalFileList from '@app/components/fileUpload/ModalFileList';
import MorteeMode from '@mortee/morteeMode';
import Table, { TableFormattedDate, TableSmallText } from '@app/components/tables/Table';
import useInfraStores from '@app/hooks/useInfraStores';
import { PaymentCheckBatchStatus } from '@mortee/domain/paymentsConsts';
import { formattedDateOptionsWithHoursAndMinutes } from '@app/utils/timeUtils';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import useAppStores from '@app/hooks/useAppStores';
import PaginationWithIndex from '@app/components/PaginationWithIndex';

interface BatchesTableProps {
  emptyComponent: ReactElement;
}

const isProgressDone = (batch: PaymentCheckBatchModel): boolean => {
  return batch.status != PaymentCheckBatchStatus.batchInProgress;
};

const BatchesTable: FunctionComponent<BatchesTableProps> = observer((props) => {
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { paymentCheckStore } = useAppStores<MorteeMode>();

  const onBatchRowClicked = (batch: PaymentCheckBatchModel): void => {
    if (isProgressDone(batch)) {
      browserHistory.push(navigationStore.generateSingleBatchLink(batch.id));
    }
  };

  const { emptyComponent } = props;
  const { loadingState, currentPageBatches, fetchBatchFile, totalBatchesCount } = paymentCheckStore;

  const columns = createColumns(fetchBatchFile);

  const onPaginationChange = (page: number): void => {
    paymentCheckStore.setCurrentPageIndex(page - 1);
    paymentCheckStore.loadCurrentPageBatches();
  };

  return (
    <StyledCard>
      <Table
        data-testid='batchesTable'
        columns={columns}
        dataSource={currentPageBatches}
        loading={loadingState === LoadingState.Loading}
        pagination={false}
        rowKey={(audit: PaymentCheckBatchModel): string => audit.id}
        isLoaded={loadingState === LoadingState.NotLoading}
        rowClickable
        onRow={(batch): { onClick: VoidFunction } => ({ onClick: (): void => onBatchRowClicked(batch) })}
        emptyComponent={emptyComponent}
      />
      <PaginationWithIndex
        total={totalBatchesCount}
        pageSize={PAYMENT_BATCH_PAGE_SIZE}
        currentIndex={paymentCheckStore.currentPageIndex + 1}
        onChange={onPaginationChange}
      />
    </StyledCard>
  );
});

export default BatchesTable;

function createColumns(
  fetchBatchFile: (batchId: string, fileId: string) => Promise<NamedResource>,
): ColumnProps<PaymentCheckBatchModel>[] {
  return [
    {
      title: <div data-testid='lblAuditDate'>Date</div>,
      dataIndex: 'writeTimestamp',
      width: '130px',
      key: 'writeTimestamp',
      render: (text: any, record: PaymentCheckBatchModel, index: number): React.ReactNode => (
        <TableFormattedDate
          options={formattedDateOptionsWithHoursAndMinutes}
          value={record.writeTimestamp}
          dataTestId={`${index}_date_info`}
        />
      ),
    },
    {
      title: <div data-testid='lblAuditUser'>User</div>,
      dataIndex: 'user',
      width: '150px',
      key: 'user',
      render: (text: any, record: PaymentCheckBatchModel): React.ReactNode => (
        <TableSmallText>{record.performingUserName}</TableSmallText>
      ),
    },
    {
      title: <div data-testid='lblAuditNotes'>Notes</div>,
      dataIndex: 'notes',
      key: 'notes',
      width: '350px',
      render: (text: any, record: PaymentCheckBatchModel, index: number): React.ReactNode => (
        <TableSmallText data-testid={`${index}_notes_info`}>
          {record.notes?.trim() ? record.notes : `Note wasn't provided`}
        </TableSmallText>
      ),
    },
    {
      title: <div data-testid='lblAuditFiles'>File</div>,
      dataIndex: 'files',
      width: '45px',
      align: 'center',
      key: 'files',
      render: (text: any, record: PaymentCheckBatchModel, index: number): React.ReactNode => {
        return (
          <DownloadFilesSvg
            accessibilityLabel='files list'
            id='btn-payment-file-list-modal-open'
            dataTestId={`${index}_btn-payment-file-list-modal-open`}
            fileNum={record.files.length}
            onClick={(event): void => {
              event.stopPropagation();

              showInfoModal(
                <ModalFileList
                  files={record.files.map((file) => [
                    file,
                    (fileId): Promise<NamedResource> => fetchBatchFile(record.id, fileId),
                  ])}
                  title={`Download Payment File${record.files.length > 1 ? 's' : ''}`}
                />,
                {
                  okText: 'CANCEL',
                },
              );
            }}
          />
        );
      },
    },
    {
      title: <div data-testid='lblAuditStatus'>Status</div>,
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      key: 'status',
      render: (text: any, record: PaymentCheckBatchModel, index: number): React.ReactNode => (
        <Status dataTestId={`${index}_status_info`} status={record.status} mode='color' fontSize={13} isAuditStatus />
      ),
    },
  ];
}

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0;
  flex-direction: column;
`;
