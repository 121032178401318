import React, { FC } from 'react';
import { ColorScheme, getColorScheme } from '@app/domain/theme';

export interface SpriteSymbol {
  id: string;
  viewBox: string;
  content: string;
}

export interface SVGProps extends React.SVGProps<{}> {
  image: SpriteSymbol;
  colorScheme?: ColorScheme;
  outerViewBox?: string;
  accessibilityLabel: string;
}

const SVG: FC<SVGProps> = (props) => {
  const { image, outerViewBox, ref, style, colorScheme, accessibilityLabel, ...svgProps } = props;

  // Should not happen, better be safe
  if (!image) {
    return null;
  }

  const useStyle: React.CSSProperties = {
    verticalAlign: 'middle',
    fill: colorScheme && getColorScheme(colorScheme).main,
    ...style,
  };

  return (
    <svg viewBox={outerViewBox ?? image.viewBox} style={useStyle} {...svgProps}>
      {accessibilityLabel && <title>{accessibilityLabel}</title>}
      <use xlinkHref={`#${image.id}`} />
    </svg>
  );
};

export default SVG;
