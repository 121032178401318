import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { CURRENCIES } from '@app/domain/currencies';

interface Props {
  supportedCurrencies: string[];
}

const CurrencyChips: FC<Props> = observer(({ supportedCurrencies }) => {
  const currenciesToDisplay = Object.entries(CURRENCIES).filter(([key, value]) => supportedCurrencies.includes(key));

  return (
    <div>
      <ChipsGrid>
        {currenciesToDisplay.map(([key, value]) => (
          <Chip key={key}>
            <span>
              <BoldSymbol>{value.symbol}</BoldSymbol> {key}
            </span>
          </Chip>
        ))}
      </ChipsGrid>
    </div>
  );
});

export default CurrencyChips;

const Chip = styled.div`
  width: 61px;
  height: 23px;
  border-radius: 12px;
  border: solid 1.1px var(--primary-500);
  background-color: var(--white);
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: -0.07px;
  color: var(--primary-500);
`;

const BoldSymbol = styled.span`
  font-weight: bold;
`;

const ChipsGrid = styled.div`
  display: grid;
  grid-gap: 9px;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(56px, 1fr));
  margin-bottom: 40px;
`;
