import { action, makeObservable, observable } from 'mobx';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import cloneDeep from 'lodash/cloneDeep';
import { MorteeVerificationResult } from '@mortee/domain/morteeAccountVerification';

export default class PayeeSearchStore {
  @observable loading = false;
  @observable selectedPayeeForVerification: UnifiedPayee | undefined = undefined;
  @observable isAfterVerification: boolean = false;
  @observable isVerificationError: boolean = false;
  @observable verificationDetails: MorteeVerificationResult | undefined = undefined;
  @observable accountDetails: Partial<MorteeAccountDetails> | undefined = undefined;
  @observable selectedPayeeFromSearch?: UnifiedPayee = undefined;
  @observable selectedBankAccountCertificateFile?: File = undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  resetAll = (): void => {
    this.selectedPayeeFromSearch = undefined;
    this.selectedBankAccountCertificateFile = undefined;
    this.isAfterVerification = false;
    this.verificationDetails = undefined;
    this.accountDetails = undefined;
    this.isVerificationError = false;
  };

  @action
  resetToAfterPayeeSelected = (payee: UnifiedPayee): void => {
    this.selectedPayeeFromSearch = cloneDeep(payee);
    this.selectedBankAccountCertificateFile = undefined;
    this.isAfterVerification = false;
    this.verificationDetails = undefined;
    this.accountDetails = undefined;
    this.isVerificationError = false;
  };

  @action
  resetToAfterCertificateSelected = (bankAccountCertificateFile: File): void => {
    this.selectedPayeeFromSearch = undefined;
    this.selectedBankAccountCertificateFile = bankAccountCertificateFile;
    this.isAfterVerification = false;
    this.verificationDetails = undefined;
    this.accountDetails = undefined;
    this.isVerificationError = false;
  };

  static get TOO_MANY_RESULTS_AMOUNT(): number {
    return 150;
  }

  static get RESULTS_TO_QUERY(): number {
    return 10;
  }

  @action
  setSelectedPayee = (payee: this['selectedPayeeFromSearch']): void => {
    this.selectedPayeeFromSearch = payee;
  };

  @action
  setVerificationResult = (
    verificationDetails: MorteeVerificationResult | undefined,
    accountDetails: Partial<MorteeAccountDetailsExtended>,
    isVerificationError: boolean,
  ): void => {
    this.isAfterVerification = true;
    this.verificationDetails = verificationDetails;
    this.accountDetails = accountDetails;
    this.isVerificationError = isVerificationError;
  };

  @action
  resetAccountSearch = (): void => {
    this.isAfterVerification = false;
    this.verificationDetails = undefined;
  };
}
