import { Ref, useEffect, useState } from 'react';
import useRerender from '@app/hooks/useRerender';

interface ScrollDistance {
  distanceFromBottom: number;
  distanceFromTop: number | undefined;
}

export function useScrollDistance<THTMLElement extends HTMLElement>(): [
  ScrollDistance | null,
  Ref<THTMLElement>,
  Ref<THTMLElement>,
] {
  const rerenderFunc = useRerender();
  const [scrollElement, setScrollElement] = useState<THTMLElement | null>(null);
  const [scrollContentElement, setScrollContentElement] = useState<HTMLElement | null>(null);

  useEffect((): VoidFunction | undefined => {
    // Don't listen to anything in case one of the elements is null
    if (!scrollElement || !scrollContentElement) {
      return;
    }

    function onResize(): void {
      // For some reason it prevent infinite loop in some cases
      window.requestAnimationFrame(rerenderFunc);
    }

    scrollElement.addEventListener('scroll', onResize);

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(scrollElement);
    resizeObserver.observe(scrollContentElement);

    return (): void => {
      scrollElement.removeEventListener('scroll', onResize);
      resizeObserver.disconnect();
    };
  }, [rerenderFunc, scrollContentElement, scrollElement]);

  const scrollDistance = scrollElement && {
    distanceFromBottom: getDistanceFromBottom(scrollElement),
    distanceFromTop: getDistanceFromTop(scrollElement),
  };

  return [scrollDistance, setScrollElement, setScrollContentElement];
}

function getDistanceFromBottom(scrollableDiv: HTMLElement): number {
  const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;

  return Math.max(0, scrollHeight - scrollTop - clientHeight);
}

function getDistanceFromTop(element: HTMLElement): number {
  return Math.max(0, element.scrollTop);
}
