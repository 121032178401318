import React, { ReactNode } from 'react';
import NakedFormInput, { InputProps } from '@app/components/inputs/NakedFormInput';
import MagnifierIcon from '@app/images/magnifier.svg';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import { ForwardingFC } from '@app/domain/technicals/components';
import { useTranslation } from 'react-i18next';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';

type Props = Omit<InputProps, 'renderSideComponent'> & {
  isSearchActive?: boolean;
  onButtonClick?(): void;
};

const NakedFormSearchInput: ForwardingFC<HTMLInputElement, Props> = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation();

  const { isSearchActive, onButtonClick, ...inputProps } = props;

  function renderMagnifier(): ReactNode {
    if (onButtonClick) {
      return (
        <StyledButton id='btn-search-input' appearance='text' cornerType='circle' onClick={onButtonClick}>
          <SVG
            accessibilityLabel={isSearchActive ? 'search' : ''}
            image={MagnifierIcon}
            width={25}
            height={25}
            colorScheme={isSearchActive ? 'secondary' : 'black'}
          />
        </StyledButton>
      );
    }
    return (
      <ClickEventPropagationBlocker>
        <MagnifierSVG
          accessibilityLabel={t('general.search')}
          image={MagnifierIcon}
          width={25}
          height={25}
          colorScheme={isSearchActive ? 'secondary' : 'black'}
        />
      </ClickEventPropagationBlocker>
    );
  }

  return <NakedFormInput {...(inputProps as InputProps)} renderSideComponent={renderMagnifier} ref={ref} />;
});

export default NakedFormSearchInput;

const MagnifierSVG = styled(SVG)`
  align-self: center;
  margin-right: 10px;
  margin-bottom: 7px;
`;

const StyledButton = styled(Button)`
  margin-right: 7px;
  padding: 3px;
  align-self: center;
`;
