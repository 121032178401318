import ThemeStore from '@app/stores/ThemeStore';
import { css, SerializedStyles } from '@emotion/react';
import { computed } from 'mobx';
import { defaultTheme } from '@app/domain/theme';

export default class MorteeThemeStore extends ThemeStore {
  constructor() {
    super(defaultTheme);
  }

  @computed
  get modeStyles(): SerializedStyles {
    return css`
      :root {
        --app-main-color: var(--primary-200);
        --action-color: ${this.theme.actionColor.main};
        --action-color-hover: ${this.theme.actionColor.fullHover};
        --action-color-active: ${this.theme.actionColor.fullActive};
        --action-text-color: ${this.theme.actionColor.fullTextColor};
      }

      a {
        text-decoration: none !important;
      }
    `;
  }
}
