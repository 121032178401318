import { action, makeObservable, observable } from 'mobx';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import cloneDeep from 'lodash/cloneDeep';

export const GUEST_RESULTS_TO_QUERY = 10;

export default class ARVerificationStore {
  @observable selectedPayeeFromSearch?: AggregatedValidatedPayee = undefined;
  @observable selectedBankAccountCertificateFile?: File = undefined;
  @observable isAfterValidation: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  resetAll = (): void => {
    this.selectedPayeeFromSearch = undefined;
    this.selectedBankAccountCertificateFile = undefined;
    this.isAfterValidation = false;
  };

  @action
  resetToAfterPayeeSelected = (payee: AggregatedValidatedPayee): void => {
    this.selectedPayeeFromSearch = cloneDeep(payee);
    this.selectedBankAccountCertificateFile = undefined;
    this.isAfterValidation = false;
  };

  @action
  resetToAfterCertificateSelected = (bankAccountCertificateFile: File): void => {
    this.selectedPayeeFromSearch = undefined;
    this.selectedBankAccountCertificateFile = bankAccountCertificateFile;
    this.isAfterValidation = false;
  };

  @action
  setIsAfterValidation = (isAfterValidation): void => {
    this.isAfterValidation = isAfterValidation;
  };
}
