import { PrivatePayee, PrivatePayeeServerResponse, transformPrivatePayee } from '@mortee/domain/privatePayee';
import {
  AggregatedValidatedPayee,
  AggregatedValidatedPayeeServerResponse,
  transformAggregatedValidatedPayee,
} from '@app/domain/aggregatedValidatedPayee';
import {
  transformValidatedPayeeAccount,
  ValidatedPayeeAccount,
  ValidatedPayeeAccountServerResponse,
} from '@app/domain/validatedPayeeAccount';
import {
  DeterministicValidationRepresentationData,
  DeterministicValidationRepresentationDataServerResponse,
  DeterministicValidationResultType,
  transformDeterministicValidationRepresentationData,
} from '@app/domain/deterministicValidation';
import { mapValues } from '@app/utils/utils';
import { PrivatePayeeAccount, PrivatePayeeAccountServerResponse } from '@mortee/domain/privatePayeeAccount';

export interface DeterministicValidationWithPrivatePayeeServerResponse {
  privatePayee: PrivatePayeeServerResponse;
  privatePayeeAccounts: PrivatePayeeAccountServerResponse[];
  validatedPayees: AggregatedValidatedPayeeServerResponse[];
  validatedPayeesAccounts: Record<string, ValidatedPayeeAccountServerResponse[]>;
  validationLevel: DeterministicValidationResultType;
  validationRepresentations: DeterministicValidationRepresentationDataServerResponse[];
}

export interface DeterministicValidationWithPrivatePayee {
  privatePayee: PrivatePayee;
  privatePayeeAccounts: PrivatePayeeAccount[];
  validatedPayees: AggregatedValidatedPayee[];
  validatedPayeesAccounts: Record<string, ValidatedPayeeAccount[]>;
  validationLevel: DeterministicValidationResultType;
  validationRepresentations: DeterministicValidationRepresentationData[];
}

export function transformDeterministicValidation(serverResponse: DeterministicValidationWithPrivatePayeeServerResponse): DeterministicValidationWithPrivatePayee {
  return <DeterministicValidationWithPrivatePayee>{
    privatePayee: transformPrivatePayee(serverResponse.privatePayee),
    privatePayeeAccounts: serverResponse.privatePayeeAccounts,
    validatedPayees: serverResponse.validatedPayees.map(transformAggregatedValidatedPayee),
    validatedPayeesAccounts: mapValues(serverResponse.validatedPayeesAccounts, (accounts) =>
      accounts.map(transformValidatedPayeeAccount),
    ),
    validationLevel: serverResponse.validationLevel,
    validationRepresentations: serverResponse.validationRepresentations.map(transformDeterministicValidationRepresentationData),
  };
}

