import { distinctValues, mergeArrays } from '@app/utils/arrayUtils';
import {
  KnoxerAllIdentityProvidersAssignedToUser,
  KnoxerAllIdentityProvidersAssignedToUserServerResponse,
  TenantUserServerResponse,
} from '@app/domain/userManagement/users';

export interface OrganizationalUserServerResponse {
  tenantUserId: string;
  name: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  organizationAppRoleIds: string[];
  globalAppRoleIds: string[];
  ssoUserIds: KnoxerAllIdentityProvidersAssignedToUserServerResponse[];
}

export interface OrganizationalUser {
  tenantUserId: string;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  organizationAppRoleIds: string[];
  globalAppRoleIds: string[];
  allActiveAppRoleIds: string[];
  ssoUserIds: KnoxerAllIdentityProvidersAssignedToUser[];
}

export function transformOrganizationalUser(serverResponse: OrganizationalUserServerResponse): OrganizationalUser {
  return {
    ...serverResponse,
    name: serverResponse.name ?? null,
    email: serverResponse.email ?? null,
    phoneNumber: serverResponse.phoneNumber ?? null,
    allActiveAppRoleIds: distinctValues(mergeArrays([serverResponse.organizationAppRoleIds, serverResponse.globalAppRoleIds])),
  };
}

export interface UserCandidateToOrganization {
  tenantUserId: string;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  isPartOfOrganization: boolean;
}

export function transformTenantUserToUserCandidateToOrganization(
  serverResponse: TenantUserServerResponse,
  relevantOrganizationId: string,
): UserCandidateToOrganization {
  return {
    tenantUserId: serverResponse.tenantUserId,
    name: serverResponse.name ?? null,
    email: serverResponse.email ?? null,
    phoneNumber: serverResponse.phoneNumber ?? null,
    isPartOfOrganization: serverResponse.organizationIds.includes(relevantOrganizationId),
  };
}

export function transformOrganizationalUserToUserCandidateToOrganization(
  serverResponse: OrganizationalUserServerResponse,
): UserCandidateToOrganization {
  return {
    tenantUserId: serverResponse.tenantUserId,
    name: serverResponse.name ?? null,
    email: serverResponse.email ?? null,
    phoneNumber: serverResponse.phoneNumber ?? null,
    isPartOfOrganization: true,
  };
}
