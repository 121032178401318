import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';

const CategoryAndValues: FunctionComponent<
  {
    label: string;
    inlineLabel?: boolean;
    labelClassName?: string;
    valuesClassName?: string;
    className?: string;
    children: ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ inlineLabel, label, labelClassName, valuesClassName, className, children, ...rest }) => {
  return (
    <Container {...rest} inline={inlineLabel} className={className}>
      <Label className={labelClassName}>{label}:</Label>
      <Value className={valuesClassName}>{children}</Value>
    </Container>
  );
};

export default CategoryAndValues;

const Container = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: ${(p): string => (p.inline ? 'row' : 'column')};
  align-items: baseline;

  font-size: 14px;
  letter-spacing: 0.39px;
  line-height: normal;

  color: var(--black-weak);
`;

const Label = styled.span`
  font-weight: bold;
  color: var(--black-strong);
  white-space: nowrap;

  margin-right: 4px;
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
`;
