import React from 'react';
import { NormalText } from '../../Styles';
import { InstructionFormDetails } from '../../commonAfterFormPage/InstructionFormDetails';

export const ACHFormDetails: InstructionFormDetails = {
  instructionsSubTitle: 'supplierValidation.afterRegister.achChase.thankYou',
  extraInstructions: [
    'supplierValidation.afterRegister.achChase.firstInstruction',
    'supplierValidation.afterRegister.achChase.secondInstruction',
  ],
  paymentInstructionsTitle: 'supplierValidation.afterRegister.paymentInst',
  paymentInstructionsLines: [
    {
      description: 'supplierValidation.afterRegister.beneficiaryName',
      value: 'nsKnox Technologies, Inc',
    },
    {
      description: 'supplierValidation.afterRegister.address',
      value: `135 Madison Ave New York, NY 10016`,
    },
    {
      description: 'supplierValidation.afterRegister.checkingAccount',
      value: `625559593`,
    },
    {
      description: 'supplierValidation.afterRegister.swift',
      value: `CHASUS33`,
    },
    {
      description: 'supplierValidation.afterRegister.routingNumber',
      value: `322271627`,
    },
  ],
  paymentInstructionsAddress: {
    description: 'supplierValidation.afterRegister.bankAndAddress',
    value: (
      <NormalText>
        JP Morgan Chase,
        <br />
        10430 Highland Manor Drive
        <br />
        Tampa, FL 33610
      </NormalText>
    ),
  },
  currencies: [],
  pleaseNote: {
    pleaseNoteTitle: 'supplierValidation.afterRegister.pleaseNote',
    pleaseNoteItem: [
      'supplierValidation.afterRegister.achChase.firstNotice',
      'supplierValidation.afterRegister.achChase.secondNotice',
    ],
  },
  showWireTransferWarningBox: {
    achWireTransfer: false,
    swiftWireTransfer: false,
  },
};
