import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import emptyVerificationList from '@mortee/images/check/homePage/empty-verifications-list.svg';
import Button from '@app/components/Button';

interface CheckEmptyResultsProps {
  titleText: string;
  subTitleText?: string;
  buttonText?: string;
  handleNewVerification?: () => void;
}

const CheckEmptyResults: FunctionComponent<CheckEmptyResultsProps> = (props) => {
  const { buttonText, handleNewVerification, subTitleText, titleText } = props;

  return (
    <EmptyContainer>
      <StyledSVG accessibilityLabel='' image={emptyVerificationList} />
      <StyledText isTitle>{titleText}</StyledText>
      <StyledText>{subTitleText}</StyledText>
      {buttonText && handleNewVerification && (
        <StyledButton id='btn-start-first-verification' colorScheme='primary' onClick={handleNewVerification}>
          {buttonText}
        </StyledButton>
      )}
    </EmptyContainer>
  );
};

export default CheckEmptyResults;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSVG = styled(SVG)`
  width: 119px;
  height: 150px;
  margin: 32px 0 16px;
`;

const StyledText = styled.span<{ isTitle?: boolean }>`
  color: var(--gray-blue-deprecated);

  font-size: ${(p): number => (p.isTitle ? 22 : 16)}px;
  font-weight: ${(p): string => (p.isTitle ? 'normal' : '300')};
  margin-bottom: 4px;

  ${(p): string => (!p.isTitle ? `font-weight: 200;` : '')}
`;

const StyledButton = styled(Button)`
  margin: 36px;
`;
