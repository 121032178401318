import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import CardTable from '@app/components/tables/CardTable';
import { ColumnProps } from 'antd/lib/table';
import { removeColumnInteractionProperties, TableStandardText } from '@app/components/tables/Table';
import { FormattedDate, FormattedTime } from '@app/components/Locale';
import { GuestVerificationType, IPSVerificationLog, SearchedAccountType } from '@mortee/domain/ips';
import { formatInternationalPhoneNumberForDisplay } from '@app/utils/phoneUtils';
import Loadable from '@app/utils/Loadable';
import {
  getRepresentationNameWithBIC,
  splitAccountDetailsIntoRepresentationsMap,
} from '@app/domain/accountDetailsRepresentations';
import styled from '@emotion/styled';
import { getColumnPastTimeFilterProps } from '@app/components/tableFilters/singleValue/byPastTimeFieldFilterCreator';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import getColumnMultiValueFieldSelectProps from '@app/components/tableFilters/multiValue/byFieldFilterCreator';
import { getColumnMultiValueConstSelectLocalFilterProps } from '@app/components/tableFilters/multiValue/constsFilterCreator';
import {
  BooleanFilterOptions,
  getColumnBooleanFilterProps,
} from '@app/components/tableFilters/singleValue/byBooleanFieldFilterCreator';
import EmptyResults from '@mortee/routes/ips/logTable/EmptyResults';
import Certificate from '@app/images/certificate.svg';
import { Bold15TransparentBlack900 } from '@app/components/Text';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import { formatRepresentationWithSwiftban } from '@app/domain/accountDetailsFormatters';
import SVG from '@app/components/SVG';
import Card from '@app/components/card/Card';
import TooltipUnstyled from '@app/components/TooltipUnstyled';
import { compare } from '@app/utils/comparatorUtils';

interface Props {
  header?: ReactNode;
  allowInteraction?: boolean;
  verificationLogs: Loadable<IPSVerificationLog[]>;
}

const IPSVerificationLogsTable: FunctionComponent<Props> = ({ allowInteraction = false, header, verificationLogs }) => {
  const columns = useMemo(() => {
    const columnsWithInteraction = createColumnsWithInteraction(verificationLogs.result ?? []);
    if (allowInteraction) {
      return columnsWithInteraction;
    }

    return columnsWithInteraction.map((column) => removeColumnInteractionProperties(column));
  }, [verificationLogs, allowInteraction]);

  if (verificationLogs.result?.length === 0) {
    return <EmptyResults />;
  }

  return (
    <CardTable
      header={header}
      columns={columns}
      dataSource={verificationLogs.result}
      loading={!verificationLogs.isResolved()}
      pagination={{ pageSize: 25 }}
      rowKey={(log): string => log.logUniformId}
    />
  );
};

export default IPSVerificationLogsTable;

function createColumnsWithInteraction(data: IPSVerificationLog[]): ColumnProps<IPSVerificationLog>[] {
  const accountRepresentationsToSelect: SearchedAccountType[] = [
    'domestic',
    'iban',
    'swift',
    GuestVerificationType.BankAccountCertificate,
  ];
  const accountVerificationsTypesSelectOptions = accountRepresentationsToSelect.map((verificationType) => ({
    text:
      verificationType === GuestVerificationType.BankAccountCertificate
        ? 'Bank Account Certificate'
        : getRepresentationNameWithBIC(verificationType as AccountRepresentationType),
    value: verificationType,
  }));

  const renderCertifiedType = (record: IPSVerificationLog): ReactNode => {
    switch (record.verificationType) {
      case GuestVerificationType.AccountVerification:
        return (
          <React.Fragment>
            {/*Cant assert it is AccountRepresentationType since the verification type is AccountVerification*/}
            {getRepresentationNameWithBIC(record.searchedAccountType as AccountRepresentationType)}:{' '}
            {record.searchedAccountDetailsFormatted}
          </React.Fragment>
        );
      case GuestVerificationType.BankAccountCertificate:
        return (
          <TooltipUnstyled title={(): ReactNode => renderCertifiedTooltip(record.searchedAccountDetails)}>
            <CertificateVerificationType>
              <CertificateImage>
                <StyledSvg accessibilityLabel='certificate details' image={Certificate} data-testid='certificate-icon' />
              </CertificateImage>
              Bank Account Certificate
            </CertificateVerificationType>
          </TooltipUnstyled>
        );
    }
  };

  const renderCertifiedTooltip = (searchedAccountDetails: MorteeAccountDetails): ReactNode => {
    const representationsMap = splitAccountDetailsIntoRepresentationsMap(
      { ...searchedAccountDetails, localFormat: null },
      false,
      true,
    );
    return (
      <CertificateTooltipCard shadowType='elevated'>
        <CertificateTooltipTitle>Certificate</CertificateTooltipTitle>
        <DataGrid>
          {Array.from(representationsMap.entries()).map(([representationType, representation]) => (
            <DataGridRow
              title={`${getRepresentationNameWithBIC(representationType)}:`}
              valueDataTest={`ips-table-certificate-tooltip-representation-${representationType}`}
            >
              {formatRepresentationWithSwiftban(representation)}
            </DataGridRow>
          ))}
        </DataGrid>
      </CertificateTooltipCard>
    );
  };

  const filterByVerificationAndType = (value, record): boolean => {
    if (record.verificationType === GuestVerificationType.BankAccountCertificate) {
      return record.verificationType === value;
    } else {
      return record.searchedAccountType === value;
    }
  };

  return [
    {
      title: <div data-testid='lblTimestamp'>Date & Time</div>,
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '220px',
      defaultSortOrder: 'descend',
      sorter: compare.byField((record) => record?.timestamp, compare.numbers()),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <TableStandardText>
          <FormattedDate value={record.timestamp} options={{ day: 'numeric', month: 'long', year: 'numeric' }} />,{' '}
          <FormattedTime value={record.timestamp} options={{ hour: '2-digit', minute: '2-digit' }} />
        </TableStandardText>
      ),
      ...getColumnPastTimeFilterProps('Date & Time', (record) => record.timestamp),
    },
    {
      title: <div data-testid='lblEmailUsed'>Email Used</div>,
      dataIndex: 'userEmail',
      key: 'userEmail',
      sorter: compare.byStringField((record) => record.userEmail),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <TableStandardText>{record.userEmail}</TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => record.userEmail),
    },
    {
      title: <div data-testid='lblPhoneUsed'>Phone Used</div>,
      dataIndex: 'userPhone',
      key: 'userPhone',
      sorter: compare.byStringField((record) => record.userPhone, 'upper', true),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <TableStandardText>
          {record.userPhone ? formatInternationalPhoneNumberForDisplay(record.userPhone) : 'N/A'}
        </TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => record.userPhone),
    },
    {
      title: <div data-testid='lblSearchedPayee'>Searched Payee</div>,
      dataIndex: 'searchedPayee',
      key: 'searchedPayee',
      sorter: compare.byStringField((record) => record.aggregatedPayeeData.mainName),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <TableStandardText>{record.aggregatedPayeeData.mainName || 'Unknown Name'}</TableStandardText>
      ),
      ...getColumnMultiValueFieldSelectProps(data, (record) => record.aggregatedPayeeData.mainName),
    },
    {
      title: <div data-testid='lblSearchedAccount'>Searched Account</div>,
      dataIndex: 'searchedAccount',
      key: 'searchedAccount',
      sorter: compare
        .byStringField((record: IPSVerificationLog) => record.searchedAccountType)
        .then(compare.byStringField((record) => record.searchedAccountDetailsFormatted)),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <TableStandardText>{renderCertifiedType(record)}</TableStandardText>
      ),
      ...getColumnMultiValueConstSelectLocalFilterProps(accountVerificationsTypesSelectOptions, filterByVerificationAndType),
    },
    {
      title: <div data-testid='lblValidationResult'>Result</div>,
      dataIndex: 'validated',
      key: 'validated',
      sorter: compare.byField((record) => record.validated, compare.booleans()),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <ValidatedText validated={record.validated}>{record.validated ? 'Validated' : 'Not Validated'}</ValidatedText>
      ),
      ...getColumnBooleanFilterProps(
        'Show Validated Results',
        (log) => log.validated,
        (filterOption) => {
          switch (filterOption) {
            case BooleanFilterOptions.all:
              return 'Show All';
            case BooleanFilterOptions.onlyTrue:
              return 'Show Validated';
            case BooleanFilterOptions.onlyFalse:
              return 'Show Not Validated';
          }
        },
      ),
    },
    {
      title: <div data-testid='lblFoundAccount'>Account (last 4 digits)</div>,
      dataIndex: 'foundAccount',
      key: 'foundAccount',
      sorter: compare.byStringField((record) => record.account?.shortAccountIdentifier),
      render: (text: any, record: IPSVerificationLog): React.ReactNode => (
        <TableStandardText>{record.account?.shortAccountIdentifier ?? '-'}</TableStandardText>
      ),
      ...getColumnMultiValueFieldSelectProps<IPSVerificationLog>(data, (log) => log.account?.shortAccountIdentifier),
    },
  ];
}

const ValidatedText = styled(TableStandardText)<{ validated: boolean }>`
  font-weight: bold;
  color: ${(p): string => (p.validated ? 'var(--success-300)' : 'var(--error-600)')};
`;

const CertificateImage = styled.div`
  border-radius: 100px;
  width: 24px;
  height: 24px;
  background-color: var(--accent-blue-500);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSvg = styled(SVG)`
  width: 18px;
  height: 20px;
  margin-top: 2px;
`;

const CertificateVerificationType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CertificateTooltipCard = styled(Card)`
  padding: 20px 24px;
`;

const CertificateTooltipTitle = styled(Bold15TransparentBlack900.div)`
  margin-bottom: 30px;
`;
