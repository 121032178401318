import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component';
import React, { ReactNode, useEffect } from 'react';
import PaginationManager from '@app/utils/pagination/PaginationManager';
import { observer } from 'mobx-react';
import Loadable from '@app/utils/Loadable';

interface PaginatedInfiniteScrollProps<T extends object> extends Omit<InfiniteScrollProps, 'next' | 'hasMore' | 'dataLength'> {
  id: string;
  dataTestId: string;
  paginationManager: Loadable<PaginationManager<T>>;
  initialLoader?: InfiniteScrollProps['loader'];
  children: (item: T[]) => ReactNode;
}

const PaginatedInfiniteScroll = observer(
  <T extends object>({
    id,
    dataTestId,
    paginationManager,
    children: itemsMapper,
    loader,
    initialLoader,
    ...rest
  }: PaginatedInfiniteScrollProps<T>) => {
    useEffect(() => {
      paginationManager.map((loadedPaginationManager) => {
        loadedPaginationManager?.hasMore && !loadedPaginationManager?.items.length && loadedPaginationManager.loadNextPage();
      });
    }, [paginationManager]);

    const getLoader = (loadedPaginationManager?: PaginationManager<T>): ReactNode => {
      return loadedPaginationManager?.items.length ? loader : initialLoader ?? loader;
    };

    const result = paginationManager.resolve(
      (loadedPaginationManager): ReactNode => {
        return (
          <div id={id} data-testid={dataTestId}>
            <InfiniteScroll
              scrollThreshold='200px'
              dataLength={loadedPaginationManager.items.length}
              hasMore={loadedPaginationManager.hasMore}
              next={(): Promise<void> => loadedPaginationManager.loadNextPage()}
              loader={getLoader(loadedPaginationManager)}
              {...rest}
            >
              {itemsMapper(loadedPaginationManager.items)}
            </InfiniteScroll>
          </div>
        );
      },
      (): ReactNode => {
        return getLoader();
      },
    );
    return <>{result}</>;
  },
);

export default PaginatedInfiniteScroll;
