import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import Card from '@app/components/card/Card';
import { RowSeparator } from '@app/components/Styles';
import Button from '@app/components/Button';
import DownArrow from '@app/images/arrows/ic-down-arrow-white.svg';
import SVG from '@app/components/SVG';
import useInfraStores from '@app/hooks/useInfraStores';
import OrganizationsSelectionList from '@app/components/sidebar/OrganizationsSelectionList';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import ButtonThatOpensPopup from '@app/components/popup/ButtonThatOpensPopup';

interface UserInfoProps {
  className?: string;
}

const UserInfo: FunctionComponent<UserInfoProps> = observer((props) => {
  const { authenticationStore, userStore } = useInfraStores<AuthenticatedMode>();

  const renderPopup = (onDone: () => void): ReactNode => {
    const { user, doesUserHaveOrganizations, selectedOrganization } = userStore;

    if (!user) {
      return null;
    }

    const onOtherOrganizationSelected = (organizationId: string): void => {
      userStore.switchOrganizationById(organizationId);
      onDone();
    };

    const onLogoutClick = (): void => {
      authenticationStore.logout();
      onDone();
    };

    const { displayName } = user;

    let otherOrganizations: UserOrganization[] = [];
    let organizationName: undefined | string;

    if (doesUserHaveOrganizations && selectedOrganization) {
      organizationName = selectedOrganization.data.name;
      otherOrganizations = user.organizations.filter((organization) => organization.id !== selectedOrganization.id);
    }

    return (
      <UserPopup shadowType='elevated'>
        <PopupCurrentInfo>
          {organizationName && <PopupOrganizationName>{organizationName}</PopupOrganizationName>}
          <PopupUserName>{displayName}</PopupUserName>
        </PopupCurrentInfo>
        <RowSeparator />
        <StyledOtherOrgsList userOrganizations={otherOrganizations} onOrganizationClicked={onOtherOrganizationSelected} />
        <LogoutButton id='btn-userinfo-logout' appearance='outline' colorScheme='primary' onClick={onLogoutClick}>
          SIGN OUT
        </LogoutButton>
      </UserPopup>
    );
  };

  const { user, doesUserHaveOrganizations, selectedOrganization } = userStore;

  if (!user) {
    return null;
  }

  const { displayName } = user;

  return (
    <UserInfoButton
      dataTestId='btn-user-info-popup-open'
      id='btn-user-info-popup-open'
      popupContent={renderPopup}
      popupPlacement='top-left'
      popupPlacementOffset={{ x: 10, y: -80 }}
      appearance='text'
      colorScheme='white'
      className={props.className}
    >
      <UserSummaryContent>
        <SummaryOrganizationName>{doesUserHaveOrganizations && selectedOrganization?.data.name}</SummaryOrganizationName>
        <SummaryUserName>{displayName}</SummaryUserName>
      </UserSummaryContent>
      <StyledSVG accessibilityLabel='' image={DownArrow} />
    </UserInfoButton>
  );
});

export default UserInfo;

const UserInfoButton = styled(ButtonThatOpensPopup)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UserSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
`;

const UserPopup = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  line-height: normal;
  width: 356px;
  position: absolute;
  left: -1px;
  bottom: -1px;
`;

const SummaryOrganizationName = styled.div`
  font-weight: bold;
  line-height: 1.3;
  font-size: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: white;
  margin-bottom: 1px;
`;

const SummaryUserName = styled.div`
  font-size: 12.8px;
  color: white;
  text-align: start;
`;

const PopupCurrentInfo = styled.div`
  text-align: center;
  color: var(--gray-blue-deprecated);
  padding-left: 20px;
  padding-right: 20px;
`;

const PopupOrganizationName = styled.div`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.22px;
  margin-bottom: 10px;
`;

const PopupUserName = styled.div`
  font-size: 14px;
  letter-spacing: 0.19px;
  margin-bottom: 15px;
`;

const StyledOtherOrgsList = styled(OrganizationsSelectionList)`
  align-self: stretch;
`;

const LogoutButton = styled(Button)`
  margin-top: 27px;
`;

const StyledSVG = styled(SVG)`
  min-width: 18px;
  width: 18px;
  height: 18px;
`;
