import { useState } from 'react';

export default function useRunningComponentKey(keyPrefix: string = ''): [string, () => void] {
  const [runningIndex, setRunningIndex] = useState<number>(0);

  function nextKey(): void {
    setRunningIndex(runningIndex + 1);
  }

  return [`${keyPrefix}-${runningIndex}`, nextKey];
}
