import { MutableRefObject, Ref } from 'react';

type RefFunction<T> = { bivarianceHack(instance: T | null): void }['bivarianceHack'];

export function refOfMergedRefs<T>(...refs: Ref<T>[]): RefFunction<T> {
  return (element: T) => refs.forEach((ref) => setRef(ref, element));
}

export function setRef<T>(ref: Ref<T>, element: T): void {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(element);
    return;
  }

  if (typeof ref === 'object') {
    (ref as MutableRefObject<T>).current = element;
    return;
  }
}
