import React, { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import Table, { ObserverTableProps } from './Table';
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import {
  CaptionStartTransparentBlack400,
  CaptionStartTransparentBlack600,
  CaptionStartTransparentBlack900,
  SubtitleSmallStartTransparentGrey800,
} from '../Text';
import { TableProps } from 'antd/lib/table';
import { isDefined } from '@app/utils/utils';

interface CardListTableProps<T> extends ObserverTableProps<T> {
  header?: ReactNode;
}

function CardTable<T>({ header, pagination, ...tableProps }: CardListTableProps<T>): ReactElement {
  const totalAmountsText = (total: number, [start, end]: [number, number]): ReactNode => (
    <CaptionStartTransparentBlack600.span>
      {start}-{end} of {total}
    </CaptionStartTransparentBlack600.span>
  );

  const calcPaginationConfig = (currentPaginationConfig: TableProps<T>['pagination']): TableProps<T>['pagination'] => {
    if (currentPaginationConfig === false) {
      return false;
    }

    return {
      hideOnSinglePage: true,
      showTotal: totalAmountsText,
      ...currentPaginationConfig,
    };
  };

  const paginationConfig = calcPaginationConfig(pagination);

  return (
    <StyledCard>
      {isDefined(header) && (typeof header === 'string' ? <CardHeader>{header}</CardHeader> : header)}
      <StyledTable pagination={paginationConfig} {...tableProps} />
    </StyledCard>
  );
}

export default CardTable;

const StyledTable = styled(Table)<CardListTableProps<any>>`
  flex: 1;

  .ant-table-column-title {
    ${SubtitleSmallStartTransparentGrey800.css}
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 45px;

    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-prev {
      border: none;

      .ant-pagination-item-link {
        border: none;
      }
    }

    .ant-pagination-item {
      ${CaptionStartTransparentBlack400.css}
      min-width: auto;
      padding: 8px 2px;

      &.ant-pagination-item-active {
        font-weight: bold;
        ${CaptionStartTransparentBlack900.css}
      }

      a {
        color: inherit;
      }
    }
  }

  width: 100%;
`;

const StyledCard = styled(Card)`
  padding: 0;
`;
