import React from 'react';
import NakedDropdown, { DropdownProps } from '@app/components/inputs/NakedDropdown';
import COUNTRIES, { getCountriesOrderByCountryCode } from '@app/domain/countries';
import OldChip from '@app/components/OldChip';
import DropdownItem from '@app/components/inputs/DropdownItem';
import styled from '@emotion/styled';
import { ForwardingFC } from '@app/domain/technicals/components';

interface Props extends Omit<DropdownProps, 'placeholder' | 'children'> {
  currentPayeeCountryCode?: string;
  customPlaceholder?: string;
}

const CountryDropdown: ForwardingFC<HTMLDivElement, Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ currentPayeeCountryCode, customPlaceholder, ...dropdownProps }, ref) => {
    return (
      <NakedDropdown placeholder={customPlaceholder ?? 'Country'} {...dropdownProps} ref={ref} isSearchable>
        {getCountriesOrderByCountryCode().map((countryCode) => {
          const country = COUNTRIES[countryCode];
          const isThisThePayeeCountry = currentPayeeCountryCode === countryCode;
          const text = `${countryCode} - ${country.name}`;
          return (
            <CountryDropdownItem
              key={countryCode}
              keywords={[country.name, countryCode, text]}
              value={countryCode}
              textWhenSelected={text}
            >
              <div>{text}</div>
              {isThisThePayeeCountry && <OldChip>Country of payee</OldChip>}
            </CountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  },
);

export default CountryDropdown;

const CountryDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;
