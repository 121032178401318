import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import useAppStores from '@app/hooks/useAppStores';
import browserHistory from '@app/utils/browserHistory';
import {
  GuestBankAccountCertificateVerifyResult,
  transformGuestBankAccountCertificateVerifyResult,
} from '@ar/domain/arAccountVerification';
import GuestVerificationWithCertificateAfterVerificationPage from './certificateVerificationPage/GuestVerificationWithCertificateAfterVerificationPage';
import GuestVerificationWithCertificateBeforeVerificationPage from './certificateVerificationPage/GuestVerificationWithCertificateBeforeVerificationPage';
import * as verifyService from '@ar/services/arVerifyService';
import Log from '@app/libs/logger';
import { extractLogErrorIdFromError, HttpStatus, isRequestError, isWebErrorContent } from '@app/libs/request';
import { LoadingState } from '@app/utils/Loadable';
import sleep from '@app/utils/sleep';
import { CertificateVerificationError, CertificateVerificationErrorsTypes } from '@app/domain/bac';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';

const GuestVerificationWithCertificatePage: FC = observer(() => {
  const { navigationStore } = useInfraStores<ArMode>();
  const { arVerificationStore } = useAppStores<ArMode>();

  const selectedCertificateFile = arVerificationStore.selectedBankAccountCertificateFile;

  // We need to make sure the file exist in the global store
  if (!selectedCertificateFile) {
    arVerificationStore.resetAll();
    browserHistory.push(navigationStore.generateStartPageLink());
    return <div />;
  }

  const [verificationError, setVerificationError] = useState<CertificateVerificationError | null>(null);
  const [verificationResult, verifyCertificate] = useManuallyCalledLoadable<GuestBankAccountCertificateVerifyResult>(
    async (): Promise<GuestBankAccountCertificateVerifyResult> => {
      try {
        // Make all verify certificate requests take at lease 3 seconds
        const [verifyResultServerResponse] = await Promise.all([
          verifyService.validateAccountWithBankAccountCertificate(selectedCertificateFile),
          sleep(3000),
        ]);

        const verifyResult = transformGuestBankAccountCertificateVerifyResult(verifyResultServerResponse);

        Log.event('accountValidationResult', { validated: verifyResult.validated }, true);

        return verifyResult;
      } catch (e: unknown) {
        if (!(isRequestError(e) && e.code === HttpStatus.badRequest && isWebErrorContent(e.responseJSON))) {
          Log.event('accountValidationResult', { error: 'Unknown' }, true);
          setVerificationError({
            errorType: 'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR',
            errorLogId: extractLogErrorIdFromError(e),
          });
          throw e;
        }

        Log.event('accountValidationResult', { error: e.responseJSON.error }, true);
        if (CertificateVerificationErrorsTypes.includes(e.responseJSON.error)) {
          setVerificationError({
            errorType: e.responseJSON.error,
            errorLogId: extractLogErrorIdFromError(e),
          });
        } else {
          setVerificationError({
            errorType: 'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR',
            errorLogId: extractLogErrorIdFromError(e),
          });
        }

        throw e;
      }
    },
  );

  const replaceCertificate = (newCertificate: File): void => {
    arVerificationStore.resetToAfterCertificateSelected(newCertificate);
    setVerificationError(null);
  };

  useEffect(() => {
    arVerificationStore.setIsAfterValidation(!!verificationResult.result);
  }, [arVerificationStore, verificationResult.result]);

  if (verificationResult.loadState === LoadingState.InProgress) {
    return <GuestVerificationWithCertificateAfterVerificationPage loading />;
  }

  if (!verificationError && verificationResult.loadState === LoadingState.Resolved) {
    return <GuestVerificationWithCertificateAfterVerificationPage verificationResult={verificationResult.result} />;
  }

  return (
    <GuestVerificationWithCertificateBeforeVerificationPage
      certificate={selectedCertificateFile}
      verifyCertificate={verifyCertificate}
      replaceCertificate={replaceCertificate}
      certificateVerificationError={verificationError}
    />
  );
});

export default GuestVerificationWithCertificatePage;
