import { BasicTheme, ColorSchemeObject, colorSchemePresets, textColorSchemeFromMainColor } from '@app/domain/theme';

export interface ARTheme extends BasicTheme {
  headerColor: ColorSchemeObject;
  headerTextColor: ColorSchemeObject;
  isHeaderDark: boolean;
  continueButtonColor: ColorSchemeObject;
  brand?: {
    logo: ResourceData;
    clientName: string;
    additionalLogos?: ResourceData[];
  };
  fontFamily?: string;
}

export const defaultARTheme: ARTheme = {
  actionColor: colorSchemePresets.secondary,
  headerColor: colorSchemePresets.primary,
  headerTextColor: textColorSchemeFromMainColor(colorSchemePresets.primary.main),
  continueButtonColor: colorSchemePresets.secondary,
  isHeaderDark: true,
  inputBoxAppearance: 'corners',
};
