export enum OrganizationServiceType {
  // Account verification Module
  accountVerificationService = 'AccountVerificationService',

  // Payment check module
  paymentCheck = 'PaymentCheckOnlinePortalService',

  // MVF guard module
  OnlineMasterDataGuard = 'OnlineMasterDataGuard',

  // Incoming Payment Security module
  IncomingPaymentSecurityService = 'IncomingPaymentSecurityService',

  // Secure Files module
  secureFiles = 'SecureFilesService',

  // SFTP module
  sftp = 'FileBasedSftpService',
}
