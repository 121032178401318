import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { flow } from 'mobx';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import downloadEnabled from '@app/images/download-v2-enabled.svg';
import Loader from './Loader';
import Log from '@app/libs/logger';
import { downloadResourceByNamedResource } from '@app/utils/fileUtils';
import { useTranslation } from 'react-i18next';

export interface DownloadableResourceProps {
  dataTestId?: string;
  fileMetadata: FileMetadata;
  spinnerWhileDownloading?: boolean;
  className?: string;
  children?: ReactNode;
  center?: boolean;
  fetchFile(fileId: string): Promise<NamedResource>;
}

const DownloadableResource: FunctionComponent<DownloadableResourceProps> = observer((props) => {
  const { t } = useTranslation();
  const localStore = useLocalObservable(() => ({
    isPreparingDownload: false as boolean,
  }));

  const renderContent = (): ReactElement => {
    const { dataTestId, children, center = true } = props;

    if (children) {
      return (
        <FileDownloadContainer
          id='btn-downloadable-resource-download'
          data-testid={dataTestId}
          isSingleElement
          onClick={(): Promise<void> => downloadResource()}
          center={center}
        >
          {children}
        </FileDownloadContainer>
      );
    }

    return (
      <>
        <FilenameText>{fileName}</FilenameText>
        <StyledDownloadSVG
          accessibilityLabel={t('general.accessibility.download')}
          id='btn-downloadable-resource-download'
          data-testid={dataTestId}
          onClick={(): Promise<void> => downloadResource()}
          image={downloadEnabled}
        />
      </>
    );
  };

  const downloadResource = flow(function* () {
    localStore.isPreparingDownload = true;

    try {
      const resource: NamedResource = yield fetchFile(fileMetadata.id);
      downloadResourceByNamedResource(resource);
    } catch (e: unknown) {
      Log.exception(e);
    } finally {
      localStore.isPreparingDownload = false;
    }
  });

  const { fileMetadata, fetchFile, spinnerWhileDownloading = true, className, center = true } = props;

  const fileName = fileMetadata.name ?? '';

  if (spinnerWhileDownloading && localStore.isPreparingDownload) {
    return <Loader spinning />;
  }

  return (
    <FileContainer className={className}>
      <FileDownloadContainer center={center}>{renderContent()}</FileDownloadContainer>
    </FileContainer>
  );
});

export default DownloadableResource;

const FileDownloadContainer = styled.div<{ isSingleElement?: boolean; isDownloadBlocked?: boolean; center?: boolean }>`
  opacity: ${(p): number => (p.isDownloadBlocked ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: ${(p): string => (p.isSingleElement ? (p.center ? 'space-around' : 'flex-start') : 'space-between')};
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledDownloadSVG = styled(SVG)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const FilenameText = styled.span`
  font-size: 15px;
  font-weight: 300;
  color: var(--gray-blue-deprecated);
`;
