import React from 'react';
import styled from '@emotion/styled';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import getCardCornersCssClass, { CornerAppearance } from '../corners/CornerCssGenerator';
import { SerializedStyles } from '@emotion/react';
import { isEdge } from '@app/utils/browserUtils';
import { ForwardingFC } from '@app/domain/technicals/components';

const DEFAULT_PLACEHOLDER_ROWS = 5;

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  corners?: CornerAppearance;
  showPlaceholder?: boolean;
  placeholderRows?: number;
  loading?: boolean;
  dataTestId?: string;
  customBackgroundColor?: string;
  strokeColor?: string;
  shadowType?: 'none' | 'regular' | 'elevated';
  fontFamily?: string;
}

const Card: ForwardingFC<HTMLDivElement, CardProps> = React.forwardRef((props: CardProps, ref) => {
  const {
    corners,
    showPlaceholder,
    loading,
    placeholderRows = DEFAULT_PLACEHOLDER_ROWS,
    children,
    className,
    id,
    dataTestId = id,
    customBackgroundColor = 'white',
    strokeColor,
    shadowType = 'regular',
    fontFamily,
    ...divProps
  } = props;

  const placeholderReady = !(showPlaceholder && loading);

  return (
    <StyledCard
      {...divProps}
      id={id}
      ref={ref}
      shadowType={shadowType}
      className={className}
      corners={corners}
      customBackgroundColor={customBackgroundColor}
      strokeColor={strokeColor}
      fontFamily={fontFamily}
      data-testid={dataTestId}
    >
      <ReactPlaceholder type='text' rows={placeholderRows} ready={placeholderReady}>
        {children ?? <div />}
      </ReactPlaceholder>
    </StyledCard>
  );
});

export default Card;

const StyledCard = styled.div<CardProps>`
  border: 0 solid transparent;
  ${isEdge && 'border: 1px solid transparent;'};
  ${({ shadowType }): string => {
    switch (shadowType) {
      case 'none': {
        return '';
      }
      case 'elevated': {
        return 'box-shadow: var(--box-shadow-3);';
      }
      case 'regular':
      default: {
        return 'box-shadow: var(--box-shadow-1);';
      }
    }
  }}
  fontFamily: ${(p): string => (p.fontFamily ? p.fontFamily : '')};
  ${(p): SerializedStyles => getCardCornersCssClass(p.corners, p.customBackgroundColor, p.strokeColor)}
  padding: 16px;
  transition: 0.3s box-shadow ease-in-out, 0.3s opacity ease-in-out, 0.3s background-color ease-in-out;
`;
