import request, { HttpMethod } from '@app/libs/request';
import config from '@app/config';
import { OrganizationServerResponse } from '@app/domain/userManagement/organizations';
import { ORGANIZATION_HEADER_NAME } from '@app/utils/userIdentityHeadersGenerator';
import { KnoxerIdentityProvidersServerResponse } from '@app/domain/userManagement/identityProviders';

export async function getAllIdentityProvidersOfOrganization(
  organizationId: string,
): Promise<KnoxerIdentityProvidersServerResponse[]> {
  return request<KnoxerIdentityProvidersServerResponse[]>(config.serverUrls.backee, `/api/organizations/identity-providers`, {
    method: HttpMethod.get,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot get IdentityProviders of organization.',
      },
    },
  });
}

export async function addIdentityProviderToOrganization(
  organizationId: string,
  knoxerId: string,
  identityProviderName: string,
): Promise<OrganizationServerResponse> {
  return request<OrganizationServerResponse>(config.serverUrls.backee, `/api/organizations/identity-providers`, {
    data: {
      knoxerId,
      identityProviderName,
    },
    method: HttpMethod.post,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot add identity provider to organization.',
      },
    },
  });
}

export async function removeIdentityProviderFromOrganization(
  organizationId: string,
  knoxerId: string,
  identityProviderName: string,
): Promise<OrganizationServerResponse> {
  return request<OrganizationServerResponse>(config.serverUrls.backee, `/api/organizations/identity-providers`, {
    data: {
      knoxerId,
      identityProviderName,
    },
    method: HttpMethod.delete,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot remove identity provider from organization.',
      },
    },
  });
}
