import React from 'react';
import { InstructionFormDetails } from '../../commonAfterFormPage/InstructionFormDetails';
import { NormalText } from '../../Styles';

export const BOFAWireFormDetails: InstructionFormDetails = {
  instructionsSubTitle: 'supplierValidation.afterRegister.nowYouAreRegisteredBofaWire',
  paymentInstructionsTitle: 'supplierValidation.afterRegister.paymentInst',
  paymentInstructionsLines: [
    {
      description: 'supplierValidation.afterRegister.beneficiaryName',
      value: 'nsKnox Technologies, Inc',
    },
    {
      description: 'supplierValidation.afterRegister.address',
      value: '135 Madison Ave, New York, NY 10016',
    },
    { description: 'supplierValidation.afterRegister.checkingAccount', value: '466007904549' },
    {
      description: 'supplierValidation.afterRegister.swift',
      value: 'supplierValidation.afterRegister.bofaWire.swiftValues',
    },
    {
      description: 'ABA Fedwire',
      value: '026009593',
    },
  ],
  paymentInstructionsAddress: {
    description: 'supplierValidation.afterRegister.bankAndAddress',
    value: (
      <NormalText>
        Bank of America,
        <br />
        222 Broadway,
        <br />
        New York, NY 10038
      </NormalText>
    ),
  },
  currencies: [],
  pleaseNote: {
    pleaseNoteTitle: 'supplierValidation.afterRegister.pleaseNote',
    pleaseNoteItem: ['supplierValidation.afterRegister.transferMustBe'],
  },
  showWireTransferWarningBox: {
    achWireTransfer: true,
    swiftWireTransfer: false,
  },
  extraInstructions: null,
};
