import React, { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const ClickEventPropagationBlocker: FC<Props> = ({ onClick, ...rest }) => {
  return (
    <div
      {...rest}
      onClick={(e): void => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
      }}
    />
  );
};

export default ClickEventPropagationBlocker;
