import { isTruthy } from '@app/utils/utils';
import { isWebErrorContent, RequestError } from '@app/libs/request';
import { BlackListedFileTypesList, CheckFileOptions, FileErrorTypes } from '@app/domain/files';
import { mergeArrays } from '@app/utils/arrayUtils';

export function extractErroredFileName(requestError: RequestError): string[] {
  if (isWebErrorContent(requestError.responseJSON)) {
    const { fileNames, filename, fileName } = requestError.responseJSON.additionalData;
    return (fileNames ?? [filename, fileName]).filter(isTruthy);
  }
  return [];
}

export function getFileErrorText(errorType: FileErrorTypes, options: CheckFileOptions, fileName?: string): string {
  let errorText;
  switch (errorType) {
    case FileErrorTypes.emptyFile:
      errorText = `The specified file ${fileName ? `${fileName} ` : ''}has no content`;
      break;
    case FileErrorTypes.fileNameLength:
      errorText = `The specified file ${fileName ? `${fileName} ` : ''}name is too long, it must be less than ${
        options.maxFileNameLength
      } characters`;
      break;
    case FileErrorTypes.fileSize:
      errorText = `The specified file ${fileName ? `${fileName} ` : ''}is exceeding the maximum file size of ${
        options.maxFileSizeMB
      }MB`;
      break;
    case FileErrorTypes.invalidFileName:
      errorText = `The file name can contain only the following: English and Hebrew letters, spaces, digits 0-9, underscore _, parentheses ( ), hyphen -, dot (.)`;
      break;
    case FileErrorTypes.forbiddenExtension:
      errorText = `The file could not be uploaded. Files with the following extensions are not allowed: ${mergeArrays([
        BlackListedFileTypesList,
        options.forbiddenExtensions,
      ]).join(', ')}.`;
      break;
    case FileErrorTypes.tooManyFiles:
      errorText = `You can upload up to ${options.maxFilesAllowed} files at a time`;
      break;
    case FileErrorTypes.unknownExtension:
      return (
        `The file extension is not supported.` +
        (options.allowedExtensions
          ? ` Only ${options.allowedExtensions
              .map((allowedExtension) => allowedExtension.toUpperCase())
              .join(', ')} files can be uploaded.`
          : '')
      );
  }
  return errorText;
}
