import React, { FC, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BodySmallStartTransparentBlack600, Bold13TransparentGrey800 } from '@app/components/Text';
import { distinctValues, mergeArrays, valueOrValuesAsArray } from '@app/utils/arrayUtils';
import { StringSeparatorList } from '@app/components/SeparatorList';
import Nationality from '@app/components/Nationality';
import { getRepresentationNameWithBIC } from '@app/domain/accountDetailsRepresentations';
import { calcRepresentationsToActuallyDisplay } from '@app/utils/deterministicValidationUtils';
import { Subtitle } from './Styles';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import { pluralize } from '@app/utils/stringUtils';
import Chip from '@app/components/OldChip';
import { isDefined } from '@app/utils/utils';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import { PrivatePayee, SupplierNumberType } from '@mortee/domain/privatePayee';
import {
  getFormattedLEI,
  getFormattedLEIWithoutType,
  orderedStrongLeisDefinitions,
  removeStrongIds,
} from '@app/domain/legalEntityIdentifier';
import { DeterministicValidationWithPrivatePayee } from '@mortee/domain/morteeDeterministicValidation';
import { compare } from '@app/utils/comparatorUtils';

interface Props {
  privatePayee: PrivatePayee;
  deterministicValidation: DeterministicValidationWithPrivatePayee;
}

const PayeeAndAccountDetails: FC<Props> = ({ privatePayee, deterministicValidation }) => {
  const strongIds = privatePayee.strongLegalIdentifies
    ? Object.values(privatePayee.strongLegalIdentifies).filter(isDefined).flat()
    : [];
  const regularPayeeLegalIdentifiers = removeStrongIds(privatePayee.data.legalIdentifiers, strongIds);

  const payeeIds = mergeArrays(
    [
      regularPayeeLegalIdentifiers?.map((lei) => getFormattedLEI(lei, true)),
      privatePayee.data.supplierNumbers?.map((supplierNumber) => `${supplierNumber} (${SupplierNumberType})`),
    ],
    true,
  );

  const representationsToDisplay = calcRepresentationsToActuallyDisplay(deterministicValidation.validationRepresentations);

  return (
    <div data-testid='payee-and-account-details'>
      <GridContainer>
        <ColumnDiv>
          <Bold13TransparentGrey800.div>PAYEE DETAILS</Bold13TransparentGrey800.div>
          <DataGrid>
            {renderCategoryAndValues(pluralize('Name', privatePayee.data.names.length), renderNames(privatePayee.data.names))}
            {renderCategoryAndNationality('Country', privatePayee.data.countryCode)}
            {renderCategoryAndValues('Internal ID', privatePayee.externalReferenceId)}
            {...orderedStrongLeisDefinitions.map((strongIdTypeDefinition) => {
              const strongIdsOfType = privatePayee.strongLegalIdentifies?.[strongIdTypeDefinition.type];

              if (strongIdsOfType?.length) {
                return renderCategoryAndValues(
                  strongIdTypeDefinition.name,
                  strongIdsOfType.map((lei) => getFormattedLEIWithoutType(lei, strongIdTypeDefinition.formatWithCountry)),
                );
              }

              // Don't display anything
              return;
            })}
            {!!payeeIds.length && renderCategoryAndValues('IDs', payeeIds)}
            {renderCategoryAndCompanyCodes(
              pluralize('Sub-organization', privatePayee.data.companyCodes.length),
              privatePayee.data.companyCodes,
            )}
          </DataGrid>
        </ColumnDiv>
        <ColumnDiv>
          <Subtitle>BANK ACCOUNT DETAILS</Subtitle>
          <DataGrid>
            {representationsToDisplay.map((representation) =>
              renderCategoryAndValues(getRepresentationNameWithBIC(representation.type), representation.formattedRepresentation),
            )}
          </DataGrid>
        </ColumnDiv>
      </GridContainer>
    </div>
  );
};

export default PayeeAndAccountDetails;

const renderValue = (value: ReactElement | string | string[]): ReactNode => {
  if (Array.isArray(value) || typeof value === 'string') {
    const string = valueOrValuesAsArray(value);
    return (
      <>
        {distinctValues(string).map((str) => (
          <div key={str}>{str}</div>
        ))}
      </>
    );
  }

  return value;
};

const renderCategoryAndValues = (category: string, value: string | string[] | ReactElement): ReactElement => (
  <DataGridRow title={`${category}:`} key={category} valueDataTest={`details-${category}`}>
    {renderValue(value)}
  </DataGridRow>
);

const renderCategoryAndNationality = (category: string, countryCode: string | null | undefined): ReactElement => (
  <DataGridRow title={`${category}:`} key={category} valueDataTest='payee-details-country'>
    <Nationality countryCode={countryCode} unknownFlagStyle='wavyFlag' />
  </DataGridRow>
);

const renderNames = (names: string[]): ReactElement | string => {
  if (names?.length === 0) {
    return 'Not provided';
  }

  return (
    <BodySmallStartTransparentBlack600.div>
      <StringSeparatorList
        items={names.slice().sort(compare.stringsCaseInsensitive())}
        separator={
          <>
            &nbsp;
            <MiscInfoBullet />
            &nbsp;
          </>
        }
      />
    </BodySmallStartTransparentBlack600.div>
  );
};

const renderCategoryAndCompanyCodes = (category: string, companyCodes: string[]): ReactNode => {
  if (companyCodes.length === 0) {
    return null;
  }

  return (
    <ChipsBagDataGridRow title={`${category}:`} key={category} valueDataTest={`details-${category}`}>
      {companyCodes.map((companyCode) => (
        <Chip key={companyCode} className={WRAP_WORDS_CSS_PROPERTIES}>
          {companyCode}
        </Chip>
      ))}
    </ChipsBagDataGridRow>
  );
};

const ChipsBagDataGridRow = styled(DataGridRow)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > *:not(:first-child) {
    margin-top: 4px;
  }
`;

const MiscInfoBullet = styled.span`
  display: inline-block;
  align-self: center;

  min-width: 4px;
  max-width: 4px;
  height: 4px;
  margin-bottom: 2px;
  background-color: var(--black-weak);
  border-radius: 50%;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 19px;
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
