import React, { FC } from 'react';
import styled from '@emotion/styled';
import PrefilledInformationByCustomerTooltip from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/PrefilledInformationByCustomerTooltip';
import FormItemsBox, { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';

interface Props extends Omit<FormItemsBoxProps, 'sideContent'> {
  hasPrefilledData: boolean;
}

const SupplierRegistrationFormItemsBox: FC<Props> = ({ hasPrefilledData, disabled, ...formItemBoxProps }) => {
  return (
    <FormItemsBox
      {...formItemBoxProps}
      disabled={hasPrefilledData || disabled}
      sideContent={hasPrefilledData ? <StyledPrefilledInformationByCustomerTooltip /> : undefined}
    />
  );
};

export default SupplierRegistrationFormItemsBox;

const StyledPrefilledInformationByCustomerTooltip = styled(PrefilledInformationByCustomerTooltip)`
  align-self: center;
  margin-inline-end: 12px;
`;
