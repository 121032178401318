export const GOOGLE_SSO_LOGIN_MODE_PATH: string = '/sso/google';
export const GOOGLE_SSO_AUTHORIZATION_PATH: string = 'oauth2/authorization/google';
export const SAML_SSO_LOGIN_MODE_PATH_PREFIX: string = '/sso/saml';
export const SAML_SSO_LOGIN_MODE_PATH_PREFIX_WITH_SLASH: string = `${SAML_SSO_LOGIN_MODE_PATH_PREFIX}/`;
export const SAML_PROVIDER_REGEX = /^[a-zA-Z0-9\-_]+$/;

export const OIDC_SSO_LOGIN_MODE_PATH_PREFIX: string = '/sso/oidc';
export const OIDC_SSO_LOGIN_MODE_PATH_PREFIX_WITH_SLASH: string = `${OIDC_SSO_LOGIN_MODE_PATH_PREFIX}/`;
export const OIDC_PROVIDER_REGEX = /^[a-zA-Z0-9\-_]+$/;
export const GENERAL_SSO_PROVIDER_REGEX = /^[a-zA-Z0-9\-_]+$/;

export enum SuccessErrorQueryParamValues {
  googleSso = 'sso',
  saml = 'saml',
  oidc = 'oidc',
}

export enum SSOErrorQueryParamValues {
  googleSso = 'sso',
  saml = 'saml',
  oidc = 'oidc',
}

export function extractSamlProviderNameFromAddress(pathname: string): string | null {
  const isSamlPath = pathname.startsWith(SAML_SSO_LOGIN_MODE_PATH_PREFIX_WITH_SLASH);

  if (!isSamlPath) {
    return null;
  }

  const samlProviderName = pathname.substring(SAML_SSO_LOGIN_MODE_PATH_PREFIX_WITH_SLASH.length);

  if (!samlProviderName) {
    return null;
  }

  if (!SAML_PROVIDER_REGEX.test(samlProviderName)) {
    return null;
  }

  return samlProviderName;
}

export function extractOIDCProviderNameFromAddress(pathname: string): string | null {
  const isOidcPath = pathname.startsWith(OIDC_SSO_LOGIN_MODE_PATH_PREFIX_WITH_SLASH);

  if (!isOidcPath) {
    return null;
  }

  const oidcProviderName = pathname.substring(OIDC_SSO_LOGIN_MODE_PATH_PREFIX_WITH_SLASH.length);

  if (!oidcProviderName) {
    return null;
  }

  if (!OIDC_PROVIDER_REGEX.test(oidcProviderName)) {
    return null;
  }

  return oidcProviderName;
}
