import React, { FC, useContext } from 'react';
import LineInputBox from './LineInputBox';
import CornersInputBox from './CornersInputBox';
import BorderInputBox from '@app/components/inputs/inputBox/BorderInputBox';
import FormContext from '@app/components/inputs/FormContext';
import useTheme from '@app/hooks/useTheme';
import Log from '@app/libs/logger';
import { InputBoxBaseProps } from '@app/components/inputs/inputBox/base';

export type InputBoxAppearance = 'line' | 'corners' | 'border' | 'none';

export interface InputBoxProps extends InputBoxBaseProps {
  appearance?: InputBoxAppearance;
}

type InputBoxComponent = React.ComponentType<InputBoxBaseProps>;

const InputBox: FC<InputBoxProps> = ({ colorScheme, inputState, appearance: propsAppearance, className, children }) => {
  const { appearance: contextAppearance } = useContext(FormContext);
  const { inputBoxAppearance: themeAppearance } = useTheme();

  const appearance = propsAppearance || contextAppearance || themeAppearance;

  if (appearance === 'none') {
    return <div className={className}>{children}</div>;
  }

  const InputGroupAppearance = calculateBoxAppearance(appearance);

  return (
    <InputGroupAppearance inputState={inputState} colorScheme={colorScheme} className={className}>
      {children}
    </InputGroupAppearance>
  );
};

function calculateBoxAppearance(appearance: InputBoxAppearance | undefined): InputBoxComponent {
  if (!appearance) {
    return CornersInputBox;
  }

  switch (appearance) {
    case 'line':
      return LineInputBox;
    case 'corners':
      return CornersInputBox;
    case 'border':
      return BorderInputBox;
    default: {
      Log.exception('Invalid input box appearance, defaulting to "corners" input box', { appearance });
      return CornersInputBox;
    }
  }
}

export default InputBox;
