import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import NotValidatedSmallIcon from '@mortee/images/deterministicValidation/small-dv-not-validated.svg';
import SelfValidatedSmallIcon from '@mortee/images/deterministicValidation/small-dv-self-validated.svg';
import StandardValidatedSmallIcon from '@mortee/images/deterministicValidation/small-dv-standard-validation.svg';
import ExtendedValidatedSmallIcon from '@mortee/images/deterministicValidation/small-dv-extended-validation.svg';
import NotValidatedBigIcon from '@mortee/images/deterministicValidation/big-dv-not-validated.svg';
import SelfValidatedBigIcon from '@mortee/images/deterministicValidation/big-dv-self-validated.svg';
import StandardValidatedBigIcon from '@mortee/images/deterministicValidation/big-dv-standard-validation.svg';
import ExtendedValidatedBigIcon from '@mortee/images/deterministicValidation/big-dv-extended-validation.svg';
import SVG from '@app/components/SVG';
import { Tooltip } from 'antd';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';

interface Props {
  validationLevel: DeterministicValidationResultType;
  size: 'small' | 'big';
  showText?: boolean;
  className?: string;
}

const ValidationLevelIcon: FunctionComponent<Props> = (props) => {
  const { validationLevel, size, showText, className } = props;

  const validationLevelText = getText(validationLevel);

  return (
    <LayoutContainer className={className} size={size}>
      <Tooltip title={showText ? '' : validationLevelText}>
        <StyledValidationIcon
          accessibilityLabel={validationLevel}
          data-testid={`mdg-icon-${validationLevel}-${size}`}
          size={size}
          image={getIcon(size, validationLevel)}
        />
      </Tooltip>
      {showText && getStyledTextComponent(validationLevel, validationLevelText, size)}
    </LayoutContainer>
  );
};

export default ValidationLevelIcon;

const getIcon = (size: Props['size'], validationLevel: DeterministicValidationResultType): SpriteSymbol => {
  if (size === 'big') {
    switch (validationLevel) {
      case DeterministicValidationResultType.notValidated: {
        return NotValidatedBigIcon;
      }
      case DeterministicValidationResultType.selfApproved: {
        return SelfValidatedBigIcon;
      }
      case DeterministicValidationResultType.standardValidated: {
        return StandardValidatedBigIcon;
      }
      case DeterministicValidationResultType.extendedValidated: {
        return ExtendedValidatedBigIcon;
      }
    }
  } else {
    switch (validationLevel) {
      case DeterministicValidationResultType.notValidated: {
        return NotValidatedSmallIcon;
      }
      case DeterministicValidationResultType.selfApproved: {
        return SelfValidatedSmallIcon;
      }
      case DeterministicValidationResultType.standardValidated: {
        return StandardValidatedSmallIcon;
      }
      case DeterministicValidationResultType.extendedValidated: {
        return ExtendedValidatedSmallIcon;
      }
    }
  }
};

const getText = (validationLevel: DeterministicValidationResultType): string => {
  switch (validationLevel) {
    case DeterministicValidationResultType.notValidated: {
      return 'Not validated';
    }
    case DeterministicValidationResultType.selfApproved: {
      return 'Self Validated';
    }
    case DeterministicValidationResultType.standardValidated: {
      return 'Standard Validation';
    }
    case DeterministicValidationResultType.extendedValidated: {
      return 'Extended Validation';
    }
  }
};

const getStyledTextComponent = (
  validationLevel: DeterministicValidationResultType,
  text: string,
  size: Props['size'],
): ReactNode => {
  switch (validationLevel) {
    case DeterministicValidationResultType.notValidated: {
      return <NotValidatedText size={size}>{text}</NotValidatedText>;
    }
    case DeterministicValidationResultType.selfApproved: {
      return <SelfValidatedText size={size}>{text}</SelfValidatedText>;
    }
    case DeterministicValidationResultType.standardValidated: {
      return <StandardValidatedText size={size}>{text}</StandardValidatedText>;
    }
    case DeterministicValidationResultType.extendedValidated: {
      return <ExtendedValidatedText size={size}>{text}</ExtendedValidatedText>;
    }
  }
};

const LayoutContainer = styled.div<{ size: Props['size'] }>`
  display: flex;
  flex-direction: ${(p): string => (p.size === 'big' ? 'column' : 'row')};
  align-items: center;
`;

const StyledValidationIcon = styled(SVG)<{ size: Props['size'] }>`
  width: ${(p): string => (p.size === 'small' ? '15px' : '27px')};
  height: ${(p): string => (p.size === 'small' ? '18px' : '32px')};
`;

const ValidationText = styled.div<{ size: Props['size'] }>`
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  ${(p): string => (p.size === 'small' ? 'margin-left: 8px;' : 'margin-top: 4px;')};
`;

const NotValidatedText = styled(ValidationText)`
  color: var(--alert-color);
`;

const SelfValidatedText = styled(ValidationText)`
  color: var(--primary-500);
`;

const StandardValidatedText = styled(ValidationText)`
  color: var(--accent-blue-600);
`;

const ExtendedValidatedText = styled(ValidationText)`
  color: #b243ea;
`;
