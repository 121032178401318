import { action, computed, flow, makeObservable, observable } from 'mobx';
import paymentCheckServices from '@mortee/services/paymentCheckServices';
import Log from '@app/libs/logger';
import { handleMultiFileUploadError, SinglePaymentServerResponse } from '@mortee/domain/paymentsConsts';
import PaymentCheckBatchModel from '@mortee/models/PaymentCheckBatchModel';
import SinglePaymentModel from '@mortee/models/SinglePaymentModel';
import { translateFileNameToContentType } from '@app/domain/files';
import { RequestError } from '@app/libs/request';
import { CancellablePromise } from 'mobx/dist/api/flow';

import { compare } from '@app/utils/comparatorUtils';

export enum LoadingState {
  NotLoading,
  Loading,
  ErrorWhileLoading,
}

export const PAYMENT_BATCH_PAGE_SIZE: number = 7;

export default class PaymentCheckStore {
  @observable private _allBatchesByPage: Map<number, PaymentCheckBatchModel[]> = new Map<number, PaymentCheckBatchModel[]>();
  @observable loadingState: LoadingState = LoadingState.NotLoading;
  @observable private _currentPageIndex: number = 0;
  @observable totalBatchesCount: number = 0;

  @observable private _selectedBatch: PaymentCheckBatchModel | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentPageIndex(): number {
    return this._currentPageIndex;
  }

  @action
  setCurrentPageIndex = (currentPageIndex: number): void => {
    if (currentPageIndex !== this._currentPageIndex) {
      this._currentPageIndex = currentPageIndex;
    }
  };

  @computed
  get currentPageBatches(): PaymentCheckBatchModel[] {
    const currentPageBatches = this._allBatchesByPage.get(this._currentPageIndex);
    if (currentPageBatches) {
      return Array.from(currentPageBatches)
        .filter((batch) => batch.loaded)
        .sort(compare.writeTimestamp().reverse());
    }

    return [];
  }

  @computed
  get selectedBatch(): PaymentCheckBatchModel | null {
    return this._selectedBatch;
  }

  private loadingWrapper<R, Args extends unknown[]>(
    func: (...args: Args) => CancellablePromise<R>,
  ): (...args: Args) => CancellablePromise<R> {
    return flow<R, Args>(function* (this: PaymentCheckStore, ...args: Args) {
      try {
        this.loadingState = LoadingState.Loading;
        const result = yield func.call(this, ...args);
        this.loadingState = LoadingState.NotLoading;
        return result;
      } catch (e: unknown) {
        this.loadingState = LoadingState.ErrorWhileLoading;
        throw e;
      }
    });
  }

  resetBatches = (): void => {
    this.setCurrentPageIndex(0);
    this._allBatchesByPage.clear();
  };

  loadCurrentPageBatches = this.loadingWrapper(
    flow<any, []>(function* (this: PaymentCheckStore) {
      try {
        if (this._allBatchesByPage.get(this._currentPageIndex)?.length) {
          return;
        }

        const pageData = yield paymentCheckServices.getBatchesByIndex(
          this._currentPageIndex * PAYMENT_BATCH_PAGE_SIZE,
          PAYMENT_BATCH_PAGE_SIZE,
        );
        if (!pageData) {
          return;
        }

        if (this.totalBatchesCount != pageData.totalElements) {
          this.totalBatchesCount = pageData.totalElements;
        }

        const data = pageData.content;
        this._allBatchesByPage.set(
          this._currentPageIndex,
          data.map((batchResponse) => new PaymentCheckBatchModel(batchResponse.id, batchResponse)),
        );
      } catch (e: unknown) {
        Log.exception(e);
        throw e;
      }
    }),
  );

  storePaymentBatch = this.loadingWrapper(
    flow(function* (this: PaymentCheckStore, filesToUpload: File[], notes: string | undefined) {
      try {
        const sendForm = new FormData();
        const batchRequest = { notes };

        filesToUpload.forEach((file) =>
          sendForm.append('paymentFiles', new Blob([file], { type: translateFileNameToContentType(file.name) }), file.name),
        );

        sendForm.append(
          'request',
          new Blob([JSON.stringify(batchRequest)], {
            type: 'application/json',
          }),
        );

        const batchResponse = yield paymentCheckServices.storePaymentBatch(sendForm);
        const newBatch = new PaymentCheckBatchModel(batchResponse.id, batchResponse);
        this._allBatchesByPage.get(0)?.push(newBatch);

        return newBatch;
      } catch (e: unknown) {
        Log.exception(e);
        throw handleMultiFileUploadError(e as RequestError);
      }
    }),
  );

  getRejectedPaymentsOfBatch = this.loadingWrapper(
    flow<any, [string]>(function* (this: PaymentCheckStore, batchId: string) {
      try {
        const payments: SinglePaymentServerResponse[] = yield paymentCheckServices.getRejectedPaymentsOfBatch(batchId);
        return payments.map((payment) => {
          const { name = '', id = '' } = this._selectedBatch?.files.find((file) => file.id === payment.fileId) || {};
          return new SinglePaymentModel(payment, { id, name });
        });
      } catch (e: unknown) {
        Log.exception(e);
        throw e;
      }
    }),
  );

  @action
  setSelectedBatchById = (batchId: string | null): void => {
    if (batchId && this._selectedBatch?.id !== batchId) {
      const batchFromLoadedBatches = Array.from(this._allBatchesByPage.values())
        .flat()
        .find((batch) => batch.id === batchId);

      if (batchFromLoadedBatches) {
        this._selectedBatch = batchFromLoadedBatches;
      } else {
        this._selectedBatch = new PaymentCheckBatchModel(batchId);
      }
    }
  };

  fetchBatchFile = flow(function* (batchId: string, fileId: string) {
    try {
      return yield paymentCheckServices.fetchBatchFile(batchId, fileId);
    } catch (e: unknown) {
      Log.exception(e);
    }
  });
}
