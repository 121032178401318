import { WrappedFormUtils } from 'antd/lib/form/Form';
import { calcUpdateRequestAmountOfChanges, createUpdateRequestFromUpdateFieldsDiff } from '@app/domain/helpers/updateRequest';

interface FormInvalidStatus {
  isFormNotInitialized: true;
  storeRequest: null;
  updateRequest: null;
  amountOfChanges: 0;
  amountOfErrors: number;
}

interface FormEntityStatus<TObject extends Record<any, any>> {
  isFormNotInitialized: false;
  storeRequest: TObject;
  updateRequest: UpdateRequestObject<TObject>;
  amountOfChanges: number;
  amountOfErrors: number;
}

type FormChangesStatus<TObject extends Record<any, any>> = FormEntityStatus<TObject> | FormInvalidStatus;

export default function useFormChangesStatus<TFormFields extends object, TObject extends Record<any, any>>(
  initialDataToCompare: TObject,
  form: WrappedFormUtils<TFormFields>,
  formFieldsToUpdateFieldsFunc: (formFields: Partial<TFormFields>) => TObject | null,
  updateObjectEquator: ObjectFieldsEquators<TObject>,
): FormChangesStatus<TObject> {
  const fieldsError = form.getFieldsError();
  const fields = Object.keys(fieldsError);
  const amountOfErrors = fields.filter((field) => fieldsError[field]).length;

  const formFields = form.getFieldsValue() as Partial<TFormFields>;
  const formFieldsAllUpdateFields = formFieldsToUpdateFieldsFunc(formFields);

  // If the form is not ready yet (still loading data)
  if (!formFieldsAllUpdateFields) {
    return {
      isFormNotInitialized: true,
      storeRequest: null,
      updateRequest: null,
      isNewEntity: null,
      amountOfChanges: 0,
      amountOfErrors: amountOfErrors,
    } as FormInvalidStatus;
  }

  const updateRequest = createUpdateRequestFromUpdateFieldsDiff<TObject>(
    initialDataToCompare,
    formFieldsAllUpdateFields,
    updateObjectEquator,
  );

  const amountOfChanges = calcUpdateRequestAmountOfChanges(updateRequest);

  return {
    isFormNotInitialized: false,
    storeRequest: formFieldsAllUpdateFields,
    updateRequest,
    isNewEntity: false,
    amountOfChanges,
    amountOfErrors,
  } as FormEntityStatus<TObject>;
}
