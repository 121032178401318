import PermissionsStore from '@app/stores/PermissionsStore';
import UserStore from '@app/stores/UserStore';
import { computed, makeObservable } from 'mobx';
import { OrganizationServiceType } from '@app/domain/organizations';
import { UserRolesType } from '@app/domain/user';

export default class MorteePermissionStore extends PermissionsStore {
  constructor(allSections: Section[], userStore: UserStore) {
    super(allSections, userStore);

    makeObservable(this);
  }

  @computed
  get isCreateMvfFileOnMvfCheckEnable(): boolean {
    return this.isActionAllowed([OrganizationServiceType.OnlineMasterDataGuard], [UserRolesType.createFileSurveyRole], true);
  }

  @computed
  get isPaymentFileOnPaymentCheckEnable(): boolean {
    return this.isActionAllowed([OrganizationServiceType.paymentCheck], [UserRolesType.paymentCheckCreateRole]);
  }

  @computed
  get isMasterDataGuardEnable(): boolean {
    return this.isActionAllowed(
      [OrganizationServiceType.OnlineMasterDataGuard],
      [UserRolesType.viewFileSurveyRole, UserRolesType.createFileSurveyRole],
    );
  }

  @computed
  get isInitiateSelfApproveEnable(): boolean {
    return this.isActionAllowed([OrganizationServiceType.OnlineMasterDataGuard], [UserRolesType.initiateSelfApproveRole]);
  }

  @computed
  get isInitiateRemoveSelfApproveEnable(): boolean {
    return this.isActionAllowed([OrganizationServiceType.OnlineMasterDataGuard], [UserRolesType.initiateRemoveSelfApproveRole]);
  }

  @computed
  get isDownloadCertificateEnable(): boolean {
    return this.isActionAllowed(
      [OrganizationServiceType.accountVerificationService],
      [UserRolesType.adminRole, UserRolesType.viewValidationRequestsRole],
    );
  }

  @computed
  get isSendEmailToPayeePostValidationEnable(): boolean {
    return this.isActionAllowed(
      [OrganizationServiceType.accountVerificationService],
      [UserRolesType.adminRole, UserRolesType.viewValidationRequestsRole],
    );
  }

  @computed
  get isSendEmailToCustomerPostValidationEnable(): boolean {
    return this.isActionAllowed(
      [OrganizationServiceType.accountVerificationService],
      [UserRolesType.adminRole, UserRolesType.viewValidationRequestsRole],
    );
  }

  @computed
  get isIPSViewMyAccountsEnable(): boolean {
    return this.isActionAllowed([OrganizationServiceType.IncomingPaymentSecurityService], [UserRolesType.ipsViewMyAccountsRole]);
  }

  @computed
  get isIPSManageVerificationRemindersEnable(): boolean {
    return this.isActionAllowed([OrganizationServiceType.IncomingPaymentSecurityService], [UserRolesType.ipsViewLogsRole]);
  }

  @computed
  get isStoreValidationsEnable(): boolean {
    return this.isActionAllowed([], [UserRolesType.storeValidatedPayeesRole]);
  }

  @computed
  get isViewManageValidatedPayeesEnable(): boolean {
    return this.isActionAllowed([], [UserRolesType.viewManageValidatedPayeesRole, UserRolesType.storeValidatedPayeesRole]);
  }

  @computed
  get isAllowedToDownloadBankAccountCertificate(): boolean {
    return this.isActionAllowed([OrganizationServiceType.IncomingPaymentSecurityService], [UserRolesType.ipsViewMyAccountsRole]);
  }

  @computed
  get isAllowedToCreateValidationRecord(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.createValidationSystemRole]);
  }

  @computed
  get isAllowedToCreateValidationsReport(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.createValidationsReportSystemRole]);
  }

  @computed
  get isValidationApprover(): boolean {
    return this.isActionAllowed([], [UserRolesType.adminRole, UserRolesType.roleSvManagementValidationsApprover]);
  }
}
