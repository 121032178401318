import React, { Component, ReactElement } from 'react';
import {
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectRouteEvent,
  FinicityConnect,
} from '@finicity/connect-web-sdk';

interface Props {
  url: string;
  onDone: () => void;
  onError: () => void;
  onCancel: () => void;
}

export default class FinicityFlowConnect extends Component<Props> {
  constructor(props: Props) {
    super(props);

    FinicityConnect.launch(props.url, this.connectEventHandlers, this.connectOptions);
  }

  componentWillUnmount(): void {
    FinicityConnect.destroy();
  }

  connectEventHandlers: ConnectEventHandlers = {
    onDone: (event: ConnectDoneEvent) => {
      this.props.onDone();
    },
    onCancel: (event: ConnectCancelEvent) => {
      this.props.onCancel();
    },
    onError: (event: ConnectErrorEvent) => {
      this.props.onError();
    },
    onRoute: (event: ConnectRouteEvent) => {},
    onUser: (event: any) => {},
    onLoad: () => {},
  };

  connectOptions: ConnectOptions = {
    overlay: 'rgba(0, 0, 0, 0.2)',
  };

  render(): ReactElement {
    return <div />;
  }
}
