import NavigationStore from '@app/stores/NavigationStore';
import PermissionsStore from '@app/stores/PermissionsStore';
import GuestLoginStore from './GuestLoginStore';
import UserStore from '@app/stores/UserStore';
import LocaleStore from '@app/stores/LocaleStore';
import InfraStores from '@app/stores/InfraStores';
import HealthCheckStore from '@app/stores/HealthCheckStore';
import ThemeStore from '@app/stores/ThemeStore';
import LanguageStore from '@app/stores/LanguageStore';

export default class GuestInfraStores<
  TNavigationStore extends NavigationStore = NavigationStore,
  TPermissionsStore extends PermissionsStore = PermissionsStore,
  TThemeStore extends ThemeStore = ThemeStore
> extends InfraStores<TNavigationStore, TPermissionsStore, TThemeStore> {
  guestLoginStore: GuestLoginStore;

  constructor(
    userStore: UserStore,
    languageStore: LanguageStore,
    localeStore: LocaleStore,
    navigationStore: TNavigationStore,
    permissionsStore: TPermissionsStore,
    themeStore: TThemeStore,
    healthCheckStore: HealthCheckStore,
  ) {
    super(userStore, languageStore, localeStore, navigationStore, permissionsStore, themeStore, healthCheckStore);

    this.guestLoginStore = new GuestLoginStore();
  }

  async init(): Promise<void> {
    await super.init();
    await this.guestLoginStore.initAfterAllStoresInitialized();
  }
}

export function isGuestInfraStores(infraStores: InfraStores): infraStores is GuestInfraStores {
  return 'guestLoginStore' in infraStores;
}
