import React, { FC } from 'react';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { css } from '@emotion/css';

interface Props extends Omit<TooltipPropsWithTitle, 'overlayClassName'> {
  className?: string;
}

const TooltipUnstyled: FC<Props> = (tooltipProps) => {
  return <Tooltip {...tooltipProps} overlayClassName={classNames(cleanTooltipOverlayClass, tooltipProps.className)} />;
};

export default TooltipUnstyled;

const cleanTooltipOverlayClass = css`
  padding-bottom: 0;
  max-width: unset;

  & .ant-tooltip-arrow {
    display: none;
  }

  & .ant-tooltip-inner {
    background: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;
