import React, { FunctionComponent, ReactNode } from 'react';
import { GuestPayeeSearchVerifyResult } from '@ar/domain/arAccountVerification';
import { observer } from 'mobx-react';
import { calculateValidatedSwiftCodeToDisplay } from '@app/domain/accountsDetailsHelpers';
import VerificationValidatedAccountDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedAccountDetails';

interface PayeeAccountDetailsProps {
  validationDetails: GuestPayeeSearchVerifyResult;
  accountDetails: Partial<MorteeAccountDetails>;
  children?: ReactNode;
  className?: string;
}

const PayeeSearchGuestAccountDetails: FunctionComponent<PayeeAccountDetailsProps> = observer((props) => {
  const { validationDetails, accountDetails } = props;
  const displayAccountDetails: MorteeAccountDetails = validationDetails.validatedAccountDetails ?? {
    iban: null,
    swiftCode: null,
    countryCode: null,
    bankCode: null,
    branchCode: null,
    accountNumber: null,
    ...accountDetails,
  };

  displayAccountDetails.swiftCode = validationDetails.validated
    ? calculateValidatedSwiftCodeToDisplay(validationDetails.validatedAccountDetails?.swiftCode, accountDetails.swiftCode)
    : accountDetails.swiftCode ?? null;

  return (
    <VerificationValidatedAccountDetails
      payeeAccount={{
        presentationAccountNumber: validationDetails.presentationAccountNumber,
        accountDetails: displayAccountDetails,
        bankCountryCode: validationDetails.lastValidatedPayeeAccountEventData?.bankCountryCode ?? undefined,
        bankName: validationDetails.lastValidatedPayeeAccountEventData?.bankName ?? undefined,
        furtherCredit: validationDetails.lastValidatedPayeeAccountEventData?.furtherCredit ?? undefined,
      }}
      validationTimestamp={validationDetails.validationTimestamp}
    />
  );
});

export default PayeeSearchGuestAccountDetails;
