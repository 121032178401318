import NavigationStore from '@app/stores/NavigationStore';
import UserStore from '@app/stores/UserStore';
import PermissionsStore from '@app/stores/PermissionsStore';
import { makeObservable } from 'mobx';
import { LocationDescriptor } from 'history';

export default class ARNavigationStore extends NavigationStore {
  constructor(userStore: UserStore, permissionsStore: PermissionsStore) {
    super(userStore, permissionsStore);

    makeObservable(this);
  }

  generateStartPageLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.guestVerificationStartPage);
  }

  generateChooseBacPage(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.guestVerificationChooseBac);
  }

  generateChooseSearchPage(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.guestVerificationChooseSearch);
  }

  generatePayeeVerificationEnterAccountPageLink(validatedPayeeUniformId: string): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.guestVerificationWithPayee, { validatedPayeeUniformId });
  }

  generateCertificateVerificationPageLink(): LocationDescriptor {
    return this.generateLink(this.permissionsStore.allowedRoutesByKey.guestVerificationWithCertificate);
  }
}
