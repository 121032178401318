import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import InfoIcon from '@app/images/exclamation-primary.svg';
import SVG from '@app/components/SVG';
import { GUEST_RESULTS_TO_QUERY } from '@ar/stores/appStores/ARVerificationStore';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import Loadable from '@app/utils/Loadable';
import Button from '@app/components/Button';
import VerificationValidatedPayeeFullDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedPayeeFullDetails';
import { createNTimes } from '@app/utils/arrayUtils';
import { useTranslation } from 'react-i18next';
import HiddenAccessibilityText from '@app/components/inputs/HiddenAccessibilityText';

interface Props {
  className?: string;
  searchText: string;
  results: Loadable<AggregatedValidatedPayee[]>;
  hasTooManyPayeeSearchResults: boolean;
  onPayeeSelected(payee: AggregatedValidatedPayee): void;
}

const GuestSearchPayeeResults: FunctionComponent<Props> = observer(
  ({ className, searchText, results, hasTooManyPayeeSearchResults, onPayeeSelected }) => {
    const { t } = useTranslation();

    const renderContent = (): ReactElement => {
      return results.resolve(
        (loadedResults): ReactElement => {
          if (!loadedResults.length) {
            return (
              <ZeroResultsContainer role='status'>{t('ar.guestVerification.noResults', { searchText })}</ZeroResultsContainer>
            );
          }

          return (
            <>
              <HiddenAccessibilityText role='status'>
                {t('ar.guestVerification.resultFound', { resultsLength: loadedResults.length })}
              </HiddenAccessibilityText>
              {loadedResults.map((payee, index) => {
                return (
                  <PayeeResultButton
                    id={`btn-search-select-validated-payee-${payee.uniformId}`}
                    key={payee.uniformId}
                    index={index}
                    onClick={(): void => onPayeeSelected(payee)}
                    dataTestId='companyCard'
                    appearance='text'
                    cornerType='none'
                    colorScheme='white'
                  >
                    <VerificationValidatedPayeeFullDetails payee={payee} hideAddress hideMoreInfo />
                  </PayeeResultButton>
                );
              })}
            </>
          );
        },
        () => {
          return (
            <>
              {createNTimes(2, (index) => {
                return (
                  <PayeeResultLoading key={index} index={index} data-testid={`loading-search-select-validated-payee-${index}`}>
                    <VerificationValidatedPayeeFullDetails loading hideAddress hideMoreInfo />
                  </PayeeResultLoading>
                );
              })}
            </>
          );
        },
      );
    };

    return (
      <div className={className}>
        {hasTooManyPayeeSearchResults && (
          <MiddleCard>
            <StyledSVG accessibilityLabel='' image={InfoIcon} height={24} width={24} />
            <MiddleTextTitle role='status' data-testid='too-many-payees-results'>
              {t('ar.guestVerification.tooManyResults', { maxResults: GUEST_RESULTS_TO_QUERY })}
            </MiddleTextTitle>
          </MiddleCard>
        )}
        <ResultsCard>
          <ScrollableList>{renderContent()}</ScrollableList>
        </ResultsCard>
      </div>
    );
  },
);

export default GuestSearchPayeeResults;

const ResultsCard = styled(Card)`
  padding: 8px 0;
`;

const ScrollableList = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

const PayeeResultButton = styled(Button)<{ index: number }>`
  display: grid;
  width: 100%;
  padding: 16px 32px;
  text-align: left;
  text-align: start;

  background-color: var(${(p): string => (p.index % 2 ? '--primary-200-50-a' : '--white')});

  &:hover {
    background-color: var(--primary-200-100-a) !important;
  }
`;

const PayeeResultLoading = styled.div<{ index: number }>`
  padding: 16px 32px;
  text-align: left;
  text-align: start;

  background-color: var(${(p): string => (p.index % 2 ? '--primary-200-50-a' : '--white')});
`;

const ZeroResultsContainer = styled.div`
  padding-left: 12px;
  line-height: 20px;
`;

const StyledSVG = styled(SVG)`
  margin-right: 12px;
`;

const MiddleCard = styled(Card)`
  margin-bottom: 4px;
  padding: 13px 32px;
  display: flex;
  flex-direction: row;
`;

const MiddleTextTitle = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-300);
`;
