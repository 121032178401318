import React, { ReactNode } from 'react';
import ButtonThatOpensPopup from '@app/components/popup/ButtonThatOpensPopup';
import AssigneeWithInitials from '@mortee/components/AssigneeWithInitials';
import AssigneeSelector from '@mortee/routes/validationSystem/assigneeEdit/AssigneeSelector';
import { Assignee } from '@mortee/domain/validationSystem';
import { FormInputProps } from '@app/utils/form/form';
import Loadable from '@app/utils/Loadable';
import { ForwardingFC } from '@app/domain/technicals/components';

interface Props extends FormInputProps<Assignee, false> {
  id: string;
  dataTestId?: string;
  assignableUsersLoadable: Loadable<Assignee[]>;
  className?: string;
}

const SelectAssigneeInput: ForwardingFC<HTMLButtonElement, Props> = React.forwardRef(
  ({ id, dataTestId = id, value: currentAssignee, onChange, assignableUsersLoadable, accessibilityLabel, className }, ref) => {
    const buttonContent = <AssigneeWithInitials assignee={currentAssignee ?? null} />;

    return (
      <ButtonThatOpensPopup
        id={id}
        dataTestId={dataTestId}
        className={className}
        appearance='text'
        cornerType='circle'
        colorScheme='grey'
        shadowType='none'
        popupContent={(onDone): ReactNode => (
          <AssigneeSelector
            assignedUser={currentAssignee ?? null}
            onClose={onDone}
            onChange={(assignee): void => onChange?.(assignee)}
            assignableUsersLoadable={assignableUsersLoadable}
          />
        )}
        ref={ref}
        aria-label={currentAssignee?.name ? `${currentAssignee?.name} - ${accessibilityLabel}` : accessibilityLabel}
      >
        {buttonContent}
      </ButtonThatOpensPopup>
    );
  },
);

export default SelectAssigneeInput;
