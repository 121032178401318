import React, { FC, useState } from 'react';
import useMountEffect from '@app/hooks/useMountEffect';
import ErrorModal from '@app/components/ErrorModal';
import Log from '@app/libs/logger';
import styled from '@emotion/styled';
import useInfraStores from '@app/hooks/useInfraStores';

const HealthChecker: FC = ({ children }) => {
  const { healthCheckStore } = useInfraStores();
  const [healthCheckFailed, setHealthCheckFailed] = useState(false);
  const [cookiesCheckFailed, setCookiesCheckFailed] = useState(false);

  useMountEffect(() => {
    const healthCheckFunc = async (): Promise<void> => {
      try {
        await healthCheckStore.runHealthCheck();
      } catch (e) {
        Log.exception(e, { message: 'Could not connect to our services' });
        setHealthCheckFailed(true);
      }
    };

    healthCheckFunc();

    cookiesEnabledCheck();
  });

  const cookiesEnabledCheck = (): void => {
    if (!navigator.cookieEnabled) {
      Log.event('Cookies are disabled');
      setCookiesCheckFailed(true);
    }
  };

  if (healthCheckFailed) {
    return (
      <CenteredContainer>
        <WideErrorModal
          headerContent='Health check failed'
          bodyContent={
            <>
              A connection with the PaymentKnox™ server could not be established.
              <br />
              <br />
              This is typically due to required IP/port access out of your network to PaymentKnox being blocked.
              <br />
              <br />
              For additional information/support, please contact nsKnox at:{' '}
              <MailTo href='mailto:support@nsknox.net' target='_blank'>
                support@nsknox.net
              </MailTo>
            </>
          }
          okButtonText=''
          onDone={(): void => {}}
          hideOkButton
        />
      </CenteredContainer>
    );
  }

  if (cookiesCheckFailed) {
    return (
      <CenteredContainer>
        <WideErrorModal
          isWarning={true}
          headerContent='Cookies are disabled'
          bodyContent={
            <>
              It may impact your connection to our servers.
              <br />
              <br />
              In order to solve this, enable your cookies in your browser settings or contact nsKnox support for further
              assistance in:{' '}
              <MailTo href='mailto:support@nsknox.net' target='_blank'>
                support@nsknox.net
              </MailTo>
            </>
          }
          okButtonText=''
          onDone={(): void => {}}
          hideOkButton
        />
      </CenteredContainer>
    );
  }

  return <>{children}</>;
};

export default HealthChecker;

const MailTo = styled.a`
  font-weight: bold;
`;

const WideErrorModal = styled(ErrorModal)`
  width: 500px;
  box-shadow: var(--box-shadow-3);
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
