import React, { FC, useRef } from 'react';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import BackeeMode from '@backee/backeeMode';
import useScrollTopOnLocationChange from '@app/hooks/useScrollTopOnLocationChange';
import LoginPage from '@app/login/routes/LoginPage';
import LoggingOutPage from '@app/login/routes/LoggingOutPage';

const AuthenticationProvider: FC = observer(({ children }) => {
  const { authenticationStore } = useInfraStores<BackeeMode>();
  const appContentContainerRef = useRef<HTMLDivElement | null>(null);
  useScrollTopOnLocationChange(appContentContainerRef);

  const { isLoggedIn, logoutLoading } = authenticationStore;

  if (logoutLoading) {
    return <LoggingOutPage />;
  }

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return <>{children}</>;
});

export default AuthenticationProvider;
