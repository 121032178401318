import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import ArrowBullet from './ArrowBullet';

const ArrowBulletedLine: FunctionComponent<
  {
    bulletIcon?: SpriteSymbol;
    bulletIconClass?: string;
    className?: string;
    children: ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ bulletIcon, bulletIconClass, className, children, ...rest }) => {
  const getBulletIcon = (): ReactElement => {
    if (!bulletIcon) {
      return <ArrowBullet />;
    }

    return <DefaultStyledCustomBullet accessibilityLabel='' image={bulletIcon} className={bulletIconClass} />;
  };

  return (
    <Container {...rest} className={className}>
      {getBulletIcon()}
      <Value>{children}</Value>
    </Container>
  );
};

export default ArrowBulletedLine;

const Container = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  font-size: 14px;
  letter-spacing: 0.39px;
  line-height: normal;

  color: var(--black-weak);
`;

const DefaultStyledCustomBullet = styled(SVG)`
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;

  margin-right: 8px;

  object-fit: contain;
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
`;
