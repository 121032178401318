import { ValidatedPayee, ValidatedPayeeServerResponse } from '@app/domain/validatedPayee';
import {
  AggregatedValidatedPayee,
  AggregatedValidatedPayeeServerResponse,
  transformAggregatedValidatedPayee,
} from '@app/domain/aggregatedValidatedPayee';
import {
  transformValidatedPayeeAccount,
  ValidatedPayeeAccount,
  ValidatedPayeeAccountServerResponse,
} from '@app/domain/validatedPayeeAccount';

export function getUnifiedPayeeMainId(privateId?: string[], validatedId?: string): string {
  if (validatedId) {
    return validatedId;
  }

  return privateId?.[0] ?? '';
}

export interface ValidatedPayeeWithAccountsServerResponse {
  payee: ValidatedPayeeServerResponse;
  aggregatedPayeeData: AggregatedValidatedPayeeServerResponse;
  accounts: ValidatedPayeeAccountServerResponse[];
}

export interface ValidatedPayeeWithAccounts {
  payee: ValidatedPayee;
  aggregatedPayeeData: AggregatedValidatedPayee;
  accounts: ValidatedPayeeAccount[];
}

export function transformValidatedPayeeWithAccounts(
  serverResponse: ValidatedPayeeWithAccountsServerResponse,
): ValidatedPayeeWithAccounts {
  return {
    ...serverResponse,
    accounts: serverResponse.accounts.map(transformValidatedPayeeAccount),
    aggregatedPayeeData: transformAggregatedValidatedPayee(serverResponse.aggregatedPayeeData),
    payee: transformValidatedPayee(serverResponse.payee),
  };
}

function transformValidatedPayee(serverResponse: ValidatedPayeeServerResponse): ValidatedPayee {
  return { ...serverResponse, sanctionsScreeningInfo: serverResponse.latestSanctionsScreeningEvent };
}
