import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { InfoContent } from '@app/components/DisplayFields';
import Nationality from '../Nationality';
import withPlaceholder from '../withPlaceholder';
import VerificationValidatedPayeeDetailsMoreInfoButton from './VerificationValidatedPayeeDetailsMoreInfoButton';
import GuestPayeeDetails from './VerificationValidatedPayeeDetails';
import { Regular13TransparentBlack900 } from '@app/components/Text';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';

interface DataProps {
  payee: AggregatedValidatedPayee;
  searchText?: string;
}

interface DisplayProps {
  hideMainName?: boolean;
  hideAddress?: boolean;
  hideMoreInfo?: boolean;
  className?: string;
}

const VerificationValidatedPayeeFullDetails: FunctionComponent<DataProps & DisplayProps> = observer((props) => {
  const { className, payee, searchText, hideMainName, hideAddress, hideMoreInfo } = props;
  const { countryCode } = payee;

  return (
    <MainContainer className={className}>
      <SectionContentContainer>
        <GuestPayeeDetails
          payee={payee}
          searchText={searchText}
          hideCountry
          hideAddress={hideAddress}
          hideMainName={hideMainName}
        />
        <SectionContentRight>
          <PropTextCountryCode>
            <StyledNationality countryCode={countryCode} />
          </PropTextCountryCode>
          <SectionContentOptions>{!hideMoreInfo && <StyledMoreInfoButton payee={payee} />}</SectionContentOptions>
        </SectionContentRight>
      </SectionContentContainer>
    </MainContainer>
  );
});

const LoadingGuestPayeeDetails: FunctionComponent<DisplayProps> = observer((props) => {
  const { className, hideMainName, hideAddress } = props;

  return (
    <MainContainer className={className}>
      <SectionContentContainer>
        <GuestPayeeDetails loading hideCountry hideAddress={hideAddress} hideMainName={hideMainName} />
        <SectionContentRight>
          <PropTextCountryCode>
            <StyledNationality loading />
          </PropTextCountryCode>
        </SectionContentRight>
      </SectionContentContainer>
    </MainContainer>
  );
});

export default withPlaceholder<DisplayProps, DataProps>(VerificationValidatedPayeeFullDetails, LoadingGuestPayeeDetails);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const SectionContentOptions = styled.div`
  display: flex;
  align-self: flex-end;
`;

const PropTextCountryCode = styled(InfoContent)`
  align-self: flex-end;
`;

const StyledNationality = styled(Nationality)`
  align-self: center;
  ${Regular13TransparentBlack900.css}
`;

const StyledMoreInfoButton = styled(VerificationValidatedPayeeDetailsMoreInfoButton)`
  margin-right: calc(var(--button-line-padding) * -1);
`;
