import React, { ComponentType, ReactElement } from 'react';
import { observer } from 'mobx-react';
import Loader from '@app/components/Loader';
import { ARConfiguration } from '@ar/domain/arGeneral';
import useARDynamicSubdomain from '@ar/hooks/useARDynamicSubdomain';

export interface ARPageProps {
  configuration: ARConfiguration | null;
}

export default function withARConfiguration(Component: ComponentType<ARPageProps>): ComponentType {
  return observer(
    (props): ReactElement => {
      const configurationLoadable = useARDynamicSubdomain();

      if (!configurationLoadable.isResolved()) {
        return <Loader spinning fullScreen />;
      }

      return <Component configuration={configurationLoadable.result} {...props} />;
    },
  );
}
