import React, { FC, ReactElement } from 'react';
import { useProgressTabsContext } from '@mortee/components/ProgressTabs/ProgressTabsContext';
import { getSpecificTabId, getTabPanelId } from '@mortee/components/ProgressTabs/data';

interface ProgressTabsPanelProps {
  className?: string;
}

const ProgressTabsPanel: FC<ProgressTabsPanelProps> = ({ className }): ReactElement => {
  const { id, options, selectedTab } = useProgressTabsContext();

  const selectedItem = options.find((option) => option.key === selectedTab.value);

  const specificTabId = getSpecificTabId(id, selectedItem?.key);

  return (
    <div className={className} role='tabpanel' id={getTabPanelId(specificTabId)} aria-labelledby={specificTabId}>
      {selectedItem?.render()}
    </div>
  );
};

export default ProgressTabsPanel;
