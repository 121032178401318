import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useNonInitialEffect(effect: EffectCallback, deps?: DependencyList): void {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dependencies come from the outside and should stay consistent
  }, deps);
}
