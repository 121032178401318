import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { ValidatedPayeeAccountAttachment } from '@app/domain/validatedPayeeAccount';
import DocumentIcon from '@app/images/document-icon.svg';
import { Regular13TransparentBlack600, Regular13TransparentBlack900 } from '@app/components/Text';
import Button from '@app/components/Button';
import SVG from '@app/components/SVG';
import DownloadAttachmentIcon from '@app/images/cloud-download.svg';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { AttachmentSource } from '@app/services/attachmentServices';
import { useTranslation } from 'react-i18next';

interface AccountAttachmentsProps {
  validatedAccountAttachments: ValidatedPayeeAccountAttachment[] | undefined;
  downloadHandler: (
    source: AttachmentSource,
    payeeId: string | undefined,
    accountId: string | undefined,
    fileId: string,
  ) => Promise<void>;
  source: AttachmentSource;
}

export const AccountAttachments: FC<AccountAttachmentsProps> = observer(
  ({ validatedAccountAttachments, downloadHandler, source }) => {
    const { t } = useTranslation();
    const renderAttachment = (attachment: ValidatedPayeeAccountAttachment): ReactElement => {
      return (
        <AttachmentContainer key={attachment.id}>
          <StyledSVG accessibilityLabel={t('general.accessibility.attachment')} image={DocumentIcon} height={15} width={12} />
          <Regular13TransparentBlack900.div>{attachment.description}</Regular13TransparentBlack900.div>
          <Regular13TransparentBlack600.div>{attachment.fileMetadata.name}</Regular13TransparentBlack600.div>
          <Button
            id={`download-attch-${attachment.id}`}
            dataTestId={`download-attch-${attachment.fileMetadata.name}`}
            appearance='text'
            size='small'
            onClick={(): Promise<void> =>
              downloadHandler(
                source,
                attachment.validatedPayeeUniformId,
                attachment.validatedPayeeAccountUniformId,
                attachment.id,
              )
            }
          >
            <SVG
              accessibilityLabel={t('general.accessibility.downloadAttachment')}
              image={DownloadAttachmentIcon}
              height={18}
              width={21}
            />
          </Button>
        </AttachmentContainer>
      );
    };

    if (!validatedAccountAttachments?.length) {
      return null;
    }

    return (
      <AttachmentsContainer strokeColor={attachmentCardBorderColor}>
        {validatedAccountAttachments.map(renderAttachment)}
      </AttachmentsContainer>
    );
  },
);

export const AttachmentsContainer = styled(Card)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 0.5fr 3fr 4fr 0.5fr;
  grid-row-gap: 12px;
  align-items: center;

  & > *:not(:first-child):before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--gray-solid-200);
    grid-column: 1 / -1;
  }
`;

export const AttachmentContainer = styled.div`
  display: contents;
`;

export const attachmentCardBorderColor = '#797D8D';

const StyledSVG = styled(SVG)`
  justify-self: center;
`;
