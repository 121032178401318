import React, { FC, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import { H5StartTransparentBlack900 } from '@app/components/Text';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { searchTenantUsers } from '@app/services/userManagement/usersService';
import {
  OrganizationalUserServerResponse,
  transformOrganizationalUserToUserCandidateToOrganization,
  transformTenantUserToUserCandidateToOrganization,
  UserCandidateToOrganization,
} from '@app/domain/userManagement/organizationUsers';
import { LoadableCreator } from '@app/utils/Loadable';
import OrganizationalUsersSearchResults from '@app/components/userManagement/addUsersToOrganization/OrganizationalUsersSearchResults';
import { FormComponentProps } from 'antd/lib/form';
import { Form } from 'antd';
import useForm from '@app/hooks/useForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import { SEARCH_USERS_MIN_LENGTH } from '@app/domain/userManagement/users';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';

interface Props extends FormComponentProps<SearchFormFields> {
  organizationId: string;
  organizationName: string;
  addUserToOrganization(tenantUserId: string): Promise<OrganizationalUserServerResponse>;
  onDone(): void;
}

interface SearchFormFields {
  query: string;
}

const AddUsersToOrganizationModal: FC<Props> = (props) => {
  const { organizationId, organizationName, addUserToOrganization, onDone } = props;
  const { form, showFormErrors, setShowFormErrors } = useForm(props, { initialShowErrors: 'none' });

  const [searchUsersResults, searchUsers, setSearchUsers] = useManuallyCalledLoadable<
    UserCandidateToOrganization[],
    [searchQuery: string]
  >(
    async (searchQuery: string): Promise<UserCandidateToOrganization[]> => {
      const tenantUserServerResponses = await searchTenantUsers(searchQuery);
      return tenantUserServerResponses.map((tenantUserServerResponse) =>
        transformTenantUserToUserCandidateToOrganization(tenantUserServerResponse, organizationId),
      );
    },
  );

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Record<string, unknown>, values: SearchFormFields) => {
      if (errors) {
        setShowFormErrors('all');
        return;
      }

      searchUsers(values.query);
    });
  };

  function handleKeyPress(e: KeyboardEvent<HTMLDivElement>): void {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleOk();
    }
  }

  async function addUserToOrganizationAndUpdateList(tenantUserId: string): Promise<void> {
    const organizationalUserServerResponse = await addUserToOrganization(tenantUserId);
    const newUserCandidateToOrganization = transformOrganizationalUserToUserCandidateToOrganization(
      organizationalUserServerResponse,
    );

    if (!searchUsersResults.isResolved()) {
      return;
    }

    setSearchUsers((currentSearchUsersResult) => {
      if (!currentSearchUsersResult.isResolved()) {
        return currentSearchUsersResult;
      }

      const newSearchList = currentSearchUsersResult.result.map((oldUserCandidate) => {
        if (oldUserCandidate.tenantUserId === newUserCandidateToOrganization.tenantUserId) {
          return newUserCandidateToOrganization;
        }

        return oldUserCandidate;
      });

      return LoadableCreator.resolved(newSearchList);
    });
  }

  return (
    <MainCard id='add-users-to-org-modal'>
      <TitleLine>
        <Title>
          <H5StartTransparentBlack900.div>Add new users to {organizationName}</H5StartTransparentBlack900.div>
        </Title>
        <CloseButton id='btn-add-users-to-org-modal-close' appearance='text' colorScheme='primary' onClick={onDone}>
          <SVG accessibilityLabel='close' image={ClearIcon} height={30} />
        </CloseButton>
      </TitleLine>
      <NsknoxForm
        form={form}
        appearance='corners'
        onSubmit={handleOk}
        showErrors={showFormErrors}
        setShowErrors={setShowFormErrors}
      >
        <SearchContainer>
          <SearchFormItemBox fieldName='query' fieldDecoratorOptions={fieldDecorators.query}>
            <NakedFormInput
              name='inpt-add-users-to-org-search'
              type='text'
              autoFocus
              placeholder='🔍 Search user - Phone number / Email'
              placeholderStyle='floating'
              dataTestId='inpt-add-users-to-org-search'
              onKeyPress={handleKeyPress}
            />
          </SearchFormItemBox>
          <SearchButton id='btn-add-users-to-org-search-users' onClick={handleOk}>
            SEARCH
          </SearchButton>
        </SearchContainer>
      </NsknoxForm>
      <OrganizationalUsersSearchResults
        searchResultsLoadable={searchUsersResults}
        addUserToOrganization={addUserToOrganizationAndUpdateList}
      />
    </MainCard>
  );
};

const fieldDecorators: FormFieldDecorators<SearchFormFields> = {
  query: {
    rules: [
      {
        required: true,
        transform: trim,
        message: `Please enter a search query`,
      },
      {
        min: SEARCH_USERS_MIN_LENGTH.query,
        message: `Enter at least ${SEARCH_USERS_MIN_LENGTH.query} characters`,
      },
    ],
  },
};

export default Form.create<Props>()(AddUsersToOrganizationModal);

const MainCard = styled(Card)`
  width: min(900px, 90vw);
  min-height: 600px;
  max-height: 70vh;

  padding: 26px 32px 0;
  position: relative;

  display: flex;
  flex-direction: column;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 auto;

  margin-bottom: 4px;
`;

const Title = styled.div`
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const SearchFormItemBox = styled(FormItemBox)`
  flex: 1;
`;

const SearchButton = styled(Button)`
  margin-top: 8px;
  flex: 0 0 auto;
`;
