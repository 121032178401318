import request, { HttpMethod } from '@app/libs/request';
import config from '@app/config';
import { UserRoleTypeServerResponse } from '@app/domain/userManagement/roleTypes';

export async function getAllWebAppRoleTypes(): Promise<UserRoleTypeServerResponse[]> {
  return request<UserRoleTypeServerResponse[]>(config.serverUrls.backee, `/api/web-app-role-types`, {
    method: HttpMethod.get,
    errorsHandler: {
      default: {
        message: 'Cannot get service types.',
      },
    },
  });
}
