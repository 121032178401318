import { computed, makeObservable, observable } from 'mobx';
import Loadable, { lazyLoadable, LoadableCreator } from '@app/utils/Loadable';
import { AllKnoxers, Knoxer, KnoxerAuthType, transformKnoxer } from '@app/domain/userManagement/knoxers';
import { getAllKnoxers } from '@app/services/userManagement/knoxersService';

export default class KnoxersStore {
  @observable private _knoxers: Loadable<AllKnoxers> = LoadableCreator.notStarted();

  constructor() {
    makeObservable(this);
  }

  @computed
  get knoxers(): Loadable<AllKnoxers> {
    return lazyLoadable(
      () => this._knoxers,
      (newValue) => (this._knoxers = newValue),
      async (): Promise<AllKnoxers> => {
        const allKnoxersServerResponse = await getAllKnoxers();
        const allKnoxers = allKnoxersServerResponse.map(transformKnoxer);
        return KnoxersStore.extractAndAssertValidKnoxers(allKnoxers);
      },
    );
  }

  private static extractAndAssertValidKnoxers(allKnoxers: Knoxer[]): AllKnoxers {
    if (allKnoxers.length !== 2) {
      throw new Error(`Expected 2 knoxer, got ${allKnoxers.length}`);
    }

    const emailKnoxer = allKnoxers.find((knoxer) => knoxer.authType === KnoxerAuthType.email);

    if (!emailKnoxer) {
      throw new Error(`Expected email knoxer but could not find any`);
    }

    const phoneKnoxer = allKnoxers.find((knoxer) => knoxer.authType === KnoxerAuthType.phone);

    if (!phoneKnoxer) {
      throw new Error(`Expected phone knoxer but could not find any`);
    }

    return { emailKnoxer, phoneKnoxer };
  }
}
