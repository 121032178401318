import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { FileSurveyReport, StoredMvfFileSurveyServerResponse } from '@mortee/domain/mvfCheck';
import { downloadResourceByNamedResource } from '@app/utils/fileUtils';

export function storeMvfFileSurvey(data: FormData, isAsync: boolean = true): Promise<StoredMvfFileSurveyServerResponse> {
  return request<StoredMvfFileSurveyServerResponse>(serverUrl, `/api/surveys/automated`, {
    method: HttpMethod.post,
    params: {
      async: isAsync,
    },
    isFileUpload: true,
    data: data,
    timeout: 5400000,
    suppressNotification: true,
  });
}

export async function getAllSurveys(): Promise<StoredMvfFileSurveyServerResponse[]> {
  return request<StoredMvfFileSurveyServerResponse[]>(serverUrl, `/api/surveys/automated`, {
    method: HttpMethod.get,
    errorsHandler: {
      default: {
        message: 'Error fetching MVF surveys.',
      },
    },
  });
}

export async function getSurveysByIndex(
  surveyOffset: number,
  limit: number,
): Promise<PaginatedData<StoredMvfFileSurveyServerResponse>> {
  return request<PaginatedData<StoredMvfFileSurveyServerResponse>>(serverUrl, `/api/surveys/automated`, {
    method: HttpMethod.get,
    params: { offset: surveyOffset, limit },
    headers: {
      'Api-Version': 'v2',
    },
    errorsHandler: {
      default: {
        message: 'Error fetching MVF surveys.',
      },
    },
  });
}

export async function getFileSurveyById(surveyId: string): Promise<StoredMvfFileSurveyServerResponse> {
  return request<StoredMvfFileSurveyServerResponse>(serverUrl, `/api/surveys/automated/:surveyId`, {
    method: HttpMethod.get,
    pathParams: {
      surveyId,
    },
    errorsHandler: {
      default: {
        message: 'Error fetching MVF survey.',
      },
    },
    suppressNotification: true,
  });
}

export async function getFileSurveyReportById(surveyId: string): Promise<FileSurveyReport[]> {
  return request<FileSurveyReport[]>(serverUrl, `/api/surveys/automated/:surveyId/report`, {
    method: HttpMethod.get,
    pathParams: {
      surveyId,
    },
    errorsHandler: {
      default: {
        message: 'Error fetching MVF survey report.',
      },
    },
    timeout: 300000,
  });
}

export function fetchFileSurveyFile(surveyId: string, fileId: string): Promise<NamedResource> {
  return request<NamedResource>(serverUrl, `/api/surveys/automated/:surveyId/files/:fileId`, {
    method: HttpMethod.get,
    pathParams: {
      surveyId,
      fileId,
    },
    errorsHandler: {
      default: {
        message: 'Error while downloading MVF file.',
      },
    },
  });
}

export async function downloadChangeLogReport(surveyId: string): Promise<void> {
  const namedResource = await request<NamedResource>(serverUrl, `/api/surveys/automated/:surveyId/report/download`, {
    method: HttpMethod.get,
    pathParams: {
      surveyId,
    },
    errorsHandler: {
      default: {
        message: 'Error fetching MVF changelog report.',
      },
    },
    timeout: 400000,
  });

  downloadResourceByNamedResource(namedResource);
}
