import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader from '@app/components/header/PageHeader';
import validatedPayeeManagementServices, { editValidatedPayee } from '@mortee/services/validatedPayeeManagementServices';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import { useParams } from 'react-router-dom';
import useLoadable from '@app/hooks/loadable/useLoadable';
import { transformValidatedPayeeWithAccounts, ValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';
import Loader from '@app/components/Loader';
import { removeStrongIds } from '@app/domain/legalEntityIdentifier';
import { KnoxIdOrigin } from '@mortee/stores/appStores/ValidatedPayeesManagementStore';
import { convertEpochToDatePickerValue } from '@app/components/inputs/DatePicker';
import { getSanctionStatusForResults } from '@app/domain/validatedPayee';
import ValidatedPayeeForm, {
  ValidatedPayeeEventData,
  ValidatedPayeeFormFields,
} from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeForm';
import {
  StoreValidatedPayeeRequest,
  transformLegalEntityIdentifierToLegalIdentifierRequest,
} from '@mortee/domain/vaildatedPayeeManagement';
import { removeFirstLeadingKnoxPrefix } from '@app/utils/stringUtils';
import useIsMounted from '@app/hooks/useIsMounted';
import browserHistory from '@app/utils/browserHistory';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { shoot } from '@app/utils/messageLauncher';
import Log from '@app/libs/logger';

const EditValidatedPayeePage: FC = observer(() => {
  const { validatedPayeeUniformId } = useParams<{ validatedPayeeUniformId: string }>();
  const { navigationStore } = useInfraStores<MorteeMode>();
  const isMountedRef = useIsMounted();

  const [validatedPayeeWithAccounts] = useLoadable(async () => {
    const validatedPayeesWithAccountsServerResponses = await validatedPayeeManagementServices.getValidatedPayeesWithAccountsByUniformIds(
      [validatedPayeeUniformId],
    );
    const validatedPayeeWithAccountServerResponse = validatedPayeesWithAccountsServerResponses?.[0];

    if (!validatedPayeeWithAccountServerResponse) {
      throw new Error('Could not find validated payee with uniform id ${validatedPayeeUniformId}');
    }

    return transformValidatedPayeeWithAccounts(validatedPayeeWithAccountServerResponse);
  }, [validatedPayeeUniformId]);

  function calculateValidatedPayeeInitialValues(payee: ValidatedPayeeWithAccounts): ValidatedPayeeFormFields {
    const payeeData = payee.aggregatedPayeeData;
    const strongLegalIdentifies = payeeData.strongLegalIdentifies || {};
    const weakLegalIdentifies = removeStrongIds(payeeData.legalIdentifiers, Object.values(strongLegalIdentifies).flat());

    const [payeeTaxId1, payeeTaxId2] = strongLegalIdentifies.TaxId || [undefined, undefined];
    const payeeKnoxId =
      strongLegalIdentifies.KnoxId?.[0].value && removeFirstLeadingKnoxPrefix(strongLegalIdentifies.KnoxId?.[0].value);
    const payeeDunsId = strongLegalIdentifies.DUNS?.[0];
    const payeeCountryCode = payeeData.countryCode;

    const payeeSanctionsScreeningInfo = payee.payee.sanctionsScreeningInfo;

    return {
      primaryName: payeeData.mainName || '',
      additionalName: payeeData.names.find((name) => name !== payeeData?.mainName) || '',
      primaryAddress: payeeData.primaryFields?.address || '',
      additionalAddress: payeeData.addresses.find((address) => address !== payeeData?.primaryFields?.address) || '',
      taxId1: payeeTaxId1 ? transformLegalEntityIdentifierToLegalIdentifierRequest(payeeTaxId1) : undefined,
      taxId2: payeeTaxId2 ? transformLegalEntityIdentifierToLegalIdentifierRequest(payeeTaxId2) : undefined,
      legalId1: weakLegalIdentifies[0]
        ? transformLegalEntityIdentifierToLegalIdentifierRequest(weakLegalIdentifies[0])
        : undefined,
      legalId2: weakLegalIdentifies[1]
        ? transformLegalEntityIdentifierToLegalIdentifierRequest(weakLegalIdentifies[1])
        : undefined,
      legalId3: weakLegalIdentifies[2]
        ? transformLegalEntityIdentifierToLegalIdentifierRequest(weakLegalIdentifies[2])
        : undefined,
      duns: payeeDunsId ? transformLegalEntityIdentifierToLegalIdentifierRequest(payeeDunsId) : undefined,
      knoxIdOrigin: KnoxIdOrigin.existing,
      existingKnoxId: payeeKnoxId,
      knoxIdSuffix: undefined,
      countryCode: payeeCountryCode || '',
      sanctionsScreeningTimestamp: convertEpochToDatePickerValue(payeeSanctionsScreeningInfo?.timestamp),
      sanctionsStatus: getSanctionStatusForResults(payeeSanctionsScreeningInfo) || undefined,
      supplierValidationRegistrationNumber: undefined,
    };
  }

  function calculateValidatedPayeeEventData(payee: ValidatedPayeeWithAccounts): ValidatedPayeeEventData {
    return {
      originatingValidatedPayeeAccountEventId: payee.payee.events[0].originatingValidatedPayeeAccountEventId,
      uniformId: payee.aggregatedPayeeData.uniformId,
      ownerOrgId: payee.aggregatedPayeeData.ownerOrgId,
    };
  }

  async function updateValidatedPayee(payload: StoreValidatedPayeeRequest): Promise<void> {
    try {
      const result = await editValidatedPayee(validatedPayeeUniformId, payload);
      if (isMountedRef.current) {
        browserHistory.push(navigationStore.generateManageValidatedPayeePageHref(result.uniformId));
      }
      shoot({ type: 'success', closeable: true }, `Validated payee ${payload.primaryFields.name} edited successfully`);
    } catch (e) {
      Log.exception(e);
    }
  }

  return validatedPayeeWithAccounts.resolve<ReactElement>(
    (payee) => (
      <PageWithHeader
        width='full'
        header={
          <PageHeader backButton wrapHeaderTitle>
            <PageHeaderTitle title={`${payee.aggregatedPayeeData.mainName} - Edit Payee`} />
          </PageHeader>
        }
      >
        <ValidatedPayeeForm
          payeeEventData={calculateValidatedPayeeEventData(payee)}
          initialValues={calculateValidatedPayeeInitialValues(payee)}
          storeValidatedPayee={updateValidatedPayee}
        />
      </PageWithHeader>
    ),
    () => <Loader fullScreen spinning />,
  );
});

export default EditValidatedPayeePage;
