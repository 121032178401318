import { BodyRegularStartTransparentBlack900, Regular22TransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';

export const TabTitle = styled(Regular22TransparentBlack900.div)`
  margin-top: 24px;
  margin-bottom: 20px;
`;

export const TransparentCard = styled.div`
  padding: 20px;
  border: 1px solid var(--transparent-gray-150);

  ${BodyRegularStartTransparentBlack900.css};
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 30px var(--side-padding);

  gap: 30px;

  flex: 0 0 auto;
`;

export const SidePadding = styled.div`
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
`;
