import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { distinctValues, getListWithLeading } from '@app/utils/arrayUtils';
import { isDefined } from '@app/utils/utils';
import styled from '@emotion/styled';
import ExpanderButton from '@app/components/inputs/ExpanderButton';
import Nationality from '@app/components/Nationality';
import InnerCard from '@app/components/card/InnerCard';
import ReactPlaceholder from 'react-placeholder';
import withPlaceholder from '@app/components/withPlaceholder';
import { equalsLEI, unifyLeis } from '@app/utils/legalIdentifierUtils';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import { getFormattedLEI, LegalEntityIdentifier, removeStrongIds } from '@app/domain/legalEntityIdentifier';
import { convertTimestampToDefaultTimeString } from '@app/utils/timeUtils';
import { getSanctionStatusForResults, getTextFromSanctionStatus } from '@app/domain/validatedPayee';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import AutoHideEmptyContent from '@app/components/AutoHideEmptyContent';
import { compare } from '@app/utils/comparatorUtils';
import { ValidatedPayeeAccountVisibilityText } from '@mortee/domain/vaildatedPayeeManagement';

interface DataProps {
  data: ValidatedPayeeToDisplay;
  moreInfoHideable: boolean;
  hideMainName?: boolean;
  showUniformId?: boolean;
  showVisibility?: boolean;
  displayNotProvidedValues?: boolean;
}

interface DisplayProps {
  className?: string;
}

const VALUE_NOT_PROVIDED_TEXT = 'Not Provided';

const ValidatedPayeeDetails: FC<DataProps & DisplayProps> = ({
  data,
  showUniformId,
  showVisibility,
  hideMainName,
  moreInfoHideable,
  displayNotProvidedValues,
  className,
}) => {
  const [moreInfoExpanded, setMoreInfoExpanded] = useState(!moreInfoHideable);

  const {
    uniformId = 'new-payee',
    names,
    countryCode,
    addresses: unorderedAddresses,
    emails,
    phones,
    faxes,
    websites,
    descriptions,
    legalIdentifiers,
    strongLegalIdentifies,
    primaryFields,
    sanctionsScreeningInfo,
    sanctionsScreeningStatusResult,
    effectiveDate,
    visibility,
  } = data;

  const [primaryName, ...restOfNames] = getListWithLeading(
    names?.slice().sort(compare.stringsCaseInsensitive()),
    primaryFields?.name,
  );
  const orderedAddresses = getListWithLeading(
    unorderedAddresses?.slice().sort(compare.stringsCaseInsensitive()),
    primaryFields?.address,
  );

  const strongIdsToDisplay = {
    KnoxId: getListWithLeading(strongLegalIdentifies?.KnoxId, primaryFields?.legalIdentifier, equalsLEI),
    DUNS: getListWithLeading(strongLegalIdentifies?.DUNS, primaryFields?.legalIdentifier, equalsLEI),
    TaxId: getListWithLeading(strongLegalIdentifies?.TaxId, primaryFields?.legalIdentifier, equalsLEI),
  };

  const strongIds = strongLegalIdentifies ? Object.values(strongLegalIdentifies).filter(isDefined).flat() : [];
  const primaryFieldWithoutStrong = primaryFields?.legalIdentifier
    ? removeStrongIds([primaryFields.legalIdentifier], strongIds)
    : [];

  const unifiedLeis = getListWithLeading(
    unifyLeis(removeStrongIds(legalIdentifiers, strongIds)),
    primaryFieldWithoutStrong?.[0],
    equalsLEI,
  );

  const displayOtherIds = unifiedLeis && !!unifiedLeis?.length;

  const renderLegalIds = (legalIds: LegalEntityIdentifier[] | undefined, displayCountryCode?: boolean): ReactNode => {
    return distinctValues(legalIds?.map((value) => getFormattedLEI(value, displayCountryCode)))?.map((leiDetails) => (
      <div key={leiDetails}>{leiDetails}</div>
    ));
  };

  const sanctionStatusResult = sanctionsScreeningStatusResult
    ? getTextFromSanctionStatus(sanctionsScreeningStatusResult)
    : getTextFromSanctionStatus(getSanctionStatusForResults(sanctionsScreeningInfo));

  function calculateFieldDisplay(
    fieldKey: string,
    fieldName: string,
    fieldData: string | string[] | undefined | null,
  ): ReactNode {
    const joinedFieldData = fieldData instanceof Array ? fieldData.join(', ') : fieldData;

    if (joinedFieldData || displayNotProvidedValues) {
      return renderGridLine(fieldKey, fieldName, joinedFieldData || VALUE_NOT_PROVIDED_TEXT, `payee-details-${fieldKey}`);
    }
  }

  function renderMoreInfo(): ReactNode {
    if (!moreInfoExpanded) {
      return;
    }

    return (
      <AutoHideEmptyContent>
        <MoreInfoCard>
          <SmallKeyValueGrid hideWhenEmpty>
            {showUniformId && renderGridLine('uniformId', 'nsKnox Unique ID', uniformId, 'payee-details-uniform-id')}
            {showVisibility &&
              visibility?.value &&
              renderGridLine(
                'visibility',
                'Visibility',
                `${
                  ValidatedPayeeAccountVisibilityText[visibility.value] +
                  (visibility.organizationName ? ` - (${visibility.organizationName})` : '')
                }`,
                'payee-details-visibility',
              )}
            {!!Object.values(strongIdsToDisplay).flat().length &&
              renderGridLine(
                'ids',
                'IDs',
                <>
                  {renderLegalIds(strongIdsToDisplay?.KnoxId, false)}
                  {renderLegalIds(strongIdsToDisplay?.DUNS, false)}
                  {renderLegalIds(strongIdsToDisplay?.TaxId, true)}
                </>,
                'payee-details-ids',
              )}
            {displayOtherIds &&
              renderGridLine('otherIds', 'Other IDs', renderLegalIds(unifiedLeis, true), 'payee-details-other-ids')}
          </SmallKeyValueGrid>
          <BigKeyValueGrid hideWhenEmpty>
            {calculateFieldDisplay('address', 'Address', orderedAddresses)}
            {calculateFieldDisplay('email', 'Email', emails)}
            {calculateFieldDisplay('phone', 'Phone', phones)}
            {calculateFieldDisplay('fax', 'Fax', faxes)}
            {calculateFieldDisplay('website', 'Website', websites)}
            {calculateFieldDisplay('description', 'Description', descriptions)}
            {calculateFieldDisplay(
              'sanctions',
              'Sanctions screening date',
              convertTimestampToDefaultTimeString(sanctionsScreeningInfo?.timestamp),
            )}
            {calculateFieldDisplay('sanctionsStatus', 'Sanctions screening status', sanctionStatusResult)}
            {calculateFieldDisplay('effectiveDate', 'Effective date', convertTimestampToDefaultTimeString(effectiveDate))}
          </BigKeyValueGrid>
        </MoreInfoCard>
      </AutoHideEmptyContent>
    );
  }

  return (
    <div className={className}>
      {!hideMainName && <PayeeMainTitle data-testid='payee-main-name'>{primaryName}</PayeeMainTitle>}
      {(restOfNames.length || countryCode || moreInfoHideable) && (
        <PayeeSubTitle>
          <PayeeSubTitleFlexibleContent>
            {!!restOfNames.length && (
              <AlsoKnownAs data-testid='payee-aka'>
                <InfoCategory>Also known as:</InfoCategory>&nbsp;
                <InfoValue data-testid='payee-aka-value'>{restOfNames.join(', ')}</InfoValue>
              </AlsoKnownAs>
            )}
            {countryCode && <StyledNationality countryCode={countryCode} />}
          </PayeeSubTitleFlexibleContent>
          {moreInfoHideable && (
            <StyledExpanderButton
              expanded={moreInfoExpanded}
              setExpanded={setMoreInfoExpanded}
              id={`btn-expand-validated-payee-${uniformId}-more-info-${moreInfoExpanded}`}
              appearance='text'
              size='small'
            >
              {moreInfoExpanded ? 'SHOW LESS' : 'MORE INFO'}
            </StyledExpanderButton>
          )}
        </PayeeSubTitle>
      )}
      {renderMoreInfo()}
    </div>
  );
};

const Placeholder: FC<DisplayProps> = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <PayeeMainTitle>
        <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 190, margin: '0.2em' }} />
      </PayeeMainTitle>
      <PayeeSubTitle>
        <PayeeSubTitleFlexibleContent>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 500, margin: '0.2em' }} />
        </PayeeSubTitleFlexibleContent>
      </PayeeSubTitle>
    </div>
  );
};

const renderGridLine = (key: string | number, title: ReactNode, value: ReactNode, valueDataTest: string): ReactElement => {
  return (
    <DataGridRow key={key} title={title} valueDataTest={valueDataTest}>
      {value}
    </DataGridRow>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(ValidatedPayeeDetails, Placeholder);

const PayeeMainTitle = styled.div`
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: var(--transparent-black-900);
`;

const PayeeSubTitle = styled.div`
  display: flex;
  align-items: flex-end;

  padding-top: 12px;
`;

const PayeeSubTitleFlexibleContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledExpanderButton = styled(ExpanderButton)`
  flex: 0 0 auto;
  width: 14ch;
`;

const AlsoKnownAs = styled.div`
  margin-right: 12px;
`;

const StyledNationality = styled(Nationality)`
  flex: 0 0 auto;

  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: var(--transparent-black-600);
`;

const MoreInfoCard = styled(InnerCard)`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  padding-top: calc(28px - var(--info-row-gap, 10px));
`;

const SmallKeyValueGrid = styled(DataGrid)`
  flex: 400px 0 0;
  margin-right: 16px;
`;

const BigKeyValueGrid = styled(DataGrid)`
  flex: 400px 1 1;
`;

export const InfoCategory = styled.span`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: var(--transparent-black-900);
`;

export const InfoValue = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: var(--transparent-black-600);

  & > *:not(:first-child) {
    margin-top: var(--info-row-gap, 10px);
  }
`;
