import React, { FC } from 'react';
import { SupplierValidationRecordStatus } from '@mortee/domain/validationSystem';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { validationRecordStatusSpecialComponents } from '@mortee/domain/validationSystemInfoComponents';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import Card from '@app/components/card/Card';

interface Props {
  onSelect(newValue: SupplierValidationRecordStatus): void;
  options?: SupplierValidationRecordStatus[];
  className?: string;
}

const ValidationRecordStatusSelector: FC<Props> = (props) => {
  const { onSelect, options = Object.values(SupplierValidationRecordStatus), className } = props;

  function handleSelectStatus(selectedStatus: SupplierValidationRecordStatus): void {
    onSelect(selectedStatus);
  }

  return (
    <ClickEventPropagationBlocker>
      <Container className={className} shadowType='elevated'>
        {options.map((status) => (
          <ValidationRecordStatusButton
            key={status}
            id={`btn-set-status-${status}`}
            appearance='text'
            cornerType='none'
            colorScheme='grey'
            onClick={(): void => handleSelectStatus(status)}
          >
            {validationRecordStatusSpecialComponents.render(status)}
          </ValidationRecordStatusButton>
        ))}
      </Container>
    </ClickEventPropagationBlocker>
  );
};

export default ValidationRecordStatusSelector;

const Container = styled(Card)`
  display: grid;
  padding: 0;
`;

const ValidationRecordStatusButton = styled(Button)`
  padding: 15px 22px;
`;
