import React, { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import Button from './Button';
import ApprovedIcon from '@app/images/ic-approved-white.svg';

interface ErrorModalProps {
  headerContent: ReactNode;
  bodyContent: ReactNode;
  okButtonText: string;
  onDone: () => void;
  className?: string;
}

const SuccessModal: FunctionComponent<ErrorModalProps> = (props) => {
  const { headerContent, bodyContent, okButtonText, onDone, className } = props;

  return (
    <MainContainer className={className}>
      <Header>
        <StyledGoodMatchIcon image={ApprovedIcon} accessibilityLabel={''} />
        <div>{headerContent}</div>
      </Header>
      <Content>
        <Text>{bodyContent}</Text>
        <OKButton id='modal-ok' appearance='outline' onClick={onDone}>
          {okButtonText}
        </OKButton>
      </Content>
    </MainContainer>
  );
};

export default SuccessModal;

const MainContainer = styled.div`
  width: 400px;
  background-color: white;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: white;

  padding: 24px 24px 14px;

  background-color: #396b49;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Text = styled.div`
  font-size: 15px;
  line-height: 1.33;
  color: var(--transparent-black-600);
  white-space: pre-line;
  align-self: center;
`;

const StyledGoodMatchIcon = styled(SVG)`
  width: 64px;
  height: 64px;
`;

const OKButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 32px;
`;
