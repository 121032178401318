import React, { FC } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useAppStores from '@app/hooks/useAppStores';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import PageWithHeader from '@app/components/PageWithHeader';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import browserHistory from '@app/utils/browserHistory';
import useInfraStores from '@app/hooks/useInfraStores';
import { BodyRegularStartPrimary200, BodySmallStartTransparentBlack600, Bold16Primary300 } from '@app/components/Text';
import { Upload } from 'antd';
import ReactiveDragger from '@app/components/fileUpload/ReactiveDragger';
import MorteeMode from '@mortee/morteeMode';
import SearchPayee from './initialPage/SearchPayee';
import useMountEffect from '@app/hooks/useMountEffect';

const AccountVerificationInitialPage: FC = observer(() => {
  useInnerPageEnterAnalyticsReport(InnerPage.verify);
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { payeeSearchStore } = useAppStores<MorteeMode>();

  useMountEffect(() => {
    // initial cleanup
    payeeSearchStore.resetAll();
  });

  const onPayeeSelected = (payee: UnifiedPayee): void => {
    payeeSearchStore.resetToAfterPayeeSelected(payee);
    browserHistory.push(navigationStore.generateAccountVerificationWithPayeeLink(payee.ids));
  };

  const handleBeforeUpload = (file: File): boolean => {
    payeeSearchStore.resetToAfterCertificateSelected(file);
    browserHistory.push(navigationStore.generateAccountVerificationWithBankAccountCertificateLink());
    return false;
  };

  return (
    <PageWithHeader paddingTop={52} width={850} widthReactive={false}>
      <ReactiveDragger
        id='drag-account-verification-add-certificate'
        showUploadList={false}
        openFileDialogOnClick={false}
        beforeUpload={(file): boolean => handleBeforeUpload(file)}
      >
        <MainCard>
          <MainCardInnerTitle>Enter payee details</MainCardInnerTitle>
          <SearchPayee onPayeeSelected={onPayeeSelected} />
          <CenteredText>
            <OR>OR</OR>
            <BodyRegularStartPrimary200.div>
              Drag & drop file or{' '}
              <StyledUpload accept='.pdf' showUploadList={false} beforeUpload={(file): boolean => handleBeforeUpload(file)}>
                <UnderlinedLink data-testid='upload-account-verification-add-certificate'>click to upload</UnderlinedLink>
              </StyledUpload>{' '}
              the nsKnox Bank Account Certificate
            </BodyRegularStartPrimary200.div>
          </CenteredText>
        </MainCard>
      </ReactiveDragger>
    </PageWithHeader>
  );
});

export default AccountVerificationInitialPage;

const MainCard = styled(Card)`
  padding: 34px 40px 55px;
`;

const MainCardInnerTitle = styled(Bold16Primary300.div)`
  margin-bottom: 24px;
`;

const CenteredText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OR = styled(BodySmallStartTransparentBlack600.div)`
  padding: 40px;
`;

const StyledUpload = styled(Upload)`
  & .ant-upload {
    // Fix ant-upload text css override
    ${BodyRegularStartPrimary200.css};
  }
`;

const UnderlinedLink = styled.a`
  text-decoration: underline !important;
`;
