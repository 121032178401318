import React, { PropsWithChildren, ReactElement } from 'react';
import styled from '@emotion/styled';
import { CaptionStartTransparentBlack900 } from '@app/components/Text';
import { ColorSchemeObject, getColorScheme } from '@app/domain/theme';
import CheckImage from '@app/images/check-secondary-300.svg';
import SVG from '@app/components/SVG';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import { DEFAULT_EQUALS_FUNCTION_CASE_INSENSITIVE } from '@app/utils/utils';
import Button from '@app/components/Button';

export type Suggestion<TValue> = {
  name: string;
  value: TValue;
  displayValue: string | null | undefined;
  theme?: ColorSchemeObject;
};

interface Props<TValue> {
  value: TValue | undefined;
  suggestions: Suggestion<TValue>[];
  disabled?: boolean;
  dataTestId: string;
  className?: string;
  onChange(newValue: TValue | undefined): void;
  equalityCheck?(inputValue: TValue | undefined, suggestionValue: TValue): boolean;
}

const ValueSuggestions = <TValue,>(props: PropsWithChildren<Props<TValue>>): ReactElement => {
  const {
    value,
    onChange,
    suggestions,
    equalityCheck = DEFAULT_EQUALS_FUNCTION_CASE_INSENSITIVE,
    disabled = false,
    dataTestId,
    className,
  } = props;

  if (!suggestions.length) {
    return <></>;
  }

  function onSuggestionClicked(isValueEqual: boolean, selectedSuggestionValue: TValue): void {
    if (disabled) {
      return;
    }

    if (isValueEqual) {
      return onChange?.(undefined);
    }

    return onChange?.(selectedSuggestionValue);
  }

  return (
    <SuggestionsContainer className={className} data-testid={dataTestId}>
      {suggestions.map((suggestion) => {
        const isValueEqual = equalityCheck(value, suggestion.value);

        const valueToDisplay = suggestion.displayValue ?? suggestion.value;

        return (
          <ValueChip
            id={`form-item-value-suggestion-${suggestion.name}`}
            data-testid={`form-item-value-suggestion-${suggestion.name}`}
            selected={isValueEqual}
            aria-selected={isValueEqual}
            disabled={disabled}
            aria-disabled={disabled}
            key={suggestion.name}
            onClick={(): void => onSuggestionClicked(isValueEqual, suggestion.value)}
            appearance='outline'
            chipColor={suggestion.theme}
          >
            <SuggestionCategory>{suggestion.name}:</SuggestionCategory>
            <SuggestionValue>{valueToDisplay}</SuggestionValue>
            <CheckSVGContainer isVisible={isValueEqual}>
              <CheckSVG image={CheckImage} accessibilityLabel={`suggestion ${suggestion.name} - ${valueToDisplay} selected`} />
            </CheckSVGContainer>
          </ValueChip>
        );
      })}
    </SuggestionsContainer>
  );
};

export default ValueSuggestions;

const SuggestionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  --gap-between-suggestions: 30px;
  column-gap: var(--gap-between-suggestions);
  margin-bottom: 15px;
`;

function getChipColor(color: ColorSchemeObject | null | undefined): string {
  if (color?.main) {
    return color.main;
  }
  return `${getColorScheme('secondary300').main}18`;
}

const ValueChip = styled(Button)<{
  selected: boolean;
  disabled: boolean;
  chipColor: ColorSchemeObject | null | undefined;
}>`
  max-width: calc(50% - (var(--gap-between-suggestions) / 2)); /* Deducting half the parent gap from each child */

  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.2em 0.6em;
  margin-top: 10px;

  ${CaptionStartTransparentBlack900.css}

  --chip-outline-color: ${(p): string => (p.selected ? getColorScheme('secondary300').main : getColorScheme('grey').main)};
  --chip-text-color: ${(p): string => (p.selected ? getColorScheme('secondary300').main : getColorScheme('primary').main)};
  --chip-background: ${(p): string => (p.selected ? `${getChipColor(p.chipColor)}` : 'transparent')};
  --chip-shadow: ${(p): string => (p.selected ? `var(--box-shadow-1)` : 'none')};

  &:hover {
    --chip-shadow: ${(p): string => (p.selected || !p.disabled ? `var(--box-shadow-1)` : 'none')};
  }

  border: var(--chip-outline-color) 1px solid;
  background: var(--chip-background);
  color: var(--chip-text-color);
  box-shadow: var(--chip-shadow);

  transition: border 0.2s, color 0.2s, background-color 0.2s, box-shadow 0.2s;

  &:focus {
    filter: brightness(0.9);
  }

  line-height: 1.5;
  // For chip ellipsis purposes
  overflow: hidden;

  border-radius: 4px;

  ${(p): string =>
    p.disabled
      ? `
    cursor: default;
    opacity: 0.7;
    `
      : ''}
`;

const SuggestionCategory = styled.span`
  flex: 0 0 auto;
  font-weight: bold;
  ${WRAP_WORDS_CSS_PROPERTIES};
`;

const SuggestionValue = styled.span`
  flex: 1;
  font-weight: bold;
  ${WRAP_WORDS_CSS_PROPERTIES};

  margin-left: 5px;
`;

const CheckSVGContainer = styled.div<{ isVisible: boolean }>`
  align-self: center;

  flex: 0 0 auto;
  min-height: 16px;
  max-height: 16px;
  line-height: 0;
  overflow: hidden;
  transition: min-width 0.2s, max-width 0.2s;

  ${(p): string => {
    return p.isVisible
      ? `
    min-width: 16px;
    max-width: 16px;
    `
      : `
    min-width: 0;
    max-width: 0;
    `;
  }}
`;

const CheckSVG = styled(SVG)`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
`;
