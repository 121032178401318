import React, { FC } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DEFAULT_FORM_VALIDATION_OPTIONS, FormFieldDecorators } from '@app/utils/form/form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { trim } from '@app/utils/stringUtils';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import NakedFormChipList from '@app/components/inputs/NakedFormChipList';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import Loadable from '@app/utils/Loadable';
import { Regular15TransparentBlack600 } from '@app/components/Text';
import { arrayValidator, patternValidator, patternValidatorSynchronous } from '@app/utils/validators';
import { VERIFICATION_REMINDERS_FIELD_MAX_LENGTH } from '@mortee/domain/verificationReminderFields';

export enum ReminderTimePeriod {
  Days3 = '3',
  Days7 = '7',
  Days14 = '14',
  Days30 = '30',
}

const reminderTimePeriodText: Record<ReminderTimePeriod, string> = {
  [ReminderTimePeriod.Days3]: '3 days',
  [ReminderTimePeriod.Days7]: '7 days',
  [ReminderTimePeriod.Days14]: '14 days (default)',
  [ReminderTimePeriod.Days30]: '30 days',
};

export interface CreateVerificationReminderFormFields {
  payerName: string;
  emailsAndDomains: string[];
  accountUniformIdToMonitor: string;
  timePeriod: ReminderTimePeriod;
}

interface Props extends FormComponentProps<CreateVerificationReminderFormFields> {
  initialFormValues: CreateVerificationReminderFormFields | null;
  accountsOfUserByUniformId: Loadable<Map<string, string>>;
  className?: string;
  onFormSubmit(values: CreateVerificationReminderFormFields): void;
}

const CreateVerificationReminderFormModal: FC<Props> = (props) => {
  const { onFormSubmit, accountsOfUserByUniformId, initialFormValues, className } = props;

  const { form, isFormInvalid, showFormErrors, setShowFormErrors } = useForm(props);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleOk();
  };

  const handleOkClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await handleOk();
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll(DEFAULT_FORM_VALIDATION_OPTIONS, (errors: Object, values: CreateVerificationReminderFormFields) => {
      if (errors) {
        setShowFormErrors('all');
        return;
      }

      onFormSubmit(values);
    });
  };

  const fieldDecorators = createFieldDecorators(initialFormValues);

  return (
    <NsknoxForm
      onSubmit={handleFormSubmit}
      form={form}
      appearance='corners'
      showErrors={showFormErrors}
      className={className}
      setShowErrors={setShowFormErrors}
    >
      <FormItemBox fieldName='payerName' fieldDecoratorOptions={fieldDecorators.payerName}>
        <NakedFormInput
          name='txt-create-verification-reminder-payer-name'
          dataTestId='txt-create-verification-reminder-payer-name'
          type='text'
          placeholder='Payer name'
          disableSuggestion
        />
      </FormItemBox>
      <FormItemBox fieldName='emailsAndDomains' fieldDecoratorOptions={fieldDecorators.emailsAndDomains}>
        <NakedFormChipList
          id='txt-create-verification-reminder-emails-and-domains'
          dataTestId='txt-create-verification-reminder-emails-and-domains'
          placeholder='Payer emails or domains'
          pasteItemsSeparators={[';', ',']}
          arrayItemValidator={patternValidatorSynchronous(
            VALIDATION_PATTERNS.domainOrEmail,
            (invalidValue) => `"${invalidValue}" is an invalid domain or email`,
          )}
        />
      </FormItemBox>
      {accountsOfUserByUniformId.resolve(
        (loadedAllPayeeAndAccountPairs) => (
          <FormItemBox fieldName='accountUniformIdToMonitor' fieldDecoratorOptions={fieldDecorators.accountUniformIdToMonitor}>
            <NakedDropdown
              accessibilityLabel='your account'
              name='txt-create-verification-reminder-your-account'
              dataTestId='txt-create-verification-reminder-your-account'
              placeholder='Your account'
            >
              {Array.from(loadedAllPayeeAndAccountPairs.entries()).map(([accountUniformId, accountDisplayText]) => {
                return (
                  <StyledDropdownItem key={accountUniformId} value={accountUniformId} textWhenSelected={accountDisplayText}>
                    <div>{accountDisplayText}</div>
                  </StyledDropdownItem>
                );
              })}
            </NakedDropdown>
          </FormItemBox>
        ),
        () => (
          <FormItemBox
            fieldName='accountUniformIdToMonitor'
            fieldDecoratorOptions={fieldDecorators.accountUniformIdToMonitor}
            disabled
          >
            <NakedDropdown
              accessibilityLabel='your account'
              name='txt-create-verification-reminder-your-account'
              dataTestId='txt-create-verification-reminder-your-account'
              placeholder='Your account'
              loading
              children={[]}
            />
          </FormItemBox>
        ),
      )}
      <FormItemBox fieldName='timePeriod' fieldDecoratorOptions={fieldDecorators.timePeriod}>
        <NakedDropdown
          accessibilityLabel='time period'
          name='txt-create-verification-time-period'
          dataTestId='txt-create-verification-reminder-time-period'
          placeholder='Expiration date'
        >
          {Object.values(ReminderTimePeriod).map((reminderTimePeriod) => {
            return (
              <StyledDropdownItem
                key={reminderTimePeriod}
                value={reminderTimePeriod}
                textWhenSelected={reminderTimePeriodText[reminderTimePeriod]}
              >
                <div>{reminderTimePeriodText[reminderTimePeriod]}</div>
              </StyledDropdownItem>
            );
          })}
        </NakedDropdown>
      </FormItemBox>
      <ActionsContainer>
        <Button id='btn-create-verification-reminder-submit' disabled={isFormInvalid} onClick={handleOkClick}>
          CONTINUE {'>'}
        </Button>
      </ActionsContainer>
    </NsknoxForm>
  );
};

export default Form.create<Props>()(CreateVerificationReminderFormModal);

const createFieldDecorators = (
  initialValues: CreateVerificationReminderFormFields | null,
): FormFieldDecorators<CreateVerificationReminderFormFields> => ({
  payerName: {
    initialValue: initialValues?.payerName,
    rules: [
      {
        required: true,
        transform: trim,
        message: `Missing Payer Name`,
      },
      {
        max: VERIFICATION_REMINDERS_FIELD_MAX_LENGTH.payerName,
        message: `Max ${VERIFICATION_REMINDERS_FIELD_MAX_LENGTH.payerName} characters`,
      },
    ],
  },
  accountUniformIdToMonitor: {
    initialValue: initialValues?.accountUniformIdToMonitor,
    rules: [
      {
        required: true,
        transform: trim,
        message: `Missing Account`,
      },
    ],
  },
  emailsAndDomains: {
    initialValue: initialValues?.emailsAndDomains,
    rules: [
      {
        required: true,
        type: 'array',
        min: 1,
        message: `Missing Emails and Domains`,
      },
      {
        type: 'array',
        max: VERIFICATION_REMINDERS_FIELD_MAX_LENGTH.domainOrEmailAmount,
        message: `Max ${VERIFICATION_REMINDERS_FIELD_MAX_LENGTH.domainOrEmailAmount} domains or emails`,
      },
      {
        validator: arrayValidator(
          patternValidator(
            VALIDATION_PATTERNS.domainOrEmail,
            (invalidValue) => `"${invalidValue}" is an invalid domain or email`,
          ),
          'One of the values is invalid',
        ),
      },
    ],
  },
  timePeriod: {
    initialValue: initialValues?.timePeriod ?? ReminderTimePeriod.Days14,
    rules: [
      {
        required: true,
        message: `Missing Account`,
      },
    ],
  },
});

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;

  // To allow the buttons drop shadow to fully show
  padding: 10px var(--side-padding) 8px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  ${Regular15TransparentBlack600.css};
  padding: 12px 28px;
`;
