import React, { FC, ReactNode, useState } from 'react';
import BackeeMode from '@backee/backeeMode';
import { observer } from 'mobx-react';
import CheckboxCardsList from '@app/components/CheckboxCardsList';
import { wrapValueAsArray } from '@app/utils/arrayUtils';
import PermissionListItem from '@app/components/PermissionListItem';
import useInfraStores from '@app/hooks/useInfraStores';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import { IdentityProvider } from '@app/domain/userManagement/identityProviders';
import Loadable from '@app/utils/Loadable';

interface Props {
  id: string;
  dataTestId: string;
  identityProviders: Loadable<IdentityProvider[]>;
  activeIdentityProviders: string[];
  className?: string;
  onServiceAdd(identityProviderName: string): Promise<void>;
  onServiceRemove(identityProviderName: string): Promise<void>;
}

const ManageOrganizationIdentityProviders: FC<Props> = observer(
  ({ id, dataTestId, identityProviders, activeIdentityProviders, onServiceAdd, onServiceRemove, className }) => {
    const { permissionsStore } = useInfraStores<BackeeMode>();

    const [filteredData, searchTerm, setSearchTerm] = useLoadableSearchFilter(identityProviders, {
      partialMatches(identityProvider) {
        return [identityProvider.name];
      },
    });

    const [changingServiceId, setChangingServiceId] = useState<string | undefined>();

    async function onItemAddRequest(id: string): Promise<void> {
      setChangingServiceId(id);

      try {
        await onServiceAdd(id);
      } finally {
        setChangingServiceId(undefined);
      }
    }

    async function onItemRemoveRequest(id: string): Promise<void> {
      setChangingServiceId(id);

      try {
        await onServiceRemove(id);
      } finally {
        setChangingServiceId(undefined);
      }
    }

    return (
      <div>
        <InputBox appearance='line' colorScheme='secondary'>
          <NakedFormInput
            name={`${id}-search`}
            dataTestId={`${dataTestId}-search`}
            type='text'
            colorScheme='primary'
            placeholderStyle='onlyWhenEmpty'
            heightType='thick'
            clearable
            placeholder='🔍 Search for Identity Provider Name'
            value={searchTerm}
            onChange={(newValue): void => setSearchTerm(newValue ?? '')}
            autoFocus
          />
        </InputBox>
        <CheckboxCardsList
          id={id}
          dataTestId={dataTestId}
          contentColumnTitle='IdentityProviders'
          readonly={!permissionsStore.isEditAllowed}
          activeIds={activeIdentityProviders}
          changingIds={wrapValueAsArray(changingServiceId)}
          allItems={filteredData}
          itemRenderer={(identityProvider: IdentityProvider): ReactNode => {
            return <PermissionListItem permissionsId={identityProvider.name} displayName={identityProvider.type} />;
          }}
          itemKeywordsGetter={(identityProvider: IdentityProvider): string[] => {
            return [identityProvider.id, identityProvider.name];
          }}
          onAdd={onItemAddRequest}
          onRemove={onItemRemoveRequest}
          className={className}
        />
      </div>
    );
  },
);

export default ManageOrganizationIdentityProviders;
