import styled from '@emotion/styled';
import { Bold13TransparentGrey800 } from '@app/components/Text';

export const Subtitle = styled(Bold13TransparentGrey800.div)`
  margin-top: 21px;
  margin-bottom: 4px;

  &:first-child {
    margin-top: 0;
  }
`;
