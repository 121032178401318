import { trim } from '@app/utils/stringUtils';
import { ACCOUNT_FIELD_MAX_LENGTH, INFORMATION_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ConditionalValidationRule } from '@app/utils/form/form';
import { CURRENCIES_KEYS } from '@app/domain/currencies';

enum KoreanLegalIdTypesForCompany {
  BRN = 'Business Registration Number',
}

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-KR-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.KR.bank,
        transform: trim,
        translatedMessage: {
          key: `supplierValidation.supplierRegister.KR.errors.bankCodeLength`,
          value: ACCOUNT_FIELD_MAX_LENGTH.KR.bank,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.numeric,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.KR.errors.invalidBankCode`,
          `supplierValidation.supplierRegister.errors.invalidBankCode`,
        ],
      },
    ],
  };
}

function getAdditionalSwiftRules(form: WrappedFormUtils<BaseFormFields>): ConditionalValidationRule[] {
  return [
    {
      required: form.getFieldValue('currency') === CURRENCIES_KEYS.USD,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

export const KR: SupplierRegistrationCountryFields = {
  additionalPersonalName: true,
  additionalCompanyName: true,
  showDateOfBirth: true,
  leis: { types: KoreanLegalIdTypesForCompany },
  legalIdTypesForCompany: [
    {
      key: KoreanLegalIdTypesForCompany.BRN,
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingLegalIdValue',
        },
        {
          len: INFORMATION_FIELD_MAX_LENGTH.KR.BNR,
          transform: trim,
          translatedMessage: `supplierValidation.supplierRegister.KR.errors.legalIdValueLength`,
        },
        {
          pattern: VALIDATION_PATTERNS.numeric,
          transform: trim,
          translatedMessage: `supplierValidation.supplierRegister.KR.errors.legalIdValueLength`,
        },
      ],
    },
  ],
  bankCode: getBankCodeFields,
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
};
