import Loadable, { LoadableCreator } from '@app/utils/Loadable';
import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';
import useIsMounted from '@app/hooks/useIsMounted';
import Log from '@app/libs/logger';

type ArgsAndAbortSignal<Args extends readonly unknown[] = []> = [...Args, AbortSignal];
type LoadFunctionWithAbortSignal<TLoadFuncArgs extends unknown[], TResult> = (
  ...args: ArgsAndAbortSignal<TLoadFuncArgs>
) => Promise<TResult>;

interface LoadableLoadFunction<TResult, TLoadFuncArgs extends unknown[]> {
  (...args: TLoadFuncArgs): Promise<void>;
  silent(...args: TLoadFuncArgs): Promise<void>;
}

export type LoadableResponse<TResult, TLoadFuncArgs extends unknown[]> = [
  loadable: Loadable<TResult>,
  loadFunc: LoadableLoadFunction<TResult, TLoadFuncArgs>,
  setLoadable: Dispatch<SetStateAction<Loadable<TResult>>>,
  currentlyLoadingAbortControllerRef: MutableRefObject<AbortController | undefined>,
];

export default function useManuallyCalledLoadable<TResult, TLoadFuncArgs extends unknown[] = []>(
  loadFunc: LoadFunctionWithAbortSignal<TLoadFuncArgs, TResult>,
): LoadableResponse<TResult, TLoadFuncArgs> {
  const isMounted = useIsMounted();
  const currentlyLoadingAbortControllerRef = useRef<AbortController>();

  const [loadable, setLoadable] = useState<Loadable<TResult>>(LoadableCreator.notStarted());

  function wrapLoadFuncAsLoadableSetterFunc(
    customLoadFunc: (...args: ArgsAndAbortSignal<TLoadFuncArgs>) => Promise<TResult>,
    isSilentLoading: boolean,
  ): (...args: TLoadFuncArgs) => Promise<void> {
    return async (...args: TLoadFuncArgs) => {
      currentlyLoadingAbortControllerRef.current?.abort();

      const thisRunAbortController = new AbortController();
      currentlyLoadingAbortControllerRef.current = thisRunAbortController;

      const setter = (newValue: Loadable<TResult>): void => {
        if (isMounted.current && !thisRunAbortController.signal.aborted) {
          setLoadable(newValue);
        }
      };

      if (!isSilentLoading) {
        setter(LoadableCreator.inProgress<TResult>());
      }

      try {
        const result = await customLoadFunc(...args, thisRunAbortController.signal);

        setter(LoadableCreator.resolved(result));
      } catch (e: unknown) {
        Log.exception(e);

        if (!isSilentLoading) {
          setter(LoadableCreator.rejected(e));
        }

        throw e;
      } finally {
        if (currentlyLoadingAbortControllerRef.current === thisRunAbortController) {
          currentlyLoadingAbortControllerRef.current = undefined;
        }
      }
    };
  }

  const loadLoadableFunc: LoadableLoadFunction<TResult, TLoadFuncArgs> = Object.assign(
    wrapLoadFuncAsLoadableSetterFunc(loadFunc, false),
    {
      silent: wrapLoadFuncAsLoadableSetterFunc(loadFunc, true),
    },
  );

  return [loadable, loadLoadableFunc, setLoadable, currentlyLoadingAbortControllerRef];
}
