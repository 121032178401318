import React, { FC, ReactElement, ReactNode } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import CategoryAndValues from '@app/components/CategoryAndValues';
import { Diff, DiffState } from '@app/utils/diffUtils';

interface MvfVendorInfoProps {
  internalId: string;
  names: Diff;
  legalIdentifiers: Diff;
  countryCode: Diff;
  showErpFields: boolean;
  supplierNumbers: Diff;
  companyCodes: Diff;
}

const ValueOrUnknown: FC<{ children: string | string[] | undefined }> = ({ children }) => {
  const values: [] = children ? ((Array.isArray(children) ? children : [children]) as []) : [];
  return (
    <>
      {values.map(
        (value): ReactElement => (
          <div key={value}>{value}</div>
        ),
      )}
      {!values.length && 'Unknown'}
    </>
  );
};

const MvfVendorInfo: FC<MvfVendorInfoProps> = ({
  names,
  legalIdentifiers,
  internalId,
  countryCode,
  showErpFields,
  supplierNumbers,
  companyCodes,
}): ReactElement => {
  const renderDiff = (diff: Diff): ReactNode => {
    if (diff.state !== DiffState.Different) {
      return <ValueOrUnknown>{diff.newValue}</ValueOrUnknown>;
    }

    return (
      <>
        <CategoryAndValues label='Old' labelClassName={labelOldValueClassName} inlineLabel data-testid='old-change-mvf'>
          <ValueOrUnknown>{diff.oldValue}</ValueOrUnknown>
        </CategoryAndValues>
        <CategoryAndValues label='New' labelClassName={labelNewValueClassName} inlineLabel data-testid='new-change-mvf'>
          <ValueOrUnknown>{diff.newValue}</ValueOrUnknown>
        </CategoryAndValues>
      </>
    );
  };

  return (
    <div>
      <StyledCategoryAndValues label='Name' inlineLabel data-testid='mvf-names-label'>
        {renderDiff(names)}
      </StyledCategoryAndValues>
      <StyledCategoryAndValues label="ID's" inlineLabel data-testid='mvf-ids-label'>
        {renderDiff(legalIdentifiers)}
      </StyledCategoryAndValues>
      <StyledCategoryAndValues label='Internal ID' inlineLabel data-testid='mvf-internal-label'>
        <ValueOrUnknown>{internalId}</ValueOrUnknown>
      </StyledCategoryAndValues>
      {(showErpFields || supplierNumbers.state !== DiffState.Same) && (
        <StyledCategoryAndValues label='Supplier number' inlineLabel data-testid='mvf-supplier-number'>
          {renderDiff(supplierNumbers)}
        </StyledCategoryAndValues>
      )}
      {(showErpFields || companyCodes.state !== DiffState.Same) && (
        <StyledCategoryAndValues label='Sub-organization' inlineLabel data-testid='mvf-company-code'>
          {renderDiff(companyCodes)}
        </StyledCategoryAndValues>
      )}
      <StyledCategoryAndValues label='Country' inlineLabel data-testid='mvf-country-label'>
        {renderDiff(countryCode)}
      </StyledCategoryAndValues>
    </div>
  );
};

export default MvfVendorInfo;

const StyledCategoryAndValues = styled(CategoryAndValues)`
  margin-top: 3px;
`;

const labelNewValueClassName = css`
  color: var(--accent-blue-600);
`;

const labelOldValueClassName = css`
  color: rgba(3, 173, 239, 0.67);
`;
