import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem, { DropdownItemProps } from '@app/components/inputs/DropdownItem';
import { transformSupplierValidationExport, ValidationSystemOrganization } from '@mortee/domain/validationSystem';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import { isTruthy } from '@app/utils/utils';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import styled from '@emotion/styled';
import PasswordFormInput from '@app/components/inputs/PasswordFormInput';
import Loader from '@app/components/Loader';
import { downloadResourceByFileContent } from '@app/utils/fileUtils';
import { formattedDateOptionsDDMMYYYY } from '@app/utils/timeUtils';
import { FileTypes } from '@app/domain/files';
import validationSystemServices from '@mortee/services/validationSystemServices';
import useInfraStores from '@app/hooks/useInfraStores';
import Card from '@app/components/card/Card';
import { ModalCloseButton } from '@app/components/Modal';
import { KeyAsciiValue } from '@app/domain/uiConsts';
import useManuallyCalledLoadable from '@app/hooks/loadable/useManuallyCalledLoadable';
import Button from '@app/components/Button';

interface Props {
  onDone: () => void;
}

const ValidationsReportModal: FC<Props> = observer(({ onDone }) => {
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(undefined);
  const [selectedPassword, setSelectedPassword] = useState<string | undefined>(undefined);

  const { validationSystemStore } = useAppStores<MorteeMode>();
  const { localeStore } = useInfraStores<MorteeMode>();
  const [loadable, downloadExportSupplierValidationReportRecords] = useManuallyCalledLoadable(
    async function downloadExportSupplierValidationReportRecords(): Promise<void> {
      if (loadable.isInProgress()) {
        return;
      }

      if (selectedOrgId && organizations) {
        const selectedOrg = organizations.find((org) => org.id === selectedOrgId);

        const orgName = selectedOrg?.groupName || selectedOrg?.name || '';

        const blob = transformSupplierValidationExport(
          await validationSystemServices.exportValidationsReport(selectedOrgId, selectedPassword),
        );
        const fileType = FileTypes.xlsx;
        const name = `${orgName} SV Status Analysis - ${localeStore
          .formatDate(Date.now(), formattedDateOptionsDDMMYYYY)
          .split('/')
          .join('-')}`;
        downloadResourceByFileContent(blob.content, fileType, `${name}.${fileType}`);
        resetFields();
      }
    },
  );

  const renderOrgItem = (org: ValidationSystemOrganization): ReactElement<DropdownItemProps> => {
    const displayName = [org.groupName, org.name].filter(isTruthy).join(' - ');
    return (
      <DropdownItem textWhenSelected={displayName} key={org.id} value={org.id}>
        {displayName}
      </DropdownItem>
    );
  };

  const organizations = validationSystemStore.organizations.result;

  function resetFields(): void {
    setSelectedOrgId(undefined);
    setSelectedPassword(undefined);
  }

  const handleOnKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (e.which === KeyAsciiValue.Enter) {
      e.preventDefault();
      await downloadExportSupplierValidationReportRecords();
    }
  };

  if (!organizations) {
    return <Loader spinning />;
  }

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitle>Validations report</ModalTitle>
        <ModalCloseButton onDone={onDone} />
      </ModalHeader>
      <ModalContent>
        <InputBox>
          <NakedDropdown
            accessibilityLabel='organization'
            name='drp-validation-report-orgs'
            dataTestId='drp-validation-report-orgs'
            placeholder='Organization'
            value={selectedOrgId}
            onChange={(orgId: string | undefined): void => setSelectedOrgId(orgId)}
            isSearchable
          >
            {organizations.map((org) => renderOrgItem(org))}
          </NakedDropdown>
        </InputBox>
        <InputBox>
          <StyledPasswordFormInput
            name='inpt-report-password'
            placeholder='Report password'
            value={selectedPassword}
            onChange={setSelectedPassword}
            onKeyPress={handleOnKeyPress}
            disableSuggestion
          />
        </InputBox>
        <DownloadButton
          id='btn-download-validation-report'
          dataTestId='btn-download-validation-report'
          onClick={(): void => {
            downloadExportSupplierValidationReportRecords();
          }}
          loading={loadable.isInProgress()}
          disabled={!selectedOrgId || !organizations}
        >
          Download
        </DownloadButton>
      </ModalContent>
    </ModalContainer>
  );
});

export default ValidationsReportModal;

const ModalContainer = styled(Card)`
  padding: 25px;
  width: 450px;
`;

const ModalHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DownloadButton = styled(Button)`
  align-self: flex-end;
`;

const StyledPasswordFormInput = styled(PasswordFormInput)`
  padding-right: 10px;
`;

const ModalTitle = styled.div`
  flex: 1;
`;
