import React, { FC, ReactNode } from 'react';
import ValidatedPayeeDetails from '@mortee/components/validatedPayee/ValidatedPayeeDetails';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import Button from '@app/components/Button';
import CardHeader from '@app/components/card/CardHeader';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import { isDefined } from '@app/utils/utils';
import StoreConfirmationAlerts from '@mortee/routes/validatedPayeesManagement/StoreConfirmationAlerts';
import AlertSpriteSymbol from '@app/images/alert-outline.svg';
import { pluralize } from '@app/utils/stringUtils';

interface Props {
  payeeDetails: ValidatedPayeeToDisplay;
  alerts: ReactNode[];
  onConfirm: () => void;
  onClose: () => void;
  isEditMode: boolean;
}

const StoreValidatedPayeeConfirmationModal: FC<Props> = ({ onConfirm, onClose, payeeDetails, alerts, isEditMode }) => {
  const renderedAlerts = alerts.filter(isDefined);

  return (
    <MainCard shadowType='elevated'>
      <CardHeader>VALIDATED PAYEE DETAILS SUMMARY</CardHeader>
      <AlertsBox
        title={`${renderedAlerts.length} data ${pluralize('check', renderedAlerts.length)} did not pass:`}
        icon={AlertSpriteSymbol}
        alerts={renderedAlerts}
      />
      <PaddedValidatedPayeeDetails data={payeeDetails} moreInfoHideable={false} displayNotProvidedValues />
      <ActionsContainer>
        <Button
          id='btn-create-validated-payee-confirmation-modal-close'
          appearance='text'
          colorScheme='secondary'
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          id='btn-create-validated-payee-confirmation-modal-create'
          appearance='full'
          colorScheme='secondary'
          onClick={onConfirm}
          autoFocus
        >
          {isEditMode ? 'UPDATE' : 'CREATE'}
        </Button>
      </ActionsContainer>
    </MainCard>
  );
};

export default StoreValidatedPayeeConfirmationModal;

const AlertsBox = styled(StoreConfirmationAlerts)`
  background: var(--warning-color);
  padding: var(--card-padding);
  margin: 16px 0;
`;

const MainCard = styled(Card)`
  width: min(950px, 100vw);

  // Padding will be applied by the content
  padding: 0;

  --card-padding: 16px 24px;
`;

const PaddedValidatedPayeeDetails = styled(ValidatedPayeeDetails)`
  padding: var(--card-padding);
  padding-top: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  // To allow the buttons drop shadow to fully show
  padding: var(--card-padding);

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
