import { trim } from '@app/utils/stringUtils';
import { ACCOUNT_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import {
  BaseFormFields,
  SupplierRegistrationCountryFields,
} from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { onChangeValidateOtherFields, requireOnOtherFieldFull } from '@app/utils/validators';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import { AccountType } from '@app/domain/accountsDetailsHelpers';
import { CURRENCIES_KEYS } from '@app/domain/currencies';

const AccountTypeInfo = {
  [AccountType.Futsu]: 'Futsu 普通',
  [AccountType.Touza]: 'Touza 当座',
  [AccountType.Chochiku]: 'Chochiku 貯蓄',
};

export function isJpyCurrency(form: WrappedFormUtils<BaseFormFields>): boolean {
  return form.getFieldValue('currency') === CURRENCIES_KEYS.JPY;
}

function getBankCodeFields(form: WrappedFormUtils<BaseFormFields>): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-JP-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: isJpyCurrency(form),
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBankCode',
      },
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.JP.bank,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.JP.bank,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.JP.errors.invalidBankCode`,
          'supplierValidation.supplierRegister.errors.invalidBankCode',
        ],
      },
      {
        transform: trim,
        validator: requireOnOtherFieldFull(
          form,
          'branchCode',
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.JP.errors.missingBankCode`,
            'supplierValidation.supplierRegister.errors.missingBankCode',
          ]),
        ),
      },
      {
        validator: onChangeValidateOtherFields<BaseFormFields>(form, 'branchCode'),
      },
    ],
  };
}

function getBranchCodeFields(form: WrappedFormUtils<BaseFormFields>): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-JP-branch-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: isJpyCurrency(form),
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBranchCode',
      },
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.JP.branch,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: ACCOUNT_FIELD_MAX_LENGTH.JP.branch,
        },
      },
      {
        pattern: /^[0-9]+$/,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.JP.errors.invalidBranchCode`,
          'supplierValidation.supplierRegister.errors.invalidBranchCode',
        ],
      },
      {
        transform: trim,
        validator: requireOnOtherFieldFull(
          form,
          'bankCode',
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.JP.errors.missingBranchCode`,
            'supplierValidation.supplierRegister.errors.missingBranchCode',
          ]),
        ),
      },
      {
        validator: onChangeValidateOtherFields<BaseFormFields>(form, 'bankCode'),
      },
    ],
  };
}

export const JP: SupplierRegistrationCountryFields = {
  bankCode: getBankCodeFields,
  branchCode: getBranchCodeFields,
  accountType: AccountTypeInfo,
  showAccountType: isJpyCurrency,
  additionalCompanyName: true,
  additionalPersonalName: true,
};
