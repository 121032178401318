import React, { FC, ReactNode } from 'react';
import { SmallTextSlateGray300 } from '@app/components/Text';
import styled from '@emotion/styled';
import { SectionCategoryNames } from '@app/domain/sections';
import LinkSelectableBulletedItem from '@app/components/LinkSelectableBulletedItem';

interface Props {
  category: SectionCategory | undefined;
  sections: Section[];
  selectedSectionId: string | undefined;
  selectedSectionChanged: (sectionId: string) => void;
  className?: string;
}

const SectionCategory: FC<Props> = ({ category, sections, selectedSectionId, selectedSectionChanged, className }) => {
  return (
    <div className={className}>
      <SectionTitle>{category && SectionCategoryNames[category]}</SectionTitle>
      {sections.map(
        (section): ReactNode => (
          <LinkSelectableBulletedItem
            id={`btn-sidebar-item-${category}-${section.id}`}
            dataTestId={`btn-openselect-item-${section.id}`}
            key={section.id}
            selected={section.id === selectedSectionId}
            to={section.baseRoute.path}
          >
            {section.name}
          </LinkSelectableBulletedItem>
        ),
      )}
    </div>
  );
};

export default SectionCategory;

const SectionTitle = styled(SmallTextSlateGray300.div)`
  padding: 2px 0 2px 24px;
`;
