import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { useCallback } from 'react';
import { useSingleQueryParam } from '@app/hooks/useSingleQueryParam';
import { createValueInEnumPredicate } from '@app/utils/enumUtils';
import { getTableViewsForUser, TableView } from '@mortee/domain/validationSystemTableViews';

interface ValidationRecordTableViewResult {
  readonly currentTableView: TableView;
  readonly onTableViewChange: (newView: TableView) => void;
  readonly tableViewOptions: TableView[];
}

export default function useValidationRecordTableViewQueryParam(): ValidationRecordTableViewResult {
  const { permissionsStore } = useInfraStores<MorteeMode>();

  const tableViewsConfiguration = getTableViewsForUser(permissionsStore.isValidationApprover);

  const [tableViewQueryParam, setTableView] = useSingleQueryParam<TableView>('view', createValueInEnumPredicate(TableView));

  let tableView: TableView;
  if (tableViewQueryParam) {
    tableView = tableViewQueryParam;
  } else {
    setTableView(tableViewsConfiguration.default);
    tableView = tableViewsConfiguration.default;
  }

  const onSetTableView = useCallback(
    (newView: TableView) => {
      if (tableViewsConfiguration.options.includes(newView)) {
        setTableView(newView);
      }
    },
    [tableViewsConfiguration, setTableView],
  );

  if (!tableViewsConfiguration.options.includes(tableView)) {
    setTableView(tableViewsConfiguration.default);
  }

  return {
    currentTableView: tableView,
    onTableViewChange: onSetTableView,
    tableViewOptions: tableViewsConfiguration.options,
  };
}
