import styled from '@emotion/styled';
import { Regular15TransparentBlack900 } from './Text';
import React, { FC } from 'react';
import Button from './Button';
import { ColorScheme, ColorSchemeObject, colorSchemePresets, getColorScheme } from '@app/domain/theme';

export interface ChipProps {
  appearance?: 'full' | 'outline' | 'outline-squared';
  colorScheme?: ColorScheme;
  disabled?: boolean;
}

const Chip = styled.span<ChipProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.6em;

  --chip-background-color: ${(p): string => {
    switch (p.appearance) {
      case 'outline':
        return 'transparent';
      case 'outline-squared':
        return `${getChipColorScheme(p.colorScheme).main}18`;
      default:
        return getChipColorScheme(p.colorScheme).main;
    }
  }};
  --chip-outline-color: ${(p): string => {
    switch (p.appearance) {
      case 'outline':
        return getChipColorScheme(p.colorScheme).main;
      case 'outline-squared':
        return getChipColorScheme(p.colorScheme).main;
      default:
        return 'transparent';
    }
  }};
  --chip-text-color: ${(p): string => {
    switch (p.appearance) {
      case 'outline':
        return getChipColorScheme(p.colorScheme).main;
      case 'outline-squared':
        return getChipColorScheme(p.colorScheme).main;
      default:
        return getChipColorScheme(p.colorScheme).fullTextColor;
    }
  }};
  --chip-border-radius: ${(p): string => {
    switch (p.appearance) {
      case 'outline':
        // Setting border radius to a huge value allows us to change the height and still get the effect of rounded corners.
        // Setting 50% will create an ellipse which is not the desired result
        return '1000000px';
      case 'outline-squared':
        return '6px';
      default:
        // Setting border radius to a huge value allows us to change the height and still get the effect of rounded corners.
        // Setting 50% will create an ellipse which is not the desired result
        return '1000000px';
    }
  }};

  background-color: var(--chip-background-color);
  border: var(--chip-outline-color) 1px solid;

  transition: filter 0.2s;

  &:focus {
    filter: brightness(0.9);
  }

  ${Regular15TransparentBlack900.css}
  line-height: 1.5;

  color: var(--chip-text-color);
  fill: var(--chip-text-color);

  // For chip ellipsis purposes
  overflow: hidden;

  border-radius: var(--chip-border-radius);

  ${(p): string =>
    p.disabled
      ? `
    filter: grayscale(0.4);
    opacity: 0.5;
    `
      : ''}
`;

interface ClickableChipProps extends ChipProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const ClickableChip = styled(Chip)<ClickableChipProps>`
  cursor: pointer;
  user-select: none;

  &:hover {
    --chip-background-color: ${(p): string =>
      p.appearance === 'outline' ? 'transparent' : getChipColorScheme(p.colorScheme).fullHover};
    --chip-outline-color: ${(p): string =>
      p.appearance === 'outline' ? getChipColorScheme(p.colorScheme).fullHover : 'transparent'};
    --chip-text-color: ${(p): string =>
      p.appearance === 'outline' ? getChipColorScheme(p.colorScheme).fullHover : getChipColorScheme(p.colorScheme).fullTextColor};
  }

  &:active {
    --chip-background-color: ${(p): string =>
      p.appearance === 'outline' ? 'transparent' : getChipColorScheme(p.colorScheme).fullActive};
    --chip-outline-color: ${(p): string =>
      p.appearance === 'outline' ? getChipColorScheme(p.colorScheme).fullActive : 'transparent'};
    --chip-text-color: ${(p): string =>
      p.appearance === 'outline'
        ? getChipColorScheme(p.colorScheme).fullActive
        : getChipColorScheme(p.colorScheme).fullTextColor};
  }

  transition: filter 0.2s, border 0.2s, color 0.2s;

  ${(p): string =>
    p.disabled
      ? `
  pointer-events: none;
  cursor: default;
  `
      : ''}
`;

function getChipColorScheme(colorScheme: ColorScheme | undefined): ColorSchemeObject {
  return getColorScheme(colorScheme, colorSchemePresets.primary);
}

interface DeleteableChipProps extends ChipProps {
  id: string;
  dataTestId?: string;
  disabled?: boolean;
  className?: string;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
}

export const DeletableChip: FC<DeleteableChipProps> = ({ id, dataTestId = id, disabled, onDelete, children, ...chipProps }) => {
  return (
    <ChipOfDeletableChip {...chipProps} disabled={disabled} data-testid={dataTestId}>
      <ChipContent>{children}</ChipContent>{' '}
      <CircleButton
        id={`btn-${id}-delete`}
        dataTestId={`btn-${dataTestId}-delete`}
        disabled={disabled}
        onClick={onDelete}
        size='small'
        appearance='text'
        cornerType='none'
        colorScheme='grey'
      >
        ✕
      </CircleButton>
    </ChipOfDeletableChip>
  );
};

const ChipOfDeletableChip = styled(Chip)`
  padding: 0 0.15em 0 0.6em;
`;

const ChipContent = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

const CircleButton = styled(Button)`
  color: var(--chip-text-color);
  padding: 0.1em 0.4em;
  margin-left: 8px;

  // Setting border radius to a really big value allows us to change the height and still get the effect of rounded corners.
  // Setting 50% will create an ellipse which is not the desired result
  border-radius: 10000px;
`;

export default Chip;
