import { isDefined } from '@app/utils/utils';

interface InitArgs {
  exceptionLogger: ExceptionLogger;
  eventLogger: EventLogger;
  analyticsMonitor: AnalyticsMonitor | null;
  perfMonitor: PerfMonitor;
  ignoredExceptions?: string[];
  getDistinctId?: () => Promise<string> | string;
}

class Log {
  private ignoredExceptions: string[] = [];
  private loggers: EventLogger[];
  private exceptionLogger: ExceptionLogger;
  private analyticsMonitor: AnalyticsMonitor | null;
  private perfMonitor: PerfMonitor;
  private customGetDistinctId: InitArgs['getDistinctId'];

  async init(options: InitArgs): Promise<void> {
    this.customGetDistinctId = options.getDistinctId;
    this.loggers = [options.eventLogger, options.exceptionLogger];
    this.exceptionLogger = options.exceptionLogger;
    this.analyticsMonitor = options.analyticsMonitor;
    this.perfMonitor = options.perfMonitor;

    if (options.ignoredExceptions) {
      this.ignoredExceptions = this.ignoredExceptions.concat(options.ignoredExceptions);
    }

    const loggersInits: Promise<void>[] = [];

    this.loggers.forEach((logger) => {
      loggersInits.push(logger.init());
    });

    await Promise.all(loggersInits);
    await this.analyticsMonitor?.init();

    await this.setStaticData();
  }

  private async getDistinctId(): Promise<any> {
    let id;

    try {
      id = await this.customGetDistinctId?.();
    } catch (e: unknown) {}

    return id || this.generateId();
  }

  private async setStaticData(): Promise<void> {
    const id = await this.getDistinctId();

    this.loggers.map(
      async (logger: EventLogger): Promise<void> => {
        logger.identify(id);
      },
    );
  }

  setTag(name: string, value: string | number): void {
    for (const logger of this.loggers) {
      try {
        logger.setTag?.(name, value);
      } catch (e: unknown) {
        this.exception(e);
      }
    }
  }

  setUserInfo(userId?: string, userInfo: Object = {}): void {
    for (const logger of this.loggers) {
      try {
        logger.setUserInfo(userId, userInfo);
      } catch (e: unknown) {
        this.exception(e);
      }
    }

    try {
      this.analyticsMonitor?.setUserInfo(userId, userInfo);
    } catch (e: unknown) {
      this.exception(e);
    }
  }

  event(name: string, additionalData?: unknown, writeToAnalytics = false): void {
    for (const logger of this.loggers) {
      this.eventToLogger(logger, name, additionalData);
    }

    if (writeToAnalytics) {
      this.eventToAnalytics(name, additionalData);
    }
  }

  eventToExceptionLogger(name: string, additionalData?: unknown): void {
    this.eventToLogger(this.exceptionLogger, name, additionalData);
  }

  private eventToLogger(logger: EventLogger, name: string, additionalData?: unknown): void {
    try {
      logger.event(name, Log.calculateAdditionalData(additionalData));
    } catch (e: unknown) {
      this.exception(e);
    }
  }

  private eventToAnalytics(name: string, additionalData?: unknown): void {
    try {
      this.analyticsMonitor?.event(name, Log.calculateAdditionalData(additionalData));
    } catch (e: unknown) {
      this.exception(e);
    }
  }

  currentView(viewName: string, additionalData?: unknown): void {
    for (const logger of this.loggers) {
      try {
        logger.currentView(viewName, Log.calculateAdditionalData(additionalData));
      } catch (e: unknown) {
        this.exception(e);
      }
    }
  }

  private static calculateAdditionalData(additionalData: unknown): object {
    if (!isDefined(additionalData)) {
      return {};
    }

    if (typeof additionalData !== 'object') {
      return {
        data: additionalData,
      };
    }

    return additionalData;
  }

  start(eventName: string): number | undefined {
    return this.perfMonitor.start(eventName);
  }

  stop(id?: number): void {
    this.perfMonitor.stop(id);
  }

  incrementMetric(id: number | undefined, event: string): void {
    this.perfMonitor.incrementMetric(id, event);
  }

  exception(ex: any, additionalData?: object): void {
    try {
      let errorString = String(ex);
      let errorJson;

      try {
        errorString = JSON.stringify(ex, this.replaceErrors);
      } catch (ex1: unknown) {
        // Do nothing
      }

      try {
        errorJson = JSON.parse(errorString);
      } catch (ex2: unknown) {
        // Do nothing
      }

      if (this.ignoredExceptions.find((x: string): boolean => errorString.includes(x))) {
        this.eventToExceptionLogger('exception', ex);
        return;
      }

      let exceptionError: Error;

      if (ex.stackTrace) {
        exceptionError = ex;
      } else {
        exceptionError = new Error(errorString);
      }

      this.exceptionLogger.exception(exceptionError, errorString, { ex: errorJson, ...additionalData });
    } catch (e: unknown) {}
  }

  private replaceErrors(_key: string, value: Object): Object {
    if (value instanceof Error) {
      const error = {};

      Object.getOwnPropertyNames(value).forEach((key: string) => {
        error[key] = value[key];
      });

      return error;
    }

    return value;
  }

  private generateId(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}

export default new Log();
