import React, { FC, ReactElement, ReactNode, useLayoutEffect } from 'react';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import {
  getPrivatePayeeAccountSelfApproveReasonText,
  PrivatePayeeAccountSelfApproveReason,
} from '@mortee/domain/privatePayeeAccount';
import { FormFieldDecorators } from '@app/utils/form/form';
import { REQUIRED_FORM_MESSAGE, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { trim } from '@app/utils/stringUtils';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import styled from '@emotion/styled';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import FormItemsBox, { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';
import InputDecorator from '@app/components/inputs/InputDecorator';
import { Subtitle } from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/privatePayeeItem/selfApprove/Styles';
import DatePicker, {
  convertDatePickerValueToEpoch,
  convertEpochToDatePickerValue,
  DatePickerValue,
  datePickerValueNotFutureTimestampValidator,
} from '@app/components/inputs/DatePicker';
import FileAttacher from './FileAttacher';
import {
  CheckFileOptions,
  DEFAULT_MAX_FILE_AMOUNT,
  DEFAULT_MAX_FILE_NAME_LENGTH,
  DEFAULT_MAX_FILE_SIZE_MB,
  FileTypes,
} from '@app/domain/files';
import { Tooltip } from 'antd';
import NakedFormTextarea from '@app/components/inputs/NakedFormTextarea';
import { SELF_APPROVE_FIELD_MAX_LENGTH } from '@mortee/domain/initiateSelfApproveFields';

export const MAX_ALLOWED_FILES = 5;

export const SELF_APPROVE_FORM_ALLOWED_EXTENSIONS = [
  FileTypes.txt,
  FileTypes.eml,
  FileTypes.msg,
  FileTypes.csv,
  FileTypes.pdf,
  FileTypes.xml,
  FileTypes.doc,
  FileTypes.docx,
  FileTypes.png,
  FileTypes.jpeg,
  FileTypes.jpg,
];

export interface SelfApproveFields {
  reason: PrivatePayeeAccountSelfApproveReason;
  note: string;
  description: string;
  contactPhoneNumber: string;
  contactName: string;
  phoneCallDate: DatePickerValue | undefined;
  phoneCallDescription: string;
  files: File[];
}

interface Props {
  form: WrappedFormUtils<SelfApproveFields>;
  showFormErrors: FormItemsBoxProps['showErrors'];
  resetShowFormErrors: () => void;
}

const PrivateAccountInitiateSelfApproveForm: FC<Props> = ({ form, showFormErrors, resetShowFormErrors }) => {
  const reasonChanged = (newReason: PrivatePayeeAccountSelfApproveReason): void => {
    const { setFieldsValue } = form;

    resetShowFormErrors();
    setFieldsValue({
      reason: newReason,
      note: '',
      description: '',
      contactPhoneNumber: '',
      contactName: '',
      phoneCallDate: undefined,
      phoneCallDescription: '',
    } as SelfApproveFields);
  };

  const currentReason = form.getFieldValue('reason') as PrivatePayeeAccountSelfApproveReason | undefined;

  useLayoutEffect(() => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [currentReason]);

  const renderFormByReason = (reason: PrivatePayeeAccountSelfApproveReason | undefined): ReactElement => {
    if (reason === PrivatePayeeAccountSelfApproveReason.phoneCallVerification) {
      return (
        <>
          <FormSubtitle>Call details</FormSubtitle>
          <InputLine>
            <ContactNameFormItemBox
              fieldName='contactName'
              fieldDecoratorOptions={fieldDecoratorPerReason.PhoneCallVerification.contactName ?? null}
              appearance='corners'
              showErrors={showFormErrors}
              form={form}
            >
              <NakedFormInput
                name='inpt-mdg-initiate-self-contact-name'
                type='text'
                placeholder='Who did you talk to'
                heightType='thin'
              />
            </ContactNameFormItemBox>
            <ContactPhoneNumberFormItemBox
              fieldName='contactPhoneNumber'
              fieldDecoratorOptions={fieldDecoratorPerReason.PhoneCallVerification.contactPhoneNumber ?? null}
              appearance='corners'
              showErrors={showFormErrors}
              form={form}
            >
              <NakedFormInput
                name='inpt-mdg-initiate-self-contact-phone-number'
                type='text'
                placeholder='Phone number'
                heightType='thin'
              />
            </ContactPhoneNumberFormItemBox>
          </InputLine>
          <FormSubtitle>Date</FormSubtitle>
          <FormItemBox
            fieldName='phoneCallDate'
            fieldDecoratorOptions={fieldDecoratorPerReason.PhoneCallVerification.phoneCallDate ?? null}
            appearance='none'
            showErrors={showFormErrors}
            form={form}
          >
            <DatePicker
              accessibilityLabel='Date'
              appearance='corners'
              id='date-picker-mdg-initiate-self-call-timestamp'
              placeholderStyle='onlyWhenEmpty'
              heightType='thin'
            />
          </FormItemBox>
          <FormSubtitle>Call description</FormSubtitle>
          <InputLine>
            <FullWidthFormItemBox
              fieldName='phoneCallDescription'
              fieldDecoratorOptions={fieldDecoratorPerReason.PhoneCallVerification.phoneCallDescription ?? null}
              appearance='corners'
              showErrors={showFormErrors}
              form={form}
            >
              <NakedFormTextarea
                name='inpt-mdg-initiate-self-phone-call-description'
                placeholder='Enter a short description of the call'
                minRows={3}
                type='text'
                heightType='thin'
              />
            </FullWidthFormItemBox>
          </InputLine>
        </>
      );
    }

    if (!reason) {
      return (
        <Tooltip title='Select a reason before adding a note.'>
          <StyledFormItemBox
            fieldName='note'
            fieldDecoratorOptions={{}}
            appearance='corners'
            showErrors={showFormErrors}
            form={form}
            disabled
          >
            <NakedFormTextarea
              name='inpt-mdg-initiate-self-no-reason-note'
              placeholder='Note'
              minRows={3}
              type='text'
              heightType='thin'
            />
          </StyledFormItemBox>
        </Tooltip>
      );
    }

    const noteFieldDecoratorOptions = fieldDecoratorPerReason[reason].note ?? null;
    return (
      <StyledFormItemBox
        fieldName='note'
        fieldDecoratorOptions={noteFieldDecoratorOptions}
        appearance='corners'
        showErrors={showFormErrors}
        form={form}
      >
        <NakedFormTextarea name='inpt-mdg-initiate-self-note' placeholder='Note' minRows={3} type='text' heightType='thin' />
      </StyledFormItemBox>
    );
  };

  const renderReasonSelection = (reason: PrivatePayeeAccountSelfApproveReason | undefined): ReactNode => {
    const renderedDropdown = (
      <ReasonDropdown
        accessibilityLabel='self approve reason'
        name='drp-mdg-initiate-self-approve-reason'
        dataTestId='drp-mdg-initiate-self-approve-reason'
        placeholder='Choose reason'
        placeholderStyle='onlyWhenEmpty'
        onChange={reasonChanged}
        heightType='thin'
        popupWidth='auto'
      >
        {Object.values(PrivatePayeeAccountSelfApproveReason).map((reason) => {
          const reasonText = getPrivatePayeeAccountSelfApproveReasonText(reason);
          return (
            <StyledDropdownItem value={reason} key={reason} textWhenSelected={reasonText}>
              {reasonText}
            </StyledDropdownItem>
          );
        })}
      </ReasonDropdown>
    );

    if (reason === PrivatePayeeAccountSelfApproveReason.other) {
      return (
        <FormItemsBox
          fieldNames={['reason', 'description']}
          appearance='corners'
          showErrors={showFormErrors}
          form={form}
          expandBelowOnError
        >
          <OtherReasonLineWrapper>
            <ReasonWrapper>
              <InputDecorator form={form} fieldName='reason' fieldDecoratorOptions={reasonFieldDecorator}>
                {renderedDropdown}
              </InputDecorator>
            </ReasonWrapper>
            <InputBuffer />
            <InputDecorator
              form={form}
              fieldName='description'
              fieldDecoratorOptions={fieldDecoratorPerReason.Other.description ?? null}
            >
              <OtherReasonInput
                name='inpt-mdg-initiate-self-other-reason'
                type='text'
                placeholder='Type the reason here'
                autoFocus
                placeholderStyle='onlyWhenEmpty'
                heightType='thin'
              />
            </InputDecorator>
          </OtherReasonLineWrapper>
        </FormItemsBox>
      );
    }

    return (
      <OnlyReasonFormItemBox
        fieldName='reason'
        fieldDecoratorOptions={reasonFieldDecorator}
        appearance='corners'
        showErrors={showFormErrors}
        form={form}
        expandBelowOnError={reason !== PrivatePayeeAccountSelfApproveReason.phoneCallVerification}
      >
        {renderedDropdown}
      </OnlyReasonFormItemBox>
    );
  };

  const checkFileOptions: CheckFileOptions = {
    allowedExtensions: SELF_APPROVE_FORM_ALLOWED_EXTENSIONS,
    maxFilesAllowed: DEFAULT_MAX_FILE_AMOUNT,
    maxFileSizeMB: DEFAULT_MAX_FILE_SIZE_MB,
    maxFileNameLength: DEFAULT_MAX_FILE_NAME_LENGTH,
  };

  return (
    <div>
      {renderReasonSelection(currentReason)}
      {renderFormByReason(currentReason)}
      <FormSubtitle>Attachments</FormSubtitle>
      <FormItemBox fieldName='files' fieldDecoratorOptions={filesFieldDecorator} appearance='none' form={form} expandBelowOnError>
        <FileAttacher id='mdg-initiate-self' disabled={!currentReason} checkFileOptions={checkFileOptions} />
      </FormItemBox>
    </div>
  );
};

const reasonFieldDecorator: GetFieldDecoratorOptions = {
  rules: [
    {
      transform: trim,
      required: true,
      message: REQUIRED_FORM_MESSAGE,
    },
    {
      type: 'enum',
      transform: trim,
      enum: Object.values(PrivatePayeeAccountSelfApproveReason),
      message: 'Invalid reason',
    },
  ],
};

const filesFieldDecorator: GetFieldDecoratorOptions = {
  initialValue: [],
  rules: [
    {
      type: 'array',
      max: MAX_ALLOWED_FILES,
      message: `You can upload up to ${MAX_ALLOWED_FILES} files at a time`,
    },
  ],
};

const fieldDecoratorPerReason: Record<PrivatePayeeAccountSelfApproveReason, Partial<FormFieldDecorators<SelfApproveFields>>> = {
  [PrivatePayeeAccountSelfApproveReason.transactionHistory]: {
    note: {
      rules: [
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialCharsWithNewline,
          message: 'Invalid character',
        },
      ],
    },
  },
  [PrivatePayeeAccountSelfApproveReason.smallAmountTransaction]: {
    note: {
      rules: [
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialCharsWithNewline,
          message: 'Invalid character',
        },
      ],
    },
  },
  [PrivatePayeeAccountSelfApproveReason.oneTimeTransaction]: {
    note: {
      rules: [
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialCharsWithNewline,
          message: 'Invalid character',
        },
      ],
    },
  },
  [PrivatePayeeAccountSelfApproveReason.phoneCallVerification]: {
    contactPhoneNumber: {
      rules: [
        {
          transform: trim,
          required: true,
          message: REQUIRED_FORM_MESSAGE,
        },
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.contactPhoneNumber,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.contactPhoneNumber} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.numericWithSpecialChars,
          message: 'Invalid character',
        },
      ],
    },
    contactName: {
      rules: [
        {
          transform: trim,
          required: true,
          message: REQUIRED_FORM_MESSAGE,
        },
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.contactName,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.contactName} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          message: 'Invalid character',
        },
      ],
    },
    phoneCallDate: {
      initialValue: convertEpochToDatePickerValue(Date.now()),
      rules: [
        {
          type: 'object',
        },
        {
          validator: (rule, value: DatePickerValue | undefined, callback): void => {
            const dateEpoch = convertDatePickerValueToEpoch(value);

            if (!dateEpoch) {
              return callback(REQUIRED_FORM_MESSAGE);
            }

            return callback();
          },
          message: REQUIRED_FORM_MESSAGE,
        },
        datePickerValueNotFutureTimestampValidator(),
      ],
    },
    phoneCallDescription: {
      rules: [
        {
          required: true,
          transform: trim,
          message: REQUIRED_FORM_MESSAGE,
        },
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.description,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.description} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialCharsWithNewline,
          message: 'Invalid character',
        },
      ],
    },
  },
  [PrivatePayeeAccountSelfApproveReason.other]: {
    description: {
      rules: [
        {
          required: true,
          transform: trim,
          message: REQUIRED_FORM_MESSAGE,
        },
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.otherReason,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.otherReason} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          message: 'Invalid character',
        },
      ],
    },
    note: {
      rules: [
        {
          transform: trim,
          max: SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note,
          message: `Max ${SELF_APPROVE_FIELD_MAX_LENGTH.initiateSelfApprove.note} characters`,
        },
        {
          transform: trim,
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialCharsWithNewline,
          message: 'Invalid character',
        },
      ],
    },
  },
};

export default PrivateAccountInitiateSelfApproveForm;

const ReasonDropdown = styled(NakedDropdown)`
  min-width: 275px;
`;

const OtherReasonLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const OtherReasonInput = styled(NakedFormInput)`
  flex: 1;
`;

const InputBuffer = styled.div`
  width: 1px;
  margin: 12px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

const ReasonWrapper = styled.div`
  display: inline-block;
`;

const OnlyReasonFormItemBox = styled(FormItemBox)`
  display: inline-block;
`;

const InputLine = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const ContactNameFormItemBox = styled(FormItemBox)`
  width: 230px;
`;

const ContactPhoneNumberFormItemBox = styled(FormItemBox)`
  width: 200px;
`;

const FullWidthFormItemBox = styled(FormItemBox)`
  flex: 1;
`;

const FormSubtitle = styled(Subtitle)`
  margin-top: 1px;
`;

const StyledDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: rgba(61, 68, 90, 0.7);
`;

const StyledFormItemBox = styled(FormItemBox)`
  margin-top: 10px;
`;
