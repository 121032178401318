import Card from '@app/components/card/Card';
import styled from '@emotion/styled';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import Button from '@app/components/Button';
import { H6StartTransparentBlack800 } from '@app/components/Text';

export const StyledBackeeForm = styled(NsknoxForm)`
  display: flex;
  align-items: stretch;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 34px;
  }
`;

export const StyledBackeeCard = styled(Card)`
  padding: 24px 32px;
  width: clamp(400px, 100%, 700px);
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;
`;

export const FieldsTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 8px;
`;

export const ActionsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
