import React, { Ref } from 'react';
import { ForwardingFC } from '@app/domain/technicals/components';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { css } from '@emotion/css';
import { NakedDropdownInnerInputProps } from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputProps';

const NakedDropdownInnerInputDefault: ForwardingFC<
  HTMLInputElement | HTMLTextAreaElement,
  NakedDropdownInnerInputProps
> = React.forwardRef((props: NakedDropdownInnerInputProps, ref) => {
  const { commonProps, inputSpecificProps } = props;
  const { disabled, readOnly } = commonProps;
  const { value, onChange, ...inputSpecificPropsRest } = inputSpecificProps;

  function getInputClassName(): string | undefined {
    return !disabled && readOnly ? nonSearchableInputClassName : undefined;
  }

  return (
    <NakedFormInput
      value={(value as string | undefined) ?? ''}
      {...commonProps}
      {...inputSpecificPropsRest}
      ref={ref as Ref<HTMLInputElement>}
      onChangeEvent={(changeEvent): void => onChange?.(changeEvent)}
      type='text'
      onSelect={(event): void => {
        if (readOnly) {
          event.preventDefault();
          event.stopPropagation();
          if (event.currentTarget) {
            event.currentTarget.selectionStart = null;
            event.currentTarget.selectionEnd = null;
          }
        }
      }}
      inputClassName={getInputClassName()}
    />
  );
});

export default NakedDropdownInnerInputDefault;

const nonSearchableInputClassName = css`
  cursor: pointer !important;
`;
