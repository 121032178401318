import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BodyRegularStartWhite1000 } from './Text';

export interface SelectableBulletedItemProps {
  id: string;
  dataTestId?: string;
  selected: boolean;
  onSelection: () => void;
  className?: string;
  children: ReactNode;
}

const SelectableBulletedItem: FC<SelectableBulletedItemProps> = ({
  id,
  dataTestId = id,
  selected,
  onSelection,
  className,
  children,
}) => {
  return (
    <Main onClick={onSelection} className={className} id={id} data-testid={dataTestId}>
      {selected ? <ItemBullet /> : <NotSelectedItemBullet />}
      <Content selected={selected}>{children}</Content>
    </Main>
  );
};

export default SelectableBulletedItem;

const Main = styled(BodyRegularStartWhite1000.div)`
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
`;

const Content = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  pointer-events: none;

  ${(p): string =>
    p.selected
      ? `
  font-weight: bold;
  padding: 13px 16px 11px;
  `
      : ''};
`;

const ItemBullet = styled.div`
  align-self: stretch;
  width: 0.45em;
  background-color: var(--white);
  transition: opacity 0.2s;
`;

const NotSelectedItemBullet = styled(ItemBullet)`
  opacity: 0;

  ${Main}:hover & {
    opacity: 0.2;
  }
`;
