import { FormProps } from 'antd/lib/form';
import React, { FC } from 'react';
import { Form as AntdForm } from 'antd';
import FormContext, { FormData } from './FormContext';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props extends Exclude<FormProps, 'form'>, FormData {
  form: WrappedFormUtils;
}

const NsknoxForm: FC<Props> = ({ form, colorScheme, showErrors, setShowErrors, appearance, disabled, ...formProps }) => {
  return (
    <FormContext.Provider value={{ form, colorScheme: colorScheme, showErrors, appearance, disabled, setShowErrors }}>
      <AntdForm {...formProps} />
    </FormContext.Provider>
  );
};

export default NsknoxForm;
