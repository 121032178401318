import { isTruthy } from '@app/utils/utils';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Regular12White } from '@app/components/Text';

interface Props {
  name: string;
}

const InitialsCircle: FC<Props> = (props) => {
  const { name } = props;
  const initials = convertNameIntoInitials(name);

  return <Circle>{initials}</Circle>;
};

function convertNameIntoInitials(name: string): string {
  return name
    .split(' ')
    .map((w) => {
      return w.trim()[0]?.toUpperCase();
    })
    .filter(isTruthy)
    .join('');
}

export default InitialsCircle;

const Circle = styled(Regular12White.div)`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(181, 181, 181, 0.6);
  border-radius: 10000000px;

  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
`;
