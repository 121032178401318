import React, { FC, ReactNode, useContext } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import {
  BodyRegularStartTransparentBlack400,
  CaptionStartColorless,
  CaptionStartError600,
  CaptionStartTransparentBlack600,
} from '../Text';
import FormInputsContext, { InputGroupState } from './FormInputsContext';
import { css, SerializedStyles } from '@emotion/react';
import { ColorScheme, ColorSchemeObject } from '@app/domain/theme';
import useColorScheme from '@app/hooks/useColorScheme';
import { useTranslation } from 'react-i18next';
import HiddenAccessibilityText from '@app/components/inputs/HiddenAccessibilityText';

export interface ValueWithPlaceholderProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'placeholder'> {
  colorScheme: ColorScheme;
  valueNotEmpty?: boolean;
  hasFocusWithin?: boolean;
  placeholderElementId: string;
  placeholder: string | null;
  placeholderStyle?: 'floating' | 'onlyWhenEmpty';
  placeholderVerticalAlign?: 'center' | 'top';
  heightType?: 'thick' | 'thin' | 'ultra-thin';
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
}

interface PlaceholderProps {
  colorScheme: ColorSchemeObject;
  placeholderVerticalAlign: ValueWithPlaceholderProps['placeholderVerticalAlign'];
  hasContent?: boolean;
  hasFocusWithin?: boolean;
  state: InputGroupState;
}

const ValueWithPlaceholder: FC<ValueWithPlaceholderProps> = (props) => {
  const {
    placeholderElementId,
    colorScheme: propColorScheme,
    heightType,
    valueNotEmpty,
    hasFocusWithin,
    placeholder,
    placeholderStyle = placeholder ? 'floating' : 'onlyWhenEmpty',
    placeholderVerticalAlign = 'center',
    className,
    children,
    dataTestId,
    ...rest
  } = props;

  const { state, underInputText, underInputTextType } = useContext(FormInputsContext);
  const { t } = useTranslation();
  const colorScheme = useColorScheme(propColorScheme);

  const Placeholder: StyledComponent<PlaceholderProps, any, any> =
    placeholderStyle === 'onlyWhenEmpty' ? HiderPlaceholder : FloaterPlaceholder;

  function renderAccessibilityErrorText(): ReactNode {
    if (!underInputText) {
      return;
    }

    let underTextType: string;

    switch (underInputTextType) {
      case 'success':
        underTextType = t('general.accessibility.success');
        break;
      case 'warning':
        underTextType = t('general.accessibility.warning');
        break;
      case 'error':
        underTextType = t('general.accessibility.error');
        break;
      case 'validating':
        underTextType = t('general.accessibility.validating');
        break;
    }

    return (
      <HiddenAccessibilityText role='alert'>
        {' '}
        {underTextType}: {underInputText}
      </HiddenAccessibilityText>
    );
  }

  return (
    <Container className={className} heightType={heightType} {...rest}>
      <Placeholder
        id={placeholderElementId}
        hasContent={valueNotEmpty}
        hasFocusWithin={hasFocusWithin}
        state={state}
        colorScheme={colorScheme}
        placeholderVerticalAlign={placeholderVerticalAlign}
      >
        {placeholder}
        {renderAccessibilityErrorText()}
      </Placeholder>
      <ValueContainer placeholderStyle={placeholderStyle} data-testid={dataTestId}>
        {children}
      </ValueContainer>
    </Container>
  );
};

export default ValueWithPlaceholder;

const Container = styled.div<{ heightType: ValueWithPlaceholderProps['heightType'] }>`
  --container-min-height: 54px;
  --resting-item-top-distance: 16px;
  --resting-item-bottom-distance: 16px;
  --floating-value-top-distance: 20px;
  --floating-value-bottom-distance: 8px;
  --floating-placeholder-top-distance: 7px;

  ${(p): string => {
    switch (p.heightType) {
      case 'thin': {
        return `
          --container-min-height: 48px;
          --resting-item-top-distance: 12px;
          --resting-item-bottom-distance: 12px;
          --floating-value-top-distance: 19px;  
          --floating-value-bottom-distance: 5px;
          --floating-placeholder-top-distance: 5px;
        `;
      }
      case 'ultra-thin': {
        return `
          --container-min-height: 36px;
          --resting-item-top-distance: 8px;
          --resting-item-bottom-distance: 8px;
          --floating-value-top-distance: 12px;  
          --floating-value-bottom-distance: 6px;
          --floating-placeholder-top-distance: 3px;
        `;
      }
    }

    return '';
  }}

  min-height: var(--container-min-height);
  margin: 0 15px;
  position: relative;
`;

const ValueContainer = styled.div<{ placeholderStyle?: 'floating' | 'onlyWhenEmpty' }>`
  z-index: 1;
  width: 100%;
  padding-top: ${(p): string =>
    p.placeholderStyle === 'onlyWhenEmpty' ? 'var(--resting-item-top-distance)' : 'var(--floating-value-top-distance)'};
  padding-bottom: ${(p): string =>
    p.placeholderStyle === 'onlyWhenEmpty' ? 'var(--resting-item-bottom-distance)' : 'var(--floating-value-bottom-distance)'};
`;

const FloaterPlaceholder = styled.div<PlaceholderProps>`
  position: absolute;
  text-align: start;
  left: 0;
  right: 0;
  user-select: none;
  transition: 0.15s all ease-in-out;

  ${Container}:focus-within & {
    ${(p): SerializedStyles => getLabelFloatingOrResting(p.hasContent, p.state, true, p.placeholderVerticalAlign)}
    ${(p): SerializedStyles => getLabelDesign(p.hasContent, p.state, true, p.colorScheme.main)}
  }

  ${Container}:not(:focus-within) & {
    ${(p): SerializedStyles => getLabelFloatingOrResting(p.hasContent, p.state, !!p.hasFocusWithin, p.placeholderVerticalAlign)}
    ${(p): SerializedStyles => getLabelDesign(p.hasContent, p.state, !!p.hasFocusWithin, p.colorScheme.main)}
  }
`;

const HiderPlaceholder = styled.div<PlaceholderProps>`
  position: absolute;
  text-align: start;
  user-select: none;
  line-height: normal;

  ${(p): SerializedStyles => getLabelFloatingOrResting(false, p.state, !!p.hasFocusWithin, p.placeholderVerticalAlign)}
  ${(p): SerializedStyles => getLabelDesign(false, p.state, !!p.hasFocusWithin, p.colorScheme.main)}
  ${(p): string => (p.hasContent ? 'display: none;' : '')}
`;

const getLabelDesign = (
  hasContent: boolean | undefined,
  state: InputGroupState,
  focused: boolean,
  color: string,
): SerializedStyles => {
  if (!(focused || hasContent)) {
    return BodyRegularStartTransparentBlack400.css;
  }

  if (state === 'error') {
    return CaptionStartError600.css;
  }

  if (focused) {
    if (state === 'disabled') {
      return CaptionStartTransparentBlack600.css;
    }

    return calcFocusedPlaceholderCss(color);
  }

  // This means it has content but it is not focused
  return CaptionStartTransparentBlack600.css;
};

const getLabelFloatingOrResting = (
  hasContent: boolean | undefined,
  state: InputGroupState,
  focused: boolean,
  placeholderVerticalAlign: PlaceholderProps['placeholderVerticalAlign'],
): SerializedStyles => {
  const isRestingState = !focused && !hasContent;
  if (!isRestingState) {
    return FloatingPlaceholderClass;
  }

  if (placeholderVerticalAlign === 'top') {
    return RestingTopPlaceholderClass;
  }

  return RestingMiddlePlaceholderClass;
};

const RestingMiddlePlaceholderClass = css`
  top: 50%;
  transform: translateY(-50%);
`;

const RestingTopPlaceholderClass = css`
  top: var(--resting-item-top-distance);
  transform: translateY(0);
`;

const FloatingPlaceholderClass = css`
  top: var(--floating-placeholder-top-distance);
  transform: translateY(0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function calcFocusedPlaceholderCss(color: string): SerializedStyles {
  return css`
    ${CaptionStartColorless.css};
    color: ${color};
  `;
}
