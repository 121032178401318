import React, { FC } from 'react';
import {
  BlueWarningIcon,
  HighlightedInstructionsAdditional,
  HighlightedInstructionsCard,
  HighlightedInstructionsContainer,
  HighlightedInstructionsIcon,
  InstructionsCard,
  InstructionsTitleCard,
  NormalText,
  PaymentInstructionsLineHr,
} from '@supplierRegistration/routes/Styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useTheme from '@app/hooks/useTheme';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import styled from '@emotion/styled';
import Instructions from '@supplierRegistration/images/mexico-instructions.png';

const MexicoFrictionlessAfterFormSpecialComponent: FC = observer(() => {
  const { t } = useTranslation();
  const { headerColor, isHeaderDark } = useTheme<SupplierRegistrationMode>();

  return (
    <div>
      <HighlightedInstructionsCard customBackgroundColor='#f5f6fa'>
        <HighlightedInstructionsIcon>
          <BlueWarningIcon />
        </HighlightedInstructionsIcon>
        <HighlightedInstructionsContainer>
          <HighlightedInstructionsAdditional>
            <BoldText>{t('supplierValidation.afterRegister.mexicoFrictionless.noticeItem1')}</BoldText>
          </HighlightedInstructionsAdditional>
          <HighlightedInstructionsAdditional>
            {t('supplierValidation.afterRegister.mexicoFrictionless.noticeItem2')}
          </HighlightedInstructionsAdditional>
        </HighlightedInstructionsContainer>
      </HighlightedInstructionsCard>

      <InstructionsTitleCard isHeaderDark={isHeaderDark} customBackgroundColor={isHeaderDark ? headerColor.main : 'black'}>
        {t('supplierValidation.afterRegister.mexicoFrictionless.pleaseSendInfo')}
      </InstructionsTitleCard>
      <PaddedInstructionsCard customBackgroundColor='#f5f6fa' noPadding>
        <SendInfo>{t('supplierValidation.afterRegister.mexicoFrictionless.senderBankName')}</SendInfo>
        <PaymentInstructionsLineHr />
        <SendInfo>{t('supplierValidation.afterRegister.mexicoFrictionless.mxnAmount')}</SendInfo>
        <PaymentInstructionsLineHr />
        <SendInfo>{t('supplierValidation.afterRegister.mexicoFrictionless.trackingKey')}</SendInfo>
      </PaddedInstructionsCard>

      <br />
      <UnderlinedText> {t('supplierValidation.afterRegister.mexicoFrictionless.seeBelow')}</UnderlinedText>
      <br />
      <img alt={t('supplierValidation.accessibility.wiringInstructions')} src={Instructions} width={'80%'} />
    </div>
  );
});

export default MexicoFrictionlessAfterFormSpecialComponent;

const SendInfo = styled(NormalText)`
  &:last-child {
    padding-bottom: 14px;
  }

  &:first-child {
    padding-top: 14px;
  }
`;

const PaddedInstructionsCard = styled(InstructionsCard)`
  margin-bottom: 30px;
`;

const UnderlinedText = styled.div`
  text-decoration: underline;
`;

const BoldText = styled.div`
  font-weight: bold;
`;
