import React, { CSSProperties, FC, useRef } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import useScrollTopOnLocationChange from '@app/hooks/useScrollTopOnLocationChange';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useInfraStores from '@app/hooks/useInfraStores';
import SupplierRegistrationFooter from '@supplierRegistration/routes/app/SupplierRegistrationFooter';
import useSupplierRegistrationCustomerParameters from '@supplierRegistration/hooks/useSupplierRegistrationCustomerData';
import Loader from '@app/components/Loader';
import useAppStores from '@app/hooks/useAppStores';
import config from '@app/config';
import ReCAPTCHA from 'react-google-recaptcha';

const SupplierRegistrationAppContent: FC = observer((props) => {
  const { children } = props;

  const { languageStore, navigationStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const appContentContainerRef = useRef<HTMLDivElement | null>(null);
  useScrollTopOnLocationChange(appContentContainerRef);

  const pageDirection = languageStore.isRTL ? 'rtl' : 'ltr';

  useSupplierRegistrationCustomerParameters(!navigationStore.isIpsPage);

  const { currentConfiguration, currentAsyncProcessMaskedData } = supplierRegistrationCustomerDataStore;
  if (currentConfiguration.isNotSettled() || currentAsyncProcessMaskedData?.isNotSettled()) {
    return <Loader spinning fullScreen />;
  }

  const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha; // do NOT add && window['grecaptcha']

  return (
    <PageLayout direction={pageDirection}>
      {shouldUseCaptcha && (
        <ReCAPTCHA
          sitekey={config.supplierRegistration.captcha.siteKey}
          size='invisible'
          ref={supplierRegistrationCustomerDataStore.getCaptchaRef()}
        />
      )}
      <ContentLayout ref={appContentContainerRef}>
        <ContentContainer id='main'>{children}</ContentContainer>
        <StyledFooter />
      </ContentLayout>
    </PageLayout>
  );
});

export default SupplierRegistrationAppContent;

const ContentLayout = styled.main`
  flex: 1 1 auto;
  overflow: auto;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const ContentContainer = styled.div`
  padding-bottom: 32px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledFooter = styled(SupplierRegistrationFooter)`
  flex: 0 0 auto;
  text-align: center;
`;

const PageLayout = styled.div<{ direction?: CSSProperties['direction'] }>`
  display: flex;
  flex-direction: row;
  flex: auto;
  height: 100vh;
  min-height: 0;

  direction: ${(p): CSSProperties['direction'] => p.direction ?? 'ltr'};

  font-family: var(--title-font-family);

  .grecaptcha-badge {
    margin-bottom: 50px !important;
  }
`;
