import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  ArchivedValidatedPayee,
  FileSurveyReportAccountDeterministicValidation,
  PrivatePayeeDeterministicValidationData,
} from '@mortee/domain/mvfCheck';
import ArchivedValidatedPayeeValidations from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/ArchivedValidatedPayeeValidations';
import {
  cleanNoSwiftSuffixAccountDetails,
  cleanNoSwiftSuffixRepresentationAccountDetails,
} from '@app/domain/accountsDetailsHelpers';
import {
  doesAccountDetailsContainRepresentation,
  splitAccountDetailsIntoRepresentationsMap,
} from '@app/domain/accountDetailsRepresentations';
import CategoryAndValues from '@app/components/CategoryAndValues';
import { formatRepresentationWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { groupBy, isDefined } from '@app/utils/utils';
import Expander from '@app/components/inputs/Expander';
import { DeterministicValidationResultType, translateDeterministicValidationLevel } from '@app/domain/deterministicValidation';
import { PrivatePayeeAccountValidationStatus } from '@mortee/domain/privatePayeeAccount';

interface DeterministicValidationResultProps {
  inputAccountDetails: MorteeAccountDetailsExtended;
  deterministicValidation: FileSurveyReportAccountDeterministicValidation;
}

enum AccountValidationCategory {
  notValidated = 'notValidated',
  selfValidated = 'selfValidated',
  validated = 'validated',
}

const classifyAccountDetailsRepresentationByCategory = (
  representation: MorteeRepresentationAccountDetails,
  allValidatedAccountDetails: MorteeAccountDetails[],
  allSelfApprovedAccountDetails: MorteeAccountDetails[],
): AccountValidationCategory => {
  const isValidated = allValidatedAccountDetails.some((validatedAccountDetails) =>
    doesAccountDetailsContainRepresentation(validatedAccountDetails, representation),
  );

  if (isValidated) {
    return AccountValidationCategory.validated;
  }

  const isSelfValidated = allSelfApprovedAccountDetails.some((selfValidatedAccountDetails) =>
    doesAccountDetailsContainRepresentation(selfValidatedAccountDetails, representation),
  );

  return isSelfValidated ? AccountValidationCategory.selfValidated : AccountValidationCategory.notValidated;
};

const groupAllRepresentationsByValidationLevel = (
  accountRepresentationsDetails: MorteeRepresentationAccountDetails[],
  archivedValidatedPayees: ArchivedValidatedPayee[] | null,
  privateAccountValidations: PrivatePayeeDeterministicValidationData[],
): Map<AccountValidationCategory, MorteeRepresentationAccountDetails[]> => {
  const allValidatedAccountDetailsDetails: MorteeAccountDetails[] =
    archivedValidatedPayees
      ?.flatMap((validatedPayee) => validatedPayee.validatedPayeeAccounts)
      .map((validatedPayeeAccount) => validatedPayeeAccount.accountDetails)
      .map(cleanNoSwiftSuffixAccountDetails) ?? [];

  const allSelfApprovedAccountDetails: MorteeAccountDetails[] =
    privateAccountValidations
      .filter(
        (privateAccountValidation) =>
          privateAccountValidation.validationStatus === PrivatePayeeAccountValidationStatus.selfApproved,
      )
      .map((validatedPayeeAccount) => validatedPayeeAccount.accountDetails)
      .map(cleanNoSwiftSuffixAccountDetails) ?? [];

  return groupBy(accountRepresentationsDetails, (accountDetails) =>
    classifyAccountDetailsRepresentationByCategory(
      cleanNoSwiftSuffixRepresentationAccountDetails(accountDetails),
      allValidatedAccountDetailsDetails,
      allSelfApprovedAccountDetails,
    ),
  );
};

const DeterministicValidationResult: FC<DeterministicValidationResultProps> = (props) => {
  const { inputAccountDetails, deterministicValidation } = props;
  const { validationResult, archivedValidatedPayees, privateAccountValidations } = deterministicValidation;

  const accountRepresentationDetails: MorteeRepresentationAccountDetails[] = Array.from(
    splitAccountDetailsIntoRepresentationsMap(inputAccountDetails).values(),
  ).filter(isDefined);

  const representationsByValidationLevel = groupAllRepresentationsByValidationLevel(
    accountRepresentationDetails,
    archivedValidatedPayees,
    privateAccountValidations,
  );

  // Show only the status in case all of the items are not validated or all
  const displayMoreThanStatus =
    (representationsByValidationLevel.get(AccountValidationCategory.notValidated)?.length ?? 0) !==
      accountRepresentationDetails.length &&
    (representationsByValidationLevel.get(AccountValidationCategory.selfValidated)?.length ?? 0) !==
      accountRepresentationDetails.length;

  const archivedValidatedPayeesThatHaveAccounts = archivedValidatedPayees?.filter(
    (archivedValidatedPayee) => !!archivedValidatedPayee.validatedPayeeAccounts.length,
  );

  if (!displayMoreThanStatus) {
    return (
      <CategoryAndValues label='Result' inlineLabel>
        <Result validationResult={validationResult}>{translateDeterministicValidationLevel(validationResult)}</Result>
      </CategoryAndValues>
    );
  }

  return (
    <Container>
      <BigResultText>
        Result:{' '}
        <Result validationResult={validationResult} data-testid='mvf-changelog-table-dv-validation-result'>
          {translateDeterministicValidationLevel(validationResult)}
        </Result>
      </BigResultText>
      {!!representationsByValidationLevel.get(AccountValidationCategory.notValidated)?.length && (
        <CategoryAndValues label='Not validated accounts' data-testid='mvf-changelog-table-not-validated-accounts'>
          {representationsByValidationLevel
            .get(AccountValidationCategory.notValidated)
            ?.map(formatRepresentationWithSwiftban)
            .map((formattedAccount) => (
              <div key={formattedAccount}>{formattedAccount}</div>
            ))}
        </CategoryAndValues>
      )}
      {!!representationsByValidationLevel.get(AccountValidationCategory.selfValidated)?.length && (
        <CategoryAndValues label='Self approved by you' data-testid='mvf-changelog-table-self-validated-accounts'>
          {representationsByValidationLevel
            .get(AccountValidationCategory.selfValidated)
            ?.map(formatRepresentationWithSwiftban)
            .map((formattedAccount) => (
              <div key={formattedAccount}>{formattedAccount}</div>
            ))}
        </CategoryAndValues>
      )}
      {!!archivedValidatedPayeesThatHaveAccounts?.length && (
        <CategoryAndValues label='Validated accounts' data-testid='mvf-changelog-table-validated-accounts'>
          {archivedValidatedPayeesThatHaveAccounts?.map((archivedValidatedPayee, index) => {
            const name = archivedValidatedPayee.primaryValidatedName || archivedValidatedPayee.validatedNames?.[0] || 'Validated';

            return (
              <Expander accessibilityLabel='validated payee info' id={`dv-validated-payee-${name}`} key={index} title={name}>
                <StyledArchivedValidatedPayeeValidations
                  inputAccountDetails={inputAccountDetails}
                  archivedValidatedPayee={archivedValidatedPayee}
                />
              </Expander>
            );
          })}
        </CategoryAndValues>
      )}
    </Container>
  );
};

export default DeterministicValidationResult;

const Container = styled.div`
  & > *:not(:first-child) {
    margin-bottom: 13px;
  }
`;

const Result = styled.span<{ validationResult: DeterministicValidationResultType }>`
  ${(p): string =>
    p.validationResult === DeterministicValidationResultType.notValidated ? 'color: var(--alert-strong-color);' : ''}
`;

const BigResultText = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: var(--transparent-black-900);
`;

const StyledArchivedValidatedPayeeValidations = styled(ArchivedValidatedPayeeValidations)`
  margin-left: 16px;
`;
