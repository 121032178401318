export interface PrivatePayeeAccountServerResponse {
  uniformId: string;
  validationStatus: PrivatePayeeAccountValidationStatus;
  accountDetails: MorteeAccountDetails;
  events: (
    | PrivatePayeeAccountSelfApproveEvent
    | PrivatePayeeAccountPortalSelfApproveEvent
    | PrivatePayeeAccountPortalRemoveSelfApproveEvent
    | PrivatePayeeAccountRemoveSelfApproveEvent
    | MorteeEvent<Exclude<PrivatePayeeAccountEventType,
    | PrivatePayeeAccountEventType.PrivatePayeeAccountSelfApproved
    | PrivatePayeeAccountEventType.PrivatePayeeAccountPortalSelfApproved
    | PrivatePayeeAccountEventType.PrivatePayeeAccountPortalRemoveSelfApprove
    | PrivatePayeeAccountEventType.PrivatePayeeAccountRemoveSelfApproval>>
    )[];
}

export enum PrivatePayeeAccountValidationStatus {
  notValidated = 'NotValidated',
  selfApproved = 'SelfApproved',
  requireStandardValidation = 'RequireStandardValidation',
  requireExtendedValidation = 'RequireExtendedValidation',
}

export interface PrivatePayeeAccount {
  uniformId: string;
  validationStatus: PrivatePayeeAccountValidationStatus;
  accountDetails: MorteeAccountDetails;
  events: (
    | PrivatePayeeAccountSelfApproveEvent
    | PrivatePayeeAccountPortalSelfApproveEvent
    | PrivatePayeeAccountPortalRemoveSelfApproveEvent
    | PrivatePayeeAccountRemoveSelfApproveEvent
    | MorteeEvent<Exclude<PrivatePayeeAccountEventType,
    | PrivatePayeeAccountEventType.PrivatePayeeAccountSelfApproved
    | PrivatePayeeAccountEventType.PrivatePayeeAccountPortalSelfApproved
    | PrivatePayeeAccountEventType.PrivatePayeeAccountPortalRemoveSelfApprove
    | PrivatePayeeAccountEventType.PrivatePayeeAccountRemoveSelfApproval>>
    )[];
}

export enum PrivatePayeeAccountEventType {
  PrivatePayeeAccountRequireExtendedValidation = 'PrivatePayeeAccountRequireExtendedValidation',
  PrivatePayeeAccountMarkRemoved = 'PrivatePayeeAccountMarkRemoved',
  PrivatePayeeAccountCreateEnabled = 'PrivatePayeeAccountCreateEnabled',
  PrivatePayeeAccountCreateDisabled = 'PrivatePayeeAccountCreateDisabled',
  PrivatePayeeAccountSelfApproved = 'PrivatePayeeAccountSelfApproved',
  PrivatePayeeAccountPortalSelfApproved = 'PrivatePayeeAccountPortalSelfApproved',
  PrivatePayeeAccountRequireStandardValidation = 'PrivatePayeeAccountRequireStandardValidation',
  PrivatePayeeAccountPortalRemoveSelfApprove = 'PrivatePayeeAccountPortalRemoveSelfApproval',
  PrivatePayeeAccountRemoveSelfApproval = 'PrivatePayeeAccountRemoveSelfApproval',
}

export interface PrivatePayeeAccountSelfApproveEvent
  extends MorteeEvent<PrivatePayeeAccountEventType.PrivatePayeeAccountSelfApproved,
    PrivatePayeeAccountPortalSelfApproveEventData> {
  performingUserName: string;
}

export interface PrivatePayeeAccountPortalSelfApproveEvent
  extends MorteeEvent<PrivatePayeeAccountEventType.PrivatePayeeAccountPortalSelfApproved,
    PrivatePayeeAccountPortalSelfApproveEventData> {
  performingUserName: string;
}

export interface PrivatePayeeAccountPortalRemoveSelfApproveEvent
  extends MorteeEvent<PrivatePayeeAccountEventType.PrivatePayeeAccountPortalRemoveSelfApprove,
    PrivatePayeeAccountRemoveSelfApproveEventData> {
  performingUserName: string;
}

export interface PrivatePayeeAccountRemoveSelfApproveEvent
  extends MorteeEvent<PrivatePayeeAccountEventType.PrivatePayeeAccountRemoveSelfApproval,
    PrivatePayeeAccountRemoveSelfApproveEventData> {
  performingUserName: string;
}

interface PrivatePayeeAccountPortalSelfApproveEventData {
  reason: PrivatePayeeAccountSelfApproveReason;
  note: string;
  description: string;
  contactPhoneNumber: string;
  contactName: string;
  phoneCallTimestamp: number;
  attachmentFiles: FileMetadata[];
}

interface PrivatePayeeAccountRemoveSelfApproveEventData {
  note: string;
}

export enum PrivatePayeeAccountSelfApproveReason {
  phoneCallVerification = 'PhoneCallVerification',
  transactionHistory = 'TransactionHistory',
  smallAmountTransaction = 'SmallAmountTransaction',
  oneTimeTransaction = 'OneTimeTransaction',
  other = 'Other',
}

export const getPrivatePayeeAccountSelfApproveReasonText = (reason: PrivatePayeeAccountSelfApproveReason): string => {
  switch (reason) {
    case PrivatePayeeAccountSelfApproveReason.phoneCallVerification:
      return 'Account verified by phone';
    case PrivatePayeeAccountSelfApproveReason.transactionHistory:
      return 'Previous successful transactions';
    case PrivatePayeeAccountSelfApproveReason.smallAmountTransaction:
      return 'Small amount transaction only';
    case PrivatePayeeAccountSelfApproveReason.oneTimeTransaction:
      return 'One time transaction';
    case PrivatePayeeAccountSelfApproveReason.other:
      return 'Other';
  }
};

export type SelfApprovePrivatePayeeAccountRequest =
  | SelfApprovePrivatePayeeAccountSimpleRequest
  | SelfApprovePrivatePayeeAccountOtherRequest
  | SelfApprovePrivatePayeeAccountPhoneCallRequest;

interface SelfApprovePrivatePayeeAccountSimpleRequest {
  reason:
    | PrivatePayeeAccountSelfApproveReason.oneTimeTransaction
    | PrivatePayeeAccountSelfApproveReason.transactionHistory
    | PrivatePayeeAccountSelfApproveReason.smallAmountTransaction;
  note?: string;
}

interface SelfApprovePrivatePayeeAccountOtherRequest {
  reason: PrivatePayeeAccountSelfApproveReason.other;
  description: string;
  note?: string;
}

interface SelfApprovePrivatePayeeAccountPhoneCallRequest {
  reason: PrivatePayeeAccountSelfApproveReason.phoneCallVerification;
  contactPhoneNumber: string;
  contactName: string;
  phoneCallTimestamp: number;
  phoneCallDescription: string;
}

export type RemoveSelfApproveRequest = {
  note: string;
};