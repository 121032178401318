import request, { HttpMethod } from '@app/libs/request';
import config from '@app/config';
import { UserRoleTypeServerResponse } from '@app/domain/userManagement/roleTypes';
import { ORGANIZATION_HEADER_NAME } from '@app/utils/userIdentityHeadersGenerator';

export async function getOrganizationWebAppRoleTypes(organizationId: string): Promise<UserRoleTypeServerResponse[]> {
  return request<UserRoleTypeServerResponse[]>(config.serverUrls.backee, `/api/web-app-role-types/organization`, {
    method: HttpMethod.get,
    headers: {
      [ORGANIZATION_HEADER_NAME]: organizationId,
    },
    errorsHandler: {
      default: {
        message: 'Cannot get roles relevant to organization',
      },
    },
  });
}
