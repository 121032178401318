import { ComponentType } from 'react';
import { fromDefinition } from '../domain/sections';
import { arrayToObject } from '@app/utils/arrayUtils';
import InfraStores from '../stores/InfraStores';

export default abstract class Mode<TInfraStore extends InfraStores = InfraStores, TAppStores extends object = {}> {
  private readonly _sections: Section[];
  private readonly _routes: Partial<{ [key in RoutesKeys]: FlatRoute }>;

  protected constructor(sections: SectionDefinition[]) {
    this._sections = sections.map((section) => fromDefinition(section, this.isAnonymous));
    this._routes = arrayToObject<RoutesKeys, FlatRoute, 'id'>(
      this._sections.flatMap((section) => section.routes),
      'id',
    );
  }

  get sections(): Section[] {
    return this._sections;
  }

  get allRoutes(): Partial<{ [key in RoutesKeys]: FlatRoute }> {
    return this._routes;
  }

  abstract createInfraStores(): TInfraStore;

  get isAnonymous(): boolean {
    return false;
  }

  abstract createAppStores(infraStores: TInfraStore): TAppStores;

  abstract getAppContentComponent(): ComponentType;

  abstract getHeadTitle(): string;

  abstract getDefaultHeaderComponent(): ComponentType;

  getLocalePath(): string {
    return 'locales/default/list.json';
  }
}

export type InfraStoresOfMode<TMode extends Mode> = TMode extends Mode<infer TInfraStore> ? TInfraStore : never;
export type AppStoresOfMode<TMode extends Mode> = TMode extends Mode<infer TInfraStore, infer TAppStores> ? TAppStores : never;
