import { ConditionalValidationRule } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';

function getAdditionalSwiftRules(): ConditionalValidationRule[] {
  return [
    {
      required: true,
      transform: trim,
      translatedMessage: 'supplierValidation.supplierRegister.errors.missingSwift',
    },
  ];
}

function getBankCodeFields(): SupplierRegistrationField {
  return {
    id: 'inpt-supp-reg-bank-code',
    inputType: 'number',
    decoratorRules: [
      {
        required: false,
        transform: trim,
      },
    ],
  };
}

export const DK: SupplierRegistrationCountryFields = {
  swiftCodeAdditionalRules: getAdditionalSwiftRules,
  bankCode: getBankCodeFields,
};
