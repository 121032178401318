import { computed, makeObservable, observable } from 'mobx';
import Loadable, { lazyLoadable, LoadableCreator } from '@app/utils/Loadable';
import { BackeeServiceType } from '@backee/domain/serviceTypes';
import { getAllServiceTypes } from '@backee/services/serviceTypesService';

export default class OrganizationsStore {
  @observable private _serviceTypes: Loadable<BackeeServiceType[]> = LoadableCreator.notStarted();

  constructor() {
    makeObservable(this);
  }

  @computed
  get serviceTypes(): Loadable<BackeeServiceType[]> {
    return lazyLoadable(
      () => this._serviceTypes,
      (newValue) => (this._serviceTypes = newValue),
      async () => {
        const allServiceTypes = await getAllServiceTypes();
        return allServiceTypes.sort((serviceType1, serviceType2) => {
          return serviceType1.id.localeCompare(serviceType2.id);
        });
      },
    );
  }
}
