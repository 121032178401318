import React, { FC } from 'react';
import SVG from '@app/components/SVG';
import InfoImage from '@app/images/designSystem/indication/info.svg';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import TooltipWithFourCorners from '@app/components/TooltipWithFourCorners';
import useAppStores from '@app/hooks/useAppStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { BodySmallStartTransparentBlack600 } from '@app/components/Text';

interface Props {
  className?: string;
}

const PrefilledInformationByCustomerTooltip: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const tooltipContent = (
    <div>
      <BodySmallStartTransparentBlack600.div>
        {t('supplierValidation.supplierRegister.asyncInitiateValidation.prefilledInformationTooltipContent', {
          customerName: supplierRegistrationCustomerDataStore.customerName,
        })}
      </BodySmallStartTransparentBlack600.div>
    </div>
  );

  return (
    <TooltipWithFourCorners title={tooltipContent} overlayStyle={{ maxWidth: '40ch' }}>
      <InformationIcon
        className={className}
        image={InfoImage}
        accessibilityLabel={t('supplierValidation.supplierRegister.asyncInitiateValidation.prefilledInformationIconLabel')}
      />
    </TooltipWithFourCorners>
  );
};

export default PrefilledInformationByCustomerTooltip;

const InformationIcon = styled(SVG)`
  width: 24px;
  height: 24px;
`;
