import styled from '@emotion/styled';
import Button from '@app/components/Button';

export const ActionsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PrimaryButton = styled(Button)`
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 6px;
`;