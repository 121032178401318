import React, { ReactElement } from 'react';
import { FormFieldDecorators, FormInputPlaceholderProps } from '@app/utils/form/form';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import Loadable from '@app/utils/Loadable';
import DropdownItem from '@app/components/inputs/DropdownItem';
import { ForwardingFC } from '@app/domain/technicals/components';
import { trim } from '@app/utils/stringUtils';
import { REQUIRED_FORM_MESSAGE } from '@app/domain/uiConsts';
import { USER_FIELD_MAX_LENGTH } from '@app/domain/userManagement/users';
import styled from '@emotion/styled';
import FormItemBox from '@app/components/inputs/FormItemBox';
import useAppStores from '@app/hooks/useAppStores';
import BackeeMode from '@backee/backeeMode';

export interface DoubleSSOIdentityForUser {
  ssoId: string;
  emailKnoxerSSOProvider: string;
  phoneKnoxerSSOProvider: string;
}

interface Props extends FormInputPlaceholderProps<false> {
  id: string;
  dataTestId?: string;
  fieldName: string;
  allEmailKnoxerSSOProviders: Loadable<string[]>;
  allPhoneKnoxerSSOProviders: Loadable<string[]>;
  disabled?: boolean;
  className?: string;
}

const DoubleSSOFlowInput: ForwardingFC<HTMLDivElement, Props> = React.forwardRef(
  (
    {
      id,
      dataTestId = id,
      fieldName,
      accessibilityLabel,
      allEmailKnoxerSSOProviders,
      allPhoneKnoxerSSOProviders,
      disabled,
      className,
    },
    ref,
  ) => {
    const { knoxersStore } = useAppStores<BackeeMode>();
    const knoxers = knoxersStore.knoxers.result;

    const fieldDecorators = createFieldDecorators();
    return (
      <Line className={className} ref={ref}>
        <SameWidthFormItemBox
          fieldName={`${fieldName}.ssoId`}
          fieldDecoratorOptions={fieldDecorators.ssoId}
          showAsRequired
          disabled={disabled}
        >
          <NakedFormInput
            name={`txt-${id}-sso-id`}
            dataTestId={`txt-${dataTestId}-sso-id`}
            type='text'
            placeholder='SSO ID'
            accessibilityLabel={`${accessibilityLabel} - SSO Id`}
            disableSuggestion
          />
        </SameWidthFormItemBox>
        <SameWidthFormItemBox
          fieldName={`${fieldName}.emailKnoxerSSOProvider`}
          fieldDecoratorOptions={fieldDecorators.emailKnoxerSSOProvider}
          showAsRequired
          disabled={disabled}
        >
          <NakedDropdown
            placeholder={`SSO Provider - ${knoxers?.emailKnoxer.name} Knoxer`}
            accessibilityLabel={`${accessibilityLabel} - ${knoxers?.emailKnoxer.name} Knoxer SSO Provider`}
            name={`drp-${id}-email-knoxer-sso-provider`}
            dataTestId={`drp-${dataTestId}-email-knoxer-sso-provider`}
            loading={!allEmailKnoxerSSOProviders.isResolved()}
          >
            {
              allEmailKnoxerSSOProviders.result?.map((ssoProvider) => {
                return (
                  <DropdownItem key={ssoProvider} value={ssoProvider} textWhenSelected={ssoProvider}>
                    {ssoProvider}
                  </DropdownItem>
                );
              }) as ReactElement[]
            }
          </NakedDropdown>
        </SameWidthFormItemBox>
        <SameWidthFormItemBox
          fieldName={`${fieldName}.phoneKnoxerSSOProvider`}
          fieldDecoratorOptions={fieldDecorators.phoneKnoxerSSOProvider}
          showAsRequired
          disabled={disabled}
        >
          <NakedDropdown
            placeholder={`SSO Provider - ${knoxers?.phoneKnoxer.name} Knoxer`}
            accessibilityLabel={`${accessibilityLabel} - ${knoxers?.phoneKnoxer.name} Knoxer SSO Provider`}
            name={`drp-${id}-phone-knoxer-sso-provider`}
            dataTestId={`drp-${dataTestId}-phone-knoxer-sso-provider`}
            loading={!allPhoneKnoxerSSOProviders.isResolved()}
          >
            {
              allPhoneKnoxerSSOProviders.result?.map((ssoProvider) => {
                return (
                  <DropdownItem key={ssoProvider} value={ssoProvider} textWhenSelected={ssoProvider}>
                    {ssoProvider}
                  </DropdownItem>
                );
              }) as ReactElement[]
            }
          </NakedDropdown>
        </SameWidthFormItemBox>
      </Line>
    );
  },
);

export default DoubleSSOFlowInput;

const createFieldDecorators = (): FormFieldDecorators<DoubleSSOIdentityForUser> => ({
  ssoId: {
    rules: [
      {
        required: true,
        transform: trim,
        message: REQUIRED_FORM_MESSAGE,
      },
      {
        max: USER_FIELD_MAX_LENGTH.ssoId,
        message: `Max ${USER_FIELD_MAX_LENGTH.ssoId} characters`,
      },
    ],
  },
  emailKnoxerSSOProvider: {
    rules: [
      {
        required: true,
        message: REQUIRED_FORM_MESSAGE,
      },
    ],
  },
  phoneKnoxerSSOProvider: {
    rules: [
      {
        required: true,
        message: REQUIRED_FORM_MESSAGE,
      },
    ],
  },
});

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const SameWidthFormItemBox = styled(FormItemBox)`
  flex: 1;
`;
