import React, { FunctionComponent, ReactElement } from 'react';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import { getCountryName } from '@app/domain/countries';
import Button from '@app/components/Button';
import { formatAccountWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { getLEIText } from '@app/utils/legalIdentifierUtils';
import { VerificationResultText } from '@app/domain/accountVerification';
import { LegalEntityIdentifier } from '@app/domain/legalEntityIdentifier';

interface ValidationInfoModalProps {
  verificationResultText: VerificationResultText;
  validatedNames: string[] | null;
  validatedLegalEntityIdentifiers: LegalEntityIdentifier[];
  validatedCountryCode: string | null;
  accountDetails?: MorteeAccountDetailsExtended | MorteeAccountDetailsExtended[] | null;
  validatedAddresses: string[] | null;
  onReturn: () => void;
  dataTestId?: string;
  className?: string;
}

const ValidationInfoModal: FunctionComponent<ValidationInfoModalProps> = (props) => {
  const renderLabelAndValues = (key: string, values?: string[] | null): ReactElement => {
    return (
      <RowContainer>
        <Label>{key}:</Label>
        <ColumnContainer>
          {values?.map((value) => (
            <Text key={value}>{value}</Text>
          ))}
        </ColumnContainer>
      </RowContainer>
    );
  };

  const renderValidationDescription = (): ReactElement => {
    const { verificationResultText } = props;

    if (verificationResultText === VerificationResultText.standardValidation) {
      return (
        <Text>
          The vendor's bank account information was verified by nsKnox. This verification ensures the specified bank account was
          registered under the specified vendor name and ID and, therefore, it is most probably correct.
        </Text>
      );
    }
    if (verificationResultText === VerificationResultText.extendedValidation) {
      return (
        <Text>
          Bank account details were validated through Extended Validation. The vendor's bank account information was verified by
          nsKnox. This is the most comprehensive verification as it actively verifies the validity of the account and the identity
          of the account holders.
        </Text>
      );
    }
    return <></>;
  };

  const {
    verificationResultText,
    validatedNames,
    accountDetails,
    validatedAddresses,
    validatedLegalEntityIdentifiers,
    validatedCountryCode,
    className,
    dataTestId,
    onReturn,
  } = props;

  return (
    <MainContainer className={className} data-testid={dataTestId}>
      <Header>
        <Title>{verificationResultText}</Title>
        <CloseButton id='btn-close-validation-info-modal' appearance='text' colorScheme='primary' onClick={onReturn}>
          <SVG accessibilityLabel='close' image={ClearIcon} height={18} />
        </CloseButton>
      </Header>
      <Info>
        {renderLabelAndValues('Names', validatedNames)}
        {renderLabelAndValues('Vendor IDs', validatedLegalEntityIdentifiers?.map(getLEIText))}
        {renderLabelAndValues('Country', [getCountryName(validatedCountryCode)])}
        {renderLabelAndValues('Accounts', formatAccountWithSwiftban(accountDetails))}
        {renderLabelAndValues('Addresses', validatedAddresses)}
      </Info>
      {renderValidationDescription()}
    </MainContainer>
  );
};

export default ValidationInfoModal;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 32px;
  width: 542px;
`;

const Title = styled.div`
  color: var(--accent-blue-600);
  font-size: 17px;
  letter-spacing: 0.23px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--transparent-black-600);
`;

const Label = styled.span`
  font-weight: bold;
  letter-spacing: 0.39px;
  font-size: 14px;
  color: var(--transparent-black-900);
  margin-right: 4px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  color: rgba(53, 60, 79, 0.4);
  align-self: flex-end;
  padding: 4px 6px 5px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Info = styled.div`
  margin-top: 22px;
  margin-bottom: 25px;
`;
