import React, { FC, ReactElement, ReactNode, useRef, useState } from 'react';
import styled from '@emotion/styled';
import SupplierValidationRecordActivityLogCommentInput, {
  NewCommentTypesWhitelist,
} from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/activityLog/SupplierValidationRecordActivityLogCommentInput';
import { ActivityLog, CommentEvent, isEventAComment } from '@mortee/domain/validationSystemTimelineEvents';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import LoadableTransitionLoader from '@app/components/LoadableTransitionLoader';
import { CommentTypes, commentTypesDisplayData, SupplierValidationRecord } from '@mortee/domain/validationSystem';
import Loadable from '@app/utils/Loadable';
import SupplierValidationRecordComment from '@mortee/routes/validationSystem/editValidtionRecord/secondaryTabs/SupplierValidationRecordComment';
import MultiChipSelector from '@app/components/MultiChipSelector';

type CommentsFilterOptions = CommentTypes[];
const DEFAULT_FILTER_OPTION: CommentsFilterOptions = [];

interface Props {
  record: SupplierValidationRecord;
  allTimelineEventLoadable: Loadable<ActivityLog>;
  allowedNewCommentTypes: NewCommentTypesWhitelist;
  onSaved(): void;
  className?: string;
}

const SupplierValidationRecordComments: FC<Props> = ({
  record,
  allTimelineEventLoadable,
  allowedNewCommentTypes,
  onSaved,
  className,
}) => {
  const [filter, setFilter] = useState<CommentsFilterOptions>(DEFAULT_FILTER_OPTION);
  const isFirstLoadOfComments = useRef<boolean>(true);

  const commentEventsLoadable = allTimelineEventLoadable.map((timelineEvents: ActivityLog): CommentEvent[] => {
    const allComments = timelineEvents.events.filter(isEventAComment);

    if (!filter.length) {
      return allComments;
    }

    return allComments.filter((event) => filter.includes(event.data.comment.commentType));
  });

  const onSubmitComment = async (): Promise<void> => {
    await onSaved();
    scrollToBottom(true);
  };

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  function scrollToBottom(smooth: boolean): void {
    messagesEndRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'instant' });
  }

  if (isFirstLoadOfComments.current && commentEventsLoadable.isResolved()) {
    isFirstLoadOfComments.current = false;
    setTimeout(() => {
      scrollToBottom(false);
    }, 0);
  }

  function renderLoadedComments(comments: CommentEvent[]): ReactElement {
    if (!comments.length) {
      return getEmptyCommentsText(filter);
    }

    return (
      <Content>
        {comments.map((comment) => (
          <PaddedSupplierValidationRecordComment
            key={comment.data.comment.id}
            record={record}
            comment={comment}
            onCommentDeleted={onSaved}
          />
        ))}
      </Content>
    );
  }

  function getEmptyCommentsText(filter: CommentsFilterOptions): ReactElement {
    if (filter.length) {
      return <Content>No comments under this filter</Content>;
    }

    return <Content>No comments have been written yet</Content>;
  }

  return (
    <Main className={className} data-testid='supplier-validation-record-comments'>
      <FilterLine>
        <span>Quick filter:</span>
        <MultiChipSelector
          id='supplier-validation-record-comments-filters'
          value={filter}
          onChange={(newFilter: CommentTypes[]): void => setFilter(newFilter ?? DEFAULT_FILTER_OPTION)}
          options={[
            {
              id: 'supplier-validation-record-comments-filters-validation',
              dataTestId: 'supplier-validation-record-comments-filters-validation',
              text: commentTypesDisplayData.InternalValidation.text,
              value: CommentTypes.internalValidation,
              selectedColorScheme: commentTypesDisplayData.InternalValidation.colorScheme,
            },
            {
              id: 'supplier-validation-record-comments-filters-internal',
              dataTestId: 'supplier-validation-record-comments-filters-internal',
              text: commentTypesDisplayData.InternalComment.text,
              value: CommentTypes.internalComment,
              selectedColorScheme: commentTypesDisplayData.InternalComment.colorScheme,
            },
            {
              id: 'supplier-validation-record-comments-filters-public',
              dataTestId: 'supplier-validation-record-comments-filters-public',
              text: commentTypesDisplayData.PublicComment.text,
              value: CommentTypes.publicComment,
              selectedColorScheme: commentTypesDisplayData.PublicComment.colorScheme,
            },
          ]}
          accessibilityLabel='Record Comments Filter'
        />
      </FilterLine>
      <StyledVerticalShadowScroller>
        <LoadableTransitionLoader loadable={commentEventsLoadable} firstLoadingContent={(): ReactNode => <InitialHeightDiv />}>
          {renderLoadedComments}
        </LoadableTransitionLoader>
        <div ref={messagesEndRef} />
      </StyledVerticalShadowScroller>
      <StyledSupplierValidationRecordActivityLogCommentInput
        allowedCommentTypes={allowedNewCommentTypes}
        onCommentSaved={onSubmitComment}
        staticId={record.staticId}
      />
    </Main>
  );
};

export default SupplierValidationRecordComments;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin-bottom: 15px;

  flex: 1;
`;

const FilterLine = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 10px;
  flex-wrap: wrap;

  flex: 0 0 auto;
`;

const StyledVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
`;

const InitialHeightDiv = styled.div`
  height: 40vh;
`;

const Content = styled.div`
  padding-inline: 16px;
`;

const StyledSupplierValidationRecordActivityLogCommentInput = styled(SupplierValidationRecordActivityLogCommentInput)`
  margin: 30px 24px;
`;

const PaddedSupplierValidationRecordComment = styled(SupplierValidationRecordComment)`
  padding-block: 16px 26px;
`;
