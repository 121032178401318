import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import {
  KnoxIdServerResponse,
  LegalEntityIdTypes,
  LegalEntityIdTypesServerResponse,
  OrganizationDataFromRegistrationFormRefServerResponse,
  StoreValidatedPayeeAccountRequest,
  StoreValidatedPayeeAccountWithTypeRequest,
  StoreValidatedPayeeRequest,
  ValidatedPayeeAccountStoreWarningServerResponse,
  ValidatedPayeeStoreWarningServerResponse,
} from '@mortee/domain/vaildatedPayeeManagement';
import { ValidatedPayeeWithAccountsServerResponse } from '@app/domain/validatedPayeeWithAccounts';
import { removeIrrelevantAccountDetailsFields } from '@app/domain/accountsDetailsHelpers';
import { ValidatedPayeeServerResponse } from '@app/domain/validatedPayee';
import { AggregatedValidatedPayeeServerResponse } from '@app/domain/aggregatedValidatedPayee';
import { ValidatedPayeeAccountServerResponse } from '@app/domain/validatedPayeeAccount';

export async function storeValidatedPayee(
  validatedPayeeRequest: StoreValidatedPayeeRequest,
): Promise<ValidatedPayeeServerResponse> {
  return request<ValidatedPayeeServerResponse>(serverUrl, `/api/validations`, {
    data: validatedPayeeRequest,
    method: HttpMethod.post,
    errorsHandler: {
      SANCTIONS_SCREENING_NOT_CONFIGURED_ERROR: {
        message: (): string => 'No sanctions lists defined',
      },
      default: {
        message: 'Could not create validated payee.',
      },
    },
  });
}

export async function editValidatedPayee(
  validatedPayeeUniformId: string,
  validatedPayeeRequest: StoreValidatedPayeeRequest,
): Promise<ValidatedPayeeServerResponse> {
  return request<ValidatedPayeeServerResponse>(serverUrl, `/api/validations/:validatedPayeeUniformId`, {
    data: validatedPayeeRequest,
    method: HttpMethod.put,
    pathParams: { validatedPayeeUniformId },
    errorsHandler: {
      default: {
        message: 'Could not edit validated payee.',
      },
    },
  });
}

export async function storeValidatedAccountPayee(
  validatedPayeeUniformId: string,
  validatedPayeeAccountRequest: StoreValidatedPayeeAccountWithTypeRequest,
): Promise<ValidatedPayeeAccountServerResponse> {
  return request<ValidatedPayeeAccountServerResponse>(serverUrl, `/api/validations/:validatedPayeeUniformId/accounts`, {
    data: validatedPayeeAccountRequest,
    pathParams: { validatedPayeeUniformId },
    method: HttpMethod.post,
    errorsHandler: {
      default: {
        message: 'Could not create validated payee account.',
      },
    },
  });
}

export default {
  async calculateValidatedPayeeStoreWarnings(
    validatedPayeeRequest: StoreValidatedPayeeRequest,
  ): Promise<ValidatedPayeeStoreWarningServerResponse[]> {
    return request<ValidatedPayeeStoreWarningServerResponse[]>(serverUrl, `/api/validations/warnings`, {
      data: validatedPayeeRequest,
      method: HttpMethod.post,
      errorsHandler: {
        default: {
          message: 'Could not calculate validated payee warnings.',
        },
      },
    });
  },

  async getValidatedPayeesWithAccountsByUniformIds(uniformIds: string[]): Promise<ValidatedPayeeWithAccountsServerResponse[]> {
    return request<ValidatedPayeeWithAccountsServerResponse[]>(serverUrl, `/api/validations/with-accounts/uniform-ids`, {
      data: uniformIds,
      method: HttpMethod.post,
      errorsHandler: {
        default: {
          message: 'Could not fetch validated payees.',
        },
      },
    });
  },

  async getAllLegalEntityIdTypes(): Promise<LegalEntityIdTypesServerResponse> {
    return request<LegalEntityIdTypesServerResponse>(serverUrl, `/api/leis`, {
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not get data required for the form.',
        },
      },
    });
  },

  async getOrganizationDataBySupplierValidationRegistrationNumber(
    supplierValidationRegistrationNumber: string,
  ): Promise<OrganizationDataFromRegistrationFormRefServerResponse> {
    return request<OrganizationDataFromRegistrationFormRefServerResponse>(
      serverUrl,
      `/api/validations/organization-data/:supplierValidationRegistrationNumber`,
      {
        method: HttpMethod.get,
        pathParams: { supplierValidationRegistrationNumber },
        suppressNotification: true,
        errorsHandler: {
          SUPPLIER_VALIDATION_PROCESS_DOES_NOT_EXIST: {
            message: (): string => 'supplier validation registration number does not exists',
          },
          default: {
            message: 'Could not fetch organization data for supplier validation registration number.',
          },
        },
      },
    );
  },

  async calculateValidatedPayeeAccountStoreWarnings(
    validatedPayeeUniformId: string,
    validatedPayeeAccountRequest: StoreValidatedPayeeAccountRequest,
  ): Promise<ValidatedPayeeAccountStoreWarningServerResponse[]> {
    return request<ValidatedPayeeAccountStoreWarningServerResponse[]>(
      serverUrl,
      `/api/validations/:validatedPayeeUniformId/accounts/warnings`,
      {
        data: validatedPayeeAccountRequest,
        method: HttpMethod.post,
        pathParams: { validatedPayeeUniformId },
        errorsHandler: {
          default: {
            message: 'Could not calculate validated payee account warnings.',
          },
        },
      },
    );
  },

  async searchValidatedPayeeAccountsByAccountRepresentations(
    searchedAccountDetails: MorteeAccountDetails,
  ): Promise<AggregatedValidatedPayeeServerResponse[]> {
    return request<AggregatedValidatedPayeeServerResponse[]>(serverUrl, `/api/validations/payee-by-account-details`, {
      params: removeIrrelevantAccountDetailsFields(searchedAccountDetails),
      method: HttpMethod.get,
      errorsHandler: {
        default: {
          message: 'Could not search existing validated payees by account details.',
        },
      },
    });
  },
};

export async function generateKnoxId(suffix?: string): Promise<KnoxIdServerResponse> {
  return request<KnoxIdServerResponse>(serverUrl, `/api/knox-ids`, {
    data: { suffix },
    method: HttpMethod.post,
    errorsHandler: {
      default: {
        message: 'Could not get generate knox id.',
      },
    },
  });
}

export function transformLegalEntityIdTypes(serverResponse: LegalEntityIdTypesServerResponse): LegalEntityIdTypes {
  return serverResponse;
}
