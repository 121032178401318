import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import GuestStepDoneSVG from '@app/guestLogin/images/guest-step-done.svg';
import SVG from '@app/components/SVG';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import { useTranslation } from 'react-i18next';

interface Props {
  steps: ReactNode[];
  currentStepIndex: number;
  showStepNumbering?: boolean;
  isLastStepAutomaticallyPassed?: boolean;
  className?: string;
  isHeaderDark: boolean;
}

const StepsProgress: FC<Props> = observer(
  ({ steps, currentStepIndex, showStepNumbering, isLastStepAutomaticallyPassed, className, isHeaderDark }) => {
    const { languageStore } = useInfraStores();
    const { t } = useTranslation();

    function isStepPassed(stepIndex: number): boolean {
      const isLastStep = stepIndex == steps.length - 1;

      if (isLastStep && isLastStepAutomaticallyPassed) {
        return true;
      }

      return stepIndex < currentStepIndex;
    }

    function isStepCurrentOrPassed(stepIndex: number): boolean {
      return stepIndex <= currentStepIndex;
    }

    function isStepCurrent(stepIndex: number): boolean {
      return stepIndex === currentStepIndex;
    }

    function renderStepCircleContent(stepIndex: number): ReactNode {
      if (isStepPassed(stepIndex)) {
        return (
          <SVG
            data-testid='vldt-for-supp-check-icon'
            accessibilityLabel={t('general.accessibility.checkIcon')}
            image={GuestStepDoneSVG}
            height={18}
            width={18}
          />
        );
      }

      if (showStepNumbering) {
        return stepIndex + 1;
      }

      return null;
    }

    return (
      <Container className={className}>
        {steps.map((stepTitle, stepIndex) => {
          const currentOrPassed = isStepCurrentOrPassed(stepIndex);

          return (
            <SectionContainer
              data-testid='vldt-for-supp-steps'
              key={stepIndex}
              isRTL={languageStore.isRTL}
              isHeaderDark={isHeaderDark}
            >
              <Circle isCurrentStepOrPassed={currentOrPassed}>{renderStepCircleContent(stepIndex)}</Circle>
              <StateText isStepCurrentOrPassed={isStepCurrentOrPassed(stepIndex)} isCurrentStep={isStepCurrent(stepIndex)}>
                {stepTitle}
              </StateText>
            </SectionContainer>
          );
        })}
      </Container>
    );
  },
);

export default StepsProgress;

const Container = styled(LocaleAwareDiv)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  --circle-size: 24px;
  --line-distance-from-ball: 8px;
  --line-thickness: 2px;
`;

const SectionContainer = styled.div<{ isRTL: boolean; isHeaderDark: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      top: calc(var(--circle-size) / 2 - calc(var(--line-thickness) / 2));
      width: calc(100% - var(--circle-size) - calc(var(--line-distance-from-ball) * 2));
      ${(p): string => (p.isRTL ? 'right' : 'left')}: calc(50% + calc(var(--circle-size) / 2 + var(--line-distance-from-ball)));
      height: var(--line-thickness);
      background-color: var(${(p): string => (p.isHeaderDark ? '--white' : '--black-strong')});
      order: -1;
    }
  }
`;

const Circle = styled.div<{ isCurrentStepOrPassed?: boolean }>`
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  ${(p): string => (p.isCurrentStepOrPassed ? '' : 'border: 1.2px solid #909090')};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: bold;

  margin-bottom: 12px;

  transition: background-color 0.3s, color 0.3s;
  background-color: var(${(p): string => (p.isCurrentStepOrPassed ? '--action-color, var(--accent-blue-600)' : '--white')});
  color: var(${(p): string => (p.isCurrentStepOrPassed ? '--white' : '--primary-300')});
`;

const StateText = styled.div<{ isCurrentStep?: boolean; isStepCurrentOrPassed?: boolean }>`
  font-family: var(--text-font-family);
  font-size: 12px;
  line-height: 1.33;
  text-align: center;

  font-weight: ${(p): string => (p.isCurrentStep ? 'bold' : 'normal')};
  color: ${(p): string =>
    p.isStepCurrentOrPassed
      ? 'var(--current-or-passed-step-color, var(--accent-blue-600))'
      : 'var(--not-passed-step-color, var(--white))'};
  transition: color 0.3s;
`;
