import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import browserHistory from '@app/utils/browserHistory';
import BackArrowIcon from '@app/images/arrows/left-arrow-thick-accent.svg';
import { History } from 'history';
import SVG from '@app/components/SVG';
import LocationDescriptor = History.LocationDescriptor;

interface LinkProps {
  to: LocationDescriptor;
  className?: string;
}

interface ActionProps {
  onClick: () => void;
  className?: string;
}

const PageHeaderBackButton: FunctionComponent<LinkProps | ActionProps> = (props) => {
  const { className } = props;
  const goToPage = (): void => {
    if ('to' in props) {
      browserHistory.push(props.to);
    } else {
      props.onClick();
    }
  };

  return (
    <RoundButton
      id='btn-header-back'
      colorScheme='grey'
      appearance='text'
      cornerType='none'
      onClick={goToPage}
      className={className}
    >
      <SVG accessibilityLabel='' width={28} height={28} image={BackArrowIcon} />
    </RoundButton>
  );
};

export default PageHeaderBackButton;

const RoundButton = styled(Button)`
  padding: 8px;
  border-radius: 50%;

  align-self: flex-end;
`;
