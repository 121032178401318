import { observer } from 'mobx-react';
import React, { FunctionComponent, ReactElement, useRef } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { GENERAL_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { ActionsContainer, PrimaryButton } from '@app/guestLogin/routes/Styles';
import { Form } from 'antd';
import styled from '@emotion/styled';
import { confirm, ModalBody, ModalContainer, ModalTextLine, ModalTitle } from '@app/components/Modal';
import DropdownItem from '@app/components/inputs/DropdownItem';
import COUNTRIES, {
  CountryCode,
  DIAL_CODES,
  getCountriesOrder,
  getCountryByCountryCode,
  getCountryCodeByDialCode,
} from '@app/domain/countries';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import { GuestAuthCodeDeliveryMethod } from '@app/guestLogin/domain/guestLogin';
import { LoginSubTitle, LoginTitle } from '../GuestLoginFlow';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';
import { onChangeValidateOtherFields, validatePhoneNumber } from '@app/utils/validators';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import { storeUserCountry, useLocaleCountry } from '@app/hooks/useLocaleCountry';
import useMountEffect from '@app/hooks/useMountEffect';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';

interface EnterPhoneFormFields {
  phoneNumber: string;
  countryCode: CountryCode;
  dialCode: string;
}

interface Props extends FormComponentProps<EnterPhoneFormFields> {
  setDialAndPhone: (dialCode: string | undefined, phone: string | undefined, country: string | undefined) => void;
  setWaitingForCode: () => void;
  initialPhone: string | undefined;
  defaultDialCode: string | undefined;
  defaultCountryCode: string | undefined;
}

const GuestLoginEnterPhone: FunctionComponent<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.loginEnterPhone);
  const { guestLoginStore } = useInfraStores<ArMode>();
  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);
  const { form } = props;
  const { t } = useTranslation();
  const modalContext = useModalContext();

  useLocaleCountry((userCountry, userCountryCode): void => {
    let dialCode: string | undefined;
    let countryCode: string | undefined;

    if (props.defaultDialCode) {
      dialCode = props.defaultDialCode;
      countryCode = props.defaultCountryCode || getCountryCodeByDialCode(dialCode);
    } else {
      dialCode = userCountry.dialCode.toString();
      countryCode = userCountryCode;
    }

    form.setFieldsValue({ countryCode: dialCode });
    form.setFieldsValue({ country: countryCode });

    phoneNumberInputRef.current?.focus();
  });

  const fieldDecorators: FormFieldDecorators<EnterPhoneFormFields> = {
    countryCode: {
      initialValue:
        props.defaultCountryCode ||
        (props.defaultDialCode ? getCountryCodeByDialCode(props.defaultDialCode.toString()) : undefined),
      rules: [
        {
          max: GENERAL_FIELD_MAX_LENGTH.country,
          translatedMessage: {
            key: 'ar.guestLogin.errors.maxCharacters',
            value: GENERAL_FIELD_MAX_LENGTH.country,
          },
        },
        {
          validator: onChangeValidateOtherFields<EnterPhoneFormFields>(props.form, 'phoneNumber'),
        },
      ],
    },
    dialCode: {
      initialValue: props.defaultDialCode,
      rules: [
        {
          required: true,
          translatedMessage: 'ar.guestLogin.errors.invalidCountryCode',
        },
        {
          max: GENERAL_FIELD_MAX_LENGTH.dialCode,
          translatedMessage: {
            key: 'ar.guestLogin.errors.maxCharacters',
            value: GENERAL_FIELD_MAX_LENGTH.dialCode,
          },
        },
        {
          pattern: /^[0-9]+$/,
          translatedMessage: 'ar.guestLogin.errors.numericCountryCode',
        },
        {
          type: 'enum',
          enum: DIAL_CODES,
          translatedMessage: 'ar.guestLogin.errors.invalidCountryCode',
        },
        {
          validator: onChangeValidateOtherFields<EnterPhoneFormFields>(props.form, 'phoneNumber'),
        },
      ],
    },
    phoneNumber: {
      initialValue: props.initialPhone,
      rules: [
        {
          required: true,
          translatedMessage: 'ar.guestLogin.errors.invalidPhoneNumber',
        },
        {
          validator: validatePhoneNumber<EnterPhoneFormFields>(
            props.form,
            'dialCode',
            convertToTranslatedMessage('ar.guestLogin.errors.invalidPhoneNumber'),
          ),
        },
      ],
    },
  };

  useMountEffect(() => {
    props.form.validateFields();
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleOk();
    }
  };

  const handleDropDownChange = (country: string): void => {
    form.setFieldsValue({ dialCode: getCountryByCountryCode(country)?.dialCode.toString() ?? undefined });
  };

  const handleDialCodeChange = async (dialCode: string): Promise<void> => {
    form.setFieldsValue({ countryCode: getCountryCodeByDialCode(dialCode) ?? undefined });
  };

  const isFormInvalid = (): boolean => {
    const { form } = props;
    const { getFieldsError } = form;

    const fieldsError = getFieldsError();

    const fields = Object.keys(fieldsError);
    return fields.length === 0 || fields.some((field) => fieldsError[field]);
  };

  const handleOk = async (): Promise<void> => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Object, values: EnterPhoneFormFields) => {
      const { phoneNumber, dialCode, countryCode } = values;
      if (errors) {
        return;
      }

      const formattedDialCode = dialCode.trim();
      const formattedPhoneNumber = phoneNumber.trim();
      const internationalPhoneNumber = joinNationalPhoneAndDialCode(formattedDialCode, formattedPhoneNumber);

      confirm({
        content: renderPhoneNumber(internationalPhoneNumber),
        okText: t('general.upperYes'),
        cancelText: t('general.upperNo'),
        icon: <span />,
        onOk: () => {
          storeUserCountry(countryCode);
          requestTextMessageCodeFromPhoneKnoxer(formattedDialCode, formattedPhoneNumber, internationalPhoneNumber, countryCode);
        },
      });
    });
  };

  const requestTextMessageCodeFromPhoneKnoxer = async (
    formattedCountryCode: string,
    formattedPhoneNumber: string,
    internationalPhoneNumber: string,
    country: string,
  ): Promise<void> => {
    const { setDialAndPhone, setWaitingForCode } = props;
    const didRequestSucceed = await guestLoginStore.requestCodeFromPhoneKnoxer(
      internationalPhoneNumber,
      GuestAuthCodeDeliveryMethod.TextMessage,
    );
    if (didRequestSucceed) {
      setDialAndPhone(formattedCountryCode, formattedPhoneNumber, country);
      setWaitingForCode();
    }
  };

  const renderPhoneNumber = (phoneNumber: string): ReactElement => {
    return (
      <ModalAppContext {...modalContext}>
        <ModalContainer>
          <LocaleAwareDiv>
            <ModalTitle>{t('ar.guestLogin.phoneCode.phoneConfirm')}</ModalTitle>
            <ModalBody>
              <ModalTextLine>{t('ar.guestLogin.phoneCode.isYourPhoneNumber')}</ModalTextLine>
              <ModalPhoneNumberLine>{phoneNumber}</ModalPhoneNumberLine>
            </ModalBody>
          </LocaleAwareDiv>
        </ModalContainer>
      </ModalAppContext>
    );
  };

  const renderCountryDropdown = (): ReactElement => {
    return (
      <NakedDropdown
        accessibilityLabel={t('general.accessibility.country')}
        name='drp-guest-phone-enter-phone-country-name'
        dataTestId='drp-guest-phone-enter-phone-country-name'
        placeholder={t<string>('ar.guestLogin.phoneCode.country')}
        onChange={handleDropDownChange}
        isSearchable
      >
        {getCountriesOrder(i18n.language, (name: string) => t(`general.countries.${name}`)).map((countryCode) => {
          const country = COUNTRIES[countryCode];
          return (
            <CountryDropdownItem
              key={countryCode + country.dialCode}
              keywords={[t(`general.countries.${country.name}`), country.name, `+${country.dialCode}`, countryCode]}
              value={countryCode}
              textWhenSelected={`general.countries.${country.name}`}
            >
              <div>{t(`general.countries.${country.name}`)}</div>
              <StyledCountryDialCode>+{country.dialCode}</StyledCountryDialCode>
            </CountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const onBack = (): void => {
    guestLoginStore.setIsUserStartedLogin(false);
    guestLoginStore.setEmailKnoxerLoggedIn(false);
  };

  const hasFieldErrors = isFormInvalid();

  return (
    <>
      <ContentContainer>
        <LoginTitle>{t('ar.guestLogin.phoneCode.phoneVerification')}</LoginTitle>
        <LoginSubTitle>{t('ar.guestLogin.phoneCode.selectCountry')}</LoginSubTitle>
        <EnterPhoneForm onSubmit={handleFormSubmit}>
          <FormItemBox
            appearance='corners'
            form={form}
            fieldName='countryCode'
            fieldDecoratorOptions={fieldDecorators.countryCode}
            showErrors='none'
          >
            {renderCountryDropdown()}
          </FormItemBox>
          <InputsContainer>
            <CountryCodeItem>
              <FormItemBox appearance='corners' form={form} fieldName='dialCode' fieldDecoratorOptions={fieldDecorators.dialCode}>
                <NakedFormInput
                  name='inpt-guest-phone-enter-phone-country-code'
                  type='number'
                  placeholder={t<string>('ar.guestLogin.phoneCode.countryCode')}
                  prefix='+'
                  onKeyPress={handleKeyPress}
                  onChange={handleDialCodeChange}
                  disableSuggestion
                />
              </FormItemBox>
            </CountryCodeItem>
            <PhoneNumberItem>
              <FormItemBox
                appearance='corners'
                form={form}
                fieldName='phoneNumber'
                fieldDecoratorOptions={fieldDecorators.phoneNumber}
              >
                <NakedFormInput
                  name='inpt-guest-phone-enter-phone-phone-number'
                  type='number'
                  ref={phoneNumberInputRef}
                  placeholder={t<string>('ar.guestLogin.phoneCode.phoneNumber')}
                  onKeyPress={handleKeyPress}
                  disableSuggestion
                />
              </FormItemBox>
            </PhoneNumberItem>
          </InputsContainer>
          <ActionsContainer>
            <Button id='btn-guest-phone-enter-phone-back' appearance='text' onClick={onBack} size='small'>
              {t('general.upperBack')}
            </Button>
            <PrimaryButton id='btn-aguest-phone-enter-phone-continue' onClick={handleOk} disabled={hasFieldErrors}>
              {t('general.upperContinue')}
            </PrimaryButton>
          </ActionsContainer>
        </EnterPhoneForm>
      </ContentContainer>
    </>
  );
});

export default Form.create<Props>()(GuestLoginEnterPhone);

const ContentContainer = styled.div`
  padding: 32px 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const EnterPhoneForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  direction: ltr;
`;

const CountryCodeItem = styled(LocaleAwareDiv)`
  width: 140px;
  margin-right: 20px;
`;

const PhoneNumberItem = styled(LocaleAwareDiv)`
  flex: 1;
`;

const ModalPhoneNumberLine = styled.div`
  color: var(--accent-blue-600);
  direction: ltr;
`;

const CountryDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const StyledCountryDialCode = styled.div`
  color: var(--transparent-black-900);
`;
