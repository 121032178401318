export const SELF_APPROVE_FIELD_MAX_LENGTH = {
  initiateSelfApprove: {
    note: 1000,
    contactName: 50,
    contactPhoneNumber: 50,
    description: 1000,
    otherReason: 60,
  },
  removeSelfApprove: {
    note: 1000,
  },
};