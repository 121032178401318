import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import { AccountVerificationPayeeSearchServerResponse } from '@mortee/domain/morteeAccountVerification';

export async function searchPayee(searchText: string, limit?: number): Promise<AccountVerificationPayeeSearchServerResponse[]> {
  return request<AccountVerificationPayeeSearchServerResponse[]>(serverUrl, `/api/verifications`, {
    method: HttpMethod.get,
    params: { limit, query: searchText },
    suppressNotification: true,
  });
}
