import { SinglePaymentResult, SinglePaymentServerResponse, SinglePaymentStatus } from '@mortee/domain/paymentsConsts';

export default class SinglePaymentModel {
  id: string;
  status: SinglePaymentStatus;
  accountDetails: MorteeAccountDetails;
  amount: number;
  currency: string;
  file: FileMetadata;
  results: SinglePaymentResult[];

  constructor(obj: SinglePaymentServerResponse, file: FileMetadata) {
    this.id = obj.id;
    this.status = obj.status;
    this.accountDetails = obj.accountDetails;
    this.amount = obj.amount;
    this.currency = obj.currency;
    this.results = obj.results;
    this.file = {
      id: obj.fileId,
      name: file.name,
    };
  }
}
