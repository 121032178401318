import React, { FC } from 'react';
import styled from '@emotion/styled';
import Footer from '@app/components/Footer';
import useInfraStores from '@app/hooks/useInfraStores';
import Loader from '@app/components/Loader';
import ArMode from '@ar/arMode';
import GuestLoginPage from '@app/guestLogin/routes/GuestLoginPage';
import { observer } from 'mobx-react';
import { ARPageProps } from '@ar/routes/withARConfiguration';

interface Props extends ARPageProps {}

const ARAppContent: FC<Props> = observer((props) => {
  const { guestLoginStore } = useInfraStores<ArMode>();

  const { isLoggedIn, isLoginLoading } = guestLoginStore;

  if (isLoggedIn && isLoginLoading) {
    return <Loader fullScreen spinning />;
  }

  if (!isLoggedIn) {
    return <GuestLoginPage configuration={props.configuration} />;
  }

  const { children } = props;

  return (
    <PageLayout>
      <ContentLayout>
        <ContentContainer id='main'>{children}</ContentContainer>
        <StyledFooter />
      </ContentLayout>
    </PageLayout>
  );
});

export default ARAppContent;

const ContentLayout = styled.main`
  flex: 1 1 auto;
  overflow: auto;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const ContentContainer = styled.div`
  padding-bottom: 32px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledFooter = styled(Footer)`
  flex: 0 0 auto;
  text-align: center;
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: auto;
  height: 100vh;
  min-height: 0;
`;
