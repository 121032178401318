import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { SupplierValidationRecord } from '@mortee/domain/validationSystem';
import { confirmAsync, ModalBody, ModalTitle, WrappingModalContainer } from '@app/components/Modal';
import validationSystemServices from '@mortee/services/validationSystemServices';
import SVG from '@app/components/SVG';
import Pending from '@app/images/waiting.svg';
import Resolve from '@app/images/resolve.svg';
import Approved from '@app/images/approved-circle.svg';
import AsyncButton from '@app/components/AsyncButton';
import { Alert, AlertStatus } from '@mortee/domain/validationSystemAlerts';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { BodySmallStartTransparentBlack600, Regular15TransparentBlack900 } from '@app/components/Text';
import { FormattedDate, FormattedTime } from '@app/components/Locale';
import AlertIcon from '@app/images/alert-circle.svg';

interface Props {
  record: SupplierValidationRecord;
  alert: Alert;
  onAlertResolve(): void;
  className?: string;
}

const SupplierValidationRecordActivityLog: FC<Props> = ({ record, alert, onAlertResolve, className }) => {
  const { permissionsStore } = useInfraStores<MorteeMode>();

  async function handleResolveAlert(alertId: string, alertText: string): Promise<void> {
    const renderConfirmationModal = (alertText: string): ReactElement => {
      return (
        <WrappingModalContainer>
          <StyledModalTitle>
            <SVG accessibilityLabel='alert' image={AlertIcon} width={36} height={36} />
            Resolve Alert Confirmation
          </StyledModalTitle>
          <ModalBody>
            <ModalSubTitleText>
              you are about to resolve the alert:
              <br />
              {alertText}
            </ModalSubTitleText>
          </ModalBody>
        </WrappingModalContainer>
      );
    };

    const shouldResolve = await confirmAsync({
      content: renderConfirmationModal(alertText),
      okText: 'Resolve',
      cancelText: 'Cancel',
      icon: <span />,
      maskClosable: false,
      width: 550,
    });

    if (!shouldResolve) {
      return;
    }

    await validationSystemServices.resolveValidationAlert(record.staticId, alertId);
    onAlertResolve();
  }

  function getStatusAction(status: AlertStatus): ReactElement {
    if (status === AlertStatus.resolved) {
      return (
        <ResolvedDiv id='btn-supplier-validation-record-timeline-alert-resolved'>
          Resolved <SVG accessibilityLabel='resolved' image={Approved} width={24} height={24} />
        </ResolvedDiv>
      );
    }

    if (status === AlertStatus.active && permissionsStore.isValidationApprover) {
      return (
        <ResolveAlertButton
          id='btn-supplier-validation-record-timeline-alert-resolve'
          appearance='text'
          onClick={(): Promise<void> => handleResolveAlert(alert.alertId, alert.alertText)}
        >
          Mark as resolved <SVG accessibilityLabel='resolve' image={Resolve} width={24} height={24} />
        </ResolveAlertButton>
      );
    }

    return (
      <PendingApprovalDiv id='btn-supplier-validation-record-timeline-alert-pending-approval'>
        Pending resolve <SVG accessibilityLabel='pending approval' image={Pending} width={24} height={24} />
      </PendingApprovalDiv>
    );
  }

  return (
    <Main className={className}>
      <EventMetadataLine>
        <MetadataLeft>
          <FormattedDate value={alert.creationTimestamp} />
          <FormattedTime value={alert.creationTimestamp} />
          <span>{alert.createdByUserName}</span>
        </MetadataLeft>
        <MetadataRight>{getStatusAction(alert.alertStatus)}</MetadataRight>
      </EventMetadataLine>
      <AlertText>{alert.alertText}</AlertText>
    </Main>
  );
};

export default SupplierValidationRecordActivityLog;

const MetadataRight = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const ModalSubTitleText = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
  word-break: break-word;
`;

const ResolveAlertButton = styled(AsyncButton)`
  border: 1px solid rgba(252, 85, 85, 1);
  color: rgba(252, 85, 85, 1);
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 13px;
  height: 30px;
  display: flex;
  align-items: center;
`;

const ResolvedDiv = styled.div`
  color: rgba(46, 133, 64, 1);
  padding: 0px 8px;
  font-size: 13px;
`;

const PendingApprovalDiv = styled.div`
  border: 1px solid rgba(112, 123, 153, 1);
  color: rgba(112, 123, 153, 1);
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 13px;
`;

export const AlertText = styled(Regular15TransparentBlack900.div)`
  padding: 12px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const EventMetadataLine = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  gap: 10px;
`;

const MetadataLeft = styled(BodySmallStartTransparentBlack600.div)`
  display: flex;
  align-items: baseline;
  flex: 1;

  gap: 10px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const StyledModalTitle = styled(ModalTitle)`
  display: flex;
`;
