import React, { ReactElement, ReactNode, useRef } from 'react';
import Loader, { LoaderProps } from './Loader';
import styled from '@emotion/styled';
import Loadable from '@app/utils/Loadable';

type LoadedRenderFunc<T> = (loaded: T) => ReactElement;

interface TransitionLoaderProps<T> extends Omit<LoaderProps, 'spinning'> {
  loadable: Loadable<T>;
  firstLoadingContent?(): ReactNode;
  children: LoadedRenderFunc<T>;
  contentClassName?: string;
  className?: string;
}

function LoadableTransitionLoader<T>(props: TransitionLoaderProps<T>): ReactElement {
  const { loadable, firstLoadingContent, contentClassName, className, children, ...loaderProps } = props;
  const lastLoadedData = useRef<Loadable<T> | undefined>(loadable.isResolved() ? loadable : undefined);

  if (loadable.isResolved()) {
    lastLoadedData.current = loadable;
    return children(loadable.result);
  }

  return (
    <Container className={className}>
      <ContentContainer isFaded={loadable.isInProgress()} className={contentClassName}>
        {lastLoadedData.current?.isResolved() ? children(lastLoadedData.current.result) : firstLoadingContent?.()}
      </ContentContainer>
      {loadable.isInProgress() && <Loader spinning transparent {...loaderProps} />}
    </Container>
  );
}

export default LoadableTransitionLoader;

const Container = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div<{ isFaded: boolean }>`
  opacity: 1;
  transition: opacity 0.5s;
  ${(p): string => (p.isFaded ? 'opacity: 0.1;' : '')}
`;
