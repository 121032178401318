import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { ValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { UnifiedPayee } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import PayeeDetails from '@mortee/components/payeeDetails/PayeeDetails';
import SVG from '@app/components/SVG';
import ClearIcon from '@app/images/ic_close.svg';
import Button from '@app/components/Button';
import { BlockDescriptor, BufferLine } from '@app/components/DisplayFields';
import ValidatedPayeeAccountDetails from '@mortee/components/validatedPayee/ValidatedPayeeAccountDetails';
import SeparatorList from '@app/components/SeparatorList';
import Card from '@app/components/card/Card';

interface Props {
  validatedPayee: AggregatedValidatedPayee;
  validatedPayeeAccounts: ValidatedPayeeAccount[];
  onDone: () => void;
}

const ValidatedPayeeModal: FC<Props> = ({ validatedPayee, validatedPayeeAccounts, onDone }) => {
  const unifiedPayee = convertToMorteeUnifiedPayee(validatedPayee);

  return (
    <MainContainer>
      <CloseButton id={`btn-mvf-guard-validated-payee-modal-close`} appearance='text' colorScheme='primary' onClick={onDone}>
        <SVG accessibilityLabel='close' image={ClearIcon} height={20} />
      </CloseButton>
      <PayeeDetails title='Vendor info in PaymentKnox' payee={unifiedPayee} fullDetails />
      {!!validatedPayeeAccounts?.length && (
        <>
          <BufferLine />
          <BlockDescriptor>Bank Accounts</BlockDescriptor>
          <SeparatorList
            items={validatedPayeeAccounts}
            keyGetter={(account): string => account.uniformId}
            separator={<ShortBufferLine />}
          >
            {(validatedPayeeAccount): ReactElement => (
              <ValidatedPayeeAccountDetails validatedPayeeAccount={validatedPayeeAccount} />
            )}
          </SeparatorList>
        </>
      )}
    </MainContainer>
  );
};

export default ValidatedPayeeModal;

const convertToMorteeUnifiedPayee = (validatedPayee: AggregatedValidatedPayee): UnifiedPayee => {
  return {
    id: validatedPayee.uniformId,
    ids: { validatedId: validatedPayee.uniformId },
    names: validatedPayee.names?.map((name) => ({ name, isValidatedPayee: true })),
    addresses: validatedPayee.addresses,
    emails: validatedPayee.emails,
    phones: validatedPayee.phones,
    faxes: validatedPayee.faxes,
    websites: validatedPayee.websites,
    description: validatedPayee.descriptions?.[0],
    legalIdentifiers: validatedPayee.legalIdentifiers,
    countryCode: validatedPayee.countryCode,
    primaryFields: validatedPayee.primaryFields,
    strongLegalIdentifies: validatedPayee.strongLegalIdentifies,
    externalReferenceIds: [],
    supplierNumbers: [],
    companyCodes: [],
  };
};

const MainContainer = styled(Card)`
  width: max(950px, 100vh);
  padding: 24px 24px 20px;
  position: relative;
  box-shadow: var(--box-shadow-3);
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(53, 60, 79, 0.4);
  transition: 0.1s all ease-in-out;
  align-self: flex-end;
  padding: 4px 6px 5px;
  margin-bottom: 3px;

  position: absolute;
  top: 12px;
  right: 12px;
`;

const ShortBufferLine = styled(BufferLine)`
  width: 32px;
`;
