import React, { FunctionComponent, ReactElement, useRef } from 'react';
import styled from '@emotion/styled';
import { FileSurveyReportAccountNonDeterministicVerification } from '@mortee/domain/mvfCheck';
import { getCountryName } from '@app/domain/countries';
import { showInfoModal } from '@app/components/Modal';
import ValidationInfoModal from '@mortee/routes/masterDataGuard/mvfCheck/mvfCheckReportPage/ValidationInfoModal';
import { formatAccountWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { distinctValues } from '@app/utils/arrayUtils';
import { getLEIText } from '@app/utils/legalIdentifierUtils';
import { VerificationResultText } from '@app/domain/accountVerification';
import { translateVerificationLevel, VerificationResultType } from '@mortee/domain/morteeAccountVerification';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';

interface NonDeterministicVerificationResultProps {
  verification: FileSurveyReportAccountNonDeterministicVerification;
}

const NonDeterministicVerificationResult: FunctionComponent<NonDeterministicVerificationResultProps> = (props) => {
  const modal = useRef<any>();

  const renderValidationLevelText = (verificationResultText: VerificationResultText): ReactElement => {
    if (
      verificationResultText !== VerificationResultText.standardValidation &&
      verificationResultText !== VerificationResultText.extendedValidation
    ) {
      return <TextContainer>{verificationResultText}</TextContainer>;
    }

    return (
      <LinkTextContainer onClick={(): void => openValidationInfoModal(verificationResultText)}>
        {verificationResultText}
      </LinkTextContainer>
    );
  };

  const modalContext = useModalContext();

  const openValidationInfoModal = (verificationResultText: VerificationResultText): void => {
    const {
      validatedNames,
      validatedLegalEntityIdentifiers,
      validatedCountryCode,
      privateAccountDetails,
      validatedAccountDetails,
      validatedAddresses,
    } = props.verification;

    const content = (
      <ModalAppContext {...modalContext}>
        <ValidationInfoModal
          verificationResultText={verificationResultText}
          validatedNames={validatedNames}
          validatedLegalEntityIdentifiers={validatedLegalEntityIdentifiers}
          validatedCountryCode={validatedCountryCode}
          accountDetails={validatedAccountDetails ?? privateAccountDetails}
          validatedAddresses={validatedAddresses}
          dataTestId='validationInfoModal'
          onReturn={closeValidationInfoModal}
        />
      </ModalAppContext>
    );
    modal.current = showInfoModal(content, { okType: 'ghost', className: 'card' });
  };

  const closeValidationInfoModal = (): void => {
    modal.current?.destroy();
    modal.current = null;
  };

  const {
    verificationResult,
    validatedNames,
    validatedLegalEntityIdentifiers,
    privateAccountDetails,
    validatedAccountDetails,
    validatedCountryCode,
    privateLegalEntityIdentifiers,
  } = props.verification;

  switch (verificationResult) {
    case VerificationResultType.extendedValidated: {
      return (
        <Container>
          <RowContainer>
            <Label>Result:</Label>
            {renderValidationLevelText(translateVerificationLevel(verificationResult))}
          </RowContainer>
          <RowContainer>
            <Label>Names:</Label>
            <TextContainer>{validatedNames?.join(', ')}</TextContainer>
          </RowContainer>
          <RowContainer>
            <Label>Vendor IDs:</Label>
            <ColumnContainer>
              {distinctValues(validatedLegalEntityIdentifiers?.map(getLEIText))?.map((text) => (
                <TextContainer key={text}>{text}</TextContainer>
              ))}
            </ColumnContainer>
          </RowContainer>
          <RowContainer>
            <Label>Country:</Label>
            <TextContainer>{getCountryName(validatedCountryCode)}</TextContainer>
          </RowContainer>
          <RowContainer>
            <Label>Accounts:</Label>
            <ColumnContainer>
              {formatAccountWithSwiftban(validatedAccountDetails ?? privateAccountDetails)?.map((account) => (
                <TextContainer key={account}>{account}</TextContainer>
              ))}
            </ColumnContainer>
          </RowContainer>
        </Container>
      );
    }
    case VerificationResultType.lightweightValidated:
    case VerificationResultType.reverseWireValidated:
    case VerificationResultType.externalBankValidated:
    case VerificationResultType.internalValidated:
    case VerificationResultType.lyonsValidated: {
      return (
        <Container>
          <RowContainer>
            <Label>Result:</Label>
            {renderValidationLevelText(translateVerificationLevel(verificationResult))}
          </RowContainer>
          <RowContainer>
            <Label>Vendor IDs:</Label>
            <ColumnContainer>
              {distinctValues(validatedLegalEntityIdentifiers?.map(getLEIText))?.map((text) => (
                <TextContainer>{text}</TextContainer>
              ))}
            </ColumnContainer>
          </RowContainer>
          <RowContainer>
            <Label>Accounts:</Label>
            <ColumnContainer>
              {formatAccountWithSwiftban(validatedAccountDetails ?? privateAccountDetails)?.map((account) => (
                <TextContainer key={account}>{account}</TextContainer>
              ))}
            </ColumnContainer>
          </RowContainer>
        </Container>
      );
    }
    case VerificationResultType.selfApproved: {
      return (
        <Container>
          <RowContainer>
            <Label>Result:</Label>
            <TextContainer>{translateVerificationLevel(verificationResult)}</TextContainer>
          </RowContainer>
          <RowContainer>
            <Label>Vendor IDs:</Label>
            <ColumnContainer>
              {distinctValues(privateLegalEntityIdentifiers?.map(getLEIText))?.map((text) => (
                <TextContainer>{text}</TextContainer>
              ))}
            </ColumnContainer>
          </RowContainer>
          <RowContainer>
            <Label>Accounts:</Label>
            <ColumnContainer>
              {formatAccountWithSwiftban(privateAccountDetails)?.map((account) => (
                <TextContainer key={account}>{account}</TextContainer>
              ))}
            </ColumnContainer>
          </RowContainer>
        </Container>
      );
    }
    default: {
      return (
        <Container>
          <RowContainer>
            <Label>Result:</Label>
            <TextContainer>{translateVerificationLevel(verificationResult)}</TextContainer>
          </RowContainer>
        </Container>
      );
    }
  }
};

export default NonDeterministicVerificationResult;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: bold;
  letter-spacing: 0.39px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.97);
  margin-right: 4px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.39px;
  color: var(--transparent-black-600);
`;

const LinkTextContainer = styled(TextContainer)`
  color: var(--accent-blue-600);
  text-decoration: underline;
  cursor: pointer;
`;
