import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import {
  displayDistributionWarningMessage,
  SupplierValidationRecord,
  transformSupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import {
  SidePadding,
  TabTitle,
  TransparentCard,
} from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';
import LinkSupplierRegistrationToSupplierValidationRecordForm from '@mortee/routes/validationSystem/SelectSupplierRegistrationForSupplierValidationRecordForm';
import { showContentOnlyModal } from '@app/components/Modal';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';
import Button from '@app/components/Button';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import validationSystemServices from '@mortee/services/validationSystemServices';
import * as messageLauncher from '@app/utils/messageLauncher';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import AsyncButton from '@app/components/AsyncButton';
import Toggle from '@app/components/inputs/Toggle';
import SVG from '@app/components/SVG';
import InfoImage from '@app/images/designSystem/indication/info-grey.svg';
import { Tooltip } from 'antd';
import { BodyRegularStartTransparentBlack600 } from '@app/components/Text';
import SupplierRegistrationCard from '@mortee/routes/validationSystem/SupplierRegistrationCard';

interface Props {
  record: SupplierValidationRecord;
  className?: string;
  onSaved(updatedRecord: SupplierValidationRecord, userWantsToClose: boolean): void;
}

const EditValidationRecordRegistrationFormsTab: FC<Props> = observer((props) => {
  const { record, onSaved, className } = props;

  const { userStore } = useInfraStores<MorteeMode>();
  const modalContext = useModalContext();
  const [isOfflineForm, setIsOfflineForm] = useState<boolean>(record.isOfflineForm);

  async function onSave(selectedRegistrationProcess: SupplierRegistrationProcess | undefined): Promise<void> {
    const serverResponse = await validationSystemServices.updateValidationRecord(record.staticId, {
      svRegistrationId: { content: selectedRegistrationProcess?.id ?? null },
    });

    if (selectedRegistrationProcess) {
      messageLauncher.shoot(
        { type: 'success' },
        `Form ${selectedRegistrationProcess.registrationNumber} successfully linked to validation ${record.presentationId}`,
      );
    } else {
      messageLauncher.shoot({ type: 'success' }, `Successfully unlinked form to validation ${record.presentationId}`);
    }

    displayDistributionWarningMessage(serverResponse.warnings);

    onSaved(transformSupplierValidationRecord(serverResponse.record, userStore.user?.id), false);
  }

  function showLinkModal(): void {
    showContentOnlyModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <LinkSupplierRegistrationToSupplierValidationRecordForm
            supplierValidationRecord={record}
            onSave={onSave}
            onDone={onDone}
          />
        </ModalAppContext>
      ),
      {
        okText: 'DONE',
      },
    );
  }

  async function onToggleIsOffline(isOfflineFormNewValue: boolean | undefined): Promise<void> {
    setIsOfflineForm(!!isOfflineFormNewValue);
    let serverResponse;
    try {
      serverResponse = await validationSystemServices.updateValidationRecord(record.staticId, {
        isOfflineForm: { content: !!isOfflineFormNewValue },
      });
    } catch (e) {
      setIsOfflineForm(!isOfflineFormNewValue);
      return;
    }

    if (isOfflineFormNewValue) {
      messageLauncher.shoot({ type: 'success', closeable: true }, `Successfully turned on offline process`);
    } else {
      messageLauncher.shoot({ type: 'success', closeable: true }, `Successfully turned off offline process`);
    }

    onSaved(transformSupplierValidationRecord(serverResponse.record, userStore.user?.id), false);
  }

  return (
    <Main id='edit-validation-record-registration-forms' className={className}>
      <Content>
        <HeaderContainer>
          <TabTitle>Registration Forms</TabTitle>
          <ToggleContainer>
            <Tooltip
              title={
                record.supplierRegistrationProcess
                  ? `Cannot use offline process when registration form is linked.`
                  : 'This validation has no registration'
              }
            >
              <InformationIcon
                accessibilityLabel={
                  record.supplierRegistrationProcess
                    ? `Cannot use offline process when registration form is linked.`
                    : 'This validation has no registration'
                }
                image={InfoImage}
                width={24}
                height={24}
              />
            </Tooltip>
            <div>Offline Process</div>
            <Toggle
              accessibilityLabel='Toggle is offline form'
              name='toggle-edit-validation-record-is-offline-form'
              value={isOfflineForm}
              onChange={onToggleIsOffline}
              disabled={!!record.supplierRegistrationProcess}
            />
          </ToggleContainer>
        </HeaderContainer>
        <TransparentCard>
          {record.supplierRegistrationProcess ? (
            <div>
              <SectionTitle>Linked Registration Form</SectionTitle>
              <SupplierRegistrationCard
                item={record.supplierRegistrationProcess}
                selected={false}
                dataTestId='sr-selected-registration-form'
              />
              <ActionsContainer>
                <AsyncButton
                  id='btn-edit-validation-record-clear-registration-form'
                  onClick={(): Promise<void> => onSave(undefined)}
                >
                  CLEAR
                </AsyncButton>
                <Button id='btn-edit-validation-record-edit-registration-form' onClick={showLinkModal}>
                  EDIT
                </Button>
              </ActionsContainer>
            </div>
          ) : (
            <div>
              <SectionTitle>No linked registration form</SectionTitle>
              <ActionsContainer>
                <Button id='btn-edit-validation-record-add-registration-form' onClick={showLinkModal} disabled={isOfflineForm}>
                  + ADD REGISTRATION FORM
                </Button>
              </ActionsContainer>
            </div>
          )}
        </TransparentCard>
      </Content>
    </Main>
  );
});

export default EditValidationRecordRegistrationFormsTab;

const Main = styled(VerticalShadowScroller)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled(SidePadding)`
  flex: 1;
`;

const SectionTitle = styled.h2`
  font-size: unset;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 12px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InformationIcon = styled(SVG)`
  width: 24px;
  height: 24px;
`;

const ToggleContainer = styled(BodyRegularStartTransparentBlack600.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
