import React, { FC } from 'react';
import Button from '@app/components/Button';
import GoogleIcon from '@app/images/ic-google.svg';
import styled from '@emotion/styled';
import SVG from '@app/components/SVG';

interface GoogleSSOButtonProps {
  onClick: () => void;
}

const GoogleSSOButton: FC<GoogleSSOButtonProps> = ({ onClick }) => {
  return (
    <StyledButton id='btn-auth-google-sso' appearance='text' type='button' onClick={onClick}>
      <StyledGoogleSVG accessibilityLabel='' image={GoogleIcon} />
      <SsoButtonText>Sign in with Google</SsoButtonText>
    </StyledButton>
  );
};

export default GoogleSSOButton;

const StyledButton = styled(Button)`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
`;

const StyledGoogleSVG = styled(SVG)`
  height: 18px;
  width: 18px;
  margin-right: 24px;
`;

const SsoButtonText = styled.span`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: rgba(0, 0, 0, 0.54);
`;
