import * as supplierRegistrationManagementServices from '@mortee/services/supplierRegistrationManagementServices';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';

export default class SupplierRegistrationForManagementStore {
  getSupplierRegistrationProcesses = async (
    onlyLoadLastMonths: boolean,
    showHiddenRecords: boolean,
    showOnlyUnlinked: boolean,
  ): Promise<SupplierRegistrationProcess[]> => {
    const serverResponse = await supplierRegistrationManagementServices.getSupplierRegistrationProcesses(
      onlyLoadLastMonths,
      showHiddenRecords,
      showOnlyUnlinked,
    );
    return serverResponse.map((singleValidationProcess) =>
      supplierRegistrationManagementServices.transformToSupplierRegistrationProcess(singleValidationProcess),
    );
  };
}
