import { TFunction } from 'react-i18next';
import React, { ReactNode } from 'react';

interface ErrorMessageOptions {
  preventPleaseContactText?: boolean;
  errorLogId?: string | null;
}

export function createEnglishFormalErrorMessage(errorReason: ReactNode, options?: ErrorMessageOptions): string;
export function createEnglishFormalErrorMessage(errorReason: ReactNode, errorLogId?: string | null): string;
export function createEnglishFormalErrorMessage(
  errorReason: ReactNode,
  options?: string | ErrorMessageOptions | null,
): ReactNode {
  const { preventPleaseContactText, errorLogId }: ErrorMessageOptions =
    typeof options === 'string' ? { errorLogId: options } : options ?? {};

  const pleaseContactText = preventPleaseContactText ? null : 'Please contact us at support@nsknox.net for assistance.';
  return createFormalErrorMessage(errorReason, pleaseContactText, errorLogId && `Refer to error code: ${errorLogId}.`);
}

export function createTranslatedFormalErrorMessage(
  t: TFunction<string>,
  errorReasonTranslationKey: string,
  pleaseContactTranslationKey: string,
  referToErrorCodeTranslationKey?: string,
  errorLogId?: string | null,
): ReactNode {
  try {
    return createFormalErrorMessage(
      t(errorReasonTranslationKey),
      t(pleaseContactTranslationKey),
      errorLogId && referToErrorCodeTranslationKey && t(referToErrorCodeTranslationKey, { errorLogId }),
    );
  } catch {
    const logId = errorLogId ? `(${errorLogId})` : '';
    return `Unexpected error occurred while trying to login. ${logId}`;
  }
}

function createFormalErrorMessage(
  errorReason: ReactNode,
  pleaseContactText: string | null,
  referToErrorCodeText: string | null | undefined,
): ReactNode {
  return (
    <span>
      {errorReason}
      <br />
      {pleaseContactText}
      {referToErrorCodeText && (
        <>
          <br />
          {referToErrorCodeText}
        </>
      )}
    </span>
  );
}
