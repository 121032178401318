import Mode from '@app/modes/Mode';
import SupplierRegistrationAppContent from './routes/app/SupplierRegistrationAppContent';
import { ComponentType } from 'react';
import FAQPage from './routes/faqPage/FAQPage';
import AfterFormPage from './routes/supplierRegistrationProcess/AfterFormPage';
import SupplierRegistrationFormPage from './routes/supplierRegistrationProcess/SupplierRegistrationFormPage';
import SupplierRegistrationHeader from './routes/app/SupplierRegistrationHeader';
import SupplierRegistrationInfraStores from '@supplierRegistration/stores/infraStores/SupplierRegistrationInfraStores';
import SupplierRegistrationAppStores from '@supplierRegistration/stores/appStores/SupplierRegistrationAppStores';
import { extractSubdomainFromLocation } from '@supplierRegistration/hooks/useSubdomainFromLocation';

class SupplierRegistrationMode extends Mode<SupplierRegistrationInfraStores, SupplierRegistrationAppStores> {
  constructor() {
    super(sectionDefinitions);
  }

  get isAnonymous(): boolean {
    return true;
  }

  getAppContentComponent(): ComponentType {
    return SupplierRegistrationAppContent;
  }

  getHeadTitle(): string {
    return 'nsKnox Validation';
  }

  createInfraStores(): SupplierRegistrationInfraStores {
    return new SupplierRegistrationInfraStores(this.sections, this.getLocalePath());
  }

  createAppStores(infraStores: SupplierRegistrationInfraStores): SupplierRegistrationAppStores {
    return new SupplierRegistrationAppStores(infraStores.themeStore);
  }

  getDefaultHeaderComponent(): ComponentType {
    return SupplierRegistrationHeader;
  }

  getLocalePath(): string {
    return 'locales/supplierRegistration/list.json';
  }
}

export default SupplierRegistrationMode;

const sectionDefinitions: SectionDefinition[] = [
  {
    id: 'supplierRegistrationForSuppliers',
    name: 'Account Validation For Suppliers',
    requiredRoles: [],
    requiredServices: [],
    routes: [
      {
        id: 'accountRegistrationForSuppliersWelcome',
        path: '/',
        redirectFromPaths: ['/apply'],
        component: SupplierRegistrationFormPage,
        requiredRoles: [],
        requiredServices: [],
        innerRoutes: [
          {
            id: 'accountRegistrationForSuppliersFAQ',
            path: '/faq',
            component: FAQPage,
            requiredRoles: [],
            requiredServices: [],
          },
          {
            id: 'accountRegistrationForSuppliersAfterForm',
            path: '/step-2',
            component: AfterFormPage,
            requiredRoles: [],
            requiredServices: [],
          },
        ],
      },
    ],
  },
  {
    id: 'supplierRegistrationForIncomingPayments',
    name: 'Incoming Payments Security Registration',
    requiredRoles: [],
    requiredServices: [],
    isDisabled: !!extractSubdomainFromLocation(),
    routes: [
      {
        id: 'accountRegistrationForIncomingPaymentSecurityWelcome',
        path: '/ips',
        component: SupplierRegistrationFormPage,
        requiredRoles: [],
        requiredServices: [],
        innerRoutes: [
          {
            id: 'accountRegistrationForIncomingPaymentSecurityFAQ',
            path: '/faq',
            component: FAQPage,
            requiredRoles: [],
            requiredServices: [],
          },
          {
            id: 'accountRegistrationForIncomingPaymentSecurityAfterForm',
            path: '/step-2',
            component: AfterFormPage,
            requiredRoles: [],
            requiredServices: [],
          },
        ],
      },
    ],
  },
];
