export const IsraelBankCodes: any = [
  { code: 10, name: 'בנק לאומי' },
  { code: 12, name: 'בנק הפועלים' },
  { code: 20, name: 'בנק מזרחי' },
  { code: 11, name: 'בנק דיסקונט' },
  { code: 17, name: 'בנק מרכנתיל דיסקונט' },
  { code: 68, name: 'בנק מרכנתיל דיסקונט לשעבר בנק מוניציפל/דקסיה' },
  { code: 31, name: 'בנק הבינלאומי' },
  { code: 46, name: 'בנק מסד' },
  { code: 14, name: 'בנק הבינלאומי לשעבר בנק אוצר החייל' },
  { code: 26, name: 'בנק הבינלאומי לשעבר יובנק' },
  { code: 52, name: 'בנק הבינלאומי לשעבר בנק פועלי אגודת ישראל' },
  { code: 13, name: 'בנק מזרחי' },
  { code: 4, name: 'בנק יהב' },
  { code: 9, name: 'בנק הדואר' },
  { code: 54, name: 'בנק ירושלים' },
  { code: 18, name: 'וואן זירו הבנק הדיגיטלי' },
  { code: 22, name: 'סיטי בנק (Citibank)' },
  { code: 23, name: 'HSBC' },
  { code: 39, name: 'סטייט בנק אוף אינדייה (state bank of india)' },
  { code: 34, name: 'בנק לאומי לשעבר בנק ערבי ישראלי' },
  { code: 99, name: 'בנק ישראל' },
];
