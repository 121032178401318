import React, { FC, ReactNode } from 'react';
import { Bold18TransparentBlack900 } from '@app/components/Text';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import { useTranslation } from 'react-i18next';
import BackArrow from '@app/components/BackArrow';

interface Props {
  title?: string;
  children?: ReactNode;
  backButtonAction?: () => void;
}

const AboveMainCardTitle: FC<Props> = ({ title, children, backButtonAction }) => {
  const { t } = useTranslation();

  const displayBackButton = (backButtonAction: () => void): ReactNode => {
    return (
      <BackButton id='a-card-title-back' dataTestId='startOverBtn' appearance='text' onClick={(): void => backButtonAction()}>
        <SmallSquareArrowBullet />
        <span>{t('general.upperBack', 'BACK')}</span>
      </BackButton>
    );
  };

  return (
    <PageTitle>
      <Left>
        {!!backButtonAction && displayBackButton(backButtonAction)}
        {!!backButtonAction && title && <VerticalBuffer />}
        <Bold18TransparentBlack900.span>{title}</Bold18TransparentBlack900.span>
      </Left>
      <span>{children}</span>
    </PageTitle>
  );
};

export default AboveMainCardTitle;

const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;

  --vertical-buffer-padding: 10px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  padding: 6px var(--vertical-buffer-padding);
  margin-inline-start: calc(var(--vertical-buffer-padding) * -1);

  & > *:not(:first-child) {
    margin-inline-start: 9px;
  }
`;

const VerticalBuffer = styled.span`
  height: 50%;
  border-inline-start: 1px solid var(--transparent-black-200);
  margin-inline-end: var(--vertical-buffer-padding);
`;

const SmallSquareArrowBullet = styled(BackArrow)`
  width: 7px;
  height: 10px;
`;
