import React, { FC } from 'react';
import { css } from '@emotion/css';
import Highlighter from 'react-highlight-words';
import { caseInsensitiveEquals } from '@app/utils/stringUtils';

const boldHighlightClassName = css`
  font-weight: bold;
`;

const heavyHighlightClassName = css`
  font-weight: 900;
`;

interface HighlighterProps {
  searchText: string | undefined;
  fontWeight?: 'Heavy' | 'Bold';
  highlightClass?: string;
  fullMatchOnly?: boolean;
  children?: string;
}

const TextHighlighter: FC<HighlighterProps> = ({ searchText, fontWeight, highlightClass, fullMatchOnly, children }) => {
  if (!(typeof children === 'string') || !searchText) {
    return <>{children}</>;
  }

  const fullyHighlightClass = highlightClass ?? (fontWeight === 'Heavy' ? heavyHighlightClassName : boldHighlightClassName);

  if (fullMatchOnly) {
    if (caseInsensitiveEquals(searchText, children)) {
      return <span className={fullyHighlightClass}>{children}</span>;
    }

    return <>{children}</>;
  }

  return (
    <Highlighter
      highlightClassName={fullyHighlightClass}
      highlightTag='span'
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={children}
    />
  );
};

export default TextHighlighter;
