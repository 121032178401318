import { COUNTRY_CODES } from '@app/domain/countries';

export interface BankBranchData {
  bankCode: string | null;
  branchCode: string | null;
}

export type BankBranchManipulation = (bankBranchData: BankBranchData) => BankBranchData;

const TAIWAN_BANK_CODE_LENGTH = 3;
const TAIWAN_BRANCH_CODE_LENGTH = 4;
const TAIWAN_TOTAL_LENGTH = TAIWAN_BANK_CODE_LENGTH + TAIWAN_BRANCH_CODE_LENGTH;

export const bankBranchCountrySpecificLogic: Partial<Record<COUNTRY_CODES, BankBranchManipulation>> = {
  [COUNTRY_CODES.Taiwan]: ({ bankCode, branchCode }): BankBranchData => {
    bankCode = removeLeadingZeros(bankCode) || null;
    branchCode = removeLeadingZeros(branchCode) || null;

    if (!bankCode && !branchCode) {
      return {
        bankCode: null,
        branchCode: null,
      };
    }

    if (!bankCode || !branchCode) {
      const singleValue = !bankCode ? branchCode : bankCode;

      if ((singleValue?.length ?? 0) > TAIWAN_TOTAL_LENGTH) {
        // We don't know how to merge a value which is too long
        if (!bankCode && !branchCode) {
          return {
            bankCode,
            branchCode,
          };
        }
      }

      return {
        bankCode: singleValue?.padStart(TAIWAN_TOTAL_LENGTH, '0') || null,
        branchCode: null,
      };
    }

    if (bankCode.length > TAIWAN_BANK_CODE_LENGTH || branchCode.length > TAIWAN_BRANCH_CODE_LENGTH) {
      // We don't know how to merge if one of the values is too long
      return {
        bankCode,
        branchCode,
      };
    }

    return {
      bankCode: bankCode.padStart(TAIWAN_BANK_CODE_LENGTH, '0') + branchCode.padStart(TAIWAN_BRANCH_CODE_LENGTH, '0'),
      branchCode: null,
    };
  },
};

function removeLeadingZeros(str?: string | null, preventInCaseAllZeros = false): string | null {
  if (!str) {
    return null;
  }

  if (preventInCaseAllZeros) {
    const isAllZeros = !str.match(/[^0]/);
    if (isAllZeros) {
      return str;
    }
  }

  return str?.replace(/^0+/, '') || null;
}
