import { trim } from './stringUtils';
import { VALIDATION_PATTERNS } from '../domain/uiConsts';
import { LegalEntityIdentifier } from '@app/domain/legalEntityIdentifier';

export const equalsLEI = (a: LegalEntityIdentifier, b: LegalEntityIdentifier): boolean =>
  (!a && !b) || (a && b && a.countryCode === b.countryCode && a.typeName === b.typeName && a.value === b.value);

export function trimDunsValue(duns: string): string;
export function trimDunsValue(duns: string | undefined): string | undefined;
export function trimDunsValue(duns: string | undefined): string | undefined {
  return duns?.trim().replace(/-/g, '');
}

type BaseLegalId = { value: string };

export function cleanLegalId<T extends BaseLegalId>(legalId: T | undefined): T | undefined {
  if (!legalId) {
    return;
  }

  return {
    ...legalId,
    value: trim(legalId.value),
  };
}

export function cleanDunsLegalId<T extends BaseLegalId>(legalId: T | undefined): T | undefined {
  if (!legalId || !legalId.value) {
    return;
  }

  return {
    ...legalId,
    value: trimDunsValue(legalId.value),
  };
}

export function stripPrefixAndSuffixFromKnoxId(knoxId: string): string;
export function stripPrefixAndSuffixFromKnoxId(knoxId: string | undefined): string | undefined;
export function stripPrefixAndSuffixFromKnoxId(knoxId: string | undefined): string | undefined {
  if (!knoxId) {
    return undefined;
  }

  const matches = VALIDATION_PATTERNS.fullKnoxIdOrKnoxIdDigits.exec(knoxId);

  return matches?.[1] ?? undefined;
}

export function getLEIText(lei: undefined): undefined;
export function getLEIText(lei: LegalEntityIdentifier): string;
export function getLEIText(lei: LegalEntityIdentifier | undefined): string | undefined {
  if (!lei) {
    return;
  }

  return `${lei.value}${lei.typeName || lei.countryCode ? ` (${lei.typeName ?? lei.countryCode})` : ''}`;
}

export function unifyLeis<T extends LegalEntityIdentifier>(identifiers: T[] | undefined): T[] {
  const unifyLeis: T[] = [];

  identifiers
    ?.sort((a) => (a.typeName ? -1 : 1))
    .forEach((identifier) => {
      const existingIdentifier = unifyLeis.find(
        (unifiedLei) =>
          unifiedLei.value === identifier.value &&
          unifiedLei.countryCode === identifier.countryCode &&
          unifiedLei.typeName === identifier.typeName,
      );
      if (identifier.typeName && !existingIdentifier) {
        unifyLeis.push(identifier);
      } else {
        if (
          !unifyLeis.find(
            (unifiedLei) => unifiedLei.value === identifier.value && unifiedLei.countryCode === identifier.countryCode,
          )
        ) {
          unifyLeis.push(identifier);
        }
      }
    });

  return unifyLeis.sort((a, b) => a.value.localeCompare(b.value));
}
