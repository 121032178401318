import React, { HTMLAttributeReferrerPolicy } from 'react';
import styled from '@emotion/styled';
import { ForwardingFC } from '@app/domain/technicals/components';
import Button, { ButtonProps } from './Button';
import { Link, LinkProps } from 'react-router-dom';

// id and onClick exist to enforce their existence
interface LinkButtonProps extends Omit<ButtonProps, 'onClick'> {
  to: LinkProps['to'];
  target?: string;
  rel?: string;
  referrerPolicy?: HTMLAttributeReferrerPolicy;
}

const LinkButton: ForwardingFC<HTMLButtonElement, LinkButtonProps> = React.forwardRef(
  ({ to, target, rel, referrerPolicy, ...rest }, ref) => {
    return (
      <NonDecoratedLink to={to} target={target} rel={rel} referrerPolicy={referrerPolicy}>
        <Button onClick={(): void => {}} ref={ref} {...rest} />
      </NonDecoratedLink>
    );
  },
);

export default LinkButton;

const NonDecoratedLink = styled(Link)`
  text-decoration: none !important;
`;
