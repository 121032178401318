import React, { FC, ReactElement, ReactNode } from 'react';
import Card from '@app/components/card/Card';
import styled from '@emotion/styled';
import SupplierRegistrationHeader from '../app/SupplierRegistrationHeader';
import faqData from './faqData.json';
import { Collapse } from 'antd';
import ExpanderOpenedIcon from '@app/images/expander-opened.svg';
import ExpanderClosedIcon from '@app/images/expander-closed.svg';
import SVG from '@app/components/SVG';
import ArrowBullet from '@app/components/ArrowBullet';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import PageWithHeader from '@app/components/PageWithHeader';
import useInfraStores from '@app/hooks/useInfraStores';
import browserHistory from '@app/utils/browserHistory';
import BackButton from '@supplierRegistration/components/BackButton';
import { BigText, Title } from '@supplierRegistration/routes/Styles';
import useTheme from '@app/hooks/useTheme';
import VideoPopup from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/VideoPopup';
import useAppStores from '@app/hooks/useAppStores';
import config from '@app/config';

const { Panel } = Collapse;

const FAQPage: FC = observer(() => {
  const { t } = useTranslation();
  const { languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const { brand } = useTheme<SupplierRegistrationMode>();
  const { autoplayingUrl, nonAutoplayingUrl, visible: videoVisible } = config.supplierRegistration.video;

  const renderSingle = ({ q, a }: { q: string; a: string | string[] }): ReactNode => {
    const answerParagraphs = Array.isArray(a) ? a : [a];
    return (
      <StyledPanel key={q} header={t(q)}>
        {answerParagraphs.map((paragraph, index) => (
          <p role='status' key={index}>
            {t(paragraph)}
          </p>
        ))}
      </StyledPanel>
    );
  };

  return (
    <PageWithHeader header={<SupplierRegistrationHeader />} widthReactive>
      <BackButton onClick={(): void => browserHistory.goBack()}>{t('supplierValidation.supplierRegister.back')}</BackButton>
      <MainCard>
        <Title>
          {brand ? t('supplierValidation.nsknoxStandardAccountValidation') : t('supplierValidation.standardAccountValidation')}
        </Title>
        {supplierRegistrationCustomerDataStore.customerName && videoVisible && (
          <VideoContainer>
            <VideoPopup
              id='proccess-explainer-video'
              title={t<string>('supplierValidation.explainerVideo')}
              autoplaySrc={autoplayingUrl}
              nonAutoplaySrc={nonAutoplayingUrl}
              width='489'
              height='270'
              frameBorder='0'
              allowFullScreen
            />
          </VideoContainer>
        )}
        <StyledCollapse
          isRTL={languageStore.isRTL}
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }): ReactElement => (
            <InnerIcon
              accessibilityLabel={t('general.accessibility.expander')}
              data-testid='expander-faq-icon'
              image={isActive ? ExpanderOpenedIcon : ExpanderClosedIcon}
            />
          )}
        >
          {faqData.map(renderSingle)}
        </StyledCollapse>
      </MainCard>
      <ContactUsTitle>{t('supplierValidation.FAQ.contactNsknox')}</ContactUsTitle>
      <BigText18px>
        <Trans i18nKey='supplierValidation.FAQ.forFurtherSupport'>
          For further support or clarifications, please contact nsKnox at:{' '}
          <MailTo href='mailto:accountvalidation@nsknox.net'>accountvalidation@nsknox.net</MailTo> or at any of the phone numbers
          detailed below:
        </Trans>
      </BigText18px>
      <ContactInfoContainer>
        <ContactInfo>
          <SmallSquareArrowBullet /> <BigText18px>{t('supplierValidation.FAQ.USA')}&nbsp;</BigText18px>
          <StyledBigText>+1 917 695 3505</StyledBigText>
        </ContactInfo>
        <ContactInfo>
          <SmallSquareArrowBullet /> <BigText18px>{t('supplierValidation.FAQ.UK')}&nbsp;</BigText18px>
          <StyledBigText>+44 (0) 20 3151 4055</StyledBigText>
        </ContactInfo>
        <ContactInfo>
          <SmallSquareArrowBullet /> <BigText18px>{t('supplierValidation.FAQ.other')}&nbsp;</BigText18px>
          <StyledBigText>+972 54 707 3700</StyledBigText>
        </ContactInfo>
      </ContactInfoContainer>
    </PageWithHeader>
  );
});

export default FAQPage;

// @ts-expect-error -- in old antd versions there is a mismatch between the collapse props and the type of the default values
const StyledCollapse = styled(Collapse)<{ isRTL?: boolean }>`
  margin-top: 38px;

  .ant-collapse-header {
    padding: 21px ${(p): number => (p.isRTL ? 64 : 16)}px 19px ${(p): number => (p.isRTL ? 16 : 64)}px !important;

    svg {
      left: ${(p): string => (p.isRTL ? 'unset' : '16px')} !important;
      right: ${(p): string => (p.isRTL ? '16px' : 'unset')} !important;
    }
  }
`;

export const BigText18px = styled(BigText)`
  font-size: 18px;
  font-weight: 200;
`;

const StyledBigText = styled(BigText18px)`
  direction: ltr;
`;

const MainCard = styled(Card)`
  font-weight: 200;
  max-width: 1000px;
  align-self: center;
  padding: 54px 40px;
  line-height: 1.4;
  margin-bottom: 52px;
  margin-top: 32px;
`;

const VideoContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;

const InnerIcon = styled(SVG)`
  width: 32px;
  height: 32px;
`;

const StyledPanel = styled(Panel)`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.67) !important;

  & .ant-collapse-header {
    font-weight: 200;
    color: rgba(0, 0, 0, 0.67) !important;
  }

  & .ant-collapse-arrow {
    margin: 0 !important;
    top: 30px;
  }

  & .ant-collapse-content-box {
    padding-left: 64px;
  }

  p {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.67) !important;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const MailTo = styled.a`
  font-weight: bold;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 32px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &:not(:first-child) {
    margin-inline-start: 24px;
  }
`;

const SmallSquareArrowBullet = styled(ArrowBullet)`
  width: 14px;
  height: 14px;

  margin-inline-end: 4px;
`;

export const ContactUsTitle = styled(Title)`
  color: var(--action-color);
`;
