import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ActionsContainer, FooterSubTitle, FooterTitle, SubTitle, Title } from '../../Styles';
import { action, flow } from 'mobx';
import { LoginErrorBody, LoginErrorCodes } from '@app/login/domain/loginConsts';
import * as messageLauncher from '@app/utils/messageLauncher';
import { RowSeparator } from '@app/components/Styles';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';

interface Props extends FormComponentProps<EnterCodeFormFields> {
  knoxerId: string;
  flowId: string;
  email: string;
  codeLength: number;
  onBack: () => void;
  onSubmit: () => void;
}

interface EnterCodeFormFields {
  code: string;
}

const ForgotPasswordEnterCode: FunctionComponent<Props> = observer((props) => {
  const fieldDecorators: FormFieldDecorators<EnterCodeFormFields> = {
    code: {
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'Enter code',
        },
        {
          len: props.codeLength,
          message: `Code must be ${props.codeLength} digits long`,
        },
        {
          pattern: /^[0-9]+$/,
          message: 'Code must be numeric',
        },
      ],
    },
  };

  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,
  }));

  const verifySentCode = flow(function* (code: string) {
    const { knoxerId, flowId, email, onSubmit, onBack } = props;

    try {
      yield authenticationStore.forgotPasswordEnterCode(knoxerId, flowId, email, code);
      localStore.inputErrorText = null;
      onSubmit();
    } catch (e: unknown) {
      const response = e as LoginErrorBody;

      switch (response.errorCode) {
        case LoginErrorCodes.ForgotPasswordInvalidCode: {
          localStore.inputErrorText = 'Wrong code, try again';
          break;
        }
        case LoginErrorCodes.ForgotPasswordCodeAlreadyVerified: {
          // this means that we can proceed to enter a new password
          onSubmit();
          break;
        }
        case LoginErrorCodes.ForgotPasswordFlowExpired: {
          messageLauncher.shootErrorOld('Forgot password process has expired, restarting process');
          onBack();
          break;
        }
        case LoginErrorCodes.ForgotPasswordInvalidFlowId: {
          messageLauncher.shootErrorOld(
            'Unexpected error occurred while trying to enter email verification code, restarting process',
          );
          onBack();
          break;
        }
      }
    }
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleOk = (): void => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    if (authenticationStore.loading) {
      return;
    }

    validateFieldsAndScroll((errors: Object, values: EnterCodeFormFields) => {
      if (errors) {
        return;
      }

      const { code } = values;

      verifySentCode(code.trim());
    });
  };

  const handleCodeInputClick = action(() => {
    const { resetFields } = props.form;

    if (localStore.inputErrorText) {
      resetFields();
      localStore.inputErrorText = null;
    }
  });

  const { form, email, codeLength, onBack } = props;

  return (
    <>
      <ContentContainer>
        <Title>Forgot Password</Title>
        <LoginSubTitle>Check {email} inbox for the code to reset your password.</LoginSubTitle>
        <EnterCodeForm onSubmit={handleFormSubmit}>
          <FormItemBox
            appearance='line'
            form={form}
            fieldName='code'
            fieldDecoratorOptions={fieldDecorators.code}
            forcedErrorText={localStore.inputErrorText}
          >
            <NakedFormInput
              name='inpt-auth-forgot-enter-code'
              type='number'
              autoFocus
              colorScheme='secondary'
              placeholder={`Enter ${codeLength} digits code`}
              onMaxLengthAchieved={handleOk}
              maxLength={codeLength}
              onClick={handleCodeInputClick}
              onFocus={handleCodeInputClick}
            />
          </FormItemBox>
          <ActionsContainer>
            <Button id='btn-auth-forgot-enter-code-back' appearance='text' onClick={onBack} size='small'>
              BACK
            </Button>
          </ActionsContainer>
        </EnterCodeForm>
      </ContentContainer>
      <RowSeparator />
      <FooterContainer>
        <FooterTitle>Email not arriving?</FooterTitle>
        <FooterSubTitle>
          Make sure that {email} is spelled correctly and that this email was approved by the user who invited you.
        </FooterSubTitle>
      </FooterContainer>
    </>
  );
});

export default Form.create<Props>()(ForgotPasswordEnterCode);

const ContentContainer = styled.div`
  padding: 48px 64px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const LoginSubTitle = styled(SubTitle)`
  margin-bottom: 15px;
`;

const FooterContainer = styled.div`
  padding: 24px 72px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const EnterCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;
