import React, { FunctionComponent, useMemo, useState } from 'react';
import { PaginationConfig } from 'antd/lib/table';
import {
  getRecordType,
  renderRecordType,
  ShouldSendInstComponentTypes,
  ShouldSendInstructionsToSupplier,
  SupplierRegistrationProcess,
} from '@mortee/domain/morteeRegistrationForms';
import { FormattedDateTime } from '@app/components/Locale';
import Loadable from '@app/utils/Loadable';
import { renderSpecialInfo } from '@app/components/SpecialInfoComponents';
import { isTruthy } from '@app/utils/utils';
import { distinctValuesByKey } from '@app/utils/arrayUtils';
import { ExtendedColumnProps, TableStandardText } from '@app/components/tables/Table';
import CardTable from '@app/components/tables/CardTable';
import { getColumnTextSearchProps } from '@app/components/tableFilters/textSearch/byTextSearchFilterCreator';
import { getColumnMultiValueConstSelectLocalFilterProps } from '@app/components/tableFilters/multiValue/constsFilterCreator';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import { getColumnCustomTimeLocalFilterProps } from '@app/components/tableFilters/dateValue/byColumnCustomTimeFilterCreator';
import SupplierRegistrationItemMenu from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationItemMenu';
import {
  SupplierRegistrationProcessInstructionTypesSubsets,
  SupplierRegistrationProcessFormType,
} from '@app/domain/commonSupplierRegistration';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import { BodyRegular15TransparentBlack900, CaptionStartTransparentBlack400 } from '@app/components/Text';
import { TableRowSelection } from 'antd/lib/table/interface';
import styled from '@emotion/styled';
import SupplierRegistrationTableActions from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationTableActions';
import { css } from '@emotion/css';
import * as supplierRegistrationManagementServices from '@mortee/services/supplierRegistrationManagementServices';
import { transformToSupplierRegistrationProcess } from '@mortee/services/supplierRegistrationManagementServices';
import EyeOffImage from '@app/images/ic-eye-off.svg';
import EyeOnImage from '@app/images/ic-eye-on.svg';
import SVG from '@app/components/SVG';
import Button from '@app/components/Button';
import { shoot } from '@app/utils/messageLauncher';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import AsyncButton from '@app/components/AsyncButton';
import { Tooltip } from 'antd';
import { compare } from '@app/utils/comparatorUtils';
import LinkRegistrationFormsToSupplierValidationRecordActions from '@mortee/routes/supplierRegistrationsManagement/LinkRegistrationFormsToSupplierValidationRecordActions';
import { getInstructionType, renderInstructionType } from '@mortee/domain/validationSystemInfoComponents';

interface SupplierRegistrationsTableProps {
  validationsLoadable: Loadable<SupplierRegistrationProcess[]>;
  onItemClick(item: SupplierRegistrationProcess): void;
  linkToSupplierValidationRecordActions: LinkToSupplierValidationRecordActions | false | undefined;
  className?: string;
  onItemUpdate(record: SupplierRegistrationProcess): void;
}

interface LinkToSupplierValidationRecordActions {
  onItemLinkToNewValidationClick(item: SupplierRegistrationProcess): void;
  onItemLinkToExistingValidationClick(item: SupplierRegistrationProcess): void;
}

const SupplierRegistrationsTable: FunctionComponent<SupplierRegistrationsTableProps> = (props) => {
  const { validationsLoadable, onItemClick, onItemUpdate, linkToSupplierValidationRecordActions, className } = props;
  const modalContext = useModalContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const selectedRecords = useMemo(() => {
    return selectedRowKeys.map((rowKey) => validationsLoadable.result?.find((record) => record.id === rowKey)).filter(isTruthy);
  }, [validationsLoadable.result, selectedRowKeys]);

  const rowSelection: TableRowSelection<SupplierRegistrationProcess> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: string[]) => setSelectedRowKeys(newSelectedRowKeys),
  };

  const updateRecordVisibility = async (
    supplierRegistrationProcessId: string,
    isIgnored: boolean,
  ): Promise<SupplierRegistrationProcess> => {
    const updateSupplierRegistrationProcessesServerResponse = await supplierRegistrationManagementServices.setVisibilitySupplierRegistrationItem(
      { processIds: [supplierRegistrationProcessId], isIgnored },
    );
    return transformToSupplierRegistrationProcess(updateSupplierRegistrationProcessesServerResponse[0]);
  };

  const handleVisibilityChange = async (
    supplierRegistrationProcessId: string,
    registrationNumber: string,
    isIgnored: boolean,
  ): Promise<void> => {
    onItemUpdate(await updateRecordVisibility(supplierRegistrationProcessId, isIgnored));
    shoot({ type: 'success', closeable: true }, (closeFunc) => (
      <ModalAppContext {...modalContext}>
        <Content>
          <BodyRegular15TransparentBlack900.div>
            Record {registrationNumber} was {isIgnored ? 'hidden' : 'unhidden'}
          </BodyRegular15TransparentBlack900.div>
          <Button
            id='undo-button'
            appearance='text'
            colorScheme='primary'
            onClick={async (): Promise<void> => {
              closeFunc();
              onItemUpdate(await updateRecordVisibility(supplierRegistrationProcessId, !isIgnored));
            }}
          >
            UNDO
          </Button>
        </Content>
      </ModalAppContext>
    ));
  };

  const clearSelectedRowsKeys = (): void => {
    setSelectedRowKeys(['']);
  };

  function getRowClassName(item): string {
    if (item.hidden === true) {
      return hiddenRowClassName;
    }

    return '';
  }

  const [filteredData, searchTerm, setSearchTerm] = useLoadableSearchFilter(validationsLoadable, {
    exactMatches(item) {
      return [item.id];
    },
    partialMatches(item) {
      return [
        item.companyName,
        item.additionalCompanyName,
        item.email,
        item.referringCustomer,
        item.accountDetails.accountNumber,
        item.registrationNumber,
      ];
    },
  });

  const paginationConfig: PaginationConfig = {
    position: 'both',
    defaultPageSize: 50,
  };

  return (
    <>
      <TableUtilitiesHeader>
        <StyledInputBox appearance='corners'>
          <NakedFormInput
            name='sv-table-search'
            dataTestId='sv-table-search'
            type='text'
            colorScheme='primary'
            placeholderStyle='onlyWhenEmpty'
            heightType='thick'
            clearable
            placeholder='🔍 Search for Registration Number / Account holder / Account number / Referring customer / Mail / Record Id'
            value={searchTerm}
            onChange={(newValue): void => setSearchTerm(newValue ?? '')}
            autoFocus
          />
        </StyledInputBox>
        <SupplierRegistrationTableActions
          onItemUpdate={onItemUpdate}
          selectedRecords={selectedRecords}
          clearSelectedRows={clearSelectedRowsKeys}
        />
      </TableUtilitiesHeader>
      <CardTable
        rowClassName={getRowClassName}
        data-testid='SupplierRegistrationsTable'
        columns={createColumns(linkToSupplierValidationRecordActions, handleVisibilityChange)}
        dataSource={filteredData.result ?? []}
        rowSelection={rowSelection}
        className={className}
        loading={!filteredData.isResolved()}
        pagination={paginationConfig}
        onRow={(record): { onClick: VoidFunction } => {
          return {
            onClick: (): void => onItemClick(record), // click row
          };
        }}
        rowClickable
        rowKey={(validationProcess: SupplierRegistrationProcess): string => validationProcess.id}
      />
    </>
  );
};

export default SupplierRegistrationsTable;

function getAccountNumberToDisplay(accountDetails: MorteeAccountDetails): string | null {
  return accountDetails.accountNumber ?? accountDetails.iban;
}

const comparatorOfShouldSendInstructions = compare.enum(ShouldSendInstructionsToSupplier);
const instructionTypeTableOptions = distinctValuesByKey(
  SupplierRegistrationProcessInstructionTypesSubsets.registrationFormFilterable.map((instructionType) => ({
    text: renderInstructionType(instructionType),
    value: getInstructionType(instructionType)?.name ?? instructionType,
  })),
  (record) => record.text,
);

function createColumns(
  linkToSupplierValidationRecordActions: LinkToSupplierValidationRecordActions | false | undefined,
  handleVisibilityChange: (
    supplierRegistrationProcessId: string,
    registrationNumber: string,
    isIgnored: boolean,
  ) => Promise<void>,
): ExtendedColumnProps<SupplierRegistrationProcess>[] {
  return [
    {
      title: <div data-testid='lblVisibility'>Visible</div>,
      key: 'visibility',
      width: '120px',
      sorter: compare.byField((record1) => record1.hidden, compare.booleans()),
      ...getColumnMultiValueConstSelectLocalFilterProps(
        [
          { text: 'Hidden', value: 'true' },
          { text: 'Not Hidden', value: 'false' },
        ],
        (value, record): boolean => record.hidden.toString() === value,
      ),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => {
        const isHidden = record.hidden;

        return (
          <StyledCheckIconContainer>
            <AsyncButton
              id='btn-set-hidden'
              appearance='text'
              onClick={async (e): Promise<void> => {
                e.preventDefault();
                e.stopPropagation();
                await handleVisibilityChange(record.id, record.registrationNumber, !isHidden);
              }}
            >
              {isHidden ? (
                <Tooltip title='unhide record'>
                  <EyeSVG onlyShowOnRowHover={!isHidden} accessibilityLabel='unhide record' image={EyeOffImage} />
                </Tooltip>
              ) : (
                <Tooltip title='hide record'>
                  <EyeSVG onlyShowOnRowHover={!isHidden} accessibilityLabel='hide record' image={EyeOnImage} />
                </Tooltip>
              )}
            </AsyncButton>
          </StyledCheckIconContainer>
        );
      },
    },
    {
      key: 'linkToValidation',
      hidden: !linkToSupplierValidationRecordActions,
      className: narrowColumnClassName,
      sorter: compare.byField((record) => record.isLinkedToSvManagementRecord, compare.booleans()),
      ...getColumnMultiValueConstSelectLocalFilterProps(
        [
          { text: 'Linked', value: 'true' },
          { text: 'Unlinked', value: 'false' },
        ],
        (value, record): boolean => record.isLinkedToSvManagementRecord.toString() === value,
      ),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <LinkRegistrationFormsToSupplierValidationRecordActions
          registrationFormRecordId={record.id}
          isRegistrationFormRecordLinkedToSupplierValidationRecord={record.isLinkedToSvManagementRecord}
          onItemLinkToNewValidationClick={(): void => {
            linkToSupplierValidationRecordActions && linkToSupplierValidationRecordActions.onItemLinkToNewValidationClick(record);
          }}
          onItemLinkToExistingValidationClick={(): void => {
            linkToSupplierValidationRecordActions &&
              linkToSupplierValidationRecordActions.onItemLinkToExistingValidationClick(record);
          }}
        />
      ),
    },
    {
      title: <div data-testid='lblWriteTimestamp'>Time of registration</div>,
      dataIndex: 'writeTimestamp',
      key: 'writeTimestamp',
      width: '190px',
      defaultSortOrder: 'descend',
      sorter: compare.writeTimestamp(),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>
          <FormattedDateTime value={record.writeTimestamp} />
        </TableStandardText>
      ),
      ...getColumnCustomTimeLocalFilterProps((record) => record.writeTimestamp),
    },
    {
      title: <div data-testid='lblRegistrationNumber'>Registration Number</div>,
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      width: '150px',
      sorter: compare.byStringField((record) => record.registrationNumber),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText data-testid='supplier-registration-management-table-record-registration-number'>
          {record.registrationNumber}
        </TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => record.registrationNumber),
    },
    {
      title: <div data-testid='lblFormType'>Form Type</div>,
      dataIndex: 'type',
      key: 'type',
      width: '150px',
      sorter: compare.byStringField((record) => record.type),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>{renderRecordType(record)}</TableStandardText>
      ),
      ...getColumnMultiValueConstSelectLocalFilterProps(
        Object.values(SupplierRegistrationProcessFormType).map((formType) => ({
          text: getRecordType(formType)?.name ?? formType,
          value: formType,
        })),
        (value, record): boolean => record.type === value,
      ),
    },
    {
      title: <div data-testid='lblCompany'>Company / Account holder</div>,
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: compare.byStringField((record) => record.companyName),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>
          {record.companyName}
          {record.additionalCompanyName && (
            <CaptionStartTransparentBlack400.div>
              (English name: {record.additionalCompanyName})
            </CaptionStartTransparentBlack400.div>
          )}
        </TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => [record.companyName, record.additionalCompanyName]),
    },
    {
      title: <div data-testid='lblAccountNum'>Account number</div>,
      dataIndex: 'accountDetails',
      key: 'accountDetails',
      sorter: compare.byStringField((record) => getAccountNumberToDisplay(record.accountDetails)),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>{getAccountNumberToDisplay(record.accountDetails)}</TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => getAccountNumberToDisplay(record.accountDetails)),
    },
    {
      title: <div data-testid='lblReferringCustomer'>Referring customer</div>,
      dataIndex: 'referringCustomer',
      key: 'referringCustomer',
      sorter: compare.byStringField((record) => record.referringCustomer),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>{record.referringCustomer}</TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => record.referringCustomer),
    },
    {
      title: <div data-testid='lblInstructions'>Instructions</div>,
      dataIndex: 'instructionType',
      key: 'instructionType',
      width: '160px',
      sorter: compare.byStringField((record) => record.instructionType),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => {
        return <TableStandardText>{renderInstructionType(record.instructionType)}</TableStandardText>;
      },
      ...getColumnMultiValueConstSelectLocalFilterProps(
        instructionTypeTableOptions,
        (value, record): boolean => getInstructionType(record.instructionType)?.name === value,
      ),
    },
    {
      title: <div data-testid='lblShouldSendInst'>Should send instructions</div>,
      dataIndex: 'shouldSendInstructionToSupplier',
      key: 'shouldSendInstructionToSupplier',
      sorter: (record1, record2): number =>
        comparatorOfShouldSendInstructions(record1?.shouldSendInstructionToSupplier, record2?.shouldSendInstructionToSupplier),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>
          {renderSpecialInfo(ShouldSendInstComponentTypes[record.shouldSendInstructionToSupplier])}
        </TableStandardText>
      ),
      ...getColumnMultiValueConstSelectLocalFilterProps(
        Object.entries(ShouldSendInstComponentTypes).map(([shouldSendInstructionsType, option]) => ({
          text: option.name,
          value: shouldSendInstructionsType,
        })),
        (value, record) => record.shouldSendInstructionToSupplier === value,
      ),
    },
    {
      title: <div data-testid='lblMail'>Mail</div>,
      dataIndex: 'email',
      key: 'email',
      colSpan: 2,
      sorter: compare.byStringField((record) => record.email),
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => (
        <TableStandardText>{record.email}</TableStandardText>
      ),
      ...getColumnTextSearchProps((record) => record.email),
    },
    {
      title: '',
      colSpan: 0,
      dataIndex: 'actions',
      key: 'actions',
      width: '60px',
      render: (text: any, record: SupplierRegistrationProcess): React.ReactNode => {
        return <SupplierRegistrationItemMenu id={`registration-forms-table-item-menu`} supplierRegistrationProcess={record} />;
      },
    },
  ];
}

const StyledInputBox = styled(InputBox)`
  flex: 1;
`;

const TableUtilitiesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const narrowColumnClassName = css`
  padding: 0 !important;
`;

const StyledCheckIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-around;
  justify-content: center;
`;

const EyeSVG = styled(SVG, { shouldForwardProp: preventForwardTheseProps('onlyShowOnRowHover') })<{
  onlyShowOnRowHover: boolean;
}>`
  width: 30px;
  height: 30px;
  padding: 3px 4px;

  ${(p): string => (p.onlyShowOnRowHover ? 'opacity: 0;' : '')}

  transition: opacity 0.2s;

  tr:hover & {
    opacity: 1;
  }
`;

const hiddenRowClassName = css`
  background: #00000010;
  opacity: 0.5;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
