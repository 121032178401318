import React, { FC } from 'react';
import ImageFromResource from '@app/components/ImageFromResource';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  logos: ResourceData[] | undefined | null;
  maxLogosToKeepStatic: number;
  className?: string;
}

const LogoCarousel: FC<Props> = ({ logos, maxLogosToKeepStatic, className }) => {
  const { t } = useTranslation();

  if (!logos?.length) {
    return <></>;
  }

  if (logos.length <= maxLogosToKeepStatic) {
    return (
      <StaticLogosContainer className={className}>
        {logos.map((logo, index) => (
          <Logo altName={t('general.accessibility.logo')} resource={logo} key={`logo-${index}`} />
        ))}
      </StaticLogosContainer>
    );
  }

  //to hide the loading when finishing a loop
  const doubledLogos = [...logos, ...logos];

  return (
    <LogosContainer className={className}>
      <LogoRow>
        {doubledLogos.map((logo, index) => (
          <MovingLogo altName={t('general.accessibility.logo')} resource={logo} key={`logo-${index}`} />
        ))}
      </LogoRow>
    </LogosContainer>
  );
};

export default LogoCarousel;

const LogosContainer = styled.div`
  height: 40px;
  position: relative;
  overflow: hidden;
`;

const StaticLogosContainer = styled(LogosContainer)`
  display: flex;
  justify-content: space-around;
`;

const LogoRow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  animation: slideMove 18s linear infinite;

  @keyframes slideMove {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
`;

const Logo = styled(ImageFromResource)`
  max-height: 40px;
`;

const MovingLogo = styled(Logo)`
  margin-left: 100px;
`;
