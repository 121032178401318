import { isDefined } from '@app/utils/utils';

export function createValueInEnumPredicate<E>(enumObj: Record<string, E>): (value: any) => value is E {
  return (value): value is E => {
    if (!isDefined(value)) {
      return false;
    }

    const enumValues = Object.values(enumObj);

    return enumValues.includes(value as E);
  };
}

export function isValueInEnum<E>(enumObj: Record<string, E>, value: any): value is E {
  return createValueInEnumPredicate(enumObj)(value);
}
