import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import Mode from '@app/modes/Mode';

export default function useMode<TMode extends Mode<any>>(): TMode {
  const mode = React.useContext(MobXProviderContext).mode;

  if (!mode) {
    throw new Error(
      'mode is not defined, you might have app context missing, if you are using a modal, please wrap it with ModalAppContext',
    );
  }

  return mode as TMode;
}
